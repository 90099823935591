export type MenuApprovalState = 'submitted' | 'rejected' | 'cancelled' | 'approved';

export class MenuApprovalStates {
  public static SUBMITTED: MenuApprovalState = 'submitted';
  public static REJECTED: MenuApprovalState = 'rejected';
  public static CANCELLED: MenuApprovalState = 'cancelled';
  public static APPROVED: MenuApprovalState = 'approved';

  public static get values(): MenuApprovalState[] {
    return [
      MenuApprovalStates.SUBMITTED,
      MenuApprovalStates.REJECTED,
      MenuApprovalStates.CANCELLED,
      MenuApprovalStates.APPROVED
    ];
  }
}

export type MenuApprovalStateEnum = typeof MenuApprovalStates;
