export interface ProjectedHeadcount {
  minExpected: number;
  maxExpected: number;
  liveHeadcount: number | null;
  maxCapacity: number | null;
  remainingHeadcount: number | null;
  orderNumberList: number[];
}

export function createProjectedHeadcountFromJson(json: Partial<ProjectedHeadcount> = {}): ProjectedHeadcount {
  // TODO CPD-15436 Remove default values when removing old functionality
  const projectedHeadcount: Partial<ProjectedHeadcount> = {};
  projectedHeadcount.minExpected = json.minExpected;
  projectedHeadcount.maxExpected = json.maxExpected;
  projectedHeadcount.liveHeadcount = json.liveHeadcount !== undefined ? json.liveHeadcount : null;
  projectedHeadcount.maxCapacity = json.maxCapacity !== undefined ? json.maxCapacity : null;
  projectedHeadcount.remainingHeadcount = json.remainingHeadcount !== undefined ? json.remainingHeadcount : null;
  projectedHeadcount.orderNumberList = json.orderNumberList || [];

  return projectedHeadcount as ProjectedHeadcount;
}
