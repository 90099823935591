import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractCompactInputComponent } from '../compact-input.component';

@Component({
  selector: 'app-compact-input[type=text]',
  template: `
    <app-compact-input-wrapper
      (focusInput)="focusTextInput()"
      [label]="label"
      [icon]="icon"
      [currentValue]="value"
    >
      <div class="compact-input__input-wrapper">
        <input
          #textInput
          class="compact-input__input"
          [value]="value"
          (enter)="textInput.blur()"
          (blur)="update(textInput.value)"
          test-id="input"
        />
      </div>
    </app-compact-input-wrapper>
  `
})
export class CompactInputTextComponent extends AbstractCompactInputComponent {

  @Input()
  public label: string;

  @ViewChild('textInput', { static: false })
  public textInput: ElementRef;

  public get value(): string {
    return this.control.value;
  }

  public focusTextInput(): void {
    if (this.textInput && this.textInput.nativeElement) {
      this.textInput.nativeElement.focus();
    }
  }

  public update(newText: string): void {
    this.control.setValue(newText);
  }
}
