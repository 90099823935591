import {
  CartCustomItem,
  CartCustomItemOption,
  CartItem,
  CartItemBundle,
  CartItemType,
  CartSingleItem,
  isBundleCartItem,
  isCustomCartItem,
  ItemGroupType
} from '@citypantry/util-models';

interface TransformedCartItem {
  item: string;
  quantity: number;
  type: CartItemType;
}

interface TransformedCartItemBundle extends TransformedCartItem {
  groups: TransformedCartItemBundleGroup[];
}

interface TransformedCartCustomItem extends TransformedCartItem {
  selectedOptions: CartCustomItemOption[];
}

interface TransformedCartItemBundleGroup {
  type: ItemGroupType;
  cartItems: TransformedCartItem[];
}

export function transformCartItemForNetworkRequest(cartItem: CartCustomItem): TransformedCartCustomItem;
export function transformCartItemForNetworkRequest(cartItem: CartItemBundle): TransformedCartItemBundle;
export function transformCartItemForNetworkRequest(cartItem: CartSingleItem): TransformedCartItem;
export function transformCartItemForNetworkRequest(cartItem: CartItem):
TransformedCartItem | TransformedCartItemBundle | TransformedCartCustomItem {
  let transformedCartItem = {};

  if (isBundleCartItem(cartItem)) {
    const groups: TransformedCartItemBundleGroup[] = [];

    for (const group of cartItem.groups) {
      const groupItems = group.cartItems.map((item: CartSingleItem) => transformCartItemForNetworkRequest(item));

      groups.push({
        type: group.type,
        cartItems: groupItems,
      });
    }

    transformedCartItem = {
      ...transformedCartItem,
      groups
    };
  }

  if (isCustomCartItem(cartItem)) {
    transformedCartItem = {
      ...transformedCartItem,
      selectedOptions: cartItem.selectedOptions.filter(({ quantity }) => quantity > 0),
    };
  }

  return {
    ...transformedCartItem,
    item: cartItem.item.id,
    quantity: cartItem.quantity,
    type: cartItem.type
  };
}
