import { ModuleWithProviders, NgModule } from '@angular/core';
import sinon from 'sinon';
import { AnalyticsApi } from '../analytics.api';
import { AnalyticsDataDirective } from './analytics-data.directive';
import { AnalyticsEcommerceContextDirective } from './analytics-ecommerce-context.directive';
import { AnalyticsEnhancedEcommerceDirective } from './analytics-enhanced-ecommerce.directive';

@NgModule({
  declarations: [
    AnalyticsEnhancedEcommerceDirective,
    AnalyticsDataDirective,
    AnalyticsEcommerceContextDirective,
  ],
  exports: [
    AnalyticsEnhancedEcommerceDirective,
    AnalyticsDataDirective,
    AnalyticsEcommerceContextDirective,
  ]
})
export class AnalyticsEcommerceComponentsModule {

  public static forTest(): ModuleWithProviders<AnalyticsEcommerceComponentsModule> {
    return {
      ngModule: AnalyticsEcommerceComponentsModule,
      providers: [
        {
          provide: AnalyticsApi,
          useValue: {
            addEcommerceImpression: sinon.stub(),
            addEcommerceProduct: sinon.stub()
          } as Partial<AnalyticsApi>
        }
      ]
    };
  }
}
