import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { isDateOnWeekend, IsoDate } from '@citypantry/util';
import {
  combineDeliveryDateTime,
  splitDeliveryDateTime,
  VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES,
  VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES,
} from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';
import moment, { Moment } from 'moment';
import { pairwise, startWith } from 'rxjs/operators';

interface FormValue {
  date: string;
  time: number;
}

const getAvailableDeliveryTimes = (date: moment.Moment): number[] =>
  isDateOnWeekend(date) ? VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES : VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES;

@Component({
  selector: 'app-date-parameters-form',
  templateUrl: './date-parameters-form.component.html'
})
export class DateParametersFormComponent implements OnInit {
  @Input()
  public control: AbstractControl;

  @Input()
  public minDay: IsoDate;

  public form: FormGroup;
  public deliveryTimes: number[] = [];

  public ngOnInit(): void {
    const initialValue: { date: Moment } = this.control.value;

    const { date, time: timeInitialValue } = splitDeliveryDateTime(initialValue && initialValue.date);

    this.deliveryTimes = getAvailableDeliveryTimes(moment.tz(date, 'YYYY-MM-DD', 'Europe/London'));

    const dateControl = new FormControl(
      date,
      {
        validators: [Validators.required, CustomValidators.date],
        updateOn: 'change'
      },
    );

    const timeControl = new FormControl(
      timeInitialValue,
      {
        validators: [
          Validators.required,
          (control) => CustomValidators.timeInListAndDateOnWeekend(dateControl, this.deliveryTimes)(control),
          (control) => CustomValidators.valueInList(this.deliveryTimes)(control),
        ],
        updateOn: 'change'
      }
    );
    this.form = new FormGroup({
      date: dateControl,
      time: timeControl,
    });

    this.form.valueChanges.pipe(
      startWith(this.form.value as FormValue),
      pairwise()
    ).subscribe(([prev, next]) => {
      if (prev && next && prev.date !== next.date) {
        const nextDeliveryTimes = getAvailableDeliveryTimes(moment.tz(next.date, 'YYYY-MM-DD', 'Europe/London'));
        // only update deliveryTimes if the available times for the selected date
        // differ from those available for the previously selected date
        if (this.deliveryTimes !== nextDeliveryTimes) {
          this.deliveryTimes = nextDeliveryTimes;
          timeControl.updateValueAndValidity({ onlySelf: true });
        }
      }
      this.control.setValue(this.getParametersFromFormValue(next));
    });

    this.control.setValidators((ctrl: AbstractControl) => {
      const value = ctrl.value;
      return value && value.date ? null : { invalid: true };
    });
    this.control.setValue(this.getParametersFromFormValue(this.form.value), { emitEvent: false });
  }

  public onBlur(): void {
    this.control.markAsTouched();
  }

  private getParametersFromFormValue(value: FormValue): DateParameter {
    const date = this.form.controls.date.valid && this.form.controls.time.valid ?
      combineDeliveryDateTime(value.date, value.time) :
      null;

    return {
      date
    };
  }
}

export interface DateParameter { date: Moment }
