<form
  [formGroup]="form"
  novalidate
  analyticsId="item-bundle-modal-form"
>
  <div
    *ngIf="form.get('quantity').invalid"
    class="section-alert mb-standard"
    test-id="quantityErrors"
  >
    <span
      class="section-alert__message"
      [errorSwitch]="form.get(['quantity']).errors"
    >
      <ng-container *errorCase="'min'">
        The minimum order is for {{ item.minimumOrderQuantity }} {{ 'people' | plural:item.minimumOrderQuantity }}
      </ng-container>
      <ng-container *errorCase="'max'">
        The maximum order is for {{ item.maximumOrderQuantity }} {{ 'people' | plural:item.maximumOrderQuantity }}
      </ng-container>
      <ng-container *errorCase="'default'">
        There is a problem with this input.
      </ng-container>
    </span>
  </div>

  <div class="mb-5 d-flex align-items-center">
    <ng-container *ngIf="useSimplifiedQuantity; else manualQuantityInput">
      <i class="icon icon-users text-color-stone-2 mr-1"></i>
      <b test-id="computedQuantity">
        {{ getBundleCount() }} {{ 'people' | plural:getBundleCount() }}
      </b>
      <span class="ml-x-small text-muted">
        from options selected
      </span>
    </ng-container>
    <ng-template #manualQuantityInput>
      <app-quantity-picker
        [min]="item.minimumOrderQuantity"
        [max]="item.maximumOrderQuantity"
        formControlName="quantity"
        analyticsId="manual-quantity"
        test-id="manualQuantity"
      ></app-quantity-picker>
      <i class="icon icon-users text-color-stone-2 ml-2 mr-1"></i>
      <span *ngIf="item.servingStyle !== ServingStyles.PLATTER" test-id="quantityType">
        {{ 'people' | plural:getBundleCount() }}
      </span>
      <span *ngIf="item.servingStyle === ServingStyles.PLATTER" test-id="quantityType">
        {{ 'platter' | plural:getBundleCount() }}
        <span test-id="platterFeedsCount">
          (Feeds {{ getPlatterFeedsQuantity() }} {{ 'people' | plural:getPlatterFeedsQuantity() }})
        </span>
      </span>
    </ng-template>

    <div
      *ngIf="shouldShowKcalTotal()"
      class="ml-auto text-color-stone-2"
      test-id="kcalTotal"
    ><strong>{{calculateKcalTotal()}}kcal</strong> total</div>
  </div>

  <section
    *ngFor="let itemGroup of item.groups; index as groupIndex"
    class="mb-5"
  >
    <app-choice-group
      *ngIf="itemGroup.type === ItemGroupTypes.CHOICE_GROUP"
      [showErrors]="wasSubmitted"
      [itemGroup]="itemGroup"
      [formGroup]="form.get(['itemGroups', groupIndex])"
      test-id="groups"
    ></app-choice-group>
    <app-fixed-group
      *ngIf="itemGroup.type === ItemGroupTypes.FIXED_GROUP"
      [itemGroup]="itemGroup"
      [bundleCount]="getBundleCount()"
      test-id="groups"
    ></app-fixed-group>
    <app-flexible-group
      *ngIf="itemGroup.type === ItemGroupTypes.FLEXIBLE_GROUP"
      [itemGroup]="itemGroup"
      [formGroup]="form.get(['itemGroups', groupIndex])"
      test-id="groups"
    ></app-flexible-group>
    <app-upgrade-group
      *ngIf="itemGroup.type === ItemGroupTypes.UPGRADE_GROUP"
      [itemGroup]="itemGroup"
      [formGroup]="form.get(['itemGroups', groupIndex])"
      [bundleCount]="getBundleCount()"
      [canOverrideQuantities]="canOverrideQuantities"
      [hidePrices]="hidePrices"
      test-id="groups"
    ></app-upgrade-group>
  </section>

  <div class="item-modal__footer">
    <app-item-modal-footer
      [subtotal]="calculateItemTotal()"
      [hidePrices]="hidePrices"
      (cancel)="onCancel()"
      test-id="footer"
    >
      <button
        item-modal-footer-slot="submitButton"
        type="button"
        class="button button--primary"
        (click)="onSubmitItem()"
        [analyticsItem]="item"
        ecommerceEventTrigger="click"
        [ecommerceActionType]="AnalyticsEcommerceActions.ADD"
        [ecommerceEventId]="AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_ADD_BUTTON"
        test-id="submitButton"
      >
        {{ cartItem ? 'Update in cart' : 'Add to cart' }}
      </button>
    </app-item-modal-footer>
  </div>
</form>
