/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type PositiveFoodFeedbackTag = 'Tasty' | 'As described' | 'Great presentation' | 'Well labelled' | 'Good portion size';

export class PositiveFoodFeedbackTags {
  public static TASTY:              PositiveFoodFeedbackTag & 'Tasty'              = 'Tasty';
  public static AS_DESCRIBED:       PositiveFoodFeedbackTag & 'As described'       = 'As described';
  public static GREAT_PRESENTATION: PositiveFoodFeedbackTag & 'Great presentation' = 'Great presentation';
  public static WELL_LABELLED:      PositiveFoodFeedbackTag & 'Well labelled'      = 'Well labelled';
  public static GOOD_PORTION_SIZE:  PositiveFoodFeedbackTag & 'Good portion size'  = 'Good portion size';

  public static get values(): PositiveFoodFeedbackTag[] {
    return [
      PositiveFoodFeedbackTags.TASTY,
      PositiveFoodFeedbackTags.AS_DESCRIBED,
      PositiveFoodFeedbackTags.GREAT_PRESENTATION,
      PositiveFoodFeedbackTags.WELL_LABELLED,
      PositiveFoodFeedbackTags.GOOD_PORTION_SIZE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type PositiveFoodFeedbackTagEnum = typeof PositiveFoodFeedbackTags;
