<div
  class="alert"
  [ngClass]="type !== 'warning' && 'alert--' + type"
  [class.alert--transparent]="isTransparent"
  test-id="alert"
>
  <span e2e-test-id="appAlertContent">
    <ng-content></ng-content>
  </span>
</div>
