import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePickerModule } from '@citypantry/components-date-picker';
import { DatetimeComponentsModule } from '@citypantry/components-datetime';
import { FeedbackComponentsModule } from '@citypantry/components-feedback';
import { FormComponentsModule } from '@citypantry/components-form';
import { ImageComponentsModule } from '@citypantry/components-image';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { TextComponentsModule } from '@citypantry/components-text';
import { TooltipComponentsModule } from '@citypantry/components-tooltip';
import { VendorInfoComponentsModule } from '@citypantry/components-vendor-info';
import { AnalyticsComponentsModule, AnalyticsEcommerceComponentsModule } from '@citypantry/shared-analytics';
import { RootModule } from '@citypantry/shared-root';
import { EditableDropdownComponent } from './editable-dropdown/editable-dropdown.component';
import { FindNewVendorPopupComponent } from './find-new-vendor-dialog/find-new-vendor-popup.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { ParametersFormComponent } from './parameters-form/parameters-form.component';
import { RecommendedVendorsComponent } from './recommended-vendors/recommended-vendors.component';
import { SearchCantFindWhatYourLookingForComponent } from './search-cant-find-what-your-looking-for/search-cant-find-what-your-looking-for.component';
import { SearchFiltersBarComponent } from './search-filters-bar/search-filters-bar.component';
import { SearchFiltersButtonComponent } from './search-filters-button/search-filters-button.component';
import { SearchFiltersEcoFriendlyPackagingToggleComponent } from './search-filters-eco-friendly-packaging-toggle/search-filters-eco-friendly-packaging-toggle.component';
import { SearchFiltersFavouritesToggleComponent } from './search-filters-favourites-toggle/search-filters-favourites-toggle.component';
import { SearchFiltersFormComponent } from './search-filters-form/search-filters-form.component';
import { SearchFiltersListComponent } from './search-filters-list/search-filters-list.component';
import { SearchFiltersTogglesComponent } from './search-filters-toggles/search-filters-toggles.component';
import { SearchIndividualChoiceToggleComponent } from './search-individual-choice-toggle/search-individual-choice-toggle.component';
import { SearchLayoutSlotDirective } from './search-layout/search-layout-slot.directive';
import { SearchLayoutComponent } from './search-layout/search-layout.component';
import { SearchParametersPopupComponent } from './search-parameters-popup/search-parameters-popup.component';
import { SearchParamsHeaderComponent } from './search-params-header/search-params-header.component';
import { SearchParametersPopupContentComponent } from './search-params-popup-content/search-parameters-popup-content.component';
import { SearchPopupHeaderComponent } from './search-popup-header/search-popup-header.component';
import { SearchPromoCardComponent } from './search-promo-card/search-promo-card.component';
import { SearchRecommendationResultsComponent } from './search-recommendation-results/search-recommendation-results.component';
import { SearchResultPlaceholderComponent } from './search-result-placeholder/search-result-placeholder.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchResultsListComponent } from './search-results-list/search-results-list.component';
import { SearchSortDropdownComponent } from './search-sort-dropdown/search-sort-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,

    ImageComponentsModule,
    ModalComponentsModule,
    AnalyticsEcommerceComponentsModule,
    AnalyticsComponentsModule,
    DatetimeComponentsModule,
    TextComponentsModule,
    TooltipComponentsModule,
    FormComponentsModule,
    DatePickerModule,
    FeedbackComponentsModule,
    VendorInfoComponentsModule,
    RootModule,
  ],
  declarations: [
    FindNewVendorPopupComponent,
    SearchLayoutComponent,
    SearchLayoutSlotDirective,
    SearchParamsHeaderComponent,
    SearchPopupHeaderComponent,
    SearchResultComponent,
    SearchResultPlaceholderComponent,
    SearchResultsListComponent,
    NoResultsComponent,
    SearchFiltersBarComponent,
    SearchFiltersTogglesComponent,
    SearchFiltersEcoFriendlyPackagingToggleComponent,
    SearchFiltersFavouritesToggleComponent,
    SearchFiltersButtonComponent,
    SearchFiltersListComponent,
    SearchParametersPopupContentComponent,
    ParametersFormComponent,
    SearchSortDropdownComponent,
    SearchIndividualChoiceToggleComponent,
    SearchCantFindWhatYourLookingForComponent,
    SearchRecommendationResultsComponent,
    RecommendedVendorsComponent,
    SearchPromoCardComponent,
    SearchFiltersFormComponent,
    SearchParametersPopupComponent,
    EditableDropdownComponent,
  ],
  exports: [
    // Only export components whose tags are used in templates in other modules
    FindNewVendorPopupComponent,
    SearchLayoutComponent,
    SearchLayoutSlotDirective,
    SearchParamsHeaderComponent,
    SearchPopupHeaderComponent,
    SearchResultComponent,
    SearchResultPlaceholderComponent,
    SearchResultsListComponent,
    NoResultsComponent,
    SearchFiltersBarComponent,
    SearchFiltersTogglesComponent,
    SearchFiltersEcoFriendlyPackagingToggleComponent,
    SearchFiltersFavouritesToggleComponent,
    SearchFiltersButtonComponent,
    SearchFiltersListComponent,
    SearchParametersPopupContentComponent,
    ParametersFormComponent,
    SearchSortDropdownComponent,
    SearchIndividualChoiceToggleComponent,
    SearchCantFindWhatYourLookingForComponent,
    SearchRecommendationResultsComponent,
    RecommendedVendorsComponent,
    SearchPromoCardComponent,
    SearchParametersPopupComponent,
    SearchFiltersFormComponent,
  ]
})
export class SearchComponentsModule { }
