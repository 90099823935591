type ResultSuccess<S> = (S extends void ? { success: true } : { success: true, value: S });

interface ResultFailure<E> { success: false, error?: E }

/**
 * To discriminate between Result success and error types, use `result.success === true` or `result.success === false`.
 */
export type Result<S, E = any> = ResultSuccess<S> | ResultFailure<E>;

export namespace Result {
  export function success(): ResultSuccess<void>;
  export function success<S>(value: S): ResultSuccess<S>;
  export function success<S>(value?: S): ResultSuccess<S> {
    if (typeof value === 'undefined') {
      return {
        success: true
      } as ResultSuccess<S>;
    } else {
      return {
        success: true,
        value
      } as ResultSuccess<S>;
    }
  }

  export function failure<E>(error?: E): ResultFailure<E> {
    return {
      success: false,
      error
    };
  }
}
