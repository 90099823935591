import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ItemsResponse, getItem, getItems, mapmap, objectToParams } from '@citypantry/util';
import { AgeConfirmationEnum } from '@citypantry/util-enums';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  CartItem,
  ChoiceDeadline,
  CustomerLocation,
  CustomerLocationId,
  EaterAtLocation,
  EaterCart,
  EaterCartSubmitError,
  EaterFeedback,
  EaterMeal,
  EaterRefundRequest,
  EatersSummary,
  IndividualChoiceOrder,
  IndividualChoiceOrderGroup,
  IndividualChoicePaymentDetails,
  ItemId,
  MinorCurrency,
  OrderHumanId,
  OrderId,
  OrderValidationError,
  Page,
  PaginateQuery,
  PaymentCard,
  PaymentCardId,
  PaymentIntentSummary,
  PaymentTerms,
  SelectedVendor,
  UserId,
  ValidateOrderGroupData,
  ValidationResponse,
  createCustomerLocationFromJson,
  createEaterAtLocationFromJson,
  createEaterCartFromJson,
  createEaterFeedbackFromJson,
  createEaterMealFromJson,
  createEatersSummaryFromJson,
  createIndividualChoiceOrderFromJson,
  createIndividualChoiceOrderGroupFromJson,
  createPaymentCardFromJson,
  createPaymentIntentSummaryFromJson,
  minorCurrencyToMajor,
} from '@citypantry/util-models';
import moment, { Moment } from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import {
  CreateIndividualChoiceOrderGroupRequest,
  IndividualChoiceChildOrderPayload,
  IndividualChoiceOrderGroupCreationRequest,
  ValidateIndividualChoiceOrderGroupRequest,
} from '../models';
import { transformCartItemForNetworkRequest } from '../transformers';

export interface EaterCartUpdateResult {
  cart?: EaterCart;
  error?: EaterCartSubmitError;
}

export interface OrderGroupValidationResponse {
  validationErrors: string[];
  childOrders: [{
    id: OrderId;
    validationErrors: string[];
  }];
}

// TODO CPD-12327 Add useHiddenBudget to ValidateOrderGroupData once feature fully implemented on frontend for individual choice setup
export type ValidateOrderGroupDataWithUseHiddenBudget = ValidateOrderGroupData & { useHiddenBudget: boolean };

@Injectable({
  providedIn: 'root'
})
export class IndividualChoiceApi {

  constructor(
    private http: HttpClient,
  ) { }

  public createOrderGroup(
    deliveryDate: Moment,
    location: CustomerLocation,
    choiceDeadline: ChoiceDeadline,
    budget: MinorCurrency,
    selectedVendors: SelectedVendor[],
    expectedHeadcount: number,
    isSubsidisedChoiceTurnedOn: boolean,
    paymentDetails: IndividualChoicePaymentDetails,
    useHiddenBudget: boolean,
    isColleagueGroupsToggledOn: boolean,
  ): Observable<ValidationResponse<IndividualChoiceOrderGroup, HttpErrorResponse>> {
    const payload = mapCreateOrderParametersToPayload(
      deliveryDate,
      location,
      choiceDeadline,
      budget,
      selectedVendors,
      expectedHeadcount,
      isSubsidisedChoiceTurnedOn,
      useHiddenBudget,
      isColleagueGroupsToggledOn,
      paymentDetails,
    );

    return this.http.post(`/individual-choice-order-groups`, payload, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(createIndividualChoiceOrderGroupFromJson),
      map((individualChoiceOrderGroup: IndividualChoiceOrderGroup) => ({
        success: true as const,
        value: individualChoiceOrderGroup,
      })),
      catchError((errorResponse: HttpErrorResponse) => throwError({
        success: false as const,
        error: errorResponse.error
      }))
    );
  }

  public validateOrderGroup(
    validateOrderGroupData: ValidateOrderGroupDataWithUseHiddenBudget,
  ): Observable<ValidationResponse<IndividualChoiceOrderGroup, OrderValidationError>> {

    const payload = mapValidateOrderParametersToPayload(validateOrderGroupData);

    return this.http.post(`/individual-choice-order-groups/validate`, payload, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(createIndividualChoiceOrderGroupFromJson),
      map((individualChoiceOrderGroup: IndividualChoiceOrderGroup) => ({
        success: true as const,
        value: individualChoiceOrderGroup,
      })),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 422) {
          const errors = errorResponse.error.errors;
          const handledErrorType = [
            'VendorHasEnoughCapacity',
            'SameDayOrderIsAllowed',
            'VendorHasViableIndividualChoiceEnabledLocation',
            'BeforeChoiceDeadline',
            'HasSufficientTimeToChoose',
            'SelectedVendorLocationsCanCoverExpectedHeadcount',
            'SelectedVendorLocationHasEnoughCapacity',
            'IsSelectedVendorLocationIndividualChoiceViable'
          ].find((errorType) => errors.hasOwnProperty(errorType));

          if (handledErrorType) {
            return of({
              success: false as const,
              error: {
                type: handledErrorType,
                message: errors[handledErrorType].message,
                messageArgs: errors[handledErrorType].messageArgs
              }
            });
          } else {
            return throwError(errorResponse.error);
          }
        } else {
          return throwError(errorResponse.error);
        }
      }),
    );
  }

  public validateDeliveryDate(orderGroupId: OrderId, deliveryDate: Moment): Observable<OrderGroupValidationResponse> {
    return this.http.post<OrderGroupValidationResponse>(`/customer/individual-choice-order-groups/${orderGroupId}/validate-delivery-date`, {
      deliveryDate
    });
  }

  public getEatersSummary(orderGroupId: OrderId): Observable<EatersSummary> {
    return this.http.get(`/individual-choice-order-groups/${orderGroupId}/eaters`).pipe(
      getItem(),
      map(createEatersSummaryFromJson),
    );
  }

  public getEatersSummaryForChildOrder(childOrderId: OrderId): Observable<EatersSummary> {
    return this.http.get(`/individual-choice/${childOrderId}/eaters`).pipe(
      getItem(),
      map(createEatersSummaryFromJson),
    );
  }

  public deleteEaterCart(userId: UserId | 'me', orderId: OrderId): Observable<OrderId> {
    return this.http.delete(`/eaters/${userId}/orders/${orderId}/cart`).pipe(
      mapTo(orderId),
    );
  }

  public submitExtraItemsCart(
    orderId: OrderId,
    cartItems: CartItem[],
    extraItemsAdditionalReference: string,
    hasCustomerConfirmedAgeForExtraItems: AgeConfirmationEnum,
  ): Observable<IndividualChoiceOrder> {
    return this.http.put(`/individual-choice/${orderId}/extras`, {
      extraItems: cartItems.map(transformCartItemForNetworkRequest),
      extraItemsAdditionalReference,
      hasCustomerConfirmedAgeForExtraItems: (hasCustomerConfirmedAgeForExtraItems === AgeConfirmationEnum.CONFIRMED),
    }).pipe(
      getItem(),
      map(createIndividualChoiceOrderFromJson)
    );
  }

  public createOrUpdateEaterCart(
    orderId: OrderId,
    cartItems: CartItem[],
    eaterId: UserId | 'me',
    departmentReference: string,
    deskNumber: string | null,
    hasEaterConfirmedAge: AgeConfirmationEnum,
    recommendedItemId: ItemId | null,
  ): Observable<EaterCartUpdateResult> {
    const payload = {
      cartItems: cartItems.map(transformCartItemForNetworkRequest),
      departmentReference,
      deskNumber,
      hasEaterConfirmedAge: (hasEaterConfirmedAge === AgeConfirmationEnum.CONFIRMED),
      recommendedItemId
    };
    return this.http.put(`/eaters/${eaterId}/orders/${orderId}/cart`, payload, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(createEaterCartFromJson),
      map((cart) => ({ cart })),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // error - HttpErrorResponse
          // error.error - Response body
          // error.error.errors - list of errors returned by the API
          if (error.status === 422 && error.error && error.error.errors) {
            const errors = error.error.errors;
            if (errors.IsPlacedBeforeDeadline) {
              return of<EaterCartUpdateResult>({
                error: {
                  type: 'PastDeadline',
                  messageArgs: {
                    deadline: moment(errors.IsPlacedBeforeDeadline.messageArgs.deadline)
                  }
                }
              });
            }
            if (errors.IsWithinBudget) {
              return of<EaterCartUpdateResult>({
                error: {
                  type: 'OverBudget',
                  messageArgs: {
                    budget: errors.IsWithinBudget.messageArgs.budget
                  }
                }
              });
            }
            if (errors.EaterCanOrderFromMultipleVendors) {
              return of<EaterCartUpdateResult>({
                error: {
                  type: 'DifferentVendorChosenAlready',
                },
              });
            }
            if (errors.IsWithinVendorMaxHeadcount) {
              return of<EaterCartUpdateResult>({
                error: {
                  type: 'VendorReachedCapacity',
                },
              });
            }
          }
        }
        return throwError(error);
      })
    );
  }

  public addVendorToOrder(orderGroupId: OrderId, payload: IndividualChoiceChildOrderPayload): Observable<void> {
    return this.http.post<void>(`/individual-choice-order-groups/${orderGroupId}/add-order`, payload);
  }

  // refactor paymentMethodId's type when it can be something different from a remembered payment card
  public createPaymentIntent(
    orderId: OrderId,
    eaterId: UserId | 'me',
    rememberCard: boolean,
    paymentMethodId: PaymentCardId
  ): Observable<PaymentIntentSummary> {
    return this.http.get(`/eaters/${eaterId}/orders/${orderId}/create-stripe-payment-intent`,
      { params: objectToParams({ rememberCard, paymentMethod: paymentMethodId }) },
    ).pipe(
      getItem(),
      map(createPaymentIntentSummaryFromJson)
    );
  }

  public getMyMeals(): Observable<EaterMeal[]> {
    return this.http.get<ItemsResponse<EaterMeal>>(
      `/eaters/me/carts`, {
        params: {
          from: moment().tz('Europe/London').startOf('day').toISOString()
        }
      }
    ).pipe(
      getItems(),
      mapmap(createEaterMealFromJson),
    );
  }

  public getMyPreviousMeals(query: PaginateQuery): Observable<Page<EaterMeal>> {
    const sortedQuery: PaginateQuery = {
      ...query,
      sortBy: 'requestedDeliveryDate',
      sortDir: 'desc'
    };
    return this.http.get<Page<EaterMeal>>(
      `/eaters/me/past-carts`, { params: objectToParams(sortedQuery) }
    ).pipe(
      map((page) => ({
        ...page,
        items: page.items.map(createEaterMealFromJson)
      })),
    );
  }

  public markEaterAsAway(eaterId: UserId | 'me', orderId: OrderId): Observable<OrderId> {
    const payload = {
      eater: eaterId,
      isAway: true
    };

    return this.http.put(`/individual-choice-order-groups/${orderId}/update-eater-status`, payload).pipe(
      mapTo(orderId)
    );
  }

  public clearEaterAwayStatus(eaterId: UserId | 'me', orderId: OrderId): Observable<OrderId> {
    const payload = {
      eater: eaterId,
      isAway: false
    };

    return this.http.put(`/individual-choice-order-groups/${orderId}/update-eater-status`, payload).pipe(
      mapTo(orderId)
    );
  }

  public getLocationsWithEaters(): Observable<CustomerLocation[]> {
    return this.http.get('/individual-choice/locations').pipe(
      getItems(),
      mapmap(createCustomerLocationFromJson)
    );
  }

  public getEatersAtLocation(locationId: string): Observable<EaterAtLocation[]> {
    return this.http.get(`/individual-choice/locations/${locationId}/eaters`).pipe(
      getItems(),
      mapmap(createEaterAtLocationFromJson)
    );
  }

  public getPreviousDepartmentReferences(): Observable<string[]> {
    return this.http.get(`/eaters/me/carts/recent-department-references`).pipe(
      getItems(),
    );
  }

  public getEaterCart(eaterId: UserId | 'me', orderId: OrderId | OrderHumanId): Observable<EaterCart | null> {
    return this.http.get(`/eaters/${eaterId}/orders/${orderId}/cart`,
      { headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' } }
    ).pipe(
      getItem(),
      map(createEaterCartFromJson),
      catchError((errorResponse) => {
        const error = errorResponse.apiResponse || errorResponse;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of(null);
        } else {
          return throwError(errorResponse.error);
        }
      })
    );
  }

  public deleteEaterLocation(userId: UserId, locationId: CustomerLocationId): Observable<string> {
    return this.http.delete(`/eaters/${userId}/locations/${locationId}`).pipe(
      mapTo(locationId),
    );
  }

  public getPaymentCard(userId: UserId | 'me'): Observable<PaymentCard> {
    return this.http.get(`/users/${userId}/payment-cards`).pipe(
      getItems(),
      map((items) => items[0]),
      map((item) => item ? createPaymentCardFromJson(item) : null)
    );
  }

  public deletePaymentCard(paymentCardId: PaymentCardId): Observable<true> {
    return this.http.delete(`/payment-methods/${paymentCardId}`).pipe(
      mapTo<any, true>(true)
    );
  }

  public submitEaterRefundRequest(feedbackForm: EaterRefundRequest): Observable<true> {
    return this.http.post(
      `/eaters/me/issues`,
      feedbackForm,
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'} },
    ).pipe(
      mapTo<any, true>(true)
    );
  }

  public editDeliveryDate(orderId: OrderId, deliveryDate: Moment): Observable<IndividualChoiceOrderGroup> {
    return this.http.post<IndividualChoiceOrderGroup>(`/individual-choice-order-groups/${orderId}/update-delivery-date`, {
      deliveryDate
    }).pipe(
      map(createIndividualChoiceOrderGroupFromJson)
    );
  }

  public updateDeliveryContact(orderId: OrderId, deliveryContactId: string): Observable<IndividualChoiceOrderGroup> {
    return this.http.post<IndividualChoiceOrderGroup>(
      `/individual-choice-order-groups/${orderId}/delivery-contact/${deliveryContactId}`,
      null
    ).pipe(
      map(createIndividualChoiceOrderGroupFromJson)
    );
  }

  public getEaterFeedback(eaterId: UserId, orderId: OrderId): Observable<EaterFeedback | null> {
    return this.http.get(`/eaters/${eaterId}/orders/${orderId}/feedback`).pipe(
      map((res: Partial<EaterFeedback> | null) => res ? createEaterFeedbackFromJson(res) : null)
    );
  }

  public createEaterFeedback(eaterFeedback: EaterFeedback): Observable<EaterFeedback> {
    return this.http.post<EaterFeedback>(`/eaters/${eaterFeedback.userId}/orders/${eaterFeedback.orderId}/feedback`, eaterFeedback).pipe(
      map(createEaterFeedbackFromJson),
    );
  }
}

export function mapOrderParametersToPayload(
  deliveryDate: Moment,
  choiceDeadline: ChoiceDeadline,
  budget: MinorCurrency,
  selectedVendors: SelectedVendor[],
  expectedHeadcount: number,
  isSubsidisedChoiceTurnedOn: boolean,
  useHiddenBudget: boolean,
  isColleagueGroupsToggledOn: boolean,
  paymentDetails?: IndividualChoicePaymentDetails
): IndividualChoiceOrderGroupCreationRequest {
  return {
    requestedDeliveryDate: deliveryDate,
    choiceDeadline,
    choiceOpenTime: paymentDetails && paymentDetails.choiceOpenTimeSetting && paymentDetails.choiceOpenTimeSetting.type === 'scheduled'
      ? paymentDetails.choiceOpenTimeSetting.value
      : null,
    budget: minorCurrencyToMajor(budget),
    childOrders: selectedVendors.map((selectedVendor) => ({
      menuContent: selectedVendor.contentId,
      menuItems: selectedVendor.selectedItemIds,
      selectedVendorLocationId: selectedVendor.vendorLocationId,
    })),
    expectedHeadcount,
    isSubsidisedChoiceTurnedOn,
    useHiddenBudget,
    card: paymentDetails && paymentDetails.card ? paymentDetails.card : null,
    paymentTerm: paymentDetails && paymentDetails.paymentTerm ? PaymentTerms.mapEnumToApiValue(paymentDetails.paymentTerm) : null,
    threeDSecureEnrichedNonce: paymentDetails && paymentDetails.threeDSecureEnrichedNonce ? paymentDetails.threeDSecureEnrichedNonce : null,
    purchaseOrderNumber: paymentDetails && paymentDetails.costAllocation ? paymentDetails.costAllocation.purchaseOrderNumber : '',
    departmentReference: paymentDetails && paymentDetails.costAllocation ? paymentDetails.costAllocation.departmentReference : '',
    cartsAdditionalReferenceRequired: paymentDetails && paymentDetails.costAllocation
      ? paymentDetails.costAllocation.cartsAdditionalReferenceRequired : false,
    colleagueGroupsEnabled: isColleagueGroupsToggledOn,
  };
}

export function mapCreateOrderParametersToPayload(
  deliveryDate: Moment,
  location: CustomerLocation,
  choiceDeadline: ChoiceDeadline,
  budget: MinorCurrency,
  selectedVendors: SelectedVendor[],
  expectedHeadcount: number,
  isSubsidisedChoiceTurnedOn: boolean,
  useHiddenBudget: boolean,
  isColleagueGroupsToggledOn: boolean,
  paymentDetails?: IndividualChoicePaymentDetails,
): CreateIndividualChoiceOrderGroupRequest {
  return {
    location: location.id,
    ...mapOrderParametersToPayload(
      deliveryDate,
      choiceDeadline,
      budget,
      selectedVendors,
      expectedHeadcount,
      isSubsidisedChoiceTurnedOn,
      useHiddenBudget,
      isColleagueGroupsToggledOn,
      paymentDetails,
    )
  };
}

export function mapValidateOrderParametersToPayload(
  validateOrderGroupData: ValidateOrderGroupDataWithUseHiddenBudget
): ValidateIndividualChoiceOrderGroupRequest {
  return {
    location: validateOrderGroupData.location,
    ...mapOrderParametersToPayload(
      validateOrderGroupData.deliveryDate,
      validateOrderGroupData.choiceDeadline,
      validateOrderGroupData.budget,
      validateOrderGroupData.selectedVendors,
      validateOrderGroupData.expectedHeadcount,
      validateOrderGroupData.isSubsidisedChoiceTurnedOn,
      validateOrderGroupData.useHiddenBudget,
      validateOrderGroupData.isColleagueGroupsToggledOn,
      validateOrderGroupData.paymentDetails,
    )
  };
}
