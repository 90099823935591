<div class="row no-gutters mb-small">
  <div class="col-auto">
    <input
      type="checkbox"
      [formControl]="control"
      test-id="checkbox"
    />
  </div>
  <div class="col ml-small">
    <div class="mb-small">
      <label
        (click)="toggleValue()"
        test-id="name"
      >{{ item.name }} </label>
      <a
        class="link link--light link--small text-nowrap"
        (click)="onToggleShowMoreInfo()"
        test-id="toggleInfoLink"
      >{{ showMoreInfo ? 'Less info' : 'More info' }}</a>
    </div>
    <div
      *ngIf="shouldShowKcals()"
      class="mt-small text-color-stone-2"
      test-id="kcal"
    >{{ item.kcal }}kcal per portion</div>
    <div
      *ngIf="item.ageRestricted || hasDietaries()"
      class="d-flex mt-small"
    >
      <span
        *ngIf="item.ageRestricted"
        class="icon icon--medium"
        aria-label="Age restricted"
        test-id="ageRestricted"
      >age_restricted</span>
      <app-dietaries
        *ngIf="hasDietaries()"
        class="vertical-separator"
        [dietaries]="item.dietaries"
        test-id="dietaries"
      ></app-dietaries>
    </div>
  </div>
</div>
