import { InjectionToken } from '@angular/core';
import { IllustrativeIcons } from '@citypantry/util-enums';
import { PromotionNames, SearchPromoCardModel } from '@citypantry/util-models';

export type SearchPromoCardContent = Partial<{[key in keyof typeof PromotionNames]: SearchPromoCardModel }>;

export const SEARCH_PROMO_CARD_CONTENT = new InjectionToken<SearchPromoCardContent>('searchPromoCardContent');

export const searchPromoCardContent: SearchPromoCardContent = {
  [PromotionNames.JUST_EAT_PAY]: {
    name: PromotionNames.JUST_EAT_PAY,
    title: 'Just Eat Pay',
    subtitle: 'Food, wherever you work',
    buttonText: 'Find out more',
    link: '/l/just-eat-pay',
    icon: 'eater',
    openLinkInNewTab: true,
    closeOnClick: false,
  },
  [PromotionNames.QUALIFICATION]: {
    name: PromotionNames.QUALIFICATION,
    title: 'Tell us about you',
    subtitle: 'Finish your profile with us',
    buttonText: 'Complete',
    link: null,
    icon: 'contact-form',
    openLinkInNewTab: false,
    closeOnClick: false,
  },
  [PromotionNames.SAME_DAY_DELIVERY]: {
    name: PromotionNames.SAME_DAY_DELIVERY,
    title: 'Same Day Ordering',
    subtitle: 'Order up to 3 hours before',
    buttonText: 'Find out more',
    link: '/menus/search?sameDay=true',
    icon: 'bowl',
    openLinkInNewTab: false,
    closeOnClick: true,
  },
  [PromotionNames.FREE_DELIVERY]: {
    name: PromotionNames.FREE_DELIVERY,
    title: 'Free Delivery',
    subtitle: 'in Central London',
    buttonText: 'Find out more',
    link: 'https://citypantrysupport.zendesk.com/hc/en-us/articles/360007640133-What-is-the-free-delivery-offer-',
    icon: 'bowl',
    openLinkInNewTab: true,
    closeOnClick: true,
  },
  [PromotionNames.POP_UP]: {
    name: PromotionNames.POP_UP,
    title: 'Big event coming up?',
    subtitle: 'Bring a pop-up into your office',
    buttonText: 'Find out more',
    link: '/l/pop-ups',
    icon: 'bowl',
    openLinkInNewTab: true,
    closeOnClick: true,
  },
  [PromotionNames.TOP_UPS]: {
    name: PromotionNames.TOP_UPS,
    title: 'Introducing Top-ups',
    subtitle: 'Allow your colleagues to contribute',
    buttonText: 'Tell me more',
    link: '/l/individual-choice#additional-feature',
    icon: 'individual-choice',
    openLinkInNewTab: true,
    closeOnClick: true,
    isTwoLines: true,
  },
  [PromotionNames.INDIVIDUAL_CHOICE]: {
    name: PromotionNames.INDIVIDUAL_CHOICE,
    title: 'Try Individual Choice',
    subtitle: 'Let your colleagues choose their own food',
    buttonText: 'Find out more',
    link: '/l/individual-choice',
    icon: 'individual-choice',
    openLinkInNewTab: true,
    closeOnClick: true,
    isTwoLines: true,
  },
  [PromotionNames.PANTRY_PACKAGE_WIZARD]: {
    name: PromotionNames.PANTRY_PACKAGE_WIZARD,
    title: 'Feed your remote teams@home',
    subtitle: null,
    buttonText: 'Discover Hampers',
    icon: IllustrativeIcons.PRESENT,
    link: '/l/enquiry',
    openLinkInNewTab: false,
    closeOnClick: true,
    isTwoLines: false,
    wrapTitle: true,
  },
};
