<app-logo-popup
  [show]="true"
  [hideCloseButton]="!showCloseButton"
  [modalStyle]="autocomplete ? 'medium' : null"
  (close)="onClose($event)"
  test-id="popup"
  analyticsId="search-params-modal"
>
  <app-in-viewport
    analyticsId="top-of-component"
  ></app-in-viewport>
  <form
    class="search-parameters-popup form"
    [class.form--show-all-errors]="submitted"
    e2e-test-id="searchParametersPopup"
  >
    <p class="heading-3 dialog__title search-parameters-popup__title">
      Just tell us where and when
    </p>

    <div class="dialog__content">
      <p>
        <span class="d-md-block">
          {{ autocomplete ? 'Select your delivery address or postcode and' : 'Select your delivery postcode and' }}
        </span>
          <span class="d-md-block">
          preferred time to find available restaurants
        </span>
      </p>
      <app-parameters-form
        [control]="paramsControl"
        [params]="params"
        [locations]="locations"
        [address]="autocomplete"
        [submitted]="submitted"
        [canEnterPostcode]="canEnterPostcode"
        test-id="form"
      ></app-parameters-form>
    </div>

    <div class="dialog__footer flex-wrap justify-content-center">
      <button
        class="button button--primary button--full-width"
        (click)="onSubmit()"
        test-id="button"
        e2e-test-id="showFoodButton"
        analyticsId="show-food-button"
      >
        Show me the food
      </button>
    </div>

    <div *ngIf="marketingCampaignComponent">
      <div
        class="text-center m-small"
        test-id="marketingCampaignComponent"
      >
        <span class="d-md-block" test-id="body1">
            {{ marketingCampaignComponent.body1 }}
        </span>
        <span class="d-md-block">
          <a
            href="{{ marketingCampaignComponent.ctaLink }}"
            [target]="marketingCampaignComponent.openLinkInNewTab ? '_blank' : '_self'"
            class="link"
            test-id="anchor"
            analyticsId="marketing-campaign-link"
            [analyticsData]="{ analyticsPrefix: marketingCampaignComponent.analyticsPrefix }"
          >{{ marketingCampaignComponent.ctaText }}</a>
          <span test-id="body2">{{ marketingCampaignComponent.body2 }}</span>
        </span>
        <app-in-viewport
          analyticsId="bottom-of-component"
          [analyticsData]="{ analyticsPrefix: marketingCampaignComponent.analyticsPrefix }"
        ></app-in-viewport>
      </div>
    </div>
  </form>
</app-logo-popup>
