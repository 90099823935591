import { isDisruptionActive, mapSelectors } from '@citypantry/util';
import { INDIVIDUAL_CHOICE_SEARCH_REQUEST_PARAMS } from '@citypantry/util-models';
import { getPublicState } from '../app.state';
import {
  getLastLoadedPage,
  getSearchRequest,
  getSearchResults,
  getSearchResultsTotal,
  isSearching,
  PublicState
} from '../public/public.state';

const isWeWorkModeEnabled = (state: PublicState) =>
  !state.disableWeWorkMode;

const hasAdditionalNonICFilters = (state: PublicState): boolean => {
  const essentialFilters = ['postcode', 'date', 'timestamp'];
  const individualChoiceFilters = [ ...INDIVIDUAL_CHOICE_SEARCH_REQUEST_PARAMS ];

  const acceptableFilters = [...essentialFilters, ...individualChoiceFilters];
  const isAdditionalFilter = (fieldName: string) => !acceptableFilters.includes(fieldName);

  const isNotNullOrEmptyArray = (value: any) => {
    if (!value) {
      return false;
    }

    return !(Array.isArray(value) && value.length === 0);
  };

  for (const [key, value] of Object.entries(state.searchRequest)) {
    if (isAdditionalFilter(key) && isNotNullOrEmptyArray(value)) {
      return true;
    }
  }

  return false;
};

const isDeliveryDisruptionActive = (state: PublicState): boolean => {
  const searchRegion = state.searchRegion;
  const searchDate = state.searchRequest.date;

  return isDisruptionActive(searchRegion, searchDate);
};

const getSearchType = (state: PublicState) => state.searchType;
const isSearchPromoCardHidden = (state: PublicState) => state.hideSearchPromotionCard;
const showOutOfRegionCaptureModal = (state: PublicState) => state.outOfRegionCaptureModal.isOpen;
const showFindNewVendorPopup = (state: PublicState) => state.showFindNewVendorPopup;
const cancelledOrderIds = (state: PublicState) => state.cancelledOrderIds;

export const SearchSelectors = mapSelectors(getPublicState, {
  isWeWorkModeEnabled,
  hasAdditionalNonICFilters,
  isDeliveryDisruptionActive,
  getSearchType,
  getSearchRequest,
  isSearching,
  getLastLoadedPage,
  getSearchResultsTotal,
  getSearchResults,
  isSearchPromoCardHidden,
  showOutOfRegionCaptureModal,
  showFindNewVendorPopup,
  cancelledOrderIds,
});
