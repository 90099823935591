import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '@citypantry/components-form';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { StarRatingSelectComponent } from './star-rating-select/star-rating-select.component';
import { StarRatingLabelPipe } from './pipes/star-rating-label.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FormComponentsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    StarRatingComponent,
    StarRatingSelectComponent,
    StarRatingLabelPipe
  ],
  exports: [
    StarRatingComponent,
    StarRatingSelectComponent,
    StarRatingLabelPipe
  ]
})
export class SharedStarRatingModule { }
