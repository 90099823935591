export function freeze<T>(object: T): T {
  if (!object) {
    return object;
  }
  if (typeof object === 'object') {
    if (Array.isArray(object)) {
      for (const value of object) {
        freeze(value);
      }
    } else {
      for (const value in object) {
        if (object.hasOwnProperty(value)) {
          freeze(value);
        }
      }
    }
    Object.freeze(object);
  }
  return object;
}

/**
 * Takes an object with bool-ish keys and returns the number of keys whose values are truthy.
 * @param {object} object any object
 * @returns {number} An integer >= 0 representing the count of truthy values.
 */
export function countTrueValues(object: { [key: string]: any }): number {
  return Object.values(object)
    .filter((value: any) => !!value)
    .length;
}
