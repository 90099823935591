import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { AnalyticsAction } from './analytics-action.enum';
import { AnalyticsProperties } from './analytics-properties.model';
import { AnalyticsActions } from './analytics.actions';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private store: Store<AppState>,
  ) {
  }

  public trackPage(): void {
    this.store.dispatch(AnalyticsActions.trackPage());
  }

  public trackEvent(action: AnalyticsAction, properties: AnalyticsProperties): void {
    this.store.dispatch(AnalyticsActions.trackEvent({ action, properties }));
  }

  public trackCustomEvent(action: string, properties: AnalyticsProperties): void {
    this.store.dispatch(AnalyticsActions.trackCustomEvent({ action, properties }));
  }

  public clearEcommerceData(): void {
    this.store.dispatch(AnalyticsActions.clearEcommerceData());
  }

  public triggerHotjar(event: string): void {
    this.store.dispatch(AnalyticsActions.triggerHotjar({ event }));
  }
}
