import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, mapmap, objectToParams } from '@citypantry/util';
import {
  createMenuApprovalFromJson,
  createMenuAvailabilityFromJson,
  createMenuFromJson,
  Image,
  ItemId,
  Menu,
  MenuApproval,
  MenuApprovalStates,
  MenuAvailability,
  Page,
  PaginateQuery,
  transformPage
} from '@citypantry/util-models';
import { Observable, of } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

interface MenuDraftRequest {
  name: string;
  draft: {
    heroImage: Image | null;
    disclaimer: string | null;
    sections: {
      title: string;
      hidden: boolean;
      items: ItemId[];
    }[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class MenuBuilderApi {
  constructor(
    private http: HttpClient
  ) {}

  public getMenus(query: PaginateQuery): Observable<Page<Menu>> {
    return this.http
      .get('/menus', { params: objectToParams(query, true) }).pipe(
        map(transformPage(createMenuFromJson)),
      );
  }

  public getMenusWaitingForApproval(query: PaginateQuery): Observable<Page<MenuApproval>> {
    return this.http.get('/staff/menus/submitted', {
      params: objectToParams({
        ...query,
      })
    }).pipe(
      map(transformPage(createMenuApprovalFromJson)),
    );
  }

  public getEmptyMenuForCurrentlyLoggedinVendor(): Observable<Menu> {
    return this.http.get('/menus/new').pipe(
      getItem(),
      map(createMenuFromJson),
    );
  }

  public saveMenu(menu: Menu): Observable<Menu> {
    // TODO: disable name editing in menu builder and allow to put menuContent only?
    let httpCall: Observable<Object>;
    const data = this.getMenuDraftRequest(menu);
    if (menu.id) {
      httpCall = this.http.put(`/menus/${menu.id}`, data);
    } else {
      httpCall = this.http.post('/menus', data);
    }
    return httpCall.pipe(
      getItem(),
      map(createMenuFromJson),
    );
  }

  /**
   * Removes the menu and all its versions from all vendor- and customer-facing views.
   * This does not affect approved menus already ordered against.
   * @param menu Menu to delete.
   * @returns {Observable<boolean>} True if successful. Will never return false, instead it errors on failure.
   */
  public deleteMenu(menu: Menu): Observable<boolean> {
    return this.http.request<void>('DELETE', `/menus/${menu.id}`, { observe: 'response' }).pipe(
      map((response: HttpResponse<void>) => {
        // Valid response codes from DELETE https://stackoverflow.com/a/2342589
        // Note: This is over-engineered
        switch (response.status) {
          case 200:
          case 202:
          case 204:
            return true;
          default:
            throw new Error(`Unexpected response status code: ${ response.status }`);
        }
      }),
    );
  }

  /**
   * Completely deletes the draft from the menu. This is an irreversible operation.
   * Deleting the draft can only be done when the menu already has approved versions. Otherwise the backend will throw an error.
   * @param menu The menu whose draft should be discarded.
   * @returns {Observable<boolean>} True if successful. Will never return false, instead it errors on failure.
   */
  public discardMenuDraft(menu: Menu): Observable<boolean> {
    return this.http.request<void>('DELETE', `/menus/${menu.id}/draft`, { observe: 'response' }).pipe(
      map((response: HttpResponse<void>) => {
        // Valid response codes from DELETE https://stackoverflow.com/a/2342589
        switch (response.status) {
          case 200:
          case 202:
          case 204:
            return true;
          default:
            throw new Error(`Unexpected response status code: ${ response.status }`);
        }
      }),
    );
  }

  /**
   * Retrieves the current schedule, as a list of availabilities.
   */
  public getCurrentVendorSchedule(): Observable<MenuAvailability[]> {
    return this.http.get('menu-availabilities').pipe(
      getItems(),
      mapmap(createMenuAvailabilityFromJson),
    );
  }

  /**
   * Retrieves a list of all menus currently available to the vendor for scheduling.
   * This list will be used in giving the vendor a choice of which menus to select to schedule.
   *
   * TODO: Ideally this should retrieve a subset of the menu information; all we need is name and ID.
   */
  public getMenusForScheduling(): Observable<Menu[]> {
    return this.http
      .get('menus', {
        params: objectToParams({
          status: MenuApprovalStates.APPROVED,
          pageSize: -1
        })
      }).pipe(
        getItems(),
        mapmap(createMenuFromJson),
      );
  }

  public updateSchedule(availabilities: MenuAvailability[]): Observable<boolean> {
    return this.http
      .post('menu-availabilities', { menuAvailabilities: availabilities })
      .pipe(mapTo(true));
  }

  /**
   * Create a draft version of this menu.
   * @returns true if a draft version was created, false if one already existed.
   */
  public editMenu(menu: Menu): Observable<boolean> {
    if (menu.draft) {
      return of(false);
    } else {
      return this.http.put(`menus/${menu.id}/draft`, {})
        .pipe(mapTo(true));
    }
  }

  public cloneActiveMenu(menu: Menu): Observable<Menu> {
    return this.cloneMenu(menu.id, 'active');
  }

  public cloneDraftMenu(menu: Menu): Observable<Menu> {
    return this.cloneMenu(menu.id, 'draft');
  }

  private cloneMenu(menuId: string, source: 'active' | 'draft'): Observable<Menu> {
    return this.http.post(`/menus/${menuId}/clone`, { source }).pipe(
      getItem(),
      map(createMenuFromJson),
    );
  }

  private getMenuDraftRequest(menu: Menu): MenuDraftRequest {
    return {
      name: menu.name,
      draft: {
        heroImage: menu.draft.heroImage,
        disclaimer: menu.draft.disclaimer || null,
        sections: menu.draft.sections.map((section) => ({
          title: section.title,
          hidden: !!section.hidden,
          items: section.items.map(({ id }) => id),
        })),
      }
    };
  }
}
