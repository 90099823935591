import { IsoDate } from './iso-date.model';

export interface DateRange {
  from: IsoDate;
  to: IsoDate;
}

export namespace DateRange {
  export function isInRange(pickerDate: IsoDate, dateRange: DateRange): boolean {
    return pickerDate >= dateRange.from && pickerDate <= dateRange.to;
  }

  export function ofSingleDay(day: IsoDate): DateRange {
    return { from: day, to: day };
  }
}
