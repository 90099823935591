import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IsoDate } from '@citypantry/util';
import {
  DeliveryWindow,
  OrderDateAvailability,
  SearchOrderTypeEnum,
  SearchOrderTypes,
  VendorAvailability
} from '@citypantry/util-models';
import moment, { Moment } from 'moment';
import { filter } from 'rxjs/operators';
import { DateParameter } from '../date-parameters-form/date-parameters-form.component';
import fromMoment = IsoDate.fromMoment;

@Component({
  selector: 'app-edit-date-popup',
  templateUrl: './edit-date-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDatePopupComponent implements OnInit {
  @Input()
  public dateAvailability: OrderDateAvailability;

  @Input()
  public deliveryWindow: DeliveryWindow;

  @Input()
  public isLoading: boolean;

  @Input()
  public requestedDeliveryDate: Moment;

  @Output()
  public update: EventEmitter<Moment> = new EventEmitter();

  @Output()
  public changeDate: EventEmitter<Moment> = new EventEmitter();

  @Output()
  public findNewVendor: EventEmitter<Moment> = new EventEmitter();

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  public SearchOrderTypes: SearchOrderTypeEnum = SearchOrderTypes;

  public paramsControl: FormControl;

  public get dateIsChanged(): boolean {
    return !this.requestedDeliveryDate.isSame(this.paramsControl.value.date);
  }

  public ngOnInit(): void {
    this.paramsControl = new FormControl({date: moment(this.requestedDeliveryDate)});
    this.paramsControl.valueChanges.pipe(
      filter(() => this.paramsControl.valid)
    ).subscribe((value: DateParameter) => this.update.emit(value.date));
  }

  public get earliestSelectableDay(): IsoDate {
    const tomorrow = moment().startOf('day').add(1, 'day');
    return fromMoment(tomorrow);
  }

  public dateIsUnavailable(): boolean {
    return this.dateAvailability && (!this.dateAvailability.isAvailable || this.unavailableVendors.length > 0);
  }

  public onClose(): void {
    this.close.emit();
  }

  public onFindNewVendorClick(): void {
    if (!this.isLoading && this.paramsControl.valid) {
      this.findNewVendor.emit(this.paramsControl.value.date);
    }
  }

  public onUpdateDeliveryDetailsClick(): void {
    if (!this.isLoading && this.paramsControl.valid) {
      this.changeDate.emit(this.paramsControl.value.date);
    }
  }

  public get unavailableVendors(): VendorAvailability[] {
    return this.dateAvailability.vendorAvailabilities.filter((vendor) => !vendor.isAvailable);
  }

  public get vendors(): VendorAvailability[] {
    return this.dateAvailability.vendorAvailabilities;
  }
}
