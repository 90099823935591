/* eslint-disable-next-line no-restricted-imports */ // Only allowed here; it's exposed here if you need it anywhere else
import { CustomValidators } from 'ng2-validation';

import { AbstractControl, ValidatorFn } from '@angular/forms';

type ConfiguredValidatorFn<T> = (value: T) => ValidatorFn;

// Disable whitespace rules to allow nice alignment here
/* eslint-disable @typescript-eslint/type-annotation-spacing */
export const rangeLength:         ConfiguredValidatorFn<[number, number]> = CustomValidators.rangeLength;
export const min:                 ConfiguredValidatorFn<number>           = CustomValidators.min;
export const gt:                  ConfiguredValidatorFn<number>           = CustomValidators.gt;
export const gte:                 ConfiguredValidatorFn<number>           = CustomValidators.gte;
export const max:                 ConfiguredValidatorFn<number>           = CustomValidators.max;
export const lt:                  ConfiguredValidatorFn<number>           = CustomValidators.lt;
export const lte:                 ConfiguredValidatorFn<number>           = CustomValidators.lte;
export const range:               ConfiguredValidatorFn<[number, number]> = CustomValidators.range;
export const digits:              ValidatorFn                             = CustomValidators.digits;
export const number:              ValidatorFn                             = CustomValidators.number;
export const url:                 ValidatorFn                             = CustomValidators.url;
export const email:               ValidatorFn                             = CustomValidators.email;
export const date:                ValidatorFn                             = CustomValidators.date;
export const minDate:             ConfiguredValidatorFn<string>           = CustomValidators.minDate;
export const maxDate:             ConfiguredValidatorFn<string>           = CustomValidators.maxDate;
export const dateISO:             ValidatorFn                             = CustomValidators.dateISO;
export const creditCard:          ValidatorFn                             = CustomValidators.creditCard;
export const json:                ValidatorFn                             = CustomValidators.json;
export const base64:              ValidatorFn                             = CustomValidators.base64;
export const phone:               ConfiguredValidatorFn<string>           = CustomValidators.phone;
export const internationalPhone : ConfiguredValidatorFn<string>           = CustomValidators.internationalPhone;
export const uuid:                ConfiguredValidatorFn<string>           = CustomValidators.uuid;
export const equal:               ConfiguredValidatorFn<string>           = CustomValidators.equal;
export const notEqual:            ConfiguredValidatorFn<string>           = CustomValidators.notEqual;
export const equalTo:             ConfiguredValidatorFn<AbstractControl>  = CustomValidators.equalTo;
export const notEqualTo:          ConfiguredValidatorFn<AbstractControl>  = CustomValidators.notEqualTo;
