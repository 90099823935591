import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-back-bar',
  template: `
    <div class="overlay-banner">
      <div class="container vertically-center">
        <button
          class="material-icon-button material-icon-button--secondary-clear mr-x-small"
          (click)="back.emit()"
          test-id="backButton"
        >arrow_back</button>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBackBarComponent {
  @Input()
  public text: string;

  @Output()
  public back: EventEmitter<void> = new EventEmitter();
}
