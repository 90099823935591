import { Injectable } from '@angular/core';
import { AnalyticsActions, AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';
import { LoyaltyApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, CheckoutAction, CheckoutActions, RouterActions } from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CheckoutEffects {

  public loadThankYouPage$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(CheckoutActions.thankYouPageLoad.type),
    withLatestFrom(
      this.store.select(AuthSelectors.isOrderer),
    ),
    switchMap(([action, isOrderer]) => {
      const { order } = action;

      if (order) {

        // TODO CPD-15153: remove/refactor this code as part of this task to hide loyalty points
        if(isOrderer) {
          // Orderers do not have api-permissions or product-intentions to request loyalty points, so we fast-return a value of 0
          return of(CheckoutActions.orderPointsFetchSuccess({ loyaltyPoints: 0 }));
        }

        return this.loyaltyApi.getProvisionalPointsForLoggedInCustomer(order.costBreakdown.itemsCostAfterPromoCode.gross).pipe(
          tap(() => {
            // Provides product with a clear view of when orders are placed and what type of payment was used
            this.analyticsService.trackEvent(AnalyticsActions.PLACE, {
              category: AnalyticsCategories.THANK_YOU_PAGE,
              label: `Order Placed - ${ order.id } - ${ order.paymentTerm }`
            });
          }),
          map((loyaltyPoints: number) => CheckoutActions.orderPointsFetchSuccess({ loyaltyPoints })));
      } else {
        return of(RouterActions.go({ path: '/menus/search' }));
      }
    }),
  ));

  public orderMore$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(CheckoutActions.orderMore.type),
    switchMap(({ searchRequest }) => of(
      PublicActions.prepareSearch({ request: searchRequest }),
      RouterActions.go({ path: '/menus/search' }),
    )),
  ));

  constructor(
    private action$: Actions<CheckoutAction>,
    private store: Store<AppState>,
    private loyaltyApi: LoyaltyApi,
    private analyticsService: AnalyticsService,
  ) {}
}
