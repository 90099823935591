import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-state-loading',
  template: `
    <div
      *ngIf="isLoading"
      class="state-loading"
      test-id="spinner"
    >
      <div class="spinner">
        <span class="state-loading-spinner-image"></span>
      </div>
    </div>
  `,
  styleUrls: ['./state-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateLoadingComponent {
  @Input()
  public isLoading: boolean;
}
