import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { Result } from '@citypantry/util';
import { UserId } from '@citypantry/util-models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, race } from 'rxjs';
import { map, mapTo, take } from 'rxjs/operators';
import { BetaFeature } from './models/beta-feature.enum';
import { UserRegisterError, UserRegisterInfo } from './models/user-register-info.model';
import {
  ActionTypes,
  authRefreshRequest,
  disableBetaFeature,
  hideSSOTokenError,
  loginWithEmail,
  masquerade,
  registerEater,
  RegisterEaterFailureAction,
  showSSOTokenError,
  unmasquerade
} from './state/auth.actions';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private store: Store<AppState>,
    private action$: Actions,
  ) {
  }

  public loginWithEmail(email: string): void {
    this.store.dispatch(loginWithEmail(email));
  }

  public refreshAuth(): void {
    this.store.dispatch(authRefreshRequest());
  }

  public unmasquerade(): void {
    this.store.dispatch(unmasquerade());
  }

  public masquerade(userId: UserId): void {
    this.store.dispatch(masquerade(userId));
  }

  public disableBetaFeature(feature: BetaFeature): void {
    this.store.dispatch(disableBetaFeature(feature));
  }

  public showSSOTokenError(): void {
    this.store.dispatch(showSSOTokenError());
  }

  public hideSSOTokenError(): void {
    this.store.dispatch(hideSSOTokenError());
  }

  /**
   * This method is used to register a new eater.
   *
   * It must be called instead of AuthAPI being publicly visible because there are side effects
   * done as part of this registration (the resulting user info is immediately used to log the user in).
   *
   * However, external callers need to be able to determine whether and when the call has succeeded,
   * and if it hasn't, what the error is. This is why this method returns an Observable rather than void.
   */
  public registerEater(registerInfo: UserRegisterInfo): Observable<Result<void, UserRegisterError>> {
    this.store.dispatch(registerEater(registerInfo));

    // Listening to action$ is valid from "smart" places, such as Effects
    // or services that act as the external interface to a segregated module.
    // We want to notify callers when the action has dispatched without having to store
    // some data in the store that only exists to record that this call is ongoing and/or finished.

    const success$ = this.action$.pipe(
      ofType(ActionTypes.AUTH_FETCHED_FOR_REGISTERED_EATER),
      mapTo(Result.success()),
    );
    const failure$ = this.action$.pipe(
      ofType(ActionTypes.REGISTER_EATER_FAILURE),
      map((action: RegisterEaterFailureAction) => Result.failure(action.payload.error)),
    );

    return race([success$, failure$])
      .pipe(take(1));
  }
}
