/**
 * Calculates the loyalty points for a given item total.
 *
 * This method exists as a single implementation of this business logic.
 * While it is currently trivial, future implementations might not be and should refer to a single point of entry.
 */
export function computeLoyaltyPoints(itemsTotal: number): number {
  if (typeof itemsTotal !== 'number') {
    return 0;
  }
  if (itemsTotal < 0) {
    return 0;
  }
  // Loyalty points are 1 per £ spent, so we can just floor them
  return Math.floor(itemsTotal);
}
