import {
  CustomItem,
  Dietaries,
  dietariesUnion,
  isCustomItem,
  isItemBundle,
  isSingleItem,
  Item,
  MenuContent,
  Section,
  SingleItem,
} from '@citypantry/util-models';

/**
 * Take a mixed array of ItemBundles, SingleItems and CustomItems and pull the SingleItems up
 * out of the ItemBundle into the same array.
 */
function flattenItems(items: Item[]): (SingleItem | CustomItem)[] {
  const flattenedItems: (SingleItem | CustomItem)[] = [];
  for (const item of items) {
    if (isSingleItem(item) || isCustomItem(item)) {
      flattenedItems.push(item);
    }
    if (isItemBundle(item)) {
      for (const group of item.groups) {
        for (const groupItem of group.items) {
          flattenedItems.push(groupItem);
        }
      }
    }
  }
  return flattenedItems;
}

/**
 * Pull all the Items out of all Sections in a MenuContent
 */
function extractItemsFromMenuContent(menuContent: MenuContent): Item[] {
  const extractedItems = menuContent?.sections?.reduce((items: Item[], section: Section) => items.concat(section.items), []);
  return extractedItems || [];
}

export function computeMenuDietaries(menuContent: MenuContent): Dietaries {
  const items = extractItemsFromMenuContent(menuContent);
  const flattenedItems = flattenItems(items);
  return dietariesUnion(
    flattenedItems.map((item: SingleItem | CustomItem) => {
      return isCustomItem(item) ? item.possibleDietaries : item.dietaries;
    }
    ));
}
