/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type RefundPayer = 'citypantry' | 'vendor';

export class RefundPayers {
  public static CITYPANTRY: RefundPayer & 'citypantry' = 'citypantry';
  public static VENDOR:     RefundPayer & 'vendor'     = 'vendor';

  public static get values(): RefundPayer[] {
    return [
      RefundPayers.CITYPANTRY,
      RefundPayers.VENDOR,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type RefundPayerEnum = typeof RefundPayers;
