<div class="multiple-image-upload">
  <div
    class="multiple-image-upload__item text-center"
    *ngFor="let image of images; let first = first; let i = index"
  >
    <div
      *ngIf="first"
      class="ribbon multiple-image-upload__ribbon">
      <div class="ribbon__content">Feature Image</div>
    </div>
    <div
      class="upload-preview"
      test-id="uploadedImagePreview"
      [backgroundImage]="image.medium">
      <button
        *ngIf="!first"
        class="button button--secondary upload-preview__button mr-small"
        test-id="setMainImageButton"
        (click)="setMainImage(i)">
        <p class="block-text">Make Feature</p>
      </button>
      <button
        aria-label="Delete"
        class="material-icon-button material-icon-button--light"
        (click)="deleteImage(i)"
        test-id="deleteImageButton">
        delete
      </button>
    </div>
  </div>
  <label
    class="upload-button"
    [class.upload-button--over]="isFileOver"
    ng2FileDrop
    [uploader]="uploader"
    (fileOver)="onFileOver($event)">
    <span
      *ngIf="!isUploading"
      class="upload-button__copy">
      <i class="material-icons">cloud_upload</i>
      <span
        class="upload-button__copy--label"
        test-id="uploadButtonLabel">Upload New Image</span>
      <span class="upload-button__copy--constraint sub-text" test-id="constraintDimensions">{{ constraintDimensions[0] }}px &times; {{ constraintDimensions[1] }}px</span>
      <span class="upload-button__copy--constraint sub-text">JPEG, PNG, Max 25MB</span>
    </span>

    <span
      class="spinner"
      *ngIf="isUploading" test-id="uploadButtonSpinner">
        <span class="bounce1"></span>
        <span class="bounce2"></span>
        <span class="bounce3"></span>
    </span>
    <input
      type="file"
      ng2FileSelect
      [uploader]="uploader"
      hidden
      multiple>
  </label>
</div>
