import { AfterContentInit, Directive, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompactInputWrapperComponent } from './compact-input-wrapper.component';

@Directive()
/* eslint-disable-next-line @angular-eslint/directive-class-suffix */ // Exception for an abstract class
export abstract class AbstractCompactInputComponent implements AfterContentInit, OnInit {

  @Input()
  public icon: string;

  @Input()
  public control: FormControl;

  @ViewChild(CompactInputWrapperComponent, { static: true })
  private compactInput: CompactInputWrapperComponent;

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error('Cannot instantiate a CompactInput without a FormControl instance');
    }
    this.control.registerOnDisabledChange(() => this.onDisabledStateChanged());
    this.onDisabledStateChanged();
    this.control.statusChanges.subscribe(() => this.onValidityStateChanged());
    this.onValidityStateChanged();
  }

  public ngAfterContentInit(): void {
    if (!this.compactInput) {
      throw new Error('Could not find a CompactInputComponent child!');
    }
  }

  protected onDisabledStateChanged(): void {
    this.compactInput.setDisabled(this.control.disabled);
  }

  protected onValidityStateChanged(): void {
    this.compactInput.setValid(this.control.valid);
  }
}
