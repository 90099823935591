<div
  [class]="'modal' + (modalStyle ? ' modal--' + modalStyle : '')"
  [class.modal--open]="show"
  [class.modal--full]="noBorder"
  [class.modal--vertically-centered]="centerVertically"
  [class.modal--overflow-visible]="showOverflow"
  [class.modal--no-width]="noWidth"
  (click)="onBackgroundClick($event)"
  test-id="modal"
  #container
>
  <div
    class="modal__content"
    test-id="wrapper"
  >
    <ng-content></ng-content>
  </div>
</div>
