import { Loadable, Order } from '@citypantry/util-models';
import { CheckoutAction, CheckoutActions } from './checkout.actions';

export interface CheckoutState {
  order: Order | null;
  loyaltyPoints: Loadable<number>;
  hidePromotionBanner: boolean;
}

export const INITIAL_STATE: CheckoutState = {
  order: null,
  loyaltyPoints: { isLoading: true, item: null },
  hidePromotionBanner: false
};

export function reducer(state: CheckoutState = INITIAL_STATE, action: CheckoutAction): CheckoutState {
  switch (action.type) {
    case CheckoutActions.thankYouPageLoad.type: {
      const { order } = action;
      return { ...state, order };
    }

    case CheckoutActions.orderPointsFetchSuccess.type: {
      const { loyaltyPoints } = action;
      return { ...state, loyaltyPoints: { isLoading: false, item: loyaltyPoints } };
    }

    case CheckoutActions.hidePromotionBanner.type: {
      return {...state, hidePromotionBanner: true};
    }

    default:
      return state;
  }
}
