import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ExternalLinkTemplateContext, RouterLinkTemplateContext } from '../../header.component';

@Component({
  selector: 'app-eater-user-header',
  templateUrl: './eater-user-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EaterUserHeaderComponent {
  @Input()
  public externalLinkTemplate: TemplateRef<ExternalLinkTemplateContext>;

  @Input()
  public routerLinkTemplate: TemplateRef<RouterLinkTemplateContext>;

  @Input()
  public authenticatedUserLinksTemplate: TemplateRef<void>;
}
