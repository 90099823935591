import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDropdownComponent {
  @Input()
  public triggerLabel: string;

  @Input()
  public triggerAnalyticsId: string | null;

  public get isOpen(): boolean {
    return this._isOpen;
  }

  private _isOpen: boolean;

  public toggleOpen(): void {
    this._isOpen = !this._isOpen;
  }

  public onClickOutside(): void {
    this._isOpen = false;
  }
}
