import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-filters-favourites-toggle',
  template: `
    <button
      class="control-button control-button--emphasis control-button--expand-tablet"
      [class.control-button--active]="enabled"
      (click)="onToggle()"
      test-id="button"
      analyticsId="favourite-filter"
      [analyticsData]="{ enabled: enabled }"
    >
      <div class="control-button__content">
        <div class="toggle-input">
          <i class="material-icons" test-id="icon">{{ enabled ? 'heart' : 'heart_outline' }}</i>
          <span class="ml-small">Favourites</span>
        </div>
      </div>
    </button>
  `
})
export class SearchFiltersFavouritesToggleComponent {

  @Input()
  public enabled: boolean;

  @Output()
  public enabledChange: EventEmitter<Boolean> = new EventEmitter();

  public onToggle(): void {
    this.enabledChange.emit(!this.enabled);
  }
}
