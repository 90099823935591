import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange, IsoDate } from '@citypantry/util';

@Component({
  selector: 'app-date-range-picker',
  template: `
    <p class="label">
      From
    </p>

    <app-date-picker
      placeholder="No date set"
      [value]="from || to"
      [maxDay]="to"
      (update)="updateFrom($event)"
      test-id="pickerFrom"
    ></app-date-picker>

    <p class="label">
      To
    </p>

    <app-date-picker
      placeholder="No date set"
      [value]="to || from"
      [minDay]="from"
      (update)="updateTo($event)"
      test-id="pickerTo"
    ></app-date-picker>
  `,
  styles: [`.label { margin-bottom: 2px }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DateRangePickerComponent {
  @Input()
  public from: IsoDate | null;

  @Input()
  public to: IsoDate | null;

  @Output()
  public update: EventEmitter<DateRange | null> = new EventEmitter();

  constructor() {
    this.from = null;
    this.to = null;
  }

  public updateFrom(from: IsoDate | null): void {
    this.from = from;
    this.emitUpdate();
  }

  public updateTo(to: IsoDate | null): void {
    this.to = to;
    this.emitUpdate();
  }

  public emitUpdate(): void {
    if (!this.from && !this.to) {
      this.update.emit(null);
      return;
    }

    this.update.emit({
      from: this.from || this.to,
      to: this.to || this.from,
    });
  }
}
