/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type HeadCountQualificationType = 'one_to_fifty' | 'fifty_to_one_hundred' | 'one_hundred_to_five_hundred' | 'over_five_hundred';

export class HeadCountQualificationTypes {
  public static ONE_TO_FIFTY:                HeadCountQualificationType & 'one_to_fifty'                = 'one_to_fifty';
  public static FIFTY_TO_ONE_HUNDRED:        HeadCountQualificationType & 'fifty_to_one_hundred'        = 'fifty_to_one_hundred';
  public static ONE_HUNDRED_TO_FIVE_HUNDRED: HeadCountQualificationType & 'one_hundred_to_five_hundred' = 'one_hundred_to_five_hundred';
  public static OVER_FIVE_HUNDRED:           HeadCountQualificationType & 'over_five_hundred'           = 'over_five_hundred';

  public static get values(): HeadCountQualificationType[] {
    return [
      HeadCountQualificationTypes.ONE_TO_FIFTY,
      HeadCountQualificationTypes.FIFTY_TO_ONE_HUNDRED,
      HeadCountQualificationTypes.ONE_HUNDRED_TO_FIVE_HUNDRED,
      HeadCountQualificationTypes.OVER_FIVE_HUNDRED,
    ];
  }

  // Do not modify this line - Custom code below here
  // Note: These values should match whatever is required by HubSpot as the API is no longer constrained by enum.
}

export type HeadCountQualificationTypeEnum = typeof HeadCountQualificationTypes;
