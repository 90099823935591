import { CartManager } from '@citypantry/util-cart-manager';
import {
  CartItem,
  CartStatus,
  CartStatuses,
  createIndividualChoiceOrderRequirementsFromJson,
  CustomerLocation,
  DeliverableMenu,
  EaterCartListItem,
  EaterCartSubmitError,
  findMenuItemById,
  IndividualChoiceOrder,
  IndividualChoiceOrderGroup,
  IndividualChoiceOrderRequirements,
  isCustomCartItem,
  isSingleCartItem,
  ItemId,
  MajorCurrency,
  MenuContent,
  OrderDateAvailability,
  OrderId,
  UserId
} from '@citypantry/util-models';
import { Moment } from 'moment';
import { IndividualChoiceAction, IndividualChoiceActions } from './individual-choice.actions';

export interface IndividualChoiceShareModal {
  timeRemaining: number | null;
  hasError: boolean;
  shareUrl: string;
}

export interface MandatoryCostCodesModal {
  eaterCartsWithoutCostCodes: EaterCartListItem[];
}

export interface ChooseForEater {
  eaterId: UserId;
  eaterName: string;
  menuContent: MenuContent;
  cartItems: CartItem[];
  departmentReference: string;
  orderId: OrderId | null;
  childOrderId: OrderId;
  isSubmitting: boolean;
  errors: EaterCartSubmitError;
  remainingBudget: MajorCurrency | null;
  openItem: {
    itemId: ItemId;
    cartIndex: number | null; // null = new item being created
  } | null;
  useDeskNumbers: boolean;
  deskNumber: string | null;
  ageConfirmationDialogVisible: boolean;
}

interface Extras {
  isSubmitting: boolean;
  openItem: {
    itemId: ItemId;
    cartIndex: number | null; // null = new item being created
  } | null;
  currentOrderMenu: DeliverableMenu | null;
  ageConfirmationDialogVisible: boolean;
}

export interface IndividualChoiceState {
  createOrderStatus: CartStatus;
  individualChoiceOrder: IndividualChoiceOrderGroup | null;
  currentChildOrderId: OrderId | null;
  pendingIndividualChoice: IndividualChoiceOrderRequirements | null; // Placing individual choice orders

  shareIndividualChoiceOrderModal: IndividualChoiceShareModal | null;
  mandatoryCostCodesModal: MandatoryCostCodesModal | null;

  extras: Extras;

  chooseForEater: ChooseForEater | null;

  isLoading: boolean;

  hidePromotionBanner: boolean;

  editDatePopupIsOpen: boolean;
  cancelOrderAfterEditPopupIsOpen: boolean;
  newDeliveryDate: Moment | null;
  isEditDatePopupLoading: boolean;
  selectedDateAvailability: OrderDateAvailability | null;
  deliveryDetailsUpdateSuccessPopupIsOpen: boolean;
  isUpdatingDeliveryContact: boolean;
  editContactPopupIsOpen: boolean;
  updatedOrderLocation: CustomerLocation;
  paymentResolutionFormSubmitting: boolean;
}

const initialChooseForEaterData: ChooseForEater = {
  eaterId: null,
  eaterName: null,
  orderId: null,
  childOrderId: null,
  errors: null,
  cartItems: [],
  departmentReference: '',
  menuContent: null,
  isSubmitting: false,
  remainingBudget: null,
  openItem: null,
  useDeskNumbers: false,
  deskNumber: null,
  ageConfirmationDialogVisible: false,
};

export const initialState: IndividualChoiceState = {
  createOrderStatus: CartStatuses.IDLE,
  individualChoiceOrder: null,
  currentChildOrderId: null,
  pendingIndividualChoice: null,
  shareIndividualChoiceOrderModal: null,
  mandatoryCostCodesModal: null,
  extras: {
    isSubmitting: false,
    openItem: null,
    currentOrderMenu: null,
    ageConfirmationDialogVisible: false,
  },

  chooseForEater: initialChooseForEaterData,

  isLoading: false,

  hidePromotionBanner: false,

  editDatePopupIsOpen: false,
  cancelOrderAfterEditPopupIsOpen: false,
  newDeliveryDate: null,
  isEditDatePopupLoading: false,
  selectedDateAvailability: null,
  deliveryDetailsUpdateSuccessPopupIsOpen: false,
  isUpdatingDeliveryContact: false,
  editContactPopupIsOpen: false,
  updatedOrderLocation: null,
  paymentResolutionFormSubmitting: false,
};

export function reducer(state: IndividualChoiceState = initialState, action: IndividualChoiceAction): IndividualChoiceState {
  switch (action.type) {

    case IndividualChoiceActions.shareIndividualChoiceOrderModalOpened.type: {
      const shareUrl = action.shareUrl;

      return {
        ...state,
        shareIndividualChoiceOrderModal: {
          shareUrl,
          timeRemaining: null,
          hasError: false,
        },
      };
    }

    case IndividualChoiceActions.shareIndividualChoiceModalCopyFailed.type: {
      return {
        ...state,
        shareIndividualChoiceOrderModal: {
          ...state.shareIndividualChoiceOrderModal,
          hasError: true,
        },
      };
    }

    case IndividualChoiceActions.tickShareIndividualChoiceModalCountdown.type: {
      const timeRemaining = action.timeRemaining;

      if (!state.shareIndividualChoiceOrderModal) {
        return state;
      }

      return {
        ...state,
        shareIndividualChoiceOrderModal: {
          ...state.shareIndividualChoiceOrderModal,
          timeRemaining,
          hasError: false,
        },
      };
    }

    case IndividualChoiceActions.hideShareIndividualChoiceModal.type: {
      return {
        ...state,
        shareIndividualChoiceOrderModal: null,
      };
    }

    case IndividualChoiceActions.individualChoiceOrderCartNotesUpdated.type: {
      const { notes } = action;

      return {
        ...state,
        individualChoiceOrder: {
          ...state.individualChoiceOrder,
          orders: state.individualChoiceOrder.orders.map((order) => ({
            ...order,
            notes
          }))
        }
      };
    }

    case IndividualChoiceActions.individualChoiceOrderCartCutleryUpdated.type: {
      const { includeCutlery } = action;

      return {
        ...state,
        individualChoiceOrder: {
          ...state.individualChoiceOrder,
          orders: state.individualChoiceOrder.orders.map((order) => ({
            ...order,
            includeCutlery
          }))
        }
      };
    }

    case IndividualChoiceActions.cartsAdditionalReferenceRequiredUpdated.type: {
      const { cartsAdditionalReferenceRequired } = action;

      return {
        ...state,
        individualChoiceOrder: {
          ...state.individualChoiceOrder,
          cartsAdditionalReferenceRequired
        }
      };
    }

    case IndividualChoiceActions.showMandatoryCostCodesDialog.type: {
      const { eaterCartsWithoutCostCodes } = action;

      return {
        ...state,
        mandatoryCostCodesModal: {
          eaterCartsWithoutCostCodes,
        }
      };
    }

    case IndividualChoiceActions.mandatoryCostCodesDialogConfirmClicked.type:
    case IndividualChoiceActions.mandatoryCostCodesDialogClosed.type: {
      return {
        ...state,
        mandatoryCostCodesModal: null,
      };
    }

    case IndividualChoiceActions.setActiveOrderId.type: {
      const childOrderId = action.orderId;
      return {
        ...state,
        currentChildOrderId: childOrderId
      };
    }

    case IndividualChoiceActions.menuLoaded.type: {
      const menu = action.menu;

      return {
        ...state,
        extras: {
          ...state.extras,
          currentOrderMenu: menu
        }
      };
    }

    case IndividualChoiceActions.extraItemsAddSingleItemToCart.type: {
      const activeOrder = findCurrentChildOrder(state);

      if (!activeOrder) {
        return state;
      } else {
        const extraItems = CartManager.addOrUpdateSingleItem(activeOrder.extraItems || [], action.item, action.quantity || null);

        return {
          ...state,
          individualChoiceOrder: {
            ...state.individualChoiceOrder,
            orders: state.individualChoiceOrder.orders.map((order) => {
              if (order.id === activeOrder.id) {
                return {
                  ...order,
                  extraItems,
                };
              } else {
                return order;
              }
            })
          },
        };
      }
    }

    case IndividualChoiceActions.removeExtraItem.type: {
      const cartIndex = action.cartIndex;
      const activeOrder = findCurrentChildOrder(state);
      const extras = activeOrder && activeOrder.extraItems || [];

      if (!activeOrder || !extras.length) {
        return state;
      } else {
        return {
          ...state,
          individualChoiceOrder: {
            ...state.individualChoiceOrder,
            orders: state.individualChoiceOrder.orders.map((order) => {
              if (order.id === activeOrder.id) {
                return {
                  ...order,
                  extraItems: CartManager.removeItem(extras, cartIndex),
                };
              } else {
                return order;
              }
            })
          },
        };
      }
    }

    case IndividualChoiceActions.extraItemsAddOrUpdateItemInCart.type: {
      const activeOrder = findCurrentChildOrder(state);

      const menuContent = state.extras.currentOrderMenu.content;
      const item = findMenuItemById(menuContent, state.extras.openItem.itemId);

      const result = CartManager.addOrUpdateItem(
        activeOrder.extraItems,
        item,
        state.extras.openItem.cartIndex,
        action.event,
      );

      return {
        ...state,
        individualChoiceOrder: {
          ...state.individualChoiceOrder,
          orders: state.individualChoiceOrder.orders.map((order) => {
            if (order.id === activeOrder.id) {
              return {
                ...order,
                extraItems: result,
              };
            } else {
              return order;
            }
          })
        },
      };
    }

    case IndividualChoiceActions.extraItemsItemClicked.type: {
      const { item } = action;

      const activeOrder = findCurrentChildOrder(state);
      const cartItems = activeOrder && activeOrder.extraItems;
      const cartIndex = CartManager.getCartIndexWhenAddingItem(item, cartItems);

      return {
        ...state,
        extras: {
          ...state.extras,
          openItem: { itemId: item.id, cartIndex },
        },
      };
    }

    case IndividualChoiceActions.extraItemsItemModalClosed.type: {
      return {
        ...state,
        extras: {
          ...state.extras,
          openItem: null,
        }
      };
    }

    case IndividualChoiceActions.extraItemsCartItemEditClicked.type: {
      const { item, cartIndex } = action;

      return {
        ...state,
        extras: {
          ...state.extras,
          openItem: { itemId: item.id, cartIndex },
        },
      };
    }

    case IndividualChoiceActions.submitExtraItems.type: {
      const currentOrder = findCurrentChildOrder(state);
      const extraItems = currentOrder && currentOrder.extraItems || [];

      return {
        ...state,
        extras: {
          ...state.extras,
          isSubmitting: true,
          ageConfirmationDialogVisible: CartManager.areAnyCartItemsAgeRestricted(extraItems),
        }
      };
    }

    case IndividualChoiceActions.extraItemsSubmitted.type: {
      const { order } = action;
      return {
        ...state,
        extras: {
          ...state.extras,
          isSubmitting: false,
        },
        individualChoiceOrder: {
          ...state.individualChoiceOrder,
          orders: state.individualChoiceOrder.orders.map((currentOrder) => {
            if (currentOrder.id === order.id) {
              return order;
            } else {
              return currentOrder;
            }
          })
        }
      };
    }

    case IndividualChoiceActions.extraItemsAgeConfirmationDialogConfirm.type: {
      return {
        ...state,
        extras: {
          ...state.extras,
          ageConfirmationDialogVisible: false,
        },
      };
    }

    case IndividualChoiceActions.extraItemsAgeConfirmationDialogCancel.type: {
      return {
        ...state,
        extras: {
          ...state.extras,
          isSubmitting: false,
          ageConfirmationDialogVisible: false,
        },
      };
    }

    case IndividualChoiceActions.loadOrderGroup.type: {
      return {
        ...state,
        individualChoiceOrder: null,
        currentChildOrderId: null,
        chooseForEater: initialChooseForEaterData,
      };
    }

    case IndividualChoiceActions.orderGroupLoaded.type:
    case IndividualChoiceActions.orderGroupCancelSuccess.type: {
      const { orderGroup } = action;
      return {
        ...state,
        individualChoiceOrder: orderGroup,
        currentChildOrderId: null,
        isLoading: false,
      };
    }

    case IndividualChoiceActions.addItemToCart.type: {
      const { item, quantity } = action;
      const cartItems = state.chooseForEater.cartItems || [];

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          cartItems: CartManager.addOrUpdateSingleItem(cartItems, item, quantity),
        }
      };
    }

    case IndividualChoiceActions.departmentReferenceChange.type: {
      const { departmentReference } = action;

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          departmentReference,
        }
      };
    }

    case IndividualChoiceActions.deskNumberChanged.type: {
      const { deskNumber } = action;

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          deskNumber,
        }
      };
    }

    case IndividualChoiceActions.removeItemFromCart.type: {
      const { cartIndex } = action;

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          cartItems: CartManager.removeItem(state.chooseForEater.cartItems || [], cartIndex)
        }
      };
    }

    case IndividualChoiceActions.updateItemQuantityInCart.type: {
      const { cartIndex, quantity } = action;

      let cartItems = state.chooseForEater.cartItems || [];
      const cartItem = cartItems[cartIndex];

      if (!cartItem) {
        return state;
      }

      if (quantity > 0) {
        if (isCustomCartItem(cartItem)) {
          cartItems = CartManager.updateCustomItem(cartItems, cartIndex, quantity, null);
        } else if (isSingleCartItem(cartItem)) {
          cartItems = CartManager.updateSingleItem(cartItems, cartIndex, quantity);
        }
        // Bundles should never get quantity changes from cart
      } else {
        cartItems = CartManager.removeItem(cartItems, cartIndex);
      }

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          cartItems,
        }
      };
    }

    case IndividualChoiceActions.submitEaterCart.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          isSubmitting: true,
          errors: null,
          ageConfirmationDialogVisible: CartManager.areAnyCartItemsAgeRestricted(state.chooseForEater.cartItems),
        }
      };
    }

    case IndividualChoiceActions.eaterCartSubmitFailed.type: {
      const error: EaterCartSubmitError = action.error;

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          errors: error,
          isSubmitting: false
        }
      };
    }

    case IndividualChoiceActions.eaterCartSubmitted.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          cartItems: [],
          departmentReference: '',
          errors: null,
          isSubmitting: false
        }
      };
    }

    case IndividualChoiceActions.dismissEaterCartError.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          errors: null
        }
      };
    }

    case IndividualChoiceActions.chooseForEaterAddOrUpdateItemInCart.type: {
      if (state.chooseForEater && state.chooseForEater.openItem) {
        const menuContent = state.chooseForEater.menuContent;
        const item = findMenuItemById(menuContent, state.chooseForEater.openItem.itemId);

        const result = CartManager.addOrUpdateItem(
          state.chooseForEater.cartItems,
          item,
          state.chooseForEater.openItem.cartIndex,
          action.event,
        );

        return {
          ...state,
          chooseForEater: {
            ...state.chooseForEater,
            cartItems: result,
          }
        };
      }

      return state;
    }

    case IndividualChoiceActions.chooseForEaterCartItemEditClicked.type: {
      const { item, cartIndex } = action;

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          openItem: { itemId: item.id, cartIndex },
        },
      };
    }

    case IndividualChoiceActions.chooseForEaterMenuItemAddClicked.type: {
      const { item } = action;

      const cartIndex = CartManager.getCartIndexWhenAddingItem(item, state.chooseForEater.cartItems);

      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          openItem: { itemId: item.id, cartIndex },
        },
      };
    }

    case IndividualChoiceActions.chooseForEaterItemUnselected.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          openItem: null,
        },
      };
    }

    case IndividualChoiceActions.initialiseChooseForEater.type: {
      const { eaterId, eaterName, orderId, childOrderId } = action;
      return {
        ...state,
        chooseForEater: {
          eaterId,
          eaterName,
          orderId,
          childOrderId,
          cartItems: [],
          departmentReference: '',
          isSubmitting: false,
          errors: null,
          menuContent: null,
          remainingBudget: null,
          openItem: null,
          useDeskNumbers: false,
          deskNumber: null,
          ageConfirmationDialogVisible: false,
        }
      };
    }

    case IndividualChoiceActions.orderSummaryLoaded.type: {
      const { summary } = action;
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          menuContent: summary.individualChoice.menuContent,
          useDeskNumbers: summary.useDeskNumbers,
        }
      };
    }

    case IndividualChoiceActions.eaterCartLoaded.type: {
      const { eaterCart } = action;
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          remainingBudget: eaterCart.remainingBudget,
          departmentReference: eaterCart.departmentReference,
        }
      };
    }

    case IndividualChoiceActions.orderGroupCancel.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case IndividualChoiceActions.orderGroupCancelFailure.type: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case IndividualChoiceActions.hidePromotionBanner.type: {
      return {
        ...state,
        hidePromotionBanner: true
      };
    }

    case IndividualChoiceActions.openEditDatePopup.type:
      return {
        ...state,
        editDatePopupIsOpen: true,
      };

    case IndividualChoiceActions.closeEditDatePopup.type:
      return {
        ...state,
        editDatePopupIsOpen: false,
      };

    case IndividualChoiceActions.findNewVendor.type: {
      const { newDeliveryDate } = action;

      return {
        ...state,
        editDatePopupIsOpen: false,
        cancelOrderAfterEditPopupIsOpen: true,
        newDeliveryDate,
      };
    }

    case IndividualChoiceActions.closeCancelOrderPopup.type:
      return {
        ...state,
        cancelOrderAfterEditPopupIsOpen: false,
      };

    case IndividualChoiceActions.checkDateAvailability.type:
      return {
        ...state,
        isEditDatePopupLoading: true,
      };

    case IndividualChoiceActions.setSelectedDateAvailability.type: {
      const { selectedDateAvailability } = action;
      return {
        ...state,
        selectedDateAvailability,
        isEditDatePopupLoading: false,
      };
    }

    case IndividualChoiceActions.updateDeliveryDate.type:
      return {
        ...state,
        isEditDatePopupLoading: true,
      };

    case IndividualChoiceActions.updateDeliveryDateSuccess.type:
      return {
        ...state,
        isEditDatePopupLoading: false,
        deliveryDetailsUpdateSuccessPopupIsOpen: true,
      };

    case IndividualChoiceActions.closeDeliveryDetailsUpdatedPopup.type:
      return {
        ...state,
        deliveryDetailsUpdateSuccessPopupIsOpen: false,
      };

    case IndividualChoiceActions.addDeliveryContact.type:
      return {
        ...state,
        isUpdatingDeliveryContact: true,
      };

    case IndividualChoiceActions.updateDeliveryContact.type:
      return {
        ...state,
        isUpdatingDeliveryContact: true,
      };

    case IndividualChoiceActions.updateDeliveryContactSuccess.type:
      return {
        ...state,
        isUpdatingDeliveryContact: false,
      };

    case IndividualChoiceActions.openEditContactPopup.type:
      return {
        ...state,
        editContactPopupIsOpen: true,
      };

    case IndividualChoiceActions.closeEditContactPopup.type:
      return {
        ...state,
        editContactPopupIsOpen: false,
      };

    case IndividualChoiceActions.chooseForEaterAgeConfirmationDialogConfirm.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          ageConfirmationDialogVisible: false,
        }
      };
    }

    case IndividualChoiceActions.chooseForEaterAgeConfirmationDialogCancel.type: {
      return {
        ...state,
        chooseForEater: {
          ...state.chooseForEater,
          isSubmitting: false,
          ageConfirmationDialogVisible: false,
        }
      };
    }

    case IndividualChoiceActions.showShareIndividualChoiceModalForNewOrder.type: {
      return {
        ...state,
        updatedOrderLocation: null
      };
    }

    case IndividualChoiceActions.showShareIndividualChoiceModalForUpdatedOrder.type: {
      const { updatedOrderLocation } = action;

      return {
        ...state,
        updatedOrderLocation
      };
    }

    case IndividualChoiceActions.payForExistingOrder.type:
    case IndividualChoiceActions.addCardAndPayForExistingOrder.type: {
      return {
        ...state,
        paymentResolutionFormSubmitting: true,
      };
    }

    case IndividualChoiceActions.paymentResolutionGuardPageLoad.type:
    case IndividualChoiceActions.failedToAddOrVerifyPaymentCard.type: {
      return {
        ...state,
        paymentResolutionFormSubmitting: false,
      };
    }

    default:
      return state;
  }
}

export function deserialiseIndividualChoiceState(state: Partial<IndividualChoiceState>): IndividualChoiceState {
  if (!state) {
    return initialState;
  }
  try {
    const pendingIndividualChoice = deserialisePendingIndividualChoice(state.pendingIndividualChoice);

    return {
      ...initialState,
      pendingIndividualChoice,
    };
  } catch (e) {
    console.error(e);
    return initialState;
  }
}

function deserialisePendingIndividualChoice(
  pendingIndividualChoice: IndividualChoiceOrderRequirements,
): IndividualChoiceOrderRequirements | null {
  if (!pendingIndividualChoice) {
    return null;
  }
  try {
    return createIndividualChoiceOrderRequirementsFromJson(pendingIndividualChoice);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function findOrder(state: IndividualChoiceState, orderId: OrderId): IndividualChoiceOrder {
  return state.individualChoiceOrder &&
    state.individualChoiceOrder.orders &&
    state.individualChoiceOrder.orders.find((order) => order.id === orderId) || null;
}

export function findCurrentChildOrder(state: IndividualChoiceState): IndividualChoiceOrder | null {
  return findOrder(state, state.currentChildOrderId);
}

// TODO CPD-11713 refactor state to use createSelector
export const getShareIndividualChoiceOrderModal = (state: IndividualChoiceState) => state.shareIndividualChoiceOrderModal;
export const getCurrentChildOrderId = (state: IndividualChoiceState) => state.currentChildOrderId;
export const getIndividualChoiceOrder = (state: IndividualChoiceState) => state.individualChoiceOrder;
export const getOrderGroupId = (state: IndividualChoiceState) => state.individualChoiceOrder ? state.individualChoiceOrder.id : null;
export const getActiveIndividualChoiceChildOrder = findCurrentChildOrder;

