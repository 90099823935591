import { GlobalDialogSettings } from './global-dialog-settings.model';
import { GlobalDialogAction, GlobalDialogActions } from './global-dialog.actions';

export const FEATURE_STATE_NAME = 'globalDialog';

export interface GlobalDialogState {
  settings: GlobalDialogSettings | null;
}

const INITIAL_STATE: GlobalDialogState = {
  settings: null,
};

export function reducer(state: GlobalDialogState = INITIAL_STATE, action: GlobalDialogAction): GlobalDialogState {
  switch (action.type) {

    case GlobalDialogActions.showDialog.type: {
      return {
        ...state,
        settings: action.settings,
      };
    }

    case GlobalDialogActions.completeDialog.type: {
      return {
        ...state,
        settings: null,
      };
    }

    default:
      return state;
  }
}
