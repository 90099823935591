<div class="cart-total">
  <div
    class="cart-total__total line-item"
    test-id="totalText"
    e2e-test-id="totalText"
  >
    {{ totalDisplayText || 'Total' }}
    <span
      class="line-item__amount"
      test-id="amount"
      e2e-test-id="totalItemAmount"
    >
      {{ amount | minorToMajor | currencyWithBlank:currencyCode }}
    </span>
  </div>
  <div
    *ngIf="hasIncrease || hasDecrease"
    @slideInOut
    class="cart-total__price-change line-item"
    [class.cart-total__price-change--increase]="hasIncrease"
    [class.cart-total__price-change--decrease]="hasDecrease"
    test-id="priceChange"
  >
    {{ priceChange > 0 ? 'Balance to pay' : 'Refund amount' }}
    <span class="line-item__amount">
      {{ priceChangeAmount | minorToMajor | currency:currencyCode }}
    </span>
  </div>
</div>
