import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsEvent } from '../analytics-event.interface';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const promoBannerRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'promotion-banner bottom-of-banner',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.PROMOTION_BANNER,
      label: `${getLabelPrefix(event)} viewed`,
    })
  },
  {
    url: '*',
    path: 'promotion-banner link',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.PROMOTION_BANNER,
      label: `${getLabelPrefix(event)} link clicked`,
    })
  },
  {
    url: '*',
    path: 'promotion-banner tag',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.PROMOTION_BANNER,
      label: `${getLabelPrefix(event)} tag clicked`,
    })
  },
];

function getLabelPrefix(event: AnalyticsEvent): string {
  // If a specific prefix is provided use it, if not use the promotions name by default
  return event.data['promotion-banner'].analyticsLabelPrefixOverride
    ? event.data['promotion-banner'].analyticsLabelPrefixOverride
    : event.data['promotion-banner'].name;
}
