import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { VendorOrdersNotificationsApi } from './api/vendor-notifications.api';
import { VendorNotificationsActions } from './vendor-notifications.actions';
import { VendorNotificationsSelectors } from './vendor-notifications.select';

@Injectable()
export class VendorNotificationsEffects {

  public vendorNavigation$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ROUTER_NAVIGATION),
    /* withLatestFrom cannot be used here as it doesn't wait for the observable to emit a value if one does not already exist
     * Auth state may not have already loaded at this point so we use switchMap to wait until the query has emit one value */
    switchMap(() => this.store.select(AuthSelectors.isVendor).pipe(take(1))),
    filter((isVendor) => isVendor),
    map(() => VendorNotificationsActions.requestToLoadPendingOrdersCount())
  ));

  public requestToLoadPendingOrdersCount$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(VendorNotificationsActions.requestToLoadPendingOrdersCount.type),
    withLatestFrom(this.store$.select(VendorNotificationsSelectors.isLoadingPendingOrdersCount)),
    switchMap(([, isLoading]: [Action, boolean]) => isLoading ? EMPTY : of(VendorNotificationsActions.loadPendingOrdersCount()))
  ));

  public loadPendingOrdersCount$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(VendorNotificationsActions.loadPendingOrdersCount.type),
    switchMap(() => {
      return this.vendorOrdersNotificationsApi.getPendingOrdersCount().pipe(
        map((count: number) => VendorNotificationsActions.pendingOrdersCountLoaded({
          count,
          timestamp: moment().format(),
        })),
      );
    })
  ));

  constructor(
    private action$: Actions,
    private vendorOrdersNotificationsApi: VendorOrdersNotificationsApi,
    private store$: Store<AppState>,
    private store: Store<AppState>
  ) { }
}
