export interface TrackedLocation {
  country: string | null;
  companyName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string | null;
  postcode: string | null;
  latitude: number;
  longitude: number;
}

export function createTrackedLocationFromJson(json?: Partial<TrackedLocation>): TrackedLocation {
  json = json || {};

  const location: Partial<TrackedLocation> = {};

  location.country = json.country || null;
  location.companyName = json.companyName || null;
  location.addressLine1 = json.addressLine1 || null;
  location.addressLine2 = json.addressLine2 || null;
  location.addressLine3 = json.addressLine3 || null;
  location.city = json.city || null;
  location.postcode = json.postcode || null;
  location.latitude = json.latitude;
  location.longitude = json.longitude;

  return location as TrackedLocation;
}

export function toArray(location: TrackedLocation): string[] {
  return ([
    location.companyName,
    location.addressLine1,
    location.addressLine2,
    location.addressLine3,
    location.city,
    location.postcode,
    location.country
  ]).filter((x) => !!x);
}
