import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { DietaryExceptNone } from '@citypantry/util-models';

@Component({
  selector: 'app-diet-icon',
  template: `
    <span
      class="icon icon--medium"
      [ngClass]="iconCssClass"
      [tooltip]="tooltipEnabled ? ((diet | dietName) + (!available ? ' not available' : '')) : ''"
      tooltipPosition="bottom"
      analyticsId="diet-icon"
      [analyticsData]="{ name: diet | dietName, active: active }"
      test-id="diet"
    ></span>
  `,
  styleUrls: ['./diet-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DietIconComponent {

  @Input()
  public diet: DietaryExceptNone;

  @Input()
  public available: boolean;

  @Input()
  public active: boolean;

  @Input()
  public tooltipEnabled: boolean;

  constructor() {
    this.available = true;
    this.tooltipEnabled = true;
  }

  public get iconCssClass(): string {
    return `icon-dietary-${this.cssClassModifier}${
      !this.available ? '-disabled' : (this.active ? '-active' : '')}`;
  }

  private get cssClassModifier(): string {
    switch (this.diet) {
      case 'vegetarian':  return 'vegetarian';
      case 'vegan':       return 'vegan';
      case 'noGluten':    return 'no-gluten';
      case 'noNuts':      return 'no-nuts';
      case 'noDairy':     return 'no-dairy';
      case 'halal':       return 'halal';
      case 'pescatarian': return 'pescatarian';
      default:
        throw new UnreachableCaseError(this.diet);
    }
  }
}
