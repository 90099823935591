import { Injectable } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';
import { UserId } from '@citypantry/util-models';
import { CookieService } from 'ngx-cookie';
import { HEADER_KEY_USER_ID } from './auth.headers';

export const MASQUERADING_COOKIE_KEY = 'masqueradingAsUserId';

interface AuthHeaders {
  [headerName: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  constructor(
    private cookie: CookieService,
    private windowRef: WindowRef
  ) {}

  /**
   * Returns headers required to authenticate with the backend, or `null` if the user is missing some properties.
   */
  public getAuthHeaders(): AuthHeaders | null {
    const cookieData = this.cookie.getAll();

    if (cookieData[MASQUERADING_COOKIE_KEY]) {
      return {
        [HEADER_KEY_USER_ID]: cookieData[MASQUERADING_COOKIE_KEY]
      };
    }

    return null;
  }

  public unmasquerade(): void {
    this.cookie.remove(MASQUERADING_COOKIE_KEY, {domain: `.${this.windowRef.nativeWindow.location.host}`});
  }

  public masquerade(userId: UserId): void {
    this.cookie.put(MASQUERADING_COOKIE_KEY, userId, {domain: `.${this.windowRef.nativeWindow.location.host}`});
  }
}
