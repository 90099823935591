<form [formGroup]="form" novalidate>
  <div [class]="getClass()" test-id="wrapper">
      <div class="star-picker__stars">
        <ng-container *ngFor="let value of values; first as first">
          <label
            (mouseenter)="mouseEnterStar(value)"
            (mouseleave)="mouseLeaveStar()"
            [for]="starPickerStar.id"
            class="star-picker__star"
            [class.star-picker__star--none]="first"
            [attr.aria-label]="value"
            test-id="star"
          ></label>
          <input
            #starPickerStar
            generatedId="star-picker-star"
            (click)="onTouched()"
            [value]="value"
            formControlName="starRating"
            type="radio"
            name="starRating"
            class="star-picker__input"
            analyticsId="star-rating"
            test-id="input"
          >
        </ng-container>
      </div>
      <span
        class="star-picker__text"
        test-id="visibleLabel"
      >{{ getOptionToShow() | starRatingLabel:hideDefaultText }}</span>
  </div>
</form>
