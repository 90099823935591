<div class="cart-panel cart-panel--separator-above">
  <div class="cart-panel__content">

    <div class="cart-notes">
      <div
        class="cart-notes__label-row"
        (click)="toggle($event)"
      >
        <div
          #label
          class="cart-notes__title"
          generatedId="cart-notes-label"
        >
          Dietary Notes & Cutlery
        </div>
        <div class="cart-notes__expand">
        <span test-id="addedLabel">
          {{ notes || includeCutlery ? '(added)' : '(none)' }}
        </span>

          <button
            class="expand-toggle-button expand-toggle-button--light"
            [class.expand-toggle-button--expanded]="isExpanded"
            test-id="toggleButton"
          ></button>
        </div>
      </div>
      <div
        class="cart-notes__input-wrapper"
        e2e-test-id="cartNotesInput"
        [@expand]="isExpanded"
        (@expand.done)="onExpandCompleted()"
        test-id="inputWrapper"
      >
        <textarea
          #input
          class="form-input cart-notes__input"
          [attr.aria-labelledby]="label.id"
          [placeholder]="'Please add any additional dietary preferences or explicit labeling requirements for certain items. \n' +
                         'You\'ll be able to fill in any delivery notes later.'"
          rows="3"
          [value]="notes"
          [disabled]="disallowEditNotes"
          (focus)="toggle($event, true)"
          (blur)="onBlur($event.target.value)"
          test-id="input"
        ></textarea>

        <label class="form-choice form-choice--large mt-x-small">
          <input
            class="form-choice__input"
            type="checkbox"
            [checked]="includeCutlery"
            [disabled]="disallowEditNotes || !isCutleryAvailable"
            (change)="onCutleryChange($event.target.checked)"
            test-id="cutleryCheckbox"
          />
          <span class="form-choice__label">
            <span><!-- to escape inline-flex container -->
              Include cutlery and napkins
              <span
                *ngIf="!isCutleryAvailable"
                class="d-block mt-x-small"
                test-id="cutleryNotAvailable"
              >(Not available for your chosen {{ 'restaurant' | plural:childOrderCount }})</span>
            </span>
          </span>
        </label>

      </div>
    </div>
  </div>
</div>
