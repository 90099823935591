import { Address } from '../../address.model';
import { MajorCurrency } from '../../money';
import { createCustomerLocationFromJson, CustomerLocation } from '../customer-location.model';

type EnumNumericValue = number; // Represents raw enum values from the API; avoid

export interface MealPlanRequirements {
  headcount: number;
  maxBudget: MajorCurrency;
  dietaryNotes?: string;
  requestCutleryAndServiettes: boolean;
  deliveryAddress: Address;
  location: CustomerLocation;
}

export interface MealPlanRequirementsJsonProperties {
  // Mapped fields
  headcount: number;
  maxBudget: MajorCurrency;
  dietaryNotes?: string;
  requestCutleryAndServiettes: boolean;
  deliveryAddress: CustomerLocation;
  location: CustomerLocation;

  // Not mapped yet; move up if they become relevant

  time: number; // integer representing time, e.g. 1030 = 10:30pm
  deliveryDays: string[];
  duration: EnumNumericValue;
  durationText: string; // Enum value
  minBudget: number;
  packagingTypeChoice: EnumNumericValue;
  isToBeCateredOnBankHolidays: boolean;
  paymentTerm: number;
  billingAddress: any;
  packageDispositions: any;
  cityPantryNotes?: string;
  purchaseOrderNumber?: any;
  departmentReference?: any;
}

export function createMealPlanRequirementsFromJson(json?: MealPlanRequirementsJsonProperties): MealPlanRequirements {
  const requirements: Partial<MealPlanRequirements> = {};

  requirements.headcount = json.headcount || 0;
  requirements.maxBudget = json.maxBudget || 0;
  requirements.dietaryNotes = json.dietaryNotes || '';
  requirements.requestCutleryAndServiettes = json.requestCutleryAndServiettes || false;
  requirements.deliveryAddress = json.deliveryAddress ? createCustomerLocationFromJson(json.deliveryAddress) : null;
  requirements.location = json.location ? createCustomerLocationFromJson(json.location) : null;

  return requirements as MealPlanRequirements;
}
