import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemDeliverabilityProblem } from '@citypantry/util-models';
import { TranslateDeliverabilityProblemToMessagePipe } from '../pipes/translate-deliverability-problem-to-message.pipe';

@Component({
  selector: 'app-deliverability-flag',
  template: `
    <i
      *ngIf="showDeliverabilityFlag()"
      class="icon icon-flag icon--medium status-text--red mr-2"
      [tooltip]="getDeliverabilityProblemsMessage()"
      test-id="flag"
    ></i>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverabilityFlagComponent {

  @Input()
  public problems: ItemDeliverabilityProblem[];

  constructor(
    private translateProblemPipe: TranslateDeliverabilityProblemToMessagePipe
  ) {}

  public showDeliverabilityFlag(): boolean {
    return (this.problems || []).length > 0;
  }

  public getDeliverabilityProblemsMessage(): string {
    return (this.problems || []).map((problem) => this.translateProblemPipe.transform(problem)).join(' ');
  }
}
