import { createAction, props, union } from '@ngrx/store';
import { AnalyticsAction as AnalyticsActionEnum } from './analytics-action.enum';
import { AnalyticsProperties } from './analytics-properties.model';

export const AnalyticsActions = {
  trackPage: createAction(
    '[Analytics] Track page',
  ),

  trackEvent: createAction(
    '[Analytics] Track event',
    props<{ action: AnalyticsActionEnum, properties: AnalyticsProperties }>(),
  ),

  trackCustomEvent: createAction(
    '[Analytics] Track custom event',
    props<{ action: string, properties: AnalyticsProperties }>(),
  ),

  clearEcommerceData: createAction(
    '[Analytics] Clear ecommerce data',
  ),

  triggerHotjar: createAction(
    '[Analytics] Trigger hotjar',
    props<{ event: string }>(),
  ),
};

const all = union(AnalyticsActions);
export type AnalyticsAction = typeof all;
