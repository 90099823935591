import { Pipe, PipeTransform } from '@angular/core';
import { DateInEuropeLondonPipe } from '@citypantry/components-datetime';

import { DeliveryWindow } from '@citypantry/util-models';

/**
 * Converts a DeliveryWindow object to a formatted string.
 */
@Pipe({
  name: 'formatDeliveryWindow'
})
export class FormatDeliveryWindowPipe implements PipeTransform {

  constructor(private dateInEuropeLondon: DateInEuropeLondonPipe) {}

  public transform(deliveryWindow: DeliveryWindow): string {
    const datePipe = this.dateInEuropeLondon;

    return `${datePipe.transform(deliveryWindow.start.clone(), 'HH:mm')} - ${datePipe.transform(deliveryWindow.end.clone(), 'HH:mm')}`;
  }
}
