export type FoodType = 'main' | 'side' | 'dessert' | 'drink' | 'other';

export class FoodTypes {
  public static MAIN: FoodType = 'main';
  public static SIDE: FoodType = 'side';
  public static DESSERT: FoodType = 'dessert';
  public static DRINK: FoodType = 'drink';
  public static OTHER: FoodType = 'other';

  public static get values(): FoodType[] {
    return [
      FoodTypes.MAIN,
      FoodTypes.SIDE,
      FoodTypes.DESSERT,
      FoodTypes.DRINK,
      FoodTypes.OTHER
    ];
  }
}

export type FoodTypeEnum = typeof FoodTypes;
