<div
  class="editable-dropdown"
  [class.editable-dropdown--edit]="isEditing"
  test-id="component"
>
  <div class="input-icon editable-dropdown__dropdown">
    <select
      class="form-input"
      #dropdown
      [formControl]="dropdownControl"
      [class.form-input--error]="invalid && touched"
      test-id="dropdown"
      e2e-test-id="dropdown"
    >
      <option
        *ngIf="dropdownControl.value === -2"
        disabled
        [ngValue]="-2"
        test-id="initialOption"
      >{{ initialLabel }}</option>
      <option
        *ngFor="let option of options; index as i"
        [ngValue]="i"
        test-id="options"
      >
        {{ option.label }}
      </option>
      <option
        [ngValue]="-1"
        test-id="options"
      >{{ manualLabel }}</option>
    </select>
    <i
      class="icon input-icon__icon"
      [ngClass]="iconClass"
      test-id="icon"
    ></i>
  </div>
  <input
    class="form-input editable-dropdown__text"
    [class.form-input--error]="invalid && touched"
    placeholder="{{ placeholder }}"
    #text
    [value]="textValue"
    (enter)="text.blur()"
    (blur)="updateText(text.value)"
    test-id="textInput"
  />
  <button
    class="editable-dropdown__back-button"
    (click)="stopEditing()"
    test-id="backButton"
  >
    <i class="icon icon-left-caret"></i>
  </button>
</div>
