import { animate, animation, keyframes, style } from '@angular/animations';

export const shake = animation(
  animate(
    '{{ timing }} {{ delay }}s',
    keyframes([
      style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ transform: 'translate3d(-{{ distance }}, 0, 0)', offset: 0.2 }),
      style({ transform: 'translate3d({{ distance }}, 0, 0)', offset: 0.4 }),
      style({ transform: 'translate3d(-{{ distance }}, 0, 0)', offset: 0.6 }),
      style({ transform: 'translate3d({{ distance }}, 0, 0)', offset: 0.8 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 }),
    ])
  ),
  { params: { timing: '1s', delay: 0, distance: '10%' } }
);
