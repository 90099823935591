import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { MealPlanApi } from '@citypantry/shared-api';
import { AppState, RouterActions } from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { createSearchRequestFromURLQuery, MealPlan } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrepareMealPlanSearchRequestGuard implements CanActivate {

  constructor(
    private mealPlanApi: MealPlanApi,
    private store: Store<AppState>,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const request = createSearchRequestFromURLQuery(route.queryParams);

    return this.mealPlanApi.getMealPlan(route.params.mealPlanId).pipe(
      map((mealPlan: MealPlan) => {
        this.store.dispatch(PublicActions.prepareMealPlanSearch({ request, mealPlan, proposedOrderId: route.params.orderId }));
        return true;
      }),
      catchError((error: any) => {
        if (!(error instanceof HttpResponse) || error.status !== 404) {
          console.error(error);
        }
        this.store.dispatch(RouterActions.go({ path: ['/404'] }));
        return of(false);
      })
    );
  }
}
