import { Pipe, PipeTransform } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { VatRateType, VatRateTypes } from '@citypantry/util-models';

@Pipe({
  name: 'vatRateLabel',
})
export class VatRateLabelPipe implements PipeTransform {

  public transform(value: VatRateType): string | null {
    switch (value) {
      case VatRateTypes.UK_ZERO_RATE:
      case VatRateTypes.IE_ZERO_RATE:
        return 'Zero Rate (0%)';
      case VatRateTypes.UK_REDUCED_RATE:
        return 'Reduced Rate (12.5%)';
      case VatRateTypes.IE_REDUCED_RATE:
        return 'Reduced Rate (13.5%)';
      case VatRateTypes.UK_STANDARD_RATE:
        return 'Standard Rate (20%)';
      case VatRateTypes.IE_STANDARD_RATE:
        return 'Standard Rate (23%)';
      default:
        throw new UnreachableCaseError(value);
    }
  }
}
