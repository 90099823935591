import moment, { Moment } from 'moment';
import { CustomerId } from '../customer/customer.model';

declare const ConsolidatedInvoiceIdSymbol: unique symbol;
export type ConsolidatedInvoiceId = string & {
  [ConsolidatedInvoiceIdSymbol]: never;
};

export interface ConsolidatedInvoice {
  id: ConsolidatedInvoiceId;
  reference: string;
  frequency: 'monthly' | 'weekly';
  customerId: CustomerId;
  companyName: string;
  recipient: string; // customer's email
  timestamp: Moment | null;

  htmlFileSystemUrl: string;
  pdfFileSystemUrl: string;

  vatCost: number;
  netCost: number;
  grossCost: number;
}

export function createConsolidatedInvoiceFromJson(json: Partial<ConsolidatedInvoice> = {}): ConsolidatedInvoice {
  const invoice: Partial<ConsolidatedInvoice> = {};

  invoice.id = json.id;
  invoice.reference = json.reference;
  invoice.frequency = json.frequency;
  invoice.customerId = json.customerId;
  invoice.companyName = json.companyName;
  invoice.recipient = json.recipient;
  invoice.timestamp = json.timestamp ? moment.unix(json.timestamp as unknown as number) : null;
  invoice.htmlFileSystemUrl = json.htmlFileSystemUrl;
  invoice.pdfFileSystemUrl = json.pdfFileSystemUrl;

  invoice.vatCost = json.vatCost;
  invoice.netCost = json.netCost;
  invoice.grossCost = json.grossCost;

  return invoice as ConsolidatedInvoice;
}
