import { ensureValidEnumValue } from '@citypantry/util';
import { Allergens, createAllergensFromJson } from '../menu';
import { createItemFromJson } from '../menu/items/create-item';
import { CustomItem, isCustomItem } from '../menu/items/custom-item.model';
import { createDietariesFromJson, Dietaries } from '../menu/items/dietaries.model';
import { isItemBundle, ItemBundle } from '../menu/items/item-bundle.model';
import { ItemGroupType, ItemGroupTypes } from '../menu/items/item-group-type.enum';
import { Item } from '../menu/items/item.model';
import { isSingleItem, SingleItem } from '../menu/items/single-item.model';
import { MenuContent } from '../menu/menu.model';
import { Section } from '../menu/section.model';
import { MajorCurrency } from '../money';
import { CartItemType, CartItemTypes } from './cart-item-type.enum';

export interface CartItem {
  type: CartItemType;
  item: SingleItem | ItemBundle | CustomItem;
  quantity: number;
  price?: MajorCurrency;
  aggregatedAllergens?: Allergens;
  ageRestricted?: boolean; // Computed in the frontend upon creation
}

export interface CartSingleItem extends CartItem {
  item: SingleItem;
}

export interface CartCustomItem extends CartItem {
  item: CustomItem;
  selectedOptions: CartCustomItemOption[];
  dietaries: Dietaries | null;
}

export interface CartCustomItemOption {
  optionIndex: number;
  quantity: number;
}

export interface CartItemBundle extends CartItem {
  item: ItemBundle;
  groups: CartItemBundleGroup[]; // These match the groups by index; item.group[x].items[y] <=> groups[x].items[y].item
}

export interface CartItemBundleGroup {
  type: ItemGroupType;
  cartItems: CartSingleItem[];
}

export type SingleOrCustomCartItem = CartSingleItem | CartCustomItem;

export function isSingleOrCustomCartItem(cartItem: CartItem): cartItem is SingleOrCustomCartItem {
  return isSingleCartItem(cartItem) || isCustomCartItem(cartItem);
}

export function isSingleCartItem(cartItem: CartItem): cartItem is CartSingleItem {
  return isSingleItem(cartItem.item);
}

export function isCustomCartItem(cartItem: CartItem): cartItem is CartCustomItem {
  return isCustomItem(cartItem.item);
}

export function isBundleCartItem(cartItem: CartItem): cartItem is CartItemBundle {
  return isItemBundle(cartItem.item);
}

export function createCartItemFromJson<T extends CartItem = CartItem>(json: Partial<CartItem> = {}): T {
  const cartItem: Partial<T> = {};

  cartItem.type = ensureValidEnumValue(CartItemTypes, json.type);
  cartItem.quantity = json.quantity || 0;
  cartItem.price = json.price || 0;
  cartItem.aggregatedAllergens = createAllergensFromJson(json.aggregatedAllergens);
  cartItem.ageRestricted = !!json.ageRestricted;

  const item = createItemFromJson(json.item);
  cartItem.item = item;

  if (isItemBundle(item)) {
    (cartItem as unknown as Partial<CartItemBundle>).groups = ((json as Partial<CartItemBundle>).groups || [])
      .map(createCartItemBundleGroup);
  }

  if (isCustomItem(cartItem.item)) {
    (cartItem as unknown as Partial<CartCustomItem>).selectedOptions = ((json as Partial<CartCustomItem>).selectedOptions || [])
      .map(createCartCustomItemOptionFromJson);
    (cartItem as unknown as Partial<CartCustomItem>).dietaries = createDietariesFromJson((json as Partial<CartCustomItem>).dietaries);
  }

  return cartItem as T;
}

function createCartItemBundleGroup(jsonGroup: Partial<CartItemBundleGroup>): CartItemBundleGroup {
  return {
    type: ensureValidEnumValue(ItemGroupTypes, jsonGroup.type),
    cartItems: (jsonGroup.cartItems || []).map<CartSingleItem>(createCartItemFromJson)
  };
}

function createCartCustomItemOptionFromJson(jsonSelectedOption: Partial<CartCustomItemOption>): CartCustomItemOption {
  return {
    optionIndex: jsonSelectedOption.optionIndex,
    quantity: jsonSelectedOption.quantity || 0,
  };
}

export interface SectionedItemGroup {
  sectionName: string;
  cartItems: CartItem[];
}

export function getItemsGroupedBySection(
  cartItems: CartItem[],
  menuContent: MenuContent
): SectionedItemGroup[] {
  const cartItemsWithSection: string[] = [];
  const cartItemsBySection: SectionedItemGroup[] = [];

  menuContent.sections.forEach((section: Section) => {
    const sectionCartItems: CartItem[] = [];

    cartItems.forEach((cartItem: CartItem) => {
      if (section.items.some((item: Item) => item.id === cartItem.item.id)) {
        cartItemsWithSection.push(cartItem.item.id);
        sectionCartItems.push(cartItem);
      }
    });

    if (sectionCartItems.length) {
      cartItemsBySection.push({ sectionName: section.title, cartItems: sectionCartItems });
    }
  });

  // If there is a difference between the total number of cart items and items with a section, this means there
  // are items without a section, which causes items to be missing on the vendor order page (see CPD-7405). This checks
  // to see if there's a difference, and if so, puts the items without a section into an 'Other Items' section.
  // TODO CPD-7405 Find a fix for the underlying issue of why there is a discrepency between the cart item and immutable menu item
  if (cartItems.length !== cartItemsWithSection.length) {
    const itemsWithoutSection = cartItems.filter(
      (cartItem: CartItem) => !cartItemsWithSection.includes(cartItem.item.id)
    );

    cartItemsBySection.push({ sectionName: 'Other Items', cartItems: itemsWithoutSection });
  }

  return cartItemsBySection;
}

export function hasSameItemIdAndSelectedOptions(item: CartCustomItem, otherItem: CartCustomItem): boolean {
  if (item.item.id !== otherItem.item.id || item.selectedOptions.length !== otherItem.selectedOptions.length) {
    return false;
  }

  for (const selectedOption of item.selectedOptions) {
    if (!otherItem.selectedOptions.find((otherItemSelectedOption) => (
      selectedOption.optionIndex === otherItemSelectedOption.optionIndex &&
      selectedOption.quantity === otherItemSelectedOption.quantity
    ))) {
      return false;
    }
  }

  return true;
}
