import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'formatTimeFromHours',
})
export class FormatTimeFromHoursPipe implements PipeTransform {

  /**
   * A pipe to format the time given just the hour of the day.
   * This pipe will not consider timezones
   *
   * Default formatting will result in the following outcomes:
   * given 10 will return 10am
   * given 15 will return 3pm
   *
   * @param hour Number of hours past midnight
   */
  public transform(hour: number): string {
    return moment().startOf('day').add(hour, 'hours').format('ha');
  }
}
