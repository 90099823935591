import { Injectable } from '@angular/core';
import { AnalyticsActions, AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';
import { CartApi, DeliverableMenuResponse, MealPlanApi, MenuApi, OrderApi, SearchApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { ExpectedPaymentError } from '@citypantry/shared-braintree';
import { ErrorService } from '@citypantry/shared-error';
import { PaymentService } from '@citypantry/shared-payment';
import { PromotionService } from '@citypantry/shared-promotion';
import {
  AppState,
  CartSelectors,
  MealPlanSelectors,
  MenuSelectors,
  RouterActions,
  RouterSelectors,
  SearchSelectors
} from '@citypantry/state';
import { PublicAction, PublicActions } from '@citypantry/state-public';
import { CartQueries, MenuQueries } from '@citypantry/state-queries';
import { normalisePostcode, UnreachableCaseError } from '@citypantry/util';
import { AgeConfirmationEnum } from '@citypantry/util-enums';
import {
  Cart,
  CartDeliverability,
  CartType,
  CartTypes,
  constructThreeDSecureParameters,
  DeliverableMenu,
  EMPTY_CART_DELIVERABILITY,
  ErrorMessage,
  getDefaultDeliveryDate,
  MealPlan,
  Order,
  ReorderCartDetails,
  SearchRequest
} from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { concat, EMPTY, forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

interface PrepareCartMappedProperties {
  fromSearch: boolean;
  requestedDate: Moment;
  reorderCart: ReorderCartDetails | null;
}

const doesCartMatchCurrentMenu = (cart: Cart, deliverableMenu: DeliverableMenu): boolean =>
  cart.contentId === deliverableMenu.content.id
  && cart.vendorLocationId === deliverableMenu.vendorLocation?.id;

@Injectable()
export class CartEffects {

  /**
   * This effect is triggered by the MenuExistsGuard and ensures the cart is in the right state before the page is loaded.
   */

  public prepareCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.prepareCart.type),
    switchMap(({ fromSearch, requestedDate, restoreItemsFromCartId, restoreDateFromCart }) => {
      let reorderCartDetails = of(null);
      if (restoreItemsFromCartId) {
        reorderCartDetails = this.menuQueries.getDeliverableMenu().pipe(
          take(1),
          switchMap((deliverableMenu) =>
            this.cartApi.getReorderableCart(
              restoreItemsFromCartId,
              deliverableMenu!.content.id, /* eslint-disable-line @typescript-eslint/no-non-null-assertion */ // TODO CPD-14529
              restoreDateFromCart ? null : requestedDate.toISOString()
            )
          ),
        );
      }
      return reorderCartDetails.pipe(
        map((reorderCart: ReorderCartDetails | null) => ({ fromSearch, requestedDate, reorderCart }))
      );
    }),
    withLatestFrom<
      PrepareCartMappedProperties,
      [PrepareCartMappedProperties, CartType, Cart, SearchRequest, MealPlan, string, DeliverableMenu]
    >(
      this.store.select(CartSelectors.getCartType),
      this.store.select(CartSelectors.getCart),
      this.store.select(SearchSelectors.getSearchRequest),
      this.store.select(MealPlanSelectors.getMealPlan),
      this.store.select(MealPlanSelectors.getProposedOrderId),
      this.menuQueries.getDeliverableMenu(),
    ),
    switchMap((
      [{ fromSearch, requestedDate, reorderCart }, cartType, cart, request, mealPlan, proposedOrderId, deliverableMenu],
    ): Observable<Action> => {
      const prepareCartAction = this.prepareCart(
        fromSearch,
        requestedDate,
        reorderCart,
        cartType,
        cart,
        request,
        mealPlan,
        proposedOrderId,
        deliverableMenu
      );

      return prepareCartAction
        ? of(prepareCartAction, PublicActions.cartPrepareSuccess())
        : of(PublicActions.cartPrepareSuccess());
    })
  ));

  public setCartFreeDeliveryOnPrepareCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.prepareCart.type),
    switchMap(() =>
      this.promotionService.isEligibleForFreeDeliveryPromotion().pipe(
        map((freeDelivery) => PublicActions.setCartFreeDelivery({ freeDelivery }))
      )
    )
  ));

  public checkCartDeliverabilityIfRequired$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.setupExistingOrder.type,
      PublicActions.updateCart.type,
    ),
    mapTo(PublicActions.checkCartDeliverability()),
  ));

  public checkCartDeliverability$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.checkCartDeliverability.type),
    withLatestFrom(this.store.select(CartSelectors.getCart)),
    switchMap(([, cart]): Observable<CartDeliverability> => {
      if (!cart.cartItems.length || !cart.postcode) {
        return of(EMPTY_CART_DELIVERABILITY);
      } else {
        return this.cartApi.getDeliverability(cart, cart.deliveryDate, cart.postcode);
      }
    }),
    map((deliverability: CartDeliverability) => PublicActions.cartDeliverabilityLoaded({ deliverability, isSubmittingCart: false })),
  ));

  public submitCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.submitCart.type),
    this.withValidationResult(), // send validation request so that latest cart data is persisted in the DB
    withLatestFrom(
      this.store.select(CartSelectors.getCartType),
      this.store.select(CartSelectors.getCart),
    ),
    switchMap((
      [{ input, result: validationResult }, cartType, cart]
    ): Observable<Action> => {
      if (validationResult.type !== PublicActions.orderValidated.type) {
        return of(validationResult); // terminate cart submission if validation fails
      } else if (cartType === CartTypes.NEW_CART) {
        this.analyticsService.trackCustomEvent('initiate_checkout', { category: AnalyticsCategories.CART });
        return concat(
          this.cartApi.getDeliverability(cart, cart.deliveryDate, cart.postcode).pipe(
            map((deliverability): Action => PublicActions.cartDeliverabilityLoaded({ deliverability, isSubmittingCart: true })),
            // We attempt to submit the cart regardless of the result because doSubmitCart
            // calls cartQueries.hasProblems() to decide whether to proceed and we don't want to duplicate that logic
          ),
          of(PublicActions.doSubmitCart(input))
        );
      } else {
        return of(PublicActions.doSubmitCart(input));
      }
    }),
  ));

  public doSubmitCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.doSubmitCart.type,
      PublicActions.ageConfirmationDialogConfirm.type,
    ),
    withLatestFrom(this.store.select(CartSelectors.isAgeConfirmationDialogVisible)),
    filter(([, isAgeConfirmationDialogVisible]) => !isAgeConfirmationDialogVisible),
    withLatestFrom(
      this.store.select(CartSelectors.getCartType),
      this.store.select(CartSelectors.getCart),
      this.store.select(CartSelectors.isAdditionalCardPaymentRequired),
      this.cartQueries.hasProblems(),
      this.cartQueries.canOverrideAllProblems(),
      this.store.select(AuthSelectors.isLoggedInUser),
      this.store.select(AuthSelectors.isCustomerOrOrderer),
    ),
    switchMap(([[action], cartType, cart, isAdditionalCardPaymentRequired, hasProblems, canOverride, isLoggedIn, isCustomerOrOrderer]:
      [
        [ (ReturnType<typeof PublicActions.doSubmitCart | typeof PublicActions.ageConfirmationDialogConfirm>) ],
        CartType, Cart, boolean, boolean, boolean, boolean, boolean
      ]
    ) => {
      switch (cartType) {
        case CartTypes.NEW_CART: {
          const canSubmitCart = !isLoggedIn || isCustomerOrOrderer;
          const overrideErrors = action.overrideErrors;
          return this.submitNewCart(canSubmitCart, hasProblems, canOverride, overrideErrors);
        }
        case CartTypes.EXISTING_CART: {
          const hasCustomerConfirmedAge = action.type === PublicActions.ageConfirmationDialogConfirm.type
            ? AgeConfirmationEnum.CONFIRMED : AgeConfirmationEnum.NOT_CONFIRMED;

          if (isAdditionalCardPaymentRequired) {
            return of(PublicActions.verifyCardThenUpdateCart({ cart, hasCustomerConfirmedAge }));
          } else {
            return of(PublicActions.updateCartOnOrder({ cart, hasCustomerConfirmedAge, threeDSecureEnrichedNonce: null }));
          }
        }
        case CartTypes.MEALPLAN_CART:
          return of(PublicActions.replaceMealPlanCart({ cart }));
        default:
          throw new UnreachableCaseError(cartType);
      }
    }),
  ));

  public clearEcommerceDataForNewCarts$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.createNewCart.type
    ),
    withLatestFrom(
      this.store.select(CartSelectors.getCartType),
    ),
    filter(([, cartType]) => cartType !== CartTypes.EXISTING_CART),
    tap(() => {
      this.analyticsService.clearEcommerceData();
    }),
  ), { dispatch: false });

  public loadNotificationsForExistingCarts$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.addOrUpdateSingleItem.type,
      PublicActions.addOrUpdateItemBundle.type,
      PublicActions.removeItemFromCart.type,
      PublicActions.setupExistingOrder.type
    ),
    withLatestFrom(
      this.store.select(CartSelectors.getCartType),
    ),
    filter(([, cartType]) => cartType === CartTypes.EXISTING_CART),
    mapTo(PublicActions.loadCartNotifications()),
  ));

  public loadCartNotifications$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.loadCartNotifications.type),
    withLatestFrom(
      this.store.select(CartSelectors.getCart),
      this.store.select(CartSelectors.getCartOrder)
    ),
    switchMap(([, cart, order]: [Action, Cart, Order]) => this.cartApi.getNotifications(cart, order.id)),
    map((notifications) => PublicActions.cartNotificationsLoaded({ notifications })),
  ));

  public verifyCardThenUpdateCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.verifyCardThenUpdateCart.type),
    withLatestFrom(
      this.store.select(CartSelectors.getCartOrder),
      this.store.select(CartSelectors.getPriceChange),
      this.store.select(AuthSelectors.getUserEmail),
      this.store.select(AuthSelectors.isStaffOrSudo)
    ),
    switchMap((
      [action, order, priceChange, userEmail, isStaffOrSudo]
    ) => {
      const { cart, hasCustomerConfirmedAge } = action;

      if (isStaffOrSudo) {
        // Skip 3D secure verification for staff because they cannot pass the challenge
        return of(PublicActions.updateCartOnOrder({ cart, hasCustomerConfirmedAge, threeDSecureEnrichedNonce: null }));
      }

      const parameters = constructThreeDSecureParameters(priceChange, userEmail, order.location);

      return this.paymentService.verifyCard(order.paymentCardId, parameters).pipe(
        map((threeDSecureEnrichedNonce) => {
          return PublicActions.updateCartOnOrder({ cart, hasCustomerConfirmedAge, threeDSecureEnrichedNonce });
        }),
        catchError((error) => {
          return of(PublicActions.failedToVerifyCard({ error }), PublicActions.submitCartFailed());
        }),
      );
    }),
  ));

  public handlePaymentCardError$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.failedToVerifyCard.type),
    tap((action) => {
      const { error } = action;

      if (error instanceof ExpectedPaymentError) {
        this.errorService.showAlert(error.message, error.detailedMessage);
      } else {
        throw error;
      }
    }),
  ), { dispatch: false });

  public updateCartOnOrder$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.updateCartOnOrder.type),
    withLatestFrom(
      this.store.select(CartSelectors.getCartOrder),
      this.store.select(CartSelectors.isExistingCartFromAdmin)
    ),
    switchMap(([action, order, fromAdmin]) => {
      const { cart, hasCustomerConfirmedAge, threeDSecureEnrichedNonce } = action;

      return this.cartApi.updateCart(order.id, cart, hasCustomerConfirmedAge, threeDSecureEnrichedNonce).pipe(
        map(() => fromAdmin ?
          RouterActions.goExternal({ url: `/admin/order/${order.id}` }) :
          RouterActions.go({ path: `/menus/customer/orders/${order.id}` })
        ),
        catchError((error: any) => {
          this.errorService.logError(error);
          return of(PublicActions.submitCartFailed());
        })
      );
    }),
  ));

  public replaceMealPlanCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.replaceMealPlanCart.type),
    withLatestFrom(
      this.store.select(MealPlanSelectors.getMealPlan),
      this.store.select(AuthSelectors.isStaff),
      this.store.select(MealPlanSelectors.getProposedOrderId)
    ),
    switchMap(([action, { id: mealPlanId }, isStaff, proposedOrderId]) => {
      const cart = action.cart;
      const deliveryDate = cart.deliveryDate;

      if (!mealPlanId || !deliveryDate || !cart) {
        throw new Error('Cannot replace cart in a meal plan when any one of id, date or cart are missing');
      }

      const url = isStaff ?
        `/admin/meal-plan/${mealPlanId}/review` :
        `/customer/meal-plans/${mealPlanId}/review`;

      // CPD-3852 Clear meal plan mode from state so the user does not see the meal plan cart the next time they open that menu
      return this.mealPlanApi.replaceCart(mealPlanId, cart, proposedOrderId).pipe(
        switchMap(() => {
          return of(PublicActions.clearMealPlanCart(), RouterActions.goExternal({ url }));
        }));
    }),
  ));

  public updateDateInUrl$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.updateCart.type,
      PublicActions.submitCartChanges.type,
      PublicActions.removeInvalidCartItems.type
    ),
    withLatestFrom(this.store.select(CartSelectors.getDeliveryDate)),
    map(([, deliveryDate]: [Action, moment.Moment]) => deliveryDate.format('YYYY-MM-DDTHH:mm')),
    distinctUntilChanged(),
    withLatestFrom(this.store.select(RouterSelectors.getQueryParams)),
    filter(([date, queryParams]) => date !== queryParams.date), // Don't update the date if it's already there
    delay(1), // Wait a tiny bit to make sure any pending navigation has finished
    map(([date]) => RouterActions.query({
      params: { date },
      extras: { replaceUrl: true } // CPD-2233: Don't add a history entry just because we've updated the URL
    })),
  ));

  public createMenuPdfFromCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.createMenuPdf.type),
    withLatestFrom(this.store.select(CartSelectors.getCart)),
    switchMap(([, cart]) => {
      return this.cartApi.createMenuPdf(cart).pipe(
        map((url) => RouterActions.goExternal({ url })));
    }),
  ));

  public createCartQuote$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.cartDownloadQuote.type),
    withLatestFrom(this.store.select(CartSelectors.getCart)),
    switchMap(([, cart]) => {
      return this.cartApi.createQuotePdf(cart).pipe(
        map((url) => PublicActions.cartApiCreateQuoteSuccess({ url })),
      );
    }),
  ));

  public openCartQuote$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.cartApiCreateQuoteSuccess.type),
    map(({ url }) => RouterActions.goExternalInNewWindow({ url })),
  ));

  public loadNewCartMenu$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.updateCartParameters.type
    ),
    withLatestFrom(
      this.store.select(CartSelectors.getCart),
      this.store.select(MenuSelectors.getCurrentVendorLocationSlug),
    ),
    switchMap((
      [{ postcode, deliveryDate }, cart, vendorLocationSlug]
    ) => {
      return forkJoin([
        this.cartApi.getDeliverability(cart, deliveryDate, postcode),
        this.menuApi.getDeliverableMenu(
          cart.vendorId,
          vendorLocationSlug,
          deliveryDate,
          postcode,
          this.store.select(MenuSelectors.getCurrentVendor)
        ),
      ]).pipe(
        map(([deliverability, menuResponse]: [CartDeliverability, DeliverableMenuResponse]) =>
          PublicActions.pendingCartDeliverabilityLoaded({
            deliverability, menu: menuResponse.menu || null,
            menuError: menuResponse.error || null
          }))
      );
    }),
  ));

  public triggerOrderValidation$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.addOrUpdateSingleItem.type,
      PublicActions.addOrUpdateItemBundle.type,
      PublicActions.removeItemFromCart.type,
      PublicActions.submitCartChanges.type,
      PublicActions.createNewCart.type,
      PublicActions.createMealplanCart.type,
      PublicActions.setupExistingMealplanCart.type,
    ),
    filter((action: PublicAction) => {
      return action.type !== PublicActions.createNewCart.type ||
        !!action.reorderCart;
    }),
    withLatestFrom(
      this.store.select(CartSelectors.getCart),
    ),
    filter(([, cart]) => {
      return !!(cart && cart.postcode); // if there is no postcode, openPendingCartChangesModal will be triggered instead
    }),
    mapTo(PublicActions.validateOrder())
  ));

  public validateOrder$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.validateOrder.type),
    this.withValidationResult(),
    map(({ result }) => result),
  ));

  public trackFirstAdd$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.addOrUpdateSingleItem.type,
      PublicActions.addOrUpdateItemBundle.type
    ),
    startWith(null as Action), // Ensures the distinctUntilChanged closure is entered on first ADD_OR_UPDATE_SINGLE_ITEM
    withLatestFrom(this.store.select(CartSelectors.getCart)),
    distinctUntilChanged(([, oldCart], [, newCart]) => {
      const oldCartHasNoVendor = !oldCart.vendorId;
      const cartVendorHasChanged = oldCart.vendorId !== newCart.vendorId;
      const oldCartHasCartItems = oldCart.cartItems.length > 0;

      if (oldCartHasNoVendor || cartVendorHasChanged) {
        // if there was no vendor previously, or the vendorId has changed this considered a clean cart
        return false;
      }

      // if we reach this point we can assume we are using the same cart, so we check if it has already got items
      return oldCartHasCartItems;
    }),
    filter(([action]) => !!action), // Prevents startWith(null) action from dispatching trackAbandonedCart
    tap(() => {
      this.analyticsService.trackCustomEvent('add_to_cart', { category: AnalyticsCategories.CART });
    }),
    mapTo(PublicActions.trackAbandonedCart()),
  ));

  public trackAbandonedCart$: Observable<void> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.trackAbandonedCart.type),
    withLatestFrom(
      this.store.select(AuthSelectors.isStaffOrSudo),
      this.store.select(MealPlanSelectors.getMealPlan),
    ),
    filter(([, isStaffOrSudo, mealPlan]) => !mealPlan && !isStaffOrSudo),
    switchMap(() => {
      return this.store.select(CartSelectors.hasCartId).pipe(
        first((hasId) => hasId), // to track the cart that has been abandoned
        // we need to wait until we have a Cart ID in store that the backend is aware of
        // (i.e. wait until the first order validation call comes back with a Cart ID from the backend)
        withLatestFrom(this.store.select(CartSelectors.getCart)),
        switchMap(([, cart]) => this.cartApi.trackAbandonedCart(cart)),
      );
    }),
  ), { dispatch: false });

  public trackRestoreItemsFromCartAnalytic$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.trackRestoreItemsFromCartSourceAnalytic.type,
    ),
    tap((action) => this.analyticsService.trackEvent(
      AnalyticsActions.RESTORE,
      {
        category: AnalyticsCategories.CART,
        label: `Cart items restored from ${action.source}`
      }
    )),
  ), { dispatch: false });

  public loadMenuRegion$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.prepareCart.type, PublicActions.submitCartChanges.type),
    withLatestFrom(
      this.store.select(SearchSelectors.getSearchRequest),
    ),
    switchMap(([, searchRequest]: [Action, SearchRequest]) => {
      const { postcode } = searchRequest;
      if (!postcode) {
        return EMPTY;
      }
      return this.searchApi.getOperationalRegion(postcode).pipe(
        map((region) => PublicActions.loadMenuRegionSuccess({ region })),
        catchError(() => {
          return of(PublicActions.loadMenuRegionFailure());
        })
      );
    }),
  ));

  constructor(
    private action$: Actions<PublicAction>,
    private store: Store<AppState>,
    private cartApi: CartApi,
    private searchApi: SearchApi,
    private cartQueries: CartQueries,
    private promotionService: PromotionService,
    private menuQueries: MenuQueries,
    private mealPlanApi: MealPlanApi,
    private menuApi: MenuApi,
    private orderApi: OrderApi,
    private errorService: ErrorService,
    private analyticsService: AnalyticsService,
    private paymentService: PaymentService,
  ) {
  }

  private withValidationResult<T>(): OperatorFunction<T, { input: T, result: Action }> {
    return (input$) => input$.pipe(
      withLatestFrom(
        this.store.select(CartSelectors.getCart),
        this.store.select(CartSelectors.getCartOrder),
        this.promotionService.isEligibleForFreeDeliveryPromotion(),
        this.store.select(MealPlanSelectors.getMealPlan),
        this.store.select(AuthSelectors.customer.getId),
      ),
      switchMap(([input, cart, order, isFreeDelivery, mealPlan, profileCustomerId]) => {
        const customerId = profileCustomerId
          ? profileCustomerId
          : mealPlan && mealPlan.customer && mealPlan.customer.id || null;

        return this.orderApi.validateMarketplaceOrder(
          cart.headcount,
          cart,
          cart.deliveryDate,
          customerId,
          order ? order.id : null,
          mealPlan ? mealPlan.id : null
        ).pipe(
          map((response): Action =>
            response.success === true
              ? PublicActions.orderValidated({ costBreakdown: response.value.costBreakdown, cartId: response.value.cartId, isFreeDelivery })
              : PublicActions.orderValidationFailed({ error: response.error.violation, cartId: response.error.cartId })),
          catchError((error: ErrorMessage) => {
            this.errorService.showErrorMessage(error);
            return of(PublicActions.orderValidationFailed({ error: null, cartId: null }));
          }),
          map((result) => ({ input, result })),
        );
      }),
    );
  }

  /**
   * This method is called before the user enters the Menu Page. It should trigger validation and ensure the cart is in the correct state.
   * FIXME: All the information used by this method is in the store - instead of triggering different actions it could use a single reducer.
   */
  private prepareCart(
    fromSearch: boolean,
    requestedDate: Moment,
    reorderCart: ReorderCartDetails | null,
    cartType: CartType,
    cart: Cart,
    request: SearchRequest,
    mealPlan: MealPlan | null,
    proposedOrderId: string | null,
    deliverableMenu: DeliverableMenu,
  ): Action | null {
    if (cartType === CartTypes.EXISTING_CART) {
      // prepareCart is only called when navigating directly to a vendor page,
      // but the cart in the store is one we were editing - clear it, we do not want to edit it!
      return PublicActions.createNewCart({
        deliveryDate: requestedDate || request.date || getDefaultDeliveryDate(),
        postcode: normalisePostcode(request.postcode) || null,
        menuId: deliverableMenu.menuId,
        contentId: deliverableMenu.content.id,
        vendorId: deliverableMenu.vendor.id,
        vendorLocationId: deliverableMenu.vendorLocation?.id,
        currencyIsoCode: deliverableMenu.vendor.country.currency.code,
      });
    }

    if (fromSearch) {
      if (doesCartMatchCurrentMenu(cart, deliverableMenu)) {
        // Cart matches current menu, just update date & postcode
        return PublicActions.updateCart({ deliveryDate: request.date, postcode: request.postcode });
      }

      // Different contentId = the customer has moved to a different menu
      // TODO CPD-2321 If the vendorId matches but the contentId does not, show the BAD_MENU popup

      if (mealPlan) {
        return PublicActions.createMealplanCart({
          deliveryDate: request.date,
          mealPlan,
          menuId: deliverableMenu.menuId,
          contentId: deliverableMenu.content.id,
          vendorId: deliverableMenu.vendor.id,
          vendorLocationId: deliverableMenu.vendorLocation?.id,
          reorderCart,
          currencyIsoCode: deliverableMenu.vendor.country.currency.code
        });
      }

      // We got here from search, the menu is different, and there's no meal plan
      // Create a new cart from the search parameters
      return PublicActions.createNewCart({
        deliveryDate: request.date,
        postcode: normalisePostcode(request.postcode),
        menuId: deliverableMenu.menuId,
        contentId: deliverableMenu.content.id,
        vendorId: deliverableMenu.vendor.id,
        vendorLocationId: deliverableMenu.vendorLocation?.id,
        currencyIsoCode: deliverableMenu.vendor.country.currency.code,
      });
    }

    if (mealPlan && proposedOrderId) {
      const proposedOrder = mealPlan.proposedOrders.find((order) => order.id === proposedOrderId);
      return PublicActions.createMealplanCart({
        deliveryDate: moment(proposedOrder.requestedDeliveryDate),
        mealPlan,
        menuId: deliverableMenu.menuId,
        contentId: deliverableMenu.content.id,
        vendorId: deliverableMenu.vendor.id,
        vendorLocationId: deliverableMenu.vendorLocation?.id,
        reorderCart,
        currencyIsoCode: deliverableMenu.vendor.country.currency.code,
      });
    }

    if (doesCartMatchCurrentMenu(cart, deliverableMenu) && !reorderCart) {
      if (!requestedDate || requestedDate.isSame(cart.deliveryDate, 'minute')) {
        // We got here by direct link but everything is the same - just check the cart is still valid
        return PublicActions.checkCartDeliverability();
      } else {
        return PublicActions.updateCart({ deliveryDate: requestedDate, postcode: null }); // Will trigger validation
      }
    }

    // We have arrived here by a direct link, but the content ID does not match the current cart
    // Create a new cart for the menu we have
    return PublicActions.createNewCart({
      deliveryDate: (reorderCart && reorderCart.deliveryDate) || requestedDate || getDefaultDeliveryDate(),
      postcode: normalisePostcode(reorderCart && reorderCart.postcode) || request.postcode || null,
      menuId: deliverableMenu.menuId,
      contentId: deliverableMenu.content.id,
      vendorId: deliverableMenu.vendor.id,
      vendorLocationId: deliverableMenu.vendorLocation?.id,
      currencyIsoCode: deliverableMenu.vendor.country.currency.code,
      reorderCart,
    });
  }

  private submitNewCart(
    canSubmitCart: boolean,
    hasProblems: boolean,
    canOverride: boolean,
    overrideErrors: boolean
  ): Observable<Action> {
    if (!canSubmitCart) {
      this.errorService.showAlert(
        'Cannot check out',
        'Please sign in as a customer to order!',
      );
      return EMPTY;
    }

    if (!hasProblems) {
      return of(RouterActions.goExternal({ url: '/checkout' }));
    }
    if (canOverride) {
      if (overrideErrors) {
        return of(RouterActions.goExternal({ url: '/checkout' }));
      } else {
        return of(PublicActions.showCartOverridesModal({ submitCartOnSuccess: true }));
      }
    }
    // It's now possible that the user attempted to submit a valid cart that became invalid after
    // an external deliverability check
    return EMPTY;
  }
}
