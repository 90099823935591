import { NgModule } from '@angular/core';
import { CleanWhitespaceOnValueChangeDirective } from './directives/clean-whitespace-on-value-change.directive';
import { RemainingCapacityPipe } from './pipes/remaining-capacity.pipe';
import { CapitalisePipe } from './pipes/capitalise.pipe';
import { ConcatPipe } from './pipes/concat.pipe';
import { OnOffPipe } from './pipes/on-off.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { PossessiveNameOrDefaultPipe } from './pipes/possessive-name-or-default.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  providers: [
    CapitalisePipe,
    ConcatPipe,
    OnOffPipe,
    PluralPipe,
    PossessiveNameOrDefaultPipe,
    TruncatePipe,
    RemainingCapacityPipe,
  ],
  declarations: [
    CapitalisePipe,
    ConcatPipe,
    OnOffPipe,
    PluralPipe,
    PossessiveNameOrDefaultPipe,
    TruncatePipe,
    RemainingCapacityPipe,
    CleanWhitespaceOnValueChangeDirective,
  ],
  exports: [
    CapitalisePipe,
    ConcatPipe,
    OnOffPipe,
    PluralPipe,
    PossessiveNameOrDefaultPipe,
    TruncatePipe,
    RemainingCapacityPipe,
    CleanWhitespaceOnValueChangeDirective,
  ],
})
export class TextComponentsModule {}
