/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type AnalyticsEcommerceAction =
  'click'
  | 'detail'
  | 'add'
  | 'remove'
  | 'checkout'
  | 'checkout_option'
  | 'purchase'
  | 'refund'
  | 'promo_click'
  | 'cartQuantityChange';

export class AnalyticsEcommerceActions {
  public static CLICK:           AnalyticsEcommerceAction & 'click'           = 'click';
  public static DETAIL:          AnalyticsEcommerceAction & 'detail'          = 'detail';
  public static ADD:             AnalyticsEcommerceAction & 'add'             = 'add';
  public static REMOVE:          AnalyticsEcommerceAction & 'remove'          = 'remove';
  public static CHECKOUT:        AnalyticsEcommerceAction & 'checkout'        = 'checkout';
  public static CHECKOUT_OPTION: AnalyticsEcommerceAction & 'checkout_option' = 'checkout_option';
  public static PURCHASE:        AnalyticsEcommerceAction & 'purchase'        = 'purchase';
  public static REFUND:          AnalyticsEcommerceAction & 'refund'          = 'refund';
  public static PROMO_CLICK:     AnalyticsEcommerceAction & 'promo_click'     = 'promo_click';

  /**
   * This value is converted into `add` or `remove` in the 'AnalyticsEnhancedEcommerceDirective' based on quantity changes.
   * 'cartQuantityChange' is not a natively supported action in GA Enhanced Ecommerce, so it should never be sent to GA.
   */
  public static CART_QUANTITY_CHANGE: AnalyticsEcommerceAction & 'cartQuantityChange' = 'cartQuantityChange';

  public static get values(): AnalyticsEcommerceAction[] {
    return [
      AnalyticsEcommerceActions.CLICK,
      AnalyticsEcommerceActions.DETAIL,
      AnalyticsEcommerceActions.ADD,
      AnalyticsEcommerceActions.REMOVE,
      AnalyticsEcommerceActions.CHECKOUT,
      AnalyticsEcommerceActions.CHECKOUT_OPTION,
      AnalyticsEcommerceActions.PURCHASE,
      AnalyticsEcommerceActions.REFUND,
      AnalyticsEcommerceActions.PROMO_CLICK,
      AnalyticsEcommerceActions.CART_QUANTITY_CHANGE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type AnalyticsEcommerceActionEnum = typeof AnalyticsEcommerceActions;
