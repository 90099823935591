import { ItemTypes } from '@citypantry/util-models';
import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const itemBundleFormPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/bundles/:id',
    path: 'item-bundle-editor-sidebar save-item-and-return',
    events: 'click',
    definition: ((event) => ({
      category: AnalyticsCategories.ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Item save and return clicked',
      extraFields: {
        itemType: ItemTypes.ITEM_BUNDLE,
        itemId: event.data['item-bundle-editor-sidebar'].itemId,
        isItemComplete: event.data['item-bundle-editor-sidebar'].isItemComplete,
      },
    })),
  },
  {
    url: '/menus/builder/bundles/:id',
    path: 'item-bundle-editor-sidebar save-item',
    events: 'click',
    definition: ((event) => ({
      category: AnalyticsCategories.ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Item save clicked',
      extraFields: {
        itemType: ItemTypes.ITEM_BUNDLE,
        itemId: event.data['item-bundle-editor-sidebar'].itemId,
        isItemComplete: event.data['item-bundle-editor-sidebar'].isItemComplete,
      },
    })),
  },
];
