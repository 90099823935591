import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const mealPlanSearchRules: AnalyticsRule[] = [
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'parameters-form postcode',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Postcode set'
    }
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Date set'
    }
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Time set'
    }
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'marketplace-search-popup',
    events: {
      class: 'SearchParametersPopupContentComponent',
      eventName: 'complete',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Submit search'
    }
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'search-query-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Search query entered',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'sort-by-select',
    events: 'change',
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `Sort by ${event.data['sort-by-select']}`,
    }),
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'headcount-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'No of people',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'max-budget-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Budget per person',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'occasions-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Occasion box toggled',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'occasions-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['occasions-checkbox']} occasion checked/unchecked`,
    }),
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'cuisine-types-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Type Of cuisine box toggled',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'cuisine-types-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['cuisine-types-checkbox']} cuisine type checked/unchecked`,
    }),
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'dietary-concerns-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Dietary Concerns box toggled',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'dietary-concerns-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['dietary-concerns-checkbox']} dietary requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'filters-form',
    events: {
      class: 'SearchFiltersFormComponent',
      eventName: 'changeWeWorkMode',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'WeWork Friendly vendors toggle',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/search',
    path: 'search-result link',
    events: 'click',
    definition: (event) => {
      if (event.data['search-result']['isTopPick']) {
        return [{
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Top Pick Result'
        }, {
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Vendor'
        }];
      } else {
        return {
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Vendor'
        };
      }
    }
  },
];
