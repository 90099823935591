/// <reference types="google.maps" />

import { CommonModule, DOCUMENT } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';
import { GoogleMaps } from './google-maps';
import { GoogleMapsApis } from './google-maps-apis';
import { GoogleMapsAttributionDirective } from './google-maps-attribution.directive';
import { GoogleMapsAutocomplete } from './google-maps-autocomplete.service';
import { GoogleMapsConfig } from './google-maps-config';
import { GoogleMapsPlaces } from './google-maps-places.service';

@NgModule({
  declarations: [
    GoogleMapsAttributionDirective,
  ],
  providers: [
    GoogleMapsApis,
    GoogleMapsAutocomplete,
    GoogleMapsPlaces,
  ],
  exports: [
    GoogleMapsAttributionDirective,
  ],
  imports: [
    CommonModule,
  ]
})
export class GoogleMapsModule {
  public static libraryLoadInitiated = false;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private config: GoogleMapsConfig,
    private mapsLoader: GoogleMaps,
    private windowRef: WindowRef,
  ) {
    if (!GoogleMapsModule.libraryLoadInitiated) {
      this.loadLibrary();
      GoogleMapsModule.libraryLoadInitiated = true;
    }
  }

  public static forRoot(config: GoogleMapsConfig): ModuleWithProviders<GoogleMapsModule> {
    return {
      ngModule: GoogleMapsModule,
      providers: [
        {
          provide: GoogleMapsConfig,
          useValue: config,
        },
      ]
    };
  }

  public loadLibrary(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.config.key}&libraries=places&&callback=__google_maps_library_loaded`;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);

    this.windowRef.nativeWindow.__google_maps_library_loaded = () => {
      this.mapsLoader.onLibraryLoaded();
    };
  }
}
