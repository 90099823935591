import { createCurrencyFromJson, Currency } from './money';
import { VatRateType } from './payment';

export interface Country {
  id: string;
  country: string;
  iso3Code: string;
  callingCode: string;
  languages: string[];
  currency: Currency;
  distanceUnit: string;
  allowedVatRateTypes: VatRateType[];
  active: boolean;
}

export function createCountryFromJson(json?: Partial<Country>): Country {
  const country: Partial<Country> = {};

  country.id = json.id;
  country.country = json.country;
  country.iso3Code = json.iso3Code;
  country.callingCode = json.callingCode;
  country.languages = json.languages;
  country.currency = createCurrencyFromJson(json.currency);
  country.distanceUnit = json.distanceUnit;
  country.allowedVatRateTypes = json.allowedVatRateTypes;
  country.active = json.active;

  return country as Country;
}
