<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/search'], label: 'Search' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngIf="canAccessOnDemand">
    <app-header-dropdown
      [triggerLabel]="'Meals'"
      test-id="mealsDropdown"
    >
      <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/individual-ordering'], label: 'Individual Ordering', isSecondary: true }"></ng-container>
      <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/my-meals'], label: 'Group Ordering', isSecondary: true }"></ng-container>
    </app-header-dropdown>
  </ng-container>
  <ng-container *ngIf="!canAccessOnDemand">
    <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/my-meals'], label: 'Meals' }"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/orders', label: 'Orders' }"></ng-container>
  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/admin/invoices'], label: 'Invoices' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/customers', label: 'Customers', e2eTestId: 'customersAdminLink' }"></ng-container>
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/vendors', label: 'Restaurants' }"></ng-container>
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/users', label: 'Users' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/teams-at-home', label: 'TEAMS@HOME', analyticsId: 'teams-at-home' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <app-header-dropdown
    [triggerLabel]="'More'"
    test-id="moreDropdown"
  >
    <!-- MA & Sales teams items -->
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/meal-plan', label: 'Meal plan', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/promo-codes', label: 'Promo codes', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/pop-ups', label: 'Pop-ups', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/blog', label: 'Blog', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/feedback', label: 'Feedback', isSecondary: true }"></ng-container>

    <!-- Vendors team items -->
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/recommended-vendors', label: 'Recommended Vendors', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/builder/menus/approve'], label: 'Approve Menus', isSecondary: true }"></ng-container>

    <!-- Ops/CX team items -->
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://app.frontapp.com', label: 'Driver SMS', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/sms/mailmerge', label: 'SMS Mailmerge', isSecondary: true }"></ng-container>

    <!-- Dev team items -->
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/admin/global-id-lookup', label: 'Global ID lookup', isSecondary: true }"></ng-container>

    <!-- all Staff users -->
    <ng-container *ngTemplateOutlet="authenticatedUserLinksTemplate"></ng-container>
  </app-header-dropdown>
</ul>
