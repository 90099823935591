import { ensureValidEnumValue } from '@citypantry/util';
import moment, { Moment } from 'moment';
import { CustomerId } from '../customer';
import { MajorCurrency } from '../money';
import { OrderId } from '../order';
import { SingleInvoiceStatus, SingleInvoiceStatuses } from './single-invoice-status.enum';

declare const SingleInvoiceIdSymbol: unique symbol;
export type SingleInvoiceId = string & {
  [SingleInvoiceIdSymbol]: never;
};

export interface SingleInvoice {
  id: SingleInvoiceId;
  number: string;
  dateIssued: Moment | null;
  headcount: number;
  htmlFileSystemUrl: string | null;
  pdfFileSystemUrl: string | null;
  isOverdue: boolean | null;
  status: SingleInvoiceStatus;

  order: {
    customerId: CustomerId;
    customerCompanyName: string;
    date: Moment | null;
    humanId: string;
    id: OrderId;
    isPaidOnAccount: boolean;
  };
  totalAmountAfterPromoCode: MajorCurrency;
}

interface SingleInvoiceJsonProperties {
  pdfFileSystemUrl: string | null;
}

export function createSingleInvoiceFromJson(json: Partial<SingleInvoice & SingleInvoiceJsonProperties> = {}): SingleInvoice {
  const invoice: Partial<SingleInvoice> = {};

  invoice.id = json.id;
  invoice.number = json.number;
  invoice.dateIssued = json.dateIssued ? moment(json.dateIssued) : null;
  invoice.headcount = json.headcount;
  invoice.htmlFileSystemUrl = json.htmlFileSystemUrl || null;
  invoice.pdfFileSystemUrl = json.pdfFileSystemUrl || null;
  invoice.isOverdue = json.isOverdue;
  invoice.status = ensureValidEnumValue(SingleInvoiceStatuses, json.status);

  invoice.order = {
    customerId: json.order.customerId,
    customerCompanyName: json.order.customerCompanyName,
    date: json.order.date ? moment(json.order.date) : null,
    humanId: json.order.humanId,
    id: json.order.id,
    isPaidOnAccount: json.order.isPaidOnAccount,
  };
  invoice.totalAmountAfterPromoCode = json.totalAmountAfterPromoCode;

  return invoice as SingleInvoice;
}
