import { CartDeliverabilityProblemTypes } from './cart-deliverability-problem-type.enum';
import { CartDeliverabilityProblem, createCartDeliverabilityProblemFromJson } from './cart-deliverability-problem.model';

export const EMPTY_CART_DELIVERABILITY: CartDeliverability = {
  problems: []
};

Object.freeze(EMPTY_CART_DELIVERABILITY);

export interface CartDeliverability {
  problems: CartDeliverabilityProblem[];
}

export function createCartDeliverabilityFromJson(json: Partial<CartDeliverability>): CartDeliverability {
  return {
    problems: (json.problems || []).map(createCartDeliverabilityProblemFromJson)
      .sort((a, b) => CartDeliverabilityProblemTypes.order.indexOf(a.type) - CartDeliverabilityProblemTypes.order.indexOf(b.type))
  };
}
