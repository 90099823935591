import { Pipe, PipeTransform } from '@angular/core';
import { UseCaseQualificationType, UseCaseQualificationTypes } from '@citypantry/util-models';

@Pipe({
  name: 'useCaseQualification'
})
export class UseCaseQualificationTypePipe implements PipeTransform {
  private static descriptors: { [key: string]: string } = {
    [UseCaseQualificationTypes.BREAKFAST]: 'Breakfast',
    [UseCaseQualificationTypes.TEAM_LUNCHES]: 'Team Lunches',
    [UseCaseQualificationTypes.EVENTS]: 'Events',
    [UseCaseQualificationTypes.MEETINGS_AND_WORKSHOPS]: 'Meetings & Workshops',
    [UseCaseQualificationTypes.HOLIDAYS_AND_CELEBRATIONS]: 'Holidays & Celebrations',
  };

  public transform(value: UseCaseQualificationType | null): string {
    if (!value) {
      return null;
    }

    return UseCaseQualificationTypePipe.descriptors[value];
  }
}
