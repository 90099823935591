import moment, { Moment } from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

declare const IsoDateSymbol: unique symbol;

export type IsoDate = string & {
  [IsoDateSymbol]: never;
};

export function isIsoDate(value: string): value is IsoDate {
  return value && !!value.match(/\d{4}-\d{2}-\d{2}/);
}

export namespace IsoDate {
  export function fromString(stringDate: string): IsoDate {
    if (!isIsoDate(stringDate)) {
      throw new TypeError();
    }

    return stringDate;
  }

  export function fromMoment(momentDate: Moment): IsoDate {
    return IsoDate.fromString(momentDate.format(ISO_DATE_FORMAT));
  }

  export function toMoment(isoDate: IsoDate): Moment {
    return moment(isoDate, ISO_DATE_FORMAT);
  }
}
