import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsEvent } from '../analytics-event.interface';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const promotionPanelRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'promotion-panel in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.PROMOTION_PANEL,
      label: `${event.data['promotion-panel'].name} Promotion Panel viewed`,
    })
  },
  {
    url: '*',
    path: 'promotion-panel cta',
    events: 'click',
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.PROMOTION_PANEL,
      label: `${event.data['promotion-panel'].name} Promotion Panel CTA clicked`,
    })
  }
];
