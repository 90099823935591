import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { numberOfVisibleDietariesSet, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-choice-group-item',
  templateUrl: './choice-group-item.component.html'
})
export class ChoiceGroupItemComponent implements OnInit {
  @Input()
  public item: SingleItem;

  @Input()
  public showMoreInfo: boolean;

  @Input()
  public control: FormControl;

  @Input()
  public set limitReached(reached: boolean) {
    if (this.control) {
      if (reached && !this.control.value) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this._limitReached = reached;
    }
  }

  @Output()
  public toggleMoreInfo: EventEmitter<void> = new EventEmitter();

  private _limitReached: boolean;

  public ngOnInit(): void {
    if (this.control) {
      if (this._limitReached && !this.control.value) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  public toggleValue(): void {
    if (this.control.enabled) {
      this.control.setValue(!this.control.value);
    }
  }

  public onToggleShowMoreInfo(): void {
    this.toggleMoreInfo.emit();
  }

  public hasDietaries(): boolean {
    return this.item && numberOfVisibleDietariesSet(this.item.dietaries) > 0;
  }

  public shouldShowKcals(): boolean {
    return this.item.kcal !== null && this.item.kcal >= 0;
  }
}
