import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TypedSimpleChanges } from '@citypantry/util';
import { DeliveryContact, OrderStatuses, OrderTracking, toArray, TrackedLocation } from '@citypantry/util-models';
import moment from 'moment';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
})
export class DeliveryDetailsComponent implements OnChanges {

  @Input()
  public orderTracking: OrderTracking;

  @Input()
  public allowDateEditing?: boolean = false;

  @Input()
  public isPantryPackageOrder?: boolean = false;

  @Input()
  public isLoading?: boolean = false;

  @Input()
  public deliveryContacts?: DeliveryContact[];

  @Input()
  public isCustomerOrStaffOrSudo: boolean;

  @Output()
  public editDate: EventEmitter<void> = new EventEmitter();

  @Output()
  public changeDeliveryContact: EventEmitter<void> = new EventEmitter();

  @Output()
  public addDeliveryContact: EventEmitter<void> = new EventEmitter();

  @Output()
  public updateDeliveryContact: EventEmitter<string> = new EventEmitter();

  public isEditingDeliveryContact: boolean = false;
  public toArray: (location: TrackedLocation) => string[] = toArray;

  public selectedContactId: string;

  public ngOnChanges(changes: TypedSimpleChanges<DeliveryDetailsComponent>): void {
    if (this.isEditingDeliveryContact) {
      if (changes.deliveryContacts || changes.orderTracking) {
        const currentContact = this.getCurrentContactFromList();
        this.selectedContactId = currentContact ? currentContact.id : this.deliveryContacts[0].id;
      }

      if (changes.orderTracking) {
        this.isEditingDeliveryContact = false;
      }
    }
  }

  public editDateClicked(): void {
    this.editDate.emit();
  }

  public editDeliveryContactClicked(): void {
    this.changeDeliveryContact.emit();
    this.isEditingDeliveryContact = true;
  }

  public setSelectedContact(event: Event): void {
    this.selectedContactId = (event.target as HTMLSelectElement).value;
  }

  public addContact(): void {
    this.addDeliveryContact.emit();
  }

  public updateContact(): void {
    const currentContactFromList = this.getCurrentContactFromList();

    if (currentContactFromList && this.selectedContactId === currentContactFromList.id) {
      this.isEditingDeliveryContact = false;
    } else {
      const selectedContact = this.deliveryContacts.find((contact) => contact.id === this.selectedContactId);
      this.updateDeliveryContact.emit(selectedContact.id);
    }
  }

  public canEditDeliveryContact(): boolean {
    return this.notCancelledOrDelivered() && this.notFinalised() && this.isCustomerOrStaffOrSudo;
  }

  public canEditDate(): boolean {
    return this.notCancelledOrDelivered() && this.notFinalised() && this.isCustomerOrStaffOrSudo && this.allowDateEditing === true;
  }

  private notCancelledOrDelivered(): boolean {
    return !(this.orderTracking.status === OrderStatuses.DELIVERED || this.orderTracking.status === OrderStatuses.CANCELLED);
  }

  private notFinalised(): boolean {
    return moment() < this.orderTracking.finalisationDate;
  }

  private getCurrentContactFromList(): DeliveryContact | null {
    // necessary as we only have the contact's name + phone number in the OrderTracking object, not its id
    let res;
    if (this.deliveryContacts) {
      res = this.deliveryContacts.find((contact) => {
        return contact.fullName === this.orderTracking.deliveryContactFullName &&
        contact.phoneNumber === this.orderTracking.deliveryContactPhoneNumber;
      });
    }

    return res || null;
  }
}
