/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type PromotionComponent =
  'BANNER'
  | 'SEARCH_PROMO_TILE'
  | 'RECOMMENDATION_TILE'
  | 'POP_UP_DIALOG';

export class PromotionComponents {
  public static BANNER:              PromotionComponent & 'BANNER'              = 'BANNER';
  public static SEARCH_PROMO_TILE:   PromotionComponent & 'SEARCH_PROMO_TILE'   = 'SEARCH_PROMO_TILE';
  public static RECOMMENDATION_TILE: PromotionComponent & 'RECOMMENDATION_TILE' = 'RECOMMENDATION_TILE';
  public static POP_UP_DIALOG:       PromotionComponent & 'POP_UP_DIALOG'       = 'POP_UP_DIALOG';

  public static get values(): PromotionComponent[] {
    return [
      PromotionComponents.BANNER,
      PromotionComponents.SEARCH_PROMO_TILE,
      PromotionComponents.RECOMMENDATION_TILE,
      PromotionComponents.POP_UP_DIALOG,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type PromotionComponentEnum = typeof PromotionComponents;
