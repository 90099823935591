import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { HeaderType } from '@citypantry/shared-auth';
import { TypedSimpleChanges } from '@citypantry/util';
import { CustomerId, MealPlan, OrderId, UserId, VendorId } from '@citypantry/util-models';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  LostbarCustomer,
  LostbarCustomerUser,
  LostbarOrder, LostbarResultGroupType, LostbarSearchResult,
  LostbarUser,
  LostbarVendor,
  LostbarVendorUser
} from './state/lostbar-search-result.model';

export const LOSTBAR_SHOWING_CLASS = 'lostbar-showing';

@Component({
  selector: 'app-lostbar',
  templateUrl: './lostbar.component.html',
  styleUrls: ['./lostbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostbarComponent implements AfterViewInit, OnChanges {
  @Input()
  public isLoading: boolean;

  @Input()
  public isFixed: boolean;

  @Input()
  public headerType: HeaderType;

  @Input()
  public name: string;

  @Input()
  public companyName: string | null;

  @Input()
  public isSudo: boolean;

  @Input()
  public set searchResults(value: LostbarSearchResult[]) {
    this._searchResults = value;
    this.highlightedResultIndex = 0;
  }
  public get searchResults(): LostbarSearchResult[] {
    return this._searchResults;
  }

  @Output()
  public search: EventEmitter<string> = new EventEmitter();

  @Output()
  public unmasquerade: EventEmitter<void> = new EventEmitter();

  @Output()
  public masquerade: EventEmitter<UserId> = new EventEmitter();

  @Output()
  public viewMealplanAsCustomer: EventEmitter<{ userId: UserId, mealPlanId: MealPlan['id'] }> = new EventEmitter();

  @Output()
  public editMealplan: EventEmitter<MealPlan['id']> = new EventEmitter();

  @Output()
  public editCustomer: EventEmitter<CustomerId> = new EventEmitter();

  @Output()
  public editVendor: EventEmitter<VendorId> = new EventEmitter();

  @Output()
  public editOrder: EventEmitter<OrderId> = new EventEmitter();

  @ViewChild('lostbar', { static: false })
  public lostbar: ElementRef;

  public query: string;
  public isDropdownOpen: boolean;
  public highlightedResultIndex: number;
  public searchQueryChanged: Subject<string> = new Subject<string>();

  public LostbarResultGroupTypeEnum: typeof LostbarResultGroupType = LostbarResultGroupType;
  public HeaderTypeEnum: typeof HeaderType = HeaderType;

  private _searchResults: LostbarSearchResult[];

  constructor(
    private container: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.searchQueryChanged.pipe(
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe((query: string) => {
      this.search.emit(query);
    });
  }

  public ngOnChanges(changes: TypedSimpleChanges<LostbarComponent>): void {
    if (changes.searchResults && this.searchResults && this.searchResults.length) {
      this.isDropdownOpen = true;
    }

    setTimeout(() => {
      this.recalculateHeight();
    });
  }

  public ngAfterViewInit(): void {
    this.recalculateHeight();
  }

  public onQueryChange(newQuery: string): void {
    this.query = newQuery;
    this.searchQueryChanged.next(newQuery);
  }

  public onUnmasquerade(): void {
    this.unmasquerade.emit();
    this.closeDropdown();
  }

  public onMasquerade(userId: UserId): void {
    this.masquerade.emit(userId);
    this.closeDropdown();
  }

  public onViewMealplanAsCustomer(userId: UserId, mealPlanId: MealPlan['id']): void {
    this.viewMealplanAsCustomer.emit({ userId, mealPlanId });
    this.closeDropdown();
  }

  public onEditMealplan(mealPlanId: MealPlan['id']): void {
    this.editMealplan.emit(mealPlanId);
    this.closeDropdown();
  }

  public onEditCustomer(customerId: CustomerId): void {
    this.editCustomer.emit(customerId);
    this.closeDropdown();
  }

  public onEditVendor(vendorId: VendorId): void {
    this.editVendor.emit(vendorId);
    this.closeDropdown();
  }

  public onEditOrder(orderId: OrderId): void {
    this.editOrder.emit(orderId);
    this.closeDropdown();
  }

  public closeDropdown(): void {
    this.isDropdownOpen = false;
  }

  public onInputKeyDown(event: KeyboardEvent) {
    // Handle up/down arrow navigation
    // (Note: for a long keypress, the browser is expected to fire multiple keydown events with `repeat: true` property)
    if (event.key === 'ArrowUp') {
      if (this.highlightedResultIndex === 0) {
        this.highlightedResultIndex = this.searchResults.length - 1;
      } else {
        this.highlightedResultIndex = Math.max(0, this.highlightedResultIndex - 1);
      }
    }
    if (event.key === 'ArrowDown') {
      if (this.highlightedResultIndex === this.searchResults.length - 1) {
        this.highlightedResultIndex = 0;
      } else {
        this.highlightedResultIndex = Math.min(this.searchResults.length - 1, this.highlightedResultIndex + 1);
      }
    }
  }

  public onInputKeyUp(event: KeyboardEvent) {
    // Do the default action on Enter press
    if (event.key === 'Enter') {
      const highlightedResult = this.searchResults[this.highlightedResultIndex];

      if (highlightedResult) {
        // eslint-disable-next-line default-case
        switch (highlightedResult.group) {
          case LostbarResultGroupType.ORDERS: {
            this.onEditOrder((highlightedResult as LostbarOrder)._id);
            break;
          }
          case LostbarResultGroupType.CUSTOMERS:
          case LostbarResultGroupType.VENDORS: {
            this.onMasquerade((highlightedResult as (LostbarCustomer | LostbarVendor)).user._id);
            break;
          }
          case LostbarResultGroupType.MEMBERS:
          case LostbarResultGroupType.VENDOR_USERS:
          case LostbarResultGroupType.USERS: {
            this.onMasquerade((highlightedResult as (LostbarCustomerUser | LostbarVendorUser | LostbarUser))._id);
            break;
          }
          // no MealPlan support - MP is deprecated
        }
      }
    }
  }

  public onResultMouseover(index: number): void {
    this.highlightedResultIndex = index;
  }

  private recalculateHeight(): void {
    const height = this.lostbar && this.lostbar.nativeElement.offsetHeight || 0;

    if (this.container && this.container.nativeElement) {
      Object.assign(this.container.nativeElement.style, {
        height: `${height}px`
      });
    }

    if (height > 0) {
      this.renderer.addClass(this.document.documentElement, LOSTBAR_SHOWING_CLASS);
    } else {
      this.renderer.removeClass(this.document.documentElement, LOSTBAR_SHOWING_CLASS);
    }
  }
}

