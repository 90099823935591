import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result, mapmap } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  ErrorMessage,
  ErrorResponse,

  ItemLabelServerSideAnalyticsEvent,

  OrderId,

  Vendor,
  VendorId,
  VendorLocation,
  VendorLocationId,
  VendorLocationPayload,
  createVendorFromJson,
  createVendorLocationFromJson
} from '@citypantry/util-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendorApi {

  constructor(
    private http: HttpClient
  ) {}

  public getVendorLocations(vendorId: VendorId | 'me'): Observable<VendorLocation[]> {
    return this.http.get(`/vendor/${vendorId}/addresses`).pipe(
      mapmap(createVendorLocationFromJson)
    );
  }

  // If you are adding more /staff endpoints, consider creating a dedicated Staff API class (e.g. StaffVendorApi).
  public getVendorAsStaff(vendorId: VendorId | 'me'): Observable<Vendor> {
    return this.http.get(`/staff/vendors/${vendorId}`).pipe(
      map(createVendorFromJson)
    );
  }

  public setDefaultLocationAsStaff(vendorId: VendorId, locationId: VendorLocationId): Observable<Result<void>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.post<boolean>(
      `/staff/vendors/${vendorId}/set-default-location`,
      { location: locationId },
      { headers },
    ).pipe(
      mapTo(Result.success()),
      catchError(() => of(Result.failure('Unable to set vendor default location'))),
    );
  }

  public updateVendorLocation(locationId: string, location: VendorLocationPayload): Observable<Result<VendorLocation, ErrorMessage>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.put(`/addresses/${locationId}`, location, { headers }).pipe(
      map(createVendorLocationFromJson),
      map((vendorLocation: VendorLocation) => Result.success(vendorLocation) ),
      catchError((error: any) => {
        if (error instanceof ErrorResponse) {
          return of(Result.failure(error.messages[0]));
        } else {
          return throwError(error);
        }
      })
    );
  }

  public deleteVendorLocation(locationId: string): Observable<Result<void, string>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.delete(`/addresses/${locationId}`, { headers }).pipe(
      mapTo({ success: true as const }),
      catchError((error) =>  error instanceof HttpErrorResponse && error.error && error.error.errorTranslation
        ? of({ success: false as const, error: error.error.errorTranslation })
        : throwError(error)
      ),
    );
  }

  public recordLabelPrintEvent(
    eventName: ItemLabelServerSideAnalyticsEvent,
    orderId: OrderId,
  ): Observable<void> {
    return this.http.put(`/vendor/orders/${orderId}/print-event`, {
      eventName,
    }, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(mapTo(undefined));
  }
}
