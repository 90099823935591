import { compareInOrderOfPreference } from '@citypantry/util';
import moment from 'moment';
import { EaterMeal, EaterMealOption } from './eater-meal.model';

// TODO CPD-11616 this should be in feature-my-meals but some selectors in the state depend on it

export interface MyMeal {
  meal: EaterMeal;
  isChoiceOpenTimeInTheFuture: boolean;
  isDeadlineSoon: boolean;
  isDeadlinePassed: boolean;
  isCancellable: boolean;
  isConfirmed: boolean; // represents whether the eater has made a choice and paid successfully (if applicable)
  isMultiVendor: boolean;
  isSubsidisedChoice: boolean;
}

export interface MyMeals {
  months: {
    firstDeliveryDate: moment.Moment; // first delivery date inside this month
    weeks: {
      firstDeliveryDate: moment.Moment; // first delivery date inside this week
      days: {
        firstDeliveryDate: moment.Moment; // first delivery date inside this day
        meals: MyMeal[];
      }[];
    }[];
  }[];
}

function mapEaterMealsToMyMeals(eaterMeals: EaterMeal[], now: moment.Moment, sortDescending?: boolean): MyMeals {
  const meals: MyMeals = {
    months: [],
  };

  if (!eaterMeals.length) {
    return meals;
  }

  let sortedMeals: EaterMeal[];
  if (sortDescending) {
    sortedMeals = eaterMeals.slice().sort(
      (left: EaterMeal, right: EaterMeal) =>
        right.requestedDeliveryDate.unix() - left.requestedDeliveryDate.unix()
    );
  } else {
    sortedMeals = eaterMeals.slice().sort(
      (left: EaterMeal, right: EaterMeal) =>
        left.requestedDeliveryDate.unix() - right.requestedDeliveryDate.unix()
    );
  }

  const firstMeal = sortedMeals[0];
  let currentYear = firstMeal.requestedDeliveryDate.year();
  let currentMonth = firstMeal.requestedDeliveryDate.month();
  let currentWeekOfYear = firstMeal.requestedDeliveryDate.week();
  let currentDayOfYear = firstMeal.requestedDeliveryDate.dayOfYear();

  let monthIndex = 0;
  let weekIndex = 0;
  let dayIndex = 0;

  for (const meal of sortedMeals) {
    const year = meal.requestedDeliveryDate.year();
    const month = meal.requestedDeliveryDate.month();
    const weekOfYear = meal.requestedDeliveryDate.week();
    const dayOfYear = meal.requestedDeliveryDate.dayOfYear();

    if (dayOfYear !== currentDayOfYear || year !== currentYear) {
      currentDayOfYear = dayOfYear;
      dayIndex++;
    }

    if (weekOfYear !== currentWeekOfYear || year !== currentYear) {
      currentWeekOfYear = weekOfYear;
      weekIndex++;
      dayIndex = 0;
    }

    if (month !== currentMonth || year !== currentYear) {
      currentMonth = month;
      monthIndex++;
      dayIndex = 0;
      weekIndex = 0;
    }

    if (year !== currentYear) {
      currentYear = year;
      weekIndex = 0;
      dayIndex = 0;
    }

    meals.months[monthIndex] = meals.months[monthIndex] || {
      firstDeliveryDate: meal.requestedDeliveryDate,
      weeks: [],
    };
    meals.months[monthIndex].weeks[weekIndex] = meals.months[monthIndex].weeks[weekIndex] || {
      firstDeliveryDate: meal.requestedDeliveryDate,
      days: [],
    };
    meals.months[monthIndex].weeks[weekIndex].days[dayIndex] = meals.months[monthIndex].weeks[weekIndex].days[dayIndex] || {
      firstDeliveryDate: meal.requestedDeliveryDate,
      meals: [],
    };

    meals.months[monthIndex].weeks[weekIndex].days[dayIndex].meals.push(mapEaterMealToMyMeal(meal, now));
  }

  return meals;
}

/**
 * @returns `meal.eaterOptions` sorted in following order:
 *
 * * confirmed
 * * pending topup confirmation
 * * not chosen
 * * * available
 * * * almost sold out
 * * * sold out
 */
function sortMealOptionsInEaterMeal(meal: EaterMeal): EaterMealOption[] {
  return meal.eaterOptions.slice().sort((a, b) => {
    const comparison = compareInOrderOfPreference(a, b, [
      EaterMealOption.isConfirmed,
      EaterMealOption.isPendingTopupConfirmation,
      EaterMealOption.isAvailable,
      EaterMealOption.isAlmostSoldOut,
      EaterMealOption.isSoldOut
    ]);

    return comparison || 1;
  });
}

function mapEaterMealToMyMeal(meal: EaterMeal, now: moment.Moment): MyMeal {
  const isBeforeChoiceOpenTime = meal.choiceOpenTime ? meal.choiceOpenTime.isAfter(now) : false;
  const isDeadlinePassed = meal.choiceDeadline.isBefore(now);
  // Soon qualifies as the deadline being before 24 hours from now
  const isDeadlineSoon = meal.choiceDeadline.isBefore(now.clone().add(1, 'day'));
  const isConfirmed = meal.eaterOptions.some(EaterMealOption.isConfirmed);
  const isCancellable = false; // TODO CPD-2398 cancel meal
  const isMultiVendor = meal.eaterOptions.length > 1;
  const isSubsidisedChoice = !!meal.isSubsidisedChoice;

  return {
    meal: {
      ...meal,
      eaterOptions: sortMealOptionsInEaterMeal(meal)
    },
    isCancellable,
    isChoiceOpenTimeInTheFuture: isBeforeChoiceOpenTime,
    isDeadlinePassed,
    isDeadlineSoon,
    isConfirmed,
    isMultiVendor,
    isSubsidisedChoice,
  };
}

export const MyMealsUtils = {
  mapEaterMealToMyMeal,
  mapEaterMealsToMyMeals,
};
