import { MinorCurrency } from './minor-major-currency.model';

export interface Money {
  amount: MinorCurrency;

  // this field is never going to have a value for now,
  // but creating and typing it already gives us an opportunity to start referencing the field name
  // in places we expect it to be needed in the future
  currencyCode?: never;
}

export function createMoneyFromJson(json: Partial<Money> = {}): Money {
  return {
    amount: json.amount || 0,
  };
}
