import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Image, ItemType } from '@citypantry/util-models';

@Component({
  selector: 'app-menu-item-image',
  template: `
    <div
      class="item-panel__image"
      analyticsId="menu-item-image"
      [analyticsData]="{ imageCount: count, itemType: itemType }"
      [backgroundImage]="images[0].medium"
      tabindex="0"
      [attr.aria-label]="'Click to see ' + count + ' ' + ('image' | plural:count) + ' for this item'"
      test-id="image"
    >
      <span class="item-panel__image-overlay"></span>
      <span
        class="item-panel__image-count"
        [class.item-panel__image-count--visible]="count > 1"
        test-id="count"
      >{{ count }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemImageComponent {
  @Input()
  public images: Image[];

  public get count(): number {
    return this.images.length;
  }

  @Input()
  public itemType: ItemType;
}
