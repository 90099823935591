import { CustomerLocation, SimpleUser } from '@citypantry/util-models';
import { AccountAction, AccountActions } from './account.actions';

export interface AccountState {
  locationsWithEaters: {
    locations: CustomerLocation[];
    isLoading: boolean;
  } | null; // null if never initialised
  activeLocationId: string | null; // null = no location selected (possibly none loaded)
  eatersAtActiveLocation: {
    eaters: SimpleUser[];
    isLoading: boolean;
  };
  hasCopiedShareUrl: boolean;
}

export const initialState: AccountState = {
  locationsWithEaters: null,
  activeLocationId: null,
  eatersAtActiveLocation: {
    isLoading: false, // Since activeLocationId is null we are not in a pending state
    eaters: [],
  },
  hasCopiedShareUrl: false,
};

export function reducer(state: AccountState = initialState, action: AccountAction): AccountState {
  switch (action.type) {
    case AccountActions.fetchLocationsWithEaters.type: {
      return {
        ...state,
        locationsWithEaters: {
          locations: [],
          ...state.locationsWithEaters,
          isLoading: true
        }
      };
    }

    case AccountActions.locationsWithEatersFetched.type: {
      const locations = action.locations;

      return {
        ...state,
        locationsWithEaters: {
          locations,
          isLoading: false
        }
      };
    }

    case AccountActions.fetchEatersAtLocation.type: {
      const locationId = action.locationId;

      return {
        ...state,
        activeLocationId: locationId,
        eatersAtActiveLocation: {
          eaters: [],
          isLoading: true
        },
        hasCopiedShareUrl: false
      };
    }

    case AccountActions.refreshEatersAtLocation.type: {
      const locationId = action.locationId;

      return {
        ...state,
        activeLocationId: locationId,
        hasCopiedShareUrl: false
      };
    }

    case AccountActions.eatersAtLocationFetched.type: {
      const eaters = action.eaters;

      return {
        ...state,
        eatersAtActiveLocation: {
          eaters,
          isLoading: false
        }
      };
    }

    case AccountActions.shareLocationUrlCopied.type: {
      return {
        ...state,
        hasCopiedShareUrl: true
      };
    }

    default:
      return state;
  }
}
