<ul
  class="list list--thick-solid-line list--tall-height pb-x-large"
>
  <li class="row list__item no-gutters align-items-center pb-x-small">
    <div class="col">
      <h3
        class="mb-none sub-header--bold"
        [class.text-color-red-1]="shouldShowErrors() && sectionForm.invalid"
      >
        {{ section.name }}
      </h3>
    </div>
    <div
      class="col text-right text-color-stone-2 text-weight-medium"
      [class.text-color-red-1]="shouldShowErrors() && sectionForm.invalid"
      test-id="sectionSelectionCount"
    >
      <ng-container *ngIf="section.minOptions === section.maxOptions && section.minOptions > 0 && section.maxOptions > 0">
        {{ getSelectedOptionsCount() }} / {{ section.maxOptions }} selected
      </ng-container>
      <ng-container *ngIf="section.minOptions !== section.maxOptions && section.minOptions > 0 && section.maxOptions > 0">
        {{ getSelectedOptionsCount() }} / {{ section.maxOptions }} selected (min. {{ section.minOptions }} options)
      </ng-container>
      <ng-container *ngIf="section.minOptions !== section.maxOptions && section.minOptions > 0 && !section.maxOptions">
        {{ getSelectedOptionsCount() }} selected (min. {{ section.minOptions }} options)
      </ng-container>
      <ng-container *ngIf="section.minOptions !== section.maxOptions && section.minOptions === 0 && section.maxOptions > 0">
        {{ getSelectedOptionsCount() }} selected (max. {{ section.maxOptions }} options)
      </ng-container>
    </div>
  </li>
  <li
    *ngFor="let option of section.options; let index = index;"
    class="list__item custom-item-modal-form-section-layout__option"
    [formGroup]="getOptionsForm(index)"
    test-id="option"
  >
    <div class="custom-item-modal-form-section-layout__option-info">
      <div
        class="custom-item-modal-form-section-layout__option-name"
        test-id="optionName"
      >
        <span [title]="option.name">{{ option.name }}</span>
      </div>
      <div
        *ngIf="option.ageRestricted || hasDietaries(option) || (option.kcal === 0 || option.kcal >= 0)"
        class="custom-item-modal-form-section-layout__option-meta"
      >
        <span
          *ngIf="option.ageRestricted"
          class="icon icon--medium"
          aria-label="Age restricted"
          test-id="optionAgeRestricted"
        >age_restricted</span>
        <app-dietaries
          *ngIf="hasDietaries(option)"
          [dietaries]="option.dietaries"
          test-id="optionDietaries"
        ></app-dietaries>
        <div
          *ngIf="option.kcal === 0 || option.kcal > 0"
          class="custom-item-modal-form-section-layout__option-kcal text-color-stone-2 text-nowrap"
          test-id="optionKcal"
        >{{ option.kcal }}kcal</div>
      </div>
      <div
        *ngIf="option.price && !hidePrices"
        class="custom-item-modal-form-section-layout__option-price text-color-stone-2 text-nowrap"
        test-id="optionPrice"
      >+ {{ option.price | currency:'GBP' }}</div>
    </div>
    <div class="custom-item-modal-form-section-layout__option-action">
      <app-quantity-picker
        *ngIf="isQuantityOption(); else toggle"
        [max]="option.maxQuantity"
        formControlName="quantity"
        test-id="optionQuantityPicker"
      ></app-quantity-picker>
      <ng-template #toggle>
        <label class="form-choice form-choice--large">
          <input
            type="checkbox"
            class="form-choice__input"
            (change)="onToggleOption(option, $event.target.checked)"
            formControlName="quantity"
            name="quantity"
            test-id="optionToggle"
          >
          <span class="form-choice__label body-text custom-item-modal-form-section-layout__checkbox-label"></span>
        </label>
      </ng-template>
    </div>
  </li>
</ul>
