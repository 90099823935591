/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type SearchOrderType = 'individual-choice' | 'marketplace';

export class SearchOrderTypes {
  public static INDIVIDUAL_CHOICE: SearchOrderType & 'individual-choice' = 'individual-choice';
  public static MARKETPLACE:       SearchOrderType & 'marketplace'       = 'marketplace';

  public static get values(): SearchOrderType[] {
    return [
      SearchOrderTypes.INDIVIDUAL_CHOICE,
      SearchOrderTypes.MARKETPLACE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type SearchOrderTypeEnum = typeof SearchOrderTypes;
