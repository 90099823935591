import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { slideIn, slideOut } from '@citypantry/util-animations';

@Component({
  selector: 'app-cart-notes',
  templateUrl: './cart-notes.component.html',
  animations: [
    trigger('expand', [
      state('false', style({
        height: '0',
        overflow: 'hidden'
      })),
      transition('* => true', useAnimation(slideIn)),
      transition('* => false', useAnimation(slideOut))
    ])
  ]
})
export class CartNotesComponent implements OnInit {

  @Input()
  public set notes(notes: string) {
    this._notes = notes;
  }

  public get notes(): string {
    return this._notes || '';
  }

  @Input()
  public includeCutlery: boolean;

  @Input()
  public isCutleryAvailable: boolean;

  @Input()
  public disallowEditNotes: boolean;

  @Input()
  public childOrderCount: number;

  @Output()
  public updateNotes: EventEmitter<string> = new EventEmitter();

  @Output()
  public setCutlery: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('input', { static: false })
  public notesInput: ElementRef;

  public isExpanded: boolean;

  private _notes: string;

  constructor() {
    this.childOrderCount = 1;
    this.isExpanded = false;
  }

  public ngOnInit(): void {
    this.isExpanded = !!(this.notes || this.includeCutlery);
  }

  public toggle(event: Event, expand?: boolean): void {
    event.preventDefault();
    if (typeof expand === 'boolean') {
      this.isExpanded = expand;
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  public onExpandCompleted(): void {
    if (this.isExpanded && this.notesInput && this.notesInput.nativeElement) {
      this.notesInput.nativeElement.focus();
    }
  }

  public onBlur(notes: string): void {
    this.updateNotes.emit(notes || '');
  }

  public onCutleryChange(checked: boolean): void {
    this.setCutlery.emit(checked);
  }
}
