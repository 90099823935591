import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppState } from '@citypantry/state';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { AuthInterceptor } from './auth.interceptor';
import { fetchAuth } from './state/auth.actions';
import { AuthEffects } from './state/auth.effects';
import { FEATURE_STATE_NAME, reducer } from './state/auth.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
    EffectsModule.forFeature([
      AuthEffects
    ]),
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
})
export class AuthModule {
  constructor(
    store: Store<AppState>
  ) {
    store.dispatch(fetchAuth()); // Fetch auth at app startup
  }
}
