import { ensureValidEnumValue } from '@citypantry/util';
import { TrackingView, TrackingViews } from './tracking-view.enum';

export interface TrackedDelivery {
  view: TrackingView;
  // String of format: HH:mm
  eta: string | null;
  // String of format: HH:mm : HH:mm
  etaWindow: string | null;
  // Humanised duration (i.e 5 seconds ago)
  durationSinceLastUpdate: string | null;
  deliveryProvider: string | null;
}

export function createTrackedDeliveryFromJson(json?: Partial<TrackedDelivery>): TrackedDelivery {
  json = json || {};

  const delivery: Partial<TrackedDelivery> = {};

  delivery.view = ensureValidEnumValue(TrackingViews, json.view);
  delivery.eta = json.eta || null;
  delivery.etaWindow = json.etaWindow || null;
  delivery.durationSinceLastUpdate = json.durationSinceLastUpdate || null;
  delivery.deliveryProvider = json.deliveryProvider || null;

  return delivery as TrackedDelivery;
}
