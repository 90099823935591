import { UserId } from '../user-id.model';

export interface EaterAtLocation {
  id: UserId;
  firstName: string;
  lastName: string;
  email: string;
  isLeadUser: boolean; // true if the Eater is a lead user of given Customer and Location
}

export function createEaterAtLocationFromJson(json: Partial<EaterAtLocation> = {}): EaterAtLocation {
  const user: Partial<EaterAtLocation> = {};

  user.id = json.id;
  user.firstName = json.firstName;
  user.lastName = json.lastName;
  user.email = json.email;
  user.isLeadUser = !!json.isLeadUser;

  return user as EaterAtLocation;
}
