<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/', label: 'Home' }"></ng-container>
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://business.just-eat.co.uk/privacy-policy', label: 'Privacy Policy' }"></ng-container>
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/vendor/profile', label: 'Profile' }"></ng-container>
  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/builder/menus'], label: 'Menus' }"></ng-container>
  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/builder/items'], label: 'Items' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://help.citypantry.com/hc/en-us/categories/360001675413-Vendor-Partners', label: 'FAQ' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <app-header-dropdown
    [triggerLabel]="'Account'"
    e2e-test-id="accountNavigationLink"
    test-id="dropdown"
  >
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/vendor/addresses', label: 'Addresses', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/vendor/users'], label: 'Users', isSecondary: true }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/vendor/holidays', label: 'Holidays', isSecondary: true }"></ng-container>

    <ng-container *ngTemplateOutlet="authenticatedUserLinksTemplate"></ng-container>
  </app-header-dropdown>

  <li class="header-navigation__item">
    <a
      class="header-navigation__button button button--primary"
      [routerLink]="['/menus/vendor/orders']"
      test-id="ordersButton"
    >
      Orders
    </a>
    <span
      *ngIf="pendingOrdersCount"
      class="header-navigation__vendor-notifications-pending sub-text circle-icon circle-icon--red"
      test-id="pendingOrdersNotification"
    >
      {{ pendingOrdersCount }}
    </span>
  </li>
</ul>
