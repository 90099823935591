import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VendorId } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FavouritesApi {
  constructor(
    private http: HttpClient
  ) {
  }

  public updateFavouriteVendor(
    vendorId: VendorId,
    favourite: boolean
  ): Observable<undefined> {
    return this.http.post(`/users/me/favourites`, {
      vendor: vendorId,
      favourite,
    }).pipe(mapTo(undefined));
  }
}
