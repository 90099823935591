import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-star-rating-select',
  templateUrl: './star-rating-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StarRatingSelectComponent),
    multi: true,
  }],
})
export class StarRatingSelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  public hideDefaultText: boolean = false;

  public values: number[];
  public modifiers: string[];

  public hoveredOption: number | null;

  public form: FormGroup;
  public starRating: FormControl;

  public onTouched: () => void;

  constructor(private fb: FormBuilder) {
    this.values  = [0, 1, 2, 3, 4, 5];
    this.modifiers  = ['default', 'bad', 'poor', 'ok', 'good', 'excellent'];
  }

  public ngOnInit(): void {
    this.hoveredOption = null;

    this.starRating = this.fb.control(0);
    this.form = this.fb.group({starRating: this.starRating});
  }

  public mouseEnterStar(index: number): void {
    this.hoveredOption = index;
  }

  public mouseLeaveStar(): void {
    this.hoveredOption = null;
  }

  public getOptionToShow(): number {
    return this.hoveredOption !== null ? this.hoveredOption : this.form.get('starRating').value;
  }

  public getClass(): string {
    const optionToShow = this.getOptionToShow();
    if (!this.modifiers[optionToShow]) {
      return 'star-picker star-picker--default';
    }
    return `star-picker star-picker--${this.modifiers[optionToShow]}`;
  }

  public registerOnChange(fn: any): void {
    this.starRating.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    // Sets the initial value
    const initialValue = value || 0;
    this.form.setValue({starRating: initialValue}, {emitEvent: false});
  }
}
