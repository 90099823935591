<div
  class="bundle-section-header"
  [class.bundle-section-header--has-errors]="showErrors && formGroup.invalid"
>
  <div
    class="bundle-section-header__heading"
    test-id="heading"
  >
    {{ itemGroup.heading }}
  </div>
  <div class="bundle-section-header__prompt">
    <app-item-group-prompt
      [itemGroup]="itemGroup"
      test-id="prompt"
    ></app-item-group-prompt>
  </div>

  <div
    *ngIf="showErrors && formGroup.invalid"
    class="bundle-section-header__errors"
  >
    <div
      class="section-alert"
      [errorSwitch]="formGroup.errors"
      test-id="error"
    >
      <span
        *errorCase="'minChoices'; let error"
        class="section-alert__message"
        test-id="errorMinChoices"
      >
        Please select {{ error.min }}{{ error.min !== error.max ? ' to ' + error.max : '' }}
        {{ 'option' | plural:(error.min !== error.max ? error.max : error.min) }}
      </span>
      <span
        *errorCase="'maxChoices'; let error"
        class="section-alert__message"
        test-id="errorMaxChoices"
      >
        Please select {{ error.max }}{{ error.min !== error.max ? ' to ' + error.max : '' }}
        {{ 'option' | plural:error.max }}
      </span>
      <span
        *errorCase="'default'"
        class="section-alert__message"
        test-id="errorDefault"
      >
        Please enter a valid choice.
      </span>
    </div>
  </div>
  <hr class="bundle-section-header__rule" />
</div>

<div class="row [ pt-standard px-none ] [ pt-md-x-large px-md-standard ]">
  <ng-container
    *ngFor="let item of itemGroup.items; index as index"
  >
    <div
      [class]="'col-12 col-md-6 mb-standard order-md-' + getItemOrder(index)"
    >
      <app-choice-group-item
        [item]="item"
        [control]="formGroup.get(item.id)"
        [limitReached]="isChoiceLimitReached()"
        [showMoreInfo]="moreInfoItem === item"
        (toggleMoreInfo)="toggleMoreInfo(item)"
        test-id="items"
      ></app-choice-group-item>
    </div>
    <div
      *ngIf="moreInfoItem === item"
      [class]="'col-12 mb-standard order-md-' + getMoreInfoOrder(index)"
    >
      <app-additional-item-info
        [item]="item"
        [showDescription]="true"
        [showPerServing]="true"
        (close)="toggleMoreInfo()"
        test-id="moreInfo"
      ></app-additional-item-info>
    </div>
  </ng-container>
</div>
