import { isDisruptionActive, mapSelectors } from '@citypantry/util';
import { CartDeliverabilityProblem, CartNotificationTypes, DeliverableMenuError, PaymentTerms } from '@citypantry/util-models';
import { getPublicState } from '../app.state';
import {
  getCart,
  getCartDeliverabilityProblems,
  getCartOrder,
  getCartOverridesDialog,
  getCartStatus,
  getCartType,
  getCartValidityErrors,
  getDeliveryDate,
  getOrderValidationError,
  getPendingCartParams,
  hasCartId,
  isExistingCartFromAdmin,
  PublicState
} from '../public/public.state';

const getCartItemsFailedToRestore = (state: PublicState) => state.cartItemsFailedToRestore;
const isDeliveryDisruptionActive = (state: PublicState): boolean => {
  const region = state.menuRegion;
  const date = state.cart.deliveryDate;

  return isDisruptionActive(region, date);
};

const getCartShareModal = (state: PublicState) => state.cartShareModal;
const isLoadingCartChanges = (state: PublicState) => state.pendingCartChanges && state.pendingCartChanges.isLoading || false;
const getPendingCartError = (state: PublicState): CartDeliverabilityProblem | DeliverableMenuError | null =>
  state.pendingCartChanges && (state.pendingCartChanges.deliverabilityProblems[0] || state.pendingCartChanges.menuError) || null;
const getPendingCartChangeReason = (state: PublicState) => state.pendingCartChanges && state.pendingCartChanges.reason || null;
const isPendingCartChangesOpen = (state: PublicState) => !!state.pendingCartChanges;
const isAgeConfirmationDialogVisible = (state: PublicState): boolean => !!state.ageConfirmationDialog;
const shouldOverrideCartErrorsOnAgeConfirmationConfirm = (state: PublicState): boolean =>
  state.ageConfirmationDialog && state.ageConfirmationDialog.userHasConfirmedOverridingErrors;

const getPriceChange = (state: PublicState) => {
  const refund = state.cartNotifications.find((notification) => notification.type === CartNotificationTypes.REFUND);
  if (refund) {
    // The messageArgs.amount is positive for easy displaying, but we're using it as a value opposite to "charge" so we use the negative
    return -1 * refund.messageArgs.amount;
  }
  const charge = state.cartNotifications.find((notification) => notification.type === CartNotificationTypes.EXTRA_CHARGE);
  if (charge) {
    return charge.messageArgs.amount;
  }
  return 0;
};

const isAdditionalCardPaymentRequired = (state: PublicState): boolean => {
  const order = state.cartOrder;
  if (!order) {
    return false;
  }

  const isPaidOnCard = order.paymentTerm === PaymentTerms.PAY_BY_CARD;

  return isPaidOnCard && getPriceChange(state) > 0;
};

export const CartSelectors = mapSelectors(getPublicState, {
  getCart,
  getCartType,
  getCartOrder,
  getCartStatus,
  isExistingCartFromAdmin,
  getDeliveryDate,
  hasCartId,
  getCartValidityErrors,
  getCartDeliverabilityProblems,
  getPendingCartParams,
  getCartItemsFailedToRestore,
  isDeliveryDisruptionActive,
  getCartShareModal,
  isLoadingCartChanges,
  getPendingCartError,
  getPendingCartChangeReason,
  isPendingCartChangesOpen,
  isAgeConfirmationDialogVisible,
  shouldOverrideCartErrorsOnAgeConfirmationConfirm,
  getPriceChange,
  isAdditionalCardPaymentRequired,
  getOrderValidationError,
  getCartOverridesDialog,
});
