import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const lostbarRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'lostbar view-mealplan-as-customer',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Mealplan View as Customer clicked`,
      additionalData: {
        mealplanId: event.data['view-mealplan-as-customer'].mealplanId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-mealplan',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Mealplan clicked`,
      additionalData: {
        id: event.data['edit-mealplan'].mealplanId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-customer',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Customer clicked`,
      additionalData: {
        customerId: event.data['edit-customer'].customerId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar masquerade-as-customer',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Masquerade as Customer clicked`,
      additionalData: {
        customerId: event.data['masquerade-as-customer'].customerId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-customer-by-user',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Customer by Customer User clicked`,
      additionalData: {
        customerId: event.data['edit-customer-by-user'].customerId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar masquerade-as-customer-user',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Masquerade as Customer by Customer User clicked`,
      additionalData: {
        userId: event.data['masquerade-as-customer-user'].userId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-vendor',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Vendor clicked`,
      additionalData: {
        vendorId: event.data['edit-vendor'].vendorId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar masquerade-as-vendor',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Masquerade as Vendor clicked`,
      additionalData: {
        vendorId: event.data['masquerade-as-vendor'].vendorId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-vendor-by-user',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Vendor by Vendor User clicked`,
      additionalData: {
        vendorId: event.data['edit-vendor-by-user'].vendorId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar masquerade-as-vendor-user',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Masquerade as Vendor by Vendor User clicked`,
      additionalData: {
        userId: event.data['masquerade-as-vendor-user'].userId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar masquerade-as-user',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Masquerade as User clicked`,
      additionalData: {
        userId: event.data['masquerade-as-user'].userId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar edit-order',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Edit Order clicked`,
      additionalData: {
        orderId: event.data['edit-order'].orderId,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar search',
    events: 'blur',
    definition: (event) => ({
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.TYPE_IN,
      label: `Search query entered`,
      additionalData: {
        query: event.data['search'].query,
      }
    })
  },
  {
    url: '*',
    path: 'lostbar unmasquerade',
    events: 'click',
    definition: {
      category: AnalyticsCategories.LOSTBAR,
      action: AnalyticsActions.CLICK,
      label: `Unmasquerade clicked`,
    }
  },
];
