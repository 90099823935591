import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const individualChoiceSearchRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/search',
    path: 'search-query',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Search query entered'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'parameters-form postcode',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Postcode set'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Date set'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Time set'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'cuisine-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['cuisine-filter']} cuisine type checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'occasion-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['occasion-filter']} occasion checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'dietary-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['dietary-filter']} dietary requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'vendor-flag-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['vendor-flag-filter']} vendor requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-result link',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData[] => {
      const { index, maxIndex } = event.path.find(({ id }) => id === 'search-result');
      const { totalResultCount } = event.data['search-result-list'];
      const { vendorName, vendorId, isTopPick } = event.data['search-result'];

      const events: DispatchAnalyticsEventData[] = [{
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `Vendor`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
        },
      }];

      // TODO CPD-11178 this event might be redundant, determine whether it can be removed
      if (event.data['search-result']['isTopPick']) {
        events.push({
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Top Pick Result'
        });
      }

      return events;
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-result search-card-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      const { index, maxIndex } = event.path.find(({ id }) => id === 'search-result');
      const { totalResultCount } = event.data['search-result-list'];
      const { vendorName, vendorId, isTopPick, vendorRating, vendorReviewCount, menuId } = event.data['search-result'];

      return ({
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        // TODO CPD-11178 this event might have a too specific label, see if it can be simplified to "Vendor shown"
        label: `Vendor seen: ${event.data['search-result'].vendorName} (${event.data['search-result'].vendorId})`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
          vendorRating, vendorReviewCount, menuId,
        },
      });
    },
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-no-results',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      if (event.data['search-no-results'].analyticsPrefix) {
        return {
          action: AnalyticsActions.VIEW,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: `${event.data['search-no-results'].analyticsPrefix} No search results`
        };
      }

      return {
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `No search results`,
      };
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-no-results-cta',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-no-results-cta'].analyticsPrefix} No search results cta clicked`
    })
  },
  {
    url: '/menus/individual-choice/search',
    path: 'cant-find-what-you-want suggest-a-restaurant',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Suggest a restaurant'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-promo-card close',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-promo-card'].promotion.name} closed`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-promo-card button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-promo-card'].promotion.name} clicked`,
    }),
  },
  {
    url: '/menus/individual-choice/search',
    path: 'cant-find-what-you-want chat-to-us',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Chat to us'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'cant-find-what-you-want top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `can't find what your looking for in viewport`
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-options',
    events: {
      class: 'IndividualChoiceCartOptionsComponent',
      eventName: 'updateChoiceDeadline'
    },
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: `choice deadline changed, new-deadline: ${event.event}`
    })
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-options allow-topups-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `IC Allow Top-ups Toggle ${event.data['allow-topups-toggle'].active ? 'OFF' : 'ON'}`,
    })
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-options ic-cart-budget-per-person',
    events: 'click',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'IC Budget per Person entered'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-options expected-headcount',
    events: 'click',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'IC Expected Headcount value entered'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'edit-postcode',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: `Edit postcode button clicked`
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'edit-delivery-date',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: `Edit delivery date button clicked`
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'edit-delivery-time',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: `Edit delivery time button clicked`
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'order-now-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `CREATE ORDER button clicked`
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-choice-mode-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `Individual choice mode toggled off`,
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-search-popup show-food-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_SEARCH_POPUP,
      label: `SHOW ME THE FOOD button clicked`,
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'search-params-modal top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `IC search popup viewed`,
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-search-params',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Edit IC search parameters clicked'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-vendors vendor-icon',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Vendor icon clicked'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-vendors remove-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Remove vendor button clicked'
    }
  },
  {
    url: '/menus/individual-choice/search',
    path: 'ic-cart-buttons create-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `CREATE ORDER button clicked`,
    }
  },
];
