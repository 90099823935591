import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const homePageRules: AnalyticsRule[] = [
  // Hero section
  {
    url: '/',
    path: 'hero search-food-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Top panel) Search office food'
    },
  },
  {
    url: '/',
    path: 'hero teams-at-home-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Top panel) Teams working from home'
    },
  },

  // Teams at home section
  {
    url: '/',
    path: 'teams-at-home pantry-package-more-info-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Teams at home panel) Pantry packages more info'
    },
  },
  {
    url: '/',
    path: 'teams-at-home gift-card-more-info-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Teams a home panel) Gift cards more info'
    },
  },
  {
    url: '/',
    path: 'teams-at-home talk-to-guru-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Teams a home panel) Talk to a food guru'
    },
  },

  // In the office section
  {
    url: '/',
    path: 'in-the-office about-us-search-food-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(In the office panel) Search office food'
    },
  },

  // Solutions for everyone section
  {
    url: '/',
    path: 'solutions-for-everyone office-managers-tab',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Solutions for everyone panel) Office managers '
    },
  },
  {
    url: '/',
    path: 'solutions-for-everyone ceo-hr-tab',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Solutions for everyone panel) CEO / HR'
    },
  },
  {
    url: '/',
    path: 'solutions-for-everyone employees-tab',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Solutions for everyone panel) Employees'
    },
  },
  {
    url: '/',
    path: 'solutions-for-everyone food-guru-cta',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Solutions for everyone panel) Talk to a food guru '
    },
  },

  // Benefits CTA banner
  {
    url: '/',
    path: 'benefits-cta cta-banner-button',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Benefits panel) Talk to us'
    },
  },
  {
    url: '/',
    path: 'talk-to-us-cta cta-banner-button',
    events: 'click',
    definition: {
      category: AnalyticsCategories.HOME_PAGE,
      action: AnalyticsActions.CLICK,
      label: '(Who we are panel) Get in touch'
    },
  },
];
