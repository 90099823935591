import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-square-spinner',
  template: `
    <div class="square-spinner-layout">
      <div
        class="square-spinner square-spinner--inline"
        [class.square-spinner--small]="small"
        test-id="spinner"
      ></div>
    </div>
  `,
  styles: [`
    .square-spinner-layout {
      display: flex;
      justify-content: center;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareSpinnerComponent {
  @Input()
  public small: boolean;
}
