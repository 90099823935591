import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const myMealsPageRules: AnalyticsRule[] = [
  {
    url: '/my-meals*',
    path: 'upcoming-meals',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Upcoming meals tab',
    }
  },
  {
    url: '/my-meals*',
    path: 'previous-meals',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Previous meals tab',
    }
  },
  {
    url: '/my-meals',
    path: 'meal-row',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Meal row seen',
      extraFields: {
        orderGroupId: event.data['meal-row'].orderGroupId,
      }
    })
  },
  {
    url: '/my-meals',
    path: 'meal-confirmation-dialog top-of-modal',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'meal-confirmation-dialog seen',
      extraFields: {
        customerId: event.data['meal-confirmation-dialog'].customerId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'meal-confirmation-dialog dialog-confirm',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'meal-confirmation-dialog confirm clicked',
      extraFields: {
        customerId: event.data['meal-confirmation-dialog'].customerId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'meal-confirmation-dialog dialog-cancel',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'meal-confirmation-dialog cancel clicked',
      extraFields: {
        customerId: event.data['meal-confirmation-dialog'].customerId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'clear-order-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Clear order button',
      extraFields: {
        orderId: event.data['clear-order-button'].orderId,
        vendorId: event.data['clear-order-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'choose-meal-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Choose meal button',
      extraFields: {
        orderId: event.data['choose-meal-button'].orderId,
        vendorId: event.data['choose-meal-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'add-order-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Add order button',
      extraFields: {
        orderId: event.data['add-order-button'].orderId,
        vendorId: event.data['add-order-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'mark-as-away-button',
    events: 'click',
    definition: (event) => {
      const label = !event.data['mark-as-away-button'].isMarkedAsAway ? 'Mark as away button' : `I'm in the office button`;

      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.MY_MEALS,
        label,
        extraFields: {
          orderGroupId: event.data['mark-as-away-button'].orderGroupId
        }
      };
    }
  },
  {
    url: '/my-meals',
    path: 'sold-out-text',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Sold out text',
      extraFields: {
        orderId: event.data['sold-out-text'].orderId,
        vendorId: event.data['sold-out-text'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'add-order-other-option-chosen-button',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Add order (Other option chosen) button',
      extraFields: {
        orderId: event.data['add-order-other-option-chosen-button'].orderId,
        vendorId: event.data['add-order-other-option-chosen-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'add-order-other-option-chosen-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Add order (Other option chosen) button',
      extraFields: {
        orderId: event.data['add-order-other-option-chosen-button'].orderId,
        vendorId: event.data['add-order-other-option-chosen-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'just-eat-voucher-code-card top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Just Eat Voucher Code card seen',
    }
  },
  {
    url: '/my-meals',
    path: 'just-eat-voucher-code-card voucher-code-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Just Eat Voucher Code button',
      extraFields: {
        ctaText: event.data['voucher-code-button'].ctaText
      }
    })
  },
  {
    url: '/my-meals/previous',
    path: 'report-an-issue-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Report an issue button',
      extraFields: {
        orderGroupId: event.data['report-an-issue-button'].orderGroupId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'track-order-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Track order button',
      extraFields: {
        orderId: event.data['track-order-button'].orderId,
        vendorId: event.data['track-order-button'].vendorId
      }
    })
  },
  {
    url: '/my-meals',
    path: 'track-order-button track-order-button-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MY_MEALS,
      label: 'Track order button shown',
      extraFields: {
        orderId: event.data['track-order-button'].orderId,
        vendorId: event.data['track-order-button'].vendorId
      }
    })
  },
];
