import { PaymentCard } from '@citypantry/util-models';
import { PaymentAction, PaymentActions } from './payment.actions';

export const FEATURE_STATE_NAME = 'payment';

export interface PaymentState {
  paymentCards: PaymentCard[];
}

export const initialState: PaymentState = {
  paymentCards: []
};

export function reducer(state: PaymentState = initialState, action: PaymentAction): PaymentState {
  switch (action.type) {

    case PaymentActions.paymentCardsFetchSuccess.type: {
      const { paymentCards } = action;
      return { ...state, paymentCards };
    }

    default:
      return state;
  }
}
