import { NgModule } from '@angular/core';
import { environment } from '@citypantry/shared-app-config';
import { deserialiseAuthState } from '@citypantry/shared-auth';
import {
  AppState,
  BasicRouterStateSerializer,
  deserialiseFavouriteVendorsState,
  deserialiseIndividualChoiceSetupState,
  deserialiseIndividualChoiceState,
  reducers,
  RouterEffects,
} from '@citypantry/state';
import { deserialisePublicState } from '@citypantry/state-public';
import { EffectsModule as NgrxEffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, INIT, MetaReducer, StoreModule, UPDATE } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import moment from 'moment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { logActionsToErrorPlugin, logStateToErrorPlugin } from './error-plugin-loggers';

/**
 * The default replacer in localStorageSync writes moment objects as their `object` representation.
 * We need to serialise them as ISO strings instead so we can deserialise them properly later.
 */
const momentAwareReplacer = (key: string, value: any) => {
  return (typeof value === 'object') && value && moment.isMoment(value) ?
    value.toISOString() :
    value;
};

/**
 * This function is a metareducer which is capable of merging rehydrated state from the localStorage into the active state.
 * As a metareducer it is called on every action, but only needs to act on INIT and UPDATE (which are the ones initialising state).
 *
 * @param state The state currently in the store
 * @param rehydratedState The data loaded from the localStorage
 * @param action The action currently being applied
 */
const mergeReducer = (state: any, rehydratedState: any, action: Action) => {
  switch (action.type) {
    case INIT: { // Called when the store initialises, after all the default data has been set by reducers

      // The default merge algorithm for localStorageSync uses deepmerge which breaks moment as it loses the prototype.
      // We only use top level keys to store data (no deeply nested serialisation), so can simply merge at the top level.

      return {
        ...state,
        ...rehydratedState,
      };
    }
    case UPDATE: { // Called when a lazy-loaded feature state initialises

      // NOTE (19/11/2020): This code block is untested because we currently do not have
      // any lazy-loaded state that is serialised to/from localStorage.
      // If this code does not work as intended, do not assume it is correct.
      // If you find that this code does work as intended, remove this comment.

      // action.features is a list of feature names that have been added to the state
      // Pick those from the rehydratedState and merge them into state, if they exist

      const featuresToMerge: { [key: string]: any } = {};
      let hasFeatures = false;
      for (const feature of ((action as any).features as string[])) {
        if (rehydratedState.hasOwnProperty(feature) && rehydratedState[feature]) {
          featuresToMerge[feature] = rehydratedState[feature];
          hasFeatures = true;
        }
      }
      if (hasFeatures) {
        return {
          ...state,
          ...featuresToMerge,
        };
      } else { // don't create a new state if there isn't anything to merge, to prevent messing with strict equality
        return state;
      }
    }
    default:
      return state;
  }
};

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  // see https://github.com/btroncone/ngrx-store-localstorage#localstorageconfig for details
  return localStorageSync({
    storageKeySerializer: (key) => `menus.state.${ key }`,
    rehydrate: true, // Pull state from local storage on app startup
    restoreDates: false, // Leave states as strings; we manually rehydrate them in the `deserialize...` functions
    mergeReducer, // This reducer deals with merging state restored from the localStorage into whatever state the application already has.
    keys: [{
      'public': {
        deserialize: deserialisePublicState,
        replacer: momentAwareReplacer,
      },
    }, {
      'auth': {
        deserialize: deserialiseAuthState,
        replacer: momentAwareReplacer,
      },
    }, {
      'favouriteVendors': {
        deserialize: deserialiseFavouriteVendorsState,
        replacer: momentAwareReplacer,
      },
    }, {
      'individualChoice': {
        deserialize: deserialiseIndividualChoiceState,
        replacer: momentAwareReplacer,
      },
    }, {
      'individualChoiceSetup': {
        deserialize: deserialiseIndividualChoiceSetupState,
        replacer: momentAwareReplacer,
      },
    },
    ],
  })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

@NgModule({
  imports: [
    /**
     * StoreModule.provideStore is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store and uses
     * the store as the single source of truth for the router's state.
     */
    StoreRouterConnectingModule.forRoot({
      serializer: BasicRouterStateSerializer,
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      maxAge: 40, // Maximum number of rows to show in the Redux plugin
      stateSanitizer: logStateToErrorPlugin,
      monitor: logActionsToErrorPlugin,
      logOnly: environment.production,
    }),

    NgrxEffectsModule.forRoot([
      RouterEffects,
    ]),
  ]
})
export class GlobalStoreModule {}
