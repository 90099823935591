import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DatePickerComponent } from './date-picker.component';
import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DpDatePickerModule,
    FormsModule,
  ],
  declarations: [
    DatePickerComponent,
    DateRangePickerComponent,
  ],
  exports: [
    DatePickerComponent,
    DateRangePickerComponent,
  ]
})
export class DatePickerModule {}
