<div class="row" *ngIf="!hideExistingContacts">
  <div class="col-6">
    <div class="form-group">
      <select
        class="form-input"
        [formControl]="selectedContact"
        test-id="selectedContact"
        analyticsId="delivery-contact-picker"
      >
        <option>Add new contact</option>
        <option
          *ngFor="let deliveryContact of deliveryContacts"
          [value]="deliveryContact.id"
        >
          {{ deliveryContact.fullName }}{{ deliveryContact.phoneNumber ? ', ' : '' }} {{ deliveryContact.phoneNumber }}
        </option>
      </select>
    </div>
  </div>
</div>
<form [formGroup]="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-group__label">First name *</label>
        </div>
        <input
          class="form-input"
          formControlName="firstName"
          [class.form-input--error]="shouldShowErrors('firstName')"
          test-id="firstName"
          e2e-test-id="firstName"
          analyticsId="first-name"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-group__label">Last name *</label>
        </div>
        <input
          class="form-input"
          formControlName="lastName"
          [class.form-input--error]="shouldShowErrors('lastName')"
          test-id="lastName"
          e2e-test-id="lastName"
          analyticsId="last-name"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-group__label">Email address *</label>
        </div>
        <input
          class="form-input"
          formControlName="email"
          [class.form-input--error]="shouldShowErrors('email')"
          test-id="email"
          e2e-test-id="email"
          analyticsId="email"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-group__label">Phone number *</label>
        </div>
        <input
          class="form-input"
          formControlName="phoneNumber"
          [class.form-input--error]="shouldShowErrors('phoneNumber')"
          test-id="phoneNumber"
          e2e-test-id="phoneNumber"
          analyticsId="phone-number"
        />
        <app-form-error [errors]="getErrorsToDisplay('phoneNumber')"></app-form-error>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="form-choice">
          <input
            class="form-choice__input"
            type="checkbox"
            formControlName="emailNotificationEnabled"
            analyticsId="email-delivery"
            test-id="emailNotificationEnabled"
            e2e-test-id="emailNotificationEnabled"
          />
          <span class="form-choice__label">Send delivery notifications to email</span>
        </label>
        <div
          *ngIf="form.hasError('mustSelectNotificationType') && (form.get('smsNotificationEnabled').touched || form.get('emailNotificationEnabled').touched)"
          class="text-color-red-1 mt-small"
          test-id="mustSelectNotificationTypeError"
        >
          At least one option must be selected
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label class="form-choice">
          <input
            class="form-choice__input"
            type="checkbox"
            formControlName="smsNotificationEnabled"
            analyticsId="sms-delivery"
            test-id="smsNotificationEnabled"
            e2e-test-id="smsNotificationEnabled"
          />
          <span class="form-choice__label">Send delivery notifications to phone (SMS)</span>
        </label>
      </div>
    </div>
  </div>
</form>
