import { add, divide, multiply, round, subtract } from 'mathjs';
import { MajorCurrency, MinorCurrency } from './minor-major-currency.model';

// MATH OPERATIONS //

/** Performs a floating-point safe add operation on multiple price values, and rounds the output to 2 decimal places. */
export function safeAddCurrency<T extends number = number>(base: T, ...args: T[]): T {
  return round(args.reduce((previous, current) => add(previous || 0, current || 0), base || 0), 2) as T;
}

/** Performs a floating-point safe subtract operation on multiple price values, and rounds the output to 2 decimal places. */
export function safeSubtractCurrency<T extends number = number>(base: T, ...args: T[]): T {
  return round(args.reduce((previous, current) => subtract(previous || 0, current || 0), base || 0), 2) as T;
}

/** Performs a floating-point safe multiply operation on a price value, and rounds the output to 2 decimal places. */
export function safeMultiplyCurrency<T extends number = number>(base: T, ...args: number[]): T {
  return round(args.reduce((previous, current) => multiply(previous || 0, current || 0), base || 0), 2) as T;
}

/** Performs a floating-point safe divide operation on a price value, and rounds the output to 2 decimal places. */
export function safeDivideCurrency<T extends number = number>(base: T, ...args: number[]): T {
  return round(args.reduce((previous, current) => divide(previous || 0, current || 0), base || 0), 2) as T;
}

// MAPPING MODELS //

export function minorCurrencyToMajor(currency: MinorCurrency): MajorCurrency {
  /* eslint-disable-next-line jefb/no-casting-to-majorcurrency */ // All other places must call this method which wraps this illegal call
  return safeDivideCurrency(currency, 100) as MajorCurrency;
}

export function majorCurrencyToMinor(currency: MajorCurrency): MinorCurrency {
  return round(safeMultiplyCurrency(currency, 100), 0) as MinorCurrency;
}

/**
 * A casting function that should be used in form components and in fromJson methods.
 *
 * If you find you need to use it elsewhere because of a lint failure,
 * reconsider your architecture, and if you see no other way around it,
 * use eslint-disable-next-line jefb/no-casting-to-majorcurrency.
 */
export function convertUserInputToMajorCurrency(formValue: number | string): MajorCurrency {
  const value: number = typeof formValue === 'number' ? formValue : parseFloat(formValue);

  /* eslint-disable-next-line jefb/no-casting-to-majorcurrency */ // All other places must call this method which wraps this illegal call
  return round(value, 2) as MajorCurrency;
}
