import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const groupsOverviewPageRules: AnalyticsRule[] = [
  {
    url: '/menus/groups',
    path: 'colleagues-cta',
    events: 'click',
    definition: () => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_OVERVIEW_PAGE,
      label: 'View Colleagues Link',
    }),
  },
  {
    url: '/menus/groups',
    path: 'addresses-cta',
    events: 'click',
    definition: () => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_OVERVIEW_PAGE,
      label: 'View Addresses Link',
    }),
  },
  {
    url: '/menus/groups',
    path: 'create-group',
    events: 'click',
    definition: () => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_OVERVIEW_PAGE,
      label: 'Create Group Button',
    }),
  },
];
