import { CartDeliverabilityProblemType } from '../cart/cart-deliverability-problem-type.enum';

export type OrderValidationErrorType = 'VendorHasEnoughCapacity' |
  'MinimumOrderValue' |
  'SameDayOrderIsAllowed' |
  'CartDeliverability' |
  'NextDayOrderDeadline' |
  string;

export interface BaseOrderValidationError {
  type: OrderValidationErrorType;
  message: string;
  messageArgs: { [key: string]: any };
}

export interface OrderValidationCartError extends BaseOrderValidationError {
  type: 'CartDeliverability';
  message: CartDeliverabilityProblemType;
}

export type OrderValidationError = BaseOrderValidationError | OrderValidationCartError;

export function createOrderValidationErrorFromJson(json: Partial<OrderValidationError> = {}): OrderValidationError {
  const error: Partial<OrderValidationError> = {};

  error.type = json.type;
  error.message = json.message;
  error.messageArgs = json.messageArgs;

  return error as OrderValidationError;
}
