import { CustomerQualification } from './qualification';
import { createCustomerStatusFromJson, CustomerStatus } from './status/customer-status.model';

declare const CustomerIdSymbol: unique symbol;
export type CustomerId = string & {
  [CustomerIdSymbol]: never;
};

export interface Customer {
  id: CustomerId;
  humanId: string;
  companyName: string;
  email: string;
  status: CustomerStatus;
  isEligibleForIndividualChoice: boolean;
  isServiceFeeEnabled: boolean;
  serviceFeeRate: number | null;
  qualification: CustomerQualification;
  isPaidOnAccount: boolean;
  isSubsidisedChoiceEnabled: boolean;
}

/**
 * Properties present in the JSON that will not be converted
 */
export interface CustomerJsonProperties {
  // Not currently required - move to main interface if necessary
  user: any;
  members: any;
  primaryMember: any;
  payOnAccountStatus: any;
  payOnAccountStatusText: any;
  dateAdded: any;
  daysUntilInvoiceOverdue: any;
  invoicePaymentTerms: any;
  maxSpendPerMonth: any;
  currentMealPlanStatus: any;
  currentMealPlanStatusText: any;
  lastMealPlanEndDate: any;
  persona: any;
  selfIdentifiedPersona: any;
  payOnAccountInvoiceRecipient: any;
  status: any;
  isOnMealPlan: any;

  // Not used
  accountsEmail: any; // Deprecated
  accountsContactName: any; // Deprecated
  accountsTelephoneNumber: any; // Deprecated
}

export function createCustomerFromJson(json?: Partial<Customer & CustomerJsonProperties>): Customer {
  json = json || {};
  const customer: Partial<Customer> = {};

  customer.id = json.id;
  customer.humanId = json.humanId;
  customer.companyName = json.companyName;
  customer.email = json.email;
  customer.status = createCustomerStatusFromJson(json.status);

  customer.isEligibleForIndividualChoice = json.isEligibleForIndividualChoice;
  customer.isPaidOnAccount = json.isPaidOnAccount;

  customer.isServiceFeeEnabled = json.isServiceFeeEnabled;
  customer.serviceFeeRate = json.serviceFeeRate;

  customer.isSubsidisedChoiceEnabled = !!json.isSubsidisedChoiceEnabled;

  return customer as Customer;
}
