import { Component, Input } from '@angular/core';

const CUTOFF_DISTANCE = 2.5;

@Component({
  selector: 'app-vendor-distance',
  template: `<span test-id="distance"><i
    *ngIf="isBeyondCutoffDistance(distance)"
    class="icon icon-alert-triangle"
    test-id="distanceAlert"
  ></i>
  {{ distance | number:'1.0-1' }} mi
  </span>`
})
export class VendorDistanceComponent {
  @Input()
  public distance: number;

  public isBeyondCutoffDistance(distance: number): boolean {
    return distance > CUTOFF_DISTANCE;
  }
}
