import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const shareCartModalRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'share-cart-modal copy',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SHARE_CART_MODAL,
      label: 'Copy'
    }
  },
];
