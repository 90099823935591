import moment from 'moment';

interface Disruption {
  region: string;
  from: moment.Moment;
  to: moment.Moment;
}

const disruptions: Disruption[] = [{
  region: 'London',
  from: moment.tz('2019-10-07T00:00:00', 'Europe/London'),
  to: moment.tz('2019-10-20T23:59:59', 'Europe/London'),
}];

/*
 * If disruptions changes to come from some sort of configuration then we may need to move the disruptions to the store
 * */
export function isDisruptionActive(region: string, date: moment.Moment): boolean {
  if (region === null || !moment.isMoment(date)) {
    return false;
  }
  return disruptions.some((disruption) => region === disruption.region && date.isBetween(disruption.from, disruption.to));
}
