import { Injectable } from '@angular/core';
import { Dispatch } from '@citypantry/state';
import { Action } from '@ngrx/store';
import { OperationalRegionsActions } from './operational-regions.actions';

@Injectable({ providedIn: 'root' })
export class OperationalRegionsService {

  @Dispatch()
  public loadOperationalRegions(): Action {
    return OperationalRegionsActions.loadOperationalRegions();
  }
}
