/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type RefundRecipient = 'customer' | 'eaters' | 'customer_and_eaters';

export class RefundRecipients {
  public static CUSTOMER:            RefundRecipient & 'customer'            = 'customer';
  public static EATERS:              RefundRecipient & 'eaters'              = 'eaters';
  public static CUSTOMER_AND_EATERS: RefundRecipient & 'customer_and_eaters' = 'customer_and_eaters';

  public static get values(): RefundRecipient[] {
    return [
      RefundRecipients.CUSTOMER,
      RefundRecipients.EATERS,
      RefundRecipients.CUSTOMER_AND_EATERS,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type RefundRecipientEnum = typeof RefundRecipients;
