import { CustomerAdvancedBudgetingAction, CustomerAdvancedBudgetingActions } from './customer-advanced-budgeting.actions';

export interface CustomerAdvancedBudgetingState {
  isSubmitting: boolean;
}

export const INITIAL_STATE: CustomerAdvancedBudgetingState = {
  isSubmitting: false,
};

export function reducer(
  state: CustomerAdvancedBudgetingState = INITIAL_STATE,
  action: CustomerAdvancedBudgetingAction
): CustomerAdvancedBudgetingState {
  switch (action.type) {
    case CustomerAdvancedBudgetingActions.submitAdvancedBudgets.type: {
      return {
        ...state,
        isSubmitting: true,
      };
    }

    case CustomerAdvancedBudgetingActions.advancedBudgetsSavingSuccess.type:
    case CustomerAdvancedBudgetingActions.advancedBudgetsSavingError.type: {
      return {
        ...state,
        isSubmitting: false,
      };
    }

    default:
      return state;
  }
}
