import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapmap, Result } from '@citypantry/util';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { CustomerId } from '@citypantry/util-models';
import {
  ColleagueNotificationPreferences,
  createColleagueNotificationPreferencesFromJson
} from '../models/colleague-notification-preferences.model';
import {
  ColleagueNotificationPreferencesConfig,
  createColleagueNotificationPreferencesConfigFromJson
} from '../models/colleague-notification-preferences-config.model';

@Injectable({
  providedIn: 'root',
})
export class ColleagueNotificationPreferencesApi {

  constructor(private http: HttpClient) {
  }

  public getColleagueNotificationPreferences(): Observable<ColleagueNotificationPreferences[]> {
    return this.http.get('/users/me/colleague-notification-preferences').pipe(
      mapmap(createColleagueNotificationPreferencesFromJson),
    );
  }

  public updateColleagueNotificationPreferences(
    colleagueNotificationPreferences: ColleagueNotificationPreferences
  ): Observable<ColleagueNotificationPreferences> {
    return this.http.put('/users/me/colleague-notification-preference', colleagueNotificationPreferences).pipe(
      map(createColleagueNotificationPreferencesFromJson)
    );
  }

  public overrideColleagueNotificationPreferences(
    copyColleagueNotificationsToAllMembers: boolean,
    colleagueNotificationPreferences: ColleagueNotificationPreferences[],
    customerId: CustomerId
  ): Observable<Result<void, string>> {
    return this.http.put(
      `/customer/${customerId}/members/colleague-notification-preferences`,
      {
        copyColleagueNotificationsToAllMembers,
        colleagueNotificationPreferences
      }).pipe(
      mapTo(Result.success()),
      catchError(() => of(Result.failure('Error when overriding colleague notification preferences')))
    );
  }

  public getColleagueNotificationPreferencesConfig(customerId: CustomerId): Observable<ColleagueNotificationPreferencesConfig> {
    return this.http.get(`/customer/${customerId}/members/colleague-notification-preferences-config`).pipe(
      map(createColleagueNotificationPreferencesConfigFromJson),
    );
  }
}
