<div
  class="quantity-picker"
  e2e-test-id="quantityPicker"
  analyticsId="quantity-picker"
>
  <button
    *ngIf="!inputOnly"
    class="quantity-picker__button quantity-picker__button--decrement"
    type="button"
    (click)="decrease()"
    [disabled]="isDisabled"

    ecommerceEventTrigger="click"
    [ecommerceActionType]="AnalyticsEcommerceActions.REMOVE"
    [ecommerceEventId]="analyticsEcommerceEventId"
    analyticsId="decrement"
    test-id="decrement"
    e2e-test-id="decrementItemQuantityButton"
  >
  </button>
  <input
    #quantityInput
    [class.quantity-picker__input--no-value]="!quantity"
    [class.quantity-picker__input--large-value]="quantity > 99"
    type="number"
    class="quantity-picker__input text-center"
    title="Quantity"
    [value]="quantity"
    [disabled]="isDisabled"
    (keypress)="filterInput($event)"
    (blur)="onBlur($event.target.value)"
    (keyup)="keyUp($event.target.value)"
    (focus)="selectAll()"

    ecommerceEventTrigger="blur"
    [ecommerceActionType]="AnalyticsEcommerceActions.CART_QUANTITY_CHANGE"
    [ecommerceEventId]="analyticsEcommerceEventId"

    analyticsId="text-box"
    test-id="quantityInput"
    e2e-test-id="quantityInput"
  >
  <button
    *ngIf="!inputOnly"
    class="quantity-picker__button quantity-picker__button--increment"
    type="button"
    (click)="increase()"
    [disabled]="isDisabled"

    ecommerceEventTrigger="click"
    [ecommerceActionType]="AnalyticsEcommerceActions.ADD"
    [ecommerceEventId]="analyticsEcommerceEventId"

    analyticsId="increment"
    test-id="increment"
    e2e-test-id="incrementItemQuantityButton"
  >
  </button>

</div>
