/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type NegativeFoodFeedbackTag =
  'Small portion size'
  | 'Missing items'
  | 'Not as described'
  | 'Not tasty'
  | 'Unclear labelling'
  | 'Poor presentation'
  | 'Badly packaged'
  | 'Dietary requirements not met';

export class NegativeFoodFeedbackTags {
  public static SMALL_PORTION_SIZE:           NegativeFoodFeedbackTag & 'Small portion size'           = 'Small portion size';
  public static MISSING_ITEMS:                NegativeFoodFeedbackTag & 'Missing items'                = 'Missing items';
  public static NOT_AS_DESCRIBED:             NegativeFoodFeedbackTag & 'Not as described'             = 'Not as described';
  public static NOT_TASTY:                    NegativeFoodFeedbackTag & 'Not tasty'                    = 'Not tasty';
  public static UNCLEAR_LABELLING:            NegativeFoodFeedbackTag & 'Unclear labelling'            = 'Unclear labelling';
  public static POOR_PRESENTATION:            NegativeFoodFeedbackTag & 'Poor presentation'            = 'Poor presentation';
  public static BADLY_PACKAGED:               NegativeFoodFeedbackTag & 'Badly packaged'               = 'Badly packaged';
  public static DIETARY_REQUIREMENTS_NOT_MET: NegativeFoodFeedbackTag & 'Dietary requirements not met' = 'Dietary requirements not met';

  public static get values(): NegativeFoodFeedbackTag[] {
    return [
      NegativeFoodFeedbackTags.SMALL_PORTION_SIZE,
      NegativeFoodFeedbackTags.MISSING_ITEMS,
      NegativeFoodFeedbackTags.NOT_AS_DESCRIBED,
      NegativeFoodFeedbackTags.NOT_TASTY,
      NegativeFoodFeedbackTags.UNCLEAR_LABELLING,
      NegativeFoodFeedbackTags.POOR_PRESENTATION,
      NegativeFoodFeedbackTags.BADLY_PACKAGED,
      NegativeFoodFeedbackTags.DIETARY_REQUIREMENTS_NOT_MET,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type NegativeFoodFeedbackTagEnum = typeof NegativeFoodFeedbackTags;
