import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';

@Injectable({
  providedIn: 'root'
})
export class HubspotInitializer {

  constructor(
    private appConfig: AppConfig,
  ) {}

  public run(): void {
    ((d: any, s: string, i: string, r: number) => {
      if (d.getElementById(i)) {
        return;
      }
      const n: any = d.createElement(s);
      const e = d.getElementsByTagName(s)[0];
      n.id = i;
      n.src = `//js.hs-analytics.net/analytics/${(Math.ceil(new Date() as any / r) * r)}/${this.appConfig.HUBSPOT_ID}.js`;
      e.parentNode.insertBefore(n, e);
    })(document, 'script', 'hs-analytics', 300000);
  }
}
