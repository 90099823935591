import { mapSelectors } from '@citypantry/util';
import { getCheckoutState } from '../app.state';
import { CheckoutState } from './checkout.state';

export const getOrder = (state: CheckoutState) => state.order;
export const getLoyaltyPoints = (state: CheckoutState) => state.loyaltyPoints;
const isPromoBannerHidden = (state: CheckoutState) => state.hidePromotionBanner;

export const CheckoutSelectors = mapSelectors(getCheckoutState, {
  getOrder,
  getLoyaltyPoints,
  isPromoBannerHidden
});
