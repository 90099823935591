import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { isDateOnWeekend, TypedSimpleChanges } from '@citypantry/util';
import {
  combineDeliveryDateTime,
  CustomerLocation,
  CustomerLocationId,
  getDefaultDeliveryDate,
  splitDeliveryDateTime,
  VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES,
  VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES,
} from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';
import moment from 'moment';
import { pairwise, startWith } from 'rxjs/operators';
import { EditableDropdownOption } from '../editable-dropdown/editable-dropdown.component';
import { Parameters } from './parameters.model';

interface FormValue {
  date: string;
  time: number;
  postcode: string;
  location: string;
  postcodeOrLocation: string;
}

const getAvailableDeliveryTimes = (date: moment.Moment): number[] =>
  isDateOnWeekend(date) ? VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES : VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES;

@Component({
  selector: 'app-parameters-form',
  templateUrl: './parameters-form.component.html',
  styleUrls: ['./parameters-form.component.scss'],
})
export class ParametersFormComponent implements OnInit, OnChanges {

  @Input()
  public control: AbstractControl;

  @Input()
  public params: Parameters;

  @Input()
  public locations: CustomerLocation[];

  @Input()
  public address: boolean;

  @Input()
  public submitted: boolean;

  @Input()
  public canEnterPostcode: boolean;

  public isEnteringPostcode: boolean;
  public locationOptions: EditableDropdownOption[] = [];
  public locationPostcodeMap: {[key in string]: CustomerLocationId} = {};
  public form: FormGroup;
  public deliveryTimes: number[];

  public ngOnInit(): void {
    const initialValue: Parameters = this.control.value;

    this.generateLocationOptions();

    this.isEnteringPostcode = this.canEnterPostcode && Object.keys(this.locationPostcodeMap).length <= 0;

    const { date, time } = splitDeliveryDateTime(initialValue && initialValue.date || getDefaultDeliveryDate());
    this.deliveryTimes = getAvailableDeliveryTimes(moment.tz(date, 'YYYY-MM-DD', 'Europe/London'));

    const postcode = (initialValue && initialValue.postcode) ? initialValue.postcode : null;
    const location = (initialValue && initialValue.location) ? initialValue.location : null;
    const postcodeOrLocation = location || postcode || '';

    const dateControl = new FormControl(
      date,
      {
        validators: [Validators.required, CustomValidators.date],
        updateOn: 'change'
      },
    );
    const timeControl = new FormControl(
      time,
      {
        validators: [
          Validators.required,
          (control) => CustomValidators.timeInListAndDateOnWeekend(dateControl, this.deliveryTimes)(control),
          (control) => CustomValidators.valueInList(this.deliveryTimes)(control),
        ],
        updateOn: 'change'
      }
    );
    this.form = new FormGroup({
      postcodeOrLocation: new FormControl(
        postcodeOrLocation,
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      postcode: new FormControl(
        initialValue && initialValue.postcode || '',
        {
          validators: [Validators.required, CustomValidators.postcode],
        }
      ),
      location: new FormControl(
        initialValue && initialValue.location || '',
        {
          validators: [],
        }
      ),
      date: dateControl,
      time: timeControl,
    });

    this.form.valueChanges.pipe(
      startWith(this.form.value as FormValue),
      pairwise()
    ).subscribe(([prev, next]) => {
      if (prev && next && prev.date !== next.date) {
        const nextDeliveryTimes = getAvailableDeliveryTimes(moment.tz(next.date, 'YYYY-MM-DD', 'Europe/London'));
        // only update deliveryTimes if the available times for the selected date
        // differ from those available for the previously selected date
        if (this.deliveryTimes !== nextDeliveryTimes) {
          this.deliveryTimes = nextDeliveryTimes;
          timeControl.updateValueAndValidity({ onlySelf: true });
        }
      }
      this.control.setValue(this.getParametersFromFormValue(next));
    });

    this.control.setValidators((ctrl: AbstractControl) => {
      const value = ctrl.value;
      return value && value.postcode && value.date ? null : { invalid: true };
    });
    this.control.setValue(this.getParametersFromFormValue(this.form.value), { emitEvent: false });
  }

  public ngOnChanges(changes: TypedSimpleChanges<ParametersFormComponent>): void {
    if (this.submitted === true) {
      this.form.get('postcodeOrLocation').markAsTouched();
    }
    if (changes.locations) {
      this.generateLocationOptions();
    }
  }

  public onBlur(): void {
    this.control.markAsTouched();
  }

  public getPostcodeLabel(): string {
    if (this.address) {
      return 'Address or Postcode';
    } else if (this.locationOptions?.length) {
      return this.isEnteringPostcode ? 'New Postcode' : 'Select Location';
    } else {
      return 'Postcode';
    }
  }

  private generateLocationOptions(): void {
    this.locationOptions = this.getLocationOptions(this.locations);
    this.locationPostcodeMap = this.locations ?
      this.locations.reduce((map, location) => ({...map, [location.id]: location.postcode}), {}) :
      {};
  }

  private getLocationOptions(locations: CustomerLocation[]): EditableDropdownOption[] {
    if (!locations) {
      return [];
    } else {
      return locations.map((location) => ({
        label: `${location.name}, ${location.postcode}`,
        value: location.id
      }));
    }
  }

  private getParametersFromFormValue(value: FormValue): Parameters {
    let postcode: string;
    let location: string = null;
    if (this.isEnteringPostcode) {
      postcode = !CustomValidators.postcode(this.form.controls.postcodeOrLocation) ? value.postcodeOrLocation.trim() : '';
    } else {
      // The validator returns null if it is a postcode, and returns an error if it is not a postcode.
      // If the validator returns an error, we can assume that the value is a location id
      location = CustomValidators.postcode(this.form.controls.postcodeOrLocation) ? value.postcodeOrLocation : '';

      // If they have a location set, get the postcode for that location, otherwise use as custom postcode
      postcode = this.locationPostcodeMap && this.locationPostcodeMap.hasOwnProperty(value.postcodeOrLocation)
        ? this.locationPostcodeMap[value.postcodeOrLocation].trim()
        : value.postcodeOrLocation.trim();
    }

    const date = this.form.controls.date.valid && this.form.controls.time.valid ?
      combineDeliveryDateTime(value.date, value.time) :
      null;

    return {
      postcode,
      date,
      location,
    };
  }
}
