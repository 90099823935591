import { style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RED_1 } from '@citypantry/pattern-library/dist/js/colors';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventIdEnum,
  AnalyticsEcommerceEventIds
} from '@citypantry/shared-analytics';
import { collapseTopLeft, slideInOut } from '@citypantry/util-animations';

@Component({
  selector: 'app-deletable-cart-item',
  templateUrl: './deletable-cart-item.component.html',
  animations: [
    trigger('slideInOut', slideInOut),
    trigger('remove', [
      transition(':leave', [
        style({
          backgroundColor: RED_1,
        }),
        useAnimation(collapseTopLeft, { params: { time: '0.3s' }}),
      ]),
    ]),
  ],
  styles: [':host { overflow: hidden }'],
})
export class DeletableCartItemComponent {
  @Input()
  public hasError: boolean;

  @Input()
  public isBundleCartItem: boolean;

  @Output()
  public remove: EventEmitter<void> = new EventEmitter();

  public isRemoving: boolean;

  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;
  public AnalyticsEcommerceEventIds: AnalyticsEcommerceEventIdEnum = AnalyticsEcommerceEventIds;

  constructor() {
    this.isRemoving = false;
  }

  public onRemoveClicked(): void {
    this.remove.emit();
  }

  public enterRemovingState(): void {
    this.isRemoving = true;
  }

  public leaveRemovingState(): void {
    this.isRemoving = false;
  }

}
