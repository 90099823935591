import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const customerRecommendationHomePageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/home',
    path: 'recommendation',
    events: 'click',
    definition: (event) => {
      const { category, position, vendorName } = event.data['recommendation'];
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.CUSTOMER_HOME_PAGE,
        label: `recommendation result - ${category} - position ${position} - ${vendorName}`
      };
    }
  },
  {
    url: '/menus/customer/home',
    path: 'recommendations-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      return {
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.CUSTOMER_HOME_PAGE,
        label: `${event.data['recommendations-in-viewport'].category} category`
      };
    }
  },
  {
    url: '/menus/customer/home',
    path: 'top-of-footer',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.CUSTOMER_HOME_PAGE,
      label: `customer-homepage top-of-footer-seen`
    }
  },
  {
    url: '/menus/customer/home',
    path: 'bottom-of-footer',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.CUSTOMER_HOME_PAGE,
      label: `customer-homepage bottom-of-footer-seen`
    }
  },
];
