import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PromotionBannerModel } from '@citypantry/shared-promotion';
import { PromotionName } from '@citypantry/util-models';

@Component({
  selector: 'app-promotion-banner',
  template: `
    <div
      *ngIf="promotion"
      class="promotion-banner"
      [class.promotion-banner--transparent]="transparent"
      test-id="promotionBanner"
      analyticsId="promotion-banner"
      [analyticsData]="promotion"
    >
      <a
        class="promotion-banner__link"
        [attr.href]="promotion.link ? promotion.link : null"
        [attr.target]="promotion.link ? promotion.openLinkInNewTab ? '_blank' : '_parent' : null"
        (click)="onClick()"
        analyticsId="link"
        test-id="promotionLink"
      >
        {{ promotion.bannerText }}
      </a>
      <a
        class="promotion-banner__tag button button--primary button--white button--small"
        [class.promotion-banner__tag--show]="!animateIn"
        [class.promotion-banner__tag--animate-in]="animateIn"
        [attr.href]="promotion.link ? promotion.link : null"
        [attr.target]="promotion.link ? promotion.openLinkInNewTab ? '_blank' : '_parent' : null"
        (click)="onClick()"
        analyticsId="tag"
        test-id="promotionTag"
      >
        {{ promotion.buttonText }}
      </a>
      <app-in-viewport analyticsId="bottom-of-banner"></app-in-viewport>
    </div>
  `
})
export class PromotionBannerComponent implements OnChanges {

  @Input()
  public promotion: PromotionBannerModel;

  @Input()
  public transparent: boolean;

  @Input()
  public animateIn: boolean;

  @Output()
  public tagAnimatedIn: EventEmitter<PromotionName> = new EventEmitter();

  @Output()
  public ctaClicked: EventEmitter<PromotionBannerModel> = new EventEmitter();

  public ngOnChanges(): void {
    if (this.promotion) {
      this.tagAnimatedIn.emit(this.promotion.name);
    }
  }

  public onClick(): void {
    this.ctaClicked.emit(this.promotion);
  }
}
