<form [formGroup]="form">
  <h3 class="sub-header--bold mb-large">Location</h3>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="form-group__label">Location name*</label>
        <input
          class="form-input"
          type="text"
          formControlName="name"
          analyticsId="location-name"
          test-id="locationName"
        />
        <app-form-error
          [errors]="getErrorsToDisplay('name')"
          test-id="formErrorName"
        ></app-form-error>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label class="form-group__label">Company name</label>
        <input
          class="form-input"
          type="text"
          formControlName="companyName"
          analyticsId="company-name"
          test-id="companyName"
        />
        <app-form-error
          [errors]="getErrorsToDisplay('companyName')"
          test-id="formErrorCompanyName"
        ></app-form-error>
      </div>
    </div>
  </div>

  <h3 class="sub-header--bold mb-large">Delivery Address</h3>

  <!-- Building Name -->
  <div class="form-group">
    <label class="form-group__label">Building name / floor number</label>
    <input
      class="form-input"
      type="text"
      formControlName="addressLine2"
      analyticsId="building-name-or-floor-number"
      test-id="addressLine2"
    />
    <app-form-error
      [errors]="getErrorsToDisplay('addressLine2')"
      test-id="formErrorAddressLine2"
    ></app-form-error>
  </div>

  <!-- Street Address -->
  <div class="form-group">
    <label class="form-group__label form-group__label--required">Street Address</label>
    <input
      class="form-input"
      type="text"
      formControlName="addressLine1"
      analyticsId="street-address"
      test-id="addressLine1"
    />
    <app-form-error
      [errors]="getErrorsToDisplay('addressLine1')"
      test-id="formErrorAddressLine1"
    ></app-form-error>
  </div>

  <!-- Address Line 3  -->
  <div class="form-group">
    <label class="form-group__label">Address Line 3</label>
    <input
      class="form-input"
      type="text"
      formControlName="addressLine3"
      analyticsId="address-line-3"
      test-id="addressLine3"
    />
    <app-form-error
      [errors]="getErrorsToDisplay('addressLine3')"
      test-id="formErrorAddressLine3"
    ></app-form-error>
  </div>

  <div class="row">

    <!-- City -->
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-group__label form-group__label--required">City</label>
        <input
          class="form-input"
          type="text"
          formControlName="city"
          test-id="city"
          analyticsId="city"
        />
        <app-form-error
          [errors]="getErrorsToDisplay('city')"
          test-id="formErrorCity"
        ></app-form-error>
      </div>
    </div>

    <!-- Postcode  -->
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-group__label form-group__label--required">
          Postcode
          <i
            class="material-icons ml-x-small icon icon--small"
            tooltip="Unable to edit postcode during checkout, go back to the search page to change the delivery postcode"
            tooltipPosition="top"
          >help_outline</i>
        </label>
        <input
          class="form-input"
          type="text"
          formControlName="postcode"
          autocomplete="force-off"
          test-id="postcode"
          analyticsId="postcode"
        />
        <app-form-error
          [errors]="getErrorsToDisplay('postcode')"
          test-id="formErrorPostcode"
        ></app-form-error>
      </div>
    </div>

  </div>

  <!-- Country -->
  <div class="form-group">
    <label class="form-group__label form-group__label--required">Country</label>
    <input
      class="form-input"
      type="text"
      formControlName="country"
      test-id="country"
      analyticsId="country"
    />
    <app-form-error
      [errors]="getErrorsToDisplay('country')"
      test-id="formErrorCountry"
    ></app-form-error>
  </div>
</form>
