import { toggleElement } from '@citypantry/util';
import { VendorId } from '@citypantry/util-models';
import { FavouriteVendorsAction, FavouriteVendorsActions } from './favourite-vendors.actions';

export type FavouriteVendorsState = Readonly<{
  favouritedVendorIds: VendorId[];
}>;

export const INITIAL_STATE: FavouriteVendorsState = {
  favouritedVendorIds: []
};

export function reducer(
  state: FavouriteVendorsState = INITIAL_STATE,
  action: FavouriteVendorsAction,
): FavouriteVendorsState {
  switch (action.type) {
    case FavouriteVendorsActions.toggleVendorFromSearchComponent.type:
    case FavouriteVendorsActions.toggleVendorFromCustomerMenu.type:
      return {
        ...state,
        favouritedVendorIds: toggleElement(state.favouritedVendorIds, action.vendorId),
      };

    case FavouriteVendorsActions.favouritesLoadedFromAuth.type:
      return {
        ...state,
        favouritedVendorIds: action.favouriteVendors
      };

    default:
      return state;
  }
}

export function deserialiseFavouriteVendorsState(state: Partial<FavouriteVendorsState>): FavouriteVendorsState {
  try {
    return {
      ...INITIAL_STATE,
      ...state,
    };
  } catch (e) {
    console.error(e);
    return INITIAL_STATE;
  }
}
