import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyHolidaysEffects } from './company-holidays/company-holidays.effects';
import {
  FEATURE_STATE_NAME as COMPANY_HOLIDAYS_STATE_NAME,
  reducer as companyHolidaysReducer
} from './company-holidays/company-holidays.state';
import { CpDatePickerDirective } from './cp-date-picker/cp-date-picker.directive';
import { OperationalRegionsEffects } from './operational-regions/operational-regions.effects';
import {
  FEATURE_STATE_NAME as OPERATIONAL_REGIONS_STATE_NAME,
  reducer as operationalRegionsReducer
} from './operational-regions/operational-regions.state';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      CompanyHolidaysEffects,
      OperationalRegionsEffects,
    ]),
    StoreModule.forFeature(COMPANY_HOLIDAYS_STATE_NAME, companyHolidaysReducer),
    StoreModule.forFeature(OPERATIONAL_REGIONS_STATE_NAME, operationalRegionsReducer),
  ],
  providers: [
    CompanyHolidaysEffects,
    OperationalRegionsEffects,
  ],
  declarations: [
    CpDatePickerDirective,
  ],
  exports: [
    CpDatePickerDirective,
  ]
})
export class RootModule {}
