import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ExternalLinkTemplateContext } from '../../header.component';

@Component({
  selector: 'app-default-user-header',
  templateUrl: './default-user-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultUserHeaderComponent {
  @Input()
  public isLoggedIn: boolean;

  @Input()
  public externalLinkTemplate: TemplateRef<ExternalLinkTemplateContext>;

  @Input()
  public separatorTemplate: TemplateRef<void>;

  @Input()
  public searchFoodButtonTemplate: TemplateRef<void>;
}
