import { Directive, Input } from '@angular/core';
import { ItemBundle, SingleItem } from '@citypantry/util-models';

/**
 * This directive enables context specific data to be accessed inside of dumb components without the use of @Input
 *
 * Using @Input to inject context specific analytics data on dumb and generic components breaks the convention.
 * They should be unaware of their specific context, and focus on their core functionality.
 *
 * When this directive is placed on the dumb component, 'AnalyticsEnhancedEcommerceDirective' will traverse up its ancestor tree
 * and inject the closest AnalyticsDataDirective. This provides the dumb component with specific analytics data without
 * compromising it's dumbness ie using @Input to supply quantity-picker with an Item, making the quantity picker aware that
 * it is for an item.
 */
@Directive({
  selector: '[analyticsItem]'
})
export class AnalyticsDataDirective {

  @Input()
  // TODO CPD-9126: update typing to include CustomItems
  public analyticsItem: SingleItem | ItemBundle;

}
