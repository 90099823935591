import { Address, createAddressFromJson } from '../address.model';
import { DeliveryContact } from '../order';
import { CustomerId } from './customer.model';

declare const CustomerLocationIdSymbol: unique symbol;
export type CustomerLocationId = string & {
  [CustomerLocationIdSymbol]: never;
};

export interface CustomerLocation extends Address {
  id: CustomerLocationId;

  humanId: string;
  name: string;
  shareToken: string;
  companyName: string;
  customerId: CustomerId;
  defaultDeliveryContact: DeliveryContact;

  deliveryInstructions: string;
  cityPantryDeliveryInstructions?: string;
  loadingTime: number;
}

export interface NewDeliveryLocation extends Address {
  name: string;
  companyName: string;
  defaultDeliveryContact: DeliveryContact;
  deliveryInstructions: string;
}

interface CustomerLocationJsonProperties {
  type: 'location';
  customer: CustomerId;
  members: any;
  billingAddressId?: string;
  billingAddress?: any;
}

export function createCustomerLocationFromJson(json?: Partial<CustomerLocation & CustomerLocationJsonProperties>): CustomerLocation {
  json = json || {};
  const location: Partial<CustomerLocation> = {
    ...createAddressFromJson(json),
    id: json.id as any as CustomerLocationId,
  };

  location.humanId = json.humanId;
  location.name = json.name;
  location.shareToken = json.shareToken;
  location.companyName = json.companyName;
  location.customerId = json.customer;
  location.defaultDeliveryContact = json.defaultDeliveryContact;

  location.deliveryInstructions = json.deliveryInstructions;
  location.cityPantryDeliveryInstructions = json.cityPantryDeliveryInstructions;
  location.loadingTime = json.loadingTime;

  return location as CustomerLocation;
}
