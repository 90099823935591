import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { FEATURE_STATE_NAME, OperationalRegionsState } from './operational-regions.state';

const getOperationalRegionsState = createFeatureSelector<OperationalRegionsState>(FEATURE_STATE_NAME);

export const getAllOperationalRegions = (state: OperationalRegionsState): string[] => state.regions;

export const OperationalRegionsSelectors = mapSelectors(getOperationalRegionsState, {
  getAll: getAllOperationalRegions,
});
