import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyWithBlank',
  pure: true
})
export class CurrencyWithBlankPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
  ) {}

  // TODO: CPD-9503 remove the default currency code when all calls have value passed in.
  public transform(value: number | null, currencyCode: string = 'GBP'): any {
    if (typeof value === 'number') {
      return this.currencyPipe.transform(value, currencyCode);
    } else {
      const symbol = getCurrencySymbol(currencyCode, 'narrow');
      return `${symbol}--.--`;
    }
  }
}
