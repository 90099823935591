import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ItemBundle,
  ItemDeliverabilityProblem,
  numberOfVisibleDietariesSet,
  VatRateTypes,
  zeroVatRateTypes,
} from '@citypantry/util-models';

@Component({
  selector: 'app-item-bundle',
  styles: [`:host { display: flex; flex-grow: 1; }`],
  templateUrl: './item-bundle.component.html',
})
export class ItemBundleComponent {
  @Input()
  public deliverabilityProblems: ItemDeliverabilityProblem[];

  @Input()
  public itemBundle: ItemBundle;

  @Input()
  public selectionEnabled: boolean;

  @Input()
  public hidePrices: boolean;

  @Output()
  public displayItemBundle: EventEmitter<ItemBundle> = new EventEmitter();

  @Output()
  public imageClick: EventEmitter<void> = new EventEmitter();

  public zeroVatRateTypes: VatRateTypes[];

  constructor() {
    this.deliverabilityProblems = [];
    this.zeroVatRateTypes = zeroVatRateTypes;
  }

  public get disabled(): boolean {
    return this.deliverabilityProblems && this.deliverabilityProblems.length > 0;
  }

  public select(): void {
    if (!this.disabled) {
      this.displayItemBundle.emit(this.itemBundle);
    }
  }

  public hasDietaries(): boolean {
    return numberOfVisibleDietariesSet(this.itemBundle.dietaries) > 0;
  }

  public calculatePricePerPerson(): number {
    return this.itemBundle.price / (this.itemBundle.portionSize || 1);
  }

  public getImage(): string {
    return this.itemBundle && this.itemBundle.images.length && this.itemBundle.images[0].medium;
  }

  public getImageCount(): number {
    return this.itemBundle && this.itemBundle.images.length || 0;
  }

  public onClickImage(): void {
    this.imageClick.emit();
  }
}
