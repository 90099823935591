import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import { TypedSimpleChanges } from '@citypantry/util';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnChanges, OnInit, OnDestroy {

  @Input()
  public tooltip: string | TemplateRef<unknown>;

  @Input()
  public tooltipDisabled: boolean;

  @Input()
  public tooltipPosition: 'top' | 'bottom' | 'left' | 'right' | 'center';

  @Input()
  public lockTooltip: undefined | 'open' | 'closed';

  private visible: boolean;

  private overlayRef: OverlayRef;

  private isTooltipContentActive: boolean;
  private isActive: boolean;
  private isInitialised: boolean;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
  ) {
    this.tooltipPosition = 'bottom';
  }

  public ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      // Create position attached to the elementRef
      .flexibleConnectedTo(this.elementRef)
      // Describe how to connect overlay to the elementRef
      .withPositions(this.computePositions());

    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy
    });

    this.checkTooltipOpenState();

    this.isInitialised = true;
  }

  public ngOnChanges(changes: TypedSimpleChanges<TooltipDirective>): void {
    if (changes['lockTooltip'] && this.isInitialised) {
      this.checkTooltipOpenState();
    }
  }

  public ngOnDestroy(): void {
    this.hide(true);
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  public show(): void {
    this.isActive = true;

    if (!this.tooltip || this.tooltipDisabled || this.visible || this.lockTooltip === 'closed') {
      return;
    }

    this.visible = true;
    const tooltipPortal = new ComponentPortal(TooltipComponent);

    // Attach tooltip portal to overlay
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

    // Pass content to tooltip component instance
    tooltipRef.instance.content = this.tooltip;
    tooltipRef.instance.placement = this.tooltipPosition;
    tooltipRef.instance.animateIn();

    tooltipRef.instance.activeStateChange.subscribe((tooltipHover: boolean) => {
      this.isTooltipContentActive = tooltipHover;
      this.hideIfNotActive();
    });
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onBecomeInactive(): void {
    this.isActive = false;
    this.hideIfNotActive();
  }

  /**
   * Hide the tooltip if neither the tooltip trigger element nor the content are being hovered over or have focus.
   * This is done in the next stack frame (on timeout) because the mouse may have moved from one to the other, and we want to
   * ensure the state is up to date before testing whether we need to hide.
   */
  private hideIfNotActive(): void {
    setTimeout(() => {
      if (!this.isActive && !this.isTooltipContentActive) {
        this.hide();
      }
    });
  }

  private hide(force: boolean = false): void {
    if (!force && (!this.visible || this.lockTooltip === 'open')) {
      return;
    }

    this.overlayRef.detach();
    this.visible = false;
  }

  private checkTooltipOpenState(): void {
    if (this.lockTooltip === 'open' && !this.visible) {
      this.show();
    } else if (this.lockTooltip === 'closed' && this.visible) {
      this.hide();
    }
  }

  /**
   * The tooltip component is rendered inside of a CDK overlay.
   *
   * Determine how to attach the overlay element to 'this' element (origin).
   * E.g. [origin (X:start/Y:start), overlay (X:end/Y:end)] means attach the overlay's bottom (Y:end) right (X:end) corner
   * to the element's top (Y:start) left (X:start) corner.
   * Examples:
   *
   * [origin (X:start/Y:start), overlay (X:end/Y:end)]
   * . . . . . . . |
   * . . Overlay . |
   * ______________|
   *               |‾‾‾‾‾‾‾‾‾‾|
   *               |  Origin  |
   *               |__________|
   *
   * [origin (X:start/Y:center), overlay (X:end/Y:center)]
   * . . . . . . . |
   * . . . . . . . |
   * . . . . . . . |‾‾‾‾‾‾‾‾‾‾|
   * . . Overlay . |  Origin  |
   * . . . . . . . |__________|
   * . . . . . . . |
   * . . . . . . . |
   *
   * [origin (X:center/Y:end), overlay (X:center/Y:start)]
   *               |‾‾‾‾‾‾‾‾‾‾|
   *               |  Origin  |
   * ______________|__________|______________
   * . . . . . . . . . . . . . . . . . . . . .
   * . . . . . . . .  Overlay  . . . . . . . .
   * . . . . . . . . . . . . . . . . . . . . .
   *
   */
  private computePositions(): ConnectedPosition[] {
    switch (this.tooltipPosition) {
      case 'left':
        return [{
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        }];
      case 'right':
        return [{
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        }];
      case 'top':
        return [{
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        }];
      case 'center':
        return [{
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY: 'top',
        }];
      case 'bottom':
      default:
        return [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }];
    }
  }
}
