import { CuisineType, DietaryType, EventType, VendorFlag } from '@citypantry/util-models';

export interface SearchFilters {
  cuisines: CuisineType[];
  events: EventType[];
  dietaries: DietaryType[];
  vendorFlags: VendorFlag[];
}

export const EMPTY_SEARCH_FILTERS: SearchFilters = {
  cuisines: [],
  events: [],
  dietaries: [],
  vendorFlags: []
};
Object.freeze(EMPTY_SEARCH_FILTERS);
