<div class="search-filters-bar-layout">
  <app-search-individual-choice-toggle
    class="search-filters-bar-layout__item"
    [enabled]="true"
    [toggleDisabled]="individualChoiceToggleDisabled"
    (enabledChange)="onIndividualChoiceDisabled()"
    test-id="icToggle"
  ></app-search-individual-choice-toggle>

  <app-search-filters-button
    class="search-filters-bar-layout__item"
    [(isExpanded)]="isFiltersListExpanded"
    [activeFiltersCount]="selectedFiltersCount"
    test-id="filtersButton"
  ></app-search-filters-button>

  <app-search-sort-dropdown
    class="search-filters-bar-layout__sort"
    [sortBy]="sortOrder"
    [showDistanceOption]="showDistanceSortOption"
    (sortByChange)="onSortOrderChange($event)"
    test-id="sort"
  ></app-search-sort-dropdown>

  <app-search-filters-list
    class="search-filters-bar-layout__filters-list"
    [isExpanded]="isFiltersListExpanded"
    [selectedFilters]="selectedFilters"
    (selectedFiltersChange)="onSelectedFiltersChange($event)"
    test-id="filtersList"
  ></app-search-filters-list>
</div>
