import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { Cart } from '../cart';
import { createCustomerFromJson, createCustomerLocationFromJson, Customer, CustomerLocation } from '../customer';
import { createIndividualChoiceFromJson, IndividualChoice } from '../individual-choice';
import { MajorCurrency } from '../money';
import { createVendorFromJson, createVendorLocationFromJson, Vendor, VendorLocation } from '../vendor';
import { PaymentCardId } from '../payment';
import { CostBreakdown, createCostBreakdownFromJson } from './cost-breakdown.model';
import { DeliveryContact } from './delivery-contact.model';
import { OrderId } from './order-id.model';
import { OrderSize, OrderSizes } from './order-size.enum';
import { OrderStatus, OrderStatuses } from './order-status.enum';
import { PaymentTerm, PaymentTerms } from './payment-term.enum';

type TODO = any; // Type for untyped fields; if you use one of these fields please add a type interface

export interface Order {
  id: OrderId;
  humanId: number;
  status: OrderStatus;
  paymentTerm: PaymentTerm;
  paymentCardId: PaymentCardId | null;
  date: moment.Moment;

  // Pickup info
  vendor: Vendor;
  pickupAddress?: VendorLocation;
  pickupDate?: moment.Moment;
  returnRequired: boolean | null;
  returnRequiredDefault: boolean;
  orderSize: OrderSize | null;
  orderSizeDefault: OrderSize;
  orderSizeChangeReason: string | null;

  // Dropoff info
  customer: Customer;
  location: CustomerLocation;
  deliveryContact: DeliveryContact;
  displayDeliveryContact: DeliveryContact;
  displayDeliveryInstructions: string;
  requestedDeliveryDate?: moment.Moment;
  worryFreePeriodEndDate: moment.Moment;
  deliveryInstructions?: string;
  cityPantryDeliveryInstructions?: string;
  useDeskNumbers?: boolean | null;

  // Cart & Price info
  cart?: Cart;
  costBreakdown: CostBreakdown;
  customerCostBreakdown: CostBreakdown;

  // Individual Choice
  individualChoice: IndividualChoice | null;

  // Other
  vendorOwnDelivery: boolean;
  vendorOwnDeliveryOverridden: boolean;
  hasRequestedDeliveryDatePassed: boolean;
  willVendorDeliverCutleryAndServiettes: boolean;
  dietaryRequirements: string;
  isMealPlan: boolean;
  invoiceOnly: boolean;
  hasBeenInvoiced: boolean;
  vendorHasSetPickupLocation: boolean;
  headcount: number;
  popup: boolean;
  purchaseOrderNumber: string;
  departmentReference: string;
  clubZeroCode: string|null;
}

/**
 * Properties present in the JSON that will not be converted
 */
interface OrderJsonProperties {
  // Values that get mapped differently, e.g. enum properties where we use the text representation only
  // Or values whose name in the Order interface is different than the API return value
  status: OrderStatus;
  dietaryRequirementsText: string;
  packagingTypeChoice?: number;
  packagingTypeChoiceText?: string;

  // Values we don't need right now; if you really need them, move them up to the Order model
  promoCode: TODO;
  promoCodeCode?: TODO;
  promoCodeAmount?: MajorCurrency;

  reasonsForManualUpdate: string[];
  hasChanged: boolean;
  changedDate?: moment.Moment;
  calculatedPickupDate?: moment.Moment;
  extras: string; // "Cutlery and Serviettes" or "None", refers to extras the vendor has to supply

  customerUser: TODO;
  date: moment.Moment;
  hasRequestedDeliveryDatePassed: boolean;
  location: TODO;
  parkingSuggestion?: string;

  serviceFeeAmount: MajorCurrency;
  isPaidOnAccount: boolean;
  paidOnAccountStatus: null | 0 | 1;
  hasBeenInvoiced: boolean;
  subTotalVatAmount: MajorCurrency;
  subTotalExcludingVat: MajorCurrency;
  subTotalIncludingVat: MajorCurrency;
  totalAmountBeforePromoCode: MajorCurrency;
  totalAmountAfterPromoCode: MajorCurrency;
  totalAmountExcludingVatBeforePromoCode: MajorCurrency;
  shippingExcludingVatAmount: MajorCurrency;
  shippingIncludingVatAmount: MajorCurrency;
  shippingVatAmount: MajorCurrency;
  totalTaxAmount: MajorCurrency;

  leftKitchenDate?: moment.Moment;
  customerServiceEvents: TODO[];
  drivingTimeFromPickupAddressToDeliveryAddress: number;
  purchaseOrderNumber: string;
  departmentReference: string;
  hasBeenReviewed: boolean;
  teamReviewCount: number;
  hasBeenRefunded: boolean;
  confirmedRefundValueFromCP?: number;
  confirmedRefundValueFromVendor?: number;
  isMealPlan: boolean; // => $this->isMealPlan(),
  driverDelay: number;
  deliveryInstructions: string;
  cityPantryDeliveryInstructions: string;
  packageTotalGrossFoodCost?: number;
  progress: TODO;
  feedback: TODO;
  forEvent: boolean;
  containsHotFood: boolean;
  deliveryDistance: number;
  trackingUrl?: string;
  customerStatus?: TODO;
  lastUpdated?: moment.Moment;
  vendorOwnDelivery: boolean;
  invoiceOnly: boolean;
  popup: boolean;

  // These properties are valid Order properties but should not be relevant in this project.
  // If that changes, map them and move them back up to Order.
  packageItemQuantities: TODO;
  package?: TODO;
  oldPackage?: TODO;
  deliveryAddress: TODO; // deprecated, use location or deliveryContact

  isDeleted: boolean;
  isPast: boolean; // Duplicate of hasRequestedDeliveryDatePassed but fails when requested delivery date is null - do not use

  usesServiceFee: boolean; // not used anywhere currently
}

export function createOrderFromJson(json?: Partial<Order & OrderJsonProperties>): Order {
  // TODO: Clean this up before finishing CPD-1702
  json = json || {};

  const order: Partial<Order> = {};

  order.id = json.id;
  order.humanId = json.humanId;
  order.customer = createCustomerFromJson(json.customer);
  // order.customerUser = json.customerUser; // TODO
  order.vendor = createVendorFromJson(json.vendor);

  // order.promoCode = json.promoCode;
  // order.promoCodeCode = json.promoCodeCode;
  // order.promoCodeAmount = json.promoCodeAmount;
  //
  // order.reasonsForManualUpdate = json.reasonsForManualUpdate || [];
  order.date = moment(json.date);
  // order.hasChanged = json.hasChanged;
  // order.changedDate = json.changedDate ? moment(json.changedDate) : null;
  // order.calculatedPickupDate = json.calculatedPickupDate ? moment(json.calculatedPickupDate) : null;
  order.pickupDate = json.pickupDate ? moment(json.pickupDate) : null;
  order.pickupAddress = json.pickupAddress ? createVendorLocationFromJson(json.pickupAddress) : null;
  order.requestedDeliveryDate = json.requestedDeliveryDate ? moment(json.requestedDeliveryDate) : null;
  order.worryFreePeriodEndDate = json.worryFreePeriodEndDate ? moment(json.worryFreePeriodEndDate) : null;
  order.hasRequestedDeliveryDatePassed = json.hasRequestedDeliveryDatePassed;
  order.location = createCustomerLocationFromJson(json.location); // May need more fields as Location extends Address
  // order.parkingSuggestion = json.parkingSuggestion;
  order.headcount = json.headcount;

  // cost attributes
  order.costBreakdown = createCostBreakdownFromJson(json.costBreakdown);
  order.customerCostBreakdown = createCostBreakdownFromJson(json.customerCostBreakdown);

  // order.leftKitchenDate = json.leftKitchenDate ? moment(json.leftKitchenDate) : null;
  // order.customerServiceEvents = json.customerServiceEvents || [];
  // order.drivingTimeFromPickupAddressToDeliveryAddress = json.drivingTimeFromPickupAddressToDeliveryAddress;
  order.purchaseOrderNumber = json.purchaseOrderNumber;
  order.departmentReference = json.departmentReference;
  // order.hasBeenReviewed = json.hasBeenReviewed;
  // order.teamReviewCount = json.teamReviewCount;
  order.willVendorDeliverCutleryAndServiettes = json.willVendorDeliverCutleryAndServiettes;

  // order.hasBeenRefunded = json.hasBeenRefunded;
  // order.confirmedRefundValueFromCP = json.confirmedRefundValueFromCP;
  // order.confirmedRefundValueFromVendor = json.confirmedRefundValueFromVendor;
  order.isMealPlan = json.isMealPlan;
  order.paymentTerm = PaymentTerms.mapApiValueToEnum(json.paymentTerm);
  order.paymentCardId = json.paymentCardId;

  order.cart = json.cart; // TODO create cart from JSON

  // order.driverDelay = json.driverDelay;
  order.displayDeliveryContact = json.displayDeliveryContact;
  order.deliveryContact = json.deliveryContact;
  order.displayDeliveryInstructions = json.displayDeliveryInstructions;
  order.deliveryInstructions = json.deliveryInstructions;
  order.cityPantryDeliveryInstructions = json.cityPantryDeliveryInstructions;
  order.useDeskNumbers = json.useDeskNumbers || null;
  // order.packageTotalGrossFoodCost = json.packageTotalGrossFoodCost;
  // order.extras = json.extras;
  // order.progress = json.progress;
  // order.feedback = json.feedback;
  // order.forEvent = json.forEvent;
  // order.containsHotFood = json.containsHotFood;
  // order.deliveryDistance = json.deliveryDistance;
  // order.isPast = json.isPast;
  // order.trackingUrl = json.trackingUrl;
  // order.customerStatus = json.customerStatus;
  // order.lastUpdated = json.lastUpdated ? moment(json.lastUpdated) : null;
  // order.isDeleted = json.isDeleted;
  order.individualChoice = json.individualChoice ? createIndividualChoiceFromJson(json.individualChoice) : null;
  order.vendorOwnDelivery = json.vendorOwnDelivery;
  order.invoiceOnly = json.invoiceOnly;
  order.hasBeenInvoiced = json.hasBeenInvoiced;
  order.vendorHasSetPickupLocation = json.vendorHasSetPickupLocation;
  order.returnRequired = json.returnRequired;
  order.returnRequiredDefault = json.returnRequiredDefault;
  order.popup = json.popup;

  // Enum mappings
  order.status = ensureValidEnumValue(OrderStatuses, json.status);
  // order.packagingTypeChoice = PackagingTypeChoices.fromBackendValue(json.packagingTypeChoice);

  order.orderSize = json.orderSize ? ensureValidEnumValue(OrderSizes, json.orderSize) : null;
  order.orderSizeDefault = ensureValidEnumValue(OrderSizes, json.orderSizeDefault);
  order.orderSizeChangeReason = json.orderSizeChangeReason ? json.orderSizeChangeReason : null;

  // Custom mappings
  order.dietaryRequirements = json.dietaryRequirementsText;
  order.clubZeroCode = json.clubZeroCode ? json.clubZeroCode : null;

  return order as Order;
}
