<div
  class="lostbar lostbar-layout"
  [class.lostbar--fixed]="isFixed"
  analyticsId="lostbar"
  test-id="lostbar"
>
<!-- the DOM element id is used in ScrollHandleDirective.scrollOffsetElements -->
  <div
    class="lostbar__wrapper"
    [class.lostbar__wrapper--customer]="headerType === HeaderTypeEnum.PURCHASER"
    [class.lostbar__wrapper--restaurant]="headerType === HeaderTypeEnum.VENDOR"
    #lostbar
    id="lostbar"
    test-id="lostbarWrapper"
  >
    <div class="lostbar__user-name">
      <strong
        test-id="name"
        e2e-test-id="lostbarUserName"
      >{{ name }}</strong> &mdash;


      <!-- Note: This is displayed based on the currently masqueraded user, and HeaderType is unrelated to the LostbarResult model -->
      <span
        [ngSwitch]="headerType"
        test-id="details"
      >
        <ng-container *ngSwitchCase="HeaderTypeEnum.STAFF">
          (admin)
        </ng-container>

        <ng-container *ngSwitchCase="HeaderTypeEnum.VENDOR">
          {{ companyName || 'No Company Name' }} (restaurant)
        </ng-container>

        <ng-container *ngSwitchCase="HeaderTypeEnum.PURCHASER">
          {{ companyName || 'No Company Name' }} (customer)
        </ng-container>

        <ng-container *ngSwitchCase="HeaderTypeEnum.EATER">
          (eater)
        </ng-container>
      </span>
    </div>

    <div class="lostbar__input-wrapper">
      <input
        type="text"
        title="Lostbar input"
        class="lostbar__input"
        placeholder="Search..."
        [ngModel]="query"
        (ngModelChange)="onQueryChange($event)"
        (keydown)="onInputKeyDown($event)"
        (keyup)="onInputKeyUp($event)"
        analyticsId="search"
        [analyticsData]="{ query: query }"
        test-id="searchInput"
      />

      <div
        *ngIf="isDropdownOpen"
        class="dropdown-menu lostbar-layout__dropdown-menu"
        (clickOutside)="closeDropdown()"
        test-id="dropdown"
      >
        <div
          *ngFor="let result of searchResults; let i = index"
          class="dropdown-menu__match lostbar-layout__dropdown-menu-match"
          (mouseover)="onResultMouseover(i)"
          [class.lostbar-layout__dropdown-menu-match--highlighted]="i === highlightedResultIndex"
          test-id="resultWrapper"
        >
          <!-- Meal plans -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.MEAL_PLANS"
            class="lostbar__result"
          >
            <div
              class="lostbar__result-detail lostbar__result-detail--customer lostbar-layout__result-detail"
              (click)="onEditMealplan(result._id)"
              analyticsId="edit-mealplan"
              [analyticsData]="{ mealplanId: result._id }"
              test-id="mealPlanResult"
            >
              <strong>{{ result.customer.companyName }}</strong>
              <span> from {{ result.startDate | date:'EEE d MMM' }}</span>
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--customer"
              (click)="onViewMealplanAsCustomer(result._id, result.customer._id)"
              analyticsId="view-mealplan-as-customer"
              [analyticsData]="{ mealplanId: result._id }"
              test-id="mealPlanViewButton"
            >View as Customer</span>
            <span
              class="lostbar__result-action lostbar__result-action--customer-secondary"
              (click)="onEditMealplan(result._id)"
              analyticsId="edit-mealplan"
              [analyticsData]="{ mealplanId: result._id }"
              test-id="mealPlanEditButton"
            >Edit</span>
          </div>

          <!-- Customers -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.CUSTOMERS"
            class="lostbar__result"

          >
            <div
              class="lostbar__result-detail lostbar__result-detail--customer lostbar-layout__result-detail"
              (click)="onMasquerade(result.user._id)"
              analyticsId="masquerade-as-customer"
              [analyticsData]="{ customerId: result.user._id }"
              test-id="customerResult"
            >
              <strong>{{ result.companyName }}</strong>
              <span> ({{ result.user.firstName }} {{ result.user.lastName }})</span>
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--customer-secondary"
              (click)="onEditCustomer(result._id)"
              analyticsId="edit-customer"
              [analyticsData]="{ customerId: result._id }"
              test-id="customerEdit"
            >Edit Customer</span>
            <span
              class="lostbar__result-action lostbar__result-action--customer"
              (click)="onMasquerade(result.user._id)"
              analyticsId="masquerade-as-customer"
              [analyticsData]="{ customerId: result.user._id }"
              test-id="customerMasquerade"
            >Masquerade</span>
          </div>

          <!-- Members -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.MEMBERS"
            class="lostbar__result"
            >
            <div
              class="lostbar__result-detail lostbar__result-detail--customer lostbar-layout__result-detail"
              (click)="onMasquerade(result._id)"
              analyticsId="masquerade-as-customer-user"
              [analyticsData]="{ userId: result._id }"
              test-id="customerUserResult"
            >
              <strong>{{ result.customer.companyName }}</strong>
              ({{ result.firstName }} {{ result.lastName }})
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--customer-secondary"
              (click)="$event.stopPropagation(); onEditCustomer(result.customer._id)"
              analyticsId="edit-customer-by-user"
              [analyticsData]="{ customerId: result.customer._id }"
              test-id="customerUserEdit"
            >Edit Customer</span>
            <span
              class="lostbar__result-action lostbar__result-action--customer"
              (click)="$event.stopPropagation(); onMasquerade(result._id)"
              analyticsId="masquerade-as-customer-user"
              [analyticsData]="{ userId: result._id }"
              test-id="customerUserMasquerade"
            >Masquerade</span>
          </div>

          <!-- Vendors -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.VENDORS"
            class="lostbar__result"
          >
            <div
              class="lostbar__result-detail lostbar__result-detail--restaurant lostbar-layout__result-detail"
              (click)="onMasquerade(result.user._id)"
              analyticsId="masquerade-as-vendor"
              [analyticsData]="{ vendorId: result._id }"
              test-id="vendorResult"
            >
              <strong>{{ result.name }}</strong>
              <span> ({{ result.user.firstName }} {{ result.user.lastName }})</span>
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--restaurant-secondary"
              (click)="onEditVendor(result._id)"
              analyticsId="edit-vendor"
              [analyticsData]="{ vendorId: result._id }"
              test-id="vendorEdit"
            >Edit Restaurant</span>
            <span
              class="lostbar__result-action lostbar__result-action--restaurant"
              (click)="onMasquerade(result.user._id)"
              analyticsId="masquerade-as-vendor"
              [analyticsData]="{ vendorId: result._id }"
              test-id="vendorMasquerade"
            >Masquerade</span>
          </div>

          <!-- Vendor user -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.VENDOR_USERS"
            class="lostbar__result"
          >
            <div
              class="lostbar__result-detail  lostbar__result-detail--restaurant lostbar-layout__result-detail"
              (click)="onMasquerade(result._id)"
              analyticsId="masquerade-as-vendor-user"
              [analyticsData]="{ userId: result._id }"
              test-id="vendorUserResult"
            >
              <strong>{{ result.vendor.name }}</strong>
              <span> ({{ result.firstName }} {{ result.lastName }})</span>
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--restaurant-secondary"
              (click)="onEditVendor(result.vendor._id)"
              analyticsId="edit-vendor-by-user"
              [analyticsData]="{ vendorId: result.vendor._id }"
              test-id="vendorUserEdit"
            >Edit Restaurant</span>
            <span
              class="lostbar__result-action lostbar__result-action--restaurant"
              (click)="onMasquerade(result._id)"
              analyticsId="masquerade-as-vendor-user"
              [analyticsData]="{ userId: result._id }"
              test-id="vendorUserMasquerade"
            >Masquerade</span>
          </div>

          <!-- Generic User -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.USERS"
            class="lostbar__result"
          >
            <div
              class="lostbar__result-detail lostbar-layout__result-detail"
              (click)="onMasquerade(result._id)"
              analyticsId="masquerade-as-user"
              [analyticsData]="{ userId: result._id }"
              test-id="userResult"
            >
              <strong>{{ result.firstName }} {{ result.lastName }}</strong> ({{ result.email }})
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--admin"
              (click)="onMasquerade(result._id)"
              analyticsId="masquerade-as-user"
              [analyticsData]="{ userId: result._id }"
              test-id="userMasquerade"
            >Masquerade</span>
          </div>

          <!-- Orders -->
          <div
            *ngIf="result.group === LostbarResultGroupTypeEnum.ORDERS"
            class="lostbar__result"
            (click)="onEditOrder(result._id)"
            analyticsId="edit-order"
            [analyticsData]="{ orderId: result._id }"
            test-id="orderResultContainer"
          >
            <div class="lostbar__result-detail">
              <strong>{{ result.vendor.name }} → {{ result.customer.companyName }}</strong>
              <span> for {{ result.requestedDeliveryDate | date:"H:mm [on] EEE d MMM" }}</span>
            </div>
            <span
              class="lostbar__result-action lostbar__result-action--admin"
            >Edit</span>
          </div>
        </div>
      </div>
    </div>
    <div class="lostbar__action-wrapper">
      <button
        *ngIf="isSudo"
        class="lostbar__action"
        (click)="onUnmasquerade()"
        analyticsId="unmasquerade"
        test-id="unmasqueradeButton"
        e2e-test-id="unmasqueradeButton"
      >
        Unmasquerade
      </button>
      <div
        class="lostbar__loader"
        *ngIf="isLoading"
      >
        <div class="circle-spinner"></div>
      </div>
    </div>
  </div>
</div>
