import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const extrasPageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    path: 'menu-header dietaries diet-icon',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `${event.data['diet-icon'].name} dietary toggled ${event.data['diet-icon'].active ? 'off' : 'on'}`,
    }),
  },
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    path: 'menu-content single-item more-info',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'More info',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    // In case we want to specify:
    // options: single-item, item-bundle-modal-form upgrade-group-item, item-bundle-modal-form flexible-group-item
    path: 'menu-content quantity-picker decrement',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker minus button clicked',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    path: 'menu-content quantity-picker text-box',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker text-box changed',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    path: 'menu-content quantity-picker increment',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker plus button clicked',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId/:childOrderId/extras',
    path: 'menu-content menu-item-image',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData => {
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.VENDORS_MENU,
        label: 'Item image clicked',
        extraFields: {
          imageCount: event.data['menu-item-image'].imageCount,
          itemType: event.data['menu-item-image'].itemType,
        },
      };
    }
  },
];
