// TODO rename to Location
export interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  country?: string;
  county?: string;
  landlineNumber?: string;
  latitude?: number;
  longitude?: number;
  postcode: string;
}

export interface AddressJsonProperties {
  countryName?: string;
}

export function createAddressFromJson(json: Partial<Address & AddressJsonProperties> = {}): Address {
  const address: Partial<Address> = {};

  address.addressLine1 = json.addressLine1;
  address.addressLine2 = json.addressLine2;
  address.addressLine3 = json.addressLine3;
  address.city = json.city;
  address.country = json.country || json.countryName || null;
  address.county = json.county;
  address.landlineNumber = json.landlineNumber;
  address.latitude = json.latitude;
  address.longitude = json.longitude;
  address.postcode = json.postcode;

  return address as Address;
}
