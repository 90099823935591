import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { countTrueValues, nonEmpty, sum } from '@citypantry/util';
import {
  CartItemBundle,
  CartItemBundleGroup,
  ChoiceItemGroup,
  isFlexibleItemGroup,
  ItemBundle,
  ItemGroup,
  ItemGroupTypes,
  SingleItem,
} from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';

interface ItemCounts {
  [itemId: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class ItemBundleFormService {

  constructor(
    private fb: FormBuilder
  ) {}

  public createForm(itemBundle: ItemBundle, bundleCartItem?: CartItemBundle, canOverrideMax?: boolean ): FormGroup {
    const form = this.fb.group({
      quantity: [
        this.getBundleQuantity(itemBundle, bundleCartItem),
        [
          CustomValidators.min(itemBundle.minimumOrderQuantity),
          canOverrideMax ? null : CustomValidators.max(itemBundle.maximumOrderQuantity),
        ].filter(nonEmpty)
      ],
      itemGroups: this.createItemGroupsControlArray(itemBundle, bundleCartItem)
    });

    this.registerOnItemBundleQuantityChange(form, itemBundle);

    return form;
  }

  private getBundleQuantity(itemBundle: ItemBundle, bundleCartItem?: CartItemBundle): number {
    if (bundleCartItem) {
      return bundleCartItem.quantity;
    } else {
      return itemBundle.minimumOrderQuantity || 1;
    }
  }

  private createItemGroupsControlArray(itemBundle: ItemBundle, bundleCartItem?: CartItemBundle): FormArray {
    let hasFlexibleGroups = false;
    return this.fb.array(itemBundle.groups.map((group: ItemGroup, index: number) => {
      switch (group.type) {

        case ItemGroupTypes.FLEXIBLE_GROUP:
        case ItemGroupTypes.UPGRADE_GROUP: {

          let validator = null;
          if (group.type === ItemGroupTypes.FLEXIBLE_GROUP) {
            const isFirstFlexibleGroup = !hasFlexibleGroups;
            hasFlexibleGroups = true;
            validator = !isFirstFlexibleGroup ? validateGroupQuantityEqualsBundleQuantity : null;
          }

          const controlGroup = this.createQuantityControlGroup(
            group,
            itemBundle,
            bundleCartItem ? bundleCartItem.groups[index] : null
          );
          if (validator) {
            controlGroup.setValidators([].concat(controlGroup.validator || [], validator));
          }

          return controlGroup;
        }

        case ItemGroupTypes.CHOICE_GROUP:
          return this.createCheckboxControlGroup(
            group as ChoiceItemGroup,
            bundleCartItem ? bundleCartItem.groups[index] : null
          );

        case ItemGroupTypes.FIXED_GROUP:
        default:
          return this.fb.group({}); // Will create an empty form group
      }
    }));
  }

  /**
   * Creates a quantity-based form control group with quantities set to 0 by default.
   */
  private createQuantityControlGroup(
    group: ItemGroup,
    itemBundle: ItemBundle,
    cartGroup: CartItemBundleGroup | null
  ): FormGroup {
    const items: { [key: string]: number } = {};
    group.items.forEach((item: SingleItem) => {
      const existingItem = cartGroup && cartGroup.cartItems.find((cartItem) => cartItem.item.id === item.id);
      items[item.id] = existingItem ? existingItem.quantity : 0;
    });

    if (!cartGroup && group.type === ItemGroupTypes.FLEXIBLE_GROUP) {
      // We want to distribute the quantities evenly the first time we set up the group.
      this.setItemQuantitiesEvenly(items, itemBundle.minimumOrderQuantity);
    }

    return this.fb.group(items);
  }

  private createCheckboxControlGroup(group: ChoiceItemGroup, cartGroup?: CartItemBundleGroup): FormGroup {
    const itemsGroup: { [key: string]: boolean } = {};
    const validator = (control: AbstractControl) => validateCountIsWithinRange(
      control,
      group.minimumOrderQuantity,
      group.maximumOrderQuantity
    );
    group.items.forEach((item: SingleItem) => {
      const checked = cartGroup && cartGroup.cartItems.find((cartItem) => cartItem.item.id === item.id && cartItem.quantity > 0);
      itemsGroup[item.id] = !!checked;
    });

    return this.fb.group(itemsGroup, { validators: [validator] });
  }

  /**
   * If item bundle quantity changes, we want to update the validity of item groups as they may no longer be valid
   */
  private registerOnItemBundleQuantityChange(form: FormGroup, itemBundle: ItemBundle): void {
    const quantityControl: FormControl = form.get('quantity') as FormControl;

    const flexibleGroupControls = (form.get('itemGroups') as FormArray).controls
      .filter((_, index) => isFlexibleItemGroup(itemBundle.groups[index]));

    if (!flexibleGroupControls.length) {
      return;
    }

    const [primaryFlexibleGroupControl, ...secondaryFlexibleGroupControls] = flexibleGroupControls;

    primaryFlexibleGroupControl.valueChanges.subscribe((value: ItemCounts) => {
      const total = computeItemsTotalQuantity(value);
      if (total !== quantityControl.value) {
        quantityControl.setValue(total, { onlySelf: false, emitEvent: true });
      }
    });

    quantityControl.valueChanges.subscribe(() => {
      secondaryFlexibleGroupControls.forEach((group: FormGroup) => {
        group.updateValueAndValidity(); // Re-validate
      });
    });
  }

  private setItemQuantitiesEvenly(items: { [key: string]: number }, newQuantity: number): void {
    const currentQuantity = computeItemsTotalQuantity(items);
    const diff = Math.abs(newQuantity - currentQuantity);
    const increment = newQuantity > currentQuantity ? 1 : -1;

    for (let i = 0; i < diff; i++) {
      let id;
      if (increment > 0) {
        id = this.getLeastPopularItemId(items);
      } else {
        id = this.getMostPopularItemId(items);
      }

      if (id !== undefined) {
        items[id] += increment;
      }
    }
  }

  /**
   * Returns the id of the item that has the lowest value or undefined if the items hash is empty.
   */
  private getLeastPopularItemId(items: { [id: string]: number }): string | undefined {
    if (Object.keys(items).length === 0) {
      return undefined;
    }
    return Object.keys(items).reduce((leastPopularId, id) => items[id] < items[leastPopularId] ? id : leastPopularId);
  }

  /**
   * Returns the id of the item that has the highest value or undefined if the items hash is empty.
   */
  private getMostPopularItemId(items: { [id: string]: number }): string | undefined {
    if (Object.keys(items).length === 0) {
      return undefined;
    }
    return Object.keys(items).reduce((mostPopular, id) => items[id] > items[mostPopular] ? id : mostPopular);
  }
}

/**
 * Validates that the sum the item group values equals the quantity of the bundle.
 */
export function validateGroupQuantityEqualsBundleQuantity(groupObject: AbstractControl): { [errorKey: string]: any } {
  const groupTotal = computeItemsTotalQuantity(groupObject.value);
  const quantityControl = groupObject.root.get('quantity') as FormControl;
  if (!quantityControl || !quantityControl.value) {
    return null;
  }
  const itemBundleQuantity = quantityControl.value;
  if (isValueOutsideRange(quantityControl)) {
    return null; // Don't show an error if quantity is completely invalid
  }

  if (groupTotal !== itemBundleQuantity) {
    return { groupTotalIncorrect: { groupTotal, itemBundleQuantity } };
  }

  return null;
}

/**
 * Returns the sum of quantities of the given items.
 */
export function computeItemsTotalQuantity(items: ItemCounts): number {
  return Object.values(items).reduce(sum(), 0);
}

/**
 * Validates that the count of the ticked checkboxes is in between the two values.
 */
export function validateCountIsWithinRange(
  checkboxGroup: AbstractControl,
  min: number,
  max: number
): { [errorKey: string]: any } {
  const count = countTrueValues(checkboxGroup.value);
  if (count < min) {
    return { minChoices: { min, max } };
  }
  if (count > max) {
    return { maxChoices: { min, max } };
  }

  return null;
}

function isValueOutsideRange(control: FormControl): boolean {
  return control.errors && (control.errors['min'] || control.errors['max']);
}
