import { ValidatorFn, Validators } from '@angular/forms';

// Phone numbers prefixed with +44 must be followed by 10 digits long where the first one is not zero
// Phone numbers prefixed with a different country code can be followed by any length, as long as the first number is no 0
const PHONE_REGEX = /^\+(?!44)[1-9]\d+|\+44[1-9][0-9]{9}$/;

export const internationalPhone: ValidatorFn = (control) =>
  Validators.pattern(PHONE_REGEX)(control)
    ? { internationalPhone: control.value }
    : null;
