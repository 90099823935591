/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type AnalyticsAction =
  'Click'
  | 'Type in'
  | 'Select'
  | 'Play'
  | 'Add item'
  | 'Failure'
  | 'Blur'
  | 'View'
  | 'Assign'
  | 'Restore'
  | 'Place'
  | 'Accept'
  | 'Hover'
  | 'Complete Registration'
  | 'Created';

export class AnalyticsActions {
  public static CLICK:                 AnalyticsAction & 'Click'                 = 'Click';
  public static TYPE_IN:               AnalyticsAction & 'Type in'               = 'Type in';
  public static SELECT:                AnalyticsAction & 'Select'                = 'Select';
  public static PLAY:                  AnalyticsAction & 'Play'                  = 'Play';
  public static ADD_ITEM:              AnalyticsAction & 'Add item'              = 'Add item';
  public static FAILURE:               AnalyticsAction & 'Failure'               = 'Failure';
  public static BLUR:                  AnalyticsAction & 'Blur'                  = 'Blur';
  public static VIEW:                  AnalyticsAction & 'View'                  = 'View';
  public static ASSIGN:                AnalyticsAction & 'Assign'                = 'Assign';
  public static RESTORE:               AnalyticsAction & 'Restore'               = 'Restore';
  public static PLACE:                 AnalyticsAction & 'Place'                 = 'Place';
  public static ACCEPT:                AnalyticsAction & 'Accept'                =  'Accept';
  public static HOVER:                 AnalyticsAction & 'Hover'                 =  'Hover';
  public static COMPLETE_REGISTRATION: AnalyticsAction & 'Complete Registration' =  'Complete Registration';
  public static CREATED:               AnalyticsAction & 'Created'               =  'Created';

  public static get values(): AnalyticsAction[] {
    return [
      AnalyticsActions.CLICK,
      AnalyticsActions.TYPE_IN,
      AnalyticsActions.SELECT,
      AnalyticsActions.PLAY,
      AnalyticsActions.ADD_ITEM,
      AnalyticsActions.FAILURE,
      AnalyticsActions.BLUR,
      AnalyticsActions.VIEW,
      AnalyticsActions.ASSIGN,
      AnalyticsActions.RESTORE,
      AnalyticsActions.PLACE,
      AnalyticsActions.ACCEPT,
      AnalyticsActions.HOVER,
      AnalyticsActions.COMPLETE_REGISTRATION,
      AnalyticsActions.CREATED,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type AnalyticsActionEnum = typeof AnalyticsActions;
