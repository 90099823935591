import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { CustomerQualificationActions } from './customer-qualification.actions';

@Injectable({
  providedIn: 'root'
})
export class ShowQualificationGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    // Frontend, for old reasons, sends a includeRegistrationTrackingCode=1 query param on successful registration.
    // It's not used in menus but we can use it to determine whether we came from registration.
    if (route.queryParamMap.has('includeRegistrationTrackingCode')) {
      this.store.dispatch(CustomerQualificationActions.showQualificationModal());
    }
    return true;
  }
}
