import {
  ItemId,
  ItemOrderTypeEligibility,
  majorCurrencyToMinor,
  MenuContent,
  MinorCurrency,
  Section,
  SectionSelectedStatus,
} from '@citypantry/util-models';

export function getMenuSectionsWithAdditionalItemData(
  content: MenuContent,
  selectedItemIds: ItemId[],
  availableItemIds: ItemId[],
  budget: MinorCurrency,
  serverHiddenSectionIndices: number[],
  isSubsidisedChoiceEnabled: boolean
): { enhancedSections: Section[], updatedSelectedItemIds: ItemId[] } {
  const updatedSelectedItemIds: ItemId[] = selectedItemIds ? [] : null;

  const sections = content ? content.sections : [];
  const enhancedSections: Section[] = sections.map((section, i) => {
    const enhancedItems = [];
    let selectedCount = 0;
    let hiddenItemCount = 0;

    for (const item of section.items) {
      let hidden = false;
      if (!ItemOrderTypeEligibility.isIndividualChoiceCompatible(item) || !availableItemIds.includes(item.id)
      ) {
        hidden = true;
        hiddenItemCount++;
      }

      const inBudget = majorCurrencyToMinor(item.price) <= budget || !!isSubsidisedChoiceEnabled;
      const selectable = !hidden && inBudget;
      const selected = selectable && !!selectedItemIds && selectedItemIds.some((selectedItem) => selectedItem === item.id);
      if (selected) {
        updatedSelectedItemIds.push(item.id);
        selectedCount++;
      }

      const enhancedItem = {
        ...item,
        selectable,
        selected,
        hidden,
        inBudget
      };
      enhancedItems.push(enhancedItem);
    }

    const sectionSelectableItemsCount = enhancedItems.filter((item) => item.selectable).length;
    const sectionHidden = serverHiddenSectionIndices.includes(i) || hiddenItemCount === enhancedItems.length;
    let sectionSelected: SectionSelectedStatus;

    if (selectedCount === 0) {
      sectionSelected = 'none';
    } else if (selectedCount === sectionSelectableItemsCount) {
      sectionSelected = 'all';
    } else {
      sectionSelected = 'some';
    }

    return {
      ...section,
      items: enhancedItems,
      selected: sectionSelected,
      hidden: sectionHidden,
    };
  });

  return { enhancedSections, updatedSelectedItemIds };
}
