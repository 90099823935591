import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploaderFactory, ItemUploader } from '@citypantry/components-form';
import { safeUnsubscribe } from '@citypantry/util';
import { Image, Vendor } from '@citypantry/util-models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuHeaderComponent implements OnInit, OnDestroy {

  @Input()
  public vendor: Vendor;

  @Input()
  public vendorLocationName: string | null;

  @Input()
  public heroImageUrl: string;

  /**
   * If set, displays the distance to the vendor, and a warning if out of range (used by staff only)
   */
  @Input()
  public minDistance: number | null; // Null = not set, will not render

  /**
   * If true, allows editing menuName and hero image
   */
  @Input()
  public editable: boolean;

  /**
   * Displays an editable "menu name" tag if present (and editable=true)
   */
  @Input()
  public menuName: string | void;

  @Input()
  public hideBackArrow: boolean;

  @Input()
  public showWeWorkFriendly: boolean;

  @Input()
  public searchType: 'individual-choice' | 'marketplace';

  @Input()
  public distance: number;

  @Input()
  public postcode: string | null;

  @Input()
  public phoneNumber: string | null;

  @Output()
  public heroImageUploaded: EventEmitter<Image> = new EventEmitter();

  @Output()
  public nameChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  public goBack: EventEmitter<void> = new EventEmitter();

  public isEditingName: boolean;

  public uploader: ItemUploader<Image>;
  private uploaderSubscription: Subscription;

  constructor(
    private uploaderFactory: FileUploaderFactory
  ) {
    this.isEditingName = false;
  }

  public ngOnInit(): void {
    if (this.editable) {
      this.configureUploader();
    }
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.uploaderSubscription);
  }

  public onBackArrowClick(): void {
    this.goBack.emit();
  }

  public editName(): void {
    this.isEditingName = true;
  }

  public submitName(name?: string): void {
    this.isEditingName = false;
    if (name) {
      this.nameChanged.emit(name);
    }
  }

  public hasMinDistance(): boolean {
    return typeof this.minDistance === 'number';
  }

  public showVendorLocationDetails(): boolean {
    return !!(this.postcode || this.phoneNumber);
  }

  private configureUploader(): void {
    this.uploader = this.uploaderFactory.getItemUploader<Image>(
      (data: any) => ({
        large: data.large,
        medium: data.medium,
        thumbnail: data.thumbnail,
        original: data.original,
      })
    );

    this.uploaderSubscription = this.uploader.upload$.subscribe((item: Image) => {
      this.heroImageUrl = item.large;
      this.heroImageUploaded.emit(item);
    });
  }

}
