import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthSelectors, HeaderType } from '@citypantry/shared-auth';
import { Select } from '@citypantry/state';
import { safeUnsubscribe } from '@citypantry/util';
import { CustomerId, MealPlan, OrderId, UserId, VendorId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LostbarSearchResult } from './state/lostbar-search-result.model';
import { LostbarActions } from './state/lostbar.actions';
import { LostbarSelectors } from './state/lostbar.select';
import { LostbarState } from './state/lostbar.state';

@Component({
  selector: 'app-lostbar-container',
  template: `
    <app-lostbar
      *ngIf="isStaffOrSudo$ | async"

      [isLoading]="isLoading$ | async"
      [headerType]="headerType$ | async"
      [name]="userName$ | async"
      [companyName]="companyName$ | async"
      [isSudo]="isSudo$ | async"
      [isFixed]="isLostbarFixed"
      [searchResults]="searchResults$ | async"

      (search)="onSearch($event)"
      (unmasquerade)="onUnmasquerade()"
      (masquerade)="onMasquerade($event)"
      (viewMealplanAsCustomer)="onViewMealplanAsCustomer($event)"
      (editMealplan)="onEditMealplan($event)"
      (editCustomer)="onEditCustomer($event)"
      (editVendor)="onEditVendor($event)"
      (editOrder)="onEditOrder($event)"

      test-id="lostbar"
    ></app-lostbar>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostbarContainerComponent implements OnDestroy {

  @Select(AuthSelectors.isLoading)
  public isLoading$: Observable<boolean>;

  @Select(AuthSelectors.getUserName)
  public userName$: Observable<string>;

  @Select(AuthSelectors.isStaffOrSudo)
  public isStaffOrSudo$: Observable<boolean>;

  @Select(AuthSelectors.isSudo)
  public isSudo$: Observable<boolean>;

  // TODO CPD-15535 fix for vendor company name
  @Select(AuthSelectors.customer.getCompanyName)
  public companyName$: Observable<string | null>;

  @Select(LostbarSelectors.getSearchResults)
  public searchResults$: Observable<LostbarSearchResult[] | null>;

  @Select(AuthSelectors.getHeaderType)
  public headerType$: Observable<HeaderType>;

  public isLostbarFixed: boolean;
  private routerEventsSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<LostbarState>,
  ) {
    this.getRouteData();
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.routerEventsSubscription);
  }

  public onSearch(query: string): void {
    this.store.dispatch(LostbarActions.searchQueryChanged({ query }));
  }

  public onUnmasquerade(): void {
    this.store.dispatch(LostbarActions.unmasqueradeClicked());
  }

  public onMasquerade(userId: UserId): void {
    this.store.dispatch(LostbarActions.masqueradeClicked({ userId }));
  }

  public onViewMealplanAsCustomer(event: { userId: UserId, mealPlanId: MealPlan['id'] }): void {
    this.store.dispatch(LostbarActions.viewMealplanAsCustomerClicked(event));
  }

  public onEditMealplan(mealPlanId: MealPlan['id']): void {
    this.store.dispatch(LostbarActions.editMealplanClicked({ mealPlanId }));
  }

  public onEditCustomer(customerId: CustomerId): void {
    this.store.dispatch(LostbarActions.editCustomerClicked({ customerId }));
  }

  public onEditVendor(vendorId: VendorId): void {
    this.store.dispatch(LostbarActions.editVendorClicked({ vendorId }));
  }

  public onEditOrder(orderId: OrderId): void {
    this.store.dispatch(LostbarActions.editOrderClicked({ orderId }));
  }

  private getRouteData(): void {
    this.routerEventsSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      let route = this.activatedRoute.snapshot.firstChild;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.isLostbarFixed = !!route.data.headerFixed;
    });
  }
}
