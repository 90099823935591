import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { promotionBannerContent, PROMOTION_BANNER_CONTENT } from './promotion-banner.content';
import { PromotionPanelComponent } from './promotion-panel/promotion-panel.component';
import { PromotionQueries } from './promotion.queries';
import { searchPromoCardContent, SEARCH_PROMO_CARD_CONTENT } from './search-promo-card.content';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsComponentsModule
  ],
  providers: [
    PromotionQueries,
    { provide: SEARCH_PROMO_CARD_CONTENT, useValue: searchPromoCardContent },
    { provide: PROMOTION_BANNER_CONTENT, useValue: promotionBannerContent }
  ],
  declarations: [
    PromotionPanelComponent
  ],
  exports: [
    PromotionPanelComponent
  ]
})
export class PromotionModule {}
