import { OperationalRegionsAction, OperationalRegionsActions } from './operational-regions.actions';

export const FEATURE_STATE_NAME = 'operationalRegions';

export interface OperationalRegionsState {
  regions: string[];
}

export const INITIAL_STATE: OperationalRegionsState = {
  regions: [],
};

export function reducer(state: OperationalRegionsState = INITIAL_STATE, action: OperationalRegionsAction): OperationalRegionsState {
  switch (action.type) {

    case OperationalRegionsActions.operationalRegionsLoaded.type: {
      return {
        ...state,
        regions: action.regions,
      };
    }

    default:
      return state;
  }
}
