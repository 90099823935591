import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { ErrorMessage } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { ErrorActions } from './error.actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private store: Store<AppState>,
  ) {}

  /**
   * Log an error to console, send it to Sentry and Analytics.
   * Do NOT use for expected errors that block user action but don't require dev attention (e.g. validation errors).
   *
   * @param error Any kind of object that can be handled by ErrorReporterService.
   */
  public logError(error: any, showToUser: boolean = true): void {
    // If the error is an HttpErrorResponse, we need to force the HttpHeaders to initialise as NgRX won't let them initialise
    // after they've been passed into an Action
    if (error instanceof HttpErrorResponse && error.headers instanceof HttpHeaders) {
      error.headers.has('fake-header');
    }

    this.store.dispatch(ErrorActions.logError({ error, showToUser }));
  }

  /**
   * Show an error message to the user and send it to Analytics (without reporting to Sentry).
   */
  public showErrorMessage(error: ErrorMessage): void {
    this.store.dispatch(ErrorActions.showErrorMessage({ error }));
  }

  public showAlert(title: string, text: string): void {
    this.store.dispatch(ErrorActions.showAlert({ title, text }));
  }
}
