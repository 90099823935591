import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AttributionStore {
  private elements: HTMLDivElement[] = [];

  public addElement(element: HTMLDivElement): void {
    this.elements.push(element);
  }

  public hasElement(): boolean {
    return this.elements.length > 0;
  }

  public getElement(): HTMLDivElement {
    if (this.elements.length === 0) {
      return null;
    }
    return this.elements[0];
  }

  public removeElement(element: HTMLDivElement): void {
    this.elements = this.elements.filter((existingElement) => existingElement !== element);
  }
}
