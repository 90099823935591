import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

const DEFAULT_ERROR_MESSAGE_MAP = {
  required: 'This field is required',
  email: 'You must provide a valid e-mail address',
  personName: 'This field contains invalid characters',
  entityName: 'This field contains invalid characters',
  freeText: 'This field contains invalid characters',
  phone: 'Invalid phone number',
  maxlength: 'This field is too long', // MaxLengthValidator directive is intentionally lowercase to match native maxlength attribute: https://angular.io/api/forms/MaxLengthValidator
} as const;

type ErrorKey = keyof typeof DEFAULT_ERROR_MESSAGE_MAP | string;

type ErrorMessageMap = { [key in ErrorKey]: string; };

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent {

  @Input()
  public set errors(formErrors: ValidationErrors | null) {
    this._errors = formErrors || {};
  }
  public get errors(): ValidationErrors | null {
    return this._errors;
  }

  @Input() public messageOverrides: ErrorMessageMap = {};

  @Input() public showInline: boolean;

  private _errors: ValidationErrors;

  public get messages(): string[] {
    return Object.keys(this._errors).map((key: ErrorKey) => {
      const overridesWithDefault: ErrorMessageMap = {
        [key]: 'This field is invalid',
        ...DEFAULT_ERROR_MESSAGE_MAP,
        ...this.messageOverrides,
      };
      return overridesWithDefault[key];
    });
  }
}
