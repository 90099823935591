import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ChatWidgetService } from '@citypantry/shared-chat-widget';
import { RouterErrorHandler } from '@citypantry/shared-error';
import { getPublicState, RouterActions, Select } from '@citypantry/state';
import { isInPrinting } from '@citypantry/state-public';
import { WindowRef } from '@citypantry/util-browser';
import { createSelector, Store } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

const LOADING_DEBOUNCE_TIME_MS = 300;
export const INITIAL_LOAD_MINIMUM_TIME_MS = 2_000;

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
    <ng-container *ngIf="!initialLoadingAnimationRunning">
      <app-lostbar-container
        *ngIf="(hideComponentsForPrinting$ | async) !== true"
        test-id="lostbar"
      ></app-lostbar-container>
      <app-horizontal-loader
        class="loader"
        [isLoading]="loading$ | async"
      ></app-horizontal-loader>
      <app-header-container
        *ngIf="(hideComponentsForPrinting$ | async) !== true"
        test-id="header"
      ></app-header-container>
      <router-outlet></router-outlet>
      <app-footer class="no-print-on-printable mt-auto"></app-footer>
      <app-error></app-error>
      <app-customer-qualification-modal></app-customer-qualification-modal>
      <app-global-dialog></app-global-dialog>
    </ng-container>
  `,
})
export class AppComponent implements OnInit {
  @Select(createSelector(getPublicState, isInPrinting))
  public hideComponentsForPrinting$: Observable<boolean>;

  public loading$: Observable<boolean>;

  // When this is true, we hide the entire app and wait for the initial animation to finish
  public initialLoadingAnimationRunning: boolean;

  private loadingEvents: Subject<boolean>;

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private chatWidgetService: ChatWidgetService,
    private store: Store,
  ) {
    this.loadingEvents = new Subject<boolean>();
    this.loading$ = this.loadingEvents.pipe(
      debounce((loading: boolean) => timer(loading ? LOADING_DEBOUNCE_TIME_MS : 0)) // Only debounce "true"
    );
  }

  public ngOnInit(): void {
    this.initLoadingAnimation();
    this.chatWidgetService.initialiseChat();

    // No need to unsubscribe as it is never destroyed
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        this.loadingEvents.next(true);
      }

      if (event instanceof NavigationError && RouterErrorHandler.isChunkLoadError(event.error)) {
        // A chunk load error occurs when we attempt to load a lazy loaded module, but the reference has changed (e.g. after a deployment).
        // The thrown error is handled in RouterErrorHandler, preventing it from re-throwing to prevent the user from seeing the error.
        // Here, we re-navigate to the target url, using goExternal so that the browser does a full reload to fetch the updated reference.
        this.store.dispatch(RouterActions.goExternal({ url: event.url }));
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        setTimeout(() => {
          // Do this in a timeout because sometimes navigationEvents trigger during the render,
          // which causes ExpressionChangedAfterItHasBeenCheckedErrors
          this.loadingEvents.next(false);
        });
      }
    });
  }

  private initLoadingAnimation(): void {
    const initialAnimationStartTime = this.windowRef.nativeWindow['loadingAnimationStarted'] || 0;
    const now = new Date().getTime();
    const waitTime = Math.max(0, INITIAL_LOAD_MINIMUM_TIME_MS - (now - initialAnimationStartTime));

    const wrapper = this.document.getElementById('loadingAnimationWrapper');
    this.initialLoadingAnimationRunning = waitTime > 0;

    const removeLoadingAnimation = () => {
      if (wrapper) {
        this.renderer.removeChild(this.renderer.parentNode(wrapper), wrapper);
      }
      this.initialLoadingAnimationRunning = false;
    };

    if (this.initialLoadingAnimationRunning) {
      setTimeout(removeLoadingAnimation, waitTime);
    } else {
      removeLoadingAnimation();
    }
  }
}
