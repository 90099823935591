/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type CartType = 'NEW_CART' | 'EXISTING_CART' | 'MEALPLAN_CART';

export class CartTypes {
  public static NEW_CART:      CartType & 'NEW_CART'      = 'NEW_CART';
  public static EXISTING_CART: CartType & 'EXISTING_CART' = 'EXISTING_CART';
  public static MEALPLAN_CART: CartType & 'MEALPLAN_CART' = 'MEALPLAN_CART';

  public static get values(): CartType[] {
    return [
      CartTypes.NEW_CART,
      CartTypes.EXISTING_CART,
      CartTypes.MEALPLAN_CART,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type CartTypeEnum = typeof CartTypes;
