import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'starRatingLabel',
  pure: true,
})
export class StarRatingLabelPipe implements PipeTransform {
  private options: string[] = ['Leave your feedback', 'Bad', 'Poor', 'OK', 'Good', 'Excellent'];

  public transform(value: number, hideDefaultText: boolean = false): string {
    if (hideDefaultText) {
      this.options[0] = '';
    }

    if (value === null || value > 5 || value < 0) {
      value = 0;
    }

    return this.options[value];
  }
}
