/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type RefundType = 'fixed' | 'percentage';

export class RefundTypes {
  public static FIXED:      RefundType & 'fixed'      = 'fixed';
  public static PERCENTAGE: RefundType & 'percentage' = 'percentage';

  public static get values(): RefundType[] {
    return [
      RefundTypes.FIXED,
      RefundTypes.PERCENTAGE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type RefundTypeEnum = typeof RefundTypes;
