import moment from 'moment';
import { ScheduledOrderFailures } from '../order-schedule/scheduled-order-failure.enum';
import { OrderStatus, OrderStatuses } from '../order/order-status.enum';
import {
  CalendarOrderGroupIndividualChoice,
  createCalendarOrderGroupIndividualChoiceFromJson
} from './calendar-order-group-individual-choice.model';
import { CalendarOrderSchedule, createCalendarOrderScheduleFromJson } from './calendar-order-schedule.model';
import {
  createExistingCalendarOrderFromJson,
  createPlaceholderCalendarOrderFromJson,
  ExistingCalendarOrder,
  PlaceholderCalendarOrder
} from './calendar-order.model';

export type CalendarOrderGroup = PlaceholderCalendarOrderGroup | ExistingCalendarOrderGroup;

/* CalendarOrderGroup models */

interface BaseCalendarOrderGroup {
  locationName: string;
  requestedDeliveryDate: moment.Moment;
  schedule: CalendarOrderSchedule | null;
  canBeRepeated: boolean;
  postcode: string;
}

export interface PlaceholderCalendarOrderGroup extends BaseCalendarOrderGroup {
  isPlaceholder: true;
  orders: PlaceholderCalendarOrder[];
}

export interface ExistingCalendarOrderGroup extends BaseCalendarOrderGroup {
  isPlaceholder: false;
  orders: ExistingCalendarOrder[];

  isMealPlan: boolean;
  individualChoice: CalendarOrderGroupIndividualChoice | null;
}

/* helper functions */

export function isPlaceholderOrderGroup(calendarOrderGroup: CalendarOrderGroup): calendarOrderGroup is PlaceholderCalendarOrderGroup {
  return !!calendarOrderGroup.isPlaceholder;
}

const reverseOrderedStatuses = [...OrderStatuses.orderedValues].reverse();
export function getExistingOrderGroupStatus(existingOrderGroup: ExistingCalendarOrderGroup): OrderStatus {
  if (existingOrderGroup.orders.every((order) => order.status === OrderStatuses.CANCELLED)) {
    return OrderStatuses.CANCELLED;
  }

  for (const status of reverseOrderedStatuses) {
    if (existingOrderGroup.orders.some((order) => order.status === status)) {
      return status;
    }
  }

  throw new Error(
    `Could not determine CalendarOrderGroup status.
    IC parent order id: ${ existingOrderGroup.individualChoice.orderGroupId }.
    Statuses in the group: [ ${ existingOrderGroup.orders.map((order) => order.status).join(', ') } ].`
  );
}

export function hasFailure(placeholderOrderGroup: PlaceholderCalendarOrderGroup): boolean {
  return placeholderOrderGroup.orders.some((order) => !!order.failureType);
}

export function hasTemporaryFailure(placeholderOrderGroup: PlaceholderCalendarOrderGroup): boolean {
  return placeholderOrderGroup.orders.some((order) => order.failureType === ScheduledOrderFailures.TEMPORARY);
}

export function hasPermanentFailure(placeholderOrderGroup: PlaceholderCalendarOrderGroup): boolean {
  return placeholderOrderGroup.orders.some((order) => order.failureType === ScheduledOrderFailures.PERMANENT);
}

/* fromJson functions */

function createBaseCalendarOrderGroupFromJson(json: Partial<BaseCalendarOrderGroup>): BaseCalendarOrderGroup {
  const group: Partial<BaseCalendarOrderGroup> = {};

  group.locationName = json.locationName;
  group.requestedDeliveryDate = json.requestedDeliveryDate ? moment(json.requestedDeliveryDate) : null;
  group.schedule = json.schedule ? createCalendarOrderScheduleFromJson(json.schedule) : null;
  group.canBeRepeated = !!json.canBeRepeated;
  group.postcode = json.postcode;

  return group as BaseCalendarOrderGroup;
}

function createPlaceholderCalendarOrderGroupFromJson(json: Partial<PlaceholderCalendarOrderGroup>): PlaceholderCalendarOrderGroup {
  const group: Partial<PlaceholderCalendarOrderGroup> = createBaseCalendarOrderGroupFromJson(json);

  group.isPlaceholder = true;
  group.orders = (json.orders || []).map(createPlaceholderCalendarOrderFromJson);

  return group as PlaceholderCalendarOrderGroup;
}

function createExistingCalendarOrderGroupFromJson(json: Partial<ExistingCalendarOrderGroup>): ExistingCalendarOrderGroup {
  const group: Partial<ExistingCalendarOrderGroup> = createBaseCalendarOrderGroupFromJson(json);

  group.isPlaceholder = false;
  group.orders = (json.orders || []).map(createExistingCalendarOrderFromJson);

  group.isMealPlan = !!json.isMealPlan;
  group.individualChoice = json.individualChoice ? createCalendarOrderGroupIndividualChoiceFromJson(json.individualChoice) : null;

  return group as ExistingCalendarOrderGroup;
}

export function createCalendarOrderGroupFromJson(json: Partial<CalendarOrderGroup>): CalendarOrderGroup {
  const group = json as CalendarOrderGroup; // cheating with typing to get "isPlaceholderOrderGroup" to work correctly

  if (isPlaceholderOrderGroup(group)) {
    return createPlaceholderCalendarOrderGroupFromJson(group);
  } else {
    return createExistingCalendarOrderGroupFromJson(group);
  }
}
