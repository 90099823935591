import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const mealPlanMenuPageRules: AnalyticsRule[] = [
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'menu-header vendor-rating',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Ratings/Reviews',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'menu-header dietaries diet-icon',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `${event.data['diet-icon'].name} dietary toggled ${event.data['diet-icon'].active ? 'off' : 'on'}`,
    }),
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'menu-content single-item more-info',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'More info',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    // options: single-item, item-bundle-modal-form upgrade-group-item, item-bundle-modal-form flexible-group-item
    path: 'menu-content quantity-picker decrement',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker minus button clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    // options: single-item, item-bundle-modal-form upgrade-group-item, item-bundle-modal-form flexible-group-item
    path: 'menu-content quantity-picker text-box',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker text-box changed',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    // options: single-item, item-bundle-modal-form upgrade-group-item, item-bundle-modal-form flexible-group-item
    path: 'menu-content quantity-picker increment',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker plus button clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cart submit',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Order Now',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cart more-actions',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Dropdown menu clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cart download-quote',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Download quote clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cart share-cart',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Share cart clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cart download-pdf',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Download Menu PDF clicked',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'menu-cart',
    events: {
      class: 'CartComponent',
      eventName: 'changeWeWorkMode',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'WeWork Friendly items toggle',
    },
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'end-of-menu',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'End of menu viewed'
    }
  },
  {
    url: '/menus/mealplan/:mealPlanId/orders/:orderId/vendors/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cartItemsRestoreFailureDialog',
    events: {
      class: 'CartItemsRestoreFailureDialogComponent',
      eventName: 'dismiss',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CART,
      label: 'Item reorder failure dismissed',
    },
  },
];
