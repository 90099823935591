import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@citypantry/components-date-picker';
import { DatetimeComponentsModule } from '@citypantry/components-datetime';
import { FormComponentsModule } from '@citypantry/components-form';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { TextComponentsModule } from '@citypantry/components-text';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { LocationModule } from '@citypantry/shared-location';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CancelOrderAfterEditPopupComponent } from './cancel-order-popup/cancel-order-after-edit-popup.component';
import { DateParametersFormComponent } from './date-parameters-form/date-parameters-form.component';
import { DeliveryDetailsUpdatedPopupComponent } from './delivery-details-updated-popup/delivery-details-updated-popup.component';
import { DeliveryDetailsEffects } from './delivery-details.effects';
import { FEATURE_STATE_NAME, reducer } from './delivery-details.state';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { DeliveryQuestionsComponent } from './delivery-questions/delivery-questions.component';
import { EditDatePopupComponent } from './edit-date-popup/edit-date-popup.component';
import { EditDeliveryContactPopupComponent } from './edit-delivery-contact-popup/edit-delivery-contact-popup.component';
import { FormatDeliveryWindowPipe } from './pipes/format-delivery-window.pipe';
import { TrackingProgressStepComponent } from './tracking-progress-step/tracking-progress-step.component';
import { TrackingProgressComponent } from './tracking-progress/tracking-progress.component';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsComponentsModule,
    EffectsModule.forFeature([
      DeliveryDetailsEffects,
    ]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
    FormsModule,
    ReactiveFormsModule,

    DatePickerModule,
    DatetimeComponentsModule,
    ModalComponentsModule,
    LocationModule,
    TextComponentsModule,
    FormComponentsModule,
  ],
  declarations: [
    CancelOrderAfterEditPopupComponent,
    DateParametersFormComponent,
    DeliveryDetailsComponent,
    DeliveryDetailsUpdatedPopupComponent,
    DeliveryQuestionsComponent,
    EditDatePopupComponent,
    EditDeliveryContactPopupComponent,
    FormatDeliveryWindowPipe,
    TrackingProgressComponent,
    TrackingProgressStepComponent,
  ],
  providers: [
    DeliveryDetailsEffects,
    FormatDeliveryWindowPipe,
  ],
  exports: [
    CancelOrderAfterEditPopupComponent,
    DeliveryDetailsComponent,
    DeliveryDetailsUpdatedPopupComponent,
    DeliveryQuestionsComponent,
    EditDatePopupComponent,
    EditDeliveryContactPopupComponent,
    FormatDeliveryWindowPipe,
    TrackingProgressComponent,
  ],
})
export class DeliveryDetailsModule {}
