import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const thankYouPageRules: AnalyticsRule[] = [
  {
    url: '/checkout/thank-you',
    path: 'orderMore',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.THANK_YOU_PAGE,
      label: 'Order More',
    },
  },
];
