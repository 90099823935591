export interface Currency {
  name: string;
  symbol: string;
  code: string;
}

export function createCurrencyFromJson(json?: Partial<Currency & CurrencyJsonProperties>): Currency {
  const currency: Partial<Currency> = {};

  currency.name = json.currency;
  currency.symbol = json.symbol;
  currency.code = json.isoCode;

  return currency as Currency;
}

interface CurrencyJsonProperties {
  currency: string;
  symbol: string;
  isoCode: string;
}
