import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: `
    <div
      class="tooltip tooltip--{{ placement }}"
      [class.tooltip--animated]="isAnimated"
      [class.fade-in]="fadeIn"
      role="tooltip"
      test-id="tooltipContent"
    >
      <div class="tooltip__inner">
        <ng-container *ngIf="isText; else template">
          {{ content }}
        </ng-container>
        <ng-template #template>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-template>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {

  @Input()
  public set content(content: string | TemplateRef<unknown>) {
    this.isText = typeof content === 'string';
    this._content = content;
  }

  public get content(): string | TemplateRef<unknown> {
    return this._content;
  }

  @Input()
  public placement: 'top' | 'bottom' | 'left' | 'right' | 'center';

  @Input()
  public standalone: boolean;

  @Output()
  public activeStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isText: boolean;
  public isAnimated: boolean;
  public fadeIn: boolean = false;

  private isHovered: boolean;
  private isFocused: boolean;

  private _content: string | TemplateRef<unknown>;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  public animateIn(): void {
    this.isAnimated = true;

    // Setting this to true in a timeout will trigger the CSS animation.
    // If it's already there when the element is created, the CSS animation assumes no transition has happened.
    setTimeout(() => {
      this.fadeIn = true;
      this.cdr.markForCheck();
    });
  }

  @HostListener('focusin')
  public onFocusIn(): void {
    this.isFocused = true;
    this.onHoverStateChange();
  }

  @HostListener('mouseenter')
  public onMouseOver(): void {
    this.isHovered = true;
    this.onHoverStateChange();
  }

  @HostListener('focusout')
  public onFocusOut(): void {
    this.isFocused = false;
    this.onHoverStateChange();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isHovered = false;
    this.onHoverStateChange();
  }

  private onHoverStateChange(): void {
    this.activeStateChange.next(!!(this.isHovered || this.isFocused));
  }
}
