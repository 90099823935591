import { createAction, props, union } from '@ngrx/store';
import { MetaData } from '../meta.module';

export const MetaActions = {
  setMeta: createAction(
    '[Meta] Set meta data manually',
    props<MetaData>(),
  ),
  navigatedToRouteWithMeta: createAction(
    '[Router] Navigated to route with meta data defined',
    props<MetaData>(),
  ),
  navigatedToRouteWithoutMeta: createAction(
    '[Router] Navigated to route with no meta data defined',
  ),
  postNavigationToRouteWithoutMetaTimeoutDone: createAction(
    '[Meta Effects] Timeout after navigation to meta-less route is done',
    props<MetaData>(),
  ),
  setCanonicalUrl: createAction(
    '[Meta Module] Set Canonical URL',
    props<{ path: string | null }>(),
  )
};

const all = union(MetaActions);
export type MetaAction = typeof all;
