/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type RefundReason =
  'Early Delivery'
  | 'Late Delivery'
  | 'Failed Delivery'
  | 'Food Appearance'
  | 'Food Hygiene'
  | 'Food Quality/Taste'
  | 'Food Temperature'
  | 'Item(s) Missing'
  | 'Labelling'
  | 'Portion Sizing'
  | 'Wrong Food'
  | 'Dish Substitution'
  | 'Order Cancelled by Customer'
  | 'Order Cancelled by Vendor' // TODO: this should be updated to say restaurant instead of vendor as part of CPD-17248
  | 'Promotional Discount'
  | 'Reduced Headcount'
  | 'Technical Issue'
  | 'Wrong Date/Time'
  | 'Other Reason';

export class RefundReasons {
  public static EARLY_DELIVERY:              RefundReason & 'Early Delivery'              = 'Early Delivery';
  public static LATE_DELIVERY:               RefundReason & 'Late Delivery'               = 'Late Delivery';
  public static FAILED_DELIVERY:             RefundReason & 'Failed Delivery'             = 'Failed Delivery';
  public static FOOD_APPEARANCE:             RefundReason & 'Food Appearance'             = 'Food Appearance';
  public static FOOD_HYGIENE:                RefundReason & 'Food Hygiene'                = 'Food Hygiene';
  public static FOOD_QUALITY_TASTE:          RefundReason & 'Food Quality/Taste'          = 'Food Quality/Taste';
  public static FOOD_TEMPERATURE:            RefundReason & 'Food Temperature'            = 'Food Temperature';
  public static ITEM_S_MISSING:              RefundReason & 'Item(s) Missing'             = 'Item(s) Missing';
  public static LABELLING:                   RefundReason & 'Labelling'                   = 'Labelling';
  public static PORTION_SIZING:              RefundReason & 'Portion Sizing'              = 'Portion Sizing';
  public static WRONG_FOOD:                  RefundReason & 'Wrong Food'                  = 'Wrong Food';
  public static DISH_SUBSTITUTION:           RefundReason & 'Dish Substitution'           = 'Dish Substitution';
  public static ORDER_CANCELLED_BY_CUSTOMER: RefundReason & 'Order Cancelled by Customer' = 'Order Cancelled by Customer';
  public static ORDER_CANCELLED_BY_VENDOR:   RefundReason & 'Order Cancelled by Vendor'   = 'Order Cancelled by Vendor';
  public static PROMOTIONAL_DISCOUNT:        RefundReason & 'Promotional Discount'        = 'Promotional Discount';
  public static REDUCED_HEADCOUNT:           RefundReason & 'Reduced Headcount'           = 'Reduced Headcount';
  public static TECHNICAL_ISSUE:             RefundReason & 'Technical Issue'             = 'Technical Issue';
  public static WRONG_DATE_TIME:             RefundReason & 'Wrong Date/Time'             = 'Wrong Date/Time';
  public static OTHER_REASON:                RefundReason & 'Other Reason'                = 'Other Reason';

  public static get values(): RefundReason[] {
    return [
      RefundReasons.EARLY_DELIVERY,
      RefundReasons.LATE_DELIVERY,
      RefundReasons.FAILED_DELIVERY,
      RefundReasons.FOOD_APPEARANCE,
      RefundReasons.FOOD_HYGIENE,
      RefundReasons.FOOD_QUALITY_TASTE,
      RefundReasons.FOOD_TEMPERATURE,
      RefundReasons.ITEM_S_MISSING,
      RefundReasons.LABELLING,
      RefundReasons.PORTION_SIZING,
      RefundReasons.WRONG_FOOD,
      RefundReasons.DISH_SUBSTITUTION,
      RefundReasons.ORDER_CANCELLED_BY_CUSTOMER,
      RefundReasons.ORDER_CANCELLED_BY_VENDOR,
      RefundReasons.PROMOTIONAL_DISCOUNT,
      RefundReasons.REDUCED_HEADCOUNT,
      RefundReasons.TECHNICAL_ISSUE,
      RefundReasons.WRONG_DATE_TIME,
      RefundReasons.OTHER_REASON,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type RefundReasonEnum = typeof RefundReasons;
