import { Component, Input } from '@angular/core';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';

@Component({
  selector: 'app-no-results',
  template: `
    <app-in-viewport
      analyticsId="search-no-results"
      [analyticsData]="{analyticsPrefix: (marketingCampaignComponent ? marketingCampaignComponent.analyticsPrefix : null)}"
    ></app-in-viewport>
    <app-feedback-block
      *ngIf="marketingCampaignComponent; else default;"
      [icon]="marketingCampaignComponent.icon"
      title="We couldn't find any menus matching your search terms"
      test-id="marketingCampaign"
    >
      <span test-id="body1">
        {{ marketingCampaignComponent.body1 }}
      </span>
      <a
        href="{{ marketingCampaignComponent.ctaLink }}"
        [target]="marketingCampaignComponent.openLinkInNewTab ? '_blank' : '_self'"
        test-id="anchor"
        analyticsId="search-no-results-cta"
        [analyticsData]="{analyticsPrefix: marketingCampaignComponent.analyticsPrefix}"
      >{{ marketingCampaignComponent.ctaText }}</a>
      <span test-id="body2">
        {{ marketingCampaignComponent.body2 }}
      </span>
    </app-feedback-block>

    <ng-template #default>
      <app-feedback-block
        icon="sad"
        [title]="title"
        test-id="default"
      >
        <ng-template #title>
          We couldn't find any menus<br />
          matching your search terms.
        </ng-template>

        Check your delivery date, delivery time, and postcode first. If you're still left hungry, please contact us via our
        <a href="https://help.citypantry.com/hc/en-us" target="_blank">support page</a> and we'll see what we can do to help.
      </app-feedback-block>
    </ng-template>
  `,
})

export class NoResultsComponent {
  @Input()
  public marketingCampaignComponent: MarketingCampaignComponentModel | null;
}
