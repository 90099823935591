import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthSelectors } from '@citypantry/shared-auth';
import { HistorySelectors } from '@citypantry/shared-history';
import { AppState, getPublicState, IndividualChoiceSetupSelectors, RouterActions } from '@citypantry/state';
import { getSearchRequest, getSearchType, PublicActions, PublicState } from '@citypantry/state-public';
import { CartQueries, SearchQueries } from '@citypantry/state-queries';
import { CartType, CartTypes, createSearchRequestFromURLQuery, MealPlan, SearchOrderType, SearchOrderTypes, SearchRequest, SearchRequestIndividualChoice, VendorFlags } from '@citypantry/util-models';
import { select, Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrepareSearchRequestGuard implements CanActivate {
  private publicState$: Observable<PublicState>;

  constructor(
    private store: Store<AppState>,
    private searchQueries: SearchQueries,
    private cartQueries: CartQueries,
  ) {
    this.publicState$ = this.store.select(getPublicState);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.searchQueries.getSearchDateAndPostcode(),
      this.store.select(HistorySelectors.getReferer),
      this.publicState$.pipe(select(getSearchType)),
      this.publicState$.pipe(select(getSearchRequest)),
      this.searchQueries.getMealPlan(),
      this.cartQueries.getCartType(),
      this.store.select(AuthSelectors.customer.isEligibleForIndividualChoice)
    ]).pipe(
      take(1),
      withLatestFrom(
        this.store.select(IndividualChoiceSetupSelectors.getSearchRequest)
      ),
      map(([
        [
          { date, postcode },
          referer,
          searchType,
          searchRequest,
          mealPlan,
          cartType,
          isEligibleForIC
        ],
        icSearchRequest
      ]: [
        [
          { date: Moment, postcode: string },
          string,
          SearchOrderType,
          SearchRequestIndividualChoice,
          MealPlan,
          CartType,
          boolean
        ],
        SearchRequest
      ]) => {
        const request = createSearchRequestFromURLQuery(route.queryParams);

        if (route.queryParams && route.data.popUpMode) {
          // When popUpMode is specified we need to force Marketplace mode
          searchType = SearchOrderTypes.MARKETPLACE;
          request.options = (request.options || []).concat(VendorFlags.POPUPS);
        } else if (searchRequest.options) {
          // If we are not in pop up mode, and query params are not provided popup must be stripped from the search request
          request.options = searchRequest.options.filter((option) => option !== VendorFlags.POPUPS);
        }

        // If there is a referer, take into account search params from the store.
        if (referer) {
          // Check if the user should be redirected to IC search instead
          if (isEligibleForIC && searchType === SearchOrderTypes.INDIVIDUAL_CHOICE) {
            const queryParams = Object.keys(route.queryParams).length ? route.queryParams : icSearchRequest;
            this.store.dispatch(RouterActions.go({ path: '/menus/individual-choice/search', query: queryParams }));
            return false;
          }
          // If there is a referer and we have a date or postcode in the store,
          // they take priority as they might have been changed in the cart on the menu page
          request.date = (date && date.isAfter() ? date : null) || request.date;
          request.postcode = postcode || request.postcode;
        }

        // If there is no referer, then this instance of the search page is the first ever page the user has hit in the application.
        // In that case, ignore any existing search parameters in the store - all URL parameters take precedence.

        // CPD-3852 Clear mealplan mode from the state if it exists
        if (mealPlan || cartType === CartTypes.MEALPLAN_CART) {
          this.store.dispatch(PublicActions.clearMealPlanCart());
        }

        this.store.dispatch(PublicActions.prepareSearch({ request }));

        return true;
      })
    );
  }
}
