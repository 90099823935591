import { Injectable } from '@angular/core';
import { AnalyticsActions, AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';
import { SearchApi } from '@citypantry/shared-api';
import { CustomerQualificationService } from '@citypantry/shared-customer-qualification';
import { ErrorService } from '@citypantry/shared-error';
import { AppState, RouterActions, SearchSelectors } from '@citypantry/state';
import { PublicAction, PublicActions } from '@citypantry/state-public';
import { SearchQueries } from '@citypantry/state-queries';
import { createURLQueryFromSearchRequest, PromotionNames, requestsMatch, SearchRequest } from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class SearchEffects {

  public trackSameDaySearch$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.search.type,
      PublicActions.searchPageWeWorkModeChanged.type,
    ),
    switchMap(() => this.searchQueries.getSearchRequest().pipe(take(1))), // Make sure we get the latest, but only once per input
    filter(({ date }: SearchRequest) => date && date.isSame(moment(), 'day')),
    tap(() => {
      this.analyticsService.trackEvent(AnalyticsActions.CLICK, {
        category: AnalyticsCategories.SEARCH_PARAMETERS,
        label: 'Same Day Search',
      });
    }),
  ), { dispatch: false });

  public updateSearch$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.search.type,
      PublicActions.searchPageWeWorkModeChanged.type,
      PublicActions.loadNextPage.type,
    ),
    withLatestFrom(
      this.searchQueries.getSearchRequest(),
      this.store.select(SearchSelectors.getLastLoadedPage),
      this.store.select(SearchSelectors.getSearchResultsTotal),
      this.store.select(SearchSelectors.getSearchResults),
    ),
    filter(([action, , , resultCount, results]) => action.type !== PublicActions.loadNextPage.type || results.length < resultCount),
    distinctUntilChanged(([lastAction, lastRequest, lastPage], [action, request, pageNumber]) => {
      const isSameRequest = requestsMatch.ignoringTimestamp(lastRequest, request);

      // CPD-4154 If there is a currentPage:1 in the state *before* the first search, then the first call to loadNextPage compares
      // lastPage=1 (from the original state, fetched on the first search action) with lastPage=1 (from the first search result).
      // This should not be a problem if the deserialiser always clears results on load (and sets page number to 1),
      // but we can ensure it does not happen again by only checking that the page number has changed if
      // both the last and the current action are LOAD_NEXT_PAGE (i.e. "do not load next for the same page twice").
      const isSameLoadNextPageActionAsPrevious =
        action.type === PublicActions.loadNextPage.type && lastAction.type === PublicActions.loadNextPage.type &&
        lastPage === pageNumber;

      return isSameRequest && (
        ([PublicActions.search.type, PublicActions.searchPageWeWorkModeChanged.type] as string[]).indexOf(action.type) >= 0 ||
        isSameLoadNextPageActionAsPrevious
      );
    }),
    switchMap(([action, request, pageNumber]) => {
      if (action.type !== PublicActions.loadNextPage.type) {
        return of(PublicActions.resetSearch(), PublicActions.searchResultsLoad({ request, pageNumber: 1 }));
      } else {
        return of(               PublicActions.searchResultsLoad({ request, pageNumber: pageNumber + 1 }));
      }
    }),
  ));

  public updateQuery$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.search.type,
      PublicActions.searchPageWeWorkModeChanged.type,
    ),
    switchMap(() => this.searchQueries.getSearchRequest().pipe(take(1))), // Make sure we get the latest, but only once per input
    map((request) => RouterActions.query({ params: createURLQueryFromSearchRequest(request), extras: { replaceUrl: true } })),
  ));

  public runSearch$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.searchResultsLoad.type),
    switchMap(({ request, pageNumber }) =>
      this.searchApi.search(request, pageNumber).pipe(
        map((resultsPage) => PublicActions.searchResultsLoadSuccess({ resultsPage })),
        catchError((error: any) => {
          this.errorService.logError(error);
          return of(PublicActions.searchResultsLoadFailure({ error }));
        })
      )
    )
  ));

  public updateRegion$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.search.type),
    withLatestFrom(
      this.store.select(SearchSelectors.getSearchRequest),
    ),
    switchMap(([, searchRequest]) => {
      const postcode = searchRequest.postcode;

      if (postcode) {
        return this.searchApi.getOperationalRegion(postcode).pipe(
          map((operationalRegion) => PublicActions.operationalRegionLoadSuccess({ operationalRegion })),
          catchError(() => {
            return of(PublicActions.operationalRegionLoadFailure());
          })
        );
      }

      return EMPTY;
    }),
  ));

  public handlePromoCardClick$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.promoCardClick.type),
    tap((action) => {
      switch (action.promotionName) {
        case PromotionNames.QUALIFICATION:
          this.customerQualificationService.showQualificationModal();
          break;
        default:
        // No action needed for this promotion
      }
    }),
  ), { dispatch: false });

  constructor(
    private action$: Actions<PublicAction>,
    private store: Store<AppState>,
    private searchApi: SearchApi,
    private searchQueries: SearchQueries,
    private errorService: ErrorService,
    private customerQualificationService: CustomerQualificationService,
    private analyticsService: AnalyticsService,
  ) {}
}
