<div
  class="compact-input"
  [class.compact-input--disabled]="isDisabled"
  [class.compact-input--active]="!isDisabled && isActive"
  [class.compact-input--error]="!isDisabled && hasError"
  [class.compact-input--dropdown]="type === 'choice' || type === 'switch'"
  [class.compact-input--dropdown-switched]="isSwitched"
  test-id="compactInput"
>
  <i class="compact-input__icon icon"
    [ngClass]="['icon-' + icon]"
    (click)="onIconClick()"
    #iconRef
    test-id="icon"
  ></i>
  <label class="compact-input__label-wrapper">
    <span
      class="compact-input__label"
      test-id="label"
    >
      {{ label }}
    </span>
    <span
      class="compact-input__label compact-input__label--switched"
      *ngIf="type === 'switch' && switchedLabel"
      test-id="switchedLabel"
    >
      {{ switchedLabel }}
    </span>
  </label>

  <ng-content></ng-content>

  <div class="compact-input__value-wrapper">
    <span
      class="compact-input__value"
      test-id="displayValue"
    >{{ currentValue }}</span>
  </div>
</div>
