import { createSimpleUserFromJson, SimpleUser } from '../../customer';
import { createEaterCartsSummaryFromJson, EaterCartsSummary } from './eater-carts-summary.model';

/**
 * A summary of all eaters for an individual choice order
 */
export interface EatersSummary {
  away: SimpleUser[];
  pending: SimpleUser[];
  chosen: EaterCartsSummary[];
}

export function createEatersSummaryFromJson(json?: Partial<EatersSummary>): EatersSummary {
  json = json || {};
  const summary: Partial<EatersSummary> = {};

  summary.away = (json.away || []).map(createSimpleUserFromJson);
  summary.pending = (json.pending || []).map(createSimpleUserFromJson);
  summary.chosen = (json.chosen || []).map(createEaterCartsSummaryFromJson);

  return summary as EatersSummary;
}
