import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { EventEmittersOf, EventEmitterType } from '@citypantry/util';
import { InViewportDirective as NgInViewportDirective } from 'ng-in-viewport';
import { ComponentEvent } from './analytics-event.interface';

/**
 * Component which emits "in viewport" events for analytics tracking.
 *
 * This component can be extended with more complex tracking as necessary;
 * its main advantage is that it can maintain state so it is able to emit events such as "first time in viewport".
 *
 * Its drawback is that it is a 0-size component that needs to be added to the DOM and may cause issues in layouts
 * where the number of elements matters, such as flex layouts. If this occurs, consider devising a different solution.
 */
@Component({
  selector: 'app-in-viewport',
  template: `<div
    inViewport
    (inViewportAction)="trackViewport($event)"
    test-id="element"
  ></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InViewportComponent {

  /**
   * Contains analytics-compatible event definitions for this class; allows declaring the analytics event as
   * {
   *   url: '...',
   *   path: '...',
   *   event: InViewportComponent.events.firstSeen
   * }
   * If this throws compiler errors, you may have added a new EventEmitter and not added it to this map.
   */
  public static readonly events: { [K in EventEmittersOf<InViewportComponent>]: ComponentEvent & { eventName: K } } = {
    firstSeen: {
      class: 'InViewportComponent',
      eventName: 'firstSeen'
    }
  };

  @Output()
  public readonly firstSeen: EventEmitter<void> = new EventEmitter();

  private wasSeen: boolean;

  constructor() {
    this.wasSeen = false;
  }

  public trackViewport({ visible }: EventEmitterType<NgInViewportDirective['inViewportAction']>): void {
    if (visible && !this.wasSeen) {
      this.wasSeen = true;
      this.firstSeen.emit();
    }
  }
}
