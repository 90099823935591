export type MealPlanStatus = 2 | 3 | 4;

export class MealPlanStatuses {
  public static PENDING_STAFF_APPROVAL: MealPlanStatus = 2;
  public static ACTIVE: MealPlanStatus = 3;
  public static PENDING_CUSTOMER_APPROVAL: MealPlanStatus = 4;

  public static get values(): MealPlanStatus[] {
    return [
      MealPlanStatuses.PENDING_STAFF_APPROVAL,
      MealPlanStatuses.ACTIVE,
      MealPlanStatuses.PENDING_CUSTOMER_APPROVAL
    ];
  }
}

export type MealPlanStatusEnum = typeof MealPlanStatuses;
