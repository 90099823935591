import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerLocation, SearchRequest } from '@citypantry/util-models';
import moment from 'moment';

@Component({
  selector: 'app-search-params-header',
  templateUrl: './search-params-header.component.html',
  styleUrls: ['./search-params-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchParamsHeaderComponent {

  @Input()
  public resultCount: number;

  @Input()
  public date: moment.Moment;

  @Input()
  public location: CustomerLocation | null;

  @Input()
  public searchRequest: SearchRequest | null;

  @Input()
  public query: string;

  @Output()
  public queryChange: EventEmitter<string> = new EventEmitter();

  @Output()
  public changeParams: EventEmitter<void> = new EventEmitter();

  constructor() {
    this.query = '';
  }

  public onSearchSubmit(searchValue: string): void {
    if ((searchValue && searchValue !== this.query) || (!searchValue && this.query)) {
      this.queryChange.emit(searchValue);
    }
  }
}
