import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { WindowRef } from '@citypantry/util-browser';
import { Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import {
  GaEnhancedEcomAction,
  GaEnhancedEcomActionFieldObject,
  GaEnhancedEcomProductFieldObject
} from 'angulartics2/ga-enhanced-ecom/ga-enhanced-ecom-options';
import { take } from 'rxjs/operators';
import { AnalyticsAction } from './analytics-action.enum';
import { AnalyticsProperties } from './analytics-properties.model';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsApi {

  constructor(
    private angulartics2: Angulartics2,
    private angularticsEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce,
    private store: Store<AppState>,
    private windowRef: WindowRef,
  ) {}

  public trackPage(path: string): void {
    this.angulartics2.pageTrack.next({ path });
  }

  public trackEvent(action: AnalyticsAction, properties: AnalyticsProperties): void {
    this.angulartics2.eventTrack.next({ action, properties });
  }

  public trackCustomEvent(action: string, properties: AnalyticsProperties): void {
    this.angulartics2.eventTrack.next({ action, properties });
  }

  public addEcommerceImpression(): void {
    // TODO: Complete stub for item impressions recording
  }

  public addEcommerceProduct(properties: Partial<GaEnhancedEcomProductFieldObject>): void {
    this.executeIfTracking(() => {
      this.angularticsEcommerce.ecAddProduct(properties);
    });
  }

  public setEcommerceAction(action: GaEnhancedEcomAction,  properties: Partial<GaEnhancedEcomActionFieldObject>): void {
    this.executeIfTracking(() => {
      this.angularticsEcommerce.ecSetAction(action, properties);

      this.executeIfGaIsAvailable((ga: any) => {
        ga('send', 'event', 'ecommerce', 'event', {'nonInteraction': true});
      });
    });
  }

  public clearEcommerceData(): void {
    this.executeIfGaIsAvailable((ga: any) => {
      ga('ec:clear');
    });
  }

  public triggerHotjar(event: string): void {
    const hj = this.windowRef.nativeWindow['hj'];
    if (hj) {
      // global hj function is only included when Segment is initialised
      hj('trigger', event);
      // we make an explicit tagRecording call to hotjar to make the recording filterable by the event string
      hj('tagRecording', [event]);
    }
  }

  private executeIfGaIsAvailable(fn: Function): void {
    const ga = this.windowRef.nativeWindow['ga'];

    if (ga) {
      fn(ga);
    }
  }

  private executeIfTracking(fn: Function): void {
    this.store.select(AuthSelectors.isStaffOrSudo).pipe(
      take(1)
    )
      .subscribe((isStaffOrSudo: boolean) => {
        if (!isStaffOrSudo) {
          fn();
        }
      });
  }
}
