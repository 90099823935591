import { Moment } from 'moment';
import { CompanyHolidaysAction, CompanyHolidaysActions } from './company-holidays.actions';

export const FEATURE_STATE_NAME = 'companyHolidays';

export interface CompanyHolidaysState {
  holidays: Moment[];
}

export const INITIAL_STATE: CompanyHolidaysState = {
  holidays: [],
};

export function reducer(state: CompanyHolidaysState = INITIAL_STATE, action: CompanyHolidaysAction): CompanyHolidaysState {
  switch (action.type) {

    case CompanyHolidaysActions.companyHolidaysLoaded.type: {
      return {
        ...state,
        holidays: action.holidays,
      };
    }

    default:
      return state;
  }
}
