import { environment } from '@citypantry/shared-app-config';
import { Flat, flatten, inflate, normalisePostcode, sum, unique, zip } from '@citypantry/util';
import { CartManager } from '@citypantry/util-cart-manager';
import {
  Cart,
  CartDeliverabilityProblem,
  CartDeliverabilityProblemTypes,
  CartItem,
  CartItemBundle,
  CartItemBundleGroup,
  CartNotification,
  CartPrice,
  CartStatus,
  CartStatuses,
  CartType,
  CartTypes,
  CartValidityError,
  CartValidityErrorTypes,
  CustomerId,
  DeliverableMenu,
  DeliverableMenuError,
  FailedToReorderItem,
  FoodTypes,
  ItemBundle,
  ItemDeliverability,
  ItemDeliverabilityProblems,
  ItemGroup,
  ItemGroupTypes,
  ItemId,
  MajorCurrency,
  MealPlan,
  MenuContent,
  Order,
  OrderValidationError,
  PendingCartChangeReason,
  PendingCartChangeReasons,
  ProblemWithItemIds,
  SearchOrderType,
  SearchOrderTypes,
  SearchRequest,
  SearchRequestIndividualChoice,
  SearchResult,
  UndeliverableItems,
  UndeliverableMenu,
  Vendor,
  createCartFromJson,
  findMenuItemById,
  getProblemsWithItemIds,
  isBundleCartItem,
  isItemBundle,
  isItemMenuMismatchProblem,
  isOutsideMenuScheduleProblem,
  isSingleCartItem,
  isSingleItem,
  isUndeliverableItemsProblem,
  isUpgradeItemGroup,
  isVendorCapacityExceededProblem,
  safeAddCurrency,
  safeSubtractCurrency
} from '@citypantry/util-models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { compose, createSelector } from '@ngrx/store';
import { compare } from 'mathjs';
import moment, { Moment } from 'moment';
import { computeLoyaltyPoints } from '../util/compute-loyalty-points';
import { PublicAction, PublicActions } from './public.actions';

const menuContentsAdapter = createEntityAdapter<MenuContent>();
const vendorAdapter = createEntityAdapter<Vendor>();

export const INITIAL_PRICE: CartPrice = {
  items: 0,
  delivery: 0,
  deliveryVat: 0,
  serviceFee: 0,
  serviceFeeVat: 0,
  total: 0
};

export interface CartParams {
  postcode: string | null;
  deliveryDate: Moment;
}

export interface CartShareModal {
  timeRemaining: number | null;
  hasError: boolean;
  shareUrl: string;
}

export interface PendingCartChanges {
  reason: PendingCartChangeReason;
  params: CartParams;

  // When params get changed, this can result in one of three cases:
  // 1. There is no cart (no cart items), and a menu for the new day was successfully loaded: `menu` is set
  // 2. There is no cart, and menu cannot be loaded: `error` is set
  // 3. There is a cart: `deliverabilityProblems` is set (it might be empty), and `menu` is set

  deliverabilityProblems: CartDeliverabilityProblem[];
  menu: Flat<DeliverableMenu> | null;
  menuError: DeliverableMenuError | null;
  isLoading: boolean;
}

interface CartDeliverabilityState {
  problems: CartDeliverabilityProblem[];
  undeliverableItems: UndeliverableItems | null;
}

export interface MenuDateChanged {
  newDate: Moment;
  queryDate: Moment;
}

export interface PublicState {

  // ===================== //
  //     Entity Stores     //
  // ===================== //

  menuContents: EntityState<MenuContent>;
  vendors: EntityState<Vendor>;
  currentMenu: Flat<DeliverableMenu> | null;
  undeliverableMenu: UndeliverableMenu | null;
  currentVendorName: string;

  // ===================== //
  //         Menu          //
  // ===================== //

  openItemBundleId: ItemId | null;
  dateChanged: MenuDateChanged | null; // Exists when API returned menu date different than user requested

  // ===================== //
  //        Search         //
  // ===================== //

  searchRequest: SearchRequestIndividualChoice;
  searchRegion: string | null;
  searchResults: SearchResult[];
  searchResultsTotal: number;
  lastLoadedPage: number;
  isSearching: boolean;
  mealPlan: MealPlan | null; // Required if cartType === MEAL_PLAN
  proposedOrderId: string | null;

  searchType: SearchOrderType;
  searchPreferencesCustomerId: CustomerId;
  disableWeWorkMode: boolean; // Negative property so that wework mode is default enabled even when it's not already in the state

  // This field serves two purposes:
  // - Hiding the promo card on click (all other search promo card logic depends on re-querying local storage)
  // - Insuring that the next eligible promotion is not shown during the same session (if the user is eligible for more than one promotion).
  hideSearchPromotionCard: boolean;

  outOfRegionCaptureModal: {
    isOpen: boolean;
    wasSubmitted: boolean;
  };

  showFindNewVendorPopup: boolean;
  cancelledOrderIds: number[];

  // ===================== //
  //         Order         //
  // ===================== //

  currentOrder: Order | null;
  orderValidationError: OrderValidationError | null;

  // ===================== //
  //         Cart          //
  // ===================== //

  cart: Cart;
  menuRegion: string;
  cartType: CartType;
  cartOrder: Order | null;
  cartStatus: CartStatus;
  isExistingCartFromAdmin: boolean; // If the cart type is EXISTING_CART, whether it was loaded from the admin site or the customer site

  cartValidityErrors: CartValidityError[]; // TODO CPD-2749 validate min/max quantities
  cartDeliverability: CartDeliverabilityState;
  cartNotifications: CartNotification[];

  pendingCartChanges: null | PendingCartChanges;
  cartOverridesDialog: null | {
    submitCartOnSuccess: boolean;
  };

  cartItemsFailedToRestore: FailedToReorderItem[];
  cartShareModal: CartShareModal | null;
  ageConfirmationDialog: { userHasConfirmedOverridingErrors: boolean } | null;

  // ===================== //
  //       Homepage        //
  // ===================== //

  isHubspotModalOpen: boolean;

  // ===================== //
  //    Printing Events    //
  // ===================== //
  printingEventsCounter: number;
}

export const EMPTY_CART: Cart = {
  id: null,
  menuId: null,
  contentId: null,
  vendorId: null,
  vendorLocationId: null,
  deliveryDate: null,
  postcode: null,
  includeCutlery: false,
  notes: '',
  cartItems: [],
  price: INITIAL_PRICE,
  headcount: 0,
  manualHeadcount: 0,
  isFreeDelivery: false,
  loyaltyPoints: 0,
  currencyIsoCode: null,
  vendorLocationSlug: null,
  vendorLocationPublicName: null
};

const EMPTY_CART_DELIVERABILITY: CartDeliverabilityState = {
  problems: [],
  undeliverableItems: null
};

// Used when setting up a new cart
const initialCartProperties: Partial<PublicState> = {
  cartStatus: CartStatuses.IDLE,
  cartType: null,
  cartOrder: null,
  isExistingCartFromAdmin: false,
  cartValidityErrors: [],
  cartDeliverability: EMPTY_CART_DELIVERABILITY,
  cartNotifications: [],
  pendingCartChanges: null,
};

export const initialState: PublicState = {
  cartShareModal: null,
  menuContents: menuContentsAdapter.getInitialState(),
  vendors: vendorAdapter.getInitialState(),
  currentMenu: null,
  undeliverableMenu: null,
  currentVendorName: null,
  openItemBundleId: null,
  dateChanged: null,

  searchRequest: {
    postcode: null
  },
  searchRegion: null,
  searchResults: [],
  searchResultsTotal: null,
  lastLoadedPage: null,
  isSearching: false,
  mealPlan: null,
  proposedOrderId: null,

  currentOrder: null,
  orderValidationError: null,

  cart: EMPTY_CART,
  menuRegion: null,
  cartType: CartTypes.NEW_CART,
  cartOrder: null,
  cartStatus: CartStatuses.IDLE,
  isExistingCartFromAdmin: false,
  cartValidityErrors: [],
  cartDeliverability: EMPTY_CART_DELIVERABILITY,
  cartNotifications: [],
  pendingCartChanges: null,
  cartOverridesDialog: null,
  cartItemsFailedToRestore: [],
  ageConfirmationDialog: null,

  isHubspotModalOpen: false,

  printingEventsCounter: 0,

  searchType: SearchOrderTypes.MARKETPLACE,
  searchPreferencesCustomerId: null,
  disableWeWorkMode: false,
  hideSearchPromotionCard: false,
  showFindNewVendorPopup: false,
  cancelledOrderIds: null,

  outOfRegionCaptureModal: {
    isOpen: false,
    wasSubmitted: false,
  },
};

const mapDeliverableMenu = {
  flatten: (menu: DeliverableMenu): Flat<DeliverableMenu> => flatten(menu, { content: 'id', vendor: 'id' }),
  inflate: (menu: Flat<DeliverableMenu>, state: PublicState): DeliverableMenu => inflate<DeliverableMenu>(menu, {
    'content': (contentId: string) => state.menuContents.entities[contentId],
    'vendor': (vendorId: string) => state.vendors.entities[vendorId]
  })
};

export function reducer(state: PublicState, action: PublicAction): PublicState {
  const newState = publicReducer(state, action);
  return compose(
    ensurePostcodeIsNormal
  )(newState);
}

export function publicReducer(state: PublicState = initialState, action: PublicAction): PublicState {
  switch (action.type) {

    case PublicActions.deliverableMenuLoaded.type: {
      const { deliverableMenu } = action;
      const { menu, menuContents, vendors } = addDeliverableMenuToState(deliverableMenu, state);

      return recomputeCartProperties({
        ...state,
        menuContents,
        vendors,
        currentMenu: menu,
        orderValidationError: null,
        currentVendorName: deliverableMenu.vendor.name
      });
    }

    case PublicActions.undeliverableMenuLoaded.type: {
      const { undeliverableMenu } = action;

      return {
        ...state,
        undeliverableMenu,
        currentVendorName: undeliverableMenu.vendorName
      };
    }

    case PublicActions.loadMenuRegionSuccess.type: {
      const { region } = action;

      return {
        ...state,
        menuRegion: region,
      };
    }

    case PublicActions.loadMenuRegionFailure.type: {
      return {
        ...state,
        menuRegion: null,
      };
    }

    case PublicActions.deliverableMenuDateChanged.type: {
      const payload =  action;

      return {
        ...state,
        dateChanged: {
          newDate: payload.newDate,
          queryDate: payload.queryDate
        }
      };
    }

    case PublicActions.closeDateChangedDialog.type: {
      return {
        ...state,
        dateChanged: null,
      };
    }

    case PublicActions.resetSearch.type: {
      return {
        ...state,
        searchResults: [],
        searchResultsTotal: 0,
        lastLoadedPage: 0,
        isSearching: false,
      };
    }
    case PublicActions.search.type: {
      const newRequest = action.request;

      if (!newRequest) {
        return state; // Leave state unchanged, this action is also used as a signal for effects
      }

      return {
        ...state,
        searchRequest: {
          ...state.searchRequest,
          ...newRequest
        },
        cart: updateCartWithSearchRequest(state.cart, newRequest)
      };
    }
    case PublicActions.prepareSearch.type:
    case PublicActions.prepareMealPlanSearch.type:
    case PublicActions.updateSearchParameters.type: {
      let request: SearchRequest;

      if (action.type === PublicActions.updateSearchParameters.type) {
        request = {};
        if (action.request.postcode) {
          request.postcode = action.request.postcode;
        }
        if (action.request.date) {
          request.date = action.request.date;
        }
      } else {
        request = action.request;
      }

      const searchRequest = {
        ...state.searchRequest,
        ...request
      };

      let mealPlan = state.mealPlan;
      let proposedOrderId = state.proposedOrderId;
      switch (action.type) {
        case PublicActions.prepareSearch.type:
          mealPlan = null;
          proposedOrderId = null;
          break;
        case PublicActions.prepareMealPlanSearch.type: {
          mealPlan = action.mealPlan;
          proposedOrderId = action.proposedOrderId;
          const requirements = mealPlan.requirementsAtGeneration;
          searchRequest.maxBudget = requirements.maxBudget;
          searchRequest.headcount = requirements.headcount;
          searchRequest.postcode = requirements.deliveryAddress.postcode;
          break;
        }
        default:
        // No change to meal plan
      }

      return {
        ...state,
        searchRequest,
        mealPlan,
        proposedOrderId,
        cart: updateCartWithSearchRequest(state.cart, searchRequest)
      };
    }

    case PublicActions.operationalRegionLoadSuccess.type: {
      const { operationalRegion } = action;
      return  {
        ...state,
        searchRegion: operationalRegion,
      };
    }

    case PublicActions.operationalRegionLoadFailure.type: {
      return  {
        ...state,
        searchRegion: null,
        outOfRegionCaptureModal: {
          ...state.outOfRegionCaptureModal,
          isOpen: !state.outOfRegionCaptureModal.wasSubmitted,
        },
      };
    }

    case PublicActions.outOfRegionCaptureModalSubmitted.type: {
      return {
        ...state,
        outOfRegionCaptureModal: {
          ...state.outOfRegionCaptureModal,
          wasSubmitted: true,
        },
      };
    }

    case PublicActions.outOfRegionCaptureModalClose.type: {
      return {
        ...state,
        outOfRegionCaptureModal: {
          ...state.outOfRegionCaptureModal,
          isOpen: false,
        },
      };
    }

    case PublicActions.clearSearchLocation.type: {
      return {
        ...state,
        searchRequest: {
          ...state.searchRequest,
          postcode: null
        },
        searchRegion: null,
        // Clear the cart because without a location it would not be valid
        cart: EMPTY_CART
      };
    }
    case PublicActions.searchResultsLoad.type: {
      return {
        ...state,
        isSearching: true
      };
    }
    case PublicActions.searchResultsLoadSuccess.type: {
      const page = action.resultsPage;
      return {
        ...state,
        searchResults: state.searchResults.concat(page.items),
        searchResultsTotal: page.total,
        lastLoadedPage: page.page,
        isSearching: false,
      };
    }
    case PublicActions.searchResultsLoadFailure.type: {
      return {
        ...state,
        isSearching: false
      };
    }

    case PublicActions.mealPlanLoaded.type: {
      return {
        ...state,
        mealPlan: action.mealPlan,
        proposedOrderId: action.proposedOrderId,
      };
    }

    case PublicActions.selectItemBundle.type: {
      const { itemBundle } = action;

      return {
        ...state,
        openItemBundleId: itemBundle.id,
      };
    }

    case PublicActions.unselectItemBundle.type:
      return {
        ...state,
        openItemBundleId: null
      };

    /**
     * Called when the user visits a menu page for a different vendor.
     * This sets up a brand new cart of type NEW_CART.
     */
    case PublicActions.createNewCart.type: {
      const {
        reorderCart,
        ...settings
      } = action;

      const cartItems = reorderCart ? reorderCart.cartItems : [];
      const cartItemsFailedToRestore = reorderCart ? reorderCart.failedItems : [];

      return recomputeCartProperties({
        ...state,
        ...initialCartProperties,
        cartType: CartTypes.NEW_CART,
        cart: {
          ...initialState.cart,
          ...settings,
          cartItems,
        },
        // This is definitely not an existing or mealplan cart
        cartOrder: null,
        cartItemsFailedToRestore,
        mealPlan: null
      });
    }

    /**
     * Updates the current cart's delivery date and postcode.
     * This is not usually a user action, but happens when the user visits a menu page with different parameters in the URL
     * than the current cart.
     * This will trigger validation in the cart effects, and may leave the cart in an undeliverable state.
     * It also updates the search parameters to ensure they are consistent, in case the user visited the cart page by direct link.
     */
    case PublicActions.updateCart.type: {
      const { deliveryDate, postcode } = action;

      const newCart = {
        ...state.cart,
        deliveryDate,
        postcode: postcode || state.cart.postcode
      };

      return recomputeCartProperties({
        ...state,
        cart: newCart,
        searchRequest: {
          ...state.searchRequest,
          date: newCart.deliveryDate || state.searchRequest.date,
          postcode: newCart.postcode || state.searchRequest.postcode
        }
      });
    }

    case PublicActions.setCartFreeDelivery.type: {
      const { freeDelivery } = action;

      const newCart = {
        ...state.cart,
        isFreeDelivery: freeDelivery
      };

      return recomputeCartProperties({
        ...state,
        cart: newCart
      });
    }

    case PublicActions.setCartCutlery.type: {
      const includeCutlery = !!action.includeCutleryAndNapkins;
      if (state.cart.includeCutlery === includeCutlery) {
        return state;
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          includeCutlery
        }
      };
    }

    case PublicActions.setCartNotes.type: {
      const notes = action.notes;
      if (state.cart.notes === notes) {
        return state;
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          notes
        }
      };
    }

    case PublicActions.addOrUpdateSingleItem.type: {
      const { item, quantity } = action;

      const cartItems = CartManager.addOrUpdateItem(state.cart.cartItems, item, null, { quantity });

      return updateCartItems(state, cartItems);
    }

    case PublicActions.addOrUpdateItemBundle.type: {
      const { quantity, cartGroups } = action.event;

      const menu = getCurrentMenu(state);
      const item = findMenuItemById(menu.content, state.openItemBundleId) as ItemBundle;

      if (item) {
        const cartItems = CartManager.addOrUpdateItemBundle(state.cart.cartItems, item, cartGroups, quantity);

        return updateCartItems(state, cartItems);
      }

      return state;
    }

    case PublicActions.removeItemFromCart.type: {
      const cartIndex = action.cartIndex;

      const cartItems = CartManager.removeItem(state.cart.cartItems || [], cartIndex);

      return updateCartItems(state, cartItems);
    }

    case PublicActions.setupExistingOrder.type: {
      const { order, cart, fromAdmin } = action;

      const newCart = {
        ...cart,
        manualHeadcount: 0,
        price: {
          items: order.costBreakdown.itemsCost.gross,
          delivery: order.costBreakdown.deliveryCost.net,
          deliveryVat: order.costBreakdown.deliveryCost.vat,
          total: order.costBreakdown.itemsCostAfterRefunds.gross,
        }
      } as Cart;

      return recomputeCartProperties({
        ...state,
        cartType: CartTypes.EXISTING_CART,
        cart: newCart,
        isExistingCartFromAdmin: fromAdmin,
        cartOrder: order,
        cartNotifications: [],
        pendingCartChanges: null,
        cartStatus: CartStatuses.IDLE,
        orderValidationError: null,
      });
    }

    case PublicActions.createMealplanCart.type: {
      const {
        mealPlan,
        deliveryDate,
        menuId,
        vendorId,
        vendorLocationId,
        contentId,
        reorderCart,
        currencyIsoCode,
      } = action;

      const requirements = mealPlan.requirementsAtGeneration;

      const cartItems = reorderCart ? reorderCart.cartItems : [];
      const cartItemsFailedToRestore = reorderCart ? reorderCart.failedItems : [];
      const notes = (reorderCart ? reorderCart.notes : null) || requirements.dietaryNotes;
      const includeCutlery = (reorderCart ? reorderCart.includeCutlery : null) || requirements.requestCutleryAndServiettes;

      const cart: Cart = {
        postcode: requirements.deliveryAddress.postcode,
        deliveryDate,
        notes,
        includeCutlery,
        menuId,
        contentId,
        vendorId,
        vendorLocationId,
        cartItems,
        manualHeadcount: 0,
        price: INITIAL_PRICE,
        headcount: 0,
        loyaltyPoints: 0,
        requiredHeadcount: requirements.headcount,
        isFreeDelivery: false,
        currencyIsoCode,
        vendorLocationSlug: null,
        vendorLocationPublicName: null
      };

      return recomputeCartProperties({
        ...state,
        ...initialCartProperties,
        cartType: CartTypes.MEALPLAN_CART,
        cart,
        cartItemsFailedToRestore,
        mealPlan,
      });
    }

    case PublicActions.clearMealPlanCart.type: {
      return recomputeCartProperties({
        ...state,
        ...initialCartProperties,
        cart: EMPTY_CART,
        mealPlan: null,
        proposedOrderId: null
      });
    }

    case PublicActions.setupExistingMealplanCart.type: {
      const { mealPlan, cart } = action;

      const newCart = {
        ...cart,
        manualHeadcount: 0,
      };

      return recomputeCartProperties({
        ...state,
        ...initialCartProperties,
        cartType: CartTypes.MEALPLAN_CART,
        cart: newCart,
        mealPlan
      });
    }

    case PublicActions.replaceMealPlanCart.type: {
      return {
        ...state,
        cartStatus: CartStatuses.SUBMITTING
      };
    }

    case PublicActions.checkCartDeliverability.type: {
      return {
        ...state,
        cartStatus: CartStatuses.VALIDATING
      };
    }

    case PublicActions.cartDeliverabilityLoaded.type: {
      const {
        isSubmittingCart,
        deliverability: { problems: cartDeliverabilityProblems }
      } = action;

      const canOverrideAllProblems = cartDeliverabilityProblems.filter((problem) => !problem.overridable).length === 0;

      let pendingCartChanges = state.pendingCartChanges;
      const openPendingChangesModal = cartDeliverabilityProblems.length &&
        !state.pendingCartChanges &&
        (!isSubmittingCart ||
          !canOverrideAllProblems);
      if (openPendingChangesModal) {
        const reason = PendingCartChangeReasons.BAD_DATE;

        pendingCartChanges = {
          ...openPendingCartChangesModal(reason, state),
          deliverabilityProblems: cartDeliverabilityProblems
        };
      }

      const { problems, undeliverableItems } = createCartDeliverability(cartDeliverabilityProblems);

      let cartStatus = CartStatuses.IDLE;
      if (isSubmittingCart && !openPendingChangesModal) {
        cartStatus = state.cartStatus;
      }
      return {
        ...state,
        cartDeliverability: {
          problems,
          undeliverableItems
        },
        cartStatus,
        pendingCartChanges
      };
    }

    case PublicActions.loadCartNotifications.type: {
      return {
        ...state,
        cartStatus: CartStatuses.VALIDATING
      };
    }

    case PublicActions.cartNotificationsLoaded.type: {
      return {
        ...state,
        cartNotifications: action.notifications,
        cartStatus: CartStatuses.IDLE
      };
    }

    case PublicActions.openCartParamsModal.type: {
      return {
        ...state,
        pendingCartChanges: openPendingCartChangesModal(PendingCartChangeReasons.MANUAL_OPEN, state)
      };
    }

    case PublicActions.closeCartParamsModal.type: {
      if (!state.pendingCartChanges) {
        return state;
      }

      const cart = cartHasAllRequiredParams(state) ? state.cart : {
        ...state.cart,
        cartItems: []
      };

      return recomputeCartProperties({
        ...state,
        cart,
        pendingCartChanges: null
      });
    }

    case PublicActions.updateCartParameters.type: {
      if (!state.pendingCartChanges) {
        return state;
      }

      const params: CartParams = action;

      return {
        ...state,
        pendingCartChanges: {
          ...state.pendingCartChanges,
          params,
          isLoading: true
        }
      };
    }

    case PublicActions.pendingCartDeliverabilityLoaded.type: {
      if (!state.pendingCartChanges) {
        return state;
      }

      const { deliverability, menu: deliverableMenu, menuError } = action;
      const { menu, menuContents, vendors } = addDeliverableMenuToState(deliverableMenu, state);

      return {
        ...state,
        menuContents,
        vendors,
        pendingCartChanges: {
          ...state.pendingCartChanges,
          deliverabilityProblems: deliverability.problems,
          menu,
          menuError,
          isLoading: false
        }
      };
    }

    case PublicActions.submitCartChanges.type: {
      if (!state.pendingCartChanges) {
        return state;
      }

      const override = action.override;

      const changes = state.pendingCartChanges;
      const hasCart = state.cart.cartItems.length > 0;
      const hasProblems = changes.deliverabilityProblems.length > 0;

      if (changes.isLoading || (!override && changes.menuError)) {
        return state;
      }
      if (hasCart && (!override && hasProblems)) {
        // Abort if we attempt to just submit an invalid state
        return state;
      }

      if (!hasCart && !changes.menu) {
        // Nothing to do here, the popup should not even be open
        return {
          ...state,
          pendingCartChanges: null
        };
      }

      if (changes.params.postcode === state.cart.postcode &&
        changes.params.deliveryDate.isSame(state.cart.deliveryDate)) {
        // Just close the popup
        // NOTE even if menu deliverability has changed we are not applying it
        return {
          ...state,
          pendingCartChanges: null
        };
      }

      // note: we don't recompute delivery price here,
      // because it will be computed on backend side anyway (see triggerOrderValidation$ Effect)
      return recomputeCartProperties(applyPendingCartChanges(state, override), false, true);
    }

    case PublicActions.removeInvalidCartItems.type: {
      if (!state.pendingCartChanges) {
        return state;
      }

      const changes = state.pendingCartChanges;
      const hasCart = state.cart.cartItems.length > 0;
      const hasProblems = changes.deliverabilityProblems.length > 0;

      if (changes.isLoading || changes.menuError) {
        return state;
      }

      if (!hasCart || !hasProblems) {
        // Abort if we attempt to just submit a state that needs no resolution
        return state;
      }

      return recomputeCartProperties(applyPendingCartChanges(state, false));
    }

    case PublicActions.submitCart.type: {
      const isAgeConfirmationDialogVisible = state.cartType === CartTypes.EXISTING_CART
        && CartManager.areAnyCartItemsAgeRestricted(state.cart.cartItems);
      return {
        ...state,
        cartStatus: CartStatuses.SUBMITTING,
        cartOverridesDialog: null,
        ageConfirmationDialog: isAgeConfirmationDialogVisible ? {
          userHasConfirmedOverridingErrors: action.overrideErrors
        } : null,
      };
    }

    case PublicActions.ageConfirmationDialogConfirm.type: {
      return {
        ...state,
        ageConfirmationDialog: null,
      };
    }

    case PublicActions.ageConfirmationDialogCancel.type: {
      return {
        ...state,
        cartStatus: CartStatuses.IDLE,
        ageConfirmationDialog: null,
      };
    }

    case PublicActions.submitCartFailed.type: {
      return {
        ...state,
        cartStatus: CartStatuses.IDLE,
      };
    }

    case PublicActions.showCartOverridesModal.type: {
      return {
        ...state,
        cartOverridesDialog: {
          submitCartOnSuccess: action.submitCartOnSuccess
        }
      };
    }

    case PublicActions.abortCartOverridesModal.type: {
      let cartStatus = state.cartStatus;
      if (state.cartOverridesDialog && state.cartOverridesDialog.submitCartOnSuccess) {
        // In case we were attempting to submit, reset the cart status to normal after the modal was aborted
        cartStatus = CartStatuses.IDLE;
      }
      return {
        ...state,
        cartOverridesDialog: null,
        cartStatus
      };
    }

    case PublicActions.hideHubspotModal.type: {
      return {
        ...state,
        isHubspotModalOpen: false
      };
    }

    case PublicActions.showHubspotModal.type: {
      return {
        ...state,
        isHubspotModalOpen: true,
      };
    }

    case PublicActions.enterPrintMode.type: {
      return {
        ...state,
        printingEventsCounter: state.printingEventsCounter + 1
      };
    }

    case PublicActions.exitPrintMode.type: {
      return {
        ...state,
        printingEventsCounter: Math.max(0, state.printingEventsCounter - 1)
      };
    }

    case PublicActions.validateOrder.type: {
      return {
        ...state,
        cartStatus: CartStatuses.VALIDATING
      };
    }

    case PublicActions.orderValidated.type: {
      const { costBreakdown, cartId, isFreeDelivery } = action;

      // Total cost received from backend does not take into account free delivery eligibility.
      // Free delivery promocode will be applied in checkout and is not used for /validate request.
      const total: MajorCurrency = isFreeDelivery ?
        safeSubtractCurrency(costBreakdown.itemsCostAfterRefunds.gross, costBreakdown.deliveryCost.gross) :
        costBreakdown.itemsCostAfterRefunds.gross;

      // note: we still need to recomputeCartProperties here - we received prices from backend but need to recalculate loyalty points
      return recomputeCartProperties({
        ...state,
        cartStatus: CartStatuses.IDLE,
        orderValidationError: null,
        cart: {
          ...state.cart,
          id: cartId,
          price: {
            items: costBreakdown.itemsCost.gross,
            delivery: costBreakdown.deliveryCost.net,
            deliveryVat: costBreakdown.deliveryCost.vat,
            serviceFee: costBreakdown.serviceFeeCost.net,
            serviceFeeVat: costBreakdown.serviceFeeCost.vat,
            total
          }
        }
      }, false, false);
    }

    case PublicActions.orderValidationFailed.type: {
      const { error, cartId } = action;
      return {
        ...state,
        cartStatus: CartStatuses.IDLE,
        orderValidationError: error,
        cart: {
          ...state.cart,
          id: cartId,
        }
      };
    }

    case PublicActions.updateSearchType.type: {
      return {
        ...state,
        searchType: action.searchType,
      };
    }

    case PublicActions.updateSearchTypeForCustomer.type: {
      const payload = action;
      return updateSearchTypeForCustomer(state, payload.searchType, payload.searchPreferencesCustomerId);
    }

    case PublicActions.searchPageWeWorkModeChanged.type:
    case PublicActions.menuPageWeWorkModeChanged.type: {
      const disableWeWorkMode = !action.enabled;

      if (!!state.disableWeWorkMode === disableWeWorkMode) {
        return state;
      } else {
        return {
          ...state,
          disableWeWorkMode,
        };
      }
    }

    case PublicActions.hideSearchPromoCard.type: {
      return {
        ...state,
        hideSearchPromotionCard: true
      };
    }

    case PublicActions.cartItemsRestoreFailureDialogDismiss.type: {
      return {
        ...state,
        cartItemsFailedToRestore: [],
      };
    }

    case PublicActions.shareCartModalOpen.type: {
      const shareUrl = action.shareUrl;

      return {
        ...state,
        cartShareModal: {
          shareUrl,
          timeRemaining: null,
          hasError: false,
        },
      };
    }

    case PublicActions.shareCartModalDismiss.type: {
      return {
        ...state,
        cartShareModal: null,
      };
    }

    case PublicActions.shareCartLinkCopyFailed.type: {
      return {
        ...state,
        cartShareModal: {
          ...state.cartShareModal,
          hasError: true,
        },
      };
    }

    case PublicActions.tickShareCartModalCountdown.type: {
      const timeRemaining = action.timeRemaining;

      if (!state.cartShareModal) {
        return state;
      }

      return {
        ...state,
        cartShareModal: {
          ...state.cartShareModal,
          timeRemaining,
          hasError: false,
        },
      };
    }

    case PublicActions.openFindNewVendorPopup.type: {
      const cancelledOrderIds = action.cancelledOrderIds;

      return {
        ...state,
        showFindNewVendorPopup: true,
        cancelledOrderIds,
      };
    }

    case PublicActions.closeFindNewVendorPopup.type: {
      return {
        ...state,
        showFindNewVendorPopup: false,
        cancelledOrderIds: null,
      };
    }

    default:
      return state;
  }
}

function addDeliverableMenuToState(deliverableMenu: DeliverableMenu, state: PublicState): {
    menu: Flat<DeliverableMenu>;
    menuContents: EntityState<MenuContent>;
    vendors: EntityState<Vendor>;
  } {
  if (!deliverableMenu) {
    return {
      menu: null,
      menuContents: state.menuContents,
      vendors: state.vendors
    };
  }

  const menu = mapDeliverableMenu.flatten(deliverableMenu);
  const menuContents = menuContentsAdapter.addOne(deliverableMenu.content, state.menuContents);
  const vendors = vendorAdapter.addOne(deliverableMenu.vendor, state.vendors);

  return { menu, menuContents, vendors };
}

/**
 * Applies PendingCartChanges to the state. This method assumes that the user *can* do this.
 * Call this from both the "submit" and the "resolve" actions as it resolves any problems.
 *
 * It will set the new cart parameters on the cart and the search state, apply the new menu if appropriate,
 * and resolve any issues automatically as much as possible.
 *
 * There is some error handling but in general this method assumes that all validation has been done before it is called.
 * @param {PublicState} state The current state
 * @param {boolean} overrideProblems Whether to override problems that can be overridden.
 * @returns {PublicState} A new state.
 */
function applyPendingCartChanges(state: PublicState, overrideProblems: boolean): PublicState {
  const changes = state.pendingCartChanges;
  const { postcode, deliveryDate } = changes.params;
  // There might not be a new menu when the popup has opened because of a deliverability error on load; in that case use the old one
  const flatNewMenu = changes.menu || state.currentMenu;
  const newMenu = mapDeliverableMenu.inflate(flatNewMenu, state);

  const newState: PublicState = {
    ...state,
    currentMenu: flatNewMenu,
    searchRequest: {
      ...state.searchRequest,
      postcode,
      date: deliveryDate
    },
    cart: {
      ...state.cart,
      deliveryDate,
      postcode,
      menuId: newMenu.menuId,
      contentId: newMenu.content.id,
    },
    cartDeliverability: createCartDeliverability(changes.deliverabilityProblems),
    pendingCartChanges: null,
  };

  const unresolvableProblems = changes.deliverabilityProblems.filter(isUnresolvableCartDeliverabilityProblem(overrideProblems));
  if (unresolvableProblems.length) {
    if (!environment.production) {
      console.warn('Invalid state: Attempted to submit cart parameters modal but there are unresolvable problems:', unresolvableProblems);
    }
    return state;
  }

  if (changes.deliverabilityProblems.length) {
    return resolveCartDeliverabilityProblems(newState, changes.deliverabilityProblems, overrideProblems);
  } else {
    return newState;
  }
}

function isUnresolvableItemDeliverabilityProblem(itemProblem: ProblemWithItemIds): boolean {
  return itemProblem.problem !== ItemDeliverabilityProblems.OUTSIDE_DELIVERY_TIME
    && itemProblem.problem !== ItemDeliverabilityProblems.NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION;
}

function isUnresolvableCartDeliverabilityProblem(overrideProblems: boolean): (problem: CartDeliverabilityProblem) => boolean {
  return (problem: CartDeliverabilityProblem) => {
    if (problem.overridable && overrideProblems) {
      return false;
    }
    if (isOutsideMenuScheduleProblem(problem)) {
      return !problem.scheduledMenu;
    }
    if (isVendorCapacityExceededProblem(problem)) {
      return false;
    }
    if (isItemMenuMismatchProblem(problem)) {
      return false;
    }
    if (isUndeliverableItemsProblem(problem)) {
      const itemProblems = getProblemsWithItemIds(problem);
      if (!itemProblems.find(isUnresolvableItemDeliverabilityProblem)) {
        return false;
      }
    }
    return true;
  };
}

/**
 * Resolves all resolvable problems with the cart.
 * This method assumes that all problems are resolvable and that this has been checked before calling.
 *
 * This function is called in one of two cases:
 * a) a user who can override problems has submitted the modal using the "override" feature (overrideProblems=true)
 * b) a user who cannot override problems has submitted using the "resolve" feature (overrideProblems=false)
 *
 * @param {PublicState} state The future state
 * @param {CartDeliverabilityProblem[]} problems List of resolvable problems
 * @param {boolean} overrideProblems If true, will skip resolving problems that can be overridden.
 * @returns {PublicState} A new state based on the input state, with all resolvable problems either resolved or ignored.
 */
function resolveCartDeliverabilityProblems(
  state: PublicState,
  problems: CartDeliverabilityProblem[],
  overrideProblems: boolean
): PublicState {
  // There are four possible issues that can be resolved:
  // 1 OUTSIDE_MENU_SCHEDULE: We clear the cart entirely, and apply the new menu
  // 2 VENDOR_CAPACITY_EXCEEDED: We clear the cart entirely, and apply the new menu
  // 3 ITEM_MENU_MISMATCH: We clear the cart entirely, and apply the new menu
  // 4 UNDELIVERABLE_ITEMS: Remove the undeliverables, and apply the new menu

  const outsideMenuSchedule = problems.find(isOutsideMenuScheduleProblem);
  const vendorCapacityExceeded = problems.find(isVendorCapacityExceededProblem);
  const itemMenuMismatch = problems.find(isItemMenuMismatchProblem);
  const undeliverableItems = problems.find(isUndeliverableItemsProblem) as UndeliverableItems;
  if (outsideMenuSchedule || (vendorCapacityExceeded && !overrideProblems) || itemMenuMismatch) {
    // Same resolution for (1), (2), and (3) - clear the cart
    return {
      ...state,
      cartDeliverability: EMPTY_CART_DELIVERABILITY,
      cartNotifications: [],
      cart: {
        ...state.cart,
        cartItems: []
      }
    };
  }
  if (undeliverableItems) {
    const itemProblems = getProblemsWithItemIds(undeliverableItems);
    const itemIdsToRemove: ItemId[] = [].concat(...itemProblems
      .filter((itemProblem) => !isUnresolvableItemDeliverabilityProblem(itemProblem))
      .map((itemProblem) => itemProblem.itemIds)
    ).filter(unique);
    if (itemIdsToRemove.length && !overrideProblems) { // If we are not overriding, we are resolving; we need to remove these items
      const newCartItems = state.cart.cartItems.filter(
        (cartItem) => itemIdsToRemove.indexOf(cartItem.item.id) < 0
      );
      const newMenu = getCurrentMenu(state) as DeliverableMenu; // state is already the updated value containing the new menu

      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: newCartItems
        },
        cartDeliverability: {
          ...EMPTY_CART_DELIVERABILITY,
          undeliverableItems: getUndeliverableItemsProblem(newCartItems, newMenu.itemDeliverabilities, newMenu.vendor.name)
        },
        cartNotifications: []
      };
    }
  }
  return {
    ...state,
    cartDeliverability: overrideProblems ? createCartDeliverability(problems) : EMPTY_CART_DELIVERABILITY
  };
}

function getCartParams(cart: Cart): CartParams {
  return {
    postcode: cart.postcode,
    deliveryDate: cart.deliveryDate
  };
}

function openPendingCartChangesModal(reason: PendingCartChangeReason, state: PublicState): PendingCartChanges {
  if (state.pendingCartChanges) {
    return state.pendingCartChanges;
  }
  return {
    reason,
    deliverabilityProblems: [],
    menuError: null,
    menu: null,
    isLoading: false,
    params: getCartParams(state.cart)
  };
}

// TODO CPD-5507: clean up recomputeCartProperties
export function recomputeCartProperties(
  state: PublicState,
  recomputeDeliveryPrice: boolean = true,
  recomputeItemsPrice: boolean = true
): PublicState {
  const cart = state.cart;

  const price = calculatePrice(cart.cartItems, cart.isFreeDelivery, cart.price);
  if (!recomputeDeliveryPrice) {
    price.delivery = state.cart.price.delivery;
    price.deliveryVat = state.cart.price.deliveryVat;
  }
  if (!recomputeItemsPrice) {
    price.items = state.cart.price.items;
    price.total = state.cart.price.total;
  }

  const headcount = calculateHeadcount(cart.cartItems);
  const loyaltyPoints = computeLoyaltyPoints(price.items);
  const cartValidityErrors = calculateCartValidityErrors(
    cart,
    state.currentMenu && state.currentMenu.minOrderValue as number || 0
  );

  if (headcount === cart.headcount &&
    loyaltyPoints === cart.loyaltyPoints &&
    isPriceEqual(cart.price, price) &&
    JSON.stringify(state.cartValidityErrors) === JSON.stringify(cartValidityErrors)
  ) {
    return state;
  }

  return {
    ...state,
    cart: {
      ...cart,
      price,
      headcount,
      loyaltyPoints,
    },
    cartValidityErrors,
  };
}

export function ensurePostcodeIsNormal(state: PublicState): PublicState {
  const search = state.searchRequest.postcode;
  const cart = state.cart.postcode;

  const normalisedSearch = normalisePostcode(search);
  const normalisedCart = normalisePostcode(cart);

  if (search === normalisedSearch && cart === normalisedCart) {
    return state;
  } else {
    return {
      ...state,
      searchRequest: {
        ...state.searchRequest,
        postcode: normalisedSearch
      },
      cart: {
        ...state.cart,
        postcode: normalisedCart
      }
    };
  }
}

function isPriceEqual(priceA: CartPrice, priceB: CartPrice): boolean {
  return priceA.delivery === priceB.delivery &&
    priceA.serviceFee === priceB.serviceFee &&
    priceA.items === priceB.items &&
    priceA.total === priceB.total;
}

function calculateCartValidityErrors(cart: Cart, minOrderValue: number): CartValidityError[] {
  if (!cart.cartItems.length) {
    return [];
  }
  const price = CartManager.getCartTotal(cart.cartItems);

  const errors: CartValidityError[] = [];

  if (compare((price || 0), (minOrderValue || 0)) === -1) {
    errors.push({
      type: CartValidityErrorTypes.BELOW_MINIMUM_ORDER_VALUE,
      messageArgs: { minOrderValue },
      overridable: false
    });
  }

  if (!cart.postcode) {
    errors.push({
      type: CartValidityErrorTypes.POSTCODE_REQUIRED,
      messageArgs: {},
      overridable: false
    });
  }

  cart.cartItems.forEach((cartItem: CartItem) => {
    const exceededMaxQuantityBy = cartItem.quantity - cartItem.item.maximumOrderQuantity;

    if (0 < exceededMaxQuantityBy) {
      errors.push({
        type: CartValidityErrorTypes.ITEM_OVER_MAX_QUANTITY,
        messageArgs: {
          itemName: cartItem.item.name,
          exceededMaxQuantityBy
        },
        overridable: false
      });
    }

    if (isBundleCartItem(cartItem)) {
      cartItem.groups.forEach((group: CartItemBundleGroup) => {
        group.cartItems.forEach((childCartItem: CartItem) => {
          const exceededChildItemMaxQuantityBy = childCartItem.quantity - childCartItem.item.maximumOrderQuantity;

          if (0 < exceededChildItemMaxQuantityBy) {
            errors.push({
              type: CartValidityErrorTypes.ITEM_OVER_MAX_QUANTITY,
              messageArgs: {
                itemName: childCartItem.item.name,
                itemBundleName: cartItem.item.name,
                exceededMaxQuantityBy: exceededChildItemMaxQuantityBy
              },
              overridable: false
            });
          }
        });
      });
    }
  });

  return errors;
}

export function deserialisePublicState(state: Partial<PublicState>): PublicState {
  if (!state) {
    return initialState;
  }
  try {
    const searchRequest = deserialiseSearchRequest(state.searchRequest);
    const hasNewCart = state.cartType === CartTypes.NEW_CART;
    const hasMealPlanCart = state.cartType === CartTypes.MEALPLAN_CART;
    const cart = hasNewCart || hasMealPlanCart ? deserialiseCart(state.cart) : EMPTY_CART;
    const mealPlan = hasMealPlanCart ? state.mealPlan : null;

    return {
      ...initialState,
      searchRequest,
      cartType: state.cartType,
      cart,
      mealPlan,
      proposedOrderId: state.proposedOrderId,
      orderValidationError: state.orderValidationError,
      outOfRegionCaptureModal: {
        isOpen: false,
        wasSubmitted: state.outOfRegionCaptureModal.wasSubmitted,
      },
    };
  } catch (e) {
    console.error(e);
    return initialState;
  }
}

function deserialiseSearchRequest(request: SearchRequest): SearchRequest {
  if (!request) {
    return {};
  }
  return {
    // Not deserialising the other parameters as the user may not want them
    postcode: request.postcode || null,
    date: request.date && moment(request.date).isAfter() ? moment(request.date) : null,
  };
}

function deserialiseCart(cart: Cart): Cart {
  if (!cart) {
    return EMPTY_CART;
  }
  try {
    const newCart = createCartFromJson(cart);
    const price = calculatePrice(cart.cartItems, cart.isFreeDelivery, cart.price);

    return {
      ...newCart,
      headcount: calculateHeadcount(cart.cartItems),
      manualHeadcount: cart.cartItems.length ? newCart.manualHeadcount : 0,
      price,
      loyaltyPoints: computeLoyaltyPoints(price.items),

      // Before multicurrency support carts stored in local storage assumed 'GBP' currency.
      // Carts are stored in customers local storage until they create a new cart or clear their storage.
      // Therefore defaulting to GBP here is important to ensure 'old' carts have 'GBP' currency.
      currencyIsoCode: cart.currencyIsoCode || 'GBP',
    };
  } catch (e) {
    console.error(e);
    return EMPTY_CART;
  }
}

export function calculatePrice(items: CartItem[], freeDelivery: boolean = false, currentPrice: CartPrice = null): CartPrice {
  if (!items) {
    return INITIAL_PRICE;
  }

  const itemTotal = CartManager.getCartTotal(items);
  const delivery = freeDelivery ? 0 : (currentPrice ? currentPrice.delivery : 0);
  const deliveryVat = freeDelivery ? 0 : (currentPrice ? currentPrice.deliveryVat : 0);
  const serviceFee = currentPrice ? currentPrice.serviceFee : 0;
  const serviceFeeVat = currentPrice ? currentPrice.serviceFeeVat : 0;

  const total = safeAddCurrency(itemTotal, serviceFee, serviceFeeVat, delivery, deliveryVat);

  return {
    items: itemTotal,
    delivery,
    deliveryVat,
    serviceFee,
    serviceFeeVat,
    total
  };
}

/**
 * Calculates the head count for a cart.
 *
 * The final headcount value depends on the presence of MAIN items. It is computed as:
 * - the sum of quantities of all items in the cart, if there are no MAINs
 * - the sum of quantities of all MAIN items, if there is at least one MAIN single item or one bundle with MAINs in it
 *
 * Note that the headcount of a bundle ignores the bundle quantity if it has upgrade MAINs bu no non-upgrade MAINs.
 *
 * @param items The list of items in this cart.
 * @returns {number} The estimated number of people this cart will feed.
 */
function calculateHeadcount(items: CartItem[]): number {
  if (!items || !items.length) {
    return 0;
  }

  const mainBundles = items
    .filter((cartItem: CartItem) => isItemBundle(cartItem.item) && hasMains(cartItem as CartItemBundle));

  const mainSingleItems = items
    .filter((cartItem: CartItem) => isSingleItem(cartItem.item) && cartItem.item.foodType === FoodTypes.MAIN);

  let headcountRelevantItems = [];
  if (mainBundles.length > 0) {
    headcountRelevantItems = [...mainBundles, ...mainSingleItems];
  } else if (mainSingleItems.length > 0) {
    // no main bundles but found some main singles then use those
    headcountRelevantItems = mainSingleItems;
  } else {
    // no main singles or bundles? everything counts!
    headcountRelevantItems = items;
  }

  return headcountRelevantItems.reduce(sum((cartItem: CartItem) => {
    let headcount = 0;

    // add headcount from a single item or a bundle that a) has no mains, or b) has non-upgrade mains
    if (isSingleCartItem(cartItem)
      || isBundleCartItem(cartItem) && (!hasMains(cartItem) || hasNonUpgradeMains(cartItem))) {
      const portionSize = cartItem.item.portionSize || 1;
      headcount = cartItem.quantity * portionSize;
    }

    if (isBundleCartItem(cartItem)) {
      // add headcount from MAIN upgrades
      headcount += getUpgradeHeadcounts(cartItem);
    }

    return headcount;
  }), 0);
}

function hasMains(cartBundle: CartItemBundle): boolean {
  return cartBundle.groups.some((cartGroup) => cartGroup.cartItems.some((cartItem) => cartItem.item.foodType === FoodTypes.MAIN));
}

function hasNonUpgradeMains(cartBundle: CartItemBundle): boolean {
  return cartBundle.groups
    .filter((cartGroup) => cartGroup.type !== ItemGroupTypes.UPGRADE_GROUP)
    .some((cartGroup) => cartGroup.cartItems.some((cartItem) => cartItem.item.foodType === FoodTypes.MAIN));
}

/**
 * Calculates the head count of all the upgrades in an item bundle. This only counts MAIN items.
 * @param cartItem A bundle cart item.
 * @returns {number} The number of people the upgrades in this bundle will feed.
 */
function getUpgradeHeadcounts(cartItem: CartItemBundle): number {
  return getCartUpgradeGroups(cartItem)
    .reduce((headcount, cartGroup) => {
      const mains = cartGroup.cartItems.filter((groupItem) => groupItem.item.foodType === FoodTypes.MAIN);
      return headcount + calculateHeadcount(mains);
    }, 0);
}

/**
 * From a bundle cart item, returns all upgrade groups as pairs together with their chosen quantities.
 * @param cartItem A bundle cart item.
 * @returns {[UpgradeItemGroup,CartItemBundleGroup][]} List of all the upgrade groups in the bundle, together with their quantities.
 */
function getCartUpgradeGroups(cartItem: CartItemBundle): CartItemBundleGroup[] {
  return cartItem.item.groups
    .map(zip<ItemGroup, CartItemBundleGroup>(cartItem.groups))
    .filter(([group]) => isUpgradeItemGroup(group))
    .map(([, cartGroup]) => cartGroup);
}

/**
 * Set the postcode and date from the search request on the cart only if the cart does not already have a postcode
 * or date.
 * @param {SearchRequest} searchRequest
 * @param {Cart} cart
 * @returns {Cart}
 */
function updateCartWithSearchRequest(cart: Cart, searchRequest: SearchRequest): Cart {
  return {
    ...cart,
    postcode: cart.postcode || searchRequest.postcode,
    deliveryDate: cart.deliveryDate || searchRequest.date
  };
}

function createCartDeliverability(problems: CartDeliverabilityProblem[]): {
  problems: CartDeliverabilityProblem[];
  undeliverableItems: UndeliverableItems | null;
} {
  const problemsWithoutUndeliverableItems = problems.filter(
    (problem) => problem.type !== CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS);
  const undeliverableItems = problems.find(
    (problem) => problem.type === CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS) as UndeliverableItems;

  return {
    problems: problemsWithoutUndeliverableItems,
    undeliverableItems: undeliverableItems || null
  };
}

function getUndeliverableItemsProblem(
  cartItems: CartItem[],
  itemDeliverabilities: ItemDeliverability[],
  vendorName: string
): UndeliverableItems | null {
  const cartItemIds = cartItems.map(({ item }) => item.id);
  const undeliverableItems: ItemDeliverability[] = itemDeliverabilities.filter(({ itemId }) => cartItemIds.indexOf(itemId) >= 0);

  if (!undeliverableItems.length) {
    return null;
  } else {
    return {
      type: CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS,
      itemDeliverabilities: undeliverableItems,
      vendorName,
      overridable: true // Undeliverable items are always admin-overridable,
      // and a non-admin would (should) not be able to add this item at all
    };
  }
}

function updateSearchTypeForCustomer(
  state: PublicState,
  searchType: SearchOrderType,
  searchPreferencesCustomerId: CustomerId | null
): PublicState {
  if (searchPreferencesCustomerId === state.searchPreferencesCustomerId) {
    return state;
  } else {
    return {
      ...state,
      searchType,
      searchPreferencesCustomerId
    };
  }
}

function updateCartItems(
  state: PublicState,
  cartItems: CartItem[]
): PublicState {
  const newCart = {
    ...state.cart,
    cartItems,
    manualHeadcount: cartItems.length ? state.cart.manualHeadcount : 0
  };

  const undeliverableItemsProblem = getUndeliverableItemsProblem(
    cartItems,
    state.currentMenu.itemDeliverabilities as ItemDeliverability[],
    state.vendors.entities[state.currentMenu.vendor as string].name
  );

  let pendingCartChanges = state.pendingCartChanges;
  if (!pendingCartChanges && cartItems.length && !state.cart.postcode) {
    pendingCartChanges = openPendingCartChangesModal(PendingCartChangeReasons.MISSING_DATA, state);
  }

  // note: we don't recompute delivery price here,
  // because it will be computed on backend side anyway (see triggerOrderValidation$ Effect)
  return recomputeCartProperties({
    ...state,
    cart: newCart,
    cartDeliverability: {
      problems: state.cartDeliverability.problems,
      undeliverableItems: undeliverableItemsProblem
    },
    pendingCartChanges,
  }, false, true);
}

export function getCurrentMenu(state: PublicState): DeliverableMenu | null {
  return state.currentMenu && mapDeliverableMenu.inflate(state.currentMenu, state);
}

export const getCurrentVendor = createSelector(
  getCurrentMenu,
  (currentMenu) => currentMenu && currentMenu.vendor || null,
);

export const getSearchResults = (state: PublicState): SearchResult[] => state.searchResults;
export const getSearchRequest = (state: PublicState) => state.searchRequest;
export const getSearchResultsTotal = (state: PublicState) => state.searchResultsTotal;
export const getLastLoadedPage = (state: PublicState) => state.lastLoadedPage;
export const isSearching = (state: PublicState) => state.isSearching;
export const getMealPlan = (state: PublicState) => state.mealPlan;
export const getProposedOrderId = (state: PublicState) => state.proposedOrderId;

export const getCart = (state: PublicState) => state.cart;
export const hasCartId = (state: PublicState) => !!state.cart.id;
export const getDeliveryDate = (state: PublicState) => state.cart.deliveryDate;
export const getCartType = (state: PublicState) => state.cartType;
export const getCartOrder = (state: PublicState) => state.cartOrder;
export const getCartStatus = (state: PublicState) => state.cartStatus;
export const isExistingCartFromAdmin = (state: PublicState) => state.isExistingCartFromAdmin;
export const getCartValidityErrors = (state: PublicState) => state.cartValidityErrors;
export const getCartDeliverabilityProblems = (state: PublicState): CartDeliverabilityProblem[] =>
  state.cartDeliverability.problems.concat(state.cartDeliverability.undeliverableItems || []);
export const getCartNotifications = (state: PublicState) => state.cartNotifications;
export const getOrderValidationError = (state: PublicState) => state.orderValidationError;

export const getMenuDateChanged = (state: PublicState) => state.dateChanged;
export function cartHasAllRequiredParams(state: PublicState): boolean {
  // Needs to be a function to be hoisted; this is used further up in the reducer
  return !!state.cart.postcode && !!state.cart.deliveryDate;
}

export const getPendingCartParams = (state: PublicState) => state.pendingCartChanges && state.pendingCartChanges.params || null;
export const getCartOverridesDialog = (state: PublicState) => {
  const dialog = state.cartOverridesDialog;
  return {
    isOpen: !!dialog,
    isSubmittable: dialog && !!dialog.submitCartOnSuccess
  };
};

export const isInPrinting = (state: PublicState) => !!state.printingEventsCounter;

export const getSearchType = (state: PublicState) => state.searchType;
export const getSearchPreferencesCustomerId = (state: PublicState) => state.searchPreferencesCustomerId;
export const getSearchRegion = (state: PublicState) => state.searchRegion;

export const getCurrentVendorName = (state: PublicState) => state.currentVendorName;
