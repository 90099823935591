import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export const MAX_TEXT_LENGTH_BEFORE_WRAP = 240;

@Component({
  selector: 'app-about-vendor',
  template: `
    <ng-container
      analyticsId="about-vendor"
    >
      <p
        class="body-text text-color-charcoal-2 mb-standard mb-md-large mt-none"
        style="white-space: pre-line"
        test-id="text"
      >{{ truncatedText }} <button
          *ngIf="isTextTooLong"
          class="button-link text-color-charcoal-2"
          (click)="toggleExpanded()"
          analyticsId="read-more"
          test-id="expand"
        >{{ expanded ? 'Read Less' : 'Read More' }}</button>
      </p>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutVendorComponent {

  @Input()
  public set aboutText(text: string) {
    this.text = (text || '')
      .replace(/\u00a0/g, ' ') // Replace &nbsp; characters because some vendors use them
      .trim();
  }

  public expanded: boolean;

  private text: string;

  public get isTextTooLong(): boolean {
    return this.text.length > MAX_TEXT_LENGTH_BEFORE_WRAP;
  }

  public get truncatedText(): string {
    if (this.expanded || !this.isTextTooLong) {
      return this.text;
    }

    const isNextCharacterWhitespace = !!this.text[MAX_TEXT_LENGTH_BEFORE_WRAP].match(/\s/);

    if (isNextCharacterWhitespace) {
      return `${ this.text.slice(0, MAX_TEXT_LENGTH_BEFORE_WRAP).trim() }…`;
    } else {
      const lastSpace = this.text.slice(0, MAX_TEXT_LENGTH_BEFORE_WRAP).lastIndexOf(' ');
      const sliceEnd = lastSpace > 0 ? lastSpace : MAX_TEXT_LENGTH_BEFORE_WRAP;
      return `${ this.text.slice(0, sliceEnd).trim() }…`;
    }
  }

  public toggleExpanded(): void {
    this.expanded = !this.expanded;
  }
}
