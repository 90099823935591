/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type MarketingCampaignComponentName =
  'Search skinny banner'
  | 'Search params modal footer'
  | 'Global Header Link'
  | 'My Meals'
  | 'Vendor Menu'
  | 'No search results'
  | 'Vendors search tile ribbon';

export class MarketingCampaignComponentNames {
  public static SEARCH_SKINNY_BANNER:       MarketingCampaignComponentName & 'Search skinny banner'       = 'Search skinny banner';
  public static SEARCH_PARAMS_MODAL_FOOTER: MarketingCampaignComponentName & 'Search params modal footer' = 'Search params modal footer';
  public static GLOBAL_HEADER_LINK:         MarketingCampaignComponentName & 'Global Header Link'         = 'Global Header Link';
  public static MY_MEALS:                   MarketingCampaignComponentName & 'My Meals'                   = 'My Meals';
  public static VENDOR_MENU:                MarketingCampaignComponentName & 'Vendor Menu'                = 'Vendor Menu';
  public static NO_SEARCH_RESULTS:          MarketingCampaignComponentName & 'No search results'          = 'No search results';
  public static VENDORS_SEARCH_TILE_RIBBON: MarketingCampaignComponentName & 'Vendors search tile ribbon' = 'Vendors search tile ribbon';

  public static get values(): MarketingCampaignComponentName[] {
    return [
      MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
      MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
      MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
      MarketingCampaignComponentNames.MY_MEALS,
      MarketingCampaignComponentNames.VENDOR_MENU,
      MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
      MarketingCampaignComponentNames.VENDORS_SEARCH_TILE_RIBBON,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type MarketingCampaignComponentNameEnum = typeof MarketingCampaignComponentNames;
