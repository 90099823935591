import { Inject, Injectable, Optional, Provider } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';
import { AnalyticsTrackingLogLevel, LogLevel } from './log-level.type';

type LogFunction = (...data: any) => void;

/**
 * Inject into analytics components and services.
 * Call one of the logging functions e.g. logger.warn(), logger.debug() (same arguments as console.log).
 * Output will be prefixed with the corresponding level, and only printed if the system log level is high enough to print the data.
 *
 * @see AnalyticsTrackingModule.forRoot() for the configuration of the system log level
 * @see LogLevel for the list of valid log levels, their descriptions, and their order
 */
@Injectable()
export class AnalyticsLogger {

  /**
   * Provider for use in tests; this will never log anything.
   */
  public static silent: Provider = {
    provide: AnalyticsLogger,
    useFactory: () => new AnalyticsLogger(LogLevel.NONE, null), // We don't need windowRef because LogLevel.NONE doesn't use it
  };

  /**
   * Log a warning, e.g. if an analytics handler has ended up in an unexpected state.
   */
  public warn: LogFunction;
  /**
   * Print high-level information about the current sequence of events.
   * This should be used sparingly and information should be aimed at answering the question "what analytics is happening?"
   */
  public info: LogFunction;
  /**
   * Print low-level information allowing a user to follow the flow of information through the application.
   */
  public verbose: LogFunction;
  /**
   * Print extremely detailed, low-level information to aid in debugging.
   */
  public debug: LogFunction;
  /**
   * Print extremely detailed, low-level information to aid in debugging.
   *
   * @see debug() This is an alias of debug()
   */
  public log: LogFunction;

  constructor(
    @Inject(AnalyticsTrackingLogLevel) @Optional() private readonly logLevel: LogLevel,
    private readonly windowRef: WindowRef,
  ) {
    this.logLevel = this.logLevel || LogLevel.NONE;

    this.warn    = this.createLogFunction('W', LogLevel.WARN, 'warn');
    this.info    = this.createLogFunction('I', LogLevel.INFO, 'info');
    this.verbose = this.createLogFunction('V', LogLevel.VERBOSE, 'log');
    this.debug   = this.createLogFunction('D', LogLevel.DEBUG, 'log');
    this.log     = this.debug;
  }

  private createLogFunction(prefix: string, logLevel: LogLevel, consoleFunction: keyof Console = 'info'): LogFunction {
    if (this.logLevel >= logLevel) {
      return (...data: any): void => {
        this.windowRef.nativeWindow.console[consoleFunction](`[Analytics|${prefix}]`, ...data);
      };
    } else {
      return noop;
    }
  }
}

function noop(): void { /* do nothing */ }
