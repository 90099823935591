import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const individualChoiceCheckoutRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'pay-on-account',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: `Pay on Account toggle clicked`,
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'pay-on-card',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: `Pay on Card toggle clicked`,
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'pay-on-account-disabled-message',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: `PoA accounts@citypantry.com clicked`,
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'process-order',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Clicked Process Order'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'po-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Order PO number entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'additional-reference',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Order additional reference entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'ic-edit-order',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Edit order button clicked'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'ic-edit-delivery',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Edit delivery button clicked'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'card-dropdown',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Card payment method picker clicked'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'card-holder-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Card holder name field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'card-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Card number field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'expiry-month',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Expiry month field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'expiry-year',
    events: 'select',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Expiry year field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'cvv',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'CVV field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'card-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Card name field entered'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'additional-reference-not-required',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Eater cost code required toggle - NO'
    }
  },
  {
    url: '/menus/individual-choice/checkout/payment-details',
    path: 'additional-reference-required',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: 'Eater cost code required toggle - YES'
    }
  }
];
