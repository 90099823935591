import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, mapmap, objectToParams } from '@citypantry/util';
import {
  CalendarOrderGroup,
  createCalendarOrderGroupFromJson,
  CustomerId
} from '@citypantry/util-models';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyOrdersApi {

  constructor(
    private http: HttpClient,
  ) { }

  public getCalendarOrderGroupsWithinRange(from: number, to: number, customerId: CustomerId): Observable<CalendarOrderGroup[]> {
    return this.http.get(
      `/calendar/${customerId}/orders`,
      { params: objectToParams({
        from: moment(from).toISOString(),
        to: moment(to).toISOString()
      })}
    ).pipe(
      getItems(),
      mapmap(createCalendarOrderGroupFromJson)
    );
  }

  public generateInvoice(invoiceId: string): Observable<string> {
    return this.http.get(`/invoices/${invoiceId}/url`).pipe(getItem());
  }
}
