import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const megaFooterRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'mega-footer event-type-link',
    events: 'click',
    definition: {
      category: AnalyticsCategories.MEGA_FOOTER,
      action: AnalyticsActions.CLICK,
      label: 'Event type dropdown clicked',
    },
  },
  {
    url: '*',
    path: 'mega-footer event-types link',
    events: 'click',
    definition: (e) => {
      const element = e.sourceElement.nativeElement;
      const text = ('innerText' in element) ? element.innerText : element.textContent;
      return {
        category: AnalyticsCategories.MEGA_FOOTER,
        action: AnalyticsActions.CLICK,
        label: `(Event Type) ${text} clicked`,
      };
    },
  },
];
