import {
  AfterViewChecked,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  SkipSelf,
  ViewContainerRef,
} from '@angular/core';
import { environment } from '@citypantry/shared-app-config';
import { nonEmpty } from '@citypantry/util';
import { AnalyticsEvent, AnalyticsEventPathSegment, EventDefinition } from './analytics-event.interface';
import { AnalyticsEventsService } from './analytics-events.service';
import { AnalyticsLogger } from './analytics-logger.service';

@Directive({
  selector: '[analyticsId]'
})
export class AnalyticsIdDirective implements OnDestroy, AfterViewChecked {

  @Input()
  public set analyticsId(analyticsId: string) {
    if (!analyticsId.match(/[a-z0-9_-]/i)) {
      throw new Error(`Invalid analytics ID: "${analyticsId}"`);
    }

    this._analyticsId = analyticsId;
  }

  public get analyticsId(): string {
    return this._analyticsId;
  }

  @Input()
  public analyticsData: any;

  private _analyticsId: string;

  private readonly registeredChildren: Map<string, AnalyticsIdDirective[]>;
  private eventUnsubscribers: (() => void)[];
  private hasAttemptedInitialization: boolean; // this flag helps us ensure initialization

  constructor(
    @Optional() @SkipSelf() private parent: AnalyticsIdDirective,
    private element: ElementRef,
    private analyticsService: AnalyticsEventsService,
    private vcRef: ViewContainerRef,
    private logger: AnalyticsLogger,
  ) {
    this.registeredChildren = new Map<string, AnalyticsIdDirective[]>();
    this.eventUnsubscribers = [];
    this.hasAttemptedInitialization = false;
  }

  public ngOnDestroy(): void {
    // For some kinds of events (e.g. URL changes on some platforms), Angular starts calling `ngOnDestroy` before the element is removed.
    // This causes the analytics events not to fire because the listeners are already unregistered by the time the browser fires
    // their DOM events.
    // To ensure that any events that are about to be fired do still fire, we wait until the next stack frame before we unsubscribe.

    setTimeout(() => {
      this.eventUnsubscribers.forEach((unsubscribe) => unsubscribe());

      const needsToDeregister = this.parent && this.hasAttemptedInitialization;
      if (needsToDeregister) {
        this.parent.removeChild(this);
        this.logger.verbose(`(Destroyed) Directive deregistered: "${this.parent.analyticsId}" > "${this.analyticsId}"`);
      }
    });
  }

  public ngAfterViewChecked(): void {
    const isPresentInDOM = this.element.nativeElement?.isConnected;

    if (isPresentInDOM && !this.hasAttemptedInitialization) {
      this.hasAttemptedInitialization = true;
      this.subscribeToEvents();

      if (this.parent) {
        this.parent.addChild(this);
        this.logger.verbose(`Directive registered: "${this.parent.analyticsId}" > "${this.analyticsId}"`);
      }
    }
  }

  public addChild(child: AnalyticsIdDirective): void {
    const id = child.analyticsId;
    const list = this.getRegisteredChildrenById(id);
    if (list.indexOf(child) < 0) {
      this.registeredChildren.set(id, [...list, child]);
    }
  }

  public removeChild(child: AnalyticsIdDirective): void {
    const id = child.analyticsId;
    const list = this.getRegisteredChildrenById(id);
    this.registeredChildren.set(id, list.filter((entry) => entry !== child));
  }

  private getRegisteredChildrenById(id: string): AnalyticsIdDirective[] {
    let result = this.registeredChildren.get(id);
    if (!result) {
      result = [];
      this.registeredChildren.set(id, result);
    }
    return result;
  }

  /**
   * Fetch all direct children with the given ID, in the order in which they appear in the HTML.
   *
   * We cannot rely on the order in which they register as they can be added/removed later,
   * which will cause them to appear in the wrong place.
   *
   * To work around this, we traverse the DOM to compute the positions each time we need them.
   */
  private getChildrenByIdInContentOrder(id: string): AnalyticsIdDirective[] {
    const registeredChildren = this.getRegisteredChildrenById(id);

    // Construct a list of paths along DOM nodes from the registered child directive element
    // to this element (in reverse, i.e. going [(this,) nonChildDomNodeA, nonChildDomNodeB, child]).
    // Each entry in the path corresponds to the position of that DOM node among its siblings.
    // Sorting by those paths allows us to determine the order in which these elements appear in the DOM.
    // E.g. if you have DOM children like
    // <div> // node #0
    //   <p> // node #0
    //     <span analyticsId /> // node #0 (child 0)
    //   </p>
    //   <p>  // node #1
    //     <span>  // node #0
    //       <span analyticsId /> // node #0 (child 1)
    //     </span>
    //   </p>
    // </div>
    // Resulting array will be [[0, 0, 0], [0, 1, 0, 0]].
    // Sorting the arrays by comparing each index in sequence will show that 000 < 0100, so 000 is earlier in the HTML.

    const thisElement = this.element.nativeElement;
    let longestPath = 0;
    const paths = registeredChildren.map((child) => {
      const path = [];
      let currentElement: HTMLElement = child.element.nativeElement;
      while (
        currentElement.parentElement && // Stop if we hit the root
        currentElement !== thisElement && // Don't process thisElement as a DOM node; stop when we hit it

        // If the directive is attached to a non-rendered element, such as `ng-container`,
        // this.element will be a comment node adjacent to the child, so the previous check would never catch.
        // In those cases, thisElement and currentElement will be adjacent to each other, sharing a parent.
        // We can therefore detect that we have reached thisElement by stopping when we hit the shared parent.
        // <div> // currentElement
        //   <!-- ng-container --> // thisElement
        //   <div> // previous currentElement
        //     <div analyticsId> // child element
        currentElement !== thisElement.parentElement
      ) {
        const elementIndexAmongSiblings = Array.prototype.indexOf.call(currentElement.parentElement.children, currentElement);
        // Non-rendered components are replaced with a comment node, so they will return -1 in a search for them amongst children.
        // Skip this element if that happens as it does not actually contain the previous `currentElement`.
        if (elementIndexAmongSiblings >= 0) {
          path.unshift(elementIndexAmongSiblings);
        }

        currentElement = currentElement.parentElement;
      }
      longestPath = Math.max(longestPath, path.length);
      if (!currentElement.parentElement) {
        // This element is not a DOM child of the parent anymore;
        // it might have been removed but not yet destroyed, or it might have been moved around
        return null; // skip it
      }
      return {
        path,
        child,
      };
    }).filter(nonEmpty);

    if (!environment.production && (registeredChildren.length * longestPath) > 1000) {
      /* eslint-disable max-len */ // More readable this way
      console.warn(
        'WARNING: Analytics elements are deeply nested and have many siblings. This may cause a performance bottleneck. ' +
        'Consider reducing the level of nesting, or grouping siblings by adding parent analyticsId elements that contain a subset of the children. ' +
        'It is possible to use placeholder elements such as <ng-container analyticsId="placeholder">.' +
        'You can also try to use `delayRegistrationUntil` option on elements that have `*ngIf` initially set to `false`.'
      );
      /* eslint-enable max-len */
    }

    const comparePaths = ([a, ...restA]: number[], [b, ...restB]: number[]): number => {
      if (a !== b) {
        return a - b;
      } else {
        if (!restA.length || !restB.length) {
          // This should not happen, as it can only happen when all previous comparisons were equal.
          // That would indicate that A is a DOM descendant of B (or vice versa),
          // but then A should not be an injected child of *this* element (it would get injected as a child into B).
          console.error('Failed to compute indices properly: elements are unexpectedly nested', id, paths);
          return restA.length - restB.length; // fail gracefully; parent gets returned before child
        }

        return comparePaths(restA, restB);
      }
    };

    return paths
      .sort((a, b) => comparePaths(a.path, b.path))
      .map(({ child }) => child);
  }

  private subscribeToEvents(): void {
    const events = this.getEventTriggers();

    for (const event of events) {
      if (typeof event === 'string') {
        const eventName = event;

        if (this.element.nativeElement && this.element.nativeElement.addEventListener) {
          const eventListener = ($event: Event) => {
            this.trackEvent($event, eventName);
          };
          this.element.nativeElement.addEventListener(eventName, eventListener);
          this.eventUnsubscribers.push(() => this.element.nativeElement.removeEventListener(eventName, eventListener));
        }
      } else {
        const { class: componentClassName, eventName } = event;
        const eventEmitter = this.getEventEmitter(componentClassName, eventName);
        if (!eventEmitter) {
          continue;
        }
        const subscription = eventEmitter.subscribe(
          ($event: any) => {
            this.trackEvent($event, event);
          });
        this.eventUnsubscribers.push(() => subscription.unsubscribe());
      }
    }
  }

  private getEventTriggers(path: AnalyticsEventPathSegment[] = []): EventDefinition[] {
    this.addStepToPath(path);

    if (this.parent) {
      return this.parent.getEventTriggers(path);
    } else {
      return this.analyticsService.getEventTriggersForElement(path);
    }
  }

  private trackEvent(event: Event, eventDefinition: EventDefinition): void {
    let analyticsEvent: AnalyticsEvent;
    if (typeof eventDefinition === 'string') {
      analyticsEvent = {
        sourceElement: this.element,
        source: 'DOM',
        event,
        eventName: eventDefinition,
        path: [],
        data: {},
      };
    } else {
      analyticsEvent = {
        sourceElement: this.element,
        source: 'Component',
        componentEvent: eventDefinition,
        event,
        path: [],
        data: {},
      };
    }
    this.logger.debug('Tracking Analytics Event', this.analyticsId, analyticsEvent);
    this.bubbleEvent(analyticsEvent);
  }

  private bubbleEvent(event: AnalyticsEvent): void {
    this.addStepToPath(event.path);

    if (this.analyticsData) {
      event.data[this._analyticsId] = this.analyticsData;
    }

    if (this.parent) {
      this.parent.bubbleEvent(event);
    } else {
      this.analyticsService.dispatchEvent(event);
    }
  }

  /**
   * Modifies the given array, adding the current element to the path, and updating the index of the last child if present.
   * @param path An event path to update. This element will be modified by this operation.
   */
  private addStepToPath(path: AnalyticsEventPathSegment[]): void {
    if (path.length) {
      // Call came from a child, update its index
      const child = path[path.length - 1];
      const childrenAtId = this.getChildrenByIdInContentOrder(child.id);
      const index = childrenAtId.indexOf(child.directive);
      if (index >= 0 && childrenAtId.length > 1) {
        child.index = index;
        child.maxIndex = childrenAtId.length - 1;
      }
    }

    path.push({
      id: this._analyticsId,
      index: 0,
      maxIndex: 0,
      data: this.analyticsData || null,
      directive: this,
    });
  }

  private getEventEmitter<T extends { [K: string]: EventEmitter<any> }>(
    componentClassName: string,
    eventName: keyof T
  ): EventEmitter<any> | null {
    const component = this.getHostElementFromViewContainerRef<T>();
    this.logger.debug('Fetched host component to attach event emitter', component);

    if (!environment.production && !(component && component.constructor.name === componentClassName)) {
      this.logger.warn('Misconfigured analytics event rule: ' +
        `Could not find a component with class '${componentClassName}' on the element. ` +
        '\nEvent definition:', { class: componentClassName, eventName },
      '\nDOM element:', this.element.nativeElement,
      '\nAnalytics Directive instance:', this
      );

      // If we can't find the right element, throw an error.
      // We can only do this in dev mode, but it will help us catch bugs.
      // If this throws and the element is definitely bound to the right component,
      // check if the vcRef hack in getHostElementFromViewContainerRef is working correctly.
      throw new Error(`[ANALYTICS MISCONFIGURATION] Attempted to attach event "${eventName}" to a non-matching component:
Class "${componentClassName}" was not found on element with analyticsId="${this.analyticsId}".
Check if the element has an instance of that class, or if the analytics rule for elements with this ID is misconfigured.`);
    }

    const eventEmitter = component && component[eventName];
    if (!eventEmitter) {
      const errorMessage = `Attempted to attach event "${eventName}" to component with analytics-id="${this.analyticsId}" ` +
        `but class "${componentClassName}" does not have an EventEmitter for this event`;
      if (environment.production) {
        console.warn(errorMessage);

        return null;
      } else {
        throw new Error(errorMessage);
      }
    }

    return eventEmitter;
  }

  /**
   * Get the component on this element.
   *
   * This is highly specific to Angular 12 and may break in later iterations.
   * When figuring out how to access it in this version of Angular, I found it easiest to console.log(this.vcRef)
   * and search the resulting tree for a reference to the component I'm looking for (e.g. InViewportComponent),
   * then working backwards to justify why that reference is likely to stay the same
   * (see the current implementation below for documentation).
   */
  private getHostElementFromViewContainerRef<T extends { [K: string]: EventEmitter<any> }>(): T | null {
    // TL;DR of the below method:
    // return this.vcRef._lContainer[0][8];
    // Writeup at https://stackoverflow.com/a/69706978/462235

    const vcRef = this.vcRef as any; // We're accessing private properties so we cast to any to avoid awkward TS validation issues

    // We fetch the component associated with the element this directive is attached to by navigating via the ViewContainerRef.
    // The VCRef contains a reference to the LContainer, which represents the state associated with the container:
    // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/container.ts#L65
    const lContainer = vcRef._lContainer;

    if (!lContainer) {
      return null;
    }

    // LView has all its elements defined as array elements, with keys hardcoded to numeric constants:
    // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/view.ts#L26-L57
    // We care about two of them:
    const HOST = 0; // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/view.ts#L29
    const CONTEXT = 8; // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/view.ts#L37

    // LContainer is an array, with the element at the HOST position being an LView if the container is on a Component Node
    // (which it should be if this instance of analyticsId has been correctly set up with this component class rule).
    // Note that LContainer uses the same indexes as LView, so it's the same HOST constant as declared in the LView interfaces file.
    // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/container.ts#L66-L72

    const lView = lContainer[HOST];
    if (!lView) {
      return null;
    }

    // For a non-root component, the context is the component instance.
    // So if this analyticsId directive is correctly attached to an Angular Component (e.g. `<app-*`),
    // this array entry will contain the instance of that component.
    // https://github.com/angular/angular/blob/12.2.x/packages/core/src/render3/interfaces/view.ts#L173-L180
    const contextElement = lView[CONTEXT];

    return contextElement || null;
  }
}
