import { Injectable } from '@angular/core';
import { CartApi } from '@citypantry/shared-api';
import { AppConfig } from '@citypantry/shared-app-config';
import { AppState, CartSelectors, MenuSelectors } from '@citypantry/state';
import { CartShareModal, PublicActions } from '@citypantry/state-public';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ClipboardService } from 'ngx-clipboard';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class MenuEffects {

  public showShareCartLinkModal$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.shareCartLinkClick.type),
    withLatestFrom(
      this.store.select(CartSelectors.getCart),
      this.store.select(MenuSelectors.getCurrentVendor),
    ),
    switchMap(([, cart, vendor]) => {
      return this.cartApi.cloneCart(cart).pipe(
        map((clonedCart) =>
          `${this.config.FRONTEND_BASE}/menus/vendors/${vendor.slug}?restoreItemsFromCartId=${clonedCart.id}` +
            `&restoreSource=shareable_cart_link`,
        ),
        map((shareUrl) => PublicActions.shareCartModalOpen({ shareUrl }))
      );
    }),
  ));

  public copyShareCartURL$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.shareCartCopyButtonClick.type),
    withLatestFrom(this.store.select(CartSelectors.getCartShareModal)),
    map(([, modal]: [Action, CartShareModal]) => {
      try {
        this.clipboardService.copyFromContent(modal.shareUrl);
        return PublicActions.shareCartLinkCopySuccess();
      } catch (e) {
        return PublicActions.shareCartLinkCopyFailed();
      }
    }),
  ));

  public shareCartLinkCopied$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.shareCartLinkCopySuccess.type),
    mapTo(PublicActions.tickShareCartModalCountdown({ timeRemaining: 5 })),
  ));

  public tickShareCartModalCountdown$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PublicActions.tickShareCartModalCountdown.type),
    withLatestFrom(this.store.select(CartSelectors.getCartShareModal)),
    switchMap(([, modal]: [Action, CartShareModal]) => {
      if (!modal) {
        return EMPTY;
      }

      const timeRemaining = modal.timeRemaining;
      if (timeRemaining <= 0) {
        return of(PublicActions.shareCartModalDismiss());
      }

      return timer(1000).pipe(
        map(() => PublicActions.tickShareCartModalCountdown({ timeRemaining: timeRemaining - 1 })));
    }),
  ));

  constructor(
    private action$: Actions,
    private cartApi: CartApi,
    private store: Store<AppState>,
    private config: AppConfig,
    private clipboardService: ClipboardService
  ) {
  }
}
