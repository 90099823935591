import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dispatch, Select } from '@citypantry/state';
import { CustomerQualification } from '@citypantry/util-models';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerQualificationActions } from '../customer-qualification.actions';
import { QualificationSelectors } from '../customer-qualification.select';

@Component({
  selector: 'app-customer-qualification-modal',
  template: `
    <app-customer-qualification-modal-content
      *ngIf="isOpen$ | async"
      [isCompleted]="isCompleted$ | async"
      (close)="hide()"
      test-id="content"
    >
      <app-customer-qualification-form
        [isSubmitting]="isSubmitting$ | async"
        (formSubmit)="submit($event)"
        test-id="form"
      >
      </app-customer-qualification-form>
    </app-customer-qualification-modal-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerQualificationModalComponent {
  @Select(QualificationSelectors.isModalOpen)
  public isOpen$: Observable<boolean>;

  @Select(QualificationSelectors.isFormCompleted)
  public isCompleted$: Observable<boolean>;

  @Select(QualificationSelectors.isSubmitting)
  public isSubmitting$: Observable<boolean>;

  @Dispatch()
  public submit(qualificationData: CustomerQualification): Action {
    return CustomerQualificationActions.qualifyCustomer({ qualificationData });
  }

  @Dispatch()
  public hide(): Action {
    return CustomerQualificationActions.hideQualificationModal();
  }
}
