import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CartCustomItem, CartItemBundle, isCustomItem, isItemBundle, isSingleItem, Item } from '@citypantry/util-models';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SubmitCustomItemModalEvent } from './custom-item-modal-form/custom-item-modal-form.component';
import { SubmitItemBundleModalEvent } from './item-bundle-modal-form/item-bundle-modal-form.component';

export type SubmitItemModalEvent = SubmitItemBundleModalEvent | SubmitCustomItemModalEvent;

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemModalComponent {
  @Input()
  public item: Item;

  @Input()
  public cartItem: CartItemBundle | CartCustomItem | null;

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public hidePrices: boolean = false;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  @Output()
  public add: EventEmitter<SubmitItemModalEvent> = new EventEmitter<SubmitItemModalEvent>();

  /* eslint-disable @typescript-eslint/typedef */ // These are okay to be inferred. We don't want to redeclare them
  public isCustomItem = isCustomItem;
  public isSingleItem = isSingleItem;
  public isItemBundle = isItemBundle;
  /* eslint-enable */

  public swiper: SwiperConfigInterface = {
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  public onCancel(): void {
    this.close.emit();
  }

  public onSubmitItem(event: SubmitItemModalEvent): void {
    this.add.emit(event);
    this.close.emit();
  }

  public getItemBundlePricePerPerson(): number | null {
    if (!isItemBundle(this.item)) {
      return null;
    }
    const portionSize = this.item.portionSize > 1 ? this.item.portionSize : 1;
    return this.item.price / portionSize;
  }
}
