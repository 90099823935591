import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-rating',
  template: `
    <app-star-rating
      [rating]="rating"
      [compact]="compact"
      [disabled]="!this.rating"
      test-id="starRatingComponent"
    >
      <span
        *ngIf="displayValue === 'rating'"
        class="rating-stars__text"
        test-id="displayRating"
      >
        ({{ displayRating }})
      </span>
      <span
        *ngIf="displayValue === 'reviewCount'"
        class="rating-stars__text rating-stars__text--grey"
        test-id="displayReviewCount"
      >
        {{ displayReviewCount }}
      </span>
    </app-star-rating>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VendorRatingComponent {

  @Input()
  public rating: number | null;

  @Input()
  public reviewCount: number;

  @Input()
  public compact: boolean;

  @Input()
  public displayValue: 'rating' | 'reviewCount';

  public get displayReviewCount(): string {
    if (!this.reviewCount || !this.rating) {
      return 'Not Enough Ratings';
    }

    return `(${this.reviewCount})`;
  }

  public get displayRating(): string {
    if (this.rating) {
      return this.rating.toFixed(1);
    }
    return '';
  }
}
