<div
  class="delivery-details"
  *ngIf="orderTracking"
  test-id="deliveryDetails"
>
  <div class="info-banner info-banner--solid justify-content-start pl-large">
    <div class="info-banner__content text-uppercase text-size-small text-weight-bold">
      Delivery
    </div>
  </div>

  <div class="panel">
    <div class="panel__content">
      <div class="text-weight-bold text-size-default mb-medium mt-small">
        <div class="d-flex mb-medium">
          <span class="mr-small align-self-end">
            <i class="icon">date</i>
          </span>
          <span>
            <h4
              class="mb-none"
              test-id="deliveryDate"
            >{{ orderTracking.requestedDeliveryDate | dateInEuropeLondon:'dddd D MMMM, YYYY' }}</h4>
          </span>
        </div>

        <ng-container *ngIf="!isPantryPackageOrder">
          <div class="d-flex mb-medium">
            <span class="mr-small align-self-end">
              <i class="icon">time</i>
            </span>
            <span>
              <h4
                class="mb-none"
                test-id="deliveryWindow"
              >{{ orderTracking.deliveryWindow | formatDeliveryWindow }}</h4>
            </span>
          </div>

          <div
            *ngIf="canEditDate()"
            class="px-small text-right"
            test-id="editDate"
          >
            <button
              class="button-link text-weight-bold"
              (click)="editDateClicked()"
              analyticsId="edit-date-button"
            >
              Edit
            </button>
          </div>
        </ng-container>
      </div>

      <hr class="my-x-large" *ngIf="!isPantryPackageOrder" />

      <div class="d-flex" *ngIf="!isPantryPackageOrder">
        <div class="mr-small">
          <i class="icon ">pin</i>
        </div>
        <div
          class="d-flex flex-column"
          *ngIf="orderTracking.location; let location"
          test-id="deliveryAddress"
        >
          <span
            class="my-x-small"
            *ngFor="let line of toArray(location)"
          >{{ line }}<br>
          </span>
        </div>
      </div>

      <div *ngIf="isCustomerOrStaffOrSudo">
        <hr class="my-x-large" />

        <ng-container
          class="text-right"
          *ngIf="!isLoading; else loading"
        >
          <div
            class="d-flex"
            [ngClass]="{'align-items-center' : !orderTracking.deliveryContactPhoneNumber}"
            *ngIf="!isEditingDeliveryContact; else editingContact"
          >
            <div class="mr-small">
              <i class="icon">person</i>
            </div>
            <div class="d-flex flex-column">
              <span
                class="my-x-small"
                *ngIf="orderTracking.deliveryContactFullName"
                test-id="contactName"
              >{{ orderTracking.deliveryContactFullName }}
              </span>
              <span
                *ngIf="orderTracking.deliveryContactPhoneNumber"
                test-id="contactNumber"
              >{{ orderTracking.deliveryContactPhoneNumber }}
              </span>
            </div>

            <button
              *ngIf="canEditDeliveryContact()"
              class="button-link text-weight-bold pt-medium px-small ml-auto"
              (click)="editDeliveryContactClicked()"
              test-id="editContactButton"
              analyticsId="edit-contact-button"
            >
              Edit
            </button>
          </div>

          <ng-template #editingContact>
            <select
              class="form-input"
              (change)="setSelectedContact($event)"
              test-id="contactSelect"
              analyticsId="delivery-contact-picker"
            >
              <option
                *ngFor="let deliveryContact of deliveryContacts"
                [value]="deliveryContact.id"
                [selected]="deliveryContact.id === selectedContactId"
                test-id="contactOptions"
              >
                {{ deliveryContact.fullName }}{{ deliveryContact.phoneNumber ? ', ' : '' }} {{ deliveryContact.phoneNumber }}
              </option>
            </select>

            <div class="text-right mt-small">
              <button
                class="button-link text-weight-bold"
                (click)="addContact()"
                test-id="addContactButton"
                analyticsId="add-contact-button"
              >
                Add
              </button>

              <button
                class="button-link text-weight-bold ml-small"
                (click)="updateContact()"
                test-id="saveContactButton"
                analyticsId="save-contact-button"
              >
                Save
              </button>
            </div>

          </ng-template>
        </ng-container>

        <ng-template #loading>
          <div
            class="circle-spinner d-block mx-auto"
            test-id="loadingSpinner"
          ></div>
        </ng-template>

        <div
          *ngIf="orderTracking.displayDeliveryInstructions && !isPantryPackageOrder"
          class="d-flex my-large"
          test-id="deliveryInstructions"
        >
          <div class="mr-small">
            <i class="icon">info</i>
          </div>
          <div class="d-flex flex-column">
            <span
              class="my-x-small text-size-small"
            >{{ orderTracking.displayDeliveryInstructions }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
