import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat'
})
export class ConcatPipe implements PipeTransform {

  public transform(value: string[] | null, ...args: string[]): string {
    const separator = args[0] || ', ';

    return value ? value.join(separator) : '';
  }
}
