import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';
import { CustomerLocation } from '@citypantry/util-models';
import { Parameters } from '../parameters-form/parameters.model';

@Component({
  selector: 'app-search-parameters-popup-content',
  templateUrl: './search-parameters-popup-content.component.html',
})
export class SearchParametersPopupContentComponent implements OnInit {

  @Input()
  public params: Parameters;

  @Input()
  public locations: CustomerLocation[];

  @Input()
  public showCloseButton: boolean;

  @Input()
  public autocomplete: boolean;

  @Input()
  public marketingCampaignComponent: MarketingCampaignComponentModel;

  @Input()
  public canEnterPostcode: boolean;

  @Output()
  public complete: EventEmitter<Parameters> = new EventEmitter();

  @Output()
  public close: EventEmitter<'close' | 'outside'> = new EventEmitter();

  public form: FormGroup;
  public paramsControl: FormControl;
  public submitted: boolean;

  public ngOnInit(): void {
    this.paramsControl = new FormControl(this.params);
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.paramsControl.valid) {
      this.complete.emit(this.paramsControl.value);
    }
  }

  public onClose(source: 'close' | 'outside'): void {
    this.close.emit(source);
  }
}
