import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { HistorySelectors } from '@citypantry/shared-history';
import { AppState } from '@citypantry/state';
import { arrayify, nonEmpty, safeUnsubscribe } from '@citypantry/util';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import UrlPattern from 'url-pattern';
import { AnalyticsEvent, EventDefinition } from './analytics-event.interface';
import { AnalyticsLogger } from './analytics-logger.service';
import { AnalyticsActions } from './analytics.actions';
import {
  AnalyticsRule,
  AnalyticsRules,
  AnalyticsRulesProvider,
  DispatchAnalyticsEventData,
  RuleDefinition,
} from './rules/analytics-rule.interface';

interface ParsedRule {
  url: UrlPattern;
  path: RulePathSegment[]; // starting from bottom element, going up the tree (towards <html>)
  definition: RuleDefinition;
  events: EventDefinition[];
}

interface RulePathSegment {
  id: string;
  index: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsEventsService implements OnDestroy {

  private readonly rules: ParsedRule[];

  private readonly rulesById: Map<string, ParsedRule[]>;

  private currentUrl: string;
  private readonly storeSubscription: Subscription;

  constructor(
    @Optional() @Inject(AnalyticsRules) rulesProviders: (AnalyticsRule[] | AnalyticsRulesProvider)[],
    private store: Store<AppState>,
    private logger: AnalyticsLogger,
  ) {
    const rules = (rulesProviders || []).map((provider) => Array.isArray(provider) ? provider : provider.getRules());
    this.rules = [].concat(...rules).map(parseRule);
    this.rulesById = new Map();
    for (const rule of this.rules) {
      const id = rule.path[0].id;
      let rulesInMap = this.rulesById.get(id);
      if (!rulesInMap) {
        rulesInMap = [];
        this.rulesById.set(id, rulesInMap);
      }
      rulesInMap.push(rule);
    }

    this.logger.verbose(`Initialised with ${this.rules.length} rules`);

    // Ensure this gets updated in the _next_ stack frame, because some events (e.g. clicking a URL) will trigger an immediate URL change.
    // We want the URL from when the event was triggered, not what it has already been set to.
    this.storeSubscription = this.store.select(HistorySelectors.getReferer)
      .pipe(
        delay(0), // Will not update until the next stack frame
        map((url) => url === null ? 'unknown' : url) // In rare cases the referer will not be set so make sure it is set to something
      )
      .subscribe((value) => this.currentUrl = value);
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.storeSubscription);
  }

  /**
   * Trigger all appropriate handling for an event that has happened.
   * It is possible that no matchers consider this event, in which case it is ignored.
   */
  public dispatchEvent(event: AnalyticsEvent): void {
    this.logger.verbose('Received a dispatch event', event);

    const matchedData = this.getDispatchDataForEvent(this.currentUrl, event);
    this.logger.debug('Matched dispatch data from rules:', matchedData);

    // Commented out because it is a legitimate case, but might be useful for debugging
    // if (!matchedData.length && !environment.production) {
    //   console.warn('Event has no handlers:', event);
    // }

    matchedData.forEach(({ action, category, label, extraFields }) => {
      // Send extraFields as a string to avoid segment storing one new column per extra field
      const additionalData = extraFields && Object.keys(extraFields).length ?
        { additionalData: JSON.stringify(extraFields) } : {};

      const trackingEventAction = AnalyticsActions.trackEvent({ action, properties: { category, label, ...additionalData } });
      this.logger.info(`Dispatching action [${action}|${category}|${label}]`, trackingEventAction);
      this.store.dispatch(trackingEventAction);
    });
  }

  public getEventTriggersForElement(elementPath: RulePathSegment[]): EventDefinition[] {
    const matchingRules = this.getRulesForPath(elementPath);

    return matchingRules
      .reduce((_events, rule) => _events.concat(rule.events), [])
      .filter((event: EventDefinition, index: number, events: EventDefinition[]) => {
        if (typeof event === 'string') {
          return events.indexOf(event) === index;
        } else {
          const firstOccurrence = events.findIndex((_event) =>
            typeof _event !== 'string' &&
            _event.class === event.class &&
            _event.eventName === event.eventName);
          return firstOccurrence === index;
        }
      });
  }

  private getDispatchDataForEvent(url: string, event: AnalyticsEvent): DispatchAnalyticsEventData[] {
    const urlWithoutQuery = (url || '').split(/[?#]/g, 2)[0];
    this.logger.debug('URL is', urlWithoutQuery);

    let results: DispatchAnalyticsEventData[] = [];
    for (const rule of this.rules) {
      const matchesUrl = rule.url.match(urlWithoutQuery);
      if (
        matchesUrl &&
        pathMatches(rule.path, event.path) &&
        eventMatches(rule.events, event.source === 'DOM' ? event.eventName : event.componentEvent)
      ) {
        this.logger.verbose('Matched rule', rule);
        const definition = rule.definition;
        try {
          results = results.concat(arrayify(typeof definition === 'function' ? definition(event, matchesUrl) : definition));
        } catch (e) {
          this.logger.warn('Error when getting rule data', e, definition);
          // Skip this rule and continue; one rule failing should not stop other analytics from working
        }
      }
    }

    return results;
  }

  private getRulesForPath(elementPath: RulePathSegment[]): ParsedRule[] {
    const rules = this.rulesById.get(elementPath[0].id);
    if (!rules) {
      return [];
    }

    return rules.filter((rule) => pathMatches(rule.path, elementPath, true));
  }
}

/**
 * Determines whether a given element is part of a given rule path.
 * @param rulePath The rule path to test (expected)
 * @param elementPath The path to the element (actual)
 * @param ignorePositions If set to true, the matcher ignores all positional parts of the path, i.e. the `[n]` parts.
 *                        Use when determining whether an element has the potential of matching a path rather than
 *                        whether it currently matches.
 *                        As an example, used when evaluating which events in rules need to be bound to the element (because the element
 *                        may be at a different position when the rule triggers).
 */
export function pathMatches(rulePath: RulePathSegment[], elementPath: RulePathSegment[], ignorePositions: boolean = false): boolean {
  const expectedPath = rulePath.slice();
  const actualPath = elementPath.slice();

  // Paths are in reverse order - targeted element and the emitting element are the first ones in the list
  const firstExpectedSegment = expectedPath[0];
  const firstActualSegment = actualPath[0];

  if (!segmentsMatch(firstExpectedSegment, firstActualSegment, ignorePositions)) {
    return false;
  }

  // Compare full path contents
  for (const expectedSegment of expectedPath) {
    let matches = false;
    while (!matches && actualPath.length) {
      const actualSegment = actualPath.shift();
      matches = segmentsMatch(expectedSegment, actualSegment, ignorePositions);
    }

    if (!matches) {
      return false;
    }
  }

  return true;
}

function segmentsMatch(expectedSegment: RulePathSegment, actualSegment: RulePathSegment, ignorePositions: boolean): boolean {
  return (expectedSegment.id === actualSegment.id) &&
    (ignorePositions || expectedSegment.index === null || expectedSegment.index === actualSegment.index);
}

/**
 * Determines whether the given event matches the rule event(s).
 * @param ruleEvents One or more event names (expected)
 * @param actualEvent The triggered event (actual)
 */
function eventMatches(ruleEvents: EventDefinition[], actualEvent: EventDefinition): boolean {
  return !!ruleEvents.find((event: EventDefinition) => {
    if (typeof event === 'string') {
      return event === actualEvent;
    } else if (typeof actualEvent === 'string') {
      return false;
    } else {
      return event.eventName === actualEvent.eventName;
    }
  });
}

/**
 * Parses a rule definition into the internal format.
 */
function parseRule({ url: ruleUrl, path: rulePath, definition, events: ruleEvents }: AnalyticsRule): ParsedRule {
  const url = new UrlPattern(ruleUrl);
  const events = arrayify(ruleEvents);
  const path = rulePath.split(/\s+/g).filter(nonEmpty)
    .map((segment) => {
      const segmentMatch = segment.match(/^([a-z0-9_-]+)(?:\[(\d+)\])?$/i);
      if (!segmentMatch) {
        throw new Error(`Path segment should look like "somePath-segment_id" or "somePath-segment_id[0]", was "${segment}"`);
      }
      const [, id, indexMatch] = segmentMatch;
      const index = typeof indexMatch === 'string' ? parseInt(indexMatch, 10) : null;
      return {
        id,
        index
      };
    }).reverse();

  return {
    url,
    path,
    events,
    definition,
  };
}

// Commented out but present for debugging
// function pathToString(path: RulePathSegment[]): string {
//   return path.map(({ id, index }) => `${id}[${index}]`).join(' ');
// }
