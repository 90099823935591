import { MajorCurrency } from '../money';

export interface ColleagueGroupSummary {
  id: string;
  oid: string;
  name: string;
  budget: MajorCurrency | null;
  numberOfMembers: number;
}

export function createColleagueGroupSummaryFromJson(json: Partial<ColleagueGroupSummary>): ColleagueGroupSummary {
  return {
    id: json.id,
    oid: json.oid,
    name: json.name,
    numberOfMembers: json.numberOfMembers,
    budget: json.budget
  };
}
