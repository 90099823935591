import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItems, mapmap } from '@citypantry/util';
import {
  AdvancedBudgets,
  createBudgetRangeFromJson,
  createEaterFromJson,
  CustomerId,
  CustomerLocationId,
  Eater,
  UserId
} from '@citypantry/util-models';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerApi {

  constructor(
    private http: HttpClient,
  ) { }

  public downloadCustomerOrdersReportCsv(query: { from: moment.Moment, to: moment.Moment }): Observable<string> {
    const body = {
      pageSize: -1,
      from: query.from.toISOString(),
      to: query.to.toISOString(),
    };
    return this.http.post(`/customers/me/eaters-export-csv`, body).pipe(
      map((response: { url: string }) => response.url)
    );
  }

  public saveAdvancedBudgets(advancedBudgets: AdvancedBudgets): Observable<AdvancedBudgets> {
    return this.http.post(`/customers/me/advanced-budgets`, { advancedBudgets }).pipe(
      getItems(),
      mapmap(createBudgetRangeFromJson)
    );
  }

  public promoteLeadUser(
    customerId: CustomerId | 'me',
    userId: UserId,
    locationId: CustomerLocationId,
    primaryOperationalRegion: string,
    phoneNumber: string | null,
  ): Observable<null> {
    return this.http.post(`/customers/${customerId}/promote-lead-user`, { userId, locationId, primaryOperationalRegion, phoneNumber }).pipe(
      mapTo(null)
    );
  }

  public getAllEaters(): Observable<Eater[]> {
    return this.http.get('/customer/eaters').pipe(
      getItems(),
      map((eaters) =>
        /**
         * The API is currently returning the eaters in an object like such:
         * { eaterId: { ...eater Object } }
         * as opposed to a simple array:
         * [ { ...eater Object } ]
         * Hence this mapping
         *
         * TODO CPD-13942: Remove this map when the api is updated to just return the array
         */
        //
        Object.keys(eaters).map((id) => eaters[id])
      ),
      mapmap(createEaterFromJson)
    );
  }
}
