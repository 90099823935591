import { AnimationEvent, style, transition, trigger, useAnimation } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fadeInDown, fadeOutUp } from '@citypantry/util-animations';
import { WindowRef } from '@citypantry/util-browser';
import { noop } from 'rxjs';
import { ContextDropdownAnchorDirective } from './context-dropdown-anchor.directive';

@Component({
  selector: 'app-context-dropdown',
  template: `
    <ng-content select="[context-dropdown-anchor]"></ng-content>
    <div
      class="context-dropdown"
      [class.context-dropdown--align-right]="align === 'right'"
      [class.context-dropdown--above]="isAbove"
      [class.context-dropdown--center-on-anchor]="centerOnAnchor"
      #dropdownParent
      test-id="dropdownParent"
    >
      <div
        *ngIf="isOpen"
        [@inOutAnimation]
        (@inOutAnimation.done)="onInOutAnimationDone($event)"
        class="context-dropdown__content animate-dropdown-child"
        #dropdownContent
        test-id="dropdownContent"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  exportAs: 'dropdown',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        useAnimation(fadeInDown)
      ]),
      transition(':leave', [
        style({
          overflow: 'visible'
        }),
        useAnimation(fadeOutUp)
      ])
    ])
  ]
})
export class ContextDropdownComponent implements OnDestroy, AfterViewChecked {

  @ContentChild(ContextDropdownAnchorDirective, { read: ElementRef, static: false })
  public anchor: ElementRef;

  @ViewChild('dropdownParent', { static: false })
  public dropdownParent: ElementRef;

  @ViewChild('dropdownContent', { static: false })
  public dropdownContent: ElementRef;

  @Input()
  public align: 'right' | 'left';

  @Input()
  public positionAbsolute: boolean;

  @Input()
  public centerOnAnchor: boolean;

  @Output()
  public closeAnimationDone: EventEmitter<void> = new EventEmitter();

  public isOpen: boolean;
  public isAbove: boolean;

  private hasBeenOpened: boolean;
  private originalParentNode: HTMLElement;
  private unsubscribeOutsideClick: () => void;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private cdr: ChangeDetectorRef
  ) {
    this.unsubscribeOutsideClick = noop;
    this.isOpen = false;

  }

  public ngOnDestroy(): void {
    this.unsubscribeOutsideClick();

    const currentParentNode = this.renderer.parentNode(this.dropdownParent.nativeElement);
    this.renderer.removeChild(currentParentNode, this.dropdownParent.nativeElement);
  }

  public ngAfterViewChecked(): void {
    // The intended flow is documented in unit tests - it should be:
    // open() -> ngAfterViewChecked() -> setUpInViewport() -> [ngAfterViewChecked() any number of times] -> close()

    // When *ngIf change has been already computed, `content.nativeElement` is now an existing rendered element that we can operate on.
    // `this.hasBeenOpened` flag helps us ensure that this logic is only called once per open/close cycle,
    // and not every time the AfterViewChecked hook triggers (which would be very expensive).
    if (this.isOpen && !this.hasBeenOpened) {
      this.setUpInViewport();
    }
  }

  public toggle(force?: boolean): void {
    // Casting to be 100% sure we are comparing two booleans
    const currentState = !!this.isOpen;
    const newState = typeof force === 'boolean' ? force : !currentState;

    if (newState !== currentState) {
      if (newState) {
        this.open();
      } else {
        this.close();
      }
    }
  }

  public onInOutAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.closeAnimationDone.emit();
    }
  }

  public setUpInViewport(): void { // public only so that it can be tested with a sinon spy; do not call directly
    this.hasBeenOpened = true;

    const anchorRect = this.windowRef.getBoundingClientRect(this.anchor.nativeElement);
    const contentRect = this.windowRef.getBoundingClientRect(this.dropdownContent.nativeElement);

    const shouldPositionAbove = this.shouldPositionAbove(anchorRect, contentRect);

    if (this.positionAbsolute) {
      this.attachToPage(anchorRect, shouldPositionAbove);
    }

    setTimeout(() => {
      this.isAbove = shouldPositionAbove; // Set in the next CDR cycle to avoid "ExpressionChangedAfterItHasBeenChecked" error
      this.listenForOutsideClick();
    });
  }

  private open(): void {
    this.isOpen = true;
    // The rest will happen in AfterViewChecked hook, when the DOM tree is in a correct state
  }

  private close(): void {
    this.isOpen = false;

    if (this.hasBeenOpened) {
      this.hasBeenOpened = false;

      if (this.positionAbsolute) {
        this.detachFromPage();
      }

      this.unsubscribeOutsideClick();
      this.cdr.markForCheck();
    }
  }

  private listenForOutsideClick(): void {
    const outsideClickCallback = (event: MouseEvent) => {
      if (!this.dropdownParent.nativeElement.contains(event.target)) {
        this.close();
      }
    };
    const stopListening = this.renderer.listen('document', 'click', outsideClickCallback);

    this.unsubscribeOutsideClick = () => {
      stopListening();
      this.unsubscribeOutsideClick = noop;
    };
  }

  private shouldPositionAbove(anchorRect: DOMRect, contentRect: DOMRect) {
    const viewportHeight = this.windowRef.getViewportHeight();
    const fitsBelow = anchorRect.top + anchorRect.height + contentRect.height <= viewportHeight;

    return !fitsBelow && (anchorRect.top - contentRect.height >= 0);
  }

  private attachToPage(anchorRect: DOMRect, shouldPositionAbove: boolean): void {
    this.originalParentNode = this.dropdownParent.nativeElement.parentElement;

    this.renderer.appendChild(this.document.body, this.dropdownParent.nativeElement);
    const scrollY = this.windowRef.nativeWindow.scrollY;

    const styles: { [name: string]: string } = {
      position: 'absolute',
    };
    styles.width = `${ anchorRect.width }px`;
    styles.left = `${ anchorRect.left }px`;

    if (shouldPositionAbove) {
      styles.top = `${ (anchorRect.top - anchorRect.height) + scrollY }px`;
    } else {
      styles.top = `${ anchorRect.top + anchorRect.height + scrollY }px`;
    }

    Object.assign(this.dropdownParent.nativeElement.style, styles);
  }

  private detachFromPage(): void {
    this.renderer.appendChild(
      this.originalParentNode,
      this.dropdownParent.nativeElement
    );
    Object.assign(this.dropdownParent.nativeElement.style, {
      position: 'relative',
      left: 0,
      top: 0,
      width: 'auto',
    });
  }
}
