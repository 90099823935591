import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SimplePaginationComponent } from './simple-pagination/simple-pagination.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AnalyticsComponentsModule,
  ],
  declarations: [
    BreadcrumbsComponent,
    SimplePaginationComponent,
  ],
  exports: [
    BreadcrumbsComponent,
    SimplePaginationComponent,
  ],
})
export class NavigationComponentsModule {}
