import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, ErrorHandler as AngularErrorHandler, Injectable, Injector } from '@angular/core';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import { ErrorMessage, ErrorResponse, isJsonApiErrorsDocument } from '@citypantry/util-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorReporterService } from './error-reporter.service';

/**
 * ErrorHandler is only exported from ErrorModule so that it can be used as an AngularErrorHandler in AppModule.
 * Do not use it directly elsewhere; use ErrorService instead.
 *
 * @see ErrorService
 */
@Injectable()
export class ErrorHandler implements AngularErrorHandler, HttpInterceptor {

  private errorReporterService: ErrorReporterService;

  constructor(
    private injector: Injector
  ) { }

  public handleError(error: any): void {
    if (!this.errorReporterService) {
      this.errorReporterService = this.injector.get(ErrorReporterService);
    }

    this.errorReporterService.reportError(error);
    this.disableChangeDetectionOfErroredComponent(error);
  }

  /**
   * Global error handler for all HTTP requests.
   * To bypass for a request, add `{ [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }` to its headers.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const disableErrorHandling = req.headers.has(HEADER_KEY_DISABLE_ERROR_HANDLING);
    if (disableErrorHandling) {
      req = req.clone({
        headers: req.headers.delete(HEADER_KEY_DISABLE_ERROR_HANDLING)
      });
    }
    return next.handle(req).pipe(
      catchError((error) => {
        const mappedError = this.mapHttpErrorToErrorResponseIfPossible(error);

        if (disableErrorHandling) {
          return throwError(mappedError);
        } else {
          this.handleError(mappedError);
          return of<HttpEvent<any>>(); // GULP - swallow any errors
        }
      })
    );
  }

  /**
   * During development, if an error is introduced in a template (e.g. `{{ null.foo }}`),
   * the change detection will continuously trigger the error handler, freezing the user's browser/machine.
   * This workaround disables change detection of the component containing the current error
   * so that it doesn't trigger the same error again.
   *
   * @see https://github.com/angular/angular/issues/17010#issuecomment-310991345
   * @see https://citypantry.slack.com/archives/C012C3AAWCC/p1591616823045300
   */
  private disableChangeDetectionOfErroredComponent(error: any): void {
    const debugContext = error.ngDebugContext;
    const changeDetectorRef = debugContext && debugContext.injector.get(ChangeDetectorRef);
    if (changeDetectorRef) {
      changeDetectorRef.detach();
    }
  }

  // TODO CPD-16097 Handle errors from web-services as well as api: this function incorrectly assumes that any error response of shape
  //  { errors: any } can be mapped to ErrorResponse. However web-services returns a JSON:API Error Object.
  private mapHttpErrorToErrorResponseIfPossible<T = unknown>(error: HttpErrorResponse | T): ErrorResponse | HttpErrorResponse | T {
    // error - HttpErrorResponse
    // error.error - Response body
    // error.error.errors - list of error messages returned by the API
    const httpErrorResponse = error as HttpErrorResponse;
    const body = httpErrorResponse.error;

    if (isJsonApiErrorsDocument(body)) {
      return new HttpErrorResponse({ error: body});
    } else if (httpErrorResponse && body && body.errors && Array.isArray(body.errors)) {
      return new ErrorResponse(
        (body.errors as ErrorMessage[]).map((e) => ({ ...e, context: body.errors })),
        httpErrorResponse,
      );
    } else {
      return error as T;
    }
  }
}
