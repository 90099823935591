import { transition, trigger, useAnimation } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { concat, UnreachableCaseError } from '@citypantry/util';
import { shake, slideInOut } from '@citypantry/util-animations';
import {
  CartItem,
  CartItemTypes,
  CustomItemOption,
  isBundleCartItem,
  isCustomCartItem,
  isSingleCartItem,
  ItemGroupTypes,
} from '@citypantry/util-models';

interface SelectedOption {
  quantity: number;
  name: string;
}

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  animations: [
    trigger('slideInOut', slideInOut),
    trigger('adjusted', [
      transition('false => true', [
        useAnimation(shake, {
          params: {
            timing: '400ms'
          }
        })
      ])
    ])
  ],
  styles: [':host { overflow: hidden }']
})
export class CartItemComponent {
  @Input()
  public cartItem: CartItem;

  @Input()
  public hasError: boolean;

  @Input()
  public cartItemAdjustmentMessage: string | null;

  @Input()
  public canOverrideQuantity: boolean | null;

  @Input()
  public currencyCode: string;

  @Input()
  public useHiddenBudget: boolean = false;

  @Output()
  public remove: EventEmitter<void> = new EventEmitter();

  @Output()
  public setItemQuantity: EventEmitter<number> = new EventEmitter();

  @Output()
  public edit: EventEmitter<void> = new EventEmitter();

  @Output()
  public itemNameClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public quantityAdjusted: EventEmitter<string> = new EventEmitter();

  @ViewChild('quantity', { static: false })
  public quantityInput: ElementRef;

  public isItemListExpanded: boolean;
  public showInputAdjusted: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isItemListExpanded = false;
    this.showInputAdjusted = false;
  }

  public get isBundleCartItem(): boolean {
    return isBundleCartItem(this.cartItem);
  }

  public get isCustomCartItem(): boolean {
    return isCustomCartItem(this.cartItem);
  }

  public hasSelectedOptions(): boolean {
    return this.getSelectedOptions().length > 0;
  }

  public getSelectedOptions(): SelectedOption[] {
    switch (this.cartItem.type) {
      case CartItemTypes.SINGLE_ITEM:
        return [];
      case CartItemTypes.ITEM_BUNDLE:
        return this.getSelectedBundleItems();
      case CartItemTypes.CUSTOM_ITEM:
        return this.getSelectedCustomItemOptions();
      default:
        throw new UnreachableCaseError(this.cartItem.type);
    }
  }

  public onRemove(): void {
    this.remove.emit();
  }

  public onItemNameClicked(): void {
    if (this.isBundleCartItem) {
      this.edit.emit();
    } else {
      this.itemNameClick.emit();
    }
  }

  public onEditClicked(): void {
    if (this.isBundleCartItem || this.isCustomCartItem) {
      this.edit.emit();
    }
  }

  public toggleItemList(): void {
    this.isItemListExpanded = !this.isItemListExpanded;
  }

  public inputAdjustAnimationFinished(): void {
    this.showInputAdjusted = false;
  }

  /**
   * We are listening to click and change events here so that someone tabbing through the inputs doesn't cause the modal to open on focus
   */
  public onQuantityTouched(event: Event): void {
    if (!isBundleCartItem(this.cartItem)) {
      return;
    }

    event.preventDefault();
    setTimeout(() => {
      this.resetQuantity();

      if (this.quantityInput && this.quantityInput.nativeElement && this.quantityInput.nativeElement === this.document.activeElement) {
        this.quantityInput.nativeElement.blur();
      }
    });

    this.edit.emit();
  }

  public onQuantityBlurred(quantityString: string): void {
    if (!isSingleCartItem(this.cartItem) && !isCustomCartItem(this.cartItem)) {
      return;
    }
    const newValue = parseInt(quantityString, 10);
    if (isNaN(newValue)) {
      // Blurred with invalid value
      this.resetQuantity();
      this.showInputAdjusted = true;
    } else if (newValue === 0) {
      this.remove.emit();
    } else {

      const quantity = this.ensureQuantityIsWithinBounds(newValue);

      if (quantity !== this.cartItem.quantity) {
        this.setItemQuantity.emit(quantity);
      }

      if (quantity !== newValue) {
        if (this.quantityInput && this.quantityInput.nativeElement) {
          setTimeout(() => {
            this.quantityInput.nativeElement.value = `${quantity}`;
          });
        }
        this.showInputAdjusted = true;

        if (quantity < newValue) {
          this.quantityAdjusted.emit(`you can't order more than ${this.cartItem.item.maximumOrderQuantity}`);
        }
      }
    }
  }

  private getSelectedBundleItems(): SelectedOption[] {
    if (!isBundleCartItem(this.cartItem)) {
      throw new Error(`Unexpected item type "${this.cartItem.type}", expected "${CartItemTypes.ITEM_BUNDLE}"`);
    }

    const ensureQuantityIsNumber = (quantity: number | boolean): number => {
      if (typeof quantity === 'boolean') {
        // Choice groups use checkboxes so their quantities end up as booleans
        return quantity ? 1 : 0;
      }
      return +quantity;
    };

    return this.cartItem.groups
      .filter((group) => group.type !== ItemGroupTypes.FIXED_GROUP)
      .map((group) =>
        group.cartItems.map(({ item: { name }, quantity }): SelectedOption => ({
          name,
          quantity: ensureQuantityIsNumber(quantity)
        })))
      .reduce(concat<SelectedOption>(), [])
      .filter(({ quantity }) => quantity > 0);
  }

  private getSelectedCustomItemOptions(): SelectedOption[] {
    if (!isCustomCartItem(this.cartItem)) {
      throw new Error(`Unexpected item type "${this.cartItem.type}", expected "${CartItemTypes.CUSTOM_ITEM}"`);
    }

    const customCartItem = this.cartItem;
    const getSelectedQuantity = (optionIndex: CustomItemOption['optionIndex']): number =>
      (customCartItem.selectedOptions
        .find(({ optionIndex: selectedIndex }) => selectedIndex === optionIndex) || { quantity: 0 }).quantity || 0;

    return customCartItem.item.sections
      .map(({ options }) => options)
      .reduce(concat<CustomItemOption>(), [])
      .map(({ name, optionIndex }) => ({
        name,
        quantity: getSelectedQuantity(optionIndex),
      }))
      .filter(({ quantity }) => quantity > 0);
  }

  private ensureQuantityIsWithinBounds(quantity: number): number {
    if (this.canOverrideQuantity) {
      return quantity;
    }

    const minOrderQuantity = this.cartItem.item.minimumOrderQuantity;
    const maxOrderQuantity = this.cartItem.item.maximumOrderQuantity;
    if (minOrderQuantity && quantity < minOrderQuantity) {
      return minOrderQuantity;
    }
    if (maxOrderQuantity && quantity > maxOrderQuantity) {
      return maxOrderQuantity;
    }
    return quantity;
  }

  private resetQuantity(): void {
    if (this.quantityInput && this.quantityInput.nativeElement) {
      this.quantityInput.nativeElement.value = `${ this.cartItem.quantity }`; // Reset
    }
  }
}
