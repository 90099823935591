import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedStarRatingModule } from '@citypantry/shared-star-rating';
import { VendorDistanceComponent } from './vendor-distance/vendor-distance.component';
import { VendorFlagNamePipe } from './vendor-flag-name.pipe';
import { VendorRatingComponent } from './vendor-rating/vendor-rating.component';

@NgModule({
  imports: [
    CommonModule,
    SharedStarRatingModule
  ],
  declarations: [
    VendorDistanceComponent,
    VendorRatingComponent,
    VendorFlagNamePipe,
  ],
  providers: [
    VendorFlagNamePipe,
  ],
  exports: [
    VendorDistanceComponent,
    VendorRatingComponent,
    VendorFlagNamePipe,
  ],
})
export class VendorInfoComponentsModule {}
