import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const groupsEditPageRules: AnalyticsRule[] = [
  {
    url: '/menus/groups/:groupOid',
    path: 'delete-group-cta',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Delete Group',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'save-group-cta',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Save Group',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'remove-colleague-cta',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Remove Eater With Id ${event.data['remove-colleague-cta'].eaterId}`,
    }),
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'add-colleagues-cta',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Add Colleagues',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'add-colleague-to-group',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Add Eater With Id ${event.data['add-colleague-to-group'].eaterId}`,
    }),
  },

  /* Budget settings */
  {
    url: '/menus/groups/:groupOid',
    path: 'per-order-budget-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Budget per Order ${event.data['per-order-budget-input'].budget}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-order-budget-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Order Budget toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-day-budget-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Budget per Day ${event.data['per-day-budget-input'].budget}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-day-budget-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Day Budget toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-week-budget-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Budget per Week ${event.data['per-week-budget-input'].budget}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-week-budget-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Week Budget toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-month-budget-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Budget per Month ${event.data['per-month-budget-input'].budget}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-month-budget-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Month Budget toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-day-order-limit-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Order Limit per Day ${event.data['per-day-order-limit-input'].limit}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-day-order-limit-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Day Order Limit toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-week-order-limit-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Order Limit per Week ${event.data['per-week-order-limit-input'].limit}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-week-order-limit-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Week Order Limit toggled on/off',
    },
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-month-order-limit-input',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: `Change Orders Limit per Month ${event.data['per-month-order-limit-input'].limit}`,
    })
  },
  {
    url: '/menus/groups/:groupOid',
    path: 'per-month-order-limit-enabled-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.GROUPS_EDIT_PAGE,
      label: 'Per Month Orders Limit toggled on/off',
    },
  },
];
