import { ensureValidEnumValue } from '@citypantry/util';
import { DayOfWeek, DaysOfWeek } from '../../day-of-week.enum';

export interface ItemAvailability {
  useAllLocations: boolean; // If false, `locations` contains the list of valid options
  locationIds: string[]; // List of address IDs
  useDefaultDeliveryDays: boolean; // If false, `deliveryDays` contains the list of valid days
  deliveryDays: DayOfWeek[];
  earliestDeliveryTime: string | null; // HH:mm
  latestDeliveryTime: string | null; // HH:mm
}

export const EMPTY_AVAILABILITY: ItemAvailability = Object.freeze({
  useAllLocations: true,
  locationIds: [],
  useDefaultDeliveryDays: true,
  deliveryDays: [],
  earliestDeliveryTime: null,
  latestDeliveryTime: null,
});

export function createItemAvailabilityFromJson(json?: Partial<ItemAvailability>): ItemAvailability {
  if (!json) {
    json = {};
  }

  const availability: Partial<ItemAvailability> = {};

  // Watch out - if an availability is created from an empty object, it will have the useX flags set to false,
  // but contain empty values for the lists, and therefore the item will never be deliverable.

  availability.useAllLocations = !!json.useAllLocations;
  availability.locationIds = json.locationIds || [];
  availability.useDefaultDeliveryDays = !!json.useDefaultDeliveryDays;
  availability.deliveryDays = (json.deliveryDays || []).map((day) => ensureValidEnumValue(DaysOfWeek, day));
  availability.earliestDeliveryTime = json.earliestDeliveryTime;
  availability.latestDeliveryTime = json.latestDeliveryTime;

  return availability as ItemAvailability;
}
