import { DeliveryContact } from '@citypantry/util-models';
import { DeliveryContactAction, DeliveryContactActions } from './delivery-contact.actions';

export const FEATURE_STATE_NAME = 'deliveryContacts';

export interface DeliveryContactsState {
  contacts: DeliveryContact[];
  isLoading: boolean;
}

export const INITIAL_STATE: DeliveryContactsState = {
  contacts: null,
  isLoading: false,
};

export function reducer(state: DeliveryContactsState = INITIAL_STATE, action: DeliveryContactAction): DeliveryContactsState {
  switch (action.type) {

    case DeliveryContactActions.getDeliveryContacts.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DeliveryContactActions.getDeliveryContactsSuccess.type: {
      return {
        ...state,
        contacts: action.contacts,
        isLoading: false
      };
    }

    default:
      return state;
  }
}
