import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const topMenuRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'site-header what-we-do',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'What we do',
    },
  },
  {
    url: '*',
    path: 'site-header vendor-signup',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Sell food with us',
    },
  },
  {
    url: '*',
    path: 'site-header support',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Support',
    },
  },
  {
    url: '*',
    path: 'site-header inspiration',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Get Inspired',
    },
  },
  {
    url: '*',
    path: 'site-header teams-at-home',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Teams @ Home',
    },
  },
  {
    url: '*',
    path: 'site-header marketing-campaign-link',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: `${event.data['marketing-campaign-link'].analyticsPrefix} header link clicked`,
    }),
  },
  {
    url: '*',
    path: 'site-header pantry-packages',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Pantry Packages',
    },
  },
  {
    url: '*',
    path: 'site-header just-eat-gift-cards',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Just Eat Gift Cards',
    },
  },
  {
    url: '*',
    path: 'site-header blog',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Blog',
    },
  },
  {
    url: '*',
    path: 'site-header pop-ups',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Pop-ups',
    },
  },
  {
    url: '*',
    path: 'site-header search',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Search food',
    },
  },
  {
    url: '*',
    path: 'site-header login',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Login',
    },
  },
  {
    url: '*',
    path: 'site-header logout',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Logout',
    },
  },
  {
    url: '*',
    path: 'site-header customer-home',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'Recommended',
    },
  }, {
    url: '*',
    path: 'site-header logo',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.TOP_MENU,
      label: 'City Pantry Logo',
    },
  }
];
