import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AppConfig } from '@citypantry/shared-app-config';
import {
  AppState,
  FavouriteVendorsActions,
  IndividualChoiceSetupActions,
  RouterActions,
  RouterSelectors
} from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { AdvancedBudgets, CustomerId, SearchOrderTypes, SearchPreferences } from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthStorageService } from '../auth-storage.service';
import { AuthApi } from '../auth.api';
import { BetaFeatureApi } from '../beta-feature.api';
import { BetaFeatures } from '../models/beta-feature.enum';
import { NavigationPermissionsApi } from '../api/navigation-permissions.api';
import {
  ActionTypes,
  authFetched,
  AuthFetchedAction,
  authFetchFailed,
  AuthFetchFailedAction,
  authFetchFailedForRegisteredEater,
  AuthFetchFailedForRegisteredEaterAction,
  authFetchedForRegisteredEater,
  betaFeatureDisabled,
  BetaFeatureDisabledAction,
  DisableBetaFeatureAction,
  fetchAuth,
  LoginWithEmailAction,
  MasqueradeAction,
  RegisterEaterAction,
  registerEaterFailure,
  registerEaterSuccess,
  RegisterEaterSuccessAction,
} from './auth.actions';
import { AuthSelectors } from './auth.select';

@Injectable()
export class AuthEffects {

  public fetchAuth$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.FETCH_AUTH, ActionTypes.AUTH_REFRESH_REQUEST, ActionTypes.REGISTER_EATER_SUCCESS),
      switchMap(() => {
        return forkJoin([
          this.authApi.fetchCurrentlyLoggedInProfile(),
          this.navigationPermissionsApi.getNavigationPermissions(),
        ]).pipe(
          map(([userProfile, navigationPermissionsResult]) =>
            authFetched(
              userProfile,
              navigationPermissionsResult.success === true ? navigationPermissionsResult.value : null
            )
          ),
          catchError((error) => of(authFetchFailed(error))),
        );
      })
    ));

  public fetchAuthForRegisteredEater$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.REGISTER_EATER_SUCCESS),
      switchMap((action: RegisterEaterSuccessAction) => {
        return this.navigationPermissionsApi.getNavigationPermissions().pipe(
          map((navigationPermissionsResult) =>
            authFetchedForRegisteredEater(
              action.payload.profile,
              navigationPermissionsResult.success === true ? navigationPermissionsResult.value : null
            )
          ),
          catchError((error) => of(authFetchFailedForRegisteredEater(error))),
        );
      })
    ));

  /**
   * This effect throws the error that was caught in fetchAuth$ or fetchAuthForRegisteredEater$ so that it can be shown to the user by the default error handler.
   */
  public rethrowAuthFetchError$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(
        ActionTypes.AUTH_FETCH_FAILED,
        ActionTypes.AUTH_FETCH_FAILED_FOR_REGISTERED_EATER,
      ),
      map((action: AuthFetchFailedAction | AuthFetchFailedForRegisteredEaterAction) => {
        throw action.payload.error;
      }),
    ), { dispatch: false, useEffectsErrorHandler: true });

  public updateSearchPreferencesOnAuthFetched$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.AUTH_FETCHED),
      withLatestFrom(
        this.store.select(AuthSelectors.getUserSearchPreferences),
        this.store.select(AuthSelectors.customer.isAdvancedBudgetingEnabled),
        this.store.select(AuthSelectors.customer.getAdvancedBudgets),
        this.store.select(AuthSelectors.customer.getId),
      ),
      switchMap((
        [, searchPreferences, isAdvancedBudgetingEnabled, advancedBudgets, customerId]:
        [AuthFetchedAction, SearchPreferences | null, boolean, AdvancedBudgets | null, CustomerId | null]
      ) => {
        const newOrderType = (searchPreferences && searchPreferences.orderType) || SearchOrderTypes.MARKETPLACE;
        return of(
          PublicActions.updateSearchTypeForCustomer({ searchType: newOrderType, searchPreferencesCustomerId: customerId }),
          IndividualChoiceSetupActions.updateSearchPreferences({
            searchPreferences,
            isAdvancedBudgetingEnabled,
            advancedBudgets,
            customerId,
          }),
        );
      })
    ));

  public updateFavouritedVendorsOnAuthFetched$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.AUTH_FETCHED),
      filter((userProfile: AuthFetchedAction) => !!userProfile.payload.profile),
      map((userProfile: AuthFetchedAction) => {
        const favouriteVendors = userProfile.payload.profile.favouriteVendors;
        return FavouriteVendorsActions.favouritesLoadedFromAuth({ favouriteVendors });
      })
    ));

  public unmasquerade$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.UNMASQUERADE),
      tap(() => {
        this.authStorageService.unmasquerade();
      }),
      map(fetchAuth)
    ));

  public masquerade$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.MASQUERADE),
      tap((action: MasqueradeAction) => {
        const userId = action.payload.userId;
        this.authStorageService.masquerade(userId);
      }),
      map(fetchAuth)
    ));

  public disableBetaFeature$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.DISABLE_BETA_FEATURE),
      switchMap((action: DisableBetaFeatureAction) => {
        const feature = action.payload.feature;
        return this.betaFeatureApi.disableBetaFeature(feature).pipe(
          map(() => betaFeatureDisabled(feature))
        );
      })
    ));

  public redirectToOldCustomerOrdersOnBetaFeatureDisabled$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.BETA_FEATURE_DISABLED),
      filter((action: BetaFeatureDisabledAction) => action.payload.feature === BetaFeatures.MY_ORDERS),
      mapTo(RouterActions.goExternal({ url: '/customer/orders' }))
    ));

  public redirectToLoginUrlWithEmailAndForwardUrl$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOGIN_WITH_EMAIL),
      withLatestFrom(
        this.store.select(RouterSelectors.getQueryParams)
      ),
      switchMap(([action, params]: [LoginWithEmailAction, Params]) => {
        const emailParam = `email=${encodeURIComponent(action.payload.email)}`;

        // If no redirect url was specified, request a redirect to '/',
        // where the HomeRedirectGuard will take care of any futher redirects if needed
        const forwardUrl = params.forward || '/';
        const redirectToParam = `redirectTo=${encodeURIComponent(forwardUrl)}`;

        const url = `${this.appConfig.API_BASE}/user/login?${emailParam}&${redirectToParam}`;

        return of(RouterActions.goExternal({ url }));
      }),
    ));

  public registerEater$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ActionTypes.REGISTER_EATER),
    switchMap((action: RegisterEaterAction) => {
      return this.authApi.register(action.payload.userInfo).pipe(
        map((response) => response.success === true ?
          registerEaterSuccess(response.value) :
          registerEaterFailure(response.error)
        ),
      );
    }),
  ));

  constructor(
    private action$: Actions,
    private store: Store<AppState>,
    private authApi: AuthApi,
    private navigationPermissionsApi: NavigationPermissionsApi,
    private authStorageService: AuthStorageService,
    private betaFeatureApi: BetaFeatureApi,
    private appConfig: AppConfig,
  ) {}
}
