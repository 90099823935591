import { Component, Input } from '@angular/core';
import { FixedItemGroup, ItemId, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-fixed-group',
  templateUrl: './fixed-group.component.html'
})
export class FixedGroupComponent {

  @Input()
  public itemGroup: FixedItemGroup;

  @Input()
  public bundleCount: number;

  public moreInfoItem: SingleItem;

  constructor() {
    this.moreInfoItem = null;
  }

  public toggleMoreInfo(item?: SingleItem): void {
    if (!item || this.moreInfoItem === item) {
      this.moreInfoItem = null;
    } else {
      this.moreInfoItem = item;
    }
  }

  public getItemOrder(index: number): number {
    if (index % 2) { // isOdd
      return index * 2 - 1; // Odd items are shifted back one to allow the "more info" panel to take their place
    } else {
      return index * 2;
    }
  }

  public getMoreInfoOrder(index: number): number {
    if (index % 2) { // isOdd
      return index * 2 + 1;
    } else {
      return index * 2 + 2; // Swaps into place for the item at (index + 1) * 2
    }
  }

  public getFixedItemQuantity(itemId: ItemId): number | null {
    const quantityObject = (this.itemGroup.quantities || [])
      .find(({ itemId: id }) => id === itemId);
    return quantityObject && quantityObject.quantity || null;
  }
}
