import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { safeUnsubscribe } from '@citypantry/util';
import { Subscription } from 'rxjs';
import { CompactInputOption } from '../compact-input-option.model';
import { AbstractCompactInputComponent } from '../compact-input.component';

@Component({
  selector: 'app-compact-input[type=choice]',
  template: `
    <app-compact-input-wrapper
      (blurInput)="blurDropdown()"
      [label]="label"
      [icon]="icon"
      [currentValue]="currentDisplayValue"
      type="choice"
    >
      <select
        #dropdown
        class="compact-input__dropdown"
        [formControl]="control"
        test-id="dropdown"
      >
        <option
          *ngFor="let option of options"
          [ngValue]="option.value"
          test-id="options"
        >{{ option.label }}</option>
      </select>
    </app-compact-input-wrapper>
  `
})
export class CompactInputChoiceComponent<T> extends AbstractCompactInputComponent implements OnInit, OnDestroy {

  @Input()
  public label: string;

  @Input()
  public options: CompactInputOption<T>[];

  @ViewChild('dropdown', { static: false })
  public dropdown: ElementRef;

  public currentDisplayValue: string;

  private valueSubscription: Subscription;

  public ngOnInit(): void {
    super.ngOnInit();

    if (!this.options) {
      throw new Error('No options passed into CompactInputChoiceComponent!');
    }

    this.valueSubscription = this.control.valueChanges.subscribe((newValue: T) => this.onDropdownChange(newValue));
    this.currentDisplayValue = this.getCurrentDisplayValue(this.control.value);
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.valueSubscription);
  }

  public onDropdownChange(newValue: T): void  {
    this.currentDisplayValue = this.getCurrentDisplayValue(newValue);
  }

  public getCurrentDisplayValue(value: T): string {
    if (!this.options) {
      return '';
    }

    const selectedOption = this.options.find((option) => option.value === value);
    return selectedOption && selectedOption.label || '';
  }

  public blurDropdown(): void {
    if (this.dropdown && this.dropdown.nativeElement) {
      this.dropdown.nativeElement.blur();
    }
  }
}
