import { isCustomItem } from './custom-item.model';
import { ItemTypes } from './item-type.enum';
import { Item } from './item.model';
import { ServingStyles } from './serving-style.enum';
import { SingleItem } from './single-item.model';

const isMarketplaceCompatible = (item: Item): boolean => !isCustomItem(item);

const isIndividualChoiceCompatible = (item: Item): boolean => {
  if ((item.type !== ItemTypes.SINGLE_ITEM && item.type !== ItemTypes.CUSTOM_ITEM && item.type !== ItemTypes.ITEM_BUNDLE)
    || (item.minimumOrderQuantity > 1)
    || (item.servingStyle && item.servingStyle !== ServingStyles.INDIVIDUAL)
    || (item.type === ItemTypes.SINGLE_ITEM && (item as SingleItem).portionSize > 1)) {
    return false;
  }

  return true;
};

export const ItemOrderTypeEligibility = {
  isMarketplaceCompatible,
  isIndividualChoiceCompatible,
};
