import { ErrorMessage } from '@citypantry/util-models';
import { Alert } from './alert.model';
import { ErrorAction, ErrorActions } from './error.actions';

export const FEATURE_STATE_NAME = 'error';

export interface ErrorState {
  errors: ErrorMessage[];
  alerts: Alert[];
}

export const initialState: ErrorState = {
  errors: [],
  alerts: [],
};

export function reducer(state: ErrorState = initialState, action: ErrorAction): ErrorState {
  switch (action.type) {
    case ErrorActions.showErrorMessage.type: {
      const { error } = action;
      return {
        ...state,
        errors: [...state.errors, error],
      };
    }

    case ErrorActions.dismissCurrentError.type: {
      return {
        ...state,
        errors: state.errors.slice(1),
      };
    }

    case ErrorActions.showAlert.type: {
      const { title, text } = action;
      const alert: Alert = { title, text };
      return {
        ...state,
        alerts: [...state.alerts, alert],
      };
    }

    case ErrorActions.dismissAlert.type: {
      const { alert } = action;
      return {
        ...state,
        alerts: state.alerts.filter((pendingAlert) => {
          if (pendingAlert === alert) {
            return false;
          }
          if (
            stringMatch(pendingAlert.title, alert.title) &&
            stringMatch(pendingAlert.text, alert.text)
          ) {
            return false;
          }
          return true;
        })
      };
    }

    default:
      return state;
  }
}

function stringMatch(stringA: string, stringB: string): boolean {
  return (!stringA && !stringB) || stringA === stringB;
}
