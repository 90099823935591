import { Image } from '../image.model';
import { VendorId } from '../vendor/vendor.model';
import { VendorLocationId } from '../vendor';

export interface SearchResult {
  menuId: string;
  heroImage: Image;
  vendorId: VendorId;
  vendorName: string;
  vendorImage: Image;
  vendorTags: string[];
  vendorSlug: string;
  vendorLocationPublicName: string;
  vendorLocationId: VendorLocationId;
  vendorLocationSlug: string;
  vendorRating: number | null;
  vendorReviewCount: number;
  vendorRecommended: boolean;
  distance?: number; // in miles - only set when postcode provided
  mainsWithinBudget?: number;
  remainingCapacity: number;
}

export function createSearchResultFromJson(json: any): SearchResult {
  return {
    menuId: json.menuId,
    heroImage: json.heroImage,
    vendorId: json.vendorId,
    vendorName: json.vendorName,
    vendorImage: json.vendorImage,
    vendorTags: json.vendorTags,
    vendorSlug: json.vendorSlug,
    vendorLocationPublicName: json.vendorLocationPublicName,
    vendorLocationSlug: json.vendorLocationSlug,
    vendorLocationId: json.vendorLocationId,
    vendorRating: json.vendorRating,
    vendorReviewCount: json.vendorReviewCount,
    vendorRecommended: json.vendorRecommended,
    distance: json.distance,
    mainsWithinBudget: json.mainsWithinBudget,
    remainingCapacity: json.remainingCapacity,
  };
}
