import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export type FormPathGetArg = (string | number)[] | string;
export type ValidationResult = ValidationErrors | null;

function validateComparedToField(
  controlOrPath: FormPathGetArg | AbstractControl,
  validator: (thisValue: any, thatValue: any) => ValidationResult
): ValidatorFn {
  const isPath = controlOrPath instanceof Array || typeof controlOrPath === 'string';

  return (control: AbstractControl) => {
    if (!control || !control.root) {
      return null;
    }

    const otherControl: AbstractControl = isPath ?
      control.root.get(controlOrPath as FormPathGetArg) :
      controlOrPath as AbstractControl;

    if (!otherControl) {
      return null;
    }
    try {
      return validator(control.value, otherControl.value);
    } catch (e) {
      return null;
    }
  };
}

function validateComparedToNumericField(
  controlOrPath: FormPathGetArg | AbstractControl,
  validator: (thisValue: number, thatValue: number) => ValidationResult
): ValidatorFn {
  return validateComparedToField(controlOrPath, (thisValue: any, thatValue: any) => {
    try {
      const valueA = (typeof thisValue === 'number') ? thisValue : parseFloat(thisValue);
      const valueB = (typeof thatValue === 'number') ? thatValue : parseFloat(thatValue);

      if (isNaN(valueA) || isNaN(valueB)) {
        return null; // Skip validation if value is non-numeric
      }

      return validator(valueA, valueB);
    } catch (e) {
      return null;
    }
  });
}

function validateGreaterThanOrEqualToField(controlOrPath: FormPathGetArg | AbstractControl): ValidatorFn {
  return validateComparedToNumericField(controlOrPath,
    (thisValue, thatValue) => thisValue >= thatValue ?
      null :
      { greaterThanOrEqualToField: `Must be greater than other field's value (${thatValue})` }
  );
}

export {
  validateGreaterThanOrEqualToField as greaterThanOrEqualToField,
};
