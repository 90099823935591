import { ErrorMessage } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const CreateStaffUserActions = {
  submitForm: createAction(
    '[Create Staff User Form] Submit',
    props<{ firstName: string, lastName: string, email: string }>(),
  ),

  userCreateSuccess: createAction(
    '[Create Staff User API] Staff User Created',
  ),

  userCreateFailure: createAction(
    '[Create Staff User API] Staff User Create Failed',
    props<{ errorMessage: ErrorMessage }>(),
  ),
};

const all = union(CreateStaffUserActions);
export type CreateStaffUserAction = typeof all;
