<span
  class="dietaries"
  analyticsId="dietaries"
>
  <ng-container *ngFor="let dietary of DIETARIES_DISPLAY_ORDER">
    <app-diet-icon
      *ngIf="showUnavailable || dietaries && dietaries[dietary]"
      class="dietaries__item"
      [class.dietaries__item--clickable]="canBeToggled && dietaries && dietaries[dietary]"
      [diet]="dietary"
      [available]="dietaries && dietaries[dietary]"
      [active]="activeDietaries.includes(dietary)"
      (click)="clickDietary(dietary)"
      test-id="dietary"
    ></app-diet-icon>
  </ng-container>
</span>
