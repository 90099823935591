import { environment } from '@citypantry/shared-app-config';
import { mapSelectors } from '@citypantry/util';
import { CartManager } from '@citypantry/util-cart-manager';
import { CartCustomItem, CartItemBundle, findMenuItemById, IndividualChoiceOrder, isBundleCartItem, isCustomCartItem } from '@citypantry/util-models';
import { getIndividualChoiceState } from '../app.state';
import { computeMenuDietaries } from '../util/compute-menu-dietaries';
import { ChooseForEater, findOrder, IndividualChoiceState } from './individual-choice.state';

// A helper that lets us avoid constantly checking for state.chooseForEater
function selectFromChooseForEater<T>(
  select: (chooseForEaterState: ChooseForEater) => T, fallback: T | null = null
): ((icState: IndividualChoiceState) => T) {
  return (icState: IndividualChoiceState) => icState.chooseForEater ? select(icState.chooseForEater) : fallback;
}

const getBudget = selectFromChooseForEater((state: ChooseForEater) => state.remainingBudget);
const getCartItems = selectFromChooseForEater((state: ChooseForEater) => state.cartItems, []);
const getOrderId = selectFromChooseForEater((state: ChooseForEater) => state.orderId);
const getCartTotal = selectFromChooseForEater((state: ChooseForEater) => CartManager.getCartTotal(state.cartItems));
const getChildOrderId = selectFromChooseForEater((state: ChooseForEater) => state.childOrderId);
const getDepartmentReference = selectFromChooseForEater((state: ChooseForEater) => state.departmentReference);
const getDeskNumber = selectFromChooseForEater((state: ChooseForEater) => state.deskNumber);
const getEaterId = selectFromChooseForEater((state: ChooseForEater) => state.eaterId);
const getEaterIdToChooseFor = selectFromChooseForEater((state: ChooseForEater) => state.eaterId);
const getEaterMenuDietaries = selectFromChooseForEater((state: ChooseForEater) =>
  (state.menuContent ? computeMenuDietaries(state.menuContent) : null)
);
const getEaterName = selectFromChooseForEater((state: ChooseForEater) => state.eaterName);
const getMenuContent = selectFromChooseForEater((state: ChooseForEater) => state.menuContent);
const getUseDeskNumbers = selectFromChooseForEater((state: ChooseForEater) => state.useDeskNumbers);

const getOpenCartItem = (icState: IndividualChoiceState): CartCustomItem | CartItemBundle | null => {
  const openItem = icState.chooseForEater && icState.chooseForEater.openItem;
  if (!openItem || typeof openItem.cartIndex !== 'number') {
    return null;
  }

  const cartItem = icState.chooseForEater.cartItems[openItem.cartIndex];
  if (!cartItem || !(isCustomCartItem(cartItem) || isBundleCartItem(cartItem)) || openItem.itemId !== cartItem.item.id) {
    if (environment.production) {
      return null;
    } else {
      throw new Error('Invalid cart item or cart item ID');
    }
  }

  return cartItem;
};
const getOpenItem = (icState: IndividualChoiceState) => {
  if (!icState.chooseForEater || !icState.chooseForEater.openItem) {
    return null;
  }
  const menuContent = icState.chooseForEater.menuContent;
  const itemId = icState.chooseForEater.openItem.itemId;
  return menuContent && findMenuItemById(menuContent, itemId) || null;
};
const getSubmitErrors = selectFromChooseForEater((state: ChooseForEater) => state.errors, null);
const isInitialised = selectFromChooseForEater((state: ChooseForEater) => state.eaterId !== null, false);
const isLoaded = (icState: IndividualChoiceState) =>
  icState.chooseForEater ? !!(typeof getBudget(icState) === 'number' && getMenuContent(icState)) : false;
const isSubmitting = selectFromChooseForEater((state: ChooseForEater) => state.isSubmitting, false);
const isAgeConfirmationDialogVisible = selectFromChooseForEater((state: ChooseForEater) => state.ageConfirmationDialogVisible, false);
const getCustomerLocation = (state: IndividualChoiceState) =>
  state.individualChoiceOrder ? state.individualChoiceOrder.location : null;
const getEaterChoiceDeadline = (state: IndividualChoiceState) =>
  state.individualChoiceOrder ? state.individualChoiceOrder.choiceDeadline : null;

function findEaterChildOrder(state: IndividualChoiceState): IndividualChoiceOrder {
  return state.chooseForEater && findOrder(state, state.chooseForEater.childOrderId);
}
const getEaterVendor = (state: IndividualChoiceState) => {
  const childOrder = findEaterChildOrder(state);
  if (!childOrder) {
    return null;
  }
  return childOrder.vendor ? childOrder.vendor : null;
};
const getEaterSelectedVendorLocationName = (state: IndividualChoiceState): string | null => {
  const childOrder = findEaterChildOrder(state);
  return childOrder?.selectedVendorLocation?.name || null;
};
const getEaterRequestedDeliveryDate = (state: IndividualChoiceState) => {
  const childOrder = findEaterChildOrder(state);
  return childOrder ? childOrder.requestedDeliveryDate : null;
};

export const IndividualChoiceChooseForEaterSelectors = mapSelectors(getIndividualChoiceState, {
  getBudget,
  getCartItems,
  getOrderId,
  getCartTotal,
  getChildOrderId,
  getDepartmentReference,
  getDeskNumber,
  getEaterId,
  getEaterIdToChooseFor,
  getEaterMenuDietaries,
  getEaterName,
  getMenuContent,
  getOpenCartItem,
  getOpenItem,
  getSubmitErrors,
  getUseDeskNumbers,
  isInitialised,
  isLoaded,
  isSubmitting,
  isAgeConfirmationDialogVisible,
  getCustomerLocation,
  getEaterChoiceDeadline,
  getEaterVendor,
  getEaterSelectedVendorLocationName,
  getEaterRequestedDeliveryDate,
});
