import { Component, Input } from '@angular/core';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';

@Component({
  selector: 'app-marketing-campaign-banner',
  template: `
    <div
      *ngIf="campaign"
      class="promotion-banner"
      test-id="campaignBanner"
      analyticsId="marketing-campaign-banner"
      [analyticsData]="campaign"
    >
      <a
        class="promotion-banner__link"
        [attr.href]="campaign.ctaLink ? campaign.ctaLink : null"
        [attr.target]="campaign.ctaLink ? campaign.openLinkInNewTab ? '_blank' : '_parent' : null"
        analyticsId="link"
        test-id="campaignLink"
      >
        {{ campaign.body1 }}
      </a>
      <a
        class="promotion-banner__tag button button--primary button--white button--small promotion-banner__tag--show"
        [attr.href]="campaign.ctaLink ? campaign.ctaLink : null"
        [attr.target]="campaign.ctaLink ? campaign.openLinkInNewTab ? '_blank' : '_parent' : null"
        analyticsId="tag"
        test-id="campaignTag"
      >
        {{ campaign.ctaText }}
      </a>
      <app-in-viewport analyticsId="bottom-of-banner"></app-in-viewport>
    </div>
  `
})
export class MarketingCampaignBannerComponent {

  @Input()
  public campaign: MarketingCampaignComponentModel;

}
