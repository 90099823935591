import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HorizontalLoaderComponent } from './horizontal-loader/horizontal-loader.component';
import { SquareSpinnerComponent } from './square-spinner/square-spinner.component';
import { StateLoadingComponent } from './state-loading/state-loading.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    HorizontalLoaderComponent,
    StateLoadingComponent,
    SquareSpinnerComponent,
  ],
  exports: [
    HorizontalLoaderComponent,
    StateLoadingComponent,
    SquareSpinnerComponent,
  ],
})
export class LoadingComponentsModule { }
