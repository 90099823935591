import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cart-toggle',
  templateUrl: './cart-toggle.component.html',
  styleUrls: ['./cart-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartToggleComponent {

  @Input()
  public toggle: boolean;

  @Input()
  public labelForOn: string = 'On';

  @Input()
  public labelForOff: string = 'Off';

  @Input()
  public disabled: boolean;

  @Input()
  public toggleTooltip: string;

  @Output()
  public toggleChange: EventEmitter<boolean> = new EventEmitter();

  public get onOffText(): string {
    return this.disabled ? 'Disabled' :
      this.toggle ? this.labelForOn : this.labelForOff;
  }

  public updateToggle(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    const input: HTMLInputElement = event.target as HTMLInputElement;
    input.checked = this.toggle;
    this.toggleChange.emit(!this.toggle);
  }
}
