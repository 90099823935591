import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const customerOrderPageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/orders/:orderId',
    path: 'order-again',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Order again',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'order-faqs',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Order FAQs (Tracking progress)',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-faqs',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Delivery FAQs (Tracking progress)',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'track-your-order',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Track Your Order button',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'smile-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Feedback link - positive',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'frown-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Feedback link - negative',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-order',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Edit order',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-details edit-date-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Edit delivery date',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-details edit-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Edit delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-details add-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Add delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-details save-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Save delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-details delivery-contact-picker',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Select delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup first-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `First name field entered`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup last-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Last name field entered`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Email field entered`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup phone-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Phone number field entered`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup email-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Email delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup sms-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `SMS delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup save',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Save new delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-delivery-contact-popup cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: `Cancel new delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-date-popup change-date-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Update delivery details',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-date-popup find-new-vendor-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Find new vendor',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'keep-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Keep existing order',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'cancel-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Cancel existing order',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'cancel-order',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Cancel order',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'table-menu',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Table Menu',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'item-labels',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Item Labels',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'order-checklist',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Order Checklist',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'purchase-order-number-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Purchase Order changed'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'department-reference-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Department Reference changed'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'cancel-edit-references-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Cancel editing references button'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-update-references-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Update references button'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'edit-references-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Edit References link'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'get-invoice-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Get Invoice link'
    }
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'order-faqs-questions',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Order FAQs (Any questions?)',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'delivery-faqs-questions',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Delivery FAQs (Any questions?)',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'chat-to-us',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Chat to us (Any questions?)',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'vendor-available',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Vendor available',
    },
  },
  {
    url: '/menus/customer/orders/:orderId',
    path: 'vendor-unavailable',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      label: 'Vendor not available',
    },
  },
];
