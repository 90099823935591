import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ExternalLinkTemplateContext, RouterLinkTemplateContext } from '../../header.component';

@Component({
  selector: 'app-vendor-user-header',
  templateUrl: './vendor-user-header.component.html',
  styleUrls: ['./vendor-user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorUserHeaderComponent {
  @Input()
  public pendingOrdersCount: number;

  @Input()
  public separatorTemplate: TemplateRef<void>;

  @Input()
  public externalLinkTemplate: TemplateRef<ExternalLinkTemplateContext>;

  @Input()
  public routerLinkTemplate: TemplateRef<RouterLinkTemplateContext>;

  @Input()
  public authenticatedUserLinksTemplate: TemplateRef<void>;
}
