import { CustomerLocation, CustomerLocationId, SimpleUser, UserId } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const AccountActions = {
  fetchLocationsWithEaters: createAction(
    '[Account] Fetch Locations With Eaters',
  ),

  locationsWithEatersFetched: createAction(
    '[Account] Locations With Eaters Fetched',
    props<{ locations: CustomerLocation[] }>(),
  ),

  fetchEatersAtLocation: createAction(
    '[Account] Fetch Eaters At Location',
    props<{ locationId: string }>(),
  ),

  refreshEatersAtLocation: createAction(
    '[Account] Refresh Eaters At Location',
    props<{ locationId: string }>(),
  ),

  eatersAtLocationFetched: createAction(
    '[Account] Eaters At Location Fetched',
    props<{ eaters: SimpleUser[] }>(),
  ),

  deleteEaterFromLocation: createAction(
    '[Account] Delete Eater From Location',
    props<{ userId: UserId, locationId: CustomerLocationId }>(),
  ),

  promoteLeadUser: createAction(
    '[Account] Promote Eater At Location',
    props<{ userId: UserId, locationId: CustomerLocationId, operationalRegion: string, phoneNumber: string | null }>(),
  ),

  promoteLeadUserSuccess: createAction(
    '[Customer API] Promote Lead User Success',
    props<{ locationId: CustomerLocationId }>(),
  ),

  copyShareLocationUrl: createAction(
    '[Account] Copy Share Location URL',
    props<{ shareUrl: string }>(),
  ),

  shareLocationUrlCopied: createAction(
    '[Account] Share Location URL Copied',
  ),
};

const all = union(AccountActions);
export type AccountAction = typeof all;
