<form
  [formGroup]="form"
  action="."
  [@expand]="isExpanded ? 'expanded' : 'closed'"
>
  <div
    class="search-filters search-filters-layout"
    test-id="searchFilters"
  >
    <div class="search-filters__column">
      <div class="search-filters__list-wrapper">
        <div class="search-filters__list-title">
          <div class="circle-icon circle-icon--orange">
            <i class="icon icon-food-fork-drink"></i>
          </div>
          <span class="search-filters__list-title-text">Cuisine</span>
        </div>
        <ul
          class="search-filters__list"
          formGroupName="cuisines"
          >
          <li
            class="search-filters__list-item"
            *ngFor="let cuisine of visibleCuisineTypes"
            >
            <label class="form-choice">
              <input
                type="checkbox"
                class="form-choice__input"
                [formControlName]="cuisine"
                analyticsId="cuisine-filter"
                [analyticsData]="cuisine"
              >
              <span class="form-choice__label">{{ cuisine }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="search-filters__column">
      <div class="search-filters__list-wrapper">
        <div class="search-filters__list-title">
          <div class="circle-icon circle-icon--red">
            <i class="icon icon-silverware"></i>
          </div>
          <span class="search-filters__list-title-text">Occasion</span>
        </div>
        <ul
          class="search-filters__list"
          formGroupName="events"
          >
          <li
            class="search-filters__list-item"
            *ngFor="let event of visibleEventTypes"
            >
            <label class="form-choice">
              <input
                type="checkbox"
                class="form-choice__input"
                [formControlName]="event"
                analyticsId="occasion-filter"
                [analyticsData]="event"
              >
              <span class="form-choice__label">{{ event }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="search-filters__column">
      <div class="search-filters__list-wrapper">
        <div class="search-filters__list-title">
          <div class="circle-icon circle-icon--green">
            <i class="icon icon-carrot"></i>
          </div>
          <span class="search-filters__list-title-text">Dietary requirements</span>
        </div>
        <ul
          class="search-filters__list"
          formGroupName="dietaries"
          >
          <li
            class="search-filters__list-item"
            *ngFor="let dietary of visibleDietaryTypes"
            >
            <label class="form-choice">
              <input
                type="checkbox"
                class="form-choice__input"
                [formControlName]="dietary"
                analyticsId="dietary-filter"
                [analyticsData]="dietary"
              >
              <span class="form-choice__label">{{ dietary }}</span>
            </label>
          </li>
        </ul>
      </div>

      <div class="search-filters__list-wrapper">
        <div class="search-filters__list-title">
          <div class="circle-icon circle-icon--purple">
            <i class="icon icon-cog"></i>
          </div>
          <span class="search-filters__list-title-text">Restaurant features</span>
        </div>
        <ul
          class="search-filters__list"
          formGroupName="vendorFlags"
          >
          <li
            class="search-filters__list-item"
            *ngFor="let vendorFlag of visibleVendorFlags"
            >
            <label class="form-choice">
              <input
                type="checkbox"
                class="form-choice__input"
                [formControlName]="vendorFlag"
                analyticsId="vendor-flag-filter"
                [analyticsData]="vendorFlag"
              >
              <span class="form-choice__label">{{ vendorFlag | vendorFlagName }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
