import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeOnRelativeDate',
  pure: false,
})
export class TimeOnRelativeDatePipe implements PipeTransform {

  public transform(date: moment.Moment): string {
    if (!date) {
      return '';
    }

    date = date.clone().tz('Europe/London');

    return date.calendar(null, {
      sameDay: `HH:mm [today]`,
      nextDay: `HH:mm [tomorrow]`,
      nextWeek: `HH:mm, dddd`,
      sameElse: `HH:mm, ddd DD MMM`,
      lastDay: '',
      lastWeek: '',
    });
  }

}
