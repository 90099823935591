import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const menuListPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/menus',
    path: 'menu-builder-list menu-send-to-review',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.MENU_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Send to review clicked',
      extraFields: {
        isMenuComplete: event.data['menu-send-to-review'].isMenuComplete,
      },
    })
  },
  {
    url: '/menus/builder/menus',
    path: 'menu-builder-scheduling save-menu-schedule',
    events: 'click',
    definition: {
      category: AnalyticsCategories.MENU_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Save menu schedule clicked',
    },
  },
];
