import { createCustomerFromJson, Customer } from '../../customer/customer.model';
import { createSimpleCustomerLocationFromJson, SimpleCustomerLocation } from '../../customer/simple-customer-location.model';

export interface EaterLocation {
  customer: Customer;
  location: SimpleCustomerLocation;
}

export function createEaterLocationFromJson(json?: Partial<EaterLocation>): EaterLocation {
  json = json || {};

  const eaterLocation: Partial<EaterLocation> = {};

  eaterLocation.customer = createCustomerFromJson(json.customer);
  eaterLocation.location = createSimpleCustomerLocationFromJson(json.location);

  return eaterLocation as EaterLocation;
}
