import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePaginationComponent {

  @Input()
  public page: number;

  @Input()
  public pageSize: number;

  @Input()
  public total: number;

  @Output()
  public goToPage: EventEmitter<number> = new EventEmitter<number>();

  public onPageChanged(page: number): void {
    if (page < 1 || page > this.lastPage || page === this.page) {
      return;
    }
    this.goToPage.emit(page);
  }

  public get isFirstPage(): boolean {
    return this.page === 1;
  }

  public get hasPreviousPage(): boolean {
    return !this.isFirstPage;
  }

  public get isLastPage(): boolean {
    return this.page >= this.lastPage;
  }

  public get hasNextPage(): boolean {
    return !this.isLastPage;
  }

  public get lastPage(): number {
    return this.pageSize > 0 ?
      Math.max(1, Math.ceil(this.total / this.pageSize)) :
      1; // Accounts for the instances where pageSize is 0, or undefined
  }
}
