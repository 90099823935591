import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';

export type ScrollState = 'top' | 'bottom' | 'middle' | null;

export const DETECT_SCROLL_CHANGE_FREQUENCY_MS = 100;

@Directive({
  selector: '[scrollState]'
})
export class ScrollStateDirective implements OnInit, OnDestroy {

  @Output()
  public scrollState: EventEmitter<ScrollState> = new EventEmitter();

  private isDestroyed: boolean;

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private window: WindowRef,
  ) {}

  public ngOnInit(): void {
    if (this.isDestroyed) {
      return;
    }
    const element = this.elementRef.nativeElement;
    let lastScrollState = this.determineScrollState(element);
    let lastRun = 0;

    this.zone.runOutsideAngular(() => {
      const tick = (time: number) => {
        if (this.isDestroyed) {
          return;
        }
        if (lastRun === 0 || (time - lastRun > DETECT_SCROLL_CHANGE_FREQUENCY_MS)) {
          const scrollState = this.determineScrollState(element);
          if (scrollState !== lastScrollState) {
            this.zone.run(() => {
              this.scrollState.emit(scrollState);
            });
          }
          lastRun = time;
          lastScrollState = scrollState;
        }
        this.window.nativeWindow.requestAnimationFrame(tick);
      };

      this.window.nativeWindow.requestAnimationFrame(tick);
    });
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  private determineScrollState(element: HTMLElement): ScrollState {
    const sh = element.scrollHeight;
    const ch = element.clientHeight;
    if (sh === ch) {
      return null;
    }
    // 0 < scrollTop <= scrollHeight - clientHeight
    const st = element.scrollTop;
    if (st === 0) {
      return 'top';
    }
    if (st === sh - ch) {
      return 'bottom';
    }
    return 'middle';
  }
}
