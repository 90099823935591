import {
  AdvancedBudgets,
  ChoiceDeadline,
  CuisineType,
  CustomerId,
  CustomerLocation,
  DeliverableMenu,
  DietaryType,
  EventType,
  IndividualChoiceOrderGroup,
  IndividualChoicePaymentDetails,
  ItemId,
  MinorCurrency,
  NewDeliveryLocation,
  OrderValidationError,
  Page,
  SearchLocation,
  SearchPreferences,
  SearchRequestIndividualChoice,
  SearchResult,
  SearchSortType,
  SelectedVendor,
  VendorFlag,
  VendorId,
  VendorLocationId,
  VendorLocationSlug,
  VendorSlug,
} from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';
import moment from 'moment';
import { CustomerLocationUpdate, DeliveryDetailsUpdate } from './individual-choice-setup.state';

export const IndividualChoiceSetupActions = {
  setSearchRequest: createAction(
    '[Individual Choice Setup] Set Search Request',
    props<{
      searchRequest: SearchRequestIndividualChoice;
      customerLocation: CustomerLocation | null; // nullable if customer's current location not set in search
      searchLocation: SearchLocation;
    }>(),
  ),

  search: createAction(
    '[Individual Choice Setup] Search',
  ),

  searchResultsLoaded: createAction(
    '[Individual Choice Setup] Search Results Loaded',
    props<{ results: Page<SearchResult> }>(),
  ),

  loadNextPage: createAction(
    '[Individual Choice Setup] Load Next Page',
  ),

  addVendorToCart: createAction(
    '[Individual Choice Setup] Add Vendor To Cart',
  ),

  removeVendorFromCart: createAction(
    '[Individual Choice Setup] Remove Vendor From Cart',
    props<{
      vendorId: VendorId;
      vendorLocationId: VendorLocationId;
    }>(),
  ),

  hiddenBudgetVendorAddedToCart: createAction(
    '[Individual Choice Setup] Budget Hidden Vendor Added To Cart',
  ),

  permanentlyDismissHiddenBudgetVendorModal: createAction(
    '[Individual Choice Setup] Budget Hidden Vendor Modal Permanently Dismissed',
    props<{ vendorId: VendorId }>(),
  ),

  showSearchParametersDialog: createAction(
    '[Individual Choice Setup] Show Search Parameters Dialog',
  ),

  closeSearchParametersDialog: createAction(
    '[Individual Choice Setup] Close Search Parameters Dialog',
  ),

  updateSearchParameters: createAction(
    '[Individual Choice Setup] Update Search Parameters',
    props<{
      timestamp: moment.Moment;
      date: moment.Moment;
      customerLocation: CustomerLocation | null;
      searchLocation: SearchLocation | null;
    }>(),
  ),

  doSearch: createAction(
    '[Individual Choice Setup] Do Search',
    props<{
      request: SearchRequestIndividualChoice;
      pageNumber: number;
    }>(),
  ),

  resetSearch: createAction(
    '[Individual Choice Setup] Reset Search',
  ),

  updateSearchFilters: createAction(
    '[Individual Choice Setup] Update Search Filters',
    props<{
      cuisines: CuisineType[];
      events: EventType[];
      dietaries: DietaryType[];
      vendorFlags: VendorFlag[];
    }>(),
  ),

  updateSearchQuery: createAction(
    '[Individual Choice Setup] Update Search Query',
    props<{ query: string }>(),
  ),

  fetchMenu: createAction(
    '[Individual Choice Setup] Fetch Menu',
    props<{
      vendorIdOrSlug: VendorId | VendorSlug;
      vendorLocationSlug: VendorLocationSlug | null;
    }>(),
  ),

  menuFetched: createAction(
    '[Individual Choice Setup] Menu Fetched',
    (deliverableMenu: DeliverableMenu, isSubsidisedChoiceEnabled: boolean = false) => ({
      deliverableMenu,
      isSubsidisedChoiceEnabled,
    })
  ),

  createIndividualChoiceOrderGroup: createAction(
    '[Individual Choice Setup] Create Individual Choice Order Group',
    props<{ paymentDetails: IndividualChoicePaymentDetails }>(),
  ),

  individualChoiceOrderGroupCreated: createAction(
    '[Individual Choice Setup] Individual Choice Order Group Created',
    props<{
      orderGroup: IndividualChoiceOrderGroup;
      selectedVendors: SelectedVendor[]; // provided as a payload property because this action's reducer clears the existing value from state
    }>(),
  ),

  fetchAvailableItemIds: createAction(
    '[Individual Choice Setup] Fetch Available Item Ids',
    props<{
      vendorIdOrSlug: VendorId | VendorSlug;
      vendorLocationSlug: VendorLocationSlug | null;
    }>(),
  ),

  availableItemIdsFetched: createAction(
    '[Individual Choice Setup] Available Item Ids Fetched',
    props<{
      itemIds: ItemId[];
      isSubsidisedChoiceEnabled: boolean;
    }>(),
  ),

  clearSelectedVendors: createAction(
    '[Individual Choice Setup] Clear Selected Vendors',
  ),

  updateHeadcount: createAction(
    '[Individual Choice Setup] Update Headcount',
    props<{ headcount: number }>(),
  ),

  updateBudget: createAction(
    '[Individual Choice Setup] Update Budget',
    props<{ budget: MinorCurrency }>(),
  ),

  updateSubsidisedChoice: createAction(
    '[Individual Choice Setup] Update Subsidised Choice',
    props<{ turnedOn: boolean }>(),
  ),

  updateColleagueGroups: createAction(
    '[Individual Choice Setup] Update Colleague Groups',
    props<{ toggledOn: boolean }>(),
  ),

  updateChoiceDeadline: createAction(
    '[Individual Choice Setup] Update Choice Deadline',
    props<{ choiceDeadline: ChoiceDeadline }>(),
  ),

  updateSortOrder: createAction(
    '[Individual Choice Setup] Update Sort Order',
    props<{ sortOrder: SearchSortType }>(),
  ),

  validateIndividualChoiceOrderGroup: createAction(
    '[Individual Choice Setup] Validate Individual Choice Order Group',
  ),

  individualChoiceOrderGroupValidated: createAction(
    '[Individual Choice Setup] Individual Choice Order Group Validated',
    props<{ order: IndividualChoiceOrderGroup }>(),
  ),

  individualChoiceOrderGroupValidationFailed: createAction(
    '[Individual Choice Setup] Individual Choice Order Group Validation Failed',
    (error?: OrderValidationError) => ({
      error: error || null
    })
  ),

  operationalRegionLoadSuccess: createAction(
    '[Individual Choice] Operational Region Load Success',
    props<{ operationalRegion: string }>(),
  ),

  operationalRegionLoadFailure: createAction(
    '[Individual Choice] Operational Region Load Failure',
  ),

  updateSearchPreferences: createAction(
    '[Individual Choice Setup] Update Search Preferences',
    props<{
      searchPreferences: SearchPreferences | null;
      isAdvancedBudgetingEnabled: boolean;
      advancedBudgets: AdvancedBudgets | null;
      customerId: CustomerId | null;
    }>(),
  ),

  saveDeliveryLocation: createAction(
    '[Individual Choice Setup] Save Delivery Location',
    props<{ customerLocation: CustomerLocationUpdate }>(),
  ),

  deliveryLocationUpdated: createAction(
    '[Individual Choice Setup] Delivery location Updated',
    props<{ customerLocation: CustomerLocation }>(),
  ),

  deliveryLocationError: createAction(
    '[Individual Choice Setup] Delivery location Error',
  ),

  deliveryLocationFormFinished: createAction(
    '[Individual Choice Setup] Delivery Location Form Finished',
  ),

  deliveryDetailsSubmitted: createAction(
    '[Individual Choice Setup] Delivery Details Submitted',
    props<{ deliveryDetailsUpdate: DeliveryDetailsUpdate }>(),
  ),

  deliveryDetailsUpdated: createAction(
    '[Individual Choice Setup] Delivery Details Updated',
    props<{ customerLocation: CustomerLocation }>(),
  ),

  newDeliveryLocationSubmitted: createAction(
    '[Individual Choice Setup] New Delivery Location Submitted',
    props<{ newDeliveryLocation: NewDeliveryLocation }>(),
  ),

  newCardPaymentDetailsSubmitted: createAction(
    '[Individual Choice Setup] New Card Payment Details Submitted',
    props<{ paymentDetails: IndividualChoicePaymentDetails }>(),
  ),

  existingCardPaymentDetailsSubmitted: createAction(
    '[Individual Choice Setup] Existing Card Payment Details Submitted',
    props<{ paymentDetails: IndividualChoicePaymentDetails }>(),
  ),

  failedToAddOrVerifyPaymentCard: createAction(
    '[Individual Choice Setup] Failed to add or verify payment card',
    props<{ error: Error }>(),
  ),

  paymentFormLoading: createAction(
    '[Individual Choice Setup] Payment Form Loading',
  ),

  paymentFormFinished: createAction(
    '[Individual Choice Setup] Payment Form Finished',
  ),

  toggleSelectedItems: createAction(
    '[Individual Choice Setup] Toggle Selected Items',
    props<{
      items: ItemId[];
      isSubsidisedChoiceEnabled: boolean;
    }>(),
  ),

  editCustomerPageLocationView: createAction(
    '[EditCustomerLocationPageGuard] Edit Customer Location Page View',
    props<{ customerLocation: CustomerLocation }>(),
  ),

  removeSearchRequest: createAction(
    '[Individual Choice Setup] Remove Search Request'
  ),

  paymentDetailsGuardPageLoad: createAction(
    '[Load Payment Details Page Guard] Page Load'
  ),

  menuPageView: createAction(
    '[Individual Choice Setup] Menu Page View',
    props<{
      vendorIdOrSlug: VendorId | VendorSlug;
      vendorLocationSlug: VendorLocationSlug | null;
    }>(),
  ),
};

const all = union(IndividualChoiceSetupActions);
export type IndividualChoiceSetupAction = typeof all;
