export interface Image {
  thumbnail: string;
  medium: string;
  large: string;
  original: string | null;
}

export function createImageFromJson(json?: Partial<Image>): Image {
  const image: Partial<Image> = {};

  image.thumbnail = json.thumbnail;
  image.medium = json.medium;
  image.large = json.large;
  image.original = json.original || null;

  return image as Image;
}
