import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { createURLQueryFromSearchRequest, SearchRequest, SearchResult } from '@citypantry/util-models';
import moment from 'moment';

export type SearchResultUrl = (any | 'VENDOR_ID' | 'VENDOR_SLUG' | 'VENDOR_LOCATION_SLUG' | 'MENU_ID')[];

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultComponent {
  @Input()
  public result: SearchResult;

  @Input()
  public showDistance: boolean;

  @Input()
  public searchRequest: SearchRequest;

  /**
   * The URL to redirect to when a search result is clicked.
   * Any segments with value 'VENDOR_ID', 'VENDOR_SLUG', 'VENDOR_LOCATION_SLUG' or 'MENU_ID'
   * will be replaced with the value from the SearchResult.
   * 'VENDOR_LOCATION_SLUG' will be filtered out if there is no vendorLocationSlug in the SearchResult.
   */
  @Input()
  public url: SearchResultUrl;

  @Input()
  public date: moment.Moment;

  @Input()
  public useDisplayMode: boolean;

  @Input()
  public showMainsWithinBudget?: boolean;

  @Input()
  public showFavouriteButton: boolean;

  @Input()
  public isFavourited: boolean | undefined;

  @Input()
  public isHiddenBudgetVendor?: boolean;

  @Output()
  public toggleFavourite: EventEmitter<void> = new EventEmitter();

  @Input()
  public showRemainingCapacity: boolean;

  @Input()
  public showExactRemainingCapacity: boolean;

  public stars: number[] = [1, 2, 3, 4, 5];

  constructor() {
    this.useDisplayMode = false;
    this.showFavouriteButton = false;
    this.showRemainingCapacity = false;
    this.showExactRemainingCapacity = false;
  }

  public get canShowDistance(): boolean {
    return typeof this.result.distance !== 'undefined' && this.result.distance !== null && this.showDistance;
  }

  public getUrl(): any[] {
    return (this.url || [])
      // If we don't have a vendor location slug, we shouldn't have it in the url
      .filter((segment: any) => segment !== 'VENDOR_LOCATION_SLUG' || this.result.vendorLocationSlug)
      .map((segment: any) => {
        switch (segment) {
          case 'VENDOR_ID':             return this.result.vendorId;
          case 'MENU_ID':               return this.result.menuId;
          case 'VENDOR_SLUG':           return this.result.vendorSlug;
          case 'VENDOR_LOCATION_SLUG':  return this.result.vendorLocationSlug;
          default:                      return segment;
        }
      });
  }

  public getQueryParams(): Params {
    const dateString = this.date ? this.date.format('YYYY-MM-DDTHH:mm') : null;
    const searchRequest = this.searchRequest ? createURLQueryFromSearchRequest(this.searchRequest) : null;

    return dateString ? {...searchRequest, date: dateString} : searchRequest;
  }

  public onFavouriteToggled(): void {
    this.toggleFavourite.emit();
  }
}
