import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { ScrollState } from '@citypantry/components-scroll';

@Component({
  selector: 'app-cart-scroll-panel',
  template: `
    <div
      class="cart-panel__content"
      (scrollState)="setScrollState($event)"
      test-id="scrollContent"
    >
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartScrollPanelComponent {

  private scrollState: ScrollState;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.scrollState = null;

    this.renderer.addClass(this.element.nativeElement, 'cart-panel');
    this.renderer.addClass(this.element.nativeElement, 'cart-panel--scrollable');
  }

  public setScrollState(scrollState: ScrollState): void {
    if (this.scrollState) {
      this.renderer.removeClass(this.element.nativeElement, `cart-panel--scrolled-${this.scrollState}`);
    }

    if (scrollState) {
      this.renderer.addClass(this.element.nativeElement, `cart-panel--scrolled-${scrollState}`);
    }

    this.scrollState = scrollState;
  }
}
