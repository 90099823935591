import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feeds-quantity',
  templateUrl: './feeds-quantity.component.html',
  styleUrls: ['./feeds-quantity.component.scss']
})
export class FeedsQuantityComponent {
  @Input()
  public quantity: number;
}
