import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Image } from '@citypantry/util-models';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { FileUploaderFactory } from '../file-uploader-factory.class';

/**
 * The S3 bucket into which the image gets uploaded.
 * Currently only supports "single-item" which is for single items, custom items and bundles.
 */
export type ImageUploadTarget = 'single-item';

@Component({
  selector: 'app-multiple-image-upload',
  templateUrl: './multiple-image-upload.component.html',
  styleUrls: ['./multiple-image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleImageUploadComponent),
      multi: true
    }
  ]
})
export class MultipleImageUploadComponent implements OnInit, ControlValueAccessor {
  @Input()
  public target: ImageUploadTarget;

  /**
   * Dimensions, in pixels, of suggested image upload dimensions (used for the label).
   * Defaults to 2x the displayed size of an item bundle's hero image (see .item-bundle-modal__hero)
   */
  @Input()
  public constraintDimensions: [number, number] = [1530, 640];

  public uploader: FileUploader;
  public isUploading: boolean;
  public isFileOver: boolean;
  public images: Image[];

  private onChange: Subject<Image[]>;

  constructor(fileUploaderFactory: FileUploaderFactory) {
    this.uploader = fileUploaderFactory.getFileUploader();
    this.isFileOver = false;
    this.isUploading = false;
    this.onChange = new Subject<Image[]>();
    this.images = [];
  }

  public ngOnInit(): void {
    this.uploader.setOptions({
      additionalParameter: {
        target: this.target
      }
    });

    this.uploader.onBeforeUploadItem = (fileItem: FileItem): void => {
      fileItem.withCredentials = false;
      this.isUploading = true;
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string): void => {
      const imageData: Image = JSON.parse(response);
      this.images.push({
        thumbnail: imageData.thumbnail,
        medium: imageData.medium,
        large: imageData.large,
        original: imageData.original,
      });
    };

    // TODO error handling

    this.uploader.onCompleteItem = (): void => {
      this.isUploading = false;
      this.onChange.next(this.images.slice());
    };
  }

  public writeValue(images: Image[]): void {
    this.images = images && images.slice() || [];
  }

  public registerOnChange(listener: (images: Image[]) => void): void {
    this.onChange.subscribe(listener);
  }

  public registerOnTouched(): void {
    // not needed here
  }

  /**
   * Gets called when a file is dragged over the component's label.
   */
  public onFileOver(isFileOver: any): void {
    this.isFileOver = isFileOver;
  }

  /**
   * When the user selects a new image to be their 'main image'
   *
   * Main image is currently defined by being the first in the list
   */
  public setMainImage(index: number): void {
    // remove item at the index
    const removedImages: Image[] = this.images.splice(index, 1);
    // place it at the beginning
    this.images.unshift(removedImages[0]);

    this.onChange.next(this.images.slice());
  }

  /**
   * When a user opts to remove an item. Doesn't delete it remotely,
   * just removes any reference to it.
   */
  public deleteImage(index: number): void {
    // remove item at the index
    this.images.splice(index, 1);

    this.onChange.next(this.images.slice());
  }
}
