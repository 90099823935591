import { VendorId } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const FavouriteVendorsActions = {
  toggleVendorFromSearchComponent: createAction(
    '[Search Component] Toggle Vendor',
    props<{
      vendorId: VendorId;
      favourited: boolean;
    }>(),
  ),

  toggleVendorFromCustomerMenu: createAction(
    '[Customer Menu] Toggle Vendor',
    props<{
      vendorId: VendorId;
      favourited: boolean;
    }>(),
  ),

  favouritesLoadedFromAuth: createAction(
    '[Auth Effects] favourites loaded from auth',
    props<{
      favouriteVendors: VendorId[];
    }>()
  ),

  pageLoadedWithVendorToAddPresent: createAction(
    '[StoreFavouriteAfterAuthenticationGuard] Page loaded with vendorToAdd present',
    props<{ vendorId: VendorId }>(),
  )
};

const all = union(FavouriteVendorsActions);
export type FavouriteVendorsAction = typeof all;
