/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type StatusColour =
  'green'
  | 'green-2'
  | 'orange'
  | 'orange-2'
  | 'red'
  | 'red-2'
  | 'charcoal'
  | 'stone-2';

export class StatusColours {
  public static GREEN:    StatusColour & 'green'    = 'green';
  public static GREEN2:   StatusColour & 'green-2'  = 'green-2';
  public static ORANGE:   StatusColour & 'orange'   = 'orange';
  public static ORANGE2:  StatusColour & 'orange-2' = 'orange-2';
  public static RED:      StatusColour & 'red'      = 'red';
  public static RED2:     StatusColour & 'red-2'    = 'red-2';
  public static CHARCOAL: StatusColour & 'charcoal' = 'charcoal';
  public static STONE2:   StatusColour & 'stone-2'  = 'stone-2';

  public static get values(): StatusColour[] {
    return [
      StatusColours.GREEN,
      StatusColours.GREEN2,
      StatusColours.ORANGE,
      StatusColours.ORANGE2,
      StatusColours.RED,
      StatusColours.RED2,
      StatusColours.CHARCOAL,
      StatusColours.STONE2,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type StatusColourEnum = typeof StatusColours;
