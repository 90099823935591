/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type RefundRefundee = 'customer' | 'eater';

export class RefundRefundees {
  public static CUSTOMER:   RefundRefundee & 'customer' = 'customer';
  public static EATER:      RefundRefundee & 'eater'    = 'eater';

  public static get values(): RefundRefundee[] {
    return [
      RefundRefundees.CUSTOMER,
      RefundRefundees.EATER,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type RefundRefundeesEnum = typeof RefundRefundees;
