import { VendorNotificationAction, VendorNotificationsActions } from './vendor-notifications.actions';

export const FEATURE_STATE_NAME = 'vendorNotifications';

export interface VendorNotificationsState {
  loadingPendingOrdersCount: boolean;
  pendingOrdersCount: number;
  pendingOrdersCountLoadedAt: string | null;
}

export const INITIAL_STATE: VendorNotificationsState = {
  loadingPendingOrdersCount: false,
  pendingOrdersCount: 0,
  pendingOrdersCountLoadedAt: null,
};

export function reducer(state: VendorNotificationsState = INITIAL_STATE, action: VendorNotificationAction): VendorNotificationsState {
  switch (action.type) {
    case VendorNotificationsActions.loadPendingOrdersCount.type: {
      return {
        ...state,
        loadingPendingOrdersCount: true,
      };
    }

    case VendorNotificationsActions.pendingOrdersCountLoaded.type: {
      const { count, timestamp } = action;

      return {
        ...state,
        loadingPendingOrdersCount: false,
        pendingOrdersCount: count,
        pendingOrdersCountLoadedAt: timestamp,
      };
    }

    default:
      return state;
  }
}
