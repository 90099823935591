import { EMPTY_DIETARIES } from './dietaries.model';
import { EMPTY_AVAILABILITY } from './item-availability.model';
import { EMPTY_ITEM_GROUP, ItemGroup } from './item-group.model';
import { ItemTypes } from './item-type.enum';
import { Item } from './item.model';
import { ServingStyle, ServingStyles } from './serving-style.enum';

export interface ItemBundle extends Item {
  groups: ItemGroup[];
  servingStyle: ServingStyle; // Unlike Item, this is mandatory
  portionSize?: number; // integer, applies only when servingStyle is PLATTER
  ageRestricted: boolean | undefined; // Set by the backend upon save, true if the bundle can only be configured with age restricted items
}

export function createEmptyItemBundle(currencyIsoCode: string): ItemBundle {
  return {
    type: ItemTypes.ITEM_BUNDLE,
    name: '',
    description: '',
    dietaries: EMPTY_DIETARIES,
    cuisine: null,
    ecoFriendlyPackaging: null,
    events: [],
    images: [],
    price: null,
    currencyIsoCode,
    notice: null,
    minimumOrderQuantity: null,
    maximumOrderQuantity: null,
    portionSize: 1,
    groups: [EMPTY_ITEM_GROUP],
    servingStyle: ServingStyles.INDIVIDUAL,
    vatRateType: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    ageRestricted: undefined, // Set by the backend upon save, true if the bundle can only be configured with age restricted items

    availability: EMPTY_AVAILABILITY
  };
}

export function isItemBundle(item: Item): item is ItemBundle {
  return item.type === ItemTypes.ITEM_BUNDLE;
}

// For the createFromJson() method see create-item.ts
