/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ItemDeliverabilityProblem =
  'more_notice_required'
  | 'outside_delivery_radius'
  | 'outside_delivery_time'
  | 'not_in_operational_region'
  | 'choice_deadline_past_notice'
  | 'not_deliverable_from_selected_vendor_location';

export class ItemDeliverabilityProblems {
  public static MORE_NOTICE_REQUIRED:                          ItemDeliverabilityProblem & 'more_notice_required'                          = 'more_notice_required';
  public static OUTSIDE_DELIVERY_RADIUS:                       ItemDeliverabilityProblem & 'outside_delivery_radius'                       = 'outside_delivery_radius';
  public static OUTSIDE_DELIVERY_TIME:                         ItemDeliverabilityProblem & 'outside_delivery_time'                         = 'outside_delivery_time';
  public static NOT_IN_OPERATIONAL_REGION:                     ItemDeliverabilityProblem & 'not_in_operational_region'                     = 'not_in_operational_region';
  public static CHOICE_DEADLINE_PAST_NOTICE:                   ItemDeliverabilityProblem & 'choice_deadline_past_notice'                   = 'choice_deadline_past_notice';
  public static NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION: ItemDeliverabilityProblem & 'not_deliverable_from_selected_vendor_location' = 'not_deliverable_from_selected_vendor_location';

  public static get values(): ItemDeliverabilityProblem[] {
    return [
      ItemDeliverabilityProblems.MORE_NOTICE_REQUIRED,
      ItemDeliverabilityProblems.OUTSIDE_DELIVERY_RADIUS,
      ItemDeliverabilityProblems.OUTSIDE_DELIVERY_TIME,
      ItemDeliverabilityProblems.NOT_IN_OPERATIONAL_REGION,
      ItemDeliverabilityProblems.CHOICE_DEADLINE_PAST_NOTICE,
      ItemDeliverabilityProblems.NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION,
    ];
  }

  // Do not modify this line - Custom code below here

  public static get priority(): ItemDeliverabilityProblem[] {
    return [
      ItemDeliverabilityProblems.NOT_IN_OPERATIONAL_REGION,
      ItemDeliverabilityProblems.OUTSIDE_DELIVERY_RADIUS,
      ItemDeliverabilityProblems.MORE_NOTICE_REQUIRED,
      ItemDeliverabilityProblems.CHOICE_DEADLINE_PAST_NOTICE,
      ItemDeliverabilityProblems.NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION,
      ItemDeliverabilityProblems.OUTSIDE_DELIVERY_TIME,
    ];
  }
}

export type ItemDeliverabilityProblemEnum = typeof ItemDeliverabilityProblems;
