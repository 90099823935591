import { Injectable } from '@angular/core';
import { AttributionStore } from './attribution-store';
import { GoogleMaps } from './google-maps';

@Injectable({ providedIn: 'root' })
export class GoogleMapsApis {
  private _autocomplete: google.maps.places.AutocompleteService;
  private _places: google.maps.places.PlacesService;

  constructor(
    private maps: GoogleMaps,
    private attributionStore: AttributionStore,
  ) {
  }

  public get autocomplete(): Promise<google.maps.places.AutocompleteService> {
    return this.maps.ready().then(() => {
      if (!this._autocomplete) {
        this._autocomplete = new google.maps.places.AutocompleteService();
      }
      return this._autocomplete;
    });
  }

  public get places(): Promise<google.maps.places.PlacesService> {
    return this.maps.ready().then(() => {
      if (!this.attributionStore.hasElement()) {
        // eslint-disable-next-line max-len
        throw Error('No google attribution found. Please use the google-maps-attribution directive to appoint an attribution element before injecting GoogleMapsPlaces.');
      }
      if (!this._places) {
        this._places = new google.maps.places.PlacesService(this.attributionStore.getElement());
      }
      return this._places;
    });
  }
}
