import { Injectable } from '@angular/core';
import { ScreenSize, ScreenSizes } from './screen-size.enum';
import { WindowRef } from './window-ref.service';

const breakpoints = {
  [ScreenSizes.MOBILE]: 320,
  [ScreenSizes.PHABLET]: 460,
  [ScreenSizes.TABLET]: 768,
  [ScreenSizes.LAPTOP]: 1024,
  [ScreenSizes.DESKTOP]: 1280,
};

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  constructor(
    private windowRef: WindowRef,
  ) {}

  public isAbove(screenSize: ScreenSize): boolean {
    const size = breakpoints[screenSize];
    return this.windowRef.nativeWindow.matchMedia(`(min-width: ${size}px)`).matches;
  }

  public isBelow(screenSize: ScreenSize): boolean {
    const size = breakpoints[screenSize];
    return this.windowRef.nativeWindow.matchMedia(`(max-width: ${size - 1}px)`).matches;
  }
}
