import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VendorNotificationsEffects } from './vendor-notifications.effects';
import { FEATURE_STATE_NAME, reducer } from './vendor-notifications.state';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([VendorNotificationsEffects]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
  providers: [
    VendorNotificationsEffects
  ],
})
export class VendorNotificationsModule { }
