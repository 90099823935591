import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Imports that modify the application and are required globally
import 'moment-timezone'; // Load moment-timezone to make it available on moment

import { environment } from '@citypantry/shared-app-config';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
