<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://business.just-eat.co.uk/privacy-policy', label: 'Privacy Policy' }"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/customer/orders', label: 'Orders' }"></ng-container>

  <!-- header-container ensures this is not shown to Orderers; in the future more granular permissions like this one could be supported by AuthSelectors -->
  <ng-container *ngIf="showMealPlanLink">
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/customer/meal-plans', label: 'Meal plans' }"></ng-container>
  </ng-container>

  <ng-container *ngIf="isEater">
    <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/my-meals'], label: 'Meals' }"></ng-container>
  </ng-container>

  <app-header-dropdown
    [triggerLabel]="'Account'"
    e2e-test-id="accountNavigationLink"
    test-id="accountDropdown"
  >
    <ng-container *ngIf="!isOrderer">
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/customer/account-details', label: 'Account details', isSecondary: true }"></ng-container>

      <ng-container *ngIf="isEligibleForIndividualChoice">
        <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { label: 'Colleagues', routerLink: ['/menus/account/locations/eaters'], isSecondary: true }"></ng-container>
      </ng-container>

      <ng-container *ngIf="!isOrderer">
        <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { label: 'Colleague Notifications', routerLink: ['/menus/customer/colleague-notifications'], isSecondary: true }"></ng-container>
      </ng-container>

      <ng-container *ngIf="isColleagueGroupsEnabled">
        <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { label: 'Groups', routerLink: ['/menus/groups'], isSecondary: true }"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { label: 'Reports', routerLink: ['/menus/customer/reports'], isSecondary: true }"></ng-container>

      <ng-container *ngIf="isAdvancedBudgetingEnabled">
        <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { label: 'Advanced Budgeting', routerLink: ['/menus/customer/advanced-budgeting'], isSecondary: true }"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/customer/addresses', label: 'Addresses', isSecondary: true }"></ng-container>
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/user/payment-cards', label: 'Payment Cards', isSecondary: true }"></ng-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="authenticatedUserLinksTemplate"></ng-container>
  </app-header-dropdown>

  <ng-container *ngIf="!isOrderer">
    <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://citypantrysupport.zendesk.com', label: 'Support', analyticsId: 'supportLink' }"></ng-container>

    <app-header-dropdown
      [triggerLabel]="'Get Inspired'"
      [triggerAnalyticsId]="'inspiration'"
      test-id="getInspiredDropdown"
    >
      <li
        *ngIf="marketingCampaignComponent"
        test-id="marketingCampaignLinkWrapper"
      >
        <a
          class="header-navigation__link header-navigation__link--secondary"
          href="{{ marketingCampaignComponent.ctaLink }}"
          [target]="marketingCampaignComponent.openLinkInNewTab ? '_blank' : '_parent'"
          analyticsId="marketing-campaign-link"
          [analyticsData]="{analyticsPrefix: marketingCampaignComponent.analyticsPrefix}"
          test-id="marketingCampaignLink"
        >{{ marketingCampaignComponent.ctaText }}</a>
      </li>

      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/pantry-packages', label: 'Pantry Packages', isSecondary: true, analyticsId: 'pantry-packages' }"></ng-container>
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/just-eat-gift-cards', label: 'Just Eat Gift Cards', isSecondary: true, analyticsId: 'just-eat-gift-cards' }"></ng-container>
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/teams-at-home', label: 'TEAMS@HOME', isSecondary: true, analyticsId: 'teams-at-home' }"></ng-container>
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/blog', label: 'Blog', isSecondary: true, analyticsId: 'blog' }"></ng-container>
      <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/customer/home'], label: 'Recommended', isSecondary: true, analyticsId: 'customer-home' }"></ng-container>
      <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/what-we-do', label: 'What we do', isSecondary: true, analyticsId: 'what-we-do' }"></ng-container>
    </app-header-dropdown>

    <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/pop-ups', label: 'Pop-ups', analyticsId: 'pop-ups' }"></ng-container>

  </ng-container>

  <ng-container *ngTemplateOutlet="searchFoodButtonTemplate"></ng-container>
</ul>
