/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type CartValidityErrorType =
  'BELOW_MINIMUM_ORDER_VALUE'
  | 'POSTCODE_REQUIRED'
  | 'ITEM_OVER_MAX_QUANTITY';

export class CartValidityErrorTypes {
  public static BELOW_MINIMUM_ORDER_VALUE: CartValidityErrorType & 'BELOW_MINIMUM_ORDER_VALUE' = 'BELOW_MINIMUM_ORDER_VALUE';
  public static POSTCODE_REQUIRED:         CartValidityErrorType & 'POSTCODE_REQUIRED'         = 'POSTCODE_REQUIRED';
  public static ITEM_OVER_MAX_QUANTITY:    CartValidityErrorType & 'ITEM_OVER_MAX_QUANTITY'    = 'ITEM_OVER_MAX_QUANTITY';

  public static get values(): CartValidityErrorType[] {
    return [
      CartValidityErrorTypes.BELOW_MINIMUM_ORDER_VALUE,
      CartValidityErrorTypes.POSTCODE_REQUIRED,
      CartValidityErrorTypes.ITEM_OVER_MAX_QUANTITY,
    ];
  }

  // Do not modify this line - Custom code below here
  public static errorsOverridableByAdmin: CartValidityErrorType[] = [
    CartValidityErrorTypes.BELOW_MINIMUM_ORDER_VALUE,
    CartValidityErrorTypes.ITEM_OVER_MAX_QUANTITY,
  ];
}

export type CartValidityErrorTypeEnum = typeof CartValidityErrorTypes;
