import { Injectable } from '@angular/core';
import { DeliveryContactApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { CustomerId } from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeliveryContactActions } from './delivery-contact.actions';

@Injectable({
  providedIn: 'root',
})
export class DeliveryContactEffects {

  public getDeliveryContacts$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(DeliveryContactActions.getDeliveryContacts.type),
    switchMap(() => this.store.select(AuthSelectors.customer.getId)),
    switchMap((customerId: CustomerId) => {
      return this.deliveryContactsApi.getDeliveryContacts(customerId).pipe(
        map((contacts) => DeliveryContactActions.getDeliveryContactsSuccess({ contacts }))
      );
    }),
  ));

  constructor(
    private action$: Actions,
    private deliveryContactsApi: DeliveryContactApi,
    private store: Store<AppState>,
  ) {}
}
