import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const individualChoiceEditDeliveryRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'location-picker',
    events: 'change',
    definition:  {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Location option selected`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'edit-location-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Edit location button`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'drop-off-instructions',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Drop off instructions entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'delivery-contact-picker',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Delivery Contact picker clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'first-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `First name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'last-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Last name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Email field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'phone-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Phone number field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'email-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `Email delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'sms-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `SMS delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'cancel-changes',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `CANCEL CHANGES button clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-delivery',
    path: 'save-and-continue',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      label: `SAVE AND CONTINUE button clicked`,
    },
  },
];
