import { Injectable } from '@angular/core';
import { ErrorCodes } from '@citypantry/util-models';

export const ExpectedErrors: { [key: string]: ExpectedError } = {
  [ErrorCodes.CANNOT_ADD_MASQUERADED_EATER_TO_LOCATION]: {
    title: `Cannot add masqueraded eater to location `,
    message: 'Do not follow location links when masquerading. It is not possible to add a masqueraded eater to a location.'
  },
  [ErrorCodes.CANNOT_CANCEL_ALREADY_CANCELLED_ORDER]: {
    title: 'You can\'t cancel this order',
    message: 'This order has already been cancelled.',
  },
  [ErrorCodes.CANNOT_CANCEL_ORDER_PAST_DEADLINE]: {
    title: 'You can no longer cancel this order',
    message: 'Unfortunately the deadline for cancelling this order has now passed.',
  },
  [ErrorCodes.CANNOT_CHARGE_CARD]: {
    title: 'Payment failed',
    message: 'Your card was declined. Please try again or contact your card provider.'
  },
  [ErrorCodes.CANNOT_CREATE_ORDER_PAST_DEADLINE]: {
    title: 'The order deadline has passed',
    message: 'Unfortunately the deadline for placing orders at your chosen time has now passed. ' +
      'Please choose an alternative delivery time / date.'
  },
  [ErrorCodes.CANNOT_EDIT_INDIVIDUAL_CHOICE_ORDER_PAST_DEADLINE]: {
    title: 'Order deadline passed',
    message: 'It is not possible to edit this order any more.'
  },
  [ErrorCodes.CANNOT_PROMOTE_MEMBER_OF_OTHER_CUSTOMER]: {
    title: 'This colleague is already an admin',
    message: 'Your colleague is already an admin for another account. Please choose another person.',
  },
  [ErrorCodes.CANNOT_UPDATE_EATER_CART_FOR_CANCELLED_ORDER]: {
    title: 'Order has been cancelled',
    message: 'It is not possible to confirm your choice as this order has been cancelled.'
  },
  [ErrorCodes.CANNOT_VERIFY_CARD]: {
    title: 'Card verification failed',
    message: 'Payment card verification failed. Please try an alternative card or contact your card issuer.'
  },
  [ErrorCodes.DELIVERY_AREA_NOT_AVAILABLE]: {
    title: 'Out of delivery area',
    message: 'Sorry, delivery to this area is not yet available.'
  },
  [ErrorCodes.EMAIL_IN_USE]: {
    title: 'Email already in use',
    message: 'A user with this email already exists.',
  },
  [ErrorCodes.INCOMPLETE_MENU_SUBMITTED]: {
    title: 'Incomplete menu submitted',
    message: 'One or more of the items submitted is not complete'
  },
  [ErrorCodes.INSUFFICIENT_TIME_FOR_EATERS_TO_CHOOSE]: {
    title: 'Your team does not have enough time to choose their meals',
    message: 'Choose a later delivery time to make sure no one misses out.',
  },
  [ErrorCodes.ORDER_NO_LONGER_EDITABLE]: {
    title: 'Could not edit',
    message: 'This order is no longer editable.'
  },
  [ErrorCodes.UNAPPROVED_VENDOR_SCHEDULING_MENUS]: {
    title: 'You haven\'t been approved',
    message: 'You must be approved first to schedule your menus.'
  },
  [ErrorCodes.VENDOR_OVER_HEADCOUNT_CAPACITY]: {
    title: 'You can\'t increase the headcount on this order',
    message: `The vendor cannot accept any more orders for this day. Give us a call on 020 3893 3500 to see if we can help.`
  },
  [ErrorCodes.CANNOT_ORDER_ABOVE_ENFORCED_HEADCOUNT_LIMIT]: {
    title: 'We\'re sorry, this vendor has now reached capacity for orders',
    message: 'Please choose from one of the other available options.'
  },
  [ErrorCodes.NOT_ALLOWED_TO_ORDER_FROM_MULTIPLE_VENDORS]: {
    title: 'You can\'t order from more than one vendor',
    message: 'Please clear your existing meal choice to choose from this option.'
  },
  [ErrorCodes.CANNOT_GENERATE_VALID_VENDOR_LOCATION_SLUG]: {
    title: 'Unable to generate a valid slug',
    message: 'We\'re unable to generate a valid slug from the "Public Name" submitted. ' +
      'Please choose a public name that is long enough and does not only consist of special characters.'
  },
  [ErrorCodes.VENDOR_LOCATION_NAMES_TOO_SIMILAR]: {
    title: 'Unable to generate a valid slug',
    message: 'Vendor has another location with a public name which is too similar to this one.'
  },
  [ErrorCodes.CANNOT_DELETE_DEFAULT_VENDOR_LOCATION]: {
    title: 'Cannot delete vendor location',
    message: 'You cannot delete a location that is set as the default.'
  },
};

interface ExpectedError {
  message: string;
  title: string;
}

/**
 * Wrapper around ExpectedErrors so that it can be mocked in tests
 */
@Injectable()
export class ExpectedErrorTranslator {

  /**
   * Fetch a translation for the given error. In case there is none, it will still return a translation object with default messages.
   */
  public translate(key: string): ExpectedError {
    return ExpectedErrors[key] || {
      title: 'Something\'s gone wrong',
      message: 'We\'re sorry about that. But don\'t worry, our team are on it! While they investigate, please refresh the page.'
    };
  }
}
