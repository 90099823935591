import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, InjectionToken, Input, OnDestroy, Renderer2 } from '@angular/core';

export const SCROLL_HANDLE_DIRECTIVE_INJECTION_TOKEN: InjectionToken<ScrollHandleDirective> = new InjectionToken(
  'ScrollHandleDirective',
);

/**
 * Directive to allow scrolling elements into view.
 * It will consider the height of all elements passed into scrollOffsetElements to determine where to scroll.
 * If any of the elements in scrollOffsetElements are off-screen, they will be ignored.
 *
 * Usage:
 *
 * <div scrollHandle scrollOffsetElements="#menuBar,#lostbar">
 *
 * @ViewChildren(ScrollHandleDirective)
 * private handles: QueryList<ScrollHandleDirective>;
 *
 * scrollToItem(i: number): void {
 *   handles[i].scrollIntoView({ block: 'start', behavior: 'smooth' }); // Same interface as Element#scrollIntoView()
 * }
 */
@Directive({
  selector: '[scrollHandle]',
  providers: [
    {
      provide: SCROLL_HANDLE_DIRECTIVE_INJECTION_TOKEN,
      useExisting: ScrollHandleDirective
    }
  ]
})
export class ScrollHandleDirective implements OnDestroy {

  /**
   * Saved and exposed so that parent components can identify the instances of ScrollHandleDirective inside them.
  */
  @Input()
  public scrollHandle: string | undefined;

  /**
   * The selector used to find offset elements, preferably identified by native "id" attribute.
   */
  @Input()
  public scrollOffsetElements: string | undefined;

  private scrollElement: HTMLAnchorElement;

  constructor(
    private hostElement: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
  }

  public ngOnDestroy(): void {
    if (this.scrollElement) {
      this.teardownScrollElement();
    }
  }

  // We use an empty scrollElement as an anchor that we will scroll to,
  // instead of calling native .scrollIntoView() directly on our host element.
  // This way we can specify additional arbitrary offset elements (that are expected to be sticky/fixed)
  // and ensure that enough space above our host element stays reserved for them.
  public scrollIntoView(settings?: boolean | ScrollIntoViewOptions): void {
    // the nearest positioned ancestor - our point of reference for position: absolute
    const offsetParent = this.hostElement.nativeElement.offsetParent;

    if (!this.scrollElement) {
      const newElement = this.renderer.createElement('a');
      this.renderer.setStyle(newElement, 'position', 'absolute');
      this.renderer.appendChild(offsetParent, newElement);
      this.scrollElement = newElement;
    }

    // the total height of all scrollOffsetElements
    const offsetElementsHeight = this.getOffsetElementsHeight();

    // distance between top of host element and top of offsetParent
    const offsetTop = this.hostElement.nativeElement.offsetTop;

    const position = offsetTop - offsetElementsHeight;
    this.renderer.setStyle(this.scrollElement, 'top', `${position}px`);

    // native DOM method
    this.scrollElement.scrollIntoView(settings);
  }

  private teardownScrollElement(): void {
    if (this.scrollElement) {
      this.renderer.removeChild(this.hostElement.nativeElement.offsetParent, this.scrollElement);
    }
  }

  private getOffsetElementsHeight(): number {
    if (!this.scrollOffsetElements) {
      return 0;
    }

    const elements: NodeListOf<Element> = this.document.documentElement.querySelectorAll(this.scrollOffsetElements);
    if (!elements || !elements.length) {
      return 0;
    }

    let totalHeight = 0;
    /* eslint-disable-next-line @typescript-eslint/prefer-for-of */ // NodeListOf is not an array, cannot use for...of
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const clientRect = element.getBoundingClientRect();

      totalHeight += clientRect.height;
    }
    return totalHeight;
  }
}
