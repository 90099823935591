import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Moment } from 'moment';

@Component({
  selector: 'app-tracking-progress-step',
  templateUrl: './tracking-progress-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingProgressStepComponent {
  @Input()
  public title: string;

  // The variable datetime is alternative to the HTML banner-datetime block.
  @Input()
  public datetime: Moment;

  @Input()
  public showBannerBody: boolean;

  @Input()
  public showBannerButton: boolean;
}
