/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type SingleInvoiceStatus = 'awaiting_payment' | 'paid';

export class SingleInvoiceStatuses {
  public static AWAITING_PAYMENT: SingleInvoiceStatus & 'awaiting_payment' = 'awaiting_payment';
  public static PAID:             SingleInvoiceStatus & 'paid'             = 'paid';

  public static get values(): SingleInvoiceStatus[] {
    return [
      SingleInvoiceStatuses.AWAITING_PAYMENT,
      SingleInvoiceStatuses.PAID,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type InvoiceStatusEnum = typeof SingleInvoiceStatuses;
