import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { OrderId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { DeliveryDetailsActions } from './delivery-details.actions';

@Injectable({ providedIn: 'root' })
export class DeliveryDetailsService {
  constructor(private store: Store<AppState>) {}

  public loadTrackingForOrders(ordersIds: OrderId[]): void {
    this.store.dispatch(DeliveryDetailsActions.loadTrackingForOrders({ ordersIds }));
  }
}
