import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@citypantry/shared-app-config';
import { CustomerLocation } from '@citypantry/util-models';

@Injectable({
  providedIn: 'root'
})
export class ShareableUrlService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationStrategy: LocationStrategy,
    private config: AppConfig,
  ) {}

  public getShareableUrl(location: CustomerLocation): string {
    const shareToken = location.shareToken;
    const shortName = location.name
      .toLocaleLowerCase()
      .replace(new RegExp('(-|[^a-zA-Z0-9 ])+', 'g'), ' ') // replace groups of special characters with a space
      .trim() // remove surrounding space
      .replace(new RegExp('[ -]+', 'g'), '-') // replace any groups of hyphens and/or spaces with a single hyphen
      .slice(0, 20) // Max length: 20
      .replace(/-+$/, ''); // Trim trailing hyphens if we've ended up with any

    const urlTree = this.getUrlTree([`/eat/${shortName}-${shareToken}`]);

    return this.config.FRONTEND_BASE + this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }

  private getUrlTree(target: string[]): UrlTree {
    return this.router.createUrlTree(target, {
      relativeTo: this.route,
    });
  }
}
