/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ItemType = 'SingleItem' | 'ItemBundle' | 'CustomItem';

export class ItemTypes {
  public static SINGLE_ITEM:       ItemType & 'SingleItem'       = 'SingleItem';
  public static ITEM_BUNDLE:       ItemType & 'ItemBundle'       = 'ItemBundle';
  public static CUSTOM_ITEM:       ItemType & 'CustomItem'       = 'CustomItem';

  public static get values(): ItemType[] {
    return [
      ItemTypes.SINGLE_ITEM,
      ItemTypes.ITEM_BUNDLE,
      ItemTypes.CUSTOM_ITEM,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ItemTypeEnum = typeof ItemTypes;
