/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ScheduledOrderFailure = 'temporary' | 'permanent';

export class ScheduledOrderFailures {
  public static TEMPORARY: ScheduledOrderFailure & 'temporary' = 'temporary';
  public static PERMANENT: ScheduledOrderFailure & 'permanent' = 'permanent';

  public static get values(): ScheduledOrderFailure[] {
    return [
      ScheduledOrderFailures.TEMPORARY,
      ScheduledOrderFailures.PERMANENT,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ScheduledOrderFailureEnum = typeof ScheduledOrderFailures;
