import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { boolObjectToStrings, stringsToBoolObject } from '@citypantry/util';
import {
  CuisineType,
  CuisineTypes,
  DietaryType,
  DietaryTypes,
  EventType,
  EventTypes,
  VendorFlag,
  VendorFlags
} from '@citypantry/util-models';
import { SearchFilters } from '../search-filters.model';

@Component({
  selector: 'app-search-filters-list',
  templateUrl: './search-filters-list.component.html',
  styleUrls: ['./search-filters-list.component.scss'],
  animations: [
    trigger('expand', [
      state('expanded', style({
        height: '*'
      })),
      state('closed', style({
        height: 0,
        overflow: 'hidden'
      })),
      transition('expanded => closed', [
        animate('0.4s')
      ]),
      transition('closed => expanded', [
        animate('0.4s')
      ]),
    ])
  ],
})
export class SearchFiltersListComponent implements OnInit, OnChanges {
  @Input()
  public isExpanded: boolean;

  @Input()
  public selectedFilters: SearchFilters;

  @Output()
  public selectedFiltersChange: EventEmitter<SearchFilters> = new EventEmitter();

  public visibleCuisineTypes: CuisineType[];
  public visibleEventTypes: EventType[];
  public visibleDietaryTypes: DietaryType[];
  public visibleVendorFlags: VendorFlag[];

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
    this.visibleCuisineTypes = CuisineTypes.values;
    this.visibleEventTypes = EventTypes.values;
    this.visibleDietaryTypes = DietaryTypes.values;
    this.visibleVendorFlags = [VendorFlags.ECO_FRIENDLY_PACKAGING, VendorFlags.EXCLUSIVE_TO_CP];
  }

  public ngOnInit(): void {
    this.updateForm();
  }

  public ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm(): void {
    if (this.selectedFilters) {
      if (!this.form) {
        this.buildForm();
      } else {
        this.form.setValue(this.createFormFields(), { emitEvent: false });
      }
    }
  }

  private buildForm(): void {
    const initialValue = this.createFormFields();

    this.form = this.fb.group({
      cuisines: this.fb.group(initialValue.cuisines),
      events: this.fb.group(initialValue.events),
      dietaries: this.fb.group(initialValue.dietaries),
      vendorFlags: this.fb.group(initialValue.vendorFlags),
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.selectedFiltersChange.emit(this.createSearchFiltersFromForm());
      }
    });
  }

  // component takes and emits arrays of strings, but internally uses { string: boolean } structures for the form
  private createFormFields(): {
    cuisines: { [key: string]: boolean };
    events: { [key: string]: boolean };
    dietaries: { [key: string]: boolean };
    vendorFlags: { [key: string]: boolean };
    } {
    return {
      cuisines: stringsToBoolObject(this.visibleCuisineTypes, this.selectedFilters.cuisines),
      events: stringsToBoolObject(this.visibleEventTypes, this.selectedFilters.events),
      dietaries: stringsToBoolObject(this.visibleDietaryTypes, this.selectedFilters.dietaries),
      vendorFlags: stringsToBoolObject(this.visibleVendorFlags, this.selectedFilters.vendorFlags),
    };
  }

  private createSearchFiltersFromForm(): SearchFilters {
    return {
      cuisines: boolObjectToStrings<CuisineType>(this.form.value.cuisines, CuisineTypes.values),
      events: boolObjectToStrings<EventType>(this.form.value.events, EventTypes.values),
      dietaries: boolObjectToStrings<DietaryType>(this.form.value.dietaries, DietaryTypes.values),
      vendorFlags: boolObjectToStrings<VendorFlag>(this.form.value.vendorFlags, VendorFlags.values),
    };
  }
}
