import { NgModule } from '@angular/core';
import { ScrollHandleDirective } from './scroll-handle.directive';
import { ScrollSpyDirective, ScrollSpyTargetDirective } from './scroll-spy.directive';
import { ScrollStateDirective } from './scroll-state.directive';

@NgModule({
  declarations: [
    ScrollHandleDirective,
    ScrollSpyDirective,
    ScrollSpyTargetDirective,
    ScrollStateDirective,
  ],
  exports: [
    ScrollHandleDirective,
    ScrollSpyDirective,
    ScrollSpyTargetDirective,
    ScrollStateDirective,
  ]
})
export class ScrollComponentsModule {}
