import { Component, EventEmitter, Input, Output } from '@angular/core';

import { numberOfVisibleDietariesSet, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-fixed-group-item',
  templateUrl: './fixed-group-item.component.html',
})
export class FixedGroupItemComponent {
  @Input()
  public item: SingleItem;

  @Input()
  public quantity: number;

  @Input()
  public set bundleCount(val: number) {
    this._bundleCount = val ? val : 1;
  }

  @Input()
  public showMoreInfo: boolean;

  @Output()
  public toggleMoreInfo: EventEmitter<void> = new EventEmitter();

  private _bundleCount = 1;

  public onToggleShowMoreInfo(): void {
    this.toggleMoreInfo.emit();
  }

  public calculateQuantity(): number {
    return this.quantity * this._bundleCount;
  }

  public hasDietaries(): boolean {
    return this.item && numberOfVisibleDietariesSet(this.item.dietaries) > 0;
  }

  public shouldShowKcals(): boolean {
    return this.item.kcal !== null && this.item.kcal >= 0;
  }
}
