<div
  class="cart-panel cart-panel--no-bottom-padding cart-panel--separator-above"
>

  <div class="cart-panel__content">
    <div
      class="line-item cart-text cart-text--title"
      test-id="subtotal"
      e2e-test-id="orderSubtotal"
    >
      Order Subtotal
      <div
        class="line-item__amount"
        e2e-test-id="itemAmount"
      >
        {{ itemsCost | currency:currencyCode }}
      </div>
    </div>
    <div
      *ngIf="minOrderWarning; else headcountLine"
      class="cart-text cart-text--warning"
      @slideInOut
      test-id="minOrderAmount"
      e2e-test-id="minOrderAmount"
    >
      <span
        class="d-inline-block"
        [@drama]="drama"
        (@drama.done)="dramaOver()"
      >
        Minimum order amount is {{ minOrderWarning.messageArgs.minOrderValue | currency:currencyCode }}
      </span>
    </div>
    <ng-template #headcountLine>
      <div
        class="cart-text line-item hide-short-screens"
        @slideInOut
        test-id="headcount"
      >
        {{ getHeadcount() }} {{ 'people' | plural:getHeadcount() }}

        <div class="line-item__amount">
          {{ getPricePerPerson() | currency:currencyCode }} / person
        </div>
      </div>
      <div
        *ngIf="getMissingHeadcount() > 0"
        class="cart-text cart-text--warning hide-short-screens"
        @slideInOut
        test-id="missingHeadcount"
      >
        You need {{ getMissingHeadcount() }} more {{ 'portion' | plural:getMissingHeadcount() }}!
      </div>
    </ng-template>
  </div>
</div>
