import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, ValidatorFn } from '@angular/forms';
import { sum } from '@citypantry/util';
import { CartCustomItemOption, CustomItemOption, CustomItemSection, numberOfVisibleDietariesSet } from '@citypantry/util-models';
import { CustomItemOptionType, CustomItemOptionTypes } from './custom-item-option-type.enum';

export interface OptionValue {
  optionIndex: CustomItemOption['optionIndex'];
  quantity: number;
}

@Component({
  selector: 'app-custom-item-modal-form-section',
  templateUrl: './custom-item-modal-form-section.component.html',
  styleUrls: ['./custom-item-modal-form-section.component.scss'],
})
export class CustomItemModalFormSectionComponent implements OnInit {
  @Input()
  public section: CustomItemSection;

  @Input()
  public selectedOptions: CartCustomItemOption[] = [];

  @Input()
  public wasSubmitted: boolean;

  @Input()
  public hidePrices: boolean;

  public sectionForm: FormGroup;

  constructor(
    @SkipSelf() private parent: FormGroupDirective,
    private fb: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.sectionForm = this.createSectionControl(this.section);
    (this.parent.form.get('customItemSections') as FormArray).push(this.sectionForm);
  }

  public getOptionsForm(index: number): FormGroup {
    return (this.sectionForm.controls.options as FormArray).controls[index] as FormGroup;
  }

  public getSelectedOptionsCount(sectionForm?: FormGroup): number {
    const form = sectionForm || this.sectionForm;

    return form.value.options.reduce(sum((({ quantity }: OptionValue) => quantity)), 0);
  }

  public shouldShowErrors(): boolean {
    return this.sectionForm.invalid && this.wasSubmitted;
  }

  public onToggleOption(option: CustomItemOption, checked: boolean): void {
    if (this.isQuantityOption()) {
      return; // quantity options should not be toggleable
    }

    const optionType = this.getOptionType();
    const currentValue = this.sectionForm.value.options;

    // goes through the list of options and checks or unchecks them
    // based on their type and new provided value
    const getUpdatedOptionQuantity = ({ optionIndex, quantity }: OptionValue): number => {
      switch (optionType) {
        case CustomItemOptionTypes.SINGLE_CHOICE: {
          // check/uncheck selected based on initial state, uncheck all others
          return (optionIndex === option.optionIndex && checked) ? 1 : 0;
        }
        case CustomItemOptionTypes.SINGLE_CHOICE_REQUIRED: {
          // check selected, uncheck all others
          return (optionIndex === option.optionIndex) ? 1 : 0;
        }
        case CustomItemOptionTypes.MULTIPLE_CHOICE: {
          // check/uncheck selected based on initial state, leave others as they were
          return (optionIndex === option.optionIndex) ? +checked : +quantity;
        }
        default: {
          return quantity;
        }
      }
    };

    const newValue = currentValue.map((optionValue: OptionValue) => ({
      ...optionValue, quantity: getUpdatedOptionQuantity(optionValue)
    }));
    this.sectionForm.controls.options.patchValue(newValue);
  }

  public isQuantityOption(): boolean {
    return this.getOptionType() === CustomItemOptionTypes.QUANTITY;
  }

  public hasDietaries(option: CustomItemOption): boolean {
    return numberOfVisibleDietariesSet(option.dietaries) > 0;
  }

  private getOptionType(): CustomItemOptionType {
    const { minOptions, maxOptions } = this.section;
    const areAllOptionsToggleable = this.areAllOptionsToggleable();

    if (minOptions === 0 && maxOptions === 1) {
      return CustomItemOptionTypes.SINGLE_CHOICE;
    } else if (minOptions === 1 && maxOptions === 1) {
      return CustomItemOptionTypes.SINGLE_CHOICE_REQUIRED;
    } else if (areAllOptionsToggleable && (!maxOptions || maxOptions > 1)) {
      return CustomItemOptionTypes.MULTIPLE_CHOICE;
    } else {
      return CustomItemOptionTypes.QUANTITY;
    }
  }

  private areAllOptionsToggleable(): boolean {
    return !this.section.options.some((option) => option.maxQuantity !== 1);
  }

  private createSectionControl(section: CustomItemSection): FormGroup {
    const sectionValidatorFn: ValidatorFn = (formGroup: FormGroup) => {
      if (this.getSelectedOptionsCount(formGroup) < section.minOptions) {
        return { minimumOptionsSelected: true };
      }
      if (section.maxOptions && this.getSelectedOptionsCount(formGroup) > section.maxOptions) {
        return { maximumOptionsSelected: true };
      }
      return null;
    };

    const options = new FormArray(
      (section.options || []).map((option) => this.createOptionControl(option)),
    );

    return new FormGroup({ options }, [sectionValidatorFn]);
  }

  private createOptionControl(option: CustomItemOption): FormGroup {
    const cartItemOption = this.selectedOptions && this.selectedOptions.find(
      (selectedOption) => selectedOption.optionIndex === option.optionIndex,
    );

    const optionValue: OptionValue = {
      // optionIndex field value is never modified in the form;
      // it's here to ensure we have a reference to the optionIndex without having to compare with the original item
      optionIndex: option.optionIndex,
      quantity: cartItemOption ? cartItemOption.quantity : 0,
    };

    return this.fb.group(optionValue);
  }
}
