import { NavigationExtras, Params } from '@angular/router';
import { createAction, props, union } from '@ngrx/store';

export const RouterActions = {
  go: createAction(
    '[Router] Go',
    props<{
      path: string | any[];
      query?: object;
      extras?: NavigationExtras;
    }>(),
  ),

  goExternal: createAction(
    '[Router] Go External',
    props<{ url: string }>(),
  ),

  goExternalInNewWindow: createAction(
    '[Router] Go External In New Window',
    props<{ url: string }>(),
  ),

  replace: createAction(
    '[Router] Replace',
    props<{
      path: string | any[];
      query?: object;
      extras?: NavigationExtras;
    }>(),
  ),

  // Changes query params without page reload
  query: createAction(
    '[Router] Query',
    props<{ params: Params, extras?: NavigationExtras }>(),
  ),

  hash: createAction(
    '[Router] Hash',
    props<{ fragment: string | null }>(),
  ),

  historyBack: createAction(
    '[Router] History Back',
  ),
};

const all = union(RouterActions);
export type RouterAction = typeof all;
