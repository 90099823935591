import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const vendorOrdersRules: AnalyticsRule[] = [
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-tab-content',
    events: {
      class: 'OrdersGridComponent',
      eventName: 'rateDriverClick'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Rate driver link`,
      extraFields: {
        orderId: event.event,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-tab-content',
    events: {
      class: 'OrdersGridComponent',
      eventName: 'actionsDropdownClick'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Actions Dropdown Toggle`,
      extraFields: {
        orderId: event.event.orderId,
        hasBeenOpened: event.event.hasBeenOpened,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-header tab-link',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Rate driver link`,
      extraFields: {
        tab: event.data['tab-link'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-grid first-page',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `First page`,
      extraFields: {
        page: event.data['first-page'].page,
        tab: event.data['vendor-orders-grid'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-grid previous-page',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Previous page`,
      extraFields: {
        page: event.data['previous-page'].page,
        tab: event.data['vendor-orders-grid'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-grid next-page',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Next page`,
      extraFields: {
        page: event.data['next-page'].page,
        tab: event.data['vendor-orders-grid'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-grid last-page',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Last page`,
      extraFields: {
        page: event.data['last-page'].page,
        tab: event.data['vendor-orders-grid'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-grid jump-page',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Jump to page`,
      extraFields: {
        page: event.data['jump-page'].page,
        tab: event.data['vendor-orders-grid'].tab,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-tab-content',
    events: {
      class: 'OrdersGridComponent',
      eventName: 'changeDateRange'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Set a date range filter`,
      extraFields: {
        dateFrom: event.event.from,
        dateTo: event.event.to,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-tab-content',
    events: {
      class: 'OrdersGridComponent',
      eventName: 'changePickupAddressIds'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Filter by pickup addresses`,
      extraFields: {
        pickupAddressIds: event.event,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-tab-content',
    events: {
      class: 'OrdersGridComponent',
      eventName: 'clearFilters'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Clear filters`,
    },
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-header',
    events: {
      class: 'OrdersHeaderComponent',
      eventName: 'changeHideCancelledOrders'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Toggle hide cancelled orders`,
      extraFields: {
        hide: event.event,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-header date-range-pill',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Remove filter by date range`,
      extraFields: {
        dateFrom: event.data['date-range-pill'].dateRange.from,
        dateTo: event.data['date-range-pill'].dateRange.to,
      }
    }),
  },
  {
    url: '/menus/vendor/orders',
    path: 'vendor-orders-header pickup-address-pill',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDERS_PAGE,
      label: `Remove filter by pickup address`,
      extraFields: {
        pickupAddressId: event.data['pickup-address-pill'].pickupAddressId,
      }
    }),
  },
];
