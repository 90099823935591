export interface DeliveryContact extends CreateDeliveryContactPayload {
  id: string;
}

export interface CreateDeliveryContactPayload {
  type: 'delivery-contact';
  firstName: string;
  lastName: string | null;
  fullName: string;
  phoneNumber: string | null;
  smsNotificationEnabled: boolean;
  email: string | null;
  emailNotificationEnabled: boolean;
}

export function createDeliveryContactFromJson(json?: Partial<DeliveryContact>): DeliveryContact {
  return {
    id: json.id,
    firstName: json.firstName,
    lastName: json.lastName,
    fullName: json.fullName,
    phoneNumber: json.phoneNumber,
    smsNotificationEnabled: json.smsNotificationEnabled,
    email: json.email,
    emailNotificationEnabled: json.emailNotificationEnabled,
    type: 'delivery-contact'
  };
}
