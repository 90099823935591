export interface SimpleCustomerLocation {
  id: string;
  name: string;
  postcode: string;
  addressLine1: string;
}

export function createSimpleCustomerLocationFromJson(json: Partial<SimpleCustomerLocation> = {}): SimpleCustomerLocation {
  const location: Partial<SimpleCustomerLocation> = {};
  location.id = json.id;
  location.name = json.name;
  location.postcode = json.postcode;
  location.addressLine1 = json.addressLine1;

  return location as SimpleCustomerLocation;
}
