import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState, FavouriteVendorsActions } from '@citypantry/state';
import { WindowRef } from '@citypantry/util-browser';
import { VendorId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class StoreFavouriteAfterAuthenticationGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
    private windowRef: WindowRef,
  ) {}

  public canActivate(): boolean {
    const pendingVendorAdd = this.windowRef.nativeWindow.sessionStorage.getItem('pendingVendorAdd');
    if (pendingVendorAdd) {
      this.store.dispatch(FavouriteVendorsActions.pageLoadedWithVendorToAddPresent({
        vendorId: pendingVendorAdd as any as VendorId
      }));
    }
    return true;
  }
}
