import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { OrderScheduleId, OrderScheduleType, OrderScheduleTypes } from '../order-schedule';

export type CalendarOrderSchedule = WeeklyCalendarOrderSchedule | never; // replace "never" with other types as appropriate

interface BaseCalendarOrderSchedule {
  id: OrderScheduleId;
  interval: number;
  endDate: moment.Moment;
  type: OrderScheduleType;
}
export interface WeeklyCalendarOrderSchedule extends BaseCalendarOrderSchedule {
  type: typeof OrderScheduleTypes.WEEKLY;
}
export function isWeeklyCalendarOrderSchedule(schedule: BaseCalendarOrderSchedule): schedule is WeeklyCalendarOrderSchedule {
  return schedule.type === OrderScheduleTypes.WEEKLY;
}

export function createCalendarOrderScheduleFromJson(json?: Partial<CalendarOrderSchedule>):
CalendarOrderSchedule {
  const schedule: Partial<CalendarOrderSchedule> = {};

  schedule.id = json.id as OrderScheduleId;
  schedule.interval = json.interval;
  schedule.endDate = json.endDate ? moment(json.endDate) : null;
  schedule.type = ensureValidEnumValue(OrderScheduleTypes, json.type);

  return schedule as CalendarOrderSchedule;
}
