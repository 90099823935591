import { Component } from '@angular/core';
import { DialogAbortEvent, DialogSettings } from '@citypantry/components-modal';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, Select } from '@citypantry/state';
import { deepDistinctUntilChanged } from '@citypantry/util';
import { ErrorMessage } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Alert } from './alert.model';
import { ErrorActions } from './error.actions';
import { ErrorSelectors } from './error.select';
import { ExpectedErrorTranslator } from './expected-errors.model';

@Component({
  selector: 'app-error',
  template: `
    <app-dialog
      [settings]="dialogSettings$ | async"
      test-id="dialog"
    >
      <p class="dialog__content m-none">
        {{ dialogContent$ | async }}
      </p>

      <p
        *ngIf="(error$ | async)?.rayId"
        class="dialog__content mt-large mb-none text-color-stone-2"
        test-id="rayId"
      >
        Ray ID: {{ (error$ | async)?.rayId }}
      </p>

      <div
        *ngIf="(isStaffOrSudo$ | async) && (error$ | async)?.context"
        class="mt-standard"
        test-id="errorContext"
      >
        <div class="text-weight-medium">Error context (visible to staff only):</div>
        <pre
          class="error-modal-layout__context panel text-size-small"
        >{{ (error$ | async)?.context | json }}</pre>
      </div>
    </app-dialog>`,
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Select(ErrorSelectors.getTopmostError)
  public error$: Observable<ErrorMessage | null>;

  @Select(AuthSelectors.isStaffOrSudo)
  public isStaffOrSudo$: Observable<boolean>;
  public dialogSettings$: Observable<DialogSettings | null>;
  public dialogContent$: Observable<string | null>;

  @Select(ErrorSelectors.getTopmostAlert)
  private alert$: Observable<Alert | null>;

  constructor(
    private store: Store<AppState>,
    private errorTranslator: ExpectedErrorTranslator,
  ) {
    const dialogSettingsWithContent$: Observable<DialogSettings | null> = combineLatest([
      this.error$,
      this.alert$,
    ]).pipe(
      map(([error, alert]: [ErrorMessage | null, Alert | null]): [ErrorMessage | null, Alert | null] => {
        // Weird filter to ensure we don't get two events when we already have an error but an alert comes in
        if (error) {
          return [error, null]; // Ignores any incoming alerts while errors are present
        } else {
          return [null, alert];
        }
      }),
      deepDistinctUntilChanged(),
      map(([error, alert]) => {
        if (error) {
          return this.getFatalErrorSettings(error);
        }
        if (alert) {
          return this.getAlertSettings(alert);
        }
        return null;
      }),
      distinctUntilChanged(),
    );

    this.dialogSettings$ = dialogSettingsWithContent$.pipe(
      map((dialogSettingsWithContent): DialogSettings => dialogSettingsWithContent
        ? { ...dialogSettingsWithContent, textContent: undefined }
        : null,
      ),
    );

    this.dialogContent$ = dialogSettingsWithContent$.pipe(
      map((dialogSettingsWithContent) => dialogSettingsWithContent && dialogSettingsWithContent.textContent),
    );
  }

  private getAlertSettings(alert: Alert): DialogSettings {
    return {
      title: alert.title,
      illustrativeIcon: 'warning',
      textContent: alert.text,
      hideCancelButton: true,
      hideCloseButton: true,
      overrideTitleStyle: 'text-center',
      onAbort: (event: DialogAbortEvent) => {
        if (event.source === 'outside') {
          event.cancel();
        }
      },
      onComplete: () => {
        this.store.dispatch(ErrorActions.dismissAlert({ alert }));
      }
    };
  }

  private getFatalErrorSettings(error: ErrorMessage): DialogSettings {
    const translation = this.errorTranslator.translate(error.key);

    return {
      illustrativeIcon: 'warning',
      title: translation.title,
      textContent: translation.message,
      confirmLabel: 'Refresh Page',
      fullWidth: true,
      overrideTitleStyle: 'text-center',
      onConfirm: () => {
        window.location.reload();
      },
      onAbort: (event: DialogAbortEvent) => {
        if (event.source === 'outside') {
          event.cancel();
          return;
        }
        this.store.dispatch(ErrorActions.dismissCurrentError());
      }
    };
  }
}
