import { Pipe, PipeTransform } from '@angular/core';

/**
 * Capitalise the first character in the string provided.
 */
@Pipe({
  name: 'capitalise'
})
export class CapitalisePipe implements PipeTransform {
  public transform(value: string): string {
    if (value === null || typeof value === 'undefined') {
      value = '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
