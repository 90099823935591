import moment from 'moment';
import { Image } from '../image.model';
import { createVendorFromJson, Vendor } from '../vendor/vendor.model';
import { createMenuAvailabilityFromJson, MenuAvailability } from './builder';
import { createDietariesFromJson, Dietaries, Item, ItemId } from './items';
import { createSectionFromJson, Section } from './section.model';

export interface Menu {
  id?: string;
  name: string;
  vendor: Vendor;
  draft: MenuContent | null;
  active: MenuContent | null; // The latest *approved* menu. Once a menu has one, it will always have one.
  pendingApproval?: moment.Moment;
  availabilities: MenuAvailability[];
}

export interface MenuContent {
  id?: string;
  version: number;
  heroImage?: Image;
  dietaries: Dietaries;
  sections: Section[];
  disclaimer?: string;
}

export function createMenuFromJson(json: Partial<Menu> = {}): Menu {
  const menu: Partial<Menu> = {};

  menu.id = json.id;
  menu.name = json.name || '';
  menu.vendor = createVendorFromJson(json.vendor);
  menu.draft = json.draft ? createMenuContentFromJson(json.draft) : null;
  menu.active = json.active ? createMenuContentFromJson(json.active) : null;
  menu.pendingApproval = json.pendingApproval ? moment(json.pendingApproval) : null;
  menu.availabilities = (json.availabilities || []).map(createMenuAvailabilityFromJson);

  return menu as Menu;
}

export function createMenuContentFromJson(json: Partial<MenuContent> = {}): MenuContent {
  const menuContent: Partial<MenuContent> = {};

  menuContent.id = json.id;
  menuContent.version = json.version;
  menuContent.dietaries = createDietariesFromJson(json.dietaries);
  menuContent.sections = (json.sections || []).map(createSectionFromJson);
  menuContent.heroImage = json.heroImage;
  menuContent.disclaimer = json.disclaimer;

  return menuContent as MenuContent;
}

/**
 * Returns an object with all top level items mapped by ID
 */
export function extractTopLevelItems(menuContent: MenuContent): { [itemId: string]: Item } {
  return menuContent.sections.reduce((items, section) =>
    section.items.reduce(
      (_items, item) => ({ ..._items, [item.id]: item }),
      items,
    ),
  {},
  );
}

export function filterMenuContent(menuContent: MenuContent, itemFilterFn: (item: Item) => boolean): MenuContent {
  const newSections = menuContent.sections.reduce((accumulator: Section[], oldSection: Section) => {
    const newItems = oldSection.items.filter(itemFilterFn);
    if (newItems.length) {
      accumulator.push({
        ...oldSection,
        items: newItems
      });
    }
    return accumulator;
  }, []);

  return {
    ...menuContent,
    sections: newSections
  };
}

export function findMenuItemById(menuContent: MenuContent, itemId: ItemId): Item | undefined {
  return menuContent.sections && menuContent.sections.reduce((items: Item[], section) => items.concat(section.items), [])
    .find((item) => item.id === itemId);
}
