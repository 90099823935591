import { createSelector } from '@ngrx/store';
import { getRecommendationsState } from '../app.state';
import { RecommendationState } from './recommendation.state';

const getRecommendedVendors = (state: RecommendationState) => state.recommendedVendors || null;
const getRecommendationRequest = (state: RecommendationState) => state.recommendationRequest || null;

export const RecommendationSelectors = {
  getRecommendedVendors:    createSelector(getRecommendationsState, getRecommendedVendors),
  getRecommendationRequest: createSelector(getRecommendationsState, getRecommendationRequest),
};
