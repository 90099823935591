import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextDropdownAnchorDirective } from './context-dropdown-anchor.directive';
import { ContextDropdownComponent } from './context-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ContextDropdownComponent,
    ContextDropdownAnchorDirective,
  ],
  exports: [
    ContextDropdownComponent,
    ContextDropdownAnchorDirective,
  ]
})
export class ContextDropdownModule {}
