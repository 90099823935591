/**
 * An error which ensures that switch statements are exhaustive.
 * Use as the default case, passing in the value into the constructor.
 *
 * @example
 * switch (value) {
 *   case SomeEnum.ONE_VALUE: ...
 *   case SomeEnum.OTHER_VALUE: ...
 *   default: throw new UnreachableCaseError(value); // Will compile as long as there are no extra values for MyEnum
 * }
 *
 * @see http://ideasintosoftware.com/exhaustive-switch-in-typescript/
 */
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}
