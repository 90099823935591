import { OrderId } from '../../order';
import { UserId } from '../../user-id.model';

export interface EaterFeedback {
  foodQuality: number;
  orderId: OrderId;
  publicComments: string | null;
  userId: UserId;
}

export function createEaterFeedbackFromJson(json?: Partial<EaterFeedback>): EaterFeedback {
  const feedback: Partial<EaterFeedback> = {};

  feedback.foodQuality = json.foodQuality ? json.foodQuality : null;
  feedback.orderId = json.orderId ? json.orderId as OrderId : null;
  feedback.publicComments = json.publicComments ? json.publicComments : null;
  feedback.userId = json.userId ? json.userId as UserId : null;

  return feedback as EaterFeedback;
}

export function hashEaterFeedbackByOrderId(eaterFeedback: (EaterFeedback | null)[]): { [key: string]: EaterFeedback } {
  return eaterFeedback.reduce((hashedFeedback: { [key: string]: EaterFeedback }, feedback: EaterFeedback) => {
    if (feedback) {
      hashedFeedback[feedback.orderId.toString()] = feedback;
    }
    return hashedFeedback;
  }, {});
}
