import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppConfig } from '@citypantry/shared-app-config';
import { WindowRef } from '@citypantry/util-browser';
import { EffectsModule } from '@ngrx/effects';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { Angulartics2Hubspot } from 'angulartics2/hubspot';
import { Angulartics2Segment } from 'angulartics2/segment';
import { AnalyticsLogger } from './analytics-logger.service';
import { AnalyticsEffects } from './analytics.effects';
import { GoogleAnalyticsInitializer } from './initializers/ga-initializer';
import { HubspotInitializer } from './initializers/hubspot-initializer';
import { SegmentInitializer } from './initializers/segment-initializer';
import { AnalyticsTrackingLogLevel, LogLevel } from './log-level.type';
import { addEaterToLocationModalRules } from './rules/add-eater-to-location-modal.rules';
import { AnalyticsRules } from './rules/analytics-rule.interface';
import { builderCreateItemDialogRules } from './rules/builder-create-item-dialog.rules';
import { chooseForEaterMenuPageRules } from './rules/choose-for-eater-menu-page.rules';
import { customItemFormPageRules } from './rules/custom-item-form-page.rules';
import { customerMenuPageRules } from './rules/customer-menu-page.rules';
import { customerOrderPageRules } from './rules/customer-order.rules';
import { customerRecommendationHomePageRules } from './rules/customer-recommendation-homepage.rules';
import { eaterMenuPageRules } from './rules/eater-menu-page.rules';
import { eaterSignUpPageRules } from './rules/eater-sign-up-page.rules';
import { editMealPlanMenuPageRules } from './rules/edit-meal-plan-menu-page.rules';
import { extrasPageRules } from './rules/extras-page.rules';
import { firstTopUpRewardExperimentRules } from './rules/first-top-up-voucher-reward-experiment.rules';
import { groupsEditPageRules } from './rules/groups-edit-page.rules';
import { groupsOverviewPageRules } from './rules/groups-overview-page.rules';
import { homePageRules } from './rules/home-page.rules';
import { individualChoiceEditDeliveryRules } from './rules/ic-checkout-edit-delivery.rules';
import { individualChoiceEditLocationRules } from './rules/ic-checkout-edit-location.rules';
import { individualChoiceNewLocationRules } from './rules/ic-checkout-new-location.rules';
import { individualChoiceCheckoutRules } from './rules/ic-checkout-payment.rules';
import { individualChoiceMenuRules } from './rules/ic-menu.rules';
import { individualChoiceSearchRules } from './rules/ic-search.rules';
import { itemBuilderPageRules } from './rules/item-builder-page.rules';
import { itemBundleFormPageRules } from './rules/item-bundle-form-page.rules';
import { justEatPayCodesPageRules } from './rules/just-eat-pay-codes-page.rules';
import { loginPageRules } from './rules/login-page.rules';
import { lostbarRules } from './rules/lostbar.rules';
import { marketingCampaignBannerRules } from './rules/marketing-campaign-banner.rules';
import { marketplaceSearchRules } from './rules/marketplace-search.rules';
import { mealIssuesModalRules } from './rules/meal-issues-modal.rules';
import { mealPlanMenuPageRules } from './rules/meal-plan-menu-page.rules';
import { mealPlanSearchRules } from './rules/meal-plan-search.rules';
import { megaFooterRules } from './rules/mega-footer.rules';
import { menuBuilderPageRules } from './rules/menu-builder-page.rules';
import { menuListPageRules } from './rules/menu-list-page.rules';
import { myMealsPageRules } from './rules/my-meals-page.rules';
import { myOrdersPageRules } from './rules/my-orders-page.rules';
import { orderTrackingPageRules } from './rules/order-tracking-page.rules';
import { popupSearchRules } from './rules/popup-search.rules';
import { promoBannerRules } from './rules/promo-banner.rules';
import { promotionPanelRules } from './rules/promotion-panel.rules';
import { qualificationFormRules } from './rules/qualification-form.rules';
import { searchModalRules } from './rules/search-modal.rules';
import { shareCartModalRules } from './rules/share-cart-modal.rules';
import { singleItemFormPageRules } from './rules/single-item-form-page.rules';
import { smilerFeedbackPageRules } from './rules/smiler-feedback-page.rules';
import { thankYouPageRules } from './rules/thank-you-page.rules';
import { topMenuRules } from './rules/top-menu.rules';
import { vendorItemsPageRules } from './rules/vendor-items-page.rules';
import { vendorOrderPageRules } from './rules/vendor-order-page.rules';
import { vendorOrdersRules } from './rules/vendor-orders.rules';
import { whoOrderedWhatPageRules } from './rules/wow-page.rules';
import { SegmentService } from './segment.service';
import { editIndividualChoiceSearchRules } from './rules/edit-ic-search.rules';
import { editIndividualChoiceMenuRules } from './rules/edit-ic-menu.rules';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    EffectsModule.forFeature([
      AnalyticsEffects
    ]),

    Angulartics2Module.forRoot([
      Angulartics2GoogleAnalytics,
      Angulartics2Segment,
      Angulartics2Hubspot,
    ], {
      pageTracking: {
        autoTrackVirtualPages: false
      }
    }),
  ],
  providers: [
    Angulartics2GoogleAnalyticsEnhancedEcommerce,
    SegmentService,
    SegmentInitializer,
    AnalyticsLogger,
    {
      provide: AnalyticsTrackingLogLevel,
      useValue: 'none',
    },

    {
      provide: AnalyticsRules,
      multi: true,
      useValue: homePageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceCheckoutRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceSearchRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: marketplaceSearchRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: searchModalRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: popupSearchRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: mealPlanSearchRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: customerRecommendationHomePageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: promoBannerRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: marketingCampaignBannerRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: promotionPanelRules
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: megaFooterRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: menuBuilderPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: menuListPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: topMenuRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: qualificationFormRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: whoOrderedWhatPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: eaterMenuPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: chooseForEaterMenuPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: extrasPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: customerMenuPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: mealPlanMenuPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: editMealPlanMenuPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: builderCreateItemDialogRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: itemBuilderPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: singleItemFormPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: customItemFormPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: itemBundleFormPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: myOrdersPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: customerOrderPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: orderTrackingPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: smilerFeedbackPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: vendorOrderPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: shareCartModalRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: thankYouPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: vendorOrdersRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: vendorItemsPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceMenuRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceEditLocationRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceEditDeliveryRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: individualChoiceNewLocationRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: eaterSignUpPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: addEaterToLocationModalRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: myMealsPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: mealIssuesModalRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: loginPageRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: justEatPayCodesPageRules
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: groupsOverviewPageRules
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: groupsEditPageRules
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: lostbarRules
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: editIndividualChoiceSearchRules,
    },
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: editIndividualChoiceMenuRules,
    },
    // TODO CPD-14799: remove or clean up depending on the first-top-up-reward-voucher experiment outcome
    {
      provide: AnalyticsRules,
      multi: true,
      useValue: firstTopUpRewardExperimentRules,
    },
  ]
})
export class AnalyticsTrackingModule {
  constructor(
    private appConfig: AppConfig,
    private segmentInitializer: SegmentInitializer,
    private gaInitializer: GoogleAnalyticsInitializer,
    private hubspotInitializer: HubspotInitializer,
    private windowRef: WindowRef,

    // We're injecting all angulartics2 providers to ensure their constructors are called and tracking functions
    // subscribed but never explicitly using their instances - hence the ignore rules below. Ciao.
    // @ts-ignore
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    // @ts-ignore
    private angulartics2Segment: Angulartics2Segment,
    // @ts-ignore
    private angulartics2Hubspot: Angulartics2Hubspot,
  ) {
    this.initProviders();
  }

  /**
   * Configures the module with some extra parameters.
   * This is not required but allows setting the log level.
   *
   * @see LogLevel for a list of all allowed log levels.
   */
  public static forRoot(config?: { logLevel: LogLevel }): ModuleWithProviders<AnalyticsTrackingModule> {
    return {
      ngModule: AnalyticsTrackingModule,
      providers: [
        {
          provide: AnalyticsTrackingLogLevel,
          useValue: config && config.logLevel || LogLevel.NONE,
        },
      ],
    };
  }

  private initProviders(): void {
    if (this.windowRef.nativeWindow.hasOwnProperty('ga')) {
      this.gaInitializer.run(this.windowRef.nativeWindow.ga);
    }

    if (this.windowRef.nativeWindow.hasOwnProperty('analytics')) {
      this.segmentInitializer.run();
    }

    if (this.appConfig.HUBSPOT_ENABLED === true) {
      this.hubspotInitializer.run();
    }
  }
}
