declare const OrderIdSymbol: unique symbol;
export type OrderId = string & {
  [OrderIdSymbol]: never;
};

export function getOrderIdFromUrlPath(path: string): OrderId | null {
  if (path !== null) {
    const match = path.match(/\/([a-z0-9]{24})(?=$|\/)/);
    return match ? (match[1] as unknown as OrderId) : null;
  }
  return null;
}
