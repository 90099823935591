export interface IndividualChoiceEligibility {
  customerEligible: boolean;
  menuEligible: boolean;
  sufficientNotice: boolean;
  noticePeriodHours: number;
}

export function createIndividualChoiceEligibilityFromJson(
  json: Partial<IndividualChoiceEligibility> = {}
): IndividualChoiceEligibility {
  const eligibilityPartial: Partial<IndividualChoiceEligibility> = {};

  eligibilityPartial.customerEligible = json.customerEligible;
  eligibilityPartial.menuEligible = json.menuEligible;
  eligibilityPartial.sufficientNotice = json.sufficientNotice;
  eligibilityPartial.noticePeriodHours = json.noticePeriodHours;

  return eligibilityPartial as IndividualChoiceEligibility;
}
