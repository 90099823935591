import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { WindowRef } from '@citypantry/util-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RouterAction, RouterActions } from './router.actions';

function ensureArray(path: string | any[]): any[] {
  return Array.isArray(path) ? path as any[] : [path];
}

@Injectable()
export class RouterEffects {

  public navigate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.go),
    tap((action: ReturnType<typeof RouterActions.go>) => {
      const { path, query: queryParams, extras} = action;
      /* eslint-disable-next-line ban/ban */ // This is the only place router.navigate is allowed
      this.router.navigate(ensureArray(path), { queryParams, ...extras });
    })), { dispatch: false });

  public replace$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.replace),
    tap((action: ReturnType<typeof RouterActions.replace>) => {
      const { path, query: queryParams, extras} = action;
      /* eslint-disable-next-line ban/ban */ // This is the only place router.navigate is allowed
      this.router.navigate(ensureArray(path), { queryParams, ...extras, replaceUrl: true });
    })), { dispatch: false });

  public navigateExternal$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.goExternal),
    tap((action: ReturnType<typeof RouterActions.goExternal>) => {
      this.document.location.href = action.url;
    })), { dispatch: false });

  public navigateExternalInNewWindow$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.goExternalInNewWindow),
    tap(({ url }: ReturnType<typeof RouterActions.goExternalInNewWindow>) => {
      this.window.nativeWindow.open(url);
    }),
  ), { dispatch: false });

  public changeQuery$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.query),
    tap((action: ReturnType<typeof RouterActions.query>) => {
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);
      urlTree.queryParams = action.params;
      const newUrl = this.router.serializeUrl(urlTree);
      if (this.router.url !== newUrl) {
        this.router.navigateByUrl(urlTree, action.extras);
      }
    })), { dispatch: false });

  public changeFragment$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.hash),
    tap((action: ReturnType<typeof RouterActions.hash>) => {
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);
      urlTree.fragment = action.fragment;
      this.router.navigateByUrl(urlTree);
    })), { dispatch: false });

  public historyBack$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.historyBack),
    tap(() => {
      this.window.nativeWindow.history.back();
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions<RouterAction>,
    private router: Router,
    private window: WindowRef,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
}
