/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type AnalyticsEcommerceEventId =
  'menuItemQuantityPicker'
  | 'menuItemBundleAddButton'
  | 'menuItemBundleModalSubmitButton'
  | 'menuCartItemRemoveButton'
  | 'menuCustomItemAddButton'
  | 'menuCustomItemQuantityPicker';

export class AnalyticsEcommerceEventIds {
  public static MENU_ITEM_QUANTITY_PICKER:              AnalyticsEcommerceEventId & 'menuItemQuantityPicker'          = 'menuItemQuantityPicker';
  public static MENU_ITEM_BUNDLE_ADD_BUTTON:            AnalyticsEcommerceEventId & 'menuItemBundleAddButton'         = 'menuItemBundleAddButton';
  public static MENU_ITEM_BUNDLE_MODAL_SUBMIT_BUTTON:   AnalyticsEcommerceEventId & 'menuItemBundleModalSubmitButton' = 'menuItemBundleModalSubmitButton';
  public static MENU_CART_ITEM_REMOVE_BUTTON:           AnalyticsEcommerceEventId & 'menuCartItemRemoveButton'        = 'menuCartItemRemoveButton';
  public static MENU_CUSTOM_ITEM_ADD_BUTTON:            AnalyticsEcommerceEventId & 'menuCustomItemAddButton'         = 'menuCustomItemAddButton';
  public static MENU_CUSTOM_ITEM_QUANTITY_PICKER:       AnalyticsEcommerceEventId & 'menuCustomItemQuantityPicker'    = 'menuCustomItemQuantityPicker';

  public static get values(): AnalyticsEcommerceEventId[] {
    return [
      AnalyticsEcommerceEventIds.MENU_ITEM_QUANTITY_PICKER,
      AnalyticsEcommerceEventIds.MENU_ITEM_BUNDLE_ADD_BUTTON,
      AnalyticsEcommerceEventIds.MENU_ITEM_BUNDLE_MODAL_SUBMIT_BUTTON,
      AnalyticsEcommerceEventIds.MENU_CART_ITEM_REMOVE_BUTTON,
      AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_ADD_BUTTON,
      AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_QUANTITY_PICKER,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type AnalyticsEcommerceEventIdEnum = typeof AnalyticsEcommerceEventIds;
