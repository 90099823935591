/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromRouter from '@ngrx/router-store';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import { AccountState, reducer as accountReducer } from './account/account.state';
import { CheckoutState, reducer as checkoutReducer } from './checkout/checkout.state';
import { CreateStaffUserState, reducer as createStaffUserReducer } from './create-staff-user/create-staff-user.state';
import {
  CustomerAdvancedBudgetingState,
  reducer as customerAdvancedBudgetingReducer
} from './customer-advanced-budgeting/customer-advanced-budgeting.state';
import { EaterState, reducer as eaterReducer } from './eater/eater.state';
import {
  FavouriteVendorsState,
  reducer as favouriteVendorsReducer,
} from './favourite-vendors/favourite-vendors.state';
import { HeaderState, reducer as headerReducer } from './header/header.state';
import {
  IndividualChoiceSetupState,
  reducer as individualChoiceSetupReducer,
} from './individual-choice-setup/individual-choice-setup.state';
import {
  IndividualChoiceState,
  reducer as individualChoiceReducer
} from './individual-choice/individual-choice.state';
import { PublicState, reducer as publicReducer } from './public/public.state';
import { RecommendationState, reducer as recommendationReducer } from './recommendation/recommendation.state';
import { RouterStateUrl } from './router/router-state-serializer';
import { SearchRecommendationState, reducer as searchRecommendationsReducer } from './search-recommendation/search-recommendation.state';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  'public': PublicState;
  eater: EaterState;
  favouriteVendors: FavouriteVendorsState;
  individualChoice: IndividualChoiceState;
  account: AccountState;
  individualChoiceSetup: IndividualChoiceSetupState;
  recommendations: RecommendationState;
  searchRecommendations: SearchRecommendationState;
  createStaffUser: CreateStaffUserState;
  checkout: CheckoutState;
  advancedBudgeting: CustomerAdvancedBudgetingState;
  header: HeaderState;
}

export type AppStateSelector<T> = (state: AppState) => T;

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together.
 * Remember that compose applies the result from right to left.
 */
export const reducers = {
  router: fromRouter.routerReducer,
  public: publicReducer,
  eater: eaterReducer,
  favouriteVendors: favouriteVendorsReducer,
  individualChoice: individualChoiceReducer,
  account: accountReducer,
  individualChoiceSetup: individualChoiceSetupReducer,
  recommendations: recommendationReducer,
  searchRecommendations: searchRecommendationsReducer,
  createStaffUser: createStaffUserReducer,
  checkout: checkoutReducer,
  advancedBudgeting: customerAdvancedBudgetingReducer,
  header: headerReducer,
};

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */
export const getPublicState = (state: AppState): PublicState => state.public;
export const getRouterState = (state: AppState) => state.router;
export const getEaterState = createFeatureSelector<EaterState>('eater');
export const getFavouriteVendorsState = createFeatureSelector<FavouriteVendorsState>('favouriteVendors');
export const getRecommendationsState = createFeatureSelector<RecommendationState>('recommendations');
export const getIndividualChoiceState = (state: AppState) => state.individualChoice;
export const getAccountState = (state: AppState) => state.account;
export const getIndividualChoiceSetupState = (state: AppState) => state.individualChoiceSetup;
export const getSearchRecommendationsState = createFeatureSelector<SearchRecommendationState>('searchRecommendations');
export const getCreateStaffUserState = createFeatureSelector<CreateStaffUserState>('createStaffUser');
export const getCheckoutState = createFeatureSelector<CheckoutState>('checkout');
export const getCustomerAdvancedBudgetingState = createFeatureSelector<CustomerAdvancedBudgetingState>('advancedBudgeting');
export const getHeaderState = createFeatureSelector<HeaderState>('header');
