<form
  *ngIf="form"
  class="custom-item-modal-form-layout"
  [formGroup]="form"
  novalidate
>
  <div
    *ngIf="shouldShowIncompleteSectionsErrors()"
    class="sub-text text-color-red-1 d-flex align-items-center mb-large"
  >
    <i class="icon pr-small">warning</i>
    <span test-id="incompleteSectionError">
      <ng-container *ngTemplateOutlet="incompleteSectionErrors"></ng-container>
    </span>
  </div>

  <div class="container px-none">
    <app-custom-item-modal-form-section
      *ngFor="let section of item.sections;"
      [section]="section"
      [selectedOptions]="getCartItemSelectedOptions()"
      [wasSubmitted]="wasSubmitted"
      [hidePrices]="hidePrices"
      test-id="customSection"
    ></app-custom-item-modal-form-section>
  </div>

  <h3 class="sub-header--bold">Allergens and dietary requirements</h3>

  <div class="additional-item-info__details mb-large">
    <p class="my-none" test-id="ingredients">
      <ng-container *ngIf="item.baseItemProvided; else withoutBase">
        <strong>Base Ingredients:</strong>
        {{ item.ingredients.length ? (item.ingredients | concat) : 'Not available' }}
        <span
          *ngIf="item.baseItemAlcoholPercentage"
          class="d-block text-color-stone-2"
        >ABV: {{ item.baseItemAlcoholPercentage }}%</span>
      </ng-container>
      <ng-template #withoutBase>
        <strong>Ingredients:</strong>
        {{ !getSelectedOptions().length ? 'Not available' : '' }}
      </ng-template>
    </p>
    <p
      *ngFor="let option of getSelectedOptions()"
      class="mb-none mt-small"
      test-id="optionIngredients"
    >
      <strong>{{ option.name }}:</strong> {{ option.ingredients.length ? (option.ingredients | concat) : 'Not available' }}
      <span
        *ngIf="option.alcoholPercentage"
        class="d-block text-color-stone-2"
      >ABV: {{ option.alcoholPercentage }}%</span>
    </p>
  </div>
  <p
    *ngIf="shouldShowAllergens()"
    class="additional-item-info__details mb-large"
  >
    <strong>Allergens: </strong>
    <span test-id="allergens">{{ getAggregatedAllergens() | allergenNameList:'; ' }}</span>
  </p>
  <p class="additional-item-info__details mb-large">
    <strong>Suitable for: </strong>
    <span
      *ngIf="isItemAgeRestricted()"
      test-id="ageRestrictedText"
    >People aged 18+, </span>
    <span
      test-id="dietariesTextList"
    >{{ getAggregatedDietaries() | dietNameList }}</span>
  </p>
  <p
    *ngIf="shouldShowKcal()"
    class="additional-item-info__details mb-large"
  >
    <strong>Calories: </strong>
    <span
      test-id="kcal"
    >{{ calculateKcalTotal() }}kcal</span>
  </p>
  <p
    *ngIf="isItemAgeRestricted() || hasDietaries()"
    class="additional-item-info__details d-flex mb-massive"
  >
    <span
      *ngIf="isItemAgeRestricted()"
      class="icon icon--medium"
      aria-label="Age restricted"
      test-id="ageRestrictedIcon"
    >age_restricted</span>
    <app-dietaries
      *ngIf="hasDietaries()"
      class="vertical-separator"
      [dietaries]="getAggregatedDietaries()"
      test-id="dietariesComponent"
    ></app-dietaries>
  </p>

  <div class="item-modal__footer">
    <app-item-modal-footer
      [subtotal]="calculateItemTotal()"
      [hidePrices]="hidePrices"
      (cancel)="onCancel()"
      test-id="footer"
    >
      <app-quantity-picker
        item-modal-footer-slot="quantity"
        [analyticsItem]="item"
        [analyticsEcommerceEventId]="AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_QUANTITY_PICKER"
        [min]="item.minimumOrderQuantity"
        [max]="canOverrideQuantities ? 0 : item.maximumOrderQuantity"
        [formControl]="form.get('quantity')"
        test-id="quantityPicker"
      ></app-quantity-picker>

      <ng-container item-modal-footer-slot="errors">
        <div
          *ngIf="shouldShowQuantityErrors()"
          class="col-5 ml-auto text-right"
          test-id="quantityPickerError"
        >
          <div
            [errorSwitch]="form.controls.quantity.errors"
            class="sub-text text-color-red-1 mt-small"
          >
            <ng-container *errorCase="'min'">
              You must select at least {{ item.minimumOrderQuantity }} of this item
            </ng-container>
            <ng-container *errorCase="'max'">
              You cannot select more than {{ item.maximumOrderQuantity }} of this item
            </ng-container>
            <ng-container *errorCase="'default'">
              There is a problem with the quantity of this item
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="shouldShowIncompleteSectionsErrors()"
          class="col-5 ml-auto text-right"
          test-id="footerIncompleteSectionError"
        >
          <div
            class="sub-text text-color-red-1 mt-small"
          >
            <ng-container *ngTemplateOutlet="incompleteSectionErrors"></ng-container>
          </div>
        </div>
      </ng-container>

      <button
        item-modal-footer-slot="submitButton"
        type="button"
        class="button button--primary"
        (click)="onSubmitItem()"
        [analyticsItem]="item"
        ecommerceEventTrigger="click"
        [ecommerceActionType]="AnalyticsEcommerceActions.ADD"
        [ecommerceEventId]="AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_ADD_BUTTON"
        test-id="submitButton"
      >
        {{ cartItem ? 'Update in cart' : 'Add to cart' }}
      </button>
    </app-item-modal-footer>
  </div>
</form>

<ng-template #incompleteSectionErrors>
  You must choose options for
  <ng-container
    *ngFor="let incompleteSection of incompleteSections; let i=index; let isLast=last;"
  >
    ‘<span class="text-weight-bold">{{ incompleteSection.name }}</span>’ {{ !isLast ? i === incompleteSections.length - 2 ? ' and ' : ', ' : null }}
  </ng-container>
</ng-template>
