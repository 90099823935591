import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { WindowRef } from '@citypantry/util-browser';
import { Store } from '@ngrx/store';
import { asyncScheduler, combineLatest, Observable, SchedulerLike, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SegmentUserIdentification } from './segment-user-identification';

@Injectable()
export class SegmentService {
  private ready$: Subject<void>;

  constructor(
    private appConfig: AppConfig,
    private windowRef: WindowRef,
    private store: Store<AppState>
  ) {
    this.ready$ = new Subject();
  }

  public init(): void {
    this.analytics.load(this.appConfig.SEGMENT_ID);
    this.analytics.ready(() => this.ready$.next());
  }

  public isReady(): Observable<void> {
    return this.ready$.asObservable();
  }

  public setupUserIdentificationSubscription(scheduler: SchedulerLike = asyncScheduler): void {
    combineLatest([
      this.store.select(AuthSelectors.getUserId),
      this.store.select(AuthSelectors.getUserName),
      this.store.select(AuthSelectors.getUserEmail),
      this.store.select(AuthSelectors.getUserHumanId),
      this.store.select(AuthSelectors.isStaffOrSudo),
      this.store.select(AuthSelectors.isSudo),
    ]).pipe(
      map(([userId, name, email, humanId, isStaffOrSudo, isSudo]) => {
        return {
          id: userId || '',
          properties: {
            name: name || '',
            email: email || '',
            humanId: humanId ? `${ humanId }` :  '',
            isStaff: isStaffOrSudo,
            isMasqueraded: isSudo
          },
        };
      }),
      debounceTime(0, scheduler), // debounce to prevent having multiple changes in the same frame
    ).subscribe((userIdentification: SegmentUserIdentification) => {
      this.analytics.identify(
        userIdentification.id,
        userIdentification.properties
      );
    });
  }

  // We need to be always fetching the latest 'analytics' object from nativeWindow
  // If we just fetch it once and reuse it, there is the high risk that
  // we are not dealing with the real 'analytics' object, but rather with the
  // placeholder set up by Segment while waiting for the lib to load
  private get analytics(): SegmentAnalytics.AnalyticsJS {
    return this.windowRef.nativeWindow.analytics as SegmentAnalytics.AnalyticsJS;
  }
}
