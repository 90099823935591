import { Injectable } from '@angular/core';
import { MenuApi } from '@citypantry/shared-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CompanyHolidaysActions } from './company-holidays.actions';

@Injectable()
export class CompanyHolidaysEffects {

  public loadCompanyHolidays$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(CompanyHolidaysActions.loadCompanyHolidays.type),
    switchMap(() => this.menuApi.getCompanyHolidays()),
    map((holidays: Moment[]) => CompanyHolidaysActions.companyHolidaysLoaded({ holidays }))
  ));

  constructor(
    private action$: Actions,
    private menuApi: MenuApi
  ) {}
}
