import { createAction, union } from '@ngrx/store';

export const HeaderActions = {
  toggleNav: createAction(
    '[Header] Toggle Nav'
  ),
};

const all = union(HeaderActions);
export type HeaderAction = typeof all;
