/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ScreenSize = 'mobile' | 'phablet' | 'tablet' | 'laptop' | 'desktop';

export class ScreenSizes {
  public static MOBILE:  ScreenSize & 'mobile'  = 'mobile';
  public static PHABLET: ScreenSize & 'phablet' = 'phablet';
  public static TABLET:  ScreenSize & 'tablet'  = 'tablet';
  public static LAPTOP:  ScreenSize & 'laptop'  = 'laptop';
  public static DESKTOP: ScreenSize & 'desktop' = 'desktop';

  public static get values(): ScreenSize[] {
    return [
      ScreenSizes.MOBILE,
      ScreenSizes.PHABLET,
      ScreenSizes.TABLET,
      ScreenSizes.LAPTOP,
      ScreenSizes.DESKTOP,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ScreenSizeEnum = typeof ScreenSizes;
