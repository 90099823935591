import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const vendorItemsPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/items',
    path: 'vendor-items-page preview-item',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ITEMS_PAGE,
      label: 'Preview item',
      extraFields: {
        itemId: event.data['preview-item'].itemId,
      }
    }),
  },
  {
    url: '/menus/builder/items',
    path: 'vendor-items-page edit-item',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ITEMS_PAGE,
      label: 'Edit item',
      extraFields: {
        itemId: event.data['edit-item'].itemId,
      }
    }),
  },
];
