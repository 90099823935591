import { ensureValidEnumValue } from '@citypantry/util';
import { MenuContentStatus, MenuContentStatuses } from '../menu/builder/menu-content-status.enum';

export interface MenuContentSummary {
  menuId: string;
  menuContentId: string;
  name: string;
  status: MenuContentStatus;
}

export function createMenuContentSummaryFromJson(json: Partial<MenuContentSummary>): MenuContentSummary {
  const summary: Partial<MenuContentSummary> = {};

  summary.menuId = json.menuId;
  summary.menuContentId = json.menuContentId;
  summary.name = json.name;
  summary.status = ensureValidEnumValue(MenuContentStatuses, json.status);

  return summary as MenuContentSummary;
}
