import { CreateStaffUserAction, CreateStaffUserActions } from './create-staff-user.actions';

export interface CreateStaffUserState {
  isSubmitting: boolean;
}

export const INITIAL_STATE: CreateStaffUserState = {
  isSubmitting: false,
};

export function reducer(state: CreateStaffUserState = INITIAL_STATE, action: CreateStaffUserAction): CreateStaffUserState {
  switch (action.type) {
    case CreateStaffUserActions.submitForm.type:
      return {
        ...state,
        isSubmitting: true,
      };
    case CreateStaffUserActions.userCreateSuccess.type:
    case CreateStaffUserActions.userCreateFailure.type:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
}
