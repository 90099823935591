import { createMixedCostFromJson, MixedCost } from '../../money/mixed-cost.model';
import { CostBreakdown, createCostBreakdownFromJson } from '../../order/cost-breakdown.model';

export interface EaterCostBreakdown extends CostBreakdown {
  totalEaterCost: MixedCost;
}

export function createEaterCostBreakdownFromJson(json?: Partial<EaterCostBreakdown>): EaterCostBreakdown {
  json = json || {};

  const eaterCostBreakdown: Partial<EaterCostBreakdown> = createCostBreakdownFromJson(json);
  eaterCostBreakdown.availableBudget = json.availableBudget;
  eaterCostBreakdown.totalEaterCost = createMixedCostFromJson(json.totalEaterCost);

  return eaterCostBreakdown as EaterCostBreakdown;
}
