import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const orderTrackingPageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/order-tracking/:orderId/*',
    path: 'smile-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.ORDER_TRACKING_PAGE,
      label: 'Feedback link - positive',
    },
  },
  {
    url: '/menus/customer/order-tracking/:orderId/*',
    path: 'frown-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.ORDER_TRACKING_PAGE,
      label: 'Feedback link - negative',
    },
  },
];
