import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const smilerFeedbackPageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/orders/:orderId/feedback',
    path: 'star-rating',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FEEDBACK_PAGE,
      label: `Food rating chosen`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId/feedback',
    path: 'food-tag',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.FEEDBACK_PAGE,
      action: AnalyticsActions.CLICK,
      label: `Food tag ${event.event.target.checked ? 'selected' : 'deselected'}`,
    })
  },
  {
    url: '/menus/customer/orders/:orderId/feedback',
    path: 'smile-rating',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FEEDBACK_PAGE,
      label: `Delivery rating chosen`,
    },
  },
  {
    url: '/menus/customer/orders/:orderId/feedback',
    path: 'delivery-tag',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.FEEDBACK_PAGE,
      action: AnalyticsActions.CLICK,
      label: `Delivery tag ${event.event.target.checked ? 'selected' : 'deselected'}`,
    })
  },
];
