import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { CalendarDataSource, DatePickerComponent, RangeWithCustomCssClass } from '@citypantry/components-date-picker';
import { AppConfig } from '@citypantry/shared-app-config';
import { AppState } from '@citypantry/state';
import { DateRange, IsoDate, mapmap, safeUnsubscribe } from '@citypantry/util';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CompanyHolidaysSelectors } from '../company-holidays/company-holidays.select';

export const UPDATE_TIME_PERIOD_MS = 10000;

export interface RefreshedData {
  opsTodayDates: IsoDate[];
  today: IsoDate;
}

export interface CpDatePickerCalendarData {
  minDay: IsoDate | undefined;
  disabledDays: DateRange[];
  daysWithCustomCssClass: RangeWithCustomCssClass[];
}

/**
 * This directive implements the interface CalendarDataSource. Upon init, it attaches itself
 * to the host date picker component, and it becomes the single source of truth for the inputs
 * "minDay", "maxDay", "disabledDays" and "daysWithCustomCssClass".
 *
 * The values of those inputs are filled and kept up to date by three subscriptions
 * (except for maxDay which is always empty)
 *
 * Usage: <app-date-picker cpDatePicker></app-date-picker>
 */
@Directive({
  selector: 'app-date-picker[cpDatePicker]',
})
export class CpDatePickerDirective implements CalendarDataSource, OnDestroy, OnInit {
  public minDay: IsoDate | undefined;
  public maxDay: IsoDate | undefined;
  public disabledDays: DateRange[] = [];
  public daysWithCustomCssClass: RangeWithCustomCssClass[] = [];
  public data$: Observable<CpDatePickerCalendarData>;

  private dataSubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private appConfig: AppConfig,
    private datePicker: DatePickerComponent,
    private store: Store<AppState>
  ) {
    this.maxDay = undefined;

    const companyHolidays$: Observable<IsoDate[]> = this.store.select(CompanyHolidaysSelectors.getAll).pipe(
      mapmap(IsoDate.fromMoment),
    );

    const refreshedData$: Observable<RefreshedData> = timer(0, UPDATE_TIME_PERIOD_MS).pipe(
      share(),
      map(() => moment.tz('Europe/London')),
      map((now: Moment) => this.computeRefreshedData(now))
    );

    this.data$ = combineLatest([companyHolidays$, refreshedData$]).pipe(
      map(([companyHolidays, refreshedData]: [IsoDate[], RefreshedData]) => this.buildLatestData(companyHolidays, refreshedData)),
    );
  }

  public ngOnInit(): void {
    this.dataSubscription = this.data$.subscribe((data: CpDatePickerCalendarData) => {
      this.minDay = data.minDay;
      this.disabledDays = data.disabledDays;
      this.daysWithCustomCssClass = data.daysWithCustomCssClass;

      this.cdr.markForCheck();
    });

    // This is the single most important line of this directive: it replaces with itself
    // the "calendarDataSource" of the host date picker component
    this.datePicker.setCalendarDataSource(this);
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.dataSubscription);
  }

  private buildLatestData(companyHolidays: IsoDate[], refreshedData: RefreshedData): CpDatePickerCalendarData {
    const holidaysToRangeWithCustomCssClass: RangeWithCustomCssClass[] = companyHolidays.map(
      (singleDate: IsoDate) => RangeWithCustomCssClass.ofSingleDay(singleDate, 'dp-tooltip dp-tooltip--no-delivery')
    );
    const opsTodayDateToRangeWithCustomCssClass: RangeWithCustomCssClass[] = refreshedData.opsTodayDates.map(
      (singleDate: IsoDate) => RangeWithCustomCssClass.ofSingleDay(singleDate, 'dp-tooltip dp-tooltip--same-day')
    );

    const minDay: IsoDate = refreshedData.today;
    const disabledDays: DateRange[] = companyHolidays.concat(refreshedData.opsTodayDates).map(
      (singleDate: IsoDate) => DateRange.ofSingleDay(singleDate)
    );
    const daysWithCustomCssClass = holidaysToRangeWithCustomCssClass.concat(opsTodayDateToRangeWithCustomCssClass);

    return { minDay, disabledDays, daysWithCustomCssClass };
  }

  private computeRefreshedData(now: Moment): RefreshedData {
    const dates: IsoDate[] = [];

    // Push now to the disabled days if we're past the latest order hours or same day delivery is not enabled.
    if (now.hour() >= this.appConfig.LATEST_ORDER_HOURS || !this.appConfig.SAME_DAY_DELIVERY_ENABLED) {
      dates.push(IsoDate.fromMoment(now));
    }

    return {
      opsTodayDates: dates,
      today: IsoDate.fromMoment(now),
    };
  }
}
