import { Injectable } from '@angular/core';
import { MealPlanApi } from '@citypantry/shared-api';
import { PublicActions } from '@citypantry/state-public';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class MealPlanEffects {

  public fetchMealPlanForOrder$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.fetchMealPlanForOrder.type,
    ),
    switchMap(({ mealPlanId, proposedOrderId }) => {
      return this.mealPlanApi.getMealPlan(mealPlanId).pipe(
        switchMap((mealPlan) => {
          const order = mealPlan.proposedOrders.find(({ id }) => id === proposedOrderId);
          if (!order) {
            throw new Error(`Expected to find order ID "${proposedOrderId}" in meal plan "${mealPlanId}" but could not find it`);
          }
          return of(PublicActions.mealPlanLoaded({ mealPlan, proposedOrderId }));
        })
      );
    })
  ));

  constructor(
    private action$: Actions,
    private mealPlanApi: MealPlanApi,
  ) {
  }
}
