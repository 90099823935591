import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomItem, isCustomItem, isSingleItem, numberOfAllergensSet, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-additional-item-info',
  templateUrl: './additional-item-info.component.html',
  styles: [':host { width: 100% }']
})
export class AdditionalItemInfoComponent {
  @Input()
  public item: SingleItem | CustomItem;

  @Input()
  public showDescription: boolean;

  @Input()
  public showPerServing: boolean;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  /* eslint-disable @typescript-eslint/typedef */ // These are okay to be inferred. We don't want to redeclare them
  public isSingleItem = isSingleItem;
  public isCustomItem = isCustomItem;
  /* eslint-enable */

  public shouldShowAllergens(): boolean {
    return this.item && numberOfAllergensSet(this.item.allergens) > 0;
  }

  public shouldShowAgeRestricted(): boolean {
    return this.item && this.item.ageRestricted;
  }

  public onClose(): void {
    this.close.emit();
  }

  public shouldShowCustomItemAlcoholPercentage(item: CustomItem): boolean {
    return !!item.baseItemAlcoholPercentage
      || item.sections.some((section) =>
        section.options.some((option) => !!option.alcoholPercentage)
      );
  }
}
