<div class="search-params-header search-params-header-layout">
  <div class="container">
    <div class="row align-items-center search-params-header-layout__wrapper">
      <div class="search-params-header__params col-12 col-lg-8 my-standard m-lg-0">
        <span *ngIf="searchRequest?.postcode || (location && date)" >
          <b
            class="search-params-header__vendor-count"
            test-id="vendorCount"
          >
            {{ resultCount }}
          </b>
          {{ 'restaurant' | plural:resultCount }} can deliver to
          <button
            class="search-params-header__button"
            (click)="changeParams.emit()"
            test-id="postcodeInput"
            analyticsId="edit-postcode"
          >{{ location?.name ? (location.name | truncate: 10) : searchRequest?.postcode }}</button>
          on
          <button
            class="search-params-header__button"
            (click)="changeParams.emit()"
            test-id="dateInput"
            analyticsId="edit-delivery-date"
            e2e-test-id="searchParamsDateInput"
          >{{ date | dateInEuropeLondon:'dddd, Do MMM' }}</button>
          at
          <button
            class="search-params-header__button"
            (click)="changeParams.emit()"
            test-id="timeInput"
            analyticsId="edit-delivery-time"
          >{{ date | deliveryWindow }}</button>
        </span>
      </div>
      <div class="col-12 col-lg-4 mb-standard m-lg-0">
        <label class="search-input ml-lg-standard">
          <input
            #search
            type="search"
            class="search-input__input"
            placeholder="Search for a dish or restaurant"
            (enter)="search.blur()"
            (blur)="queryChange.emit(search.value)"
            [value]="query || ''"

            analyticsId="search-query"
            test-id="search"
            e2e-test-id="vendorSearchInput"
          >
          <button
            class="search-input__button"
            type="button"
            (click)="search.blur()"
            test-id="submitSearch"
            e2e-test-id="vendorSearchButton"
          >
            <span class="text-hide">Search</span>
          </button>
        </label>
      </div>
    </div>
  </div>
</div>
