import { Injectable } from '@angular/core';
import { AnalyticsActions, AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { ErrorReporterService } from './error-reporter.service';
import { ErrorAction, ErrorActions } from './error.actions';

@Injectable()
export class ErrorEffects {

  public reportError$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ErrorActions.logError.type),
    map(({ error, showToUser }) => this.errorReporterService.reportError(error, showToUser)),
    mapTo(undefined),
  ), { dispatch: false });

  public trackErrorMessage$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ErrorActions.showErrorMessage.type),
    tap(({ error }) => this.analyticsService.trackEvent(AnalyticsActions.FAILURE, {
      category: AnalyticsCategories.ERROR_MODAL,
      label: `Error modal displayed - ${error.key}`,
    })),
    mapTo(undefined),
  ), { dispatch: false });

  constructor(
    private action$: Actions<ErrorAction>,
    private errorReporterService: ErrorReporterService,
    private analyticsService: AnalyticsService,
  ) {}
}
