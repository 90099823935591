<div
  class="search-popup-header-layout"
  backgroundImage="/l/hubfs/citypantry.com%20pop-up%20search%20header/header-background.jpeg"
  analyticsId="popup-search-header"
>
  <div class="search-popup-header-layout__container">
    <h1 class="search-popup-header-layout__title headline mb-x-large text-color-white">Just Eat for Business Pop-ups</h1>
    <div class="search-popup-header-layout__content">
      <div class="search-popup-header-layout__button-wrapper m-small">
        <a
          (click)="onClickScroller()"
          class="button button--white button--full-width"
          analyticsId="scroll-to-content"
        >
          Explore options
        </a>
      </div>

      <div class="search-popup-header-layout__button-wrapper m-small">
        <a
          class="button button--secondary button--full-width"
          target="_blank"
          href="/l/pop-ups"
          analyticsId="more-info"
        >
          Find out more
        </a>
      </div>

    </div>
  </div>
</div>
