/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type EventType =
  'Pantry Packages'
  | 'Hampers'
  | 'DIY Boxes'
  | 'Breakfast'
  | 'Lunch'
  | 'Dinner'
  | 'Meeting'
  | 'Canapés'
  | 'Afternoon Tea'
  | 'Christmas'
  | 'Desserts'
  | 'Drinks'
  | 'Easter'
  | 'Friday Feast'
  | 'Finger Food'
  | 'Party'
  | 'Snacks'
  | 'Pancake Day'
  | 'Halloween'
  | 'Thanksgiving';

export class EventTypes {
  public static PANTRY_PACKAGES: EventType & 'Pantry Packages' = 'Pantry Packages';
  public static HAMPERS:         EventType & 'Hampers'         = 'Hampers';
  public static DIY_BOXES:       EventType & 'DIY Boxes'       = 'DIY Boxes';
  public static BREAKFAST:       EventType & 'Breakfast'       = 'Breakfast';
  public static LUNCH:           EventType & 'Lunch'           = 'Lunch';
  public static DINNER:          EventType & 'Dinner'          = 'Dinner';
  public static MEETING:         EventType & 'Meeting'         = 'Meeting';
  public static CANAPES:         EventType & 'Canapés'         = 'Canapés';
  public static AFTERNOON_TEA:   EventType & 'Afternoon Tea'   = 'Afternoon Tea';
  public static CHRISTMAS:       EventType & 'Christmas'       = 'Christmas';
  public static DESSERTS:        EventType & 'Desserts'        = 'Desserts';
  public static DRINKS:          EventType & 'Drinks'          = 'Drinks';
  public static EASTER:          EventType & 'Easter'          = 'Easter';
  public static FRIDAY_FEAST:    EventType & 'Friday Feast'    = 'Friday Feast';
  public static FINGER_FOOD:     EventType & 'Finger Food'     = 'Finger Food';
  public static PARTY:           EventType & 'Party'           = 'Party';
  public static SNACKS:          EventType & 'Snacks'          = 'Snacks';
  public static PANCAKE_DAY:     EventType & 'Pancake Day'     = 'Pancake Day';
  public static HALLOWEEN:       EventType & 'Halloween'       = 'Halloween';
  public static THANKSGIVING:    EventType & 'Thanksgiving'    = 'Thanksgiving';

  public static get values(): EventType[] {
    return [
      EventTypes.PANTRY_PACKAGES,
      EventTypes.HAMPERS,
      EventTypes.DIY_BOXES,
      EventTypes.BREAKFAST,
      EventTypes.LUNCH,
      EventTypes.DINNER,
      EventTypes.MEETING,
      EventTypes.CANAPES,
      EventTypes.AFTERNOON_TEA,
      EventTypes.CHRISTMAS,
      EventTypes.DESSERTS,
      EventTypes.DRINKS,
      EventTypes.EASTER,
      EventTypes.FRIDAY_FEAST,
      EventTypes.FINGER_FOOD,
      EventTypes.PARTY,
      EventTypes.SNACKS,
      EventTypes.PANCAKE_DAY,
      EventTypes.HALLOWEEN,
      EventTypes.THANKSGIVING,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type EventTypeEnum = typeof EventTypes;
