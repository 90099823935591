<div class="card card--banner">
  <i class="material-icons mr-small">warning</i>
  <span class="sub-text" test-id="subText">
    <b>Delivery Disruption</b> to London’s transport network is expected on the
    <b>{{ date | dateInEuropeLondon:'Do MMM' }}</b>. We are doing everything we can to mitigate against this,
    but deliveries from all services may be delayed. We recommend
    <a
      *ngIf="showAsLink"
      (click)="chooseDeliveryTime()"
      test-id="chooseDeliveryLink"
    >choosing as early a delivery time as possible</a>
    <u
      *ngIf="!showAsLink"
      test-id="chooseDeliveryText"
    >choosing as early a delivery time as possible</u>.
  </span>
</div>
