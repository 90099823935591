import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isDateOnWeekend } from '@citypantry/util';
import moment from 'moment';

function timeInListAndDateOnWeekend(dateControl: AbstractControl, availableTimes: number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isTimeAvailable = control.value && availableTimes.indexOf(control.value) >= 0;
    if (!isTimeAvailable && dateControl.value && isDateOnWeekend(moment.tz(dateControl.value, 'YYYY-MM-DD', 'Europe/London'))) {
      return {
        timeInListAndDateOnWeekend: control.value,
      };
    }
    return null;
  };
}

export {
  timeInListAndDateOnWeekend,
};
