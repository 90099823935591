import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { createCustomerLocationFromJson, CustomerLocation } from '../customer';
import { MajorCurrency } from '../money';
import { createOrderScheduleFromJson, OrderSchedule } from '../order-schedule';
import { OrderId } from '../order/order-id.model';
import { OrderStatus, OrderStatuses } from '../order/order-status.enum';
import { PaymentTerm, PaymentTerms } from '../order/payment-term.enum';
import { ChoiceDeadline, ChoiceDeadlines } from './choice-deadline.enum';
import { createIndividualChoiceOrderFromJson, IndividualChoiceOrder } from './individual-choice-order.model';

export interface IndividualChoiceOrderGroup {
  id: OrderId; // TODO CPD-4116: change to OrderGroupId and move into /models?
  budget: MajorCurrency;
  location: CustomerLocation;
  operationalRegion: string;
  choiceOpenTime: moment.Moment | null;
  choiceDeadline: moment.Moment;
  orderDeadline: moment.Moment;
  choiceDeadlineType: ChoiceDeadline;
  orders: IndividualChoiceOrder[];
  expectedHeadcount: number | null;
  status: OrderStatus;
  worryFreePeriodEndDate: moment.Moment;
  hasWorryFreePeriodEndDatePassed: boolean;
  isSubsidisedChoice: boolean;
  // We cannot determine the currency code for OrderGroups that don't have any child orders set in this case the api returns null.
  // For this reason do not rely on currencyIsoCode of OrderGroups in areas where orders could be empty for example the IC setup carts.
  // Future ticket concerned with IC setup carts: CPD-9596
  currencyIsoCode: string | null;
  cartsAdditionalReferenceRequired: boolean;
  schedule: OrderSchedule | null;
  paymentStatus: string;
  paymentTerm: PaymentTerm | null;
  last4OfPaymentCard: string | null;
  canBeRepeated: boolean;
  useHiddenBudget: boolean;
  orderIdsMarkedReadyForCollectionByCustomer: OrderId[];
  useDeskNumbers: boolean;
  requestedDeliveryDate: moment.Moment;
}

// fromJson function

export function createIndividualChoiceOrderGroupFromJson(json?: Partial<IndividualChoiceOrderGroup>): IndividualChoiceOrderGroup {
  json = json || {};

  const orderGroup: Partial<IndividualChoiceOrderGroup> = {};

  orderGroup.id = json.id;
  orderGroup.budget = json.budget;
  orderGroup.location = createCustomerLocationFromJson(json.location);
  orderGroup.operationalRegion = json.operationalRegion;
  orderGroup.choiceOpenTime = json.choiceOpenTime ? moment(json.choiceOpenTime) : null;
  orderGroup.choiceDeadline = json.choiceDeadline ? moment(json.choiceDeadline) : null;
  orderGroup.choiceDeadlineType = ensureValidEnumValue(ChoiceDeadlines, json.choiceDeadlineType);
  orderGroup.orderDeadline = json.orderDeadline ? moment(json.orderDeadline) : null;
  orderGroup.orders = (json.orders || []).map(createIndividualChoiceOrderFromJson);
  orderGroup.expectedHeadcount = json.expectedHeadcount !== undefined ? json.expectedHeadcount : null;
  orderGroup.status = ensureValidEnumValue(OrderStatuses, json.status);
  orderGroup.worryFreePeriodEndDate = json.worryFreePeriodEndDate ? moment(json.worryFreePeriodEndDate) : null;
  orderGroup.hasWorryFreePeriodEndDatePassed = !!json.hasWorryFreePeriodEndDatePassed;
  orderGroup.isSubsidisedChoice = !!json.isSubsidisedChoice;
  orderGroup.currencyIsoCode = json.currencyIsoCode;
  orderGroup.cartsAdditionalReferenceRequired = !!json.cartsAdditionalReferenceRequired;
  orderGroup.schedule = json.schedule ? createOrderScheduleFromJson(json.schedule) : null;
  orderGroup.paymentStatus = json.paymentStatus;
  orderGroup.paymentTerm = json.paymentTerm ? PaymentTerms.mapApiValueToEnum(json.paymentTerm) : null;
  orderGroup.last4OfPaymentCard = json.last4OfPaymentCard;
  orderGroup.canBeRepeated = !!json.canBeRepeated;
  orderGroup.useHiddenBudget = !!json.useHiddenBudget;
  orderGroup.orderIdsMarkedReadyForCollectionByCustomer = json.orderIdsMarkedReadyForCollectionByCustomer || [];
  orderGroup.useDeskNumbers = json.useDeskNumbers;
  orderGroup.requestedDeliveryDate = json.requestedDeliveryDate ? moment.tz(json.requestedDeliveryDate, 'Europe/London') : null;

  return orderGroup as IndividualChoiceOrderGroup;
}

export function getLargestHeadcountFromOrders(orderGroup: IndividualChoiceOrderGroup): number {
  return Math.max(0, ...orderGroup.orders.map((order) => order.expectedHeadcount));
}
