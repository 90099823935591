import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatetimeComponentsModule } from '@citypantry/components-datetime';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { DeliveryDisruptionBannerComponent } from './delivery-disruption-banner';
import { MarketingCampaignBannerComponent } from './marketing-campaign-banner/marketing-campaign-banner.component';
import { PromotionBannerComponent } from './promotion-banner/promotion-banner.component';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsComponentsModule,
    DatetimeComponentsModule,
  ],
  declarations: [
    DeliveryDisruptionBannerComponent,
    PromotionBannerComponent,
    MarketingCampaignBannerComponent,
  ],
  exports: [
    DeliveryDisruptionBannerComponent,
    PromotionBannerComponent,
    MarketingCampaignBannerComponent,
  ],
})
export class PageBannerComponentsModule {}
