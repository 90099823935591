<h3 class="sub-header--bold">Allergens and dietary requirements</h3>
<div class="additional-item-info__details mb-large">
  <p
    class="my-none"
    test-id="ingredients"
  >
    <strong>Ingredients:</strong>
    {{ item.ingredients.length ? (item.ingredients | concat) : 'Not available' }}
    <span
      *ngIf="item.alcoholPercentage"
      class="d-block text-color-stone-2"
      test-id="alcoholPercentage"
    >ABV: {{ item.alcoholPercentage }}%</span>
  </p>
</div>
<p
  class="additional-item-info__details mb-large"
  *ngIf="shouldShowAllergens()"
  test-id="allergensBlock"
>
  <strong>Allergens: </strong>
  <span test-id="allergens">{{ item.allergens | allergenNameList:'; ' }}</span>
</p>
<p class="additional-item-info__details mb-large">
  <strong>Suitable for: </strong>
  <span
    *ngIf="item.ageRestricted"
    test-id="ageRestrictedText"
  >People aged 18+, </span>
  <span
    test-id="dietariesTextList"
  >{{ item.dietaries | dietNameList }}</span>
</p>
<p
  *ngIf="item.kcal === 0 || item.kcal > 0"
  class="additional-item-info__details mb-large"
  test-id="kcal"
>
  <strong>Calories: </strong>
  <span
    test-id="kcal"
  >{{ item.kcal }} kcal</span>
</p>
<p
  *ngIf="item.ageRestricted || shouldShowDietaries()"
  class="additional-item-info__details d-flex mb-massive"
>
  <span
    *ngIf="item.ageRestricted"
    class="icon icon--medium"
    aria-label="Age restricted"
    test-id="ageRestrictedIcon"
  >age_restricted</span>
  <app-dietaries
    *ngIf="shouldShowDietaries()"
    class="vertical-separator"
    [dietaries]="item.dietaries"
    test-id="dietariesComponent"
  ></app-dietaries>
</p>
