<div class="row align-items-baseline">
  <div class="col-xl-3 col-lg-12">
    <div class="sub-header text-color-charcoal" test-id="subHeader">
      {{ title }}
    </div>
    <div class="body-text text-color-stone-1 text-weight-medium" test-id="bodyText">
      <!-- The variable datetime and the HTML banner-datetime are alternative -->
      <div *ngIf="datetime" class="d-none d-xl-block">
        {{ datetime | dateInEuropeLondon : 'HH:mm' }} <br>
        {{ datetime | dateInEuropeLondon : 'ddd, D MMM YYYY' }}
      </div>
      <div class="d-xl-none">
        {{ datetime | dateInEuropeLondon : 'HH:mm, ddd, D MMM YYYY' }}
      </div>
      <!-- DATE AND TIME -->
      <ng-content select="[banner-datetime]"></ng-content>
    </div>
  </div>
  <div class="col-xl-9 col-lg-12">
    <div class="row text-banner text-color-stone-1">
      <div *ngIf="showBannerBody" [ngClass]="showBannerButton ? 'col-7' : 'col-12'" test-id="textBanner">
        <!-- BANNER BODY -->
        <ng-content select="[banner-body]"></ng-content>
      </div>
      <div
        *ngIf="showBannerButton"
         class="col-5"
         test-id="bannerButton"
       >
        <!-- BANNER BUTTON -->
        <ng-content select="[banner-button]"></ng-content>
      </div>
    </div>
  </div>
</div>
