import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  Optional,
  Renderer2
} from '@angular/core';
import { AnalyticsDataDirective } from './analytics-data.directive';
import { AnalyticsEcommerceAction } from './analytics-ecommerce-action.enum';
import { AnalyticsEcommerceContextDirective } from './analytics-ecommerce-context.directive';
import { AnalyticsEcommerceEventId } from './analytics-ecommerce-event-id.enum';

/**
 * For an Ecommerce event to fire, two directives are required on ancestor components
 * - AnalyticsEcommerceContextDirective (the page it should fire on)
 * - AnalyticsItemProvider (the SingleItem | ItemBundle analytics is tracking)
 *
 * The closest implementations of the 2 directives will be injected into this AnalyticsEnhancedEcommerceDirective.
 * This is to remove the requirement for @Input/@Output and dispatch methods in dumb components
 * whilst making content specific, item specific analytics calls possible.
 */
@Directive({
  selector: '[ecommerceEventTrigger]'
})
export class AnalyticsEnhancedEcommerceDirective implements AfterContentInit {

  /**
   * HTML DOM event that triggers GA enhanced ecommerce method call
   */
  @Input()
  public ecommerceEventTrigger: string;

  @Input()
  public ecommerceActionType: AnalyticsEcommerceAction;

  @Input()
  public ecommerceEventId: AnalyticsEcommerceEventId;

  private unsubscribeFromRenderer: () => void;

  constructor(
    @Optional() private analyticsContext: AnalyticsEcommerceContextDirective,
    @Optional() private analyticsDataProvider: AnalyticsDataDirective,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngAfterContentInit(): void {
    if (this.unsubscribeFromRenderer) {
      this.unsubscribeFromRenderer();
    }

    this.unsubscribeFromRenderer = this.renderer.listen(
      this.elRef.nativeElement,
      this.ecommerceEventTrigger,
      () => {

        // All inputs are required
        if (this.analyticsContext && this.analyticsDataProvider && this.ecommerceEventId) {
          this.triggerAnalytics();
        }
      },
    );
  }

  public triggerAnalytics(): void {
    this.analyticsContext.handleEnhancedEcommerceEvent(
      this.analyticsDataProvider.analyticsItem,
      this.ecommerceActionType,
      this.ecommerceEventId
    );
  }
}
