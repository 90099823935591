<app-sticky-cart
  class="cart-layout"
  offset="menu"
  test-id="stickyCart"
  analyticsId="cart"
>
  <div
    class="cart-header"
    test-id="header"
  >
    <p class="heading-2 cart-header__title">
      Your Order
    </p>

    <div
      *ngIf="requiredHeadcount > 0"
      class="cart-header__content"
      test-id="headcount"
    >
      <i class="cart-header__icon icon icon-users"></i>
      {{ requiredHeadcount }} {{ 'people' | plural:requiredHeadcount }}
    </div>
  </div>

  <div
    *ngIf="!cart.cartItems.length"
    class="cart-panel cart-panel--fixed-height cart-panel--center cart-panel--separator-below"
    test-id="emptyMessage"
    @slideInOut
  >
    <div class="cart-panel__content text-center">
      <div class="cart-text cart-text--title">
        Your basket is empty
      </div>
      <div class="cart-text cart-text--light cart-text--spaced">
        <ng-container *ngIf="minOrderValue > 0; else noMinimumOrder">
          Minimum order amount {{ minOrderValue | currency:cart.currencyIsoCode }}
        </ng-container>
        <ng-template #noMinimumOrder>
          Add an item to get started
        </ng-template>
      </div>
    </div>
  </div>

  <app-cart-scroll-panel
    *ngIf="cart.cartItems.length"
    test-id="cartItemList"
  >
    <app-cart-item
      *ngFor="let cartItem of cart.cartItems; let cartIndex = index; trackBy: trackCartItem"
      [cartItem]="cartItem"
      [hasError]="isCartItemInvalid(cartItem)"
      [analyticsItem]="cartItem.item"
      [cartItemAdjustmentMessage]="getCartItemMessage(cartIndex)"
      [canOverrideQuantity]="isStaffOrSudo"
      [currencyCode]="cart.currencyIsoCode"
      @removeItem
      (remove)="removeCartItem(cartIndex)"
      (setItemQuantity)="setCartItemQuantity(cartItem, $event)"
      (edit)="editBundle(cartItem)"
      (itemNameClick)="onItemNameClicked(cartItem)"
      (quantityAdjusted)="onCartItemQuantityAdjustment(cartIndex, $event)"
      test-id="cartItems"
    ></app-cart-item>
  </app-cart-scroll-panel>

  <app-cart-subtotal
    *ngIf="cart.cartItems.length"
    @slideInOut
    [itemsCost]="cart.price.items"
    [headcount]="cart.headcount"
    [requiredHeadcount]="requiredHeadcount"
    [minOrderWarning]="getMinOrderWarning()"
    [currencyCode]="cart.currencyIsoCode"
    test-id="subtotal"
  ></app-cart-subtotal>

  <app-cart-delivery
    [deliveryCost]="cart.price.delivery"
    [deliveryVat]="cart.price.deliveryVat"
    [serviceFeeNet]="cart.price.serviceFee"
    [serviceFeeVat]="cart.price.serviceFeeVat"
    [showCost]="cart.cartItems.length > 0"
    [locationNameOrPostcode]="cart.postcode"
    [deliveryDate]="cart.deliveryDate"
    [deliveryNoticeHours]="deliveryNoticeHours"
    [showDeliveryNotice]="cart.cartItems.length === 0"
    [locked]="disableEditingParams"
    [isFreeDelivery]="cart.isFreeDelivery"
    (editDelivery)="editDeliveryParams()"
    [currencyCode]="cart.currencyIsoCode"
    test-id="deliveryDetails"
  ></app-cart-delivery>

  <div
    *ngIf="cart.cartItems.length"
    class="cart-panel cart-panel--separator-above"
    @slideInOut
  >
    <div class="cart-panel__content">
      <app-cart-total
        [amount]="isValid ? cartTotal : null"
        [priceChange]="priceChange"
        [currencyCode]="cart.currencyIsoCode"
        test-id="totalPanel"
      ></app-cart-total>
    </div>
  </div>

  <app-cart-notes
    *ngIf="cart.cartItems.length"
    [notes]="cart.notes"
    [includeCutlery]="cart.includeCutlery"
    [isCutleryAvailable]="vendor.providesCutlery"
    (updateNotes)="onNotesUpdated($event)"
    (setCutlery)="onCutleryChanged($event)"
    test-id="notes"
  ></app-cart-notes>

  <div
    class="cart-panel cart-panel--inset cart-panel--line-above"
    *ngIf="showVendorCapacityError()"
    test-id="vendorCapacityError"
  >
    <div class="cart-panel__content d-flex flex-row align-items-center">
      <span class="pr-small">
        <i class="material-icons text-color-orange-2">error</i>
      </span>
      <span class="text-color-orange-2 text-size-small">
        This restaurant is over capacity for your headcount - please
        <a
          class="text-color-orange-2"
          routerLink="/menus/search"
          test-id="vendorCapacityErrorLink"
        >choose another restaurant</a>.
      </span>
    </div>
  </div>

  <div
    class="cart-panel cart-panel--inset cart-panel--line-above"
    *ngIf="showNextDayOrderingError()"
    test-id="nextDayOrderingError"
  >
    <div class="cart-panel__content d-flex flex-row align-items-center">
      <span class="pr-small">
        <i class="material-icons text-color-orange-2">error</i>
      </span>
      <span
        class="text-color-orange-2 text-size-small"
        test-id="nextDayOrderingErrorText"
      >
        Unable to place an order for tomorrow, as it is now after {{ latestOrderHours | formatTimeFromHours }}.
      </span>
    </div>
  </div>

  <div
    *ngIf="cart.cartItems.length"
    class="cart-panel cart-panel--line-above"
    @slideInOut
  >
    <div class="cart-panel__content cart-layout__order-actions">
      <span
        class="cart-layout__order-now-button-wrapper"
        [tooltip]="getSubmitButtonTooltip()"
        tooltipPosition="top"
        test-id="checkoutButtonDisabledTooltip"
      >
        <button
          class="button button--full-width button--primary cart-layout__order-now-button"
          [class.button--admin]="isStaffOrSudo"
          [disabled]="shouldDisableOrderButton()"
          (mouseenter)="onCheckoutButtonHover()"
          (click)="onSubmit()"
          analyticsId="submit"
          test-id="checkoutButton"
          e2e-test-id="checkoutButton"
        >
          <span
            class="circle-spinner mr-2"
            *ngIf="status !== CartStatuses.IDLE"
            test-id="checkingButtonSpinner"
          ></span>
          {{ getSubmitButtonText() }}
        </button>
      </span>

      <app-context-dropdown
        *ngIf="canDownloadQuote || shouldShowDownloadMenuPdf() || shouldShowShareCart()"
        align="right"
        #orderActionsDropdown="dropdown"
        test-id="orderActionsDropdown"
      >
        <button
          context-dropdown-anchor
          class="material-icon-button material-icon-button--ghost ml-medium-small"
          (click)="orderActionsDropdown.toggle()"
          aria-label="Cart Actions"
          aria-haspopup="true"
          analyticsId="more-actions"
          test-id="moreButton"
        >
          more_horiz
        </button>

        <ul class="context-actions">
          <li
            *ngIf="shouldShowShareCart()"
            class="context-actions__item"
          >
            <button
              class="context-actions__action"
              (click)="onRequestShareCartLink()"
              analyticsId="share-cart"
              test-id="shareCartButton"
            >
              <i
                class="context-actions__icon icon"
                aria-hidden="true"
              >
                share
              </i>
              Share a copy of this cart
            </button>
          </li>
          <li
            *ngIf="canDownloadQuote"
            class="context-actions__item"
          >
            <button
              class="context-actions__action"
              [disabled]="shouldDisableOrderButton()"
              (click)="onRequestQuotePdf()"
              analyticsId="download-quote"
              test-id="downloadQuoteButton"
            >
              <i
                class="context-actions__icon icon"
                aria-hidden="true"
              >
                cloud_download
              </i>
              Download Quote
            </button>
          </li>
          <li
            *ngIf="shouldShowDownloadMenuPdf()"
            class="context-actions__item"
          >
            <button
              class="context-actions__action"
              (click)="onRequestMenuPdf()"
              analyticsId="download-pdf"
              test-id="menuPdfButton"
            >
              <i
                class="context-actions__icon icon"
                aria-hidden="true"
              >
                cloud_download
              </i>
              Download Menu PDF
            </button>
          </li>
        </ul>
      </app-context-dropdown>
    </div>
  </div>

  <button
    *ngIf="showWarning()"
    class="cart-warning-panel hide-short-screens"
    (click)="onWarningClicked()"
    @slideInOut
    test-id="warning"
  >
    <i class="material-icons cart-warning-panel__icon" aria-hidden="true">warning</i>
    There are issues with this cart
  </button>

  <div
    @slideInOut
    test-id="individualChoiceEligibility"
  >
    <div
      class="cart-panel cart-panel--line-above"
      *ngIf="!showIndividualChoiceToggle && individualChoiceEligibility && !individualChoiceEligibility.sufficientNotice"
      test-id="individualChoiceRequiresMoreNotice"
    >
      <div class="cart-panel__content text-center">
        <div class="cart-text cart-text--title">Want to let colleagues choose their own food?</div>
        <div class="cart-text cart-text--light mt-x-small">
          This restaurant needs at least <strong>{{ individualChoiceEligibility.noticePeriodHours }} hours</strong> of notice for
          <span class="text-nowrap">Group Ordering</span> orders
        </div>
      </div>
    </div>

    <!-- Until Individual Choice search is supported on mobile, we hide the individual choice toggle on smaller screens -->
    <div *ngIf="showIndividualChoiceToggle"
         class="d-none d-md-block cart-panel cart-panel--separator-above">
      <div class="cart-panel__content py-medium-small">
        <app-cart-toggle
          [toggle]="false"
          (toggleChange)="onEnterIndividualChoice()"
          analyticsId="individual-choice-toggle"
          test-id="individualChoiceToggle"
        >
          <span class="toggle-input__label cart-text cart-text--title text-underline">
            Group Ordering Mode
          </span>
        </app-cart-toggle>
      </div>
    </div>
  </div>

  <div *ngIf="showWeWorkToggle"
       class="cart-panel cart-panel--separator-above">
    <div class="cart-panel__content py-medium-small">
      <app-cart-toggle
        [toggle]="isWeWorkModeEnabled"
        (toggleChange)="onChangeWeWorkMode($event)"
        test-id="weWorkModeToggle"
      >
        <span class="toggle-input__label cart-text cart-text--title text-underline">
          WeWork Friendly
        </span>
        <span class="d-flex" [tooltip]="tooltip" tooltipPosition="top">
          <i class="icon text-color-stone-2">
            help_outline
          </i>
          <ng-template #tooltip>
            A filter for WeWork friendly items available at your chosen date and time.
            <a href="/l/wework-friendly" target="_blank" class="link link--light link--small">Find out more.</a>
          </ng-template>
        </span>
      </app-cart-toggle>
    </div>
  </div>
  <div cart-slot="footer">
    <app-price-guarantee></app-price-guarantee>
  </div>
</app-sticky-cart>
