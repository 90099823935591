import { Pipe, PipeTransform } from '@angular/core';
import { SourceQualificationType, SourceQualificationTypes } from '@citypantry/util-models';

@Pipe({
  name: 'sourceQualification'
})
export class SourceQualificationTypePipe implements PipeTransform {
  private static descriptors: { [key: string]: string } = {
    [SourceQualificationTypes.EMAIL]: 'Email',
    [SourceQualificationTypes.EVENT]: 'Event',
    [SourceQualificationTypes.LONDON_UNDERGROUND]: 'London Underground',
    [SourceQualificationTypes.OTHER]: 'Other',
    [SourceQualificationTypes.SEARCH_ENGINE]: 'Search engine',
    [SourceQualificationTypes.SOCIAL_MEDIA]: 'Social media',
    [SourceQualificationTypes.WORD_OF_MOUTH]: 'Word of mouth',
    [SourceQualificationTypes.EMAIL]: 'Email',
  };

  public transform(value: SourceQualificationType | null): string {
    if (!value) {
      return null;
    }

    return SourceQualificationTypePipe.descriptors[value];
  }
}
