export type CuisineType =
  'African'
  | 'American'
  | 'Argentinian'
  | 'Asian'
  | 'Brazilian'
  | 'British'
  | 'Caribbean'
  | 'Chinese'
  | 'Eastern European'
  | 'European'
  | 'French'
  | 'Fusion'
  | 'German'
  | 'Greek'
  | 'Hawaiian'
  | 'Indian'
  | 'Italian'
  | 'Jamaican'
  | 'Japanese'
  | 'Korean'
  | 'Kosher'
  | 'Lebanese'
  | 'Mediterranean'
  | 'Mexican'
  | 'Middle Eastern'
  | 'Moroccan'
  | 'Pan Asian'
  | 'Persian'
  | 'Peruvian'
  | 'Portuguese'
  | 'Russian'
  | 'South American'
  | 'Scandinavian'
  | 'Spanish'
  | 'Swedish'
  | 'Thai'
  | 'Vietnamese';

export class CuisineTypes {
  public static AFRICAN: CuisineType = 'African';
  public static AMERICAN: CuisineType = 'American';
  public static ARGENTINIAN: CuisineType = 'Argentinian';
  public static ASIAN: CuisineType = 'Asian';
  public static BRAZILIAN: CuisineType = 'Brazilian';
  public static BRITISH: CuisineType = 'British';
  public static CARIBBEAN: CuisineType = 'Caribbean';
  public static CHINESE: CuisineType = 'Chinese';
  public static EASTERN_EUROPEAN: CuisineType = 'Eastern European';
  public static FRENCH: CuisineType = 'French';
  public static FUSION: CuisineType = 'Fusion';
  public static GERMAN: CuisineType = 'German';
  public static GREEK: CuisineType = 'Greek';
  public static HAWAIIAN: CuisineType = 'Hawaiian';
  public static INDIAN: CuisineType = 'Indian';
  public static ITALIAN: CuisineType = 'Italian';
  public static JAMAICAN: CuisineType = 'Jamaican';
  public static JAPANESE: CuisineType = 'Japanese';
  public static KOREAN: CuisineType = 'Korean';
  public static KOSHER: CuisineType = 'Kosher';
  public static LEBANESE: CuisineType = 'Lebanese';
  public static MEDITERRANEAN: CuisineType = 'Mediterranean';
  public static MEXICAN: CuisineType = 'Mexican';
  public static MIDDLE_EASTERN: CuisineType = 'Middle Eastern';
  public static MOROCCAN: CuisineType = 'Moroccan';
  public static PAN_ASIAN: CuisineType = 'Pan Asian';
  public static PERUVIAN: CuisineType = 'Peruvian';
  public static PERSIAN: CuisineType = 'Persian';
  public static PORTUGUESE: CuisineType = 'Portuguese';
  public static RUSSIAN: CuisineType = 'Russian';
  public static SOUTH_AMERICAN: CuisineType = 'South American';
  public static SCANDINAVIAN: CuisineType = 'Scandinavian';
  public static SPANISH: CuisineType = 'Spanish';
  public static SWEDISH: CuisineType = 'Swedish';
  public static THAI: CuisineType = 'Thai';
  public static VIETNAMESE: CuisineType = 'Vietnamese';

  public static get values(): CuisineType[] {
    return [
      CuisineTypes.AFRICAN,
      CuisineTypes.AMERICAN,
      CuisineTypes.ARGENTINIAN,
      CuisineTypes.ASIAN,
      CuisineTypes.BRAZILIAN,
      CuisineTypes.BRITISH,
      CuisineTypes.CARIBBEAN,
      CuisineTypes.CHINESE,
      CuisineTypes.EASTERN_EUROPEAN,
      CuisineTypes.FRENCH,
      CuisineTypes.FUSION,
      CuisineTypes.GERMAN,
      CuisineTypes.GREEK,
      CuisineTypes.HAWAIIAN,
      CuisineTypes.INDIAN,
      CuisineTypes.ITALIAN,
      CuisineTypes.JAMAICAN,
      CuisineTypes.JAPANESE,
      CuisineTypes.KOREAN,
      CuisineTypes.KOSHER,
      CuisineTypes.LEBANESE,
      CuisineTypes.MEDITERRANEAN,
      CuisineTypes.MEXICAN,
      CuisineTypes.MIDDLE_EASTERN,
      CuisineTypes.MOROCCAN,
      CuisineTypes.PAN_ASIAN,
      CuisineTypes.PERUVIAN,
      CuisineTypes.PERSIAN,
      CuisineTypes.PORTUGUESE,
      CuisineTypes.RUSSIAN,
      CuisineTypes.SOUTH_AMERICAN,
      CuisineTypes.SCANDINAVIAN,
      CuisineTypes.SPANISH,
      CuisineTypes.SWEDISH,
      CuisineTypes.THAI,
      CuisineTypes.VIETNAMESE
    ];
  }
}

export type CuisineTypeEnum = typeof CuisineTypes;
