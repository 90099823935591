<!--
  We have multiple header components that re-use shared templates.
  Whenever you update one of the headers, ensure no templates need to be added or removed.
-->

<div
  class="site-header"
  [class.site-header--fixed]="isFixed"
  [class.site-header--small]="isSmall"
  id="site-header"
  analyticsId="site-header"
  test-id="headerWrapper"
>
  <div class="site-header__wrapper">
    <div class="container site-header__container">
      <div class="site-header__inner">
        <a
          class="header-logo"
          [class.header-logo--small]="isSmall"
          [inactiveRouterLink]="['/']"
          (click)="onLogoClicked()"
          title="Corporate Catering & Office Food Delivery Service | Just Eat for Business"
          test-id="logo"
          analyticsId="logo"
        >
          <img
            class="header-logo__image"
            src="/dist/images/logo.svg"
            alt="Just Eat for Business"
          />
        </a>

        <!-- mobile menu toggle -->
        <button
          type="button"
          class="mobile-toggle hidden-print"
          [class.mobile-toggle--active]="isNavOpen"
          [attr.aria-expanded]="isNavOpen"
          (click)="navToggleClicked()"
          test-id="mobileToggle"
        >
          <span class="mobile-toggle__option mobile-toggle__option--menu">Menu</span>
          <span class="mobile-toggle__option mobile-toggle__option--close">Close</span>
          <span class="mobile-toggle__icon"></span>
        </button>
      </div>

      <nav
        class="header-navigation hidden-print"
        [class.header-navigation--open]="isNavOpen"
        test-id="headerNavigation"
      >
        <ng-container [ngSwitch]="headerType">
          <app-default-user-header
            *ngSwitchCase="HeaderTypeEnum.DEFAULT"
            [isLoggedIn]="isLoggedIn"
            [separatorTemplate]="separatorTemplate"
            [externalLinkTemplate]="externalLinkTemplate"
            [searchFoodButtonTemplate]="searchFoodButtonTemplate"
            test-id="defaultUserHeader"
          ></app-default-user-header>

          <app-staff-user-header
            *ngSwitchCase="HeaderTypeEnum.STAFF"
            [separatorTemplate]="separatorTemplate"
            [externalLinkTemplate]="externalLinkTemplate"
            [routerLinkTemplate]="routerLinkTemplate"
            [authenticatedUserLinksTemplate]="authenticatedUserLinksTemplate"
            [canAccessOnDemand]="canAccessOnDemand"
            test-id="staffUserHeader"
          ></app-staff-user-header>

          <app-vendor-user-header
            *ngSwitchCase="HeaderTypeEnum.VENDOR"
            [pendingOrdersCount]="pendingOrdersCount"
            [separatorTemplate]="separatorTemplate"
            [externalLinkTemplate]="externalLinkTemplate"
            [routerLinkTemplate]="routerLinkTemplate"
            [authenticatedUserLinksTemplate]="authenticatedUserLinksTemplate"
            test-id="vendorUserHeader"
          ></app-vendor-user-header>

          <app-purchaser-user-header
            *ngSwitchCase="HeaderTypeEnum.PURCHASER"
            [showMealPlanLink]="showMealPlanLink"
            [isEater]="isEater"
            [isOrderer]="isOrderer"
            [isEligibleForIndividualChoice]="isEligibleForIndividualChoice"
            [sudoMode]="sudoMode"
            [isColleagueGroupsEnabled]="isColleagueGroupsEnabled"
            [isAdvancedBudgetingEnabled]="isAdvancedBudgetingEnabled"
            [marketingCampaignComponent]="marketingCampaignComponent"
            [separatorTemplate]="separatorTemplate"
            [externalLinkTemplate]="externalLinkTemplate"
            [routerLinkTemplate]="routerLinkTemplate"
            [authenticatedUserLinksTemplate]="authenticatedUserLinksTemplate"
            [searchFoodButtonTemplate]="searchFoodButtonTemplate"
            test-id="purchaserUserHeader"
          ></app-purchaser-user-header>

          <app-eater-user-header
            *ngSwitchCase="HeaderTypeEnum.EATER"
            [externalLinkTemplate]="externalLinkTemplate"
            [routerLinkTemplate]="routerLinkTemplate"
            [authenticatedUserLinksTemplate]="authenticatedUserLinksTemplate"
            test-id="eaterUserHeader"
          ></app-eater-user-header>

          <app-on-demand-user-header
            *ngSwitchCase="HeaderTypeEnum.ON_DEMAND"
            [externalLinkTemplate]="externalLinkTemplate"
            [routerLinkTemplate]="routerLinkTemplate"
            [authenticatedUserLinksTemplate]="authenticatedUserLinksTemplate"
            test-id="onDemandUserHeader"
          ></app-on-demand-user-header>
        </ng-container>
      </nav>
    </div>
  </div>
</div>


<!-- GENERIC TEMPLATES -->

<ng-template #separatorTemplate>
  <li class="header-navigation__item header-navigation__item--separator"></li>
</ng-template>

<ng-template
  #externalLinkTemplate
  let-externalHref="externalHref"
  let-label="label"
  let-isSecondary="isSecondary"
  let-analyticsId="analyticsId"
  let-testId="testId"
  let-target="target"
  let-icon="icon"
  let-e2eTestId="e2eTestId"
>
  <li class="header-navigation__item">
    <!-- We cannot allow an empty `analyticsId` tag to render, because it is not supported by `AnalyticsIdDirective` -->
    <a *ngIf="analyticsId; else noAnalyticsId"
      href="{{ externalHref }}"
      target="{{ target || '_parent' }}"
      class="header-navigation__link"
      [class.header-navigation__link--secondary]="isSecondary"
      analyticsId="{{ analyticsId }}"
      [attr.e2e-test-id]="e2eTestId"
      [attr.test-id]="testId"
    >
      {{ label }}
      <ng-container *ngIf="icon"><i class="icon icon--x-small">{{icon}}</i></ng-container>
    </a>

    <ng-template #noAnalyticsId>
      <a
        href="{{ externalHref }}"
        target="{{ target }}"
        class="header-navigation__link"
        [class.header-navigation__link--secondary]="isSecondary"
        [attr.e2e-test-id]="e2eTestId"
        [attr.test-id]="testId"
      >
        {{ label }}
        <ng-container *ngIf="icon"><i class="icon icon--x-small">{{icon}}</i></ng-container>
      </a>
    </ng-template>
  </li>
</ng-template>

<ng-template
  #routerLinkTemplate
  let-routerLink="routerLink"
  let-label="label"
  let-isSecondary="isSecondary"
  let-analyticsId="analyticsId"
  let-e2eTestId="e2eTestId"
  let-testId="testId"
>
  <li class="header-navigation__item">
    <!-- We cannot allow an empty `analyticsId` tag to render, because it is not supported by `AnalyticsIdDirective` -->
    <a *ngIf="analyticsId; else noAnalyticsId"
      [routerLink]="routerLink"
      class="header-navigation__link"
      [class.header-navigation__link--secondary]="isSecondary"
      [attr.test-id]="testId"
      [attr.e2e-test-id]="e2eTestId"
      analyticsId="{{ analyticsId }}"
    >{{ label }}</a>

    <ng-template #noAnalyticsId>
      <a
        [routerLink]="routerLink"
        class="header-navigation__link"
        [class.header-navigation__link--secondary]="isSecondary"
        [attr.test-id]="testId"
        [attr.e2e-test-id]="e2eTestId"
      >{{ label }}</a>
    </ng-template>
  </li>
</ng-template>


<!-- RE-USED GROUPS OF LINKS -->

<ng-template #authenticatedUserLinksTemplate>
  <ng-container *ngIf="!isSsoUser">
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: identityBase+'/account/', label: 'Account Settings', isSecondary: true, testId: 'accountSettingsLink', icon: 'external', target: '_blank' }"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/logout', label: 'Log out', isSecondary: true, analyticsId: 'logout' }"></ng-container>
</ng-template>

<!-- RE-USED BUTTONS -->

<ng-template #searchFoodButtonTemplate>
  <li class="header-navigation__item">
    <a
      class="header-navigation__button button button--ghost"
      [routerLink]="['/menus/search']"
      [queryParams]="getQueryParams()"
      analyticsId="search"
      e2e-test-id="searchFood"
      test-id="searchFoodButtonTemplate"
    >Search Food</a>
  </li>
</ng-template>
