import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, mapmap } from '@citypantry/util';
import { createSimpleCustomerLocationFromJson, JustEatVoucher, SimpleCustomerLocation } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EaterLocationsResponse {
  customerLocation: SimpleCustomerLocation;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class EatersService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getEaterLocations(): Observable<SimpleCustomerLocation[]> {
    return this.http.get(
      `/users/me/locations`
    ).pipe(
      getItems(),
      mapmap(mapEaterLocationsResponseToSimpleCustomerLocation),
    );
  }

  public addCurrentEaterToLocation(location: SimpleCustomerLocation): Observable<SimpleCustomerLocation> {
    return this.http.post(
      `/users/me/locations`,
      {
        id: location.id
      }
    ).pipe(
      getItem(),
      map(mapEaterLocationsResponseToSimpleCustomerLocation),
    );
  }

  public retrieveJustEatVoucher(): Observable<JustEatVoucher> {
    return this.http.get(
      `/just-eat-vouchers/retrieve`
    ).pipe(
      map(mapJustEatVouchers)
    );
  }

  public getJustEatVoucher(): Observable<JustEatVoucher | null> {
    return this.http.get(
      `/just-eat-vouchers`
    ).pipe(
      map((justEatVoucher) => {
        if (justEatVoucher) {
          return mapJustEatVouchers(justEatVoucher);
        } else {
          // 204 No Content is an expected response when no code has been previously retrieved
          return null;
        }
      }),
    );
  }
}

export function mapEaterLocationsResponseToSimpleCustomerLocation(eaterLocation: EaterLocationsResponse): SimpleCustomerLocation {
  return createSimpleCustomerLocationFromJson(eaterLocation.customerLocation);
}

function mapJustEatVouchers(json: Partial<JustEatVoucher>): JustEatVoucher {
  return {
    id: json.id,
    code: json.code,
  };
}
