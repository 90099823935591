<div
  class="bundle-section-header"
>
  <div
    class="bundle-section-header__heading"
    test-id="heading"
  >
    {{ itemGroup.heading }}
  </div>
  <div class="bundle-section-header__prompt">
    <app-item-group-prompt
      [itemGroup]="itemGroup"
      test-id="prompt"
    ></app-item-group-prompt>
  </div>

  <hr class="bundle-section-header__rule" />
</div>

<div class="row [ pt-standard px-none ] [ pt-md-x-large px-md-standard ]">
  <ng-container
    *ngFor="let item of itemGroup.items; index as index"
  >
    <div
      [class]="'col-12 col-md-6 mb-large order-md-' + getItemOrder(index)"
    >
      <app-fixed-group-item
        [item]="item"
        [quantity]="getFixedItemQuantity(item.id)"
        [bundleCount]="bundleCount"
        [showMoreInfo]="moreInfoItem === item"
        (toggleMoreInfo)="toggleMoreInfo(item)"
        test-id="items"
      ></app-fixed-group-item>
    </div>
    <div
      *ngIf="moreInfoItem === item"
      [class]="'col-12 mb-standard order-md-' + getMoreInfoOrder(index)"
    >
      <app-additional-item-info
        [item]="item"
        [showDescription]="true"
        [showPerServing]="true"
        (close)="toggleMoreInfo()"
        test-id="moreInfo"
      ></app-additional-item-info>
    </div>
  </ng-container>
</div>
