import { Injectable } from '@angular/core';
import { OperationalRegionsApi } from '@citypantry/shared-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OperationalRegionsActions } from './operational-regions.actions';

@Injectable()
export class OperationalRegionsEffects {

  public loadOperationalRegions$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(OperationalRegionsActions.loadOperationalRegions.type),
    switchMap(() => this.operationalRegionsApi.getOperationalRegions()),
    map((regions: string[]) => OperationalRegionsActions.operationalRegionsLoaded({ regions }))
  ));

  constructor(
    private action$: Actions,
    private operationalRegionsApi: OperationalRegionsApi
  ) {}
}
