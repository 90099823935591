import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delivery-details-updated-popup',
  templateUrl: './delivery-details-updated-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryDetailsUpdatedPopupComponent {
  @Input()
  public isIndividualChoice?: boolean;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  public onClose(): void {
    this.close.emit();
  }
}
