import { MinorCurrency } from '../money/minor-major-currency.model';

// TODO CPD-7866 uncommend needed fields as they are added to the api

export interface BudgetRange {
  name: string;
  budget: MinorCurrency;

  // type: 'weekly';           // discriminator for forward compatibility
  // startDay: number;         // day int offset
  // startTime: moment.Moment; // hh:mm format: 00:01
  // endDay: number;           // date int offset
  // endTime: moment.Moment;   // hh:mm format: 04:30
  // deletedAt: moment.Moment;
}

export function createBudgetRangeFromJson(json: Partial<BudgetRange> = {}): BudgetRange {
  const budgetRange: Partial<BudgetRange> = {};
  budgetRange.name = json.name;
  budgetRange.budget = json.budget;

  // budgetRange.type = json.type;
  // budgetRange.startDay = json.startDay;
  // budgetRange.startTime = moment(json.startTime, 'HH:mm');
  // budgetRange.endDay = json.endDay;
  // budgetRange.endTime = moment(json.endTime, 'HH:mm');
  // budgetRange.deletedAt = json.deletedAt ? moment(json.deletedAt) : null;

  return budgetRange as BudgetRange;
}

export type AdvancedBudgets = BudgetRange[];
