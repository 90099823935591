import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const customerMenuPageRules: AnalyticsRule[] = [
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'menu-header vendor-rating',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Ratings/Reviews',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'menu-header dietaries diet-icon',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `${event.data['diet-icon'].name} dietary toggled ${event.data['diet-icon'].active ? 'off' : 'on'}`,
    }),
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'menu-content single-item more-info',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'More info',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'quantity-picker decrement',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker minus button clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'quantity-picker text-box',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker text-box changed',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'quantity-picker increment',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Quantity picker plus button clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart submit',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Order Now',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart individual-choice-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Individual Choice toggle',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'introducing-individual-choice-dialog',
    events: {
      class: 'DialogComponent',
      eventName: 'confirm'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Individual Choice dialog Get Started clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'introducing-individual-choice-dialog',
    events: {
      class: 'DialogComponent',
      eventName: 'abort'
    },
    definition: (event) => {
      const abortSource: 'close' | 'outside' | 'cancel' = event.event;
      if (abortSource === 'cancel') {
        return {
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
          label: 'Individual Choice dialog Find Out More clicked',
        };
      } else {
        return [];
      }
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart more-actions',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Dropdown menu clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart download-quote',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Download quote clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart share-cart',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Share cart clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'cart download-pdf',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU, // probably should be CART
      label: 'Download Menu PDF clicked',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'menu-cart',
    events: {
      class: 'CartComponent',
      eventName: 'changeWeWorkMode',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'WeWork Friendly items toggle',
    },
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'parameters-form postcode',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Postcode set'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Date set'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Time set'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'end-of-menu',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'End of menu viewed'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'recommended-vendors vendor-list',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDOR_RECOMMENDATIONS,
      label: 'Recommended Vendors Seen'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'recommendation',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_RECOMMENDATIONS,
      label: 'Recommended Vendor Clicked'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'recommended-vendors see-more-vendors',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_RECOMMENDATIONS,
      label: 'See More Vendors'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'about-vendor read-more',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'Vendor Details: Read More'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'about-cp-footer footer-start',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'About CP Footer: Top of footer visible'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'about-cp-footer footer-end',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'About CP Footer: Bottom of footer visible'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'about-cp-footer find-out-more',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: 'About CP Footer: Find Out More clicked'
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'menu-content favouriteButtonContainer',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `vendor ${event.data['favouriteButtonContainer']['isAddingToFavourites'] ? 'added to' : 'removed from'} favourites`,
    }),
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'customer-menu menu-content favouriteButtonContainer',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `
      ${event.data['favouriteButtonContainer'].isAddingToFavourites ? 'Favourite' : 'Unfavourite'} vendor
      - ${event.data['customer-menu'].vendorName}`,
    }),
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'customer-menu menu-content menu-item-image',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData => {
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.VENDORS_MENU,
        label: 'Item image clicked',
        extraFields: {
          imageCount: event.data['menu-item-image'].imageCount,
          itemType: event.data['menu-item-image'].itemType,
        },
      };
    }
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'undeliverable-customer-menu top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `Undeliverable menu for ${event.data['undeliverable-customer-menu'].vendorName} viewed`,
    }),
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'undeliverable-customer-menu search',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `Undeliverable menu for ${event.data['undeliverable-customer-menu'].vendorName} Search button clicked`,
    }),
  },
  {
    url: '/menus/vendors/:vendorIdOrSlug/:vendorLocationSlug',
    path: 'undeliverable-customer-menu open-contact-form-link',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDORS_MENU,
      label: `Undeliverable menu for ${event.data['undeliverable-customer-menu'].vendorName} open chat link clicked`,
    }),
  },
];
