import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerQualification } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(
    private http: HttpClient,
  ) {}

  public qualify(qualification: CustomerQualification): Observable<void> {
    return this.http.put('/customer/qualify', {
      useCases: qualification.useCases,
      frequency: qualification.frequency,
      headCount: qualification.headCount,
      source: qualification.source,
      otherSource: qualification.sourceOther,
    }).pipe(
      mapTo(undefined)
    );
  }
}
