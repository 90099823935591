import { createAction, props, union } from '@ngrx/store';
import { Moment } from 'moment';

export const CompanyHolidaysActions = {

  loadCompanyHolidays: createAction(
    '[Root] Load Company Holidays',
  ),

  companyHolidaysLoaded: createAction(
    '[Root] Company Holidays Loaded',
    props<{ holidays: Moment[] }>(),
  ),
};

const all = union(CompanyHolidaysActions);
export type CompanyHolidaysAction = typeof all;
