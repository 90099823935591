/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

import { UnreachableCaseError } from '@citypantry/util';

export type ChoiceDeadline = 'short-notice' | 'same-day' | 'one-day' | 'two-days';

export class ChoiceDeadlines {
  public static SHORT_NOTICE: ChoiceDeadline & 'short-notice' = 'short-notice';
  public static SAME_DAY:     ChoiceDeadline & 'same-day'     = 'same-day';
  public static ONE_DAY:      ChoiceDeadline & 'one-day'      = 'one-day';
  public static TWO_DAYS:     ChoiceDeadline & 'two-days'     = 'two-days';

  public static get values(): ChoiceDeadline[] {
    return [
      ChoiceDeadlines.SHORT_NOTICE,
      ChoiceDeadlines.SAME_DAY,
      ChoiceDeadlines.ONE_DAY,
      ChoiceDeadlines.TWO_DAYS,
    ];
  }

  // Do not modify this line - Custom code below here

  public static get shortNotice(): ChoiceDeadline[] {
    return [
      ChoiceDeadlines.SHORT_NOTICE,
    ];
  }

  public static get sameDay(): ChoiceDeadline[] {
    return [
      ChoiceDeadlines.SHORT_NOTICE,
      ChoiceDeadlines.SAME_DAY,
    ];
  }

  public static get oneDay(): ChoiceDeadline[] {
    return [
      ChoiceDeadlines.SHORT_NOTICE,
      ChoiceDeadlines.SAME_DAY,
      ChoiceDeadlines.ONE_DAY,
    ];
  }

  public static get twoDays(): ChoiceDeadline[] {
    return [
      ChoiceDeadlines.SHORT_NOTICE,
      ChoiceDeadlines.SAME_DAY,
      ChoiceDeadlines.ONE_DAY,
      ChoiceDeadlines.TWO_DAYS,
    ];
  }

  public static toMinutes(choiceDeadline: ChoiceDeadline): number {
    switch (choiceDeadline) {
      case ChoiceDeadlines.SHORT_NOTICE:
        return 90;
      case ChoiceDeadlines.SAME_DAY:
        return 180; // 3 hours
      case ChoiceDeadlines.ONE_DAY:
        return 1440; // 24 hours
      case ChoiceDeadlines.TWO_DAYS:
        return 2880; // 48 hours
      default:
        throw new UnreachableCaseError(choiceDeadline);
    }
  }
}

export type ChoiceDeadlineEnum = typeof ChoiceDeadlines;
