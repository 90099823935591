export interface CustomerStatus {
  owner: CustomerStatusOwner | null;
}

export interface CustomerStatusOwner {
  id: string | null;
  name: string | null;
}

export function createCustomerStatusFromJson(json?: Partial<CustomerStatus>): CustomerStatus {
  json = json || {};

  const customerStatus: Partial<CustomerStatus> = {};
  customerStatus.owner = json.owner || null;

  return customerStatus as CustomerStatus;
}

