import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBannerComponent {
  @Input()
  public type: 'success' | 'warning' | 'error' | null = null;

  @Input()
  public icon: 'info' | 'success' | 'warning' | 'error' | null = null;

  @Input()
  public isSolid: boolean = false;

  public get classes(): string {
    const base = 'info-banner';
    const classes = [base];

    if (this.icon) {
      classes.push(`${base}--icon-${this.icon}`);
    }

    if (this.isSolid) {
      classes.push(`${base}--solid`);
    }

    if (this.type) {
      classes.push(`${base}--${this.type}`);
    }

    return classes.join(' ');
  }
}
