import { mapSelectors } from '@citypantry/util';
import { CustomerLocation } from '@citypantry/util-models';
import { getAccountState } from '../app.state';
import { AccountState } from './account.state';

const isLocationsWithEatersInitialised = (state: AccountState) => !!state.locationsWithEaters;
const isLoadingLocationsWithEaters = (state: AccountState) => !state.locationsWithEaters || state.locationsWithEaters.isLoading;
const getLocationsWithEaters = (state: AccountState) => state.locationsWithEaters ? state.locationsWithEaters.locations : [];
const getActiveLocationWithEaters = (state: AccountState): CustomerLocation | null =>
  state.locationsWithEaters && !state.locationsWithEaters.isLoading && state.activeLocationId ?
    state.locationsWithEaters.locations.find((location) => location.id === state.activeLocationId) :
    null;
const isLoadingEatersAtActiveLocation = (state: AccountState) => state.eatersAtActiveLocation.isLoading;
const hasCopiedShareUrl = (state: AccountState) => state.hasCopiedShareUrl;
const getEatersAtActiveLocation = (state: AccountState) => state.eatersAtActiveLocation.eaters;

/**
 * Used by the LoadEatersForLocationGuard
 */
const getGuardData = (state: AccountState) => ({
  activeLocation: getActiveLocationWithEaters(state),
  locations: getLocationsWithEaters(state),
  isEaterListLoading: isLoadingEatersAtActiveLocation(state),
});

export const AccountSelectors = mapSelectors(getAccountState, {
  isLocationsWithEatersInitialised,
  isLoadingLocationsWithEaters,
  getLocationsWithEaters,
  getActiveLocationWithEaters,
  isLoadingEatersAtActiveLocation,
  hasCopiedShareUrl,
  getEatersAtActiveLocation,

  getGuardData,
});
