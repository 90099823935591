import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

export interface EditableDropdownOption {
  value: string;
  label: string;
}

const EDIT_TEXT_SELECTED_INDEX = -1;
const NO_VALUE_SELECTED_INDEX = -2;

@Component({
  selector: 'app-editable-dropdown',
  templateUrl: './editable-dropdown.component.html'
})
export class EditableDropdownComponent implements OnInit, ControlValueAccessor {

  @Input()
  public options: EditableDropdownOption[];

  @Input()
  public icon: string;

  @Input()
  public placeholder: string;

  @Input()
  public manualLabel: string;

  @Input()
  public initialLabel: string;

  @Output()
  public editing: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('text', { static: false })
  public textElement: ElementRef;

  public get isEditing(): boolean {
    return this._isEditing;
  }

  public set isEditing(value: boolean) {
    this._isEditing = value;
    this.editing.emit(value);
  }

  public dropdownControl: FormControl;
  public textValue: string;

  public get iconClass(): string {
    return this.icon ? `icon-${ this.icon }` : '';
  }

  private lastValidDropdownValue: number;
  private _isEditing: boolean;

  private writtenValueOnConstruct: string;
  private isInitialised: boolean;
  private onChange$: Subject<string>;
  private onTouch$: Subject<void>;
  private lastValue: string;

  constructor(
    private control: NgControl
  ) {
    this.onChange$ = new Subject<string>();
    this.onTouch$ = new Subject<void>();

    this.control.valueAccessor = this;
  }

  public ngOnInit(): void {
    if (!this.options || !this.options.length) {
      throw new Error('Missing options for Editable Dropdown component!');
    }

    this.onChange$.subscribe((value) => this.lastValue = value);

    this.dropdownControl = new FormControl(NO_VALUE_SELECTED_INDEX);
    this.dropdownControl.valueChanges.subscribe((value: number) => {
      this.updateDropdownValue(value);
      this.onTouch$.next();
    });

    this.setValue(this.writtenValueOnConstruct, true);
    this.isInitialised = true;
  }

  public writeValue(obj: any): void {
    if (!this.isInitialised) {
      this.writtenValueOnConstruct = obj;
    } else {
      this.setValue(obj);
    }
  }

  public registerOnChange(callback: (value: string) => void): void {
    this.onChange$.subscribe(callback);
  }

  public registerOnTouched(callback: () => void): void {
    this.onTouch$.subscribe(callback);
  }

  public updateDropdownValue(value: number): void {
    if (value === EDIT_TEXT_SELECTED_INDEX) {
      this.textValue = '';
      this.isEditing = true;
      setTimeout(() => { // The element is not yet visible, so focus it once it's appeared
        this.textElement.nativeElement.focus();
      });
    } else {
      this.lastValidDropdownValue = value;
      this.onChange$.next(this.options[value].value);
    }
  }

  public updateText(value: string): void {
    this.textValue = value;
    if (this.lastValue !== value) {
      this.onChange$.next(value);
    }
    this.onTouch$.next();
  }

  public stopEditing(): void {
    this.isEditing = false;
    this.dropdownControl.setValue(this.lastValidDropdownValue);
  }

  public get invalid(): boolean {
    return this.control.invalid;
  }

  public get touched(): boolean {
    return this.control.touched;
  }

  private getOptionIndex(value: string): number {
    return this.options
      .map((option) => option.value.toLowerCase())
      .indexOf(value.toLowerCase());
  }

  private setValue(value: string, isInitialising?: boolean): void {
    this.lastValue = value;

    value = value || '';
    const setDefault = isInitialising && !value;
    const newIndex = setDefault ? NO_VALUE_SELECTED_INDEX : this.getOptionIndex(value);

    this.dropdownControl.setValue(newIndex, { emitEvent: false });
    if (isInitialising || newIndex >= 0) {
      this.lastValidDropdownValue = Math.max(newIndex, 0);
    }

    const isEditing = newIndex === EDIT_TEXT_SELECTED_INDEX;
    if (isInitialising) {
      // Delay setting this value as setting it will emit an event.
      // We don't want to trigger this event in the same cycle as a write that caused a change
      // as that may cause ExpressionChanged... exceptions in the parent component
      setTimeout(() => this.isEditing = isEditing);
    } else {
      this.isEditing = isEditing;
    }
    this.textValue = value;
  }
}
