import { Item } from '@citypantry/util-models';
import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const eaterMenuPageRules: AnalyticsRule[] = [
  {
    url: '/my-meals/:orderId',
    path: 'menu-header dietaries diet-icon',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: `${ event.data['diet-icon'].name } dietary toggled ${ event.data['diet-icon'].active ? 'off' : 'on' }`,
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'menu-content single-item more-info',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'More info',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        itemId: event.data['more-info'].itemId,
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu menu-content menu-item quantity-picker decrement',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Quantity picker minus button clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        itemId: event.data['menu-item'].itemId,
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu menu-content menu-item quantity-picker text-box',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Quantity picker text-box changed',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        itemId: event.data['menu-item'].itemId,
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu menu-content menu-item quantity-picker increment',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Quantity picker plus button clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        itemId: event.data['menu-item'].itemId,
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'department-reference-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Cost code entered'
    }
  },
  {
    url: '/my-meals/:orderId',
    path: 'desk-number-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Desk number entered'
    }
  },
  {
    url: '/my-meals/:orderId',
    path: 'desk-number',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Desk number input seen',
    },
  },

  {
    url: '/my-meals/:orderId',
    path: 'eater-menu subsidised-cart card-input pay-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Eater menu subsidised cart new payment card Pay Button clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        amount: event.data['eater-menu'].orderTotal,
        items: event.data['eater-menu'].items.map((list: { item: Item }) => list.item.id)
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu subsidised-cart remembered-card pay-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Eater menu subsidised cart existing payment card Pay Button clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        amount: event.data['eater-menu'].orderTotal,
        items: event.data['eater-menu'].items.map((list: { item: Item }) => list.item.id)
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu subsidised-cart submit-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Eater menu subsidised cart submit clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        amount: event.data['eater-menu'].orderTotal,
        items: event.data['eater-menu'].items.map((list: { item: Item }) => list.item.id)
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu non-subsidised-cart submit-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Eater menu non-subsidised cart submit clicked',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
        amount: event.data['eater-menu'].orderTotal,
        items: event.data['eater-menu'].items.map((list: { item: Item }) => list.item.id)
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu subsidised-cart remember-card',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATERS_MENU,
      label: `Eater menu subsidised cart remember card checked ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu subsidised-cart payment-error',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.EATERS_MENU,
      label: 'Eater menu subsidised cart payment failed',
      extraFields: {
        orderId: event.data['eater-menu'].orderId,
        vendorId: event.data['eater-menu'].vendorId,
      }
    }),
  },
  {
    url: '/my-meals/:orderId',
    path: 'eater-menu menu-content menu-item-image',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData => {
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.VENDORS_MENU,
        label: 'Item image clicked',
        extraFields: {
          imageCount: event.data['menu-item-image'].imageCount,
          itemType: event.data['menu-item-image'].itemType,
        },
      };
    }
  },
];
