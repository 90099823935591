import { mapSelectors } from '@citypantry/util';
import { SearchResultsCategories } from '@citypantry/util-models';
import { getSearchRecommendationsState } from '../app.state';
import { SearchRecommendationState } from './search-recommendation.state';

export const getResults = (state: SearchRecommendationState) => {
  // Pagination is not currently supported, so we unwrap CategorySearchResultsPage to a SearchResult array
  const recommendedResultsPage = state.results.find((resultSet) => resultSet.category === SearchResultsCategories.RECOMMENDED);
  return (recommendedResultsPage && recommendedResultsPage.items)
    ? recommendedResultsPage.items
    : [];
};

export const SearchRecommendationSelectors = mapSelectors(getSearchRecommendationsState, {
  getResults
});
