import { mapSelectors } from '@citypantry/util';
import { CartItem, CartItemBundle, findMenuItemById, isItemBundle, ItemBundle } from '@citypantry/util-models';
import { getPublicState } from '../app.state';
import { getCart, getCurrentMenu, getCurrentVendor, getCurrentVendorName, PublicState } from '../public/public.state';

export const getSelectedItemBundle = (state: PublicState): ItemBundle | null => {
  const itemId = state.openItemBundleId;
  const menu = getCurrentMenu(state);

  const foundItem = findMenuItemById(menu.content, itemId);
  return foundItem && isItemBundle(foundItem) ? foundItem : null;
};

export const getSelectedCartItemBundle = (state: PublicState): CartItemBundle | null => {
  const itemBundle = getSelectedItemBundle(state);
  const cart = getCart(state);

  return itemBundle && cart.cartItems && cart.cartItems.find((cartItem: CartItem) => cartItem.item.id === itemBundle.id) as CartItemBundle
    || null;
};

export const getUndeliverableMenu = (state: PublicState) => state.undeliverableMenu;

export const getCurrentVendorLocationSlug = (state: PublicState) => getCurrentMenu(state)?.vendorLocation?.slug;

export const getCurrentVendorLocationData = (state: PublicState):
  { distance: number, postcode: string | null, phoneNumber: string | null } | null => {
  const menu = getCurrentMenu(state);

  if (menu?.vendorLocation) {
    return {
      distance: menu.vendorLocation.distance,
      postcode: menu.vendorLocation.postcode,
      phoneNumber: menu.vendorLocation.phoneNumber,
    };
  }
  if (menu?.closestVendorLocation) {
    return {
      distance: menu.closestVendorLocation.distance,
      postcode: menu.closestVendorLocation.postcode,
      phoneNumber: menu.closestVendorLocation.phoneNumber,
    };
  }

  return null;
};

export const MenuSelectors = mapSelectors(getPublicState, {
  getCurrentMenu,
  getCurrentVendor,
  getCurrentVendorName,
  getCurrentVendorLocationSlug,
  getCurrentVendorLocationData,
  getUndeliverableMenu,
  getSelectedItemBundle,
  getSelectedCartItemBundle,
});
