/// <reference types="google.maps" />

import { Injectable } from '@angular/core';
import { GoogleMapsApis } from './google-maps-apis';
import { SessionTokenManager } from './session-token-manager';

@Injectable({ providedIn: 'root' })
export class GoogleMapsAutocomplete {
  constructor(
    private mapsApis: GoogleMapsApis,
    private sessionTokenManager: SessionTokenManager,
  ) {
  }

  public async getPlacePredictions(
    request: google.maps.places.AutocompletionRequest,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const autocomplete = await this.mapsApis.autocomplete;
    return new Promise((resolve, reject) => {
      if (!request.sessionToken) {
        request.sessionToken = this.sessionTokenManager.getToken();
      }
      autocomplete.getPlacePredictions(request, (results: any[], status: string): void => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
          return;
        }
        resolve(results);
      });
    });
  }
}
