import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-eco-friendly-packaging',
  template: `
<span class="vertically-center label-with-icon">
  <i
    class="label-with-icon__icon icon"
    aria-hidden="true"
  >eco</i>
  <span class="label">
    Eco-friendly packaging
  </span>
</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcoFriendlyPackagingComponent {}
