import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { toggleElement, UnreachableCaseError } from '@citypantry/util';
import { Dietaries, DIETARIES_DISPLAY_ORDER, DietaryExceptNone } from '@citypantry/util-models';

@Component({
  selector: 'app-dietaries-filter-box',
  templateUrl: './dietaries-filter-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DietariesFilterBoxComponent {

  @Input()
  public dietaries: Dietaries;

  @Input()
  public showUnavailable: boolean;

  @Input()
  public filteredDietaries: DietaryExceptNone[];

  @Output()
  public filteredDietariesChange: EventEmitter<DietaryExceptNone[]> = new EventEmitter();

  public DIETARIES_DISPLAY_ORDER: DietaryExceptNone[] = DIETARIES_DISPLAY_ORDER;

  constructor() {
    this.filteredDietaries = [];
    this.showUnavailable = false;
  }

  public clickDietary(dietary: DietaryExceptNone): void {
    // If a dietary is unavailable, we do not allow it to be toggled
    if(this.dietaries[dietary]) {
      const newFilteredDietaries = toggleElement(this.filteredDietaries, dietary);
      this.filteredDietariesChange.emit(newFilteredDietaries);
    }
  }

  public cssClass(diet: DietaryExceptNone): string {
    if(this.filteredDietaries.includes(diet)) {
      return `dietaries-filter-box__item--${this.cssClassModifier(diet)}-active`;
    }
    return '';
  }

  public cssClassModifier(diet: DietaryExceptNone): string {
    switch (diet) {
      case 'vegetarian':  return 'vegetarian';
      case 'vegan':       return 'vegan';
      case 'noGluten':    return 'no-gluten';
      case 'noNuts':      return 'no-nuts';
      case 'noDairy':     return 'no-dairy';
      case 'halal':       return 'halal';
      case 'pescatarian': return 'pescatarian';
      default:
        throw new UnreachableCaseError(diet);
    }
  }
}
