import { Directive, OnDestroy, OnInit } from '@angular/core';
import { AppState, CartSelectors, MenuSelectors } from '@citypantry/state';
import { safeUnsubscribe } from '@citypantry/util';
import { Cart, CartItem, ItemBundle, ItemType, ItemTypes, SingleItem } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { GaEnhancedEcomAction, GaEnhancedEcomProductFieldObject } from 'angulartics2/ga-enhanced-ecom/ga-enhanced-ecom-options';
import { Subscription } from 'rxjs';
import { AnalyticsApi } from '../analytics.api';
import { AnalyticsEcommerceAction, AnalyticsEcommerceActions } from './analytics-ecommerce-action.enum';
import { AnalyticsEcommerceEventId } from './analytics-ecommerce-event-id.enum';

interface PreviousItemState {
  itemId: string;
  quantity: number;
}

/**
 * In some cases analytics will need to be context specific, ie when items are added to a cart on a specific type of menu
 *
 * This directive is placed on the parent DOM element of any region of the website that should fire analytics data.
 * Child directives like AnalyticsEnhancedEcommerceDirective will traverse up their ancestor tree and inject this directive if it exists.
 * The child directive will then call methods from the injected context to fire analytics data.
 *
 * If no analyticsContext is provided the child will not be able to make any analytics calls, and therefore will not fire any.
 */
@Directive({
  selector: '[analyticsEcommerceContext]'
})
export class AnalyticsEcommerceContextDirective implements OnInit, OnDestroy {

  /**
   *  stores the previous items and quantities in a cart so quantity calculation
   *  and cart repopulation can be handled
   */
  public previousItemStates: PreviousItemState[] = [];
  public cart: Cart;
  public vendorName: string;

  public cartSubscription: Subscription;
  public currentMenuSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private analyticsApi: AnalyticsApi
  ) {}

  public ngOnInit(): void {
    this.cartSubscription = this.store.select(CartSelectors.getCart).subscribe((value) => {
      this.cart = value;
    });
    this.currentMenuSubscription = this.store.select(MenuSelectors.getCurrentVendorName).subscribe(
      (vendorName) => {
        this.vendorName = vendorName;
      });
    this.repopulatePreviousItemsState();
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.cartSubscription, this.currentMenuSubscription);
    this.previousItemStates = [];
  }

  public handleEnhancedEcommerceEvent(
    item: SingleItem | ItemBundle,
    ecommerceAction: AnalyticsEcommerceAction,
    ecommerceEventId: AnalyticsEcommerceEventId
  ): void {
    if (item && ecommerceAction && ecommerceEventId) {
      const previousItem = (this.previousItemStates.find((itemState) => itemState.itemId === item.id));
      const previousItemQuantity = (previousItem) ? previousItem.quantity : 0;

      const currentItemQuantity = this.getCartQuantity(item) || 0;
      const quantityChange = Math.abs(currentItemQuantity - previousItemQuantity);

      // Disregard actions with no cart quantity changes, unless they are of type DETAIL
      if (quantityChange !== 0 || ecommerceAction === AnalyticsEcommerceActions.DETAIL) {
        const { id, name } = item;
        const price = this.calculateItemPrice(item);
        const brand = this.vendorName;
        const category = this.convertItemTypeToCategory(item.type);

        const gaEcomAction: GaEnhancedEcomAction = this.getGaEcomAction(ecommerceAction, previousItemQuantity, currentItemQuantity);
        const gaEcomProduct: Partial<GaEnhancedEcomProductFieldObject> = {id, name, category, brand, quantity: quantityChange, price};

        this.analyticsApi.addEcommerceProduct(gaEcomProduct);
        this.analyticsApi.setEcommerceAction(gaEcomAction, {});

        this.updatePreviousState(item, currentItemQuantity);
      }
    }
  }

  private updatePreviousState(item: SingleItem | ItemBundle, quantity: number): void {
    const updateIndex = this.previousItemStates.findIndex((previousState) => previousState.itemId === item.id);

    if (updateIndex !== -1) {
      this.previousItemStates[updateIndex]['quantity'] = quantity;
    } else {
      this.previousItemStates.push({ itemId: item.id, quantity });
    }
  }

  private repopulatePreviousItemsState(): void {
    if (this.cart) {
      this.cart.cartItems.forEach((cartItem) => {
        this.previousItemStates.push({itemId: cartItem.item.id, quantity: cartItem.quantity });
      });
    }
  }

  private getCartQuantity(item: SingleItem | ItemBundle): number | null {
    const cartItems = this.cart.cartItems;

    if (!cartItems || !cartItems.length) {
      return null;
    }

    const cartItem: CartItem = cartItems.find((_cartItem: CartItem) => _cartItem.item.id === item.id);

    if (cartItem) {
      return cartItem.quantity;
    } else {
      return 0;
    }
  }

  private calculateItemPrice(item: SingleItem | ItemBundle): number {
    const cartItem = this.cart.cartItems.find((_cartItem) => _cartItem.item.id === item.id);
    let price = 0;

    if (item.type === ItemTypes.SINGLE_ITEM) {
      price = item.price;
    } else if (item.type === ItemTypes.ITEM_BUNDLE) {
      // If a bundle is added to the cart use price per head value, if it's modal is opened used it's displayed menu price
      price = (cartItem) ? cartItem.price / cartItem.quantity : item.price;
    }

    return parseFloat(price.toFixed(2));
  }

  private convertItemTypeToCategory(itemType: ItemType): string {
    let category = itemType.toString();

    if (itemType === ItemTypes.ITEM_BUNDLE) {
      category = 'bundle';
    } else if (itemType === ItemTypes.SINGLE_ITEM) {
      category = 'single';
    }

    return category;
  }

  /**
   * AnalyticsEcommerceAction cannot cast to GaEnhancedEcomAction as it has the additional CART_QUANTITY_CHANGE value.
   *
   * Converting 'CART_QUANTITY_CHANGE' to 'ADD' or 'REMOVE' removes the possibility of CART_QUANTITY_CHANGE being returned,
   * allowing typescript cast AnalyticsEcommerceAction to GaEnhancedEcomAction
   */
  private getGaEcomAction(ecommerceAction: AnalyticsEcommerceAction, previousQuantity: number, quantity: number): GaEnhancedEcomAction {
    if (ecommerceAction === AnalyticsEcommerceActions.CART_QUANTITY_CHANGE) {
      return (quantity < previousQuantity)
        ? AnalyticsEcommerceActions.REMOVE
        : AnalyticsEcommerceActions.ADD;
    } else {
      return ecommerceAction;
    }
  }
}
