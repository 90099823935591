/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ItemLabelServerSideAnalyticsEvent =
  'Open dialog'
  | 'Cancel'
  | 'Download PDF'
  | 'Pre download'
  | 'Print';

export class ItemLabelServerSideAnalyticsEvents {
  public static OPEN_DIALOG:                  ItemLabelServerSideAnalyticsEvent & 'Open dialog'     = 'Open dialog';
  public static CANCEL:                       ItemLabelServerSideAnalyticsEvent & 'Cancel'          = 'Cancel';
  public static DOWNLOAD_PDF:                 ItemLabelServerSideAnalyticsEvent & 'Download PDF'    = 'Download PDF';
  public static PRE_DOWNLOAD:                 ItemLabelServerSideAnalyticsEvent & 'Pre download'    = 'Pre download';
  public static PRINT:                        ItemLabelServerSideAnalyticsEvent & 'Print'           = 'Print';

  public static get values(): ItemLabelServerSideAnalyticsEvent[] {
    return [
      ItemLabelServerSideAnalyticsEvents.OPEN_DIALOG,
      ItemLabelServerSideAnalyticsEvents.CANCEL,
      ItemLabelServerSideAnalyticsEvents.DOWNLOAD_PDF,
      ItemLabelServerSideAnalyticsEvents.PRE_DOWNLOAD,
      ItemLabelServerSideAnalyticsEvents.PRINT,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ItemLabelServerSideAnalyticsEventEnum = typeof ItemLabelServerSideAnalyticsEvents;
