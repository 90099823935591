<footer
  class="footer"
  test-id="footer"
  analyticsId="mega-footer"
>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="footer__box footer__box--message">
          <p class="footer__message">
            Do you make incredible food that you want to share with the world? We want to hear from you!
          </p>
          <a
            href="/l/food-partner-sign-up"
            target="_parent"
            class="button button--ghost"
          >
            Sell food with us
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <nav class="footer__box">
          <p class="footer__boxtitle">
            Company
          </p>
          <ul class="footer__boxmenu">
            <li class="footer__boxitem">
              <a
                class="footer__boxlink button-link"
                href="/l/what-we-do"
                target="_parent"
              >
                What We Do
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/blog"
                target="_parent"
              >
                Blog
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/l/careers"
                target="_parent"
              >
                Careers
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/l/corporate-social-responsibility"
                target="_parent"
              >
                Social Responsibility
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <nav class="footer__box">
          <p class="footer__boxtitle">
            Catering
          </p>
          <ul class="footer__boxmenu">
            <li
              class="footer__boxitem"
              [class.footer__boxitem--active]="locationPanelActive"
              (clickOutside)="toggleLocationDropPanel(false)"
              test-id="locationPanel"
            >
              <div
                (click)="toggleLocationDropPanel(true)"
                class="footer__boxlink"
                role="button"
                tabindex="0"
                test-id="locationLink"
              >
                <span class="footer__boxlink-caption">
                  Location
                </span>
              </div>
              <div class="footer__drop-panel">
                <ul class="footer__drop-panel-list">
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/london" target="_parent">
                      London
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/manchester" target="_parent">
                      Manchester
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/leeds" target="_parent">
                      Leeds
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/birmingham" target="_parent">
                      Birmingham
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/liverpool" target="_parent">
                      Liverpool
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/reading" target="_parent">
                      Reading
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/edinburgh" target="_parent">
                      Edinburgh
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/glasgow" target="_parent">
                      Glasgow
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li
              class="footer__boxitem"
              [class.footer__boxitem--active]="eventTypePanelActive"
              (clickOutside)="toggleEventTypeDropPanel(false)"
              test-id="eventTypePanel"
            >
              <div
                (click)="toggleEventTypeDropPanel(true)"
                class="footer__boxlink"
                role="button"
                tabindex="0"
                analyticsId="event-type-link"
                test-id="eventTypeLink"
              >
                <span class="footer__boxlink-caption">
                  Event Type
                </span>
              </div>
              <div
                class="footer__drop-panel"
                analyticsId="event-types"
              >
                <ul class="footer__drop-panel-list">
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/breakfast"
                      target="_parent"
                      analyticsId="link"
                    >
                      Breakfast
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/events"
                      target="_parent"
                      analyticsId="link"
                    >
                      Events
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/lunch"
                      target="_parent"
                      analyticsId="link"
                    >
                      Lunch
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/meetings"
                      target="_parent"
                      analyticsId="link"
                    >
                      Meetings
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/office-party"
                      target="_parent"
                      analyticsId="link"
                    >
                      Office Party
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a
                      href="/l/corporate-office-catering/pop-up"
                      target="_parent"
                      analyticsId="link"
                    >
                      Pop-ups
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li
              class="footer__boxitem"
              [class.footer__boxitem--active]="foodTypePanelActive"
              test-id="foodTypePanel"
              (clickOutside)="toggleFoodTypeDropPanel(false)"
            >
              <div
                (click)="toggleFoodTypeDropPanel(true)"
                class="footer__boxlink"
                role="button"
                tabindex="0"
                test-id="foodTypeLink"
              >
                <span class="footer__boxlink-caption">
                  Food Type
                </span>
              </div>
              <div class="footer__drop-panel">
                <ul class="footer__drop-panel-list">
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/american-food" target="_parent">
                      American
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/british-food" target="_parent">
                      British
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/chinese-food" target="_parent">
                      Chinese
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/french-food" target="_parent">
                      French
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/greek-food" target="_parent">
                      Greek
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/indian-food" target="_parent">
                      Indian
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/italian-food" target="_parent">
                      Italian
                    </a>
                  </li>
                  <li class="footer__drop-panel-item">
                    <a href="/l/corporate-office-catering/mexican-food" target="_parent">
                      Mexican
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <nav class="footer__box">
          <p class="footer__boxtitle">
            Service
          </p>
          <ul class="footer__boxmenu">
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="https://help.citypantry.com/hc/en-us"
                target="_blank"
              >
                FAQ
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/l/terms-and-conditions-city-pantry"
                target="_blank"
              >
                Website Terms
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/l/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="/l/customer-terms"
                target="_blank"
              >
                Customer Terms
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <nav class="footer__box">
          <p class="footer__boxtitle">
            Connect
          </p>
          <ul class="footer__boxmenu">
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="https://www.facebook.com/JustEatforBusiness/"
              >
                <div class="footer__client-logo footer__client-logo--facebook"></div>
                <span>Facebook</span>
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="http://instagram.com/justeatforbusiness/"
              >
                <div class="footer__client-logo footer__client-logo--instagram"></div>
                <span>Instagram</span>
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="https://twitter.com/justeatbusiness"
              >
                <div class="footer__client-logo footer__client-logo--twitter"></div>
                <span>Twitter</span>
              </a>
            </li>
            <li class="footer__boxitem">
              <a
                class="footer__boxlink"
                href="https://www.linkedin.com/company/justeatforbusiness"
              >
                <div class="footer__client-logo footer__client-logo--linkedin"></div>
                <span>LinkedIn</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</footer>
