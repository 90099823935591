<div class="footer-bar">
  <div class="row align-items-center no-gutters">
    <div class="col-2">
      <button
        type="button"
        class="button-link button-link--bold"
        (click)="onCancel()"
        test-id="cancelButton"
      >Cancel</button>
    </div>

    <div class="col-3 text-right">
      <ng-content select="[item-modal-footer-slot=quantity]"></ng-content>
    </div>

    <div
      *ngIf="!hidePrices"
      class="col-2 col-md-4 text-right pr-small"
    >
      <h5
        class="m-none"
        test-id="subtotal"
      >
        <span class="d-none d-md-inline">Subtotal:</span>
        {{ subtotal | currency:'GBP' }}
      </h5>
    </div>

    <div
      class="col-5 text-right"
      [class.col-md-3]="!hidePrices"
    >
      <ng-content select="[item-modal-footer-slot=submitButton]"></ng-content>
    </div>
  </div>
  <div class="row no-gutters">
    <ng-content select="[item-modal-footer-slot=errors]"></ng-content>
  </div>
</div>
