import { Directive, ElementRef, Input, OnInit } from '@angular/core';

let globalIdCounter = 1000;

/**
 * Generates a new ID for an element associated to it.
 *
 * This way, we can be sure that the ID is globally unique for the entire HTML page,
 * regardless of other components rendered on it.
 *
 * To use it, simply attach the directive with an arbitrary (hopefully descriptive) string.
 * You can access the ID in the template by using the element ref (# notation) and accessing it via element.id.
 *
 * @example
 * <label [attr.for]="userName.id">User Name</label>
 * <input #userName generatedId="user-name" />
 */
@Directive({
  selector: '[generatedId]'
})
export class GeneratedIdDirective implements OnInit {

  @Input()
  public generatedId: string;

  constructor(
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    const prefix = this.generatedId ? `${ this.generatedId }_` : '_element_';
    const count = globalIdCounter++;

    const id = prefix + count.toString(36);

    if (this.elementRef.nativeElement) {
      this.elementRef.nativeElement.id = id;
    }
  }
}
