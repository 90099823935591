import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import { ErrorResponse } from '@citypantry/util-models';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminApi {
  constructor(
    private http: HttpClient
  ) {}

  public createStaffUser(firstName: string, lastName: string, email: string): Observable<Result<void>> {
    return this.http.post('/users/create-staff', { firstName, lastName, email }, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      mapTo<any, Result<void>>(Result.success()),
      catchError((err) => {
        if (err instanceof ErrorResponse) {
          return of(Result.failure(err.messages[0]));
        } else {
          return of(Result.failure(err));
        }
      })
    );
  }
}
