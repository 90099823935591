import { createAction, props, union } from '@ngrx/store';

export const HistoryActions = {
  setReferer: createAction(
    '[History] Set Referer',
    props<{ referer: string }>(),
  ),
};

const all = union(HistoryActions);
export type HistoryAction = typeof all;
