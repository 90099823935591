<!-- action="." must stay if we want Safari to show "Search" key instead of "Return" when the term input is focused -->
<form [formGroup]="form" action="">
  <div class="row">
    <div class="col col-12">

      <!-- Parameters -->
      <div class="panel panel--grey mb-3">
        <div class="box-title">
          Your requirements
        </div>
        <div class="panel__content">
          <div class="d-flex align-items-center mb-2">
            <i
              class="icon icon-user icon--medium icon--square text-color-stone-2"
              title="No. of people"
            ></i>
            <input
              type="number"
              min="1"
              class="form-input form-input--discreet"
              placeholder="No. of people"
              formControlName="headcount"
              e2e-test-id="headcountInput"
              analyticsId="headcount-input"
            >
          </div>
          <div class="d-flex align-items-center">
            <i
              class="icon icon-wallet icon--medium icon--square text-color-stone-2"
              title="Budget per person"
            ></i>
            <input
              type="number"
              step="1"
              min="1"
              class="form-input form-input--discreet"
              placeholder="Budget per person"
              formControlName="maxBudget"
              e2e-test-id="maxBudgetInput"
              analyticsId="max-budget-input"
            >
          </div>
        </div>
        <!-- Until Individual Choice search is supported on mobile, we hide the individual choice toggle on smaller screens -->
        <div class="d-none d-md-block inline panel__content panel__content--darker p-standard" *ngIf="isIndividualChoiceEnabled">
          <label class="toggle-input">
            <input
              type="checkbox"
              class="toggle-input__input"
              [checked]="false"
              (change)="onIndividualChoiceToggleChanged()"
              test-id="individualChoiceToggle"
              analyticsId="individual-choice-toggle"
            >
            <span
              class="toggle-input__toggle"
              e2e-test-id="individualChoiceToggle"
            ></span>

            <span class="toggle-input__label standout-text standout-text--bold">Group Ordering</span>
          </label>
          <i
            class="material-icons text-color-stone-2 ml-x-small"
            [tooltip]="icHelpTooltip"
            tooltipPosition="right"
            analyticsId="ic-help-icon"
          >help_outline</i>
          <ng-template
            #icHelpTooltip
          >
            <span>
              Let your colleagues choose their own meals by switching on our Group Ordering feature.
              Want to find out more?
              <a
                href="/l/group-ordering"
                target="_blank"
                class="link link--light link--small"
                analyticsId="ic-help-tooltip-link"
              >Click here</a>
            </span>
          </ng-template>
        </div>

        <div class="inline panel__content panel__content--darker p-standard" *ngIf="isWeWorkUser">
          <div class="vertically-center">
            <label class="toggle-input mr-x-small">
              <input
                type="checkbox"
                class="toggle-input__input"
                [checked]="isWeWorkModeEnabled"
                (change)="onWeWorkModeChanged($event.target.checked)"
                test-id="weworkToggle"
              >
              <span class="toggle-input__toggle"></span>

              <span class="toggle-input__label standout-text standout-text--bold">WeWork Friendly</span>
            </label>
            <span class="d-inline-flex" [tooltip]="tooltip" tooltipPosition="top">
              <i class="icon text-color-stone-2 cursor-default">
                help_outline
              </i>
              <ng-template #tooltip>
                A filter for WeWork friendly items available at your chosen date and time.
                <a href="/l/wework-friendly" target="_blank" class="link link--light link--small">Find out more.</a>
              </ng-template>
            </span>
          </div>
        </div>
      </div>

      <!-- Occasion -->
      <div class="panel panel--grey mb-3">
        <div
          class="box-title"
          (click)="showEvents = !showEvents"
          analyticsId="occasions-box"
        >
          Occasion
          <i
            class="icon icon--medium icon-button icon-button--white"
            [class.icon-minus-circle]="showEvents"
            [class.icon-plus-circle]="!showEvents"
          ></i>
        </div>
        <div
          class="panel__content search-filter-content"
          *ngIf="showEvents"
          formGroupName="events"
        >
          <label
            class="form-choice"
            *ngFor="let eventType of displayedEventTypes"
          >
            <input
              type="checkbox"
              class="form-choice__input"
              [formControlName]="eventType"
              analyticsId="occasions-checkbox"
              [analyticsData]="eventType"
            >
            <span class="form-choice__label">{{ eventType }}</span>
          </label>
          <button
            type="button"
            class="button button--ghost mt-3"
            *ngIf="displayedEventTypes.length !== eventTypes.length"
            (click)="showAllEvents()"
          >More options
          </button>
        </div>
      </div>

      <!-- Cuisines -->
      <div class="panel panel--grey mb-3">
        <div
          class="box-title"
          (click)="showCuisines = !showCuisines"
          analyticsId="cuisine-types-box"
        >
          Type of cuisine
          <i
            class="icon icon--medium icon-button icon-button--white"
            [class.icon-minus-circle]="showCuisines"
            [class.icon-plus-circle]="!showCuisines"
          ></i>
        </div>
        <div
          class="panel__content search-filter-content"
          *ngIf="showCuisines"
          formGroupName="cuisines"
        >
          <label
            class="form-choice"
            *ngFor="let cuisine of cuisineTypes"
          >
            <input
              type="checkbox"
              class="form-choice__input"
              [formControlName]="cuisine"
              analyticsId="cuisine-types-checkbox"
              [analyticsData]="cuisine"
            >
            <span class="form-choice__label">{{ cuisine }}</span>
          </label>
        </div>
      </div>

      <!-- Dietaries -->
      <div class="panel panel--grey mb-3">
        <div
          class="box-title"
          (click)="showDietaries = !showDietaries"
          analyticsId="dietary-concerns-box"
        >
          Dietary concerns
          <i
            class="icon icon--medium icon-button icon-button--white"
            [class.icon-minus-circle]="showDietaries"
            [class.icon-plus-circle]="!showDietaries"
          ></i>
        </div>
        <div
          class="panel__content search-filter-content"
          *ngIf="showDietaries"
          formGroupName="dietaries"
        >
          <label
            class="form-choice"
            *ngFor="let dietaryType of dietaryTypes"
          >
            <input
              type="checkbox"
              class="form-choice__input"
              [formControlName]="dietaryType"
              analyticsId="dietary-concerns-checkbox"
              [analyticsData]="dietaryType"
            >
            <span class="form-choice__label">{{ dietaryType }}</span>
          </label>
        </div>
      </div>

    </div>
  </div>
</form>
