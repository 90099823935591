import { OrderId, PaymentCard, PaymentCardId } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const PaymentActions = {

  payForIndividualChoiceOrder: createAction(
    '[Payment] Pay for an existing Individual Choice Order',
    props<{
      orderId: OrderId;
      cardId: PaymentCardId;
      threeDSecureEnrichedNonce: string;
    }>()
  ),

  paymentCardsFetch: createAction(
    '[Payment] Payment Cards Fetch'
  ),

  paymentCardsFetchSuccess: createAction(
    '[Payment API] Payment Cards Fetch Success',
    props<{ paymentCards: PaymentCard[] }>()
  ),
};

const all = union(PaymentActions);
export type PaymentAction = typeof all;
