import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalyticsAction, AnalyticsActions } from './analytics.actions';
import { AnalyticsApi } from './analytics.api';

@Injectable()
export class AnalyticsEffects {

  public trackPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.trackPage),
    tap(() => {
      this.analyticsApi.trackPage(this.router.url);
    })
  ), { dispatch: false });

  public trackEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.trackEvent),
    tap((action) => {
      const {
        action: eventAction,
        properties
      } = action;
      this.analyticsApi.trackEvent(eventAction, properties);
    })
  ), { dispatch: false });

  public trackCustomEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.trackCustomEvent),
    tap((action) => {
      const {
        action: eventAction,
        properties
      } = action;
      this.analyticsApi.trackCustomEvent(eventAction, properties);
    })
  ), { dispatch: false });

  public clearEcommerceData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.clearEcommerceData),
    tap(() => this.analyticsApi.clearEcommerceData())
  ), { dispatch: false });

  public triggerHotjar$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AnalyticsActions.triggerHotjar),
    tap((action) => {
      const event = action.event;
      this.analyticsApi.triggerHotjar(event);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions<AnalyticsAction>,
    private router: Router,
    private analyticsApi: AnalyticsApi,
  ) {}
}
