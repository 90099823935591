import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-individual-choice-toggle',
  template: `
    <label class="control-button control-button--emphasis">
      <div class="control-button__content">
        <div class="toggle-input">
          <input
            type="checkbox"
            class="toggle-input__input"
            [checked]="enabled"
            [disabled]="toggleDisabled"
            (change)="onToggle($event.target.checked)"
            test-id="input"
            analyticsId="ic-choice-mode-toggle"
          >
          <span class="toggle-input__toggle"></span>

          <span class="ml-small">Group Ordering</span>
        </div>
      </div>
    </label>
  `
})
export class SearchIndividualChoiceToggleComponent {

  @Input()
  public enabled: boolean;

  @Input()
  public toggleDisabled: boolean;

  @Output()
  public enabledChange: EventEmitter<boolean> = new EventEmitter();

  public onToggle(enabled: boolean): void {
    this.enabledChange.emit(enabled);
  }
}
