declare const StripePaymentMethodIdSymbol: unique symbol;
export type StripePaymentMethodId = string & {
  [StripePaymentMethodIdSymbol]: never;
};

export interface PaymentIntentSummary {
  clientSecret: string;
  name: string;
  email: string;
  stripePaymentMethodId: StripePaymentMethodId | null;
}

export function createPaymentIntentSummaryFromJson(json?: Partial<PaymentIntentSummary>): PaymentIntentSummary {
  json = json || {};
  const card: Partial<PaymentIntentSummary> = {};

  card.clientSecret = json.clientSecret;
  card.name = json.name;
  card.email = json.email;
  card.stripePaymentMethodId = json.stripePaymentMethodId || null;

  return card as PaymentIntentSummary;
}
