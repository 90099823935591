import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const whoOrderedWhatPageRules: AnalyticsRule[] = [
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-extras-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Edit Extra Portions',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'add-extras-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Add Extra Portions',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'repeat-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Repeat Order',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'invite-colleagues-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Invite Colleagues',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'cancel-order-button-container cancel-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Cancel Order',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters vendor-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Vendor Chosen',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters mark-as-away',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Mark as away',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters clear-away-status',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Clear "away" status',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters clear-choice',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Clear choice',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters order-on-behalf',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Order on their behalf',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters-search-field',
    events: 'focus',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Find colleague search',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'sort-eaters-dropdown',
    events: 'change',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Eater list sorted',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delete-eater-cart-dialog',
    events: {
      class: 'DialogComponent',
      eventName: 'confirm'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Clear Choice Confirmed',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delete-eater-cart-dialog',
    events: {
      class: 'DialogComponent',
      eventName: 'abort'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Clear Choice Cancelled',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'ic-order-created-close-modal',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Order created Popup closed',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'ic-order-created-share-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Order created share link clicked',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'additional-items top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Add extra portions tab viewed',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'eaters-list top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Eaters list tab viewed',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'repeat-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Repeat Order button',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'schedule-dialog interval-select',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      label: 'Repeat interval selected',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'schedule-dialog end-date-datepicker',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      label: 'End date picked'
    }
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'schedule-dialog dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      label: 'Cancel'
    }
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'schedule-dialog dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      label: 'Close'
    }
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'schedule-dialog dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      label: 'Confirm'
    }
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'cost-codes-required-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange',
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Cost codes required switched ${event.event ? 'ON' : 'OFF' }`
    })
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'order-status leave-feedback',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Leave Feedback button',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delivery-details edit-date-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Edit delivery date',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delivery-details edit-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Edit delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delivery-details add-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Add delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delivery-details save-contact-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Save delivery contact',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'delivery-details delivery-contact-picker',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Select delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup first-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `First name field entered`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup last-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Last name field entered`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Email field entered`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup phone-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Phone number field entered`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup email-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Email delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup sms-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `SMS delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup save',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Save new delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-delivery-contact-popup cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: `Cancel new delivery contact`,
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-date-popup close-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Close edit date popup',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-date-popup date-parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Change date in edit date popup',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-date-popup date-parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Change time in edit date popup',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-date-popup change-date-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Update delivery details',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'edit-date-popup find-new-vendor-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Find New Vendor',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'cancel-order-after-edit-popup keep-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Keep existing order',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'cancel-order-after-edit-popup close-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Close cancel order popup',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'cancel-order-after-edit-popup cancel-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Cancel order',
    },
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'order-status notify-colleagues-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      label: 'Notify colleagues button clicked',
      extraFields: {
        orderGroupId: event.data['notify-colleagues'].orderGroupId,
        selectedOrderCount: event.data['notify-colleagues-button'].selectedOrderCount,
        remainingOrderCount: event.data['notify-colleagues-button'].remainingOrderCount,
        totalOrderCount: event.data['notify-colleagues-button'].totalOrderCount,
      }
    })
  },
  {
    url: '/menus/customer/orders/eaters/:orderId',
    path: 'add-another-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EDIT_IC_ADD_VENDOR,
      label: 'Add another vendor button clicked'
    }
  },
];
