<div class="vertical-progress" *ngIf="trackingForOrders[0] as firstOrderTracking">
  <!-- Placed -->
  <div
    class="vertical-progress__step"
    [class.vertical-progress__step--active]="hasBeenPlaced() && !isPlacedStepComplete()"
    [class.vertical-progress__step--complete]="isPlacedStepComplete()"
    test-id="progressStep"
  >
    <div class="vertical-progress__content">
      <!-- In an IC order group, all orders have the same placedDate -->
      <app-tracking-progress-step
        [title]="'Placed'"
        [datetime]="firstOrderTracking.placedDate"
        [showBannerBody]="!anonymous && hasBeenPlaced() && !isPlacedStepComplete()"
        [showBannerButton]="isIndividualChoice && hasBeenPlaced() && !isPlacedStepComplete()"
      >
        <div banner-body>
          Thanks for your order!
          {{ isIndividualChoice ? 'Colleagues can choose food while the order is open.' : 'Once your order is finalised no further changes can be made.' }}
          <a
            class="link text-weight-medium"
            href="https://help.citypantry.com/hc/en-us/sections/360003929313-Ordering-Editing-Cancellations"
            target="_blank"
            analyticsId="order-faqs"
            test-id="link"
          >
            See Order FAQs
          </a>
        </div>
        <div banner-button>
          <div class="d-flex flex-column align-items-end mt-small">
            <button
              (click)="onColleagueOrdersClick()"
              class="button button--secondary"
              analyticsId="colleague-orders"
              test-id="colleagueOrdersButtonOnPlacedBanner"
            >
              Colleague Orders
            </button>
          </div>
        </div>
      </app-tracking-progress-step>
    </div>
  </div>
  <!-- Open -->
  <div
    *ngIf="isIndividualChoice"
    class="vertical-progress__step"
    [class.vertical-progress__step--active]="hasChoiceBeenOpened() && !hasChoiceBeenClosed()"
    [class.vertical-progress__step--complete]="hasChoiceBeenClosed()"
    test-id="progressStep"
  >
    <div class="vertical-progress__content">
      <app-tracking-progress-step
        [title]="'Open'"
        [datetime]="choiceOpenTime || firstOrderTracking.placedDate"
        [showBannerBody]="!anonymous && hasChoiceBeenOpened() && !hasChoiceBeenClosed()"
        [showBannerButton]="isIndividualChoice && hasChoiceBeenOpened() && !hasChoiceBeenClosed()"
      >
        <div banner-body>
          Colleagues can choose food now. Once your order is finalised, no further changes can be made.
          <a
            class="link text-weight-medium"
            href="https://help.citypantry.com/hc/en-us/sections/360003929313-Ordering-Editing-Cancellations"
            target="_blank"
            analyticsId="order-faqs"
            test-id="link"
          >
            See Order FAQs
          </a>
        </div>
        <div banner-button>
          <div class="d-flex flex-column align-items-end mt-small">
            <button
              (click)="onColleagueOrdersClick()"
              class="button button--secondary"
              analyticsId="colleague-orders"
              test-id="colleagueOrdersButtonOnOpenBanner"
            >
              Colleague Orders
            </button>
          </div>
        </div>
      </app-tracking-progress-step>
    </div>
  </div>
  <!-- Finalising/Finalised -->
  <div
    class="vertical-progress__step"
    [class.vertical-progress__step--active]="hasBeenFinalised() && !hasBeenOutForDelivery()"
    [class.vertical-progress__step--complete]="hasBeenOutForDelivery()"
    test-id="progressStep"
  >
    <div class="vertical-progress__content">
      <!-- In an IC order group, all orders have the same finalisationDate and deliveryContactFullName -->
      <app-tracking-progress-step
        [title]="hasBeenFinalised() ? 'Finalised' : 'Finalising'"
        [datetime]="firstOrderTracking.finalisationDate"
        [showBannerBody]="!anonymous && hasBeenFinalised() && !hasBeenOutForDelivery()"
      >
        <div banner-body>
          No further changes can be made to your order.
          We'll update {{ firstOrderTracking.deliveryContactFullName }} before your order is out for delivery.
          <a
            class="link text-weight-medium"
            href="https://help.citypantry.com/hc/en-us/sections/360003929293-Delivery"
            target="_blank"
            analyticsId="delivery-faqs"
            test-id="link"
          >
            See Delivery FAQs
          </a>
        </div>
      </app-tracking-progress-step>
    </div>
  </div>
  <!-- Out for delivery/Delivery -->
  <div
    class="vertical-progress__step"
    [class.vertical-progress__step--active]="hasBeenOutForDelivery() && !hasBeenDelivered()"
    [class.vertical-progress__step--complete]="hasBeenDelivered()"
    test-id="progressStep"
  >
    <div class="vertical-progress__content">
      <app-tracking-progress-step
        [title]="orderGroupSupportsTracking() ? 'Out for delivery' : 'Delivery'"
        [datetime]="hasBeenOutForDelivery() && isSingleVendor() && orderGroupSupportsTracking() ? firstOrderTracking.leftKitchenDate : null"
        [showBannerBody]="hasBeenOutForDelivery() && !hasBeenDelivered()"
        [showBannerButton]="!anonymous && !isIndividualChoice && orderGroupSupportsTracking() && hasBeenOutForDelivery() && !hasBeenDelivered()"
      >
        <div banner-datetime>
          <ng-container *ngIf="!hasBeenOutForDelivery() || !isSingleVendor() || (hasBeenOutForDelivery() && !orderGroupSupportsTracking())">
            {{ firstOrderTracking.requestedDeliveryDate | dateInEuropeLondon : 'ddd, D MMM YYYY' }}
          </ng-container>
        </div>
        <div banner-body>
          <div *ngFor="let orderTracking of trackingForOrders">
            <ng-container *ngIf="!orderSupportsTracking(orderTracking)">
              {{ orderTracking.vendorName }}{{ orderTracking.vendorLocationName && ' - ' + orderTracking.vendorLocationName || '' }} - No tracking available<br/>
              <ng-container *ngIf="isSingleVendor()">
                We will contact you if there are any issues. If you have any questions please
                <a
                  class="link--light"
                  (click)="openZopimChat();"
                  test-id="chatNowLink"
                >chat to us</a>.
              </ng-container>
            </ng-container>
            <ng-container *ngIf="orderSupportsTracking(orderTracking) && !hasBeenDelivered(orderTracking)">
              <ng-container *ngIf="isTrackingAvailableSoon(orderTracking); else trackingInProgress">
                {{ orderTracking.vendorName }}{{ orderTracking.vendorLocationName && ' - ' + orderTracking.vendorLocationName || '' }} - Picking up soon<br/>
              </ng-container>
              <ng-template #trackingInProgress>
                {{ orderTracking.vendorName }}{{ orderTracking.vendorLocationName && ' - ' + orderTracking.vendorLocationName || '' }} - Delivery expected {{ orderTracking.deliveryTracking.etaWindow }}
              </ng-template>
            </ng-container>
          </div>
        </div>
        <div banner-button>
          <button
            (click)="onTrackYourOrderClick()"
            class="button button--secondary "
            analyticsId="track-your-order"
            test-id="trackingButton"
          >
            Track your order
          </button>
        </div>
      </app-tracking-progress-step>
    </div>
  </div>
  <!-- Arriving/Delivered -->
  <div
    *ngIf="!isPantryPackageOrder"
    class="vertical-progress__step"
    [class.vertical-progress__step--active]="hasBeenDelivered()"
    test-id="progressStep"
  >
    <div class="vertical-progress__content">
      <app-tracking-progress-step
        [title]="hasBeenDelivered() ? 'Delivered' : 'Arriving'"
        [showBannerBody]="hasBeenDelivered() || (isIndividualChoice && !isSingleVendor() && hasBeenOutForDelivery())"
        [showBannerButton]="canLeaveOrderFeedback()"
        test-id="arrivingProgressStep"
      >
        <div banner-datetime>
          <div class="body-text text-color-stone-1 text-weight-medium" test-id="bodyText">
            Booked {{ firstOrderTracking.deliveryWindow | formatDeliveryWindow }}<br>
            {{ firstOrderTracking.requestedDeliveryDate | dateInEuropeLondon : 'ddd, D MMM YYYY' }}
          </div>
        </div>
        <div banner-body>
          <div *ngFor="let orderTracking of trackingForOrders">
            <div *ngIf="hasBeenDelivered(orderTracking)">
              {{ orderTracking.vendorName }}{{ orderTracking.vendorLocationName && ' - ' + orderTracking.vendorLocationName || '' }} - Arrived at {{ orderTracking.actualDeliveryDate | dateInEuropeLondon : 'HH:mm' }}
            </div>
          </div>
        </div>
        <div banner-button>
          <div class="d-flex flex-column align-items-end">
            <div>{{ feedbackSent ? "Thanks for leaving feedback" : "How was your order today?" }}</div>
            <div class="mt-small" *ngIf="isSingleVendor() && !feedbackSent">
              <a
                class="text-decoration-none"
                (click)="onFeedbackClick(true)"
                analyticsId="smile-link"
              >
                <button class="material-icon-button material-icon-button--light-ok mb-small" test-id="thumbsUp">
                  thumbs_up
                </button>
              </a>
              <a
                class="text-decoration-none ml-standard"
                (click)="onFeedbackClick(false)"
                analyticsId="frown-link"
              >
                <button class="material-icon-button material-icon-button--light-danger mb-small" test-id="thumbsDown">
                  thumbs_down
                </button>
              </a>
            </div>
            <div class="mt-small" *ngIf="!isSingleVendor()">
              <button
                (click)="onLeaveFeedbackButtonClick()"
                class="button"
                analyticsId="leave-feedback"
                test-id="leaveFeedback"
              >
                Leave Feedback
              </button>
            </div>
          </div>
        </div>
      </app-tracking-progress-step>
    </div>
  </div>
</div>
<div
  *ngIf="!anonymous && hasBeenPlaced() && !hasBeenFinalised() && !isCancelled()"
  class="text-banner text-right text-color-stone mt-medium"
  test-id="cancelAndEditOrderContainer"
>
  <div class="text-xl-right">
    No more changes may be made after your order is finalised
  </div>
  <a
    class="link text-weight-medium"
    (click)="onEditOrderClick()"
    analyticsId="edit-order"
    *ngIf="allowEditing()"
    test-id="editLink"
  >Edit order</a>
  <a
    class="link ml-large text-weight-medium"
    (click)="onCancelOrderClick()"
    analyticsId="cancel-order"
    *ngIf="!hasWorryFreePeriodEndDatePassed"
    test-id="cancelLink"
  >Cancel order</a>
</div>
