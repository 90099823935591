import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { objectToParams } from '@citypantry/util';
import {
  ConsolidatedInvoice,
  ConsolidatedInvoiceId,
  createConsolidatedInvoiceFromJson,
  createSingleInvoiceFromJson,
  OrderId,
  Page,
  SingleInvoice,
  SingleInvoiceId,
  SingleInvoiceStatus,
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgGridDatatablePaginateQuery } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApi {

  constructor(
    private http: HttpClient,
  ) { }

  public getSingleInvoices(query: AgGridDatatablePaginateQuery): Observable<Page<SingleInvoice>> {
    return this.http.get('/orders/customer-invoices', { params: objectToParams(query) }).pipe(
      map((response: Page<SingleInvoice>) => ({ ...response, items: response.items.map(createSingleInvoiceFromJson) }))
    );
  }

  public getInvoicesByOrder(orderId: OrderId): Observable<SingleInvoice[]> {
    return this.http.get<SingleInvoice[]>(`/orders/customer-invoices-by-order/${orderId}`);
  }

  public getConsolidatedInvoices(query: AgGridDatatablePaginateQuery): Observable<Page<ConsolidatedInvoice>> {
    return this.http.get('/orders/customer-consolidated-invoices', { params: objectToParams(query) }).pipe(
      map((response: Page<ConsolidatedInvoice>) => ({ ...response, items: response.items.map(createConsolidatedInvoiceFromJson) }))
    );
  }

  public changeSingleInvoiceStatus(invoiceId: SingleInvoiceId, status: SingleInvoiceStatus):
  Observable<{invoice: SingleInvoice, updated: boolean}> {
    return this.http.put<{invoice: SingleInvoice, updated: boolean}>(
      `/orders/customer-invoice/${invoiceId}/status`, { status }
    );
  }

  public downloadSingleInvoicesCsv(fields: string[], query: AgGridDatatablePaginateQuery | null): Observable<string> {
    const baseBody = { fields, pageSize: -1 };
    const body = query ? { ...baseBody, ...query } : baseBody;
    return this.http.post(`/orders/customer-invoices-create-csv`, body).pipe(
      map((response: { url: string }) => response.url)
    );
  }

  public downloadConsolidatedInvoicesCsv(fields: string[], query: AgGridDatatablePaginateQuery | null): Observable<string> {
    const baseBody = { fields, pageSize: -1 };
    const body = query ? { ...baseBody, ...query } : baseBody;
    return this.http.post(`/orders/customer-consolidated-invoices-create-csv`, body).pipe(
      map((response: { url: string }) => response.url)
    );
  }

  public regenerateConsolidatedInvoice(invoiceId: ConsolidatedInvoiceId): Observable<boolean> {
    return this.http.post<boolean>(`/orders/customer-consolidated-invoices/${invoiceId}`, null);
  }
}
