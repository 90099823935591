import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { safeUnsubscribe, TypedSimpleChanges } from '@citypantry/util';
import { CustomerLocation, SearchLocation } from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-delivery-location-form',
  templateUrl: './delivery-location-form.component.html'
})
export class DeliveryLocationFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public deliveryLocation: CustomerLocation | null;

  @Input()
  public formField: string = 'deliveryLocation';

  @Input()
  public fixedPostcode: string | null = null;

  public form: FormGroup;

  private onChanges: Subject<TypedSimpleChanges<DeliveryLocationFormComponent>> = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder, private parent: FormGroupDirective) {}

  public ngOnChanges(changes: TypedSimpleChanges<DeliveryLocationFormComponent>): void {
    this.onChanges.next(changes);
  }

  public shouldShowErrors(field: string): boolean {
    const control = this.form.get([field]);

    return control.touched && control.invalid;
  }

  public ngOnInit(): void {
    this.buildForm();
    if (this.deliveryLocation) {
      this.setForm(this.deliveryLocation);
    }
    this.parent.form.addControl(this.formField, this.form);

    this.subscriptions.push(
      this.onChanges.subscribe((changes) => {
        if (changes.deliveryLocation && changes.deliveryLocation.currentValue) {
          this.setForm(this.deliveryLocation);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(...this.subscriptions);
  }

  public getErrorsToDisplay(formControlName: string): ValidationErrors {
    const control = this.form.get(formControlName);

    return (control.touched) && control.invalid
      ? control.errors
      : {};
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      // Ordered to reflect order of appearance on the page
      name: ['', [ Validators.required, CustomValidators.entityName ]],
      companyName: ['', [ CustomValidators.entityName ]],
      addressLine2: ['', [ CustomValidators.entityName ]],
      addressLine1: ['', [  Validators.required, CustomValidators.entityName ]],
      addressLine3: ['', [ CustomValidators.entityName ]],
      city: ['', [ Validators.required, CustomValidators.entityName ]],
      postcode: [{
        value: this.fixedPostcode || '',
        disabled: !!this.fixedPostcode
      }, [ Validators.required, CustomValidators.postcode ]],
      country: ['', [ Validators.required, CustomValidators.entityName ]],
    });
  }

  private setForm(deliveryLocation: CustomerLocation | SearchLocation): void {
    let name = '';
    let companyName = '';
    if ('name' in deliveryLocation) {
      name = deliveryLocation.name;
    }
    if ('companyName' in deliveryLocation) {
      companyName = deliveryLocation.companyName;
    }
    if ('postcode' in deliveryLocation)  {
      this.form.get('postcode').disable();
    }
    this.form.setValue({
      name,
      companyName,
      addressLine2: deliveryLocation.addressLine2 ? deliveryLocation.addressLine2 : '',
      addressLine1: deliveryLocation.addressLine1 ? deliveryLocation.addressLine1 : '',
      addressLine3: deliveryLocation.addressLine3 ? deliveryLocation.addressLine3 : '',
      city: deliveryLocation.city ? deliveryLocation.city : '',
      postcode: deliveryLocation.postcode,
      country: deliveryLocation.country ? deliveryLocation.country : '',
    });
  }
}
