import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { OrderApi } from '@citypantry/shared-api';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { MediaQueryService, ScreenSizes, WindowRef } from '@citypantry/util-browser';
import { getOrderIdFromUrlPath } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChatWidgetType, ChatWidgetTypes } from './chat-widget-type.enum';

export const ACTIVATE_CHAT_WIDGET = 'activeChatWidget';

const CHECK_INTERVAL_FOR_ZOPIM_MS = 100;
const MAX_WAIT_TIME_FOR_ZOPIM_MS = 60_000;

export enum ZopimPopupType {
  BUTTON = 'button',
  BADGE = 'badge',
  WINDOW = 'window',
}

@Injectable({
  providedIn: 'root',
})
export class ChatWidgetService {
  constructor(
    private windowRef: WindowRef,
    private appConfig: AppConfig,
    private mediaQueryService: MediaQueryService,
    private store: Store<AppState>,
    private location: Location,
    private orderApi: OrderApi,
  ) {}

  /**
   * Initialise the chat widget if the user is a customer, orderer or vendor
   */
  public initialiseChat(): void {
    if (this.appConfig.INCLUDE_ZENDESK === false || this.mediaQueryService.isBelow(ScreenSizes.TABLET)) {
      return;
    }

    combineLatest([
      this.store.select(AuthSelectors.isCustomerOrOrderer),
      this.store.select(AuthSelectors.isVendor),
    ]).pipe(
      take(1)
    ).subscribe(([isCustomerOrOrderer, isVendor]) => {
      if (isCustomerOrOrderer || isVendor) {
        this.initZopim(($zopim) => {
          this.setChatApp(ChatWidgetTypes.ZOPIM);

          $zopim.livechat.setOnChatStart(() => {
            // tell the agent what order we're currently looking at
            this.getCurrentOrderHumanIds().subscribe((humanOrderIds: string[]) => {
              if (humanOrderIds.length) {
                $zopim.livechat.say(this.humanIdsToString(humanOrderIds));
              }
            }, () => {
              // do nothing
            });
          });
        });
      }

      return;
    });
  }

  public setChatApp(targetWidget: ChatWidgetType): void {
    switch (targetWidget) {
      case ChatWidgetTypes.ZOPIM:
        this.windowRef.nativeWindow.sessionStorage.setItem(ACTIVATE_CHAT_WIDGET, ChatWidgetTypes.ZOPIM);
        break;
      case ChatWidgetTypes.NONE:
        this.windowRef.nativeWindow.sessionStorage.setItem(ACTIVATE_CHAT_WIDGET, ChatWidgetTypes.NONE);
        break;
      default:
        this.windowRef.nativeWindow.sessionStorage.setItem(ACTIVATE_CHAT_WIDGET, ChatWidgetTypes.NONE);
    }
  }

  /* Zopim won't show a smaller popup when a larger one is already visible (e.g. showing the button when the badge is already displayed).
   * InitZopim shows the smallest popup by default. We then show() the argument widget */
  public openZopimChat(popupType: ZopimPopupType = ZopimPopupType.WINDOW): void {
    this.initZopim(($zopim: Zopim) => {
      $zopim.livechat[popupType].show();
      this.setChatApp(ChatWidgetTypes.ZOPIM);
    });
  }

  public setUserIdentity(fullName: string, email: string): void {
    this.waitForZopim(($zopim: Zopim) => {
      $zopim(() => {
        $zopim.livechat.setName(fullName);
        $zopim.livechat.setEmail(email);
      });
    });
  }

  public clearUserIdentity(): void {
    this.waitForZopim(($zopim: Zopim) => {
      $zopim(() => {
        if (!$zopim.livechat.isChatting()) {
          $zopim.livechat.clearAll();
        }
      });
    });
  }

  private initZopim(callback: ($zopim: any) => void = null): void {
    this.waitForZopim(($zopim: Zopim) => {
      $zopim(() => {
        $zopim.livechat.badge.hide();
        $zopim.livechat.button.show();

        if (this.windowRef.nativeWindow.sessionStorage.getItem('zopimExpanded') === 'true') {
          $zopim.livechat.window.show();
        }
        if (callback) {
          callback($zopim);
        }
      });
    });
  }

  /*
   * When the 3rd party Zendesk snippet loads it instantly makes a call to load a companion Zopim script.
   * This function allows us to wait until that script loads before making calls
   * If the snippet is not loaded within 60 seconds we give up.
   */
  private waitForZopim(callback: (zopim: Zopim) => void): void {
    let checkCount = 0;

    const getZopimFromWindow = () => {
      const $zopim = this.windowRef.nativeWindow['$zopim'];

      if ($zopim) {
        callback($zopim);
      }

      return $zopim;
    };

    // If Zopim is not loaded then poll it every 100ms until truthy or 60 seconds pass
    if (!getZopimFromWindow()) {
      const waitForWidget = setInterval(() => {
        if (checkCount > (MAX_WAIT_TIME_FOR_ZOPIM_MS / CHECK_INTERVAL_FOR_ZOPIM_MS)) {
          clearInterval(waitForWidget);
          return;
        }

        const $zopim = getZopimFromWindow();

        if (!$zopim) {
          checkCount++;
        } else {
          clearInterval(waitForWidget);
        }
      }, CHECK_INTERVAL_FOR_ZOPIM_MS);
    }
  }

  private getCurrentOrderHumanIds(): Observable<string[]> {
    const currentUrlPath = this.location.path(false).split('?', 2)[0];
    const orderId = getOrderIdFromUrlPath(currentUrlPath);    // single order OR IC order group

    if (orderId) {
      return this.orderApi.getOrderHumanIds(orderId);
    }
    return of([]);
  }

  private humanIdsToString(humanIds: string[]): string {
    humanIds = humanIds.map((id) => `#${ id }`);
    return `Order Id${humanIds.length > 1 ? 's' : ''}: ${humanIds.join(', ')}`;
  }
}

/**
 * ==============================================================================================
 * NOTE: No types for Zopim exist in the DefinitelyTyped collection.
 */
interface Zopim {
  (callback: () => void): void;
  livechat: {
    button: {
      hide(): void;
      show(): void;
    };
    badge: {
      hide(): void ;
      show(): void;
    };
    window: {
      hide(): void;
      show(): void;
    };
    say(message: string): void;
    setOnChatStart(callback: () => void): void;
    setName(name: string): void;
    setEmail(email: string): void;
    hideAll(): void;
    clearAll(): void;
    isChatting(): boolean;
  };
}
