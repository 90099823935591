/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type AnalyticsCategory =
  'Accepted Order Via URL'
  | 'Add eater to location modal'
  | 'Calendar view - Cancel Schedule modal'
  | 'Calendar view - Repeat Order modal'
  | 'Calendar view'
  | 'Cart'
  | 'Checkout choose location'
  | 'Checkout details'
  | 'Checkout payment'
  | 'Create new item modal'
  | 'Customer home page'
  | 'Customer order page'
  | 'Eater cart'
  | 'Eater sign up page'
  | 'Eaters menu'
  | 'Error Modal'
  | 'Feedback page'
  | 'Groups overview page'
  | 'Groups edit page'
  | 'Home page popup'
  | 'Home page video'
  | 'Home page'
  | 'IC Checkout - Edit Delivery'
  | 'IC Checkout - Edit Location'
  | 'IC Checkout - New Location'
  | 'IC Checkout - Delivery Location'
  | 'IC Checkout - Multiple Locations popup'
  | 'IC Checkout - Postcode Saved popup'
  | 'IC Order Event'
  | 'IC order summary box'
  | 'IC Search parameters'
  | 'IC Search Popup'
  | 'IC Who Ordered What - Repeat Order modal'
  | 'IC Who Ordered What'
  | 'Edit IC - Add vendor'
  | 'Edit IC - Cancel Add vendor'
  | 'Edit IC - Add vendor confirmation'
  | 'Just Eat Pay Codes page'
  | 'Item Bundle Editor'
  | 'Item Editor'
  | 'Login'
  | 'Lostbar'
  | 'Marketing campaign banner'
  | 'Meal issues modal'
  | 'Mega Footer'
  | 'Menu Editor'
  | 'MKP Search Page'
  | 'My meals page'
  | 'Optimize'
  | 'Order Tracking Page'
  | 'Popup search header'
  | 'Promotion banner'
  | 'Promotion panel'
  | 'Qualification form'
  | 'Search parameters'
  | 'Search popup'
  | 'Search recommendations'
  | 'Search results'
  | 'Share Cart Modal'
  | 'Single Item Editor'
  | 'Thank You page'
  | 'Top menu'
  | 'Vendor Items page'
  | 'Vendor order page'
  | 'Vendor orders page'
  | 'Vendor Recommendations'
  | 'Vendors menu'
  | 'What we do popup'
  | 'What we do'
  | 'First top up voucher reward';

export class AnalyticsCategories {
  public static ACCEPTED_ORDER_VIA_URL:                 AnalyticsCategory & 'Accepted Order Via URL'                   = 'Accepted Order Via URL';
  public static ADD_EATER_TO_LOCATION_MODAL:            AnalyticsCategory & 'Add eater to location modal'              = 'Add eater to location modal';
  public static CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL:    AnalyticsCategory & 'Calendar view - Cancel Schedule modal'    = 'Calendar view - Cancel Schedule modal';
  public static CALENDAR_VIEW_REPEAT_ORDER_MODAL:       AnalyticsCategory & 'Calendar view - Repeat Order modal'       = 'Calendar view - Repeat Order modal';
  public static CALENDAR_VIEW:                          AnalyticsCategory & 'Calendar view'                            = 'Calendar view';
  public static CART:                                   AnalyticsCategory & 'Cart'                                     = 'Cart';
  public static CHECKOUT_CHOOSE_LOCATION:               AnalyticsCategory & 'Checkout choose location'                 = 'Checkout choose location';
  public static CHECKOUT_DETAILS:                       AnalyticsCategory & 'Checkout details'                         = 'Checkout details';
  public static CHECKOUT_PAYMENT:                       AnalyticsCategory & 'Checkout payment'                         = 'Checkout payment';
  public static CREATE_NEW_ITEM_MODAL:                  AnalyticsCategory & 'Create new item modal'                    = 'Create new item modal';
  public static CUSTOMER_HOME_PAGE:                     AnalyticsCategory & 'Customer home page'                       = 'Customer home page';
  public static CUSTOMER_ORDER_PAGE:                    AnalyticsCategory & 'Customer order page'                      = 'Customer order page';
  public static EATER_CART:                             AnalyticsCategory & 'Eater cart'                               = 'Eater cart';
  public static EATER_SIGN_UP_PAGE:                     AnalyticsCategory & 'Eater sign up page'                       = 'Eater sign up page';
  public static EATERS_MENU:                            AnalyticsCategory & 'Eaters menu'                              = 'Eaters menu';
  public static ERROR_MODAL:                            AnalyticsCategory & 'Error Modal'                              = 'Error Modal';
  public static FEEDBACK_PAGE:                          AnalyticsCategory & 'Feedback page'                            = 'Feedback page';
  public static GROUPS_OVERVIEW_PAGE:                   AnalyticsCategory & 'Groups overview page'                     = 'Groups overview page';
  public static GROUPS_EDIT_PAGE:                       AnalyticsCategory & 'Groups edit page'                         = 'Groups edit page';
  public static HOME_PAGE_POPUP:                        AnalyticsCategory & 'Home page popup'                          = 'Home page popup';
  public static HOME_PAGE_VIDEO:                        AnalyticsCategory & 'Home page video'                          = 'Home page video';
  public static HOME_PAGE:                              AnalyticsCategory & 'Home page'                                = 'Home page';
  public static IC_CHECKOUT_EDIT_DELIVERY:              AnalyticsCategory & 'IC Checkout - Edit Delivery'              = 'IC Checkout - Edit Delivery';
  public static IC_CHECKOUT_EDIT_LOCATION:              AnalyticsCategory & 'IC Checkout - Edit Location'              = 'IC Checkout - Edit Location';
  public static IC_CHECKOUT_NEW_LOCATION:               AnalyticsCategory & 'IC Checkout - New Location'               = 'IC Checkout - New Location';
  public static IC_MULTIPLE_LOCATION_POPUP:             AnalyticsCategory & 'IC Checkout - Multiple Locations popup'   = 'IC Checkout - Multiple Locations popup';
  public static IC_POSTCODE_SAVED_POPUP:                AnalyticsCategory & 'IC Checkout - Postcode Saved popup'       = 'IC Checkout - Postcode Saved popup';
  public static IC_ORDER_EVENT:                         AnalyticsCategory & 'IC Order Event'                           = 'IC Order Event';
  public static IC_ORDER_SUMMARY_BOX:                   AnalyticsCategory & 'IC order summary box'                     = 'IC order summary box';
  public static IC_SEARCH_PARAMETERS:                   AnalyticsCategory & 'IC Search parameters'                     = 'IC Search parameters';
  public static IC_SEARCH_POPUP:                        AnalyticsCategory & 'IC Search Popup'                          = 'IC Search Popup';
  public static IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL: AnalyticsCategory & 'IC Who Ordered What - Repeat Order modal' = 'IC Who Ordered What - Repeat Order modal';
  public static IC_WHO_ORDERED_WHAT:                    AnalyticsCategory & 'IC Who Ordered What'                      = 'IC Who Ordered What';
  public static EDIT_IC_ADD_VENDOR:                     AnalyticsCategory & 'Edit IC - Add vendor'                     = 'Edit IC - Add vendor';
  public static EDIT_IC_CANCEL_ADD_VENDOR:              AnalyticsCategory & 'Edit IC - Cancel Add vendor'              = 'Edit IC - Cancel Add vendor';
  public static EDIT_IC_ADD_VENDOR_CONFIRMATION:        AnalyticsCategory & 'Edit IC - Add vendor confirmation'         = 'Edit IC - Add vendor confirmation';
  public static JUST_EAT_PAY_CODES_PAGE:                AnalyticsCategory & 'Just Eat Pay Codes page'                  = 'Just Eat Pay Codes page';
  public static ITEM_BUNDLE_EDITOR:                     AnalyticsCategory & 'Item Bundle Editor'                       = 'Item Bundle Editor';
  public static ITEM_EDITOR:                            AnalyticsCategory & 'Item Editor'                              = 'Item Editor';
  public static LOGIN:                                  AnalyticsCategory & 'Login'                                    = 'Login';
  public static LOSTBAR:                                AnalyticsCategory & 'Lostbar'                                  = 'Lostbar';
  public static MARKETING_CAMPAIGN_BANNER:              AnalyticsCategory & 'Marketing campaign banner'                = 'Marketing campaign banner';
  public static MEAL_ISSUES_MODAL:                      AnalyticsCategory & 'Meal issues modal'                        = 'Meal issues modal';
  public static MEGA_FOOTER:                            AnalyticsCategory & 'Mega Footer'                              = 'Mega Footer';
  public static MENU_EDITOR:                            AnalyticsCategory & 'Menu Editor'                              = 'Menu Editor';
  public static MKP_SEARCH_PAGE:                        AnalyticsCategory & 'MKP Search Page'                          = 'MKP Search Page';
  public static MY_MEALS:                               AnalyticsCategory & 'My meals page'                            = 'My meals page';
  public static OPTIMIZE:                               AnalyticsCategory & 'Optimize'                                 = 'Optimize';
  public static ORDER_TRACKING_PAGE:                    AnalyticsCategory & 'Order Tracking Page'                      = 'Order Tracking Page';
  public static POP_UP_HEADER:                          AnalyticsCategory & 'Popup search header'                      = 'Popup search header';
  public static PROMOTION_BANNER:                       AnalyticsCategory & 'Promotion banner'                         = 'Promotion banner';
  public static PROMOTION_PANEL:                        AnalyticsCategory & 'Promotion panel'                          = 'Promotion panel';
  public static QUALIFICATION_FORM:                     AnalyticsCategory & 'Qualification form'                       = 'Qualification form';
  public static SEARCH_PARAMETERS:                      AnalyticsCategory & 'Search parameters'                        = 'Search parameters';
  public static SEARCH_POPUP:                           AnalyticsCategory & 'Search popup'                             = 'Search popup';
  public static SEARCH_RECOMMENDATIONS:                 AnalyticsCategory & 'Search recommendations'                   = 'Search recommendations';
  public static SEARCH_RESULT:                          AnalyticsCategory & 'Search results'                           = 'Search results';
  public static SHARE_CART_MODAL:                       AnalyticsCategory & 'Share Cart Modal'                         = 'Share Cart Modal';
  public static SINGLE_ITEM_EDITOR:                     AnalyticsCategory & 'Single Item Editor'                       = 'Single Item Editor';
  public static THANK_YOU_PAGE:                         AnalyticsCategory & 'Thank You page'                           = 'Thank You page';
  public static TOP_MENU:                               AnalyticsCategory & 'Top menu'                                 = 'Top menu';
  public static VENDOR_ITEMS_PAGE:                      AnalyticsCategory & 'Vendor Items page'                        = 'Vendor Items page';
  public static VENDOR_ORDER_PAGE:                      AnalyticsCategory & 'Vendor order page'                        = 'Vendor order page';
  public static VENDOR_ORDERS_PAGE:                     AnalyticsCategory & 'Vendor orders page'                       = 'Vendor orders page';
  public static VENDOR_RECOMMENDATIONS:                 AnalyticsCategory & 'Vendor Recommendations'                   = 'Vendor Recommendations';
  public static VENDORS_MENU:                           AnalyticsCategory & 'Vendors menu'                             = 'Vendors menu';
  public static WHAT_WE_DO_POPUP:                       AnalyticsCategory & 'What we do popup'                         = 'What we do popup';
  public static WHAT_WE_DO:                             AnalyticsCategory & 'What we do'                               = 'What we do';

  // TODO CPD-14799: remove or clean up depending on the first-top-up-reward-voucher experiment outcome
  public static FIRST_TOP_UP_VOUCHER_REWARD:            AnalyticsCategory & 'First top up voucher reward'              = 'First top up voucher reward';

  public static get values(): AnalyticsCategory[] {
    return [
      AnalyticsCategories.ACCEPTED_ORDER_VIA_URL,
      AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      AnalyticsCategories.CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL,
      AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      AnalyticsCategories.CALENDAR_VIEW,
      AnalyticsCategories.CART,
      AnalyticsCategories.CHECKOUT_CHOOSE_LOCATION,
      AnalyticsCategories.CHECKOUT_DETAILS,
      AnalyticsCategories.CHECKOUT_PAYMENT,
      AnalyticsCategories.CREATE_NEW_ITEM_MODAL,
      AnalyticsCategories.CUSTOMER_HOME_PAGE,
      AnalyticsCategories.CUSTOMER_ORDER_PAGE,
      AnalyticsCategories.EATER_CART,
      AnalyticsCategories.EATER_SIGN_UP_PAGE,
      AnalyticsCategories.EATERS_MENU,
      AnalyticsCategories.ERROR_MODAL,
      AnalyticsCategories.FEEDBACK_PAGE,
      AnalyticsCategories.GROUPS_OVERVIEW_PAGE,
      AnalyticsCategories.GROUPS_EDIT_PAGE,
      AnalyticsCategories.HOME_PAGE_POPUP,
      AnalyticsCategories.HOME_PAGE_VIDEO,
      AnalyticsCategories.HOME_PAGE,
      AnalyticsCategories.IC_CHECKOUT_EDIT_DELIVERY,
      AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      AnalyticsCategories.IC_MULTIPLE_LOCATION_POPUP,
      AnalyticsCategories.IC_POSTCODE_SAVED_POPUP,
      AnalyticsCategories.IC_ORDER_EVENT,
      AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      AnalyticsCategories.IC_SEARCH_PARAMETERS,
      AnalyticsCategories.IC_SEARCH_POPUP,
      AnalyticsCategories.IC_WHO_ORDERED_WHAT_REPEAT_ORDER_MODAL,
      AnalyticsCategories.IC_WHO_ORDERED_WHAT,
      AnalyticsCategories.EDIT_IC_ADD_VENDOR,
      AnalyticsCategories.EDIT_IC_CANCEL_ADD_VENDOR,
      AnalyticsCategories.EDIT_IC_ADD_VENDOR_CONFIRMATION,
      AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      AnalyticsCategories.ITEM_BUNDLE_EDITOR,
      AnalyticsCategories.ITEM_EDITOR,
      AnalyticsCategories.LOGIN,
      AnalyticsCategories.LOSTBAR,
      AnalyticsCategories.MARKETING_CAMPAIGN_BANNER,
      AnalyticsCategories.MEAL_ISSUES_MODAL,
      AnalyticsCategories.MEGA_FOOTER,
      AnalyticsCategories.MENU_EDITOR,
      AnalyticsCategories.MKP_SEARCH_PAGE,
      AnalyticsCategories.MY_MEALS,
      AnalyticsCategories.OPTIMIZE,
      AnalyticsCategories.ORDER_TRACKING_PAGE,
      AnalyticsCategories.POP_UP_HEADER,
      AnalyticsCategories.PROMOTION_BANNER,
      AnalyticsCategories.PROMOTION_PANEL,
      AnalyticsCategories.QUALIFICATION_FORM,
      AnalyticsCategories.SEARCH_PARAMETERS,
      AnalyticsCategories.SEARCH_POPUP,
      AnalyticsCategories.SEARCH_RECOMMENDATIONS,
      AnalyticsCategories.SEARCH_RESULT,
      AnalyticsCategories.SHARE_CART_MODAL,
      AnalyticsCategories.SINGLE_ITEM_EDITOR,
      AnalyticsCategories.THANK_YOU_PAGE,
      AnalyticsCategories.TOP_MENU,
      AnalyticsCategories.VENDOR_ITEMS_PAGE,
      AnalyticsCategories.VENDOR_ORDER_PAGE,
      AnalyticsCategories.VENDOR_ORDERS_PAGE,
      AnalyticsCategories.VENDOR_RECOMMENDATIONS,
      AnalyticsCategories.VENDORS_MENU,
      AnalyticsCategories.WHAT_WE_DO_POPUP,
      AnalyticsCategories.WHAT_WE_DO,

      // TODO CPD-14799: remove or clean up depending on the first-top-up-reward-voucher experiment outcome
      AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type AnalyticsCategoryEnum = typeof AnalyticsCategories;
