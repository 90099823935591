/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type ServingStyle = 'individual' | 'buffet' | 'popup' | 'platter';

export class ServingStyles {
  public static INDIVIDUAL: ServingStyle & 'individual' = 'individual';
  public static BUFFET:     ServingStyle & 'buffet'     = 'buffet';
  public static POPUP:      ServingStyle & 'popup'      = 'popup';
  public static PLATTER:    ServingStyle & 'platter'    = 'platter';

  public static get values(): ServingStyle[] {
    return [
      ServingStyles.INDIVIDUAL,
      ServingStyles.BUFFET,
      ServingStyles.PLATTER,
      ServingStyles.POPUP,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ServingStyleEnum = typeof ServingStyles;
