import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { ErrorService } from '@citypantry/shared-error';
import { NativeWindow, WindowRef } from '@citypantry/util-browser';
import { HubspotFormId } from '@citypantry/util-models';

@Component({
  selector: 'app-hubspot-modal',
  templateUrl: './hubspot-modal.component.html',
  styleUrls: ['./hubspot-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HubspotModalComponent implements OnInit {
  @Input()
  public formId: HubspotFormId;

  @Input()
  public autoPopulateInputs: { [name: string]: any };

  @Input()
  public visible: boolean;

  @Input()
  public showLabels: boolean;

  @Input()
  public showErrorRollup: boolean;

  @Output()
  public formSubmitted: EventEmitter<void> = new EventEmitter();

  @Output()
  public hide: EventEmitter<void> = new EventEmitter();

  private nativeWindow: NativeWindow;

  constructor(
    private windowRef: WindowRef,
    private appConfig: AppConfig,
    private errorService: ErrorService,
  ) {
    this.nativeWindow = this.windowRef.nativeWindow;

    this.autoPopulateInputs = {};
    this.showLabels = true;
    this.showErrorRollup = true;
  }

  public ngOnInit(): void {
    if (this.appConfig.HUBSPOT_ENABLED === false || !this.nativeWindow.hbspt) {
      return;
    }

    if (this.onFormSubmit && !this.nativeWindow.jQuery) {
      // HubSpot forms require jQuery to exist to be able to hook into their "onFormSubmit" method,
      // which we need to use to be able to auto-populate input values.
      // As a workaround, this fix defines a fake jQuery in the window to keep HubSpot happy.
      // See: https://www.spark.app/blog/hubspot-on-form-submit-callbacks-without-jquery
      this.nativeWindow.jQuery = (selectorOrNode: string | Node) =>
        typeof selectorOrNode === 'string'
          ? document.querySelector(selectorOrNode)
          : selectorOrNode;
    }

    this.nativeWindow.hbspt.forms.create({
      css: '',
      portalId: this.appConfig.HUBSPOT_ID,
      formId: this.formId,
      target: '.js-hubspot-lead',
      submitButtonClass: 'button',
      onFormSubmit: this.onFormSubmit,
      onFormSubmitted: () => {
        this.formSubmitted.emit();
      },
    });
  }

  public onHide(): void {
    this.hide.emit();
  }

  /**
   * Map input values to the hubspot form fields.
   * If an expected field is not found log the error so we have visibility
   * when our code is out of sync with hubspot configurations.
   */
  private get onFormSubmit(): ((form: HTMLFormElement) => void) | null {
    if (!Object.keys(this.autoPopulateInputs).length) {
      return null;
    }

    return (form) => {
      Object.entries(this.autoPopulateInputs).forEach(([name, value]) => {
        const input = form.querySelector<HTMLInputElement>(`input[name=${name}]`);
        if (input) {
          input.value = value;
        } else {
          const message = `Cannot find input field with name "${name}" to auto-populate it. `
            + `Ensure it still exists in HubSpot form "${this.formId}"`;
          this.errorService.logError({ message });
        }
      });
    };
  }
}
