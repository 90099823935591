import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'dateOrOneDayWindowName'
})
export class DateOrOneDayWindowNamePipe implements PipeTransform {

  private static DEFAULT_FORMAT  = 'DD/MM/YYYY';

  public transform(date: Moment, dateFormat: string = DateOrOneDayWindowNamePipe.DEFAULT_FORMAT): string {
    if (!date) {
      return '';
    }
    let formattedValue: string;
    if (date.isSame(moment(), 'day')) {
      formattedValue = 'Today';
    } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
      formattedValue = 'Yesterday';
    } else if (date.isSame(moment().add(1, 'days'), 'day')) {
      formattedValue = 'Tomorrow';
    } else {
      formattedValue = date.format(dateFormat);
    }
    return formattedValue;
  }
}
