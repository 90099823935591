import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'todayOrTomorrow',
  pure: false,
})
export class TodayOrTomorrowPipe implements PipeTransform {
  private static NO_MESSAGE  = '[]';

  public transform(inputDate: moment.MomentInput): any {
    if (!inputDate) {
      return null;
    }

    const date = moment(inputDate);

    return date.tz('Europe/London').calendar(null, {
      sameDay: '[today]',
      nextDay : '[tomorrow]',
      lastDay: TodayOrTomorrowPipe.NO_MESSAGE,
      lastWeek : TodayOrTomorrowPipe.NO_MESSAGE,
      nextWeek : TodayOrTomorrowPipe.NO_MESSAGE,
      sameElse: TodayOrTomorrowPipe.NO_MESSAGE,
    });
  }
}
