import { VendorLocationId, VendorLocationSlug } from './vendor-location.model';

export interface SimpleVendorLocation {
  id: VendorLocationId;
  slug: VendorLocationSlug;
  name: string | null;
  postcode?: string | null;
  addressLine1?: string | null;
  city?: string | null;
}

export function createSimpleVendorLocationFromJson(json?: Partial<SimpleVendorLocation>): SimpleVendorLocation {
  json = json || {};
  const simpleVendorLocation: Partial<SimpleVendorLocation> = {};
  simpleVendorLocation.id = json.id;
  simpleVendorLocation.slug = json.slug;
  simpleVendorLocation.name = json.name || null;
  simpleVendorLocation.postcode = json.postcode || '';
  simpleVendorLocation.addressLine1 = json.addressLine1 || '';
  simpleVendorLocation.city = json.city || '';

  return simpleVendorLocation as SimpleVendorLocation;
}
