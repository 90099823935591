import { mapSelectors } from '@citypantry/util';
import { OrderTracking } from '@citypantry/util-models';
import { createFeatureSelector } from '@ngrx/store';
import { DeliveryDetailsState, FEATURE_STATE_NAME } from './delivery-details.state';

const getDeliveryDetailsState = createFeatureSelector<DeliveryDetailsState>(FEATURE_STATE_NAME);

const getTrackingForOrders = (state: DeliveryDetailsState): OrderTracking[] => state.trackingForOrders;

const isLoadingTracking = (state: DeliveryDetailsState): boolean => state.isLoading;

export const DeliveryDetailsSelectors = mapSelectors(getDeliveryDetailsState, {
  getTrackingForOrders,
  isLoadingTracking,
});
