import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[backgroundImage]'
})
export class BackgroundImageDirective implements OnChanges {

  @Input()
  public backgroundImage: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  public ngOnChanges(): void {
    const backgroundImageString = this.backgroundImage ? `url(${ this.backgroundImage })` : '';
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', backgroundImageString);
  }
}
