import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GoogleMaps {
  public readonly ready$: Observable<true>;
  private pendingReady: Promise<void>;
  private resolvePending: Function;

  constructor() {
    this.pendingReady = new Promise((resolve) => this.resolvePending = resolve);
    this.ready$ = from(this.pendingReady).pipe(mapTo(true));
  }

  public async ready(): Promise<void> {
    return this.pendingReady;
  }

  public onLibraryLoaded(): void {
    this.resolvePending();
  }
}
