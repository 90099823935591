import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePickerModule } from '@citypantry/components-date-picker';
import { FormComponentsModule } from '@citypantry/components-form';
import { LoadingComponentsModule } from '@citypantry/components-loading';
import { MenuModule } from '@citypantry/components-menu';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { NavigationComponentsModule } from '@citypantry/components-navigation';
import { PageBannerComponentsModule } from '@citypantry/components-page-banner';
import { ScrollComponentsModule } from '@citypantry/components-scroll';
import { SearchComponentsModule } from '@citypantry/components-search';
import { TextComponentsModule } from '@citypantry/components-text';
import { TooltipComponentsModule } from '@citypantry/components-tooltip';
import { AnalyticsComponentsModule, AnalyticsEcommerceComponentsModule } from '@citypantry/shared-analytics';
import { HubspotModalModule } from '@citypantry/shared-hubspot-modal';
import { MarketingCampaignModule } from '@citypantry/shared-marketing-campaign';
import { PromotionModule } from '@citypantry/shared-promotion';
import { RootModule } from '@citypantry/shared-root';
import { EffectsModule } from '@ngrx/effects';
import { COMPACT_INPUTS_INTERNAL } from './compact-input';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchComponent } from './search-component/search.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { SearchEffects } from './state/search.effects';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    RouterModule,
    EffectsModule.forFeature([
      SearchEffects
    ]),
    ModalComponentsModule,
    SearchComponentsModule,
    AnalyticsEcommerceComponentsModule,
    AnalyticsComponentsModule,
    TextComponentsModule,
    TooltipComponentsModule,
    DatePickerModule,
    LoadingComponentsModule,
    PageBannerComponentsModule,
    NavigationComponentsModule,
    PromotionModule,
    HubspotModalModule,
    ScrollComponentsModule,
    FormComponentsModule,
    RootModule,
    MarketingCampaignModule,
  ],
  declarations: [
    SearchPageComponent,
    SearchComponent,
    SearchBarComponent,
    COMPACT_INPUTS_INTERNAL,
  ],
})
export class SearchModule {
}
