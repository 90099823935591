<div
  class="cart-item"
  [class.cart-item--bundle]="isBundleCartItem"
  [class.cart-item--slide-on-hover]="!isBundleCartItem"
  [class.cart-item--removing]="isRemoving"
  [class.cart-item--has-error]="hasError"
  @remove
  (mouseleave)="leaveRemovingState()"
  test-id="root"
>
  <div class="cart-item__content">
    <ng-content></ng-content>

    <button
      class="cart-item__remove-icon"
      (click)="onRemoveClicked()"
      (mouseenter)="enterRemovingState()"
      (focus)="enterRemovingState()"
      (blur)="leaveRemovingState()"
      tabindex="0"
      test-id="removeIcon"
      aria-label="Remove Item"
    >
      <i class="material-icons" aria-hidden="true">remove_circle_outline</i>
    </button>
  </div>

  <div class="cart-item__remove-button">
    <!-- We have to disable tabbing to the remove button because for accessibility Chrome will jump it into view, shifting our component. -->
    <!-- Instead, we leave the remove icon focusable and clickable so that focusing it will trigger the same effect as hovering, -->
    <!-- and clicking it (pressing enter while focused) will remove the item as well. -->
    <button
      class="button button--small button--warning"
      tabindex="-1"
      (click)="onRemoveClicked()"
      ecommerceEventTrigger="click"
      [ecommerceActionType]="AnalyticsEcommerceActions.REMOVE"
      [ecommerceEventId]="AnalyticsEcommerceEventIds.MENU_CART_ITEM_REMOVE_BUTTON"
      test-id="removeButton"
      analyticsId="remove-vendor"
    >Remove</button>
  </div>
</div>
