export type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export class DaysOfWeek {
  public static MONDAY: DayOfWeek = 'Monday';
  public static TUESDAY: DayOfWeek = 'Tuesday';
  public static WEDNESDAY: DayOfWeek = 'Wednesday';
  public static THURSDAY: DayOfWeek = 'Thursday';
  public static FRIDAY: DayOfWeek = 'Friday';
  public static SATURDAY: DayOfWeek = 'Saturday';
  public static SUNDAY: DayOfWeek = 'Sunday';

  public static get values(): DayOfWeek[] {
    return [
      DaysOfWeek.MONDAY,
      DaysOfWeek.TUESDAY,
      DaysOfWeek.WEDNESDAY,
      DaysOfWeek.THURSDAY,
      DaysOfWeek.FRIDAY,
      DaysOfWeek.SATURDAY,
      DaysOfWeek.SUNDAY
    ];
  }
}

export type DayOfWeekEnum = typeof DaysOfWeek;
