import { RecommendationRequest, SearchResult } from '@citypantry/util-models';
import { RecommendationAction, RecommendationActions } from './recommendation.actions';

export interface RecommendationState {
  recommendedVendors: SearchResult[];
  recommendationRequest: RecommendationRequest;
}

export const INITIAL_STATE: RecommendationState = {
  recommendedVendors: [],
  recommendationRequest: null
};

export function reducer(state: RecommendationState = INITIAL_STATE, action: RecommendationAction): RecommendationState {
  switch (action.type) {
    case RecommendationActions.recommendedVendorsLoaded.type: {
      const recommendedVendors = action.recommendedVendors;
      return {
        ...state,
        recommendedVendors
      };
    }
    case RecommendationActions.setRecommendationRequest.type: {
      const recommendationRequest = action.recommendationRequest;
      return {
        ...state,
        recommendationRequest
      };
    }
    default:
      return state;
  }
}
