import { NgModule } from '@angular/core';
import { PaymentModule } from '@citypantry/shared-payment';
import { EffectsModule as NgrxEffectsModule } from '@ngrx/effects';
import { AccountEffects } from './account.effects';
import { CartEffects } from './cart.effects';
import { CheckoutEffects } from './checkout.effects';
import { FavouriteVendorsEffects } from './favourite-vendors.effects';
import { IndividualChoiceEffects } from './individual-choice.effects';
import { MealPlanEffects } from './meal-plan.effects';
import { MenuEffects } from './menu.effects';
import { RecommendationEffects } from './recommendation.effects';
import { SearchRecommendationEffects } from './search-recommendation.effects';

@NgModule({
  imports: [
    PaymentModule,

    NgrxEffectsModule.forFeature([
      AccountEffects,
      CartEffects,
      CheckoutEffects,
      FavouriteVendorsEffects,
      IndividualChoiceEffects,
      MealPlanEffects,
      MenuEffects,
      RecommendationEffects,
      SearchRecommendationEffects,
    ])
  ]
})
export class GlobalEffectsModule {}
