/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type NegativeDeliveryFeedbackTag =
  'Too early'
  | 'Too late'
  | 'Poor tracking'
  | 'Poor communication'
  | 'Driver politeness'
  | 'Equipment collection'
  | 'Rough Handling';

export class NegativeDeliveryFeedbackTags {
  public static TOO_EARLY:            NegativeDeliveryFeedbackTag & 'Too early'            = 'Too early';
  public static TOO_LATE:             NegativeDeliveryFeedbackTag & 'Too late'             = 'Too late';
  public static POOR_TRACKING:        NegativeDeliveryFeedbackTag & 'Poor tracking'        = 'Poor tracking';
  public static POOR_COMMUNICATION:   NegativeDeliveryFeedbackTag & 'Poor communication'   = 'Poor communication';
  public static DRIVER_POLITENESS:    NegativeDeliveryFeedbackTag & 'Driver politeness'    = 'Driver politeness';
  public static EQUIPMENT_COLLECTION: NegativeDeliveryFeedbackTag & 'Equipment collection' = 'Equipment collection';
  public static ROUGH_HANDLING:       NegativeDeliveryFeedbackTag & 'Rough Handling'       = 'Rough Handling';

  public static get values(): NegativeDeliveryFeedbackTag[] {
    return [
      NegativeDeliveryFeedbackTags.TOO_EARLY,
      NegativeDeliveryFeedbackTags.TOO_LATE,
      NegativeDeliveryFeedbackTags.POOR_TRACKING,
      NegativeDeliveryFeedbackTags.POOR_COMMUNICATION,
      NegativeDeliveryFeedbackTags.DRIVER_POLITENESS,
      NegativeDeliveryFeedbackTags.EQUIPMENT_COLLECTION,
      NegativeDeliveryFeedbackTags.ROUGH_HANDLING,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type NegativeDeliveryFeedbackTagEnum = typeof NegativeDeliveryFeedbackTags;
