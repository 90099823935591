import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventIdEnum,
  AnalyticsEcommerceEventIds,
} from '@citypantry/shared-analytics';
import {safeUnsubscribe, sum, zip} from '@citypantry/util';
import { CartManager } from '@citypantry/util-cart-manager';
import {
  Allergen,
  CartCustomItem,
  CartCustomItemOption,
  computeAllergens,
  computeDietaries,
  CustomItem,
  CustomItemOption,
  CustomItemSection,
  Dietaries,
  MajorCurrency,
  numberOfVisibleDietariesSet,
} from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';
import { Subscription } from 'rxjs';
import { OptionValue } from './custom-item-modal-form-section/custom-item-modal-form-section.component';

type CustomItemOptionPair = [{ quantity: number }, CustomItemOption];

export interface SubmitCustomItemModalEvent {
  quantity: number;
  selectedOptions: CartCustomItemOption[];
}

@Component({
  selector: 'app-custom-item-modal-form',
  templateUrl: './custom-item-modal-form.component.html',
})
export class CustomItemModalFormComponent implements OnInit, OnDestroy {

  @Input()
  public set item(value: CustomItem) {
    this._item = this.ensureItemHasOptionIndexes(value);
  }

  public get item(): CustomItem {
    return this._item;
  }

  @Input()
  public cartItem: CartCustomItem | null;

  @Input()
  public subtotal: MajorCurrency;

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public hidePrices: boolean;

  @Output()
  public submitItem: EventEmitter<SubmitCustomItemModalEvent> = new EventEmitter<SubmitCustomItemModalEvent>();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;
  public AnalyticsEcommerceEventIds: AnalyticsEcommerceEventIdEnum = AnalyticsEcommerceEventIds;

  public form: FormGroup;
  public wasSubmitted: boolean;

  public incompleteSections: CustomItemSection[]; // cached on changes to improve performance

  private _item: CustomItem;
  private sectionsValueSubscription: Subscription;

  constructor(
    private cdref: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    // Note: the form is created once and not updated when item and cartItem change.
    // It's causing problems with children components and their own formControls attached to parent form.
    // Ensure this component is torn down and recreated when the inputs need to change.

    const quantity = new FormControl(this.cartItem ? this.cartItem.quantity : this.item.minimumOrderQuantity, [
      Validators.required,
      CustomValidators.min(this.item.minimumOrderQuantity),
      CustomValidators.max(this.item.maximumOrderQuantity),
    ]);
    const customItemSections = new FormArray([]);

    this.form = new FormGroup({
      quantity,
      customItemSections,
    });

    this.sectionsValueSubscription = customItemSections.valueChanges.subscribe(() => this.onSectionsValueChanged());
    this.onSectionsValueChanged();

    // fixes ExpressionChangedAfterItHasBeenCheckedError
    // https://github.com/angular/angular/issues/23657
    this.cdref.detectChanges();
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.sectionsValueSubscription);
  }

  public shouldShowIncompleteSectionsErrors(): boolean {
    return this.incompleteSections && this.incompleteSections.length && this.wasSubmitted;
  }

  public shouldShowQuantityErrors(): boolean {
    return this.form.controls.quantity.invalid && this.wasSubmitted;
  }

  public getCartItemSelectedOptions(): CartCustomItemOption[] {
    return this.cartItem && this.cartItem.selectedOptions || [];
  }

  public shouldShowAllergens(): boolean {
    return this.item
      && this.getAggregatedAllergens().length > 0;
  }

  public shouldShowKcal(): boolean {
    let optionsWithCalories = false;

    if (this.getSelectedOptions().length) {
      optionsWithCalories = this.getSelectedOptions().some((el) => el.kcal !== null);
    }

    return this.item.baseItemKcal > 0 || this.item.baseItemKcal === 0 || optionsWithCalories;
  }

  public calculateKcalTotal(): number {
    let optionsTotal = 0;

    if (this.getSelectedOptions().length) {
      optionsTotal += this.pairedFormOptionsWithItemOptions()
        .filter(([{ quantity }, { kcal }]) => kcal !== null && quantity >= 1)
        .map(([{ quantity }, { kcal }]) => quantity * kcal)
        .reduce(sum(), 0);
    }

    return this.item.baseItemKcal + optionsTotal;
  }

  public hasDietaries(): boolean {
    return numberOfVisibleDietariesSet(this.getAggregatedDietaries()) > 0;
  }

  public calculateItemTotal(): number {
    const formValue = this.form.value;
    const cartOptions = this.createCartOptionsFromSelectedItemOptions();
    return CartManager.calculateCustomItemPrice(this.item, formValue.quantity, cartOptions);
  }

  public getAggregatedAllergens(): Allergen[] {
    return computeAllergens(this.item.allergens, this.getSelectedOptions());
  }

  public getAggregatedDietaries(): Dietaries {
    return computeDietaries(this.item.possibleDietaries, this.getSelectedOptions());
  }

  public isItemAgeRestricted(): boolean {
    return this.item && (this.item.ageRestricted || this.getSelectedOptions().some((option) => option.ageRestricted));
  }

  public onSubmitItem(): void {
    if (!this.form.valid) {
      this.wasSubmitted = true;
      return;
    }
    const formValue = this.form.value;
    this.submitItem.emit({
      quantity: formValue.quantity,
      selectedOptions: this.createCartOptionsFromSelectedItemOptions(),
    });
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public getSelectedOptions(): CustomItemOption[] {
    return this.pairedFormOptionsWithItemOptions()
      .filter(([{ quantity }]) => quantity >= 1)
      .map(([, option]) => option);
  }

  private onSectionsValueChanged(): void {
    this.incompleteSections = this.item.sections.filter((section, index) => {
      const form = this.form.get(['customItemSections', index]);
      return form && form.errors && form.errors.minimumOptionsSelected;
    });
  }

  /**
   * Pairs form quantity values for custom item options with associated
   * instance of CustomItemOption from CustomItem model
   */
  private pairedFormOptionsWithItemOptions(): CustomItemOptionPair[] {
    const itemOptions: CustomItemOption[] = [].concat(
      ...this.item.sections.map(({ options }) => options),
    );

    const customItemSections: { options: OptionValue[] }[] = this.form.value.customItemSections;
    const optionFormValues: OptionValue[] = [].concat(
      ...customItemSections.map(({ options }) => options),
    );
    return optionFormValues.map(zip(itemOptions));
  }

  private createCartOptionsFromSelectedItemOptions(): CartCustomItemOption[] {
    return this.pairedFormOptionsWithItemOptions()
      .map(([{ quantity }, { optionIndex }]): CartCustomItemOption => ({
        optionIndex,
        quantity,
      }));
  }

  /**
   * This call is necessary to ensure that when a CustomItem is previewed (at which point
   * it will not have any OptionIndex values because those are generated when converting to Immutable),
   * the form still works correctly.
   * To achieve this we simply assign custom option indexes ourselves.
   *
   * @param item An item to be used in the form
   * @return A copy of this item with all optionIndexes assigned to a number
   *
   * @see CPD-12106 CPD-10899
   */
  private ensureItemHasOptionIndexes(item: CustomItem): CustomItem {
    // We can assume that an item either has all optionIndex values or none of them
    if (item.sections.length &&
      item.sections[0].options.length &&
      typeof item.sections[0].options[0].optionIndex === 'number'
    ) {
      return item;
    }

    let optionIndex = 1;
    function addOptionIndex(option: CustomItemOption): CustomItemOption {
      if (typeof option.optionIndex === 'number') {
        throw new Error('Unexpected: Item is missing some but not all optionIndex values');
      }
      return {
        ...option,
        optionIndex: optionIndex++,
      };
    }

    return {
      ...item,
      sections: item.sections.map((section) => ({
        ...section,
        options: section.options.map(addOptionIndex),
      })),
    };
  }
}
