import { FormControl, ValidatorFn } from '@angular/forms';

// http://i18napis.appspot.com/address/data/GB
/* eslint-disable max-len */
const POSTCODE_REGEX = /^(GIR ?0AA|((AB|AL|B|BA|BB|BD|BF|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]? ?\d[ABD-HJLN-UW-Z]{2}))|BFPO ?\d{1,4})$/i;

export const isPostcodeValid = (inputPostcode: string = ''): boolean => {
  if (!inputPostcode) {
    return true;
  }

  const sanitizedPostcode = inputPostcode.replace(/[\s\t]+/g, '');
  return sanitizedPostcode && POSTCODE_REGEX.test(sanitizedPostcode);
};

export const postcode: ValidatorFn =
  (control: FormControl): { [key: string]: any } => {
    return isPostcodeValid(control.value) ?
      null :
      { postcode: true };
  };
