import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { WindowRef, computedStyle } from '@citypantry/util-browser';

export type StickyState = 'none' | 'top' | 'bottom';

interface StyleProps {
  offsetTop?: number;
  [key: string]: string | number;
}

const SCROLL_END_DEBOUNCE_TIME_MS = 100;
const RESIZE_END_DEBOUNCE_TIME_MS = 100;

@Directive({
  selector: '[sticky]'
})
export class StickyDirective implements AfterViewInit, OnDestroy {

  /**
   * Used for attaching the directive. Can be used to disable sticky behaviour, if set to `false` (strict checking, not just falsy)
   */
  @Input()
  public sticky: boolean;

  /**
   * CSS selector for attaching the sticky element to another unique element.
   * If set, will trigger sticky when the element hits that selector's element rather than the top of the window.
   */
  @Input()
  public stickyAfter: string;

  /**
   * Whether to stick the element to the bottom of its parent when the parent bottom scrolls up past the viewport.
   * Defaults to true. When set to a falsy value, only the top of the parent scrolling out of or into view will trigger a stick/unstick.
   */
  @Input()
  public stickToBottom: boolean;

  @Output()
  public stickied: EventEmitter<StickyState> = new EventEmitter();

  private element: HTMLElement;
  private parent: HTMLElement;
  private stickyAfterElement: HTMLElement | null;
  private fillerElement: HTMLElement | null;
  private originalDimensions: StyleProps;
  private mutationObserver: MutationObserver | undefined;

  private scrollEndTimeout: number;
  private resizeEndTimeout: number;

  private stickyState: StickyState;

  constructor(
    element: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private window: WindowRef,
    private zone: NgZone
  ) {
    this.element = element.nativeElement;
    this.parent = this.element.parentElement;
  }

  public ngAfterViewInit(): void {
    this.stickyAfterElement = this.stickyAfter ? this.document.querySelector(this.stickyAfter) as HTMLElement : null;

    this.renderer.setStyle(this.element, 'box-sizing', 'border-box');

    // Set the parent position to relative if it's not already one of the "relative-ish" types.
    const allowedPositions = ['absolute', 'fixed', 'relative'];
    const parentElPosition = computedStyle(this.document, this.parent, 'position');
    if (allowedPositions.indexOf(parentElPosition) === -1) { // inherit, initial, static, unset
      this.renderer.setStyle(this.parent, 'position', 'relative');
    }

    this.originalDimensions = this.computeOriginalDimensions();

    this.handleScroll();
  }

  public ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  @HostListener('window:scroll')
  public handleScroll(): void {
    const isScrolling = !!this.scrollEndTimeout;
    if (this.scrollEndTimeout) {
      clearTimeout(this.scrollEndTimeout);
    }
    this.scrollEndTimeout = setTimeout(() => {
      this.scrollEndTimeout = null;
    }, SCROLL_END_DEBOUNCE_TIME_MS) as any as number; // The linter gets confused about the types here

    if (!isScrolling) {
      // start scrolling
      this.startScrolling();
    }
  }

  @HostListener('window:resize')
  @HostListener('window:orientationChange')
  public handleWindowResize(): void {
    if (this.resizeEndTimeout) {
      clearTimeout(this.resizeEndTimeout);
    }
    this.resizeEndTimeout = setTimeout(() => {
      this.recomputeAllStyles();
      this.resizeEndTimeout = null;
    }, RESIZE_END_DEBOUNCE_TIME_MS) as any as number; // The linter gets confused about the types here
  }

  private recomputeAllStyles(): void {
    this.unsetAllStyles();

    setTimeout(() => {
      this.originalDimensions = this.computeOriginalDimensions();
      this.updateStickyState(true);
    });
  }

  private startScrolling(): void {
    // In each animation frame, we updateStickyState, as long as
    // a) we are scrolling (this.scrollEndTimeout is set) and
    // b) we are not resizing (this.resizeEndTimeout is not set).

    const tick = () => {
      if (!this.scrollEndTimeout) {
        // If we are not scrolling, we terminate the animation loop.
        return;
      }
      if (!this.resizeEndTimeout) { // While we're resizing, don't do anything. But don't stop the loop, just skip this instance
        this.zone.run(() => {
          this.updateStickyState();
        });
      }

      this.window.nativeWindow.requestAnimationFrame(tick);
    };

    this.zone.runOutsideAngular(() => {
      // Start off the animation loop
      this.window.nativeWindow.requestAnimationFrame(tick);
    });
  }

  private unsetAllStyles(): void {
    if (this.fillerElement) {
      this.renderer.removeChild(this.parent, this.fillerElement);
      this.fillerElement = null;
    }

    // Unset all styles that are set elsewhere, so we can recompute the original dimensions
    ['position', 'float', 'top', 'bottom', 'width', 'left', 'right']
      .forEach((style) => {
        this.renderer.removeStyle(this.element, style);
      });
  }

  private updateStickyState(force?: boolean): void {
    let originalDimensionsAreFresh = false;
    if (!this.originalDimensions) {
      this.originalDimensions = this.computeOriginalDimensions();
      originalDimensionsAreFresh = true;
    }

    const parentRect: DOMRect = this.parent.getBoundingClientRect();
    const stickyOffsetTop = this.getStickyOffsetTop();
    const newStickyState = this.determineStickyState(parentRect, stickyOffsetTop);

    if (this.stickyState === 'none' && newStickyState !== 'none' && !originalDimensionsAreFresh) {
      // Ensure original dimensions are always up-to-date whenever we're not scrolling
      this.originalDimensions = this.computeOriginalDimensions();
    }

    if (!force && newStickyState === this.stickyState) {
      return;
    }
    const posX = this.getXPosition(parentRect);

    this.updateStylesForStickyState(newStickyState, posX, stickyOffsetTop);
    this.setCurrentStickyState(newStickyState);
  }

  private updateStylesForStickyState(newStickyState: StickyState, posX: StyleProps, stickyOffsetTop: number): void {
    if (newStickyState === 'bottom') {
      const floatAdjustment =
        this.originalDimensions.float === 'right' ? { right: 0 } :
          this.originalDimensions.float === 'left' ? { left: 0 } : {};

      this.setStyles(this.element, {
        position: 'absolute',
        float: 'none',
        top: 'inherit',
        bottom: 0,
        ...posX,
        ...floatAdjustment
      });
    } else if (newStickyState === 'top') {
      // if not floating, add an empty filler element, since the original elements becomes 'fixed'
      if (!this.fillerElement && this.originalDimensions.float !== 'left' && this.originalDimensions.float !== 'right') {
        this.fillerElement = this.renderer.createElement('div');
        this.renderer.setStyle(this.fillerElement, 'height', `${this.element.offsetHeight}px`);
        this.renderer.insertBefore(this.parent, this.fillerElement, this.element);
      }

      this.setStyles(this.element, {
        position: 'fixed', // fixed is a lot smoother than absolute
        float: 'none',
        top: `${stickyOffsetTop}px`,
        bottom: 'inherit',
        ...posX,
      });
    } else { // 'none'

      if (this.fillerElement) {
        this.renderer.removeChild(this.parent, this.fillerElement);
        this.fillerElement = null;
      }
      this.setStyles(this.element, {
        position: this.originalDimensions.position,
        float: this.originalDimensions.float,
        top: this.originalDimensions.top,
        bottom: this.originalDimensions.bottom,
        width: this.originalDimensions.width,
        left: this.originalDimensions.left,
        ...posX
      });
    }
  }

  private setCurrentStickyState(state: StickyState): void {
    if (state !== this.stickyState) {
      this.stickyState = state;
      this.stickied.emit(state);
    }
  }

  private determineStickyState(parentRect: DOMRect, stickyOffsetTop: number): StickyState {
    // I'm not 100% sure this will work correctly in all cases, but it works in all cases I've tested.
    // If it starts failing (e.g. on short elements or ones with weird padding), this block might be the cause.
    const clientRect = this.element.getBoundingClientRect();
    if (parentRect.height === clientRect.height) {
      return 'none'; // No scrolling if the elements are the same height
    }

    if ((this.originalDimensions.marginTop as number) + (this.originalDimensions.marginBottom as number) +
      (this.originalDimensions.height as number) + stickyOffsetTop >= parentRect.bottom) {
      if (typeof this.stickToBottom !== 'undefined') {
        return this.stickToBottom ? 'bottom' : 'top'; // Leave at top if stickToBottom is false
      }
      return 'bottom';
    } else if (parentRect.top * -1 + (this.originalDimensions.marginTop as number) + stickyOffsetTop > this.originalDimensions.offsetTop) {
      return 'top';
    } else {
      return 'none';
    }
  }

  private getStickyOffsetTop(): number {
    return this.stickyAfterElement ? this.stickyAfterElement.getBoundingClientRect().bottom : 0;
  }

  private getXPosition(parentRect: DOMRect): StyleProps {
    const bodyRect: DOMRect = this.document.body.getBoundingClientRect();

    if (this.originalDimensions.float === 'right') {
      const right = bodyRect.right - parentRect.right + (this.originalDimensions.marginRight as number);
      return { right: `${right}px` };
    } else if (this.originalDimensions.float === 'left') {
      const left = parentRect.left - bodyRect.left + (this.originalDimensions.marginLeft as number);
      return { left: `${left}px` };
    } else {
      return { width: `${parentRect.width}px` };
    }
  }

  private setStyles(element: HTMLElement, styles: StyleProps): void {
    for (const prop in styles) {
      if (styles.hasOwnProperty(prop)) {
        this.renderer.setStyle(element, prop, styles[prop]);
      }
    }
  }

  private computeOriginalDimensions(): StyleProps {
    return {
      position: computedStyle(this.document, this.element, 'position'),
      float: computedStyle(this.document, this.element, 'float'),
      top: computedStyle(this.document, this.element, 'top'),
      bottom: computedStyle(this.document, this.element, 'bottom'),
      left: computedStyle(this.document, this.element, 'left'),
      width: computedStyle(this.document, this.element, 'width'),
      height: this.element.getBoundingClientRect().height,
      offsetTop: this.element.offsetTop || 0,
      offsetLeft: this.element.offsetLeft || 0,
      marginTop: parseInt(computedStyle(this.document, this.element, 'marginTop'), 10) || 0,
      marginBottom: parseInt(computedStyle(this.document, this.element, 'marginBottom'), 10) || 0,
      marginLeft: parseInt(computedStyle(this.document, this.element, 'marginLeft'), 10) || 0,
      marginRight: parseInt(computedStyle(this.document, this.element, 'marginLeft'), 10) || 0,
    };
  }
}
