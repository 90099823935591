import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppState } from '@citypantry/state';
import { OrderId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { DeliveryDetailsActions } from './delivery-details.actions';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDetailsGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean {
    const activeRoute = next.firstChild || next;
    const ordersIds = [activeRoute.paramMap.get('orderId') as unknown as OrderId];

    this.store.dispatch(DeliveryDetailsActions.loadTrackingForOrders({ ordersIds }));

    return true;
  }
}
