import { InjectionToken } from '@angular/core';
import { IllustrativeIcons } from '@citypantry/util-enums';
import moment from 'moment';
import { MarketingCampaignComponentNames } from './marketing-campaign-component-name.enum';
import { MarketingCampaignNames } from './marketing-campaign-name.enum';
import { MarketingCampaignModel } from './marketing-campaign.model';

export const MARKETING_CAMPAIGNS = new InjectionToken<MarketingCampaignModel[]>('marketingCampaignContent');

/* eslint-disable max-len */ // Content can be long
export const marketingCampaignContent: MarketingCampaignModel[] = [
  {
    marketingCampaignName: MarketingCampaignNames.VEGANUARY,
    analyticsPrefix: 'veganuary-2022',
    activeFrom: moment.tz('2022-01-04', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2022-01-31', 'Europe/London').endOf('day'),
    activeComponents: [],
  },
  {
    marketingCampaignName: MarketingCampaignNames.VALENTINES_DAY,
    analyticsPrefix: 'valentines-day-2022',
    activeFrom: moment.tz('2022-02-01', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2022-02-13', 'Europe/London').endOf('day'),
    activeComponents: [],
  },
  {
    marketingCampaignName: MarketingCampaignNames.PANCAKE_DAY,
    analyticsPrefix: 'pancake-day-2022',
    activeFrom: moment.tz('2022-02-15', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2022-02-28', 'Europe/London').endOf('day'),
    activeComponents: [],
  },
  {
    marketingCampaignName: MarketingCampaignNames.INTERNATIONAL_WOMENS_DAY,
    analyticsPrefix: 'international-womens-day-2022',
    activeFrom: moment.tz('2022-03-02', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2022-03-07', 'Europe/London').endOf('day'),
    activeComponents: [],
  },
  {
    marketingCampaignName: MarketingCampaignNames.EASTER,
    analyticsPrefix: 'easter-2021',
    activeFrom: moment.tz('2021-03-01', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-03-31', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
        body1: 'Celebrate Easter with your remote teams this spring with our special seasonal Pantry Packages',
        ctaText: 'Discover hampers',
        ctaLink: '/l/pantry-packages/occasion/easter?occasion=easter_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Want to send something special to your teams at home this Easter?',
        ctaText: 'Contact us',
        ctaLink: '/l/easter-2021-form',
        openLinkInNewTab: true,
        body2: ' to get started.',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'Easter 2021',
        ctaLink: '/l/pantry-packages/occasion/easter?occasion=easter_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.EASTER,
        title: 'Celebrate Easter with your teams@home',
        body1: 'Send your remote teams some tasty Easter-themed treats and hampers this spring!',
        ctaText: 'Contact us',
        ctaLink: '/l/easter-2021-form',
        openLinkInNewTab: true,
        body2: ' and a member of the team will be in touch shortly.',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.EASTER,
        title: 'Celebrate Easter with your teams@home',
        body1: 'Looking for some Easter treats to send to your teams at home?' +
          ' We\'ve got plenty of special seasonal hampers to choose from!',
        ctaText: 'Contact us',
        ctaLink: '/l/easter-2021-form',
        openLinkInNewTab: true,
        body2: ' and a member of the team will be in touch shortly.',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.EASTER,
        body1: 'Want to send your teams something special this Easter?' +
          ' We\'ve got lots of celebration hampers to choose from, with nationwide delivery available!',
        ctaText: 'Contact us',
        ctaLink: '/l/easter-2021-form',
        openLinkInNewTab: true,
        body2: ' and a member of the team will be in touch shortly.',
      },
    ],
  },
  {
    marketingCampaignName: MarketingCampaignNames.MAY_BANK_HOLIDAYS,
    analyticsPrefix: 'may-bank-holidays-2021',
    activeFrom: moment.tz('2021-04-12', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-05-28', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
        body1: 'Make your teams\' bank holiday extra special with delicious hampers delivered to their doors',
        ctaText: 'Search hampers',
        ctaLink: '/l/pantry-packages/category/bank-holiday-hampers?occasion=bank_holiday_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Looking for bank holiday treats for your remote teams?',
        ctaText: 'Contact us',
        ctaLink: '/l/may-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to discover delicious hampers now!',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'Bank holiday hampers',
        ctaLink: '/l/pantry-packages/category/bank-holiday-hampers?occasion=bank_holiday_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.PARTY,
        title: 'Celebrate the bank holiday with your teams@home',
        body1: 'Order tasty hampers for your work-from-home teams to enjoy over the bank holiday weekend!',
        ctaText: 'Contact us',
        ctaLink: '/l/may-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to get started.',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.PARTY,
        title: 'Celebrate the bank holiday with your teams@home',
        body1: 'Looking for ways to treat your remote teams this bank holiday?' +
          ' Our food hampers are just what you need. Nationwide home delivery available!',
        ctaText: 'Contact us',
        ctaLink: '/l/may-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' and a member of the team will be in touch.',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.PARTY,
        body1: 'Want to make your remote teams\' bank holiday extra special?' +
          ' From picnic boxes to sweet treats, we\'ve got delicious food hampers for everyone!',
        ctaText: 'Contact us',
        ctaLink: '/l/may-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' and a member of the team will be in touch.',
      },
    ],
  },
  {
    marketingCampaignName: MarketingCampaignNames.PRIDE_MONTH,
    analyticsPrefix: 'pride-month-2021',
    activeFrom: moment.tz('2021-06-01', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-06-28', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
        body1: 'Celebrate Pride Month with your teams, both in and out of the office!',
        ctaText: 'Find treats',
        ctaLink: '/l/pantry-packages/occasion/pride?occasion=pride_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Looking for Pride Month treats for your in-office and remote teams?',
        ctaText: 'Contact us',
        ctaLink: '/l/pride-month-form-2021',
        openLinkInNewTab: true,
        body2: ' to find out more!',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'Pride Month 2021',
        ctaLink: '/l/pantry-packages/occasion/pride?occasion=pride_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.HEART,
        title: 'Celebrate Pride Month with your teams',
        body1: 'Order tasty Pride-themed treats for your teams to enjoy this June, whether they\'re in the office or working from home.',
        ctaText: 'Contact us',
        ctaLink: '/l/pride-month-form-2021',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.HEART,
        title: 'Celebrate Pride Month with your teams',
        body1: 'We\'ve got lots of tasty Pride-themed treats they\'ll love!',
        ctaText: 'Contact us',
        ctaLink: '/l/pride-month-form-2021',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.HEART,
        title: 'We couldn\'t find any menus matching your search terms',
        body1: 'Celebrate Pride Month in the office and with your remote teams by sending them some delicious rainbow treats from Just Eat for Business!',
        ctaText: 'Contact us',
        ctaLink: '/l/pride-month-form-2021',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
    ],
  },
  {
    marketingCampaignName: MarketingCampaignNames.AUGUST_BANK_HOLIDAY,
    analyticsPrefix: 'august-bank-holiday-2021',
    activeFrom: moment.tz('2021-08-02', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-08-27', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
        body1: 'Help your teams celebrate the August bank holiday with tasty treats!',
        ctaText: 'Find treats',
        ctaLink: '/l/pantry-packages/occasion/bank-holiday-hampers?occasion=bank_holiday_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Looking for bank holiday treats for your teams?',
        ctaText: 'Contact us',
        ctaLink: '/l/august-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to find out more!',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'August Bank Holiday',
        ctaLink: '/l/pantry-packages/occasion/bank-holiday-hampers?occasion=bank_holiday_2021',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.SUMMER,
        title: 'Celebrate the bank holiday with your teams',
        body1: 'Order tasty bank holiday treats for your teams to enjoy this August!',
        ctaText: 'Contact us',
        ctaLink: '/l/august-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.SUMMER,
        title: 'Celebrate the bank holiday with your teams',
        body1: 'Want to celebrate the August bank holiday with your teams? We\'ve got lots of tasty treats they\'ll love!',
        ctaText: 'Contact us',
        ctaLink: '/l/august-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.SUMMER,
        title: 'We couldn\'t find any menus matching your search terms',
        body1: 'Celebrate the August bank holiday with your teams by sending them some delicious treats from Just Eat for Business!',
        ctaText: 'Contact us',
        ctaLink: '/l/august-bank-holiday-2021-form',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
    ],
  },
  {
    marketingCampaignName: MarketingCampaignNames.HALLOWEEN,
    analyticsPrefix: 'halloween-2021',
    activeFrom: moment.tz('2021-10-14', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-10-24', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Looking for Halloween themed treats for your teams?',
        ctaText: 'Contact us',
        ctaLink: '/l/pantry-packages/occasion/halloween',
        openLinkInNewTab: true,
        body2: ' to find out more!',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'Halloween',
        ctaLink: '/l/pantry-packages/occasion/halloween',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.CUPCAKE,
        title: 'Celebrate Halloween with your teams',
        body1: 'Order tasty Halloween themed treats for your teams to enjoy this spooky season!',
        ctaText: 'Contact us',
        ctaLink: '/l/pantry-packages/occasion/halloween',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.CUPCAKE,
        title: 'Celebrate Halloween with your teams',
        body1: 'Order tasty Halloween themed treats for your teams to enjoy this spooky season!',
        ctaText: 'Contact us',
        ctaLink: '/l/pantry-packages/occasion/halloween',
        openLinkInNewTab: true,
        body2: ' to find out more.',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.CUPCAKE,
        title: 'We couldn\'t find any menus matching your search terms',
        body1: 'Want to celebrate Halloween with your teams? Give our spooky themed-treats a go. Delivered in-office or at-home!',
        ctaText: 'Contact us',
        ctaLink: '/l/pantry-packages/occasion/halloween',
        openLinkInNewTab: true,
        body2: ' to find out more!',
      },
    ],
  },
  {
    marketingCampaignName: MarketingCampaignNames.CHRISTMAS,
    analyticsPrefix: 'christmas-2021',
    activeFrom: moment.tz('2021-10-25', 'Europe/London').startOf('day'),
    activeTo: moment.tz('2021-12-17', 'Europe/London').endOf('day'),
    activeComponents: [
      {
        name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
        body1: 'Celebrating the festive season with your teams?',
        ctaText: 'Contact us',
        ctaLink: '/l/pantry-packages/occasion/festive',
        openLinkInNewTab: true,
        body2: ' to start planning the perfect end-of-year celebrations!',
      },
      {
        name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
        ctaText: 'Festive season 2021',
        ctaLink: '/l/pantry-packages/occasion/festive',
        openLinkInNewTab: true,
      },
      {
        name: MarketingCampaignComponentNames.MY_MEALS,
        icon: IllustrativeIcons.CANDY_CANE,
        title: 'Celebrate the Festive season with your teams',
        body1: 'Want to celebrate the holidays with your team? From office parties and festive treats to at-home hampers and more,',
        ctaText: 'contact us',
        ctaLink: '/l/pantry-packages/occasion/festive',
        openLinkInNewTab: true,
        body2: ' to start planning your celebrations!',
      },
      {
        name: MarketingCampaignComponentNames.VENDOR_MENU,
        icon: IllustrativeIcons.CANDY_CANE,
        title: 'Celebrate the Festive season with your teams',
        body1: 'Want to celebrate the holidays with your team? From office parties and festive treats to at-home hampers and more,',
        ctaText: 'contact us',
        ctaLink: '/l/pantry-packages/occasion/festive',
        openLinkInNewTab: true,
        body2: ' to start planning your celebrations! ',
      },
      {
        name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
        icon: IllustrativeIcons.CANDY_CANE,
        title: 'We couldn\'t find any menus matching your search terms',
        body1: 'Want to celebrate the holidays with your team? From office parties and festive treats to at-home hampers and more,',
        ctaText: 'contact us',
        ctaLink: '/l/pantry-packages/occasion/festive',
        openLinkInNewTab: true,
        body2: ' to start planning your celebrations!',
      },
    ],
  },
];

// below is an example marketing campaign
// change marketingCampaignName and analyticsPrefix accordingly
// change the activeFrom and activeTo for when you want the campaign to start and end
// add as many or as few marketing campaign components to active components as you require
// for more information on how to configure a marketing campaign
// please see https://www.notion.so/Reusable-Marketing-Campaigns-Guide-89f27d00eb3c459d84310cabab442cf8

/**
{
  marketingCampaignName: MarketingCampaignNames.EASTER,
  analyticsPrefix: 'easter-2021',
  activeFrom: moment.tz('2021-03-01', 'Europe/London').startOf('day'),
  activeTo: moment.tz('2021-03-31', 'Europe/London').endOf('day'),
  activeComponents: [
    {
      name: MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER,
      body1: 'Celebrate Easter with your remote teams this spring with our special seasonal Pantry Packages',
      ctaText: 'Discover hampers',
      ctaLink: '/l/pantry-packages/occasion/easter?occasion=easter_2021',
      openLinkInNewTab: true,
    },
    {
      name: MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
      body1: 'Want to send something special to your teams at home this Easter?',
      ctaText: 'Contact us',
      ctaLink: '/l/easter-2021-form',
      openLinkInNewTab: true,
      body2: ' to get started.',
    },
    {
      name: MarketingCampaignComponentNames.GLOBAL_HEADER_LINK,
      ctaText: 'Easter 2021',
      ctaLink: '/l/pantry-packages/occasion/easter?occasion=easter_2021',
      openLinkInNewTab: true,
    },
    {
      name: MarketingCampaignComponentNames.MY_MEALS,
      icon: IllustrativeIcons.EASTER,
      title: 'Celebrate Easter with your teams@home',
      body1: 'Send your remote teams some tasty Easter-themed treats and hampers this spring!',
      ctaText: 'Contact us',
      ctaLink: '/l/easter-2021-form',
      openLinkInNewTab: true,
      body2: ' and a member of the team will be in touch shortly.',
    },
    {
      name: MarketingCampaignComponentNames.VENDOR_MENU,
      icon: IllustrativeIcons.EASTER,
      title: 'Celebrate Easter with your teams@home',
      body1: 'Looking for some Easter treats to send to your teams at home?' +
        ' We\'ve got plenty of special seasonal hampers to choose from!',
      ctaText: 'Contact us',
      ctaLink: '/l/easter-2021-form',
      openLinkInNewTab: true,
      body2: ' and a member of the team will be in touch shortly.',
    },
    {
      name: MarketingCampaignComponentNames.NO_SEARCH_RESULTS,
      icon: IllustrativeIcons.EASTER,
      body1: 'Want to send your teams something special this Easter?' +
        ' We\'ve got lots of celebration hampers to choose from, with nationwide delivery available!',
      ctaText: 'Contact us',
      ctaLink: '/l/easter-2021-form',
      openLinkInNewTab: true,
      body2: ' and a member of the team will be in touch shortly.',
    },
  ],
},
**/
