import { merge, Subject } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

/**
 * Decorator for host listeners that fire rapidly (e.g. scroll, resize, mousemove).
 * Ensures that the bound method is called at most 16 times per second and at least once per event block.
 * Place after a @HostListener().
 *
 * @example
 *   @HostListener('window:scroll')
 *   @DebounceHostListener()
 *   private handleWindowScroll(): void {
 *     if (this.window.scrollTop < 100) { ... }
 *   }
 *
 * WARNING: When code that uses this is tested, the test case needs use of the `fakeAsync` helpers as this code adds timer events.
 */
/* eslint-disable-next-line @typescript-eslint/naming-convention */ // decorators should be PascalCase
export function DebounceHostListener(): MethodDecorator {
  return (targetProto: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const oldHandler = descriptor.value;

    const scrollEvents = new Subject<{ target: any, args: any[]}>();
    merge(
      // Make sure that we don't get too many events
      scrollEvents.pipe(debounceTime(100)),
      // But also make sure we get some while the scroll happens
      scrollEvents.pipe(throttleTime(60))
    ).subscribe(({ target, args }) => oldHandler.apply(target, args));

    descriptor.value = function(...args: any[]): void {
      scrollEvents.next({
        target: this, /* eslint-disable-line no-invalid-this */ // We need to pass the function context into the callback,
        // otherwise it will not run properly without using `.bind()
        args
      });
    };
  };
}
