<div
  *ngIf="breadcrumbs?.length"
  class="breadcrumbs"
  test-id="container"
>
  <div
    *ngFor="let breadcrumb of breadcrumbs;"
    class="breadcrumbs__step"
    test-id="crumbs"
  >
    <a
      *ngIf="breadcrumb.route"
      [routerLink]="breadcrumb.route.path"
      [queryParams]="breadcrumb.route.queryParams"
      test-id="routerLink"
    >
      {{ breadcrumb.label }}
    </a>
    <a
      *ngIf="!breadcrumb.route && breadcrumb.link"
      [href]="breadcrumb.link"
      test-id="browserLink"
    >
      {{ breadcrumb.label }}
    </a>
    <ng-container
      *ngIf="!breadcrumb.route && !breadcrumb.link"
    >
      {{ breadcrumb.label }}
    </ng-container>
  </div>
</div>
