import { Pipe, PipeTransform } from '@angular/core';
import { DateRange, IsoDate } from '@citypantry/util';
import moment from 'moment';

@Pipe({
  name: 'humanFriendlyDateRange'
})
export class HumanFriendlyDateRangePipe implements PipeTransform {
  public transform(dateRange: DateRange): string {
    if (dateRange.from === dateRange.to) {
      return this.formatDate(dateRange.from);
    }

    return [this.formatDate(dateRange.from), this.formatDate(dateRange.to)].join(' - ');
  }

  private formatDate(isoDate: IsoDate): string {
    return moment(isoDate).format('DD/MM/YYYY');
  }
}
