import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { objectToParams } from '@citypantry/util';
import { createSearchResultFromJson, Page, RecommendationRequest, SearchResult, transformPage } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecommendationApi {

  constructor(private http: HttpClient) {
  }

  public getRecommendations(recommendationRequest: RecommendationRequest): Observable<SearchResult[]> {
    const date = recommendationRequest.date.format('YYYY-MM-DDTHH:mm');
    const params = objectToParams({
      ...recommendationRequest,
      date,
      pageNumber: 1,
      pageSize: 3,
      excludeVendorId: recommendationRequest.excludeVendorId
    });

    return this.http.get<Page<SearchResult>>(`/recommend`, { params })
      .pipe(
        map(transformPage(createSearchResultFromJson)),
        map((result: Page<SearchResult>) =>
          // Currently we do not make use of pagination so we only take SearchResults
          result.items
        )
      );
  }
}
