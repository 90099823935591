import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tag-input',
  templateUrl: 'tag-input.component.html',
  styleUrls: ['tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true
    }
  ]
})
export class TagInputComponent implements ControlValueAccessor, OnInit {

  @Input()
  public showClearAllButton: boolean = true;

  public tags: string[];
  public currentTag: string;

  @ViewChild('currentTagInput', { static: false })
  public currentTagInput: ElementRef;

  private propagateChange: () => void;

  private wrapperElement: HTMLElement;

  private onChanges: Subject<void>;

  constructor(private element: ElementRef) {
    this.tags = [];
    this.propagateChange = () => { /* Placeholder function */
    };
    this.onChanges = new Subject();
  }

  public ngOnInit(): void {
    this.wrapperElement = this.element.nativeElement.querySelector('.js-tag-input');
  }

  public writeValue(obj: string[]): void {
    this.tags = obj && obj.slice() || [];
  }

  public registerOnChange(listener: (values: string[]) => void): void {
    this.propagateChange = () => listener(this.tags.slice());
  }

  public registerOnTouched(fn: () => void): void {
    this.onChanges.subscribe(fn);
  }

  public onWrapperClick(event: MouseEvent): void {
    if (event.target === this.wrapperElement) {
      this.currentTagInput.nativeElement.focus();
    }
  }

  public remove(tagIndex: number): void {
    this.tags = this.tags.filter((_: string, index: number) => tagIndex !== index);
    this.propagateChange();
  }

  public onClearAllClick(): void {
    this.tags = [];
    this.propagateChange();
  }

  public onBlur(): void {
    this.endCurrentTag();
    this.onChanges.next();
  }

  public onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        if (!this.currentTag) {
          break;
        }
      /* falls through */
      case 'Enter':
      /* falls through */
      case 'ArrowRight':
      /* falls through */
      case ',':
      /* falls through */
      case ';':
        event.preventDefault();
        this.endCurrentTag();
        break;
      case 'Backspace': // Backspace
        if (!this.currentTag) {
          event.preventDefault();
          if (this.tags.length) {
            this.currentTag = this.tags.pop();
            this.propagateChange();
          }
        }
        break;
      default: // Nothing
    }
  }

  private endCurrentTag(): void {
    const currentTag = this.currentTag && this.currentTag.trim();
    if (!currentTag) {
      return;
    }
    const tags = currentTag
      .split(/[,;]/) // Split on all separator strings
      .map((tag: string) => tag.trim())
      .filter((tag: string) => !!tag);
    this.tags.push(...tags);
    this.currentTag = '';

    this.propagateChange();
  }
}
