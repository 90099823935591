import { animate, animation, style } from '@angular/animations';

export const collapseTopLeft = animation([
  style({
    height: '*',
    transform: 'translateX(0)',
    overflow: 'hidden'
  }),
  animate('{{ time }}',
    style({
      height: 0,
      transform: 'translateX(-100%)'
    })
  )],
{ params: { time: '500ms' }}
);
