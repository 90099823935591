import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { FEATURE_STATE_NAME, HistoryState } from './history.state';

const getHistoryState = createFeatureSelector<HistoryState>(FEATURE_STATE_NAME);

export const getReferer = (state: HistoryState): string | null =>
  state.current;
export const getPreviousPageExcludingQueryChanges = (state: HistoryState): string | null =>
  state.previousExcludingQueryChanges;

export const HistorySelectors = mapSelectors(getHistoryState, {
  getReferer,
  getPreviousPageExcludingQueryChanges,
});
