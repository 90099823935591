import { Injectable } from '@angular/core';
import { Breadcrumb } from '@citypantry/components-navigation';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, MealPlanSelectors, SearchSelectors } from '@citypantry/state';
import { MealPlan, MealPlanStatuses, SearchRequest, VendorFlags } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchQueries {
  constructor(
    private store: Store<AppState>,
  ) {}

  public getSearchDateAndPostcode(): Observable<{ date: Moment, postcode: string, location?: string }> {
    return this.store.select(SearchSelectors.getSearchRequest).pipe(
      map((request: SearchRequest) => {
        if (request === null) {
          return {
            date: null,
            postcode: null
          };
        }

        return {
          date: request.date || null,
          postcode: request.postcode || null,
          location: request.location || null,
        };
      }),
    );
  }

  public hasMealPlanRequirements(): Observable<boolean> {
    return this.store.select(MealPlanSelectors.getMealPlan).pipe(
      map((mealPlan) => !!mealPlan),
    );
  }

  public getMealPlan(): Observable<null | MealPlan> {
    return this.store.select(MealPlanSelectors.getMealPlan);
  }

  public isSearching(): Observable<boolean> {
    return this.store.select(SearchSelectors.isSearching);
  }

  public getSearchBreadcrumbs(): Observable<Breadcrumb[] | null> {
    return this.store.select(MealPlanSelectors.getMealPlan).pipe(
      map((mealPlan: MealPlan) => {
        if (mealPlan) {
          // TODO this is duplicated in customer-menu.component.ts. There might be a shared place it can be put
          const mealPlanLink = mealPlan.status === MealPlanStatuses.PENDING_STAFF_APPROVAL ?
            `/admin/meal-plan/${mealPlan.id}/review` :
            `/customer/meal-plans/${mealPlan.id}/review`;

          return [
            { label: `${mealPlan.customer.companyName} Meal Plan`, link: mealPlanLink },
            { label: 'Choose Vendor' },
          ];
        } else {
          return null;
        }
      }),
    );
  }

  public hasAllParametersRequiredForSearch(): Observable<boolean> {
    return this.store.select(SearchSelectors.getSearchRequest).pipe(
      map((request: SearchRequest) => {
        return !!(
          request.postcode && request.postcode.trim().length &&
          request.date && request.date.isValid()
        );
      }),
    );
  }

  public getSearchRequest(): Observable<SearchRequest> {
    return combineLatest([
      this.store.select(SearchSelectors.getSearchRequest),
      this.store.select(AuthSelectors.isWeWorkUser),
      this.store.select(SearchSelectors.isWeWorkModeEnabled)
    ]).pipe(
      map(([searchRequest, isWeWorkUser, isWeWorkModeEnabled]) => {
        if (isWeWorkUser && isWeWorkModeEnabled) {
          return {
            ...searchRequest,
            options: (searchRequest.options || []).concat(VendorFlags.WEWORK_FRIENDLY)
          };
        } else {
          return searchRequest;
        }
      })
    );
  }
}
