import { ChoiceDeadline } from '../individual-choice/choice-deadline.enum';
import { MajorCurrency } from '../money/minor-major-currency.model';
import { SearchOrderType } from './search-order-type.enum';

export interface SearchPreferences {
  expectedHeadcount: number;
  budget: MajorCurrency;
  choiceDeadline: ChoiceDeadline;
  orderType: SearchOrderType;
  isSubsidisedChoiceTurnedOn: boolean;
}

export function createSearchPreferencesFromJson(json?: Partial<SearchPreferences>, isSubsidisedChoiceEnabled?: boolean): SearchPreferences {
  json = json || {};
  const searchPreferences: Partial<SearchPreferences> = {};

  searchPreferences.expectedHeadcount = json.expectedHeadcount;
  searchPreferences.budget = json.budget;
  searchPreferences.choiceDeadline = json.choiceDeadline;
  searchPreferences.orderType = json.orderType;
  searchPreferences.isSubsidisedChoiceTurnedOn = isSubsidisedChoiceEnabled; // TODO CPD-6841: remove and use actual preferences

  return searchPreferences as SearchPreferences;
}
