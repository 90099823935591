import { type } from '@citypantry/util';
import { UserId } from '@citypantry/util-models';
import { Action } from '@ngrx/store';
import { BetaFeature } from '../models/beta-feature.enum';
import { UserProfile } from '../models/user-profile.model';
import { UserRegisterError, UserRegisterInfo } from '../models/user-register-info.model';
import { NavigationPermissions } from '../models/navigation-permissions.model';

// no-empty-interface: Empty interfaces help define actions so are necessary even if they have no payload
/* eslint-disable @typescript-eslint/no-empty-interface */

// naming-convention: Legacy action types don't adhere to our naming conventions because they try to look like enums
/* eslint-disable @typescript-eslint/naming-convention */
export const ActionTypes = {
  FETCH_AUTH: type('[Auth] Fetch Auth'),
  AUTH_FETCHED: type('[Auth] Auth Fetched'),
  AUTH_FETCH_FAILED: type('[Auth] Auth Fetch Failed'),
  AUTH_REFRESH_REQUEST: type('[Auth] Auth Refresh Request'),
  LOGIN_WITH_EMAIL: type('[Auth] Login With Email'),
  UNMASQUERADE: type('[Auth] Unmasquerade'),
  MASQUERADE: type('[Auth] Masquerade'),
  CUSTOMER_LOCATIONS_FETCHED: type('[Auth] Customer Locations Fetched'),
  DISABLE_BETA_FEATURE: type('[Auth] Disable Beta Feature'),
  BETA_FEATURE_DISABLED: type('[Auth] Beta Feature Disabled'),
  SHOW_SSO_TOKEN_ERROR: type('[Auth] Show SSO Token Error'),
  HIDE_SSO_TOKEN_ERROR: type('[Auth] Hide SSO Token Error'),
  REGISTER_EATER: type('[Auth] Register Eater'),
  REGISTER_EATER_SUCCESS: type('[User API] Register Eater Success'),
  REGISTER_EATER_FAILURE: type('[User API] Register Eater Failure'),
  AUTH_FETCHED_FOR_REGISTERED_EATER: type('[Auth] Auth Fetched For Registered Eater'),
  AUTH_FETCH_FAILED_FOR_REGISTERED_EATER: type('[Auth] Auth Fetch Failed For Registered Eater'),
};
/* eslint-enable @typescript-eslint/naming-convention */

export interface FetchAuthAction extends Action {
}

export function fetchAuth(): FetchAuthAction {
  return {
    type: ActionTypes.FETCH_AUTH
  };
}

/**
 * This action should only be used by AuthService.refreshAuth and is necessary in order to
 * differentiate external updates to the auth state from internal calls to fetchAuth()
 */
export interface AuthRefreshRequestAction extends FetchAuthAction {}

export function authRefreshRequest(): AuthRefreshRequestAction {
  return {
    type: ActionTypes.AUTH_REFRESH_REQUEST
  };
}

export interface AuthFetchedAction extends Action {
  payload: {
    profile: UserProfile | null;
    navigationPermissions: NavigationPermissions | null;
  };
}

export function authFetched(
  profile: UserProfile | null,
  navigationPermissions: NavigationPermissions | null
): AuthFetchedAction {
  return {
    type: ActionTypes.AUTH_FETCHED,
    payload: {
      profile,
      navigationPermissions,
    }
  };
}

export interface AuthFetchFailedAction extends Action {
  payload: {
    error: any;
  };
}

export function authFetchFailed(error: any): AuthFetchFailedAction {
  return {
    type: ActionTypes.AUTH_FETCH_FAILED,
    payload: {
      error
    }
  };
}

export interface LoginWithEmailAction extends Action {
  payload: {
    email: string;
  };
}

export function loginWithEmail(email: string): LoginWithEmailAction {
  return {
    type: ActionTypes.LOGIN_WITH_EMAIL,
    payload: {
      email
    }
  };
}

export interface UnmasqueradeAction extends Action {
}

export function unmasquerade(): UnmasqueradeAction {
  return {
    type: ActionTypes.UNMASQUERADE
  };
}

export interface MasqueradeAction extends Action {
  payload: {
    userId: UserId;
  };
}

export function masquerade(userId: UserId): MasqueradeAction {
  return {
    type: ActionTypes.MASQUERADE,
    payload: {
      userId
    }
  };
}

export interface DisableBetaFeatureAction extends Action {
  payload: {
    feature: BetaFeature;
  };
}

export function disableBetaFeature(feature: BetaFeature): DisableBetaFeatureAction {
  return {
    type: ActionTypes.DISABLE_BETA_FEATURE,
    payload: {
      feature
    }
  };
}

export interface BetaFeatureDisabledAction extends Action {
  payload: {
    feature: BetaFeature;
  };
}

export function betaFeatureDisabled(feature: BetaFeature): BetaFeatureDisabledAction {
  return {
    type: ActionTypes.BETA_FEATURE_DISABLED,
    payload: {
      feature
    }
  };
}

export interface ShowSSOTokenError extends Action {}

export function showSSOTokenError(): ShowSSOTokenError {
  return {
    type: ActionTypes.SHOW_SSO_TOKEN_ERROR
  };
}

export interface HideSSOTokenError extends Action {}

export function hideSSOTokenError(): HideSSOTokenError {
  return {
    type: ActionTypes.HIDE_SSO_TOKEN_ERROR
  };
}

export interface ForgotPasswordAction extends Action {
  payload: {
    email: string;
  };
}

export interface RegisterEaterAction extends Action {
  payload: {
    userInfo: UserRegisterInfo;
  };
}

export function registerEater(userInfo: UserRegisterInfo): RegisterEaterAction {
  return {
    type: ActionTypes.REGISTER_EATER,
    payload: { userInfo }
  };
}

export interface RegisterEaterSuccessAction extends Action {
  payload: {
    profile: UserProfile;
  };
}

export function registerEaterSuccess(userProfile: UserProfile): RegisterEaterSuccessAction {
  return {
    type: ActionTypes.REGISTER_EATER_SUCCESS,
    payload: {
      profile: userProfile,
    },
  };
}

export interface RegisterEaterFailureAction extends Action {
  payload: {
    error: UserRegisterError;
  };
}

export function registerEaterFailure(error: UserRegisterError): RegisterEaterFailureAction {
  return {
    type: ActionTypes.REGISTER_EATER_FAILURE,
    payload: {
      error
    }
  };
}

export interface AuthFetchedForRegisteredEaterAction extends AuthFetchedAction {
  payload: {
    profile: UserProfile;
    navigationPermissions: NavigationPermissions;
  };
}

export function authFetchedForRegisteredEater(
  userProfile: UserProfile,
  navigationPermissions: NavigationPermissions
): AuthFetchedForRegisteredEaterAction {
  return {
    type: ActionTypes.AUTH_FETCHED_FOR_REGISTERED_EATER,
    payload: {
      profile: userProfile,
      navigationPermissions,
    }
  };
}

export interface AuthFetchFailedForRegisteredEaterAction extends Action {
  payload: {
    error: any;
  };
}

export function authFetchFailedForRegisteredEater(error: any): AuthFetchFailedForRegisteredEaterAction {
  return {
    type: ActionTypes.AUTH_FETCH_FAILED_FOR_REGISTERED_EATER,
    payload: {
      error
    }
  };
}
