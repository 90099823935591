import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dayOfWeekOrToday'
})
export class DayOfWeekOrTodayPipe implements PipeTransform {
  private static DEFAULT_FORMAT  = 'dddd';

  public transform(inputDate: moment.MomentInput, dateFormat: string = DayOfWeekOrTodayPipe.DEFAULT_FORMAT): any {
    if (!inputDate) {
      return null;
    }

    const date = moment(inputDate);

    return date.tz('Europe/London').calendar(null, {
      sameDay: '[Today]',
      lastDay: dateFormat,
      nextDay : dateFormat,
      lastWeek : dateFormat,
      nextWeek : dateFormat,
      sameElse: dateFormat,
    });
  }
}
