<app-modal
  class="hubspot-modal-layout"
  [class.hubspot-modal-layout--without-labels]="!showLabels"
  [class.hubspot-modal-layout--without-error-rollup]="!showErrorRollup"
  [show]="visible"
  (clickOutsideModal)="onHide()"
  modalStyle="large"
  test-id="appModal"
>
  <div class="dialog">
    <button
      class="dialog__close"
      test-id="closeDialog"
      type="button"
      (click)="onHide()"
    >
      close
    </button>
    <div class="dialog__content dialog__full-width">
      <div class="js-hubspot-lead"></div>
    </div>
  </div>
</app-modal>
