import { Action } from '@ngrx/store';
import { StoreInjector } from './store-injector';

type DispatchableMethodDecorator<T extends Action> = (
  target: Object,
  property: string,
  descriptor: TypedPropertyDescriptor<(...args: any[]) => T | null>,
) => void;

/**
 * Dispatches the actions returned by the methods annotated by this decorator to the global NgRX store.
 *
 * @see /docs/01-project-structure/02-ngrx/actions.md
 *
 * @example
 * @Dispatch()
 * public doFoo(bar: string): Action {
 *   return SomeActions.doFoo({ bar });
 * }
 *
 * // equivalent to:
 * public doFoo(bar: string): void {
 *   this.store.dispatch(SomeActions.doFoo({ bar }));
 * }
 */
/* eslint-disable-next-line @typescript-eslint/naming-convention */ // decorators should be PascalCase
export function Dispatch<T extends Action>(): DispatchableMethodDecorator<T> {
  return (target, property, descriptor) => {
    const originalMethod = descriptor.value;

    (descriptor.value as (this: any) => void) = function(this: any, ...args: any[]): void {
      /* eslint-disable-next-line no-invalid-this */ // Legitimate use of `this` as passed into the function
      const action = originalMethod.apply(this, args);
      if (action) {
        StoreInjector.store.dispatch(action);
      }
    };
  };
}
