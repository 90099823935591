import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AuthSelectors, HeaderType } from '@citypantry/shared-auth';
import {
  MarketingCampaignComponentModel,
  MarketingCampaignComponentNames,
  MarketingCampaignService,
} from '@citypantry/shared-marketing-campaign';
import { VendorNotificationsSelectors } from '@citypantry/shared-vendor-notifications';
import {
  AppState,
  HeaderActions,
  HeaderSelectors,
  IndividualChoiceSetupSelectors,
  RouterActions,
  SearchSelectors,
  Select
} from '@citypantry/state';
import { safeUnsubscribe } from '@citypantry/util';
import { SearchOrderType, SearchRequest } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppConfig } from '@citypantry/shared-app-config';

@Component({
  selector: 'app-header-container',
  template: `
    <app-header
      class="no-print-on-printable"
      [isNavOpen]="isNavOpen$ | async"
      [headerType]="headerType$ | async"
      [isEater]="isEater$ | async"
      [isOrderer]="isOrderer$ | async"
      [isEligibleForIndividualChoice]="isEligibleForIndividualChoice$ | async"
      [isAdvancedBudgetingEnabled]="isAdvancedBudgetingEnabled$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [sudoMode]="isSudo$ | async"
      [showMealPlanLink]="showMealPlanLink$ | async"
      [isColleagueGroupsEnabled]="isColleagueGroupsEnabled$ | async"
      [isFixed]="isHeaderFixed"
      [isSmall]="isHeaderSmall"
      [pendingOrdersCount]="pendingOrdersCount$ | async"
      [searchRequest]="searchRequest$ | async"
      [searchType]="searchType$ | async"
      [marketingCampaignComponent]="marketingCampaignComponentModel"
      [isSsoUser]="isSsoUser$ | async"
      [identityBase]="identityBase"
      [canAccessOnDemand]="canAccessOnDemand$ | async"
      (logoClick)="navigateToHomepage()"
      (toggleNav)="toggleNavClicked()"
      test-id="header"
    ></app-header>
  `,
})
export class HeaderContainerComponent implements OnInit, OnDestroy {
  @Select(AuthSelectors.isEater)
  public isEater$: Observable<boolean>;

  @Select(AuthSelectors.customer.isEligibleForIndividualChoice)
  public isEligibleForIndividualChoice$: Observable<boolean>;

  @Select(AuthSelectors.customer.isAdvancedBudgetingEnabled)
  public isAdvancedBudgetingEnabled$: Observable<boolean>;

  @Select(AuthSelectors.isLoggedInUser)
  public isLoggedIn$: Observable<boolean>;

  @Select(AuthSelectors.isSudo)
  public isSudo$: Observable<boolean>;

  @Select(AuthSelectors.customer.isColleagueGroupsEnabled)
  public isColleagueGroupsEnabled$: Observable<boolean>;

  public showMealPlanLink$: Observable<boolean>;
  public isHeaderFixed: boolean;
  public isHeaderSmall: boolean;
  public queryParams$: Observable<Params>;
  public marketingCampaignComponentModel: MarketingCampaignComponentModel | null;
  public identityBase: string;

  @Select(VendorNotificationsSelectors.getPendingOrdersCount)
  public pendingOrdersCount$: Observable<number>;

  @Select(HeaderSelectors.isNavBarOpen)
  public isNavOpen$: Observable<boolean>;

  @Select(SearchSelectors.getSearchType)
  public searchType$: Observable<SearchOrderType>;

  @Select(IndividualChoiceSetupSelectors.getSearchRequest)
  public searchRequest$: Observable<SearchRequest>;

  @Select(AuthSelectors.isSsoUser)
  public isSsoUser$: Observable<boolean>;

  @Select(AuthSelectors.isOrderer)
  public isOrderer$: Observable<boolean>;

  @Select(AuthSelectors.getHeaderType)
  public headerType$: Observable<HeaderType>;

  @Select(AuthSelectors.navigationPermissions.canAccessOnDemand)
  public canAccessOnDemand$: Observable<boolean>;

  private routerEventsSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private marketingCampaignService: MarketingCampaignService,
    private appConfig: AppConfig,
  ) {
    this.showMealPlanLink$ = combineLatest([
      this.store.select(AuthSelectors.customer.isOnMealPlan),
      this.store.select(AuthSelectors.isOrderer),
    ]).pipe(
      map(([isCustomerOnMealPlan, isOrderer]) => isCustomerOnMealPlan && !isOrderer)
    );
    this.isHeaderFixed = false;
    this.isHeaderSmall = false;
    this.identityBase = this.appConfig.IDENTITY_BASE;
    this.queryParams$ = activatedRoute.queryParams;
    this.getRouteData();
  }

  public ngOnInit(): void {
    this.marketingCampaignComponentModel = this.marketingCampaignService.getMarketingCampaignComponentModel(
      MarketingCampaignComponentNames.GLOBAL_HEADER_LINK
    );
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.routerEventsSubscription);
  }

  public navigateToHomepage(): void {
    this.store.select(AuthSelectors.isLoggedInUser).pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.store.dispatch(RouterActions.go({ path: '/' }));
      } else {
        this.store.dispatch(RouterActions.goExternal({ url: '/' }));
      }
    });
  }

  public toggleNavClicked(): void {
    this.store.dispatch(HeaderActions.toggleNav());
  }

  private getRouteData(): void {
    this.routerEventsSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      let route = this.activatedRoute.snapshot.firstChild;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.isHeaderFixed = !!route.data.headerFixed;
      this.isHeaderSmall = !!route.data.headerSmall;
    });
  }
}
