/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type OrderSize = 'small' | 'medium' | 'large' | 'extra-large';

export class OrderSizes {
  public static SMALL:       OrderSize & 'small'       = 'small';
  public static MEDIUM:      OrderSize & 'medium'      = 'medium';
  public static LARGE:       OrderSize & 'large'       = 'large';
  public static EXTRA_LARGE: OrderSize & 'extra-large' = 'extra-large';

  public static get values(): OrderSize[] {
    return [
      OrderSizes.SMALL,
      OrderSizes.MEDIUM,
      OrderSizes.LARGE,
      OrderSizes.EXTRA_LARGE,
    ];
  }

  // Do not modify this line - Custom code below here

  /**
   * Compares two order sizes.
   * Relies on the values array being in order from smallest to largest
   */
  public static compare(this: void, s1: OrderSize, s2: OrderSize): number {
    return OrderSizes.values.indexOf(s1) - OrderSizes.values.indexOf(s2);
  }
}

export type OrderSizeEnum = typeof OrderSizes;
