import { OrderTracking } from '@citypantry/util-models';
import { DeliveryDetailsAction, DeliveryDetailsActions } from './delivery-details.actions';

export const FEATURE_STATE_NAME = 'deliveryDetails';

export interface DeliveryDetailsState {
  trackingForOrders: OrderTracking[];
  isLoading: boolean;
}

export const INITIAL_STATE: DeliveryDetailsState = {
  trackingForOrders: [],
  isLoading: false
};

export function reducer(
  state: DeliveryDetailsState = INITIAL_STATE,
  action: DeliveryDetailsAction): DeliveryDetailsState {
  switch (action.type) {
    case DeliveryDetailsActions.trackingForOrdersLoaded.type: {
      const { trackingForOrders } = action;
      return {
        ...state,
        trackingForOrders,
        isLoading: false
      };
    }
    case DeliveryDetailsActions.loadTrackingForOrders.type: {
      return {
        ...state,
        isLoading: true
      };
    }
    default:
      return state;
  }
}
