import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { Moment } from 'moment';
import { CompanyHolidaysState, FEATURE_STATE_NAME } from './company-holidays.state';

const getCompanyHolidaysState = createFeatureSelector<CompanyHolidaysState>(FEATURE_STATE_NAME);

export const getAllCompanyHolidays = (state: CompanyHolidaysState): Moment[] => state.holidays;

export const CompanyHolidaysSelectors = mapSelectors(getCompanyHolidaysState, {
  getAll: getAllCompanyHolidays,
});
