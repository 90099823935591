import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { shake, slideInOut } from '@citypantry/util-animations';
import { CartValidityError, MajorCurrency } from '@citypantry/util-models';

@Component({
  selector: 'app-cart-subtotal',
  templateUrl: './cart-subtotal.component.html',
  animations: [
    trigger('slideInOut', slideInOut),
    trigger('drama', [transition('* => true', useAnimation(shake, {
      params: {
        timing: '600ms',
        distance: '5px'
      }
    }))]),
  ]
})
export class CartSubtotalComponent {

  @Input()
  public itemsCost: MajorCurrency;

  @Input()
  public minOrderWarning: CartValidityError | null;

  @Input()
  public headcount: number;

  @Input()
  public requiredHeadcount: number;

  @Input()
  public currencyCode: string;

  public drama: boolean;

  public highlightMinimumOrder(): void {
    this.drama = false;
    setTimeout(() => {
      this.drama = true;
    });
  }

  public dramaOver(): void {
    this.drama = false;
  }

  public getHeadcount(): number {
    return this.headcount > 0 ? this.headcount : 1;
  }

  public getMissingHeadcount(): number {
    if (!this.requiredHeadcount) {
      return 0;
    }
    const diff = this.requiredHeadcount - this.getHeadcount();
    return diff > 0 ? diff : 0;
  }

  public getPricePerPerson(): number {
    return (this.itemsCost || 0) / this.getHeadcount();
  }
}
