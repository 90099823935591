import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ModalSize } from '../dialog/dialog-settings.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnDestroy {

  @Input()
  public set show(show: boolean) {
    if (!this._isOpen !== !show) { // Deliberately casting to avoid issues with truthy or falsy values
      this.manageHtmlClass(show);
    }

    this._isOpen = show;
  }

  public get show(): boolean {
    return this._isOpen;
  }

  @Input()
  public modalStyle?: ModalSize;

  @Input()
  public noBorder: boolean;

  @Input()
  public centerVertically: boolean;

  @Input()
  public showOverflow: boolean;

  @Input()
  public noWidth: boolean;

  @Output()
  public clickOutsideModal: EventEmitter<void> = new EventEmitter();

  @ViewChild('container', { static: false })
  public container: ElementRef;

  private _isOpen: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnDestroy(): void {
    if (this._isOpen) {
      // Cleanup
      this.manageHtmlClass(false);
    }
  }

  public onBackgroundClick($event: MouseEvent): void {
    const clickedInContainer = this.container.nativeElement === $event.target;

    if (clickedInContainer) {
      this.clickOutsideModal.emit();
    }
  }

  private manageHtmlClass(show: boolean): void {
    const html = this.document && this.document.documentElement;
    if (html) {
      html.className = show ?
        `${ html.className } modal-open` :
        html.className.replace(/(\s|^)modal-open/i, '');
    }
  }
}
