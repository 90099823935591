import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventIdEnum,
  AnalyticsEcommerceEventIds,
} from '@citypantry/shared-analytics';
import { MajorCurrency } from '@citypantry/util-models';

@Component({
  selector: 'app-item-modal-footer',
  templateUrl: './item-modal-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemModalFooterComponent {
  @Input()
  public subtotal: MajorCurrency;

  @Input()
  public hidePrices: boolean;

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;
  public AnalyticsEcommerceEventIds: AnalyticsEcommerceEventIdEnum = AnalyticsEcommerceEventIds;

  public onCancel(): void {
    this.cancel.emit();
  }
}
