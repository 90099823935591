import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[enter]'
})
export class EnterDirective {

  @Output()
  public enter: EventEmitter<KeyboardEvent> = new EventEmitter();

  @HostListener('keypress', ['$event'])
  public onKeypress($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      this.enter.emit($event);
    }
  }
}
