import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export type NativeWindow = Window & { [key: string]: any };

@Injectable({
  providedIn: 'root'
})
export class WindowRef {
  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public get nativeWindow(): NativeWindow {
    return this.document.defaultView; // Returns the `window` object containing this `document`
  }

  public getViewportHeight(): number {
    // From https://stackoverflow.com/a/8876069/462235
    return Math.max(this.document.documentElement.clientHeight, this.nativeWindow.innerHeight || 0);
  }

  public getBoundingClientRect(element: HTMLElement): DOMRect {
    return element.getBoundingClientRect();
  }
}
