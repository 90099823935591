import { Component } from '@angular/core';

@Component({
  selector: 'app-price-guarantee',
  template: `
    <div
      class="cart-price-guarantee mt-3"
    >
      <i class="cart-price-guarantee__icon icon icon-lightbulb icon--medium"></i>
      <p class="cart-price-guarantee__text">
        Our price promise - you won’t find our restaurants cheaper anywhere else.
        <a target="_blank"
           href="https://citypantrysupport.zendesk.com/hc/en-us/articles/360008669554-What-is-the-Price-Guarantee-">
          Find out more
        </a>
      </p>
    </div>
  `,
})
export class PriceGuaranteeComponent {}
