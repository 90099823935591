export type CartValidityMessageLevel = 'Info' | 'Warning' | 'Error';

export class CartValidityMessageLevels {
  public static INFO: CartValidityMessageLevel = 'Info';
  public static WARNING: CartValidityMessageLevel = 'Warning';
  public static ERROR: CartValidityMessageLevel = 'Error';

  public static get values(): CartValidityMessageLevel[] {
    return [
      CartValidityMessageLevels.INFO,
      CartValidityMessageLevels.WARNING,
      CartValidityMessageLevels.ERROR
    ];
  }
}

export type CartValidityMessageLevelEnum = typeof CartValidityMessageLevels;
