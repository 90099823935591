import { ErrorMessage } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';
import { Alert } from './alert.model';

export const ErrorActions = {
  logError: createAction(
    '[Error] Log Error',
    props<{ error: any, showToUser: boolean }>(),
  ),

  showErrorMessage: createAction(
    '[Error] Show Error Message',
    props<{ error: ErrorMessage }>(),
  ),

  dismissCurrentError: createAction(
    '[Error] Dismiss Current Error',
  ),

  showAlert: createAction(
    '[Error] Show Alert',
    props<{ title: string, text: string }>(),
  ),

  dismissAlert: createAction(
    '[Error] Dismiss Alert',
    props<{ alert: Alert }>(),
  ),
};

const all = union(ErrorActions);
export type ErrorAction = typeof all;
