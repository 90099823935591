export interface Page<T> {
  _links?: {
    [ref: string]: string;
  };

  total?: number; // Total number of results for this filter
  count?: number; // Number of results in this response
  pageSize?: number; // Number of results per page
  page?: number; // Page count, starts at 1

  items?: T[];
}

export function transformPage<I, O>(callback: (item: I) => O): ((page: Page<I>) => Page<O>) {
  return (page: Page<I>) => ({
    ...page,
    items: page.items.map(callback)
  });
}
