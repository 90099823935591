import { Injectable } from '@angular/core';
import { CustomerApi, IndividualChoiceApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { GlobalDialogService } from '@citypantry/shared-global-dialog';
import { AccountAction, AccountActions, AppState } from '@citypantry/state';
import { IllustrativeIcons } from '@citypantry/util-enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ClipboardService } from 'ngx-clipboard';
import { Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AccountEffects {

  public fetchLocationsWithEaters$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.fetchLocationsWithEaters.type),
    switchMap(() =>
      this.individualChoiceApi.getLocationsWithEaters().pipe(
        map((locations) => AccountActions.locationsWithEatersFetched({ locations }),
        )
      ))
  ));

  public fetchEatersAtLocation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      AccountActions.fetchEatersAtLocation.type,
      AccountActions.promoteLeadUserSuccess.type,
      AccountActions.refreshEatersAtLocation.type,
    ),
    switchMap(({ locationId }) =>
      this.individualChoiceApi.getEatersAtLocation(locationId).pipe(
        map((eaters) => AccountActions.eatersAtLocationFetched({ eaters })),
      )
    ),
  ));

  public deleteEaterFromLocation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.deleteEaterFromLocation.type),
    switchMap(({ userId, locationId }) =>
      this.individualChoiceApi.deleteEaterLocation(userId, locationId).pipe(
        map((returnedLocationId) => AccountActions.refreshEatersAtLocation({ locationId: returnedLocationId })),
      )
    ),
  ));

  public promoteLeadUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.promoteLeadUser.type),
    withLatestFrom(
      this.store.select(AuthSelectors.customer.getId),
    ),
    switchMap(([{ userId, locationId, operationalRegion, phoneNumber }, customerId]) =>
      this.customerApi.promoteLeadUser(customerId, userId, locationId, operationalRegion, phoneNumber).pipe(
        tap(() => {
          this.globalDialogService.showDialog({
            illustrativeIcon: IllustrativeIcons.USER_CONFIRMED,
            title: 'This colleague is now an admin',
            textContent: 'Your colleague has successfully been set as an admin for this account.',
            hideCancelButton: true,
          });
        }),
        map(() => AccountActions.promoteLeadUserSuccess({ locationId })),
      )
    ),
  ));

  public copyShareLocationUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.copyShareLocationUrl.type),
    map(({ shareUrl }) => {
      this.clipboardService.copyFromContent(shareUrl);
      return AccountActions.shareLocationUrlCopied();
    }),
  ));

  constructor(
    private actions$: Actions<AccountAction>,
    private individualChoiceApi: IndividualChoiceApi,
    private customerApi: CustomerApi,
    private clipboardService: ClipboardService,
    private globalDialogService: GlobalDialogService,
    private store: Store<AppState>,
  ) {}
}
