import { normalisePostcode } from '@citypantry/util';
import moment from 'moment';
import { CuisineType, EventType } from '../menu';
import { DietaryType, SearchRequest } from '../search';
import { VendorFlag, VendorId } from '../vendor';

export interface  RecommendationRequest {
  postcode?: string;
  date?: moment.Moment;
  headcount?: number;
  maxBudget?: number;
  cuisines?: CuisineType[];
  events?: EventType[];
  dietaries?: DietaryType[];
  options?: VendorFlag[];
  text?: string;
  excludeVendorId: VendorId | null;
}

export function createRecommendationRequestFromSearchRequest(request: SearchRequest, excludeVendorId: VendorId): RecommendationRequest {
  const recommendationRequest: RecommendationRequest = {
    date: request.date,
    excludeVendorId
  };

  if (request.postcode) {
    recommendationRequest.postcode = normalisePostcode(request.postcode);
  }

  if (request.headcount) {
    recommendationRequest.headcount = request.headcount;
  }

  if (request.maxBudget) {
    recommendationRequest.maxBudget = request.maxBudget;
  }

  if (request.cuisines) {
    recommendationRequest.cuisines = request.cuisines;
  }

  if (request.events) {
    recommendationRequest.events = request.events;
  }

  if (request.dietaries) {
    recommendationRequest.dietaries = request.dietaries;
  }

  if (request.options) {
    recommendationRequest.options = request.options;
  }

  if (request.text) {
    recommendationRequest.text = request.text;
  }

  return recommendationRequest;
}
