import { Pipe, PipeTransform } from '@angular/core';

/**
 * Renders remaining capacity (by default rounded down to the nearest 10).
 *
 * If useExactValue is false, the value will be rounded down to the nearest 10, or "Less than 10" if the value is less than 10.
 * If useExactValue is true, the exact value is always rendered.
 *
 * @example {{ 101 | remainingCapacity }} => Up to 100
 * @example {{ 101 | remainingCapacity:true }} => Up to 101
 * @example {{ 7 | remainingCapacity }} => Less than 10
 * @example {{ 7 | remainingCapacity:true }} => Up to 7
 */
@Pipe({
  name: 'remainingCapacity',
})
export class RemainingCapacityPipe implements PipeTransform {
  public transform(value: number, useExactValue: boolean = false): string {
    if (useExactValue) {
      return `Up to ${ value }`;
    }

    const valueRounded = Math.floor(value / 10) * 10;

    return valueRounded < 10 ? 'Less than 10' : `Up to ${ valueRounded }`;
  }
}
