import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchResult } from '@citypantry/util-models';
import moment from 'moment';
import { SearchResultUrl } from '../search-result/search-result.component';

@Component({
  selector: 'app-search-recommendation-results',
  templateUrl: './search-recommendation-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRecommendationResultsComponent {

  @Input()
  public recommendations: SearchResult[];

  @Input()
  public url: SearchResultUrl;

  @Input()
  public date: moment.Moment | undefined;

  @Input()
  public showDistance: boolean;

  @Input()
  public showExactRemainingCapacity: boolean;

}
