import { Injectable } from '@angular/core';
import { FavouritesApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, FavouriteVendorsAction, FavouriteVendorsActions, RouterActions } from '@citypantry/state';
import { WindowRef } from '@citypantry/util-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class FavouriteVendorsEffects {

  public persistFavouriteToBackEnd: Observable<undefined> = createEffect(() => this.action$.pipe(
    ofType(
      FavouriteVendorsActions.toggleVendorFromSearchComponent.type,
      FavouriteVendorsActions.toggleVendorFromCustomerMenu.type,
    ),
    withLatestFrom(
      this.store.select(AuthSelectors.isLoggedInUser)
    ),
    filter(([, isLoggedInUser]) => isLoggedInUser),
    switchMap(([{ vendorId, favourited }]) => this.favouritesApi.updateFavouriteVendor(vendorId, favourited))
  ), { dispatch: false });

  public storeFavouritesInSessionStorageThenRedirectToLogin$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      FavouriteVendorsActions.toggleVendorFromSearchComponent.type,
      FavouriteVendorsActions.toggleVendorFromCustomerMenu.type,
    ),
    withLatestFrom(
      this.store.select(AuthSelectors.isLoggedInUser)
    ),
    filter(([, isLoggedInUser]) => !isLoggedInUser),
    map(([{ vendorId }]) => {
      this.window.nativeWindow.sessionStorage.setItem('pendingVendorAdd', vendorId as any as string);
      return RouterActions.goExternal({ url: '/login' });
    }),
  ));

  public persistSessionStoredFavouriteAfterLogin$: Observable<void> = createEffect(() => this.action$.pipe(
    ofType(
      FavouriteVendorsActions.pageLoadedWithVendorToAddPresent.type,
    ),
    withLatestFrom(
      this.store.select(AuthSelectors.isLoggedInUser)
    ),
    filter(([, isLoggedInUser]) => isLoggedInUser),
    switchMap(([{ vendorId }]) => {
      this.window.nativeWindow.sessionStorage.removeItem('pendingVendorAdd');
      return this.favouritesApi.updateFavouriteVendor(vendorId, true);
    })
  ), { dispatch : false });

  constructor(
    private action$: Actions<FavouriteVendorsAction>,
    private store: Store<AppState>,
    private window: WindowRef,
    private favouritesApi: FavouritesApi,
  ) {}
}
