import { Injectable } from '@angular/core';
import { OrderApi } from '@citypantry/shared-api';
import { OrderId, OrderTracking } from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { DeliveryDetailsAction, DeliveryDetailsActions } from './delivery-details.actions';

@Injectable({ providedIn: 'root'})
export class DeliveryDetailsEffects {

  public loadTrackingForOrders$: Observable<DeliveryDetailsAction> = createEffect(() => this.actions$.pipe(
    ofType(DeliveryDetailsActions.loadTrackingForOrders),
    switchMap(({ ordersIds }) => from(ordersIds).pipe(
      mergeMap((orderId: OrderId) => this.orderApi.getOrderTracking(orderId)),
      toArray()
    )),
    map((trackingForOrders: OrderTracking[]) => DeliveryDetailsActions.trackingForOrdersLoaded({ trackingForOrders } )),
  ));

  constructor(
    private actions$: Actions<DeliveryDetailsAction>,
    private orderApi: OrderApi,
  ) {}
}
