import { ensureValidEnumValue, UnreachableCaseError } from '@citypantry/util';
import { createAllergensFromJson } from './allergens.model';
import { CuisineTypes } from './cuisine-type.enum';
import { CustomItem, CustomItemOption, CustomItemSection } from './custom-item.model';
import { createDietariesFromJson } from './dietaries.model';
import { EventType, EventTypes } from './event-type.enum';
import { createItemAvailabilityFromJson } from './item-availability.model';
import { ItemBundle } from './item-bundle.model';
import { ItemGroupTypes } from './item-group-type.enum';
import { ChoiceItemGroup, FixedItemGroup, isChoiceItemGroup, isFixedItemGroup, ItemGroup } from './item-group.model';
import { ItemTypes } from './item-type.enum';
import { Item } from './item.model';
import { ServingStyles } from './serving-style.enum';
import { SingleItem } from './single-item.model';

// These functions should really be in their corresponding files.
// However, there would be a circular dependency:
// the single and bundle functions depend on the abstract function which is only item-related,
// whereas the createItem function depends on the single and bundle functions.
// It is therefore easier to just keep them in a separate file.

function createAbstractItemFromJson(json?: Partial<Item & ItemJsonProperties>): Item {
  if (!json) {
    json = {};
  }

  const item: Partial<Item> = {};

  item.id = json.id;
  item.type = json.type;
  item.name = json.name;
  item.dietaries = createDietariesFromJson(json.dietaries);
  item.description = json.description;
  item.images = json.images || [];
  item.price = json.price;
  item.currencyIsoCode = json.currencyIsoCode;
  item.notice = json.notice || null;
  item.minimumOrderQuantity = json.minimumOrderQuantity;
  item.maximumOrderQuantity = json.maximumOrderQuantity;
  item.availability = createItemAvailabilityFromJson(json.availability);
  item.events = (json.events || []).map((value: EventType) => ensureValidEnumValue(EventTypes, value));
  item.servingStyle = json.servingStyle ? ensureValidEnumValue(ServingStyles, json.servingStyle) : null;
  item.cuisine = json.cuisine ? ensureValidEnumValue(CuisineTypes, json.cuisine) : null;
  item.ecoFriendlyPackaging = !!json.ecoFriendlyPackaging;

  return item as Item;
}

interface ItemJsonProperties {
  currencyIsoCode: string;
}

export function createItemFromJson(json?: Partial<ItemBundle | SingleItem | CustomItem>): ItemBundle | SingleItem | CustomItem {
  switch (json.type) {
    case ItemTypes.SINGLE_ITEM:
      return createSingleItemFromJson(json);
    case ItemTypes.ITEM_BUNDLE:
      return createItemBundleFromJson(json);
    case ItemTypes.CUSTOM_ITEM:
      return createCustomItemFromJson(json);
    default:
      throw new UnreachableCaseError(json.type);
  }
}

export function createItemBundleFromJson(json: Partial<ItemBundle>): ItemBundle {
  if (json.type !== ItemTypes.ITEM_BUNDLE) {
    throw new Error(`Cannot create Item Bundle from JSON, type mismatch: ${json.type}`);
  }
  const itemBundle: Partial<ItemBundle> = createAbstractItemFromJson(json);

  itemBundle.groups = (json.groups || []).map(createItemGroupFromJson);
  itemBundle.portionSize = json.portionSize;
  itemBundle.vatRateType = json.vatRateType;
  itemBundle.ageRestricted = !!json.ageRestricted;

  return itemBundle as ItemBundle;
}

export function createSingleItemFromJson(json: Partial<SingleItem>): SingleItem {
  if (json.type !== ItemTypes.SINGLE_ITEM) {
    throw new Error(`Cannot create Single Item from JSON, type mismatch: ${json.type}`);
  }
  const singleItem: Partial<SingleItem> = createAbstractItemFromJson(json);

  singleItem.portionSize = json.portionSize;
  singleItem.hot = json.hot;
  singleItem.fragile = json.fragile || false;
  singleItem.oversized = json.oversized || false;
  singleItem.returnRequired = json.returnRequired || false;
  singleItem.ecoFriendlyPackaging = json.ecoFriendlyPackaging || false;
  singleItem.spicy = json.spicy || false;
  singleItem.containsAlcohol = json.containsAlcohol || false;
  singleItem.ageRestricted = json.ageRestricted || false;
  singleItem.alcoholPercentage = json.alcoholPercentage || null;
  singleItem.allergens = createAllergensFromJson(json.allergens);
  singleItem.ingredients = json.ingredients || [];
  singleItem.foodType = json.foodType;
  singleItem.vatRateType = json.vatRateType;
  singleItem.kcal = json.kcal >= 0 ? json.kcal : null;

  return singleItem as SingleItem;
}

export function createCustomItemFromJson(json: Partial<CustomItem>): CustomItem {
  if (json.type !== ItemTypes.CUSTOM_ITEM) {
    throw new Error(`Cannot create Custom Item from JSON, type mismatch: ${json.type}`);
  }
  const item: Partial<CustomItem> = createAbstractItemFromJson(json);

  item.portionSize = json.portionSize;
  item.hot = json.hot;
  item.fragile = json.fragile || false;
  item.oversized = json.oversized || false;
  item.returnRequired = json.returnRequired || false;
  item.ecoFriendlyPackaging = json.ecoFriendlyPackaging || false;

  item.baseItemProvided = !!json.baseItemProvided;
  item.spicy = json.spicy || false;
  item.containsAlcohol = json.containsAlcohol || false;
  item.baseItemAgeRestricted = json.baseItemAgeRestricted || false;
  item.baseItemAlcoholPercentage = json.baseItemAlcoholPercentage || null;
  item.allergens = createAllergensFromJson(json.allergens);
  item.ingredients = json.ingredients || [];
  item.possibleDietaries = createDietariesFromJson(json.possibleDietaries);
  item.ageRestricted = !!json.ageRestricted;
  item.baseItemKcal = json.baseItemKcal >= 0 ? json.baseItemKcal : null;

  item.foodType = json.foodType;
  item.vatRateType = json.vatRateType;
  item.sections = (json.sections || []).map(createCustomItemSectionFromJson);

  return item as CustomItem;
}

export function createItemGroupFromJson(json: Partial<ItemGroup>): ItemGroup {
  if (ItemGroupTypes.values.indexOf(json.type) < 0) {
    throw new Error(`Cannot create group from JSON, invalid group type '${json.type}'`);
  }

  const group: Partial<ItemGroup> = {
    type: json.type,
    heading: json.heading,
    items: json.items.map(createSingleItemFromJson)
  };

  if (isFixedItemGroup(json)) {
    const fixedGroup = group as Partial<FixedItemGroup>;
    fixedGroup.quantities = (json.quantities || []).map((value) => ({ quantity: value.quantity || 0, itemId: value.itemId }));
  } else if (isChoiceItemGroup(json)) {
    const choiceGroup = group as Partial<ChoiceItemGroup>;
    choiceGroup.minimumOrderQuantity = json.minimumOrderQuantity;
    choiceGroup.maximumOrderQuantity = json.maximumOrderQuantity;
  }

  return group as ItemGroup;
}

export function createCustomItemSectionFromJson(json: Partial<CustomItemSection>): CustomItemSection {
  json = json || {};

  const section: Partial<CustomItemSection> = {};

  section.name = json.name;
  section.minOptions = json.minOptions || 0;
  section.maxOptions = json.maxOptions || null;
  section.options = (json.options || []).map(createCustomItemOptionFromJson);

  return section as CustomItemSection;
}

export function createCustomItemOptionFromJson(json: Partial<CustomItemOption>): CustomItemOption {
  json = json || {};

  const option: Partial<CustomItemOption> = {};

  option.name = json.name;
  option.maxQuantity = json.maxQuantity || 0;
  option.price = json.price || 0;
  option.dietaries = createDietariesFromJson(json.dietaries);
  option.allergens = createAllergensFromJson(json.allergens);
  option.ingredients = json.ingredients || [];
  option.spicy = !!json.spicy;
  option.containsAlcohol = !!json.containsAlcohol;
  option.ageRestricted = !!json.ageRestricted;
  option.alcoholPercentage = json.alcoholPercentage || null;
  option.optionIndex = json.optionIndex;
  option.kcal = json.kcal >= 0 ? json.kcal : null;

  return option as CustomItemOption;
}
