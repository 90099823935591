/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type SearchSortType = 'relevance' | 'minimumPrice' | 'vendorDateAdded' | 'distance' | 'topPicks';

export class SearchSortTypes {
  public static RELEVANCE:         SearchSortType & 'relevance'       = 'relevance';
  public static MINIMUM_PRICE:     SearchSortType & 'minimumPrice'    = 'minimumPrice';
  public static VENDOR_DATE_ADDED: SearchSortType & 'vendorDateAdded' = 'vendorDateAdded';
  public static DISTANCE:          SearchSortType & 'distance'        = 'distance';
  public static TOP_PICKS:         SearchSortType & 'topPicks'        = 'topPicks';

  public static get values(): SearchSortType[] {
    return [
      SearchSortTypes.RELEVANCE,
      SearchSortTypes.MINIMUM_PRICE,
      SearchSortTypes.VENDOR_DATE_ADDED,
      SearchSortTypes.DISTANCE,
      SearchSortTypes.TOP_PICKS,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type SearchSortTypeEnum = typeof SearchSortTypes;
