import moment from 'moment';

export interface MenuAvailability {
  menuId: string;
  from: moment.Moment;
  to?: moment.Moment;
}

interface JsonAvailability {
  menuId: string;
  from: moment.Moment | string;
  to?: moment.Moment | string;
}

export function createMenuAvailabilityFromJson(json: JsonAvailability): MenuAvailability {
  const availability: Partial<MenuAvailability> = {};

  availability.menuId = json.menuId;
  availability.from = moment(json.from);
  if (json.to) {
    availability.to = moment(json.to);
  }

  return availability as MenuAvailability;
}
