import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItems, mapmap } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  BudgetConfigs,
  ColleagueGroupResult,
  ColleagueGroupSummary,
  createColleagueGroupResultFromJson,
  createColleagueGroupSummaryFromJson,
  createEaterFromJson,
  Eater,
  ErrorMessage,
  ErrorResponse,
  SubsidisedOrderLimitConfigs,
  ValidationErrorMessage,
  ValidationResponse,
} from '@citypantry/util-models';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ColleagueGroupApi {

  constructor(private http: HttpClient) {
  }

  public createColleagueGroup(
    name: string,
  ): Observable<ValidationResponse<ColleagueGroupResult, ErrorMessage[] | ValidationErrorMessage>> {
    const body = {
      name,
    };

    return this.http.post(
      '/customer/colleague-group/manual-colleague-group',
      body,
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}}
    ).pipe(
      map(createColleagueGroupResultFromJson),
      map((colleagueGroupResult: ColleagueGroupResult) =>  ({
        success: true as const,
        value: colleagueGroupResult,
      })),
      catchError((errorResponse: HttpErrorResponse | ErrorResponse) => {
        const errors = errorResponse instanceof ErrorResponse
          ? errorResponse.apiResponse.error.errors
          : errorResponse.error.errors;

        return of({
          success: false as const,
          error: errors
        });
      }),
    );
  }

  public updateColleagueGroup(
    groupOid: string,
    name: string,
    memberIds: string[],
    budgetConfigs: BudgetConfigs,
    subsidisedOrderLimitConfigs: SubsidisedOrderLimitConfigs
  ): Observable<ValidationResponse<ColleagueGroupResult, ErrorMessage[] | ValidationErrorMessage>> {
    const body = {
      name,
      members: memberIds,
      perOrderBudgetConfig: budgetConfigs.perOrder,
      perDayBudgetConfig: budgetConfigs.perDay,
      perWeekBudgetConfig: budgetConfigs.perWeek,
      perMonthBudgetConfig: budgetConfigs.perMonth,
      perDaySubsidisedOrderLimitConfig: subsidisedOrderLimitConfigs.perDay,
      perWeekSubsidisedOrderLimitConfig: subsidisedOrderLimitConfigs.perWeek,
      perMonthSubsidisedOrderLimitConfig: subsidisedOrderLimitConfigs.perMonth,
    };

    return this.http.put(
      `/customer/colleague-group/manual-colleague-group/${groupOid}`,
      body,
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}}
    ).pipe(
      map(createColleagueGroupResultFromJson),
      map((colleagueGroupResult: ColleagueGroupResult) =>  ({
        success: true as const,
        value: colleagueGroupResult,
      })),
      catchError((errorResponse: HttpErrorResponse | ErrorResponse) => {
        const error = errorResponse instanceof ErrorResponse
          ? errorResponse.apiResponse.error.errors
          : errorResponse.error.errors;

        return of({
          success: false as const,
          error
        });
      }),
    );
  }

  public getColleagueGroup(groupOid: string): Observable<ColleagueGroupResult> {
    return this.http.get(`/customer/colleague-group/manual-colleague-group/${groupOid}`).pipe(
      map(createColleagueGroupResultFromJson)
    );
  }

  public searchColleaguesNotInGroups(search: string): Observable<Eater[]> {
    return this.http.get(`/customer/eaters/not-in-colleague-group?filterTerm=${search}`).pipe(
      getItems(),
      mapmap(createEaterFromJson),
    );
  }

  public deleteColleagueGroup(groupOid: string): Observable<true> {
    return this.http.delete(`/customer/colleague-group/manual-colleague-group/${groupOid}`).pipe(
      mapTo<any, true>(true)
    );
  }

  public getGroupsSummary(): Observable<ColleagueGroupSummary[]> {
    return this.http.get(`/customer/colleague-group/colleague-groups-summary`).pipe(
      getItems(),
      mapmap(createColleagueGroupSummaryFromJson)
    );
  }
}
