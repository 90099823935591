import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { numberOfVisibleDietariesSet, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-upgrade-group-item',
  templateUrl: './upgrade-group-item.component.html'
})
export class UpgradeGroupItemComponent {

  @Input()
  public item: SingleItem;

  @Input()
  public control: FormControl;

  @Input()
  public showMoreInfo: boolean;

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public hidePrices: boolean;

  @Output()
  public toggleMoreInfo: EventEmitter<void> = new EventEmitter();

  @Output()
  public remove: EventEmitter<void> = new EventEmitter();

  public onToggleShowMoreInfo(): void {
    this.toggleMoreInfo.emit();
  }

  public onRemove(): void {
    this.remove.emit();
  }

  public hasDietaries(): boolean {
    return this.item && numberOfVisibleDietariesSet(this.item.dietaries) > 0;
  }

  public shouldShowKcals(): boolean {
    return this.item.kcal !== null && this.item.kcal >= 0;
  }
}
