<div
  class="item-panel"
  [class.item-panel--with-image]="getImage()"
  [attr.data-item-id]="itemBundle.id"
  test-id="root"
>
  <div
    *ngIf="disabled || selectionEnabled && !itemBundle.selected"
    class="item-panel__overlay"
    test-id="disabledOverlay"
  >
    <div
      *ngIf="deliverabilityProblems.length > 0"
      class="panel panel--white panel--shadow"
      test-id="problemsPanel"
    >
      <div
        class="alert alert--info alert--transparent"
        *ngFor="let problem of deliverabilityProblems"
      >{{ problem | translateDeliverabilityProblemToMessage }}</div>
    </div>
  </div>

  <app-menu-item-image
    *ngIf="getImage()"
    [images]="itemBundle.images"
    [itemType]="itemBundle.type"
    (click)="onClickImage()"
    test-id="itemImage"
  ></app-menu-item-image>

  <div
    class="item-panel__title-and-description"
    test-id="titleAndDescription"
  >
    <h3 class="item-panel__title">
      {{ itemBundle.name }}
    </h3>
    <p class="item-panel__description">
      {{ itemBundle.description }}
      <a
        (click)="select()"
        class="item-panel__info-text-button"
        test-id="moreInfo"
      >More info</a>
    </p>
  </div>
  <div
    class="item-panel__meta"
    test-id="meta"
  >
    <div
      *ngIf="itemBundle.ageRestricted"
      class="item-panel__info-indicator mr-none"
    >
      <span
        class="icon icon--medium"
        aria-label="Age restricted"
        test-id="ageRestricted"
      >age_restricted</span>
    </div>
    <div
      *ngIf="hasDietaries()"
      class="item-panel__info-indicator vertical-separator"
      test-id="dietaries"
    >
      <app-dietaries
        [dietaries]="itemBundle.dietaries"
      ></app-dietaries>
    </div>
    <div
      *ngIf="itemBundle.portionSize > 1"
      class="item-panel__info-indicator"
    >
      <app-feeds-quantity
        [quantity]="itemBundle.portionSize"
      ></app-feeds-quantity>
    </div>
    <div
      *ngIf="itemBundle.minimumOrderQuantity > 1"
      class="item-panel__info-indicator">
      <app-minimum-order-quantity
        [minimumQuantity]="itemBundle.minimumOrderQuantity"
        test-id="minimumOrderQuantity"
      ></app-minimum-order-quantity>
    </div>
    <div class="item-panel__info-indicator">
      <app-serving-style
        [servingStyle]="itemBundle.servingStyle"
      ></app-serving-style>
    </div>
    <div
      *ngIf="itemBundle.ecoFriendlyPackaging"
      class="item-panel__info-indicator"
    >
      <app-eco-friendly-packaging
        test-id="ecoFriendlyPackaging"
      ></app-eco-friendly-packaging>
    </div>
  </div>

  <div
    class="item-panel__price-portion"
    test-id="pricePortion"
  >
    <ng-container *ngIf="!hidePrices">

      <p
        class="item-panel__price"
        test-id="itemPanelPrice"
      >
        {{ itemBundle.price | currency:itemBundle.currencyIsoCode }}
      </p>
      <p
        test-id="portionSize"
        class="item-panel__portion"
        *ngIf="itemBundle.portionSize > 1"
      >
        {{ calculatePricePerPerson() | currency:itemBundle.currencyIsoCode }} per person
      </p>
      <p
        *ngIf="!zeroVatRateTypes.includes(itemBundle.vatRateType)"
        class="item-panel__price-info"
        test-id="priceInfo"
      >
        (incl. VAT)
      </p>
    </ng-container>
    <div class="item-panel__actions">
      <ng-content></ng-content>
    </div>
  </div>
</div>
