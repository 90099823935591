import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const vendorOrderPageRules: AnalyticsRule[] = [
  {
    url: '/menus/vendor/order/:orderId',
    path: 'vendor-order-page print-item-labels',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print item labels',
    },
  },
  {
    url: '/menus/vendor/order/:orderId',
    path: 'print-issue-dialog dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print issue dialog - close',
    },
  },
  {
    url: '/menus/vendor/order/:orderId',
    path: 'print-issue-dialog dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print issue dialog - view FAQs',
    },
  },
  {
    url: '/menus/vendor/order/:orderId',
    path: 'print-labels-dialog cancel',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print item labels - cancel',
      extraFields: {
        sortOrder: event.data['cancel'].sortOrder
      }
    }),
  },
  {
    url: '/menus/vendor/order/:orderId',
    path: 'print-labels-dialog download-labels-as-pdf',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print item labels - download labels as pdf',
      extraFields: {
        sortOrder: event.data['download-labels-as-pdf'].sortOrder
      }
    }),
  },
  {
    url: '/menus/vendor/order/:orderId',
    path: 'print-labels-dialog print-labels',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.VENDOR_ORDER_PAGE,
      label: 'Print item labels - print labels',
      extraFields: {
        sortOrder: event.data['print-labels'].sortOrder
      }
    }),
  },
];
