<div
  class="px-medium"
  analyticsId="promotion-panel"
  [analyticsData]="{ name: promotionName }"
  test-id="panel"
>
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <i
        [class]="'illustrative-icon illustrative-icon--' + icon + ' illustrative-icon--medium'"
        test-id="icon"
      ></i>
    </div>
    <h4 class="sub-header mb-none text-center mx-small" test-id="title">
      {{ title }}
    </h4>
    <div>
      <i
        [class]="'illustrative-icon illustrative-icon--' + icon + ' illustrative-icon--medium'"
        test-id="icon"
      ></i>
    </div>
  </div>

  <div class="body-text text-center" test-id="body1">
    {{ body1 }}
  </div>

  <div class="body-text text-center">
    <a
        href="{{ ctaLink }}"
        target="_blank"
        test-id="ctaLink"
        analyticsId="cta"
    >{{ ctaText }}</a>

    <span class="text-center" test-id="body2">
      {{ body2 }}
    </span>
  </div>
  <hr class="horizontal-line mt-large">
  <app-in-viewport analyticsId="in-viewport"></app-in-viewport>
</div>
