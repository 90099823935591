export type DeliveryViability = 'not_assigned' | 'out_of_radius' | 'viable' | 'out_of_hours' | 'headcount_capacity_exceeded';

export class DeliveryViabilities {
  public static NOT_ASSIGNED: DeliveryViability = 'not_assigned';
  public static OUT_OF_RADIUS: DeliveryViability = 'out_of_radius';
  public static VIABLE: DeliveryViability = 'viable';
  public static OUT_OF_HOURS: DeliveryViability = 'out_of_hours';
  public static HEADCOUNT_CAPACITY_EXCEEDED: DeliveryViability = 'headcount_capacity_exceeded';

  public static get values(): DeliveryViability[] {
    return [
      DeliveryViabilities.NOT_ASSIGNED,
      DeliveryViabilities.OUT_OF_RADIUS,
      DeliveryViabilities.VIABLE,
      DeliveryViabilities.OUT_OF_HOURS,
      DeliveryViabilities.HEADCOUNT_CAPACITY_EXCEEDED,
    ];
  }
}

export type DeliveryViabilityEnum = typeof DeliveryViabilities;
