import { ItemGroupType, ItemGroupTypes } from './item-group-type.enum';
import { ItemId } from './item.model';
import { SingleItem } from './single-item.model';

export interface ItemGroup {
  type: ItemGroupType;
  heading: string;
  items: SingleItem[];
}

// TODO CPD-11361: refactor to a factory
export const EMPTY_ITEM_GROUP: ItemGroup = {
  type: ItemGroupTypes.FLEXIBLE_GROUP,
  heading: '',
  items: []
};
Object.freeze(EMPTY_ITEM_GROUP);

export interface FixedItemGroup extends ItemGroup {
  type: 'FixedGroup';
  quantities: { itemId: ItemId, quantity: number }[];
}

export interface ChoiceItemGroup extends ItemGroup {
  type: 'ChoiceGroup';
  minimumOrderQuantity: number;
  maximumOrderQuantity: number;
}

export interface FlexibleItemGroup extends ItemGroup {
  type: 'FlexibleGroup';
}

export interface UpgradeItemGroup extends ItemGroup {
  type: 'UpgradeGroup';
}

// For the createItemGroupFromJson method see create-item.ts

export function isFixedItemGroup(data: Partial<ItemGroup> | ItemGroup): data is FixedItemGroup {
  return (data.type && data.type === ItemGroupTypes.FIXED_GROUP);
}

export function isChoiceItemGroup(data: Partial<ItemGroup> | ItemGroup): data is ChoiceItemGroup {
  return (data.type && data.type === ItemGroupTypes.CHOICE_GROUP);
}

export function isFlexibleItemGroup(data: Partial<ItemGroup> | ItemGroup): data is ChoiceItemGroup {
  return (data.type && data.type === ItemGroupTypes.FLEXIBLE_GROUP);
}

export function isUpgradeItemGroup(data: Partial<ItemGroup> | ItemGroup): data is UpgradeItemGroup {
  return (data.type && data.type === ItemGroupTypes.UPGRADE_GROUP);
}
