import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TypedSimpleChanges } from '@citypantry/util';
import { SearchRequest, VendorFlag, VendorFlags } from '@citypantry/util-models';

@Component({
  selector: 'app-search-filters-eco-friendly-packaging-toggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      class="control-button control-button--emphasis control-button--expand-tablet"
      [class.control-button--active]="enabled"
      (click)="onToggle()"
      test-id="button"
      analyticsId="eco-friendly-packaging-filter"
      [analyticsData]="{ enabled: enabled }"
    >
      <div class="control-button__content">
        <div class="toggle-input">
          <i class="material-icons" test-id="icon" aria-hidden=true>eco</i>
          <span class="ml-small">Eco Friendly Packaging</span>
        </div>
      </div>
    </button>
  `
})
export class SearchFiltersEcoFriendlyPackagingToggleComponent implements OnChanges {

  @Input()
  public searchRequest: SearchRequest;

  @Output()
  public enabledChange: EventEmitter<{ enabled: boolean, currentOptions: VendorFlag[] }> = new EventEmitter();

  public enabled: boolean;

  public ngOnChanges(changes: TypedSimpleChanges<SearchFiltersEcoFriendlyPackagingToggleComponent>): void {
    if (changes.searchRequest && changes.searchRequest.currentValue) {
      const { options } = changes.searchRequest.currentValue;

      this.enabled = options && options.includes(VendorFlags.ECO_FRIENDLY_PACKAGING);
    }
  }

  public onToggle(): void {
    this.enabledChange.emit({
      enabled: !this.enabled,
      currentOptions: this.searchRequest && this.searchRequest.options || []
    });
  }
}
