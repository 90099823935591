import { ItemTypes } from '@citypantry/util-models';
import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const singleItemFormPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/items/:id',
    path: 'single-item-editor-sidebar save-item-and-return',
    events: 'click',
    definition: ((event) => ({
      category: AnalyticsCategories.ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Item save and return clicked',
      extraFields: {
        itemType: ItemTypes.SINGLE_ITEM,
        itemId: event.data['single-item-editor-sidebar'].itemId,
        isItemComplete: event.data['single-item-editor-sidebar'].isItemComplete,
      },
    })),
  },
  {
    url: '/menus/builder/items/:id',
    path: 'single-item-editor-sidebar save-item',
    events: 'click',
    definition: ((event) => ({
      category: AnalyticsCategories.ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Item save clicked',
      extraFields: {
        itemType: ItemTypes.SINGLE_ITEM,
        itemId: event.data['single-item-editor-sidebar'].itemId,
        isItemComplete: event.data['single-item-editor-sidebar'].isItemComplete,
      },
    })),
  },
];
