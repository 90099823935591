import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyHolidaysActions } from './company-holidays.actions';
import { CompanyHolidaysSelectors } from './company-holidays.select';

@Injectable({
  providedIn: 'root'
})
export class CompanyHolidaysGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.store.select(CompanyHolidaysSelectors.getAll).pipe(
      map((holidays: Moment[]) => !!holidays.length),
      take(1),
      map((holidaysExist: boolean) => {
        if (!holidaysExist) {
          this.store.dispatch(CompanyHolidaysActions.loadCompanyHolidays());
        }

        return true;
      }),
    );
  }
}
