import { Component, Input, OnInit } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { MarketingCampaignComponentModel, MarketingCampaignComponentNames, MarketingCampaignService } from '@citypantry/shared-marketing-campaign';
import { AppState, Select } from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { SearchQueries } from '@citypantry/state-queries';
import { CustomerLocation, CustomerLocationId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Parameters } from '../parameters-form/parameters.model';

@Component({
  selector: 'app-search-parameters-popup',
  template: `
    <app-search-parameters-popup-content
      [params]="currentRequest$ | async"
      [locations]="customerLocations$ | async"
      [autocomplete]="autocomplete"
      [marketingCampaignComponent]="searchParametersMarketingCampaignComponentModel"
      [canEnterPostcode]="canEnterPostcode"
      (complete)="onSubmitParameters($event)"
      analyticsId="marketplace-search-popup"
      test-id="content"
    ></app-search-parameters-popup-content>
  `,
})
export class SearchParametersPopupComponent implements OnInit {

  @Input()
  public autocomplete: boolean;

  @Input()
  public canEnterPostcode: boolean;

  public currentRequest$: Observable<Parameters>;

  @Select(AuthSelectors.customer.getLocations)
  public customerLocations$: Observable<CustomerLocation[]>;
  public searchParametersMarketingCampaignComponentModel: MarketingCampaignComponentModel | null;

  constructor(
    private store: Store<AppState>,
    private searchQueries: SearchQueries,
    private marketingCampaignService: MarketingCampaignService,
  ) {
  }

  public ngOnInit(): void {
    this.currentRequest$ = this.searchQueries.getSearchDateAndPostcode();
    this.searchParametersMarketingCampaignComponentModel = this.marketingCampaignService.getMarketingCampaignComponentModel(
      MarketingCampaignComponentNames.SEARCH_PARAMS_MODAL_FOOTER,
    );
  }

  public onSubmitParameters(params: Parameters): void {
    this.store.dispatch(PublicActions.search({
      request:
        {
          date: params.date,
          postcode: params.postcode,
          location: params.location ? params.location as CustomerLocationId : null
        },
    }));
  }
}
