import { Injectable } from '@angular/core';
import { Client, DataCollector, ThreeDSecure } from 'braintree-web';

import { Braintree } from './braintree';

@Injectable({
  providedIn: 'root'
})
export class BraintreeApi {

  constructor(
    private braintree: Braintree
  ) {}

  public async createBraintreeClient(token: string): Promise<Client> {
    const client = await this.braintree.getClient();

    return client.create({ authorization: token });
  }

  public async createBraintreeDataCollector(client: Client): Promise<DataCollector> {
    const dataCollector = await this.braintree.getDataCollector();

    return dataCollector.create({
      client,
      kount: true,
      paypal: false
    });
  }

  public async createThreeDSecureInstance(client: Client): Promise<ThreeDSecure> {
    const threeDSecure = await this.braintree.getThreeDSecure();

    return threeDSecure.create({
      version: '2', // Uses 3DS2 whenever possible but falls back to 3DS1
      client
    });
  }
}
