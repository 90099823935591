// TODO: this is a component for legacy search page. remove it when all search pages are using the new design.

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { boolObjectToStrings, stringsToBoolObject } from '@citypantry/util';
import { CuisineType, CuisineTypes, DietaryType, DietaryTypes, EventType, EventTypes, SearchRequest } from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';

@Component({
  selector: 'app-search-filters-form',
  templateUrl: './search-filters-form.component.html',
  styleUrls: ['./search-filters-form.component.scss']
})
export class SearchFiltersFormComponent implements OnInit, OnChanges {
  @Input()
  public request: SearchRequest;

  @Input()
  public isIndividualChoiceEnabled: boolean;

  @Input()
  public isWeWorkUser: boolean;

  @Input()
  public isWeWorkModeEnabled: boolean;

  @Output()
  public requestChanged: EventEmitter<Partial<SearchRequest>> = new EventEmitter();

  @Output()
  public selectIndividualChoice: EventEmitter<void> = new EventEmitter();

  @Output()
  public changeWeWorkMode: EventEmitter<boolean> = new EventEmitter();

  public form: FormGroup;
  public showCuisines: boolean;
  public showEvents: boolean;
  public displayedEventTypes: EventType[];
  public showDietaries: boolean;
  public cuisineTypes: CuisineType[] = CuisineTypes.values;
  public eventTypes: EventType[] = EventTypes.values;
  public dietaryTypes: DietaryType[] = DietaryTypes.values;

  constructor(
    private fb: FormBuilder,
  ) {
    // Default states for the toggleable boxes
    this.showEvents = false;
    this.showCuisines = false;
    this.showDietaries = false;

    this.displayedEventTypes = this.eventTypes.slice(0, 5);
  }

  public ngOnInit(): void {
    this.updateForm();

    if (this.request.events && this.request.events.length) {
      const visibleEvents = this.displayedEventTypes.filter((val) => this.request.events.indexOf(val) >= 0);
      // check if any of the search requests events are hidden
      if (visibleEvents.length !== this.request.events.length) {
        this.showAllEvents();
      }
      this.showEvents = true;
    }
    if (this.request.cuisines && this.request.cuisines.length) {
      this.showCuisines = true;
    }
    if (this.request.dietaries && this.request.dietaries.length) {
      this.showDietaries = true;
    }
  }

  public ngOnChanges(): void {
    this.updateForm();
  }

  public showAllEvents(): void {
    this.displayedEventTypes = this.eventTypes;
  }

  public onIndividualChoiceToggleChanged(): void {
    this.selectIndividualChoice.emit();
  }

  public onWeWorkModeChanged(enabled: boolean): void {
    this.changeWeWorkMode.emit(enabled);
  }

  private updateForm(): void {
    if (!this.form) {
      this.buildForm();
    } else {
      this.form.patchValue(this.createFormFieldsFromRequest(this.request), { emitEvent: false });
    }
  }

  private buildForm(): void {
    const initialValue = this.createFormFieldsFromRequest(this.request);

    this.form = this.fb.group({
      // prepare-search-request.guard ensures the date exists here
      headcount: [initialValue.headcount, CustomValidators.min(1)],
      maxBudget: [initialValue.maxBudget, CustomValidators.min(0.01)],
      cuisines: this.fb.group(initialValue.cuisines),
      events: this.fb.group(initialValue.events),
      dietaries: this.fb.group(initialValue.dietaries),
    });

    this.form.valueChanges.subscribe((value) => {
      if (this.form.valid) {
        const request = this.createRequestFromFormFields(value);
        this.requestChanged.emit(request);
      }
    });
  }

  private createFormFieldsFromRequest(request: SearchRequest): any {
    return {
      headcount: request.headcount,
      maxBudget: request.maxBudget,
      cuisines: stringsToBoolObject(CuisineTypes.values, request.cuisines),
      events: stringsToBoolObject(EventTypes.values, request.events),
      dietaries: stringsToBoolObject(DietaryTypes.values, request.dietaries),
    };
  }

  private createRequestFromFormFields(value: any): Partial<SearchRequest> {
    // These properties are always present
    const request: Partial<SearchRequest> = {
      headcount: value.headcount,
      maxBudget: value.maxBudget,
      cuisines: boolObjectToStrings<CuisineType>(value.cuisines, CuisineTypes.values),
      events: boolObjectToStrings<EventType>(value.events, EventTypes.values),
      dietaries: boolObjectToStrings<DietaryType>(value.dietaries, DietaryTypes.values),
    };

    return request;
  }
}
