import moment from 'moment';
import { createSimpleCustomerLocationFromJson, SimpleCustomerLocation } from '../../customer';
import { Image } from '../../image.model';
import { ItemId } from '../../menu/items';
import { MajorCurrency } from '../../money';
import { OrderId } from '../../order';
import { VendorId } from '../../vendor';
import { VendorLocationCapacityStatus, VendorLocationCapacityStatuses } from '../../vendor/vendor-location-capacity-status.enum';
import { EaterCartStatus, EaterCartStatuses } from './eater-cart-status.enum';

export const ALMOST_SOLD_OUT_HEADCOUNT_PERCENTAGE = 0.6;

/**
 * An eater's view of their meal. Used e.g. in My-Meals.
 */
export interface EaterMeal {
  orderId: OrderId;
  isCancelled: boolean;
  isMarkedAsAway: boolean;
  isMultiVendor: boolean;
  location: SimpleCustomerLocation;
  requestedDeliveryDate: moment.Moment;
  orderDeadline: moment.Moment;
  choiceOpenTime: moment.Moment | null;
  choiceDeadline: moment.Moment;
  eaterOptions: EaterMealOption[];
  isSubsidisedChoice: boolean;
  useDeskNumbers: boolean;
  enforceSingleVendorChoice: boolean;
}

export interface EaterMealOption {
  orderId: OrderId;
  orderHumanId: number;
  isDelivered: boolean;
  vendorId: VendorId;
  vendorName: string;
  vendorImage: Image[];
  vendorLocationName: string | null;
  itemIds: ItemId[];
  itemNames: string[];
  topUpValue: MajorCurrency;
  eaterCartStatus: EaterCartStatus;
  deskNumber: string | null;
  vendorLocationCapacityStatus: VendorLocationCapacityStatus;
}

export function createEaterMealFromJson(json?: Partial<EaterMeal>): EaterMeal {
  json = json || {};
  const eaterMeal: Partial<EaterMeal> = {};

  eaterMeal.orderId = json.orderId;
  eaterMeal.isCancelled = !!json.isCancelled;
  eaterMeal.isMarkedAsAway = !!json.isMarkedAsAway;
  eaterMeal.location = createSimpleCustomerLocationFromJson(json.location);
  eaterMeal.requestedDeliveryDate = json.requestedDeliveryDate ? moment(json.requestedDeliveryDate) : null;
  eaterMeal.orderDeadline = json.orderDeadline ? moment(json.orderDeadline) : null;
  eaterMeal.choiceOpenTime = json.choiceOpenTime ? moment(json.choiceOpenTime) : null;
  eaterMeal.choiceDeadline = json.choiceDeadline ? moment(json.choiceDeadline) : null;
  eaterMeal.eaterOptions = (json.eaterOptions || []).map(createEaterMealOptionFromJson);
  eaterMeal.isSubsidisedChoice = !!json.isSubsidisedChoice;
  eaterMeal.useDeskNumbers = json.useDeskNumbers;
  eaterMeal.enforceSingleVendorChoice = !!json.enforceSingleVendorChoice;

  return eaterMeal as EaterMeal;
}

export function createEaterMealOptionFromJson(json?: Partial<EaterMealOption>): EaterMealOption {
  return {
    orderId: json.orderId,
    orderHumanId: json.orderHumanId,
    isDelivered: json.isDelivered,
    vendorId: json.vendorId,
    vendorName: json.vendorName,
    vendorLocationName: json.vendorLocationName || null,
    vendorImage: json.vendorImage || [],
    itemIds: json.itemIds || [],
    itemNames: json.itemNames || [],
    topUpValue: json.topUpValue || 0,
    eaterCartStatus: json.eaterCartStatus || null,
    deskNumber: json.deskNumber || null,
    vendorLocationCapacityStatus: json.vendorLocationCapacityStatus,
  };
}

export namespace EaterMealOption {
  /**
   * Returns whether the eater has made a choice for the given option and paid successfully (if applicable).
   */
  export const isConfirmed = (option: EaterMealOption): boolean =>
    option.itemNames.length > 0 && option.eaterCartStatus === EaterCartStatuses.CONFIRMED;

  export const isPendingTopupConfirmation = (option: EaterMealOption): boolean =>
    option.eaterCartStatus === EaterCartStatuses.PENDING_TOP_UP_PAYMENT_CONFIRMATION;

  export const isAvailable = (option: EaterMealOption): boolean =>
    option.vendorLocationCapacityStatus === VendorLocationCapacityStatuses.AVAILABLE;

  export const isAlmostSoldOut = (option: EaterMealOption): boolean =>
    option.vendorLocationCapacityStatus === VendorLocationCapacityStatuses.ALMOST_SOLD_OUT;

  export const isSoldOut = (option: EaterMealOption): boolean =>
    option.vendorLocationCapacityStatus === VendorLocationCapacityStatuses.SOLD_OUT;
}
