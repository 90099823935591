<app-modal
  [show]="true"
  (clickOutsideModal)="closeModal()"
>
  <div class="dialog">
    <button
      (click)="closeModal()"
      class="dialog__close"
      analyticsId="close-cross"
      test-id="closeCross"
    >
      Close
    </button>

    <ng-content
      *ngIf="!isCompleted"
    ></ng-content>

    <div
      *ngIf="isCompleted"
      class="p-large"
      test-id="completed"
    >
      <h2 class="text-center">Thank you for your time</h2>
      <p class="text-center mt-medium">We'll now be able to help you find the best options.</p>
      <button
        class="button button--full-width mt-medium"
        (click)="closeModal()"
        test-id="closeButton"
      >
        Close
      </button>
    </div>
  </div>
</app-modal>
