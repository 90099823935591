/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type ItemGroupType = 'FlexibleGroup' | 'FixedGroup' | 'ChoiceGroup' | 'UpgradeGroup';

export class ItemGroupTypes {
  public static FLEXIBLE_GROUP: ItemGroupType & 'FlexibleGroup' = 'FlexibleGroup';
  public static FIXED_GROUP:    ItemGroupType & 'FixedGroup'    = 'FixedGroup';
  public static CHOICE_GROUP:   ItemGroupType & 'ChoiceGroup'   = 'ChoiceGroup';
  public static UPGRADE_GROUP:  ItemGroupType & 'UpgradeGroup'  = 'UpgradeGroup';

  public static get values(): ItemGroupType[] {
    return [
      ItemGroupTypes.FLEXIBLE_GROUP,
      ItemGroupTypes.FIXED_GROUP,
      ItemGroupTypes.CHOICE_GROUP,
      ItemGroupTypes.UPGRADE_GROUP,
    ];
  }
}

export type ItemGroupTypeEnum = typeof ItemGroupTypes;
