<div class="dietaries-filter-box">
  <h3 class="dietaries-filter-box__title">Dietaries filters</h3>
  <div class="dietaries-filter-box__item-filters">
    <ng-container *ngFor="let dietary of DIETARIES_DISPLAY_ORDER">
      <div
        class="dietaries-filter-box__item"
        [ngClass]= "cssClass(dietary)"
        [class.dietaries-filter-box__item--clickable]="dietaries && dietaries[dietary]"
        [class.dietaries-filter-box__item--unavailable]="dietaries && !dietaries[dietary]"
        (click)="clickDietary(dietary)"
        test-id="dietaryFilter"
      >
        <app-diet-icon
          *ngIf="showUnavailable || dietaries && dietaries[dietary]"
          class="dietaries-filter-box__item-icon"
          [diet]="dietary"
          [available]="dietaries && dietaries[dietary]"
          [active]="filteredDietaries.includes(dietary)"
          [tooltipEnabled]="false"
          test-id="dietaryIcon"
        ></app-diet-icon>
        <span
          class="dietaries-filter-box__item-label"
          test-id="diet-label"
        >
          {{ dietary | dietName }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
