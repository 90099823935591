import { animateChild, group, query, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContextDropdownComponent } from '@citypantry/components-context-dropdown';
import { environment } from '@citypantry/shared-app-config';
import { slideInOut } from '@citypantry/util-animations';
import {
  Cart,
  CartDeliverabilityProblem,
  CartDeliverabilityProblemTypes,
  CartItem,
  CartItemAdjustment,
  CartStatus,
  CartStatusEnum,
  CartStatuses,
  CartType,
  CartTypes,
  CartValidityError,
  CartValidityErrorTypes,
  CustomerLocation,
  IndividualChoiceEligibility,
  isBundleCartItem,
  isSingleCartItem,
  isUndeliverableItemsProblem,
  Item,
  ItemBundle,
  majorCurrencyToMinor,
  MinorCurrency,
  OrderValidationError,
  SingleItem,
  UndeliverableItems,
  Vendor,
} from '@citypantry/util-models';
import { CartSubtotalComponent } from '../cart-subtotal/cart-subtotal.component';

export interface SetSingleItemQuantityDetails {
  item: SingleItem;
  quantity: number;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [
    trigger('slideInOut', slideInOut),
    trigger('removeItem', [
      // See https://www.reddit.com/r/Angular2/comments/6jikp2/animations_on_child_elements_dont_fire_leave_if/djfk4hk/
      transition(':leave', [
        group([
          query('@remove', animateChild())
        ])
      ])
    ])
  ]
})
export class CartComponent {

  // ========== //
  //   INPUTS   //
  // ========== //

  @Input()
  public cart: Cart;

  @Input()
  public vendor: Vendor;

  @Input()
  public cartType: CartType;

  @Input()
  public minOrderValue: number;

  @Input()
  public requiredHeadcount: number;

  @Input()
  public deliveryNoticeHours: number;

  @Input()
  public validityErrors: CartValidityError[];

  @Input()
  public deliverabilityProblems: CartDeliverabilityProblem[];

  @Input()
  public priceChange: MinorCurrency; // Positive for an increase, negative for a decrease

  @Input()
  public disableEditingParams: boolean;

  @Input()
  public isStaffOrSudo: boolean;

  @Input()
  public canDownloadQuote: boolean;

  @Input()
  public status: CartStatus;

  @Input()
  public individualChoiceEligibility: IndividualChoiceEligibility;

  @Input()
  public customerLocations: CustomerLocation[];

  @Input()
  public orderValidationError: OrderValidationError | null;

  @Input()
  public showWeWorkToggle: boolean;

  @Input()
  public isWeWorkModeEnabled: boolean;

  @Input()
  public canEnterIndividualChoice: boolean;

  @Input()
  public cartItemAdjustment: CartItemAdjustment;

  @Input()
  public latestOrderHours: number;

  // =========== //
  //   OUTPUTS   //
  // =========== //

  @Output()
  public editDelivery: EventEmitter<void> = new EventEmitter();

  @Output()
  public singleCartItemNameClick: EventEmitter<Item> = new EventEmitter();

  @Output()
  public removeItem: EventEmitter<number> = new EventEmitter();

  @Output()
  public setSingleItemQuantity: EventEmitter<SetSingleItemQuantityDetails> = new EventEmitter();

  @Output()
  public openItemBundleModal: EventEmitter<ItemBundle> = new EventEmitter();

  @Output()
  public setNotes: EventEmitter<string> = new EventEmitter();

  @Output()
  public setCutlery: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public showCartIssues: EventEmitter<void> = new EventEmitter();

  @Output()
  public submitCart: EventEmitter<void> = new EventEmitter();

  @Output()
  public enterIndividualChoice: EventEmitter<void> = new EventEmitter();

  @Output()
  public requestMenuPdf: EventEmitter<void> = new EventEmitter();

  @Output()
  public requestQuotePdf: EventEmitter<void> = new EventEmitter();

  @Output()
  public requestShareCartLink: EventEmitter<void> = new EventEmitter();

  @Output()
  public changeWeWorkMode: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public itemQuantityAdjusted: EventEmitter<CartItemAdjustment> = new EventEmitter();

  // =========== //
  //   MEMBERS   //
  // =========== //

  @ViewChild(CartSubtotalComponent, { static: false })
  public subtotal: CartSubtotalComponent;

  @ViewChild('orderActionsDropdown', { static: false })
  public orderActionsDropdown: ContextDropdownComponent;

  public CartStatuses: CartStatusEnum = CartStatuses;

  private get errorsAndProblems(): { overridable: boolean }[] {
    return [].concat(this.validityErrors || [], this.deliverabilityProblems || []);
  }

  public get showIndividualChoiceToggle(): boolean {
    // should be shown when customer either is not eligible for IC at all, or can enter IC
    return !(this.individualChoiceEligibility && this.individualChoiceEligibility.customerEligible) || this.canEnterIndividualChoice;
  }

  public editDeliveryParams(): void {
    this.editDelivery.emit();
  }

  public get isValid(): boolean {
    return this.errorsAndProblems.filter((error) => !error.overridable).length === 0;
  }

  public hasOverridableProblems(): boolean {
    return this.errorsAndProblems.filter((error) => error.overridable).length > 0;
  }

  public showVendorCapacityError(): boolean {
    return this.orderValidationError && this.orderValidationError.type && this.cart.cartItems.length
      && (this.orderValidationError.type === 'VendorHasEnoughCapacity'
        || this.orderValidationError.type === 'SelectedVendorLocationHasEnoughCapacity');
  }

  public showNextDayOrderingError(): boolean {
    return this.orderValidationError && this.orderValidationError.type && this.orderValidationError.type === 'NextDayOrderDeadline';
  }

  public get cartTotal(): MinorCurrency {
    return majorCurrencyToMinor(this.cart.price.total);
  }

  public showWarning(): boolean {
    return this.cart.cartItems.length && this.isStaffOrSudo && this.hasOverridableProblems();
  }

  public onWarningClicked(): void {
    this.showCartIssues.emit();
  }

  public getMinOrderWarning(): CartValidityError | null {
    return this.validityErrors &&
      this.validityErrors.find((error) => error.type === CartValidityErrorTypes.BELOW_MINIMUM_ORDER_VALUE && !error.overridable) ||
      null;
  }

  public onItemNameClicked(cartItem: CartItem): void {
    if (isSingleCartItem(cartItem)) {
      this.singleCartItemNameClick.emit(cartItem.item);
    }
  }

  public removeCartItem(cartIndex: number): void {
    this.removeItem.emit(cartIndex);
  }

  public trackCartItem(index: number, item: CartItem): string {
    return item && item.item.id;
  }

  public isCartItemInvalid(cartItem: CartItem): boolean {
    const undeliverableItems = (this.deliverabilityProblems || []).find(isUndeliverableItemsProblem) as (UndeliverableItems | null);
    if (!undeliverableItems) {
      return false;
    }
    return !!undeliverableItems.itemDeliverabilities.find((itemDeliverability) => itemDeliverability.itemId === cartItem.item.id);
  }

  public setCartItemQuantity(cartItem: CartItem, quantity: number): void {
    if (isSingleCartItem(cartItem)) {
      this.setSingleItemQuantity.emit({
        item: cartItem.item,
        quantity
      });
    }
  }

  public editBundle(cartItem: CartItem): void {
    if (isBundleCartItem(cartItem)) {
      this.openItemBundleModal.emit(cartItem.item);
    }
  }

  public onCheckoutButtonHover(): void {
    if (this.getMinOrderWarning() && this.subtotal) {
      this.subtotal.highlightMinimumOrder();
    }
  }

  public onNotesUpdated(notes: string): void {
    notes = notes.trim();
    const currentNotes = this.cart.notes || '';
    if (notes !== currentNotes) {
      this.setNotes.emit(notes);
    }
  }

  public onCutleryChanged(includeCutlery: boolean): void {
    if (this.cart.includeCutlery !== includeCutlery) {
      this.setCutlery.emit(includeCutlery);
    }
  }

  public getSubmitButtonText(): string {
    switch (this.status) {
      case CartStatuses.IDLE:
        switch (this.cartType) {
          case CartTypes.EXISTING_CART:
            return 'Save';
          case CartTypes.MEALPLAN_CART:
            return 'Save to Meal Plan';
          case CartTypes.NEW_CART:
          default:
            return 'Order now';
        }
      case CartStatuses.VALIDATING:
        return 'Checking...';
      case CartStatuses.SUBMITTING:
        return 'Submitting...';
      default:
        if (!environment.production) {
          console.warn(`Don't know what button text to show for ${this.status} cart status.`);
        }
        return 'Submitting...';
    }
  }

  public getSubmitButtonTooltip(): string | '' {
    if (this.orderValidationError
      && this.orderValidationError.type === 'CartDeliverability'
      && this.orderValidationError.message === CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE) {
      return `Sorry, ${this.vendor.name} is not accepting orders right now.`;
    }
    return '';
  }

  public onSubmit(): void {
    if (this.isValid) {
      this.submitCart.emit();
    }
  }

  public onRequestShareCartLink(): void {
    this.requestShareCartLink.emit();
    this.orderActionsDropdown.toggle(false);
  }

  public onRequestMenuPdf(): void {
    this.requestMenuPdf.emit();
    this.orderActionsDropdown.toggle(false);
  }

  public onRequestQuotePdf(): void {
    this.requestQuotePdf.emit();
    this.orderActionsDropdown.toggle(false);
  }

  public onEnterIndividualChoice(): void {
    this.enterIndividualChoice.emit();
  }

  public shouldDisableOrderButton(): boolean {
    return !this.isValid || this.status !== CartStatuses.IDLE || !!this.orderValidationError;
  }

  public shouldShowDownloadMenuPdf(): boolean {
    return this.isStaffOrSudo && this.cart.cartItems.length > 0;
  }

  public shouldShowShareCart(): boolean {
    return this.cart.cartItems.length > 0;
  }

  public onChangeWeWorkMode(enabled: boolean): void {
    this.changeWeWorkMode.emit(enabled);
  }

  public getCartItemMessage(itemIndex: number): string | null {
    if (!this.cartItemAdjustment) {
      return null;
    }
    return (itemIndex === this.cartItemAdjustment.itemIndex) ? this.cartItemAdjustment.message : null;
  }

  public onCartItemQuantityAdjustment(cartItemIndex: number, cartItemAdjustmentMessage: string): void {
    this.itemQuantityAdjusted.emit({
      message: cartItemAdjustmentMessage,
      itemIndex: cartItemIndex
    });
  }
}
