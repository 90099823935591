import { Injectable } from '@angular/core';
import { AppState, getPublicState } from '@citypantry/state';
import { getCurrentMenu, getMenuDateChanged } from '@citypantry/state-public';
import { Vendor } from '@citypantry/util-models';
import { select, Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface MenuDateChangedDialogSettings {
  queryDate: Moment;
  newDate: Moment;
  vendor: Vendor;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerMenuQueries {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  public getMenuDateChanged(): Observable<MenuDateChangedDialogSettings | null> {
    const publicState$ = this.store.select(getPublicState);
    const menu$ = publicState$.pipe(select(getCurrentMenu));
    const dateChanged$ = publicState$.pipe(select(getMenuDateChanged));

    return combineLatest([menu$, dateChanged$])
      .pipe(map(([menu, dateChanged]) => {
        if (!menu || !dateChanged) {
          return null;
        }

        return {
          newDate: dateChanged.newDate,
          queryDate: dateChanged.queryDate,
          vendor: menu.vendor,
        };
      },
      )
      );
  }
}
