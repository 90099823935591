import { IsoDate } from '@citypantry/util';
import {
  CartCustomItemOption,
  CartItemBundleGroup,
  CreateDeliveryContactPayload,
  CustomerLocation,
  CustomItem,
  DeliverableMenu,
  EaterCart,
  EaterCartListItem,
  EaterCartSubmitError,
  IndividualChoiceOrder,
  IndividualChoiceOrderGroup,
  IndividualChoiceOrderSummary,
  ItemBundle,
  NewPaymentCard,
  OrderDateAvailability,
  OrderId,
  OrderSchedule,
  PaymentCardId,
  SingleItem,
  UserId
} from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';
import { Moment } from 'moment';

export interface AddOrUpdateItemBundleInCartEvent {
  quantity: number;
  cartGroups: CartItemBundleGroup[];
}
export interface AddOrUpdateCustomItemInCartEvent {
  quantity: number;
  selectedOptions: CartCustomItemOption[];
}
export type AddOrUpdateItemInCartEvent = AddOrUpdateItemBundleInCartEvent | AddOrUpdateCustomItemInCartEvent;

export const IndividualChoiceActions = {

  loadOrderGroup: createAction(
    '[Individual Choice] Load Individual Choice Order Group',
    props<{ orderGroupId: OrderId }>(),
  ),
  reloadOrderGroup: createAction(
    '[Individual Choice] Reload Individual Choice Order Group',
    props<{ orderGroupId: OrderId }>(),
  ),
  orderGroupLoaded: createAction(
    '[Individual Choice] Individual Choice Order Group Loaded',
    props<{ orderGroup: IndividualChoiceOrderGroup }>(),
  ),
  setActiveOrderId: createAction(
    '[Individual Choice] Individual Choice Set Active Order Id',
    props<{ orderId: OrderId }>(),
  ),

  menuLoaded: createAction(
    '[Individual Choice] Menu Loaded',
    props<{ menu: DeliverableMenu }>(),
  ),

  updateIndividualChoiceOrderNotes: createAction(
    '[Individual Choice] Update Individual Choice Order Notes',
    props<{ notes: string }>(),
  ),
  updateIndividualChoiceOrderCutlery: createAction(
    '[Individual Choice] Update Individual Choice Order Cutlery',
    props<{ includeCutlery: boolean }>(),
  ),

  openEditContactPopup: createAction(
    '[Individual Choice] Open Edit Contact Popup',
  ),
  closeEditContactPopup: createAction(
    '[Individual Choice] Close Edit Contact Popup',
  ),
  addDeliveryContact: createAction(
    '[Individual Choice] Add Delivery Contact',
    props<{ deliveryContactPayload: CreateDeliveryContactPayload }>(),
  ),
  updateDeliveryContact: createAction(
    '[Individual Choice] Update Delivery Contact',
    props<{ orderGroupId: OrderId, deliveryContactId: string }>(),
  ),

  updateDeliveryContactSuccess: createAction(
    '[Individual Choice] Update Delivery Contact Success',
  ),
  mandatoryCostCodesToggleClicked: createAction(
    '[Individual Choice] Mandatory Cost Codes Toggle Clicked',
    props<{ mandatoryCostCodesEnabled: boolean }>(),
  ),
  showMandatoryCostCodesDialog: createAction(
    '[Individual Choice] Show Mandatory Cost Codes Dialog',
    props<{ eaterCartsWithoutCostCodes: EaterCartListItem[] }>(),
  ),
  mandatoryCostCodesDialogConfirmClicked: createAction(
    '[Individual Choice] Mandatory Cost Codes Dialog Confirm Button Clicked',
  ),
  mandatoryCostCodesDialogClosed: createAction(
    '[Individual Choice] Mandatory Cost Codes Dialog Closed',
  ),
  updateCartsAdditionalReferenceRequired: createAction(
    '[Individual Choice] Update Carts Additional Reference Required',
    props<{ cartsAdditionalReferenceRequired: boolean }>(),
  ),
  individualChoiceOrderCartNotesUpdated: createAction(
    '[Individual Choice] Individual Choice Order Cart Notes Updated',
    props<{ notes: string }>(),
  ),
  individualChoiceOrderCartCutleryUpdated: createAction(
    '[Individual Choice] Individual Choice Order Cart Cutlery Updated',
    props<{ includeCutlery: boolean }>(),
  ),
  cartsAdditionalReferenceRequiredUpdated: createAction(
    '[Individual Choice] Individual Choice Carts Additional Reference Required Updated',
    props<{ cartsAdditionalReferenceRequired: boolean }>(),
  ),

  markEaterAsAway: createAction(
    '[Individual Choice] Mark Eater As Away',
    props<{ userId: UserId }>(),
  ),
  clearEaterAwayStatus: createAction(
    '[Individual Choice] Clear Eater Away Status',
    props<{ userId: UserId }>(),
  ),

  /**
   * Modal for providing the user with the individual choice order shareable link
   */
  showShareIndividualChoiceModalForNewOrder: createAction(
    '[Individual Choice - Effects] Show share individual choice modal for new order',
  ),
  showShareIndividualChoiceModalForUpdatedOrder: createAction(
    '[Individual Choice - Effects] Show share individual choice modal for updated order',
    props<{ updatedOrderLocation: CustomerLocation }>(),
  ),
  hideShareIndividualChoiceModal: createAction(
    '[Individual Choice] Hide share individual choice modal',
  ),
  copyShareIndividualChoiceOrderUrl: createAction(
    '[Individual Choice] Copy Share Individual Choice Order URL',
  ),
  tickShareIndividualChoiceModalCountdown: createAction(
    '[Individual Choice] Tick share individual choice modal countdown',
    props<{ timeRemaining: number }>(),
  ),
  shareIndividualChoiceModalCopyFailed: createAction(
    '[Individual Choice] Share individual choice modal copy failed',
  ),
  shareIndividualChoiceOrderModalOpened: createAction(
    '[Individual Choice] Share individual choice Modal Opened',
    props<{ shareUrl: string }>(),
  ),
  shareIndividualChoiceLinkCopied: createAction(
    '[Individual Choice] Share individual choice Link Copied',
  ),

  /**
   * Extras
   */
  extraItemsAddSingleItemToCart: createAction(
    '[Individual Choice Extra Items] Extra Items Add Single Item To Cart',
    props<{ item: SingleItem, quantity?: number }>(),
  ),
  removeExtraItem: createAction(
    '[Individual Choice Extra Items] Remove Extra Item',
    props<{ cartIndex: number }>(),
  ),
  extraItemsItemClicked: createAction(
    '[Individual Choice Extra Items] Extra Items Item Clicked',
    props<{ item: CustomItem | ItemBundle }>(),
  ),
  extraItemsItemModalClosed: createAction(
    '[Individual Choice Extra Items] Extra Items Item Modal Closed'
  ),
  extraItemsAddOrUpdateItemInCart: createAction(
    '[Individual Choice Extra Items] Extra Items Add Or Update Item In Cart Action',
    props<{ event: AddOrUpdateItemInCartEvent }>(),
  ),
  extraItemsCartItemEditClicked: createAction(
    '[Individual Choice Extra Items] Extra Items Cart Item Edit Clicked',
    props<{ item: CustomItem | ItemBundle, cartIndex: number }>(),
  ),
  submitExtraItems: createAction(
    '[Individual Choice Extra Items] Submit Extra Items',
  ),
  extraItemsSubmitted: createAction(
    '[Individual Choice Extra Items] Extra Items Submitted',
    props<{ orderGroupId: OrderId, order: IndividualChoiceOrder }>(),
  ),
  extraItemsAgeConfirmationDialogConfirm: createAction(
    '[Individual Choice Extra Items] Extra Items Age Confirmation Dialog Confirm',
  ),
  extraItemsAgeConfirmationDialogCancel: createAction(
    '[Individual Choice Extra Items] Extra Items Age Confirmation Dialog Cancel',
  ),

  /**
   * Choose for eater
   */
  addItemToCart: createAction(
    '[Individual Choice Choose For Eater] Add Item To Cart',
    ({ item, quantity }: { item: SingleItem, quantity?: number }) => ({
      item,
      quantity: quantity || null
    })
  ),
  removeItemFromCart: createAction(
    '[Individual Choice Choose For Eater] Remove Item From Cart',
    props<{ cartIndex: number }>(),
  ),
  updateItemQuantityInCart: createAction(
    '[Individual Choice Choose For Eater] Item Quantity Updated In Cart',
    props<{ cartIndex: number, quantity: number }>(),
  ),
  departmentReferenceChange: createAction(
    '[Choose For Eater Menu Page] Department Reference Change',
    props<{ departmentReference: string }>(),
  ),
  deskNumberChanged: createAction(
    '[Choose For Eater Menu Page] Desk Number Changed',
    props<{ deskNumber: string }>(),
  ),
  submitEaterCart: createAction(
    '[Individual Choice Choose For Eater] Submit Eater Cart',
  ),
  eaterCartSubmitted: createAction(
    '[Individual Choice Choose For Eater] Eater Cart Submitted',
  ),
  eaterCartSubmitFailed: createAction(
    '[Individual Choice Choose For Eater] Eater Cart Submit Failed',
    props<{ error: EaterCartSubmitError }>(),
  ),
  dismissEaterCartError: createAction(
    '[Individual Choice Choose For Eater] Dismiss Eater Cart Error',
  ),

  chooseForEaterMenuItemAddClicked: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Menu Item Add Clicked',
    props<{ item: CustomItem | ItemBundle }>(),
  ),
  chooseForEaterItemUnselected: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Item Unselected',
  ),
  chooseForEaterAddOrUpdateItemInCart: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Item Added Or Updated In Cart',
    props<{ event: AddOrUpdateItemInCartEvent }>(),
  ),
  chooseForEaterCartItemEditClicked: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Cart Item Edit Clicked',
    props<{ item: CustomItem | ItemBundle, cartIndex: number }>(),
  ),

  chooseForEaterAgeConfirmationDialogConfirm: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Cart Age Confirmation Dialog Confirm',
  ),
  chooseForEaterAgeConfirmationDialogCancel: createAction(
    '[Individual Choice Choose For Eater] Choose For Eater Cart Age Confirmation Dialog Cancel',
  ),

  initialiseChooseForEater: createAction(
    '[Individual Choice Choose For Eater] Initialise Choose For Eater',
    props<{ eaterId: UserId, eaterName: string, orderId: OrderId, childOrderId: OrderId }>(),
  ),
  chooseForEaterPageOpened: createAction(
    '[Choose For Eater Page] Choose For Eater page opened',
  ),
  orderSummaryLoaded: createAction(
    '[Order API] Order summary loaded',
    props<{ summary: IndividualChoiceOrderSummary }>(),
  ),
  eaterCartLoaded: createAction(
    '[Individual Choice API] Eater Cart Loaded',
    props<{ eaterCart: EaterCart }>(),
  ),

  orderGroupCancel: createAction(
    '[Individual Choice Order Cart] Order Group Cancel',
  ),
  orderGroupCancelSuccess: createAction(
    '[Order API] Order Group Cancel Success',
    props<{ orderGroup: IndividualChoiceOrderGroup }>(),
  ),
  orderGroupCancelFailure: createAction(
    '[Order API] Order Group Cancel Failure',
  ),

  scheduleOrderDialogConfirmed: createAction(
    '[Who Ordered What Page] Schedule Order Dialog Confirmed',
    props<{ interval: number, endDate: IsoDate, useChoiceOpenTime: boolean }>(),
  ),
  createScheduleSuccess: createAction(
    '[Order Schedule API] Create Schedule Success',
    props<{ schedule: OrderSchedule }>(),
  ),
  createScheduleError: createAction(
    '[Order Schedule API] Create Schedule Error',
  ),

  paymentResolutionGuardPageLoad: createAction(
    '[Payment Resolution Page Guard] Page Load'
  ),

  hidePromotionBanner: createAction(
    '[Promotion] Hide promotion banner'
  ),

  openEditDatePopup: createAction(
    '[Individual Choice] Open Edit Date Popup',
  ),
  closeEditDatePopup: createAction(
    '[Individual Choice] Close Edit Date Popup',
  ),

  findNewVendor: createAction(
    '[Individual Choice] Find new vendor',
    props<{ newDeliveryDate: Moment }>()
  ),

  closeCancelOrderPopup: createAction(
    '[Individual Choice] Close Cancel Order Popup',
  ),

  cancelOrderAndNavigateToSearch: createAction(
    '[Individual Choice] Cancel Order and Navigate to Search',
    props<{ otherReason: string | null }>(),
  ),

  checkDateAvailability: createAction(
    '[Individual Choice] Check Date Availability',
    props<{ deliveryDate: Moment }>(),
  ),
  setSelectedDateAvailability: createAction(
    '[Individual Choice] Set Selected Date Availability',
    props<{ selectedDateAvailability: OrderDateAvailability }>(),
  ),
  updateDeliveryDate: createAction(
    '[Individual Choice] Update Delivery Date',
    props<{ deliveryDate: Moment }>(),
  ),
  updateDeliveryDateSuccess: createAction(
    '[Individual Choice] Update Delivery Date Success',
  ),
  closeDeliveryDetailsUpdatedPopup: createAction(
    '[Individual Choice] Close delivery details updated popup',
  ),
  inviteNewColleaguesButtonClicked: createAction(
    '[Individual Choice - WOW page] Invite New Colleagues button clicked',
  ),

  addCardAndPayForExistingOrder: createAction(
    '[Individual Choice] Add card and pay for existing order',
    props<{ newCard: NewPaymentCard }>(),
  ),

  verifyCardAndPayForExistingOrder: createAction(
    '[Individual Choice] Verify existing card and pay for existing order',
    props<{ cardId: PaymentCardId }>(),
  ),

  payForExistingOrder: createAction(
    '[Individual Choice] Pay for existing order',
    props<{ cardId: PaymentCardId, threeDSecureEnrichedNonce: string }>(),
  ),

  failedToAddOrVerifyPaymentCard: createAction(
    '[Individual Choice] Failed to add or verify payment card',
    props<{ error: Error }>(),
  ),
};

const all = union(IndividualChoiceActions);
export type IndividualChoiceAction = typeof all;
