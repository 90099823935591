import { Injectable } from '@angular/core';
import { DateInEuropeLondonPipe, DeliveryWindowPipe } from '@citypantry/components-datetime';
import { PluralPipe } from '@citypantry/components-text';
import { DeliveryContactApi, EaterCartUpdateResult, IndividualChoiceApi, OrderApi, OrderScheduleApi } from '@citypantry/shared-api';
import { AuthSelectors } from '@citypantry/shared-auth';
import { ExpectedPaymentError } from '@citypantry/shared-braintree';
import { DeliveryContactActions } from '@citypantry/shared-delivery-contact';
import { DeliveryDetailsActions } from '@citypantry/shared-delivery-details';
import { ErrorService } from '@citypantry/shared-error';
import { GlobalDialogService } from '@citypantry/shared-global-dialog';
import { PaymentService } from '@citypantry/shared-payment';
import {
  AppState,
  IndividualChoiceAction,
  IndividualChoiceActions,
  IndividualChoiceChooseForEaterSelectors,
  IndividualChoiceExtrasSelectors,
  IndividualChoiceSelectors,
  IndividualChoiceSetupActions,
  IndividualChoiceShareModal,
  RouterActions
} from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { UnreachableCaseError } from '@citypantry/util';
import { AgeConfirmationEnum, IllustrativeIcons } from '@citypantry/util-enums';
import {
  CartItem,
  constructThreeDSecureParameters,
  IndividualChoiceOrderGroup,
  OrderDateAvailability,
  OrderId,
  OrderScheduleTypes,
  SearchOrderTypes,
  SearchRequest,
  UserId
} from '@citypantry/util-models';
import { ShareableUrlService } from '@citypantry/util-url-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { EMPTY, forkJoin, Observable, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class IndividualChoiceEffects {

  public showShareOrderModalOnCreate$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.showShareIndividualChoiceModalForNewOrder),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    map(([, icOrder]: [Action, IndividualChoiceOrderGroup]) => {
      const path = this.shareableUrlService.getShareableUrl(icOrder.location);

      return IndividualChoiceActions.shareIndividualChoiceOrderModalOpened({ shareUrl: path });
    }),
  ));

  public showShareOrderModalOnUpdate$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.showShareIndividualChoiceModalForUpdatedOrder),
    map((action) => {
      const path = this.shareableUrlService.getShareableUrl(action.updatedOrderLocation);
      return IndividualChoiceActions.shareIndividualChoiceOrderModalOpened({ shareUrl: path });
    }),
  ));

  public copyShareIndividualChoiceOrderURL$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.copyShareIndividualChoiceOrderUrl),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getShareIndividualChoiceOrderModal)),
    map(([, modal]: [Action, IndividualChoiceShareModal]) => {
      try {
        this.clipboardService.copyFromContent(modal.shareUrl);
        return IndividualChoiceActions.shareIndividualChoiceLinkCopied();
      } catch (e) {
        return IndividualChoiceActions.shareIndividualChoiceModalCopyFailed();
      }
    }),
  ));

  public shareIndividualChoiceLinkCopied$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.shareIndividualChoiceLinkCopied),
    map(() => {
      return IndividualChoiceActions.tickShareIndividualChoiceModalCountdown({ timeRemaining: 5 });
    }),
  ));

  public tickShareIndividualChoiceModalCountdown$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.tickShareIndividualChoiceModalCountdown),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getShareIndividualChoiceOrderModal)),
    switchMap(([, modal]: [Action, IndividualChoiceShareModal]) => {
      if (!modal) {
        return EMPTY;
      }

      const timeRemaining = modal.timeRemaining;
      if (timeRemaining <= 0) {
        return of(IndividualChoiceActions.hideShareIndividualChoiceModal());
      }

      return timer(1000).pipe(
        map(() => IndividualChoiceActions.tickShareIndividualChoiceModalCountdown({ timeRemaining: timeRemaining - 1 })));
    }),
  ));

  public loadIndividualChoiceOrderGroup$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.loadOrderGroup,
      IndividualChoiceActions.reloadOrderGroup
    ),
    switchMap((action) =>
      this.orderApi.getIndividualChoiceOrderGroup(action.orderGroupId)
    ),
    map((orderGroup) => IndividualChoiceActions.orderGroupLoaded({ orderGroup })),
  ));

  public updateCartNotes$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.updateIndividualChoiceOrderNotes),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    switchMap(([{ notes }, { id }]) => {
      return this.orderApi.updateOrderNotes(id, notes).pipe(
        map(() => IndividualChoiceActions.individualChoiceOrderCartNotesUpdated({ notes }))
      );
    })
  ));

  public updateCartCutlery$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.updateIndividualChoiceOrderCutlery),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    switchMap(([{ includeCutlery }, { id: orderId }]) => {
      return this.orderApi.updateOrderCutlery(orderId, includeCutlery).pipe(
        map(() => IndividualChoiceActions.individualChoiceOrderCartCutleryUpdated({ includeCutlery }))
      );
    })
  ));

  public addDeliveryContact$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.addDeliveryContact),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
      this.store.select(AuthSelectors.customer.getId),
    ),
    switchMap(([{ deliveryContactPayload }, orderGroup, customerId]) => {
      return this.deliveryContactApi.addDeliveryContact(customerId, deliveryContactPayload).pipe(
        switchMap((deliveryContact) => {
          return [
            DeliveryContactActions.getDeliveryContacts(),
            IndividualChoiceActions.updateDeliveryContact({ orderGroupId: orderGroup.id, deliveryContactId: deliveryContact.id }),
          ];
        }),
      );
    }),
  ));

  public updateDeliveryContact$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.updateDeliveryContact),
    switchMap(({ orderGroupId, deliveryContactId }) => {
      return this.individualChoiceApi.updateDeliveryContact(orderGroupId, deliveryContactId);
    }),
    switchMap((orderGroup) => {
      return [
        IndividualChoiceActions.orderGroupLoaded({ orderGroup }),
        DeliveryDetailsActions.loadTrackingForOrders({ ordersIds: orderGroup.orders.map((childOrder) => childOrder.id) }),
        IndividualChoiceActions.updateDeliveryContactSuccess(),
      ];
    }
    )
  ));

  public updateCartsAdditionalReferenceRequired$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.mandatoryCostCodesDialogConfirmClicked),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    switchMap(([, { id }]) => {
      return this.orderApi.updateCartsAdditionalReferenceRequired(id, true).pipe(
        map(() => IndividualChoiceActions.cartsAdditionalReferenceRequiredUpdated({ cartsAdditionalReferenceRequired: true }))
      );
    })
  ));

  public submitExtrasCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.submitExtraItems,
      IndividualChoiceActions.extraItemsAgeConfirmationDialogConfirm,
    ),
    withLatestFrom(this.store.select(IndividualChoiceExtrasSelectors.isAgeConfirmationDialogVisible)),
    filter(([, isAgeConfirmationDialogVisible]) => !isAgeConfirmationDialogVisible),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getCurrentChildOrderId),
      this.store.select(IndividualChoiceExtrasSelectors.getCartItems),
      this.store.select(IndividualChoiceSelectors.getOrderGroupId),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getDepartmentReference),
    ),
    switchMap(([[action], activeOrderId, cartItems, orderGroupId, departmentReference]:
      [[Action, boolean], OrderId, CartItem[], OrderId, string]
    ) => {
      const hasCustomerConfirmedAgeForExtraItems = (action.type === IndividualChoiceActions.extraItemsAgeConfirmationDialogConfirm.type)
        ? AgeConfirmationEnum.CONFIRMED
        : AgeConfirmationEnum.NOT_CONFIRMED;

      return this.individualChoiceApi.submitExtraItemsCart(
        activeOrderId,
        cartItems,
        departmentReference,
        hasCustomerConfirmedAgeForExtraItems,
      ).pipe(
        switchMap((updatedOrder) =>
          of(
            IndividualChoiceActions.extraItemsSubmitted({ orderGroupId, order: updatedOrder }),
            IndividualChoiceActions.reloadOrderGroup({ orderGroupId }),
          ),
        ),
      );
    }),
  ));

  public returnToWowPage$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.extraItemsSubmitted),
    map(({ orderGroupId }) => RouterActions.go({
      path: ['/menus/customer/orders/eaters/', orderGroupId],
      query: {},
      extras: { fragment: 'additionalItems' }
    })),
  ));

  public submitEaterCart$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.submitEaterCart,
      IndividualChoiceActions.chooseForEaterAgeConfirmationDialogConfirm,
    ),
    withLatestFrom(this.store.select(IndividualChoiceChooseForEaterSelectors.isAgeConfirmationDialogVisible)),
    filter(([, isAgeConfirmationDialogVisible]) => !isAgeConfirmationDialogVisible),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getChildOrderId),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getCartItems),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getEaterId),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getDepartmentReference),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getDeskNumber),
    ),
    switchMap(([[action], , orderId, cartItems, eaterId, departmentReference, deskNumber]:
      [[Action], IndividualChoiceOrderGroup, OrderId, CartItem[], UserId, string, string]
    ) => {
      // an enum is being used to prevent a true boolean being accidentally provided - we want to capture
      // active customer consent, which is only available upon dispatch of the dialog confirm action
      const hasEaterConfirmedAge = (action.type === IndividualChoiceActions.chooseForEaterAgeConfirmationDialogConfirm.type)
        ? AgeConfirmationEnum.CONFIRMED
        : AgeConfirmationEnum.NOT_CONFIRMED;

      return this.individualChoiceApi.createOrUpdateEaterCart(
        orderId,
        cartItems,
        eaterId,
        departmentReference,
        deskNumber,
        hasEaterConfirmedAge,
        null
      ).pipe(
        map((result: EaterCartUpdateResult) => {
          if (result.error) {
            return IndividualChoiceActions.eaterCartSubmitFailed({ error: result.error });
          } else {
            return IndividualChoiceActions.eaterCartSubmitted();
          }
        }),
      );
    })
  ));

  public chooseForEaterPageOpened$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.chooseForEaterPageOpened),
    withLatestFrom(
      this.store.select(IndividualChoiceChooseForEaterSelectors.getEaterIdToChooseFor),
      this.store.select(IndividualChoiceChooseForEaterSelectors.getChildOrderId),
    ),
    switchMap(([, eaterId, childOrderId]) => {
      return forkJoin([
        this.orderApi.getIndividualChoiceOrderSummary(childOrderId),
        this.individualChoiceApi.getEaterCart(eaterId, childOrderId),
      ]);
    }),
    switchMap(([result, eaterCart]) => {
      if (result.success === false) {
        this.errorService.logError(result.error);
        return EMPTY;
      }

      return [
        IndividualChoiceActions.orderSummaryLoaded({ summary: result.value }),
        IndividualChoiceActions.eaterCartLoaded({ eaterCart }),
      ];
    })
  ));

  public cancelOrderGroup$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.orderGroupCancel),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
    ),
    switchMap(([, icOrder]) =>
      this.orderApi.cancelIndividualChoiceOrderGroup(icOrder.id).pipe(
        switchMap((result) => {
          if (result.success === false) {
            result.error.forEach((error) => this.errorService.showErrorMessage(error));
            return of(IndividualChoiceActions.orderGroupCancelFailure());
          }

          return of(IndividualChoiceActions.orderGroupCancelSuccess({ orderGroup: result.value }));
        }),
      ),
    ),
  ));

  public cancelOrderAndNavigateToSearch$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.cancelOrderAndNavigateToSearch),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
      this.store.select(IndividualChoiceSelectors.getNewDeliveryDate),
    ),
    switchMap(([, icOrder, newDeliveryDate]) =>
      this.orderApi.cancelIndividualChoiceOrderGroup(icOrder.id).pipe(
        switchMap((result) => {
          if (result.success === false) {
            result.error.forEach((error) => this.errorService.showErrorMessage(error));
            return of(IndividualChoiceActions.orderGroupCancelFailure());
          }

          const cancelledOrderIds = icOrder.orders.map((order) => order.humanId);
          const searchRequest: SearchRequest = {
            timestamp: moment(),
            location: icOrder.location.id,
            postcode: icOrder.location.postcode,
            date: newDeliveryDate,
          };

          return [
            IndividualChoiceActions.closeCancelOrderPopup(),
            PublicActions.updateSearchType({ searchType: SearchOrderTypes.INDIVIDUAL_CHOICE }),
            IndividualChoiceSetupActions.setSearchRequest({
              searchRequest,
              customerLocation: icOrder.location,
              searchLocation: icOrder.location,
            }),
            PublicActions.openFindNewVendorPopup({ cancelledOrderIds }),
            RouterActions.go({ path: '/menus/search' }),
          ];
        }),
      ),
    ),
  ));

  public createOrderSchedule$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.scheduleOrderDialogConfirmed),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
    ),
    switchMap(([action, orderGroup]) => {
      const { interval, endDate, useChoiceOpenTime } = action;
      return this.orderScheduleApi.createOrderSchedule(orderGroup.id, interval, endDate, useChoiceOpenTime).pipe(
        map((schedule) => {
          return IndividualChoiceActions.createScheduleSuccess({ schedule });
        }),
        catchError((error: any) => {
          this.errorService.showErrorMessage(error);
          return of(IndividualChoiceActions.createScheduleError());
        })
      );
    }),
  ));

  public showConfirmationAndReloadOrderOnScheduleCreated$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.createScheduleSuccess),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
    ),
    tap(([action, orderGroup]) => {
      const schedule = action.schedule;

      switch (schedule.schedule.type) {
        case OrderScheduleTypes.WEEKLY: {
          const dayOfWeek = this.dateInEuropeLondonPipe.transform(schedule.lastScheduledDate, 'dddd');
          const deliveryWindow = this.deliveryWindowPipe.transform(schedule.lastScheduledDate);
          const endDate = this.dateInEuropeLondonPipe.transform(schedule.endDate, 'D MMMM YYYY');

          let innerHtmlContent: string = null;
          if (orderGroup.choiceOpenTime) {
            innerHtmlContent = `
              <div class="card card--inset d-block mt-large text-center py-standard">
                <div class="text-weight-bold mb-small">Order opens to your colleagues:</div>
                <div>
                  ${ moment.duration(schedule.startDate.diff(orderGroup.choiceOpenTime)).humanize() }
                  before delivery day
                  at ${ orderGroup.choiceOpenTime.format('HH:mm') }
                  (${ this.pluralPipe.transform(orderGroup.choiceOpenTime.format('dddd'), 2) })
                </div>
              </div>
            `;
          }

          this.globalDialogService.showDialog({
            title: 'Your order is now scheduled to repeat',
            illustrativeIcon: IllustrativeIcons.TICK,
            textContent: `Every ${dayOfWeek} at ${deliveryWindow} until ${endDate}`,
            innerHtmlContent,
            confirmLabel: 'Close',
            hideCancelButton: true,
            overrideConfirmButtonStyle: 'secondary',
          });
          break;
        }
        default:
          throw new UnreachableCaseError(schedule.schedule.type);
      }

    }),
    map(([, orderGroup]) => IndividualChoiceActions.reloadOrderGroup({ orderGroupId: orderGroup.id })),
  ));

  public loadPaymentDetails$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.paymentResolutionGuardPageLoad,
    ),
    tap(() => {
      this.paymentService.loadPaymentCards();
    }),
  ), { dispatch: false });

  public checkDateAvailability$: Observable<IndividualChoiceAction> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.checkDateAvailability),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    switchMap(([{ deliveryDate }, orderGroup]) => this.individualChoiceApi.validateDeliveryDate(orderGroup.id, deliveryDate)),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    map(([orderGroupValidationResponse, orderGroup]) => {
      const selectedDateAvailability: OrderDateAvailability = {
        isAvailable: orderGroupValidationResponse.validationErrors.length === 0,
        vendorAvailabilities: orderGroupValidationResponse.childOrders.map((orderValidation) =>
          ({
            vendorName: orderGroup.orders.find((order) => orderValidation.id === order.id).vendor.name,
            isAvailable: !(orderValidation.validationErrors && orderValidation.validationErrors.length)
          })
        )
      };
      return IndividualChoiceActions.setSelectedDateAvailability({selectedDateAvailability});
    }),
  ));

  public updateDeliveryDate$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.updateDeliveryDate),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder)),
    switchMap(([{ deliveryDate }, orderGroup]) => this.individualChoiceApi.editDeliveryDate(orderGroup.id, deliveryDate)),
    switchMap((orderGroup) => [
      IndividualChoiceActions.updateDeliveryDateSuccess(),
      IndividualChoiceActions.closeEditDatePopup(),
      IndividualChoiceActions.orderGroupLoaded({ orderGroup }),
      DeliveryDetailsActions.loadTrackingForOrders({ ordersIds: orderGroup.orders.map((childOrder) => childOrder.id) }),
    ])
  ));

  public showShareIndividualChoiceModal$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(IndividualChoiceActions.inviteNewColleaguesButtonClicked),
    withLatestFrom(this.store.select(IndividualChoiceSelectors.getUpdatedOrderLocation)),
    map(([, updatedOrderLocation]) => updatedOrderLocation
      ? IndividualChoiceActions.showShareIndividualChoiceModalForUpdatedOrder({ updatedOrderLocation })
      : IndividualChoiceActions.showShareIndividualChoiceModalForNewOrder()
    )
  ));

  public addCard$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.addCardAndPayForExistingOrder,
    ),
    withLatestFrom(
      this.store.select(AuthSelectors.getUserId),
      this.store.select(AuthSelectors.getUserEmail),
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
      this.store.select(IndividualChoiceSelectors.getOrderGroupTotalCost),
    ),
    switchMap(([{ newCard }, userId, userEmail, { location }, totalCost]) => {
      const parameters = constructThreeDSecureParameters(totalCost, userEmail, location);

      return this.paymentService.addCard(newCard, userId, parameters).pipe(
        map((card) => {
          return IndividualChoiceActions.verifyCardAndPayForExistingOrder({ cardId: card.id });
        }),
        catchError((error) => {
          return of(IndividualChoiceActions.failedToAddOrVerifyPaymentCard({ error }));
        }),
      );
    }),
  ));

  public verifyCard$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.verifyCardAndPayForExistingOrder,
    ),
    withLatestFrom(
      this.store.select(AuthSelectors.getUserEmail),
      this.store.select(IndividualChoiceSelectors.getIndividualChoiceOrder),
      this.store.select(IndividualChoiceSelectors.getOrderGroupTotalCost),
    ),
    switchMap(([{ cardId }, userEmail, { location }, totalCost]) => {
      const parameters = constructThreeDSecureParameters(totalCost, userEmail, location);

      return this.paymentService.verifyCard(cardId, parameters).pipe(
        map((threeDSecureEnrichedNonce) => {
          return IndividualChoiceActions.payForExistingOrder({ cardId, threeDSecureEnrichedNonce });
        }),
        catchError((error) => {
          return of(IndividualChoiceActions.failedToAddOrVerifyPaymentCard({ error }));
        }),
      );
    }),
  ));

  public payForExistingOrder$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.payForExistingOrder,
    ),
    withLatestFrom(
      this.store.select(IndividualChoiceSelectors.getOrderGroupId),
    ),
    tap(([{ cardId, threeDSecureEnrichedNonce }, orderGroupId]) => {
      this.paymentService.payForIndividualChoiceOrder(orderGroupId, cardId, threeDSecureEnrichedNonce);
    }),
  ), { dispatch: false });

  public handlePaymentCardError$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(
      IndividualChoiceActions.failedToAddOrVerifyPaymentCard,
    ),
    tap(({ error }) => {
      if (error instanceof ExpectedPaymentError) {
        this.errorService.showAlert(error.message, error.detailedMessage);
      } else {
        throw error;
      }
    }),
  ), { dispatch: false });

  constructor(
    private action$: Actions<IndividualChoiceAction>,
    private store: Store<AppState>,
    private clipboardService: ClipboardService,
    private individualChoiceApi: IndividualChoiceApi,
    private shareableUrlService: ShareableUrlService,
    private orderApi: OrderApi,
    private orderScheduleApi: OrderScheduleApi,
    private globalDialogService: GlobalDialogService,
    private dateInEuropeLondonPipe: DateInEuropeLondonPipe,
    private deliveryWindowPipe: DeliveryWindowPipe,
    private pluralPipe: PluralPipe,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    private deliveryContactApi: DeliveryContactApi,
  ) {
  }
}
