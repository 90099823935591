import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-header',
  templateUrl: 'simple-header.component.html',
  styles: [':host { display: block }'],
})
export class SimpleHeaderComponent {
  @Input()
  public heading: string;

  @Input()
  public subheading: string;

  @Input()
  public showBackButton: boolean;

  @Output()
  public backButton: EventEmitter<void> = new EventEmitter();

  public onBackArrowClick(): void {
    this.backButton.emit();
  }
}
