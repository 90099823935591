import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingComponentsModule } from '@citypantry/components-loading';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomerQualificationFormComponent } from './customer-qualification-form/customer-qualification-form.component';
import { CustomerQualificationModalContentComponent } from './customer-qualification-modal/customer-qualification-modal-content.component';
import { CustomerQualificationModalComponent } from './customer-qualification-modal/customer-qualification-modal.component';
import { CustomerQualificationEffects } from './customer-qualification.effects';
import { FEATURE_STATE_NAME, reducer } from './customer-qualification.state';
import { FrequencyQualificationTypePipe } from './pipes/frequency-qualification-type.pipe';
import { HeadCountQualificationTypePipe } from './pipes/head-count-qualification-type.pipe';
import { SourceQualificationTypePipe } from './pipes/source-qualification-type.pipe';
import { UseCaseQualificationTypePipe } from './pipes/use-case-qualification-type.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AnalyticsComponentsModule,
    LoadingComponentsModule,
    EffectsModule.forFeature([
      CustomerQualificationEffects,
    ]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
    ModalComponentsModule,
  ],
  declarations: [
    CustomerQualificationModalComponent,
    CustomerQualificationModalContentComponent,
    CustomerQualificationFormComponent,

    HeadCountQualificationTypePipe,
    FrequencyQualificationTypePipe,
    SourceQualificationTypePipe,
    UseCaseQualificationTypePipe,
  ],
  providers: [
    CustomerQualificationEffects
  ],
  exports: [
    CustomerQualificationModalComponent,
    CustomerQualificationFormComponent,
  ],
})
export class CustomerQualificationModule {}
