<div
  class="d-flex justify-content-center"
  [formGroup]="form"
  analyticsId="date-parameters-form"
>
  <div class="search-parameters-popup__input">
    <div class="form-group">
      <label
        for="parameters-popup-date"
        class="form-group__label"
      >Date</label>

      <span class="input-icon">
        <app-date-picker cpDatePicker
          id="parameters-popup-date"
          formControlName="date"
          [minDay]="minDay"
          (blur)="onBlur()"
          analyticsId="date"
        ></app-date-picker>
        <i class="icon icon-calendar input-icon__icon"></i>
        <div
          *ngIf="form.hasError('required', ['date'])"
          class="form-error form-error--center form-error--floating"
        >
          Please select a date.
        </div>
        <div
          *ngIf="form.hasError('date', ['date'])"
          class="form-error form-error--center form-error--floating"
        >
          Please enter a valid date.
        </div>
      </span>
    </div>
  </div>
  <div class="search-parameters-popup__input">
    <div class="form-group">
      <label
        for="parameters-popup-time"
        class="form-group__label"
      >Time</label>
      <span class="input-icon">
        <select
          class="form-input form-input--no-dropdown-style"
          id="parameters-popup-time"
          formControlName="time"
          (blur)="onBlur()"
          analyticsId="time"
          test-id="timeInput"
        >
          <option
            *ngFor="let time of deliveryTimes"
            [ngValue]="time"
            test-id="timeInputOption"
          >{{ time | deliveryWindow }}</option>
        </select>
        <i class="input-icon__icon icon icon-clock"></i>
        <div
          *ngIf="form.get(['time'])?.errors"
          [errorSwitch]="form.get(['time'])?.errors"
          class="form-error form-error--floating"
          test-id="timeInputErrors"
        >
          <ng-container *errorCase="'required'">Please select a time.</ng-container>
          <ng-container *errorCase="'timeInListAndDateOnWeekend'">The selected time is not available on weekends. Please choose a different time slot.</ng-container>
          <ng-container *errorCase="'valueInList'">Please select a time.</ng-container>
        </div>
      </span>
    </div>
  </div>
</div>
