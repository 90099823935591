import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { BetaFeature } from './models/beta-feature.enum';

@Injectable({
  providedIn: 'root'
})
export class BetaFeatureApi {

  constructor(
    private http: HttpClient
  ) {}

  public disableBetaFeature(feature: BetaFeature): Observable<BetaFeature> {
    return this.http.delete(`/users/me/beta-features/${feature}`).pipe(
      mapTo(feature)
    );
  }
}
