export type PendingCartChangeReason = 'bad_date' | 'bad_menu' | 'missing_data' | 'manual_open';

export class PendingCartChangeReasons {
  public static BAD_DATE: PendingCartChangeReason = 'bad_date';
  public static BAD_MENU: PendingCartChangeReason = 'bad_menu'; // TODO CPD-2321 use this
  public static MISSING_DATA: PendingCartChangeReason = 'missing_data';
  public static MANUAL_OPEN: PendingCartChangeReason = 'manual_open';

  public static get values(): PendingCartChangeReason[] {
    return [
      PendingCartChangeReasons.BAD_DATE,
      PendingCartChangeReasons.BAD_MENU,
      PendingCartChangeReasons.MISSING_DATA,
      PendingCartChangeReasons.MANUAL_OPEN
    ];
  }
}

export type PendingCartChangeReasonEnum = typeof PendingCartChangeReasons;
