import { UserId } from '../../user-id.model';

export interface Eater {
  email: string;
  firstName: string;
  fullName: string;
  humanId: number;
  id: UserId;
  lastName: string;
}

export function createEaterFromJson(json?: Partial<Eater>): Eater {
  json = json || {};
  const eater: Partial<Eater> = {};

  eater.email = json.email;
  eater.firstName = json.firstName;
  eater.fullName = json.fullName;
  eater.humanId = json.humanId;
  eater.id = json.id;
  eater.lastName = json.lastName;

  return eater as Eater;
}
