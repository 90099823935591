import { Pipe, PipeTransform } from '@angular/core';
import { addDays, addHours, addSeconds, distanceInWordsToNow } from 'date-fns';

/**
 * We have to use date-fns despite is less friendly API due to a couple of related issues:
 * 1. Moment is not 'chunkable' and results in excessive included code
 *      (https://github.com/angular/angular-cli/issues/2496#issuecomment-274272747)
 * 2. ng2-moment causes issues in the AOT compilation
 *      (see https://github.com/urish/angular2-moment/issues/114)
 * 3. Using moment().add() causes a typescript compilation error
 */
@Pipe({
  name: 'dateDuration'
})
export class DateDurationPipe implements PipeTransform {

  public transform(value: number, ...args: string[]): string {
    if (!value) {
      return '';
    }
    // Default unit of time is seconds
    const unitOfTime = args[0] || 'seconds';
    // If given an argument, use it to define the period
    // e.g. {{ 48 | dateDuration:'hours' }} == 2 days
    let futureDate;
    switch (unitOfTime) {
      case 'hours':
        futureDate = addHours(new Date(), value);
        break;
      case 'days':
        futureDate = addDays(new Date(), value);
        break;
      case 'seconds':
      default:
        futureDate = addSeconds(new Date(), value);
    }

    return distanceInWordsToNow(futureDate, {
      includeSeconds: false,
      addSuffix: false
    });
  }

}
