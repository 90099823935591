<div
class="search-parameters-popup__inputs"
[formGroup]="form"
analyticsId="parameters-form"
>
  <div class="search-parameters-popup__input col search-parameters-popup__input--expand">
    <label class="form-group">
      <span class="form-group__label" test-id="postcodeLabel">
        {{ getPostcodeLabel() }}
      </span>
      <app-editable-dropdown
        *ngIf="canEnterPostcode && locationOptions && locationOptions.length"
        formControlName="postcodeOrLocation"
        icon="location"
        [options]="locationOptions"
        placeholder="Enter Postcode"
        manualLabel="Enter new postcode..."
        initialLabel="Please select"
        (editing)="isEnteringPostcode = $event"
        (blur)="onBlur()"

        analyticsId="postcode"
        test-id="editableLocationsInput"
        e2e-test-id="mkpLocationInput"
      ></app-editable-dropdown>
      <select
        *ngIf="!canEnterPostcode && locationOptions && locationOptions.length"
        class="form-input"
        formControlName="postcodeOrLocation"
        test-id="locationsInput"
      >
        <option disabled value="">Please select</option>
        <option
          *ngFor="let option of locationOptions"
          [ngValue]="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <span
        class="input-icon"
        *ngIf="!locationOptions || !locationOptions.length"
      >
        <input
          #postcodeInput
          class="form-input"
          type="text"
          [placeholder]="address ? 'Search address or postcode' : 'e.g. WC1V 1AA'"
          formControlName="postcodeOrLocation"
          (enter)="postcodeInput.blur()"
          (blur)="onBlur()"
          autocomplete="force-off"
          analyticsId="postcode"
          test-id="postcodeInput"
          e2e-test-id="postcodeInput"
        />
        <i class="input-icon__icon icon icon-location"></i>
      </span>
      <div
        *ngIf="form.get('postcode').touched &&
         (form.hasError('required', ['postcode']) || form.hasError('postcode', ['postcode']))"
        class="form-error form-error--center form-error--floating"
      >
        {{ address ? "Please enter a valid address or postcode." : "Please enter a valid postcode." }}
      </div>
    </label>
  </div>
  <div class="search-parameters-popup__input">
    <div class="form-group">
      <label
        for="parameters-popup-date"
        class="form-group__label"
      >Date</label>
      <span
        class="input-icon"
      >
        <app-date-picker cpDatePicker
          id="parameters-popup-date"
          formControlName="date"
          (blur)="onBlur()"

          analyticsId="date"
          e2e-test-id="parametersFormDatepicker"
          test-id="dateInput"
        ></app-date-picker>
        <i class="icon icon-calendar input-icon__icon"></i>
        <div
          *ngIf="form.hasError('required', ['date'])"
          class="form-error form-error--center form-error--floating"
        >
          Please select a date.
        </div>
        <div
          *ngIf="form.hasError('date', ['date'])"
          class="form-error form-error--center form-error--floating"
        >
          Please enter a valid date.
        </div>
      </span>
    </div>
  </div>
  <div class="search-parameters-popup__input">
    <div class="form-group">
      <label
        for="parameters-popup-time"
        class="form-group__label"
      >Time</label>
      <span class="input-icon">
        <select
          class="form-input form-input--no-dropdown-style"
          id="parameters-popup-time"
          formControlName="time"
          (blur)="onBlur()"

          analyticsId="time"
          test-id="timeInput"
        >
          <option
            *ngFor="let time of deliveryTimes"
            [ngValue]="time"
            test-id="timeInputOption"
          >{{ time | deliveryWindow }}</option>
        </select>
        <i class="input-icon__icon icon icon-clock"></i>
        <div
          *ngIf="form.get(['time'])?.errors"
          [errorSwitch]="form.get(['time'])?.errors"
          class="form-error form-error--floating"
          test-id="timeInputErrors"
        >
          <ng-container *errorCase="'required'">Please select a time.</ng-container>
          <ng-container *errorCase="'timeInListAndDateOnWeekend'">The selected time is not available on weekends. Please choose a different time slot.</ng-container>
          <ng-container *errorCase="'valueInList'">Please select a time.</ng-container>
        </div>
      </span>
    </div>
  </div>
</div>
