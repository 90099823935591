import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { transformPendingOrdersQuery } from '@citypantry/shared-api';
import { objectToParams } from '@citypantry/util';
import {
  Page
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendorOrdersNotificationsApi {

  constructor(
    private http: HttpClient
  ) {}

  public getPendingOrdersCount(): Observable<number> {
    return this.http.get<Page<any>>( // We use <any> here because the response has pageSize: 0 so there will be no data.
      '/vendors/me/orders',
      {params: objectToParams(transformPendingOrdersQuery({pageSize: 0}))} // Using zero page size speeds up the request as only the total is needed
    ).pipe(
      map((result) => result.total)
    );
  }
}
