/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type OrderScheduleType = 'weekly';

export class OrderScheduleTypes {
  public static WEEKLY: OrderScheduleType & 'weekly' = 'weekly';

  public static get values(): OrderScheduleType[] {
    return [
      OrderScheduleTypes.WEEKLY,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type OrderScheduleTypeEnum = typeof OrderScheduleTypes;
