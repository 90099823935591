<div
  *ngIf="promotion"
  class="search-promo-card"
  [class.search-promo-card--two-lines]="promotion.isTwoLines"
  analyticsId="search-promo-card"
  [analyticsData]="{ promotion: promotion }"
  test-id="promotionCard"
>
  <button
    class="search-promo-card__close-cross-container"
    (click)="closeClicked()"
    analyticsId="close"
    test-id="closeCross"
    aria-label="Hide this card"
  >
    <i class="icon search-promo-card__close-cross" aria-hidden="true">close</i>
  </button>
  <div class="search-promo-card__content">
    <i [ngClass]="'illustrative-icon illustrative-icon--' + promotion.icon"></i>
    <div class="search-promo-card__title-wrapper">
      <h3
        class="search-promo-card__title"
        [class.search-promo-card--wrap-title]="promotion.wrapTitle"
        test-id="promotionTitle"
      >
        {{ promotion.title }}
      </h3>
    </div>
    <div
      class="search-promo-card__subtitle"
      test-id="promotionSubtitle"
    >
      {{ promotion.subtitle }}
    </div>
    <a
      [attr.href]="promotion.link || null"
      (click)="buttonClicked()"
      [target]="promotion.openLinkInNewTab ? '_blank' : '_parent'"
      class="search-promo-card__link button button--primary"
      analyticsId="button"
      test-id="promotionLink"
    >
      {{ promotion.buttonText }}
    </a>
  </div>
</div>
