import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { CustomerQualificationState, FEATURE_STATE_NAME } from './customer-qualification.state';

const getCustomerQualificationState = createFeatureSelector<CustomerQualificationState>(FEATURE_STATE_NAME);

const isModalOpen = (state: CustomerQualificationState): boolean => state.isModalOpen;
const isFormCompleted = (state: CustomerQualificationState): boolean =>  state.formCompleted;
const isSubmitting = (state: CustomerQualificationState): boolean => state.isSubmitting;

export const QualificationSelectors = mapSelectors(getCustomerQualificationState, {
  isModalOpen,
  isFormCompleted,
  isSubmitting
});
