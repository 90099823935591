import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as sentryInstance from '@sentry/browser';
import { ErrorHandler } from './error-handler.service';
import { ErrorReporterService } from './error-reporter.service';
import { ErrorComponent } from './error.component';
import { ErrorEffects } from './error.effects';
import { FEATURE_STATE_NAME, reducer } from './error.state';
import { ExpectedErrorTranslator } from './expected-errors.model';
import { SENTRY } from './sentry';

@NgModule({
  imports: [
    CommonModule,
    ModalComponentsModule,
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
    EffectsModule.forFeature([ErrorEffects])
  ],
  declarations: [
    ErrorComponent,
  ],
  providers: [
    ErrorReporterService,
    ExpectedErrorTranslator,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandler,
      multi: true
    },
    { provide: SENTRY, useValue: sentryInstance }
  ],
  exports: [
    ErrorComponent
  ]
})
export class ErrorModule {
  constructor(
    errorReporterService: ErrorReporterService
  ) {
    errorReporterService.initialiseSentry();
  }
}
