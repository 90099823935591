import { emptyToNull, filterNullValuesFromParams, sum, unique } from '@citypantry/util';
import {
  AdvancedBudgets,
  CartStatus,
  CartStatuses,
  ChoiceDeadline,
  ChoiceDeadlines,
  CuisineType,
  CustomerId,
  CustomerLocation,
  DeliveryContact,
  DietaryType,
  EventType,
  IndividualChoiceOrderGroup,
  IndividualChoicePaymentDetails,
  ItemId,
  LoadableList,
  majorCurrencyToMinor,
  MenuContent,
  MinorCurrency,
  minorCurrencyToMajor,
  OrderValidationError,
  SearchLocation,
  SearchPreferences,
  SearchRequestIndividualChoice,
  SearchResult,
  SearchSortType,
  SelectedVendor,
  SelectedVendorLocation,
  Vendor,
  VendorFlag,
  VendorLocationId,
  VendorLocationSlug,
} from '@citypantry/util-models';
import moment from 'moment';
import { getMenuSectionsWithAdditionalItemData } from '../util/compute-visible-menu-items';
import { IndividualChoiceSetupAction, IndividualChoiceSetupActions } from './individual-choice-setup.actions';

export const DEFAULT_HEADCOUNT = 25;
export const DEFAULT_BUDGET: MinorCurrency = 1500;
export const DEFAULT_CHOICE_DEADLINE = ChoiceDeadlines.TWO_DAYS;

export interface CurrentMenu {
  vendor: Vendor;
  content: MenuContent;
  minDistance: number | null;
  serverHiddenSectionIndices: number[];
  vendorLocation: SelectedVendorLocation;
}

export interface IndividualChoiceSetupCostSummary {
  totalCost: MinorCurrency;
  deliveryCost: MinorCurrency;
  deliveryVat: MinorCurrency;
  serviceFeeNet: MinorCurrency;
  serviceFeeVat: MinorCurrency;
}

export interface DeliveryDetailsUpdate {
  deliveryInstructions: string;
  deliveryContact: DeliveryContact;
  customerLocation: CustomerLocation;
}

export interface CustomerLocationUpdate {
  location: CustomerLocation;
}

export interface IndividualChoiceSetupState {
  // Requirements - if any of these properties change we will have to clear the selected vendors.
  deliveryDate: moment.Moment | null;
  searchLocation: SearchLocation | null;
  customerLocation: CustomerLocation | null;
  headcount: number;
  budget: MinorCurrency;
  isSubsidisedChoiceTurnedOn: boolean;
  isColleagueGroupsToggledOn: boolean;
  choiceDeadline: ChoiceDeadline;
  validChoiceDeadlines: ChoiceDeadline[];

  // These properties can be changed freely without affecting the selected vendors.
  filters: {
    cuisines: CuisineType[];
    events: EventType[];
    dietaries: DietaryType[];
    vendorFlags: VendorFlag[];
    query: string | '';
  };
  sortBy: SearchSortType | null;

  isSearchParamsDialogOpen: boolean; // Dialog for editing date + postcode

  // Results
  searchRequestDate: moment.Moment;
  isSearching: boolean;
  searchResults: SearchResult[];
  resultCount: number;
  currentPage: number;

  searchRegion: string;

  // Menu Page
  currentMenu: CurrentMenu | null;
  availableItemIds: LoadableList<ItemId>;
  selectedItemIds: ItemId[] | null;
  inBudgetItemIds: ItemId[];

  // Cart
  selectedVendors: SelectedVendor[];
  createOrderStatus: CartStatus;
  // Cart validation
  orderValidationError: OrderValidationError | null;
  // The cost used for the IC cart - is null when there is no vendor and we are guessing the cost based on
  // headcount and budget, and has a value when a vendor is selected and we have validated delivery/service fees
  costSummary: IndividualChoiceSetupCostSummary | null;

  // Payment Details (e.g. PO number etc)
  paymentDetails: IndividualChoicePaymentDetails | null;
  paymentFormLoading: boolean;

  // Preferences
  preferencesCustomerId: CustomerId;

  // Advanced Budgeting
  isAdvancedBudgetingEnabled: boolean;
  advancedBudgets: AdvancedBudgets | null;

  // Location page
  isLocationFormSubmitting: boolean;
  isNewLocationFormSubmitting: boolean;

  // Edit location checkout page
  editLocation: {
    customerLocation: CustomerLocation;
  };
}

export const initialSearchState: Partial<IndividualChoiceSetupState> = {
  isSearching: false,
  searchResults: [],
  resultCount: 0,
  currentPage: 0,
};

export const initialState: IndividualChoiceSetupState = {
  deliveryDate: null,
  customerLocation: null,
  searchRegion: null,
  headcount: DEFAULT_HEADCOUNT,
  budget: DEFAULT_BUDGET,
  isSubsidisedChoiceTurnedOn: false,
  isColleagueGroupsToggledOn: true,
  choiceDeadline: DEFAULT_CHOICE_DEADLINE,
  validChoiceDeadlines: ChoiceDeadlines.twoDays,

  filters: {
    cuisines: [],
    events: [],
    dietaries: [],
    vendorFlags: [],
    query: '',
  },
  sortBy: null,

  isSearchParamsDialogOpen: false,

  searchRequestDate: null,
  ...initialSearchState,

  currentMenu: null,
  availableItemIds: {
    items: [],
    isLoading: false,
  },
  selectedItemIds: [],
  inBudgetItemIds: [],

  selectedVendors: [],
  createOrderStatus: CartStatuses.IDLE,
  orderValidationError: null,

  // the only cost-related fields currently used/displayed in IC Cart.
  costSummary: null,

  paymentDetails: null,
  paymentFormLoading: false,

  preferencesCustomerId: null,
  isLocationFormSubmitting: false,
  isNewLocationFormSubmitting: false,

  editLocation: {
    customerLocation: null
  },
} as IndividualChoiceSetupState;

export function reducer(
  oldState: IndividualChoiceSetupState = initialState,
  action: IndividualChoiceSetupAction,
): IndividualChoiceSetupState {
  return individualChoiceSetupReducer(oldState, action);
}

function getStateWithAdditionalItemAndSectionData(
  state: IndividualChoiceSetupState,
  content: MenuContent,
  selectedItemIds: ItemId[],
  availableItemIds: ItemId[],
  budget: MinorCurrency,
  vendor: Vendor,
  vendorLocation: SelectedVendorLocation,
  minDistance: number | null,
  serverHiddenSectionIndices: number[],
  isSubsidisedChoiceEnabled: boolean
): IndividualChoiceSetupState {

  const { enhancedSections, updatedSelectedItemIds } = getMenuSectionsWithAdditionalItemData(
    content, selectedItemIds, availableItemIds, budget, serverHiddenSectionIndices, isSubsidisedChoiceEnabled
  );

  return {
    ...state,
    availableItemIds: { items: availableItemIds, isLoading: false },
    selectedItemIds: updatedSelectedItemIds,
    currentMenu: {
      vendor,
      vendorLocation,
      minDistance,
      content: {
        ...content,
        sections: enhancedSections,
      },
      serverHiddenSectionIndices,
    },
  };
}

function individualChoiceSetupReducer(
  state: IndividualChoiceSetupState = initialState,
  action: IndividualChoiceSetupAction,
): IndividualChoiceSetupState {
  switch (action.type) {
    case IndividualChoiceSetupActions.setSearchRequest.type: {
      const { searchRequest, customerLocation, searchLocation } = action;
      return updateStateWithSearchRequest(state, searchRequest, customerLocation, searchLocation);
    }

    case IndividualChoiceSetupActions.updateSearchParameters.type: {
      const { timestamp, date, customerLocation, searchLocation } = action;
      const currentRequest =  getIcSetupSearchRequest(state);
      const searchRequest = {
        ...currentRequest,
        timestamp,
        date,
      };

      return updateStateWithSearchRequest(state, searchRequest, customerLocation, searchLocation);
    }

    case IndividualChoiceSetupActions.updateSearchFilters.type: {
      const { cuisines, dietaries, events, vendorFlags } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          cuisines,
          dietaries,
          events,
          vendorFlags,
        }
      };
    }

    case IndividualChoiceSetupActions.updateSortOrder.type: {
      const sortOrder = action.sortOrder;

      if (sortOrder !== state.sortBy) {
        return {
          ...state,
          sortBy: sortOrder
        };
      } else {
        return state;
      }
    }

    case IndividualChoiceSetupActions.updateSearchQuery.type: {
      const query = action.query;

      return {
        ...state,
        filters: {
          ...state.filters,
          query,
        }
      };
    }

    case IndividualChoiceSetupActions.resetSearch.type: {
      return {
        ...state,
        ...initialSearchState,
      };
    }

    case IndividualChoiceSetupActions.removeSearchRequest.type: {
      return {
        ...state,
        searchLocation: null,
        customerLocation: null,
      };
    }

    case IndividualChoiceSetupActions.doSearch.type: {
      return {
        ...state,
        isSearching: true,
      };
    }

    case IndividualChoiceSetupActions.searchResultsLoaded.type: {
      const results = action.results;
      return {
        ...state,
        isSearching: false,
        searchResults: state.searchResults.concat(results.items),
        currentPage: results.page,
        resultCount: results.total,
      };
    }

    case IndividualChoiceSetupActions.showSearchParametersDialog.type: {
      return {
        ...state,
        isSearchParamsDialogOpen: true
      };
    }

    case IndividualChoiceSetupActions.closeSearchParametersDialog.type: {
      return {
        ...state,
        isSearchParamsDialogOpen: false
      };
    }

    case IndividualChoiceSetupActions.menuFetched.type: {
      const { vendor, content, minDistance, vendorLocation } = action.deliverableMenu;
      const isSubsidisedChoiceEnabled = action.isSubsidisedChoiceEnabled;

      const serverHiddenSectionIndices = content.sections
        .map((section, index) => ({ index, section }))
        .filter(({ section }) => section.hidden)
        .map(({ index }) => index);

      const selectedVendor = state.selectedVendors.find((selected) =>
        selected.vendorId === vendor.id
        && selected.vendorLocationId === vendorLocation.id
      );
      const selectedItemIds = selectedVendor ? selectedVendor.selectedItemIds : null;

      // Can't guarantee if Menu is fetched first or available item Ids is fetched first so we need to do this in both instances
      if (!state.availableItemIds.isLoading && !!state.selectedItemIds) {
        return getStateWithAdditionalItemAndSectionData(
          state,
          content,
          (selectedItemIds || state.selectedItemIds), // already set by AVAILABLE_ITEM_IDS_FETCHED
          state.availableItemIds.items,
          state.budget,
          vendor,
          vendorLocation,
          minDistance,
          serverHiddenSectionIndices,
          isSubsidisedChoiceEnabled
        );
      }

      return {
        ...state,
        currentMenu: {
          vendor,
          vendorLocation,
          content,
          minDistance,
          serverHiddenSectionIndices,
        },
        selectedItemIds,
      };
    }

    case IndividualChoiceSetupActions.addVendorToCart.type: {
      if (state.currentMenu && !state.availableItemIds.isLoading) {
        const vendor = state.currentMenu.vendor;
        const {
          id: vendorLocationId,
          slug: vendorLocationSlug,
          name: vendorLocationName,
          remainingCapacity: vendorLocationRemainingCapacity,
        } = state.currentMenu.vendorLocation;
        const contentId = state.currentMenu.content.id;
        const availableItemIds = state.availableItemIds.items;
        const newlySelectedVendor = createSelectedVendor(
          vendor,
          contentId,
          availableItemIds,
          state.selectedItemIds,
          vendorLocationId,
          vendorLocationSlug,
          vendorLocationName,
          vendorLocationRemainingCapacity,
        );

        const selectedVendors = state.selectedVendors;
        if (!selectedVendors.find((selectedVendor) =>
          selectedVendor.vendorId === newlySelectedVendor.vendorId
          && selectedVendor.vendorLocationId === newlySelectedVendor.vendorLocationId
        )) {
          return {
            ...state,
            selectedVendors: [...state.selectedVendors, newlySelectedVendor]
          };
        }
      }

      return state;
    }

    case IndividualChoiceSetupActions.removeVendorFromCart.type: {
      const vendorId = action.vendorId;
      const selectedVendors = state.selectedVendors.filter((vendor) =>
        vendor.vendorId !== vendorId
        || vendor.vendorLocationId !== action.vendorLocationId
      );

      return {
        ...state,
        selectedVendors
      };
    }

    case IndividualChoiceSetupActions.hiddenBudgetVendorAddedToCart.type: {
      return {
        ...state,
        isSubsidisedChoiceTurnedOn: false
      };
    }

    case IndividualChoiceSetupActions.createIndividualChoiceOrderGroup.type: {
      return {
        ...state,
        createOrderStatus: CartStatuses.SUBMITTING
      };
    }

    case IndividualChoiceSetupActions.individualChoiceOrderGroupCreated.type: {
      return {
        ...state,
        createOrderStatus: CartStatuses.IDLE,
        selectedVendors: [],
      };
    }

    case IndividualChoiceSetupActions.clearSelectedVendors.type: {
      return {
        ...state,
        selectedVendors: [],
      };
    }

    case IndividualChoiceSetupActions.updateBudget.type: {
      const { budget } = action;

      return {
        ...state,
        budget,
        selectedVendors: [],
      };
    }

    case IndividualChoiceSetupActions.updateSubsidisedChoice.type: {
      const { turnedOn } = action;

      return {
        ...state,
        isSubsidisedChoiceTurnedOn: turnedOn
      };
    }

    case IndividualChoiceSetupActions.updateColleagueGroups.type: {
      const { toggledOn } = action;

      return {
        ...state,
        isColleagueGroupsToggledOn: toggledOn
      };
    }

    case IndividualChoiceSetupActions.updateHeadcount.type: {
      const { headcount } = action;

      return {
        ...state,
        headcount,
        selectedVendors: [],
      };
    }

    case IndividualChoiceSetupActions.updateChoiceDeadline.type: {
      const { choiceDeadline } = action;

      return {
        ...state,
        choiceDeadline,
        selectedVendors: [],
      };
    }

    case IndividualChoiceSetupActions.fetchAvailableItemIds.type: {
      return {
        ...state,
        availableItemIds: {
          items: [],
          isLoading: true,
        },
      };
    }

    case IndividualChoiceSetupActions.fetchMenu.type: {
      return {
        ...state,
        currentMenu: null,
        selectedItemIds: null,
        availableItemIds: {
          ...state.availableItemIds,
          items: []
        }
      };
    }

    case IndividualChoiceSetupActions.availableItemIdsFetched.type: {
      const { itemIds, isSubsidisedChoiceEnabled } = action;

      let selectedItemIds: ItemId[] = [];
      if (!state.selectedItemIds) {
        selectedItemIds = [...itemIds] as ItemId[];
      } else {
        const availableItems = [...itemIds];
        selectedItemIds = state.selectedItemIds.filter((itemId) => availableItems.includes(itemId));
      }

      const availableItemIds = {
        items: itemIds,
        isLoading: false,
      };

      if (state.currentMenu && state.currentMenu.content) {
        // Can't guarantee if Menu is fetched first or available item Ids is fetched first so we need to do this in both instances
        return getStateWithAdditionalItemAndSectionData(
          state,
          state.currentMenu.content,
          selectedItemIds,
          availableItemIds.items,
          state.budget,
          state.currentMenu.vendor,
          state.currentMenu.vendorLocation,
          state.currentMenu.minDistance,
          state.currentMenu.serverHiddenSectionIndices,
          isSubsidisedChoiceEnabled
        );
      }

      return {
        ...state,
        availableItemIds,
        selectedItemIds,
      };
    }

    case IndividualChoiceSetupActions.toggleSelectedItems.type: {
      const { items, isSubsidisedChoiceEnabled } = action;

      const filteredItemIds = items.filter((itemId) =>
        // check if the item - to be found in one of the sections - is selectable
        state.currentMenu.content.sections.find((section) => {
          const enhancedItem = section.items.find((item) => item.id === itemId);
          return enhancedItem && enhancedItem.selectable;
        })
      );

      let areAllSelected = true;
      for (const itemId of filteredItemIds) {
        if (!state.selectedItemIds.includes(itemId)) {
          areAllSelected = false;
          break;
        }
      }

      let updatedSelectedItemIds = state.selectedItemIds ? [...state.selectedItemIds] : [];

      if (areAllSelected) {
        filteredItemIds.forEach((itemId) => {
          updatedSelectedItemIds = updatedSelectedItemIds.filter((uItemId) => itemId !== uItemId);
        });
      } else {
        filteredItemIds.forEach((itemId) => {
          updatedSelectedItemIds = updatedSelectedItemIds.concat(itemId).filter(unique);
        });
      }

      const updatedSelectedVendors = state.selectedVendors.map((selectedVendor) => {
        if (selectedVendor.vendorId === state.currentMenu.vendor.id
          && selectedVendor.vendorLocationId === state.currentMenu.vendorLocation.id
        ) {
          return {
            ...selectedVendor,
            selectedItemIds: updatedSelectedItemIds,
          };
        }
        return { ...selectedVendor };
      });

      const content = state.currentMenu.content;

      return {
        ...getStateWithAdditionalItemAndSectionData(
          state,
          content,
          updatedSelectedItemIds,
          state.availableItemIds.items,
          state.budget,
          state.currentMenu.vendor,
          state.currentMenu.vendorLocation,
          state.currentMenu.minDistance,
          state.currentMenu.serverHiddenSectionIndices,
          isSubsidisedChoiceEnabled
        ),
        selectedVendors: updatedSelectedVendors,
      };
    }

    case IndividualChoiceSetupActions.validateIndividualChoiceOrderGroup.type: {
      return {
        ...state,
        createOrderStatus: CartStatuses.VALIDATING,
        orderValidationError: null
      };
    }

    case IndividualChoiceSetupActions.individualChoiceOrderGroupValidated.type: {
      const { order } = action;

      const calculatedCostSummary = calculateCost(order, state.headcount);

      return {
        ...state,
        createOrderStatus: CartStatuses.IDLE,
        orderValidationError: null,
        costSummary: calculatedCostSummary
      };
    }

    case IndividualChoiceSetupActions.individualChoiceOrderGroupValidationFailed.type: {
      const { error } = action;

      return {
        ...state,
        createOrderStatus: CartStatuses.IDLE,
        orderValidationError: error
      };
    }

    case IndividualChoiceSetupActions.updateSearchPreferences.type: {
      const {
        searchPreferences,
        isAdvancedBudgetingEnabled,
        advancedBudgets,
        customerId
      } = action;

      return updateStateWithSearchPreferences(state, searchPreferences, isAdvancedBudgetingEnabled, advancedBudgets, customerId);
    }

    case IndividualChoiceSetupActions.newCardPaymentDetailsSubmitted.type: {
      const { paymentDetails } = action;

      return {
        ...state,
        paymentDetails
      };
    }

    case IndividualChoiceSetupActions.paymentFormLoading.type: {
      return {
        ...state,
        paymentFormLoading: true
      };
    }

    case IndividualChoiceSetupActions.paymentDetailsGuardPageLoad.type:
    case IndividualChoiceSetupActions.paymentFormFinished.type:
    case IndividualChoiceSetupActions.failedToAddOrVerifyPaymentCard.type: {
      return {
        ...state,
        paymentFormLoading: false
      };
    }

    case IndividualChoiceSetupActions.operationalRegionLoadSuccess.type: {
      const { operationalRegion } = action;
      return  {
        ...state,
        searchRegion: operationalRegion,
      };
    }

    case IndividualChoiceSetupActions.operationalRegionLoadFailure.type: {
      return  {
        ...state,
        searchRegion: null,
      };
    }

    case IndividualChoiceSetupActions.deliveryDetailsUpdated.type: {
      const { customerLocation } = action;

      return {
        ...state,
        customerLocation
      };
    }

    case IndividualChoiceSetupActions.deliveryLocationUpdated.type: {
      const { customerLocation } = action;
      return {
        ...state,
        customerLocation,
        searchLocation: customerLocation
      };
    }

    case IndividualChoiceSetupActions.newDeliveryLocationSubmitted.type: {
      return {
        ...state,
        isNewLocationFormSubmitting: true
      };
    }

    case IndividualChoiceSetupActions.saveDeliveryLocation.type: {
      return {
        ...state,
        isLocationFormSubmitting: true
      };
    }

    case IndividualChoiceSetupActions.deliveryLocationFormFinished.type: {
      return {
        ...state,
        isLocationFormSubmitting: false,
        isNewLocationFormSubmitting: false,
      };
    }

    case IndividualChoiceSetupActions.editCustomerPageLocationView.type: {
      const { customerLocation } = action;
      return {
        ...state,
        editLocation: {
          customerLocation
        }
      };
    }

    default:
      return state;
  }
}

function computeValidChoiceDeadlines(requestDate: moment.Moment, deliveryDate: moment.Moment): ChoiceDeadline[] {
  if (!requestDate || !deliveryDate) {
    return [];
  }

  if (deliveryDate.isBefore(requestDate.clone().add(3, 'hours'))) {
    return ChoiceDeadlines.shortNotice;
  } else if (deliveryDate.isBefore(requestDate.clone().endOf('day'))) {
    return ChoiceDeadlines.sameDay;
  } else if (deliveryDate.isBefore(requestDate.clone().add(1, 'day').endOf('day'))) {
    return ChoiceDeadlines.oneDay;
  }
  return ChoiceDeadlines.twoDays;
}

function computeValidChoiceDeadlineOrDefault(
  choiceDeadline: ChoiceDeadline | null,
  currentDeadline: ChoiceDeadline | null,
  availableChoiceDeadlines: ChoiceDeadline[]
): ChoiceDeadline {
  if (availableChoiceDeadlines.length === 0) {
    return DEFAULT_CHOICE_DEADLINE;
  }

  if (choiceDeadline && availableChoiceDeadlines.includes(choiceDeadline)) {
    return choiceDeadline;
  }

  if (currentDeadline && availableChoiceDeadlines.includes(currentDeadline)) {
    return currentDeadline;
  }

  // otherwise return least restrictive option.
  return availableChoiceDeadlines[availableChoiceDeadlines.length - 1];
}

/**
 * This function updates all parameters in the state from the given search request.
 */
function updateStateWithSearchRequest(
  state: IndividualChoiceSetupState,
  searchRequest: SearchRequestIndividualChoice,
  customerLocation: CustomerLocation | null,
  searchLocation: SearchLocation | null
): IndividualChoiceSetupState {
  const oldRequest = getIcSetupSearchRequest(state);
  const filters = state.filters;
  const validChoiceDeadlines = computeValidChoiceDeadlines(searchRequest.timestamp, searchRequest.date);
  const newState = {
    ...state,
    searchLocation: searchLocation ? searchLocation : customerLocation,
    customerLocation,
    deliveryDate: searchRequest.date,
    budget: searchRequest.maxBudget ? majorCurrencyToMinor(searchRequest.maxBudget) : state.budget,
    headcount: searchRequest.headcount || state.headcount,
    validChoiceDeadlines,
    choiceDeadline: computeValidChoiceDeadlineOrDefault(searchRequest.choiceDeadline, state.choiceDeadline, validChoiceDeadlines),
    filters: {
      ...filters,
      cuisines: searchRequest.cuisines || [],
      events: searchRequest.events || [],
      dietaries: searchRequest.dietaries || [],
      vendorFlags: searchRequest.options || [],
      query: searchRequest.text || '',
    },
    sortBy: searchRequest.sortBy || null,
  };
  const newRequest = getIcSetupSearchRequest(newState);

  // We want to ensure that if the user changes their search request, they reset their selected vendors.
  // So we generate the previous and the current search request, and compare.
  // If they are not equal, we need to reset vendors. Since we now have no vendors, we are now guessing
  // the estimated cost - therefore it is set to null and the guess calculated in the selector.
  if (
    oldRequest.postcode === newRequest.postcode &&
    oldRequest.date && oldRequest.date.isSame(newRequest.date, 'minute') &&
    oldRequest.maxBudget === newRequest.maxBudget &&
    oldRequest.headcount === newRequest.headcount &&
    oldRequest.choiceDeadline === newRequest.choiceDeadline
  ) {
    return newState;
  } else {
    return {
      ...newState,
      selectedVendors: [],
      costSummary: null
    };
  }
}

export function calculateCost(orderGroup: IndividualChoiceOrderGroup, headcount: number): IndividualChoiceSetupCostSummary {
  // note: totalBudget is currently not based on backend data from /validate,
  // so it's not saved in store, but returned in selector instead

  const deliveryCost = orderGroup.orders.map((order) => order.costBreakdown.deliveryCost.net).reduce(sum(majorCurrencyToMinor), 0);

  const deliveryVat = orderGroup.orders.map((order) => order.costBreakdown.deliveryCost.vat).reduce(sum(majorCurrencyToMinor), 0);

  const serviceFeeNet = orderGroup.orders.map((order) => order.costBreakdown.serviceFeeCost.net).reduce(sum(majorCurrencyToMinor), 0);

  const serviceFeeVat = orderGroup.orders.map((order) => order.costBreakdown.serviceFeeCost.vat).reduce(sum(majorCurrencyToMinor), 0);

  const totalBudget = calculateTotalBudget(headcount, majorCurrencyToMinor(orderGroup.budget));

  const totalCost = totalBudget + deliveryCost + deliveryVat + serviceFeeNet + serviceFeeVat;

  return {
    totalCost,
    deliveryCost,
    deliveryVat,
    serviceFeeNet,
    serviceFeeVat
  };
}

export function calculateTotalBudget(headcount: number, budget: MinorCurrency): MinorCurrency {
  return headcount * budget;
}

function updateStateWithSearchPreferences(
  state: IndividualChoiceSetupState,
  searchPreferences: SearchPreferences | null,
  isAdvancedBudgetingEnabled: boolean,
  advancedBudgets: AdvancedBudgets | null,
  preferencesCustomerId: CustomerId | null
): IndividualChoiceSetupState {
  const newState = {
    ...state,
    isAdvancedBudgetingEnabled,
    advancedBudgets,
  };

  if (preferencesCustomerId === state.preferencesCustomerId) {
    return newState;
  } else if (!searchPreferences) {
    return {
      ...newState,
      headcount: DEFAULT_HEADCOUNT,
      budget: DEFAULT_BUDGET,
      choiceDeadline: DEFAULT_CHOICE_DEADLINE,
      isSubsidisedChoiceTurnedOn: false,
      preferencesCustomerId
    };
  } else {
    const headcount = searchPreferences.expectedHeadcount ? searchPreferences.expectedHeadcount : DEFAULT_HEADCOUNT;
    const budget = searchPreferences.budget ? majorCurrencyToMinor(searchPreferences.budget) : DEFAULT_BUDGET;
    const choiceDeadline = searchPreferences.choiceDeadline ? searchPreferences.choiceDeadline : DEFAULT_CHOICE_DEADLINE;
    const subsidisedChoiceTurnedOn = searchPreferences.isSubsidisedChoiceTurnedOn;

    const vendorFlags = [ ...state.filters.vendorFlags ];

    return {
      ...newState,
      headcount,
      budget,
      choiceDeadline,
      filters: {
        ...newState.filters,
        vendorFlags
      },
      costSummary: null,
      isSubsidisedChoiceTurnedOn: subsidisedChoiceTurnedOn,
      preferencesCustomerId
    };
  }
}

function createSelectedVendor(
  vendor: Vendor,
  contentId: string,
  availableItemIds: ItemId[],
  selectedItemIds: ItemId[],
  vendorLocationId: VendorLocationId,
  vendorLocationSlug: VendorLocationSlug,
  vendorLocationName: string | null,
  vendorLocationRemainingCapacity: number,
): SelectedVendor {
  return {
    vendorId: vendor.id,
    vendorName: vendor.name,
    vendorImage: vendor.images[0],
    vendorSlug: vendor.slug,
    contentId,
    availableItemIds,
    selectedItemIds,
    vendorLocationId,
    vendorLocationSlug,
    vendorLocationName,
    vendorLocationRemainingCapacity,
  };
}

export function deserialiseIndividualChoiceSetupState(state: Partial<IndividualChoiceSetupState>): IndividualChoiceSetupState {
  if (!state) {
    return initialState;
  }

  try {
    return {
      ...state,

      deliveryDate: state.deliveryDate && moment(state.deliveryDate).isAfter() ? moment.tz(state.deliveryDate, 'Europe/London') : null,
      isSearchParamsDialogOpen: false,
      createOrderStatus: CartStatuses.IDLE,

      currentMenu: initialState.currentMenu,
      availableItemIds: initialState.availableItemIds,
      editLocation: state.editLocation ? state.editLocation : initialState.editLocation,

      // As vendorLocationRemainingCapacity was added as a non-nullable property of SelectedVendor, we must ensure that any previously
      // serialised objects contain this property, or else they should not be deserialised (and removed from localStorage).
      selectedVendors: state.selectedVendors
        ? state.selectedVendors.filter((selectedVendor) => selectedVendor.vendorLocationRemainingCapacity !== undefined)
        : initialState.selectedVendors,

      ...initialSearchState,

    } as IndividualChoiceSetupState;
  } catch (e) {
    console.error(e);
    return initialState;
  }
}

export const getIcSetupBudget = (state: IndividualChoiceSetupState): MinorCurrency => {
  if (state.deliveryDate && state.isAdvancedBudgetingEnabled && state.advancedBudgets && state.advancedBudgets.length) {
    // this works under assumption that budget ranges in advanced budget are appropriately named and ordered.
    // to be changed when custom budget ranges are introduced.
    const dayOfWeek = state.deliveryDate.isoWeekday() - 1;
    return state.advancedBudgets[dayOfWeek].budget;
  } else {
    return state.budget;
  }
};

export const getIcSetupSearchRequest = (state: IndividualChoiceSetupState): SearchRequestIndividualChoice => {
  const request: SearchRequestIndividualChoice = {
    timestamp: state.searchRequestDate ? state.searchRequestDate : null,
    postcode: state.customerLocation && state.customerLocation.postcode
      || state.searchLocation && state.searchLocation.postcode || null,
    date: state.deliveryDate,
    headcount: state.headcount,
    choiceDeadline: state.choiceDeadline,
    maxBudget: minorCurrencyToMajor(getIcSetupBudget(state)),
    cuisines: emptyToNull(state.filters.cuisines),
    events: emptyToNull(state.filters.events),
    dietaries: emptyToNull(state.filters.dietaries),
    options: emptyToNull(state.filters.vendorFlags),
    sortBy: state.sortBy,
    text: state.filters.query || null,
    subsidisedChoice: state.isSubsidisedChoiceTurnedOn,
    location: state.searchLocation ? state.searchLocation.id : null
  };

  return filterNullValuesFromParams(request);
};
