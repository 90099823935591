export interface ColleagueNotificationPreferencesConfig {
  isPrimaryMember: boolean;
  isColleagueNotificationPreferenceOverrideEnabled: boolean;
}

export function createColleagueNotificationPreferencesConfigFromJson(json: Partial<ColleagueNotificationPreferencesConfig>):
  ColleagueNotificationPreferencesConfig {
  return {
    isPrimaryMember: json.isPrimaryMember,
    isColleagueNotificationPreferenceOverrideEnabled: json.isColleagueNotificationPreferenceOverrideEnabled,
  };
}
