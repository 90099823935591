import { OrderId } from '../order/order-id.model';
import { createSimpleVendorFromJson, SimpleVendor, VendorLocationCapacityStatus } from '../vendor';
import { createSimpleVendorLocationFromJson, SimpleVendorLocation } from '../vendor/simple-vendor-location.model';

export interface IndividualChoiceOrderWithRemainingCapacity {
  id: OrderId;
  vendor: SimpleVendor;
  selectedVendorLocation: SimpleVendorLocation;
  expectedHeadcount: number;
  remainingCapacity: number;
  capacityStatus: VendorLocationCapacityStatus;
}

export function createIndividualChoiceOrderWithRemainingCapacityFromJson(
  json?: Partial<IndividualChoiceOrderWithRemainingCapacity>
): IndividualChoiceOrderWithRemainingCapacity {
  json = json || {};

  const order: Partial<IndividualChoiceOrderWithRemainingCapacity> = {};

  order.id = json.id;
  order.vendor = createSimpleVendorFromJson(json.vendor);
  order.selectedVendorLocation =
    json.selectedVendorLocation ? createSimpleVendorLocationFromJson(json.selectedVendorLocation) : null;
  order.expectedHeadcount = json.expectedHeadcount;
  order.remainingCapacity = json.remainingCapacity;
  order.capacityStatus = json.capacityStatus;

  return order as IndividualChoiceOrderWithRemainingCapacity;
}
