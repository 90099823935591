import { Params } from '@angular/router';
import {
  csvToTrimmedStrings,
  ensureValidEnumValue,
  ensureValidEnumValues,
  filterAllowed,
  filterNullValuesFromParams,
  isDateOnWeekend,
  isValidEnumValue,
} from '@citypantry/util';
import moment from 'moment';
import { CustomerLocation, CustomerLocationId } from '../customer';
import { ChoiceDeadline, ChoiceDeadlines } from '../individual-choice';
import { CuisineType, CuisineTypes, EventType, EventTypes } from '../menu/items';
import { convertUserInputToMajorCurrency, MajorCurrency } from '../money';
import { VendorFlag, VendorFlags } from '../vendor';
import { DietaryType, DietaryTypes } from './dietary-type.enum';
import { SearchSortType, SearchSortTypes } from './search-sort-type.enum';
import Moment = moment.Moment;

// @TODO CPD-12031 move these into AppConfig as environment variables
export const WEEKDAY_LATEST_ORDER_HOURS = 21;
export const WEEKDAY_START_DELIVERY_TIME_MINUTES = 8 * 60; // start window of 07:30 - 08:00 in minutes from midnight
export const WEEKDAY_END_DELIVERY_TIME_MINUTES = 21 * 60; // end window of 20:30 - 21:00 in minutes from midnight
export const WEEKEND_LATEST_ORDER_HOURS = 15;
export const WEEKEND_START_DELIVERY_TIME_MINUTES = 8.5 * 60; // start window of 08:00 - 08:30 in minutes from midnight
export const WEEKEND_END_DELIVERY_TIME_MINUTES = 15 * 60; // end window of 14:30 - 15:00 in minutes from midnight
export const DELIVERY_TIME_INCREMENT_MINUTES = 15;

export const VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES: number[] = Object.freeze(
  generateDeliveryTimesInMinutes(WEEKDAY_START_DELIVERY_TIME_MINUTES, WEEKDAY_END_DELIVERY_TIME_MINUTES, DELIVERY_TIME_INCREMENT_MINUTES)
) as number[];
export const VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES: number[] = Object.freeze(
  generateDeliveryTimesInMinutes(WEEKEND_START_DELIVERY_TIME_MINUTES, WEEKEND_END_DELIVERY_TIME_MINUTES, DELIVERY_TIME_INCREMENT_MINUTES)
) as number[];

export const INDIVIDUAL_CHOICE_SEARCH_REQUEST_PARAMS: (keyof SearchRequestIndividualChoice)[] =
  ['choiceDeadline', 'sameDay', 'subsidisedChoice'];

export interface SearchRequest {
  timestamp?: moment.Moment; // Only the default request should be able to be null
  postcode?: string;
  location?: CustomerLocationId;
  date?: Moment;
  headcount?: number;
  maxBudget?: MajorCurrency;
  cuisines?: CuisineType[];
  events?: EventType[];
  dietaries?: DietaryType[];
  options?: VendorFlag[];
  sortBy?: SearchSortType;
  text?: string;
  favourites?: boolean;
}

// Params only applied in IC search
export interface SearchRequestIndividualChoice extends SearchRequest {
  choiceDeadline?: ChoiceDeadline;
  sameDay?: boolean;
  subsidisedChoice?: boolean;
  additionalVendor?: boolean;
}

export function createSearchRequestFromJson(json: Partial<SearchRequest>): SearchRequest {
  const searchRequest: Partial<SearchRequest> = {};

  if (json.timestamp) {
    searchRequest.timestamp = moment(json.timestamp);
  }

  if (json.postcode) {
    searchRequest.postcode = json.postcode;
  }

  if (json.date) {
    searchRequest.date = moment(json.date);
  }

  if (json.headcount) {
    searchRequest.headcount = json.headcount;
  }

  if (json.maxBudget) {
    searchRequest.maxBudget = json.maxBudget;
  }

  if (json.cuisines) {
    searchRequest.cuisines = ensureValidEnumValues(CuisineTypes, json.cuisines);
  }

  if (json.events) {
    searchRequest.events = ensureValidEnumValues(EventTypes, json.events);
  }

  if (json.dietaries) {
    searchRequest.dietaries = ensureValidEnumValues(DietaryTypes, json.dietaries);
  }

  if (json.options) {
    searchRequest.options = ensureValidEnumValues(VendorFlags, json.options);
  }

  if (json.sortBy) {
    searchRequest.sortBy = ensureValidEnumValue(SearchSortTypes, json.sortBy);
  }

  if (json.text) {
    searchRequest.text = json.text;
  }

  if (json.favourites) {
    searchRequest.favourites = !!json.favourites;
  }

  return searchRequest as SearchRequest;
}

export function createSearchRequestFromURLQuery(query: Params): SearchRequest {
  const request: SearchRequest = {
    timestamp: moment.tz('Europe/London'),
    date: getDefaultDeliveryDate()
  };

  if (query.location) {
    request.location = query.location;
  }

  if (query.postcode && query.postcode.trim()) {
    request.postcode = query.postcode;
  }

  if (query.date) {
    const queryDate = moment.tz(query.date, moment.ISO_8601, 'Europe/London');
    if (queryDate.isValid() && moment.tz('Europe/London').isBefore(queryDate)) {
      request.date = normalizeTime(queryDate);
    }
  }
  if (!request.date || query.useDefaultDeliveryDate) {
    request.date = getDefaultDeliveryDate();
  }

  if (query.headcount) {
    request.headcount = parseInt(query.headcount, 10);
  }

  if (query.maxBudget) {
    request.maxBudget = convertUserInputToMajorCurrency(query.maxBudget);
  }

  if (query.cuisines) {
    const cuisineTypes = typeof query.cuisines === 'string' ? csvToTrimmedStrings(query.cuisines) : query.cuisines;
    request.cuisines = filterAllowed(cuisineTypes as CuisineType[], CuisineTypes.values);
  }

  if (query.events) {
    const eventTypes = typeof query.events === 'string' ? csvToTrimmedStrings(query.events) : query.events;
    request.events = filterAllowed(eventTypes as EventType[], EventTypes.values);
  }

  if (query.dietaries) {
    const dietaryTypes = typeof query.dietaries === 'string' ? csvToTrimmedStrings(query.dietaries) : query.dietaries;

    request.dietaries = filterAllowed(dietaryTypes as DietaryType[], DietaryTypes.values);
  }

  if (query.options) {
    const options = typeof query.options === 'string' ? csvToTrimmedStrings(query.options) : query.options;

    request.options = filterAllowed(options as VendorFlag[], VendorFlags.values);
  }

  if (query.q) {
    request.text = query.q;
  }

  if (query.terms) {
    // Backwards-compatibility with old links
    let listOfTerms = [];
    if (request.text) {
      listOfTerms.push(request.text);
    }
    listOfTerms = listOfTerms.concat(csvToTrimmedStrings(query.terms)
      .map((term: string) => term.match(/\s/) ? `"${term}"` : term));

    request.text = listOfTerms.join(' ');
  }

  if (query.sortBy) {
    request.sortBy = query.sortBy;
  }

  if (query.favourites) {
    request.favourites = true;
  }

  return request;
}

export function createSearchRequestIndividualChoiceFromUrlQuery(query: Params): SearchRequestIndividualChoice {
  const request: SearchRequestIndividualChoice = createSearchRequestFromURLQuery(query);
  if (query.choiceDeadline && isValidEnumValue(ChoiceDeadlines, query.choiceDeadline)) {
    request.choiceDeadline = query.choiceDeadline;
  }

  if (query.sameDay && !query.date) {
    request.sameDay = query.sameDay;

    const now = moment.tz('Europe/London');
    const latestOrderHours = moment.tz(
      `${isDateOnWeekend(now) ? WEEKEND_LATEST_ORDER_HOURS : WEEKDAY_LATEST_ORDER_HOURS}:00:00`,
      'HH:mm:ss',
      'Europe/London'
    );

    // Same day search targets now plus 3.5 hours to maximise vendor options
    const targetSearchDate = now.clone().add(3.5, 'hours');

    if (targetSearchDate.isValid() && now.isBefore(latestOrderHours) && targetSearchDate.isBefore(latestOrderHours)) {
      request.date = normalizeTime(targetSearchDate);
    } else {
      const fallBackDate = now.clone()
        .add(1, 'day')
        .set({'hour': 13, 'minute': 0, 'second': 0});

      request.date = normalizeTime(fallBackDate);
    }
  }

  if (query.subsidisedChoice) {
    request.subsidisedChoice = true;
  }

  return request;
}

export function createURLQueryFromSearchRequest(request: SearchRequest, customerLocation: CustomerLocation | null = null): Params {
  const arrayToCsv = (array: string[]) => array && array.length ? array.join(',') : null;

  const postcode = request.postcode
    ? request.postcode.trim()
    : (customerLocation ? customerLocation.postcode.trim() : null);

  const date = request.date ?
    request.date.format('YYYY-MM-DDTHH:mm') : // Date format is ISO8601 without seconds and timezone, which is still valid ISO8601
    null;

  return filterNullValuesFromParams({
    location: customerLocation ? customerLocation.id : null,
    postcode,
    date,
    headcount: request.headcount || null,
    maxBudget: request.maxBudget || null,
    cuisines: arrayToCsv(request.cuisines),
    events: arrayToCsv(request.events),
    dietaries: arrayToCsv(request.dietaries),
    options: arrayToCsv(request.options),
    q: request.text && request.text.trim() || null,
    sortBy: request.sortBy,
    favourites: request.favourites || null
  });
}

export function createUrlQueryFromSearchRequestIndividualChoice(
  request: SearchRequestIndividualChoice,
  customerLocation: CustomerLocation | null = null
): Params {
  const params: Params = createURLQueryFromSearchRequest(request, customerLocation);
  return filterNullValuesFromParams({
    ...params,
    choiceDeadline: request.choiceDeadline || null,
    sameDay: request.sameDay || null,
    subsidisedChoice: request.subsidisedChoice || null
  });
}

function normalizeTime(date: Moment): Moment {
  const dateClone = date.clone();
  const minutes = dateClone.diff(dateClone.clone().startOf('day'), 'minutes');
  const minutesRounded = Math.floor(minutes / DELIVERY_TIME_INCREMENT_MINUTES) * DELIVERY_TIME_INCREMENT_MINUTES;

  const startDeliveryTimeMinutes = isDateOnWeekend(dateClone) ? WEEKEND_START_DELIVERY_TIME_MINUTES : WEEKDAY_START_DELIVERY_TIME_MINUTES;
  const endDeliveryTimeMinutes = isDateOnWeekend(dateClone) ? WEEKEND_END_DELIVERY_TIME_MINUTES : WEEKDAY_END_DELIVERY_TIME_MINUTES;

  // reset date to midnight before setting its time it to the normalized value
  dateClone.startOf('day');

  if (minutesRounded < startDeliveryTimeMinutes) {
    dateClone.minutes(startDeliveryTimeMinutes);
  } else if (minutesRounded > endDeliveryTimeMinutes) {
    dateClone.minutes(endDeliveryTimeMinutes);
  } else {
    dateClone.minutes(minutesRounded);
  }

  return dateClone;
}

export function getDefaultDeliveryDate(): Moment {
  const now = moment.tz('Europe/London');

  // default offset is 2 days (see additional conditions below)
  let daysOffset = 2;

  // offset by 3 days if the time it's past the cutoff time
  if (now.hour() >= WEEKDAY_LATEST_ORDER_HOURS) {
    daysOffset = 3;
  }

  // offset by 4 days (to Monday) if the current day is Thursday
  if (now.weekday() === 4) {
    daysOffset = 4;
  }

  // offset by 3 days (to Monday) if the current day is Friday and it's before the cutoff time, but by 4 days
  // (to Tuesday) if it's Friday past the cutoff time
  if (now.weekday() === 5) {
    if (now.hours() >= WEEKDAY_LATEST_ORDER_HOURS) {
      daysOffset = 4;
    } else {
      daysOffset = 3;
    }
  }

  // offset by 3 days (to Tuesday) if the current day is Saturday
  if (now.weekday() === 6) {
    daysOffset = 3;
  }

  // offset by 2 days (to Tuesday) if the current day is Sunday
  if (moment().weekday() === 0) {
    daysOffset = 2;
  }

  return now.clone().startOf('day').add(daysOffset, 'day').hour(13).minute(0);
}

export function generateDeliveryTimesInMinutes(start: number, end: number, increment: number): number[] {
  const times = [];
  for (let time = start; time <= end; time += increment) {
    times.push(time);
  }

  return times;
}

export function splitDeliveryDateTime(datetime: moment.Moment | null): { date: string | null, time: number | null } {
  if (!datetime) {
    return { date: null, time: null };
  }

  // We need to ensure we are in London otherwise we can get the wrong day/time
  const inLondonTime = moment.tz(datetime, 'Europe/London');
  // Work out the time (in minutes since midnight) by hour variables. This is because when a timezone change occurs during the day, the
  // moment diffing algoritmh takes into account this, whereas we do not.
  // EG. March 25th 2018, clocks go forward 1 hour at 1am, so a date diff between 00:00 and 10:00 is 9 hours, as there is 1 less hour in the
  // night. Here, we get a 10 hour difference.
  const date = inLondonTime.format('YYYY-MM-DD');
  const time = (inLondonTime.hours() * 60) + datetime.minutes();
  return { date, time };
}

export function combineDeliveryDateTime(date: string, minutesSinceMidnight: number): moment.Moment {
  // We need to construct the moment object with all the data, rather than setting the minutes with ".minutes()". This is because daylight
  // savings days have 2 timeszones (clocks move at 1am), so the midnight timezone is different to the timezone at 10am.
  const hours = Math.floor(minutesSinceMidnight / 60);
  const mins = minutesSinceMidnight - (hours * 60);
  return moment.tz(`${date} ${hours}:${mins}`, 'YYYY-MM-DD HH:m', 'Europe/London');
}

export function requestsMatch(a: SearchRequest, b: SearchRequest): boolean {
  // We can't use deepEqual() because moment instances don't compare correctly
  return JSON.stringify(a) === JSON.stringify(b);
}
export namespace requestsMatch {
  export const ignoringTimestamp = (a: SearchRequest, b: SearchRequest): boolean =>
    requestsMatch({...a, timestamp: null}, {...b, timestamp: null});
}
