import { boolObjectToStrings } from '@citypantry/util';

export type Allergen = keyof Allergens;

/**
 * List of allergen names used for displaying. Does not include "none" or "not provided" properties.
 */
export const REAL_ALLERGENS: Allergen[] = [
  'gluten',
  'crustaceans',
  'eggs',
  'fish',
  'peanuts',
  'soybeans',
  'milk',
  'nuts',
  'celery',
  'mustard',
  'sesame',
  'sulphurDioxide',
  'lupin',
  'molluscs',
];

/**
 * List of allergen names used for form building. Includes the "none" and "not provided" properties.
 */
export const ALL_ALLERGENS: Allergen[] = REAL_ALLERGENS.concat(['none', 'notProvided']);

export interface Allergens {
  gluten?: boolean;
  crustaceans?: boolean;
  eggs?: boolean;
  fish?: boolean;
  peanuts?: boolean;
  soybeans?: boolean;
  milk?: boolean;
  nuts?: boolean;
  celery?: boolean;
  mustard?: boolean;
  sesame?: boolean;
  sulphurDioxide?: boolean;
  lupin?: boolean;
  molluscs?: boolean;
  none?: boolean;
  notProvided?: boolean;
}

export const EMPTY_ALLERGENS: Allergens = {
  gluten: false,
  crustaceans: false,
  eggs: false,
  fish: false,
  peanuts: false,
  soybeans: false,
  milk: false,
  nuts: false,
  celery: false,
  mustard: false,
  sesame: false,
  sulphurDioxide: false,
  lupin: false,
  molluscs: false,
  none: false,
  notProvided: false,
};

/**
 * Constructs the allergens object from JSON. To be used when mapping the result of an HTTP call.
 */
export function createAllergensFromJson(json?: Partial<Allergens>): Allergens {
  if (!json) {
    json = {};
  }
  const allergens: Allergens = { ...EMPTY_ALLERGENS };
  Object.keys(allergens).forEach((allergen: Allergen) => {
    if (json[allergen]) {
      allergens[allergen] = true;
    }
  });

  return allergens;
}

/**
 * Calculates the number of allergens set, including "none".
 * @param allergens An Allergens object.
 * @returns {number} The number of allergen values set to true.
 */
export function numberOfAllergensSet(allergens: Allergens): number {
  if (!allergens) {
    return 0;
  }
  return ALL_ALLERGENS
    .map((allergen: Allergen) => allergens[allergen])
    .reduce((count: number, isPresent: boolean) => count + (isPresent ? 1 : 0), 0);
}

/**
 * Returns a aggregated set of Allergen[] strings from a set of Allergens[] objects.
 * If any of the Allergens objects do not have allergens provided we return notProvided
 *
 * @param {Allergens[]} allergens
 * @return {Allergen[]}
 */
export function aggregateAllergens(allergens: Allergens[]): Allergen[] {
  const allergensUnion = allergens.reduce((result: Allergens, current: Allergens) => ({
    gluten:         current.gluten         || result.gluten,
    crustaceans:    current.crustaceans    || result.crustaceans,
    eggs:           current.eggs           || result.eggs,
    fish:           current.fish           || result.fish,
    peanuts:        current.peanuts        || result.peanuts,
    soybeans:       current.soybeans       || result.soybeans,
    milk:           current.milk           || result.milk,
    nuts:           current.nuts           || result.nuts,
    celery:         current.celery         || result.celery,
    mustard:        current.mustard        || result.mustard,
    sesame:         current.sesame         || result.sesame,
    sulphurDioxide: current.sulphurDioxide || result.sulphurDioxide,
    lupin:          current.lupin          || result.lupin,
    molluscs:       current.molluscs       || result.molluscs,
    none:           current.none           || result.none,
    notProvided:    current.notProvided    || result.notProvided,
  }), {});

  if (allergensUnion.notProvided) {
    return ['notProvided']; // We can't be sure of the state of the allergens if any of Allergens[] have notProvided set to true
  }
  const allergenList = boolObjectToStrings<Allergen>(allergensUnion, REAL_ALLERGENS);

  return allergenList.length === 0 && allergensUnion.none === true
    ? ['none']
    : allergenList;
}

export function isAllergensProvided(allergens: Allergens): boolean {
  return numberOfAllergensSet(allergens) > 0
    && !boolObjectToStrings<Allergen>(allergens, ['notProvided']).length;
}
