import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onOff'
})
export class OnOffPipe implements PipeTransform {
  public transform(value: boolean | any): string {
    return value ? 'On' : 'Off';
  }
}
