export function isValidEnumValue<T extends string>(enumClass: { values: T[] }, value: string | T): boolean {
  return enumClass.values.indexOf(value as T) >= 0;
}

export function ensureValidEnumValue<T extends string>(enumClass: { values: T[] }, value: string | T): T {
  if (isValidEnumValue(enumClass, value)) {
    return value as T;
  } else {
    throw new Error(`Unexpected enum value "${ value }" is not one of: [${ enumClass.values.join(', ') }]`);
  }
}

export function ensureValidEnumValues<T extends string>(enumClass: { values: T[] }, values: (string | T)[]): T[] {
  return values.map((v) => ensureValidEnumValue(enumClass, v));
}
