<div
  class="menu-header-layout"
  analyticsId="menu-header"
>
  <div
    class="hero-image"
    [ngStyle]="heroImageUrl && {'background-image': 'url(' + heroImageUrl + ')'}"
    [class.hero-image--no-image]="!heroImageUrl"
    test-id="heroImage"
  >
    <div class="hero-image__overlay">
      <ng-content></ng-content>
    </div>
    <div
      *ngIf="editable"
      class="hero-image__edit"
    >
      <button
        *ngIf="!uploader.isUploading"
        class="button button--white"
        type="button"
        (click)="uploadInput.click()"
        [tooltip]="uploadHelp"
        lockTooltip="open"
        tooltipPosition="bottom"
        test-id="uploadButton"
      >
        Upload image
      </button>
      <ng-template #uploadHelp>
        <div class="text-center">
          2600px &times; 1000px<br>
          JPG, PNG, Max 7MB
        </div>
      </ng-template>
      <app-square-spinner
        *ngIf="uploader.isUploading"
        [small]="true"
        test-id="uploadSpinner"
      ></app-square-spinner>
      <input
        #uploadInput
        id="hero-image-upload-field"
        e2e-test-id="heroImageInput"
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        hidden
        test-id="uploadField"
      />
    </div>
    <div
      *ngIf="editable"
      class="hero-image__menu-name"
    >
      <div
        *ngIf="!isEditingName"
        class="mb-2"
      >
        Editing "<span test-id="menuName">{{ menuName }}</span>"
        <button
          class="ml-2 button-link button-link--bold"
          href="javascript:void(0)"
          (click)="editName()"
          test-id="editMenuNameLink"
          e2e-test-id="editMenuNameButton"
        >Edit name</button>
      </div>
      <div
        *ngIf="isEditingName"
        class="mb-2"
        (clickOutside)="submitName()"
        test-id="editTitleForm"
      >
        <input
          class="form-input form-input--inline"
          [value]="menuName"
          #nameInput
          test-id="menuNameInput"
          e2e-test-id="menuNameInput"
        />
        <button
          class="icon-button icon icon-check-circle"
          (click)="submitName(nameInput.value)"
          test-id="submitMenuNameButton"
          e2e-test-id="submitMenuNameButton"
        >Done</button>
      </div>
      <p class="label m-0">
        This name will not be shown to customers.
      </p>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="vendor-details col-12 d-flex flex-nowrap">
            <div class="vendor-details__logo-wrapper">
              <div
                *ngIf="!hideBackArrow"
                class="vendor-details__back-button material-icon-button material-icon-button--medium"
                (click)="onBackArrowClick()"
                test-id="backArrow"
              >arrow_back</div>
              <div
                [ngStyle]="vendor.avatarUrl && {'background-image': 'url(' + vendor.avatarUrl + ')'}"
                class="vendor-details__logo vendor-logo"
                test-id="vendorLogo"
              ></div>
            </div>
            <div class="vendor-details__info-wrapper">
              <div class="vendor-details__row-wrapper d-flex align-items-center">
                <div class="vendor-details__title-wrapper">
                  <h1
                    class="vendor-details__title"
                    test-id="vendorName"
                  >{{ vendor.name }}</h1>
                  <h2
                    *ngIf="vendorLocationName"
                    class="vendor-details__subtitle"
                    test-id="vendorLocationName"
                  >{{ vendorLocationName }}</h2>
                </div>
                <div
                  *ngIf="vendor.tags"
                  class="vendor-details__tags"
                  test-id="vendorTags"
                >
                  <span
                    *ngIf="hasMinDistance()"
                    class="vendor-details__tag"
                  >
                    <app-vendor-distance
                      *ngIf="showVendorLocationDetails()"
                      [distance]="minDistance"
                      [tooltip]="adminTooltipVendorLocation"
                      tooltipPosition="top"
                      test-id="adminMinDistance"
                    >
                      <ng-template #adminTooltipVendorLocation>
                        <b>Vendor location</b><br>
                        <ng-container *ngIf="distance">Distance to customer: {{ distance.toFixed(2) }}mi<br></ng-container>
                        <ng-container *ngIf="postcode">Postcode: {{ postcode }}<br></ng-container>
                        <ng-container *ngIf="phoneNumber">Tel: {{ phoneNumber }}</ng-container>
                      </ng-template>
                    </app-vendor-distance>
                    <app-vendor-distance
                      *ngIf="!showVendorLocationDetails()"
                      [distance]="minDistance"
                      tooltip="Distance from your location"
                      tooltipPosition="top"
                      test-id="minDistance"
                    ></app-vendor-distance>
                  </span>
                  <span
                    class="vendor-details__tag"
                    *ngFor="let tag of vendor.tags"
                  >
                    {{ tag }}
                  </span>
                </div>
              </div>

              <div class="vendor-details__row-wrapper d-flex align-items-center">
                <div
                  *ngIf="vendor.reviewsCount >= 5"
                  class="vendor-details__rating"
                >
                  <app-vendor-rating
                    [rating]="vendor.rating"
                    [reviewCount]="vendor.reviewsCount"
                    displayValue="rating"
                    analyticsId="vendor-rating"
                    test-id="rating"
                  ></app-vendor-rating>
                  <a
                    [href]="'/vendor/' + vendor.humanId + '-' + vendor.slug +'#reviews'"
                    target="_parent"
                    test-id="reviewLink"
                  >
                    {{ vendor.reviewsCount }} reviews
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col col-12 col-lg-4 menu-header-layout__badge-wrapper"
        *ngIf="showWeWorkFriendly"
      >
        <div
          class="menu-header-wework-friendly-badge"
          test-id="weWorkFriendlyBadge"
        ></div>
      </div>
    </div>
    <!--  end of AB test code-->
  </div>
</div>
