import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-filters-button',
  templateUrl: './search-filters-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFiltersButtonComponent {
  @Input()
  public activeFiltersCount: number;

  @Input()
  public isExpanded: boolean;

  @Output()
  public isExpandedChange: EventEmitter<boolean> = new EventEmitter();

  public handleClick(): void {
    this.isExpandedChange.emit(!this.isExpanded);
  }
}
