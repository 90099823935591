<ng-content select="[menu-slot=header]"></ng-content>

<div class="container mb-massive" *ngIf="menuContent">
  <div class="row">
    <div class="hide-if-empty mb-standard col-12 col-lg-8">
      <ng-content select="[menu-slot=disruption]"></ng-content>
    </div>
    <div class="col-12 col-lg-8">
      <div *ngIf="promotionalPanel">
          <ng-content select="app-promotion-panel"></ng-content>
      </div>
      <ng-content select="[menu-slot=aboutVendor]"></ng-content>
      <ng-content select="[menu-slot=dietaries]"></ng-content>
      <ng-content select="[menu-slot=content]"></ng-content>
    </div>
    <div class="col col-12 col-lg-4"><!-- Cart -->
      <ng-content select="[menu-slot=cart]"></ng-content>
    </div>
  </div>
</div>
