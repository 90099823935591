import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const builderCreateItemDialogRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'createItemDialog copyButton',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CREATE_NEW_ITEM_MODAL,
      label: 'Copy item button clicked',
    },
  },
  {
    url: '*',
    path: 'createItemDialog itemPicker',
    events: {
      class: 'ItemPickerComponent',
      eventName: 'itemPicked',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CREATE_NEW_ITEM_MODAL,
      label: 'Copy item picked',
    },
  },
];
