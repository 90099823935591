import moment from 'moment';
import { createSimpleCustomerLocationFromJson, SimpleCustomerLocation } from '../customer';
import { createVendorFromJson, Vendor, createSimpleVendorLocationFromJson, SimpleVendorLocation } from '../vendor';
import { createIndividualChoiceFromJson, IndividualChoice } from './individual-choice.model';

export interface IndividualChoiceOrderSummary {
  vendor: Vendor;
  location: SimpleCustomerLocation;
  individualChoice: IndividualChoice;
  requestedDeliveryDate: moment.Moment;
  isCancelled: boolean;
  useHiddenBudget: boolean;
  useDeskNumbers: boolean;
  selectedVendorLocation: SimpleVendorLocation | null;
}

export function createIndividualChoiceOrderSummaryFromJson(json: Partial<IndividualChoiceOrderSummary> = {}): IndividualChoiceOrderSummary {
  const summary: Partial<IndividualChoiceOrderSummary> = {};

  summary.vendor = createVendorFromJson(json.vendor);
  summary.location = createSimpleCustomerLocationFromJson(json.location);
  summary.individualChoice = createIndividualChoiceFromJson(json.individualChoice);
  if (!json.requestedDeliveryDate) {
    throw new Error('Unexpectedly missing requested delivery date on group order summary');
  }
  summary.requestedDeliveryDate = moment(json.requestedDeliveryDate);
  summary.isCancelled = json.isCancelled;
  summary.useHiddenBudget = json.useHiddenBudget;
  summary.useDeskNumbers = json.useDeskNumbers;
  summary.selectedVendorLocation = json.selectedVendorLocation
    ? createSimpleVendorLocationFromJson(json.selectedVendorLocation)
    : null;

  return summary as IndividualChoiceOrderSummary;
}
