import { Pipe, PipeTransform } from '@angular/core';
import { Dietaries, DIETARIES_DISPLAY_ORDER } from '@citypantry/util-models';
import { DietNamePipe } from './diet-name.pipe';

@Pipe({
  name: 'dietNameList'
})
export class DietNameListPipe implements PipeTransform {

  constructor(private dietNamePipe: DietNamePipe) {
  }

  public transform(value: Dietaries, separator: string = ', '): string {
    const dietariesPresent = DIETARIES_DISPLAY_ORDER.filter((key: keyof Dietaries) => value[key]);

    if (dietariesPresent.length > 0) {
      const dietaryDescriptions = dietariesPresent.map((key: keyof Dietaries) => this.dietNamePipe.transform(key));
      return dietaryDescriptions.join(separator);
    } else {
      return 'Not suitable for specific dietary requirements';
    }
  }
}
