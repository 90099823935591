import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from './error-code.enum';

export interface ErrorMessage {
  rayId?: string; // Unique ID that only exists for HTTP errors returned by API
  key: string; // Identifier for this type of error
  messageArgs?: { // Values to be interpolated in the error displayed
    [key: string]: string | number | boolean | Error;
  };
  context?: unknown; // additional information that might be useful to debug the error
  exceptionMessage?: string; // The specific error message returned by API
}

export interface ValidationErrorInformation {
  message: string;
  name?: any;
  messageArgs?: any;
}

export interface ValidationErrorMessage {
  Validation: ValidationErrorInformation[]; /* eslint-disable-line @typescript-eslint/naming-convention */ // API model, not defined by us
}

export class ErrorResponse {
  constructor(
    public messages: ErrorMessage[],
    public apiResponse: HttpErrorResponse
  ) {}

  public get status(): number {
    return this.apiResponse.status;
  }

  public static findMessageWithCode(error: any, code: ErrorCode): ErrorMessage {
    if (error instanceof ErrorResponse) {
      return error.findMessageWithCode(code);
    }
    return null;
  }

  public findMessageWithCode(code: ErrorCode): ErrorMessage {
    return this.messages.length &&
      this.messages.find((message) => message.key === code);
  }
}
