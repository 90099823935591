import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const marketingCampaignBannerRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'marketing-campaign-banner bottom-of-banner',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.MARKETING_CAMPAIGN_BANNER,
      label: `${event.data['marketing-campaign-banner'].analyticsPrefix} viewed`,
    })
  },
  {
    url: '*',
    path: 'marketing-campaign-banner link',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MARKETING_CAMPAIGN_BANNER,
      label: `${event.data['marketing-campaign-banner'].analyticsPrefix} link clicked`,
    })
  },
  {
    url: '*',
    path: 'marketing-campaign-banner tag',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MARKETING_CAMPAIGN_BANNER,
      label: `${event.data['marketing-campaign-banner'].analyticsPrefix} tag clicked`,
    })
  },
];
