import { MajorCurrency } from '../../money';
import { createCustomerFromJson, Customer, CustomerJsonProperties } from '../customer.model';
import {
  createMealPlanRequirementsFromJson,
  MealPlanRequirements,
  MealPlanRequirementsJsonProperties
} from './meal-plan-requirements.model';
import { MealPlanStatus } from './meal-plan-status.enum';
import { createProposedOrderFromJson, ProposedOrder } from './proposed-order.model';

export interface MealPlan {
  requirementsAtGeneration: MealPlanRequirements;
  customer: Customer;
  proposedOrders: ProposedOrder[];
  id: string;
  status: MealPlanStatus;
}

export interface MealPlanJsonProperties {
  // Mapped fields
  requirementsAtGeneration: MealPlanRequirementsJsonProperties;
  customer: CustomerJsonProperties;
  id: string;
  status: MealPlanStatus; // 2

  // Not mapped yet; move up if they become relevant
  datesToDeliverOn: string[]; // ["2017-09-13T10:00:00+00:00", "2017-09-20T10:00:00+00:00", "2017-09-27T10:00:00+00:00",…]
  endDate: string; // "2017-10-10T22:59:59+00:00"
  humanId: number; // 10569
  numberOfFilledOrders: number; // 2
  numberOfPlacedOrders: number; // 0
  numberOfProposedOrders: number; // 4
  placedOrders: any[]; // []
  proposedOrders: any[]; // [{,…},…]
  startDate: string; // "2017-09-10T23:00:00+00:00"
  statusText: string; // "pending_staff_approval"
  totalBudget: MajorCurrency; // 660
  totalCost: MajorCurrency; // 229.5
}

export function createMealPlanFromJson(json?: MealPlanJsonProperties): MealPlan {
  const mealPlan: Partial<MealPlan> = {};

  mealPlan.id = json.id;
  mealPlan.requirementsAtGeneration = json.requirementsAtGeneration
    ? createMealPlanRequirementsFromJson(json.requirementsAtGeneration)
    : null;
  mealPlan.customer = json.customer ? createCustomerFromJson(json.customer) : null;
  mealPlan.proposedOrders = (json.proposedOrders || []).map(createProposedOrderFromJson);
  mealPlan.status = json.status;

  return mealPlan as MealPlan;
}
