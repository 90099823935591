import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DebounceHostListener, createArray } from '@citypantry/util';
import { WindowRef } from '@citypantry/util-browser';
import { SearchRequest, SearchResult, VendorId } from '@citypantry/util-models';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { SearchResultUrl } from '../search-result/search-result.component';

const SCROLL_THRESHOLD_PX = 500;
const THROTTLE_LOAD_MORE_MS = 1000;

@Component({
  selector: 'app-search-results-list',
  templateUrl: './search-results-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsListComponent {

  @Input()
  public results: SearchResult[];

  @Input()
  public placeholderCount: number;

  /**
   * Passed through to the search result component.
   * @see SearchResultComponent.url
   */
  @Input()
  public searchResultUrl: SearchResultUrl;

  @Input()
  public resultCount: number;

  @Input()
  public isSearching: boolean;

  @Input()
  public showMainsWithinBudget: boolean;

  @Input()
  public searchRequest: SearchRequest;

  @Input()
  public hiddenBudgetVendors: VendorId[];

  @Input()
  public showDistance: boolean;

  @Input()
  public showExactRemainingCapacity: boolean;

  @Output()
  public loadMoreResults: EventEmitter<void> = new EventEmitter();

  @Output()
  public openZopimChat: EventEmitter<void> = new EventEmitter();

  private throttleLoadMore: Subject<void>;

  constructor(
    private element: ElementRef,
    private windowRef: WindowRef,
  ) {
    this.throttleLoadMore = new Subject<void>();
    this.throttleLoadMore.pipe(throttleTime(THROTTLE_LOAD_MORE_MS)).subscribe(() => this.loadMoreResults.emit());
    this.hiddenBudgetVendors = [];
  }

  @HostListener('window:resize')
  @HostListener('window:scroll')
  @DebounceHostListener()
  public onScroll(): void {
    const boundingRect: DOMRect = (this.element.nativeElement as HTMLElement).getBoundingClientRect();
    if (boundingRect.top + boundingRect.height - SCROLL_THRESHOLD_PX < this.windowRef.getViewportHeight()) {
      setTimeout(() => this.throttleLoadMore.next());
    }
  }

  public shouldShowVendorComingSoon(): boolean {
    return this.results
      && this.results.length === this.resultCount // should only display when we are at the final result
      && this.resultCount !== 0                   // should not display when no results exist, as there is already a message for this case
      && !this.isSearching;                       // should not show until we have finished searching
  }

  public get placeholders(): undefined[] {
    if ((!this.results || !this.results.length) && this.placeholderCount > 0) {
      return createArray(this.placeholderCount);
    } else {
      return [];
    }
  }

  public isHiddenBudgetVendor(vendorId: VendorId): boolean {
    return this.hiddenBudgetVendors && this.hiddenBudgetVendors.includes(vendorId);
  }
}
