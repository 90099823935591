import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { FEATURE_STATE_NAME, GlobalDialogState } from './global-dialog.state';

const getGlobalDialogState = createFeatureSelector<GlobalDialogState>(FEATURE_STATE_NAME);

const getSettings = (state: GlobalDialogState) => state.settings;

export const GlobalDialogSelectors = mapSelectors(getGlobalDialogState, {
  getSettings,
});
