import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TypedSimpleChanges } from '@citypantry/util';
import { DeliveryContact } from '@citypantry/util-models';

import { CustomValidators } from '@citypantry/util-validators';

@Component({
  selector: 'app-delivery-contact-form',
  templateUrl: './delivery-contact-form.component.html'
})
export class DeliveryContactFormComponent implements OnChanges, OnInit {

  @Input()
  public deliveryContacts: DeliveryContact[];

  @Input()
  public selectedLocationDefaultDeliveryContact: DeliveryContact | null;

  @Input()
  public hideExistingContacts?: boolean;

  public selectedContact: FormControl;

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder, private parent: FormGroupDirective) {
    this.buildForm();
  }

  public ngOnChanges(changes: TypedSimpleChanges<DeliveryContactFormComponent>): void {
    if (changes.selectedLocationDefaultDeliveryContact && changes.selectedLocationDefaultDeliveryContact.currentValue) {
      this.setForm(this.selectedLocationDefaultDeliveryContact);
    } else if (changes.selectedLocationDefaultDeliveryContact && !changes.selectedLocationDefaultDeliveryContact.currentValue) {
      this.resetForm();
    }
  }

  public shouldShowErrors(field: string): boolean {
    const control = this.form.get([field]);

    return control.touched && control.invalid;
  }

  public getErrorsToDisplay(formControlName: string): ValidationErrors {
    const control = this.form.get(formControlName);

    return control.invalid && control.touched
      ? control.errors
      : {};
  }

  public ngOnInit(): void {
    this.parent.form.addControl('deliveryContact', this.form);
  }

  private buildForm(): void {

    const mustSelectNotificationType: ValidatorFn = (formGroup: FormGroup) => {
      if (!formGroup.value.emailNotificationEnabled && !formGroup.value.smsNotificationEnabled) {
        return { 'mustSelectNotificationType': true };
      }
      return null;
    };

    this.selectedContact = new FormControl('');
    this.form = this.formBuilder.group({
      id: [''],
      firstName: ['', [Validators.required, CustomValidators.personName]],
      lastName: ['', [Validators.required, CustomValidators.personName]],
      email: ['', [Validators.required, CustomValidators.email]],
      phoneNumber: ['', [Validators.required, CustomValidators.phone]],
      emailNotificationEnabled: [false],
      smsNotificationEnabled: [false]
    }, {
      validators: [mustSelectNotificationType]
    });

    this.selectedContact.valueChanges.subscribe((contactId: string) => {
      const newSelectedContact = this.deliveryContacts.find((contact) => contact.id === contactId);

      if (newSelectedContact) {
        this.setForm(newSelectedContact);
      } else {
        this.resetForm();
      }

      // Since the selectedContact formControl sits outside the formGroup, we need to mark the formGroup
      // as dirty when the selectedContact dropdown is changed. Otherwise, the edit delivery form component
      // wont know anything has changed, and wont emit the correct value to the page component
      this.form.markAsDirty();
    });
  }

  private setForm(contact: DeliveryContact): void {
    this.selectedContact.setValue(contact.id, { emitEvent: false });
    this.form.setValue({
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
      emailNotificationEnabled: contact.emailNotificationEnabled,
      smsNotificationEnabled: contact.smsNotificationEnabled,
    });
  }

  private resetForm(): void {
    this.form.reset({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      emailNotificationEnabled: false,
      smsNotificationEnabled: false
    });
  }
}
