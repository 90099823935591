import { Routes } from '@angular/router';
import {
  PrepareMealPlanSearchRequestGuard,
  PrepareSearchRequestGuard,
  SearchComponent
} from '@citypantry/feature-search';
import {
  CanAccessJefbGuard,
  IsAuthDataLoadedGuard,
  IsLoggedInGuard,
  IsLoggedOutOrCanAccessJefbGuard,
} from '@citypantry/shared-auth';
import { ShowQualificationGuard } from '@citypantry/shared-customer-qualification';
import {
  CompanyHolidaysGuard,
  HomeRedirectGuard,
  OperationalRegionsGuard,
  StoreFavouriteAfterAuthenticationGuard,
  TrackRegistrationGuard
} from '@citypantry/shared-root';

/* eslint-disable arrow-parens */  // Lazy loading requires a very specific format, without arrow parens
export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      CompanyHolidaysGuard,
      ShowQualificationGuard,
      TrackRegistrationGuard,
      StoreFavouriteAfterAuthenticationGuard,
      OperationalRegionsGuard,
      IsAuthDataLoadedGuard,
    ],
    canActivateChild: [
      // By placing top-level guards in this canActivateChild array, we ensure they are not only run
      // on the initial SPA load, but also on any subsequent navigation within the SPA
      // (if we expect the relevant data might have changed in the meantime).
      IsAuthDataLoadedGuard,
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [HomeRedirectGuard],
        children: [] // intentionally blank as the guard always redirects the user somewhere
      },
      {
        path: 'my-meals',
        canActivate: [IsLoggedInGuard, CanAccessJefbGuard],
        loadChildren: () => import('@citypantry/feature-my-meals').then(m => m.MyMealsModule),
      },
      {
        path: 'just-eat-vouchers',
        loadChildren: () => import('@citypantry/feature-just-eat-vouchers').then(m => m.JustEatVouchersModule),
      },
      {
        path: 'eat',
        loadChildren: () => import('@citypantry/feature-eat').then(m => m.EatModule),
      },
      {
        path: 'anonymous',
        loadChildren: () => import('@citypantry/feature-logistics').then(m => m.LogisticsModule),
      },
      {
        path: 'login',
        loadChildren: () => import('@citypantry/feature-login').then(m => m.LoginModule)
      },
      {
        path: 'menus',
        children: [
          {
            path: 'account',
            canActivate: [IsLoggedInGuard, CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-account').then(m => m.AccountModule),
          },
          {
            path: 'builder',
            loadChildren: () => import('@citypantry/feature-builder').then(m => m.BuilderModule),
          },
          {
            path: 'individual-choice',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-individual-choice').then(m => m.IndividualChoiceModule),
          },
          {
            path: 'customer/orders/:orderId/feedback',
            canActivate: [IsLoggedInGuard, CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-feedback').then(m => m.FeedbackModule),
          },
          {
            // This path needs to be declared before other /customer routes!
            // The reason is that we want a separate lazy loaded module to be added
            // to a subpath of an existing lazy loaded module:
            //
            // - /customer points to CustomerModule
            // - /customer/order/eaters points to OrderEatersModule
            //
            // The separation of the two modules' routers works only because
            // the more specific route is matched BEFORE the less specific
            path: 'customer/orders/eaters',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-order-eaters').then(m => m.OrderEatersModule),
          },
          {
            path: 'search/popup',
            component: SearchComponent,
            canActivate: [IsLoggedOutOrCanAccessJefbGuard, PrepareSearchRequestGuard],
            data: {
              popUpMode: true,
              meta: {
                title: 'Just Eat for Business | Search Pop-ups',
                description: 'Search through our growing list of local restaurants and kitchens, bringing you a range ' +
                  'of delicious cuisines delivered directly ' +
                  'to your office door for team breakfasts, lunches, meetings and corporate events.',
                image: 'https://business.just-eat.co.uk/dist/images/JustEatforBusinessShare.jpg',
              },
              canonicalPath: '/menus/search',
            }
          },
          {
            path: 'search',
            component: SearchComponent,
            canActivate: [IsLoggedOutOrCanAccessJefbGuard, PrepareSearchRequestGuard],
            data: {
              meta: {
                title: 'Just Eat for Business | Search kitchens',
                description: 'Search through our growing list of local restaurants and kitchens, bringing you a range ' +
                  'of delicious cuisines delivered directly ' +
                  'to your office door for team breakfasts, lunches, meetings and corporate events.',
                image: 'https://business.just-eat.co.uk/dist/images/JustEatforBusinessShare.jpg'
              },
              canonicalPath: '/menus/search',
            }
          },
          {
            path: 'mealplan/:mealPlanId/orders/:orderId/search',
            component: SearchComponent,
            canActivate: [PrepareMealPlanSearchRequestGuard, CanAccessJefbGuard],
          },
          {
            path: 'vendor',
            loadChildren: () => import('@citypantry/feature-vendor').then(m => m.VendorModule),
          },
          {
            path: 'customer',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-customer').then(m => m.CustomerModule),
          },
          {
            path: 'admin',
            children: [
              {
                // TODO CPD-17414 Remove once automated refunds are implemented
                path: 'individual-ordering-refund',
                loadChildren: () => import('@citypantry/feature-individual-ordering-refund').then(m => m.IndividualOrderingRefundModule),
              },
              {
                path: '',
                loadChildren: () => import('@citypantry/feature-admin').then(m => m.AdminModule),
              }
            ]
          },
          {
            path: 'my-orders',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-my-orders').then(m => m.MyOrdersModule),
          },
          {
            path: 'mealplan',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-meal-plan').then(m => m.MealPlanModule),
          },
          {
            path: 'groups',
            canActivate: [CanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-colleague-groups').then(m => m.ColleagueGroupsModule),
          },
          {
            path: 'individual-ordering',
            children: [
              {
                path: 'cost-codes-administration',
                loadChildren: () => import('@citypantry/feature-individual-ordering-cost-codes-administration').then(
                  m => m.IndividualOrderingCostCodesAdministrationModule
                ),
              },
              {
                path: 'restaurant',
                loadChildren: () => import('@citypantry/feature-on-demand-menu').then(m => m.OnDemandMenuModule),
              },
              {
                path: 'checkout',
                loadChildren: () => import('@citypantry/feature-on-demand-checkout').then(m => m.OnDemandCheckoutModule),
              },
              {
                path: 'orders',
                loadChildren: () => import('@citypantry/feature-on-demand-order-tracking').then(m => m.OnDemandOrderTrackingModule),
              },
              {
                path: '', // entry point to On Demand user flow
                loadChildren: () => import('@citypantry/feature-on-demand-search').then(m => m.OnDemandSearchModule),
              },
            ]
          },
          {
            path: '',
            canActivate: [IsLoggedOutOrCanAccessJefbGuard],
            loadChildren: () => import('@citypantry/feature-customer-menu').then(m => m.CustomerMenuModule),
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'search'
          },
        ],
      },
      {
        path: 'checkout',
        canActivate: [CanAccessJefbGuard],
        loadChildren: () => import('@citypantry/feature-checkout').then(m => m.CheckoutModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@citypantry/feature-page-not-found').then(m => m.PageNotFoundModule),
  },
];
