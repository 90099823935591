import { isDisruptionActive, mapSelectors } from '@citypantry/util';
import {
  CartStatus,
  CartStatuses,
  ChoiceDeadlines,
  IndividualChoicePaymentDetails,
  MenuContent,
  MinorCurrency,
  minorCurrencyToMajor,
  SearchRequest,
  ValidateOrderGroupData,
  VendorId,
  VendorLocationSlug,
  VendorSlug,
} from '@citypantry/util-models';
import { getIndividualChoiceSetupState } from '../app.state';
import { computeLoyaltyPoints } from '../util/compute-loyalty-points';
import {
  calculateTotalBudget,
  CurrentMenu,
  getIcSetupBudget,
  getIcSetupSearchRequest,
  IndividualChoiceSetupState,
} from './individual-choice-setup.state';

const MINIMUM_TIME_FOR_EATERS_TO_CHOOSE_IN_SECONDS = 1800;

const getBudget = (state: IndividualChoiceSetupState): MinorCurrency => getIcSetupBudget(state);

const getTotalBudget = (state: IndividualChoiceSetupState) => {
  const headcount = getHeadcount(state);
  const budget = getBudget(state);
  return calculateTotalBudget(headcount, budget);
};
const getDefaultCostSummary = (state: IndividualChoiceSetupState) => ({
  deliveryCost: 0,
  deliveryVat: 0,
  serviceFeeNet: 0,
  serviceFeeVat: 0,
  totalCost: getTotalBudget(state)
});
const getCostSummary = (state: IndividualChoiceSetupState) => state.costSummary ? state.costSummary : getDefaultCostSummary(state);
const getDeliveryCost = (state: IndividualChoiceSetupState) => {
  const costSummary = getCostSummary(state);
  return costSummary.deliveryCost;
};
const getDeliveryVat = (state: IndividualChoiceSetupState) => {
  const costSummary = getCostSummary(state);
  return costSummary.deliveryVat;
};
const getServiceFeeNet = (state: IndividualChoiceSetupState) => {
  const costSummary = getCostSummary(state);
  return costSummary.serviceFeeNet;
};
const getServiceFeeVat = (state: IndividualChoiceSetupState) => {
  const costSummary = getCostSummary(state);
  return costSummary.serviceFeeVat;
};
const getOrderTotal = (state: IndividualChoiceSetupState) => {
  const costSummary = getCostSummary(state);
  return costSummary.totalCost;
};
const getLoyaltyPoints = (state: IndividualChoiceSetupState) => {
  const totalBudget = getTotalBudget(state);
  return totalBudget && computeLoyaltyPoints(minorCurrencyToMajor(totalBudget));
};
const isDeliveryDisruptionActive = (state: IndividualChoiceSetupState): boolean => {
  const searchRegion = state.searchRegion;
  const searchDate = state.deliveryDate;

  return isDisruptionActive(searchRegion, searchDate);
};
const getSearchRequest = (state: IndividualChoiceSetupState): SearchRequest => getIcSetupSearchRequest(state);

const getChoiceDeadlineDate = (state: IndividualChoiceSetupState) => {
  const minutes = ChoiceDeadlines.toMinutes(state.choiceDeadline);
  return state.deliveryDate.clone().subtract(minutes, 'minute');
};

const getLatestChoiceOpenStartPossible = (state: IndividualChoiceSetupState) =>
  getChoiceDeadlineDate(state).clone().subtract(MINIMUM_TIME_FOR_EATERS_TO_CHOOSE_IN_SECONDS, 'seconds');

// Selects all fields required for order validation, combined to avoid bugs due to state changes between selections
const getValidateOrderGroupData = (state: IndividualChoiceSetupState): ValidateOrderGroupData => ({
  location: state.searchLocation,
  deliveryDate: state.deliveryDate,
  choiceDeadline: state.choiceDeadline,
  budget: state.budget,
  selectedVendors: state.selectedVendors,
  expectedHeadcount: state.headcount,
  isSubsidisedChoiceTurnedOn: state.isSubsidisedChoiceTurnedOn,
  paymentDetails: state.paymentDetails,
  isColleagueGroupsToggledOn: state.isColleagueGroupsToggledOn
});

const getEditCustomerLocation = (state: IndividualChoiceSetupState) => state.editLocation.customerLocation;

const getCurrentMenuVendorId = (state: IndividualChoiceSetupState) =>
  state.currentMenu && state.currentMenu.vendor && state.currentMenu.vendor.id;

const isColleagueGroupsToggledOn = (state: IndividualChoiceSetupState) => state.isColleagueGroupsToggledOn;

const isSubsidisedChoiceTurnedOn = (state: IndividualChoiceSetupState) => state.isSubsidisedChoiceTurnedOn;
const getChoiceDeadline = (state: IndividualChoiceSetupState) => state.choiceDeadline;
const getValidChoiceDeadlines = (state: IndividualChoiceSetupState) => state.validChoiceDeadlines;
const getSearchLocation = (state: IndividualChoiceSetupState) => state.searchLocation;
const getCustomerLocation = (state: IndividualChoiceSetupState) => state.customerLocation;
const getIsNewLocationFormSubmitting = (state: IndividualChoiceSetupState) => state.isNewLocationFormSubmitting;
const getIsLocationFormSubmitting = (state: IndividualChoiceSetupState) => state.isLocationFormSubmitting;
const getCurrentPage = (state: IndividualChoiceSetupState) => state.currentPage;
const getDeliveryDate = (state: IndividualChoiceSetupState) => state.deliveryDate;
const getResultCount = (state: IndividualChoiceSetupState) => state.resultCount;
const getSearchResults = (state: IndividualChoiceSetupState) => state.searchResults;
const isSearching = (state: IndividualChoiceSetupState) => state.isSearching;
const isSearchParamsDialogOpen = (state: IndividualChoiceSetupState) => state.isSearchParamsDialogOpen;
const getSelectedVendors = (state: IndividualChoiceSetupState) => state.selectedVendors;
const getAvailableItemIds = (state: IndividualChoiceSetupState) => state.availableItemIds.items;
const isLoadingAvailableItems = (state: IndividualChoiceSetupState) => state.availableItemIds.isLoading;
const getSearchFilters = (state: IndividualChoiceSetupState) => ({
  cuisines: state.filters.cuisines,
  events: state.filters.events,
  dietaries: state.filters.dietaries,
  vendorFlags: state.filters.vendorFlags,
});
const getSortOrder = (state: IndividualChoiceSetupState) => state.sortBy;
const getCurrentMenu = (state: IndividualChoiceSetupState): CurrentMenu | null => state.currentMenu;
const getPaymentDetails = (state: IndividualChoiceSetupState): IndividualChoicePaymentDetails => state.paymentDetails;
const getPaymentFormLoading = (state: IndividualChoiceSetupState): boolean => state.paymentFormLoading;

const getHeadcount = (state: IndividualChoiceSetupState) => state.headcount;
const getCreateOrderStatus = (state: IndividualChoiceSetupState): CartStatus => state.createOrderStatus;
const getOrderValidationError = (state: IndividualChoiceSetupState) => state.orderValidationError;
const isValidatingOrder = (state: IndividualChoiceSetupState) => state.createOrderStatus === CartStatuses.VALIDATING;
/**
 * Returns true if parameters required for performing search or fetching the menu are present.
 * Fetching item IDs will require a separate check to ensure all required parameters are present.
 */
const hasAllRequiredSearchParameters = (state: IndividualChoiceSetupState) =>
  !!(state.deliveryDate && (state.customerLocation || state.searchLocation));

const getCurrentMenuContent = (state: IndividualChoiceSetupState): MenuContent | null => {
  if (!state.currentMenu || !state.currentMenu.content) {
    return null;
  }

  return state.currentMenu.content;
};

export const isSameAsCurrentMenu = (state: IndividualChoiceSetupState): (
  vendorIdOrSlug: VendorId | VendorSlug,
  vendorLocationSlug: VendorLocationSlug | null,
) => boolean => {
  const currentMenu = getCurrentMenu(state);
  return (vendorIdOrSlug, vendorLocationSlug) =>
    !!(currentMenu
      && currentMenu.vendor && [currentMenu.vendor.id, currentMenu.vendor.slug].includes(vendorIdOrSlug)
      && currentMenu.vendorLocation && currentMenu.vendorLocation.slug === vendorLocationSlug);
};

export const IndividualChoiceSetupSelectors = mapSelectors(getIndividualChoiceSetupState, {
  getBudget,
  getHeadcount,
  getCostSummary,
  getDeliveryCost,
  getDeliveryVat,
  getServiceFeeNet,
  getServiceFeeVat,
  getOrderTotal,
  getTotalBudget,
  getLoyaltyPoints,
  isDeliveryDisruptionActive,
  getSearchRequest,
  getDeliveryDate,
  getChoiceDeadlineDate,
  getLatestChoiceOpenStartPossible,
  getCustomerLocation,
  getValidateOrderGroupData,
  getSearchLocation,
  getEditCustomerLocation,
  getIsLocationFormSubmitting,
  getIsNewLocationFormSubmitting,
  getPaymentFormLoading,
  getCurrentMenuVendorId,
  getSelectedVendors,
  isColleagueGroupsToggledOn,

  isSubsidisedChoiceTurnedOn,
  getChoiceDeadline,
  getValidChoiceDeadlines,
  getCurrentPage,
  getResultCount,
  getSearchResults,
  isSearching,
  isSearchParamsDialogOpen,
  getAvailableItemIds,
  isLoadingAvailableItems,
  getSearchFilters,
  getSortOrder,
  getCurrentMenu,
  getPaymentDetails,
  getCreateOrderStatus,
  getOrderValidationError,
  isValidatingOrder,
  hasAllRequiredSearchParameters,
  getCurrentMenuContent,

  getIcSetupBudget,

  isSameAsCurrentMenu,
});
