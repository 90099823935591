export type ReduceFn<V, A> = (accumulator: A, current: V) => A;
export type FilterFn<T> = (current: T, index: number, items: T[]) => boolean;
export type ArrayValues<T> = T extends (infer U)[] ? U : never;

/**
 * Shorthand for summing an array using Array.reduce. Optional extraction function argument converts the value before summing.
 * For an array A, and a possible function f, the following are equivalent:
 *
 * A.reduce(sum(), 0) == A.reduce((acc, value) => acc + value), 0);
 * A.reduce(sum(f), 0) == A.map(f).reduce(sum()) == A.reduce((acc, value) => acc + f(value), 0);
 */
export function sum(): ReduceFn<number, number>;
export function sum<T>(extractor: (value: T) => number): ReduceFn<T, number>;
export function sum<T>(extractor?: (value: T) => number): ReduceFn<T, number> {
  if (!extractor) {
    extractor = (x: T) => +x; // Cast to number
  }
  return (accumulator: number, value: T) => accumulator + extractor(value);
}

/**
 * Takes a list and returns a map function to turn two lists into one list of pairs, where each pair is one element from each list.
 * Useful for when you need to iterate over two arrays simultaneously.
 * @param list
 * @returns {(item:T, index:number)=>(T|U)[]} A function to pass to Array.map such that for two arrays A, B of the same length,
 * A.map(zip(B)) == [[A[0], B[0]], [A[1], B[1]], ...]
 */
export function zip<T, U>(list: U[]): ((item: T, index: number) => [T, U]) {
  return (item: T, index: number) => [item, list[index]];
}

export function concat<T>(): ReduceFn<T[], T[]> {
  return (accumulator: T[] | undefined, current: T[]) => {
    return (accumulator || []).concat(current);
  };
}

export type Comparator<T = any> = (a: T, b: T) => boolean;

/**
 * Filter function for returning only unique values from an array.
 * @example [1, 4, 1, 5].filter(unique) // returns [1, 4, 5]
 */
export function unique<T = any>(item: T, index: number, list: T[]): boolean;
/**
 * Filter function for returning only unique values from an array, using an optional comparator.
 *
 * @param comparator Optional comparator. If omitted, strict equality is used.
 *
 * @example [1, 4, 1, 5].filter(unique()) // returns [1, 4, 5]
 * @example [{id: 1}, {id: 2}, {id: 1}].filter(unique((a, b) => a.id === b.id)) // returns [{id: 1}, {id: 2}]
 */
export function unique<T = any>(comparator?: Comparator<T>): FilterFn<T>;
export function unique<T = any>(comparatorOrItem?: T | Comparator<T>, index?: number, list?: T[]): boolean | FilterFn<T> {
  if (typeof index === 'number') {
    // Was called as `array.filter(unique)`
    return list.indexOf(comparatorOrItem as T) === index;
  } else {
    const comparator: Comparator<T> = comparatorOrItem as Comparator<T>;

    if (comparator) {
      return (item: T, _index: number, _list: T[]): boolean => {
        const matchesThisItem = comparator.bind(null, item);
        return _list.findIndex(matchesThisItem) === _index;
      };
    } else {
      // For efficiency just use indexOf directly, which does strict equality
      return (item: T, _index: number, _list: T[]): boolean => {
        return _list.indexOf(item) === _index;
      };
    }
  }
}

export function createArray(length: number): undefined[] {
  return Array.apply(null, { length }); // eslint-disable-line prefer-spread
}

/**
 * Utility function for `.filter(x => !!x)`.
 * Be aware that this will not behave as expected for arrays - if you need it to, modify this function.
 */
export function nonEmpty<T extends string | object | null | undefined = any>(value: T): boolean {
  return !!value;
}

/**
 * Ensure that the value is an array.
 */
export function arrayify<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function hasSameElementsInAnyOrder<T extends any[]>(a: T, b: T): boolean {
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return a.length === b.length && sortedA.every((element, index) => sortedB[index] === element);
}

/**
 * Given an array and a predicate, it splits the given array into an array of two arrays:
 * - the first array contains values from the given array that satisfies the predicate
 * - the second array contains values from the given array that do not satisfy the predicate
 */
export function partition<T>(input: T[], predicate: FilterFn<T>): [T[], T[]] {
  return [
    input.filter(predicate),
    input.filter((...args) => !predicate(...args)),
  ];
}

export function toggleElement<T>(input: T[], value: T): T[] {
  return input.includes(value)
    ? input.filter((foundValue) => foundValue !== value)
    : [...input, value];
}

/**
 * Inverts the given predicate function.
 */
export function not<T>(predicate: (value: T) => boolean): (value: T) => boolean {
  return (value: T) => !predicate(value);
}

/**
 * Comparison function for use in Array.sort.
 * Compares elements by each predicate in order, returns the result of first predicate that doesn't
 * produce identical values. The resulting sort will put the true value above the false value.
 *
 * @example compareInOrderOfPreference(-5, 5.01, [Number.isNaN, Number.isInteger, (value => value > 0)]) // returns 1
 * @example compareInOrderOfPreference(-5, 5.01, [Number.isNaN, (value => value > 0), Number.isInteger]) // returns -1
 */
export function compareInOrderOfPreference<T>(a: T, b: T, predicates: ((value: T) => boolean)[]): (-1 | 1 | 0) {
  for (const callback of predicates) {
    const aValue = callback(a);
    const bValue = callback(b);
    if (aValue !== bValue) {
      return aValue ? -1 : 1;
    }
  }
  return 0;
}

export function emptyToNull<T>(array: T[]): T[] | null {
  return array.length > 0 ? array : null;
}
