import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentEffects } from './payment.effects';
import { FEATURE_STATE_NAME, reducer } from './payment.state';

@NgModule({
  imports: [
    EffectsModule.forFeature([PaymentEffects]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
  providers: [
    PaymentEffects
  ]
})
export class PaymentModule {}
