import { animate, animation, AnimationReferenceMetadata, style } from '@angular/animations';

export const fadeInDown: AnimationReferenceMetadata = animation([
  style({
    opacity: 0,
    transform: 'translate3d(0, {{ offset }}, 0)'
  }),
  animate('{{ timing }}', style({
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }))
], {
  params: {
    timing: '200ms',
    offset: '-2rem', // How far down does the element move during the animation
  }
});

export const fadeOutUp: AnimationReferenceMetadata = animation([
  style({
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }),
  animate('{{ timing }}', style({
    opacity: 0,
    transform: 'translate3d(0, {{ offset }}, 0)'
  }))
], {
  params: {
    timing: '200ms',
    offset: '-2rem', // How far up does the element move during the animation
  }
});
