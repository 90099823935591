/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type SearchResultsCategory = 'recommended' | 'favourite' | 'popular' | 'recent_meals';

export class SearchResultsCategories {
  public static RECOMMENDED:  SearchResultsCategory & 'recommended'  = 'recommended';
  public static FAVOURITE:    SearchResultsCategory & 'favourite'    = 'favourite';
  public static POPULAR:      SearchResultsCategory & 'popular'      = 'popular';
  public static RECENT_MEALS: SearchResultsCategory & 'recent_meals' = 'recent_meals';

  public static get values(): SearchResultsCategory[] {
    return [
      SearchResultsCategories.RECOMMENDED,
      SearchResultsCategories.FAVOURITE,
      SearchResultsCategories.POPULAR,
      SearchResultsCategories.RECENT_MEALS,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type SearchResultsCategoryEnum = typeof SearchResultsCategories;
