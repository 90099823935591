import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const itemBuilderPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/items/:id',
    path: 'eco-friendly-packaging',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.SINGLE_ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: `Eco-friendly packaging ${event.data['eco-friendly-packaging'].checked ? 'checked' : 'unchecked'}`
    })
  },
  {
    url: '/menus/builder/bundles/:id',
    path: 'eco-friendly-packaging',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.SINGLE_ITEM_EDITOR,
      action: AnalyticsActions.CLICK,
      label: `Eco-friendly packaging ${event.data['eco-friendly-packaging'].checked ? 'checked' : 'unchecked'}`
    })
  },
];
