import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessiveNameOrDefault'
})
export class PossessiveNameOrDefaultPipe implements PipeTransform {

  public transform(name: string | null, defaultValue: string): string {
    if (!name) {
      return defaultValue;
    }

    return `${ name }'s`;
  }

}
