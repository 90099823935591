import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, MealPlanSelectors } from '@citypantry/state';
import { MealPlan, Promotion, PromotionNames } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionQueries {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  public getAllPromotions(): Observable<Promotion[]> {
    return combineLatest([
      this.store.select(AuthSelectors.getPromotions),
      this.store.select(AuthSelectors.isLoggedInUser),
    ]).pipe(
      map(([promotions, loggedIn]: [Promotion[], boolean]) => {
        // In cases where the user is not authenticated we return a static list of unauthenticated promotions
        const unauthenticatedPromotions = [{
          name: PromotionNames.FREE_DELIVERY,
          priority: 1
        }, {
          name: PromotionNames.POP_UP,
          priority: 2
        }, {
          name: PromotionNames.JUST_EAT_PAY,
          priority: 3
        }];

        return loggedIn ? promotions : unauthenticatedPromotions;
      })
    );
  }

  // returns only promotions that should be displayed in the promotion-banner
  public getBannerPromotions(): Observable<Promotion[]> {
    return this.getAllPromotions();
  }

  // returns only the promotions that should be displayed in the search-promo-card
  public getSearchPromoCardPromotions(): Observable<Promotion[]> {
    return combineLatest([
      this.getAllPromotions(),
      this.store.select(MealPlanSelectors.getMealPlan),
    ]).pipe(
      map(([promotions, mealPlan]: [Promotion[], MealPlan]) => {
        // Free delivery should never be display in the search promo card
        promotions = promotions.filter((promotion) => promotion.name !== PromotionNames.FREE_DELIVERY);

        // Meal plan customers should not be shown search card promotions
        return mealPlan ? [] : promotions;
      })
    );
  }
}
