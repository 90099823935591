import { HistoryAction, HistoryActions } from './history.actions';

export const FEATURE_STATE_NAME = 'history';

export interface HistoryState {
  previous: string | null;
  previousExcludingQueryChanges: string | null;
  current: string | null;
}

export const INITIAL_STATE: HistoryState = {
  previous: null,
  previousExcludingQueryChanges: null,
  current: null,
};

export function reducer(state: HistoryState = INITIAL_STATE, action: HistoryAction): HistoryState {
  switch (action.type) {

    case HistoryActions.setReferer.type: {
      const referer = action.referer;

      const currentWithoutQuery = state.current && state.current.split('?', 2)[0];
      const refererWithoutQuery = referer.split('?', 2)[0];
      const isQueryChangeOnly = currentWithoutQuery === refererWithoutQuery;

      return {
        ...state,
        previous: state.current,
        previousExcludingQueryChanges: isQueryChangeOnly ? state.previousExcludingQueryChanges : state.current,
        current: referer,
      };
    }

    default:
      return state;
  }
}
