import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { marketingCampaignContent, MARKETING_CAMPAIGNS } from './marketing-campaign-content';
import { MarketingCampaignService } from './marketing-campaign.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    MarketingCampaignService,
    {
      provide: MARKETING_CAMPAIGNS, useValue: marketingCampaignContent
    }
  ],
})
export class MarketingCampaignModule { }
