import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store, StoreModule } from '@ngrx/store';
import { HistoryActions } from './history.actions';
import { FEATURE_STATE_NAME, reducer } from './history.state';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
})
export class HistoryModule {
  constructor(
    @Optional() @SkipSelf() parentModule: HistoryModule,
      router: Router,
      store: Store<AppState>,
  ) {
    if (parentModule) {
      throw new Error('HistoryModule is already initialised. Initialise it only once in AppModule.');
    }

    // No need to unsubscribe as it is never destroyed
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Set the referer, so that other pages can find out who redirected to them
        store.dispatch(HistoryActions.setReferer({ referer: event.url }));
      }
    });
  }
}
