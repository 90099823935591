import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimeFromHumanReadableIntTime',
})
export class FormatTimeFromHumanReadableIntTimePipe implements PipeTransform {

  /**
   * @param humanReadableIntTime: this is a time format uses an int to express hours and minutes in a human readable way.
   * The place values for thousands and hundreds in the number express the hour and the place values for tens and units
   * express the minutes.
   * For example:
   * 1200 = "12:00" = 12 o'clock = midday
   * 345 = "03:45" = 3:45 am = quarter to four in the morning
   */
  public transform(humanReadableIntTime: number): string {
    return `${Math.floor(humanReadableIntTime / 100)}:${(`0${ humanReadableIntTime % 100 }`).slice(-2)}`;
  }

}
