import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { HeaderActions } from './header.actions';

export const FEATURE_STATE_NAME = 'header';

export interface HeaderState {
  isNavBarOpen: boolean;
}

export const INITIAL_STATE: HeaderState = {
  isNavBarOpen: false,
};

export function reducer(
  state: HeaderState = INITIAL_STATE, action: Action): HeaderState {
  switch (action.type) {

    case HeaderActions.toggleNav.type: {
      return {
        ...state,
        isNavBarOpen: !state.isNavBarOpen
      };
    }

    case ROUTER_NAVIGATED: {
      return {
        ...state,
        isNavBarOpen: false
      };
    }

    default:
      return state;
  }
}
