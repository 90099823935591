import { CustomerQualification } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const CustomerQualificationActions = {
  showQualificationModal: createAction(
    '[Customer Qualification] Show customer-qualification modal',
  ),

  hideQualificationModal: createAction(
    '[Customer Qualification] Hide customer-qualification modal',
  ),

  qualifyCustomer: createAction(
    '[Customer Qualification] Qualify customer',
    props<{ qualificationData: CustomerQualification}>(),
  ),

  customerQualified: createAction(
    '[Customer Qualification] Customer Qualified'
  )
};

const all = union(CustomerQualificationActions);
export type CustomerQualificationAction = typeof all;
