import moment, { Moment } from 'moment';
import { DeliveryWindow, OrderHumanId } from '../../order';
import { OrderId } from '../../order/order-id.model';
import { OrderStatus } from '../../order/order-status.enum';
import { createTrackedDeliveryFromJson, TrackedDelivery } from './tracked-delivery.model';
import { createTrackedLocationFromJson, TrackedLocation } from './tracked-location.model';

export interface OrderTracking {
  id: OrderId;
  humanId: OrderHumanId;
  status: OrderStatus;
  requestedDeliveryDate: Moment;
  placedDate: Moment | null;
  finalisationDate: Moment | null;
  leftKitchenDate: Moment | null;
  actualDeliveryDate: Moment | null;
  deliveryWindow: DeliveryWindow;
  location: TrackedLocation | null;
  displayDeliveryInstructions: string | null;
  deliveryContactFullName: string | null;
  deliveryContactPhoneNumber: string | null;
  trackingPhoneNumber: string | null;
  deliveryTracking: TrackedDelivery;
  vendorName: string;
  vendorLocationName: string | null;
}

export function createOrderTrackingFromJson(json?: Partial<OrderTracking>): OrderTracking {
  json = json || {};

  const order: Partial<OrderTracking> = {};

  order.id = json.id;
  order.humanId = json.humanId;
  order.status = json.status;
  order.requestedDeliveryDate = moment.tz(json.requestedDeliveryDate, 'Europe/London');
  order.deliveryWindow = {
    start: moment.tz(json.deliveryWindow.start, 'Europe/London'),
    end: moment.tz(json.deliveryWindow.end, 'Europe/London')
  };
  order.location = json.location ? createTrackedLocationFromJson(json.location) : null;
  order.displayDeliveryInstructions = json.displayDeliveryInstructions || null;
  order.deliveryContactFullName = json.deliveryContactFullName || null;
  order.deliveryContactPhoneNumber = json.deliveryContactPhoneNumber || null;
  order.trackingPhoneNumber = json.trackingPhoneNumber || null;
  order.placedDate = json.placedDate ? moment.tz(json.placedDate, 'Europe/London') : null;
  order.finalisationDate = json.finalisationDate ? moment.tz(json.finalisationDate, 'Europe/London') : null;
  order.leftKitchenDate = json.leftKitchenDate ? moment.tz(json.leftKitchenDate, 'Europe/London') : null;
  order.actualDeliveryDate = json.actualDeliveryDate ? moment.tz(json.actualDeliveryDate, 'Europe/London') : null;
  order.deliveryTracking = json.deliveryTracking ? createTrackedDeliveryFromJson(json.deliveryTracking) : null;
  order.vendorName = json.vendorName;
  order.vendorLocationName = json.vendorLocationName || null;

  return order as OrderTracking;
}
