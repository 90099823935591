import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const editIndividualChoiceMenuRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/edit/:orderGroupId/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'menu-content menu-item-image',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData => {
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.VENDORS_MENU,
        label: 'Item image clicked',
        extraFields: {
          imageCount: event.data['menu-item-image'].imageCount,
          itemType: event.data['menu-item-image'].itemType,
        },
      };
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'add-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EDIT_IC_ADD_VENDOR,
      label: 'Add vendor button clicked',
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'cancel-add-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EDIT_IC_CANCEL_ADD_VENDOR,
      label: 'Cancel vendor button clicked',
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'add-vendor-confirmation-dialog dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EDIT_IC_ADD_VENDOR_CONFIRMATION,
      label: 'Add vendor confirmation dialog - close',
    },
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/:vendorIdOrSlug/:vendorLocationSlug/menu',
    path: 'add-vendor-confirmation-dialog dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EDIT_IC_ADD_VENDOR_CONFIRMATION,
      label: 'Add vendor confirmation dialog - confirm',
    },
  },
];
