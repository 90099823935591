import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventId,
} from '@citypantry/shared-analytics';
import { environment } from '@citypantry/shared-app-config';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const DEFAULT_DEBOUNCE_TIME_MS = 600;

@Component({
  selector: 'app-quantity-picker',
  templateUrl: './quantity-picker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: QuantityPickerComponent, multi: true }
  ]
})
export class QuantityPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public inputOnly: boolean;

  @Input()
  public debounceTime: number;

  @Input()
  public analyticsEcommerceEventId: AnalyticsEcommerceEventId;

  @Output()
  public roundedDownToMaxValue: EventEmitter<void> = new EventEmitter();

  @ViewChild('quantityInput', { static: true })
  public quantityInput: ElementRef;

  public quantity: number;
  public isDisabled: boolean;

  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;

  private change: EventEmitter<number> = new EventEmitter();
  private touch: EventEmitter<void> = new EventEmitter();
  private inputValue: Subject<number>;

  // The "isDestroyed" check prevents the following error message:
  // ViewDestroyedError: Attempt to use a destroyed view: detectChanges
  // See https://stackoverflow.com/a/45602683
  private isDestroyed: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    this.inputValue = new Subject<number>();
    this.isDisabled = false;
  }

  public ngOnInit(): void {
    this.quantity = this.quantity || 0;
    this.min = this.min || 0;
    this.max = this.max || 0;
    if (this.max > 0 && this.max < this.min) {
      if (!environment.production) {
        console.warn('Quantity Picker min > max', this);
      }
    }
    this.debounceTime = typeof this.debounceTime === 'number' ? this.debounceTime : DEFAULT_DEBOUNCE_TIME_MS;
    this.inputValue.pipe(debounceTime(this.debounceTime)).subscribe((value: number) => {
      this.update(value);
      this.touch.emit();
    });
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  public writeValue(value: any): void {
    this.quantity = parseInt(value, 10) || 0; // Skip validation for the initial input,
    // to avoid triggering an output before other things have initialised
    this.quantityInput.nativeElement.value = this.quantity; // For some reason this doesn't update via ngModel so write it manually
    if (!this.isDestroyed) { // See isDestroyed for explanation
      this.cdr.detectChanges();
    }
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.change.subscribe(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this.touch.subscribe(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public increase(): void {
    const newQuantity = this.quantity + 1;

    this.update(newQuantity);
    this.touch.emit();
  }

  public decrease(): void {
    let newQuantity;
    if (this.quantity <= this.min) {
      newQuantity = 0;
    } else {
      newQuantity = this.quantity - 1;
    }
    this.update(newQuantity);
    this.touch.emit();
  }

  public filterInput(event: any): void {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public keyUp(value: string): void {
    this.inputValue.next(parseInt(value, 10));
  }

  public onBlur(newValue: string): void {
    this.update(parseInt(newValue, 10));
    this.touch.emit();
  }

  public selectAll(): void {
    if (this.quantityInput && this.quantityInput.nativeElement) {
      const element = this.quantityInput.nativeElement;
      try {
        element.select();
      } catch (e) {
        // *gulp*
        // This feature is "nice-to-have" and degrades gracefully, so we just swallow errors if there's a problem with it
      }
    }
  }

  private update(newQuantity: number): void {
    const validatedQuantity = this.getValidQuantity(newQuantity);

    if (validatedQuantity !== this.quantity || newQuantity !== validatedQuantity) {
      this.quantity = validatedQuantity;
      this.change.emit(this.quantity);

      if (this.quantityInput && this.quantityInput.nativeElement) {
        setTimeout(() => {
          this.quantityInput.nativeElement.value = `${this.quantity}`;
        });
      }
    }

    if (validatedQuantity < newQuantity) {
      this.roundedDownToMaxValue.emit();
    }
  }

  private getValidQuantity(newQuantity: number): number {
    if (typeof newQuantity !== 'number' || isNaN(newQuantity)) {
      return 0;
    }
    if (newQuantity < 0) {
      return 0;
    }
    if (this.min && newQuantity > 0 && newQuantity < this.min) {
      return this.min;
    }
    if (this.max && newQuantity > this.max) {
      return this.max;
    }
    return newQuantity;
  }
}
