import { majorCurrencyToMinor } from '../money';
import { CartNotificationType } from './cart-notification-type.enum';

export interface CartNotification {
  type: CartNotificationType;
  messageArgs: CartNotificationArgs;
}

export interface CartNotificationArgs {
  [key: string]: any;
}

export function createCartNotificationFromJson(json?: Partial<CartNotification>): CartNotification {
  json = json || {};

  const notification: Partial<CartNotification> = {};
  notification.type = json.type;
  notification.messageArgs = createMessageArgs(json.messageArgs);
  return notification as CartNotification;
}

function createMessageArgs(json?: CartNotificationArgs): CartNotificationArgs {
  json = json || {};

  const messageArgs: CartNotificationArgs = {};
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      messageArgs[key] = key === 'amount' ? majorCurrencyToMinor(json[key]) : json[key];
    }
  }
  return messageArgs;
}
