import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { GlobalDialogSettings } from './global-dialog-settings.model';
import { GlobalDialogActions } from './global-dialog.actions';
import { GlobalDialogModule } from './global-dialog.module';

@Injectable({ providedIn: GlobalDialogModule })
export class GlobalDialogService {
  constructor(
    private store: Store<AppState>
  ) {}

  public showDialog(settings: GlobalDialogSettings): void {
    this.store.dispatch(GlobalDialogActions.showDialog({ settings }));
  }
}
