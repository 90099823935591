import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search-popup-header',
  templateUrl: './search-popup-header.component.html',
  styleUrls: ['./search-popup-header.component.scss'],
})
export class SearchPopupHeaderComponent {
  @Output()
  public exploreOptionsClick: EventEmitter<void> = new EventEmitter();

  public onClickScroller(): void {
    this.exploreOptionsClick.emit();
  }
}
