export type CartNotificationType = 'extra_charge' | 'refund';

export class CartNotificationTypes {
  public static EXTRA_CHARGE: CartNotificationType = 'extra_charge';
  public static REFUND: CartNotificationType = 'refund';

  public static get values(): CartNotificationType[] {
    return [
      CartNotificationTypes.EXTRA_CHARGE,
      CartNotificationTypes.REFUND
    ];
  }
}

export type CartNotificationTypeEnum = typeof CartNotificationTypes;
