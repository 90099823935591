import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-find-new-vendor-popup',
  templateUrl: './find-new-vendor-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindNewVendorPopupComponent {

  @Input()
  public cancelledOrderIds: number[];

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  @Output()
  public openChat: EventEmitter<void> = new EventEmitter();

  public onClose(): void {
    this.close.emit();
  }

  public formatOrderIds(): string {
    if (this.cancelledOrderIds && this.cancelledOrderIds.length > 0) {
      const orderIds = this.cancelledOrderIds.map((id) => `#${id}`).join(', ');
      return `(${orderIds})`;
    }
    return '';
  }
}
