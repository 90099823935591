import { Pipe, PipeTransform } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { ItemDeliverabilityProblem, ItemDeliverabilityProblems } from '@citypantry/util-models';

@Pipe({
  name: 'translateDeliverabilityProblemToMessage'
})
export class TranslateDeliverabilityProblemToMessagePipe implements PipeTransform {

  public transform(problem: ItemDeliverabilityProblem): string | null {
    switch (problem) {
      case ItemDeliverabilityProblems.MORE_NOTICE_REQUIRED:
      case ItemDeliverabilityProblems.CHOICE_DEADLINE_PAST_NOTICE:
        return `The restaurant needs more notice to prepare this item.`;
      case ItemDeliverabilityProblems.OUTSIDE_DELIVERY_RADIUS:
      case ItemDeliverabilityProblems.NOT_IN_OPERATIONAL_REGION:
        return `Your delivery postcode is out of range for this item.`;
      case ItemDeliverabilityProblems.OUTSIDE_DELIVERY_TIME:
        return `This item can't be delivered at your chosen time`;
      case ItemDeliverabilityProblems.NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION:
        // This message will never be rendered as items having this problem will never be shown on a MKP menu, even for staff users
        return `This item is not available from the selected restaurant's menu.`;
      default:
        throw new UnreachableCaseError(problem);
    }
  }
}
