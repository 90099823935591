import { ErrorCodes } from '../error/error-code.enum';
import { ErrorMessage } from '../error/error-response.model';
import { DeliverableMenuErrorReason } from './deliverable-menu-error-reason.enum';

export interface DeliverableMenuError extends ErrorMessage {
  key: typeof ErrorCodes.MENU_NOT_FOUND | typeof ErrorCodes.VENDOR_NOT_FOUND;
  messageArgs: {
    vendorName: string;
    reason: DeliverableMenuErrorReason;
    deliveryDate: string; // ISO string
    vendorHeroImageUrl: string;
    vendorAvatarUrl: string;
  };
}

export function isDeliverableMenuError(error: any): error is DeliverableMenuError {
  if (!error) {
    return false;
  }
  const deliverableError = error as DeliverableMenuError;
  return deliverableError.key === ErrorCodes.MENU_NOT_FOUND || deliverableError.key === ErrorCodes.VENDOR_NOT_FOUND;
}
