import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { FileUploader } from 'ng2-file-upload';
import { ItemUploader } from './item-uploader.class';

@Injectable()
export class FileUploaderFactory {

  constructor(
    private appConfig: AppConfig
  ) {}

  public getFileUploader(): FileUploader {
    return new FileUploader({
      url: `${ this.appConfig.API_BASE }/files`,
      autoUpload: true
    });
  }

  public getItemUploader<T>(
    transformer: (data: any) => T
  ): ItemUploader<T> {
    const fileUploader = this.getFileUploader();

    return new ItemUploader(fileUploader, transformer);
  }
}
