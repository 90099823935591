import { createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppStateSelector } from '../app.state';
import { StoreInjector } from './store-injector';

/**
 * Bind a selector to this property which selects from the global NgRX store.
 *
 * @see /docs/01-project-structure/02-ngrx/selectors.md
 *
 * @example
 * @Select(CartSelectors.getTotal)
 * public cartTotal$: Observable<number>
 *
 * // equivalent to:
 * this.cartTotal$ = this.store.select(CartSelectors.getTotal);
 */
/* eslint-disable-next-line @typescript-eslint/naming-convention */ // decorators should be PascalCase
export function Select<T>(selector: AppStateSelector<T>): PropertyDecorator;
/**
 * Bind a selector to this property which selects from a sub-NgRX store.
 *
 * @see /docs/01-project-structure/02-ngrx/selectors.md
 * @deprecated sub-store selectors should not be used any more; this method is provided for compatibility with old selectors.
 *
 * @example
 * @Select(getPublicState, getSearchRequest)
 * public searchRequest$: Observable<SearchRequest>
 *
 * // equivalent to:
 * this.searchRequest$ = this.publicStore.select(getSearchRequest);
 */
/* eslint-disable-next-line @typescript-eslint/naming-convention */ // decorators should be PascalCase
export function Select<T, U>(storeSelector: AppStateSelector<T>, selector: (state: T) => U): PropertyDecorator;
/* eslint-disable-next-line @typescript-eslint/naming-convention */ // decorators should be PascalCase
export function Select<T, U>(baseSelector: AppStateSelector<T>, childSelector?: (state: T) => U): PropertyDecorator {
  const selector: AppStateSelector<any> = childSelector ? createSelector(baseSelector, childSelector) : baseSelector;

  return (target, property) => {
    Object.defineProperty(target, property, {
      get: (): Observable<T> => StoreInjector.store.select(selector),
    });
  };
}
