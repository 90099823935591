import { Injectable } from '@angular/core';
import { RecommendationApi } from '@citypantry/shared-api';
import { AppState, getPublicState, RecommendationAction, RecommendationActions, RecommendationSelectors } from '@citypantry/state';
import { getCart, getSearchRequest, PublicAction, PublicActions } from '@citypantry/state-public';
import { concat } from '@citypantry/util';
import {
  createRecommendationRequestFromSearchRequest,
  SearchResult,
  StaticRecommendedVendor,
  StaticRecommendedVendors
} from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { StaticRecommendedVendorsService } from './static-recommended-vendors/static-recommended-vendors.service';

@Injectable()
export class RecommendationEffects {

  public updateRecommendationRequest: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.createNewCart.type,
      PublicActions.submitCartChanges.type
    ),
    withLatestFrom(
      this.store.select(getPublicState).pipe(select(getSearchRequest)),
      this.store.select(getPublicState).pipe(select(getCart))
    ),
    switchMap(([action, searchRequest, cart]) => {
      const recommendationRequest = createRecommendationRequestFromSearchRequest(searchRequest, cart.vendorId);

      if (action.type === PublicActions.createNewCart.type) {
        recommendationRequest.date = action.deliveryDate;
        recommendationRequest.excludeVendorId = action.vendorId;
      }

      // When a user updates their cart, we will need to override the delivery date + postcode values from current the SearchRequest
      if (action.type === PublicActions.submitCartChanges.type) {
        recommendationRequest.date = cart.deliveryDate;
        recommendationRequest.postcode = cart.postcode;
      }

      return of(RecommendationActions.setRecommendationRequest({ recommendationRequest }));
    })
  ));

  public fetchRecommendedVendors$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(RecommendationActions.setRecommendationRequest.type),
    withLatestFrom(this.store.select(RecommendationSelectors.getRecommendationRequest)),
    switchMap(([, recommendationRequest]) => {
      return this.recommendationApi.getRecommendations(recommendationRequest)
        .pipe(map((results: any): Action => {
          return RecommendationActions.recommendedVendorsLoaded({ recommendedVendors: results });
        }));
    })
  ));

  public loadStaticRecommendedVendors$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(RecommendationActions.loadStaticRecommendedVendors.type),
    switchMap(() => {
      const staticRecommendedVendorRequests = this.staticRecommendedVendorsService.getStaticRecommendedVendorRequests();
      const staticRecommendedVendorApiRequests = staticRecommendedVendorRequests.map(
        (recommendedVendorRequest) => this.recommendationApi.getRecommendations(recommendedVendorRequest)
      );

      return forkJoin(staticRecommendedVendorApiRequests);
    }),
    switchMap((recommendedVendors: SearchResult[][]) => {
      const filteredVendors = recommendedVendors.reduce(concat()).filter((recommendedVendor: SearchResult) => {
        return (StaticRecommendedVendors.values).includes(recommendedVendor.vendorName as StaticRecommendedVendor);
      });

      return of(RecommendationActions.recommendedVendorsLoaded({ recommendedVendors: filteredVendors }));
    })
  ));

  constructor(
    private action$: Actions<PublicAction | RecommendationAction>,
    private store: Store<AppState>,
    private recommendationApi: RecommendationApi,
    private staticRecommendedVendorsService: StaticRecommendedVendorsService
  ) {}
}
