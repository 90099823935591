import { Injectable } from '@angular/core';
import { SearchApi } from '@citypantry/shared-api';
import { AppState, getPublicState, SearchRecommendationActions, SearchSelectors } from '@citypantry/state';
import { getSearchRequest, PublicActions } from '@citypantry/state-public';
import { CategorisedSearchRequest, CategoryPageRequest, SearchResultsCategories } from '@citypantry/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class SearchRecommendationEffects {

  public updateOnSearch$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      PublicActions.search.type,
    ),
    withLatestFrom(
      this.store.select(SearchSelectors.hasAdditionalNonICFilters),
      this.store.select(createSelector(getPublicState, getSearchRequest)),
    ),
    filter(([, hasAdditionalNonICFilters]) => !hasAdditionalNonICFilters),
    switchMap(([, , searchRequest]) => {
      const pages: CategoryPageRequest[] = [{
        category: SearchResultsCategories.RECOMMENDED,
        page: 1,
        pageSize: 3
      }];

      const request: CategorisedSearchRequest = {
        date: searchRequest.date,
        postcode: searchRequest.postcode,
        pages
      };

      return of(SearchRecommendationActions.loadSearchRecommendationResults({ request }));
    })
  ));

  public loadSearchResults$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(SearchRecommendationActions.loadSearchRecommendationResults.type),
    switchMap(({ request }) => {
      return this.searchApi.searchByCategory(request).pipe(
        map((results) => SearchRecommendationActions.searchRecommendationsResultsLoaded({results}))
      );
    })
  ));

  constructor(
    private action$: Actions,
    private store: Store<AppState>,
    private searchApi: SearchApi,
  ) {}
}
