import { HttpErrorResponse } from '@angular/common/http';

interface JsonApiInfo {
  version: string;
}

export interface JsonApiResource<S, T extends { id: any }> {
  id: T['id'];
  type: S;
  attributes: Omit<T, 'id'>;
  meta?: Record<string, any>;
}

export interface JsonApiDataDocument<S, T extends { id: any }> extends JsonApiDocument {
  data: JsonApiResource<S, T>;
}

interface JsonApiError {
  id?: string;
  status?: string;
  code?: string;
  title?: string;
  detail?: string;
  // Unimplemented properties:
  // links: JsonApiLinks | null;
  // source: JsonApiSource | null;
  // meta: JsonApiMeta | null;
}

export interface JsonApiErrorsDocument extends JsonApiDocument {
  errors: JsonApiError[];
}

export class JsonApiErrorResponse {
  public errors: JsonApiError[];

  constructor(
    public response: HttpErrorResponse
  ) {
    this.errors = (response.error as JsonApiErrorsDocument).errors;
  }
}

interface JsonApiDocument {
  jsonapi: JsonApiInfo;
}

export function isJsonApiErrorsDocument(body: any | null): body is JsonApiErrorsDocument {
  return (
    body && typeof body === 'object'
    && body.jsonapi && body.errors
  );
}
