import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ScrollHandleDirective } from '@citypantry/components-scroll';
import { StickyState } from '@citypantry/components-sticky';
import { AnalyticsEcommerceEventIdEnum, AnalyticsEcommerceEventIds } from '@citypantry/shared-analytics';
import { computeMenuDietaries } from '@citypantry/state';
import { UnreachableCaseError } from '@citypantry/util';
import {
  CartItem,
  CartItemAdjustment,
  CustomItem,
  Dietaries,
  DietaryExceptNone,
  EMPTY_DIETARIES,
  Item,
  ItemBundle,
  ItemDeliverability,
  ItemDeliverabilityProblem,
  ItemId,
  MenuContent,
  Section,
  ServingStyles,
  SingleItem,
  getItemDeliverabilityProblems,
  isCustomItem,
  isItemBundle,
  isSingleItem
} from '@citypantry/util-models';
import { SetSingleItemQuantityEvent } from '../menu/menu.component';

// We need to re-trigger on push even if the same item is loaded so we wrap it in an object each time
export interface ScrollToItemIdPush {
  itemId: ItemId;
}

@Component({
  selector: 'app-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuContentComponent implements AfterViewInit {

  @Input()
  public set menuContent(value: MenuContent) {
    this._menuContent = value;
    this.dietaries = value ? computeMenuDietaries(value) : EMPTY_DIETARIES;
    if (!this.activeSectionTitle) {
      this.activeSectionTitle = this._menuContent && this._menuContent.sections && this._menuContent.sections[0].title;
    }

    if (this._sectionSlug) {
      this.onSectionSlugChanged();
    }
  }

  public get menuContent(): MenuContent {
    return this._menuContent;
  }

  @Input()
  public set itemIdToScrollTo(scrollToItemIdPush: ScrollToItemIdPush | null) {
    if (scrollToItemIdPush && scrollToItemIdPush.itemId) {
      this.scrollToItemId(scrollToItemIdPush.itemId);
    }
  }

  @Input()
  public cartItems: CartItem[];

  @Input()
  public allowAddingNonDeliverableItems: boolean;

  @Input()
  public deliverability: ItemDeliverability[];

  @Input()
  public enableSelectingItems: boolean;

  @Input()
  public showHiddenSections: boolean;

  @Input()
  public set sectionSlug(slug: string) {
    if (slug && slug !== this._sectionSlug) {
      this._sectionSlug = slug;
      // Don't call if it hasn't changed, since it might just be synchronising with our output
      this.onSectionSlugChanged();
    }
  }

  @Input()
  public url: string;

  @Input()
  public actionStyle: 'default' | 'none'; // TODO When we simplify eater menus, add 'simple' as an option

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public filteredDietaries: DietaryExceptNone[];

  @Input()
  public autoScrollToFirstPopUp: boolean;

  @Input()
  public showFavouriteButton: boolean;

  @Input()
  public isFavourited: boolean | undefined;

  @Input()
  public hidePrices: boolean = false;

  @Output()
  public toggleFavourite: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public setSingleItemQuantity: EventEmitter<SetSingleItemQuantityEvent> = new EventEmitter();

  @Output()
  public displayItemBundle: EventEmitter<ItemBundle> = new EventEmitter();

  @Output()
  public displayCustomItem: EventEmitter<CustomItem> = new EventEmitter();

  @Output()
  public toggleSelection: EventEmitter<ItemId[]> = new EventEmitter();

  @Output()
  public scrollToItemFinished: EventEmitter<void> = new EventEmitter();

  @Output()
  public itemQuantityAdjusted: EventEmitter<CartItemAdjustment> = new EventEmitter();

  @ViewChildren(ScrollHandleDirective)
  public scrollHandles: QueryList<ScrollHandleDirective>;

  @ViewChild('tabs', { read: ElementRef, static: false })
  public set tabs(element: ElementRef) {
    setTimeout(() => this.tabsRef = element);
  }

  public dietaries: Dietaries;
  public activeSectionTitle: string;
  public tabsRef: ElementRef;
  public tabsAreStuck: boolean;

  /* eslint-disable @typescript-eslint/typedef */ // These are okay to be inferred. We don't want to redeclare them
  public isCustomItem = isCustomItem;
  public isItemBundle = isItemBundle;
  public isSingleItem = isSingleItem;
  /* eslint-enable */

  public AnalyticsEcommerceEventIds: AnalyticsEcommerceEventIdEnum = AnalyticsEcommerceEventIds;
  public displayedSingleItem: SingleItem | null = null;

  private _menuContent: MenuContent;
  private _sectionSlug: string | null;
  private sectionSlugCache: { [title: string]: string };

  constructor() {
    this._sectionSlug = null;
    this.sectionSlugCache = {};
    this.url = '';
    this.filteredDietaries = [];
    this.showFavouriteButton = false;
  }

  public ngAfterViewInit(): void {
    if (this.autoScrollToFirstPopUp && this.sectionsContainingPopUps.length > 0) {
      setTimeout(() => {
        this.scrollToMenuSection(this.sectionsContainingPopUps[0], false);
      });
    }

    this.onSectionSlugChanged();
  }

  public onTabsStuck(stickyState: StickyState): void {
    this.tabsAreStuck = stickyState !== 'none';
  }

  public get allowedSections(): Section[] {
    if (!this.menuContent || !this.menuContent.sections) {
      return [];
    }
    if (this.showHiddenSections) {
      return this.menuContent.sections;
    }
    return this.menuContent.sections.filter((section) => !section.hidden);
  }

  public get filteredSections(): Section[] {
    return this.allowedSections.filter(
      (section) => section.items.some((item) => this.doesItemMatchFilters(item)),
    );
  }

  public get sectionsContainingPopUps(): Section[] {
    return this.allowedSections.filter((section) => {
      const popUpItems =  section.items.filter((item: Item) => item.servingStyle === ServingStyles.POPUP);
      return popUpItems.length > 0;
    });
  }

  public get displayMenu(): boolean {
    return !this.filteredDietaries.length || !!this.filteredSections.length;
  }

  public onSetSingleItemQuantity(item: SingleItem, quantity: number): void {
    if (this.isItemDisabled(item.id)) {
      return;
    }
    const cartIndex = this.cartItems && this.cartItems.findIndex((cartItem) => cartItem.item.id === item.id);
    this.setSingleItemQuantity.emit({
      item,
      cartIndex: cartIndex > -1 ? cartIndex : null,
      quantity,
    });
  }

  public onDisplayItemBundle(itemBundle: ItemBundle): void {
    if (this.isItemDisabled(itemBundle.id)) {
      return;
    }
    this.displayItemBundle.emit(itemBundle);
  }

  public onDisplayCustomItem(item: CustomItem): void {
    if (this.isItemDisabled(item.id)) {
      return;
    }
    this.displayCustomItem.emit(item);
  }

  public onDisplaySingleItem(item: SingleItem): void {
    this.displayedSingleItem = item;
  }

  public onHideSingleItem(): void {
    this.displayedSingleItem = null;
  }

  public getCartQuantity(item: Item): number | null {
    if (!this.cartItems || !this.cartItems.length) {
      return null;
    }

    const cartItem: CartItem = this.cartItems.find((_cartItem: CartItem) => _cartItem.item.id === item.id);
    if (cartItem) {
      return cartItem.quantity;
    } else {
      return 0;
    }
  }

  public getDeliverabilityProblems(itemId: ItemId): ItemDeliverabilityProblem[] {
    return getItemDeliverabilityProblems(itemId, this.deliverability || []);
  }

  public getNonOverridableItemProblems(itemId: ItemId): ItemDeliverabilityProblem[] {
    if (this.allowAddingNonDeliverableItems) {
      return [];
    } else {
      return this.getDeliverabilityProblems(itemId);
    }
  }

  public showAllergenWarning(): boolean {
    return !!this.allowedSections.find((section) => !!section.items.find(allergensNotProvided));
  }

  public showDisclaimer(): boolean {
    return this.menuContent && !!this.menuContent.disclaimer;
  }

  public scrollToHandle(name: string, smoothScroll?: boolean): void {
    if (!this.scrollHandles) {
      return;
    }

    const handle = this.scrollHandles.find((potentialHandle) => potentialHandle.scrollHandle === name);

    if (handle) {
      handle.scrollIntoView({ block: 'start', behavior: smoothScroll ? 'smooth' : 'auto' });
    }
  }

  public scrollToMenuSection(section: Section, smoothScroll?: boolean, $event?: Event): void {
    if ($event) {
      $event.preventDefault();
    }

    const slug = this.getSlugForSection(section);

    this.scrollToHandle(slug, smoothScroll);
  }

  public scrollToItemRow(itemId: ItemId, smoothScroll?: boolean, $event?: Event): void {
    if ($event) {
      $event.preventDefault();
    }

    this.scrollToHandle(itemId, smoothScroll);
  }

  public onScrolledIntoSection(section: Section): void {
    setTimeout(() => {
      this.activeSectionTitle = section.title;
      this._sectionSlug = this.getSlugForSection(section);
    });
  }

  public getSlugForSection(section: Section): string {
    if (!section) {
      return '';
    }
    if (!this.sectionSlugCache[section.title]) {
      this.sectionSlugCache[section.title] = this.convertToSlug(section.title);
    }
    return this.sectionSlugCache[section.title];
  }

  public onToggleSection(items: Item[]): void {
    this.toggleItemsSelection(items.map((item: Item) => item.id));
  }

  public onToggleItemSelection(id: ItemId): void {
    this.toggleItemsSelection([id]);
  }

  public isSectionSelectable(section: Section): boolean {
    return section.items.some((item) => item.selectable);
  }

  public trackSectionByTitle(section: Section): string {
    return `${section.title}`;
  }

  public trackItemsById(item: Item): string {
    return `${item.id}`;
  }

  public getAllowedItems(section: Section): Item[] {
    return section.items.filter((item) => !item.hidden);
  }

  public doesItemMatchFilters(item: Item): boolean {
    return !this.filteredDietaries.length || this.itemContainsDietaries(item);
  }

  public onToggleFavourite(): void {
    this.toggleFavourite.emit(!this.isFavourited);
  }

  public onSingleItemQuantityRoundedDown(itemIndex: number, maximumQuantity: number): void {
    this.itemQuantityAdjusted.emit({
      message: `You can't order more than ${maximumQuantity}`,
      itemIndex
    });
  }

  private itemContainsDietaries(item: Item): boolean {
    const itemDietaries = isCustomItem(item) ? item.possibleDietaries : item.dietaries;
    return this.filteredDietaries.every((dietary) => itemDietaries[dietary]);
  }

  private toggleItemsSelection(items: ItemId[]): void {
    this.toggleSelection.emit(items);
  }

  private onSectionSlugChanged(): void {
    if (!this.menuContent || !this._sectionSlug) {
      return;
    }
    const section = this.getSectionBySlug(this._sectionSlug);
    if (section && section.title !== this.activeSectionTitle) {
      this.scrollToMenuSection(section, true);
    }
  }

  private scrollToItemId(itemId: ItemId): void {
    if (!this.menuContent || !itemId) {
      return;
    }
    const targetItem = this.getItemById(itemId);
    if (targetItem) {
      this.scrollToItemRow(itemId, true);
    }
  }

  private convertToSlug(value: string): string {
    return (value || '')
      .toLocaleLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');
  }

  private getItemById(id: string): Item | null {
    const allItems: Item[] = [];

    this.menuContent.sections.forEach((section: Section) => {
      section.items.forEach((item: Item) => {
        allItems.push(item);
      });
    });

    for (const item of allItems) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  }

  private getSectionBySlug(slug: string): Section | null {
    slug = this.convertToSlug(slug);
    for (const section of this.menuContent.sections) {
      if (this.convertToSlug(section.title) === slug) {
        return section;
      }
    }
    return null;
  }

  private isItemDisabled(itemId: ItemId): boolean {
    const hasProblems = this.getDeliverabilityProblems(itemId).length > 0;
    return hasProblems && !this.allowAddingNonDeliverableItems;
  }
}

function allergensNotProvided(item: SingleItem | ItemBundle | CustomItem): boolean {
  if (isCustomItem(item)) {
    if (item.allergens.notProvided) {
      return true;
    }

    return item.sections.some(
      (customItemSection) => customItemSection.options.some(
        (customItemOption) => customItemOption.allergens.notProvided
      )
    );
  } else if (isSingleItem(item)) {
    return item.allergens.notProvided;
  } else if (isItemBundle(item)) {
    return !!item.groups.find((group) => !!group.items.find(allergensNotProvided));
  }
  throw new UnreachableCaseError(item);
}
