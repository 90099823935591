import { Pipe, PipeTransform } from '@angular/core';
import { Allergen, Allergens } from '@citypantry/util-models';

@Pipe({
  name: 'allergenName'
})
export class AllergenNamePipe implements PipeTransform {
  private static DESCRIPTORS: { [key in keyof Allergens]: string } = {
    gluten:         'Cereals containing gluten',
    crustaceans:    'Crustaceans, for example prawns, crabs, lobster and crayfish',
    eggs:           'Eggs',
    fish:           'Fish',
    peanuts:        'Peanuts',
    soybeans:       'Soy beans',
    milk:           'Milk',
    nuts:           'Nuts, such as almonds, hazelnuts, walnuts, pecan nuts, Brazil nuts, pistachio, cashew and macadamia (Queensland) nuts',
    celery:         'Celery (and celeriac)',
    mustard:        'Mustard',
    sesame:         'Sesame',
    sulphurDioxide: 'Sulphur dioxide, which is a preservative found in some dried fruit',
    lupin:          'Lupin',
    molluscs:       'Molluscs, for example clams, mussels, whelks, oysters, snails and squid',
    none:           'None',
    notProvided:    'Not provided',
  };

  private static SHORT_NAMES: { [key in keyof Allergens]?: string } = {
    gluten:         'Gluten',
    crustaceans:    'Crustaceans',
    nuts:           'Nuts',
    celery:         'Celery',
    mustard:        'Mustard',
    sesame:         'Sesame',
    sulphurDioxide: 'Sulphur dioxide',
    molluscs:       'Molluscs',
  };

  public transform(value: Allergen, displayNone: boolean = false, useShortName: boolean = false): string {
    if (!displayNone && (value === 'none' || value === 'notProvided')) {
      return null;
    }
    if (!AllergenNamePipe.DESCRIPTORS.hasOwnProperty(value)) {
      throw new Error(`Allergen '${value}' does not have a known descriptor`);
    }
    if (useShortName && AllergenNamePipe.SHORT_NAMES[value]) {
      return AllergenNamePipe.SHORT_NAMES[value];
    }
    return AllergenNamePipe.DESCRIPTORS[value];
  }
}
