// This model contains a subset of the properties that can be supplied to braintree when doing a 3D Secure verification.
// 'amount' is the only required property, but supplying more information will
// "minimize the need for issuing banks to present authentication challenges to customers"
// https://braintree.github.io/braintree-web/current/ThreeDSecure.html#verifyCard
import { MinorCurrency, minorCurrencyToMajor } from '../money';
import { CustomerLocation } from '../customer';

export interface ThreeDSecureCustomerParameters {
  amount: string;
  email?: string;
  additionalInformation?: {
    shippingGivenName?: string;
    shippingSurname?: string;
    shippingAddress?: ThreeDSecureAddress;
  };
}

export interface ThreeDSecureAddress {
  streetAddress?: string;
  extendedAddress?: string;
  line3?: string;
  locality?: string;
  postalCode?: string;
}

export function constructThreeDSecureParameters(
  totalCost: MinorCurrency,
  userEmail: string,
  location: CustomerLocation
): ThreeDSecureCustomerParameters {
  return {
    amount: minorCurrencyToMajor(totalCost).toString(),
    email: userEmail,
    additionalInformation: {
      shippingGivenName: location.defaultDeliveryContact.firstName,
      shippingSurname: location.defaultDeliveryContact.lastName,
      shippingAddress: {
        streetAddress: location.addressLine1,
        extendedAddress: location.addressLine2,
        line3: location.addressLine3,
        locality: location.city,
        postalCode: location.postcode,
      }
    }
  };
}
