import { FormControl, ValidatorFn } from '@angular/forms';

export const every: (validator: ValidatorFn) => ValidatorFn =
  (validator) =>
    ({ value }) => {
      if (!Array.isArray(value)) {
        throw new Error(
          `"every" validator should only be used for form controls with array values. "${value}" given.`,
        );
      }

      return value
        .map((v) => validator(new FormControl(v)))
        .find(Boolean) || null;
    };
