import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@citypantry/components-navigation';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { ChatWidgetService } from '@citypantry/shared-chat-widget';
import {
  MarketingCampaignComponentModel,
  MarketingCampaignComponentNames,
  MarketingCampaignService
} from '@citypantry/shared-marketing-campaign';
import { PromotionBannerModel, PromotionService } from '@citypantry/shared-promotion';
import {
  AppState,
  Dispatch,
  FavouriteVendorsActions,
  FavouriteVendorsSelectors,
  IndividualChoiceSetupActions,
  MealPlanSelectors,
  RouterActions,
  SearchRecommendationSelectors,
  SearchSelectors,
  Select
} from '@citypantry/state';
import { PublicActions } from '@citypantry/state-public';
import { SearchQueries } from '@citypantry/state-queries';
import {
  createURLQueryFromSearchRequest,
  CustomerLocation,
  HubspotFormIdEnum,
  HubspotFormIds,
  MealPlan,
  PromotionComponents,
  PromotionName,
  SearchLocation,
  SearchOrderTypes,
  SearchPromoCardModel,
  SearchRequest,
  SearchResult,
  SearchSortType,
  VendorFlag,
  VendorFlags,
  VendorId
} from '@citypantry/util-models';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { SearchBarParameters } from '../search-bar/search-bar.component';

export const SEARCH_DEBOUNCE_TIME_MS = 600;

@Component({
  selector: 'app-search',
  template: `
    <app-marketing-campaign-banner
      *ngIf="skinnyBannerMarketingCampaignComponentModel; else promoBanner"
      [campaign]="skinnyBannerMarketingCampaignComponentModel"
      test-id="marketingCampaignBanner"
    ></app-marketing-campaign-banner>
    <ng-template #promoBanner>
      <app-promotion-banner
        [promotion]="bannerPromotion$ | async"
        [animateIn]="animateInPromotionBanner$ | async"
        [transparent]="false"
        (tagAnimatedIn)="trackPromotionBannerAnimatedIn($event)"
        test-id="promoBanner"
      ></app-promotion-banner>
    </ng-template>
    <app-search-page
      [request]="request$ | async"
      [results]="results$ | async"
      [recommendedResults]="recommendedResults$ | async"
      [showRecommendations]="(hasAdditionalFilters$ | async) === false"
      [showDisruptionBanner]="isDeliveryDisruptionActive$ | async"
      [resultsTotal]="resultsTotal$ | async"
      [isSearching]="isSearching$ | async"
      [breadcrumbs]="breadcrumbs$ | async"
      [showDistance]="isCustomerOrStaffOrSudo$ | async"
      [showExactRemainingCapacity]="isStaffOrSudo$ | async"
      [showPlaceholders]="(hasRequiredSearchParams$ | async) === false"
      [searchCardPromotion]="searchCardPromotion$ | async"
      [mealPlan]="mealPlan$ | async"
      [proposedOrderId]="proposedOrderId$ | async"
      [popUpMode]="isPopUpMode$ | async"
      [hidePromoCard]="hidePromoCard$ | async"
      [showFavouriteButtons]="true"
      [favouritedVendorIds]="favouritedVendorIds$ | async"
      [noSearchResultsMarketingCampaignComponentModel]="noSearchResultsMarketingCampaignComponentModel"
      (addFavouriteVendor)="onAddFavouriteVendor($event)"
      (removeFavouriteVendor)="onRemoveFavouriteVendor($event)"
      (loadNextPage)="loadNextPage()"
      (promoCardClick)="handlePromoCardClick($event)"
      (promoCardClose)="trackPromoCardWasClosed($event)"
      (openZopimChat)="onOpenZopimChat()"
      analyticsId="search-page"
      test-id="searchPage"
    >
      <app-search-bar
        [hidden]="(hasRequiredSearchParams$ | async) === false"
        [request]="request$ | async"
        [locked]="hasMealPlanRequirements$ | async"
        [locations]="customerLocations$ | async"
        [popUpMode]="isPopUpMode$ | async"
        [canEnterPostcode]="canEnterPostcode$ | async"
        (paramsChanged)="onParamsChanged($event)"
        (searchChanged)="onSearchChanged($event)"
        test-id="searchBar"
      ></app-search-bar>
      <app-search-filters-form
        [request]="request$ | async"
        [isIndividualChoiceEnabled]="isEligibleForIndividualChoice$ | async"
        [isWeWorkUser]="isWeWorkUser$ | async"
        [isWeWorkModeEnabled]="isWeWorkModeEnabled$ | async"
        (requestChanged)="searchWithDebounce($event)"
        (selectIndividualChoice)="onIndividualChoiceSelected()"
        (changeWeWorkMode)="changeWeWorkMode($event)"
        analyticsId="filters-form"
        test-id="filters"
      ></app-search-filters-form>
      <app-search-filters-toggles class="search-filters__toggles">
        <app-search-filters-eco-friendly-packaging-toggle
          *ngIf="this.isLoggedInUser$ | async"
          [searchRequest]="request$ | async"
          (enabledChange)="onEcoFriendlyPackagingFilterChanged($event)"
          class="mb-small mb-md-none mr-md-standard"
          test-id="ecoFriendlyPackagingFilter"
        ></app-search-filters-eco-friendly-packaging-toggle>
        <app-search-filters-favourites-toggle
          *ngIf="this.isLoggedInUser$ | async"
          [enabled]="(request$ | async).favourites"
          (enabledChange)="onFavouritesFilterChanged($event)"
          class="mb-small mb-md-none mr-md-standard"
          test-id="favouritesFilter"
        ></app-search-filters-favourites-toggle>
      </app-search-filters-toggles>
      <app-search-sort-dropdown
        class="search-filters__sort search-filters-bar-layout__sort"
        [sortBy]="(request$ | async)?.sortBy"
        [showDistanceOption]="true"
        (sortByChange)="onSortByChanged($event)"
        test-id="sort"
      ></app-search-sort-dropdown>
    </app-search-page>
    <app-search-parameters-popup
      *ngIf="(hasRequiredSearchParams$ | async) === false"
      [autocomplete]="autocomplete"
      [canEnterPostcode]="canEnterPostcode$ | async"
      test-id="searchParametersPopup"
    ></app-search-parameters-popup>
    <app-hubspot-modal
      [formId]="HubspotFormIds.OUT_OF_REGION_CAPTURE"
      [visible]="showOutOfRegionCaptureModal$ | async"
      [autoPopulateInputs]="{ out_of_region_postcode: (request$ | async).postcode }"
      [showLabels]="false"
      [showErrorRollup]="false"
      (formSubmitted)="submitOutOfRegionCaptureModal()"
      (hide)="closeOutOfRegionCaptureModal()"
      test-id="outOfRegionCaptureModal"
    ></app-hubspot-modal>
    <app-find-new-vendor-popup
      *ngIf="showFindNewVendorPopup$ | async"
      [cancelledOrderIds]="cancelledOrderIds$ | async"
      (close)="closeFindNewVendorPopup()"
      (openChat)="onOpenZopimChat()"
      test-id="findNewVendorPopup"
    >
    </app-find-new-vendor-popup>
  `,
})
export class SearchComponent implements OnInit {
  @Select(SearchRecommendationSelectors.getResults)
  public recommendedResults$: Observable<SearchResult[]>;

  @Select(FavouriteVendorsSelectors.getFavouritedVendorIds)
  public favouritedVendorIds$: Observable<VendorId[]>;

  @Select(SearchSelectors.isWeWorkModeEnabled)
  public isWeWorkModeEnabled$: Observable<boolean>;

  @Select(SearchSelectors.hasAdditionalNonICFilters)
  public hasAdditionalFilters$: Observable<boolean>;

  @Select(SearchSelectors.isDeliveryDisruptionActive)
  public isDeliveryDisruptionActive$: Observable<boolean>;

  @Select(SearchSelectors.getSearchRequest)
  public request$: Observable<SearchRequest>;

  @Select(SearchSelectors.getSearchResults)
  public results$: Observable<SearchResult[]>;

  @Select(SearchSelectors.getSearchResultsTotal)
  public resultsTotal$: Observable<number>;

  @Select(MealPlanSelectors.getMealPlan)
  public mealPlan$: Observable<MealPlan | null>;

  @Select(MealPlanSelectors.getProposedOrderId)
  public proposedOrderId$: Observable<string | null>;

  @Select(SearchSelectors.isSearchPromoCardHidden)
  public hidePromoCard$: Observable<boolean>;

  @Select(SearchSelectors.showOutOfRegionCaptureModal)
  public showOutOfRegionCaptureModal$: Observable<boolean>;

  @Select(SearchSelectors.showFindNewVendorPopup)
  public showFindNewVendorPopup$: Observable<boolean>;

  @Select(SearchSelectors.cancelledOrderIds)
  public cancelledOrderIds$: Observable<number[]>;

  @Select(AuthSelectors.customer.canEnterPostcode)
  public canEnterPostcode$: Observable<boolean>;

  public hasMealPlanRequirements$: Observable<boolean>;

  @Select(AuthSelectors.isLoggedInUser)
  public isLoggedInUser$: Observable<boolean>;

  public isSearching$: Observable<boolean>;
  public breadcrumbs$: Observable<Breadcrumb[]>;

  @Select(AuthSelectors.customer.getLocations)
  public customerLocations$: Observable<CustomerLocation[]>;
  public hasRequiredSearchParams$: Observable<boolean>;

  @Select(AuthSelectors.customer.isEligibleForIndividualChoice)
  public isEligibleForIndividualChoice$: Observable<boolean>;
  public bannerPromotion$: Observable<PromotionBannerModel>;
  public searchCardPromotion$: Observable<SearchPromoCardModel>;
  public animateInPromotionBanner$: Observable<boolean>;

  @Select(AuthSelectors.isWeWorkUser)
  public isWeWorkUser$: Observable<boolean>;
  public isPopUpMode$: Observable<boolean>;

  @Select(AuthSelectors.isCustomerOrStaffOrSudo)
  public isCustomerOrStaffOrSudo$: Observable<boolean>;

  @Select(AuthSelectors.isStaffOrSudo)
  public isStaffOrSudo$: Observable<boolean>;

  public HubspotFormIds: HubspotFormIdEnum = HubspotFormIds;
  public autocomplete: boolean;
  public noSearchResultsMarketingCampaignComponentModel: MarketingCampaignComponentModel | null;
  public skinnyBannerMarketingCampaignComponentModel: MarketingCampaignComponentModel | null;

  private searchSubject: Subject<SearchRequest>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private searchQueries: SearchQueries,
    private promotionService: PromotionService,
    private chatWidgetService: ChatWidgetService,
    private appConfig: AppConfig,
    private marketingCampaignService: MarketingCampaignService,
  ) {
    this.searchSubject = new Subject();
    this.autocomplete = this.appConfig.ADDRESS_AUTOCOMPLETE_ENABLED;
  }

  public ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME_MS)
    ).subscribe((request: SearchRequest) => this.search(request));

    this.isSearching$ = this.searchQueries.isSearching();
    this.breadcrumbs$ = this.searchQueries.getSearchBreadcrumbs();
    this.hasMealPlanRequirements$ = this.searchQueries.hasMealPlanRequirements();
    this.hasRequiredSearchParams$ = this.searchQueries.hasAllParametersRequiredForSearch();
    this.bannerPromotion$ = this.promotionService.getBannerPromotionContent();
    this.animateInPromotionBanner$ = this.bannerPromotion$.pipe(
      map((promotion) => promotion ? this.promotionService.shouldBannerAnimateIn(promotion.name) : false)
    );
    this.searchCardPromotion$ = this.promotionService.getHighestPrioritySearchPromoCard();

    this.isPopUpMode$ = this.route.data.pipe(map((data) => data.popUpMode || false ));

    this.hasRequiredSearchParams$.pipe(take(1)).subscribe((hasParams) => {
      if (hasParams) {
        this.store.dispatch(PublicActions.search({}));
      }
    });

    this.noSearchResultsMarketingCampaignComponentModel = this.marketingCampaignService.getMarketingCampaignComponentModel(
      MarketingCampaignComponentNames.NO_SEARCH_RESULTS
    );

    this.skinnyBannerMarketingCampaignComponentModel = this.marketingCampaignService.getMarketingCampaignComponentModel(
      MarketingCampaignComponentNames.SEARCH_SKINNY_BANNER
    );
  }

  @Dispatch()
  public trackPromoCardWasClosed(promotion: SearchPromoCardModel): Action {
    this.promotionService.trackPromotionHidden(promotion.name, [PromotionComponents.SEARCH_PROMO_TILE]);
    return PublicActions.hideSearchPromoCard();
  }

  @Dispatch()
  public changeWeWorkMode(enable: boolean): Action {
    return PublicActions.searchPageWeWorkModeChanged({ enabled: enable });
  }

  @Dispatch()
  public onAddFavouriteVendor(vendorId: VendorId): Action {
    return FavouriteVendorsActions.toggleVendorFromSearchComponent({ vendorId, favourited: true });
  }

  @Dispatch()
  public onRemoveFavouriteVendor(vendorId: VendorId): Action {
    return FavouriteVendorsActions.toggleVendorFromSearchComponent({ vendorId, favourited: false });
  }

  public loadNextPage(): void {
    this.store.dispatch(PublicActions.loadNextPage());
  }

  public trackPromotionBannerAnimatedIn(promotionName: PromotionName): void {
    this.promotionService.trackPromotionBannerAnimatedIn(promotionName);
  }

  public onOpenZopimChat(): void {
    this.chatWidgetService.openZopimChat();
  }

  @Dispatch()
  public handlePromoCardClick(promotion: SearchPromoCardModel): Action {
    return PublicActions.promoCardClick({ promotionName: promotion.name });
  }

  public searchWithDebounce(request: SearchRequest): void {
    this.searchSubject.next(request);
  }

  public onEcoFriendlyPackagingFilterChanged({ enabled, currentOptions }: { enabled: boolean, currentOptions: VendorFlag[] }): void {
    if (enabled) {
      this.search({
        options: [...currentOptions, VendorFlags.ECO_FRIENDLY_PACKAGING]
      });
    } else {
      const ecoFriendlyPackagingIndex = currentOptions.indexOf(VendorFlags.ECO_FRIENDLY_PACKAGING);

      // This shouldn't be possible, but if it were to happen that the eco friendly packaging filter
      // wasn't present in the currentOptions array, the index would be -1 and the slicing below would
      // result in duplicate items being set in the search request options, which could potentially lead
      // to buggy behavour. Therefore it's probably safer just to do nothing and return (since in this
      // scenario we wouldn't want to update any of the options anyway)
      if (ecoFriendlyPackagingIndex === -1) {
        return;
      }

      this.search({
        options: [...currentOptions.slice(0, ecoFriendlyPackagingIndex), ...currentOptions.slice(ecoFriendlyPackagingIndex + 1)]
      });
    }
  }

  public onFavouritesFilterChanged(newState: boolean): void {
    // this.favouritesFilterEnabled = !this.favouritesFilterEnabled;
    this.search({
      favourites: newState
    });
  }

  public onSortByChanged(sortBy: SearchSortType): void {
    this.search({
      sortBy
    });
  }

  public onParamsChanged(params: SearchBarParameters): void {
    this.search({
      ...params
    });
  }

  public onSearchChanged(newSearchText: string): void {
    this.search({
      text: newSearchText || null
    });
  }

  public onIndividualChoiceSelected(): void {
    combineLatest([
      this.store.select(SearchSelectors.getSearchRequest),
      this.customerLocations$,
    ]).pipe(take(1)).subscribe(([searchRequest, customerLocations]) => {

      const selectedCustomerLocation = customerLocations.find((customerLocation) =>  {
        return searchRequest.location && customerLocation.id === searchRequest.location;
      }) || null;

      const searchLocation: SearchLocation = selectedCustomerLocation || { postcode: searchRequest.postcode };

      this.store.dispatch(IndividualChoiceSetupActions.setSearchRequest({
        searchRequest,
        customerLocation: selectedCustomerLocation,
        searchLocation,
      }));
      this.store.dispatch(PublicActions.updateSearchType({ searchType: SearchOrderTypes.INDIVIDUAL_CHOICE }));
      this.store.dispatch(RouterActions.go({
        path: '/menus/individual-choice/search',
        query: createURLQueryFromSearchRequest(searchRequest, selectedCustomerLocation)
      }));
    });
  }

  public submitOutOfRegionCaptureModal(): void {
    this.store.dispatch(PublicActions.outOfRegionCaptureModalSubmitted());
  }

  public closeOutOfRegionCaptureModal(): void {
    this.store.dispatch(PublicActions.outOfRegionCaptureModalClose());
  }

  public closeFindNewVendorPopup(): void {
    this.store.dispatch(PublicActions.closeFindNewVendorPopup());
  }

  private search(request: SearchRequest): void {
    this.store.dispatch(PublicActions.search({ request }));
  }
}
