import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { OrderScheduleType, OrderScheduleTypes } from './order-schedule-type.enum';

declare const OrderScheduleIdSymbol: unique symbol;
export type OrderScheduleId = string & {
  [OrderScheduleIdSymbol]: never;
};

export type OrderSchedule = WeeklyOrderSchedule | never; // replace "never" with other types as appropriate

interface BaseOrderSchedule {
  id: OrderScheduleId;
  startDate: moment.Moment;
  endDate: moment.Moment;            // end date selected by the user (can be later than lastScheduledDate)
  lastScheduledDate: moment.Moment;  // requestedDeliveryDate of the last order that will be scheduled, based on endDate, interval and type
  schedule: {
    interval: number;
    type: OrderScheduleType;
  };
}
export interface WeeklyOrderSchedule extends BaseOrderSchedule {
  schedule: {
    interval: number;
    type: typeof OrderScheduleTypes.WEEKLY;
  };
}
export function isWeeklyOrderSchedule(orderSchedule: BaseOrderSchedule): orderSchedule is WeeklyOrderSchedule {
  return orderSchedule.schedule.type === OrderScheduleTypes.WEEKLY;
}

export function createOrderScheduleFromJson(json?: Partial<OrderSchedule>):
OrderSchedule {
  const orderSchedule: Partial<OrderSchedule> = { };

  orderSchedule.id = json.id as OrderScheduleId;
  orderSchedule.startDate = json.startDate ? moment(json.startDate) : null;
  orderSchedule.endDate = json.endDate ? moment(json.endDate) : null;
  orderSchedule.lastScheduledDate = json.lastScheduledDate ? moment(json.lastScheduledDate) : null;
  orderSchedule.schedule = {
    interval: json.schedule && json.schedule.interval,
    type: ensureValidEnumValue(OrderScheduleTypes, json.schedule && json.schedule.type),
  };

  return orderSchedule as OrderSchedule;
}
