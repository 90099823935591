import { Injectable } from '@angular/core';
import { PaymentApi } from '@citypantry/shared-api';
import { NewPaymentCard, PaymentCard, PaymentCardId, ThreeDSecureCustomerParameters, UserId } from '@citypantry/util-models';
import { BraintreeError, Client, DataCollector, ThreeDSecureVerifyPayload } from 'braintree-web';
import { ThreeDSecureInfo, ThreeDSecureVerifyOptions } from 'braintree-web/modules/three-d-secure';
import { AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';
import { BraintreeApi } from './braintree.api';
import { BraintreeTokenizedCard } from './braintree-tokenized-card.model';
import { ExpectedPaymentError } from './expected-payment.error';
import { ExpectedPaymentErrorType } from './expected-payment-error-type.enum';

@Injectable({ providedIn: 'root' })
export class BraintreeService {

  constructor(
    private braintreeApi: BraintreeApi,
    private paymentApi: PaymentApi,
    private analyticsService: AnalyticsService,
  ) { }

  public async addNewPaymentCard(
    creditCard: NewPaymentCard,
    userId: UserId,
    threeDSecureCustomerParameters: ThreeDSecureCustomerParameters
  ): Promise<PaymentCard> {
    const token = await this.paymentApi.getBraintreeClientToken().toPromise();
    const client: Client = await this.braintreeApi.createBraintreeClient(token);
    const deviceData = await this.getDeviceData(token, client);

    const tokenisedCard = await this.addCard(creditCard, client);
    const verificationResponse = await this.verifyCard(
      tokenisedCard.nonce,
      tokenisedCard.details.bin,
      threeDSecureCustomerParameters,
      client,
      true
    );

    const { card } = await this.paymentApi.addNewPaymentCard(
      verificationResponse.nonce,
      deviceData,
      userId,
      creditCard.cardName
    ).toPromise();

    return card;
  }

  /**
   * @return 3D Secure enriched nonce used to create a transaction
   */
  public async verifyExistingPaymentCard(
    cardId: PaymentCardId,
    threeDSecureCustomerParameters: ThreeDSecureCustomerParameters
  ): Promise<string> {
    const token = await this.paymentApi.getBraintreeClientToken().toPromise();
    const client: Client = await this.braintreeApi.createBraintreeClient(token);
    const { nonce, bin } = await this.paymentApi.getPaymentCardNonce(cardId).toPromise();

    const verificationResponse = await this.verifyCard(nonce, bin, threeDSecureCustomerParameters, client, false);

    return verificationResponse.nonce;
  }

  public async getDeviceData(existingToken?: string, existingClient?: Client): Promise<string> {
    const token = existingToken ? existingToken : await this.paymentApi.getBraintreeClientToken().toPromise();
    const client: Client = existingClient ? existingClient : await this.braintreeApi.createBraintreeClient(token);
    const dataCollector: DataCollector = await this.braintreeApi.createBraintreeDataCollector(client);
    const { deviceData } = dataCollector;

    return deviceData;
  }

  private async addCard(card: NewPaymentCard, client: Client): Promise<BraintreeTokenizedCard> {
    const options = {
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: {
        creditCard: card
      }
    };

    try {
      const braintreeAddCardResponse = await (client as any).request(options); // The types are incorrect
      return braintreeAddCardResponse.creditCards[0] as BraintreeTokenizedCard;
    } catch (error) {
      throw this.transformTokenisationError(error);
    }
  }

  /**
   * Set requestChallenge to true when adding a new card to establish Strong Consumer Authentication. This ensures we can take future
   * payments without the cardholder being present.
   */
  private async verifyCard(
    nonce: string,
    bin: string,
    customerParameters: ThreeDSecureCustomerParameters,
    client: Client,
    requestChallenge: boolean
  ): Promise<ThreeDSecureVerifyPayload> {
    const threeDSecure = await this.braintreeApi.createThreeDSecureInstance(client);
    const threeDSecureParameters: ThreeDSecureVerifyOptions = {
      amount: customerParameters.amount,
      email: customerParameters.email,
      nonce,
      bin,
      challengeRequested: requestChallenge,
      additionalInformation: {
        ...customerParameters.additionalInformation
      },
      onLookupComplete: (_data: ThreeDSecureInfo, next) => {
        // This callback executes before the user is presented with the 3D Secure challenge.
        // We are required by the braintree SDK to implement this function but the data is of no use to us currently.
        next();
      }
    } as unknown as ThreeDSecureVerifyOptions; // The types are incorrect. At the time of writing, the api docs are the source of truth.

    const response = await threeDSecure.verifyCard(threeDSecureParameters);
    const threeDSecureInfo = response.threeDSecureInfo;

    this.analyticsService.trackCustomEvent('verify_card', {
      category: AnalyticsCategories.CHECKOUT_PAYMENT,
      label: '3D Secure verification attempt (menus)',
      cardType: response.details.cardType,
      issuingBank: response.binData.issuingBank,
      liabilityShiftPossible: threeDSecureInfo.liabilityShiftPossible,
      liabilityShifted: threeDSecureInfo.liabilityShifted,
      threeDSecureVersion: threeDSecureInfo.threeDSecureVersion,
    });

    // We proceed with the transaction as long as the user hasn't failed or cancelled the challenge.
    // https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side#advanced-client-side-options
    if (threeDSecureInfo.liabilityShiftPossible === true && threeDSecureInfo.liabilityShifted === false) {
      throw new ExpectedPaymentError(
        '3D Secure Verification Required',
        'Your payment provider requires verification of your card details. Please try again.',
        ExpectedPaymentErrorType.THREE_D_SECURE_CANCELLED,
      );
    }

    return response;
  }

  private transformTokenisationError(error: BraintreeError): ExpectedPaymentError | BraintreeError  {
    if (error.code === 'CLIENT_REQUEST_ERROR') {
      return new ExpectedPaymentError(
        'Invalid Card Details',
        'Please check your card details and try again.',
        ExpectedPaymentErrorType.INVALID_CARD_DETAILS,
        error
      );
    }

    return error;
  }
}
