import { select } from '@ngrx/store';
import deepEqual from 'fast-deep-equal';
import { pipe } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

// =========================== //
//  Custom lettable operators  //
// =========================== //
export const mapmap = <T, U>(transform: ((value: T) => U)) => map((values: T[]) => values.map(transform));

export interface ItemResponse<T = any> {
  item: T;
}

export interface ItemsResponse<T = any> {
  items: T[];
}

export const getItem = <T>() => map(({ item }: ItemResponse<T>): T => item as T);
export const getItems = <T>() => map(({ items }: ItemsResponse<T>): T[] => items || []);

/**
 * Only emit a new value if it is distinct from the previous one, by deep equality
 */
export const deepDistinctUntilChanged = () => distinctUntilChanged(deepEqual);

/**
 * Returns the first nonempty value for the given selector that matches the predicate.
 * useful when e.g. waiting for an asynchronously loaded value to be in the store.
 * Empty values are automatically skipped.
 *
 * @example
 * this.store.dispatch(loadOrder(orderId));
 * this.store.pipe(
 *   waitForSelect(getOrder, (order) => order.id === orderId)
 * );
 *
 * @param selector Selector to use with the select() operator
 * @param predicate Test whether the current value is the right value.
 */
export const waitForSelect = <T>(selector: (state: any) => T, predicate: (value: T) => boolean) => pipe(
  select(selector),
  filter((value) => value && predicate(value)),
  take(1),
);

/**
 * Like `tap()`, but will only ever execute once. Useful inside guards. Not very useful inside effects.
 * @param callback Method to call when a value is emitted upstream.
 */
export const tapOnce = <T>(callback: ((value: T) => void)) => {
  let tapped = false;
  return tap((value: T) => {
    if (!tapped) {
      tapped = true;
      callback(value);
    }
  });
};

// no-console: These are for use in development only
/* eslint-disable no-console */
export const logValue = (message?: string) => tap((value: any) => {
  if (message) {
    console.log(message, value);
  } else {
    console.log(value);
  }
});
/* eslint-enable no-console */
