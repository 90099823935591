import { AbstractControl, FormGroup } from '@angular/forms';

/**
 * Creates an objects with fields from the given `values` array set to true if they exist and set to false
 * if they don't exist in the `setValues` array.
 */
export function stringsToBoolObject(values: string[], setValues: string[]): { [key: string]: boolean } {
  const object: { [key: string]: boolean } = {};
  values.forEach((value: string) => {
    object[value] = setValues && setValues.indexOf(value) !== -1;
  });

  return object;
}

/**
 * Creates an array of strings by selecting those fields from `values` that are set to true in the given `object`.
 */
export function boolObjectToStrings<T>(object: any, values: T[]): T[] {
  return values.filter((value: T) => object[value]);
}

/**
 * Filter `values` to only contain elements that are also in the `allowed` array.
 */
export function filterAllowed<T>(values: T[], allowed: T[]): T[] {
  return values.filter((value: T) => allowed.indexOf(value) !== -1);
}

/**
 * Creates a bool object with keys provided by `keys` and the values derived from `object`
 */
export function objectToBoolObject(keys: string[], object: any): { [key: string]: boolean } {
  return stringsToBoolObject(keys, boolObjectToStrings(object, keys));
}

/**
 * Given a FormGroup that represents a set of checkboxes, adds a handler that unchecks
 * all the other checkboxes when the one named "none" is checked.
 *
 * @param formGroup A FormGroup whose model is { [name: string]: boolean }
 * @param noneFormControlName The name of the control in the model which represents the "none" value.
 */
export function addNoneOfTheAboveBehaviour(formGroup: FormGroup, noneFormControlName: string = 'none'): void {
  const noneCheckbox = formGroup.controls[noneFormControlName];
  let previousValue = noneCheckbox.value;
  formGroup.valueChanges.subscribe((value) => {
    if (value[noneFormControlName] && !previousValue) {
      Object.keys(value)
        .filter((key: string) => key !== noneFormControlName)
        .forEach((key: string) => {
          formGroup.controls[key].setValue(false, { emitEvent: false });
        });
    } else if (previousValue) {
      noneCheckbox.setValue(false, { emitEvent: false });
    }
    previousValue = value[noneFormControlName];
  });
}

/**
 * Subscribe to value changes on a parent control and reset a given control.
 * If resetValue is provided, the child control's value will be reset to this,
 * or else it will fallback to null.
 */
export function resetChildControlUponParentFalseValue(
  parentControl: AbstractControl,
  childControl: AbstractControl,
  resetValue: any = null,
): void {
  parentControl.valueChanges.subscribe((value) => {
    if (!value) {
      childControl.reset(resetValue);
    }
  });
}
