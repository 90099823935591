<div class="bundle-section-header">
  <div
    class="bundle-section-header__heading"
    test-id="heading"
  >
    {{ itemGroup.heading }}
  </div>
  <div class="bundle-section-header__prompt">
    <app-item-group-prompt
      [itemGroup]="itemGroup"
      test-id="prompt"
    ></app-item-group-prompt>
  </div>
  <hr class="bundle-section-header__rule" />
</div>

<div class="container [ pt-standard px-none ] [ pt-md-x-large px-md-standard ]">
  <ng-container *ngFor="let item of selectedItems; trackBy: trackById">
    <app-upgrade-group-item
      [item]="item"
      [control]="formGroup.get(item.id)"
      [showMoreInfo]="moreInfoItem === item"
      [canOverrideQuantities]="canOverrideQuantities"
      [hidePrices]="hidePrices"
      (toggleMoreInfo)="onToggleShowMoreInfo(item)"
      (remove)="removeItem(item)"
      test-id="selectedItems"
    ></app-upgrade-group-item>

    <div class="row mb-standard" *ngIf="moreInfoItem === item">
      <app-additional-item-info
        [showDescription]="true"
        [item]="item"
        (close)="onToggleShowMoreInfo(null)"
        test-id="moreInfoSelectedItem"
      ></app-additional-item-info>
    </div>
  </ng-container>

  <div class="row" *ngIf="optionalItems.length">
    <ng-container *ngFor="let item of optionalItems; trackBy: trackById">
      <div class="col-12 mb-standard">
        <div class="row no-gutters">
          <div class="col-auto">
            <button
              class="add-button"
              (click)="addItem(item)"
              test-id="addButton"
            >
              <i class="icon icon-plus icon--medium"></i>
            </button>
          </div>
          <div class="col ml-standard pt-x-small">
            <div class="row justify-content-between">
              <div class="col col-auto">
                <span test-id="optionalItemName">
                  {{ item.name }}
                </span>
                <span>
                  <a
                    class="link link--light link--small text-nowrap"
                    (click)="onToggleShowMoreInfo(item)"
                    test-id="toggleInfoLink"
                  >
                    {{ moreInfoItem === item ? 'Less info' : 'More info' }}
                  </a>
                </span>
              </div>
              <div
                *ngIf="!hidePrices"
                class="col col-auto text-right"
                test-id="optionalItemPrice"
              >
                <span class="text-nowrap">+{{ item.price | currency:'GBP' }} each</span>
              </div>
            </div>
            <div class="row">
              <div
                class="col col-12 mt-small text-color-stone-2"
                *ngIf="shouldShowKcals(item)"
                test-id="kcal"
              >
                {{ item.kcal }}kcal per portion
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <div
                  *ngIf="item.ageRestricted || hasDietaries(item)"
                  class="d-flex mt-small"
                >
                  <span
                    *ngIf="item.ageRestricted"
                    class="icon icon--medium"
                    aria-label="Age restricted"
                    test-id="ageRestricted"
                  >age_restricted</span>
                  <app-dietaries
                    *ngIf="hasDietaries(item)"
                    class="vertical-separator"
                    [dietaries]="item.dietaries"
                    test-id="dietaries"
                  ></app-dietaries>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="moreInfoItem === item"
        class="col col-12 mb-standard"
      >
        <app-additional-item-info
          [showDescription]="true"
          [item]="item"
          [showPerServing]="true"
          (close)="onToggleShowMoreInfo(null)"
          test-id="moreInfoListItem"
        ></app-additional-item-info>
      </div>
    </ng-container>
  </div>
</div>
