<div class="tag-input">
  <ul
    class="tag-input__list js-tag-input"
    (click)="onWrapperClick($event)"
  >
    <li
      *ngFor="let tag of tags; let i = index"
      class="tag-input__item"
      test-id="tags"
    >
      <span class="tag-input__tag">
        {{ tag }}
        <button
          class="tag-input__tag-remove material-icon-button material-icon-button--small"
          (click)="remove(i)"
          aria-label="Remove"
          test-id="removeButtons"
        >
          close
        </button>
      </span>
    </li>
    <li class="tag-input__item tag-input__item--current">
      <input
        #currentTagInput
        class="tag-input__input"
        [(ngModel)]="currentTag"
        (keydown)="onKeyDown($event)"
        (blur)="onBlur()"
        test-id="currentTagInput"
      />
    </li>
  </ul>

  <ng-content></ng-content>

  <button
    *ngIf="showClearAllButton"
    class="button-link tag-input__clear-all"
    (click)="onClearAllClick()"
    test-id="clearAllButton"
  >
    Clear All
  </button>
</div>
