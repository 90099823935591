import { Pipe, PipeTransform } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { VendorFlag, VendorFlags } from '@citypantry/util-models';

@Pipe({
  name: 'vendorFlagName'
})
export class VendorFlagNamePipe implements PipeTransform {
  public transform(value: VendorFlag): string {
    switch (value) {
      case VendorFlags.INDIVIDUAL_CHOICE:
        return 'Group Ordering';
      case VendorFlags.SAME_DAY_CHOICE:
        return 'Same Day Choice';
      case VendorFlags.ONE_DAY_CHOICE:
        return 'One Day Choice';
      case VendorFlags.TWO_DAYS_CHOICE:
        return 'Two Days Choice';
      case VendorFlags.ECO_FRIENDLY_PACKAGING:
        return 'Eco Friendly Packaging';
      case VendorFlags.POPUPS:
        return 'Popups';
      case VendorFlags.BUFFET:
        return 'Buffet';
      case VendorFlags.EXCLUSIVE_TO_CP:
        return 'Exclusive to Just Eat for Business';
      case VendorFlags.GOOD_ITEM_LABELLING:
        return 'Good Item Labelling';
      case VendorFlags.SAME_DAY_ORDERING:
        return 'Same Day Ordering';
      case VendorFlags.WEWORK_FRIENDLY:
        return 'WeWork Friendly';
      default:
        throw new UnreachableCaseError(value);
    }
  }
}
