export const CHUNK_FAILED_MESSAGE = /Loading chunk \d+ failed/;

/**
 * Not to be confused with an Angular ErrorHandler.
 * The error handler passed to the router is purely the handleError function, hence why it is static.
 */
export class RouterErrorHandler {

  public static handleError(error: Error): void {
    if (RouterErrorHandler.isChunkLoadError(error)) {
      // We do not want to throw this error as we're handling it in the router event subscription in AppComponent.
      // We can't handle it here because the thrown error doesn't contain the target url.
      return;
    }

    // The default router error handler just throws the error.
    // The NavigationError event has already been created before we get to this handler.
    throw error;
  }

  public static isChunkLoadError(error: Error): boolean {
    return CHUNK_FAILED_MESSAGE.test(error.message);
  }

}
