<app-deletable-cart-item
  (remove)="onRemove()"
  [hasError]="hasError"
  [isBundleCartItem]="isBundleCartItem"
  test-id="deletableCartItem"
>
    <div class="cart-item__quantity-wrapper">
      <input
        #quantity
        type="number"
        class="cart-item__quantity"
        [value]="cartItem.quantity"
        min="0"
        step="1"
        (blur)="onQuantityBlurred($event.target.value)"
        (click)="onQuantityTouched($event)"
        (change)="onQuantityTouched($event)"
        [@adjusted]="showInputAdjusted"
        (@adjusted.done)="inputAdjustAnimationFinished()"
        test-id="quantity"
      />
      <span class="cart-item__times"></span>
    </div>

    <div class="cart-item__details">
      <span
        class="cart-item__name"
      >
        <span
          (click)="onItemNameClicked()"
          test-id="name"
          e2e-test-id="cartItemName"
        >
          {{ cartItem.item.name }}
        </span>
        <button
          *ngIf="isBundleCartItem || isCustomCartItem"
          (click)="onEditClicked()"
          class="cart-item__edit-button"
          test-id="editButton"
        >
          <i class="material-icons cart-item__edit-icon" aria-hidden="true">edit</i>
        </button>
      </span>
      <div
        *ngIf="cartItemAdjustmentMessage"
        class="cart-item__error-message"
        test-id="cartItemAdjustmentMessage"
        @slideInOut
      >
        {{ cartItemAdjustmentMessage }}
      </div>
      <div
        *ngIf="hasSelectedOptions()"
        class="cart-text"
      >
        <ul
          *ngIf="isItemListExpanded"
          @slideInOut
          class="cart-item__bundle-options"
          test-id="itemList"
        >
          <li
            *ngFor="let option of getSelectedOptions()"
            class="cart-item__bundle-option"
            test-id="selectedOption"
          >
            {{ option.quantity }} &times; {{ option.name }}
          </li>
        </ul>
        <button
          class="cart-item__options-toggle"
          (click)="toggleItemList()"
          test-id="itemListToggleButton"
        >{{ isItemListExpanded ? 'Hide Selection' : 'Show Selection ' }}</button>
      </div>
    </div>
    <span
      *ngIf="!useHiddenBudget"
      class="cart-item__price cart-text cart-text--title"
      test-id="price"
      e2e-test-id="cartPrice"
    >
      {{ cartItem.price | currency:currencyCode }}
    </span>

</app-deletable-cart-item>
