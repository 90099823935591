import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@citypantry/shared-app-config';
import { Result } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import { ErrorResponse } from '@citypantry/util-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { DISABLE_REDIRECT_ON_AUTH_FAILURE } from './auth.interceptor';
import { createUserProfileFromJson, UserProfile } from './models/user-profile.model';
import { UserRegisterError, UserRegisterInfo } from './models/user-register-info.model';

@Injectable({
  providedIn: 'root'
})
export class AuthApi {

  constructor(
    private http: HttpClient,
  ) {}

  public fetchCurrentlyLoggedInProfile(): Observable<UserProfile | null> {
    return this.http.get('/users/me/profile', {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' },
      context: new HttpContext().set(DISABLE_REDIRECT_ON_AUTH_FAILURE, true)
    }).pipe(
      timeout(environment.authTimeout),
      map(createUserProfileFromJson),
      catchError((error: HttpErrorResponse | ErrorResponse | object) => {
        if ((error instanceof HttpErrorResponse || error instanceof ErrorResponse) && error.status === 401) {
          return of(null);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  public register(userInfo: UserRegisterInfo): Observable<Result<UserProfile, UserRegisterError>> {
    return this.http.post(
      `/users`,
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        password: userInfo.password
      },
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'} },
    ).pipe(
      map(({ userProfile }: { userProfile: UserProfile }) => Result.success(createUserProfileFromJson(userProfile))),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // error - HttpErrorResponse
          // error.error - Response body
          // error.error.errors - list of errors returned by the API
          if (error.status === 422 && error.error && error.error.errors) {
            const errors = error.error.errors;
            if (errors.IsUserUnique) {
              return of(Result.failure<UserRegisterError>({
                type: 'UserAlreadyExists',
              }));
            }
            if (errors.Validation) {
              const validationErrors: { name: string, message: string }[] = errors.Validation;
              const passwordErrorMessage = validationErrors
                .filter(({ name }) => name === 'password')
                .map(({ message }) => message)
                .join(' ');
              if (passwordErrorMessage) {
                return of(Result.failure<UserRegisterError>({
                  type: 'PasswordIsWeak',
                  message: passwordErrorMessage,
                }));
              }
            }
          }
        }
        return throwError(error);
      }),
    );
  }
}
