import { cardHolderName } from './card-holder-name.validator';
import { checkboxGroup } from './checkbox-group.validator';
import { greaterThanOrEqualToField } from './compare-to-field.validator';
import { entityName } from './entity-name.validator';
import { every } from './every.validator';
import { freeText } from './free-text.validator';
import { internationalPhone } from './international-phone.validator';
import * as Ng2Validators from './ng2-custom-validators';
import { personName } from './person-name.validator';
import { phone } from './phone.validator';
import { postcode } from './postcode.validator';
import { timeInListAndDateOnWeekend } from './time-in-list-and-date-on-weekend';
import { valueInList } from './value-in-list.validator';

export const CustomValidators = {
  ...Ng2Validators,
  cardHolderName,
  checkboxGroup,
  every,
  personName,
  entityName,
  freeText,
  greaterThanOrEqualToField,
  phone,
  internationalPhone,
  postcode,
  valueInList,
  timeInListAndDateOnWeekend,
};
