<div
  class="editable-price"
  [class.editable-price--active]="isInputActive"
  [class.editable-price--error]="control.invalid"
  [class.editable-price--disabled]="disabled"
  (click)="select()"
  test-id="outerWrapper"
>
  <div
    class="editable-price__wrapper"
    test-id="innerWrapper"
  >
    <span
      class="editable-price__currency"
      test-id="currency"
    >{{ prefix || '' }}</span>
    <input
      #numberInput
      *ngIf="isInputActive"
      [value]="startValue"
      [formControl]="control"
      (enter)="submit()"
      (blur)="blur()"
      [step]="step"
      type="number"
      class="editable-price__input"
      test-id="input"
    >
    <ng-content *ngIf="!isInputActive"></ng-content>
    <div class="editable-price__submit">
      <button
        class="material-icon-button material-icon-button--small material-icon-button--secondary"
        (click)="submitClicked($event)"
        (focus)="focus()"
        test-id="submit"
      >check</button>
    </div>
    <i
      class="icon icon-edit editable-price__edit"
      test-id="edit"
    ></i>
  </div>
  <div class="editable-price__error-message form-error">
    Invalid amount
  </div>
</div>
