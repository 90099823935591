import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-minimum-order-quantity',
  templateUrl: './minimum-order-quantity.component.html',
  styleUrls: ['./minimum-order-quantity.component.scss']
})
export class MinimumOrderQuantityComponent {
  @Input()
  public minimumQuantity: number;

  @Input()
  public maximumQuantity?: number;
}
