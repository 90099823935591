import { Injectable } from '@angular/core';
import { SegmentService } from '../segment.service';

@Injectable()
export class SegmentInitializer {
  constructor(
    private segment: SegmentService
  ) {
  }

  public run(): void {
    this.segment.init();
    this.segment.setupUserIdentificationSubscription();
  }
}
