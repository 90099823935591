import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

/**
 * Converts the given comma-separated values string into an array of trimmed strings.
 */
export function csvToTrimmedStrings(csv: string): string[] {
  if (csv === null || typeof csv === 'undefined') {
    return [];
  }

  return csv.split(',')
    .map((value: string) => value.trim())
    .filter((value: string) => !!value);
}

export function filterNullValuesFromParams(params: Params): Params {
  const newParams: Params = {};
  Object.keys(params).forEach((key: string) => {
    if (params[key] !== null && typeof params[key] !== 'undefined') {
      newParams[key] = params[key];
    }
  });
  return newParams;
}

export function objectToParams(query: { [key: string]: any }, convertArraysToString: boolean = false): HttpParams {
  let params = new HttpParams();
  Object.keys(query).forEach((key) => {
    const value: any = query[key];
    if (value !== null && typeof value !== 'undefined') {
      if (query[key] instanceof Array && !convertArraysToString) {
        query[key].forEach((item: any) => {
          params = params.append(`${key.toString()}[]`, item);
        });
      } else {
        params = params.set(key, `${value}`);
      }
    }
  });
  return params;
}

/**
 * Creates URLs where relative paths are resolved.
 *
 * This method exists because there is no simple way in Angular to resolve relative paths from a guard.
 * @see https://github.com/angular/angular/issues/22763#issuecomment-379642011
 *
 * @param url The root URL (usually RouterStateSnapshot.url). Should not contain the domain or protocol.
 * @param redirectTo Segment or list of segments which may contain relative paths.
 *                   For convenience, accepts pretty much any combination of strings and arrays.
 *                   Empty strings, null and other falsy values are permitted and will be skipped.
 *
 * @example parseUrl('/menus/locations/location-1', '..', 'location-2')         // 'menus/locations/location-2'
 * @example parseUrl('/menus/locations/location-1', '..', null, 'location-2')   // 'menus/locations/location-2'
 * @example parseUrl('/menus/locations/location-1', '..', '', 'location-2')     // 'menus/locations/location-2'
 * @example parseUrl('/menus/locations/location-1', '..', 'location-2', '..')   // 'menus/locations'
 * @example parseUrl('/menus/locations/location-1', '../location-2')            // 'menus/locations/location-2'
 * @example parseUrl('/menus/locations/location-1', ['..', 'location-2'])       // 'menus/locations/location-2'
 * @example parseUrl('/menus/locations/location-1', ['..', 'location-2'], '..') // 'menus/locations'
 * @example parseUrl('/menus/locations/location-1', ['../..', 'account'])       // 'menus/account'
 */
export function parseUrl(url: string, ...redirectTo: (string | string[])[]): string {
  const firstToken = (/^(\/?)/).exec(url)[1]; // Track if the URL was absolute; don't allow popping the first '/' token
  if (firstToken) {
    url = url.replace(/^(\/?)/, '');
  }

  const urlTokens = (url || '').split('/');

  for (const pathSegments of redirectTo) {
    const pathSegmentsArray = Array.isArray(pathSegments) ? pathSegments : [pathSegments];

    for (const pathSegment of pathSegmentsArray) {

      if (!pathSegment) {
        continue; // Allow nulls, just skip that segment
      }
      const redirectToTokens = pathSegment.replace(/(^\/|\/$)/g, '').split('/');

      let token = redirectToTokens.shift();

      while (token) {
        if (token !== '.' && token !== '..') {
          redirectToTokens.unshift(token);
          break;
        }

        if (token === '..') {
          urlTokens.pop();
        }

        token = redirectToTokens.shift();
      }

      urlTokens.push(...redirectToTokens);
    }
  }

  return firstToken + urlTokens.join('/');
}

/**
 * Returns the URL without any query params or fragments.
 * @param url URL, may contain protocol and/or domain name, e.g. foo.bar?foo, http://foo.bar/foo/, or /foo/bar
 * @return URL without query params or fragments, e.g. foo.bar, foo.bar/foo/, or /foo/bar
 */
export function getSimpleUrl(url: string): string {
  const match = url.match(/^(.*?)\/?([?#].*)?$/i);
  return match ? match[1] : url;
}

/**
 * Returns only the protocol and domain from the given URL.
 * @param url A fully qualified URL, e.g. https://foo.bar/path?query=foo#baz
 * @return The base URL, e.g. https://foo.bar
 */
export function getBaseUrl(url: string): string {
  const match = url.match(/^(https?:\/\/.*?)([/?#]|$)/i);
  return match ? match[1] : url;
}
