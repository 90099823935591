import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler as AngularErrorHandler, Inject, NgModule, Renderer2, RendererFactory2 } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FooterComponentsModule } from '@citypantry/components-footer';
import { HeaderComponentsModule } from '@citypantry/components-header';
import { LoadingComponentsModule } from '@citypantry/components-loading';
import { SearchModule } from '@citypantry/feature-search';
import {
  AnalyticsComponentsModule,
  AnalyticsEcommerceComponentsModule,
  AnalyticsTrackingModule,
  LogLevel
} from '@citypantry/shared-analytics';
import { ApiUrlInterceptor } from '@citypantry/shared-api';
import { AppConfigModule, environment } from '@citypantry/shared-app-config';
import { AuthModule } from '@citypantry/shared-auth';
import { ChatWidgetModule } from '@citypantry/shared-chat-widget';
import { CustomerQualificationModule } from '@citypantry/shared-customer-qualification';
import { ErrorHandler, ErrorModule, RouterErrorHandler } from '@citypantry/shared-error';
import { GlobalDialogModule } from '@citypantry/shared-global-dialog';
import { GlobalStoreModule } from '@citypantry/shared-global-store';
import { GoogleMapsModule } from '@citypantry/shared-google-maps';
import { HistoryModule } from '@citypantry/shared-history';
import { LostbarModule } from '@citypantry/shared-lostbar';
import { MetaModule } from '@citypantry/shared-meta';
import { RootModule } from '@citypantry/shared-root';
import { StoreInjector } from '@citypantry/state';
import { GlobalEffectsModule } from '@citypantry/state-effects';
import { WindowRef } from '@citypantry/util-browser';
import { AgGridModule } from 'ag-grid-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieModule } from 'ngx-cookie';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  keyboard: false,
  pagination: true,
};

@NgModule({
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    // 3rd Party modules
    ClipboardModule,
    CookieModule.forRoot(),
    SwiperModule,
    AgGridModule.withComponents([]),

    // Store modules
    GlobalStoreModule,
    GlobalEffectsModule,

    // Internal modules - order specific
    // These must not change order since their HTTP interceptors will not work properly if they run in reverse
    // AuthModule must be included after ErrorModule so that its interceptor runs first;
    // it catches authentication errors which the error module should not see
    ErrorModule,
    AuthModule,
    AppConfigModule.forRoot(),

    // Internal modules
    // Keep these alphabetical to improve chances of not importing twice
    AnalyticsComponentsModule,
    AnalyticsEcommerceComponentsModule,
    AnalyticsTrackingModule.forRoot({
      logLevel: environment.production ? LogLevel.NONE : LogLevel.WARN,
    }),
    ChatWidgetModule,
    CustomerQualificationModule,
    FooterComponentsModule,
    GlobalDialogModule,
    GoogleMapsModule.forRoot({
      key: 'AIzaSyD5QFi-5R_78kYHcjvY3ynroXUeuxGwqAY'
    }),
    LostbarModule,
    HeaderComponentsModule,
    HistoryModule,
    MetaModule,
    SearchModule,
    RootModule,

    // Routing modules
    // Import order matters; AppRouterModule *must* be the last one as it contains the catch-all values!
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected',
        errorHandler: RouterErrorHandler.handleError
      }
    ),
    LoadingComponentsModule,
  ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: AngularErrorHandler,
      useClass: ErrorHandler
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true
    },
  ]
})
export class AppModule {
  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) document: Document,
    windowRef: WindowRef,
    _storeInjector: StoreInjector, /* eslint-disable-line @typescript-eslint/no-unused-vars */ // Injected so it gets initialised correctly
  ) {
    this.addIos11Class(rendererFactory.createRenderer(null, null), windowRef, document);
  }

  private addIos11Class(renderer: Renderer2, windowRef: WindowRef, document: Document): void {
    // Modified version of https://stackoverflow.com/a/46866149
    // This fixes CPD-2578 which causes modals to jump the page to top on opening
    // That, in turn, was caused by an input bug on iOS 11, so we're fixing it only for that
    // https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8

    const userAgent = windowRef.nativeWindow.navigator.userAgent;
    const isIos = /iPad|iPhone|iPod/.test(userAgent);
    const isIos11 = isIos && /OS 11_/.test(userAgent);

    if (isIos11) {
      renderer.addClass(document.documentElement, 'ios11BugFixCaret');
    }
  }
}
