import { CategorySearchResultsPage } from '@citypantry/util-models';
import { SearchRecommendationAction, SearchRecommendationActions } from './search-recommendation.actions';

export interface SearchRecommendationState {
  results: CategorySearchResultsPage[];
}

export const INITIAL_STATE: SearchRecommendationState = {
  results: []
};

export function reducer(state: SearchRecommendationState = INITIAL_STATE, action: SearchRecommendationAction): SearchRecommendationState {
  switch (action.type) {
    case SearchRecommendationActions.searchRecommendationsResultsLoaded.type: {
      const { results } = action;

      return {
        ...state,
        results,
      };
    }

    default:
      return state;
  }
}
