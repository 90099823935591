import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange, IsoDate, ISO_DATE_FORMAT } from '@citypantry/util';
import moment from 'moment';
import { DatePickerComponent as DpDatePickerComponent, IDatePickerConfig } from 'ng2-date-picker';
import { Subject } from 'rxjs';
import { CalendarDataSource } from './calendar-data-source.model';
import { RangeWithCustomCssClass } from './range-with-custom-css-class.model';

const INPUT_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-date-picker',
  template: `
    <dp-date-picker
      #datePicker
      [ngModel]="valueMoment"
      (ngModelChange)="onChange($event)"
      (close)="onStateChange()"
      (open)="onOpenDatePicker()"
      (onChange)="onStateChange()"
      [theme]="theme"
      [placeholder]="placeholder || ''"
      [disabled]="disabled"
      [config]="datePickerConfig"
      test-id="childDatePicker"
      e2e-test-id="childDatePicker"
    ></dp-date-picker>`,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DatePickerComponent, multi: true }
  ],

  // Keeping ChangeDetectionStrategy.OnPush here is essential, because ng2-date-picker
  // includes functions that are triggered very often, such as isDayDisabled and getDayButtonClass
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements ControlValueAccessor {
  @ViewChild('datePicker', { static: true })
  public datePicker: DpDatePickerComponent;

  @Input()
  public placeholder: string | null;

  @Input()
  public variant: string | null;

  //
  // CALENDAR DATA = Four inputs used in the calender (minDay, maxDay, disabledDays, daysWithCustomCssClass)
  //
  //
  // 1 - WHERE THESE INPUTS COME FROM
  //
  //   They are inputs that potentially need to be fetched from the store / from the current time of the day.
  //
  //   We want to give the possibility to set this data via an external provider (it can be a directive, or a service, or whatever
  //   implements the interface CalendarDataSource). The reason behind this is that, potentially, these inputs might need to be
  //   fetched from "dirty" places and we want to be able to provide them, without necessarily passing them down all the way from
  //   the smart components above.
  //
  //   If nobody forces a value on the setter "calendarData", the inputs are fetched from the component itself
  //
  //
  // 2 - HOW THESE INPUTS COPE WITH CHANGE DETECTION ON PUSH
  //
  //   Normally, input reference variables are discouraged with OnPush, because Angular won't trigger change detection when their
  //   attributes / content changes without changing the reference of the object.
  //
  //   In the specific case of the four "calendar data below", however, things will work, because the data is only considered
  //   inside functions that are triggered during change detection by ng2-date-picker: (1) isDayDisabled, (2) getDayButtonClass
  //
  //   As far as this peculiarity is encapsulated inside this component, from the outside everything will work fine, and external consumers
  //   of this component will just pass the four calendar inputs normally
  //
  //   REMEMBER: You should NOT use those two properties in the template, outside of the functions that are automatically
  //   called by ng2-date-picker
  //

  @Input()
  public set minDay(value: IsoDate | null) {
    this._minDay = value;
  }

  public get minDay(): IsoDate | null {
    if (this._calendarDataSource) {
      return this._calendarDataSource.minDay;
    }

    return this._minDay;
  }

  @Input()
  public set maxDay(value: IsoDate | null) {
    this._maxDay = value;
  }

  public get maxDay(): IsoDate | null {
    if (this._calendarDataSource) {
      return this._calendarDataSource.maxDay;
    }

    return this._maxDay;
  }

  @Input()
  public set disabledDays(value: DateRange[]) {
    this._disabledDays = value;
  }

  public get disabledDays(): DateRange[] {
    if (this._calendarDataSource) {
      return this._calendarDataSource.disabledDays;
    }

    return this._disabledDays;
  }

  @Input()
  public set daysWithCustomCssClass(value: RangeWithCustomCssClass[]) {
    this._daysWithCustomCssClass = value;
  }

  public get daysWithCustomCssClass(): RangeWithCustomCssClass[] {
    if (this._calendarDataSource) {
      return this._calendarDataSource.daysWithCustomCssClass;
    }

    return this._daysWithCustomCssClass;
  }

  /**
   * Allows anchoring the dropdown to a different element.
   * Usually the dropdown is aligned to the <input>, setting this will align the dropdown to the given element instead.
   * @param {HTMLElement} element
   */
  @Input()
  public set container(element: HTMLElement | null) {
    this.datePickerConfig.inputElementContainer = element;
  }

  @Input()
  public set value(date: IsoDate | null) {
    this.valueMoment = date ? IsoDate.toMoment(date) : null;
  }

  @Output()
  public update: EventEmitter<IsoDate | null> = new EventEmitter();

  public datePickerConfig: IDatePickerConfig;
  public valueMoment: moment.Moment | null;
  public disabled: boolean;

  public get theme(): string {
    const theme = ['cp-datepicker'];
    if (this.variant) {
      theme.push(`cp-datepicker--${this.variant}`);
    }
    return theme.join(' ');
  }

  private _minDay: IsoDate | null;
  private _maxDay: IsoDate | null;
  private _disabledDays: DateRange[] = [];
  private _daysWithCustomCssClass: RangeWithCustomCssClass[] = [];

  private updateParentFormSubject: Subject<string> = new Subject();
  private _calendarDataSource: CalendarDataSource | undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private element: ElementRef
  ) {
    this.placeholder = null;
    this.variant = null;
    this.minDay = null;
    this.maxDay = null;
    this.value = null;

    this.datePickerConfig = {
      firstDayOfWeek: 'mo',
      monthFormat: 'MMMM YYYY',
      monthBtnFormat: 'MMMM',
      format: INPUT_FORMAT,
      isDayDisabledCallback: this.isDayDisabled.bind(this),
      dayBtnCssClassCallback: this.getDayButtonClass.bind(this),
      unSelectOnClick: false,
    };
  }

  public setCalendarDataSource(calendarDataSource: CalendarDataSource): void {
    this._calendarDataSource = calendarDataSource;
  }

  public onStateChange(): void {
    this.cdr.markForCheck();
  }

  public onOpenDatePicker(): void {
    // The line below is essential, in order to force ng2-date-picker to refresh disabled days
    // To deal with this typing mismatch, we'd need to update moment; see e.g. https://github.com/ant-design/ant-design/issues/24411
    this.datePicker.dayCalendarRef.writeValue(this.valueMoment as any);
    this.datePicker.dayCalendarRef.currentDateView = this.valueMoment as any || moment();
    this.onStateChange();
  }

  public openDatepicker(): void {
    this.datePicker.inputFocused();

    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  public closeDatepicker(): void {
    this.datePicker.hideCalendar();

    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  // The ng2-date-picker emits moment instances when the user picks a date in the calendar, and strings when they type in the input
  public onChange(event: moment.Moment | string): void {
    // this.update = the event emitter, to listen for events from parent components
    //    -> It emits either a formatted date or null, consistently with how inputs are
    //       usually passed / received from parent

    // this.updateParentFormSubject = the subject to which the parent form subscribes
    //    -> It only emits strings, so that the form can work out validation etc

    // this.dispatchNativeChangeEvent() = Manually dispatch a native `change` event
    //    -> So that e.g. analytics can catch it and dispatch tracking

    if (event && !moment.isMoment(event) && (event.length !== INPUT_FORMAT.length || !(moment(event).isValid()))) {
      // Invalid text, dispatch change to ensure any validation can trigger
      this.updateParentFormSubject.next(event as string);
      return;
    }

    const newValue: moment.Moment | null = event && (moment.isMoment(event) ? event : moment(event, INPUT_FORMAT));

    if (!newValue && !this.valueMoment) {
      // Was blank, it still is blank => nothing to emit
      return;
    }

    if (!newValue) {
      // User has unselected the date
      this.update.emit(null);
      this.updateParentFormSubject.next('');
      this.valueMoment = null;
      this.dispatchNativeChangeEvent();
      return;
    }

    if (this.valueMoment && newValue.isSame(this.valueMoment)) {
      // Same values => nothing to emit
      return;
    }

    // User typed or clicked valid date
    this.update.emit(IsoDate.fromMoment(newValue));
    this.updateParentFormSubject.next(IsoDate.fromMoment(newValue));
    this.valueMoment = newValue;

    this.dispatchNativeChangeEvent();
  }

  // Needed for ControlValueAccessor
  public writeValue(value: string): void {
    // For invalid values, we don't want to update the underlying child component model
    // in case it contains an invalid string that the user typed
    if (value && value.length === ISO_DATE_FORMAT.length && moment(value, ISO_DATE_FORMAT).isValid()) {
      this.valueMoment = moment(value, ISO_DATE_FORMAT);
    }
  }

  // Needed for ControlValueAccessor
  public registerOnChange(fn: (value: any) => void): void {
    this.updateParentFormSubject.subscribe(fn);
  }

  // Needed for ControlValueAccessor
  public registerOnTouched(fn: () => void): void {
    this.datePicker.registerOnTouched(fn);
  }

  // Needed for ControlValueAccessor
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private dispatchNativeChangeEvent(): void {
    const event = new CustomEvent('change', { bubbles: true });
    this.element.nativeElement.dispatchEvent(event);
  }

  private isDayDisabled(day: moment.Moment): boolean {
    const pickerDate = IsoDate.fromMoment(day);

    if (this.minDay && pickerDate < this.minDay) {
      return true;
    }

    if (this.maxDay && pickerDate > this.maxDay) {
      return true;
    }

    for (const dateRange of this.disabledDays) {
      if (DateRange.isInRange(pickerDate, dateRange)) {
        return true;
      }
    }

    return false;
  }

  private getDayButtonClass(day: moment.Moment): string {
    const pickerDate = IsoDate.fromMoment(day);

    for (const dateRangeWithCustomCssClass of this.daysWithCustomCssClass) {
      if (DateRange.isInRange(pickerDate, dateRangeWithCustomCssClass.range)) {
        return dateRangeWithCustomCssClass.customCssClass;
      }
    }

    return '';
  }
}
