import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  createCustomerLocationFromJson,
  createEaterLocationFromJson,
  CustomerId,
  CustomerLocation,
  EaterLocation,
  ErrorMessage,
  NewDeliveryLocation,
  ValidationResponse
} from '@citypantry/util-models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerLocationApi {
  constructor(
    private http: HttpClient,
  ) { }

  public getCustomerLocations(customerId: CustomerId): Observable<CustomerLocation[]> {
    return this.http.get<{ locations: CustomerLocation[] }>(`/customers/${customerId}/locations`).pipe(
      map(({ locations }) => locations.map(createCustomerLocationFromJson)),
    );
  }

  public updateCustomerLocation(payload: CustomerLocation): Observable<ValidationResponse<CustomerLocation, ErrorMessage>> {
    return this.http.put<{location: CustomerLocation}>(
      `/locations/${payload.id}`,
      payload,
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}}
    ).pipe(
      map(({ location }) => createCustomerLocationFromJson(location)),
      map((location: CustomerLocation) =>  ({
        success: true as const,
        value: location,
      })),
      catchError((errorResponse: HttpErrorResponse) => (
        of({success: false as const, error: errorResponse.error}))
      ),
    );
  }

  public createCustomerLocation(customerId: CustomerId, newDeliveryLocation: NewDeliveryLocation): Observable<CustomerLocation> {
    return this.http.post<{location: CustomerLocation}>(`/customers/${customerId}/locations`, newDeliveryLocation).pipe(
      map(({ location }) => createCustomerLocationFromJson(location)),
    );
  }

  public getLocationByShareToken(shareToken: string): Observable<EaterLocation> {
    return this.http.get(
      `/locations/shareable/${shareToken}`
    ).pipe(
      map(createEaterLocationFromJson)
    );
  }
}
