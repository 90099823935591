import { MajorCurrency } from './minor-major-currency.model';

export interface Cost {
  gross: MajorCurrency;
  net: MajorCurrency;
  vat: MajorCurrency;
  vatRated: MajorCurrency;
}

export function createCostFromJson(json: Partial<Cost>): Cost {
  json = json || {};
  return {
    gross: json && json.gross || 0,
    net: json && json.net || 0,
    vat: json && json.vat || 0,
    vatRated: json && json.vatRated || 0,
  };
}
