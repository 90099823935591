import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from '@citypantry/components-click-outside';

import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { AnalyticsEcommerceComponentsModule } from '@citypantry/shared-analytics';
import { FooterComponent } from './footer/footer.component';
import { MegaFooterComponent } from './mega-footer/mega-footer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AnalyticsEcommerceComponentsModule,
    AnalyticsComponentsModule,
    ClickOutsideModule,
  ],
  declarations: [
    FooterComponent,
    MegaFooterComponent,
  ],
  exports: [
    FooterComponent,
    MegaFooterComponent,
  ],
})
export class FooterComponentsModule { }
