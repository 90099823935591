/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type UserType = 'staff' | 'vendor' | 'customer' | 'orderer' | 'eater';

export class UserTypes {
  public static STAFF:    UserType & 'staff'    = 'staff';
  public static VENDOR:   UserType & 'vendor'   = 'vendor';
  public static CUSTOMER: UserType & 'customer' = 'customer';
  public static ORDERER:  UserType & 'orderer'  = 'orderer';
  public static EATER:    UserType & 'eater'    = 'eater';

  public static get values(): UserType[] {
    return [
      UserTypes.STAFF,
      UserTypes.VENDOR,
      UserTypes.CUSTOMER,
      UserTypes.ORDERER,
      UserTypes.EATER,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type UserTypeEnum = typeof UserTypes;
