export interface PaymentCardNonce {
  nonce: string;
  bin: string;
}

export function createPaymentCardNonceFromJson(json?: Partial<PaymentCardNonce>): PaymentCardNonce {
  json = json || {};
  const card: Partial<PaymentCardNonce> = {};

  card.nonce = json.nonce;
  card.bin = json.bin;

  return card as PaymentCardNonce;
}
