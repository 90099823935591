<div
  class="search-result-layout search-card"
  [class.search-card--display]="useDisplayMode"
  test-id="searchCard"
  e2e-test-id="searchCard"
  analyticsId="search-result"
  [analyticsData]="{ isTopPick: result.vendorRecommended, vendorId: result.vendorId, vendorName: result.vendorName, vendorRating: result.vendorRating, vendorReviewCount: result.vendorReviewCount, menuId: result.menuId }"
>
  <a
    class="text-decoration-none"
    [routerLink]="getUrl()"
    [queryParams]="getQueryParams()"
    analyticsId="link"
    test-id="link"
  >
    <div
      class="search-card__image"
      [backgroundImage]="result.heroImage?.medium"
      test-id="cardImage"
    >
      <div
        class="search-result-layout__image-tag-container"
      >
        <div
          *ngIf="showRemainingCapacity"
          class="search-card__tag"
          test-id="remainingCapacityTag"
        >
          <i class="icon icon--small mr-x-small" aria-hidden="true">people</i>
          <span>{{ this.result.remainingCapacity | remainingCapacity:this.showExactRemainingCapacity }}</span>
        </div>
      </div>
      <div
        *ngIf="isHiddenBudgetVendor"
        class="search-result-layout__image-tag-container search-result-layout__image-tag-container--right"
      >
        <div
          class="search-card__tag"
          test-id="vendorHiddenBudgetTag"
        >
          <i class="icon icon--small mr-x-small" aria-hidden="true">visibility_off</i>
          <span>Budget hidden</span>
        </div>
      </div>
      <div
        *ngIf="result.vendorRecommended"
        class="ribbon search-card__top-pick"
        test-id="vendorTopPickRibbon"
      >
        <div class="ribbon__content" test-id="topPickBanner">Top pick by our food experts</div>
      </div>
    </div>

    <div class="search-card__header">
      <div class="search-card__header-content">
        <app-in-viewport analyticsId="search-card-in-viewport"></app-in-viewport>
        <div
          class="search-card__title"
          test-id="cardTitle"
          e2e-test-id="searchVendorCard"
        >
          {{ result.vendorName }}
        </div>
        <div
          *ngIf="result.vendorLocationPublicName"
          class="search-card__location-subtitle"
          test-id="cardLocationSubtitle"
        >
          {{ result.vendorLocationPublicName }}
        </div>
        <div class="search-card__subtitle" test-id="cardSubtitle">
          <span *ngIf="canShowDistance">
            <app-vendor-distance
              [distance]="result.distance"
              [tooltip]="tooltip"
              tooltipPosition="right"
              test-id="vendorDistance"
            >
            </app-vendor-distance>
            <ng-template
              #tooltip
            >
              Distance from your location
            </ng-template>
            -
          </span>
          {{ result.vendorTags | concat:' - ' }}
        </div>
        <app-vendor-rating
          [rating]="result.vendorRating"
          [reviewCount]="result.vendorReviewCount"
          [compact]="true"
          displayValue="reviewCount"
          test-id="rating"
        ></app-vendor-rating>
        <div
          *ngIf="showMainsWithinBudget"
          class="search-card__tag"
          test-id="mainsWithinBudgetWrapper"
        >
          <i class="icon icon--small mr-x-small" aria-hidden="true">account_balance_wallet</i>
          <span test-id="mainsWithinBudget">{{ result.mainsWithinBudget }} {{ 'main' | plural:result.mainsWithinBudget }} within budget</span>
        </div>
      </div>
      <div class="search-card__icon">
        <div
          class="search-card__vendor-logo"
          test-id="vendorLogo"
          [backgroundImage]="result.vendorImage?.thumbnail"
        ></div>
      </div>
    </div>
  </a>
  <div
    *ngIf="showFavouriteButton"
    class="search-result-layout__favourite-button-container"
  >
    <button
      class="material-icon-button material-icon-button--white material-icon-button--color-danger material-icon-button--hover-color-danger material-icon-button--medium material-icon-button--round"
      (click)="onFavouriteToggled()"
      test-id="favouriteButton"
      analyticsId="result-favourite-button"
      [analyticsData]="{ vendorName: result.vendorName, isFavourited: isFavourited }"
      [attr.aria-label]="isFavourited ? 'Remove restaurant from favourites' : 'Add restaurant to favourites'"
    >
      {{ isFavourited ? 'heart' : 'heart_outline' }}
    </button>
  </div>
</div>
