import moment from 'moment';
import { createVendorLocationFromJson, VendorLocation } from '../vendor';
import { DeliveryViabilities, DeliveryViability } from './delivery-viability.enum';

export interface ClassifiedVendorAddress extends VendorLocation {
  viability: DeliveryViability;
  distance: number;
  pickupDate: moment.Moment;
}

export function createClassifiedVendorAddressFromJson(json: Partial<ClassifiedVendorAddress>): ClassifiedVendorAddress {
  if (DeliveryViabilities.values.indexOf(json.viability) < 0) {
    throw new Error(`Unexpected viability "${json.viability}", could not create ClassifiedVendorAddress`);
  }

  const address: Partial<ClassifiedVendorAddress> = createVendorLocationFromJson(json);

  address.viability = json.viability;
  address.distance = json.distance;
  address.pickupDate = json.pickupDate && moment(json.pickupDate);

  return address as ClassifiedVendorAddress;
}

export function classifiedVendorAddressesAreTheSame(address1: ClassifiedVendorAddress, address2: ClassifiedVendorAddress): boolean {
  return address1.addressLine1 === address2.addressLine1 &&
         address1.addressLine2 === address2.addressLine2 &&
         address1.addressLine3 === address2.addressLine3 &&
         address1.city === address2.city &&
         address1.postcode === address2.postcode &&
         address1.country === address2.country;
}
