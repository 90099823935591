import { Allergens, EMPTY_ALLERGENS } from './allergens.model';
import { EMPTY_DIETARIES } from './dietaries.model';
import { FoodType } from './food-type.enum';
import { EMPTY_AVAILABILITY } from './item-availability.model';
import { ItemTypes } from './item-type.enum';
import { Item } from './item.model';

export interface SingleItem extends Item {
  foodType: FoodType;
  portionSize: number;

  // Travel Requirements
  hot: boolean;
  fragile: boolean;
  oversized: boolean;
  returnRequired: boolean;

  // Ingredients and Allergens
  ingredients: string[];
  allergens: Allergens;
  spicy: boolean;
  containsAlcohol: boolean;
  ageRestricted: boolean;
  alcoholPercentage: number | null;
  kcal: number | null;
}

export function createEmptySingleItem(currencyIsoCode: string): SingleItem {
  return {
    type: ItemTypes.SINGLE_ITEM,
    name: '',
    description: '',
    dietaries: EMPTY_DIETARIES,
    images: [],
    price: null,
    currencyIsoCode,
    notice: null,
    minimumOrderQuantity: null,
    maximumOrderQuantity: null,
    events: [],
    servingStyle: null,
    cuisine: null,
    ecoFriendlyPackaging: null,
    portionSize: 1,
    hot: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    fragile: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    oversized: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    returnRequired: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    vatRateType: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    spicy: null,
    containsAlcohol: null,
    ageRestricted: null,
    alcoholPercentage: null,
    allergens: EMPTY_ALLERGENS,
    ingredients: [],
    foodType: null,
    availability: EMPTY_AVAILABILITY,
    kcal: null,
  };
}

export function isSingleItem(item: Item): item is SingleItem {
  return item.type === ItemTypes.SINGLE_ITEM;
}

// For the createFromJson() method see create-item.ts
