import { Injectable } from '@angular/core';
import { Client, DataCollector, ThreeDSecure } from 'braintree-web';

/**
 * The braintree-web package only has a declaration file at the root level. We could import this
 * like import * as braintree from 'braintree-web', but this would result in 40kb of unnecessary
 * code being imported. Therefore, we want to just import the submodules we actually need, but
 * since these do not contain their own declaration files, TS throws an error, which we ignore.
 */
// @ts-ignore: Could not find a declaration file for module
import braintreeClient from 'braintree-web/client';
// @ts-ignore: Could not find a declaration file for module
import braintreeDataCollector from 'braintree-web/data-collector';
// @ts-ignore: Could not find a declaration file for module
import threeDSecure from 'braintree-web/three-d-secure';

@Injectable({ providedIn: 'root' })
export class Braintree {

  private client: Promise<Client>;
  private dataCollector: Promise<DataCollector>;
  private threeDSecure: Promise<ThreeDSecure>;

  public getClient(): Promise<Client> {
    if (!this.client) {
      this.initialiseClient();
    }

    return this.client;
  }

  public getDataCollector(): Promise<DataCollector> {
    if (!this.dataCollector) {
      this.initialiseDataCollector();
    }

    return this.dataCollector;
  }

  public getThreeDSecure(): Promise<ThreeDSecure> {
    if (!this.threeDSecure) {
      this.initialiseThreeDSecure();
    }

    return this.threeDSecure;
  }

  private initialiseClient(): void {
    this.client = Promise.resolve(braintreeClient);
  }

  private initialiseDataCollector(): void {
    this.dataCollector = Promise.resolve(braintreeDataCollector);
  }

  private initialiseThreeDSecure(): void {
    this.threeDSecure = Promise.resolve(threeDSecure);
  }
}
