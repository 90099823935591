<div
  class="row mb-standard"
  analyticsId="upgrade-group-item"
>
  <div class="col-auto">
    <div class="row">
      <div class="col col-auto">
        <app-quantity-picker
          [formControl]="control"
          [max]="canOverrideQuantities ? 0 : item.maximumOrderQuantity"
          test-id="quantityPicker"
        ></app-quantity-picker>
      </div>
    </div>
    <div class="row">
      <div class="col col-auto mt-medium-small">
        <button
          class="button-link"
          (click)="onRemove()"
          test-id="removeButton"
        >Remove</button>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="row pt-x-small justify-content-between">
      <div class="col col-auto">
        <span test-id="name">{{ item.name }} </span>
        <a
          class="link link--light link--small text-nowrap"
          (click)="onToggleShowMoreInfo()"
          test-id="toggleInfoLink"
        >{{ showMoreInfo ? 'Less info' : 'More info' }}</a>
        <div
          *ngIf="shouldShowKcals()"
          class="mt-small text-color-stone-2"
          test-id="kcal"
        >{{ item.kcal }}kcal per portion</div>
      </div>
      <div
        *ngIf="!hidePrices"
        class="col col-auto text-right"
        test-id="price"
      >
        <span class="text-nowrap">
          +{{ item.price | currency:'GBP' }} each
        </span>
      </div>
      <div class="col col-12 mt-medium-small d-flex">
        <span
          *ngIf="item.ageRestricted"
          class="icon icon--medium"
          aria-label="Age restricted"
          test-id="ageRestricted"
        >age_restricted</span>
        <app-dietaries
          *ngIf="hasDietaries()"
          class="vertical-separator"
          [dietaries]="item.dietaries"
          test-id="dietaries"
        ></app-dietaries>
      </div>
    </div>
  </div>
</div>
