import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const loginPageRules: AnalyticsRule[] = [
  {
    url: '/login',
    path: 'sso-login-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.LOGIN,
      label: `SSO login button clicked`,
    }
  },
  {
    url: '/login',
    path: 'email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.LOGIN,
      label: `Login email field blur`,
    }
  },
  {
    url: '/login',
    path: 'login',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.LOGIN,
      label: `Login button clicked`,
    }
  },
  {
    url: '/login',
    path: 'register',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.LOGIN,
      label: `Register link clicked`,
    }
  },
];
