import { UnreachableCaseError } from '@citypantry/util';
import moment from 'moment';
import {
  createDeliverableMenuFromJson,
  createItemDeliverabilityFromJson,
  DeliverableMenu,
  ItemDeliverability,
  ItemDeliverabilityProblem,
  ItemDeliverabilityProblems
} from '../menu';
import { CartDeliverabilityProblemType, CartDeliverabilityProblemTypes } from './cart-deliverability-problem-type.enum';

export interface CartDeliverabilityProblem {
  type: CartDeliverabilityProblemType;
  overridable: boolean;
}

export function isCartDeliverabilityProblem(problem: any): problem is CartDeliverabilityProblem {
  return problem.hasOwnProperty('type') && CartDeliverabilityProblemTypes.values.indexOf(problem.type) >= 0;
}

export interface OutsideMenuSchedule extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE;
  vendorName: string;
  scheduledMenu: DeliverableMenu | null;
  deliveryDate: moment.Moment;
}

export function isOutsideMenuScheduleProblem(problem: any): problem is OutsideMenuSchedule {
  return problem && problem.hasOwnProperty('type') && problem.type === CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE;
}

export function createOutsideMenuScheduleFromJson(json: Partial<OutsideMenuSchedule>): OutsideMenuSchedule {
  return {
    type: CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE,
    overridable: !!json.overridable,
    vendorName: json.vendorName,
    scheduledMenu: json.scheduledMenu ? createDeliverableMenuFromJson(json.scheduledMenu) : null,
    deliveryDate: moment(json.deliveryDate)
  };
}

export interface SameDayOrder extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.SAME_DAY_ORDER;
  supportPhoneNumber: string;
}

export function createSameDayOrderFromJson(json: Partial<SameDayOrder>): SameDayOrder {
  return {
    type: CartDeliverabilityProblemTypes.SAME_DAY_ORDER,
    overridable: !!json.overridable,
    supportPhoneNumber: json.supportPhoneNumber
  };
}

export interface TomorrowOrderPastDeadline extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE;
  deadlineTime: moment.Moment;
}

export function createTomorrowOrderPastDeadlineFromJson(json: Partial<TomorrowOrderPastDeadline>): TomorrowOrderPastDeadline {
  return {
    type: CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE,
    overridable: !!json.overridable,
    deadlineTime: moment.tz(`${ json.deadlineTime }`, 'HH:mm', 'Europe/London')
  };
}

export interface UndeliverableItems extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS;
  itemDeliverabilities: ItemDeliverability[];
  vendorName: string;
}

export function createUndeliverableItemsFromJson(json: Partial<UndeliverableItems>): UndeliverableItems {
  return {
    type: CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS,
    overridable: !!json.overridable,
    itemDeliverabilities: (json.itemDeliverabilities || []).map(createItemDeliverabilityFromJson),
    vendorName: json.vendorName
  };
}

export function isUndeliverableItemsProblem(problem: any): problem is UndeliverableItems {
  return problem && problem.hasOwnProperty('type') && problem.type === CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS;
}

export interface VendorCapacityExceeded extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED;
  vendorName: string;
  deliveryDate: moment.Moment;
  maxCapacityExceeded: boolean;
  capacityExceededBy: number;
}

export function createVendorCapacityExceededFromJson(json: Partial<VendorCapacityExceeded>): VendorCapacityExceeded {
  return {
    type: CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED,
    overridable: !!json.overridable,
    vendorName: json.vendorName,
    maxCapacityExceeded: !!json.maxCapacityExceeded,
    deliveryDate: moment(json.deliveryDate),
    capacityExceededBy: json.capacityExceededBy
  };
}

export function isVendorCapacityExceededProblem(problem: any): problem is VendorCapacityExceeded {
  return problem && problem.hasOwnProperty('type') && problem.type === CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED;
}

export interface VendorOnHoliday extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.VENDOR_ON_HOLIDAY;
  vendorName: string;
  deliveryDate: moment.Moment;
}

export function createVendorOnHolidayFromJson(json: Partial<VendorOnHoliday>): VendorOnHoliday {
  return {
    type: CartDeliverabilityProblemTypes.VENDOR_ON_HOLIDAY,
    overridable: !!json.overridable,
    vendorName: json.vendorName,
    deliveryDate: moment(json.deliveryDate)
  };
}

export interface CpOnHoliday extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.CP_ON_HOLIDAY;
  vendorName: string;
  deliveryDate: moment.Moment;
}

export function createCpOnHolidayFromJson(json: Partial<CpOnHoliday>): CpOnHoliday {
  return {
    type: CartDeliverabilityProblemTypes.CP_ON_HOLIDAY,
    overridable: !!json.overridable,
    vendorName: json.vendorName,
    deliveryDate: moment(json.deliveryDate)
  };
}

export interface ItemMenuMismatch extends CartDeliverabilityProblem {
  type: typeof CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH;
}

export function createItemMenuMismatchFromJson(json: Partial<ItemMenuMismatch>): ItemMenuMismatch {
  return {
    type: CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH,
    overridable: !!json.overridable,
  };
}

export function isItemMenuMismatchProblem(problem: any): problem is ItemMenuMismatch {
  return problem && problem.hasOwnProperty('type') && problem.type === CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH;
}

export function createCartDeliverabilityProblemFromJson(json: Partial<CartDeliverabilityProblem> = {}): CartDeliverabilityProblem {
  switch (json.type) {
    case CartDeliverabilityProblemTypes.CP_ON_HOLIDAY:
      return createCpOnHolidayFromJson(json as Partial<CpOnHoliday>);
    case CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE:
      return createOutsideMenuScheduleFromJson(json as Partial<OutsideMenuSchedule>);
    case CartDeliverabilityProblemTypes.SAME_DAY_ORDER:
      return createSameDayOrderFromJson(json as Partial<SameDayOrder>);
    case CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE:
      return createTomorrowOrderPastDeadlineFromJson(json as Partial<TomorrowOrderPastDeadline>);
    case CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS:
      return createUndeliverableItemsFromJson(json as Partial<UndeliverableItems>);
    case CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED:
      return createVendorCapacityExceededFromJson(json as Partial<VendorCapacityExceeded>);
    case CartDeliverabilityProblemTypes.VENDOR_ON_HOLIDAY:
      return createVendorOnHolidayFromJson(json as Partial<VendorOnHoliday>);
    case CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH:
      return createItemMenuMismatchFromJson(json as Partial<ItemMenuMismatch>);
    default:
      throw new UnreachableCaseError(json.type);
  }
}

export interface ProblemWithItemIds {
  problem: ItemDeliverabilityProblem;
  itemIds: string[];
}

/**
 * Returns problems in priority order with a list of item IDs of that problem
 */
export function getProblemsWithItemIds(undeliverableItems: UndeliverableItems): ProblemWithItemIds[] {
  const problems: { [ problem: string ]: string[] } = {};
  undeliverableItems.itemDeliverabilities.forEach((deliverability) =>
    deliverability.problems.forEach((problem) => {
      (problems[problem] || (problems[problem] = [])).push(deliverability.itemId);
    })
  );

  return ItemDeliverabilityProblems.priority
    .map((problem) => ({
      problem,
      itemIds: problems[problem]
    }))
    .filter((problem) => problem.itemIds && problem.itemIds.length);
}
