import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const addEaterToLocationModalRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'add-eater-to-location-modal bottom-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      label: 'Add eater to location modal seen'
    }
  },
  {
    url: '*',
    path: 'add-eater-to-location-modal dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      label: 'Close modal'
    }
  },
  {
    url: '*',
    path: 'add-eater-to-location-modal dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      label: 'Cancel'
    }
  },
  {
    url: '*',
    path: 'add-eater-to-location-modal',
    events: {
      class: 'EaterLocationAddModalComponent',
      eventName: 'addLocationClicked'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      label: 'Add location',
      extraFields: {
        locationId: event.event.id
      }
    })
  },
  {
    url: '*',
    path: 'add-eater-to-location-modal',
    events: {
      class: 'EaterLocationAddModalComponent',
      eventName: 'cancelAddLocationClicked',
    },
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.ADD_EATER_TO_LOCATION_MODAL,
      label: 'Close modal'
    }
  },
];
