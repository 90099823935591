import { environment } from '@citypantry/shared-app-config';
import { mapSelectors } from '@citypantry/util';
import { CartManager } from '@citypantry/util-cart-manager';
import { CartCustomItem, CartItemBundle, findMenuItemById, isBundleCartItem, isCustomCartItem } from '@citypantry/util-models';
import { getIndividualChoiceState } from '../app.state';
import { findCurrentChildOrder, IndividualChoiceState } from './individual-choice.state';

const getAdditionalReference = (state: IndividualChoiceState) => {
  const orders = state.currentChildOrderId && state.individualChoiceOrder && state.individualChoiceOrder.orders;

  if (!orders) {
    return '';
  }

  return orders.find((order) => order.id === state.currentChildOrderId).extraItemsAdditionalReference;
};
const getCartItems = (state: IndividualChoiceState) => {
  const currentOrder = findCurrentChildOrder(state);
  if (!currentOrder) {
    return [];
  } else {
    return currentOrder.extraItems;
  }
};
const getCurrentOrderMenu = (state: IndividualChoiceState) => state.extras.currentOrderMenu;
const getCartTotal = (state: IndividualChoiceState) => CartManager.getCartTotal(getCartItems(state));
const getMenuContent = (state: IndividualChoiceState) => {
  const extrasMenuContent = state.extras.currentOrderMenu && state.extras.currentOrderMenu.content;
  return extrasMenuContent || null;
};
const getMenuItemDeliverabilities = (state: IndividualChoiceState) =>
  (state.extras.currentOrderMenu && state.extras.currentOrderMenu.itemDeliverabilities) || null;
const getOpenCartItem = (state: IndividualChoiceState): CartCustomItem | CartItemBundle | null => {
  const openItem = state.extras && state.extras.openItem;
  if (!openItem || typeof openItem.cartIndex !== 'number') {
    return null;
  }

  const cartItem = getCartItems(state)[openItem.cartIndex];
  if (!cartItem || !(isCustomCartItem(cartItem) || isBundleCartItem(cartItem)) || openItem.itemId !== cartItem.item.id) {
    if (environment.production) {
      return null;
    } else {
      throw new Error('Invalid cart item or cart item ID');
    }
  }

  return cartItem;
};
const getOpenItem = (state: IndividualChoiceState) => {
  const menuContent = getMenuContent(state);

  if (!menuContent || !state.extras.openItem) {
    return null;
  }

  const itemId = state.extras.openItem.itemId;
  return findMenuItemById(menuContent, itemId) || null;
};
const isSubmitting = (state: IndividualChoiceState) => state.extras.isSubmitting;
const isAgeConfirmationDialogVisible = (state: IndividualChoiceState) => !!state.extras.ageConfirmationDialogVisible;

export const IndividualChoiceExtrasSelectors = mapSelectors(getIndividualChoiceState, {
  getAdditionalReference,
  getCartItems,
  getCartTotal,
  getCurrentOrderMenu,
  getMenuContent,
  getMenuItemDeliverabilities,
  getOpenItem,
  getOpenCartItem,
  isSubmitting,
  isAgeConfirmationDialogVisible,
});
