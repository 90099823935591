import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  fragment?: string;
}

@Injectable()
export class BasicRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const url = routerState.url;
    const queryParams = routerState.root.queryParams;
    const params = route.params;
    const fragment = route.fragment;

    // Only return an object including the URL, params, query params and fragment
    // instead of the entire snapshot
    return { url, params, queryParams, fragment };
  }
}
