import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-promotion-panel',
  templateUrl: './promotion-panel.component.html'
})
export class PromotionPanelComponent {

  @Input()
  public promotionName: string;

  @Input()
  public icon: string;

  @Input()
  public title: string;

  @Input()
  public body1: string;

  @Input()
  public ctaText: string;

  @Input()
  public ctaLink: string;

  @Input()
  public body2: string;
}
