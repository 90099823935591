
import { createRefundBreakdownFromJson, RefundBreakdown } from './refund.model';

export interface OrderVendorRefund {
  breakdown: RefundBreakdown;
  reason: string;
}

export function createOrderVendorRefundFromJson(json: Partial<OrderVendorRefund>): OrderVendorRefund {
  if (!json) {
    throw new Error('OrderRefund is null or undefined, cannot be created');
  }

  return {
    breakdown: createRefundBreakdownFromJson(json.breakdown),
    reason: json.reason
  };
}
