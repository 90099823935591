import { ensureValidEnumValue } from '@citypantry/util';
import { CartItem, createCartItemFromJson } from '../../cart';
import { SimpleUser, createSimpleUserFromJson } from '../../customer';
import { MajorCurrency, MixedCost, createMixedCostFromJson } from '../../money';
import { CostBreakdown, createCostBreakdownFromJson } from '../../order/cost-breakdown.model';
import { OrderId } from '../../order/order-id.model';
import {
  SimpleVendor,
  SimpleVendorLocation,
  createSimpleVendorFromJson,
  createSimpleVendorLocationFromJson
} from '../../vendor';
import { EaterCartStatus, EaterCartStatuses } from './eater-cart-status.enum';
import { EaterCostBreakdown, createEaterCostBreakdownFromJson } from './eater-cost-summary.model';

declare const EaterCartIdSymbol: unique symbol;
export type EaterCartId = string & {
  [EaterCartIdSymbol]: never;
};

/**
 * A lead user's view of an eater's meal, used e.g. on the WoW page.
 */
export interface EaterCartsSummary {
  user: SimpleUser;
  cartSummaries: EaterCartListItem[];
}

export interface EaterCart {
  orderId: OrderId;
  orderHumanId: number;
  vendor: SimpleVendor | null;
  selectedVendorLocation: SimpleVendorLocation | null;
  cartItems: CartItem[];
  costBreakdown: CostBreakdown;
  customerCostBreakdown: CostBreakdown;
  eaterCostBreakdown: EaterCostBreakdown;
  isMarkedAsAway: boolean;
  status: EaterCartStatus;
  refundableAmount: MixedCost | null;
  remainingBudget: MajorCurrency;
  minimumTransactionFee: MajorCurrency;
  departmentReference: string;
  deskNumber?: string | null;
  isEligibleForFirstTopUpPromotion: boolean; // TODO CPD-14799 remove if experiment failed
}

export type EaterCartListItem = Pick<EaterCart,
  'orderId' |
  'vendor' |
  'selectedVendorLocation' |
  'cartItems' |
  'costBreakdown' |
  'customerCostBreakdown' |
  'eaterCostBreakdown' |
  'isMarkedAsAway' |
  'status' |
  'refundableAmount' |
  'minimumTransactionFee' |
  'departmentReference' |
  'deskNumber'
>;

export function createEaterCartsSummaryFromJson(json?: Partial<EaterCartsSummary>): EaterCartsSummary {
  json = json || {};
  const summary: Partial<EaterCartsSummary> = {};

  summary.user = createSimpleUserFromJson(json.user);
  summary.cartSummaries = !json.cartSummaries ? [] : json.cartSummaries.map(createEaterCartListItemFromJson);
  summary.cartSummaries.sort((a, b) => a.vendor.name.localeCompare(b.vendor.name));

  return summary as EaterCartsSummary;
}

export function createEaterCartFromJson(json?: Partial<EaterCart>): EaterCart {
  json = json || {};
  const cart: Partial<EaterCart> = {};

  cart.orderId = json.orderId;
  cart.orderHumanId = json.orderHumanId;
  cart.cartItems = (json.cartItems || []).map(createCartItemFromJson);
  cart.costBreakdown = createCostBreakdownFromJson(json.costBreakdown);
  cart.customerCostBreakdown = createCostBreakdownFromJson(json.customerCostBreakdown);
  cart.eaterCostBreakdown = createEaterCostBreakdownFromJson(json.eaterCostBreakdown);
  cart.isMarkedAsAway = !!json.isMarkedAsAway;
  cart.vendor = json.vendor !== undefined ? createSimpleVendorFromJson(json.vendor) : null;
  cart.selectedVendorLocation = json.selectedVendorLocation ? createSimpleVendorLocationFromJson(json.selectedVendorLocation) : null;
  cart.status = ensureValidEnumValue(EaterCartStatuses, json.status);
  cart.refundableAmount = json.refundableAmount ? createMixedCostFromJson(json.refundableAmount) : null;
  cart.minimumTransactionFee = json.minimumTransactionFee || 0;
  cart.departmentReference = json.departmentReference ? json.departmentReference : '';
  cart.remainingBudget = json.remainingBudget || 0;
  cart.deskNumber = json.deskNumber;
  cart.isEligibleForFirstTopUpPromotion = !!json.isEligibleForFirstTopUpPromotion;

  return cart as EaterCart;
}

export function createEaterCartListItemFromJson(json?: Partial<EaterCartListItem>): EaterCartListItem {
  json = json || {};
  const cart: EaterCart = createEaterCartFromJson(json);

  delete cart.remainingBudget;

  return cart as EaterCartListItem;
}
