import { createItemFromJson } from './items/create-item';
import { Item } from './items/item.model';

export type SectionSelectedStatus = 'all' | 'some' | 'none';

export interface Section {
  title: string;
  items: Item[];
  hidden: boolean;
  selected?: SectionSelectedStatus;
}

export function createSectionFromJson(json: Partial<Section>): Section {
  if (!json) {
    throw new Error('Section is null or undefined, cannot be created');
  }

  const section: Partial<Section> = {};
  section.title = json.title;
  section.items = (json.items || []).map(createItemFromJson);
  section.hidden = json.hidden;

  return section as Section;
}
