<div
  class="row mb-standard"
  analyticsId="flexible-group-item"
>
  <div class="col-auto">
    <app-quantity-picker
      [formControl]="control"
      test-id="quantityPicker"
    ></app-quantity-picker>
  </div>
  <div class="col">
    <div class="row align-items-center">
      <div class="col mb-2 mb-md-0">
        <div>
          <span test-id="name">{{ item.name }}</span>
          <a
            class="link link--light link--small text-nowrap ml-1"
            (click)="onToggleShowMoreInfo()"
            test-id="toggleInfoLink"
          >{{ showMoreInfo ? 'Less info' : 'More info' }}</a>
        </div>
        <div class="mt-small">
          <div
            *ngIf="shouldShowKcals()"
            class="text-color-stone-2"
            test-id="kcal"
          >{{ item.kcal }}kcal per portion</div>
        </div>
      </div>
      <div
        *ngIf="item.ageRestricted || hasDietaries()"
        class="col-12 col-md-auto d-flex"
      >
        <span
          *ngIf="item.ageRestricted"
          class="icon icon--medium"
          aria-label="Age restricted"
          test-id="ageRestricted"
        >age_restricted</span>
        <app-dietaries
          *ngIf="hasDietaries()"
          class="vertical-separator"
          [dietaries]="item.dietaries"
          test-id="dietaries"
        ></app-dietaries>
      </div>
    </div>
  </div>
</div>
