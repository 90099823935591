import { Order, SearchRequest } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const CheckoutActions = {
  thankYouPageLoad: createAction(
    '[Load Thank You Page Guard] Page Load',
    props<{ order: Order | null }>(),
  ),

  orderPointsFetchSuccess: createAction(
    '[Loyalty API] Order Points Fetch Success',
    props<{ loyaltyPoints: number }>(),
  ),

  orderMore: createAction(
    '[Thank You Page] Order More',
    props<{ searchRequest: SearchRequest }>(),
  ),

  hidePromotionBanner: createAction(
    '[Promotion] Hide promotion banner'
  ),
};

const all = union(CheckoutActions);
export type CheckoutAction = typeof all;
