import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [
    OverlayModule,
    CommonModule,
  ],
  declarations: [
    TooltipDirective,
    TooltipComponent,
  ],
  exports: [
    TooltipDirective,
    TooltipComponent,
  ],
})
export class TooltipComponentsModule {}
