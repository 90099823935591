import { ensureValidEnumValue } from '@citypantry/util';
import moment, { Moment } from 'moment';
import { UserId } from '../user-id.model';
import { MinorCurrency } from '../money';
import { RefundPayer } from './refund-payer.enum';
import { RefundReason } from './refund-reason.enum';
import { RefundRecipient } from './refund-recipient.enum';
import { RefundRefundee } from './refund-refundee.enum';
import { RefundType, RefundTypes } from './refund-type.enum';

// this one we send to backend
export interface Refund {
  amount: number;
  otherReason: string;
  paidBy: RefundPayer;
  percentage: number;
  points: number;
  reason: string;
  type: RefundType;
  peopleToRefund: RefundRecipient;
  eatersToRefund?: UserId[];
}

// this one we receive from backend
export interface OrderRefund {
  breakdown: RefundBreakdown;
  createdAt: Moment;
  paidBy: RefundPayer;
  refundee: RefundRefundee;
  refundeeName: string | null;
  reason: RefundReason;
  otherReason: string;
  requestedPercentage: number | null;
  requestedAmount: number | null;
  type: RefundType;
  value: MinorCurrency | null;
}

export interface RefundBreakdown {
  nonVatableFoodRefund: MinorCurrency;
  vatableFoodRefund: MinorCurrency;
  vatableFoodRefundVAT: MinorCurrency;
  deliveryRefund: MinorCurrency | null;
  deliveryRefundVAT: MinorCurrency | null;
  serviceFeeRefund: MinorCurrency | null;
  serviceFeeRefundVAT: MinorCurrency | null;
  total: MinorCurrency;
}

export function createOrderRefundFromJson(json: Partial<OrderRefund>): OrderRefund {
  if (!json) {
    throw new Error('OrderRefund is null or undefined, cannot be created');
  }

  return {
    breakdown: createRefundBreakdownFromJson(json.breakdown),
    createdAt: moment(json.createdAt),
    paidBy: json.paidBy,
    refundee: json.refundee,
    refundeeName: json.refundeeName || null,
    reason: json.reason,
    otherReason: json.otherReason,
    requestedAmount: typeof json.requestedAmount === 'number' ? json.requestedAmount : null,
    requestedPercentage: typeof json.requestedPercentage === 'number' ? json.requestedPercentage : null,
    type: ensureValidEnumValue(RefundTypes, json.type),
    value: json.value,
  };
}

export function createRefundBreakdownFromJson(json: Partial<RefundBreakdown>): RefundBreakdown {
  if (!json) {
    throw new Error('RefundBreakdown is null or undefined, cannot be created');
  }

  return {
    nonVatableFoodRefund: json.nonVatableFoodRefund,
    vatableFoodRefund: json.vatableFoodRefund,
    vatableFoodRefundVAT: json.vatableFoodRefundVAT,
    deliveryRefund: json.deliveryRefund,
    deliveryRefundVAT: json.deliveryRefundVAT,
    serviceFeeRefund: json.serviceFeeRefund,
    serviceFeeRefundVAT: json.serviceFeeRefundVAT,
    total: json.total,
  };
}
