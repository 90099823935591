import { Component } from '@angular/core';
import { Select } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalDialogSettings } from './global-dialog-settings.model';
import { GlobalDialogActions } from './global-dialog.actions';
import { GlobalDialogSelectors } from './global-dialog.select';
import { GlobalDialogState } from './global-dialog.state';

/*
  CAUTION: GlobalDialogComponent should be a singleton, rendered only in AppComponent.
  Do not render it in any other components.
*/

@Component({
  selector: 'app-global-dialog',
  template: `
    <app-dialog
      [settings]="settings$ | async"
      (complete)="onComplete()"
      test-id="dialog"
    ></app-dialog>
  `
})

export class GlobalDialogComponent {
  @Select(GlobalDialogSelectors.getSettings)
  public settings$: Observable<GlobalDialogSettings>;

  constructor(
    private store: Store<GlobalDialogState>,
  ) {}

  public onComplete(): void {
    this.store.dispatch(GlobalDialogActions.completeDialog());
  }
}
