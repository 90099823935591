import { OrderStatuses } from '@citypantry/util-models';
import moment from 'moment';
import { VendorOrdersQuery } from '../models';

const SORT_DIRECTION_ASC = 'asc';
const SORT_DIRECTION_DESC = 'desc';
const SORT_BY_FIELD = 'requestedDeliveryDate';
const PAGE_NUMBER = 1;
export const VENDOR_ORDERS_PAGE_SIZE = 10;

const DEFAULT_QUERY: VendorOrdersQuery = {
  sortBy: SORT_BY_FIELD,
  sortDir: SORT_DIRECTION_ASC,
  page: PAGE_NUMBER,
  pageSize: VENDOR_ORDERS_PAGE_SIZE,
  pickupAddressIds: [],
  statuses: [],
};

export function transformAcceptedOrdersQuery(query: Partial<VendorOrdersQuery>): VendorOrdersQuery {
  return {
    ...DEFAULT_QUERY,
    ...query,
    sortDir: SORT_DIRECTION_ASC,
    from: query.from || moment().toISOString(),
    statuses: [
      OrderStatuses.CONFIRMED,
      OrderStatuses.BEING_PREPARED,
      OrderStatuses.READY_FOR_COLLECTION,
      OrderStatuses.LEFT_THE_KITCHEN,
      OrderStatuses.DELIVERED,
      ...query.statuses || []
    ],
  };
}

export function transformPastOrdersQuery(query: Partial<VendorOrdersQuery>): VendorOrdersQuery {
  return {
    ...DEFAULT_QUERY,
    ...query,
    sortDir: SORT_DIRECTION_DESC,
    to: query.to || moment().toISOString(),
    statuses: [
      // CPD-10153 show all orders except cancelled.
      // This makes the past orders tab a catch all for orders that don't fit into the other tabs.
      OrderStatuses.PLACED,
      OrderStatuses.VENDOR_APPROVAL_PENDING,
      OrderStatuses.CONFIRMED,
      OrderStatuses.BEING_PREPARED,
      OrderStatuses.READY_FOR_COLLECTION,
      OrderStatuses.LEFT_THE_KITCHEN,
      OrderStatuses.DELIVERED,
      ...query.statuses || []
    ],
  };
}

export function transformPendingOrdersQuery(query: Partial<VendorOrdersQuery>): VendorOrdersQuery {
  return {
    ...DEFAULT_QUERY,
    ...query,
    sortDir: SORT_DIRECTION_ASC,
    from: query.from || moment().toISOString(),
    statuses: [
      OrderStatuses.PLACED,
      OrderStatuses.VENDOR_APPROVAL_PENDING,
      ...query.statuses || []
    ],
  };
}
