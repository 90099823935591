<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://business.just-eat.co.uk/privacy-policy', label: 'Privacy Policy' }"></ng-container>

  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/my-meals'], label: 'Meals' }"></ng-container>

  <app-header-dropdown
    [triggerLabel]="'My Account'"
    e2e-test-id="accountNavigationLink"
    test-id="dropdown"
  >
    <ng-container *ngTemplateOutlet="authenticatedUserLinksTemplate"></ng-container>
  </app-header-dropdown>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://citypantrysupport.zendesk.com', label: 'Support', analyticsId: 'supportLink' }"></ng-container>
</ul>
