import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem } from '@citypantry/util';
import {
  Cart,
  createCartFromJson,
  createDeliverableMenuFromJson,
  createMealPlanFromJson,
  DeliverableMenu,
  MealPlan
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MealPlanApi {

  constructor(
    private http: HttpClient
  ) {}

  public getMealPlan(id: string): Observable<MealPlan> {
    return this.http.get<{ mealPlan: any }>(`/meal-plan/${id}`).pipe(
      map(({ mealPlan }) => mealPlan),
      map(createMealPlanFromJson),
    );
  }

  public replaceCart(id: string, cart: Cart, proposedOrderId: string): Observable<any> {
    return this.http.put(`/mealplans/${id}/orders/${proposedOrderId}/cart`, cart).pipe(
      map(createMealPlanFromJson),
    );
  }

  public getProposedOrderCart(mealPlanId: string, proposedOrderId: string): Observable<Cart> {
    return this.http.get(`/mealplans/${mealPlanId}/orders/${proposedOrderId}/cart`).pipe(
      getItem(),
      map(createCartFromJson)
    );
  }

  public getProposedOrderMenu(mealPlanId: string, requestedDeliveryDate: string): Observable<DeliverableMenu> {
    return this.http.get(`/mealplans/${mealPlanId}/orders/${requestedDeliveryDate}/menu`).pipe(
      getItem(),
      map(createDeliverableMenuFromJson)
    );
  }
}
