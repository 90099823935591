import { Provider } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, combineReducers, select, Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, isObservable, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import sinon from 'sinon';
import { AppState, reducers } from './app.state';
import { StoreInjector } from './decorators/store-injector';

// In real life, looks like store.select.withSelector(selector).returns(value)
export interface StubbableSelect<S> {
  withSelector<K>(selector: (s: S) => K): StubbableSelect.PartialStub<K>;
}
export namespace StubbableSelect {
  export interface PartialStub<K> {
    returns(value: K | Observable<K>): void;
  }
}

interface FakeSelectorData<K> {
  subject: Subject<K>;
  subscription: Subscription;
  selector(s: any): K;
}

export interface AppStateWithFeatureState extends Partial<AppState> {
  [key: string]: { [key: string]: any };
}

export class MockStore<S = AppState> {

  /**
   * Helper for using this in tests.
   * @example
   * new MyComponent(mockStore); // Cannot assign "MockStore" to "Store<AppState>"
   * new MyComponent(mockStore.cast); // OK
   */
  public get cast(): Store<S> {
    return this as unknown as Store<S>;
  }
  public state$: Observable<S>;
  public dispatch: ((action: Action) => void) & sinon.SinonSpy & { calls$: Observable<Action> };
  /* eslint-disable-next-line deprecation/deprecation */ // TS infers the wrong overload
  public select: typeof Store.prototype.select & StubbableSelect<S>;
  public pipe: typeof Observable.prototype.pipe;
  public lift: typeof Store.prototype.lift;

  private readonly rootState$: BehaviorSubject<AppStateWithFeatureState>;
  private readonly reducer: ActionReducer<AppStateWithFeatureState>;
  private readonly selectorsUsedBeforeStubbing: FakeSelectorData<any>[] = [];
  private readonly selectorsUsedAfterStubbing: FakeSelectorData<any>[] = [];

  /**
   * Constructs a new MockStore. The arguments are internal-only, and should not be used by consumers.
   *
   * @param rootState$       The root state of the store. This is a Subject containing the current root state of the store.
   *                         When creating derived ("Feature") states (a deprecated pattern) the root store should pass its value
   *                         down into the child stores.
   * @param baseReducers     The base reducers of the store. Unless they are being overridden, these are usually the reducers used to create
   *                         the real Store<AppState>.
   * @param disableSelectors If set to true, all selectors will not emit values until they are stubbed.
   *                         This is different from how a real store would behave, but prevents Page components
   *                         from throwing NullPointerExceptions.
   */
  constructor(
    rootState$?: BehaviorSubject<AppStateWithFeatureState>,
    baseReducers: ActionReducerMap<AppStateWithFeatureState> = reducers,
    private readonly disableSelectors: boolean = false,
  ) {
    this.reducer = combineReducers({ ...baseReducers }) as ActionReducer<AppStateWithFeatureState>;
    const initialState = this.reducer(undefined, { type: '' });
    this.rootState$ = rootState$ || new BehaviorSubject(initialState);
    this.dispatch = this.createDispatch();
    this.state$ = this.rootState$ as any as Observable<S>;

    this.select = this.createFakeSelect(this.disableSelectors);
    this.pipe = this.state$.pipe.bind(this.state$);
    this.lift = this.state$.lift.bind(this.state$);
    this.apply = this.apply.bind(this);

    // Store MockStore in the StoreInjector so that we can reference the last created instance from tests
    StoreInjector.store = this as unknown as Store<AppState>;
  }

  /**
   * Sets the AppState and merges only the top-level child states (i.e. BuilderState, MenuState)
   * The child states will be set in their entirety, meaning all properties in the existing state will be replaced.
   *
   * @deprecated Use apply() to set up the state
   */
  public updatePartialState(partialState: Partial<AppStateWithFeatureState>): void {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }
    const previousState = this.rootState$.getValue();
    const newState = {
      ...previousState,
      ...partialState
    } as unknown as AppState;
    this.rootState$.next(newState as AppStateWithFeatureState);
  }

  /**
   * Resets the AppState to its initial value, plus any provided state.
   * All previously set state is ignored.
   *
   * @deprecated Use apply() to set up the state
   */
  public setState(state: Partial<AppStateWithFeatureState>): void {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }
    const initialState = combineReducers(reducers)(undefined, { type: '' }) as AppState;
    const newState = {
      ...initialState,
      ...state
    } as AppStateWithFeatureState;
    this.rootState$.next(newState);
  }

  /**
   * Mirror the given state, so that whenever the state changes, this store's state matches it.
   *
   * @deprecated Use apply() to set up the state
   */
  public reflectState(reflectedState: Observable<Partial<AppStateWithFeatureState>>): void {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }
    /* eslint-disable-next-line deprecation/deprecation */
    reflectedState.subscribe((state) => this.updatePartialState(state));
  }

  /**
   * Dispatch a series of actions as you would on the real state. This does not register on the dispatch() method.
   *
   * Use this method for setting up test preconditions.
   */
  public apply(...actions: Action[]): void {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }

    let currentState = this.rootState$.getValue();
    for (const action of actions) {
      currentState = this.reducer(currentState, action);
    }
    this.rootState$.next(currentState);
  }

  public subscribeToActions(action$: Observable<Action>): void {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }
    action$.subscribe((action) => {
      this.apply(action);
    });
  }

  /**
   * TODO: this method should be removed as part of CPD-17356
   * Synchronously fetch the current store state.
   * Remember that this function always returns the root state, so you need to start with a root selector.
   */
  public get<T>(...selectors: Function[]): T {
    if (this.disableSelectors) {
      throw new Error('This method does nothing when called on MockStore.withoutState()');
    }
    return selectors.reduce((value, selector) => selector(value), this.rootState$.getValue());
  }

  private createDispatch(): MockStore['dispatch'] {
    const subject = new Subject<Action>();
    const next = (value: Action) => {
      subject.next(value);
    };
    (next as any).displayName = 'store.dispatch()';

    const stub: any = sinon.spy(next);
    stub.calls$ = subject.asObservable();

    return stub;
  }

  /**
   * Creates the store.select() method.
   * This method allows calling store.select.withSelector(selectorFn).returns(value),
   * where value is either a raw value or an Observable.
   *
   * The behaviour of the fake selector heavily depends on the moment in which it gets stubbed / subscribed
   *
   *
   * CASE 1: Subscription before the selector is stubbed + the selector has not been used yet
   *
   *   In absence of already created fake selectors, returns
   *   a fake selector built on top of the real values that come from the store.
   *   The returned selector is effectively a clone of the real selector.
   *
   *
   * CASE 2: Subscription before the selector is stubbed + the selector has already been subscribed to by someone else
   *
   *   Returns existing cached fake selector (that is a clone of the real selector).
   *
   *
   * CASE 3: Subscription after the selector has been stubbed
   *
   *   When we stub a selector, we create a new fake selector that wraps the passed observable, and we store it in a
   *   separate array of fake selectors
   *
   *   - The array, where we store clones of the original selectors, is to be consumed by subscribers
   *     who subscribed BEFORE the selector has been stubbed
   *   - The array, where we store fake stubbed selectors, is to be consumed by subscribers
   *     who come AFTER, when the selector has already been stubbed
   *
   *   Subscribers who subscribe BEFORE stubbing need to immediately stream the first value that is currently in the store;
   *   but subscribers who subscribe AFTER stubbing SHOULD NOT see the first value.
   *   This way a spec can emulate a selector that waits before emitting.
   *
   *   That's why we cannot reuse the fake selector that is already cached,
   *   that, being a ReplaySubject, would automatically stream immediately the initial value.
   *
   *
   * CASES SUMMARY:
   *  - If the list of fake selectors stubbed AFTER subscription already contains the selector, replace its subscriptions
   *    with the new observable
   *  - If the above list does not contain any existing selector, then it gets created and stored
   *  - Separately, after the selector has been stubbed, we check if any selector used BEFORE stubbing exists
   *    and replace its subscriptio

   */
  /* eslint-disable-next-line deprecation/deprecation */ // TS infers the wrong overload
  private createFakeSelect(disableSelectors: boolean): typeof Store.prototype.select & StubbableSelect<S> {
    const state$ = this.state$;
    const usedBeforeStubbing = this.selectorsUsedBeforeStubbing;
    const usedAfterStubbing = this.selectorsUsedAfterStubbing;

    const findFakeSelectorData = <K>(childSelector: (s: S) => K, target: FakeSelectorData<K>[]): FakeSelectorData<K> =>
      target.find(({ selector }) => selector === childSelector);

    const createFakeSelector = <K>(
      childSelector: (s: S) => K,
      observable: Observable<K>,
      target: FakeSelectorData<K>[]
    ): FakeSelectorData<K> => {
      // Using a ReplaySubject mimics the Store, so anyone subscribing gets the last value.
      // We can't use a BehaviorSubject because that requires an initial value and we don't have one yet.
      const subject: Subject<K> = new ReplaySubject(1);
      const subscription = observable.subscribe((value) => subject.next(value));
      const fakeSelectorData = {
        selector: childSelector,
        subject,
        subscription,
      };
      target.push(fakeSelectorData);

      return fakeSelectorData;
    };

    const replaceSubscription = <K>(fakeSelectorData: FakeSelectorData<K>, newStubbed: Observable<K>): void => {
      fakeSelectorData.subscription.unsubscribe(); // Terminate whatever is currently piping into the subject
      fakeSelectorData.subscription = newStubbed.subscribe((value) => fakeSelectorData.subject.next(value));
    };

    // This is the main `store.select` function. All it does is expose the cached value's subject.
    function fakeSelect<K>(childSelector: (s: S) => K): Observable<K> {
      let fakeSelectorData =
        findFakeSelectorData(childSelector, usedAfterStubbing) ||
        findFakeSelectorData(childSelector, usedBeforeStubbing);

      if (!fakeSelectorData) {
        const selectFromStore = disableSelectors ? EMPTY : state$.pipe(select(childSelector));
        fakeSelectorData = createFakeSelector(childSelector, selectFromStore, usedBeforeStubbing);
      }

      return fakeSelectorData.subject.asObservable();
    }

    fakeSelect.withSelector = <K>(childSelector: (s: S) => K): StubbableSelect.PartialStub<K> => ({
      returns: (value: K | Observable<K>): void => {
        const observableValue: Observable<K> = isObservable(value) ? value : of(value);
        const selectorUsedAfterStubbing = findFakeSelectorData(childSelector, usedAfterStubbing);

        if (selectorUsedAfterStubbing) {
          replaceSubscription(selectorUsedAfterStubbing, observableValue);
        } else {
          createFakeSelector(childSelector, observableValue, usedAfterStubbing);
        }

        const selectorUsedBeforeStubbing = findFakeSelectorData(childSelector, usedBeforeStubbing);
        if (selectorUsedBeforeStubbing) {
          replaceSubscription(selectorUsedBeforeStubbing, observableValue);
        }
      }
    });
    fakeSelect.displayName = 'store.select()'; // For better error messages in jasmine

    // We are only providing one of the many overloads of Store.prototype.select, so the compiler is unhappy here.
    // Cast to "any" to make it happy - we are aware that most of the overload cases won't apply to us.
    return fakeSelect as any;
  }
}

export const MockStoreProvider = {
  // Simple MockStore provider
  provide: Store,
  useFactory: () => new MockStore(),

  withoutState(): Provider {
    return {
      provide: Store,
      useFactory: () => new MockStore(
        undefined,
        undefined,
        true),
    };
  },
};

/**
 * A proxy object pretending to be a Store. Will throw an error on any property access.
 * This will be set on the StoreInjector to ensure that anyone attempting to use an invalid Store gets a helpful error message.
 */
const invalidStore = new Proxy({}, {
  get: () => {
    throw new Error('Attempted to use a Store which was not set up. Ensure you have added MockStoreProvider to your test.');
  }
}) as Store<AppState>;

/**
 * Use in afterEach() to ensure that tests cannot leak stores.
 */
export function resetStoreInjector(): void {
  StoreInjector.store = invalidStore;
}
