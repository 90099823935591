import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-customer-qualification-modal-content',
  templateUrl: 'customer-qualification-modal-content.component.html'
})
export class CustomerQualificationModalContentComponent {
  @Input()
  public isCompleted: boolean;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  public closeModal(): void {
    this.close.emit();
  }
}
