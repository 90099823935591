import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  createDeliveryContactFromJson,
  CreateDeliveryContactPayload,
  CustomerId,
  DeliveryContact,
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getItems, ItemsResponse, mapmap } from '@citypantry/util';

@Injectable({
  providedIn: 'root'
})
export class DeliveryContactApi {

  constructor(
    private http: HttpClient,
  ) { }

  public getDeliveryContacts(customerId: string | CustomerId): Observable<DeliveryContact[]> {
    return this.http.get<ItemsResponse<DeliveryContact>>(`/customers/${customerId}/delivery-contacts`).pipe(
      getItems(),
      mapmap(createDeliveryContactFromJson)
    );
  }

  public addDeliveryContact(customerId: CustomerId, payload: CreateDeliveryContactPayload): Observable<DeliveryContact> {
    return this.http.post<{ deliveryContact: DeliveryContact }>(`/customers/${customerId}/delivery-contacts`, payload).pipe(
      map(({ deliveryContact }) => createDeliveryContactFromJson(deliveryContact))
    );
  }

  public updateDeliveryContact(payload: DeliveryContact): Observable<DeliveryContact> {
    return this.http.put<{ deliveryContact: DeliveryContact }>(`delivery-contacts/${payload.id}`, payload ).pipe(
      map(({ deliveryContact }) => createDeliveryContactFromJson(deliveryContact))
    );
  }
}
