import { OrderId, OrderTracking } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const DeliveryDetailsActions = {
  loadTrackingForOrders: createAction(
    '[Delivery Details] Load Tracking for Orders',
    props<{ ordersIds: OrderId[] }>(),
  ),

  trackingForOrdersLoaded: createAction(
    '[Delivery Details] Tracking for Orders Loaded',
    props<{ trackingForOrders: OrderTracking[] }>(),
  ),
};

const all = union(DeliveryDetailsActions);
export type DeliveryDetailsAction = typeof all;
