<div
  class="row"
  analyticsId="search-result-list"
  [analyticsData]="{ totalResultCount: resultCount }"
>
  <div class="col-12 col-md-6 col-xl-4" *ngFor="let result of results">
    <app-search-result
      [result]="result"
      [url]="searchResultUrl"
      [showDistance]="showDistance"
      [showMainsWithinBudget]="showMainsWithinBudget"
      [searchRequest]="searchRequest"
      [isHiddenBudgetVendor]="isHiddenBudgetVendor(result.vendorId)"
      [showRemainingCapacity]="true"
      [showExactRemainingCapacity]="showExactRemainingCapacity"
      test-id="results"
    ></app-search-result>
  </div>
  <app-cant-find-what-your-looking-for
    *ngIf="shouldShowVendorComingSoon()"
    (openZopimClick)="openZopimChat.emit()"
    test-id="cantFindWhatYouWanted"
  ></app-cant-find-what-your-looking-for>

  <div class="col-12 col-md-6 col-xl-4" *ngFor="let placeholder of placeholders">
    <app-search-result-placeholder
      test-id="placeholders"
    ></app-search-result-placeholder>
  </div>
</div>
