import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelectors } from '../state/auth.select';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedOutOrCanAccessJefbGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(AuthSelectors.isLoggedInUser),
      this.store.select(AuthSelectors.navigationPermissions.canAccessJefb),
    ]).pipe(
      map(([isLoggedInUser, canAccessJefb]) => (!isLoggedInUser || canAccessJefb) ? true : this.router.parseUrl('/'))
    );
  }
}
