import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { safeUnsubscribe } from '@citypantry/util';
import { Subscription } from 'rxjs';
import { CompactInputOption } from '../compact-input-option.model';
import { AbstractCompactInputComponent } from '../compact-input.component';

@Component({
  selector: 'app-compact-input[type=choice-text]',
  template: `
    <app-compact-input-wrapper
      (blurInput)="blurDropdown()"
      (unswitch)="unswitch()"
      [label]="dropdownLabel"
      [icon]="icon"
      [currentValue]="getCurrentDisplayValue()"
      [switchedLabel]="textLabel"
      [isSwitched]="dropdownControl.value === -1"
      type="switch"
    >
      <select
        #dropdown
        class="compact-input__dropdown"
        [formControl]="dropdownControl"
        test-id="dropdown"
      >
        <option
          *ngFor="let option of options; index as i"
          [ngValue]="i"
          test-id="options"
        >{{ option.label }}</option>
        <option
          [ngValue]="-1"
          test-id="options"
        >{{ switchOptionLabel }}</option>
      </select>

      <div class="compact-input__input-wrapper">
        <input
          #textInput
          class="compact-input__input"
          [value]="textValue"
          (enter)="textInput.blur()"
          (blur)="update(textInput.value)"
          test-id="input"
        />
      </div>
    </app-compact-input-wrapper>
  `
})
export class CompactInputChoiceSwitchComponent extends AbstractCompactInputComponent implements OnInit, OnDestroy {

  @Input()
  public dropdownLabel: string;

  @Input()
  public switchOptionLabel: string;

  @Input()
  public textLabel: string;

  @Input()
  public options: CompactInputOption<string>[];

  @ViewChild('dropdown', { static: false })
  public dropdown: ElementRef;

  @ViewChild('textInput', { static: false })
  public textInput: ElementRef;

  public textValue: string;

  public dropdownControl: FormControl;
  private lastSelectedIndex: number;

  private dropdownSubscription: Subscription;
  private incomingValueSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (!this.options) {
      throw new Error('No options passed into CompactInputChoiceSwitchComponent!');
    }

    const initialValue = this.control.value;
    const initialOption = this.getSelectedOption(initialValue);

    this.dropdownControl = this.fb.control(initialOption);
    if (initialOption < 0) {
      this.textValue = initialValue;
      this.lastSelectedIndex = 0;
    } else {
      this.textValue = '';
      this.lastSelectedIndex = initialOption;
    }

    this.dropdownSubscription = this.dropdownControl.valueChanges
      .subscribe((dropdownValue) => this.onDropdownValueChanged(dropdownValue));
    if (!initialValue) {
      setTimeout(() => {
        // Trigger an update immediately if we have just initialised and the value was missing before
        this.onDropdownValueChanged(initialOption);
      });
    }

    this.incomingValueSubscription = this.control.valueChanges
      .subscribe((value) => {
        const selectedOption = this.getSelectedOption(value);
        this.dropdownControl.setValue(selectedOption, { emitEvent: false });

        if (selectedOption === -1) {
          this.textValue = value;
        } else {
          this.lastSelectedIndex = selectedOption;
        }
      });
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(
      this.dropdownSubscription,
      this.incomingValueSubscription
    );
  }

  public update(textValue: string, force?: boolean): void {
    if (!force && textValue && this.textValue === textValue) {
      return;
    }

    this.textValue = textValue;
    if (textValue) {
      this.control.setValue(textValue);
    } else {
      this.dropdownControl.setValue(this.lastSelectedIndex); // which will update this.control.value
    }
  }

  public unswitch(): void {
    this.update('', true);
  }

  public blurDropdown(): void {
    if (this.dropdown) {
      this.dropdown.nativeElement.blur();
    }
  }

  public getCurrentDisplayValue(): string {
    const dropdownValue = this.dropdownControl.value;
    if (dropdownValue === -1) {
      return this.textValue;
    } else {
      return this.options[dropdownValue].label;
    }
  }

  private onDropdownValueChanged(dropdownValue: number): void {
    if (dropdownValue > -1) {
      this.lastSelectedIndex = dropdownValue;
      this.control.setValue(this.options[dropdownValue].value);
    } else {
      this.textValue = '';
      this.control.markAsUntouched(); // We've just set the control, remove validation from it

      setTimeout(() => {
        if (this.textInput) {
          this.textInput.nativeElement.focus();
        }
      });
    }
  }

  private getSelectedOption(value: string): number {
    if (!value) {
      return 0; // Choose first item in dropdown if no value is present
    }
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].value === value) {
        return i;
      }
    }
    return -1;
  }
}
