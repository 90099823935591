import { ensureValidEnumValues } from '@citypantry/util';
import moment from 'moment';
import { Country, createCountryFromJson } from '../country.model';
import { Image } from '../image.model';
import { VendorFlag, VendorFlags } from './vendor-flag.enum';
import { VendorLocation, VendorLocationId, createVendorLocationFromJson } from './vendor-location.model';

declare const VendorIdSymbol: unique symbol;
export type VendorId = string & {
  [VendorIdSymbol]: never;
};

declare const VendorSlugSymbol: unique symbol;
export type VendorSlug = string & {
  [VendorSlugSymbol]: never;
};

export interface Vendor {
  id: VendorId;
  humanId: number;
  name: string;
  slug: VendorSlug;
  images: Image[];
  url: string; // Vendor homepage URL
  description: string;
  isApproved: boolean;
  isDeleted: boolean;
  dateAdded: moment.Moment;
  businessType?: {
    id: string;
    isActive: boolean;
    name: string;
  };
  addresses: VendorLocation[];
  flags: VendorFlag[];
  misspellings: string[];
  facebookUrl: string;
  twitterUrl: string;
  googlePlusUrl: string;
  youtubeUrl: string;
  vendorUsers: undefined[]; // Always empty
  isVatRegistered: boolean;
  vatNumber: string;
  mainEmail: string;
  isHaccpCompliant?: boolean;
  foodHygieneRating?: number;
  doesOwnDelivery: boolean;
  providesCutlery: boolean;
  recommended: boolean;
  recommendedSince?: moment.Moment;
  rating: number; // Between 1 and 5, or 0
  reviewsCount: number;
  exclusive: boolean;
  tags?: string[];
  deliveryNoticeHours: number;
  // TODO: CPD-9375 remove nullable,
  // api endpoints such as endpoints returning orders with embedded vendors return vendors with country set to {}
  country?: Country;
  avatarUrl: string;
  defaultLocationId: VendorLocationId | null;
}

export function createVendorFromJson(json?: Partial<Vendor>): Vendor {
  json = json || {};

  const vendor: Partial<Vendor> = {};

  vendor.id = json.id;
  vendor.humanId = json.humanId;
  vendor.name = json.name;
  vendor.slug = json.slug;
  vendor.images = json.images || [];
  vendor.url = json.url;
  vendor.description = json.description;
  vendor.isApproved = json.isApproved;
  vendor.isDeleted = json.isDeleted;
  vendor.dateAdded = json.dateAdded ? moment(json.dateAdded) : null;

  vendor.businessType = json.businessType;
  vendor.addresses = (json.addresses || []).map(createVendorLocationFromJson);
  vendor.misspellings = json.misspellings || [];

  vendor.flags = ensureValidEnumValues(VendorFlags, json.flags || []);

  vendor.facebookUrl = json.facebookUrl;
  vendor.twitterUrl = json.twitterUrl;
  vendor.googlePlusUrl = json.googlePlusUrl;
  vendor.youtubeUrl = json.youtubeUrl;

  vendor.vendorUsers = json.vendorUsers || []; // Always empty from backend
  vendor.isVatRegistered = json.isVatRegistered;
  vendor.vatNumber = json.vatNumber;
  vendor.mainEmail = json.mainEmail;
  vendor.isHaccpCompliant = json.isHaccpCompliant;
  vendor.foodHygieneRating = json.foodHygieneRating;
  vendor.doesOwnDelivery = json.doesOwnDelivery;
  vendor.providesCutlery = json.providesCutlery;
  vendor.recommended = json.recommended;
  vendor.recommendedSince = json.recommendedSince ? moment(json.recommendedSince) : null;
  vendor.rating = json.rating || 0;
  vendor.reviewsCount = json.reviewsCount || 0;
  vendor.exclusive = json.exclusive;
  vendor.tags = json.tags || [];
  vendor.deliveryNoticeHours = json.deliveryNoticeHours;
  // TODO: CPD-9375 remove nullable,
  // api endpoints such as endpoints returning orders with embedded vendors return vendors with country set to {}
  vendor.country = (json.country && json.country.id) ? createCountryFromJson(json.country) : null;
  vendor.avatarUrl = json.avatarUrl;
  vendor.defaultLocationId = json.defaultLocationId || null;

  return vendor as Vendor;
}
