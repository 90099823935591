import { Injectable } from '@angular/core';
import { AnalyticsService } from '@citypantry/shared-analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MetaService } from '@ngx-meta/core';
import { MonoTypeOperatorFunction, Observable, race, timer } from 'rxjs';
import { first, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { CanonicalTagService } from '../canonical-tag.service';
import { DEFAULT_TITLE } from '../default-title';
import { MetaData } from '../meta.module';
import { MetaAction, MetaActions } from './meta.actions';

const SET_META_TIMEOUT_MS = 300;

@Injectable()
export class MetaEffects {

  public setMetaManually$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MetaActions.setMeta.type),
    this.setMetaData(),
    mapTo(undefined),
  ), { dispatch: false });

  public setMetaAndTrackPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      MetaActions.navigatedToRouteWithMeta.type,
      MetaActions.postNavigationToRouteWithoutMetaTimeoutDone.type,
    ),
    this.setMetaData(),
    tap(() => this.analyticsService.trackPage()),
    mapTo(undefined),
  ), { dispatch: false });

  public handleNavigationToRouteWithoutMeta$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MetaActions.navigatedToRouteWithoutMeta.type),
    switchMap(() => race(
      timer(SET_META_TIMEOUT_MS).pipe(mapTo({ title: DEFAULT_TITLE })),
      this.actions$.pipe(ofType(MetaActions.setMeta.type)),
    ).pipe(first())),
    map(MetaActions.postNavigationToRouteWithoutMetaTimeoutDone),
  ));

  public setCanonicalUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MetaActions.setCanonicalUrl),
    tap(({ path }: ReturnType<typeof MetaActions.setCanonicalUrl>) => {
      this.canonicalTagService.setCanonicalPath(path);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions<MetaAction>,
    private metaService: MetaService,
    private canonicalTagService: CanonicalTagService,
    private analyticsService: AnalyticsService,
  ) {}

  private setMetaData<T extends MetaData>(): MonoTypeOperatorFunction<T> {
    return tap<T>(({ title, description, image }) => {
      this.metaService.setTitle(title);
      if (description) {
        this.metaService.setTag('description', description);
      }
      if (image) {
        this.metaService.setTag('og:image', image);
      }
    });
  }
}
