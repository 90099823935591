import { animate, animation, AnimationReferenceMetadata, state, style, transition, useAnimation } from '@angular/animations';

export const slideIn: AnimationReferenceMetadata = animation([
  style({
    minHeight: '0',
    height: '0',
    overflow: 'hidden'
  }),
  animate('{{ timing }}', style({
    height: '*'
  }))
], {
  params: {
    timing: '200ms'
  }
});

export const slideOut: AnimationReferenceMetadata = animation([
  style({
    minHeight: '0',
    overflow: 'hidden',
    height: '*'
  }),
  animate('{{ timing }}', style({
    height: '0'
  })),
], {
  params: {
    timing: '200ms'
  }
});

export const slideInOut = [
  transition(':enter', useAnimation(slideIn)),
  transition(':leave', useAnimation(slideOut)),
];

export const manualSlideInOut = [
  state('false',
    style({ display: 'none' }),
  ),
  // Not using `* => false` or `* => true` because we don't want an initial state to animate
  transition('false => true', [
    style({
      display: '',
    }),
    useAnimation(slideIn),
  ]),
  transition('true => false', useAnimation(slideOut)),
];

export const slideInToLeft: AnimationReferenceMetadata = animation([
  style({
    overflow: 'hidden',
    opacity: 0,
    transform: 'translate3d({{ offset }}, 0, 0)'
  }),
  animate('{{ timing }}', style({
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }))
], {
  params: {
    timing: '200ms',
    offset: '2rem'
  }
});
