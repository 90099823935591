import { ValidatorFn } from '@angular/forms';

// This regex is the inverse of the one used server-side, in that it matches only the invalid characters
// as opposed to strings not containing invalid characters.
const FREE_TEXT_REGEX = /[^A-Za-zÀ-ÖØ-öø-ÿ0-9 .,:_\-+*/=@&()$£?!%#"'’\n\r]/g;

interface FreeTextValidatorError {
  freeText: {
    startsWithEquals?: boolean;
    invalidCharacters?: string[];
  };
}

export const freeText: ValidatorFn = (control): FreeTextValidatorError | null => {
  const value = `${ control.value }`;

  const startsWithEquals = (value && value[0] === '=');
  const invalidCharacters = value.match(FREE_TEXT_REGEX);

  if (startsWithEquals || invalidCharacters) {
    const error: FreeTextValidatorError = { freeText: {} };

    if (startsWithEquals) {
      error.freeText.startsWithEquals = true;
    }

    if (invalidCharacters) {
      error.freeText.invalidCharacters = [...new Set(invalidCharacters)];
    }

    return error;
  }

  return null;
};
