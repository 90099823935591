import { UnreachableCaseError } from '@citypantry/util';

/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type PaymentTerm = 'pay_on_account' | 'pay_by_card' | 'free';

export class PaymentTerms {
  public static PAY_ON_ACCOUNT: PaymentTerm & 'pay_on_account' = 'pay_on_account'; // 1
  public static PAY_BY_CARD:    PaymentTerm & 'pay_by_card'    = 'pay_by_card';    // 2
  public static FREE:           PaymentTerm & 'free'           = 'free';           // 4

  public static get values(): PaymentTerm[] {
    return [
      PaymentTerms.PAY_ON_ACCOUNT,
      PaymentTerms.PAY_BY_CARD,
      PaymentTerms.FREE,
    ];
  }

  // Do not modify this line - Custom code below here

  public static mapApiValueToEnum(value: any): PaymentTerm {
    switch (value) {
      case 1:
        return PaymentTerms.PAY_ON_ACCOUNT;
      case 2:
        return PaymentTerms.PAY_BY_CARD;
      case 4:
        return PaymentTerms.FREE;
      default:
        throw new Error(`Payment Term "${value}" not recognised`);
    }
  }

  public static mapEnumToApiValue(paymentTerm: PaymentTerm): number {
    switch (paymentTerm) {
      case PaymentTerms.PAY_ON_ACCOUNT:
        return 1;
      case PaymentTerms.PAY_BY_CARD:
        return 2;
      case PaymentTerms.FREE:
        return 4;
      default:
        throw new UnreachableCaseError(paymentTerm);
    }
  }
}

export type PaymentTermEnum = typeof PaymentTerms;
