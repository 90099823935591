import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const justEatPayCodesPageRules: AnalyticsRule[] = [
  {
    url: '/just-eat-vouchers',
    path: 'my-meals-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Back to My Meals'
    }
  },
  {
    url: '/just-eat-vouchers',
    path: 'reveal-voucher-code-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Reveal Voucher Code'
    }
  },
  // todo CPD-12904 cover these rules once global dialog is configured to handle analytics
  // {
  //   url: '/just-eat-vouchers',
  //   path: 'confirm-budget-modal bottom-component', // todo or bottom-of-component or top-of-component
  //   events: InViewportComponent.events.firstSeen,
  //   definition: {
  //     action: AnalyticsActions.VIEW,
  //     category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
  //     label: 'Confirm Budget Modal'
  //   }
  // },
  // {
  //   url: '/just-eat-vouchers',
  //   path: 'confirm-budget-modal dialog-close',
  //   events: {
  //     class: 'DialogComponent',
  //     eventName: 'abort'
  //   },
  //   definition: {
  //     action: AnalyticsActions.CLICK,
  //     category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
  //     label: 'Confirm budget modal closed'
  //   }
  // },
  // {
  //   url: '/just-eat-vouchers',
  //   path: 'confirm-budget-modal dialog-close',
  //   events: {
  //     class: 'DialogComponent',
  //     eventName: 'abort'
  //   },
  //   definition: {
  //     action: AnalyticsActions.BLUR,
  //     category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
  //     label: 'Confirm budget modal closed'
  //   }
  // },
  // {
  //   url: '/just-eat-vouchers',
  //   path: 'confirm-budget-modal cancel-button',
  //   events: 'click',
  //   definition: {
  //     action: AnalyticsActions.CLICK,
  //     category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
  //     label: 'Confirm budget modal cancel'
  //   }
  // },
  // {
  //   url: '/just-eat-vouchers',
  //   path: 'confirm-budget-modal reveal-voucher-code-button',
  //   events: 'click',
  //   definition: {
  //     action: AnalyticsActions.CLICK,
  //     category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
  //     label: 'Confirm budget modal reveal voucher code'
  //   }
  // },
  {
    url: '/just-eat-vouchers',
    path: 'voucher-code',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Voucher code shown'
    }
  },
  {
    url: '/just-eat-vouchers',
    path: 'copy-voucher-code',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Copy voucher code',
      extraFields: {
        voucherCodeRevealed: event.data['copy-voucher-code'].voucherCodeRevealed
      }
    })
  },
  {
    url: '/just-eat-vouchers',
    path: 'redeem-voucher-code-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Redeem at Just Eat button'
    }
  },
  {
    url: '/just-eat-vouchers',
    path: 'redeem-voucher-code-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Redeem at Just Eat link'
    }
  },
  {
    url: '/just-eat-vouchers',
    path: 'contact-product-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.JUST_EAT_PAY_CODES_PAGE,
      label: 'Contact Product'
    }
  },
];
