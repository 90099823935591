import { DeliveryContact } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const DeliveryContactActions = {

  getDeliveryContacts: createAction(
    '[Get Delivery Contacts Guard] Get Delivery Contacts',
  ),

  getDeliveryContactsSuccess: createAction(
    '[Delivery Contacts API] Get Delivery Contacts Success',
    props<{ contacts: DeliveryContact[] }>(),
  )
};

const all = union(DeliveryContactActions);
export type DeliveryContactAction = typeof all;
