import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { ErrorState, FEATURE_STATE_NAME } from './error.state';

const getErrorState = createFeatureSelector<ErrorState>(FEATURE_STATE_NAME);

const getTopmostError = (state: ErrorState) => state.errors[0] || null;
const getTopmostAlert = (state: ErrorState) => state.alerts[0] || null;

export const ErrorSelectors = mapSelectors(getErrorState, {
  getTopmostError,
  getTopmostAlert,
});
