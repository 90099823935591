import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { HubspotModalComponent } from './hubspot-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ModalComponentsModule,
    AnalyticsComponentsModule,
  ],
  declarations: [
    HubspotModalComponent
  ],
  exports: [
    HubspotModalComponent
  ]
})
export class HubspotModalModule { }
