import { createSelector, MemoizedSelector } from '@ngrx/store';

/**
 * Represents an object that contains selectors for a certain Feature State.
 */
export interface SelectorObject<FeatureState> {
  [SelectorFnName: string]: (state: FeatureState) => any;
}

/**
 * The result of the mapSelectors call. It contains all the same method names as the original selectors objects,
 * but the source types for each function are the BaseState instead of the FeatureState.
 */
export type MappedSelectors<BaseState, FeatureState, Selectors extends SelectorObject<FeatureState>> = {
  [SelectorFnName in keyof Selectors]: MemoizedSelector<BaseState, ReturnType<Selectors[SelectorFnName]>>;
};

/**
 * Applies a selector function to a Selector object to transform it into a selector object for the base type.
 * { [key: string]: (state: BaseState) => T }.
 * @param selectors A selector object of type { [key: string]: (state: FeatureState) => T }
 * @param getSubState A selector function to select FeatureState from BaseState
 * @returns A selector object of type { [key: string]: (state: BaseState) => T }
 */
export const mapSelectors =
  <BaseState, FeatureState, Selectors extends SelectorObject<FeatureState>>(
    getSubState: (state: BaseState) => FeatureState,
    selectors: Selectors
  ): MappedSelectors<BaseState, FeatureState, Selectors> => {
  // We have to cast to `any` here because {} is a Partial<MappedSelectors> but we have no way of telling TypeScript that it *will* be valid
    const mappedSelectors: MappedSelectors<BaseState, FeatureState, Selectors> = {} as any;

    for (const x in selectors) {
      if (selectors.hasOwnProperty(x)) {
        mappedSelectors[x] = createSelector(getSubState, selectors[x]);
      }
    }

    return mappedSelectors;
  };
