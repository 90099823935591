import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, CartSelectors, MenuSelectors, SearchSelectors } from '@citypantry/state';
import { deepDistinctUntilChanged } from '@citypantry/util';
import {
  DeliverableMenu,
  filterMenuContent,
  getDefaultDeliveryDate,
  Item,
  ItemDeliverabilityProblems,
  ItemOrderTypeEligibility,
} from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuQueries {
  constructor(
    private store: Store<AppState>,
  ) {}

  /**
   * Returns the requested delivery date if set in the cart with matching vendor, otherwise
   * the default delivery date.
   */
  public getCartDeliveryDate(): Observable<Moment> {
    return this.store.select(CartSelectors.getCart).pipe(
      map((cart) => {
        if (cart.deliveryDate && cart.deliveryDate.isAfter()) {
          return cart.deliveryDate;
        }
        return getDefaultDeliveryDate();
      }),
    );
  }

  public getDeliverableMenu(): Observable<DeliverableMenu | null> {
    return combineLatest([
      this.store.select(MenuSelectors.getCurrentMenu),
      this.store.select(AuthSelectors.isWeWorkUser),
      this.store.select(SearchSelectors.isWeWorkModeEnabled),
    ]).pipe(
      map(([deliverableMenu, isWeWorkUser, isWeWorkModeEnabled]) => {
        if (!deliverableMenu) {
          return null;
        }

        function isItemDeliverableFromCurrentMenuSelectedVendorLocation(item: Item): boolean {
          return !deliverableMenu.itemDeliverabilities.some((itemDeliverabilityProblems) =>
            itemDeliverabilityProblems.itemId === item.id
            && itemDeliverabilityProblems.problems.includes(ItemDeliverabilityProblems.NOT_DELIVERABLE_FROM_SELECTED_VENDOR_LOCATION),
          );
        }

        let filteredMenuContent = deliverableMenu.content;

        // CPD-8908: filters out Custom Items in Marketplace and Meal Plan menu
        filteredMenuContent = filterMenuContent(filteredMenuContent, ItemOrderTypeEligibility.isMarketplaceCompatible);
        filteredMenuContent = filterMenuContent(filteredMenuContent, isItemDeliverableFromCurrentMenuSelectedVendorLocation);

        if (isWeWorkUser && isWeWorkModeEnabled) {
          filteredMenuContent = filterMenuContent(filteredMenuContent, isItemWeWorkFriendly);
        }

        return {
          ...deliverableMenu,
          content: filteredMenuContent
        };
      }),
      deepDistinctUntilChanged(),
    );
  }
}

function isItemWeWorkFriendly(item: Item): boolean {
  return (item.dietaries.vegan || item.dietaries.vegetarian || item.dietaries.pescatarian) && item.ecoFriendlyPackaging;
}
