import { Injectable } from '@angular/core';
import { AuthService } from '@citypantry/shared-auth';
import { RouterActions } from '@citypantry/state';
import { WindowRef } from '@citypantry/util-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LostbarAction, LostbarActions } from './lostbar.actions';
import { LostbarApi } from './lostbar.api';

@Injectable()
export class LostbarEffects {

  public queryLostbarSearch$: Observable<LostbarAction> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.searchQueryChanged.type),
    switchMap(({ query }) => {
      return this.lostbarApi.getLostbarSearchResults(query).pipe(
        map((results) => LostbarActions.searchResultsLoadSuccess({ results })),
        catchError(() => of(LostbarActions.searchResultsLoadError()))
      );
    }),
  ));

  public unmasquerade$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.unmasqueradeClicked.type),
    tap(() => {
      this.authService.unmasquerade();
    }),
  ), { dispatch: false });

  public masquerade$: Observable<unknown> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.masqueradeClicked.type),
    tap(({ userId }) => {
      this.authService.masquerade(userId);
      this.windowRef.nativeWindow.location.reload();
    }),
  ), { dispatch: false });

  public viewMealplanAsCustomer$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.viewMealplanAsCustomerClicked.type),
    tap(({ userId }) => this.authService.masquerade(userId)),
    map(({ mealPlanId }) => RouterActions.goExternal({ url: `/customer/meal-plans/${mealPlanId}/edit-orders` }))
  ));

  public editMealplan$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.editMealplanClicked.type),
    tap(() => this.authService.unmasquerade()),
    map(({ mealPlanId }) => RouterActions.goExternal({ url: `/admin/meal-plan/${mealPlanId}/review` }))
  ));

  public editCustomer$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.editCustomerClicked.type),
    tap(() => this.authService.unmasquerade()),
    map(({ customerId }) => RouterActions.goExternal({ url: `/admin/customer/${customerId}` }))
  ));

  public editVendor$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.editVendorClicked.type),
    tap(() => this.authService.unmasquerade()),
    map(({ vendorId }) => RouterActions.goExternal({ url: `/admin/vendor/${vendorId}` }))
  ));

  public editOrder$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(LostbarActions.editOrderClicked.type),
    tap(() => this.authService.unmasquerade()),
    map(({ orderId }) => RouterActions.goExternal({ url: `/admin/order/${orderId}` }))
  ));

  constructor(
    private action$: Actions<LostbarAction>,
    private lostbarApi: LostbarApi,
    private authService: AuthService,
    private windowRef: WindowRef,
  ) {}
}
