import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  createVendorLocationFromJson,
  ErrorMessage,
  ErrorResponse,
  VendorId,
  VendorLocation,
  VendorLocationPayload,
} from '@citypantry/util-models';
import {
  Observable,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaffVendorLocationApi {
  constructor(
    private http: HttpClient
  ) {}

  public createVendorLocation(vendorId: VendorId | 'me', location: VendorLocationPayload): Observable<VendorLocation> {
    return this.http.post(`/staff/vendor/${vendorId}/vendorLocations`, location).pipe(
      map(createVendorLocationFromJson)
    );
  }

  public updateVendorLocation(locationId: string, location: VendorLocationPayload): Observable<Result<VendorLocation, ErrorMessage>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.put(`/staff/vendorLocations/${locationId}`, location, { headers }).pipe(
      map(createVendorLocationFromJson),
      map((vendorLocation: VendorLocation) => Result.success(vendorLocation) ),
      catchError((error: any) => {
        if (error instanceof ErrorResponse) {
          return of(Result.failure(error.messages[0]));
        } else {
          return throwError(error);
        }
      })
    );
  }
}
