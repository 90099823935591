/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type SourceQualificationType =
  'london_underground'
  | 'social_media'
  | 'word_of_mouth'
  | 'email'
  | 'search_engine'
  | 'event'
  | 'other';

export class SourceQualificationTypes {
  public static LONDON_UNDERGROUND: SourceQualificationType & 'london_underground' = 'london_underground';
  public static SOCIAL_MEDIA:       SourceQualificationType & 'social_media'       = 'social_media';
  public static WORD_OF_MOUTH:      SourceQualificationType & 'word_of_mouth'      = 'word_of_mouth';
  public static EMAIL:              SourceQualificationType & 'email'              = 'email';
  public static SEARCH_ENGINE:      SourceQualificationType & 'search_engine'      = 'search_engine';
  public static EVENT:              SourceQualificationType & 'event'              = 'event';
  public static OTHER:              SourceQualificationType & 'other'              = 'other';

  public static get values(): SourceQualificationType[] {
    return [
      SourceQualificationTypes.LONDON_UNDERGROUND,
      SourceQualificationTypes.SOCIAL_MEDIA,
      SourceQualificationTypes.WORD_OF_MOUTH,
      SourceQualificationTypes.EMAIL,
      SourceQualificationTypes.SEARCH_ENGINE,
      SourceQualificationTypes.EVENT,
      SourceQualificationTypes.OTHER,
    ];
  }

  // Do not modify this line - Custom code below here
  // Note: These values should match whatever is required by HubSpot as the API is no longer constrained by enum.
}

export type SourceQualificationTypeEnum = typeof SourceQualificationTypes;
