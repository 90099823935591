import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  public transform(singular: string, count: number, plural?: string): any {
    const isPlural = count !== 1;

    // Handle special case of person / people because it's very common
    if ((singular === 'people' || singular === 'person') && !plural) {
      return isPlural ? 'people' : 'person';
    }

    if (!isPlural) {
      return singular;
    }
    if (isPlural && plural) {
      return plural;
    }
    // We have a singular but no plural; guess based on https://en.wikipedia.org/wiki/English_plurals
    if (singular.match(/(s|sh|ch)$/i)) {
      return `${ singular }es`;
    } else if (singular.match(/(qu|[^aeiou])y$/i)) {
      return singular.replace(/y$/i, 'ies');
    } else {
      // There are some exceptions around words ending in 'o' but that's a bit much because there's no simple way to tell
      return `${ singular }s`;
    }
  }

}
