type HTMLElementWithIEProps = HTMLElement & {
  currentStyle?: { [key: string]: any };
  runtimeStyle?: { [key: string]: any };
  style?: { [key: string]: any };
};

/**
 * Returns computed style of given element
 * From https://github.com/ng2-ui/utils/blob/master/src/dom-functions/computed-style.ts
 *
 * @param document {Document}
 * @param element HTMLElement or CSS selector
 * @param styleProp name of property to compute
 * @returns {string}
 */
export function computedStyle(
  document: Document,
  element: string | HTMLElement,
  styleProp: string
): string {
  const el: HTMLElementWithIEProps = typeof element === 'string' ? document.querySelector(element) : element;

  const defaultView: any = (el.ownerDocument || document).defaultView;
  // W3C standard way:
  if (defaultView && defaultView.getComputedStyle) {
    // sanitize property name to css notation
    // (hypen separated words eg. font-Size)
    styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
  } else if (el['currentStyle']) { // IE
    // sanitize property name to camelCase
    styleProp = styleProp.replace(/-(\w)/g, (str: string, letter: string) => {
      return letter.toUpperCase();
    });
    const value = el['currentStyle'][styleProp];
    // convert other units to pixels on IE
    if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
      return ((val: any) => {
        const oldLeft = el.style.left;
        const oldRsLeft = el['runtimeStyle'].left;
        el['runtimeStyle'].left = el['currentStyle'].left;
        el.style.left = val || 0;
        val = `${ el.style['pixelLeft'] }px`;
        el.style.left = oldLeft;
        el['runtimeStyle'].left = oldRsLeft;
        return val;
      })(value);
    }
    return value;
  }
  return '';
}
