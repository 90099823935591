import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const marketplaceSearchRules: AnalyticsRule[] = [
  {
    url: '/menus/search',
    path: 'parameters-form postcode',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Postcode set'
    }
  },
  {
    url: '/menus/search',
    path: 'parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Date set'
    }
  },
  {
    url: '/menus/search',
    path: 'parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Time set'
    }
  },
  {
    url: '/menus/search',
    path: 'marketplace-search-popup',
    events: {
      class: 'SearchParametersPopupContentComponent',
      eventName: 'complete',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_POPUP,
      label: 'Submit search'
    }
  },
  {
    url: '/menus/search',
    path: 'search-query-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Search query entered',
    },
  },
  {
    url: '/menus/search',
    path: 'sort-by-select',
    events: 'change',
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `Sort by ${event.data['sort-by-select']}`,
    }),
  },
  {
    url: '/menus/search',
    path: 'headcount-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'No of people',
    },
  },
  {
    url: '/menus/search',
    path: 'individual-choice-toggle',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Individual choice mode toggled on',
    },
  },
  {
    url: '/menus/search',
    path: 'max-budget-input',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Budget per person',
    },
  },
  {
    url: '/menus/search',
    path: 'occasions-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Occasion box toggled',
    },
  },
  {
    url: '/menus/search',
    path: 'occasions-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['occasions-checkbox']} occasion checked/unchecked`,
    }),
  },
  {
    url: '/menus/search',
    path: 'cuisine-types-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Type Of cuisine box toggled',
    },
  },
  {
    url: '/menus/search',
    path: 'cuisine-types-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['cuisine-types-checkbox']} cuisine type checked/unchecked`,
    }),
  },
  {
    url: '/menus/search',
    path: 'dietary-concerns-box',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'Dietary Concerns box toggled',
    },
  },
  {
    url: '/menus/search',
    path: 'dietary-concerns-checkbox',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['dietary-concerns-checkbox']} dietary requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/search',
    path: 'filters-form',
    events: {
      class: 'SearchFiltersFormComponent',
      eventName: 'changeWeWorkMode',
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: 'WeWork Friendly vendors toggle',
    },
  },
  {
    url: '/menus/search',
    path: 'search-result link',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData[] => {
      const { index, maxIndex } = event.path.find(({ id }) => id === 'search-result');
      const { totalResultCount } = event.data['search-result-list'];
      const { vendorName, vendorId, isTopPick } = event.data['search-result'];

      const events: DispatchAnalyticsEventData[] = [{
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `Vendor`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
        },
      }];

      // TODO CPD-11178 this event might be redundant, determine whether it can be removed
      if (event.data['search-result']['isTopPick']) {
        events.push({
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Top Pick Result'
        });
      }

      return events;
    }
  },
  {
    url: '/menus/search',
    // Scoping it to search-result-list ensures that the recommendations block doesn't trigger this
    path: 'search-result-list search-result search-card-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      const { index, maxIndex } = event.path.find(({ id }) => id === 'search-result');
      const { totalResultCount } = event.data['search-result-list'];
      const { vendorName, vendorId, isTopPick, vendorRating, vendorReviewCount, menuId } = event.data['search-result'];

      return ({
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        // TODO CPD-11178 this event might have a too specific label, see if it can be simplified to "Vendor shown"
        label: `Vendor seen: ${event.data['search-result'].vendorName} (${event.data['search-result'].vendorId})`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
          vendorRating, vendorReviewCount, menuId,
        },
      });
    },
  },
  {
    url: '/menus/search',
    path: 'search-no-results',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      if (event.data['search-no-results'].analyticsPrefix) {
        return {
          action: AnalyticsActions.VIEW,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: `${event.data['search-no-results'].analyticsPrefix} No search results`
        };
      }

      return {
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `No search results`,
      };
    }
  },
  {
    url: '/menus/search',
    path: 'search-no-results-cta',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-no-results-cta'].analyticsPrefix} No search results cta clicked`
    })
  },
  {
    url: '/menus/search',
    path: 'search-page search-result result-favourite-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `vendor ${event.data['result-favourite-button'].isFavourited ? 'added to' : 'removed from'} favourites`,
    }),
  },
  {
    url: '/menus/search',
    path: 'search-recommendation',
    events: 'click',
    definition: (events) => {
      const data = events.data['search-recommendation'];
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.SEARCH_RECOMMENDATIONS,
        label: `recommendation clicked - ${data['vendorName']} - position ${data['position']}`
      };
    }
  },
  {
    url: '/menus/search',
    path: 'recommendations-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_RECOMMENDATIONS,
      label: 'Seen in viewport'
    }
  },
  {
    url: '/menus/search',
    path: 'search-promo-card close',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-promo-card'].promotion.name} closed`,
    }),
  },
  {
    url: '/menus/search',
    path: 'search-promo-card button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-promo-card'].promotion.name} clicked`,
    }),
  },
  {
    url: '/menus/search',
    path: 'cant-find-what-you-want suggest-a-restaurant',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Suggest a restaurant'
    }
  },
  {
    url: '/menus/search',
    path: 'cant-find-what-you-want chat-to-us',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Chat to us'
    }
  },
  {
    url: '/menus/search',
    path: 'cant-find-what-you-want top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `can't find what your looking for in viewport`
    }
  },
  {
    url: '/menus/search',
    path: 'eco-friendly-packaging-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `MKP Eco Friendly Packaging filter ${(event.data['eco-friendly-packaging-filter'].enabled) ? 'on' : 'off'}`
    })
  },
  {
    url: '/menus/search',
    path: 'favourite-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MKP_SEARCH_PAGE,
      label: `Favourites filter ${(event.data['favourite-filter'].enabled) ? 'on' : 'off'}`
    })
  },
  {
    url: '/menus/search',
    path: 'result-favourite-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label:
        `${event.data['result-favourite-button'].isFavourited ? 'Favourite' : 'Unfavourite'} vendor - ${
          event.data['result-favourite-button'].vendorName}`
    })
  },
  {
    url: '/menus/search',
    path: 'ic-help-icon',
    events: 'mouseenter',
    definition: {
      action: AnalyticsActions.HOVER,
      category: AnalyticsCategories.MKP_SEARCH_PAGE,
      label: `IC toggle help hovered`
    }
  },
  {
    url: '/menus/search',
    path: 'ic-help-tooltip-link',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MKP_SEARCH_PAGE,
      label: `IC toggle help link clicked`
    }
  }
];
