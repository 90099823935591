import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePickerComponent } from '@citypantry/components-date-picker';
import { safeUnsubscribe } from '@citypantry/util';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AbstractCompactInputComponent } from '../compact-input.component';

@Component({
  selector: 'app-compact-input[type=date]',
  template: `
    <app-compact-input-wrapper
      [label]="label"
      [icon]="icon"
      [currentValue]="currentDisplayValue"
      (focusInput)="openDatepicker()"
      (blurInput)="closeDatepicker()"
    >
      <div class="compact-input__input-wrapper">
        <app-date-picker cpDatePicker
          variant="compact"
          [formControl]="control"
          [container]="hostElement"
          test-id="datepicker"
        ></app-date-picker>
      </div>
    </app-compact-input-wrapper>
  `,
  styles: [':host { display: block; }'] // Required to make the date picker attach correctly to the bounding box - it otherwise has no size
})
export class CompactInputDateComponent extends AbstractCompactInputComponent implements OnInit, OnDestroy {

  @Input()
  public label: string;

  @ViewChild(DatePickerComponent, { static: false })
  public datePicker: DatePickerComponent;

  public control: FormControl;
  public currentDisplayValue: string;

  public get hostElement(): HTMLElement {
    return this.host.nativeElement;
  }

  private valueSubscription: Subscription;

  constructor(
    private host: ElementRef
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // When the super component updates its values, update the display value
    // We do this imperatively rather than using a getDisplayValue() method to avoid calling moment().format() too many times
    this.valueSubscription = this.control.valueChanges.subscribe((value) => this.calculateCurrentDisplayValue(value));
    this.calculateCurrentDisplayValue(this.control.value);
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.valueSubscription);
  }

  public openDatepicker(): void {
    if (this.datePicker) {
      this.datePicker.openDatepicker();
    }
  }

  public closeDatepicker(): void {
    if (this.datePicker) {
      this.datePicker.closeDatepicker();
    }
  }

  private calculateCurrentDisplayValue(value: string): void {
    this.currentDisplayValue = value ? moment(value).format('DD/MM/YYYY') : '';
  }
}
