import { Pipe, PipeTransform } from '@angular/core';

/**
 * Truncates strings if they exceed a character limit.
 *
 * If maxLength is 0 or negative, returns an empty string to allow computing the max length dynamically.
 *
 * @example {{ 'hello' | truncate:8 }} => hello
 * @example {{ 'hello world' | truncate:7 }} => hello…
 * @example {{ 'hello world' | truncate:8 }} => hello w…
 * @example {{ 'hello world' | truncate:11 }} => hello world
 */
@Pipe({
  name: 'truncate',
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  public transform(value: string, maxLengthInput: string | number): string {
    if (value === null || typeof value === 'undefined') {
      value = '';
    }
    const maxLength = parseInt(`${maxLengthInput}`, 10) || 0;

    if (!value.length || value.length <= maxLength) {
      return value;
    } else {
      const sliced = value.slice(0, Math.max(0, maxLength - 1)).replace(/\s+$/, '');
      return sliced ? `${ sliced }…` : '';
    }
  }
}
