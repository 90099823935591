/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type PaymentStatus = 'paid' | 'unpaid' | 'failed';

export class PaymentStatuses {
  public static PAID:   PaymentStatus & 'paid'   = 'paid';
  public static UNPAID: PaymentStatus & 'unpaid' = 'unpaid';
  public static FAILED: PaymentStatus & 'failed' = 'failed';

  public static get values(): PaymentStatus[] {
    return [
      PaymentStatuses.PAID,
      PaymentStatuses.UNPAID,
      PaymentStatuses.FAILED,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type PaymentStatusEnum = typeof PaymentStatuses;
