/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type FrequencyQualificationType = 'daily' | 'weekly' | 'fortnightly' | 'monthly' | 'quarterly';

export class FrequencyQualificationTypes {
  public static DAILY:       FrequencyQualificationType & 'daily'       = 'daily';
  public static WEEKLY:      FrequencyQualificationType & 'weekly'      = 'weekly';
  public static FORTNIGHTLY: FrequencyQualificationType & 'fortnightly' = 'fortnightly';
  public static MONTHLY:     FrequencyQualificationType & 'monthly'     = 'monthly';
  public static QUARTERLY:   FrequencyQualificationType & 'quarterly'   = 'quarterly';

  public static get values(): FrequencyQualificationType[] {
    return [
      FrequencyQualificationTypes.DAILY,
      FrequencyQualificationTypes.WEEKLY,
      FrequencyQualificationTypes.FORTNIGHTLY,
      FrequencyQualificationTypes.MONTHLY,
      FrequencyQualificationTypes.QUARTERLY,
    ];
  }

  // Do not modify this line - Custom code below here
  // Note: These values should match whatever is required by HubSpot as the API is no longer constrained by enum.
}

export type FrequencyQualificationTypeEnum = typeof FrequencyQualificationTypes;
