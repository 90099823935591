import { CustomerQualificationAction, CustomerQualificationActions } from './customer-qualification.actions';

export const FEATURE_STATE_NAME = 'customerQualification';

export interface CustomerQualificationState {
  isModalOpen: boolean;
  formCompleted: boolean;
  isSubmitting: boolean;
}

export const INITIAL_STATE: CustomerQualificationState = {
  isModalOpen: false,
  formCompleted: false,
  isSubmitting: false,
};

export function reducer(
  state: CustomerQualificationState = INITIAL_STATE,
  action: CustomerQualificationAction): CustomerQualificationState {
  switch (action.type) {
    case CustomerQualificationActions.showQualificationModal.type: {
      return {
        ...state,
        isModalOpen: true
      };
    }
    case CustomerQualificationActions.hideQualificationModal.type: {
      return {
        ...state,
        isModalOpen: false
      };
    }
    case CustomerQualificationActions.qualifyCustomer.type: {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case CustomerQualificationActions.customerQualified.type: {
      return {
        ...state,
        formCompleted: true,
        isSubmitting: false
      };
    }
    default:
      return state;
  }
}
