import { Pipe, PipeTransform } from '@angular/core';
import { calculateWindowStart } from '@citypantry/shared-api';
import moment from 'moment';

/**
 * Converts a delivery window time to a delivery window string.
 * Valid values:
 * - minutes since midnight as a string
 * - minutes since midnight as a number
 * - moment datetime instance
 */
@Pipe({
  name: 'deliveryWindow'
})
export class DeliveryWindowPipe implements PipeTransform {

  public transform(value: number | string | moment.Moment): string {
    if (typeof value !== 'number' && !value) {
      return null;
    }
    let windowEnd: moment.Moment;
    if (typeof value === 'number' || typeof value === 'string') {
      windowEnd = moment(0) // using 0 ensures that all times are converted on a day without a summer time shift
        .startOf('day').minutes(+value);
    } else {
      windowEnd = value.clone().tz('Europe/London');
    }
    const windowStart = calculateWindowStart(windowEnd);

    return `${windowStart.format('HH:mm')} - ${windowEnd.format('HH:mm')}`;
  }
}
