import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  createPaymentCardFromJson,
  createPaymentCardNonceFromJson,
  OrderId,
  PaymentCard,
  PaymentCardId,
  PaymentCardNonce,
  PaymentTerms,
  UserId
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentApi {

  constructor(
    private http: HttpClient
  ) {}

  public addNewPaymentCard(
    nonce: string,
    deviceData: string,
    userId: UserId,
    cardName: string
  ): Observable<{ card: PaymentCard }> {
    return this.http.post<{ card: PaymentCard }>('/payment-cards', {
      nonce,
      deviceData,
      userId,
      cardName
    });
  }

  public getBraintreeClientToken(): Observable<string> {
    return this.http.get<{ token: string }>('/payment-cards/client-token').pipe(
      map(({ token }) => token)
    );
  }

  public getPaymentCardNonce(cardId: PaymentCardId): Observable<PaymentCardNonce> {
    return this.http.get(`/payment-cards/${cardId}/nonce`).pipe(
      map((createPaymentCardNonceFromJson)
      ));
  }

  public getPaymentCards(): Observable<PaymentCard[]> {
    return this.http.get('/payment-cards').pipe(
      map((paymentCards: { cards: Partial<PaymentCard>[] }) =>
        paymentCards.cards.map(createPaymentCardFromJson)
      ),
    );
  }

  public payForIndividualChoiceOrder(
    orderGroupId: OrderId,
    cardId: PaymentCardId,
    deviceData: string,
    threeDSecureEnrichedNonce: string
  ): Observable<OrderId> {
    return this.http.post(`/individual-choice-order-groups/${orderGroupId}/process-payment`, {
      paymentTerm: PaymentTerms.mapEnumToApiValue(PaymentTerms.PAY_BY_CARD),
      card: cardId,
      deviceData,
      threeDSecureEnrichedNonce
    }).pipe(
      mapTo(orderGroupId)
    );
  }
}
