<div
  class="control-button"
  [class.control-button--active]="activeFiltersCount > 0"
  (click)="handleClick()"
  test-id="controlButton"
  >
  <div class="control-button__content">
    <i class="control-button__icon icon icon-filter-list icon--medium icon--square"></i>
    <span test-id="label">Filters ({{ activeFiltersCount }})</span>
    <div
      class="expand-toggle-button"
      [class.expand-toggle-button--white]="activeFiltersCount > 0"
      [class.expand-toggle-button--expanded]="isExpanded"
      test-id="chevron"
    ></div>
  </div>
</div>
