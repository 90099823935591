import { BraintreeError } from 'braintree-web';
import { ExpectedPaymentErrorType } from './expected-payment-error-type.enum';

export class ExpectedPaymentError extends Error {

  constructor(
    message: string,
    public readonly detailedMessage: string,
    public readonly type: ExpectedPaymentErrorType,
    public readonly braintreeError?: BraintreeError
  ) {
    super(message);
  }
}
