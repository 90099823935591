<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://business.just-eat.co.uk/privacy-policy', label: 'Privacy Policy' }"></ng-container>

  <ng-container *ngTemplateOutlet="routerLinkTemplate; context: { routerLink: ['/menus/individual-ordering/orders'], label: 'Orders' }"></ng-container>

  <app-header-dropdown
    [triggerLabel]="'My Account'"
    e2e-test-id="accountNavigationLink"
    test-id="accountDropdown"
  >
    <ng-container *ngTemplateOutlet="authenticatedUserLinksTemplate"></ng-container>
  </app-header-dropdown>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://justeatforbusinessio.zendesk.com/hc/en-gb/categories/12021344794525-Frequently-Asked-Questions', label: 'Support', analyticsId: 'supportLink' }"></ng-container>
</ul>
