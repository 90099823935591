import { mapSelectors } from '@citypantry/util';
import { DeliveryContact } from '@citypantry/util-models';
import { createFeatureSelector } from '@ngrx/store';
import { DeliveryContactsState, FEATURE_STATE_NAME } from './delivery-contact.state';

const getDeliveryContactState = createFeatureSelector<DeliveryContactsState>(FEATURE_STATE_NAME);

const getDeliveryContacts = (state: DeliveryContactsState): DeliveryContact[] => state.contacts;
const isLoadingDeliveryContacts = (state: DeliveryContactsState): boolean => state.isLoading;

export const DeliveryContactSelectors = mapSelectors(getDeliveryContactState, {
  getDeliveryContacts,
  isLoadingDeliveryContacts
});
