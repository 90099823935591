import { Directive } from '@angular/core';

/**
 * Marker directive to indicate an anchor for the context dropdown;
 * the element will be used to determine where to attach the dropdown.
 */
@Directive({
  selector: '[context-dropdown-anchor]'
})
export class ContextDropdownAnchorDirective {
}
