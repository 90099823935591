import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MajorCurrency } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyApi {
  constructor(
    private http: HttpClient,
  ) {}

  public getProvisionalPointsForLoggedInCustomer(foodCost: MajorCurrency): Observable<number> {
    const params = { foodCost: String(foodCost) };

    return this.http.get<{ points: number }>('/loyalty/provisional-total', { params }).pipe(
      map(({ points }) => points),
    );
  }
}
