import { CustomerId, MealPlan, OrderId, UserId, VendorId } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';
import { LostbarSearchResult } from './lostbar-search-result.model';

export const LostbarActions = {
  searchQueryChanged: createAction(
    '[Lostbar] Search Query Changed',
    props<{ query: string }>(),
  ),
  searchResultsLoadSuccess: createAction(
    '[Lostbar API] Search Results Load Success',
    props<{ results: LostbarSearchResult[] }>(),
  ),
  searchResultsLoadError: createAction(
    '[Lostbar API] Search Results Load Error',
  ),

  unmasqueradeClicked: createAction(
    '[Lostbar] Unmasquerade Clicked',
  ),
  masqueradeClicked: createAction(
    '[Lostbar] Masquerade Clicked',
    props<{ userId: UserId }>(),
  ),
  viewMealplanAsCustomerClicked: createAction(
    '[Lostbar] View Mealplan As Customer Clicked',
    props<{ userId: UserId, mealPlanId: MealPlan['id'] }>(),
  ),
  editMealplanClicked: createAction(
    '[Lostbar] Edit Mealplan Clicked',
    props<{ mealPlanId: MealPlan['id'] }>(),
  ),
  editCustomerClicked: createAction(
    '[Lostbar] Edit Customer Clicked',
    props<{ customerId: CustomerId }>(),
  ),
  editVendorClicked: createAction(
    '[Lostbar] Edit Vendor Clicked',
    props<{ vendorId: VendorId }>(),
  ),
  editOrderClicked: createAction(
    '[Lostbar] Edit Order Clicked',
    props<{ orderId: OrderId }>(),
  ),
};

const all = union(LostbarActions);
export type LostbarAction = typeof all;
