import { trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateInEuropeLondonPipe } from '@citypantry/components-datetime';
import { slideInOut } from '@citypantry/util-animations';
import { MajorCurrency } from '@citypantry/util-models';
import moment from 'moment';

@Component({
  selector: 'app-cart-delivery',
  templateUrl: './cart-delivery.component.html',
  animations: [
    trigger('slideInOut', slideInOut),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartDeliveryComponent {

  @Input()
  public deliveryCost: MajorCurrency;

  @Input()
  public deliveryVat: MajorCurrency;

  @Input()
  public showCost: boolean;

  @Input()
  public locationNameOrPostcode: string | null;

  @Input()
  public deliveryDate: moment.Moment;

  @Input()
  public deliveryNoticeHours: number;

  @Input()
  public showDeliveryNotice: boolean;

  @Input()
  public locked: boolean;

  @Input()
  public isFreeDelivery: boolean;

  @Input()
  public currencyCode: string;

  @Output()
  public editDelivery: EventEmitter<void> = new EventEmitter();

  @Input()
  public serviceFeeNet: MajorCurrency;

  @Input()
  public serviceFeeVat: MajorCurrency;

  constructor(
    private dateInEuropeLondonPipe: DateInEuropeLondonPipe,
  ) {}

  public get hasDeliveryFee(): boolean {
    return this.deliveryCost + this.deliveryVat > 0;
  }

  public get hasServiceFee(): boolean {
    return this.serviceFeeNet + this.serviceFeeVat > 0;
  }

  public get formattedDeliveryNoticeTime(): string {
    if (this.deliveryNoticeHours < 48) {
      return `${this.deliveryNoticeHours} hours'`;
    } else {
      return `${ moment.duration(this.deliveryNoticeHours, 'hours').humanize() }'`;
    }
  }

  public get formattedOrderCutOffTime(): string {
    const cutOffTime = this.deliveryDate.clone().subtract(this.deliveryNoticeHours, 'hours');
    const isCutOffTimeToday = cutOffTime.isSame(moment(), 'day');
    return this.dateInEuropeLondonPipe.transform(
      cutOffTime,
      `HH:mm ${isCutOffTimeToday ? '[today]' : '[on] DD/MM/YYYY'}`,
    );
  }

  public editDeliveryParams(): void {
    if (!this.locked) {
      this.editDelivery.emit();
    }
  }
}
