import moment, { Moment } from 'moment';
import { ItemId } from '../menu';
import { CartItem, createCartItemFromJson } from './cart-item.model';

export interface ReorderCartDetails {
  cartItems: CartItem[];
  deliveryDate: Moment;
  failedItems: FailedToReorderItem[];
  notes: string | null;
  includeCutlery: boolean;
  postcode: string;
}

export interface FailedToReorderItem {
  itemId: ItemId;
  name: string;
  quantity: number;
  reason: string; // TODO CPD-6597 add enum of reasons
}

export function createReorderCartDetailsFromJson(json: Partial<ReorderCartDetails>): ReorderCartDetails {
  const details: Partial<ReorderCartDetails> = {};

  details.cartItems = (json.cartItems || []).map(createCartItemFromJson);
  details.deliveryDate = moment(json.deliveryDate);
  details.failedItems = json.failedItems || [];
  details.notes = json.notes;
  details.includeCutlery = json.includeCutlery;
  details.postcode = json.postcode;

  return details as ReorderCartDetails;
}
