import { Cost, createCostFromJson } from './cost.model';
import { MajorCurrency } from './minor-major-currency.model';

export interface MixedCost {
  standardRated: Cost;
  zeroRated: Cost;
  gross: MajorCurrency;
  net: MajorCurrency;
  vat: MajorCurrency;
}

export function createMixedCostFromJson(json?: Partial<MixedCost>): MixedCost {
  json = json || {};

  const mixedCost: Partial<MixedCost> = {};
  mixedCost.standardRated = createCostFromJson(json.standardRated);
  mixedCost.zeroRated = createCostFromJson(json.zeroRated);
  mixedCost.gross = json.gross || 0;
  mixedCost.net = json.net || 0;
  mixedCost.vat = json.vat || 0;

  return mixedCost as MixedCost;
}
