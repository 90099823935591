import { InjectionToken } from '@angular/core';
import { AnalyticsAction as AnalyticsAction } from '../analytics-action.enum';
import { AnalyticsCategory } from '../analytics-category.enum';
import { AnalyticsEvent, EventDefinition } from '../analytics-event.interface';

export interface AnalyticsRule {
  url: string;
  path: string;
  events: EventDefinition | EventDefinition[];
  definition: RuleDefinition;
}

export type RuleDefinition =
  DispatchAnalyticsEventData |
  DispatchAnalyticsEventData[] |
  ((event: AnalyticsEvent, urlParams: { [key: string]: any }) => (DispatchAnalyticsEventData | DispatchAnalyticsEventData[]));

export interface DispatchAnalyticsEventData {
  category: AnalyticsCategory;
  action: AnalyticsAction;
  label?: string;
  extraFields?: { [key: string]: any }; // Extra data to send to segment
}

export interface AnalyticsRulesProvider {
  getRules(): AnalyticsRule[];
}

export const AnalyticsRules = new InjectionToken<AnalyticsRule>('AnalyticsRules');
