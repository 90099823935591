import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  createIndividualChoiceOrderGroupWithRemainingCapacityFromJson,
  IndividualChoiceOrderGroupWithRemainingCapacity,
  OrderId
} from '@citypantry/util-models';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

export interface RequestOrderRemainingCapacityPayload {
  orderId: OrderId;
  remainingCapacity: number;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerIndividualChoiceApi {
  constructor(
    private http: HttpClient,
  ) {}

  public sendReadyForCollectionNotifications(orderGroupId: OrderId, orderIds: OrderId[]): Observable<Result<void, string>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.post<boolean>(
      `/customer/individual-choice-order-groups/${orderGroupId}/notify-ready-for-collection`,
      { orders: orderIds },
      { headers },
    ).pipe(
      mapTo(Result.success()),
      catchError(() => of(Result.failure('Unable to send ready for collection notifications'))),
    );
  }

  public trackOrderGroupReportedRemainingCapacities(
    orderGroupId: OrderId,
    remainingCapacities: RequestOrderRemainingCapacityPayload[],
  ): Observable<Result<void, string>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.post<boolean>(
      `/customer/individual-choice-order-groups/${orderGroupId}/reported-remaining-capacity`,
      { remainingCapacities },
      { headers }
    ).pipe(
      mapTo(Result.success()),
      catchError(() => of(Result.failure('Unable to record reported remaining capacities'))),
    );
  }

  public getIndividualChoiceOrderGroupWithRemainingCapacity(
    orderGroupId: OrderId
  ): Observable<Result<IndividualChoiceOrderGroupWithRemainingCapacity>> {
    return this.http.get(`/customer/individual-choice-order-groups/${orderGroupId}/edit-summary`).pipe(
      map(createIndividualChoiceOrderGroupWithRemainingCapacityFromJson)
    ).pipe(
      map((orderGroup: IndividualChoiceOrderGroupWithRemainingCapacity) => Result.success(orderGroup)),
      catchError(() => {
        return of(Result.failure({ error: 'Unable to fetch IC order group with remaining capacity', orderGroupId}));
      }),
    );
  }
}
