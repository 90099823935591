import { mapSelectors } from '@citypantry/util';
import { PaymentCard } from '@citypantry/util-models';
import { createFeatureSelector } from '@ngrx/store';
import { FEATURE_STATE_NAME, PaymentState } from './payment.state';

const getPaymentState = createFeatureSelector<PaymentState>(FEATURE_STATE_NAME);

const getPaymentCards = (state: PaymentState): PaymentCard[] => state.paymentCards;

export const PaymentSelectors = mapSelectors(getPaymentState, {
  getPaymentCards
});
