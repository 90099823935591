import { Pipe, PipeTransform } from '@angular/core';
import { MajorCurrency, MinorCurrency, minorCurrencyToMajor } from '@citypantry/util-models';

@Pipe({
  name: 'minorToMajor',
  pure: true
})
export class MinorToMajorPipe implements PipeTransform {
  public transform(value: MinorCurrency | null): MajorCurrency {
    if (value === null || value === undefined) {
      return null;
    }

    if (isNaN(value)){
      throw new Error(`[MinorToMajorPipe] Unexpected non-numerical value was passed: ${value}`);
    }

    return minorCurrencyToMajor(Number(value));
  }
}
