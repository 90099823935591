import { FormGroup, ValidatorFn } from '@angular/forms';

function requiredBetween(min: number, max: number): ValidatorFn {
  return (group: FormGroup): { [key: string]: string} => {
    const numberOfTicks = Object.values(group.value).filter((value) => value);
    if (min && numberOfTicks.length < min) {
      return {'minValueOfCheckboxes': `You need to tick at least ${min} checkbox${min > 1 ? 'es' : ''}`};
    }
    if (max && numberOfTicks.length > max) {
      return {'maxValueOfCheckboxes': `You cannot tick more than ${max} checkbox${max > 1 ? 'es' : ''}`};
    }
    return null;
  };
}

function requiredMax(max: number): ValidatorFn {
  return requiredBetween(null, max);
}

function requiredMin(min: number): ValidatorFn {
  return requiredBetween(min, null);
}

export const checkboxGroup = {
  requiredBetween,
  requiredMax,
  requiredMin
};
