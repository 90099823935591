import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { AnalyticsEventsService } from './analytics-events.service';
import { AnalyticsIdDirective } from './analytics-id.directive';
import { AnalyticsLogger } from './analytics-logger.service';
import { InViewportComponent } from './in-viewport.component';

@NgModule({
  imports: [
    CommonModule,
    InViewportModule,
  ],
  declarations: [
    AnalyticsIdDirective,
    InViewportComponent,
  ],
  exports: [
    AnalyticsIdDirective,
    InViewportComponent,
  ],
})
export class AnalyticsComponentsModule {

  public static forTest(): ModuleWithProviders<AnalyticsComponentsModule> {
    return {
      ngModule: AnalyticsComponentsModule,
      providers: [
        {
          provide: AnalyticsEventsService,
          useValue: {
            getEventTriggersForElement: () => [],
            dispatchEvent: () => { /* noop */ }
          } as Partial<AnalyticsEventsService>,
        },
        AnalyticsLogger.silent,
      ]
    };
  }
}
