import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchResult } from '@citypantry/util-models';

@Component({
  selector: 'app-recommended-vendors',
  templateUrl: './recommended-vendors.component.html',
  styleUrls: ['./recommended-vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedVendorsComponent {
  @Input()
  public title: string;

  @Input()
  public recommendedVendors: SearchResult[];

  @Input()
  public showPrimaryButtonStyle: boolean;

  @Input()
  public trackWhenInViewport: boolean;

  public hasBeenInViewport: boolean;

  constructor() {
    this.recommendedVendors = [];
    this.hasBeenInViewport = false;
    this.title = 'Didn’t find anything you like?';
    this.trackWhenInViewport = true;
  }
}
