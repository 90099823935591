import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  template: `
    <div
      class="rating-stars"
      [class.rating-stars--small]="compact"
      [class.rating-stars--disabled]="disabled"
    >
      <div
        class="rating-stars__stars"
        [attr.aria-label]="getRatingLabel()"
        test-id="ratingStars"
      >
        <i
          *ngFor="let star of stars"
          class="rating-stars__star material-icons"
          aria-hidden="true"
          test-id="stars"
        >
          {{ starName(star) }}
        </i>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StarRatingComponent {

  @Input()
  public rating: number;

  @Input()
  public set maxRating(maxRating: number) {
    this.stars = [ ...Array(maxRating).keys() ].map((i) => i + 1);
  };

  @Input()
  public compact: boolean;

  @Input()
  public disabled: boolean;

  public stars: number[];

  constructor() {
    this.maxRating = 5;
  }

  public get starRating(): number {
    return Math.round(this.rating * 2) / 2;
  }

  public starName(star: number): string {
    if (this.disabled) {
      return 'star_border';
    }

    if (this.starRating >= star) {
      return 'star';
    } else if (this.starRating > star - 1 && this.starRating < star) {
      return 'star_half';
    } else {
      return 'star_border';
    }
  }

  public getRatingLabel(): string {
    return `${this.starRating}/${this.stars.length} stars`;
  }
}
