import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageComponentsModule } from '@citypantry/components-image';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { AnalyticsEcommerceComponentsModule } from '@citypantry/shared-analytics';
import { FileDropDirective, FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { ButtonTypeDirective } from './directives/button-type.directive';
import { EnterDirective } from './directives/enter.directive';
import { ErrorCaseDirective, ErrorSwitchDirective } from './directives/error-switch.directive';
import { GeneratedIdDirective } from './directives/generated-id.directive';
import { EditablePriceComponent } from './editable-price/editable-price.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { QuantityPickerComponent } from './quantity-picker/quantity-picker.component';
import { FileUploaderFactory, MultipleImageUploadComponent } from './simple-file-upload';
import { TagInputComponent } from './tag-input/tag-input.component';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsEcommerceComponentsModule,
    AnalyticsComponentsModule,
    ImageComponentsModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FormErrorComponent,
    QuantityPickerComponent,
    MultipleImageUploadComponent,
    TagInputComponent,
    EditablePriceComponent,
    ButtonTypeDirective,
    EnterDirective,
    ErrorCaseDirective,
    ErrorSwitchDirective,
    GeneratedIdDirective,
  ],
  providers: [
    FileUploaderFactory,
  ],
  exports: [
    FormErrorComponent,
    QuantityPickerComponent,
    MultipleImageUploadComponent,
    FileSelectDirective,
    FileDropDirective,
    TagInputComponent,
    EditablePriceComponent,
    ButtonTypeDirective,
    EnterDirective,
    ErrorCaseDirective,
    ErrorSwitchDirective,
    GeneratedIdDirective,
  ]
})
export class FormComponentsModule {}
