import { Injectable } from '@angular/core';
import { PaymentApi } from '@citypantry/shared-api';
import { BraintreeService } from '@citypantry/shared-braintree';
import { RouterActions } from '@citypantry/state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PaymentAction, PaymentActions } from './payment.actions';

@Injectable()
export class PaymentEffects {

  public payForIndividualChoiceOrder$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(PaymentActions.payForIndividualChoiceOrder),
    switchMap(({ orderId, cardId, threeDSecureEnrichedNonce }) => {
      return from(this.braintreeService.getDeviceData()).pipe(
        switchMap((deviceData) => {
          return this.paymentApi.payForIndividualChoiceOrder(orderId, cardId, deviceData, threeDSecureEnrichedNonce).pipe(
            map((paidOrderGroupId) => {
              return RouterActions.go({ path: `/menus/individual-choice/orders/${paidOrderGroupId}/payment-success` });
            })
          );
        })
      );
    }),
  ));

  public loadPaymentCards$: Observable<PaymentAction> = createEffect(() => this.action$.pipe(
    ofType(PaymentActions.paymentCardsFetch),
    switchMap(() => this.paymentApi.getPaymentCards()),
    map((paymentCards) => PaymentActions.paymentCardsFetchSuccess({ paymentCards })),
  ));

  constructor(
    private action$: Actions<PaymentAction>,
    private braintreeService: BraintreeService,
    private paymentApi: PaymentApi,
  ) {}
}
