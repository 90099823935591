/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type VendorLocationCapacityStatus = 'SOLD_OUT' | 'ALMOST_SOLD_OUT' | 'AVAILABLE';

export class VendorLocationCapacityStatuses {
  public static SOLD_OUT:        VendorLocationCapacityStatus & 'SOLD_OUT'        = 'SOLD_OUT';
  public static ALMOST_SOLD_OUT: VendorLocationCapacityStatus & 'ALMOST_SOLD_OUT' = 'ALMOST_SOLD_OUT';
  public static AVAILABLE:       VendorLocationCapacityStatus & 'AVAILABLE'       = 'AVAILABLE';

  public static get values(): VendorLocationCapacityStatus[] {
    return [
      VendorLocationCapacityStatuses.SOLD_OUT,
      VendorLocationCapacityStatuses.ALMOST_SOLD_OUT,
      VendorLocationCapacityStatuses.AVAILABLE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type VendorLocationCapacityStatusEnum = typeof VendorLocationCapacityStatuses;
