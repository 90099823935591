/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type CustomItemOptionType =
  'single-choice' | 'single-choice-required' | 'multiple-choice' | 'quantity';

export class CustomItemOptionTypes {
  public static SINGLE_CHOICE:          CustomItemOptionType & 'single-choice' = 'single-choice';
  public static SINGLE_CHOICE_REQUIRED: CustomItemOptionType & 'single-choice-required' = 'single-choice-required';
  public static MULTIPLE_CHOICE:        CustomItemOptionType & 'multiple-choice' = 'multiple-choice';
  public static QUANTITY:               CustomItemOptionType & 'quantity' = 'quantity';

  public static get values(): CustomItemOptionType[] {
    return [
      CustomItemOptionTypes.SINGLE_CHOICE,
      CustomItemOptionTypes.SINGLE_CHOICE_REQUIRED,
      CustomItemOptionTypes.MULTIPLE_CHOICE,
      CustomItemOptionTypes.QUANTITY,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type CustomItemOptionTypeEnum = typeof CustomItemOptionTypes;
