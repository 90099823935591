<app-modal
  [show]="show"
  [showOverflow]="settings.showOverflow"
  [modalStyle]="settings.modalSize ?? 'medium'"
  (clickOutsideModal)="onOutsideClicked()"
  test-id="modal"
>
  <div class="dialog">
    <button
      *ngIf="!settings.hideCloseButton"
      class="dialog__close"
      (click)="onCloseClicked()"
      analyticsId="dialog-close"
      test-id="closeButton"
    >
      close
    </button>
    <div
      *ngIf="settings.icon"
      [class]="'dialog__icon dialog__icon--' + settings.icon"
      test-id="icon"
    >
    </div>
    <i
      *ngIf="settings.illustrativeIcon && !settings.icon"
      [class]="'illustrative-icon illustrative-icon--' + settings.illustrativeIcon"
      test-id="illustrativeIcon"
    ></i>
    <p
      [ngClass]="'heading-3 dialog__title ' + (settings.overrideTitleStyle || '')"
      test-id="title"
      *ngIf="settings.title"
    >{{ settings.title }}</p>

    <div
      *ngIf="!settings.textContent && !settings.innerHtmlContent; else contentFromSettings"
      [class.dialog__full-width]="settings.fullWidth"
      test-id="projectedContent"
    >
      <ng-content></ng-content>
    </div>

    <ng-template #contentFromSettings>
      <p
        class="dialog__content m-none"
        test-id="textContent"
      >
        {{ settings.textContent }}
      </p>
      <div
        *ngIf="settings.innerHtmlContent"
        class="dialog__full-width"
        [innerHTML]="settings.innerHtmlContent"
        test-id="innerHtmlContent"
      ></div>
    </ng-template>

    <div
      *ngIf="!settings.hideButtons"
      class="dialog__buttons justify-content-center"
      [class.button--full-width]="settings.buttonWidthStyle === 'fullWidthConfirmation' || settings.buttonWidthStyle === 'cancelAndConfirmSameWidth'"
      test-id="buttonsContainer"
    >
      <button
        *ngIf="!settings.hideCancelButton"
        [class]="'button button--' + (settings.overrideCancelButtonStyle || 'secondary')"
        [class.dialog-layout__buttons-same-width]="settings.buttonWidthStyle === 'cancelAndConfirmSameWidth'"
        (click)="onCancelClicked()"
        analyticsId="dialog-cancel"
        test-id="cancelButton"
        e2e-test-id="dialogCancelButton"
      >{{ settings.cancelLabel || 'Cancel' }}</button>
      <button
        [class]="'button button--' + (settings.overrideConfirmButtonStyle || 'primary')"
        [class.button--full-width]="settings.buttonWidthStyle === 'fullWidthConfirmation'"
        [class.dialog-layout__buttons-same-width]="settings.buttonWidthStyle === 'cancelAndConfirmSameWidth'"
        (click)="onConfirmClicked()"
        analyticsId="dialog-confirm"
        test-id="confirmButton"
        e2e-test-id="dialogConfirmButton"
      >{{ settings.confirmLabel || 'OK' }}</button>
    </div>

    <p
      *ngIf="settings.ctaWarningText"
      class="text-weight-medium"
      test-id="ctaWarningText"
    >
      {{ settings.ctaWarningText }}
    </p>
  </div>
</app-modal>
