import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { createCustomerLocationFromJson, CustomerLocation } from '../customer';
import { MajorCurrency } from '../money';
import { OrderId } from '../order/order-id.model';
import { ChoiceDeadline, ChoiceDeadlines } from './choice-deadline.enum';
import {
  createIndividualChoiceOrderWithRemainingCapacityFromJson,
  IndividualChoiceOrderWithRemainingCapacity
} from './individual-choice-order-with-remaining-capacity.model';

export interface IndividualChoiceOrderGroupWithRemainingCapacity {
  id: OrderId;
  orders: IndividualChoiceOrderWithRemainingCapacity[];
  budget: MajorCurrency;
  location: CustomerLocation;
  operationalRegion: string;
  choiceDeadline: moment.Moment;
  choiceDeadlineType: ChoiceDeadline;
  choiceOpenTime: moment.Moment | null;
  orderDeadline: moment.Moment;
  isSubsidisedChoice: boolean;
  requestedDeliveryDate: moment.Moment;
  expectedHeadcount: number | null;
}

// fromJson function

export function createIndividualChoiceOrderGroupWithRemainingCapacityFromJson(
  json?: Partial<IndividualChoiceOrderGroupWithRemainingCapacity>
): IndividualChoiceOrderGroupWithRemainingCapacity {
  json = json || {};

  const orderGroup: Partial<IndividualChoiceOrderGroupWithRemainingCapacity> = {};

  orderGroup.id = json.id;
  orderGroup.budget = json.budget;
  orderGroup.location = createCustomerLocationFromJson(json.location);
  orderGroup.operationalRegion = json.operationalRegion;
  orderGroup.choiceOpenTime = json.choiceOpenTime ? moment(json.choiceOpenTime) : null;
  orderGroup.choiceDeadline = json.choiceDeadline ? moment(json.choiceDeadline) : null;
  orderGroup.choiceDeadlineType = ensureValidEnumValue(ChoiceDeadlines, json.choiceDeadlineType);
  orderGroup.orderDeadline = json.orderDeadline ? moment(json.orderDeadline) : null;
  orderGroup.orders = (json.orders || []).map(createIndividualChoiceOrderWithRemainingCapacityFromJson);
  orderGroup.expectedHeadcount = json.expectedHeadcount !== undefined ? json.expectedHeadcount : null;
  orderGroup.isSubsidisedChoice = !!json.isSubsidisedChoice;
  orderGroup.requestedDeliveryDate = json.requestedDeliveryDate ? moment.tz(json.requestedDeliveryDate, 'Europe/London') : null;

  return orderGroup as IndividualChoiceOrderGroupWithRemainingCapacity;
}

export function getLargestHeadcountFromOrdersEditSummary(orderGroup: IndividualChoiceOrderGroupWithRemainingCapacity): number {
  return Math.max(0, ...orderGroup.orders.map((order) => order.expectedHeadcount));
}
