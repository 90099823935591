import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { DebounceHostListener } from '@citypantry/util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StickyState } from '@citypantry/components-sticky';
import { computedStyle } from '@citypantry/util-browser';

const STOPPED_SCROLLING_DELAY_MS = 250;

@Component({
  selector: 'app-sticky-cart',
  template: `
    <div
      class="cart-sticky-wrapper"
      [class.cart-sticky-wrapper--offset]="offset === 'simple'"
      [class.cart-sticky-wrapper--offset-with-menu]="offset === 'menu'"
      test-id="outerWrapper"
    >
      <div
        class="cart-sticky-wrapper__sticky-parent"
        #parent
      >
        <div
          class="cart-sticky-wrapper__sticky-child"
          sticky
          stickyAfter="#lostbar"
          [stickToBottom]="false"
          (stickied)="onStickyStateChanged($event)"
          test-id="childWrapper"
        >
          <div
            class="cart-sticky-wrapper__whitespace-wrapper"
            #whitespace
          >
            <div
              #child
              class="cart cart-sticky-wrapper__cart"
              [class.cart--stuck]="isStickied"
              [@slide]="slideCartUp"
              test-id="cart"
            >
              <ng-content></ng-content>
            </div>
            <div
              #footer
              [@fadeOut]="hideFooter"
              class="cart-sticky-wrapper__footer"
            >
              <ng-content select="[cart-slot=footer]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  animations: [
    trigger('slide', [
      state('slide1', style({
        transform: 'translateY(-{{top}}px)',
      }), { params: { top: 0 } }),
      state('slide2', style({
        transform: 'translateY(-{{top}}px)',
      }), { params: { top: 0 } }),
      transition('* <=> *', animate('200ms')),
    ]),
    trigger('fadeOut', [
      state('true', style({ visibility: 'hidden', pointerEvents: 'none' })),
      state('false', style({ visibility: 'visible' })),
      transition('* => true', [
        style({ opacity: 1 }),
        animate(200, style({ opacity: 0 }))
      ]),
      transition('* => false', [
        style({ opacity: 0, visibility: 'visible' }),
        animate(200, style({ opacity: 1 }))
      ]),
    ]),
  ],
  styleUrls: ['./sticky-cart.component.scss']
})
export class StickyCartComponent {

  /**
   * Visual offset from the top edge of the parent container.
   * If set to "simple", will align the bottom of the text with the edge of the container.
   * Use "menu" when the sticky-cart is below a menu-header, and needs to sit inside the header image.
   */
  @Input()
  public offset: 'none' | 'simple' | 'menu';

  @ViewChild('parent', { static: false })
  public parentEl: ElementRef;

  @ViewChild('child', { static: false })
  public childEl: ElementRef;

  @ViewChild('cart', { static: false })
  public cartEl: ElementRef;

  @ViewChild('whitespace', { static: false })
  public whitespaceEl: ElementRef;

  @ViewChild('footer', { static: false })
  public footerEl: ElementRef;

  public isStickied: boolean;

  public slideCartUp: 'none' | { value: 'slide1' | 'slide2', params: { top: number } };

  public hideFooter: boolean;

  private isScrolling$: Subject<void>;
  private toggle: boolean;

  constructor() {
    this.slideCartUp = 'none';
    this.hideFooter = false;

    this.isStickied = false;
    this.isScrolling$ = new Subject();

    // On scroll start
    this.isScrolling$.asObservable().subscribe(() => {
      if (this.slideCartUp !== 'none' && this.getChildOverlapAmount() === 0) {
        // Move cart back to its original position if we're scrolling and it's no longer overlapping
        // This makes for a quicker "back to where it belongs" feel than waiting for scroll end
        this.slideCartUp = 'none';
      }

      this.hideFooter = this.isFooterOverlappingParent();
    });

    // On scroll end
    this.isScrolling$.asObservable().pipe(
      debounceTime(STOPPED_SCROLLING_DELAY_MS),
    ).subscribe(() => {
      const overlapAmount = this.getChildOverlapAmount();

      if (overlapAmount > 0) {
        // When the cart overlaps the bottom edge of the parent, animate it up a bit to ensure it's always within the parent.
        // This prevents it covering up content outside the cart area.
        this.slideCartUp = {
          value: this.toggle ? 'slide1' : 'slide2', // Toggle between two values to ensure animations trigger on every change
          params: { top: overlapAmount }
        };
        this.toggle = !this.toggle;
      } else {
        this.slideCartUp = 'none';
      }
    });
  }

  public onStickyStateChanged(stickyState: StickyState): void {
    this.isStickied = stickyState !== 'none';
  }

  @HostListener('window:scroll')
  @DebounceHostListener()
  public onScroll(): void {
    this.isScrolling$.next();
  }

  private getChildOverlapAmount(): number | 0 {
    const parentBottom = this.parentEl.nativeElement.getBoundingClientRect().bottom;
    const childBottom = this.childEl.nativeElement.getBoundingClientRect().bottom;
    const whitespace = parseInt(computedStyle(document, this.whitespaceEl.nativeElement, 'paddingBottom'), 10) || 0;
    const realChildBottom = childBottom + whitespace;

    const existingOffset = this.slideCartUp === 'none' ? 0 : this.slideCartUp.params.top;
    const originalChildBottom = realChildBottom + existingOffset;

    // Bottom = position of bottom edge relative to the top of the screen. Larger value = farther down.
    if (parentBottom < originalChildBottom) {
      return Math.round(originalChildBottom - parentBottom);
    } else {
      return 0;
    }
  }

  private isFooterOverlappingParent(): boolean {
    const parentBottom = this.parentEl.nativeElement.getBoundingClientRect().bottom;
    const footerBottom = this.footerEl.nativeElement.getBoundingClientRect().bottom;
    return footerBottom > parentBottom;
  }
}
