<div
  *ngIf="recommendations.length >= 3"
  test-id="component"
>
  <app-in-viewport analyticsId="recommendations-in-viewport"></app-in-viewport>
  <h2 class="sub-header--bold mb-x-small">Recommended for you</h2>
  <p class="sub-text text-color-stone-2 mt-0 mb-medium">Based on your past orders</p>
  <div class="row">
    <div class="col col-lg-4 col-md-6 col-12" *ngFor="let result of recommendations.slice(0, 3); index as position">
      <app-search-result
        [url]="url"
        [date]="date"
        [result]="result"
        [showDistance]="showDistance"
        [showRemainingCapacity]="true"
        [showExactRemainingCapacity]="showExactRemainingCapacity"
        analyticsId="search-recommendation"
        [analyticsData]="{ position: (position + 1), vendorName: result.vendorName }"
        test-id="recommendations"
      ></app-search-result>
    </div>
  </div>
</div>
