import { createAction, props, union } from '@ngrx/store';

export const OperationalRegionsActions = {

  loadOperationalRegions: createAction(
    '[Root] Load Operational Regions',
  ),

  operationalRegionsLoaded: createAction(
    '[Root] Operational Regions Loaded',
    props<{ regions: string[] }>(),
  ),
};

const all = union(OperationalRegionsActions);
export type OperationalRegionsAction = typeof all;
