<app-logo-popup
  [show]="true"
  [noWidth]="false"
  (close)="onClose()"
  test-id="popup"
>
    <div class="dialog__title">Change Delivery Details</div>

    <div class="dialog__content">
        <form
          class="form text-center"
          test-id="form"
        >
        <div
          class="my-large"
          test-id="currentDetails"
        >
          Your current delivery details:
          <br>
          {{ requestedDeliveryDate.format('DD/MM/YYYY') }} at {{ deliveryWindow | formatDeliveryWindow }}
        </div>

        <app-date-parameters-form
          [control]="paramsControl"
          [minDay]="earliestSelectableDay"
          analyticsId="date-parameters-form"
          test-id="dateParametersForm"
        ></app-date-parameters-form>

        <div
          *ngIf="dateIsChanged && !isLoading"
          class="mt-small mb-x-large text-size-small"
        >
          <div
            *ngIf="dateAvailability.isAvailable; else dateIsUnavailable"
            test-id="dateAvailable"
          >
            <ng-container *ngFor="let vendor of dateAvailability.vendorAvailabilities">
              <div
                *ngIf="vendor.isAvailable; else vendorIsUnavailable"
                class="d-flex justify-content-center align-items-center mb-small"
                test-id="vendorAvailable"
              >
                <i class="icon icon--medium text-color-green-1 mr-small" aria-hidden="true">
                  check_circle
                </i> {{ vendor.vendorName }} is still available
                <app-in-viewport analyticsId="vendor-available"></app-in-viewport>
              </div>

              <ng-template #vendorIsUnavailable>
                <div
                  class="d-flex justify-content-center align-items-center mb-small"
                  test-id="vendorUnavailable"
                >
                  <i class="icon icon--medium text-color-red-1 mr-x-small" aria-hidden="true">
                    cross_circle
                  </i> {{ vendor.vendorName }} is not available
                </div>
                <app-in-viewport analyticsId="vendor-unavailable"></app-in-viewport>
              </ng-template>
            </ng-container>

            <div *ngIf="unavailableVendors.length > 0" class="mt-medium">
              Please change delivery details or find {{ 'a new vendor' | plural:unavailableVendors.length:'new vendors' }}
            </div>
          </div>

          <ng-template #dateIsUnavailable>
            <div test-id="vendorUnavailable">
              <i class="icon icon--medium text-color-red-1 mr-x-small" aria-hidden="true">
                cross_circle
              </i> {{ vendors[0].vendorName + ' is' | plural:vendors.length: 'Your vendors are' }} not available
              <br>
              Please change delivery details or find {{ 'a new vendor' | plural:vendors.length:'new vendors' }}
              <app-in-viewport analyticsId="date-unavailable"></app-in-viewport>
            </div>
          </ng-template>
        </div>
      </form>
    </div>

    <div class="dialog__footer flex-column">
      <ng-container *ngIf="!dateIsChanged || !dateIsUnavailable(); else findNewVendor">
        <button
          class="button button--full-width"
          type="button"
          (click)="onUpdateDeliveryDetailsClick()"
          [disabled]="isLoading || !dateIsChanged"
          analyticsId="change-date-button"
          test-id="changeDateButton"
        >
          <ng-container *ngIf="!isLoading; else loading">
            Update Delivery Details
          </ng-container>
        </button>
      </ng-container>

      <ng-template #findNewVendor>
        <button
          class="button button--full-width"
          type="button"
          (click)="onFindNewVendorClick()"
          [disabled]="isLoading || !dateIsChanged"
          analyticsId="find-new-vendor-button"
          test-id="findNewVendorsButton"
        >
          <ng-container *ngIf="!isLoading; else loading">
            Find New {{ 'Vendor' | plural:unavailableVendors.length }}
          </ng-container>
        </button>
      </ng-template>

      <ng-template #loading>
        <span class="circle-spinner mr-2" test-id="spinner"></span>
      </ng-template>
    </div>
</app-logo-popup>
