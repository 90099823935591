import { RecommendationRequest, SearchResult } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const RecommendationActions = {
  setRecommendationRequest: createAction(
    '[Recommendations] Set recommendation request',
    props<{ recommendationRequest: RecommendationRequest }>(),
  ),

  recommendedVendorsLoaded: createAction(
    '[Recommendations] Recommended vendors loaded',
    props<{ recommendedVendors: SearchResult[] }>(),
  ),

  loadStaticRecommendedVendors: createAction(
    '[Undeliverable Menu Page] Load static recommended vendors'
  )
};

const all = union(RecommendationActions);
export type RecommendationAction = typeof all;
