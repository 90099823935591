import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from '@citypantry/components-click-outside';
import { RouterComponentsModule } from '@citypantry/components-router';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { LostbarModule } from '@citypantry/shared-lostbar';
import { VendorNotificationsModule } from '@citypantry/shared-vendor-notifications';
import { HeaderContainerComponent } from './header-container.component';
import { HeaderComponent } from './header.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { DefaultUserHeaderComponent } from './components/default-user-header/default-user-header.component';
import { StaffUserHeaderComponent } from './components/staff-user-header/staff-user-header.component';
import { VendorUserHeaderComponent } from './components/vendor-user-header/vendor-user-header.component';
import { PurchaserUserHeaderComponent } from './components/purchaser-user-header/purchaser-user-header.component';
import { EaterUserHeaderComponent } from './components/eater-user-header/eater-user-header.component';
import { OnDemandUserHeaderComponent } from './components/on-demand-user-header/on-demand-user-header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AnalyticsComponentsModule,
    ClickOutsideModule,
    VendorNotificationsModule,
    RouterComponentsModule,
    LostbarModule,
  ],
  declarations: [
    HeaderContainerComponent,
    SimpleHeaderComponent,
    HeaderComponent,
    HeaderDropdownComponent,
    DefaultUserHeaderComponent,
    StaffUserHeaderComponent,
    VendorUserHeaderComponent,
    PurchaserUserHeaderComponent,
    EaterUserHeaderComponent,
    OnDemandUserHeaderComponent,
  ],
  exports: [
    HeaderContainerComponent,
    SimpleHeaderComponent,
    OnDemandUserHeaderComponent,
  ],
})
export class HeaderComponentsModule { }
