/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type TrackingView = 'tracking-available-soon' | 'in-progress' | 'delivered' | 'tracking-not-available';

export class TrackingViews {
  public static TRACKING_AVAILABLE_SOON: TrackingView & 'tracking-available-soon' = 'tracking-available-soon';
  public static IN_PROGRESS:             TrackingView & 'in-progress'             = 'in-progress';
  public static DELIVERED:               TrackingView & 'delivered'               = 'delivered';
  public static TRACKING_NOT_AVAILABLE:  TrackingView & 'tracking-not-available'  = 'tracking-not-available';

  public static get values(): TrackingView[] {
    return [
      TrackingViews.TRACKING_AVAILABLE_SOON,
      TrackingViews.IN_PROGRESS,
      TrackingViews.DELIVERED,
      TrackingViews.TRACKING_NOT_AVAILABLE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type TrackingViewEnum = typeof TrackingViews;
