import moment from 'moment';
import { CustomerId } from '../customer';
import { createEaterFromJson, Eater } from '../individual-choice/eater/eater.model';
import { MajorCurrency } from '../money';
import { UserId } from '../user-id.model';

// TODO: CPD-14470 remove this interface as the api response and colleague group interface should be made identical
export interface ColleagueGroupApiResponse {
  id: string;
  oid: string;
  name: string;
  perOrderBudgetConfig: BudgetConfig;
  perDayBudgetConfig: BudgetConfig;
  perWeekBudgetConfig: BudgetConfig;
  perMonthBudgetConfig: BudgetConfig;
  perDaySubsidisedOrderLimitConfig: SubsidisedOrderLimitConfig;
  perWeekSubsidisedOrderLimitConfig: SubsidisedOrderLimitConfig;
  perMonthSubsidisedOrderLimitConfig: SubsidisedOrderLimitConfig;
  email?: string; // Show up in the designs but don't come down from the backend yet
  customerId: CustomerId;
  members: Eater[];
  createdById: UserId;
  deletedAt: moment.Moment | null;
}

export interface BudgetConfig {
  enabled: boolean;
  amount: MajorCurrency;
}

export interface BudgetConfigs {
  perOrder: BudgetConfig;
  perDay: BudgetConfig;
  perWeek: BudgetConfig;
  perMonth: BudgetConfig;
}

export interface SubsidisedOrderLimitConfig {
  enabled: boolean;
  limit: number;
}

export interface SubsidisedOrderLimitConfigs {
  perDay: SubsidisedOrderLimitConfig;
  perWeek: SubsidisedOrderLimitConfig;
  perMonth: SubsidisedOrderLimitConfig;
}

export interface ColleagueGroupResult {
  id: string;
  oid: string;
  name: string;
  budgetConfigs: BudgetConfigs;
  subsidisedOrderLimitConfigs: SubsidisedOrderLimitConfigs;
  email?: string; // Show up in the designs but don't come down from the backend yet
  customerId: CustomerId;
  members: Eater[];
  createdById: UserId;
  deletedAt: moment.Moment | null;
}

export function createColleagueGroupResultFromJson(json: Partial<ColleagueGroupApiResponse>): ColleagueGroupResult {
  return {
    id: json.id,
    oid: json.oid,
    name: json.name,
    customerId: json.customerId,
    members: json.members.map(createEaterFromJson),
    createdById: json.createdById,
    deletedAt: json.deletedAt ? moment(json.deletedAt) : null,

    // TODO: CPD-14470 remove this mapping as the api response and colleague group interface should be made identical
    budgetConfigs: {
      perOrder: json.perOrderBudgetConfig,
      perDay: json.perDayBudgetConfig,
      perWeek: json.perWeekBudgetConfig,
      perMonth: json.perMonthBudgetConfig,
    },
    subsidisedOrderLimitConfigs: {
      perDay: json.perDaySubsidisedOrderLimitConfig,
      perWeek: json.perWeekSubsidisedOrderLimitConfig,
      perMonth: json.perMonthSubsidisedOrderLimitConfig,
    }
  };
}
