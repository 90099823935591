export interface CalendarInvoice {
  id: string;
  url: string | null;
}

export function createLatestInvoiceFromJson(json: Partial<CalendarInvoice>): CalendarInvoice {
  json = json || {};
  const invoice: Partial<CalendarInvoice> = {};
  invoice.id = json.id || '';
  invoice.url = json.url || null;
  return invoice as CalendarInvoice;
}
