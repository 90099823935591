<div class="additional-item-info">
  <a
    class="additional-item-info__close"
    (click)="onClose()"
  >close</a>
  <p
    *ngIf="showDescription && item.description"
    class="additional-item-info__description"
    test-id="description"
  >{{ item.description }}</p>
  <span
    class="additional-item-info__meta"
    test-id="meta"
  >
     <span
       test-id="foodTemperature"
     >
       {{ item.hot ? 'Hot food' : 'Cold food' }}
     </span>
     <span
       *ngIf="item.spicy"
       test-id="spicy"
     >- Spicy</span>
     <span
       *ngIf="item.containsAlcohol"
       test-id="alcohol"
     >- Contains alcohol</span>
  </span>
  <p
    *ngIf="item.ingredients?.length"
    class="additional-item-info__details"
    test-id="ingredients"
  >
    <strong>Ingredients:</strong>
    <span test-id="ingredients">
      {{ item.ingredients | concat }}
    </span>
  </p>
  <p
    *ngIf="shouldShowAllergens()"
    class="additional-item-info__details"
    test-id="allergens"
  >
    <strong>Allergens:</strong>
    <span test-id="allergens">
      {{ item.allergens | allergenNameList:'; ' }}
    </span>
  </p>
  <p class="additional-item-info__details">
    <strong>Suitable for: </strong>
    <span
      *ngIf="shouldShowAgeRestricted()"
      test-id="ageRestrictedText"
    >People aged 18+, </span>
    <span
      test-id="dietaries"
    >{{ item.dietaries | dietNameList }}</span>
  </p>
  <p
    *ngIf="isCustomItem(item) && shouldShowCustomItemAlcoholPercentage(item)"
    class="additional-item-info__details"
    test-id="alcoholPercentageCustomItem"
  >
    <strong>ABV: </strong> Varies depending on selected options
  </p>
  <p
    *ngIf="isSingleItem(item) && item.alcoholPercentage"
    class="additional-item-info__details"
    test-id="alcoholPercentageSingleItem"
  >
    <strong>ABV: </strong> {{ item.alcoholPercentage }}%
  </p>
</div>
