<app-logo-popup
  [show]="true"
  [noWidth]="false"
  (close)="keepOrderClicked()"
  test-id="popup"
>
  <h3 class="dialog__title mb-small">Cancel existing order?</h3>

  <div
    class="dialog__content m-large"
    test-id="content"
  >
    You must cancel your existing order to find {{ isMultiVendor ? 'new restaurants' : 'a new restaurant' }},
    would you like to proceed?
  </div>

  <div class="dialog__footer dialog__buttons justify-content-center">
    <button
      class="button button--secondary"
      type="button"
      [disabled]="cancellationInProgress"
      (click)="keepOrderClicked()"
      test-id="keepOrderButton"
      analyticsId="keep-order-button"
    >
      Keep existing order
    </button>

    <button
      class="button"
      type="button"
      [disabled]="cancellationInProgress"
      (click)="cancelOrderClicked()"
      test-id="cancelOrderButton"
      analyticsId="cancel-order-button"
    >
      <ng-container *ngIf="!cancellationInProgress">
        Cancel existing order
      </ng-container>

      <ng-container *ngIf="cancellationInProgress">
        <span class="circle-spinner" test-id="spinner"></span>
      </ng-container>
    </button>
  </div>
</app-logo-popup>
