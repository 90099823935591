/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type StaticRecommendedVendor = 'Leon' | 'Itsu' | 'Coco Di Mama';

export class StaticRecommendedVendors {
  public static LEON:         StaticRecommendedVendor & 'Leon'         = 'Leon';
  public static ITSU:         StaticRecommendedVendor & 'Itsu'         = 'Itsu';
  public static COCO_DI_MAMA: StaticRecommendedVendor & 'Coco Di Mama' = 'Coco Di Mama';

  public static get values(): StaticRecommendedVendor[] {
    return [
      StaticRecommendedVendors.LEON,
      StaticRecommendedVendors.ITSU,
      StaticRecommendedVendors.COCO_DI_MAMA,
    ];
  }

  // Do not modify this line - Custom code below here
}
export type StaticRecommendedVendorEnum = typeof StaticRecommendedVendors;
