import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem } from '@citypantry/util';
import { createMenuFromJson, Menu, MenuApprovalState, MenuApprovalStates } from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuApprovalApi {

  constructor(
    private http: HttpClient
  ) {}

  public requestApproval(menuId: string): Observable<Menu> {
    return this.updateApprovalStatus(menuId, MenuApprovalStates.SUBMITTED);
  }

  public approve(menuId: string): Observable<Menu> {
    return this.updateApprovalStatus(menuId, MenuApprovalStates.APPROVED);
  }

  public cancelApproval(menuId: string): Observable<Menu> {
    return this.updateApprovalStatus(menuId, MenuApprovalStates.CANCELLED);
  }

  public rejectApproval(menuId: string): Observable<Menu> {
    return this.updateApprovalStatus(menuId, MenuApprovalStates.REJECTED);
  }

  private updateApprovalStatus(menuId: string, status: MenuApprovalState): Observable<Menu> {
    return this.http.put(`menus/${menuId}/draft/status`, { status }).pipe(
      getItem(),
      map(createMenuFromJson)
    );
  }
}
