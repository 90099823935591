/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type BetaFeature =
  'my-orders';

export class BetaFeatures {
  public static MY_ORDERS: BetaFeature & 'my-orders' = 'my-orders';

  public static get values(): BetaFeature[] {
    return [
      BetaFeatures.MY_ORDERS,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type BetaFeatureEnum = typeof BetaFeatures;
