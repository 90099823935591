import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AppConfig, environment } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { safeUnsubscribe } from '@citypantry/util';
import { WindowRef } from '@citypantry/util-browser';
import { ErrorMessage, ErrorResponse } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { ErrorActions } from './error.actions';
import { SENTRY, Sentry } from './sentry';

export const UNKNOWN_ERROR_KEY = 'unknown_error';
export const LOG_ERRORS_TO_CONSOLE_KEY = 'logErrors';

@Injectable()
export class ErrorReporterService implements OnDestroy {

  private authSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private appConfig: AppConfig,
    private window: WindowRef,
    @Inject(SENTRY) private sentry: Sentry
  ) {
  }

  public initialiseSentry(): void {
    if (this.appConfig.SENTRY_ENABLED !== true) {
      return;
    }
    this.sentry.init({
      dsn: 'https://e8198786939142a4b046fa82196165db@sentry.io/1296449',
      release: `menus@${ this.appConfig.VERSION }`,
      environment: this.appConfig.ENV
    });

    this.authSubscription = combineLatest([
      this.store.select(AuthSelectors.getUserId),
      this.store.select(AuthSelectors.getUserName)
    ]).subscribe(([userId, userName]) => {
      this.sentry.configureScope((scope) => {
        scope.setUser({
          'id': userId ? userId.toString() : null,
          'username': userName,
        });
      });
    });
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.authSubscription);
  }

  public reportError(error: any, showErrorMessage: boolean = true): void {
    const rayId = this.extractRequestRayIdIfPresent(error);

    if (showErrorMessage) {
      let messages;
      if (error instanceof ErrorResponse) {
        messages = error.messages;
      } else {
        messages = [this.convertErrorToMessage(error)];
      }

      messages.forEach((message) => {
        const errorMessage: ErrorMessage = { ...message };

        if (rayId) {
          errorMessage.rayId = rayId;
        }

        this.store.dispatch(ErrorActions.showErrorMessage({ error: errorMessage }));
      });
    }

    this.logErrorToConsole(error);

    if (this.appConfig.SENTRY_ENABLED === true) {
      this.sentry.setTag('rayId', rayId);
      this.sentry.captureException(error.originalError || error);
    }
  }

  private convertErrorToMessage(error: any): ErrorMessage {
    if (error && error.hasOwnProperty('key')) {
      return {
        ...error,
        messageArgs: error.messageArgs || {},
      };
    }

    return {
      key: UNKNOWN_ERROR_KEY,
      messageArgs: {
        // Don't log the whole error as it may contain lots of data, which will absolutely screw up the store
        error: (error as Error).message
      },
      context: error instanceof Error
        ? error.message // frontend error
        : error.error && error.error.errors || error.error, // backend error
    };
  }

  private extractRequestRayIdIfPresent(error: any): string | null {
    if (error.headers instanceof HttpHeaders && error.headers.has('cf-ray')) {
      return error.headers.get('cf-ray');
    }

    return null;
  }

  private logErrorToConsole(error: any): void {
    if (environment.production && this.window.nativeWindow.localStorage.getItem(LOG_ERRORS_TO_CONSOLE_KEY) !== 'true') {
      return;
    }
    console.warn('Caught error', error);
  }

}
