export interface AnonymousItem {
  name: string;
  quantity: number;
  bundleItems: AnonymousItem[];
}

export function createAnonymousItemFromJson(json?: Partial<AnonymousItem>): AnonymousItem {
  json = json || {};
  const anonymousItem: Partial<AnonymousItem> = {};

  anonymousItem.name = json.name;
  anonymousItem.quantity = json.quantity;
  anonymousItem.bundleItems = json.bundleItems ? json.bundleItems.map(createAnonymousItemFromJson) : [];

  return anonymousItem as AnonymousItem;
}
