import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-logo-popup',
  templateUrl: './logo-popup.component.html'
})
export class LogoPopupComponent {

  @Input()
  public hideCloseButton?: true;

  @Input()
  public show: boolean;

  @Input()
  public noWidth?: boolean = false;

  @Input()
  public modalStyle: 'medium' | 'large';

  @Output()
  public close: EventEmitter<'close' | 'outside'> = new EventEmitter();

  public onCloseClicked(): void {
    this.close.emit('close');
  }

  public onOutsideClicked(): void {
    this.close.emit('outside');
  }
}
