import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapmap } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  createOrderRefundFromJson,
  createOrderVendorRefundFromJson,
  OrderId,
  OrderRefund,
  OrderVendorRefund,
  Refund,
  RefundReasonsMap,
} from '@citypantry/util-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefundApi {

  constructor(
    private http: HttpClient,
  ) { }

  public getRefundReasons(): Observable<{ other: string, reasons: RefundReasonsMap }> {
    return this.http.get<{ other: string, reasons: RefundReasonsMap }>('/refund-reasons');
  }

  public refund(orderId: OrderId, refund: Refund): Observable<{ success: true } | { success: false, error: string }> {
    return this.http.post(`/orders/${orderId}/refunds`, refund, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      mapTo({ success: true as const }),
      catchError((response: HttpErrorResponse) => throwError({ success: false, error: response.error })),
    );
  }

  public getRefunds(orderId: OrderId): Observable<OrderRefund[]> {
    return this.http.get<Observable<any>>(`/orders/${orderId}/refunds`).pipe(
      map(({ refunds }: any): Partial<OrderRefund[]> => refunds || []),
      mapmap(createOrderRefundFromJson),
    );
  }

  public getVendorRefunds(orderId: OrderId): Observable<OrderVendorRefund[]> {
    return this.http.get(`/orders/${orderId}/vendor-refunds`).pipe(
      map(({ vendorRefundBreakdowns }: any): Partial<OrderVendorRefund[]> => vendorRefundBreakdowns),
      mapmap(createOrderVendorRefundFromJson),
    );
  }
}
