import { Component } from '@angular/core';

@Component({
  selector: 'app-mega-footer',
  templateUrl: './mega-footer.component.html',
  styleUrls: ['./mega-footer.component.scss']
})
export class MegaFooterComponent {
  public locationPanelActive: boolean;
  public eventTypePanelActive: boolean;
  public foodTypePanelActive: boolean;

  constructor() {
    this.locationPanelActive = false;
    this.eventTypePanelActive = false;
    this.foodTypePanelActive = false;
  }

  public toggleLocationDropPanel(show: boolean): void {
    this.locationPanelActive = show;
  }

  public toggleEventTypeDropPanel(show: boolean): void {
    this.eventTypePanelActive = show;
  }

  public toggleFoodTypeDropPanel(show: boolean): void {
    this.foodTypePanelActive = show;
  }
}
