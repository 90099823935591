import { Image } from '../image.model';
import { VendorId } from './vendor.model';

export interface SimpleVendor {
  id: VendorId;
  name: string;
  slug: string;
  avatarUrl: string;
  images: Image[];
  providesCutlery: boolean;
}

export function createSimpleVendorFromJson(json?: Partial<SimpleVendor>): SimpleVendor {
  json = json || {};

  const vendor: Partial<SimpleVendor> = {};

  vendor.id = json.id;
  vendor.name = json.name;
  vendor.slug = json.slug;
  vendor.avatarUrl = json.avatarUrl;
  vendor.images = json.images || [];
  vendor.providesCutlery = !!json.providesCutlery;

  return vendor as SimpleVendor;
}
