import { AgeConfirmationEnum } from '@citypantry/util-enums';
import {
  Cart,
  CartDeliverability,
  CartItemBundleGroup,
  CartNotification,
  CostBreakdown,
  CustomerId,
  DeliverableMenu,
  DeliverableMenuError,
  ItemBundle,
  MealPlan,
  Order,
  OrderValidationError,
  Page,
  PromotionName,
  ReorderCartDetails,
  SearchOrderType,
  SearchRequest,
  SearchResult,
  SingleItem,
  UndeliverableMenu,
  VendorId,
  VendorLocationId
} from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';
import moment, { Moment } from 'moment';

interface AddOrUpdateItemBundleInCartEvent {
  quantity: number;
  cartGroups: CartItemBundleGroup[];
}

export const PublicActions = {
  resetSearch: createAction(
    '[Search Effects] Reset Search',
  ),

  /**
   * Update the search parameters with the new request data (merging it in),
   * and run a new search if the parameters have changed.
   *
   * THIS IS THE ONE YOU WANT TO USE TO TRIGGER A SEARCH.
   */
  search: createAction(
    '[Search] Update Search',
    props<{ request?: SearchRequest }>(),
  ),

  // Sets the search request, clears non-MKP search parameters
  prepareSearch: createAction(
    '[Search] Prepare Search',
    props<{ request: SearchRequest }>(),
  ),

  // Internal: Call the API
  searchResultsLoad: createAction(
    '[Search Effects] Search Results Load from API',
    props<{ request: SearchRequest, pageNumber: number }>(),
  ),

  searchResultsLoadFailure: createAction(
    '[Search API] Search Results Load Failure',
    props<{ error: any }>(),
  ),

  searchResultsLoadSuccess: createAction(
    '[Search API] Search Results Load Success',
    props<{ resultsPage: Page<SearchResult> }>(),
  ),

  fetchMealPlanForOrder: createAction(
    '[Search] Fetch Meal Plan For Order',
    props<{ mealPlanId: string, proposedOrderId: string }>(),
  ),

  mealPlanLoaded: createAction(
    '[Meal Plan] Meal Plan Loaded',
    props<{ mealPlan: MealPlan, proposedOrderId: string }>(),
  ),

  prepareMealPlanSearch: createAction(
    '[Search] Prepare Mealplan Search',
    props<{ request: SearchRequest, mealPlan: MealPlan, proposedOrderId: string }>(),
  ),

  updateSearchParameters: createAction(
    '[Search] Update Search Parameters',
    props<{ request: Partial<SearchRequest> }>(),
  ),

  operationalRegionLoadSuccess: createAction(
    '[Search] Operational Region Load Success',
    props<{ operationalRegion: string }>(),
  ),

  operationalRegionLoadFailure: createAction(
    '[Search] Operational Region Load Failure',
  ),

  loadNextPage: createAction(
    '[Search] Load next page',
  ),

  selectItemBundle: createAction(
    '[Menu] Select Item Bundle',
    props<{ itemBundle: ItemBundle }>(),
  ),

  unselectItemBundle: createAction(
    '[Menu] Unselect Item Bundle',
  ),

  prepareCart: createAction(
    '[Public: MKP Cart] Prepare cart',
    props<{
      fromSearch: boolean;
      requestedDate: Moment | null;
      restoreItemsFromCartId: string | null;
      restoreDateFromCart: boolean;
    }>(),
  ),

  trackRestoreItemsFromCartSourceAnalytic: createAction(
    '[Public: MKP Cart] Track restore items from cart analytic',
    props<{ source: string }>(),
  ),

  cartPrepareSuccess: createAction(
    '[Public: MKP Cart Effects] Cart Prepare Success',
  ),

  createNewCart: createAction(
    '[Public: MKP Cart] Create new cart',
    props<{
      deliveryDate: moment.Moment;
      postcode: string | null;
      menuId: string;
      contentId: string;
      vendorId: VendorId;
      vendorLocationId: VendorLocationId;
      currencyIsoCode: string;
      reorderCart?: ReorderCartDetails | null;
    }>(),
  ),

  updateCart: createAction(
    '[Public: MKP Cart] Update cart',
    props<{ deliveryDate: Moment, postcode: string | null }>(),
  ),

  addOrUpdateSingleItem: createAction(
    '[Public: MKP Cart] Add or update Single Item',
    props<{ item: SingleItem, quantity: number }>(),
  ),

  addOrUpdateItemBundle: createAction(
    '[Public: MKP Cart] Add or update Item Bundle',
    props<{ event: AddOrUpdateItemBundleInCartEvent }>(),
  ),

  removeItemFromCart: createAction(
    '[Public: MKP Cart] Remove Item From Cart',
    props<{ cartIndex: number }>(),
  ),

  setupExistingOrder: createAction(
    '[Public: MKP Cart] Set cart for existing order',
    props<{ order: Order, cart: Cart, fromAdmin: boolean }>(),
  ),

  verifyCardThenUpdateCart: createAction(
    '[Public: MKP Cart] Verify card then update cart',
    props<{ cart: Cart, hasCustomerConfirmedAge: AgeConfirmationEnum }>(),
  ),

  failedToVerifyCard: createAction(
    '[Public: MKP Cart] Failed to verify card',
    props<{ error: Error }>(),
  ),

  updateCartOnOrder: createAction(
    '[Public: MKP Cart] Update the cart on an existing order',
    props<{ cart: Cart, hasCustomerConfirmedAge: AgeConfirmationEnum, threeDSecureEnrichedNonce: string | null }>(),
  ),

  createMealplanCart: createAction(
    '[Public: MKP Cart] Create Mealplan Cart',
    props<{
      deliveryDate: moment.Moment;
      mealPlan: MealPlan;
      menuId: string;
      contentId: string;
      vendorId: VendorId;
      vendorLocationId: VendorLocationId;
      reorderCart: ReorderCartDetails | null;
      currencyIsoCode: string;
    }>(),
  ),

  clearMealPlanCart: createAction(
    '[Public: MKP Cart] Clear Mealplan Cart',
  ),

  setupExistingMealplanCart: createAction(
    '[Public: MKP Cart] Setup existing meal plan cart',
    props<{ mealPlan: MealPlan, cart: Cart }>(),
  ),

  replaceMealPlanCart: createAction(
    '[Public: MKP Cart] Replace meal plan cart',
    props<{ cart: Cart }>(),
  ),

  createMenuPdf: createAction(
    '[Public: MKP Cart] Request creation of a menu PDF',
  ),

  setCartFreeDelivery: createAction(
    '[Public: MKP Cart] Set cart free delivery',
    props<{ freeDelivery: boolean }>(),
  ),

  trackAbandonedCart: createAction(
    '[Public: MKP Cart] Track abandoned cart',
  ),

  deliverableMenuLoaded: createAction(
    '[Public] Deliverable Menu Loaded',
    props<{ deliverableMenu: DeliverableMenu }>(),
  ),

  undeliverableMenuLoaded: createAction(
    '[Public] Undeliverable Menu Loaded',
    props<{ undeliverableMenu: UndeliverableMenu }>(),
  ),

  loadMenuRegionSuccess: createAction(
    '[Public] Load Menu Region Success',
    props<{ region: string }>(),
  ),

  loadMenuRegionFailure: createAction(
    '[Public] Load Menu Region Failure',
  ),

  deliverableMenuDateChanged: createAction(
    '[Public] Deliverable Menu Date Changed',
    props<{ queryDate: Moment, newDate: Moment }>(),
  ),

  closeDateChangedDialog: createAction(
    '[Public] Close DateChanged Dialog',
  ),

  loadDeliverableMenuFailed: createAction(
    '[Public] Load Deliverable Menu Failed',
    props<{ error: DeliverableMenuError }>(),
  ),

  checkCartDeliverability: createAction(
    '[Public] Check Cart Deliverability',
  ),

  cartDeliverabilityLoaded: createAction(
    '[Public] Cart Deliverability Loaded',
    props<{ deliverability: CartDeliverability, isSubmittingCart: boolean }>(),
  ),

  // Cart parameters modal has emitted a new value
  updateCartParameters: createAction(
    '[Public] Update Cart Parameters',
    props<{ deliveryDate: Moment, postcode: string }>(),
  ),

  openCartParamsModal: createAction(
    '[Public] Open Cart Params Modal',
  ),

  closeCartParamsModal: createAction(
    '[Public] Close Cart Params Modal',
  ),

  // Cart parameters modal closed with the "submit" action
  submitCartChanges: createAction(
    '[Public] Submit Cart Changes',
    props<{ override: boolean }>(),
  ),

  doSubmitCart: createAction(
    '[Public] Do Submit Cart',
    props<{ overrideErrors: boolean }>(),
  ),

  // Cart parameters were updated, their validation has finished loading
  pendingCartDeliverabilityLoaded: createAction(
    '[Public] Pending Cart Deliverability Loaded',
    props<{ deliverability: CartDeliverability, menu: DeliverableMenu | null, menuError: DeliverableMenuError | null }>(),
  ),

  setCartNotes: createAction(
    '[Public: MKP Cart] Set Cart Notes',
    props<{ notes: string }>(),
  ),

  setCartCutlery: createAction(
    '[Public: MKP Cart] Set Cart Cutlery',
    props<{ includeCutleryAndNapkins: boolean }>(),
  ),

  // Cart parameters modal closed with the "Resolve" action
  removeInvalidCartItems: createAction(
    '[Public: MKP Cart] Submit Cart Changes and Remove Invalid Cart Items',
  ),

  showHubspotModal: createAction(
    '[Public] Show Hubspot form modal',
  ),

  hideHubspotModal: createAction(
    '[Public] Hide Hubspot form modal',
  ),

  cartNotificationsLoaded: createAction(
    '[Public] Cart Notifications Loaded',
    props<{ notifications: CartNotification[] }>(),
  ),

  loadCartNotifications: createAction(
    '[Public] Load Cart Notifications',
  ),

  submitCart: createAction(
    '[Public: MKP Cart] Submit Cart',
    props<{ overrideErrors: boolean }>(),
  ),

  submitCartFailed: createAction(
    '[Public: MKP Cart] Submit Cart Failed',
  ),

  showCartOverridesModal: createAction(
    '[Public: MKP Cart] Show Cart Overrides Modal',
    props<{ submitCartOnSuccess: boolean }>(),
  ),

  abortCartOverridesModal: createAction(
    '[Public: MKP Cart] Abort Cart Overrides Modal',
  ),

  clearSearchLocation: createAction(
    '[Search] Clear Search Location',
  ),

  promoCardClick: createAction(
    '[Search] Promo card clicked',
    props<{ promotionName: PromotionName }>(),
  ),

  enterPrintMode: createAction(
    '[Public] Enter print mode',
  ),

  exitPrintMode: createAction(
    '[Public] Exit print mode',
  ),

  validateOrder: createAction(
    '[Public: MKP Cart] Validate Order',
  ),

  orderValidated: createAction(
    '[Public: MKP Cart] Order Validated',
    props<{ costBreakdown: CostBreakdown, cartId: string, isFreeDelivery: boolean }>(),
  ),

  orderValidationFailed: createAction(
    '[Public: MKP Cart] Order Validation Failed',
    props<{ error: OrderValidationError | null, cartId: string | null }>(),
  ),

  updateSearchType: createAction(
    '[Public] Update search type',
    props<{ searchType: SearchOrderType }>(),
  ),

  updateSearchTypeForCustomer: createAction(
    '[Public] Update search type for customer',
    props<{ searchType: SearchOrderType, searchPreferencesCustomerId: CustomerId | null }>(),
  ),

  searchPageWeWorkModeChanged: createAction(
    '[Search Filters] WeWork Mode Changed',
    props<{ enabled: boolean }>(),
  ),

  hideSearchPromoCard: createAction(
    '[Promotion] Hide search promotion card',
  ),

  menuPageWeWorkModeChanged: createAction(
    '[Menu Page] WeWork Mode Changed',
    props<{ enabled: boolean }>(),
  ),

  cartDownloadQuote: createAction(
    '[Public: MKP Cart] Download Quote',
  ),

  cartApiCreateQuoteSuccess: createAction(
    '[Public: MKP Cart Api] Create Quote Success',
    props<{ url: string }>(),
  ),

  cartItemsRestoreFailureDialogDismiss: createAction(
    '[Public: MKP Cart Items Restore Failure Dialog] Dismiss Dialog',
  ),

  outOfRegionCaptureModalSubmitted: createAction(
    '[Out Of Region Capture Modal] Submit',
  ),

  outOfRegionCaptureModalClose: createAction(
    '[Out Of Region Capture Modal] Close',
  ),

  shareCartLinkClick: createAction(
    '[Public: MKP Cart] Share Cart Link Click',
  ),

  shareCartModalDismiss: createAction(
    '[Share Cart Modal] Dismiss',
  ),

  shareCartCopyButtonClick: createAction(
    '[Share Cart Modal] Copy Button Click',
  ),

  shareCartLinkCopyFailed: createAction(
    '[Clipboard Service] Share Cart Link Copy Failure',
  ),

  tickShareCartModalCountdown: createAction(
    '[Public: MKP Cart Effects] Tick Share Cart Modal Countdown',
    props<{ timeRemaining: number }>(),
  ),

  shareCartModalOpen: createAction(
    '[Share Cart Modal] Share Cart Modal Open',
    props<{ shareUrl: string }>(),
  ),

  shareCartLinkCopySuccess: createAction(
    '[Clipboard Service] Share Cart Link Copy Success',
  ),

  openFindNewVendorPopup: createAction(
    '[Find New Vendor Popup] Open',
    props<{ cancelledOrderIds: number[] }>(),
  ),

  closeFindNewVendorPopup: createAction(
    '[Find New Vendor Popup] Close',
  ),

  ageConfirmationDialogConfirm: createAction(
    '[Public: MKP Cart] Age Confirmation Dialog Confirm',
    props<{ overrideErrors: boolean }>(),
  ),

  ageConfirmationDialogCancel: createAction(
    '[Public: MKP Cart] Age Confirmation Dialog Cancel',
  ),
};

const all = union(PublicActions);
export type PublicAction = typeof all;
