<div class="simple-header simple-header--flush">
  <div class="simple-header__content">
    <button
      *ngIf="showBackButton"
      class="simple-header__back-button"
      aria-label="Back"
      (click)="onBackArrowClick()"
      test-id="backButton"
    >
    </button>
    <div
      class="simple-header__title"
      test-id="title"
    >
      {{ heading }}
    </div>
    <p
      *ngIf="subheading"
      class="simple-header__sub-title"
      test-id="subtitle"
    >
      {{ subheading }}
    </p>

    <div class="simple-header__side-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
