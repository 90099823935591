import { FormGroup, ValidatorFn } from '@angular/forms';

export function valueInList(values: any[]): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    const value = group.value;
    if (!value || values.indexOf(value) >= 0) {
      return null;
    }
    return {
      'valueInList': `Value "${value}" is not in the list of allowed values ${values}`
    };
  };
}
