import { InjectionToken } from '@angular/core';
import { PromotionNames } from '@citypantry/util-models';
import { PromotionBannerModel } from './promotion-banner.model';

export type PromotionBannerContent = Partial<{ [key in keyof typeof PromotionNames]: PromotionBannerModel }>;

export const PROMOTION_BANNER_CONTENT = new InjectionToken<PromotionBannerContent>('promotionBannerContent');

export const promotionBannerContent: PromotionBannerContent = {
  [PromotionNames.JUST_EAT_PAY]: {
    name: PromotionNames.JUST_EAT_PAY,
    bannerText: 'Just Eat Pay, food on the go wherever you work',
    buttonText: 'Show me',
    link: '/l/just-eat-pay',
    openLinkInNewTab: true,
  },
  [PromotionNames.SAME_DAY_DELIVERY]: {
    name: PromotionNames.SAME_DAY_DELIVERY,
    bannerText: 'Same-day delivery in Central London!',
    buttonText: 'Show me',
    link: '/menus/search?sameDay=true',
    openLinkInNewTab: false,
  },
  [PromotionNames.FREE_DELIVERY]: {
    name: PromotionNames.FREE_DELIVERY,
    bannerText: 'Free delivery on your first order!',
    buttonText: 'Show me',
    link: 'https://citypantrysupport.zendesk.com/hc/en-us/articles/360007640133-What-is-the-free-delivery-offer-',
    openLinkInNewTab: true,
  },
  [PromotionNames.TOP_UPS]: {
    name: PromotionNames.TOP_UPS,
    bannerText: 'Your colleagues can now pay for extra portions',
    buttonText: 'Show me',
    link: '/l/individual-choice#additional-feature',
    openLinkInNewTab: true,
  },
  [PromotionNames.INDIVIDUAL_CHOICE]: {
    name: PromotionNames.INDIVIDUAL_CHOICE,
    bannerText: 'Your colleagues can choose their own food with Individual Choice',
    buttonText: 'Show me',
    link: '/l/individual-choice',
    openLinkInNewTab: true,
  },
  [PromotionNames.VENDOR_FAQ]: {
    name: PromotionNames.VENDOR_FAQ,
    bannerText: 'For more information please see our FAQ',
    buttonText: 'Show me',
    link: 'https://help.citypantry.com/hc/en-us/categories/360001675413-Vendor-Partners',
    openLinkInNewTab: true,
  },
  [PromotionNames.CUSTOMER_SURVEY]: {
    name: PromotionNames.CUSTOMER_SURVEY,
    bannerText: 'Have you got a minute to tell us about your experience ordering today?',
    buttonText: 'Rate your experience',
    link: 'https://citypantry.typeform.com/to/ql4iP69Q',
    openLinkInNewTab: true,
    hideOnCtaClicked: true
  },
  [PromotionNames.ENABLE_SSO]: {
    name: PromotionNames.ENABLE_SSO,
    bannerText: 'Enable SSO to quickly sign in to Just Eat for Business in one click',
    buttonText: 'Get Started',
    link: '/api/auth0/login',
    openLinkInNewTab: false,
  },
};
