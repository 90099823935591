export interface Contact {
  name: string | null;
  phoneNumber: string;
}

export function createContactFromJson(json?: Partial<Contact>): Contact {
  json = json || {};
  const contact: Partial<Contact> = {};

  contact.name = json.name ? json.name : null;
  contact.phoneNumber = json.phoneNumber;

  return contact as Contact;
}
