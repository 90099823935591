import { Component, Input } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { ChoiceItemGroup, ItemGroup, ItemGroupTypes } from '@citypantry/util-models';

@Component({
  selector: 'app-item-group-prompt',
  template: '{{ message }}'
})
export class ItemGroupPromptComponent {

  @Input()
  public itemGroup: ItemGroup;

  @Input()
  public includeUpgrades: boolean; // Include the message for Upgrade Groups (not displayed to customer)

  public get message(): string {
    switch (this.itemGroup.type) {
      case ItemGroupTypes.FLEXIBLE_GROUP:
        return 'Choose one per person';
      case ItemGroupTypes.FIXED_GROUP:
        return 'Includes the following';
      case ItemGroupTypes.CHOICE_GROUP: {
        const choiceGroup = this.itemGroup as ChoiceItemGroup;
        if (choiceGroup.minimumOrderQuantity === choiceGroup.maximumOrderQuantity) {
          const options = choiceGroup.minimumOrderQuantity === 1 ? 'option' : 'options';
          return `Choose exactly ${choiceGroup.minimumOrderQuantity} ${options}`;
        } else {
          return `Choose ${choiceGroup.minimumOrderQuantity} to ${choiceGroup.maximumOrderQuantity} options`;
        }
      }
      case ItemGroupTypes.UPGRADE_GROUP:
        return this.includeUpgrades ? 'Add Upgrades' : '';
      default:
        throw new UnreachableCaseError(this.itemGroup.type);
    }
  }
}
