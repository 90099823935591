
import { MajorCurrency } from '../../money';
import { aggregateAllergens, Allergen, Allergens, EMPTY_ALLERGENS } from './allergens.model';
import { Dietaries, dietariesIntersection, EMPTY_DIETARIES } from './dietaries.model';
import { FoodType } from './food-type.enum';
import { EMPTY_AVAILABILITY } from './item-availability.model';
import { ItemTypes } from './item-type.enum';
import { Item } from './item.model';

export interface CustomItem extends Item {
  foodType: FoodType;
  portionSize: number;

  // Travel Requirements
  hot: boolean;
  fragile: boolean;
  oversized: boolean;
  returnRequired: boolean;

  // Ingredients and Allergens
  baseItemProvided: boolean;
  allergens: Allergens;
  ingredients: string[];
  spicy: boolean;
  containsAlcohol: boolean;
  baseItemAgeRestricted: boolean;
  baseItemAlcoholPercentage: number | null;
  possibleDietaries: Dietaries;
  ageRestricted: boolean; // true if the base item is age restricted or if only age restricted options can be selected
  baseItemKcal: number | null;

  // Custom options
  sections: CustomItemSection[];
}

export interface CustomItemSection {
  name: string;
  minOptions: number;
  maxOptions: number | null;
  options: CustomItemOption[];
}

export interface CustomItemOption {
  name: string;
  maxQuantity: number;
  price: MajorCurrency;
  dietaries: Dietaries;
  allergens: Allergens;
  ingredients: string[];
  containsAlcohol: boolean;
  ageRestricted: boolean;
  alcoholPercentage: number | null;
  spicy: boolean;
  optionIndex?: number; // This property will only be present for immutable items
  kcal: number | null;
}

export function createEmptyCustomItem(currencyIsoCode: string): CustomItem {
  return {
    type: ItemTypes.CUSTOM_ITEM,
    name: '',
    description: '',
    images: [],
    price: null,
    currencyIsoCode,
    notice: null,
    minimumOrderQuantity: null,
    maximumOrderQuantity: null,
    events: [],
    servingStyle: null,
    cuisine: null,
    ecoFriendlyPackaging: null,
    baseItemKcal: null,

    portionSize: 1,

    hot: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    fragile: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    oversized: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    returnRequired: undefined, // Required to be explicitly set - if it's null the backend will set it to false
    vatRateType: undefined, // Required to be explicitly set - if it's null the backend will set it to false

    baseItemProvided: true, // we require base item by default, has to be explicitly set to false by the user
    ingredients: [],
    allergens: EMPTY_ALLERGENS,
    dietaries: EMPTY_DIETARIES,
    spicy: null,
    containsAlcohol: null,
    baseItemAgeRestricted: null,
    baseItemAlcoholPercentage: null,
    possibleDietaries: EMPTY_DIETARIES,
    ageRestricted: false,

    foodType: null,
    availability: EMPTY_AVAILABILITY,

    sections: [],
  };
}

export function isCustomItem(item: Item): item is CustomItem {
  return item.type === ItemTypes.CUSTOM_ITEM;
}

export function computeAllergens(baseAllergens: Allergens, selectedOptions: CustomItemOption[]): Allergen[] {
  const selectedOptionAllergens = selectedOptions.map(({ allergens }) => allergens);
  return aggregateAllergens(selectedOptionAllergens.concat(baseAllergens));
}

export function computeDietaries(baseDietaries: Dietaries, selectedOptions: CustomItemOption[]): Dietaries {
  const selectedOptionDietaries = selectedOptions.map(({ dietaries }) => dietaries);
  return dietariesIntersection(selectedOptionDietaries.concat(baseDietaries));
}

// For the createFromJson() method see create-item.ts
