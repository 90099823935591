import { ItemDeliverabilityProblem } from './item-deliverability-problem.enum';
import { ItemId } from './item.model';

export interface ItemDeliverability {
  itemId: ItemId;
  problems: ItemDeliverabilityProblem[];
}

export function createItemDeliverabilityFromJson(json: Partial<ItemDeliverability> = {}): ItemDeliverability {
  const itemDeliverability: Partial<ItemDeliverability> = {};

  itemDeliverability.itemId = json.itemId;
  itemDeliverability.problems = json.problems || [];

  return itemDeliverability as ItemDeliverability;
}

export function getItemDeliverabilityProblems(
  itemId: ItemId,
  itemDeliverabilities: ItemDeliverability[]
): ItemDeliverabilityProblem[] {
  for (const deliverability of itemDeliverabilities) {
    if (deliverability.itemId === itemId) {
      return deliverability.problems;
    }
  }

  // good, no deliverability problems for the given item
  return [];
}
