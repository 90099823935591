import { Component } from '@angular/core';

@Component({
  selector: 'app-search-result-placeholder',
  template: `
    <div class="search-card search-card--skeleton">
      <div class="search-card__image"></div>
      <div class="search-card__header">
        <div class="search-card__icon">
          <div class="vendor-logo vendor-logo--small"></div>
        </div>
        <div class="search-card__header-content">
          <div class="search-card__title"></div>
          <div class="search-card__subtitle"></div>
        </div>
      </div>
    </div>
  `
})
export class SearchResultPlaceholderComponent {}
