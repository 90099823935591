import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { FEATURE_STATE_NAME, VendorNotificationsState } from './vendor-notifications.state';

const getVendorNotificationsState = createFeatureSelector<VendorNotificationsState>(FEATURE_STATE_NAME);

const getPendingOrdersCount = (state: VendorNotificationsState) => state.pendingOrdersCount;
const getPendingOrdersCountLoadedAt = (state: VendorNotificationsState) => state.pendingOrdersCountLoadedAt;
const isLoadingPendingOrdersCount = (state: VendorNotificationsState) => state.loadingPendingOrdersCount;

export const VendorNotificationsSelectors = mapSelectors(getVendorNotificationsState, {
  getPendingOrdersCount,
  getPendingOrdersCountLoadedAt,
  isLoadingPendingOrdersCount,
});
