<app-logo-popup
  [show]="true"
  [noWidth]="false"
  (close)="onClose()"
>
  <div
    class="dialog__title"
    test-id="header"
  >
    Delivery Details updated
  </div>

  <div class="dialog__content">
    <div
      class="m-large"
      test-id="dialogContent"
    >
      Success! Your delivery details have been updated, you{{ isIndividualChoice ? ' and your team' : '' }} will receive an email confirming your changes
    </div>
  </div>

  <div class="dialog__footer justify-content-center">
    <button
      (click)="onClose()"
      type="button"
      class="button px-x-large"
      test-id="okButton"
      analyticsId="ok-button"
    >Ok</button>
  </div>
</app-logo-popup>
