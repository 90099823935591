import moment, { Moment } from 'moment';
import { createSimpleCustomerLocationFromJson, SimpleCustomerLocation } from '../customer';
import { MajorCurrency } from '../money';

export interface IndividualChoiceOrderRequirements {
  menuId: string;
  contentId: string;
  customerLocation: SimpleCustomerLocation | null;
  deliveryDate: Moment;
  budget: MajorCurrency;
}

export function createIndividualChoiceOrderRequirementsFromJson(
  json: Partial<IndividualChoiceOrderRequirements> = {}
): IndividualChoiceOrderRequirements {
  const partial: Partial<IndividualChoiceOrderRequirements> = {};

  partial.menuId = json.menuId;
  partial.contentId = json.contentId;
  partial.customerLocation = json.customerLocation ? createSimpleCustomerLocationFromJson(json.customerLocation) : null;
  if (!json.deliveryDate) {
    throw new Error(`deliveryDate must be defined`);
  }
  partial.deliveryDate = moment(json.deliveryDate);
  partial.budget = json.budget;

  return partial as IndividualChoiceOrderRequirements;
}
