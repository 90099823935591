import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServingStyle } from '@citypantry/util-models';

@Component({
  selector: 'app-serving-style',
  template: `
<span class="vertically-center label-with-icon">
  <i
    class="label-with-icon__icon icon"
    aria-hidden="true"
  >legacy_cloche</i>
  <span class="label">
    {{ servingStyle | servingStyle }}
  </span>
</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServingStyleComponent {
  @Input()
  public servingStyle: ServingStyle;
}
