import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItems, ItemsResponse, normalisePostcode } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  CategorisedSearchRequest,
  CategorySearchResultsPage,
  createSearchResultFromJson,
  Page,
  SearchRequest,
  SearchResult,
  transformPage
} from '@citypantry/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchApi {
  constructor(
    private http: HttpClient
  ) {}

  public search(request: SearchRequest, pageNumber?: number): Observable<Page<SearchResult>> {
    const params: any = {
      ...request,
      date: request.date.format('YYYY-MM-DDTHH:mm'),
      postcode: normalisePostcode(request.postcode), // API falls over if the postcode isn't valid
      pageSize: 12
    };
    if (pageNumber) {
      params.page = pageNumber;
    }
    if (request.dietaries && request.dietaries.length) {
      params.dietaries = request.dietaries.join(',');
    }
    if (request.events && request.events.length) {
      params.events = request.events.join(',');
    }
    if (request.cuisines && request.cuisines.length) {
      params.cuisines = request.cuisines.join(',');
    }
    if (request.options && request.options.length) {
      params.options = request.options.join(',');
    }
    if (request.text) {
      params.q = request.text;
      delete params.text;
    }

    return this.http.get(
      '/search', { params }).pipe(
      map(transformPage(createSearchResultFromJson))
    );
  }

  public searchByCategory(request: CategorisedSearchRequest): Observable<CategorySearchResultsPage[]> {
    const params = {
      date: request.date.format('YYYY-MM-DDTHH:mm'),
      postcode: request.postcode,
      pages: request.pages,
    };

    return this.http.post<ItemsResponse<CategorySearchResultsPage>>('/categorised-search', params).pipe(getItems());
  }

  public getOperationalRegion(postcode: string): Observable<string> {
    const params = { postcode };
    return this.http.get<{ operationalRegion: string }>('/operational-region',
      {
        params,
        headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}
      }).pipe(map((response) => response.operationalRegion));
  }
}
