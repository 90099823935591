import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

export const searchModalRules: AnalyticsRule[] = [{
  url: '*',
  path: 'search-params-modal bottom-of-component',
  events: InViewportComponent.events.firstSeen,
  definition: (event) => ({
    action: AnalyticsActions.VIEW,
    category: AnalyticsCategories.SEARCH_PARAMETERS,
    label: `${event.data['bottom-of-component'].analyticsPrefix} promo search param footer viewed`,
  })
},
{
  url: '*',
  path: 'search-params-modal marketing-campaign-link',
  events: 'click',
  definition: (event) => ({
    action: AnalyticsActions.CLICK,
    category: AnalyticsCategories.SEARCH_PARAMETERS,
    label: `${event.data['marketing-campaign-link'].analyticsPrefix} Promo search param footer CTA clicked`,
  })
}];
