import { NgModule } from '@angular/core';
import { DateDurationPipe } from './pipes/date-duration.pipe';
import { DateInEuropeLondonPipe } from './pipes/date-in-europe-london.pipe';
import { DateOrOneDayWindowNamePipe } from './pipes/date-or-one-day-window-name';
import { DayOfWeekOrTodayPipe } from './pipes/day-of-week-or-today.pipe';
import { DeliveryWindowPipe } from './pipes/delivery-window.pipe';
import { HumanFriendlyDateRangePipe } from './pipes/human-friendly-date-range.pipe';
import { TimeOnRelativeDatePipe } from './pipes/time-on-relative-date.pipe';
import { TodayOrTomorrowPipe } from './pipes/today-or-tomorrow.pipe';

@NgModule({
  providers: [
    DateDurationPipe,
    DateInEuropeLondonPipe,
    DateOrOneDayWindowNamePipe,
    DayOfWeekOrTodayPipe,
    HumanFriendlyDateRangePipe,
    TimeOnRelativeDatePipe,
    TodayOrTomorrowPipe,
    DeliveryWindowPipe,
  ],
  declarations: [
    DateDurationPipe,
    DateInEuropeLondonPipe,
    DateOrOneDayWindowNamePipe,
    DayOfWeekOrTodayPipe,
    HumanFriendlyDateRangePipe,
    TimeOnRelativeDatePipe,
    TodayOrTomorrowPipe,
    DeliveryWindowPipe,
  ],
  exports: [
    DateDurationPipe,
    DateInEuropeLondonPipe,
    DateOrOneDayWindowNamePipe,
    DayOfWeekOrTodayPipe,
    HumanFriendlyDateRangePipe,
    TimeOnRelativeDatePipe,
    TodayOrTomorrowPipe,
    DeliveryWindowPipe,
  ],
})
export class DatetimeComponentsModule {}
