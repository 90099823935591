<div
  class="bundle-section-header"
  [class.bundle-section-header--has-errors]="formGroup.invalid"
>
  <div
    class="bundle-section-header__heading"
    test-id="heading"
  >
    {{ itemGroup.heading }}
  </div>
  <div class="bundle-section-header__prompt">
    <app-item-group-prompt
      [itemGroup]="itemGroup"
      test-id="prompt"
    ></app-item-group-prompt>
  </div>

  <div
    *ngIf="formGroup.invalid"
    class="bundle-section-header__errors"
  >
    <div
      class="section-alert"
      [errorSwitch]="formGroup.errors"
      test-id="error"
    >
      <span
        class="section-alert__message"
        test-id="errorQuantity"
        *errorCase="'groupTotalIncorrect'; let error"
      >
        You've chosen {{ error.groupTotal < error.itemBundleQuantity ? 'only ' : '' }}{{ error.groupTotal }}
        {{ 'option' | plural:error.groupTotal }} for {{ error.itemBundleQuantity }} {{ 'people' | plural:error.itemBundleQuantity }}
      </span>
      <span
        *errorCase="'default'"
        class="section-alert__message"
        test-id="errorDefault"
      >
        Please enter a valid choice.
      </span>
    </div>
  </div>
  <hr class="bundle-section-header__rule" />
</div>

<div class="[ pt-standard px-none ] [ pt-md-x-large px-md-standard ]">
  <ng-container
    *ngFor="let item of itemGroup.items"
  >
    <app-flexible-group-item
      [item]="item"
      [showMoreInfo]="moreInfoItem === item"
      [control]="formGroup.get(item.id)"
      (toggleMoreInfo)="toggleMoreInfo(item)"
      test-id="items"
    ></app-flexible-group-item>
    <div
      *ngIf="moreInfoItem === item"
      class="row mb-standard"
    >
      <app-additional-item-info
        [showDescription]="true"
        [showPerServing]="true"
        [item]="item"
        (close)="toggleMoreInfo()"
        test-id="moreInfo"
      ></app-additional-item-info>
    </div>
  </ng-container>
</div>
