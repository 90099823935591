import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const mealIssuesModalRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'eater-feedback-form-modal dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Close modal'
    }
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal',
    events: {
      class: 'DialogComponent',
      eventName: 'abort'
    },
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Close modal'
    }
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal reason',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: `Category of order issue - ${event.data['reason'].reason}`
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal item',
    events: {
      class: 'MyMealsFeedbackCheckboxRowComponent',
      eventName: 'toggle'
    },
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: `Missing item ${event.event ? 'checked' : 'unchecked'}`,
      extraFields: {
        orderId: event.data['item'].orderId,
        vendorId: event.data['item'].vendorId,
        itemId: event.data['item'].itemId
      }
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal vendor',
    events: {
      class: 'MyMealsFeedbackCheckboxRowComponent',
      eventName: 'toggle'
    },
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: `${event.data['vendor'].reason} - Vendor ${event.event ? 'checked' : 'unchecked'}`,
      extraFields: {
        orderId: event.data['vendor'].orderId,
        vendorId: event.data['vendor'].vendorId
      }
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal food-issue',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Issue with food filled in',
      extraFields: {
        orderHumanIds: event.data['food-issue'].orderHumanIds,
      }
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal bad-packaging',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Bad packaging filled in',
      extraFields: {
        orderHumanIds: event.data['bad-packaging'].orderHumanIds,
      }
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal other-reason',
    events: 'blur',
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Other reason filled in',
      extraFields: {
        orderHumanIds: event.data['other-reason'].orderHumanIds,
      }
    })
  },
  {
    url: '*',
    path: 'eater-feedback-form-modal submit',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.MEAL_ISSUES_MODAL,
      label: 'Submit request button'
    }
  },
];
