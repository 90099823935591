import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-order-after-edit-popup',
  templateUrl: './cancel-order-after-edit-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelOrderAfterEditPopupComponent {

  @Input()
  public isMultiVendor?: boolean;

  @Output()
  public keepOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  public cancelOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  public openChat: EventEmitter<void> = new EventEmitter();

  public cancellationInProgress: boolean = false;

  public keepOrderClicked(): void {
    this.keepOrder.emit();
  }

  public cancelOrderClicked(): void {
    this.cancellationInProgress = true;
    this.cancelOrder.emit();
  }
}
