import { DateRange, IsoDate } from '@citypantry/util';

export interface RangeWithCustomCssClass {
  range: DateRange;
  customCssClass: string;
}

export namespace RangeWithCustomCssClass {
  export function ofSingleDay(day: IsoDate, customCssClass: string): RangeWithCustomCssClass {
    return {
      range: DateRange.ofSingleDay(day),
      customCssClass,
    };
  }
}
