import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const menuBuilderPageRules: AnalyticsRule[] = [
  {
    url: '/menus/builder/menus/new',
    path: 'menu-builder save-menu-and-return',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.MENU_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Save and return clicked',
      extraFields: {
        isMenuComplete: event.data['menu-builder'].isMenuComplete,
        menuContentId: event.data['menu-builder'].menuContentId,
      },
    })
  },
  {
    url: '/menus/builder/menus/:id',
    path: 'menu-builder save-menu-and-return',
    events: 'click',
    definition: (event) => ({
      category: AnalyticsCategories.MENU_EDITOR,
      action: AnalyticsActions.CLICK,
      label: 'Save and return clicked',
      extraFields: {
        isMenuComplete: event.data['menu-builder'].isMenuComplete,
        menuContentId: event.data['menu-builder'].menuContentId,
      },
    })
  },
];
