import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { sum } from '@citypantry/util';
import { SearchSortType } from '@citypantry/util-models';

import { EMPTY_SEARCH_FILTERS, SearchFilters } from '../search-filters.model';

@Component({
  selector: 'app-search-filters-bar',
  templateUrl: './search-filters-bar.component.html',
  styleUrls: ['./search-filters-bar-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFiltersBarComponent {

  @Input()
  public set selectedFilters(filters: SearchFilters) {
    this._selectedFilters = filters;
    this.selectedFiltersCount = this.countSelectedFilters();
  }

  public get selectedFilters(): SearchFilters {
    return this._selectedFilters;
  }

  @Input()
  public sortOrder: SearchSortType;

  @Input()
  public showDistanceSortOption: boolean;

  @Input()
  public individualChoiceToggleDisabled: boolean;

  @Output()
  public selectedFiltersChange: EventEmitter<SearchFilters> = new EventEmitter();

  @Output()
  public sortOrderChange: EventEmitter<SearchSortType> = new EventEmitter();

  @Output()
  public individualChoiceEnabledChange: EventEmitter<boolean> = new EventEmitter();

  public isFiltersListExpanded: boolean;
  public selectedFiltersCount: number;

  private _selectedFilters: SearchFilters;

  constructor() {
    this.selectedFilters = { ...EMPTY_SEARCH_FILTERS };
  }

  public onSelectedFiltersChange(filters: SearchFilters): void {
    this.selectedFiltersChange.emit(filters);
  }

  public onSortOrderChange(sortOrder: SearchSortType): void {
    this.sortOrderChange.emit(sortOrder);
  }

  public onIndividualChoiceDisabled(): void {
    this.individualChoiceEnabledChange.emit(false);
  }

  private countSelectedFilters(): number {
    // all selectedFilters properties are arrays
    return Object.values(this.selectedFilters).reduce(sum((filters) => filters.length), 0);
  }
}
