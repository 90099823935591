import { Component, ContentChild, Input } from '@angular/core';
import { PromotionPanelComponent } from '@citypantry/shared-promotion';
import { MenuContent, SingleItem } from '@citypantry/util-models';

export interface SetSingleItemQuantityEvent {
  item: SingleItem;
  cartIndex: number | null;
  quantity: number;
}

export interface SetItemQuantityFromCartEvent {
  cartIndex: number;
  quantity: number;
}

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
})
export class MenuComponent {
  @Input()
  public menuContent: MenuContent;

  @ContentChild(PromotionPanelComponent, { static: false })
  public promotionalPanel: PromotionPanelComponent;
}
