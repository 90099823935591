import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, mapTo, take } from 'rxjs/operators';
import { AuthSelectors } from '../state/auth.select';

/**
 * IsAuthDataLoadedGuard ensures that, if a route is guarded with both canActivate and canActivateChild,
 * the navigation event cannot proceed until user profile loading attempt has finished
 * (either with API returning user profile data, or a 403 response).
 *
 * This way, we can safely use AuthSelectors (combined with e.g. take(1))
 * to "synchronously" validate the current user's permissions
 * at a specific moment in time (i.e. upon a navigation/action attempt).
 *
 * In other words, users of AuthSelectors in all nested routes guarded by IsAuthDataLoadedGuard
 * can assume that user profile is already loaded from API.
 */
@Injectable({
  providedIn: 'root',
})
export class IsAuthDataLoadedGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<AppState>,
  ) {}

  public canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(AuthSelectors.isLoaded),
      this.store.select(AuthSelectors.isFresh),
    ]).pipe(
      // Wait until auth data was either loaded or returned 403, and only then allow child nested guards
      // to proceed with any further (potentially auth-based) checks.
      filter(([isLoaded, isFresh]) => {
        return isLoaded && isFresh;
      }),
      // take(1) prevents multiple emitted values when multiple selectors emit at the same time
      // (which is not a problem for the real Angular router, but can interfere with testing).
      take(1),
      mapTo(true),
    );
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
