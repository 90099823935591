import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { DomAnalyticsEvent } from '../analytics-event.interface';
import { AnalyticsRule } from './analytics-rule.interface';

export const myOrdersPageRules: AnalyticsRule[] = [
  {
    url: '/menus/my-orders',
    path: 'order-card thumbs up',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Order feedback - Thumbs Up',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'order-card thumbs down',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Order feedback - Thumbs Down',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'order-group-feedback-dialog thumbs up',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Order feedback - Thumbs Up',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'order-group-feedback-dialog thumbs down',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Order feedback - Thumbs Down',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'hide-cancelled-orders-toggle',
    events: 'click',
    definition: (event: DomAnalyticsEvent) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: `Hide Cancelled Orders - Toggle ${ (event.event.target as HTMLInputElement).checked ? 'on' : 'off' }`,
    }),
  },
  {
    url: '/menus/my-orders',
    path: 'hide-orders-purchased-by-other-users-toggle',
    events: 'click',
    definition: (event: DomAnalyticsEvent) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: `Hide Orders Purchased By Other Users - Toggle ${ (event.event.target as HTMLInputElement).checked ? 'on' : 'off' }`,
    }),
  },
  {
    url: '/menus/my-orders',
    path: 'order-card order-again',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Order Again',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'cancel-scheduled-order-dialog cancel-subsequent',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL,
      label: `${event.data['cancel-subsequent'].checked ? 'This and following dates' : 'This order only'} toggle`

    })
  },
  {
    url: '/menus/my-orders',
    path: 'cancel-scheduled-order-dialog dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL,
      label: 'Cancel'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'cancel-scheduled-order-dialog dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL,
      label: 'Close'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'cancel-scheduled-order-dialog dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_CANCEL_SCHEDULE_MODAL,
      label: 'Confirm'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'repeat-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW,
      label: 'Repeat Order button',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'schedule-order-dialog interval-select',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      label: 'Repeat interval selected',
    },
  },
  {
    url: '/menus/my-orders',
    path: 'schedule-order-dialog end-date-datepicker',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      label: 'End date picked'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'schedule-order-dialog dialog-cancel',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      label: 'Cancel'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'schedule-order-dialog dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      label: 'Close'
    }
  },
  {
    url: '/menus/my-orders',
    path: 'schedule-order-dialog dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.CALENDAR_VIEW_REPEAT_ORDER_MODAL,
      label: 'Confirm'
    }
  },
];
