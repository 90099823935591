import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';
import { SearchOrderType, SearchOrderTypes, SearchRequest } from '@citypantry/util-models';
import { HeaderType } from '@citypantry/shared-auth';

export interface RouterLinkTemplateContext {
  routerLink: RouterLink;
  label: string;
  isSecondary: boolean;
  analyticsId: string | null;
  e2eTestId: string | null;
  testId: string;
}

export interface ExternalLinkTemplateContext {
  externalHref: string;
  label: string;
  isSecondary: boolean;
  analyticsId: string | null;
  e2eTestId: string | null;
  testId: string;
}

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  public headerType: HeaderType;

  @Input()
  public isNavOpen: boolean;

  @Input()
  public isEater: boolean;

  @Input()
  public isOrderer: boolean;

  @Input()
  public isEligibleForIndividualChoice: boolean;

  @Input()
  public isAdvancedBudgetingEnabled: boolean;

  @Input()
  public isLoggedIn: boolean;

  @Input()
  public sudoMode: boolean;

  @Input()
  public showMealPlanLink: boolean;

  @Input()
  public isColleagueGroupsEnabled: boolean;

  @Input()
  public isFixed: boolean;

  @Input()
  public isSmall: boolean;

  @Input()
  public pendingOrdersCount: number;

  @Input()
  public searchRequest: SearchRequest;

  @Input()
  public searchType: SearchOrderType;

  @Input()
  public marketingCampaignComponent: MarketingCampaignComponentModel | null;

  @Input()
  public isSsoUser: boolean;

  @Input()
  public identityBase: string;

  @Input()
  public canAccessOnDemand: boolean;

  @Output()
  public logoClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public toggleNav: EventEmitter<void> = new EventEmitter();

  // we store ViewChildren as references here only so that we can test them
  @ViewChild('routerLinkTemplate')
  public routerLinkTemplate: TemplateRef<RouterLinkTemplateContext>;

  @ViewChild('externalLinkTemplate')
  public externalLinkTemplate: TemplateRef<ExternalLinkTemplateContext>;

  @ViewChild('separatorTemplate')
  public separatorTemplate: TemplateRef<void>;

  @ViewChild('authenticatedUserLinksTemplate')
  public authenticatedUserLinksTemplate: TemplateRef<void>;

  @ViewChild('searchFoodButtonTemplate')
  public searchFoodButtonTemplate: TemplateRef<void>;

  public HeaderTypeEnum: typeof HeaderType = HeaderType;

  public getQueryParams(): SearchRequest | {} {
    if (this.searchType === SearchOrderTypes.INDIVIDUAL_CHOICE) {
      return this.searchRequest;
    }
    return {};
  }

  public onLogoClicked(): void {
    this.logoClick.emit();
  }

  public navToggleClicked(): void {
    this.toggleNav.emit();
  }
}
