import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';
import { ExternalLinkTemplateContext, RouterLinkTemplateContext } from '../../header.component';

@Component({
  selector: 'app-purchaser-user-header',
  templateUrl: './purchaser-user-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaserUserHeaderComponent {
  @Input()
  public showMealPlanLink: boolean;

  @Input()
  public isEater: boolean;

  @Input()
  public isOrderer: boolean;

  @Input()
  public isEligibleForIndividualChoice: boolean;

  @Input()
  public sudoMode: boolean;

  @Input()
  public isColleagueGroupsEnabled: boolean;

  @Input()
  public isAdvancedBudgetingEnabled: boolean;

  @Input()
  public marketingCampaignComponent: MarketingCampaignComponentModel | null;

  @Input()
  public separatorTemplate: TemplateRef<void>;

  @Input()
  public externalLinkTemplate: TemplateRef<ExternalLinkTemplateContext>;

  @Input()
  public routerLinkTemplate: TemplateRef<RouterLinkTemplateContext>;

  @Input()
  public authenticatedUserLinksTemplate: TemplateRef<void>;

  @Input()
  public searchFoodButtonTemplate: TemplateRef<void>;
}
