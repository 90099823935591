<div>
  <span
    *ngIf="quantity"
    test-id="quantity"
  >
    <strong>{{ calculateQuantity() }}</strong> &times;
  </span>
  <span test-id="name">{{ item.name }}</span>
  <a
    class="link link--light link--small text-nowrap ml-1"
    (click)="onToggleShowMoreInfo()"
    test-id="toggleInfoLink"
  >{{ showMoreInfo ? 'Less info' : 'More info' }}</a>
</div>
<div
  *ngIf="shouldShowKcals()"
  class="mt-small text-color-stone-2"
  test-id="kcal"
>{{ item.kcal }}kcal per portion</div>
<div
  *ngIf="item.ageRestricted || hasDietaries()"
  class="d-flex mt-small"
>
  <span
    *ngIf="item.ageRestricted"
    class="icon icon--medium"
    aria-label="Age restricted"
    test-id="ageRestricted"
  >age_restricted</span>
  <app-dietaries
    *ngIf="hasDietaries()"
    class="vertical-separator"
    [dietaries]="item.dietaries"
    test-id="dietaries"
  ></app-dietaries>
</div>
