import { Pipe, PipeTransform } from '@angular/core';
import { Allergen, Allergens, ALL_ALLERGENS } from '@citypantry/util-models';
import { AllergenNamePipe } from './allergen-name.pipe';

@Pipe({
  name: 'allergenNameList',
})
export class AllergenNameListPipe implements PipeTransform {

  constructor(private allergenNamePipe: AllergenNamePipe) {
  }

  public transform(value: (Allergens | Allergen[]), separator: string = ', '): string {
    const allergensPresent: Allergen[] = Array.isArray(value)
      ? value
      : ALL_ALLERGENS.filter((key: Allergen) => value[key]);

    const allergenDescriptions = allergensPresent
      .map((key: Allergen) => this.allergenNamePipe.transform(key, true));

    return allergenDescriptions.join(separator);
  }
}
