import { AppState } from '@citypantry/state';
import { Action } from '@ngrx/store';

declare const window: Window & {
  // For types see https://github.com/JustEatForBusiness/citypantry-error-plugin/blob/main/chrome/shared/debug-event.interface.ts
  __cp_bug_events__: any[]; /* eslint-disable-line @typescript-eslint/naming-convention */ // This is used by the Chrome error plugin
};

export function logStateToErrorPlugin(state: AppState, index: number): AppState {
  const events = window.__cp_bug_events__ || (window.__cp_bug_events__ = []);
  if (events.length < 100) {
    events.push({ type: 'state', index, state });
  }
  return state;
}

/**
 * State is a monitor state here, not the app state.
 * Action is an Action of type { payload: { action: Action } }
 * with the payload action being one that was dispatched inside our application.
 */
export function logActionsToErrorPlugin(state: any, action: Action): any {
  const events = window.__cp_bug_events__ || (window.__cp_bug_events__ = []);
  if (events.length < 100) {
    events.push({ type: 'action', action });
  }
  return state;
}
