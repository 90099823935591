import moment from 'moment';
import { Cart, createCartFromJson } from '../../cart';

export interface ProposedOrder {
  id: string;
  cart: Cart | null;
  requestedDeliveryDate: moment.Moment;
}

export function createProposedOrderFromJson(json?: Partial<ProposedOrder>): ProposedOrder {
  const proposedOrder: Partial<ProposedOrder> = {};

  proposedOrder.id = json.id;

  if (json.cart) {
    proposedOrder.cart = createCartFromJson(json.cart);
  } else {
    proposedOrder.cart = null;
  }
  proposedOrder.requestedDeliveryDate = json.requestedDeliveryDate ? moment(json.requestedDeliveryDate) : null;

  return proposedOrder as ProposedOrder;
}
