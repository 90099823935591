import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OperationalRegionsActions } from './operational-regions.actions';
import { OperationalRegionsSelectors } from './operational-regions.select';

@Injectable({
  providedIn: 'root',
})
export class OperationalRegionsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.store.select(OperationalRegionsSelectors.getAll).pipe(
      take(1),
      map((storeRegions: string[]) => {
        if (!storeRegions.length) {
          this.store.dispatch(OperationalRegionsActions.loadOperationalRegions());
        }
        return true;
      }),
    );
  }
}
