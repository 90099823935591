import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { Observable } from 'rxjs';

/**
 * Intercepts any request without a protocol and prepends the API URL.
 */
@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {

  constructor(
    private config: AppConfig
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.match(/:\/\//)) {
      return next.handle(request);
    }

    const url = `${this.config.API_BASE}${request.url.indexOf('/') === 0 ? '' : '/'}${request.url}`;

    return next.handle(request.clone({
      url
    }));
  }
}
