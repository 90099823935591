import { Component, Input } from '@angular/core';
import { Breadcrumb } from './breadcrumb.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent {
  @Input()
  public breadcrumbs: Breadcrumb[];

  constructor() {
    this.breadcrumbs = [];
  }
}
