import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Redirects logged in customers, vendors, or eaters to their "logged in" homepage. Logged-out users are redirected to the login page.
 */
@Injectable({
  providedIn: 'root'
})
export class HomeRedirectGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<UrlTree> {
    return combineLatest([
      this.store.select(AuthSelectors.isLoggedInUser),
      this.store.select(AuthSelectors.isCustomer),
      this.store.select(AuthSelectors.isVendor),
      this.store.select(AuthSelectors.isEater),
      this.store.select(AuthSelectors.isStaff),
      this.store.select(AuthSelectors.navigationPermissions.canAccessJefb),
      this.store.select(AuthSelectors.navigationPermissions.canAccessOnDemand),
    ]).pipe(
      map(([
        isLoggedInUser,
        isCustomer,
        isVendor,
        isEater,
        isStaff,
        canAccessJefb,
        canAccessOnDemand,
      ]) => {
        let url;

        if (!isLoggedInUser) {
          url = '/login';
        } else if (canAccessJefb) {
          if (isVendor) {
            url = '/menus/vendor';
          } else if (isCustomer) {
            url = '/menus/customer/home';
          } else if (isEater || isStaff) {
            url = '/my-meals';
          } else {
            // This should only happen if the user has not been added to a location yet.
            url = '/404';
          }
        } else if (canAccessOnDemand) {
          url = '/menus/individual-ordering';
        } else {
          // If the user is logged in, but does not have any supported type,
          // we cannot redirect them to /login, because that would cause a redirect loop.
          // (IsNotLoggedInGuard, which guards /login page, redirects from /login back to /)
          url = '/404';
        }

        return this.router.parseUrl(url);
      }),
    );
  }
}
