import { AdvancedBudgets } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const CustomerAdvancedBudgetingActions = {
  submitAdvancedBudgets: createAction(
    '[Advanced Budgeting Page] Submit advanced budgets',
    props<{ advancedBudgets: AdvancedBudgets }>(),
  ),

  advancedBudgetsSavingSuccess: createAction(
    '[Advanced Budgeting API] Advanced budgets saving success',
  ),

  advancedBudgetsSavingError: createAction(
    '[Advanced Budgeting API] Advanced budgets saving error',
  ),
};

const all = union(CustomerAdvancedBudgetingActions);
export type CustomerAdvancedBudgetingAction = typeof all;
