
<div
  class="recommended-vendors-layout panel"
  analyticsId="recommended-vendors"
  test-id="container"
>
  <div class="container">
    <div class="row">
      <div class="col col-12">
        <div class="recommended-vendors-layout__header">
          <h3 test-id="title">{{ title }}</h3>
        </div>
      </div>
    </div>

    <app-in-viewport
      *ngIf="trackWhenInViewport"
      test-id="appInViewPort"
      analyticsId="vendor-list"
    ></app-in-viewport>
    <div class="row">
      <div
        *ngFor="let recommendedVendor of recommendedVendors.slice(0, 3)"
        class="col col-sm-12 col-md-4"
      >
        <app-search-result
          [useDisplayMode]="true"
          [result]="recommendedVendor"
          [url]="['/menus/vendors', 'VENDOR_SLUG', 'VENDOR_LOCATION_SLUG']"
          analyticsId="recommendation"
          test-id="results"
        ></app-search-result>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <div class="recommended-vendors-layout__footer">
          <a
            class="button"
            [ngClass]="{ 'button--ghost': !showPrimaryButtonStyle }"
            [routerLink]="['/menus/search']"
            analyticsId="see-more-vendors"
            test-id="seeMoreVendors"
          >
            See More Restaurants
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
