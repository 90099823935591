import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const editIndividualChoiceSearchRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'search-query',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Search query entered'
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'sort-by-select',
    events: 'change',
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `Sort by ${event.data['sort-by-select']}`,
    }),
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'cuisine-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['cuisine-filter']} cuisine type checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'occasion-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['occasion-filter']} occasion checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'dietary-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['dietary-filter']} dietary requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'vendor-flag-filter',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `${event.data['vendor-flag-filter']} vendor requirement checked/unchecked`,
    }),
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'search-result link',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData[] => {
      const { index, maxIndex } = event.path.find(({ id }) => id === 'search-result');
      const { totalResultCount } = event.data['search-result-list'];
      const { vendorName, vendorId, isTopPick } = event.data['search-result'];

      const events: DispatchAnalyticsEventData[] = [{
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `Vendor`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
        },
      }];

      // TODO CPD-11178 this event might be redundant, determine whether it can be removed
      if (event.data['search-result']['isTopPick']) {
        events.push({
          action: AnalyticsActions.CLICK,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: 'Top Pick Result'
        });
      }

      return events;
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'search-result search-card-in-viewport',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      const {index, maxIndex} = event.path.find(({id}) => id === 'search-result');
      const {totalResultCount} = event.data['search-result-list'];
      const {vendorName, vendorId, isTopPick, vendorRating, vendorReviewCount, menuId} = event.data['search-result'];

      return ({
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        // TODO CPD-11178 this event might have a too specific label, see if it can be simplified to "Vendor shown"
        label: `Vendor seen: ${event.data['search-result'].vendorName} (${event.data['search-result'].vendorId})`,
        extraFields: {
          vendorName, vendorId, isTopPick,
          index, maxIndex, totalResultCount,
          vendorRating, vendorReviewCount, menuId,
        },
      });
    },
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'cant-find-what-you-want suggest-a-restaurant',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Suggest a restaurant'
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'cant-find-what-you-want chat-to-us',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: 'Can\'t find what you want - Chat to us'
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'cant-find-what-you-want top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `can't find what your looking for in viewport`
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'search-no-results',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => {
      if (event.data['search-no-results'].analyticsPrefix) {
        return {
          action: AnalyticsActions.VIEW,
          category: AnalyticsCategories.SEARCH_RESULT,
          label: `${event.data['search-no-results'].analyticsPrefix} No search results`
        };
      }

      return {
        action: AnalyticsActions.VIEW,
        category: AnalyticsCategories.SEARCH_RESULT,
        label: `No search results`,
      };
    }
  },
  {
    url: '/menus/individual-choice/edit/:orderGroupId/search',
    path: 'search-no-results-cta',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.SEARCH_RESULT,
      label: `${event.data['search-no-results-cta'].analyticsPrefix} No search results cta clicked`
    })
  },
];
