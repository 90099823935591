import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const individualChoiceEditLocationRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'location-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Location Name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'company-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Company Name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'building-name-or-floor-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Building Name / Floor Number field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'street-address',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Street address field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'address-line-3',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Address Line 3 field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'city',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `City field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'postcode',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Postcode field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'country',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Country field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'cancel-changes',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `CANCEL CHANGES button clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'save-and-continue',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `SAVE AND CONTINUE button clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Delivery location error modal closed`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/edit-location/:locationId',
    path: 'dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_EDIT_LOCATION,
      label: `Delivery location error modal start new order clicked`,
    },
  },
];
