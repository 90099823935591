import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { getBaseUrl, getSimpleUrl } from '@citypantry/util';

@Injectable()
export class CanonicalTagService {

  private canonicalUrlTag: HTMLLinkElement | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  public setCanonicalPath(path: string | null): void {
    const canonicalPath = path || getSimpleUrl(this.document.location.pathname);
    const absoluteUrl = this.document.location.href;
    const canonicalUrl = getBaseUrl(absoluteUrl) + getSimpleUrl(canonicalPath);

    this.getOrCreateTag().href = canonicalUrl;
  }

  private getOrCreateTag(): HTMLLinkElement {
    if (!this.canonicalUrlTag) {
      this.canonicalUrlTag = this.document.createElement('link');
      this.canonicalUrlTag.rel = 'canonical';
      this.document.head.appendChild(this.canonicalUrlTag);
    }
    return this.canonicalUrlTag;
  }
}
