import { Injectable } from '@angular/core';
import { Dispatch } from '@citypantry/state';
import { Action } from '@ngrx/store';
import { HistoryActions } from './history.actions';

@Injectable({ providedIn: 'root' })
export class HistoryService {

  @Dispatch()
  public onNavigationComplete(url: string): Action {
    return HistoryActions.setReferer({ referer: url });
  }
}
