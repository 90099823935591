import { CartManager } from '@citypantry/util-cart-manager';
import {
  CartItem,
  EaterCartSubmitError,
  EaterFeedback,
  EaterLocation,
  EaterMeal,
  EaterMealOption,
  findMenuItemById,
  IndividualChoiceOrderSummary,
  isCustomCartItem,
  isSingleCartItem,
  ItemId,
  JustEatVoucher,
  Loadable,
  LoadableList,
  MajorCurrency,
  MenuContent,
  OrderId,
  Page,
  PaymentCard,
  SimpleCustomerLocation,
} from '@citypantry/util-models';
import { Action } from '@ngrx/store';
import moment from 'moment';
import { INITIAL_PRICE } from '../public/public.state';
import {
  ActionTypes,
  AddItemToCartAction,
  AddOrUpdateItemInCartAction,
  EaterCartItemEditClickAction,
  EaterCartLoadedAction,
  EaterCartSubmitFailedAction,
  EaterFeedbackCreatedAction,
  EaterFeedbackLoadedAction,
  EaterMarkedAsAwayAction,
  EaterMealDeletedAction,
  EaterMealsLoadedAction,
  EaterMenuItemAddClickAction,
  EaterMenuPageView,
  EaterPreviousMealsLoadedAction,
  JustEatVoucherRetrievedAction,
  LoadEaterPreviousMealsAction,
  MyMealsPageViewAction,
  OrderSummaryLoadedAction,
  PaymentCardLoadedAction,
  PreviousDepartmentReferencesLoadedAction,
  RemoveItemFromCartAction,
  SetEaterLocationsAction,
  ShouldRememberCardChangedAction,
  ShowEaterFeedbackFormAction,
  ShowEaterLocationAddModalAction,
  ShowEaterOrderRatingFormAction,
  EaterLocationShareLinkViewedAction,
  UpdateDepartmentReferenceAction,
  UpdateDeskNumberAction,
  UpdateItemQuantityInCartAction,
} from './eater.actions';

export interface EaterOrder {
  orderId: OrderId;
  summary: IndividualChoiceOrderSummary;
}

export interface EaterLocationAddModal {
  isSubmitting: boolean;
  eaterLocation: EaterLocation;
}

export interface OrderConfirmation {
  date: moment.Moment;
}

export interface EaterState {
  eaterMeals: LoadableList<EaterMeal>;
  eaterPreviousMeals: Loadable<Page<EaterMeal> | null>;
  shareLinkLocationToBeAdded: EaterLocation | null;
  eaterCurrentLocationAddModal: EaterLocationAddModal | null;
  eaterLocations: SimpleCustomerLocation[] | null;
  eaterPreviousDepartmentReferences: LoadableList<string>;

  order: EaterOrder | null;
  cartItems: CartItem[];
  cartTotal: MajorCurrency;
  departmentReference: string;
  isSubmittingCart: boolean;
  cartSubmitError: EaterCartSubmitError | null;
  orderConfirmation: OrderConfirmation | null;
  rememberCard: boolean;
  paymentCard: PaymentCard | null;
  openItem: {
    itemId: ItemId;
    cartIndex: number | null; // null = new item being created
  } | null;
  deskNumber: string | null;
  recommendedItemId: ItemId | null;

  showEaterFeedbackFormDialog: boolean;
  showEaterOrderRatingFormDialog: boolean;
  eaterFeedbackFormMealOptions: EaterMealOption[] | null;
  eaterOrderRatingFormEaterMealOption: EaterMealOption | null;
  isEaterFeedbackFormSubmitting: boolean;
  isEaterOrderRatingFormSubmitting: boolean;
  eaterFeedbackFormError: boolean;
  eaterFeedbackFormSuccess: boolean;

  eaterFeedback: { [key: string]: EaterFeedback };
  eaterFeedbackCreated: boolean;

  remainingBudget: MajorCurrency;
  minimumTransactionFee: MajorCurrency;

  justEatVoucher: JustEatVoucher | null;

  ageConfirmationDialogVisible: boolean;
}

export const INITIAL_STATE: EaterState = {
  eaterMeals: {
    items: [],
    isLoading: false,
  },
  eaterPreviousMeals: {
    item: {
      total: 0,
      count: 0,
      pageSize: 0,
      page: 0,
      items: [],
    },
    isLoading: false,
  },
  shareLinkLocationToBeAdded: null,
  eaterCurrentLocationAddModal: null,
  eaterLocations: null,
  eaterPreviousDepartmentReferences: {
    items: [],
    isLoading: false,
  },

  order: null,
  cartItems: [],
  cartTotal: INITIAL_PRICE.items,
  departmentReference: '',
  deskNumber: null,
  isSubmittingCart: false,
  cartSubmitError: null,
  orderConfirmation: null,
  rememberCard: false,
  paymentCard: null,
  openItem: null,
  recommendedItemId: null,

  showEaterFeedbackFormDialog: false,
  showEaterOrderRatingFormDialog: false,
  eaterFeedbackFormMealOptions: null,
  eaterOrderRatingFormEaterMealOption: null,
  isEaterFeedbackFormSubmitting: false,
  isEaterOrderRatingFormSubmitting: false,
  eaterFeedbackFormError: false,
  eaterFeedbackFormSuccess: false,

  eaterFeedback: {},
  eaterFeedbackCreated: false,

  remainingBudget: 0,
  minimumTransactionFee: 0,

  justEatVoucher: null,
  ageConfirmationDialogVisible: false,
};

export function reducer(state: EaterState = INITIAL_STATE, action: Action): EaterState {
  switch (action.type) {
    case ActionTypes.ORDER_SUMMARY_LOADED: {
      const { orderId, summary } = (action as OrderSummaryLoadedAction).payload;
      const cartItems = filterCartItems(state.cartItems, summary.individualChoice.menuContent);
      const cartTotal = CartManager.getCartTotal(cartItems);

      return {
        ...state,
        order: {
          orderId,
          summary
        },
        cartItems,
        cartTotal,
        isSubmittingCart: false,
        cartSubmitError: null
      };
    }

    case ActionTypes.MY_MEALS_PAGE_VIEW: {
      if ((action as MyMealsPageViewAction).payload.silent) {
        return state;
      } else {
        return {
          ...state,
          eaterMeals: {
            items: [],
            isLoading: true,
          },
          eaterPreviousDepartmentReferences: {
            items: [],
            isLoading: true,
          }
        };
      }
    }

    case ActionTypes.EATER_MEALS_LOADED: {
      const { eaterMeals } = (action as EaterMealsLoadedAction).payload;

      return {
        ...state,
        eaterMeals: {
          items: eaterMeals,
          isLoading: false,
        },
      };
    }

    case ActionTypes.PREVIOUS_DEPARTMENT_REFERENCES_LOADED: {
      const { previousDepartmentReferences } = (action as PreviousDepartmentReferencesLoadedAction).payload;

      return {
        ...state,
        eaterPreviousDepartmentReferences: {
          items: previousDepartmentReferences,
          isLoading: false,
        }
      };
    }

    case ActionTypes.LOAD_EATER_PREVIOUS_MEALS: {
      const query = (action as LoadEaterPreviousMealsAction).payload.query;

      return {
        ...state,
        eaterPreviousMeals: {
          item: {
            ...state.eaterPreviousMeals.item,
            items: [],
            page: query.page,
            pageSize: query.pageSize,
          },
          isLoading: true,
        },
      };
    }

    case ActionTypes.EATER_PREVIOUS_MEALS_LOADED: {
      const eaterMealsPage = (action as EaterPreviousMealsLoadedAction).payload.eaterPreviousMealsPage;

      return {
        ...state,
        eaterPreviousMeals: {
          item: eaterMealsPage,
          isLoading: false,
        },
      };
    }

    case ActionTypes.HIDE_EATER_LOCATION_ADD_MODAL: {
      return {
        ...state,
        eaterCurrentLocationAddModal: null,
      };
    }

    case ActionTypes.SUBMIT_EATER_LOCATION_ADD_MODAL: {
      return {
        ...state,
        eaterCurrentLocationAddModal: {
          ...state.eaterCurrentLocationAddModal,
          isSubmitting: true,
        },
      };
    }

    case ActionTypes.EATER_LOCATION_SHARE_LINK_VIEWED: {
      const { eaterLocation } = (action as EaterLocationShareLinkViewedAction).payload;

      return {
        ...state,
        shareLinkLocationToBeAdded: eaterLocation
      };
    }

    case ActionTypes.SHOW_EATER_LOCATION_ADD_MODAL: {
      const { eaterLocation } = (action as ShowEaterLocationAddModalAction).payload;

      return {
        ...state,
        eaterCurrentLocationAddModal: {
          eaterLocation,
          isSubmitting: false,
        },
      };
    }

    case ActionTypes.EATER_LOCATION_ADDED: {
      return {
        ...state,
        shareLinkLocationToBeAdded: null,
        eaterCurrentLocationAddModal: {
          ...state.eaterCurrentLocationAddModal,
          isSubmitting: false,
        },
      };
    }

    case ActionTypes.SET_EATER_LOCATIONS: {
      const { locations } = (action as SetEaterLocationsAction).payload;

      return {
        ...state,
        eaterLocations: locations
      };
    }

    case ActionTypes.EATER_CART_LOADED: {
      const { cart } = (action as EaterCartLoadedAction).payload;
      const cartItems = cart.cartItems;
      const cartTotal = CartManager.getCartTotal(cartItems);
      const remainingBudget = cart.remainingBudget;
      const minimumTransactionFee = cart.minimumTransactionFee;
      const departmentReference = cart.departmentReference;

      return {
        ...state,
        cartItems,
        cartTotal,
        remainingBudget,
        minimumTransactionFee,
        departmentReference,
      };
    }

    case ActionTypes.EATER_MENU_PAGE_VIEW: {
      const { recommendedItemId } = (action as EaterMenuPageView).payload;

      return {
        ...state,
        recommendedItemId,
      };
    }

    case ActionTypes.ADD_ITEM_TO_CART: {
      const { item, quantity } = (action as AddItemToCartAction).payload;

      const cartItems = CartManager.addOrUpdateSingleItem(state.cartItems || [], item, quantity);
      const cartTotal = CartManager.getCartTotal(cartItems);

      return {
        ...state,
        cartItems,
        cartTotal,
      };
    }

    case ActionTypes.UPDATE_DEPARTMENT_REFERENCE: {
      const { departmentReference } = (action as UpdateDepartmentReferenceAction).payload;

      return {
        ...state,
        departmentReference,
      };
    }

    case ActionTypes.UPDATE_DESK_NUMBER: {
      const { deskNumber } = (action as UpdateDeskNumberAction).payload;

      return {
        ...state,
        deskNumber,
      };
    }

    case ActionTypes.EATER_CART_ITEM_EDIT_CLICK: {
      const { item, cartIndex } = (action as EaterCartItemEditClickAction).payload;

      return {
        ...state,
        openItem: { itemId: item.id, cartIndex },
      };
    }

    case ActionTypes.REMOVE_ITEM_FROM_CART: {
      const cartIndex = (action as RemoveItemFromCartAction).payload.cartIndex;

      const cartItems = CartManager.removeItem(state.cartItems || [], cartIndex);
      const cartTotal = CartManager.getCartTotal(cartItems);

      return {
        ...state,
        cartItems,
        cartTotal,
      };
    }

    case ActionTypes.UPDATE_ITEM_QUANTITY_IN_CART: {
      const { cartIndex, quantity } = (action as UpdateItemQuantityInCartAction).payload;

      let cartItems = state.cartItems || [];
      const cartItem = cartItems[cartIndex];

      if (!cartItem || !(isSingleCartItem(cartItem) || isCustomCartItem(cartItem))) {
        return state;
      }

      if (quantity > 0) {
        if (isCustomCartItem(cartItem)) {
          cartItems = CartManager.updateCustomItem(cartItems, cartIndex, quantity, null);
        } else if (isSingleCartItem(cartItem)) {
          cartItems = CartManager.addOrUpdateSingleItem(cartItems, cartItem.item, quantity);
        }
        // Bundles should never get quantity changes from cart
      } else {
        cartItems = CartManager.removeItem(cartItems, cartIndex);
      }

      const cartTotal = CartManager.getCartTotal(cartItems);

      return {
        ...state,
        cartItems,
        cartTotal,
      };
    }

    case ActionTypes.ADD_OR_UPDATE_ITEM_IN_CART: {
      const event = (action as AddOrUpdateItemInCartAction).payload.event;

      const item = findMenuItemById(state.order.summary.individualChoice.menuContent, state.openItem.itemId);

      const cartItems = CartManager.addOrUpdateItem(
        state.cartItems,
        item,
        state.openItem.cartIndex,
        event,
      );
      const cartTotal = CartManager.getCartTotal(cartItems);

      return {
        ...state,
        cartItems,
        cartTotal,
      };
    }

    case ActionTypes.EATER_MENU_ITEM_ADD_CLICK: {
      const { item } = (action as EaterMenuItemAddClickAction).payload;

      const cartIndex = CartManager.getCartIndexWhenAddingItem(item, state.cartItems);

      return {
        ...state,
        openItem: { itemId: item.id, cartIndex },
      };
    }

    case ActionTypes.ITEM_UNSELECTED: {
      return {
        ...state,
        openItem: null
      };
    }

    case ActionTypes.SUBMIT_EATER_CART: {
      return {
        ...state,
        isSubmittingCart: true,
        cartSubmitError: null,
        ageConfirmationDialogVisible: CartManager.areAnyCartItemsAgeRestricted(state.cartItems),
      };
    }

    case ActionTypes.EATER_CART_SUBMIT_FAILED: {
      const error: EaterCartSubmitError = (action as EaterCartSubmitFailedAction).payload.error;
      let order = state.order;
      if (error.type === 'OverBudget') {
        order = {
          ...order,
          summary: {
            ...order.summary,
            individualChoice: {
              ...order.summary.individualChoice,
              budget: error.messageArgs.budget
            }
          }
        };
      }

      return {
        ...state,
        order,
        isSubmittingCart: false,
        cartSubmitError: (action as EaterCartSubmitFailedAction).payload.error
      };
    }

    case ActionTypes.EATER_CART_SUBMITTED: {
      const date = state.order.summary.requestedDeliveryDate;
      return {
        ...state,
        cartItems: [],
        cartTotal: INITIAL_STATE.cartTotal,
        departmentReference: '',
        isSubmittingCart: false,
        cartSubmitError: null,
        deskNumber: null,
        recommendedItemId: null,
        orderConfirmation: {
          date,
        }
      };
    }

    case ActionTypes.DISMISS_EATER_CART_ERROR: {
      return {
        ...state,
        cartSubmitError: null
      };
    }

    case ActionTypes.CLEAR_ORDER_CONFIRMATION: {
      return {
        ...state,
        orderConfirmation: null
      };
    }

    case ActionTypes.EATER_MEAL_DELETED: {
      const { orderId } = (action as EaterMealDeletedAction).payload;

      return {
        ...state,
        eaterMeals: {
          ...state.eaterMeals,
          items: state.eaterMeals.items.map((meal) => {
            if (meal.orderId !== orderId) {
              return meal;
            } else {
              return {
                ...meal,
                eaterOptions: meal.eaterOptions.map((option: EaterMealOption) => ({
                  ...option,
                  itemNames: []
                }))
              };
            }
          })
        }
      };
    }

    case ActionTypes.EATER_MARKED_AS_AWAY: {
      const { orderId } = (action as EaterMarkedAsAwayAction).payload;

      return {
        ...state,
        eaterMeals: {
          ...state.eaterMeals,
          items: state.eaterMeals.items.map((meal: EaterMeal) => {
            if (meal.orderId !== orderId) {
              return meal;
            } else {
              return {
                ...meal,
                isMarkedAsAway: true,
                eaterOptions: meal.eaterOptions.map((option: EaterMealOption) => ({
                  ...option,
                  itemNames: []
                }))
              };
            }
          })
        }
      };
    }

    case ActionTypes.SHOULD_REMEMBER_CARD_CHANGED: {
      const { rememberCard } = (action as ShouldRememberCardChangedAction).payload;

      return {
        ...state,
        rememberCard
      };
    }

    case ActionTypes.EATER_PAYMENT_CARD_LOADED: {
      const { paymentCard } = (action as PaymentCardLoadedAction).payload;

      return {
        ...state,
        paymentCard
      };
    }

    case ActionTypes.SHOW_EATER_FEEDBACK_FORM: {
      const { eaterMealOptions } = (action as ShowEaterFeedbackFormAction).payload;

      return {
        ...state,
        eaterFeedbackFormMealOptions: eaterMealOptions,
        showEaterFeedbackFormDialog: true,
      };
    }

    case ActionTypes.SUBMIT_EATER_FEEDBACK_FORM: {
      return {
        ...state,
        isEaterFeedbackFormSubmitting: true,
      };
    }

    case ActionTypes.EATER_FEEDBACK_FORM_ERROR: {
      return {
        ...state,
        eaterFeedbackFormError: true,
        isEaterFeedbackFormSubmitting: false,
      };
    }

    case ActionTypes.HIDE_EATER_FEEDBACK_FORM: {
      return {
        ...state,
        eaterFeedbackFormMealOptions: null,
        isEaterFeedbackFormSubmitting: false,
        showEaterFeedbackFormDialog: false,
        eaterFeedbackFormError: false,
        eaterFeedbackFormSuccess: false,
      };
    }

    case ActionTypes.EATER_FEEDBACK_FORM_SUBMITTED: {
      return {
        ...state,
        eaterFeedbackFormMealOptions: null,
        isEaterFeedbackFormSubmitting: false,
        eaterFeedbackFormError: false,
        eaterFeedbackFormSuccess: true,
      };
    }

    case ActionTypes.SHOW_EATER_ORDER_RATING_FORM: {
      const { mealOption } = (action as ShowEaterOrderRatingFormAction).payload;

      return {
        ...state,
        eaterOrderRatingFormEaterMealOption: mealOption,
        isEaterOrderRatingFormSubmitting: false,
        showEaterOrderRatingFormDialog: true,
      };
    }

    case ActionTypes.EATER_ORDER_RATING_FORM_SUBMIT_CLICK: {
      return {
        ...state,
        isEaterOrderRatingFormSubmitting: true
      };
    }

    case ActionTypes.HIDE_EATER_ORDER_RATING_FORM: {
      return {
        ...state,
        showEaterOrderRatingFormDialog: false,
        eaterOrderRatingFormEaterMealOption: null,
      };
    }

    case ActionTypes.JUST_EAT_VOUCHER_RETRIEVED: {
      const { justEatVoucher } = (action as JustEatVoucherRetrievedAction).payload;

      return {
        ...state,
        justEatVoucher,
      };
    }

    case ActionTypes.EATER_AGE_CONFIRMATION_DIALOG_CONFIRM: {
      return {
        ...state,
        ageConfirmationDialogVisible: false,
      };
    }

    case ActionTypes.EATER_AGE_CONFIRMATION_DIALOG_CANCEL: {
      return {
        ...state,
        isSubmittingCart: false,
        ageConfirmationDialogVisible: false,
      };
    }

    case ActionTypes.EATER_FEEDBACK_LOADED: {
      const { eaterFeedback } = (action as EaterFeedbackLoadedAction).payload;
      return {
        ...state,
        eaterFeedback
      };
    }

    case ActionTypes.EATER_FEEDBACK_CREATED: {
      const { eaterFeedback } = (action as EaterFeedbackCreatedAction).payload;
      return {
        ...state,
        eaterFeedback: {
          ...state.eaterFeedback,
          [eaterFeedback.orderId.toString()]: eaterFeedback,
        },
        eaterFeedbackCreated: true,
        isEaterOrderRatingFormSubmitting: false,
        showEaterOrderRatingFormDialog: false
      };
    }

    case ActionTypes.THANKS_FOR_YOUR_FEEDBACK_COMPLETE: {
      return {
        ...state,
        eaterFeedbackCreated: false,
        showEaterOrderRatingFormDialog: false
      };
    }

    default:
      return state;
  }
}

function filterCartItems(cartItems: CartItem[], menuContent: MenuContent): CartItem[] {
  const itemIdsInMenu = (menuContent && menuContent.sections || []).reduce(
    (ids: string[], section) => ids.concat(
      (section && section.items || []).map((item) => item.id)
    ), []);
  return cartItems.filter((cartItem) => itemIdsInMenu.indexOf(cartItem.item.id) >= 0);
}
