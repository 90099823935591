import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ItemDeliverabilityProblem,
  numberOfVisibleDietariesSet,
  SingleItem,
  VatRateTypes,
  zeroVatRateTypes
} from '@citypantry/util-models';

@Component({
  selector: 'app-single-item',
  styles: [`:host { display: flex; flex-grow: 1; }`],
  templateUrl: './single-item.component.html'
})
export class SingleItemComponent {
  @Input()
  public cartQuantity: number;

  @Input()
  public deliverabilityProblems: ItemDeliverabilityProblem[];

  @Input()
  public item: SingleItem;

  @Input()
  public selectionEnabled: boolean;

  @Input()
  public hidePrices: boolean = false;

  @Output()
  public imageClick: EventEmitter<void> = new EventEmitter();

  public showMoreDetails: boolean;
  public zeroVatRateTypes: VatRateTypes[];

  constructor() {
    this.showMoreDetails = false;
    this.deliverabilityProblems = [];
    this.zeroVatRateTypes = zeroVatRateTypes;
  }

  public get disabled(): boolean {
    return this.deliverabilityProblems && this.deliverabilityProblems.length > 0;
  }

  public toggleShowMoreDetails(): void {
    if (!this.disabled) {
      this.showMoreDetails = !this.showMoreDetails;
    }
  }

  public hasDietaries(): boolean {
    return numberOfVisibleDietariesSet(this.item.dietaries) > 0;
  }

  public calculatePricePerPerson(): number {
    return this.item.price / this.item.portionSize;
  }

  public getImage(): string {
    return this.item && this.item.images.length && this.item.images[0].medium;
  }

  public onClickImage(): void {
    this.imageClick.emit();
  }
}
