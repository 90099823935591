/// <reference types="google.maps" />

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionTokenManager {
  private token: google.maps.places.AutocompleteSessionToken = null;

  public getToken(): google.maps.places.AutocompleteSessionToken {
    if (!this.token) {
      this.token = new google.maps.places.AutocompleteSessionToken();
    }
    return this.token;
  }

  public hasToken(): boolean {
    return this.token !== null;
  }

  public popToken(): google.maps.places.AutocompleteSessionToken {
    const token = this.token;
    this.token = null;
    return token;
  }
}
