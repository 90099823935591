<app-modal
  [show]="item"
  test-id="modal"
  (clickOutsideModal)="onCancel()"
  modalStyle="large"
  [noBorder]="true"
>
  <div class="item-modal">
    <button
      type="button"
      class="item-modal__close icon-button icon icon-cross touch-area"
      test-id="closeButton"
      (click)="onCancel()"
    ></button>

    <div class="item-modal__header">
      <h3
        class="item-modal__item-name heading-2"
        test-id="name"
      >{{ item.name }}</h3>
    </div>

    <div class="item-modal__container">
      <div
        *ngIf="item.images"
        class="item-modal__hero"
        [class.item-modal__hero--tall]="isSingleItem(item)"
      >
        <div
          *ngIf="item.images.length === 1"
          class="item-modal__hero-image"
          [backgroundImage]="item.images[0]?.large"
          test-id="heroImage"
        ></div>

        <swiper
          *ngIf="item.images.length > 1"
          class="carousel"
          [useSwiperClass]="true"
          [config]="swiper"
          (mouseover)="swiperComponent.directiveRef.stopAutoplay()"
          (mouseout)="swiperComponent.directiveRef.startAutoplay()"
          #swiperComponent
          test-id="carousel"
        >
          <div
            *ngFor="let image of item.images; first as first"
            class="item-modal__hero-image"
            [backgroundImage]="image.large"
            [attr.data-swiper-autoplay]="first ? 5000 : null"
            test-id="carouselImages"
          ></div>
        </swiper>
      </div>

      <div
        class="item-modal__item-details d-flex align-items-end"
        test-id="details"
      >
        <app-feeds-quantity
          *ngIf="isItemBundle(item) && item.portionSize > 1"
          class="item-modal__item-detail"
          [quantity]="item.portionSize"
          test-id="feedsQuantity"
        ></app-feeds-quantity>
        <app-serving-style
          *ngIf="item.servingStyle"
          class="item-modal__item-detail"
          [servingStyle]="item.servingStyle"
          test-id="servingStyle"
        ></app-serving-style>
        <app-minimum-order-quantity
          *ngIf="item.minimumOrderQuantity > 1"
          class="item-modal__item-detail"
          [minimumQuantity]="item.minimumOrderQuantity"
          test-id="minimumOrderQuantity"
        ></app-minimum-order-quantity>
        <app-eco-friendly-packaging
          *ngIf="item.ecoFriendlyPackaging"
          class="item-modal__item-detail"
          test-id="ecoFriendlyPackaging"
        ></app-eco-friendly-packaging>

        <div
          *ngIf="isItemBundle(item) && !hidePrices"
          class="ml-auto"
          test-id="pricePerPerson"
        >
          <span class="sub-header">
            {{ getItemBundlePricePerPerson() | currency:'GBP' }}
          </span>
          <span class="label">
            per person
          </span>
        </div>
      </div>

      <p
        *ngIf="item.description"
        class="item-modal__item-description"
        test-id="description"
      >
        {{ item.description }}
      </p>

      <app-single-item-modal-form
        *ngIf="isSingleItem(item)"
        [item]="item"
        test-id="singleItemForm"
      ></app-single-item-modal-form>
      <app-custom-item-modal-form
        *ngIf="isCustomItem(item)"
        [item]="item"
        [cartItem]="cartItem"
        [canOverrideQuantities]="canOverrideQuantities"
        [hidePrices]="hidePrices"
        (submitItem)="onSubmitItem($event)"
        (cancel)="onCancel()"
        test-id="customItemForm"
      ></app-custom-item-modal-form>
      <app-item-bundle-modal-form
        *ngIf="isItemBundle(item)"
        [item]="item"
        [cartItem]="cartItem"
        [canOverrideQuantities]="canOverrideQuantities"
        [hidePrices]="hidePrices"
        (submitItem)="onSubmitItem($event)"
        (cancel)="onCancel()"
        test-id="bundleItemForm"
      ></app-item-bundle-modal-form>
    </div>
  </div>
</app-modal>
