/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type DietaryType = 'Vegetarian' | 'Vegan' | 'No Gluten' | 'No Nuts' | 'No Dairy' | 'Halal' | 'Pescatarian';

export class DietaryTypes {
  public static VEGETARIAN:  DietaryType & 'Vegetarian'  = 'Vegetarian';
  public static VEGAN:       DietaryType & 'Vegan'       = 'Vegan';
  public static NO_GLUTEN:   DietaryType & 'No Gluten'   = 'No Gluten';
  public static NO_NUTS:     DietaryType & 'No Nuts'     = 'No Nuts';
  public static NO_DAIRY:    DietaryType & 'No Dairy'    = 'No Dairy';
  public static HALAL:       DietaryType & 'Halal'       = 'Halal';
  public static PESCATARIAN: DietaryType & 'Pescatarian' = 'Pescatarian';

  public static get values(): DietaryType[] {
    return [
      DietaryTypes.VEGETARIAN,
      DietaryTypes.VEGAN,
      DietaryTypes.NO_GLUTEN,
      DietaryTypes.NO_NUTS,
      DietaryTypes.NO_DAIRY,
      DietaryTypes.HALAL,
      DietaryTypes.PESCATARIAN,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type DietaryTypeEnum = typeof DietaryTypes;
