import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { IllustrativeIcon } from '@citypantry/util-enums';

@Component({
  selector: 'app-feedback-block',
  template: `
    <div class="col-sm-10 my-x-large mx-auto text-center text-banner">
      <div
        class="illustrative-icon mb-standard mx-auto"
        [ngClass]="'illustrative-icon--' + icon"
        test-id="icon"
      ></div>
      <div
        *ngIf="title"
        class="text-banner--large mb-standard"
        test-id="title"
      >
        <ng-container *ngIf="titleAsTemplateRef; else stringTitle">
          <ng-container *ngTemplateOutlet="titleAsTemplateRef"></ng-container>
        </ng-container>
        <ng-template #stringTitle>{{ title }}</ng-template>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackBlockComponent {
  @Input()
  public icon: IllustrativeIcon;

  @Input()
  public title: string | TemplateRef<any> | null;

  public get titleAsTemplateRef(): TemplateRef<any> | null {
    return this.title && typeof this.title !== 'string'
      ? this.title
      : null;
  }
}
