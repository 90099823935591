import { LocationStrategy } from '@angular/common';
import { Directive, HostBinding, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { TypedSimpleChanges } from '@citypantry/util';

/**
 * Behaves like a routerLink directive, but suppresses the click event to stop navigation.
 * This allows adding real links that can be opened in a new tab,
 * but whose behaviour within the tab is more than just a simple redirect.
 */
@Directive({
  selector: 'a[inactiveRouterLink]'
})
export class InactiveRouterLinkDirective implements OnInit, OnChanges {

  @HostBinding()
  public href: string;

  @Input()
  public queryParams: { [key: string]: any };

  @Input()
  public set inactiveRouterLink(segments: string | string[]) {
    if (!Array.isArray(segments)) {
      segments = [segments];
    }
    this.urlSegments = segments;
  }

  private urlSegments: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationStrategy: LocationStrategy
  ) {}

  public ngOnChanges(changes: TypedSimpleChanges<InactiveRouterLinkDirective>): void {
    if (changes.hasOwnProperty('queryParams') || changes.hasOwnProperty('inactiveRouterLink')) {
      this.updateHref();
    }
  }

  public ngOnInit(): void {
    this.updateHref();
  }

  @HostListener('click', ['$event'])
  public onClick($event: MouseEvent): void {
    $event.preventDefault();
  }

  private updateHref(): void {
    const urlTree = this.getUrlTree(this.urlSegments);
    this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }

  private getUrlTree(target: string[]): UrlTree {
    return this.router.createUrlTree(target, {
      relativeTo: this.route,
      queryParams: this.queryParams
    });
  }
}
