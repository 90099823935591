import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule, DispatchAnalyticsEventData } from './analytics-rule.interface';

export const individualChoiceMenuRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-options',
    events: {
      class: 'IndividualChoiceCartOptionsComponent',
      eventName: 'updateChoiceDeadline'
    },
    definition: (event) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: `choice deadline changed, new-deadline: ${event.event}`
    })
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'parameters-form postcode',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Postcode set'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'parameters-form date',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Date set'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'parameters-form time',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_SEARCH_PARAMETERS,
      label: 'Time set'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-vendors vendor-icon',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Vendor icon clicked'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-vendors remove-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Remove vendor button clicked'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'add-another-vendor',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'ADD ANOTHER VENDOR button clicked'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-options colleague-groups-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `Use Groups Toggle ${event.data['colleague-groups-toggle'].active ? 'OFF' : 'ON'}`,
    })
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-options allow-topups-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange'
    },
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `IC Allow Top-ups Toggle ${event.data['allow-topups-toggle'].active ? 'OFF' : 'ON'}`,
    })
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-options ic-cart-budget-per-person',
    events: 'click',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'IC Budget per Person entered'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-options expected-headcount',
    events: 'click',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'IC Expected Headcount value entered'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'search-params-modal top-of-component',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.SEARCH_PARAMETERS,
      label: `IC search popup viewed`,
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-search-params',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: 'Edit IC search parameters clicked'
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-search-popup show-food-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_SEARCH_POPUP,
      label: `SHOW ME THE FOOD button clicked`,
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-buttons create-order-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `CREATE MENU button clicked`,
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'ic-cart-ic-toggle',
    events: {
      class: 'CartToggleComponent',
      eventName: 'toggleChange'
    },
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `IC mode toggled OFF`,
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'add-vendor-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_ORDER_SUMMARY_BOX,
      label: `ADD VENDOR TO MENU button clicked`,
    }
  },
  {
    url: '/menus/individual-choice/vendor/:vendorId/:vendorLocationId/menu',
    path: 'menu-content menu-item-image',
    events: 'click',
    definition: (event): DispatchAnalyticsEventData => {
      return {
        action: AnalyticsActions.CLICK,
        category: AnalyticsCategories.VENDORS_MENU,
        label: 'Item image clicked',
        extraFields: {
          imageCount: event.data['menu-item-image'].imageCount,
          itemType: event.data['menu-item-image'].itemType,
        },
      };
    }
  },
];
