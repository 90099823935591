import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '@citypantry/util-validators';
import { round } from 'mathjs';

@Component({
  selector: 'app-editable-price',
  templateUrl: './editable-price.component.html',
})
export class EditablePriceComponent implements OnInit {

  @Input()
  public startValue: number;

  @Input()
  public locked: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public prefix: string;

  @Input()
  public step: number;

  @Input()
  public min: number | null;

  @Output()
  public updateValue: EventEmitter<number> = new EventEmitter();

  @ViewChild('numberInput', { static: false })
  public numberInput: ElementRef;

  public control: FormControl;
  public currentValue: number;
  public isInputActive: boolean;

  public ngOnInit(): void {
    this.isInputActive = false;
    this.currentValue = this.startValue;
    this.control = new FormControl(
      this.startValue, [
        CustomValidators.number,
        CustomValidators.min(this.min || 0),
        CustomValidators.max(1000),
        Validators.required,
      ],
    );
    this.control.valueChanges.subscribe((value: number) => {
      this.currentValue = value;
    });
  }

  public submit(): void {
    if (this.control.valid) {
      this.deselect();
      const value = round(this.currentValue, 2);
      this.updateValue.emit(value);
    }
  }

  public focus(): void {
    if (!this.isInputActive) {
      this.select();
    }
  }

  public select(): void {
    if (this.locked || this.disabled) {
      return;
    }

    this.isInputActive = true;
    setTimeout(() => {
      if (this.numberInput && this.numberInput.nativeElement) {
        this.numberInput.nativeElement.focus();
      }
    });
  }

  public deselect(): void {
    this.isInputActive = false;
  }

  public submitClicked($event: Event): void {
    // Prevent the click from re-focusing the input
    $event.stopPropagation();
    $event.preventDefault();

    this.submit();
  }

  public blur(): void {
    if (this.control.invalid) {
      this.control.setValue(this.startValue, { emitEvent: false });
      this.deselect();
    } else {
      this.submit();
    }
  }
}
