import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { CurrencyWithBlankPipe } from './pipes/currency-with-blank.pipe';
import { FormatTimeFromHoursPipe } from './pipes/format-time-from-hours.pipe';
import { FormatTimeFromHumanReadableIntTimePipe } from './pipes/format-time-from-human-readable-int.pipe';
import { MinorToMajorPipe } from './pipes/minor-to-major.pipe';
import { VatRateLabelPipe } from './pipes/vat-rate-label.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CurrencySymbolPipe,
    CurrencyWithBlankPipe,
    FormatTimeFromHumanReadableIntTimePipe,
    MinorToMajorPipe,
    VatRateLabelPipe,
    FormatTimeFromHoursPipe,
  ],
  exports: [
    CurrencySymbolPipe,
    CurrencyWithBlankPipe,
    FormatTimeFromHumanReadableIntTimePipe,
    MinorToMajorPipe,
    VatRateLabelPipe,
    FormatTimeFromHoursPipe,
  ],
  providers: [
    CurrencyPipe,
    CurrencySymbolPipe,
    CurrencyWithBlankPipe,
    FormatTimeFromHumanReadableIntTimePipe,
    MinorToMajorPipe,
    VatRateLabelPipe,
    FormatTimeFromHoursPipe,
  ],
})
export class CurrencyComponentsModule {}
