import { Address, createAddressFromJson } from '../address.model';
import { createOperatingSchedulesFromVendorLocationJson, OperatingSchedule } from './operating-schedule.model';
import {
  createEmptyVendorLocationNoticeSlotLimits,
  createVendorLocationNoticeSlotLimitsFromJson,
  VendorLocationNoticeSlotLimits,
} from './vendor-location-notice-slot-limits.model';

declare const VendorLocationIdSymbol: unique symbol;
export type VendorLocationId = string & {
  [VendorLocationIdSymbol]: never;
};

declare const VendorLocationSlugSymbol: unique symbol;
export type VendorLocationSlug = string & {
  [VendorLocationSlugSymbol]: never;
};

export interface VendorSimpleLocation extends Address {
  id: VendorLocationId;
}

export interface VendorLocation extends VendorSimpleLocation {
  coldDeliveryRadius?: number;
  contactName: string;
  defaultNotice?: number;
  email: string;
  hotDeliveryRadius?: number;
  individualChoiceEnabled: boolean;
  loadingTime: number;
  name: string;
  publicName: string;
  orderNotificationEmailAddresses: string[];
  orderNotificationMobileNumbers: string[];
  operatingSchedules: OperatingSchedule[];
  pickupInstructions: string;
  vendorUsesOwnEquipment: boolean;
  noticeSlotLimits: VendorLocationNoticeSlotLimits;
  clubZeroEnabled: boolean;
}

// This model represents the data that are settable by the user in the UI
export type VendorLocationPayload = Omit<VendorLocation, 'id'>;

// TODO CPD-11361: refactor to a factory
export const EMPTY_VENDOR_LOCATION_PAYLOAD: VendorLocationPayload = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  coldDeliveryRadius: null,
  contactName: '',
  country: '',
  county: '',
  defaultNotice: null,
  email: '',
  hotDeliveryRadius: null,
  individualChoiceEnabled: false,
  landlineNumber: null,
  latitude: null,
  loadingTime: null,
  longitude: null,
  name: '',
  publicName: '',
  orderNotificationEmailAddresses: [],
  orderNotificationMobileNumbers: [],
  operatingSchedules: [],
  pickupInstructions: '',
  postcode: '',
  vendorUsesOwnEquipment: false,
  noticeSlotLimits: createEmptyVendorLocationNoticeSlotLimits(),
  clubZeroEnabled: false,
};
Object.freeze(EMPTY_VENDOR_LOCATION_PAYLOAD);

export function createVendorSimpleLocationFromJson(json: Partial<VendorSimpleLocation>): VendorSimpleLocation {
  const address: Partial<VendorLocation> = createAddressFromJson(json);

  address.id = json.id;

  return address as VendorLocation;
}

export function createVendorLocationFromJson(json: Partial<VendorLocation>): VendorLocation {
  const address: Partial<VendorLocation> = createVendorSimpleLocationFromJson(json);

  address.coldDeliveryRadius = json.coldDeliveryRadius;
  address.contactName = json.contactName;
  address.defaultNotice = json.defaultNotice;
  address.email = json.email;
  address.hotDeliveryRadius = json.hotDeliveryRadius;
  address.individualChoiceEnabled = !!json.individualChoiceEnabled;
  address.loadingTime = json.loadingTime;
  address.name = json.name;
  address.publicName = json.publicName;
  address.orderNotificationEmailAddresses = json.orderNotificationEmailAddresses || [];
  address.orderNotificationMobileNumbers = json.orderNotificationMobileNumbers || [];
  address.pickupInstructions = json.pickupInstructions;
  address.vendorUsesOwnEquipment = json.vendorUsesOwnEquipment;
  // TODO: if operating schedule is not available then populate from the redundant properties
  address.operatingSchedules = createOperatingSchedulesFromVendorLocationJson(json);
  address.noticeSlotLimits = json.noticeSlotLimits
    ? createVendorLocationNoticeSlotLimitsFromJson(json.noticeSlotLimits)
    : createEmptyVendorLocationNoticeSlotLimits();
  address.clubZeroEnabled = !!json.clubZeroEnabled;

  return address as VendorLocation;
}
