import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItems, mapmap } from '@citypantry/util';
import { Country, createCountryFromJson } from '@citypantry/util-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryApi {

  constructor(
    private http: HttpClient,
  ) {}

  public getCountries(): Observable<Country[]> {
    return this.http.get('/countries').pipe(
      getItems(),
      mapmap(createCountryFromJson),
    );
  }
}
