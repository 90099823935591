/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type CartItemType = 'CartSingleItem' | 'CartCustomItem' | 'CartItemBundle';

export class CartItemTypes {
  public static SINGLE_ITEM: CartItemType & 'CartSingleItem' = 'CartSingleItem';
  public static CUSTOM_ITEM: CartItemType & 'CartCustomItem' = 'CartCustomItem';
  public static ITEM_BUNDLE: CartItemType & 'CartItemBundle' = 'CartItemBundle';

  public static get values(): CartItemType[] {
    return [
      CartItemTypes.SINGLE_ITEM,
      CartItemTypes.CUSTOM_ITEM,
      CartItemTypes.ITEM_BUNDLE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type CartItemTypeEnum = typeof CartItemTypes;
