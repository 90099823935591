import { ModuleWithProviders, NgModule } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';
import { AppConfig } from './app-config';

export function getWindowConfig(w: WindowRef): AppConfig {
  const location = w.nativeWindow.location;
  const frontendBase = `${ location.protocol }//${ location.host }`;
  return {
    ...w.nativeWindow['config'],
    FRONTEND_BASE: frontendBase,
  };
}

@NgModule()
export class AppConfigModule {
  public static forRoot(): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        {
          provide: AppConfig,
          // must useFactory else AOT will compile out useValue that is undefined at the time of compilation
          useFactory: getWindowConfig,
          deps: [WindowRef],
        },
      ],
    };
  }
}
