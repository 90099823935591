import { ValidatorFn } from '@angular/forms';

// This regex was migrated from the `frontend` project.
// It matches valid UK numbers, but also allows ANY non-UK number.
const PHONE_REGEX = /^\+(?!44)\d+|(\+44|0)[1-9][0-9]{9}$/;

export const isPhoneValid = (inputPhone: string | null): boolean => {
  if (!inputPhone) {
    return true;
  }

  const sanitizedPhone = inputPhone.replace(/[\s\t]+/g, '');
  return sanitizedPhone && PHONE_REGEX.test(sanitizedPhone);
};

export const phone: ValidatorFn = (control) => {
  return isPhoneValid(control.value) ?
    null :
    { phone: true };
};
