import moment from 'moment';

export interface MenuApproval {
  id: string;
  name: string;
  vendorId: string;
  vendorName: string;
  pendingApproval: moment.Moment | null;
  isDraftComplete: boolean;
}

export function createMenuApprovalFromJson(json: Partial<MenuApproval> = {}): MenuApproval {
  const menu: Partial<MenuApproval> = {};

  menu.id = json.id;
  menu.name = json.name || '';
  menu.vendorId = json.vendorId;
  menu.vendorName = json.vendorName;
  menu.pendingApproval = json.pendingApproval ? moment(json.pendingApproval) : null;
  menu.isDraftComplete = !!json.isDraftComplete;

  return menu as MenuApproval;
}
