/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type ChatWidgetType = 'zopim' | 'none';

export class ChatWidgetTypes {
  public static ZOPIM: ChatWidgetType & 'zopim' = 'zopim';
  public static NONE:  ChatWidgetType & 'none'  = 'none';

  public static get values(): ChatWidgetType[] {
    return [
      ChatWidgetTypes.ZOPIM,
      ChatWidgetTypes.NONE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ChatWidgetOptionEnums = typeof ChatWidgetTypes;
