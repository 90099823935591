export type Flat<T> = { [K in keyof T]?: T[K] | string };

export function flatten<T>(item: T, mappings: { [K in keyof T]?: string | ((value: T) => string) }): any {
  const flat: Flat<T> = {};
  for (const key in item) {
    if (!item.hasOwnProperty(key)) {
      continue;
    }
    if (mappings.hasOwnProperty(key)) {
      let id: string;
      if (typeof mappings[key] === 'function') {
        id = (mappings[key] as (value: T) => string)(item);
      } else {
        id = (item as any)[key][mappings[key] as string];
      }
      if (!id) {
        throw new Error(`Could not map item without ID: ${ JSON.stringify(item) }`);
      }
      (flat as any)[key] = id;
    } else {
      (flat as any)[key] = item[key];
    }
  }
  return flat as Flat<T>;
}

export function inflate<T>(flat: Flat<T>, mappings: { [K in keyof T]?: (id: string) => T[K] }): T {
  const item: any = {};
  for (const key in flat) {
    if (!flat.hasOwnProperty(key)) {
      continue;
    }
    if (mappings.hasOwnProperty(key)) {
      item[key] = mappings[key](flat[key] as string);
    } else {
      item[key] = flat[key];
    }
  }
  return item as T;
}

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ''): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type '${ label }' is not unique'`);
  }

  typeCache[label as string] = true;

  return label as T;
}
