/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type MarketingCampaignName =
  'Veganuary'
  | 'Valentine\'s Day'
  | 'Pancake Day'
  | 'International Women\'s Day'
  | 'Easter'
  | 'May bank holidays'
  | 'Pride month'
  | 'August bank holiday'
  | 'Halloween'
  | 'Christmas';

export class MarketingCampaignNames {
  public static VEGANUARY:                MarketingCampaignName & 'Veganuary'                  = 'Veganuary';
  public static VALENTINES_DAY:           MarketingCampaignName & 'Valentine\'s Day'           = 'Valentine\'s Day';
  public static PANCAKE_DAY:              MarketingCampaignName & 'Pancake Day'                = 'Pancake Day';
  public static INTERNATIONAL_WOMENS_DAY: MarketingCampaignName & 'International Women\'s Day' = 'International Women\'s Day';
  public static EASTER:                   MarketingCampaignName & 'Easter'                     = 'Easter';
  public static MAY_BANK_HOLIDAYS:        MarketingCampaignName & 'May bank holidays'          = 'May bank holidays';
  public static PRIDE_MONTH:              MarketingCampaignName & 'Pride month'                = 'Pride month';
  public static AUGUST_BANK_HOLIDAY:      MarketingCampaignName & 'August bank holiday'        = 'August bank holiday';
  public static HALLOWEEN:                MarketingCampaignName & 'Halloween'                  = 'Halloween';
  public static CHRISTMAS:                MarketingCampaignName & 'Christmas'                  = 'Christmas';

  public static get values(): MarketingCampaignName[] {
    return [
      MarketingCampaignNames.VEGANUARY,
      MarketingCampaignNames.VALENTINES_DAY,
      MarketingCampaignNames.PANCAKE_DAY,
      MarketingCampaignNames.INTERNATIONAL_WOMENS_DAY,
      MarketingCampaignNames.EASTER,
      MarketingCampaignNames.MAY_BANK_HOLIDAYS,
      MarketingCampaignNames.PRIDE_MONTH,
      MarketingCampaignNames.AUGUST_BANK_HOLIDAY,
      MarketingCampaignNames.HALLOWEEN,
      MarketingCampaignNames.CHRISTMAS,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type MarketingCampaignNameEnum = typeof MarketingCampaignNames;
