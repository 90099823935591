<li
  class="header-dropdown header-navigation__item"
  [class.header-dropdown--active]="isOpen"
  (clickOutside)="onClickOutside()"
  test-id="wrapper"
>
  <!-- We cannot allow an empty `analyticsId` tag to render, because it is not supported by `AnalyticsIdDirective` -->
  <a
    *ngIf="triggerAnalyticsId; else noAnalyticsId"
    class="header-navigation__link"
    href="javascript:void(0);"
    (click)="toggleOpen()"
    analyticsId="{{ triggerAnalyticsId }}"
    test-id="trigger"
  >
    {{ triggerLabel }} <span class="header-dropdown__arrow"></span>
  </a>

  <ng-template #noAnalyticsId>
    <a
      class="header-navigation__link"
      href="javascript:void(0);"
      (click)="toggleOpen()"
      test-id="trigger"
    >
      {{ triggerLabel }} <span class="header-dropdown__arrow"></span>
    </a>
  </ng-template>


  <ul class="header-dropdown__menu">
    <ng-content></ng-content>
  </ul>
</li>
