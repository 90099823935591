import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '@citypantry/state';
import { DeliveryContactActions } from './delivery-contact.actions';

@Injectable({ providedIn: 'root' })
export class DeliveryContactService {

  constructor(private store: Store<AppState>) {}

  public getDeliveryContacts(): void {
    this.store.dispatch(DeliveryContactActions.getDeliveryContacts());
  }
}
