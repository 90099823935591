import { ensureValidEnumValue } from '@citypantry/util';
import moment from 'moment';
import { ChoiceDeadline, ChoiceDeadlines } from '../individual-choice/choice-deadline.enum';
import { MajorCurrency } from '../money/minor-major-currency.model';
import { OrderId } from '../order/order-id.model';

export interface CalendarOrderGroupIndividualChoice {
  orderGroupId: OrderId;
  choiceDeadlineType: ChoiceDeadline;
  orderDeadline: moment.Moment;
  expectedHeadcount: number;
  budget: MajorCurrency;
  choiceOpenTime: moment.Moment | null;
}

export function createCalendarOrderGroupIndividualChoiceFromJson(json: Partial<CalendarOrderGroupIndividualChoice>):
  CalendarOrderGroupIndividualChoice {
  const individualChoice: Partial<CalendarOrderGroupIndividualChoice> = {};

  individualChoice.orderGroupId = json.orderGroupId as OrderId;
  individualChoice.choiceDeadlineType = ensureValidEnumValue(ChoiceDeadlines, json.choiceDeadlineType);
  individualChoice.orderDeadline = json.orderDeadline ? moment(json.orderDeadline) : null;
  individualChoice.expectedHeadcount = json.expectedHeadcount;
  individualChoice.budget = json.budget;
  individualChoice.choiceOpenTime = json.choiceOpenTime ? moment(json.choiceOpenTime) : null;

  return individualChoice as CalendarOrderGroupIndividualChoice;
}
