import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppState, RouterActions } from '@citypantry/state';
import { ErrorResponse } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from './auth-storage.service';

export const DISABLE_REDIRECT_ON_AUTH_FAILURE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authStorageService: AuthStorageService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedRequest = this.addAuthHeadersIfPresent(request);

    return next.handle(updatedRequest)
      .pipe(
        catchError((response: HttpErrorResponse | ErrorResponse | object): Observable<HttpEvent<any>> => {
          // If the intercepted error indicates there was a problem with user's authentication,
          // attempt forcing them to log in and redirecting them back to the page they wanted.
          //
          // WARNING: This will not work for logged in users, which would need to be forced to log out and then log back in.
          // Logging out is done by a redirect to the /logout page, which is external to the Angular app, so after being logged out,
          // the user will not be provided with the forwardUrl param on the /login page.
          if (
            (response instanceof HttpErrorResponse || response instanceof ErrorResponse) &&
            (response.status === 403 || response.status === 401) &&
            !request.context.get(DISABLE_REDIRECT_ON_AUTH_FAILURE)
          ) {
            // If the request failed in the middle of navigation (i.e. when a guard triggered it),
            // identify the url that the user intended to visit;
            // otherwise use the url of the page they were on when the request was triggered.
            let forwardUrl;
            const currentNavigation = this.router.getCurrentNavigation();
            if (currentNavigation) {
              forwardUrl = this.router.getCurrentNavigation().extractedUrl.toString();
            } else {
              forwardUrl = this.router.url;
            }

            this.store.dispatch(RouterActions.go({ path: '/login', query: { forward: forwardUrl } }));

            return new Subject(); // returning an empty observable results in an error in any guard that is waiting for a http response
          } else {
            return throwError(response);
          }
        })
      );
  }

  private addAuthHeadersIfPresent(request: HttpRequest<any>): HttpRequest<any> {
    const authHeaders = this.authStorageService.getAuthHeaders();

    if (!authHeaders) {
      return request;
    } else {
      return request.clone({
        setHeaders: authHeaders
      });
    }
  }
}
