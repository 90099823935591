import { ensureValidEnumValue } from '@citypantry/util';
import { createImageFromJson, Image } from '../image.model';
import { ScheduledOrderFailure, ScheduledOrderFailures } from '../order-schedule';
import { OrderId } from '../order/order-id.model';
import { UserId } from '../user-id.model';
import { OrderStatus, OrderStatuses } from '../order/order-status.enum';
import { CalendarInvoice, createLatestInvoiceFromJson } from './calendar-invoice.model';

export type CalendarOrder = PlaceholderCalendarOrder | ExistingCalendarOrder;

/* CalendarOrder models */

interface BaseCalendarOrder {
  vendorLogo: string;
  vendorName: string;
  vendorSlug: string;

  image: Image | null;
}

export interface PlaceholderCalendarOrder extends BaseCalendarOrder  {
  failureType: null | ScheduledOrderFailure;
}

export interface ExistingCalendarOrder extends BaseCalendarOrder {
  id: OrderId;
  cartId: string;
  status: OrderStatus;
  latestInvoice: CalendarInvoice | null;
  feedbackFoodQuality: null | number;
  eligibleForFeedback: boolean;
  purchaserId: UserId;

  // TODO CPD-14310 Move vendorLocationPublicName and vendorLocationSlug to BaseCalendarOrder when order
  //  schedule requirements can be configured for a specific vendor location
  vendorLocationPublicName: string | null;
  vendorLocationSlug: string | null;
}

/* fromJson functions */

function createBaseCalendarOrderFromJson(json: Partial<BaseCalendarOrder>): BaseCalendarOrder {
  const order: Partial<BaseCalendarOrder> = {};

  order.vendorLogo = json.vendorLogo;
  order.vendorName = json.vendorName;
  order.vendorSlug = json.vendorSlug;
  order.image = json.image ? createImageFromJson(json.image) : null;

  return order as BaseCalendarOrder;
}

export function createPlaceholderCalendarOrderFromJson(json: Partial<PlaceholderCalendarOrder>): PlaceholderCalendarOrder {
  const order: Partial<PlaceholderCalendarOrder> = createBaseCalendarOrderFromJson(json);

  order.failureType = json.failureType ? ensureValidEnumValue(ScheduledOrderFailures, json.failureType) : null;

  return order as PlaceholderCalendarOrder;
}

export function createExistingCalendarOrderFromJson(json: Partial<ExistingCalendarOrder>): ExistingCalendarOrder {
  const order: Partial<ExistingCalendarOrder> = createBaseCalendarOrderFromJson(json);

  order.id = json.id as OrderId;
  order.cartId = json.cartId;
  order.status = ensureValidEnumValue(OrderStatuses, json.status);
  order.latestInvoice = json.latestInvoice ? createLatestInvoiceFromJson(json.latestInvoice) : null;
  order.feedbackFoodQuality = json.feedbackFoodQuality === 0 ? json.feedbackFoodQuality : (json.feedbackFoodQuality || null);
  order.eligibleForFeedback = !!json.eligibleForFeedback;
  order.vendorLocationPublicName = json.vendorLocationPublicName ? json.vendorLocationPublicName : null;
  order.vendorLocationSlug = json.vendorLocationSlug ? json.vendorLocationSlug : null;
  order.purchaserId = json.purchaserId;

  return order as ExistingCalendarOrder;
}

/*
  No createCalendarOrderFromJson function was written because YAGNI.
  Currently, deciding between "createPlaceholderCalendarOrderFromJson" or "createCalendarOrderFromJson" function calls
  happens during parent model (CalendarOrderGroup) deserialization as only it contains a discriminator (isPlaceholder)
  designed specifically to distinguish between them.
*/
