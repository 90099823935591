<app-modal
  [show]="show"
  [noWidth]="noWidth"
  [showOverflow]="true"
  [centerVertically]="true"
  [modalStyle]="modalStyle"
  (clickOutsideModal)="onOutsideClicked()"
  test-id="modal"
>
  <div class="dialog dialog--logo dialog--has-footer">
    <button
      *ngIf="!hideCloseButton"
      class="dialog__close"
      (click)="onCloseClicked()"
      analyticsId="close-button"
      test-id="closeButton"
    >
      Close
    </button>

    <ng-content></ng-content>
  </div>
</app-modal>
