import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CustomItem,
  ItemDeliverabilityProblem,
  numberOfVisibleDietariesSet,
  VatRateTypes,
  zeroVatRateTypes,
} from '@citypantry/util-models';

@Component({
  selector: 'app-custom-item',
  styles: [`:host { display: flex; flex-grow: 1; }`],
  templateUrl: './custom-item.component.html',
})
export class CustomItemComponent {
  @Input()
  public deliverabilityProblems: ItemDeliverabilityProblem[];

  @Input()
  public item: CustomItem;

  @Input()
  public selectionEnabled: boolean;

  @Input()
  public hidePrices: boolean = false;

  @Output()
  public imageClick: EventEmitter<void> = new EventEmitter();

  public showMoreDetails: boolean;
  public zeroVatRateTypes: VatRateTypes[];

  constructor() {
    this.zeroVatRateTypes = zeroVatRateTypes;
  }

  public get disabled(): boolean {
    return this.deliverabilityProblems && this.deliverabilityProblems.length > 0;
  }

  public toggleShowMoreDetails(): void {
    if (!this.disabled) {
      this.showMoreDetails = !this.showMoreDetails;
    }
  }

  public hasPossibleDietaries(): boolean {
    return numberOfVisibleDietariesSet(this.item.possibleDietaries) > 0;
  }

  public calculatePricePerPerson(): number {
    return this.item.price / this.item.portionSize;
  }

  public shouldShowKcals(): boolean {
    return this.item.baseItemKcal !== null && this.item.baseItemKcal >=0;
  }

  public getImage(): string {
    return this.item && this.item.images.length && this.item.images[0].medium;
  }

  public onClickImage(): void {
    this.imageClick.emit();
  }
}
