<label
  class="toggle-input cart-toggle-layout"
  [class.toggle-input--disabled]="disabled"
  test-id="wrapper"
>
  <span class="ml-0">
    <span class="vertically-center" test-id="label">
      <ng-content></ng-content>
    </span>
  </span>
  <span
    class="toggle-input__label cart-text cart-text--title ml-auto mr-small"
    test-id="onOffText"
  >
    {{ onOffText }}
  </span>
  <input
    type="checkbox"
    class="toggle-input__input"
    (change)="updateToggle($event)"
    [checked]="disabled ? false : toggle"
    [disabled]="disabled"
    test-id="input"
  >
  <span
    class="toggle-input__toggle"
    [tooltip]="toggleTooltip"
    test-id="toggle"
  ></span>
</label>
