/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type IllustrativeIcon =
  'alcoholic-drink'
  | 'bowl'
  | 'calendar'
  | 'candy-cane'
  | 'cart'
  | 'chat'
  | 'cupcake'
  | 'delivery'
  | 'dietary'
  | 'flip'
  | 'group-link'
  | 'group'
  | 'happy'
  | 'heart'
  | 'ice-cream'
  | 'individual-choice-icon-dark'
  | 'individual-choice-icon'
  | 'individual-choice'
  | 'map'
  | 'meal-plan-simple'
  | 'meal-plan'
  | 'money'
  | 'new'
  | 'present'
  | 'question'
  | 'rating'
  | 'sad'
  | 'share'
  | 'shop'
  | 'summer'
  | 'target'
  | 'tick'
  | 'time'
  | 'user-confirmed'
  | 'user'
  | 'warning'
  | 'warning-triangle'
  | 'party'
  | 'easter';

export class IllustrativeIcons {
  public static ALCOHOLIC_DRINK:             IllustrativeIcon & 'alcoholic-drink'             = 'alcoholic-drink';
  public static BOWL:                        IllustrativeIcon & 'bowl'                        = 'bowl';
  public static CALENDAR:                    IllustrativeIcon & 'calendar'                    = 'calendar';
  public static CANDY_CANE:                  IllustrativeIcon & 'candy-cane'                  = 'candy-cane';
  public static CART:                        IllustrativeIcon & 'cart'                        = 'cart';
  public static CHAT:                        IllustrativeIcon & 'chat'                        = 'chat';
  public static CUPCAKE:                     IllustrativeIcon & 'cupcake'                     = 'cupcake';
  public static DELIVERY:                    IllustrativeIcon & 'delivery'                    = 'delivery';
  public static DIETARY:                     IllustrativeIcon & 'dietary'                     = 'dietary';
  public static GROUP:                       IllustrativeIcon & 'group'                       = 'group';
  public static GROUP_LINK:                  IllustrativeIcon & 'group-link'                  = 'group-link';
  public static HAPPY:                       IllustrativeIcon & 'happy'                       = 'happy';
  public static HEART:                       IllustrativeIcon & 'heart'                       = 'heart';
  public static ICE_CREAM:                   IllustrativeIcon & 'ice-cream'                   = 'ice-cream';
  public static INDIVIDUAL_CHOICE:           IllustrativeIcon & 'individual-choice'           = 'individual-choice';
  public static USER_CONFIRMED:              IllustrativeIcon & 'user-confirmed'              = 'user-confirmed';
  public static MAP:                         IllustrativeIcon & 'map'                         = 'map';
  public static MEAL_PLAN:                   IllustrativeIcon & 'meal-plan'                   = 'meal-plan';
  public static MEAL_PLAN_SIMPLE:            IllustrativeIcon & 'meal-plan-simple'            = 'meal-plan-simple';
  public static INDIVIDUAL_CHOICE_ICON:      IllustrativeIcon & 'individual-choice-icon'      = 'individual-choice-icon';
  public static INDIVIDUAL_CHOICE_ICON_DARK: IllustrativeIcon & 'individual-choice-icon-dark' = 'individual-choice-icon-dark';
  public static MONEY:                       IllustrativeIcon & 'money'                       = 'money';
  public static NEW:                         IllustrativeIcon & 'new'                         = 'new';
  public static RATING:                      IllustrativeIcon & 'rating'                      = 'rating';
  public static FLIP:                        IllustrativeIcon & 'flip'                        = 'flip';
  public static SAD:                         IllustrativeIcon & 'sad'                         = 'sad';
  public static SHARE:                       IllustrativeIcon & 'share'                       = 'share';
  public static SHOP:                        IllustrativeIcon & 'shop'                        = 'shop';
  public static SUMMER:                      IllustrativeIcon & 'summer'                      = 'summer';
  public static PRESENT:                     IllustrativeIcon & 'present'                     = 'present';
  public static QUESTION:                    IllustrativeIcon & 'question'                    = 'question';
  public static TARGET:                      IllustrativeIcon & 'target'                      = 'target';
  public static TICK:                        IllustrativeIcon & 'tick'                        = 'tick';
  public static TIME:                        IllustrativeIcon & 'time'                        = 'time';
  public static USER:                        IllustrativeIcon & 'user'                        = 'user';
  public static WARNING:                     IllustrativeIcon & 'warning'                     = 'warning';
  public static WARNING_TRIANGLE:            IllustrativeIcon & 'warning-triangle'            = 'warning-triangle';
  public static PARTY:                       IllustrativeIcon & 'party'                       = 'party';
  public static EASTER:                      IllustrativeIcon & 'easter'                      = 'easter';

  public static get values(): IllustrativeIcon[] {
    return [
      IllustrativeIcons.ALCOHOLIC_DRINK,
      IllustrativeIcons.BOWL,
      IllustrativeIcons.CALENDAR,
      IllustrativeIcons.CANDY_CANE,
      IllustrativeIcons.CART,
      IllustrativeIcons.CHAT,
      IllustrativeIcons.CUPCAKE,
      IllustrativeIcons.DELIVERY,
      IllustrativeIcons.DIETARY,
      IllustrativeIcons.GROUP,
      IllustrativeIcons.GROUP_LINK,
      IllustrativeIcons.HAPPY,
      IllustrativeIcons.HEART,
      IllustrativeIcons.ICE_CREAM,
      IllustrativeIcons.INDIVIDUAL_CHOICE,
      IllustrativeIcons.USER_CONFIRMED,
      IllustrativeIcons.MAP,
      IllustrativeIcons.MEAL_PLAN,
      IllustrativeIcons.MEAL_PLAN_SIMPLE,
      IllustrativeIcons.INDIVIDUAL_CHOICE_ICON,
      IllustrativeIcons.INDIVIDUAL_CHOICE_ICON_DARK,
      IllustrativeIcons.MONEY,
      IllustrativeIcons.NEW,
      IllustrativeIcons.RATING,
      IllustrativeIcons.FLIP,
      IllustrativeIcons.SAD,
      IllustrativeIcons.SHARE,
      IllustrativeIcons.SHOP,
      IllustrativeIcons.SUMMER,
      IllustrativeIcons.PRESENT,
      IllustrativeIcons.QUESTION,
      IllustrativeIcons.TARGET,
      IllustrativeIcons.TICK,
      IllustrativeIcons.TIME,
      IllustrativeIcons.USER,
      IllustrativeIcons.WARNING,
      IllustrativeIcons.WARNING_TRIANGLE,
      IllustrativeIcons.PARTY,
      IllustrativeIcons.EASTER,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type IllustrativeIconEnum = typeof IllustrativeIcons;
