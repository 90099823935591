import { mapSelectors } from '@citypantry/util';
import { VendorId } from '@citypantry/util-models';
import { getFavouriteVendorsState } from '../app.state';
import { FavouriteVendorsState } from './favourite-vendors.state';

const getFavouritedVendorIds = (state: FavouriteVendorsState): VendorId[] => state.favouritedVendorIds;

export const FavouriteVendorsSelectors = mapSelectors(getFavouriteVendorsState, {
  getFavouritedVendorIds
});
