import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FlexibleItemGroup, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-flexible-group',
  templateUrl: './flexible-group.component.html'
})
export class FlexibleGroupComponent {

  @Input()
  public itemGroup: FlexibleItemGroup;

  @Input()
  public formGroup: FormGroup;

  public moreInfoItem: SingleItem;

  constructor() {
    this.moreInfoItem = null;
  }

  public toggleMoreInfo(item: SingleItem): void {
    if (this.moreInfoItem === item) {
      this.moreInfoItem = null;
    } else {
      this.moreInfoItem = item;
    }
  }
}
