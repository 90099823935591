export type DeliverableMenuErrorReason =
  'no_menu_scheduled'
  | 'cp_on_holiday'
  | 'vendor_on_holiday'
  | 'vendor_not_approved'
  | 'vendor_hidden'
  | 'date_in_past';

export class DeliverableMenuErrorReasons {
  public static NO_MENU_SCHEDULED: DeliverableMenuErrorReason = 'no_menu_scheduled';
  public static CP_ON_HOLIDAY: DeliverableMenuErrorReason = 'cp_on_holiday';
  public static VENDOR_ON_HOLIDAY: DeliverableMenuErrorReason = 'vendor_on_holiday';
  public static VENDOR_NOT_APPROVED: DeliverableMenuErrorReason = 'vendor_not_approved';
  public static VENDOR_HIDDEN: DeliverableMenuErrorReason = 'vendor_hidden';
  public static DATE_IN_PAST: DeliverableMenuErrorReason = 'date_in_past';

  public static get values(): DeliverableMenuErrorReason[] {
    return [
      DeliverableMenuErrorReasons.NO_MENU_SCHEDULED,
      DeliverableMenuErrorReasons.CP_ON_HOLIDAY,
      DeliverableMenuErrorReasons.VENDOR_ON_HOLIDAY,
      DeliverableMenuErrorReasons.VENDOR_NOT_APPROVED,
      DeliverableMenuErrorReasons.VENDOR_HIDDEN,
      DeliverableMenuErrorReasons.DATE_IN_PAST,
    ];
  }
}

export type DeliverableMenuErrorReasonEnum = typeof DeliverableMenuErrorReasons;
