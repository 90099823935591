import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppState } from '@citypantry/state';
import { getSimpleUrl } from '@citypantry/util';
import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MetaModule as ngxMetaModule } from '@ngx-meta/core';
import { CanonicalTagService } from './canonical-tag.service';
import { MetaActions } from './state/meta.actions';
import { MetaEffects } from './state/meta.effects';

export interface MetaData {
  title: string;
  description?: string;
  image?: string;
}

@NgModule({
  imports: [
    CommonModule,
    ngxMetaModule.forRoot(),
    EffectsModule.forFeature([
      MetaEffects
    ]),
  ],
  providers: [
    CanonicalTagService
  ]
})
export class MetaModule {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
  ) {
    // No need to unsubscribe as module is never destroyed, is loaded to memory once
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = this.activatedRoute.snapshot.firstChild;
        while (route.firstChild) {
          route = route.firstChild;
        }

        if (route.outlet === 'primary' && route.data['meta'] && route.data['meta'].title) {
          const meta: MetaData = {
            title: route.data['meta'].title,
            description: route.data['meta'].description,
            image: route.data['meta'].image
          };
          this.store.dispatch(MetaActions.navigatedToRouteWithMeta(meta));
        } else {
          this.store.dispatch(MetaActions.navigatedToRouteWithoutMeta());
        }

        const canonicalPath: string | null = route.data['canonicalPath'] && getSimpleUrl(route.data['canonicalPath']) || null;

        this.store.dispatch(MetaActions.setCanonicalUrl({ path: canonicalPath }));
      }
    });
  }
}
