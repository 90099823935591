import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatWidgetService } from '@citypantry/shared-chat-widget';
import { OrderStatuses, OrderTracking, TrackingViews } from '@citypantry/util-models';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-tracking-progress',
  templateUrl: './tracking-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingProgressComponent {

  @Input()
  public trackingForOrders: OrderTracking[];

  @Input()
  public anonymous: boolean = true;

  @Input()
  public hasWorryFreePeriodEndDatePassed?: boolean;

  @Input()
  public isIndividualChoice: boolean = false;

  @Input()
  public choiceOpenTime: Moment | null;

  @Input()
  public choiceDeadline: Moment;

  @Input()
  public isPantryPackageOrder?: boolean = false;

  @Input()
  public cannotLeaveOrderFeedback: boolean;

  @Input()
  public isCustomer: boolean;

  @Output()
  public editOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  public cancelOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  public colleagueOrders: EventEmitter<void> = new EventEmitter();

  @Output()
  public trackOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  public leaveFeedback: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public openFeedbackModal: EventEmitter<void> = new EventEmitter();

  public feedbackSent: boolean = false;

  constructor(private chatWidgetService: ChatWidgetService) {}

  public hasBeenPlaced(): boolean {
    // IC orders in the same IC order group share the same placedDate.
    return !!this.trackingForOrders[0].placedDate;
  }

  public hasChoiceBeenOpened(): boolean {
    // The property choiceOpenTime of an individual choice order group is set null when that point in time is passed.
    return this.isIndividualChoice && (this.choiceOpenTime < moment.tz('Europe/London') || this.choiceOpenTime === null);
  }

  public hasChoiceBeenClosed(): boolean {
    return this.isIndividualChoice && this.choiceDeadline < moment.tz('Europe/London');
  }

  public hasBeenFinalised(): boolean {
    // IC orders in the same IC order group share the same finalisationDate.
    return !!this.trackingForOrders[0].finalisationDate && this.trackingForOrders[0].finalisationDate < moment.tz('Europe/London');
  }

  public isPlacedStepComplete(): boolean {
    return this.isIndividualChoice ? this.hasChoiceBeenOpened() : this.hasBeenFinalised();
  }

  public hasBeenOutForDelivery(): boolean {
    // In an IC order group, if a single order is OFD, we want to show the overall order group is OFD.
    return this.trackingForOrders.some((orderTracking) => {
      if (this.orderSupportsTracking(orderTracking)) {
        // If there is no leftKitchenDate but already an actualDeliveryDate, the order is delivered.
        return !!orderTracking.leftKitchenDate || this.hasBeenDelivered(orderTracking);
      } else {
        return orderTracking.deliveryWindow.start < moment.tz('Europe/London').add(1, 'hour');
      }
    });
  }

  public hasBeenDelivered(orderTracking?: OrderTracking): boolean {
    if (orderTracking) {
      return !!orderTracking.actualDeliveryDate;
    } else {
      return this.trackingForOrders.every((order) => !!order.actualDeliveryDate);
    }
  }

  public canLeaveOrderFeedback(): boolean {
    return !this.anonymous && this.hasBeenDelivered() && !this.cannotLeaveOrderFeedback;
  }

  public orderSupportsTracking(orderTracking: OrderTracking): boolean {
    return orderTracking.deliveryTracking.view !== TrackingViews.TRACKING_NOT_AVAILABLE;
  }

  public orderGroupSupportsTracking(): boolean {
    return this.trackingForOrders.some((order) => order.deliveryTracking.view !== TrackingViews.TRACKING_NOT_AVAILABLE);
  }

  public isTrackingAvailableSoon(orderTracking: OrderTracking): boolean {
    return orderTracking.deliveryTracking.view === TrackingViews.TRACKING_AVAILABLE_SOON;
  }

  public isSingleVendor(): boolean {
    return this.trackingForOrders.length === 1;
  }

  public isCancelled(): boolean {
    return this.trackingForOrders.every((tracking: OrderTracking) => tracking.status === OrderStatuses.CANCELLED);
  }

  public allowEditing(): boolean {
    return !this.isIndividualChoice && this.isCustomer;
  }

  public onColleagueOrdersClick(): void {
    this.colleagueOrders.emit();
  }

  public onEditOrderClick(): void {
    this.editOrder.emit();
  }

  public onCancelOrderClick(): void {
    this.cancelOrder.emit();
  }

  public onTrackYourOrderClick(): void {
    this.trackOrder.emit();
  }

  public onFeedbackClick(smile: boolean): void {
    this.feedbackSent = true;
    this.leaveFeedback.emit(smile);
  }

  public onLeaveFeedbackButtonClick(): void {
    this.openFeedbackModal.emit();
  }

  public openZopimChat(): void {
    this.chatWidgetService.openZopimChat();
  }
}
