/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type EaterRefundReason = 'MISSING_ITEMS' | 'OTHER' | 'WRONG_MEAL' | 'BAD_PACKAGING';

export class EaterRefundReasons {
  public static MISSING_ITEMS: EaterRefundReason & 'MISSING_ITEMS' = 'MISSING_ITEMS';
  public static WRONG_MEAL:    EaterRefundReason & 'WRONG_MEAL'    = 'WRONG_MEAL';
  public static BAD_PACKAGING: EaterRefundReason & 'BAD_PACKAGING' = 'BAD_PACKAGING';
  public static OTHER:         EaterRefundReason & 'OTHER'         = 'OTHER';

  public static get values(): EaterRefundReason[] {
    return [
      EaterRefundReasons.MISSING_ITEMS,
      EaterRefundReasons.WRONG_MEAL,
      EaterRefundReasons.BAD_PACKAGING,
      EaterRefundReasons.OTHER,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type EaterRefundReasonEnum = typeof EaterRefundReasons;
