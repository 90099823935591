import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { MetaData } from './meta.module';
import { MetaActions } from './state/meta.actions';

@Injectable({
  providedIn: 'root',
})
export class MetaService {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  public setMeta(meta: MetaData): void {
    this.store.dispatch(MetaActions.setMeta(meta));
  }

  public setCanonicalUrl(canonicalUrl: { path: string | null }): void {
    this.store.dispatch(MetaActions.setCanonicalUrl(canonicalUrl));
  }
}
