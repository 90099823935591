/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type MenuContentStatus = 'active' | 'draft' | 'pending_approval';

export class MenuContentStatuses {
  public static ACTIVE:           MenuContentStatus & 'active'         = 'active';
  public static DRAFT:            MenuContentStatus & 'draft'            = 'draft';
  public static PENDING_APPROVAL: MenuContentStatus & 'pending_approval' = 'pending_approval';

  public static get values(): MenuContentStatus[] {
    return [
      MenuContentStatuses.ACTIVE,
      MenuContentStatuses.DRAFT,
      MenuContentStatuses.PENDING_APPROVAL,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type MenuContentStatusEnum = typeof MenuContentStatuses;
