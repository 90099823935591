export interface Feedback {
  smile: boolean | null; // null if no smile has been provided
  foodQuality: number;
  foodReasons: string[];
  deliverySmile: boolean;
  deliveryReasons: string[];
  publicComments: string | null;
  privateComments: string | null;
}

export function createFeedbackFromJson(json?: Partial<Feedback>): Feedback {
  const feedback: Partial<Feedback> = {};

  feedback.smile = typeof json.smile === 'boolean' ? json.smile : null;
  feedback.foodQuality = json.foodQuality ? json.foodQuality : null;
  feedback.foodReasons = json.foodReasons ? json.foodReasons : [];
  feedback.deliverySmile = typeof json.deliverySmile === 'boolean' ? json.deliverySmile : null;
  feedback.deliveryReasons = json.deliveryReasons ? json.deliveryReasons : [];
  feedback.publicComments = json.publicComments ? json.publicComments : null;
  feedback.privateComments = json.privateComments ? json.privateComments : null;

  return feedback as Feedback;
}
