<div
  class="item-panel"
  [class.item-panel--with-image]="getImage()"
  [attr.data-item-id]="item.id"
  analyticsId="custom-item"
  test-id="root"
>

  <div
    *ngIf="disabled || (selectionEnabled && !item.selected)"
    class="item-panel__overlay"
    test-id="disabledOverlay"
  >
    <div
      *ngIf="deliverabilityProblems.length > 0"
      class="panel panel--white panel--shadow"
      test-id="problemsPanel"
    >
      <div
        class="alert alert--info alert--transparent"
        *ngFor="let problem of deliverabilityProblems"
      >{{ problem | translateDeliverabilityProblemToMessage }}</div>
    </div>
  </div>

  <app-menu-item-image
    *ngIf="getImage()"
    [images]="item.images"
    [itemType]="item.type"
    (click)="onClickImage()"
    test-id="itemImage"
  ></app-menu-item-image>

  <div
    class="item-panel__title-and-description"
    test-id="titleAndDescription"
  >
    <h3 class="item-panel__title">
      {{ item.name }}
    </h3>
    <div class="item-panel__description">
      <div
        *ngIf="shouldShowKcals()"
        test-id="kcal"
      >From {{ item.baseItemKcal }}kcal</div>
      <span>{{ item.description }}</span>
      <a
        (click)="toggleShowMoreDetails()"
        class="item-panel__info-text-button"
        analyticsId="more-info"
        test-id="moreInfo"
        [analyticsData]="{ itemId: item.id }"
      >
        {{ showMoreDetails ? 'Less info' : 'More info' }}
      </a>
    </div>
  </div>

  <div
    *ngIf="showMoreDetails"
    class="item-panel__additional-info"
    test-id="additionalInfo"
  >
    <app-additional-item-info
      [item]="item"
      (close)="toggleShowMoreDetails()"
    ></app-additional-item-info>
  </div>

  <div
    class="item-panel__meta"
    test-id="meta"
  >
    <div
      *ngIf="item.ageRestricted || hasPossibleDietaries()"
      class="item-panel__info-indicator"
    >
      <span
        *ngIf="item.ageRestricted"
        class="icon icon--medium"
        aria-label="Age restricted"
        test-id="ageRestricted"
      >age_restricted</span>
      <app-dietaries
        *ngIf="hasPossibleDietaries()"
        class="vertical-separator"
        [dietaries]="this.item.possibleDietaries"
        test-id="dietaries"
      ></app-dietaries>
    </div>
    <div
      *ngIf="item.portionSize > 1"
      class="item-panel__info-indicator"
    >
      <app-feeds-quantity
        [quantity]="item.portionSize"
      ></app-feeds-quantity>
    </div>
    <div
      *ngIf="item.minimumOrderQuantity > 1"
      class="item-panel__info-indicator"
    >
      <app-minimum-order-quantity
        [minimumQuantity]="item.minimumOrderQuantity"
        test-id="minimumOrderQuantity"
      ></app-minimum-order-quantity>
    </div>
    <div class="item-panel__info-indicator" *ngIf="item.servingStyle">
      <app-serving-style
        [servingStyle]="item.servingStyle"
        test-id="servingStyle"
      ></app-serving-style>
    </div>
    <div class="item-panel__info-indicator" *ngIf="item.ecoFriendlyPackaging">
      <app-eco-friendly-packaging
        test-id="ecoFriendlyPackaging"
      ></app-eco-friendly-packaging>
    </div>
  </div>
  <div
    class="item-panel__price-portion text-right"
    test-id="pricePortion"
  >
    <ng-container *ngIf="!hidePrices">
      <p
        class="item-panel__price"
        test-id="itemPanelPrice"
      >
        {{ item.price | currency:item.currencyIsoCode }}
      </p>
      <p
        *ngIf="item.portionSize > 1"
        class="item-panel__portion"
        test-id="portionSize"
      >
        {{ calculatePricePerPerson() | currency:item.currencyIsoCode }} per person
      </p>
      <p
        *ngIf="!zeroVatRateTypes.includes(item.vatRateType)"
        class="item-panel__price-info"
        test-id="priceInfo"
      >
        (incl. VAT)
      </p>
    </ng-container>
    <div class="item-panel__actions">
      <ng-content></ng-content>
    </div>
  </div>
</div>
