import { createAction, props, union } from '@ngrx/store';

export const VendorNotificationsActions = {
  loadPendingOrdersCount: createAction(
    '[Vendor Notifications] Load pending orders count',
  ),
  pendingOrdersCountLoaded: createAction(
    '[Vendor Notifications] Pending orders count loaded',
    props<{count: number, timestamp: string}>(),
  ),
  requestToLoadPendingOrdersCount: createAction(
    '[Vendor Notifications] Request to load pending orders count',
  ),
};

const all = union(VendorNotificationsActions);
export type VendorNotificationAction = typeof all;
