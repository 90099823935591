<div
  class="search-cant-find-what-your-looking-for p-large mx-medium"
  analyticsId="cant-find-what-you-want"
>
  <app-in-viewport
    analyticsId="top-of-component"
  ></app-in-viewport>
  <div class="search-cant-find-what-your-looking-for__icon-wrapper mr-none mr-md-large">
    <div class="illustrative-icon illustrative-icon--shop"></div>
  </div>
  <div class="search-cant-find-what-your-looking-for__content-wrapper">
    <h2 class="header">Can't find what you're looking for?</h2>
    <p class="body-text">
      We’re adding new restaurants and caterers to Just Eat for Business every week, but if you’d like to find the best food
      in your area, our Food Gurus are here to help.
    </p>
    <div class="search-cant-find-what-your-looking-for__button-wrapper mt-large ">
      <a
        class="button button--secondary"
        href="/l/-temporary-slug-67e4f8e0-ef74-4729-8d38-75263a0c9982?hs_preview=XrSVbvgh-13678195666"
        target="_blank"
        analyticsId="suggest-a-restaurant"
      >
        Suggest a restaurant
      </a>
    </div>
  </div>
</div>
