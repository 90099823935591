import { trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { slideInOut } from '@citypantry/util-animations';
import { MinorCurrency } from '@citypantry/util-models';

@Component({
  selector: 'app-cart-total',
  templateUrl: './cart-total.component.html',
  animations: [
    trigger('slideInOut', slideInOut)
  ]
})
export class CartTotalComponent {

  @Input()
  public amount: MinorCurrency | null;

  @Input()
  public priceChange: MinorCurrency;

  // Text to show instead of "total"
  @Input()
  public totalDisplayText: string | null;

  @Input()
  public currencyCode: string;

  public get hasIncrease(): boolean {
    return this.priceChange > 0;
  }

  // Using two methods ensures null-safety, unlike `!==0`
  public get hasDecrease(): boolean {
    return this.priceChange < 0;
  }

  public get priceChangeAmount(): MinorCurrency {
    return Math.abs(this.priceChange);
  }
}
