<!-- start -->
<ul
  class="pagination pagination--full-width"
  test-id="pagination"
>
  <li
    class="pagination__page pagination__page--prev"
    [class.pagination__page--disabled]="!hasPreviousPage"
    analyticsId="previous-page"
    [analyticsData]="{ page: page }"
    test-id="previousListElement"
    >
    <a
      class="pagination__link"
      (click)="onPageChanged(page - 1)"
      test-id="previousLink"
    >Previous</a>
  </li>
  <li
    *ngIf="!isFirstPage"
    class="pagination__page"
    analyticsId="first-page"
    [analyticsData]="{ page: 1 }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(1)"
      test-id="firstPageLink"
    >1</a>
  </li>
  <li
    *ngIf="page > 4"
    class="pagination__page pagination__page--disabled"
    test-id="firstEllipsis"
  >
    <span
      class="pagination__link"
    >&hellip;</span>
  </li>
  <!-- two before -->
  <li
    *ngIf="page > 3"
    class="pagination__page"
    analyticsId="jump-page"
    [analyticsData]="{ page: page }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(page - 2)"
      test-id="pageMinusTwoLink"
    >
      {{ page - 2 }}
    </a>
  </li>
  <li
    *ngIf="page > 2"
    class="pagination__page"
    analyticsId="jump-page"
    [analyticsData]="{ page: page }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(page - 1)"
      test-id="pageMinusOneLink"
    >
      {{ page - 1 }}
    </a>
  </li>
  <!-- current -->
  <li
    class="pagination__page pagination__page--active"
    test-id="currentPage"
  >
    <span
      class="pagination__link"
    >
      {{ page }}
    </span>
  </li>
  <!-- two after -->
  <li
    *ngIf="page + 1 < lastPage"
    class="pagination__page"
    analyticsId="jump-page"
    [analyticsData]="{ page: page }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(page + 1)"
      test-id="pagePlusOneLink"
    >
      {{ page + 1 }}
    </a>
  </li>
  <li
    *ngIf="page + 2 < lastPage"
    class="pagination__page"
    analyticsId="jump-page"
    [analyticsData]="{ page: page }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(page + 2)"
      test-id="pagePlusTwoLink"
    >
      {{ page + 2 }}
    </a>
  </li>
  <li
    *ngIf="page + 3 < lastPage"
    class="pagination__page pagination__page--disabled"
    test-id="secondEllipsis"
  >
    <span
      class="pagination__link"
    >&hellip;</span>
  </li>
  <!-- last -->
  <li
    *ngIf="!isLastPage"
    class="pagination__page"
    analyticsId="last-page"
    [analyticsData]="{ page: lastPage }"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(lastPage)"
      test-id="lastPageLink"
    >
      {{ lastPage }}
    </a>
  </li>
  <li
    class="pagination__page pagination__page--next"
    [class.pagination__page--disabled]="!hasNextPage"
    analyticsId="next-page"
    [analyticsData]="{ page: page }"
    test-id="nextListElement"
  >
    <a
      class="pagination__link"
      (click)="onPageChanged(page + 1)"
      test-id="nextLink"
    >Next</a>
  </li>
</ul>
