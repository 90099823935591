import moment, { Moment } from 'moment';
import {
  createIndividualChoiceEligibilityFromJson,
  IndividualChoiceEligibility
} from '../individual-choice/individual-choice-order-eligibility.model';
import { VendorLocationCapacityStatus } from '../vendor/vendor-location-capacity-status.enum';
import { VendorLocationId, VendorLocationSlug } from '../vendor/vendor-location.model';
import { createVendorFromJson, Vendor } from '../vendor/vendor.model';
import { createItemDeliverabilityFromJson, ItemDeliverability } from './items/item-deliverability.model';
import { createMenuContentFromJson, MenuContent } from './menu.model';

export interface ClosestVendorLocation {
  distance: number;
  postcode: string | null;
  phoneNumber: string | null;
}

export interface SelectedVendorLocation {
  id: VendorLocationId;
  name: string;
  slug: VendorLocationSlug;
  distance: number;
  postcode: string | null;
  phoneNumber: string | null;
  remainingCapacity: number;
  capacityStatus: VendorLocationCapacityStatus;
}

export interface DeliverableMenu {
  menuId: string;
  vendor: Vendor;
  content: MenuContent;
  date: Moment;
  minDistance: number | null; // Null when postcode === null
  itemDeliverabilities: ItemDeliverability[];
  minOrderValue: number;
  individualChoiceEligibility: IndividualChoiceEligibility | null;
  minRequiredNoticeHours: number;
  weWorkFriendly: boolean;
  closestVendorLocation: ClosestVendorLocation | null;
  vendorLocation: SelectedVendorLocation | null;
}

export function createDeliverableMenuFromJson(json: Partial<DeliverableMenu>): DeliverableMenu {
  return {
    menuId: json.menuId,
    vendor: createVendorFromJson(json.vendor),
    content: createMenuContentFromJson(json.content),
    date: moment.tz(json.date, 'Europe/London'), // should never be null
    minDistance: typeof json.minDistance === 'number' ? json.minDistance : null,
    itemDeliverabilities: (json.itemDeliverabilities || []).map(createItemDeliverabilityFromJson),
    minOrderValue: json.minOrderValue || 0,
    individualChoiceEligibility: json.individualChoiceEligibility
      ? createIndividualChoiceEligibilityFromJson(json.individualChoiceEligibility) : null,
    minRequiredNoticeHours: json.minRequiredNoticeHours,
    weWorkFriendly: json.weWorkFriendly || false,
    closestVendorLocation: json.closestVendorLocation || null,
    vendorLocation: createSelectedVendorLocationFromJson(json.vendorLocation),
  };
}

export function createSelectedVendorLocationFromJson(json: Partial<SelectedVendorLocation>): SelectedVendorLocation {
  return json && {
    id: json.id,
    distance: json.distance,
    name: json.name,
    slug: json.slug,
    postcode: json.postcode,
    phoneNumber: json.phoneNumber,
    remainingCapacity: json.remainingCapacity,
    capacityStatus: json.capacityStatus,
  };
}
