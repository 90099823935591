import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

const DEFAULT_FORMAT = 'DD/MM/YYYY HH:mm';

@Pipe({
  name: 'dateInEuropeLondon'
})
export class DateInEuropeLondonPipe implements PipeTransform {

  public transform(date: moment.MomentInput, format: string = DEFAULT_FORMAT): any {
    if (!date) {
      return null;
    }
    return moment(date).tz('Europe/London').format(format);
  }
}
