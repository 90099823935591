import { createAction, props, union } from '@ngrx/store';
import { GlobalDialogSettings } from './global-dialog-settings.model';

export const GlobalDialogActions = {
  showDialog: createAction(
    '[Global Dialog Service] Show Global Dialog',
    props<{ settings: GlobalDialogSettings }>(),
  ),
  completeDialog: createAction(
    '[Global Dialog Component] Complete Global Dialog',
  ),
};

const all = union(GlobalDialogActions);
export type GlobalDialogAction = typeof all;
