<form
  *ngIf="!hidden"
  class="search-bar"
  [@slide]
  [formGroup]="form"
  action=""
  test-id="searchBar"
>
  <div class="search-bar__wrapper">
    <div class="search-bar__content">
      <div
        class="search-bar__title"
        test-id="title"
      >
        Show me the {{ popUpMode ? 'popups' : 'food' }}&hellip;
      </div>
      <div class="search-bar__filter">
        <app-compact-input
          *ngIf="canEnterPostcode && locationOptions?.length"
          type="choice-text"
          dropdownLabel="Select Location"
          textLabel="New Postcode"
          switchOptionLabel="New Postcode"
          [options]="locationOptions"
          icon="location"
          [control]="form.get('postcodeOrLocation')"
          test-id="postcode"
        ></app-compact-input>
        <app-compact-input
          *ngIf="!canEnterPostcode && locationOptions?.length"
          type="choice"
          label="Select Location"
          [options]="locationOptions"
          icon="location"
          [control]="form.get('postcodeOrLocation')"
          test-id="postcode"
        ></app-compact-input>
        <app-compact-input
          *ngIf="!locationOptions?.length"
          type="text"
          label="Postcode"
          icon="location"
          [control]="form.get('postcodeOrLocation')"
          test-id="postcode"
        ></app-compact-input>
      </div>
      <div class="search-bar__filter">
        <app-compact-input
          label="When?"
          icon="calendar"
          type="date"
          [control]="form.get('date')"
          test-id="date"
        ></app-compact-input>
      </div>
      <div class="search-bar__filter">
        <app-compact-input
          label="What time?"
          icon="clock"
          type="choice"
          [options]="deliveryTimeOptions"
          [control]="form.get('time')"
          test-id="time"
        ></app-compact-input>
      </div>
      <div class="search-bar__search">
        <label class="search-input">
          <input
            #search
            type="search"
            class="search-input__input"
            placeholder="Search for a dish or restaurant"
            (enter)="search.blur()"
            (blur)="onSearchSubmit(search.value)"
            [value]="request.text || ''"
            analyticsId="search-query-input"
            test-id="search"
            e2e-test-id="vendorSearchInput"
          >
          <button
            class="search-input__button"
            type="button"
            (click)="search.blur()"
            test-id="submitSearch"
            e2e-test-id="vendorSearchButton"
          >
            <span class="text-hide">Search</span>
          </button>
        </label>
      </div>
    </div>
  </div>
</form>
