/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type HubspotFormId =
  'f4c74de0-5412-4a60-b76c-387fa1a02ef7'
  | '4b9bc612-3747-4aaa-8842-d1c5737a4a08';

export class HubspotFormIds {
  public static DEFAULT_LEAD_CAPTURE:  HubspotFormId & 'f4c74de0-5412-4a60-b76c-387fa1a02ef7' = 'f4c74de0-5412-4a60-b76c-387fa1a02ef7';
  public static OUT_OF_REGION_CAPTURE: HubspotFormId & '4b9bc612-3747-4aaa-8842-d1c5737a4a08' = '4b9bc612-3747-4aaa-8842-d1c5737a4a08';

  public static get values(): HubspotFormId[] {
    return [
      HubspotFormIds.DEFAULT_LEAD_CAPTURE,
      HubspotFormIds.OUT_OF_REGION_CAPTURE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type HubspotFormIdEnum = typeof HubspotFormIds;
