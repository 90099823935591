import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delivery-questions',
  template: `
    <h2 class="header mb-large">Any questions?</h2>
    <p class="text-banner">Please check our
      <a
        class="link text-weight-medium"
        href="https://help.citypantry.com/hc/en-us/sections/360003929313-Ordering-Editing-Cancellations"
        target="_blank"
        analyticsId="order-faqs-questions"
      >Order FAQs</a> or
      <a
        class="link text-weight-medium"
        href="https://help.citypantry.com/hc/en-us/sections/360003929293-Delivery"
        target="_blank"
        analyticsId="delivery-faqs-questions"
      >Delivery FAQs</a>
    </p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryQuestionsComponent {

  @Output()
  public openChat: EventEmitter<void> = new EventEmitter<void>();

  public openLinkClicked(): void {
    this.openChat.emit();
  }
}
