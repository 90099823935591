import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

// From https://gist.github.com/rob-balfre/0c989ed6a4e131a09a684dabb649e0cd
// via https://github.com/chliebel/angular2-click-outside/issues/5

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {
  @Output()
  public clickOutside: EventEmitter<MouseEvent> = new EventEmitter();

  private isInitialised: boolean;

  constructor(
    private _elementRef: ElementRef
  ) {
    this.isInitialised = false;
  }

  public ngOnInit(): void {
    setTimeout(() => this.isInitialised = true, 0);
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick($event: MouseEvent, targetElement: HTMLElement): void {
    if (!this.isInitialised) {
      // Sometimes an element with a "click outside" handler is shown on click.
      // Prevent the handler from firing on a click that caused it to exist; otherwise this event would be fired immediately on create.
      return;
    }

    const isClickedInside = this._elementRef.nativeElement.contains(targetElement);

    if (!isClickedInside) {
      this.clickOutside.emit($event);
    }
  }
}
