/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type VendorFlag =
  'individual-choice'
  | 'same-day-choice'
  | 'one-day-choice'
  | 'two-days-choice'
  | 'eco-friendly-packaging'
  | 'popups'
  | 'buffet'
  | 'exclusive-to-cp'
  | 'good-item-labelling'
  | 'same-day-ordering'
  | 'wework-friendly';

export class VendorFlags {
  public static INDIVIDUAL_CHOICE:      VendorFlag & 'individual-choice'      = 'individual-choice';
  public static SAME_DAY_CHOICE:        VendorFlag & 'same-day-choice'        = 'same-day-choice';
  public static ONE_DAY_CHOICE:         VendorFlag & 'one-day-choice'         = 'one-day-choice';
  public static TWO_DAYS_CHOICE:        VendorFlag & 'two-days-choice'        = 'two-days-choice';
  public static ECO_FRIENDLY_PACKAGING: VendorFlag & 'eco-friendly-packaging' = 'eco-friendly-packaging';
  public static POPUPS:                 VendorFlag & 'popups'                 = 'popups';
  public static BUFFET:                 VendorFlag & 'buffet'                 = 'buffet';
  public static EXCLUSIVE_TO_CP:        VendorFlag & 'exclusive-to-cp'        = 'exclusive-to-cp';
  public static GOOD_ITEM_LABELLING:    VendorFlag & 'good-item-labelling'    = 'good-item-labelling';
  public static SAME_DAY_ORDERING:      VendorFlag & 'same-day-ordering'      = 'same-day-ordering';
  public static WEWORK_FRIENDLY:        VendorFlag & 'wework-friendly'        = 'wework-friendly';

  public static get values(): VendorFlag[] {
    return [
      VendorFlags.INDIVIDUAL_CHOICE,
      VendorFlags.SAME_DAY_CHOICE,
      VendorFlags.ONE_DAY_CHOICE,
      VendorFlags.TWO_DAYS_CHOICE,
      VendorFlags.ECO_FRIENDLY_PACKAGING,
      VendorFlags.POPUPS,
      VendorFlags.BUFFET,
      VendorFlags.EXCLUSIVE_TO_CP,
      VendorFlags.GOOD_ITEM_LABELLING,
      VendorFlags.SAME_DAY_ORDERING,
      VendorFlags.WEWORK_FRIENDLY,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type VendorFlagEnum = typeof VendorFlags;
