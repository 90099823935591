<ul class="header-navigation__menu">
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/what-we-do', label: 'What we do', isSecondary: true, analyticsId: 'what-we-do' }"></ng-container>
  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/food-partner-sign-up', label: 'Sell food with us', analyticsId: 'vendor-signup' }"></ng-container>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: 'https://citypantrysupport.zendesk.com', label: 'Support', analyticsId: 'supportLink' }"></ng-container>

  <app-header-dropdown
    [triggerLabel]="'Get Inspired'"
    [triggerAnalyticsId]="'inspiration'"
    test-id="dropdown"
  >
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/pantry-packages', label: 'Pantry Packages', isSecondary: true, analyticsId: 'pantry-packages' }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/just-eat-gift-cards', label: 'Just Eat Gift Cards', isSecondary: true, analyticsId: 'just-eat-gift-cards' }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/pop-ups', label: 'Pop-ups', isSecondary: true, analyticsId: 'pop-ups' }"></ng-container>
    <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/blog', label: 'Blog', isSecondary: true, analyticsId: 'blog' }"></ng-container>
  </app-header-dropdown>

  <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="externalLinkTemplate; context: { externalHref: '/l/teams-at-home', label: 'TEAMS@HOME', analyticsId: 'teams-at-home' }"></ng-container>
  <ng-container *ngTemplateOutlet="searchFoodButtonTemplate"></ng-container>

  <!-- elements below are styled as buttons, so they don't use the routerLink/externalLinkTemplate templates -->
  <li
    *ngIf="!isLoggedIn; else logout"
    class="header-navigation__item"
    test-id="loginLink"
  >
    <a
      href="/login"
      target="_parent"
      class="header-navigation__button button button--secondary"
      analyticsId="login"
    >Login</a>
  </li>
  <ng-template #logout>
    <li
      class="header-navigation__item"
      analyticsId="logout"
      test-id="logoutLink"
    >
      <a
        href="/logout"
        target="_parent"
        class="header-navigation__button button button--secondary"
      >Log out</a>
    </li>
  </ng-template>
</ul>
