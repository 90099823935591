import { ChoiceOpenTimeSetting, CostAllocation, PaymentTerm } from '../order';
import { NewPaymentCard, PaymentCardId } from '../payment';

export const ADD_NEW_CARD_VALUE = 'new-card';

export interface IndividualChoicePaymentDetails {
  choiceOpenTimeSetting: ChoiceOpenTimeSetting;
  costAllocation: CostAllocation;
  paymentTerm: PaymentTerm;
  card?: PaymentCardId | typeof ADD_NEW_CARD_VALUE;
  newCard?: NewPaymentCard;
  // 3D Secure will fail if attempting to make payment with threeDSecureEnrichedNonce set to null, but we allow null for easier setup
  threeDSecureEnrichedNonce: string | null;
}
