import { ensureValidEnumValue } from '@citypantry/util';
import {
  AdvancedBudgets,
  Country,
  createBudgetRangeFromJson,
  createCountryFromJson,
  createCustomerLocationFromJson,
  createSearchPreferencesFromJson,
  createSimpleCustomerLocationFromJson,
  createVendorSimpleLocationFromJson,
  CustomerId,
  CustomerLocation,
  CustomerQualification,
  JustEatVoucherSchedule,
  Promotion,
  SearchPreferences,
  SimpleCustomerLocation,
  UserId,
  VendorId,
  VendorSimpleLocation
} from '@citypantry/util-models';
import { BetaFeature, BetaFeatures } from './beta-feature.enum';
import { UserType, UserTypes } from './user-type.enum';

export interface UserProfileCustomerData {
  id: CustomerId;
  isOnMealPlan: boolean;
  companyName: string | null;
  qualification: CustomerQualification;
  isSubsidisedChoiceEnabled: boolean;
  numberOfOrders: null | number;
  isAdvancedBudgetingEnabled: boolean;
  advancedBudgets: AdvancedBudgets;
  isEligibleForEaterChoiceOpenTimes: boolean;
  totalEaters: number;
  isPayOnAccountEnabled: boolean;
  hiddenBudgetVendors: VendorId[];
  isColleagueGroupsEnabled: boolean;
}

export interface UserProfileVendorData {
  id: VendorId;
  name: string;
  avatarUrl: string | ''; // Backend returns empty string if no image is set
  performanceId: string;
  deliveryNoticeHours: number | null; // TODO CPD-3229 move to the builder and remove from here
  locations: VendorSimpleLocation[]; // TODO CPD-3229 move to the builder and remove from here
  country: Country;
  isIndividualChoiceEnabled: boolean;
}

export interface UserProfileEaterData {
  locations: SimpleCustomerLocation[];
  isJustEatVouchersEnabled: boolean;
  justEatVoucherSchedule: JustEatVoucherSchedule;
}

// The core user profile fields that stay the same across all the different types of users
export interface BaseUserProfile {
  id: UserId;
  humanId: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  betaFeatures: BetaFeature[];
}

// Represents the user profile returned from the /users/me/profile endpoint
export interface UserProfile extends BaseUserProfile {
  userTypes: UserType[];
  isSudo: boolean; // Whether user is currently masqueraded
  searchPreferences: SearchPreferences;
  numberOfOrders: null | number;

  customer: null | UserProfileCustomerData;
  promotions: Promotion[];
  vendor: null | UserProfileVendorData;
  eater: null | UserProfileEaterData;
  favouriteVendors: VendorId[];
  isSsoUser: boolean;
  lostbarToken: string | null;
  locations: CustomerLocation[];
  isCartsAdditionalReferenceRequired: boolean;
  poNumberRequired: boolean;
  isEligibleForIndividualChoice: boolean;
  primaryOperationalRegion: string;
}

export function createUserProfileFromJson(json: Partial<UserProfile>): UserProfile {
  const profile: Partial<UserProfile> = {};

  profile.id = json.id;
  profile.firstName = json.firstName;
  profile.lastName = json.lastName;
  profile.name = json.name;
  profile.email = json.email;
  profile.userTypes = (json.userTypes || []).map(ensureValidEnumValue.bind(null, UserTypes));
  profile.betaFeatures = (json.betaFeatures || []).map(ensureValidEnumValue.bind(null, BetaFeatures));
  profile.isSudo = !!json.isSudo;
  profile.promotions = (json.promotions || []);
  profile.humanId = json.humanId;
  profile.isSsoUser = json.isSsoUser;
  profile.searchPreferences = createSearchPreferencesFromJson(
    // TODO CPD-6841: remove json.customer.isSubsidisedChoiceEnabled usage and use actual preferences
    json.searchPreferences, !!(json.customer && json.customer.isSubsidisedChoiceEnabled)
  );
  profile.favouriteVendors = (json.favouriteVendors || []);
  profile.lostbarToken = json.lostbarToken;
  profile.isEligibleForIndividualChoice = !!json.isEligibleForIndividualChoice;
  profile.locations = (json.locations || []).map(createCustomerLocationFromJson);
  profile.primaryOperationalRegion = json.primaryOperationalRegion;
  profile.isCartsAdditionalReferenceRequired = !!json.isCartsAdditionalReferenceRequired;
  profile.poNumberRequired = !!json.poNumberRequired;

  if (json.customer) {
    const customer: Partial<UserProfileCustomerData> = {};

    customer.id = json.customer.id;
    customer.isOnMealPlan = !!json.customer.isOnMealPlan;
    customer.companyName = json.customer.companyName || '';
    customer.qualification = json.customer.qualification;
    customer.isSubsidisedChoiceEnabled = !!json.customer.isSubsidisedChoiceEnabled;
    customer.numberOfOrders = json.numberOfOrders;
    customer.isAdvancedBudgetingEnabled = !!json.customer.isAdvancedBudgetingEnabled;
    customer.advancedBudgets = (json.customer.advancedBudgets || []).map(createBudgetRangeFromJson);
    customer.isEligibleForEaterChoiceOpenTimes = !!json.customer.isEligibleForEaterChoiceOpenTimes;
    customer.totalEaters = json.customer.totalEaters;
    customer.isPayOnAccountEnabled = !!json.customer.isPayOnAccountEnabled;
    customer.hiddenBudgetVendors = json.customer.hiddenBudgetVendors || [];
    customer.isColleagueGroupsEnabled = json.customer.isColleagueGroupsEnabled;

    profile.customer = customer as UserProfileCustomerData;
  } else {
    profile.customer = null;
  }

  if (profile.userTypes.indexOf(UserTypes.VENDOR) >= 0 && json.vendor) {
    const vendor: Partial<UserProfileVendorData> = {};

    vendor.id = json.vendor.id;
    vendor.name = json.vendor.name || '';
    vendor.avatarUrl = json.vendor.avatarUrl || '';
    vendor.performanceId = json.vendor.performanceId;
    vendor.deliveryNoticeHours = typeof json.vendor.deliveryNoticeHours === 'number' ? json.vendor.deliveryNoticeHours : null;
    vendor.locations = (json.vendor.locations || []).map(createVendorSimpleLocationFromJson);
    vendor.country = json.vendor.country ? createCountryFromJson(json.vendor.country) : null;
    vendor.isIndividualChoiceEnabled = json.vendor.isIndividualChoiceEnabled;

    profile.vendor = vendor as UserProfileVendorData;
  } else {
    profile.vendor = null;
  }

  if (profile.userTypes.indexOf(UserTypes.EATER) >= 0 && json.eater) {
    const eater: Partial<UserProfileEaterData> = {};

    eater.locations = (json.eater.locations || []).map(createSimpleCustomerLocationFromJson);
    eater.isJustEatVouchersEnabled = !!json.eater.isJustEatVouchersEnabled;
    eater.justEatVoucherSchedule = json.eater.justEatVoucherSchedule;

    profile.eater = eater as UserProfileEaterData;
  } else {
    profile.eater = null;
  }

  return profile as UserProfile;
}
