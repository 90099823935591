import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventIdEnum,
  AnalyticsEcommerceEventIds,
} from '@citypantry/shared-analytics';
import { TypedSimpleChanges, zip } from '@citypantry/util';
import { CartManager } from '@citypantry/util-cart-manager';
import {
  CartItemBundle,
  CartItemBundleGroup,
  CartItemTypes,
  CartSingleItem,
  FixedItemGroup,
  isFlexibleItemGroup,
  ItemBundle,
  ItemGroup,
  ItemGroupTypeEnum,
  ItemGroupTypes,
  ServingStyleEnum,
  ServingStyles,
  SingleItem,
} from '@citypantry/util-models';
import { ItemBundleFormService } from '../../item-bundle/item-bundle-form.service';

export interface SubmitItemBundleModalEvent {
  quantity: number;
  cartGroups: CartItemBundleGroup[];
}
@Component({
  selector: 'app-item-bundle-modal-form',
  templateUrl: './item-bundle-modal-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemBundleModalFormComponent implements OnChanges, OnInit {

  @Input()
  public set item(bundle: ItemBundle) {
    this._itemBundle = bundle;
    this.useSimplifiedQuantity = bundle && !!bundle.groups.find(isFlexibleItemGroup);
  }
  public get item(): ItemBundle {
    return this._itemBundle;
  }

  @Input()
  public cartItem: CartItemBundle;

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public hidePrices: boolean;

  @Output()
  public submitItem: EventEmitter<SubmitItemBundleModalEvent> = new EventEmitter();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter();

  public ServingStyles: ServingStyleEnum = ServingStyles;
  public ItemGroupTypes: ItemGroupTypeEnum = ItemGroupTypes;
  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;
  public AnalyticsEcommerceEventIds: AnalyticsEcommerceEventIdEnum = AnalyticsEcommerceEventIds;

  public form: FormGroup;
  public wasSubmitted: boolean;
  public useSimplifiedQuantity: boolean;

  private _itemBundle: ItemBundle;

  constructor(
    private itemBundleFormService: ItemBundleFormService
  ) {}

  public ngOnChanges(changes: TypedSimpleChanges<ItemBundleModalFormComponent>): void {
    if (changes.cartItem || changes.item) {
      this.recreateForm();
    }
  }

  public ngOnInit(): void {
    this.recreateForm();
  }

  public getBundleCount(): number {
    return parseInt(this.form.get('quantity').value, 10);
  }

  public getPlatterFeedsQuantity(): number {
    if (!this.item) {
      return 0;
    }
    const quantity = this.form.value.quantity;
    if (this.item.servingStyle === this.ServingStyles.PLATTER) {
      return quantity * this.item.portionSize;
    } else {
      return quantity;
    }
  }

  public calculateItemTotal(): number {
    const formValue = this.form.value;
    const cartGroups = this.createCartGroupsFromItemGroups(formValue.itemGroups);
    return CartManager.calculateItemBundlePrice(this.item, formValue.quantity, cartGroups);
  }

  public shouldShowKcalTotal(): boolean {
    for (const group of this.item.groups) {
      for (const item of group.items) {
        if (item.kcal !== null && item.kcal >= 0) {
          return true;
        }
      }
    }
    return false;
  }

  public calculateKcalTotal(): number {
    let fixedItemGroupTotalKcal = 0;
    this.item.groups.forEach((itemGroup: ItemGroup) => {
      if (itemGroup.type === ItemGroupTypes.FIXED_GROUP) {
        const fixedItemGroup = itemGroup as FixedItemGroup;
        fixedItemGroup.items.forEach((item) => {
          const quantity = fixedItemGroup.quantities.find((quantityMap) => quantityMap.itemId === item.id)?.quantity || 1;

          fixedItemGroupTotalKcal += item.kcal * quantity;
        });
      }
    });

    let choiceGroupTotalKcal = 0;
    let upgradeAndFlexibleGroupsTotalKcal = 0;
    const cartGroups = this.createCartGroupsFromItemGroups(this.form.value.itemGroups);
    cartGroups.forEach((cartGroup) => {
      if (cartGroup.type === ItemGroupTypes.CHOICE_GROUP) {
        choiceGroupTotalKcal +=
          cartGroup.cartItems.reduce((kcalItemSum, item) => kcalItemSum + item.quantity * item.item.kcal, 0);
      } else if (cartGroup.type === ItemGroupTypes.FLEXIBLE_GROUP || cartGroup.type === ItemGroupTypes.UPGRADE_GROUP) {
        upgradeAndFlexibleGroupsTotalKcal +=
          cartGroup.cartItems.reduce((kcalItemSum, item) => kcalItemSum + item.quantity * item.item.kcal, 0);
      }
    });

    return (fixedItemGroupTotalKcal + choiceGroupTotalKcal) * this.getBundleCount() + upgradeAndFlexibleGroupsTotalKcal;
  }

  public onSubmitItem(): void {
    if (!this.form.valid) {
      this.wasSubmitted = true;
      return;
    }
    const formValue = this.form.value;
    const cartGroups = this.createCartGroupsFromItemGroups(formValue.itemGroups);
    this.submitItem.emit({
      quantity: formValue.quantity,
      cartGroups
    });
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  private createCartGroupsFromItemGroups(itemGroups: {[key: string]: number}[]): CartItemBundleGroup[] {
    return this.item.groups
      .map(zip(itemGroups))
      .map(([group, itemGroup]: [ItemGroup, any]): CartItemBundleGroup => ({
        type: group.type,
        cartItems: group.items.map((item: SingleItem): CartSingleItem => ({
          type: CartItemTypes.SINGLE_ITEM,
          item,
          quantity: itemGroup && +itemGroup[item.id] || 0 // cast to number to ensure CHOICE items have 1 or 0 rather than true or false
        })
        )
      })
      );
  }

  private recreateForm(): void {
    if (this.item) {
      this.wasSubmitted = false;
      this.form = this.itemBundleFormService.createForm(this.item, this.cartItem as CartItemBundle, this.canOverrideQuantities);
    }
  }
}
