import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Breadcrumb } from '@citypantry/components-navigation';
import { ScrollHandleDirective } from '@citypantry/components-scroll';
import { MarketingCampaignComponentModel } from '@citypantry/shared-marketing-campaign';
import { DebounceHostListener, ensureInRange, TypedSimpleChanges } from '@citypantry/util';
import { MealPlan, SearchPromoCardModel, SearchRequest, SearchResult, VendorId } from '@citypantry/util-models';

const SCROLL_THRESHOLD_PX = 100;
export const MAX_PLACEHOLDERS_DURING_SEARCH = 12;

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent implements OnChanges {

  @Input()
  public recommendedResults: SearchResult[];

  @Input()
  public showRecommendations: boolean;

  @Input()
  public showDisruptionBanner: boolean;

  @Input()
  public results: SearchResult[];

  @Input()
  public resultsTotal: number;

  @Input()
  public isSearching: boolean;

  @Input()
  public breadcrumbs: Breadcrumb[];

  @Input()
  public showDistance: boolean;

  @Input()
  public showExactRemainingCapacity: boolean;

  @Input()
  public request: SearchRequest;

  @Input()
  public set showPlaceholders(show: boolean) {
    this._showPlaceholders = show;

    if (!show) {
      this.handleWindowScroll(); // In case we've just switched, ensure any load is triggered if the user is down the page
    }
  }

  public get showPlaceholders(): boolean {
    return this._showPlaceholders;
  }

  @Input()
  public searchCardPromotion: SearchPromoCardModel;

  @Input()
  public mealPlan: MealPlan | null;

  @Input()
  public proposedOrderId: string | null;

  @Input()
  public popUpMode: boolean;

  @Input()
  public hidePromoCard: boolean;

  @Input()
  public showFavouriteButtons: boolean;

  @Input()
  public favouritedVendorIds: VendorId[];

  @Input()
  public noSearchResultsMarketingCampaignComponentModel: MarketingCampaignComponentModel | null;

  @Output()
  public removeFavouriteVendor: EventEmitter<VendorId> = new EventEmitter();

  @Output()
  public addFavouriteVendor: EventEmitter<VendorId> = new EventEmitter();

  @Output()
  public loadNextPage: EventEmitter<void> = new EventEmitter();

  @Output()
  public promoCardClick: EventEmitter<SearchPromoCardModel> = new EventEmitter();

  @Output()
  public promoCardClose: EventEmitter<SearchPromoCardModel> = new EventEmitter();

  @Output()
  public openZopimChat: EventEmitter<void> = new EventEmitter();

  @ViewChildren(ScrollHandleDirective)
  public scrollHandles: QueryList<ScrollHandleDirective>;

  // TODO do smarter loading at the end of the page
  @ViewChildren('searchResult', { read: ElementRef })
  public searchResultElements: QueryList<ElementRef>;

  public placeholders: void[];

  private _showPlaceholders: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.results = [];
    this.showFavouriteButtons = false;
    this.favouritedVendorIds = [];
    this.placeholders = new Array(12);
  }

  public ngOnChanges(changes: TypedSimpleChanges<SearchPageComponent>): void {
    if (changes.results) {
      setTimeout(() => {
        // Ensure we trigger the next load immediately in case the results list is shorter than the screen
        // (e.g. if the screen is very large or very zoomed out)
        this.handleWindowScroll();
      });
    }
    if (changes.results || changes.showPlaceholders || changes.resultsTotal) {
      const placeholderCount = this.showPlaceholders ?
        MAX_PLACEHOLDERS_DURING_SEARCH :
        (this.resultsTotal || 0) - (this.results || []).length;
      this.placeholders = new Array(ensureInRange(0, MAX_PLACEHOLDERS_DURING_SEARCH)(placeholderCount));
    }
  }

  public scrollToHandle(name: string): void {
    if (!this.scrollHandles) {
      return;
    }

    const handle = this.scrollHandles.find((potentialHandle) => potentialHandle.scrollHandle === name);
    if (handle) {
      handle.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  public handlePromoCardClose(promotion: SearchPromoCardModel): void {
    this.promoCardClose.emit(promotion);
  }

  public handlePromoCardClick(promotion: SearchPromoCardModel): void {
    if (promotion.closeOnClick) {
      this.promoCardClose.emit(promotion);
    }

    this.promoCardClick.emit(promotion);
  }

  public get resultUrl(): string[] {
    if (this.mealPlan && this.proposedOrderId) {
      return ['/menus/mealplan',
        this.mealPlan.id,
        'orders',
        this.proposedOrderId,
        'vendors',
        'VENDOR_SLUG',
        'VENDOR_LOCATION_SLUG',
        'menu'];
    } else {
      return ['/menus/vendors', 'VENDOR_SLUG', 'VENDOR_LOCATION_SLUG'];
    }
  }

  public shouldShowPromotionCard(): boolean {
    return this.searchCardPromotion
      && !this.hidePromoCard
      && !this.popUpMode
      && !this.showPlaceholders
      && !(this.isSearching && this.results.length === 0)
      && this.results.length > 0;
  }

  public isVendorFavourited(vendorId: VendorId): boolean {
    return this.favouritedVendorIds.includes(vendorId);
  }

  public onFavouriteToggled(vendorId: VendorId): void {
    if (this.isVendorFavourited(vendorId)) {
      this.removeFavouriteVendor.emit(vendorId);
    } else {
      this.addFavouriteVendor.emit(vendorId);
    }
  }

  public shouldShowVendorComingSoon(): boolean {
    return this.results.length === this.resultsTotal // should only display when we are at the final result
      && this.resultsTotal !== 0                     // should not display when 0 results, as there is already a message for this case
      && !this.isSearching;                          // should not show until we have finished searching
  }

  @HostListener('window:scroll')
  @DebounceHostListener()
  private handleWindowScroll(): void {
    const lastWholeRowTop = this.getLastWholeRowTop();
    if (lastWholeRowTop === null) {
      return;
    }

    const viewportHeight = this.document.documentElement.clientHeight;
    if (lastWholeRowTop - SCROLL_THRESHOLD_PX <= viewportHeight) {
      // last whole row top edge (minus offset) is above the bottom edge of the screen (at least partially visible)

      if (this.results.length < this.resultsTotal) {
        // Use setTimeout to avoid this being triggered in same the stack frame as the page load,
        // which causes ChangedAfterCheck exceptions
        setTimeout(() => this.loadNextPage.emit());
      }
    }
  }

  /**
   * Computes the top offset of the last whole row of search results.
   * Returns null if no search results are available.
   */
  private getLastWholeRowTop(): number | null {
    if (!this.searchResultElements || !this.searchResultElements.length) {
      return null;
    }
    const elements = this.searchResultElements.toArray();
    const elementCount = elements.length;

    const topOffsets = []; // Stores the top offsets of each row, starting from the last row
    const rowCounts = []; // Number of items in each row, starting from the last row

    let lastOffset: number | null = null;
    // We can end the loop if we have 3 or more rows, because we only care about knowing how long the last two rows are
    for (let i = elementCount - 1; i >= 0 && topOffsets.length < 3; i--) {
      const offset = elements[i].nativeElement.getBoundingClientRect().top;
      if (lastOffset !== offset) {
        topOffsets.push(offset);
        rowCounts.push(0);
      }
      rowCounts[topOffsets.length]++;
      lastOffset = offset;
    }
    if (topOffsets.length === 1) {
      // Everything is in the same row
      return topOffsets[0];
    } else {
      if (rowCounts[0] < rowCounts[1]) {
        // Last row is shorter, so penultimate row is the last full one; return offset of penultimate row
        return topOffsets[1];
      } else {
        // Last row is the same length as penultimate, so last row is full; return offset of last row
        return topOffsets[0];
      }
    }
  }
}
