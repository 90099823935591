import moment from 'moment';
import { CartItem, createCartItemFromJson } from '../cart/cart-item.model';
import { Image } from '../image.model';
import { MajorCurrency } from '../money';
import { OrderStatus } from '../order/order-status.enum';
import { CostBreakdown, createCostBreakdownFromJson } from '../order/cost-breakdown.model';
import { DeliveryContact } from '../order/delivery-contact.model';
import { OrderId } from '../order/order-id.model';
import { createVendorFromJson, Vendor } from '../vendor';
import {
  createSimpleVendorLocationWithCapacityStatusFromJson,
  SimpleVendorLocationWithCapacityStatus
} from '../vendor/simple-vendor-location-with-capacity-status.model';

export interface IndividualChoiceOrder {
  costBreakdown: CostBreakdown;
  customerCostBreakdown: CostBreakdown;
  displayDeliveryContact: DeliveryContact;
  displayDeliveryInstructions: string;
  heroImage: Image;
  humanId: number;
  id: OrderId;
  expectedHeadcount: number;
  extraItems: CartItem[];
  extraItemsAdditionalReference: string;
  extraItemsCost: MajorCurrency;
  includeCutlery: boolean;
  menuContentId: string;
  menuId: string;
  notes: string;
  requestedDeliveryDate: moment.Moment;
  vendor: Vendor;
  currencyIsoCode: string;
  purchaseOrderNumber: string;
  departmentReference: string;
  status: OrderStatus;
  colleagueGroupsEnabled: boolean;
  selectedVendorLocation: SimpleVendorLocationWithCapacityStatus;
}

export function createIndividualChoiceOrderFromJson(json?: Partial<IndividualChoiceOrder>): IndividualChoiceOrder {
  json = json || {};

  const order: Partial<IndividualChoiceOrder> = {};

  order.id = json.id;
  order.costBreakdown = createCostBreakdownFromJson(json.costBreakdown);
  order.customerCostBreakdown = createCostBreakdownFromJson(json.customerCostBreakdown);
  order.displayDeliveryContact = json.displayDeliveryContact;
  order.displayDeliveryInstructions = json.displayDeliveryInstructions;
  order.heroImage = json.heroImage;
  order.humanId = json.humanId;
  order.expectedHeadcount = json.expectedHeadcount;
  order.extraItems = (json.extraItems || []).map(createCartItemFromJson);
  order.extraItemsAdditionalReference = json.extraItemsAdditionalReference ? json.extraItemsAdditionalReference :
    '';
  order.extraItemsCost = json.extraItemsCost;
  order.includeCutlery = !!json.includeCutlery;
  order.menuContentId = json.menuContentId;
  order.menuId = json.menuId;
  order.notes = json.notes;
  order.requestedDeliveryDate = json.requestedDeliveryDate ? moment(json.requestedDeliveryDate) : null;
  order.vendor = createVendorFromJson(json.vendor);
  order.currencyIsoCode = json.currencyIsoCode;
  order.purchaseOrderNumber = json.purchaseOrderNumber;
  order.departmentReference = json.departmentReference;
  order.status = json.status;
  order.colleagueGroupsEnabled = json.colleagueGroupsEnabled;
  order.selectedVendorLocation =
    json.selectedVendorLocation ? createSimpleVendorLocationWithCapacityStatusFromJson(json.selectedVendorLocation) : null;

  return order as IndividualChoiceOrder;
}
