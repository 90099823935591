// TODO: this is a component for legacy search page. remove it when all search pages are using the new design.
import { trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryWindowPipe } from '@citypantry/components-datetime';
import { Parameters } from '@citypantry/components-search';
import { isDateOnWeekend } from '@citypantry/util';
import { slideInOut } from '@citypantry/util-animations';
import {
  combineDeliveryDateTime,
  CustomerLocation,
  CustomerLocationId,
  SearchRequest,
  splitDeliveryDateTime,
  VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES,
  VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES,
} from '@citypantry/util-models';
import { CustomValidators } from '@citypantry/util-validators';
import moment from 'moment';
import { distinctUntilChanged, filter, map, skip, startWith, tap } from 'rxjs/operators';
import { CompactInputOption } from '../compact-input';

export interface SearchBarParameters extends Partial<SearchRequest> {
  location: CustomerLocationId;
  postcode: string;
  date: moment.Moment;
}

/**
 * FormValue mirrors the exact state of the form
 */
interface FormValue {
  postcodeOrLocation: string;
  date: string; // YYYY-MM-DD
  time: number; // Minutes from midnight
}

/**
 * DisambiguatedFormValue used for disambiguating FormValue, postcodeOrLocation becomes postcode and location
 */
interface DisambiguatedFormValue {
  postcode: string;
  location: string; // If not an existing location, will be null
  date: string; // YYYY-MM-DD
  time: number; // Minutes from midnight
}

const getAvailableDeliveryTimes = (date: moment.Moment): number[] =>
  isDateOnWeekend(date) ? VALID_WEEKEND_DELIVERY_TIMES_IN_MINUTES : VALID_WEEKDAY_DELIVERY_TIMES_IN_MINUTES;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  animations: [
    trigger('slide', slideInOut)
  ]
})
export class SearchBarComponent implements OnInit {

  @Input()
  public set request(request: SearchRequest) {
    this._request = request;
    this.onRequestChanged();
  }

  public get request(): SearchRequest {
    return this._request;
  }

  @Input()
  public set locked(isLocked: boolean) {
    if (!this._isLocked !== !isLocked) { // Cast to booleans before comparing
      this._isLocked = isLocked;

      this.updateEditableState();
    }
  }

  @Input()
  public set locations(locations: CustomerLocation[]) {
    this._locations = locations;
    this.locationOptions = (locations || [])
      .map((location: CustomerLocation): CompactInputOption<string> => ({
        value: location.id,
        label: `${location.name}, ${location.postcode}`
      }));
    this.locationPostcodeMap = locations ?
      locations.reduce((locationMap, location) => ({...locationMap, [location.id]: location.postcode}), {}) :
      {};
  }

  public get locations(): CustomerLocation[] {
    return this._locations;
  }

  @Input()
  public popUpMode: boolean;

  @Input()
  public hidden: boolean;

  @Input()
  public canEnterPostcode: boolean;

  @Output()
  public paramsChanged: EventEmitter<SearchBarParameters> = new EventEmitter();

  @Output()
  public searchChanged: EventEmitter<string> = new EventEmitter();

  public form: FormGroup;

  public deliveryTimeOptions: CompactInputOption<number>[];
  public locationOptions: CompactInputOption<string>[];

  private locationPostcodeMap: {[key in string]: string} = {};
  private _deliveryTimes: number[] = [];
  private _isLocked: boolean;
  private _request: SearchRequest;
  private _locations: CustomerLocation[];

  constructor(
    private fb: FormBuilder,
    private deliveryWindowPipe: DeliveryWindowPipe,
    private cdr: ChangeDetectorRef,
  ) {
    this.locationOptions = [];
  }

  public ngOnInit(): void {
    const { date: requestDate, time: requestTime } = splitDeliveryDateTime(this.request.date);
    this.deliveryTimes = getAvailableDeliveryTimes(moment.tz(requestDate, 'YYYY-MM-DD', 'Europe/London'));

    const postcode = (this.request && this.request.postcode) ? this.request.postcode : null;
    let location = '';

    if (this.request && this.request.location) {
      location = this.request.location;
    } else if (this.locations && this.locations.length) {
      const matchingLocation = this.locations.find((loc) => loc.postcode === postcode);

      location = matchingLocation ? matchingLocation.id : null;
    }

    const postcodeOrLocationInitialValue = location || postcode || '';

    const postcodeOrLocation = this.fb.control({
      value: postcodeOrLocationInitialValue,
      disabled: this._isLocked
    }, [ Validators.required ]);
    const date = this.fb.control({
      value: requestDate,
      disabled: this._isLocked
    }, [ Validators.required, CustomValidators.date ]);
    const time = this.fb.control({
      value: requestTime,
      disabled: this._isLocked
    }, [
      Validators.required,
      (control) => CustomValidators.timeInListAndDateOnWeekend(date, this._deliveryTimes)(control),
      (control) => CustomValidators.valueInList(this._deliveryTimes)(control),
    ]);

    this.form = this.fb.group({
      postcodeOrLocation,
      date,
      time
    });

    let lastValidValue: DisambiguatedFormValue = this.disambiguateAndValidatePostcodeAndLocation(this.form.value);

    const formValues = this.form.valueChanges.pipe(
      startWith(lastValidValue),
      map((value: FormValue) => {
        const nextDeliveryTimes = getAvailableDeliveryTimes(moment.tz(value.date, 'YYYY-MM-DD', 'Europe/London'));
        if (this.deliveryTimes !== nextDeliveryTimes) {
          this.deliveryTimes = nextDeliveryTimes;
          this.cdr.detectChanges(); // required to ensure that the new deliveryTimes are bound to the choice input's options
          time.updateValueAndValidity({ onlySelf: true });
        }
        return value;
      }),
      filter(() => !this._isLocked && time.valid && date.valid),
      map((value: FormValue) => {
        const splitFormValues = this.disambiguateAndValidatePostcodeAndLocation(value, lastValidValue);
        return {
          location: splitFormValues.location,
          postcode: splitFormValues.postcode,
          date: date.valid ? value.date : lastValidValue.date,
          time: time.valid ? value.time : lastValidValue.time,
        };
      }),
      tap((value: DisambiguatedFormValue) => lastValidValue = value),
      distinctUntilChanged((a, b) => a.postcode === b.postcode && a.location === b.location && a.date === b.date && a.time === b.time),
      skip(1), // We don't want to emit the initial value, but we do want to ensure that any initial values that match it aren't emitted
      map((value: DisambiguatedFormValue) => ({
        date: combineDeliveryDateTime(value.date, value.time),
        postcode: value.postcode,
        location: value.location
      })),
      filter((params: Parameters | null) => !!params)
    );
    formValues.subscribe(this.paramsChanged);
  }

  public onRequestChanged(): void {
    if (!this.form) {
      return;
    }
    const { date, time } = splitDeliveryDateTime(this.request && this.request.date);
    const postcodeOrLocation = this.request && this.request.location || this.request && this.request.postcode || '';

    this.form.setValue({
      postcodeOrLocation,
      date,
      time
    }, { emitEvent: false });
  }

  public onSearchSubmit(text: string): void {
    if ((text || '') !== (this.request.text || '')) {
      this.searchChanged.emit(text);
    }
  }

  private updateEditableState(): void {
    if (!this.form) {
      return; // Not yet initialised
    }

    if (this._isLocked) {
      this.form.get('postcodeOrLocation').disable({ onlySelf: true, emitEvent: false });
      this.form.get('date').disable({ onlySelf: true, emitEvent: false });
      this.form.get('time').disable({ onlySelf: true, emitEvent: false });
    } else {
      this.form.get('postcodeOrLocation').enable({ onlySelf: true, emitEvent: false });
      this.form.get('date').enable({ onlySelf: true, emitEvent: false });
      this.form.get('time').enable({ onlySelf: true, emitEvent: false });
    }
  }

  private disambiguateAndValidatePostcodeAndLocation(value: FormValue, lastValidValue?: DisambiguatedFormValue): DisambiguatedFormValue {
    let postcode: string = null;
    let location: string = null;

    if (value.postcodeOrLocation) {
      if (!CustomValidators.postcode(this.form.controls.postcodeOrLocation)) {
        // If postcodeOrLocation is a postcode, simply set the postcode
        postcode = value.postcodeOrLocation.trim();
      } else if (this.locationPostcodeMap && this.locationPostcodeMap.hasOwnProperty(value.postcodeOrLocation)) {
        // If postcodeOrLocation is a known ID, get the postcode from the map and set the id
        postcode = this.locationPostcodeMap[value.postcodeOrLocation].trim();
        location = value.postcodeOrLocation;
      }
    }

    if (!postcode && !location && lastValidValue) {
      // If postcodeOrLocation is neither a postcode, nor a known ID, use the last valid values if possible
      postcode = lastValidValue.postcode;
      location = lastValidValue.location;
    }

    return {
      postcode,
      location,
      date: value.date,
      time: value.time
    };
  }

  private set deliveryTimes(deliveryTimes: number[]) {
    this._deliveryTimes = deliveryTimes;
    this.deliveryTimeOptions = deliveryTimes.map((deliveryTime: number) => ({
      value: deliveryTime,
      label: this.deliveryWindowPipe.transform(deliveryTime)
    }));
  }
}
