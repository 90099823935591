import { CategorisedSearchRequest, CategorySearchResultsPage } from '@citypantry/util-models';
import { createAction, props, union } from '@ngrx/store';

export const SearchRecommendationActions = {
  loadSearchRecommendationResults: createAction(
    '[Search Recommendation] Load search recommendation results',
    props<{request: CategorisedSearchRequest}>(),
  ),
  searchRecommendationsResultsLoaded: createAction(
    '[Search API] Search recommendation results loaded',
    props<{results: CategorySearchResultsPage[]}>(),
  ),
};

const all = union(SearchRecommendationActions);
export type SearchRecommendationAction = typeof all;
