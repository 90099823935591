import { Pipe, PipeTransform } from '@angular/core';
import { FrequencyQualificationType, FrequencyQualificationTypes } from '@citypantry/util-models';

@Pipe({
  name: 'frequencyQualification'
})
export class FrequencyQualificationTypePipe implements PipeTransform {
  private static descriptors: { [key: string]: string } = {
    [FrequencyQualificationTypes.QUARTERLY]: 'Quarterly',
    [FrequencyQualificationTypes.MONTHLY]: 'Monthly',
    [FrequencyQualificationTypes.FORTNIGHTLY]: 'Fortnightly',
    [FrequencyQualificationTypes.WEEKLY]: 'Weekly',
    [FrequencyQualificationTypes.DAILY]: 'Daily',
  };

  public transform(value: FrequencyQualificationType | null): string {
    if (!value) {
      return null;
    }

    return FrequencyQualificationTypePipe.descriptors[value];
  }
}
