import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { deepDistinctUntilChanged } from '@citypantry/util';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatWidgetService } from './chat-widget.service';

@Injectable({
  providedIn: 'root'
})
export class ChatUserIdentityService {

  constructor(
    private store: Store<AppState>,
    private chatWidgetService: ChatWidgetService,
  ) {}

  public initialiseUserIdentity(): void {
    combineLatest([
      this.store.select(AuthSelectors.getUserName),
      this.store.select(AuthSelectors.getUserEmail),
    ]).pipe(
      map(([fullName, email]) =>
        fullName && email ?
          { fullName, email } :
          null
      ),
      deepDistinctUntilChanged(),
    ).subscribe((identity) => {
      if (identity) {
        this.chatWidgetService.setUserIdentity(identity.fullName, identity.email);
      } else {
        this.chatWidgetService.clearUserIdentity();
      }
    });
  }
}
