import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { nonEmpty } from '@citypantry/util';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsInitializer {
  constructor(
    private appConfig: AppConfig,
    private store: Store<AppState>,
  ) {
  }

  public run(ga: any): void {
    ga('create', this.appConfig.GOOGLE_ANALYTICS_ID, 'auto');
    ga('require', 'ec');

    this.store.select(AuthSelectors.getUserId).pipe(
      distinctUntilChanged(),
      filter(nonEmpty),
    ).subscribe((userId) => {
      ga('set', 'userId', userId);
    });

    this.store.select(AuthSelectors.isStaffOrSudo).pipe(distinctUntilChanged())
      .subscribe((isStaffOrSudo: boolean) => {
        ga('set', 'dimension18', isStaffOrSudo); // GA custom dimension "is Staff"
      });

    this.store.select(AuthSelectors.isSudo).pipe(distinctUntilChanged())
      .subscribe((isSudo: boolean) => {
        ga('set', 'dimension19', isSudo); // GA custom dimension "is Masqueraded"
      });
  }
}
