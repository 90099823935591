import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, ItemResponse, Result } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  AcceptOrderDefaultResponse,
  AnonymousOrderItems,
  Cart,
  CostBreakdown,
  createAnonymousOrderItemsFromJson,
  createCostBreakdownFromJson,
  CreateDeliveryContactPayload,
  createFeedbackFromJson,
  createIndividualChoiceOrderGroupFromJson,
  createIndividualChoiceOrderSummaryFromJson,
  createOrderFromJson,
  createOrderTrackingFromJson,
  createOrderValidationErrorFromJson,
  createPaymentCardFromJson,
  CustomerId,
  DeliveryDetails,
  ErrorMessage,
  ErrorResponse,
  Feedback,
  IndividualChoiceOrderGroup,
  IndividualChoiceOrderSummary,
  Order,
  OrderHumanId,
  OrderId,
  OrderTracking,
  OrderValidationError,
  PaymentCard,
  RefundPayer,
  ValidationResponse
} from '@citypantry/util-models';
import moment, { Moment } from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface OrderValidationSuccess {
  costBreakdown: CostBreakdown;
  cartId: string;
}

export const HEADER_KEY_JWT = 'CityPantry-JWT';

@Injectable({
  providedIn: 'root'
})
export class OrderApi {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getOrder(orderId: OrderId | OrderHumanId): Observable<Order> {
    return this.http.get(`/orders/${orderId}`).pipe(
      map(createOrderFromJson)
    );
  }

  public getOrderHumanIds(orderId: OrderId): Observable<string[]> {
    const headers = new HttpHeaders({
      [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true',
    });
    return this.http.get<{count: number, items: string[]}>(`/orders/${orderId}/humanId`, {
      headers
    }).pipe(getItems());
  }

  public getAnonymousOrderItems(jwt: string): Observable<AnonymousOrderItems> {
    return this.http.get(`/order-items`, {
      headers: {
        [HEADER_KEY_JWT]: jwt,
      }
    }).pipe(
      map(createAnonymousOrderItemsFromJson),
    );
  }

  public getOrderTracking(orderId: OrderId, jwt?: string): Observable<OrderTracking> {
    let headers = new HttpHeaders({
      [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true',
    });

    if (jwt) {
      headers = headers.set(HEADER_KEY_JWT, jwt);
    }

    return this.http.get(`/order-tracking/${orderId}`, {
      headers
    }).pipe(
      map(createOrderTrackingFromJson),
    );
  }

  public updateDeliveryDetails(order: Order, deliveryDetails: DeliveryDetails): Observable<Order> {
    return this.http.patch(
      `/orders/${order.id}/delivery-details`,
      {
        address: deliveryDetails.pickupAddress ? deliveryDetails.pickupAddress.id : null,
        orderSize: deliveryDetails.size,
        orderSizeChangeReason: deliveryDetails.reason,
        returnRequired: deliveryDetails.requiresReturn,
      },
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}}
    ).pipe(
      map(createOrderFromJson)
    );
  }

  public updateOrderRequestedDeliveryDate(orderId: OrderId, requestedDeliveryDate: Moment): Observable<Order> {
    return this.http.patch(`/orders/${orderId}/requested-delivery-date`, { requestedDeliveryDate }).pipe(
      map(createOrderFromJson)
    );
  }

  public acceptOrder(order: Order): Observable<Order> {
    return this.http.put<{ order: Partial<Order> }>(`/order/${order.id}/accept`, {}).pipe(
      map(({ order: newOrder }) => createOrderFromJson(newOrder))
    );
  }

  public acceptOrderDefault(orderId: OrderId, jwt: string): Observable<Result<AcceptOrderDefaultResponse, string>> {
    const headers = { [HEADER_KEY_JWT]: jwt, [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.put(`/orders/${orderId}/accept-default`, null, { headers }).pipe(
      map(
        (data: AcceptOrderDefaultResponse) => (Result.success(data))
      ),
      catchError((error) => {
        switch (error.status) {
          case 403:
            return of(Result.failure('You have to login first to view and accept this order.'));
          case 404:
            return of(Result.failure('The order does not exist or has been deleted.'));
          case 400:
            return of(Result.failure('The order has progressed to the next status in the order status flow.'));
          default:
            return throwError(error);
        }
      }),
    );
  }

  public getIndividualChoiceOrderSummary(orderId: OrderId): Observable<Result<IndividualChoiceOrderSummary, ErrorResponse | unknown>> {
    return this.http.get(
      `/individual-choice/${orderId}/summary`,
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}}
    ).pipe(
      getItem(),
      map(
        (json: Partial<IndividualChoiceOrderSummary>) => Result.success(createIndividualChoiceOrderSummaryFromJson(json))
      ),
      catchError(
        (error: ErrorResponse | unknown) => of(Result.failure(error))
      )
    );
  }

  public updateOrderNotes(orderId: OrderId, notes: string): Observable<Object> {
    return this.http.post(`/individual-choice-order-groups/${orderId}/update-dietary-notes`, { notes });
  }

  public updateOrderCutlery(orderId: OrderId, includeCutlery: boolean): Observable<Object> {
    return this.http.post(`/individual-choice-order-groups/${orderId}/update-include-cutlery`, { includeCutlery });
  }

  public updateCartsAdditionalReferenceRequired(orderId: OrderId, cartsAdditionalReferenceRequired: boolean): Observable<Object> {
    return this.http.post(
      `/individual-choice-order-groups/${orderId}/update-carts-additional-reference-required`,
      { cartsAdditionalReferenceRequired }
    );
  }

  public getIndividualChoiceOrderGroup(orderGroupId: OrderId): Observable<IndividualChoiceOrderGroup> {
    return this.http.get(`/individual-choice-order-groups/${orderGroupId}`).pipe(
      map(createIndividualChoiceOrderGroupFromJson)
    );
  }

  public cancelIndividualChoiceOrderGroup(orderGroupId: OrderId): Observable<Result<IndividualChoiceOrderGroup, ErrorMessage[]>> {
    const headers = { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' };
    return this.http.post(`/individual-choice-order-groups/${orderGroupId}/cancel`, null, { headers }).pipe(
      map((order) => (Result.success(createIndividualChoiceOrderGroupFromJson(order)))),
      catchError((error) =>
        error instanceof ErrorResponse
          ? of(Result.failure(error.messages))
          : throwError(error),
      ),
    );
  }

  public validateMarketplaceOrder(
    headcount: number,
    cart: Cart,
    requestedDeliveryDate: moment.Moment,
    customerId: CustomerId,
    orderId?: OrderId,
    mealPlanId?: string
  ): Observable<ValidationResponse<OrderValidationSuccess, { cartId: string, violation: OrderValidationError }>> {
    return this.http.post<ItemResponse<{
      costBreakdown: Partial<CostBreakdown>;
      cartId: string;
      violations: Partial<OrderValidationError>[];
    }>>('/orders/validate', {
      headCount: headcount,
      cart,
      requestedDeliveryDate: requestedDeliveryDate.toISOString(),
      customer: customerId,
      order: orderId,
      mealPlan: mealPlanId,
    }, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(({ costBreakdown, cartId, violations }) => ({
        costBreakdown: createCostBreakdownFromJson(costBreakdown),
        cartId,
        violations: violations.map(createOrderValidationErrorFromJson),
      })),
      map(({ costBreakdown, cartId, violations }) => {
        const violationsOrder = ['NextDayOrderDeadline', 'VendorHasEnoughCapacity', 'MinimumOrderValue'];
        const sortViolations = (a: OrderValidationError, b: OrderValidationError): number => {
          const orderOfA = violationsOrder.indexOf(a.type);
          const orderOfB = violationsOrder.indexOf(b.type);

          if (orderOfA === -1 && orderOfB === -1) {
            return violations.indexOf(a) - violations.indexOf(b);
          } else if (orderOfA === -1) {
            return 1;
          } else if (orderOfB === -1) {
            return -1;
          } else {
            return orderOfA - orderOfB;
          }
        };

        if (violations.length === 0) {
          return {
            success: true as const,
            value: { costBreakdown, cartId },
          };
        } else {
          return {
            success: false as const,
            error: {
              cartId,
              violation: [...violations].sort(sortViolations)[0],
            },
          };
        }
      }),
    );
  }

  public updateOrder(orderId: OrderId, order: Partial<Order>): Observable<Order> {
    return this.http.put<{updated: boolean, order: Order}>(`/order/${orderId}`, order).pipe(
      map((response) => createOrderFromJson(response.order))
    );
  }

  public updatePurchaseOrderNumberAndDepartmentReference(orderId: OrderId, updatedReferences: Partial<Order>): Observable<Order> {
    return this.http.patch<Partial<Order>>(`/order/${orderId}/update-payment-references`, updatedReferences).pipe(
      map((response) => createOrderFromJson(response))
    );
  }

  public addOrderDeliveryContact(orderId: OrderId, deliveryContact: CreateDeliveryContactPayload): Observable<Order> {
    const payload = { 'newDeliveryContact': deliveryContact };
    return this.http.put<{updated: boolean, order: Order}>(`/order/${orderId}`, payload).pipe(
      map((response) => createOrderFromJson(response.order))
    );
  }

  public updateOrderDeliveryContact(orderId: OrderId, deliveryContactId: string): Observable<Order> {
    const payload = { 'newDeliveryContact': { id: deliveryContactId } };
    return this.http.put<{updated: boolean, order: Order}>(`/order/${orderId}`, payload).pipe(
      map((response) => createOrderFromJson(response.order))
    );
  }

  public updateOrderFeedback(orderId: OrderId, feedback: Feedback): Observable<Feedback> {
    return this.http.put<Partial<Feedback>>(`/orders/${orderId}/feedback`, feedback).pipe(
      map(createFeedbackFromJson)
    );
  }

  public cancelOrder(orderId: OrderId, cancelPayload: { reason: string | null, otherReason: string | null, paidBy: RefundPayer }
  ): Observable<Order> {
    return this.http.post<{ order: Order }>(`/order/${orderId}/cancel`, cancelPayload, {
      headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}
    }).pipe(
      map((response) => createOrderFromJson(response.order))
    );
  }

  public getOrderPaymentCard(orderId: OrderId): Observable<PaymentCard> {
    return this.http.get(`/orders/${orderId}/payment-card`).pipe(
      map(createPaymentCardFromJson)
    );
  }
}
