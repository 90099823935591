import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { safeUnsubscribe } from '@citypantry/util';
import { numberOfVisibleDietariesSet, SingleItem, UpgradeItemGroup } from '@citypantry/util-models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upgrade-group',
  templateUrl: './upgrade-group.component.html'
})
export class UpgradeGroupComponent implements OnInit, OnDestroy {

  @Input()
  public set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
    this.recalculateSelectedItems();
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  @Input()
  public bundleCount: number;

  @Input()
  public itemGroup: UpgradeItemGroup;

  @Input()
  public canOverrideQuantities: boolean;

  @Input()
  public hidePrices: boolean;

  public selectedItems: SingleItem[];
  public optionalItems: SingleItem[];

  public moreInfoItem: SingleItem;

  private valueSubscription: Subscription;
  private _formGroup: FormGroup;

  constructor() {
    this.moreInfoItem = null;
  }

  public ngOnInit(): void {
    this.recalculateSelectedItems();

    if (this.formGroup) {
      this.valueSubscription = this.formGroup.valueChanges.subscribe((value: { [itemId: string]: number }) => {
        if (this.moreInfoItem && !value[this.moreInfoItem.id]) {
          this.moreInfoItem = null;
        }
        this.recalculateSelectedItems();
      });
    }
  }

  public ngOnDestroy(): void {
    safeUnsubscribe(this.valueSubscription);
  }

  public onToggleShowMoreInfo(item?: SingleItem): void {
    if (!item || this.moreInfoItem === item) {
      this.moreInfoItem = null;
    } else {
      this.moreInfoItem = item;
    }
  }

  public addItem(item: SingleItem): void {
    this.formGroup.get(item.id).setValue(Math.max(this.bundleCount || 0, item.minimumOrderQuantity || 0, 1));
    if (this.moreInfoItem === item) {
      this.moreInfoItem = null;
    }
  }

  public removeItem(item: SingleItem): void {
    this.formGroup.get(item.id).setValue(0);
    if (this.moreInfoItem === item) {
      this.moreInfoItem = null;
    }
  }

  public trackById(index: number, item: SingleItem): string {
    return item.id;
  }

  public hasDietaries(item: SingleItem): boolean {
    return item && numberOfVisibleDietariesSet(item.dietaries) > 0;
  }

  public shouldShowKcals(item: SingleItem): boolean {
    return item.kcal !== null && item.kcal >= 0;
  }

  private recalculateSelectedItems(): void {
    const items = this.itemGroup && this.itemGroup.items || [];
    this.selectedItems = items.filter((item) => this.formGroup.value[item.id] > 0);
    this.optionalItems = items.filter((item) => !this.formGroup.value[item.id]);
  }
}
