/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type EaterCartStatus = 'draft' | 'confirmed' | 'pending_top_up_payment_confirmation';

export class EaterCartStatuses {
  public static DRAFT:                               EaterCartStatus & 'draft'                               = 'draft';
  public static CONFIRMED:                           EaterCartStatus & 'confirmed'                           = 'confirmed';
  public static PENDING_TOP_UP_PAYMENT_CONFIRMATION: EaterCartStatus & 'pending_top_up_payment_confirmation' = 'pending_top_up_payment_confirmation';

  public static get values(): EaterCartStatus[] {
    return [
      EaterCartStatuses.DRAFT,
      EaterCartStatuses.CONFIRMED,
      EaterCartStatuses.PENDING_TOP_UP_PAYMENT_CONFIRMATION,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type EaterCartStatusEnum = typeof EaterCartStatuses;
