import { createAction, props, union } from '@ngrx/store';
import moment from 'moment';

export const CustomerReportsActions = {
  downloadCustomerOrdersReportCsv: createAction(
    '[Reports Page] Download Customer Orders Report CSV',
    props<{ from: moment.Moment, to: moment.Moment }>(),
  ),
};

const all = union(CustomerReportsActions);
export type CustomerReportsAction = typeof all;
