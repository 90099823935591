import { FileItem, FileUploader } from 'ng2-file-upload';

import { Observable, Subject } from 'rxjs';

export class ItemUploader<T> {

  private _upload$: Subject<T>;

  constructor(
    private fileUploader: FileUploader,
    private transformer: (response: string) => T
  ) {
    fileUploader.setOptions({
      additionalParameter: {
        target: 'single-item' // Might need to make this configurable, but at the moment there is only one valid option
      }
    });

    fileUploader.onBeforeUploadItem = (fileItem: FileItem): void => {
      fileItem.withCredentials = false; // TODO find out what this does and why we need it
    };

    fileUploader.onSuccessItem = (item: FileItem, response: string): void => {
      try {
        const data = JSON.parse(response);
        const transformed: T = this.transformer(data);

        this._upload$.next(transformed);
      } catch (e) {
        this._upload$.error(e);
      }
    };

    fileUploader.onErrorItem = (item: FileItem, response: string): void => {
      this._upload$.error(response);
    };

    this._upload$ = new Subject();
  }

  public get upload$(): Observable<T> {
    return this._upload$.asObservable();
  }

  public get isUploading(): boolean {
    return this.fileUploader.isUploading;
  }

  public get addToQueue(): Function {
    return this.fileUploader.addToQueue.bind(this.fileUploader);
  }

  // Add more getters to proxy this.fileUploader if necessary
}
