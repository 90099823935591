import { Cost, createCostFromJson, createMixedCostFromJson, MajorCurrency, MixedCost } from '../money';

export interface CostBreakdown {
  standardRatedItemsCost: Cost;
  zeroRateItemsCost: Cost;
  deliveryCost: Cost;
  serviceCost: Cost; // vendor commission that we make
  serviceFeeCost: Cost; // fee that customer pays us
  itemsCost: MixedCost;
  itemsCostWithDelivery: MixedCost;
  itemsCostAfterPromoCode: MixedCost;
  itemsCostAfterRefunds: MixedCost;
  availableBudget: MajorCurrency;
}

export function createCostBreakdownFromJson(json?: Partial<CostBreakdown>): CostBreakdown {
  json = json || {};

  const costBreakdown: Partial<CostBreakdown> = {};
  costBreakdown.standardRatedItemsCost = createCostFromJson(json.standardRatedItemsCost);
  costBreakdown.zeroRateItemsCost = createCostFromJson(json.zeroRateItemsCost);
  costBreakdown.deliveryCost = createCostFromJson(json.deliveryCost);
  costBreakdown.serviceCost = createCostFromJson(json.serviceCost);
  costBreakdown.itemsCost = createMixedCostFromJson(json.itemsCost);
  costBreakdown.itemsCostWithDelivery = createMixedCostFromJson(json.itemsCostWithDelivery);
  costBreakdown.itemsCostAfterPromoCode = createMixedCostFromJson(json.itemsCostAfterPromoCode);
  costBreakdown.itemsCostAfterRefunds = createMixedCostFromJson(json.itemsCostAfterRefunds);
  costBreakdown.serviceFeeCost = createCostFromJson(json.serviceFeeCost);
  costBreakdown.availableBudget = json.availableBudget;

  return costBreakdown as CostBreakdown;
}
