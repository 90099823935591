import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogAbortSource, DialogSettings } from './dialog-settings.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['dialog.component.scss']
})
export class DialogComponent {

  @Input()
  public set settings(settings: DialogSettings) {
    this._settings = {
      ...settings,
      onConfirm: settings && settings.onConfirm || noop,
      onAbort: settings && settings.onAbort || noop,
      onComplete: settings && settings.onComplete || noop
    };

    this.show = !!settings;
  }

  public get settings(): DialogSettings {
    return this._settings;
  }

  /**
   * Emits when the user clicks the "confirm" button.
   * Closing can be cancelled, so the dialog is not guaranteed to be done.
   */
  @Output()
  public confirm: EventEmitter<void> = new EventEmitter();

  /**
   * Emits when the user attempts to close the dialog, via close button, cancel button, or outside click.
   * Closing can be cancelled, so the dialog is not guaranteed to be done.
   */
  @Output()
  public abort: EventEmitter<DialogAbortSource> = new EventEmitter();

  /**
   * Emits when the dialog has been closed.
   * Returns true when the user clicked confirm, false on abort.
   */
  @Output()
  public complete: EventEmitter<boolean> = new EventEmitter();

  public show: boolean;

  private _settings: DialogSettings;

  constructor() {
    this.settings = null; // Triggers initial setup
  }

  public onCancelClicked(): void {
    this.triggerAbort('cancel');
  }

  public onCloseClicked(): void {
    this.triggerAbort('close');
  }

  public onOutsideClicked(): void {
    this.triggerAbort('outside');
  }

  public onConfirmClicked(): void {
    let isCancelled = false;
    this.settings.onConfirm({
      cancel: () => {
        isCancelled = true;
      }
    });
    this.confirm.emit();

    if (!isCancelled) {
      this.show = false;
      this.settings.onComplete(true); // If there are ever issues with concurrency, this may need to be moved to a setTimeout
      this.complete.emit(true);
    }
  }

  private triggerAbort(source: DialogAbortSource): void {
    let isCancelled = false;
    this.settings.onAbort({
      cancel: () => {
        isCancelled = true;
      },
      source
    });
    this.abort.emit(source);

    if (!isCancelled) {
      this.show = false;
      this.settings.onComplete(false); // If there are ever issues with concurrency, this may need to be moved to a setTimeout
      this.complete.emit(false);
    }
  }
}

function noop(): void { /* does nothing */ }
