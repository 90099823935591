import { Injectable } from '@angular/core';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState, CartSelectors, MealPlanSelectors } from '@citypantry/state';
import { Cart, CartItem, CartType, CartValidityError, CartValidityErrorTypes, MealPlan } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartQueries {

  constructor(
    private store: Store<AppState>,
  ) {}

  public getCart(): Observable<Cart> {
    return this.store.select(CartSelectors.getCart);
  }

  public getCartType(): Observable<CartType> {
    return this.store.select(CartSelectors.getCartType);
  }

  public getDeliveryDate(): Observable<Moment> {
    return this.store.select(CartSelectors.getCart).pipe(
      map((cart: Cart) => cart.deliveryDate)
    );
  }

  public getPostcode(): Observable<string> {
    return this.store.select(CartSelectors.getCart).pipe(
      map((cart: Cart) => cart.postcode)
    );
  }

  public getCartItems(): Observable<CartItem[]> {
    return this.store.select(CartSelectors.getCart).pipe(
      map((cart: Cart) => cart.cartItems)
    );
  }

  public getMealPlanId(): Observable<string> {
    return this.store.select(MealPlanSelectors.getMealPlan).pipe(
      map((mealPlan: MealPlan) => mealPlan && mealPlan.id || null)
    );
  }

  public getMealPlan(): Observable<MealPlan> {
    return this.store.select(MealPlanSelectors.getMealPlan);
  }

  public isExistingCartFromAdmin(): Observable<boolean> {
    return this.store.select(CartSelectors.isExistingCartFromAdmin);
  }

  public hasProblems(): Observable<boolean> {
    return combineLatest([
      this.store.select(CartSelectors.getCartValidityErrors),
      this.store.select(CartSelectors.getCartDeliverabilityProblems),
    ]).pipe(map(([errors, problems]) =>
      errors.length + problems.length > 0
    ));
  }

  /**
   * Determines whether all problems (if there are any) can be overridden. Returns true if there are no problems.
   */
  public canOverrideAllProblems(): Observable<boolean> {
    return combineLatest([
      this.getCartValidityErrors(),
      this.store.select(CartSelectors.getCartDeliverabilityProblems),
    ]).pipe(map(([errors, problems]) =>
      [].concat(errors, problems).filter((problem) => !problem.overridable).length === 0
    ));
  }

  /**
   * Fetch cart validity errors for the *currently active* cart.
   * This method considers some validity errors to be overridable if the user is an admin.
   */
  public getCartValidityErrors(): Observable<CartValidityError[]> {
    return combineLatest([
      this.store.select(CartSelectors.getCartValidityErrors),
      this.store.select(AuthSelectors.isStaffOrSudo)
    ]).pipe(
      map(([validityErrors, isStaffOrSudo]) => {
        if (!isStaffOrSudo) {
          return validityErrors;
        } else {
          return validityErrors.map((error) => {
            if (CartValidityErrorTypes.errorsOverridableByAdmin.indexOf(error.type) >= 0) {
              return {
                ...error,
                overridable: true
              };
            } else {
              return error;
            }
          });
        }
      })
    );
  }
}
