<form
  [formGroup]="form"
  (submit)="submitForm()"
  test-id="form"
  analyticsId="qualification-form"
>
  <h2
    [class.text-center]="!isEmbedded"
    test-id="title"
  >Getting to know you...</h2>
  <p class="mb-large">We'd love to know a little more about your setup so we can help you find better options</p>

  <div class="form-group">
    <label class="form-group__label">How often does your company get food into the office?</label>
    <select
      formControlName="frequency"
      class="form-input"
      analyticsId="frequency"
      [analyticsData]="form.value.frequency"
    >
      <option
        value="null"
        disabled
        [selected]="true"
      >
        Select an option...
      </option>
      <option
        *ngFor="let frequencyType of FrequencyQualificationTypes.values"
        [ngValue]="frequencyType"
      >
        {{ frequencyType | frequencyQualification }}
      </option>
    </select>
    <div
      *ngIf="shouldShowErrors('frequency')"
      test-id="frequencyError"
      class="form-error form-error--floating"
    >
      Please select a frequency...
    </div>
  </div>

  <div class="form-group" formGroupName="useCases">
    <label class="form-group__label">What are these occasions?</label>
    <label
      *ngFor="let useCase of UseCaseQualificationTypes.values"
      class="form-choice form-choice--large mb-2"
    >
      <input
        type="checkbox"
        [name]="useCase"
        [formControlName]="useCase"
        class="form-choice__input"
        analyticsId="use-case"
        [analyticsData]="useCase"
      />
      <span class="form-choice__label">
        {{ useCase | useCaseQualification }}
      </span>
    </label>
    <div
      *ngIf="shouldShowErrors('useCases')"
      class="form-error form-error--floating"
      test-id="useCaseError"
    >
      Please select at least one use case...
    </div>
  </div>

  <div class="form-group">
    <label class="form-group__label">How large is your company?</label>
    <select
      formControlName="headCount"
      class="form-input"
      analyticsId="head-count"
      [analyticsData]="form.value.headCount"
    >
      <option
        value="null"
        disabled
        [selected]="true"
      >
        Select an option...
      </option>
      <option
        *ngFor="let headCountType of HeadCountQualificationTypes.values"
        [ngValue]="headCountType"
      >
        {{ headCountType | headCountQualification }}
      </option>
    </select>
    <div
      class="form-error form-error--floating"
      *ngIf="shouldShowErrors('headCount')"
      test-id="headCountError"
    >
      Please select a head count...
    </div>
  </div>

  <div class="form-group">
    <label class="form-group__label">How did you hear about us?</label>
    <select
      class="form-input"
      formControlName="source"
      analyticsId="source"
      [analyticsData]="form.value.source"
    >
      <option
        value="null"
        disabled
        [selected]="true"
      >
        Select an option...
      </option>
      <option
        *ngFor="let sourceType of SourceQualificationTypes.values"
        [ngValue]="sourceType"
      >
        {{ sourceType | sourceQualification }}
      </option>
    </select>
    <div
      class="form-error form-error--floating"
      *ngIf="shouldShowErrors('source')"
      test-id="sourceError"
    >
      Please select a source...
    </div>
  </div>

  <div
    class="form-group"
    *ngIf="otherSourceSelected"
    test-id="otherSourceInput"
  >
    <input
      type="text"
      class="form-input"
      formControlName="otherSource"
    />
    <div
      class="form-error form-error--floating"
      *ngIf="shouldShowErrors('otherSource')"
      test-id="otherSourceError"
    >
      Please tell us what source...
    </div>
  </div>

  <button
    class="button button--full-width mt-2"
    [class.button--secondary]="isEmbedded"
    analyticsId="submit"
    test-id="submit"
  >
    Submit
  </button>
</form>

<app-state-loading
  [isLoading]="isSubmitting"
></app-state-loading>
