import { Result } from '@citypantry/util';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '@citypantry/shared-app-config';
import { ErrorResponse } from '@citypantry/util-models';
import { NavigationPermissions } from '../models/navigation-permissions.model';
import { DISABLE_REDIRECT_ON_AUTH_FAILURE } from '../auth.interceptor';

interface NavigationPermissionsResource {
  attributes: NavigationPermissions;
}

export interface NavigationPermissionsResponse {
  data: NavigationPermissionsResource;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationPermissionsApi {
  constructor(
    private http: HttpClient,
  ) {}

  public getNavigationPermissions(): Observable<Result<NavigationPermissions>> {
    return this.http.get<NavigationPermissionsResponse>('/navigation/permissions', {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' },
      context: new HttpContext().set(DISABLE_REDIRECT_ON_AUTH_FAILURE, true)
    }).pipe(
      timeout(environment.authTimeout),
      map((response) => Result.success(response.data.attributes)),
      catchError((errorResponse: HttpErrorResponse | ErrorResponse) => {
        const error = errorResponse instanceof ErrorResponse
          ? errorResponse.apiResponse
          : errorResponse;

        if (error.status && error.status === 401) {
          return of(Result.failure());
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
