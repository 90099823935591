export type VendorReachCriterion = BasicVendorReachCriterion | ScaleVendorReachCriterion;

export interface BasicVendorReachCriterion {
  pass: boolean;
}

export interface ScaleVendorReachCriterion extends BasicVendorReachCriterion {
  value: number;
  max: number;
}

export function isScaleVendorReachCriterion(criterion: VendorReachCriterion): criterion is ScaleVendorReachCriterion {
  return typeof (criterion as ScaleVendorReachCriterion).max === 'number' &&
    typeof (criterion as ScaleVendorReachCriterion).value === 'number';
}

export function createVendorReachCriterionFromJson<T extends BasicVendorReachCriterion>(json: Partial<T>): T {
  const criterion: Partial<T> = {};
  criterion.pass = !!json.pass;

  const scaleJson = json as VendorReachCriterion;
  if (isScaleVendorReachCriterion(scaleJson)) {
    const scaleCriterion: Partial<ScaleVendorReachCriterion> = criterion;
    scaleCriterion.max = scaleJson.max;
    scaleCriterion.value = scaleJson.value;
  }

  return criterion as T;
}
