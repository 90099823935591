import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AnalyticsCategories, AnalyticsService } from '@citypantry/shared-analytics';

@Injectable({
  providedIn: 'root',
})
export class TrackRegistrationGuard implements CanActivate {

  constructor(
    private analyticsService: AnalyticsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    // Frontend, for old reasons, sends an includeRegistrationTrackingCode=1 query param on successful registration.
    // It's not used in menus but we can use it to determine whether we came from registration.
    if (route.queryParamMap.has('includeRegistrationTrackingCode')) {
      this.analyticsService.trackCustomEvent('complete_registration', { category: AnalyticsCategories.CUSTOMER_HOME_PAGE });
    }
    return true;
  }
}
