import { type } from '@citypantry/util';
import {
  CartCustomItemOption,
  CartItemBundleGroup,
  CustomItem,
  EaterCart,
  EaterCartSubmitError,
  EaterFeedback,
  EaterLocation,
  EaterMeal,
  EaterMealOption,
  EaterRefundRequest,
  IndividualChoiceOrderSummary,
  ItemBundle,
  ItemId,
  JustEatVoucher,
  OrderId,
  Page,
  PaginateQuery,
  PaymentCard,
  PaymentCardId,
  PaymentIntentSummary,
  SimpleCustomerLocation,
  SingleItem,
  UserId,
} from '@citypantry/util-models';
import { Action } from '@ngrx/store';

// no-empty-interface: Empty interfaces help define actions so are necessary even if they have no payload
/* eslint-disable @typescript-eslint/no-empty-interface */

// naming-convention: Legacy action types don't adhere to our naming conventions because they try to look like enums
/* eslint-disable @typescript-eslint/naming-convention */
export const ActionTypes = {
  ORDER_SUMMARY_LOADED: type('[Eater] Order Summary Loaded'),

  MY_MEALS_PAGE_VIEW: type('[My Meals Guard] My Meals Page View'),
  EATER_MEALS_LOADED: type('[Individual Choice] Eater Meals Loaded'),
  PREVIOUS_DEPARTMENT_REFERENCES_LOADED: type('[Individual Choice API] Previous Department References Loaded'),

  LOAD_EATER_PREVIOUS_MEALS: type('[Eater Previous Meals Page] Load Eater Previous Meals'),
  EATER_PREVIOUS_MEALS_LOADED: type('[Eater API] Eater Previous Meals Loaded'),

  /**
   * Modal for providing the user with the individual choice add location
   */
  EATER_LOCATION_SHARE_LINK_VIEWED: type('[Eater Location Share Link Guard] Store Eater Location To Be Added'),
  ADD_USER_TO_EATER_LOCATION: type('[My Meals Add Eater Location Guard] Add User To Eater Location'),
  SHOW_EATER_LOCATION_ADD_MODAL: type('[Eater] Show Eater Location Add Modal'),
  HIDE_EATER_LOCATION_ADD_MODAL: type('[Eater] Hide Eater Location Add Modal'),
  SUBMIT_EATER_LOCATION_ADD_MODAL: type('[Eater] Submit Eater Location Add Modal'),
  EATER_LOCATION_ADDED: type('[Eater] Eater Location Added'),
  SET_EATER_LOCATIONS: type('[Eater] Set Eater Locations'),

  ADD_ITEM_TO_CART: type('[Eater] Add Item To Cart'),
  UPDATE_DEPARTMENT_REFERENCE: type('[Eater] Update Department Reference'),
  UPDATE_DESK_NUMBER: type('[Eater] Update Desk Number'),
  REMOVE_ITEM_FROM_CART: type('[Eater] Remove Item From Cart'),

  ADD_OR_UPDATE_ITEM_IN_CART: type('[Eater] Item Added Or Updated In Cart'),
  UPDATE_ITEM_QUANTITY_IN_CART: type('[Eater] Item Quantity Updated In Cart'),
  EATER_CART_ITEM_EDIT_CLICK: type('[Eater] Cart Item Edit Clicked'),
  EATER_MENU_ITEM_ADD_CLICK: type('[Eater] Menu Item Add Clicked'),
  ITEM_UNSELECTED: type('[Eater] Item Unselected'),

  SUBMIT_EATER_CART: type('[Eater] Submit Eater Cart'),
  EATER_CART_LOADED: type('[Eater] Eater Cart Loaded'),
  EATER_CART_SUBMITTED: type('[Eater] Eater Cart Submitted'),
  EATER_SUBSIDISED_CART_SUBMITTED: type('[Eater] Eater Subsidised Cart Submitted'),
  EATER_CART_SUBMIT_FAILED: type('[Eater] Eater Cart Submit Failed'),
  DISMISS_EATER_CART_ERROR: type('[Eater] Dismiss Eater Cart Error'),
  CLEAR_ORDER_CONFIRMATION: type('[Eater] Clear Order Confirmation'),
  DELETE_EATER_MEAL: type('[Eater] Delete Eater Meal'),
  EATER_MEAL_DELETED: type('[Eater] Eater Meal Deleted'),
  MARK_EATER_AS_AWAY: type('[Eater] Mark Eater As Away'),
  EATER_MARKED_AS_AWAY: type('[Eater] Eater Marked As Away'),
  SHOULD_REMEMBER_CARD_CHANGED: type('[Eater] Should Remember Card Changed'),
  LOAD_EATER_PAYMENT_CARD: type('[Eater] Load Eater Payment Card'),
  EATER_PAYMENT_CARD_LOADED: type('[Eater] Eater Payment Card Loaded'),
  DELETE_EATER_PAYMENT_CARD: type('[Eater] Delete Eater Payment Card'),
  EATER_PAYMENT_CARD_DELETED: type('[Eater] Eater Payment Card Deleted'),
  SHOW_EATER_FEEDBACK_FORM: type('[Eater] Show Eater Feedback Form'),
  HIDE_EATER_FEEDBACK_FORM: type('[Eater] Hide Eater Feedback Form'),
  SUBMIT_EATER_FEEDBACK_FORM: type('[Eater] Submit Eater Feedback Form'),
  EATER_FEEDBACK_FORM_SUBMITTED: type('[Eater] Eater Feedback Form Submitted'),
  EATER_FEEDBACK_FORM_ERROR: type('[Eater] Eater Feedback Form Error'),
  EATER_MENU_PAGE_VIEW: type('[Eater] Recommended Item Id Provided'),

  SHOW_EATER_ORDER_RATING_FORM: type('[Eater] Show Eater Order Rating Form'),
  HIDE_EATER_ORDER_RATING_FORM: type('[Eater] Hide Eater Order Rating Form'),
  EATER_ORDER_RATING_FORM_SUBMIT_CLICK: type('[My Meals Page] Eater Order Rating Submit Click'),
  EATER_FEEDBACK_LOADED: type('[My Meals Page] Eater Feedback Loaded'),
  EATER_FEEDBACK_CREATED: type('[My Meals Page] Eater Feedback Created'),
  THANKS_FOR_YOUR_FEEDBACK_COMPLETE: type('[My Meals Page] Thanks For Your Feedback Complete'),

  CART_PAYMENT_INTENT_CREATED: type('[Individual Choice API] Card Payment Intent Created'),

  USER_TESTING_CAPTURE_FORM_SUBMITTED: type('[Eater] User Testing Capture Form Submitted'),
  USER_TESTING_CAPTURE_FORM_DISMISSED: type('[Eater] User Testing Capture Form Dismissed'),
  SHOW_USER_TESTING_CAPTURE_FORM: type('[Eater] Show User Testing Capture Form'),

  GET_JUST_EAT_VOUCHER: type('[Eater] Get Just Eat Voucher'),
  RETRIEVE_JUST_EAT_VOUCHER: type('[Eater] Retrieve Just Eat Voucher'),
  JUST_EAT_VOUCHER_RETRIEVED: type('[Eater] Just Eat Voucher Retrieved'),

  EATER_AGE_CONFIRMATION_DIALOG_CONFIRM: type('[Eater Age Confirmation Dialog] Confirm'),
  EATER_AGE_CONFIRMATION_DIALOG_CANCEL: type('[Eater Age Confirmation Dialog] Cancel'),
};
/* eslint-enable @typescript-eslint/naming-convention */

export interface OrderSummaryLoadedAction extends Action {
  payload: {
    orderId: OrderId;
    summary: IndividualChoiceOrderSummary;
  };
}

export function orderSummaryLoaded(
  orderId: OrderId,
  summary: IndividualChoiceOrderSummary
): OrderSummaryLoadedAction {
  return {
    type: ActionTypes.ORDER_SUMMARY_LOADED,
    payload: {
      orderId,
      summary,
    }
  };
}

export interface MyMealsPageViewAction extends Action {
  payload: {
    silent: boolean;
  };
}

export function myMealsPageView(silent?: boolean): MyMealsPageViewAction {
  return {
    type: ActionTypes.MY_MEALS_PAGE_VIEW,
    payload: {
      silent: !!silent
    }
  };
}

export interface EaterMealsLoadedAction extends Action {
  payload: {
    eaterMeals: EaterMeal[];
  };
}

export function eaterMealsLoaded(eaterMeals: EaterMeal[]): EaterMealsLoadedAction {
  return {
    type: ActionTypes.EATER_MEALS_LOADED,
    payload: {
      eaterMeals
    }
  };
}

export interface PreviousDepartmentReferencesLoadedAction extends Action {
  payload: {
    previousDepartmentReferences: string[];
  };
}

export function previousDepartmentReferencesLoaded(previousDepartmentReferences: string[]): PreviousDepartmentReferencesLoadedAction {
  return {
    type: ActionTypes.PREVIOUS_DEPARTMENT_REFERENCES_LOADED,
    payload: {
      previousDepartmentReferences
    }
  };
}

export interface EaterFeedbackLoadedAction extends Action {
  payload: {
    eaterFeedback: { [key: string]: EaterFeedback };
  };
}

export function eaterFeedbackLoaded(eaterFeedback: { [key: string]: EaterFeedback }): EaterFeedbackLoadedAction {
  return {
    type: ActionTypes.EATER_FEEDBACK_LOADED,
    payload: {
      eaterFeedback
    }
  };
}

export interface EaterOrderRatingSubmitClickAction extends Action {
  payload: {
    foodQuality: number;
    publicComments: string;
  };
}

export function eaterOrderRatingSubmitClick(
  foodQuality: number,
  publicComments: string
): EaterOrderRatingSubmitClickAction {
  return {
    type: ActionTypes.EATER_ORDER_RATING_FORM_SUBMIT_CLICK,
    payload: {
      foodQuality,
      publicComments,
    }
  };
}

export interface EaterFeedbackCreatedAction extends Action {
  payload: {
    eaterFeedback: EaterFeedback;
  };
}

export function eaterFeedbackCreated(eaterFeedback: EaterFeedback): EaterFeedbackCreatedAction {
  return {
    type: ActionTypes.EATER_FEEDBACK_CREATED,
    payload: {
      eaterFeedback
    }
  };
}

export interface ThanksForYourFeedbackCompleteAction extends Action {}

export function thanksForYourFeedbackComplete(): ThanksForYourFeedbackCompleteAction {
  return {
    type: ActionTypes.THANKS_FOR_YOUR_FEEDBACK_COMPLETE,
  };
}

export interface LoadEaterPreviousMealsAction extends Action {
  payload: {
    query: PaginateQuery;
  };
}

export function loadEaterPreviousMeals(query: PaginateQuery): LoadEaterPreviousMealsAction {
  return {
    type: ActionTypes.LOAD_EATER_PREVIOUS_MEALS,
    payload: {
      query
    }
  };
}

export interface EaterPreviousMealsLoadedAction extends Action {
  payload: {
    eaterPreviousMealsPage: Page<EaterMeal>;
  };
}

export function eaterPreviousMealsLoaded(eaterPreviousMealsPage: Page<EaterMeal>): EaterPreviousMealsLoadedAction {
  return {
    type: ActionTypes.EATER_PREVIOUS_MEALS_LOADED,
    payload: {
      eaterPreviousMealsPage
    }
  };
}

export interface EaterCartLoadedAction extends Action {
  payload: {
    cart: EaterCart;
  };
}

export function eaterCartLoaded(cart: EaterCart): EaterCartLoadedAction {
  return {
    type: ActionTypes.EATER_CART_LOADED,
    payload: {
      cart
    }
  };
}

export interface EaterMenuPageView extends Action {
  payload: {
    recommendedItemId: ItemId | null;
  };
}

export function eaterMenuPageView(recommendedItemId: ItemId | null): EaterMenuPageView {
  return {
    type: ActionTypes.EATER_MENU_PAGE_VIEW,
    payload: {
      recommendedItemId
    }
  };
}

export interface EaterLocationAddedAction extends Action {
}

export function eaterLocationAdded(): EaterLocationAddedAction {
  return {
    type: ActionTypes.EATER_LOCATION_ADDED
  };
}

export interface HideEaterLocationAddModalAction extends Action {
}

export function hideEaterLocationAddModal(): HideEaterLocationAddModalAction {
  return {
    type: ActionTypes.HIDE_EATER_LOCATION_ADD_MODAL
  };
}

export interface SubmitEaterLocationAddModalAction extends Action {
  payload: {
    location: SimpleCustomerLocation;
  };
}

export function submitEaterLocationAddModal(location: SimpleCustomerLocation): SubmitEaterLocationAddModalAction {
  return {
    type: ActionTypes.SUBMIT_EATER_LOCATION_ADD_MODAL,
    payload: {
      location
    }
  };
}

export interface ShowEaterLocationAddModalAction extends Action {
  payload: {
    eaterLocation: EaterLocation;
  };
}

export function showEaterLocationAddModal(eaterLocation: EaterLocation): ShowEaterLocationAddModalAction {
  return {
    type: ActionTypes.SHOW_EATER_LOCATION_ADD_MODAL,
    payload: {
      eaterLocation,
    },
  };
}

export interface EaterLocationShareLinkViewedAction extends Action {
  payload: {
    eaterLocation: EaterLocation;
  };
}

export function eaterLocationShareLinkViewed(eaterLocation: EaterLocation): EaterLocationShareLinkViewedAction {
  return {
    type: ActionTypes.EATER_LOCATION_SHARE_LINK_VIEWED,
    payload: {
      eaterLocation,
    },
  };
}

export interface AddUserToEaterLocation extends Action {
  payload: {
    location: SimpleCustomerLocation;
  };
}

export function addUserToEaterLocation(location: SimpleCustomerLocation): AddUserToEaterLocation {
  return {
    type: ActionTypes.ADD_USER_TO_EATER_LOCATION,
    payload: {
      location,
    },
  };
}

export interface SetEaterLocationsAction extends Action {
  payload: {
    locations: SimpleCustomerLocation[];
  };
}

export function setEaterLocations(locations: SimpleCustomerLocation[]): SetEaterLocationsAction {
  return {
    type: ActionTypes.SET_EATER_LOCATIONS,
    payload: {
      locations,
    }
  };
}

export interface AddItemToCartAction extends Action {
  payload: {
    item: SingleItem;
    quantity: number | null;
  };
}

export function addItemToCart(item: SingleItem, quantity?: number): AddItemToCartAction {
  return {
    type: ActionTypes.ADD_ITEM_TO_CART,
    payload: {
      item,
      quantity: quantity || null
    }
  };
}

export interface UpdateDepartmentReferenceAction extends Action {
  payload: {
    departmentReference: string;
  };
}

export function updateDepartmentReference(departmentReference: string): UpdateDepartmentReferenceAction {
  return {
    type: ActionTypes.UPDATE_DEPARTMENT_REFERENCE,
    payload: {
      departmentReference,
    }
  };
}

export interface UpdateDeskNumberAction extends Action {
  payload: {
    deskNumber: string;
  };
}

export function updateDeskNumber(deskNumber: string): UpdateDeskNumberAction {
  return {
    type: ActionTypes.UPDATE_DESK_NUMBER,
    payload: {
      deskNumber,
    }
  };
}

export interface RemoveItemFromCartAction extends Action {
  payload: {
    cartIndex: number;
  };
}

export function removeItemFromCart(cartIndex: number): RemoveItemFromCartAction {
  return {
    type: ActionTypes.REMOVE_ITEM_FROM_CART,
    payload: {
      cartIndex
    }
  };
}

export interface AddOrUpdateItemBundleInCartEvent {
  quantity: number;
  cartGroups: CartItemBundleGroup[];
}
export interface AddOrUpdateCustomItemInCartEvent {
  quantity: number;
  selectedOptions: CartCustomItemOption[];
}
export type AddOrUpdateItemInCartEvent = AddOrUpdateItemBundleInCartEvent | AddOrUpdateCustomItemInCartEvent;

export interface AddOrUpdateItemInCartAction extends Action {
  payload: {
    event: AddOrUpdateItemInCartEvent;
  };
}

export function addOrUpdateItemInCart(event: AddOrUpdateItemInCartEvent): AddOrUpdateItemInCartAction {
  return {
    type: ActionTypes.ADD_OR_UPDATE_ITEM_IN_CART,
    payload: {
      event
    },
  };
}

export interface UpdateItemQuantityInCartAction extends Action {
  payload: {
    cartIndex: number;
    quantity: number;
  };
}

export function updateItemQuantityInCart(
  cartIndex: number,
  quantity: number,
): UpdateItemQuantityInCartAction {
  return {
    type: ActionTypes.UPDATE_ITEM_QUANTITY_IN_CART,
    payload: {
      cartIndex,
      quantity,
    },
  };
}

export interface EaterCartItemEditClickAction extends Action {
  payload: {
    item: CustomItem | ItemBundle;
    cartIndex: number;
  };
}

export function eaterCartItemEditClicked(item: CustomItem | ItemBundle, cartIndex: number): EaterCartItemEditClickAction {
  return {
    type: ActionTypes.EATER_CART_ITEM_EDIT_CLICK,
    payload: {
      item,
      cartIndex,
    }
  };
}

export interface EaterMenuItemAddClickAction extends Action {
  payload: {
    item: CustomItem | ItemBundle;
  };
}

export function eaterMenuItemAddClicked(item: CustomItem | ItemBundle): EaterMenuItemAddClickAction {
  return {
    type: ActionTypes.EATER_MENU_ITEM_ADD_CLICK,
    payload: {
      item
    }
  };
}

export interface ItemUnselectedAction extends Action {
}

export function itemUnselected(): ItemUnselectedAction {
  return {
    type: ActionTypes.ITEM_UNSELECTED,
  };
}

export interface SubmitEaterCartAction extends Action {
}

export function submitEaterCart(): SubmitEaterCartAction {
  return {
    type: ActionTypes.SUBMIT_EATER_CART
  };
}

export interface EaterCartSubmittedAction extends Action {
  payload: {
    cart: EaterCart;
  };
}

export function eaterCartSubmitted(cart: EaterCart): EaterCartSubmittedAction {
  return {
    type: ActionTypes.EATER_CART_SUBMITTED,
    payload: {
      cart
    }
  };
}

export interface EaterSubsidisedCartSubmittedAction extends Action {
  payload: {
    cart: EaterCart;
    rememberCard: boolean;
  };
}

export function eaterSubsidisedCartSubmitted(cart: EaterCart, rememberCard: boolean): EaterSubsidisedCartSubmittedAction {
  return {
    type: ActionTypes.EATER_SUBSIDISED_CART_SUBMITTED,
    payload: {
      cart,
      rememberCard
    }
  };
}

export interface CartPaymentIntentCreatedAction extends Action {
  payload: {
    cart: EaterCart;
    paymentIntentSummary: PaymentIntentSummary;
  };
}

export function cartPaymentIntentCreated(cart: EaterCart, paymentIntentSummary: PaymentIntentSummary): CartPaymentIntentCreatedAction {
  return {
    type: ActionTypes.CART_PAYMENT_INTENT_CREATED,
    payload: {
      cart,
      paymentIntentSummary,
    },
  };
}

export interface EaterCartSubmitFailedAction extends Action {
  payload: {
    error: EaterCartSubmitError;
  };
}

export function eaterCartSubmitFailed(error: EaterCartSubmitError): EaterCartSubmitFailedAction {
  return {
    type: ActionTypes.EATER_CART_SUBMIT_FAILED,
    payload: {
      error
    }
  };
}

export interface DismissEaterCartErrorAction extends Action {
}

export function dismissEaterCartError(): DismissEaterCartErrorAction {
  return {
    type: ActionTypes.DISMISS_EATER_CART_ERROR
  };
}

export interface ClearOrderConfirmationAction extends Action {
}

export function clearOrderConfirmation(): ClearOrderConfirmationAction {
  return {
    type: ActionTypes.CLEAR_ORDER_CONFIRMATION
  };
}

export interface DeleteEaterMealAction extends Action {
  payload: {
    orderId: OrderId;
  };
}

export function deleteEaterMeal(orderId: OrderId): DeleteEaterMealAction {
  return {
    type: ActionTypes.DELETE_EATER_MEAL,
    payload: {
      orderId
    }
  };
}

export interface MarkEaterAsAwayAction extends Action {
  payload: {
    orderId: OrderId;
  };
}

export function markEaterAsAway(orderId: OrderId): MarkEaterAsAwayAction {
  return {
    type: ActionTypes.MARK_EATER_AS_AWAY,
    payload: {
      orderId
    }
  };
}

export interface EaterMealDeletedAction extends Action {
  payload: {
    orderId: OrderId;
  };
}

export function eaterMealDeleted(orderId: OrderId): EaterMealDeletedAction {
  return {
    type: ActionTypes.EATER_MEAL_DELETED,
    payload: {
      orderId
    }
  };
}

export interface EaterMarkedAsAwayAction extends Action {
  payload: {
    orderId: OrderId;
  };
}

export function eaterMarkedAsAway(orderId: OrderId): EaterMarkedAsAwayAction {
  return {
    type: ActionTypes.EATER_MARKED_AS_AWAY,
    payload: {
      orderId
    }
  };
}

export interface ShouldRememberCardChangedAction extends Action {
  payload: {
    rememberCard: boolean;
  };
}

export function shouldRememberCardChanged(rememberCard: boolean): ShouldRememberCardChangedAction {
  return {
    type: ActionTypes.SHOULD_REMEMBER_CARD_CHANGED,
    payload: {
      rememberCard
    }
  };
}

export interface LoadPaymentCardAction extends Action {
  payload: {
    userId: UserId | 'me';
  };
}

export function loadPaymentCard(userId: UserId | 'me'): LoadPaymentCardAction {
  return {
    type: ActionTypes.LOAD_EATER_PAYMENT_CARD,
    payload: {
      userId
    }
  };
}

export interface PaymentCardLoadedAction extends Action {
  payload: {
    paymentCard: PaymentCard;
  };
}

export function paymentCardLoaded(paymentCard: PaymentCard): PaymentCardLoadedAction {
  return {
    type: ActionTypes.EATER_PAYMENT_CARD_LOADED,
    payload: {
      paymentCard
    }
  };
}

export interface DeletePaymentCardAction extends Action {
  payload: {
    paymentCardId: PaymentCardId;
  };
}

export function deletePaymentCard(paymentCardId: PaymentCardId): DeletePaymentCardAction {
  return {
    type: ActionTypes.DELETE_EATER_PAYMENT_CARD,
    payload: {
      paymentCardId
    }
  };
}

export interface PaymentCardDeletedAction extends Action {}

export function paymentCardDeleted(): PaymentCardDeletedAction {
  return {
    type: ActionTypes.EATER_PAYMENT_CARD_DELETED,
  };
}

export interface ShowEaterFeedbackFormAction extends Action {
  payload: {
    eaterMealOptions: EaterMealOption[];
  };
}

export function showEaterFeedbackForm(eaterMealOptions: EaterMealOption[]): ShowEaterFeedbackFormAction {
  return {
    type: ActionTypes.SHOW_EATER_FEEDBACK_FORM,
    payload: {
      eaterMealOptions,
    }
  };
}

export interface HideEaterFeedbackFormAction extends Action {
}

export function hideEaterFeedbackForm(): HideEaterFeedbackFormAction {
  return {
    type: ActionTypes.HIDE_EATER_FEEDBACK_FORM,
  };
}

export interface SubmitEaterFeedbackFormAction extends Action {
  payload: {
    feedbackForm: EaterRefundRequest;
  };
}

export function submitEaterFeedbackForm(feedbackForm: EaterRefundRequest): SubmitEaterFeedbackFormAction {
  return {
    type: ActionTypes.SUBMIT_EATER_FEEDBACK_FORM,
    payload: {
      feedbackForm,
    }
  };
}

export interface EaterFeedbackFormErrorAction extends Action {
}

export function eaterFeedbackFormError(): EaterFeedbackFormErrorAction {
  return {
    type: ActionTypes.EATER_FEEDBACK_FORM_ERROR,
  };
}

export interface EaterFeedbackFormSubmittedAction extends Action {
}

export function eaterFeedbackFormSubmitted(): EaterFeedbackFormSubmittedAction {
  return {
    type: ActionTypes.EATER_FEEDBACK_FORM_SUBMITTED,
  };
}

export interface ShowEaterOrderRatingFormAction extends Action {
  payload: {
    mealOption: EaterMealOption;
  };
}

export function showEaterOrderRatingForm(mealOption: EaterMealOption): ShowEaterOrderRatingFormAction {
  return {
    type: ActionTypes.SHOW_EATER_ORDER_RATING_FORM,
    payload: {
      mealOption
    }
  };
}

export interface HideEaterOrderRatingFormAction extends Action {
}

export function hideEaterOrderRatingForm(): HideEaterOrderRatingFormAction {
  return {
    type: ActionTypes.HIDE_EATER_ORDER_RATING_FORM,
  };
}

export interface RetrieveJustEatVoucherAction extends Action {
}

export function retrieveJustEatVoucher(): RetrieveJustEatVoucherAction {
  return {
    type: ActionTypes.RETRIEVE_JUST_EAT_VOUCHER,
  };
}

export interface GetJustEatVoucherAction extends Action {
}

export function getJustEatVoucher(): GetJustEatVoucherAction {
  return {
    type: ActionTypes.GET_JUST_EAT_VOUCHER,
  };
}

export interface JustEatVoucherRetrievedAction extends Action {
  payload: {
    justEatVoucher: JustEatVoucher;
  };
}

export function justEatVoucherRetrieved(justEatVoucher: JustEatVoucher): JustEatVoucherRetrievedAction {
  return {
    type: ActionTypes.JUST_EAT_VOUCHER_RETRIEVED,
    payload: {
      justEatVoucher,
    }
  };
}

export interface EaterAgeConfirmationDialogConfirm extends Action {
}

export function eaterAgeConfirmationDialogConfirm(): EaterAgeConfirmationDialogConfirm {
  return {
    type: ActionTypes.EATER_AGE_CONFIRMATION_DIALOG_CONFIRM,
  };
}

export interface EaterAgeConfirmationDialogCancel extends Action {
}

export function eaterAgeConfirmationDialogCancel(): EaterAgeConfirmationDialogCancel {
  return {
    type: ActionTypes.EATER_AGE_CONFIRMATION_DIALOG_CANCEL,
  };
}
