import { Subscription } from 'rxjs';

/**
 * Provides a safe way to easily unsubscribe subscriptions that may or may not exist.
 * Call with a variable number of subscriptions; it will skip any that are not defined, and catch and safely trap any errors that occur.
 * @param {Subscription} subscriptions
 */
export function safeUnsubscribe(...subscriptions: Subscription[]): void {
  for (const subscription of subscriptions) {
    if (subscription) {
      try {
        subscription.unsubscribe();
      } catch (e) {
        console.warn('Failed to unsubscribe:', e);
      }
    }
  }
}
