import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { StoreModule } from '@ngrx/store';
import { GlobalDialogComponent } from './global-dialog.component';
import { FEATURE_STATE_NAME, reducer } from './global-dialog.state';

@NgModule({
  imports: [
    CommonModule,
    ModalComponentsModule,
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
  declarations: [
    GlobalDialogComponent,
  ],
  exports: [
    /*
      CAUTION: GlobalDialogComponent should be a singleton, rendered only in AppComponent.
      Do not render it in any other components.
    */
    GlobalDialogComponent,
  ],
})
export class GlobalDialogModule { }
