/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type PromotionName =
  'FREE_DELIVERY'
  | 'SAME_DAY_DELIVERY'
  | 'POP_UP'
  | 'INDIVIDUAL_CHOICE'
  | 'TOP_UPS'
  | 'QUALIFICATION'
  | 'CUSTOMER_SURVEY'
  | 'PANTRY_PACKAGE_WIZARD'
  | 'ENABLE_SSO'
  | 'JUST_EAT_PAY'
  | 'VENDOR_FAQ';

export class PromotionNames {
  public static FREE_DELIVERY:         PromotionName & 'FREE_DELIVERY'         = 'FREE_DELIVERY';
  public static SAME_DAY_DELIVERY:     PromotionName & 'SAME_DAY_DELIVERY'     = 'SAME_DAY_DELIVERY';
  public static POP_UP:                PromotionName & 'POP_UP'                = 'POP_UP';
  public static INDIVIDUAL_CHOICE:     PromotionName & 'INDIVIDUAL_CHOICE'     = 'INDIVIDUAL_CHOICE';
  public static TOP_UPS:               PromotionName & 'TOP_UPS'               = 'TOP_UPS';
  public static QUALIFICATION:         PromotionName & 'QUALIFICATION'         = 'QUALIFICATION';
  public static CUSTOMER_SURVEY:       PromotionName & 'CUSTOMER_SURVEY'       = 'CUSTOMER_SURVEY';
  public static PANTRY_PACKAGE_WIZARD: PromotionName & 'PANTRY_PACKAGE_WIZARD' = 'PANTRY_PACKAGE_WIZARD';
  public static ENABLE_SSO:            PromotionName & 'ENABLE_SSO'            = 'ENABLE_SSO';
  public static JUST_EAT_PAY:          PromotionName & 'JUST_EAT_PAY'          = 'JUST_EAT_PAY';
  public static VENDOR_FAQ:            PromotionName & 'VENDOR_FAQ'            = 'VENDOR_FAQ';

  public static get values(): PromotionName[] {
    return [
      PromotionNames.FREE_DELIVERY,
      PromotionNames.SAME_DAY_DELIVERY,
      PromotionNames.POP_UP,
      PromotionNames.INDIVIDUAL_CHOICE,
      PromotionNames.TOP_UPS,
      PromotionNames.QUALIFICATION,
      PromotionNames.CUSTOMER_SURVEY,
      PromotionNames.PANTRY_PACKAGE_WIZARD,
      PromotionNames.ENABLE_SSO,
      PromotionNames.JUST_EAT_PAY,
      PromotionNames.VENDOR_FAQ,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type PromotionNameEnum = typeof PromotionNames;
