import { createVendorReachCriterionFromJson, ScaleVendorReachCriterion, VendorReachCriterion } from './vendor-reach-criterion.model';

export interface VendorReachSummary {
  scorePercent: number;
  menuContentId: string;
  criteria: VendorReachCriteria;
}

export interface VendorReachCriteria {
  individualChoice: VendorReachCriterion;
  sameDayOrdering: VendorReachCriterion;
  sameDayIndividualChoice: VendorReachCriterion;
  goodLabelling: VendorReachCriterion;
  popups: VendorReachCriterion;
  ecoFriendlyPackaging: VendorReachCriterion;

  dietaries: ScaleVendorReachCriterion;
  allergens: VendorReachCriterion;
  servingStyles: ScaleVendorReachCriterion;
  images: ScaleVendorReachCriterion;
  drinks: VendorReachCriterion;
  sides: VendorReachCriterion;
}

export function createVendorReachSummaryFromJson(json: Partial<VendorReachSummary>): VendorReachSummary {
  const summary: Partial<VendorReachSummary> = {};
  summary.scorePercent = json.scorePercent;
  summary.menuContentId = json.menuContentId;

  const jsonCriteria = json.criteria || {} as Partial<VendorReachCriteria>;
  summary.criteria = {
    individualChoice:        createVendorReachCriterionFromJson(jsonCriteria.individualChoice),
    sameDayOrdering:         createVendorReachCriterionFromJson(jsonCriteria.sameDayOrdering),
    sameDayIndividualChoice: createVendorReachCriterionFromJson(jsonCriteria.sameDayIndividualChoice),
    goodLabelling:           createVendorReachCriterionFromJson(jsonCriteria.goodLabelling),
    popups:                  createVendorReachCriterionFromJson(jsonCriteria.popups),
    ecoFriendlyPackaging:    createVendorReachCriterionFromJson(jsonCriteria.ecoFriendlyPackaging),

    dietaries:               createVendorReachCriterionFromJson(jsonCriteria.dietaries),
    allergens:               createVendorReachCriterionFromJson(jsonCriteria.allergens),
    servingStyles:           createVendorReachCriterionFromJson(jsonCriteria.servingStyles),
    images:                  createVendorReachCriterionFromJson(jsonCriteria.images),
    drinks:                  createVendorReachCriterionFromJson(jsonCriteria.drinks),
    sides:                   createVendorReachCriterionFromJson(jsonCriteria.sides),
  };

  return summary as VendorReachSummary;
}
