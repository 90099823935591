import { VendorLocationCapacityStatus } from './vendor-location-capacity-status.enum';
import { VendorLocationId, VendorLocationSlug } from './vendor-location.model';

export interface SimpleVendorLocationWithCapacityStatus {
  id: VendorLocationId;
  slug: VendorLocationSlug;
  capacityStatus: VendorLocationCapacityStatus;
  name: string | null;
}

export function createSimpleVendorLocationWithCapacityStatusFromJson(
  json?: Partial<SimpleVendorLocationWithCapacityStatus>
): SimpleVendorLocationWithCapacityStatus {
  json = json || {};
  const simpleVendorLocation: Partial<SimpleVendorLocationWithCapacityStatus> = {};
  simpleVendorLocation.id = json.id;
  simpleVendorLocation.slug = json.slug;
  simpleVendorLocation.capacityStatus = json.capacityStatus;
  simpleVendorLocation.name = json.name || null;
  return simpleVendorLocation as SimpleVendorLocationWithCapacityStatus;
}
