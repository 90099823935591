/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type PositiveDeliveryFeedbackTag = 'Friendly/helpful driver' | 'Great order tracking' | 'Great timing';

export class PositiveDeliveryFeedbackTags {
  public static FRIENDLY_HELPFUL_DRIVER: PositiveDeliveryFeedbackTag & 'Friendly/helpful driver' = 'Friendly/helpful driver';
  public static GREAT_ORDER_TRACKING:    PositiveDeliveryFeedbackTag & 'Great order tracking'    = 'Great order tracking';
  public static GREAT_TIMING:            PositiveDeliveryFeedbackTag & 'Great timing'            = 'Great timing';

  public static get values(): PositiveDeliveryFeedbackTag[] {
    return [
      PositiveDeliveryFeedbackTags.FRIENDLY_HELPFUL_DRIVER,
      PositiveDeliveryFeedbackTags.GREAT_ORDER_TRACKING,
      PositiveDeliveryFeedbackTags.GREAT_TIMING,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type PositiveDeliveryFeedbackTagEnum = typeof PositiveDeliveryFeedbackTags;
