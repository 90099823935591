import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from '@citypantry/components-click-outside';
import { ContextDropdownModule } from '@citypantry/components-context-dropdown';
import { CurrencyComponentsModule } from '@citypantry/components-currency';
import { DatetimeComponentsModule } from '@citypantry/components-datetime';
import { FeedbackComponentsModule } from '@citypantry/components-feedback';
import { FormComponentsModule } from '@citypantry/components-form';
import { ImageComponentsModule } from '@citypantry/components-image';
import { LoadingComponentsModule } from '@citypantry/components-loading';
import { ModalComponentsModule } from '@citypantry/components-modal';
import { PageBannerComponentsModule } from '@citypantry/components-page-banner';
import { ScrollComponentsModule } from '@citypantry/components-scroll';
import { StickyComponentsModule } from '@citypantry/components-sticky';
import { TextComponentsModule } from '@citypantry/components-text';
import { TooltipComponentsModule } from '@citypantry/components-tooltip';
import { VendorInfoComponentsModule } from '@citypantry/components-vendor-info';
import { AnalyticsComponentsModule, AnalyticsEcommerceComponentsModule } from '@citypantry/shared-analytics';
import { PromotionModule } from '@citypantry/shared-promotion';
import { InViewportModule } from 'ng-in-viewport';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AboutVendorComponent } from './about-vendor/about-vendor.component';
import { AdditionalItemInfoComponent } from './additional-item-info/additional-item-info.component';
import { CartDeliveryComponent } from './cart-delivery/cart-delivery.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartNotesComponent } from './cart-notes/cart-notes.component';
import { CartOverridesDialogComponent } from './cart-overrides-dialog/cart-overrides-dialog.component';
import { CartScrollPanelComponent } from './cart-scroll-panel/cart-scroll-panel.component';
import { CartSubtotalComponent } from './cart-subtotal/cart-subtotal.component';
import { CartToggleComponent } from './cart-toggle/cart-toggle.component';
import { CartTotalComponent } from './cart-total/cart-total.component';
import { CartComponent } from './cart/cart.component';
import { CustomItemComponent } from './custom-item/custom-item.component';
import { DeletableCartItemComponent } from './deletable-cart-item/deletable-cart-item.component';
import { DeliverabilityFlagComponent } from './deliverability-flag/deliverability-flag.component';
import { DeliveryNoticeComponent } from './delivery-notice/delivery-notice.component';
import { DietIconComponent } from './diet-icon/diet-icon.component';
import { DietariesFilterBoxComponent } from './dietaries-filter-box/dietaries-filter-box.component';
import { DietariesComponent } from './dietaries/dietaries.component';
import { EcoFriendlyPackagingComponent } from './eco-friendly-packaging/eco-friendly-packaging.component';
import { FeedsQuantityComponent } from './feeds-quantity/feeds-quantity.component';
import { HeaderBackBarComponent } from './header-back-bar/header-back-bar.component';
import { ItemAddButtonComponent } from './item-add-button/item-add-button.component';
import { ItemBundleComponent } from './item-bundle/item-bundle.component';
import { ItemGroupPromptComponent } from './item-group-prompt/item-group-prompt.component';
import { CustomItemModalFormSectionComponent } from './item-modal/custom-item-modal-form/custom-item-modal-form-section/custom-item-modal-form-section.component';
import { CustomItemModalFormComponent } from './item-modal/custom-item-modal-form/custom-item-modal-form.component';
import { ChoiceGroupItemComponent } from './item-modal/item-bundle-modal-form/choice-group-item/choice-group-item.component';
import { ChoiceGroupComponent } from './item-modal/item-bundle-modal-form/choice-group/choice-group.component';
import { FixedGroupItemComponent } from './item-modal/item-bundle-modal-form/fixed-group-item/fixed-group-item.component';
import { FixedGroupComponent } from './item-modal/item-bundle-modal-form/fixed-group/fixed-group.component';
import { FlexibleGroupItemComponent } from './item-modal/item-bundle-modal-form/flexible-group-item/flexible-group-item.component';
import { FlexibleGroupComponent } from './item-modal/item-bundle-modal-form/flexible-group/flexible-group.component';
import { ItemBundleModalFormComponent } from './item-modal/item-bundle-modal-form/item-bundle-modal-form.component';
import { UpgradeGroupItemComponent } from './item-modal/item-bundle-modal-form/upgrade-group-item/upgrade-group-item.component';
import { UpgradeGroupComponent } from './item-modal/item-bundle-modal-form/upgrade-group/upgrade-group.component';
import { ItemModalFooterComponent } from './item-modal/item-modal-footer/item-modal-footer.component';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { SingleItemModalFormComponent } from './item-modal/single-item-modal-form/single-item-modal-form.component';
import { MenuContentComponent } from './menu-content/menu-content.component';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { MenuItemImageComponent } from './menu-item-image/menu-item-image.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuSlotDirective } from './menu/menu-slot.directive';
import { MenuComponent } from './menu/menu.component';
import { MinimumOrderQuantityComponent } from './minimum-order-quantity/minimum-order-quantity.component';
import { MinimumOrderValueComponent } from './minimum-order-value/minimum-order-value.component';
import { NoWeworkFriendlyItemsComponent } from './no-wework-friendly-items/no-wework-friendly-items.component';
import { AllergenNameListPipe } from './pipes/allergen-name-list.pipe';
import { AllergenNamePipe } from './pipes/allergen-name.pipe';
import { DietNameListPipe } from './pipes/diet-name-list.pipe';
import { DietNamePipe } from './pipes/diet-name.pipe';
import { FoodTypeNamePipe } from './pipes/food-type-name.pipe';
import { ServingStylePipe } from './pipes/serving-style.pipe';
import { TranslateDeliverabilityProblemToMessagePipe } from './pipes/translate-deliverability-problem-to-message.pipe';
import { PriceGuaranteeComponent } from './price-guarantee/price-guarantee.component';
import { SectionComponent } from './section/section.component';
import { ServingStyleComponent } from './serving-style/serving-style.component';
import { SimpleMenuHeaderComponent } from './simple-menu-header/simple-menu-header.component';
import { SingleItemComponent } from './single-item/single-item.component';
import { StickyCartComponent } from './sticky-cart/sticky-cart.component';
import { VendorOrderDetailsComponent } from './vendor-order-details/vendor-order-details.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ImageComponentsModule,
    SwiperModule,
    AnalyticsEcommerceComponentsModule,
    CurrencyComponentsModule,
    InViewportModule,
    ModalComponentsModule,
    DatetimeComponentsModule,
    FormComponentsModule,
    TextComponentsModule,
    AnalyticsComponentsModule,
    ClickOutsideModule,
    TooltipComponentsModule,
    LoadingComponentsModule,
    ContextDropdownModule,
    FeedbackComponentsModule,
    VendorInfoComponentsModule,
    ScrollComponentsModule,
    PageBannerComponentsModule,
    PromotionModule,
    StickyComponentsModule,
  ],
  declarations: [
    MenuHeaderComponent,
    VendorOrderDetailsComponent,
    MinimumOrderValueComponent,
    DeliveryNoticeComponent,
    DietariesComponent,
    DietIconComponent,
    ItemGroupPromptComponent,
    MinimumOrderQuantityComponent,
    FeedsQuantityComponent,
    AdditionalItemInfoComponent,
    ServingStyleComponent,
    SingleItemComponent,
    CustomItemComponent,
    MenuItemComponent,
    MenuItemImageComponent,
    SectionComponent,
    MenuComponent,
    ItemBundleComponent,
    ItemModalComponent,
    ItemModalFooterComponent,
    CustomItemModalFormComponent,
    CustomItemModalFormSectionComponent,
    SingleItemModalFormComponent,
    FlexibleGroupComponent,
    FlexibleGroupItemComponent,
    ChoiceGroupComponent,
    ChoiceGroupItemComponent,
    FixedGroupComponent,
    FixedGroupItemComponent,
    UpgradeGroupComponent,
    UpgradeGroupItemComponent,
    ItemBundleModalFormComponent,
    MenuSlotDirective,
    MenuContentComponent,
    CartOverridesDialogComponent,
    CartComponent,
    CartItemComponent,
    CartToggleComponent,
    CartTotalComponent,
    CartDeliveryComponent,
    CartSubtotalComponent,
    CartNotesComponent,
    StickyCartComponent,
    DeliverabilityFlagComponent,
    ItemAddButtonComponent,
    HeaderBackBarComponent,
    CartScrollPanelComponent,
    DeletableCartItemComponent,
    PriceGuaranteeComponent,
    EcoFriendlyPackagingComponent,
    NoWeworkFriendlyItemsComponent,
    AboutVendorComponent,
    SimpleMenuHeaderComponent,
    AllergenNameListPipe,
    AllergenNamePipe,
    DietNameListPipe,
    DietNamePipe,
    FoodTypeNamePipe,
    ServingStylePipe,
    TranslateDeliverabilityProblemToMessagePipe,
    DietariesFilterBoxComponent,
  ],
  exports: [
    MenuHeaderComponent,
    VendorOrderDetailsComponent,
    MinimumOrderValueComponent,
    DeliveryNoticeComponent,
    DietariesComponent,
    DietIconComponent,
    ItemGroupPromptComponent,
    MinimumOrderQuantityComponent,
    FeedsQuantityComponent,
    AdditionalItemInfoComponent,
    ServingStyleComponent,
    SingleItemComponent,
    CustomItemComponent,
    MenuItemComponent,
    MenuItemImageComponent,
    SectionComponent,
    MenuComponent,
    ItemBundleComponent,
    ItemModalComponent,
    ItemModalFooterComponent,
    CustomItemModalFormComponent,
    CustomItemModalFormSectionComponent,
    SingleItemModalFormComponent,
    MenuSlotDirective,
    MenuContentComponent,
    CartOverridesDialogComponent,
    CartComponent,
    CartItemComponent,
    CartToggleComponent,
    CartTotalComponent,
    CartDeliveryComponent,
    CartSubtotalComponent,
    CartNotesComponent,
    StickyCartComponent,
    DeliverabilityFlagComponent,
    ItemAddButtonComponent,
    HeaderBackBarComponent,
    CartScrollPanelComponent,
    DeletableCartItemComponent,
    PriceGuaranteeComponent,
    EcoFriendlyPackagingComponent,
    NoWeworkFriendlyItemsComponent,
    AboutVendorComponent,
    SimpleMenuHeaderComponent,
    AllergenNameListPipe,
    AllergenNamePipe,
    DietNameListPipe,
    DietNamePipe,
    FoodTypeNamePipe,
    ServingStylePipe,
    TranslateDeliverabilityProblemToMessagePipe,
    DietariesFilterBoxComponent,
  ],
  providers: [
    AllergenNameListPipe,
    AllergenNamePipe,
    DietNameListPipe,
    DietNamePipe,
    FoodTypeNamePipe,
    ServingStylePipe,
    TranslateDeliverabilityProblemToMessagePipe,
  ],
})
export class MenuModule {
}
