import { DayOfWeek } from '../day-of-week.enum';
import { VendorLocation } from './vendor-location.model';

export interface OperatingSchedule {
  days: DayOfWeek[];
  fromHour: number;
  toHour: number;
  shortNotice: boolean;
}

export function createOperatingSchedulesFromVendorLocationJson(json: Partial<VendorLocation>): OperatingSchedule[] {
  if (json.operatingSchedules && json.operatingSchedules.length > 0) {
    return json.operatingSchedules.map((operatingSchedule) => ({
      days: operatingSchedule.days,
      fromHour: operatingSchedule.fromHour,
      toHour: operatingSchedule.toHour,
      shortNotice: operatingSchedule.shortNotice
    }));
  }

  return [];
}
