import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, objectToParams } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  ChoiceDeadline,
  createDeliverableMenuFromJson,
  createMenuContentFromJson,
  createMenuFromJson,
  DeliverableMenu,
  DeliverableMenuError,
  DeliverableMenuErrorReasons,
  ErrorCodes,
  ErrorResponse,
  Item,
  ItemId,
  Menu,
  MenuContent,
  MinorCurrency,
  minorCurrencyToMajor,
  OrderId,
  Vendor,
  VendorId,
  VendorLocationSlug,
  VendorSlug
} from '@citypantry/util-models';
import moment from 'moment';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import Moment = moment.Moment;

export interface DeliverableMenuResponse {
  menu?: DeliverableMenu;
  error?: DeliverableMenuError;
}

export interface DeliverableMenuItemsResponse {
  items?: ItemId[];
  error?: DeliverableMenuError;
}

@Injectable({
  providedIn: 'root'
})
export class MenuApi {
  constructor(
    private http: HttpClient,
  ) {}

  public getDeliverableMenu(
    vendorIdOrSlug: VendorId | VendorSlug,
    locationSlug: VendorLocationSlug | null,
    date: moment.Moment,
    postcode: string | null,
    currentMenuVendor$: Observable<Vendor | null>, // TODO CPD-11600 this might not be a good idea to pass in; determine why it's necessary
    choiceDeadline?: ChoiceDeadline, // currently only passed when loading a menu during IC create/edit in order to determine vendor location's remaining capacity
  ): Observable<DeliverableMenuResponse> {
    const params: { postcode?: string, locationSlug?: string, choiceDeadline?: string } = {};

    if (locationSlug) {
      params.locationSlug = locationSlug;
    }

    if (postcode) {
      params.postcode = postcode;
    }

    if (choiceDeadline) {
      params.choiceDeadline = choiceDeadline;
    }

    return this.http.get(`/public/deliverable-menus/${vendorIdOrSlug}/${date.toISOString()}`, {
      params,
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' } // We need to be able to handle 404s manually
    }).pipe(
      this.processDeliverableMenu(),
      withLatestFrom(currentMenuVendor$),
      map(([response, vendor]) => this.dateAwareResponse(date, response, vendor)),
      catchError(handleApiError)
    );
  }

  public getDeliverableMenuItemIds(
    vendorIdOrSlug: VendorId | VendorSlug,
    vendorLocationSlug: VendorLocationSlug,
    date: moment.Moment,
    postcode: string | null,
    budget: MinorCurrency | null,
    choiceDeadline: ChoiceDeadline | null,
  ): Observable<DeliverableMenuItemsResponse> {
    const params: {
      postcode?: string;
      budget?: string;
      choiceDeadline?: ChoiceDeadline;
    } = {};

    if (postcode) {
      params.postcode = postcode;
    }

    if (budget) {
      params.budget = minorCurrencyToMajor(budget).toString();
    }

    if (choiceDeadline) {
      params.choiceDeadline = choiceDeadline;
    }

    return this.http.get(`/public/deliverable-menus/${vendorIdOrSlug}/${date.toISOString()}/${vendorLocationSlug}/items`, {
      params,
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }, // We need to be able to handle 404s manually
    }).pipe(this.processDeliverableMenuItems());
  }

  public getDeliverableMenuForOrder(orderId: OrderId): Observable<DeliverableMenuResponse> {
    return this.http.get(`/orders/${orderId}/menu`, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' } // We need to be able to handle 404s manually
    }).pipe(this.processDeliverableMenu());
  }

  public getCompanyHolidays(): Observable<Moment[]> {
    return this.http.get(`/company-holidays`).pipe(
      map((response: any): Moment[] => response.data.map((date: string): Moment => moment.tz(date, 'Europe/London')))
    );
  }

  // When contentId is provided, we expect this endpoint to return the specified MenuContent in menu.active field.
  public getMenu(id: string, contentId?: string): Observable<Menu> {
    return this.http.get(`/menus/${id}`, { params: objectToParams({ contentId }) }).pipe(
      getItem(),
      map(createMenuFromJson),
    );
  }

  public getMenuContent(menuContentId: string): Observable<MenuContent> {
    return this.http.get(`/immutableMenuContent/${menuContentId}`).pipe(
      getItem(),
      map(createMenuContentFromJson)
    );
  }

  private processDeliverableMenu(): UnaryFunction<Observable<Object>, Observable<DeliverableMenuResponse>> {
    return pipe(
      getItem(),
      map((data: DeliverableMenu): DeliverableMenuResponse => {
        if (!data) {
          return {};
        }
        const menu = createDeliverableMenuFromJson(data);
        return {
          menu
        };
      }),
      catchError(handleApiError),
    );
  }

  // Checks if Vendor is on holiday or otherwise unavailable on the given day
  private dateAwareResponse(date: Moment, response: DeliverableMenuResponse, vendor: Vendor | null): DeliverableMenuResponse {
    if (vendor && response.menu && !response.menu.date.isSame(date, 'day')) {
      const error: DeliverableMenuError = {
        key: ErrorCodes.MENU_NOT_FOUND,
        messageArgs: {
          reason: DeliverableMenuErrorReasons.VENDOR_ON_HOLIDAY,
          deliveryDate: date.toISOString(),
          vendorName: vendor.name,
          vendorHeroImageUrl: null,
          vendorAvatarUrl: vendor.avatarUrl
        }
      };

      return { error };
    }

    return response;
  }

  private processDeliverableMenuItems(): UnaryFunction<Observable<Object>, Observable<DeliverableMenuItemsResponse>> {
    return pipe(
      getItems(),
      map((items: Partial<Item>[]): DeliverableMenuItemsResponse => ({ items: items.map((item) => item.id) })),
      catchError(handleApiError),
    );
  }
}

function handleApiError(response: HttpErrorResponse | ErrorResponse): Observable<{ error: DeliverableMenuError }> {
  if (response instanceof ErrorResponse && response.findMessageWithCode(ErrorCodes.MENU_NOT_FOUND)) {
    return of({
      error: response.messages[0] as DeliverableMenuError
    });
  }
  return throwError(response);
}
