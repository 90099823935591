import { Injectable } from '@angular/core';
import { getDefaultDeliveryDate, RecommendationRequest, StaticRecommendedVendors } from '@citypantry/util-models';

// This service is currently tightly coupled with RecommendationEffects.
// Move it to a better place if it needs to be used with some other file.
@Injectable({
  providedIn: 'root'
})
export class StaticRecommendedVendorsService {
  private defaultPostcode: string;

  constructor() {
    this.defaultPostcode = 'EC4M 7AA';
  }

  public getStaticRecommendedVendorRequests(): RecommendationRequest[] {
    return StaticRecommendedVendors.values.map((staticRecommendedVendor: StaticRecommendedVendors) => ({
      text: (staticRecommendedVendor as string),
      date: getDefaultDeliveryDate(),
      postcode: this.defaultPostcode,
      excludeVendorId: null
    }));
  }
}
