import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';

/**
 * Singleton class instantiated by the AppModule to keep an instance of the store
 * so that it could be injected into and used by our custom store decorators.
 */
@Injectable({
  providedIn: 'root',
})
export class StoreInjector {
  public static store: Store<AppState> | null = null;

  constructor(store: Store<AppState>) {
    StoreInjector.store = store;
  }
}
