import { NgModule } from '@angular/core';
import { InactiveRouterLinkDirective } from './inactive-router-link.directive';

@NgModule({
  declarations: [
    InactiveRouterLinkDirective
  ],
  exports: [
    InactiveRouterLinkDirective
  ]
})
export class RouterComponentsModule {}
