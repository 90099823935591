/**
 * Normalises a postcode to match UK format.
 *
 * @param {string | null | void} text Input text - can be empty
 * @returns {string | null} `null` if the input is empty,
 *                          a formatted uppercase postcode string if the input is a valid postcode,
 *                          or the original string if it is not a valid postcode.
 */
export function normalisePostcode(text: string | null | void): string | null {
  text = (text || '').trim();
  if (!text) {
    return null;
  }

  /**
   Formats:
   AA9A 9AA
   AA99 9AA
   AA9 9AA
   A9A 9AA
   A99 9AA
   A9 9AA
   @see https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
   */
  const pattern = /^\s*([a-z]{1,2}\d[a-z\d]?)\s*(\d[a-z]{2})\s*$/i;

  const match = text.match(pattern);
  if (!match) {
    return text;
  }
  return `${match[1].toLocaleUpperCase()} ${match[2].toLocaleUpperCase()}`;
}

/**
 * Curried postcode comparison function.
 *
 * @example
 * postcodeMatches('aa1 1aa', 'AA11AA'); // true
 * postcodes.find(postcodeMatches('aa1 1aa')); // returns first result where postcode matches
 */
export function postcodeMatches(postcodeA: string): (postcodeB: string) => boolean;
export function postcodeMatches(postcodeA: string, postcodeB: string): boolean;
export function postcodeMatches(postcodeA?: string, postcodeB?: string): boolean | ((postcode: string) => boolean) {
  const normalisedA = normalisePostcode(postcodeA);
  const hasTwoArguments = typeof postcodeB !== 'undefined';

  if (hasTwoArguments) {
    return normalisePostcode(postcodeA) === normalisePostcode(postcodeB);
  } else {
    return (b: string) => normalisedA === normalisePostcode(b);
  }
}
