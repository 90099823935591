import { freeze } from '@citypantry/util';
import moment, { Moment } from 'moment';
import { MajorCurrency } from '../money';
import { VendorLocationId, VendorLocationSlug } from '../vendor';
import { VendorId } from '../vendor/vendor.model';
import { CartItem, createCartItemFromJson } from './cart-item.model';

export interface Cart {
  // Only exists on persisted carts
  id?: string;

  // Cart configuration // TODO CPD-2736 move to the root
  postcode: string | null;
  deliveryDate: Moment;
  includeCutlery: boolean;
  notes: string;

  // Set on initialisation
  menuId: string;
  contentId: string;
  vendorId: VendorId;
  currencyIsoCode: string;

  // vendor location related properties are nullable due to old orders where they will not be returned by the API
  vendorLocationId: VendorLocationId | null;
  vendorLocationSlug: VendorLocationSlug | null;
  vendorLocationPublicName: string | null;

  // Items
  cartItems: CartItem[];

  // Frontend-only overrides - may not need to be sent to backend
  manualHeadcount: number;
  // Frontend-only
  isFreeDelivery: boolean;

  // Calculated properties - never set manually
  price: CartPrice;
  headcount: number;
  loyaltyPoints: number;

  // Only exists on meal plan carts
  requiredHeadcount?: number; // TODO CPD-2737 remove, it can be fetched from mealplan.requirementsAtGeneration
}

export interface CartPrice {
  items: MajorCurrency;
  delivery: MajorCurrency;
  deliveryVat: MajorCurrency;
  serviceFee: MajorCurrency;
  serviceFeeVat: MajorCurrency;
  total: MajorCurrency;
}

export const EMPTY_CART_PRICE = {
  items: 0,
  delivery: 0,
  deliveryVat: 0,
  serviceFee: 0,
  serviceFeeVat: 0,
  total: 0,
};
freeze(EMPTY_CART_PRICE);

export function createCartFromJson(json: Partial<Cart> = {}): Cart {
  const cart: Partial<Cart> = {};

  cart.id = json.id || null;
  cart.postcode = json.postcode || '';
  cart.deliveryDate = json.deliveryDate ? moment.tz(json.deliveryDate, 'Europe/London') : null;
  cart.includeCutlery = json.includeCutlery || false;
  cart.notes = json.notes || '';
  cart.menuId = json.menuId || null;
  cart.contentId = json.contentId || null;
  cart.vendorId = json.vendorId || null;
  cart.vendorLocationId = json.vendorLocationId || null;
  cart.cartItems = (json.cartItems || []).map(createCartItemFromJson);
  cart.manualHeadcount = json.manualHeadcount || 0;
  cart.isFreeDelivery = json.isFreeDelivery || false;
  cart.headcount = json.headcount || 0;
  cart.requiredHeadcount = json.requiredHeadcount || null;
  cart.loyaltyPoints = json.loyaltyPoints || 0;
  cart.currencyIsoCode = json.currencyIsoCode;
  cart.vendorLocationSlug = json.vendorLocationSlug || null;
  cart.vendorLocationPublicName = json.vendorLocationPublicName || null;

  // this field is currently no longer returned from any API endpoint,
  // but we still want to use it on frontend and initialise it here with default value
  cart.price = json.price || JSON.parse(JSON.stringify(EMPTY_CART_PRICE));

  return cart as Cart;
}
