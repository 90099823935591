import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchPromoCardModel } from '@citypantry/util-models';

@Component({
  selector: 'app-search-promo-card',
  templateUrl: './search-promo-card.component.html',
})
export class SearchPromoCardComponent {

  @Input()
  public promotion: SearchPromoCardModel;

  @Output()
  public close: EventEmitter<SearchPromoCardModel> = new EventEmitter();

  @Output()
  public buttonClick: EventEmitter<SearchPromoCardModel> = new EventEmitter();

  public buttonClicked(): void {
    if (this.promotion) {
      this.buttonClick.emit(this.promotion);
    }
  }

  public closeClicked(): void {
    if (this.promotion) {
      this.close.emit(this.promotion);
    }
  }
}
