import { Component, Input } from '@angular/core';
import { numberOfAllergensSet, numberOfVisibleDietariesSet, SingleItem } from '@citypantry/util-models';

@Component({
  selector: 'app-single-item-modal-form',
  templateUrl: './single-item-modal-form.component.html',
})
export class SingleItemModalFormComponent {
  @Input()
  public item: SingleItem;

  public shouldShowAllergens(): boolean {
    return this.item && numberOfAllergensSet(this.item.allergens) > 0;
  }

  public shouldShowDietaries(): boolean {
    return this.item && numberOfVisibleDietariesSet(this.item.dietaries) > 0;
  }
}
