import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const individualChoiceNewLocationRules: AnalyticsRule[] = [
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'location-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Location Name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'company-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Company Name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'building-name-or-floor-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Building Name / Floor Number field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'street-address',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Street address field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'address-line-3',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Address Line 3 field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'city',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `City field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'postcode',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Postcode field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'country',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Country field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'drop-off-instructions',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Drop off instructions entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'delivery-contact-picker',
    events: 'change',
    definition: {
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Delivery contact selected`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'first-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `First name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'last-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Last name field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Email field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'phone-number',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Phone number field entered`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'email-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Email delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'sms-delivery',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `SMS delivery notifications toggle -  ${event.sourceElement.nativeElement.checked ? 'ON' : 'OFF'}`,
    }),
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'cancel-changes',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `CANCEL CHANGES button clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'save-and-continue',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `SAVE AND CONTINUE button clicked`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'dialog-close',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Delivery location error modal closed`,
    },
  },
  {
    url: '/menus/individual-choice/checkout/new-location',
    path: 'dialog-confirm',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.IC_CHECKOUT_NEW_LOCATION,
      label: `Delivery location error modal start new order clicked`,
    },
  },
];
