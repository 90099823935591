import { LostbarSearchResult } from './lostbar-search-result.model';
import { LostbarAction, LostbarActions } from './lostbar.actions';

export const FEATURE_STATE_NAME = 'lostbar';

export interface LostbarState {
  searchResults: LostbarSearchResult[];
}

export const INITIAL_STATE: LostbarState = {
  searchResults: [],
};

export function reducer(state: LostbarState = INITIAL_STATE, action: LostbarAction): LostbarState {
  switch (action.type) {
    case LostbarActions.searchResultsLoadSuccess.type: {
      const { results } = action;

      return {
        ...state,
        searchResults: results
      };
    }

    default:
      return state;
  }
}
