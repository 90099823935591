import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from '@citypantry/components-click-outside';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';

import { DialogComponent } from './dialog/dialog.component';
import { LogoPopupComponent } from './logo-popup/logo-popup.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  imports: [
    CommonModule,
    ClickOutsideModule,
    AnalyticsComponentsModule,
  ],
  declarations: [
    ModalComponent,
    LogoPopupComponent,
    DialogComponent,
  ],
  exports: [
    ModalComponent,
    LogoPopupComponent,
    DialogComponent,
  ]
})
export class ModalComponentsModule { }
