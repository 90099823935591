import { Injectable } from '@angular/core';
import { Dispatch } from '@citypantry/state';
import { CustomerQualification } from '@citypantry/util-models';
import { Action } from '@ngrx/store';
import { CustomerQualificationActions } from './customer-qualification.actions';

@Injectable({ providedIn: 'root' })
export class CustomerQualificationService {

  @Dispatch()
  public showQualificationModal(): Action {
    return CustomerQualificationActions.showQualificationModal();
  }

  @Dispatch()
  public qualifyCustomer(qualificationData: CustomerQualification): Action {
    return CustomerQualificationActions.qualifyCustomer({ qualificationData });
  }
}
