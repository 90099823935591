/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type VatRateType =
  'UK_ZERO_RATE'
  | 'UK_REDUCED_RATE'
  | 'UK_STANDARD_RATE'
  | 'IE_ZERO_RATE'
  | 'IE_REDUCED_RATE'
  | 'IE_STANDARD_RATE';

export class VatRateTypes {
  public static UK_ZERO_RATE:     VatRateType & 'UK_ZERO_RATE'     = 'UK_ZERO_RATE';
  public static UK_REDUCED_RATE:  VatRateType & 'UK_REDUCED_RATE'  = 'UK_REDUCED_RATE';
  public static UK_STANDARD_RATE: VatRateType & 'UK_STANDARD_RATE' = 'UK_STANDARD_RATE';
  public static IE_ZERO_RATE:     VatRateType & 'IE_ZERO_RATE'     = 'IE_ZERO_RATE';
  public static IE_REDUCED_RATE:  VatRateType & 'IE_REDUCED_RATE'  = 'IE_REDUCED_RATE';
  public static IE_STANDARD_RATE: VatRateType & 'IE_STANDARD_RATE' = 'IE_STANDARD_RATE';

  public static get values(): VatRateType[] {
    return [
      VatRateTypes.UK_ZERO_RATE,
      VatRateTypes.UK_REDUCED_RATE,
      VatRateTypes.UK_STANDARD_RATE,
      VatRateTypes.IE_ZERO_RATE,
      VatRateTypes.IE_REDUCED_RATE,
      VatRateTypes.IE_STANDARD_RATE,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type VatRateTypeEnum = typeof VatRateTypes;

export const zeroVatRateTypes = [VatRateTypes.UK_ZERO_RATE, VatRateTypes.IE_ZERO_RATE];
