/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type OrderStatus =
  'draft'
  | 'placed'
  | 'vendor_approval_pending'
  | 'confirmed'
  | 'being_prepared'
  | 'ready_for_collection'
  | 'left_the_kitchen'
  | 'delivered'
  | 'cancelled';

export class OrderStatuses {
  public static DRAFT:                   OrderStatus & 'draft'                   = 'draft';
  public static PLACED:                  OrderStatus & 'placed'                  = 'placed';
  public static VENDOR_APPROVAL_PENDING: OrderStatus & 'vendor_approval_pending' = 'vendor_approval_pending';
  public static CONFIRMED:               OrderStatus & 'confirmed'               = 'confirmed';
  public static BEING_PREPARED:          OrderStatus & 'being_prepared'          = 'being_prepared';
  public static READY_FOR_COLLECTION:    OrderStatus & 'ready_for_collection'    = 'ready_for_collection';
  public static LEFT_THE_KITCHEN:        OrderStatus & 'left_the_kitchen'        = 'left_the_kitchen';
  public static DELIVERED:               OrderStatus & 'delivered'               = 'delivered';
  public static CANCELLED:               OrderStatus & 'cancelled'               = 'cancelled';

  public static get values(): OrderStatus[] {
    return [
      OrderStatuses.DRAFT,
      OrderStatuses.PLACED,
      OrderStatuses.VENDOR_APPROVAL_PENDING,
      OrderStatuses.CONFIRMED,
      OrderStatuses.BEING_PREPARED,
      OrderStatuses.READY_FOR_COLLECTION,
      OrderStatuses.LEFT_THE_KITCHEN,
      OrderStatuses.DELIVERED,
      OrderStatuses.CANCELLED,
    ];
  }

  // Do not modify this line - Custom code below here

  // values ordered from least to most advanced, excluding CANCELLED
  public static get orderedValues(): OrderStatus[] {
    return [
      OrderStatuses.DRAFT,
      OrderStatuses.PLACED,
      OrderStatuses.VENDOR_APPROVAL_PENDING,
      OrderStatuses.CONFIRMED,
      OrderStatuses.BEING_PREPARED,
      OrderStatuses.READY_FOR_COLLECTION,
      OrderStatuses.LEFT_THE_KITCHEN,
      OrderStatuses.DELIVERED,
    ];
  }
}

export type OrderStatusEnum = typeof OrderStatuses;
