/// <reference types="google.maps" />

import { Injectable } from '@angular/core';
import { GoogleMapsApis } from './google-maps-apis';
import { SessionTokenManager } from './session-token-manager';

@Injectable({ providedIn: 'root' })
export class GoogleMapsPlaces {
  constructor(
    private mapsApis: GoogleMapsApis,
    private sessionTokenManager: SessionTokenManager,
  ) {
  }

  public async getDetails(request: google.maps.places.PlaceDetailsRequest): Promise<google.maps.places.PlaceResult> {
    const placesNative = await this.mapsApis.places;
    return new Promise((resolve, reject) => {
      if (!request.sessionToken && this.sessionTokenManager.hasToken()) {
        request.sessionToken = this.sessionTokenManager.popToken();
      }

      placesNative.getDetails(request, (result: any, status: string): void => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
          return;
        }
        resolve(result);
      });
    });
  }
}
