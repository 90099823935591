import { Inject, Injectable } from '@angular/core';
import { ErrorService } from '@citypantry/shared-error';
import moment from 'moment';
import { MarketingCampaignComponentName } from './marketing-campaign-component-name.enum';
import { MarketingCampaignComponentModel } from './marketing-campaign-component.model';
import { MARKETING_CAMPAIGNS } from './marketing-campaign-content';
import { MarketingCampaignModel } from './marketing-campaign.model';

@Injectable() // don't use `providedIn: 'root'`, because it's provided in the module, alongside MARKETING_CAMPAIGNS token
export class MarketingCampaignService {
  constructor(
    @Inject(MARKETING_CAMPAIGNS) private marketingCampaigns: MarketingCampaignModel[],
    private errorService: ErrorService,
  ) {}

  public getMarketingCampaignComponentModel(componentName: MarketingCampaignComponentName): MarketingCampaignComponentModel | null {
    const marketingCampaign = this.getMarketingCampaign();

    if (marketingCampaign === null) {
      return null;
    }

    const activeComponents = marketingCampaign.activeComponents.filter(
      (marketingCampaignComponent) => marketingCampaignComponent.name === componentName
    );

    if (activeComponents.length > 1) {
      const message = `More than one Marketing Campaign Component configured for ${marketingCampaign.marketingCampaignName} campaign: ${
        activeComponents
          .map((component) => component.name)
          .filter((component, pos, componentNames) => {
            return componentNames.indexOf(component) === pos;
          }).join(', ')}`;
      this.errorService.logError(message, false);
    }

    if (activeComponents.length === 0) {
      return null;
    }

    return {
      ...activeComponents[0],
      analyticsPrefix: marketingCampaign.analyticsPrefix,
    };
  }

  private getMarketingCampaign(now: moment.Moment = moment.tz('Europe/London')): MarketingCampaignModel | null {
    const marketingCampaigns = this.marketingCampaigns.filter((campaignModel) => {
      const nowIsAfterCampaignStartDate = (campaignModel.activeFrom) ? now.isSameOrAfter(campaignModel.activeFrom) : true;
      const nowIsBeforeCampaignEndDate = (campaignModel.activeTo) ? now.isSameOrBefore(campaignModel.activeTo) : true;

      return nowIsAfterCampaignStartDate && nowIsBeforeCampaignEndDate;
    });

    if (marketingCampaigns.length > 1) {
      const message = `More than one Marketing Campaign set to run on this date: ${
        marketingCampaigns
          .map((marketingCampaignModel) => marketingCampaignModel.marketingCampaignName)
          .filter((campaign, pos, campaignNames) => {
            return campaignNames.indexOf(campaign) === pos;
          })
          .join(', ')}`;
      this.errorService.logError(message, false);
    }

    return marketingCampaigns.length === 0 ? null : marketingCampaigns[0];
  }
}
