import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-compact-input-wrapper',
  templateUrl: './compact-input-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactInputWrapperComponent {

  @Input()
  public icon: string;

  @Input()
  public label: string;

  @Input()
  public currentValue: string;

  @Input()
  public type: 'choice' | 'switch' | void;

  @Input()
  public isSwitched: boolean;

  @Input()
  public switchedLabel: string;

  @Output()
  public focusInput: EventEmitter<void> = new EventEmitter();

  @Output()
  public blurInput: EventEmitter<void> = new EventEmitter();

  @Output()
  public unswitch: EventEmitter<void> = new EventEmitter();

  @ViewChild('iconRef', { static: false })
  public iconRef: ElementRef;

  public isActive: boolean;

  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  public get hasError(): boolean {
    return this._hasError;
  }

  private _isDisabled: boolean;
  private _hasError: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private element: ElementRef
  ) {
    this.isActive = false;
    this._isDisabled = false;
    this._hasError = false;
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:focusin', ['$event']) // If we get focus, set active; if someone else gets focus, blur
  public onMouseup(event: MouseEvent): void {
    if (this.isDisabled) {
      return;
    }

    if (this.type === 'switch' && this.isActive && this.isSwitched && this.iconRef && this.iconRef.nativeElement.contains(event.target)) {
      // When the user clicks on the icon in an active switch component (while it's switched),
      // skip the event because it'll trigger the "back" behaviour
      return;
    }

    if (this.element.nativeElement.contains(event.target)) {
      this.setActive();
    } else {
      this.setInactive();
    }
  }

  public onIconClick(): void {
    if (this.isDisabled) {
      return;
    }

    if (this.isSwitched) {
      this.isSwitched = false;
      this.unswitch.emit();
    }
  }

  public setDisabled(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  public setValid(isValid: boolean): void {
    this._hasError = !isValid;
    this.cdr.markForCheck();
  }

  private setActive(): void {
    if (this.isActive) {
      return;
    }
    this.isActive = true;
    setTimeout(() => {
      this.focusInput.emit();
    });
  }

  private setInactive(): void {
    if (!this.isActive) {
      return;
    }
    this.isActive = false;
    this.blurInput.emit();
  }
}
