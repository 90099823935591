import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from '@citypantry/components-click-outside';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LostbarContainerComponent } from './lostbar-container.component';
import { LostbarComponent } from './lostbar.component';
import { LostbarEffects } from './state/lostbar.effects';
import { FEATURE_STATE_NAME, reducer } from './state/lostbar.state';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AnalyticsComponentsModule,
    ClickOutsideModule,
    EffectsModule.forFeature([
      LostbarEffects
    ]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
  declarations: [
    LostbarComponent,
    LostbarContainerComponent
  ],
  exports: [
    LostbarContainerComponent
  ]
})
export class LostbarModule { }
