import { concat, UnreachableCaseError } from '@citypantry/util';
import {
  CartCustomItem,
  CartCustomItemOption,
  CartItem,
  CartItemBundle,
  CartItemBundleGroup,
  CartItemTypes,
  CartSingleItem,
  CustomItem,
  CustomItemOption,
  isBundleCartItem,
  isCustomCartItem,
  isCustomItem,
  isItemBundle,
  isSingleCartItem,
  isSingleItem,
  isUpgradeItemGroup,
  Item,
  ItemBundle,
  ItemGroup,
  ItemGroupTypes,
  MajorCurrency,
  safeAddCurrency,
  safeMultiplyCurrency,
  SingleItem,
  UpgradeItemGroup,
} from '@citypantry/util-models';

export interface CreateCartSingleItemPayload {
  item: SingleItem;
  quantity: number;
}

export interface CreateCartItemBundlePayload {
  item: ItemBundle;
  quantity: number;
  cartGroups: CartItemBundleGroup[];
}

export interface CreateCartCustomItemPayload {
  item: CustomItem;
  quantity: number;
  selectedOptions: CartCustomItemOption[];
}

interface AddOrUpdateCustomItemData {
  selectedOptions: CartCustomItemOption[];
}
interface AddOrUpdateItemBundleData {
  cartGroups: CartItemBundleGroup[];
}
type AddOrUpdateItemData = { quantity: number } & ({} | AddOrUpdateCustomItemData | AddOrUpdateItemBundleData);

function isItemBundlePayload(
  payload: CreateCartSingleItemPayload | CreateCartItemBundlePayload,
): payload is CreateCartItemBundlePayload {
  return (payload as CreateCartItemBundlePayload).cartGroups !== undefined;
}

/**
 * Adds to cart or updates item in cart.
 *
 * @param cartItemsState Current cart
 * @param item
 * @param cartIndex Cart index to update. If it's null, for Single and Custom items new item instance is added to the cart
 * @param updateData Contains info that we want to update, typically coming from some user input
 *
 * @return Updated cart contents.
 */
function addOrUpdateItem(
  cartItemsState: CartItem[],
  item: Item,
  cartIndex: number | null,
  updateData: AddOrUpdateItemData,
): CartItem[] {
  let result = cartItemsState || [];

  const { quantity } = updateData;

  if (cartIndex === null) {
    cartIndex = cartItemsState.findIndex((cartItem) => cartItem.item.id === item.id);
  }

  if (item) {
    if (quantity <= 0 && cartIndex >= 0) {
      result = removeItem(cartItemsState, cartIndex);
    } else {
      if (isSingleItem(item)) {
        result = addOrUpdateSingleItem(cartItemsState, item, quantity);
      } else if (isCustomItem(item)) {
        const { selectedOptions } = (updateData as AddOrUpdateCustomItemData);
        result = addOrUpdateCustomItem(cartItemsState, item, cartIndex, selectedOptions, quantity);
      } else if (isItemBundle(item)) {
        const { cartGroups } = (updateData as AddOrUpdateItemBundleData);
        result = addOrUpdateItemBundle(cartItemsState, item, cartGroups, quantity);
      }
    }
  }

  return result;
}

function addSingleItem(cartItemsState: CartItem[], item: SingleItem, quantity: number): CartItem[] {
  return [
    ...cartItemsState,
    createCartItem({ item, quantity }),
  ];
}

function updateSingleItem(cartItemsState: CartItem[], cartIndex: number, quantity: number): CartItem[] {
  const cartItems = [...cartItemsState];
  const cartItem = cartItems[cartIndex];

  if (!cartItem || !isSingleCartItem(cartItem)) {
    return cartItems;
  }

  const price = calculateSingleItemPrice(
    cartItem.item,
    quantity,
  );

  const newCartItem: CartItem = {
    ...cartItem,
    quantity,
    price,
  };

  return cartItems.map((item, index) => index === cartIndex ? newCartItem : item);
}

/**
 * Add a single item to the cart or update an existing cart item if the single item already exists in cart
 *
 * @param cartItemsState Current cart
 * @param item Item to add or update
 * @param quantity The quantity to give the new or updated single item.
 *   If null is passed, a new item's quantity will be set to 1, an updated item's quantity will be set to the item's existing quantity + 1
 *   If 0 is passed the item will not be updated or added
 */
function addOrUpdateSingleItem(cartItemsState: CartItem[], item: SingleItem, quantity: number | null): CartItem[] {
  if (quantity !== 0) {
    const currentItemIndex = cartItemsState.findIndex((cartItem) => cartItem.item.id === item.id);
    if (currentItemIndex > -1) {
      const newQuantity = quantity || (cartItemsState[currentItemIndex].quantity + 1);
      return updateSingleItem(cartItemsState, currentItemIndex, newQuantity);
    } else {
      return addSingleItem(cartItemsState, item, quantity || 1);
    }
  }
  return cartItemsState;
}

function addCustomItem(
  cartItemsState: CartItem[],
  item: CustomItem,
  quantity: number,
  selectedOptions: CartCustomItemOption[],
): CartItem[] {
  return [
    ...cartItemsState,
    createCartItem({ item, quantity, selectedOptions }),
  ];
}

/**
 * Update the custom item at the given position in the cart.
 *
 * @param cartItemsState Current cart
 * @param cartIndex Position to update
 * @param quantity The new quantity
 * @param selectedOptions Custom Item options. Preserves the existing options if null is passed (for
 *   updating only the quantity).
 */
function updateCustomItem(
  cartItemsState: CartItem[],
  cartIndex: number,
  quantity: number,
  selectedOptions: CartCustomItemOption[] | null,
): CartItem[] {
  const cartItems = [...cartItemsState];
  const cartItem = cartItems[cartIndex];

  if (!cartItem || !isCustomCartItem(cartItem)) {
    return cartItems;
  }

  const newSelectedOptions = selectedOptions !== null ? selectedOptions : cartItem.selectedOptions;

  const price = calculateCustomItemPrice(
    cartItem.item,
    quantity,
    newSelectedOptions,
  );

  const newCartItem: CartCustomItem = {
    ...cartItem,
    quantity,
    price,
    selectedOptions: newSelectedOptions,
    ageRestricted: isCustomItemAgeRestricted(cartItem.item, newSelectedOptions),
  };

  return cartItems.map((item, index) => index === cartIndex ? newCartItem : item);
}

/**
 * Add a custom item to the cart or update an existing cart item
 *
 * @param cartItemsState Current cart
 * @param item Item to add or update
 * @param cartIndex Position of custom item to update. If null a new item will be added
 * @param selectedOptions Custom Item options. Preserves the existing options if null is passed (for updating only the quantity).
 * @param quantity The quantity to give the new or updated custom item.
 *   If null is passed, a new item's quantity will be set to 1, an updated item's quantity will be set to the item's existing quantity + 1
 *   If 0 is passed the item will not be updated or added
 */
function addOrUpdateCustomItem(
  cartItemsState: CartItem[],
  item: CustomItem,
  cartIndex: number | null,
  selectedOptions: CartCustomItemOption[] | null,
  quantity: number | null,
): CartItem[] {
  const currentItem = cartItemsState[cartIndex];

  if (currentItem) {
    const newQuantity = quantity || (cartItemsState[cartIndex].quantity + 1);
    return updateCustomItem(cartItemsState, cartIndex, newQuantity, selectedOptions);
  } else {
    if (!selectedOptions) {
      return cartItemsState;
    }
    return addCustomItem(cartItemsState, item, quantity || 1, selectedOptions);
  }
}

function addItemBundle(cartItemsState: CartItem[], item: ItemBundle, quantity: number, groups: CartItemBundleGroup[]): CartItem[] {
  return [
    ...cartItemsState,
    createCartItem({ item, quantity, cartGroups: groups }),
  ];
}

/**
 * Update the item bundle at the given position in the cart.
 *
 * @param cartItemsState Current cart
 * @param cartIndex Position to update
 * @param quantity The new quantity
 * @param groups Item Bundle groups. Preserves the existing groups if null is passed (for
 *   updating only the quantity).
 */
function updateItemBundle(
  cartItemsState: CartItem[],
  cartIndex: number,
  quantity: number,
  groups: CartItemBundleGroup[] | null,
): CartItem[] {
  const cartItems = [...cartItemsState];
  const cartItem = cartItems[cartIndex];

  if (!cartItem || !isBundleCartItem(cartItem)) {
    return cartItems;
  }

  const price = calculateItemBundlePrice(
    cartItem.item,
    quantity,
    groups !== null ? groups : cartItem.groups,
  );

  const newCartItem: CartItemBundle = {
    ...cartItem,
    quantity,
    price,
    groups,
    ageRestricted: isCartItemBundleAgeRestricted(cartItem.item, groups),
  };

  return cartItems.map((item, index) => index === cartIndex ? newCartItem : item);
}

/**
 *
 * @param cartItemsState Current cart
 * @param item Item to add or update
 * @param groups Item Bundle groups. Preserves the existing groups if null is passed and bundle item already
 *   exists in cart (for updating only).
 * @param quantity The quantity to give the new or updated item bundle.
 *   If null is passed, a new item's quantity will be set to 1, an updated item's quantity will be set to the item's existing quantity + 1.
 *   If 0 is passed the item will not be updated or added
 */
function addOrUpdateItemBundle(
  cartItemsState: CartItem[],
  item: ItemBundle,
  groups: CartItemBundleGroup[] | null,
  quantity: number | null,
): CartItem[] {
  if (quantity !== 0) {
    const currentItemIndex = cartItemsState.findIndex((cartItem) => cartItem.item.id === item.id);
    if (currentItemIndex > -1) {
      const newQuantity = quantity || (cartItemsState[currentItemIndex].quantity + 1);
      return updateItemBundle(cartItemsState, currentItemIndex, newQuantity, groups);
    } else if (groups) {
      return addItemBundle(cartItemsState, item, quantity || 1, groups);
    }
  }
  return cartItemsState;
}

function removeItem(cartItemsState: CartItem[], cartIndex: number): CartItem[] {
  const cartItems = [...cartItemsState];
  const cartItem = cartItems[cartIndex];

  if (!cartItem) {
    return cartItems;
  }

  cartItems.splice(cartIndex, 1);
  return cartItems;
}

function getCartTotal(cartItemsState: CartItem[]): MajorCurrency {
  if (!cartItemsState) {
    return 0;
  }

  return cartItemsState
    .map((item) => item.price)
    .reduce((previous, current) => safeAddCurrency(previous, current), 0);
}

/**
 * Creates a new CartItem that can be merged into the cart items list.
 *
 * @param payload Payload containing item and user input data
 */
function createCartItem(payload: CreateCartSingleItemPayload | CreateCartItemBundlePayload | CreateCartCustomItemPayload): CartItem {
  // Note: item, which is a nested property of payload, can't be used as a discriminator in TypeScript,
  // which means you will not be notified when passing a wrong item type with wrong payload structure.

  if (isSingleItem(payload.item)) {
    const { item, quantity } = payload as CreateCartSingleItemPayload;
    const price = quantity === 0 ? 0 : calculateSingleItemPrice(item, quantity);
    const ageRestricted = !!item.ageRestricted;
    const cartItem: CartSingleItem = {
      type: CartItemTypes.SINGLE_ITEM,
      item,
      quantity: (quantity > 0 && item.minimumOrderQuantity) ? Math.max(quantity, item.minimumOrderQuantity) : quantity,
      price,
      ageRestricted,
    };

    return cartItem;
  }

  if (isCustomItem(payload.item)) {
    const { item, quantity, selectedOptions } = payload as CreateCartCustomItemPayload;
    const price = !quantity ? 0 : calculateCustomItemPrice(item, quantity, selectedOptions);
    const ageRestricted = isCustomItemAgeRestricted(item, selectedOptions);
    const cartItem: CartCustomItem = {
      type: CartItemTypes.CUSTOM_ITEM,
      item,
      quantity: (quantity !== 0 && item.minimumOrderQuantity) ? Math.max(quantity, item.minimumOrderQuantity) : quantity,
      price,
      selectedOptions,
      dietaries: null, // TODO CPD-9306 This property only needs to be computed on the backend for now
      ageRestricted,
    };

    return cartItem;
  }

  if (isItemBundle(payload.item)) {
    const { item, quantity, cartGroups } = payload as CreateCartItemBundlePayload;
    const price = quantity === 0 ? 0 : calculateItemBundlePrice(item, quantity, cartGroups);

    const groupQuantitiesArray: CartItemBundleGroup[] = [];
    item.groups.forEach((bundleGroup: ItemGroup, i: number) => {
      const groupQuantities: CartItemBundleGroup = {
        type: bundleGroup.type,
        cartItems: [],
      };
      bundleGroup.items.forEach((singleItem: SingleItem, j: number) => {
        if (cartGroups[i]) {
          groupQuantities.cartItems[j] = {
            type: CartItemTypes.SINGLE_ITEM,
            item: singleItem,
            quantity: cartGroups[i].cartItems[j].quantity,
          };
        } else {
          groupQuantities.cartItems[j] = null;
        }
      });
      groupQuantitiesArray.push(groupQuantities);
    });

    const cartItem: CartItemBundle = {
      type: CartItemTypes.ITEM_BUNDLE,
      item,
      quantity,
      groups: groupQuantitiesArray,
      price,
      ageRestricted: isCartItemBundleAgeRestricted(item, cartGroups),
    };

    return cartItem;
  }

  throw new UnreachableCaseError(payload.item);
}

function calculateSingleItemPrice(item: SingleItem, quantity: number): MajorCurrency {
  return safeMultiplyCurrency(item.price, quantity);
}

/**
 * Calculates the price of a bundle item.
 * @param item The bundle to calculate for.
 * @param bundleQuantity The quantity of this bundle.
 *                       For most bundles that will be a headcount but for platters etc. it's a number of items.
 * @param cartGroups Quantities for items in this bundle's groups.
 *                                  Only relevant for upgrades; in that case it denotes how many upgrades the user has
 *                                  picked. This is returned by the ItemBundle form.
 * @returns {number} The total price of this bundle, including all upgrades.
 */
function calculateItemBundlePrice(
  item: ItemBundle,
  bundleQuantity: number,
  cartGroups: CartItemBundleGroup[],
): MajorCurrency {
  const pairWithCartGroups =
    (group: ItemGroup, index: number): [ItemGroup, CartItemBundleGroup] => [group, cartGroups[index]];

  const calculateUpgradeGroupTotal = ([, cartGroup]: [UpgradeItemGroup, CartItemBundleGroup]): MajorCurrency => {
    return cartGroup.cartItems
      .map((cartItem: CartSingleItem) => calculateSingleItemPrice(cartItem.item, cartItem.quantity))
      .reduce((previous, current) => safeAddCurrency(previous, current), 0);
  };

  return safeAddCurrency(
    safeMultiplyCurrency(item.price, (bundleQuantity || 0)),
    item.groups
      // We need to pair the groups with their quantities first because they match by index; once we filter the index changes.
      .map(pairWithCartGroups)
      .filter(([group]) => isUpgradeItemGroup(group))
      .map(calculateUpgradeGroupTotal)
      .reduce((previous, current) => safeAddCurrency<MajorCurrency>(previous, current), 0)
  );
}

/**
 * Add up Custom Item base price and selected options prices taking into account specified quantities
 *
 * @param item Custom Item to calculate
 * @param quantity Quantity of the item
 * @param {CartCustomItemOption} selectedOptions Options selected by the user.
 *        Their `optionIndex` values should be mappable to `optionIndex` values of options contained in item sections.
 */
function calculateCustomItemPrice(
  item: CustomItem,
  quantity: number,
  selectedOptions: CartCustomItemOption[],
): MajorCurrency {
  if (!quantity) {
    return 0;
  }

  const calculateCartOptionTotal = ([option, cartOption]: [CustomItemOption, CartCustomItemOption]) => {
    return safeMultiplyCurrency(option.price, cartOption.quantity);
  };

  const oneItemTotal = safeAddCurrency(item.price,
    pairCartCustomItemOptionsWithCustomItemOptions(item, selectedOptions)
      .map(calculateCartOptionTotal)
      .reduce((previous, current) => safeAddCurrency(previous, current), 0)
  );

  return safeMultiplyCurrency(oneItemTotal, quantity);
}

/**
 * This method will map an array of CartCustomItemOption[] to a source CustomItem's CustomItemOption
 * using a 1 based optionIndex which both objects share, returning them as a an ordered pair.
 */
function pairCartCustomItemOptionsWithCustomItemOptions(
  item: CustomItem,
  selectedOptions: CartCustomItemOption[],
): [CustomItemOption, CartCustomItemOption][] {
  const itemOptions: CustomItemOption[] = item.sections
    .map((section) => section.options)
    .reduce(concat(), []);

  const pairWithItemOptions = (selectedOption: CartCustomItemOption): [CustomItemOption, CartCustomItemOption] => {
    const pairedOption = itemOptions.find((itemOption) => itemOption.optionIndex === selectedOption.optionIndex);
    return [pairedOption, selectedOption];
  };

  return selectedOptions
    .map(pairWithItemOptions)
    .filter(([customItemOption]) => customItemOption);  // Ignore options that don't exist on the item
}

function getCartIndexWhenAddingItem(item: Item, cartItems: CartItem[]): number | null {
  let cartIndex = null;

  if (isItemBundle(item)) {
    const foundIndex = cartItems.findIndex((cartItem) => cartItem.item.id === item.id);
    cartIndex = foundIndex === -1 ? null : foundIndex;
  }

  return cartIndex;
}

function isCustomItemAgeRestricted(item: CustomItem, selectedOptions: CartCustomItemOption[]): boolean {
  return item.ageRestricted ||
    pairCartCustomItemOptionsWithCustomItemOptions(item, selectedOptions).some(
      ([option, cartOption]) => cartOption.quantity > 0 && option.ageRestricted,
    );
}

function isCartItemBundleAgeRestricted(item: ItemBundle, cartGroups: CartItemBundleGroup[]): boolean {
  return item.groups.some(({ items, type }: ItemGroup, i: number) =>
    items.some((singleItem: SingleItem, j: number) => {
      if (!singleItem.ageRestricted) {
        return false;
      }

      if (type === ItemGroupTypes.FIXED_GROUP) {
        return true;
      }

      return cartGroups[i]
        && cartGroups[i].cartItems[j]
        && cartGroups[i].cartItems[j].quantity > 0;
    })
  );
}

/**
 * Given an array of CartItem[], determine if any are age restricted.
 *
 * The ageRestricted property is set upon creating a CartSingleItem, CartCustomItem (based on selected options)
 * and CartItemBundle (based on selected group items).
 *
 * The reason this needs to be done in the frontend too is that we need to know if a cart item is age restricted
 * before the request is sent to the API.
 *
 * @see createCartItem
 */
function areAnyCartItemsAgeRestricted(cartItems: CartItem[]): boolean {
  return cartItems.some((cartItem) => !!cartItem.ageRestricted);
}

export const CartManager = {
  addOrUpdateCustomItem,
  addOrUpdateItem,
  addOrUpdateItemBundle,
  addOrUpdateSingleItem,
  areAnyCartItemsAgeRestricted,
  calculateCustomItemPrice,
  calculateItemBundlePrice,
  calculateSingleItemPrice,
  createCartItem,
  getCartIndexWhenAddingItem,
  getCartTotal,
  isItemBundlePayload,
  removeItem,
  updateCustomItem,
  updateSingleItem,
};
