export type ColleagueNotificationPreferenceName = 'lunch' | 'dinner';

export interface ColleagueNotificationPreferences {
  name: ColleagueNotificationPreferenceName;
  sendEaterNewMealAlert: boolean;
  sendEaterOrderOpenAlert: boolean;
  sendEaterDeadlineReminder: boolean;
  sendEaterMealReminder: boolean;
}

export function createColleagueNotificationPreferencesFromJson(json: Partial<ColleagueNotificationPreferences>):
  ColleagueNotificationPreferences {
  return {
    name: json.name,
    sendEaterNewMealAlert: json.sendEaterNewMealAlert,
    sendEaterOrderOpenAlert: json.sendEaterOrderOpenAlert,
    sendEaterDeadlineReminder: json.sendEaterDeadlineReminder,
    sendEaterMealReminder: json.sendEaterMealReminder,
  };
}
