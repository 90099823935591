<!-- Adding --no-cost class to start it off with having the class, then the property will remove it if applicable -->
<div
  class="cart-delivery cart-delivery--no-cost"
  [class.cart-delivery--no-cost]="!showCost"
  [class.cart-delivery--no-edit]="locked"
  [tabindex]="locked ? -1 : 0"
  (click)="editDeliveryParams()"
  (enter)="editDeliveryParams()"
  test-id="root"
  analyticsId="ic-cart-search-params"
>
  <div class="cart-delivery__content">
    <p
      *ngIf="isFreeDelivery"
      class="heading-3 cart-delivery__title cart-text cart-text--title"
      test-id="freeDelivery"
    >
      Free Delivery
      <!-- stop propagation to prevent delivery edit being triggered on click -->
      <a
        href="https://citypantrysupport.zendesk.com/hc/en-us/articles/360007640133-What-is-the-free-delivery-offer-"
        (click)="$event.stopPropagation()"
        target="_blank"
        class="text-decoration-none"
      >
        <i class="icon icon--standard text-color-stone-2 ml-x-small" aria-hidden="true">help_outline</i>
      </a>
      <span class="cart-delivery__cost">
        <s
          *ngIf="deliveryCost"
          test-id="freeDeliveryCost"
          e2e-test-id="freeDeliveryCost"
        >
          {{ deliveryCost | currency:currencyCode }}
        </s>
        {{ 0 | currency:currencyCode }}
      </span>
    </p>

    <p
      *ngIf="!isFreeDelivery"
      class="heading-3 cart-delivery__title cart-text cart-text--title"
    >
      <span><!-- span is necessary to ensure that with flex:space-between, these words don't split -->
        Delivery
        <span *ngIf="locationNameOrPostcode" test-id="deliveryLocation">to {{ locationNameOrPostcode | truncate: 10 }} </span>
      </span>

      <i
        *ngIf="hasDeliveryFee"
        class="icon icon--standard text-color-stone-2 ml-x-small"
        aria-hidden="true"
        [tooltip]="deliveryFeeTooltip"
        tooltipPosition="bottom"
        test-id="deliveryFeeTooltip"
      >help_outline</i>

      <ng-template #deliveryFeeTooltip>
        <ng-container>
          Find out how we calculate our delivery fees
          <a
            class="text-decoration-underlined text-color-stone-2"
            target="_blank"
            href="https://help.citypantry.com/hc/en-us/articles/360021301253-How-much-do-you-charge-for-delivery-"
           >here</a>
        </ng-container>
      </ng-template>

      <span
        *ngIf="hasDeliveryFee"
        class="cart-delivery__cost"
        test-id="cost"
      >
        {{ (deliveryCost || 0) | currency:currencyCode }}
      </span>

      <span
        *ngIf="!hasDeliveryFee && hasServiceFee"
        class="cart-delivery__cost"
        test-id="costFree"
      >
        Free
      </span>
    </p>

    <p
      class="cart-delivery__details cart-text cart-text--spaced"
      test-id="params"
    >
      <span class="vertically-center"><!-- span is necessary to ensure that with flex:space-between, these words don't split -->
        <ng-container *ngIf="locationNameOrPostcode && deliveryDate; else noDeliveryDetails">
          {{ deliveryDate | deliveryWindow }} on {{ deliveryDate | dateInEuropeLondon:'DD/MM/YYYY' }}
        </ng-container>

        <ng-template #noDeliveryDetails>
          Delivery details not set
        </ng-template>

        <i class="material-icons cart-delivery__edit-icon ml-x-small" aria-hidden="true">edit</i>
      </span>

      <span
        *ngIf="deliveryVat > 0 && hasDeliveryFee"
        class="cart-delivery__cost"
        test-id="costVat"
      >
        +{{ (deliveryVat || 0) | currency:currencyCode }} VAT
      </span>
    </p>

    <p
      *ngIf="showDeliveryNotice"
      class="cart-text cart-text--light cart-text--spaced"
      test-id="notice"
      @slideInOut
    >
      Restaurant requires
      <span
        class="text-underline-dashed cursor-pointer"
        [tooltip]="deliveryDate ? deliveryNoticeTooltip : ''"
        tooltipPosition="top"
        test-id="deliveryNoticeTooltip"
      >{{ formattedDeliveryNoticeTime }} notice</span>
      before delivery
      <ng-template
        #deliveryNoticeTooltip
      >
        <ng-container *ngIf="deliveryDate">
          Based on your delivery time, the latest this restaurant can accept orders is
          <b>{{ formattedOrderCutOffTime }}</b>
        </ng-container>
      </ng-template>
    </p>

    <div class="cart-delivery__edit-button-wrapper">
      <!-- If the edit button had a nonnegative tabindex, the user could tab to it, which would cause issues -->
      <!-- because the browser scrolls focused elements into view. -->
      <!-- To circumvent this, we make the edit button not focusable (tabindex = -1), but make the delivery box focusable instead. -->
      <button
        class="cart-delivery__edit-button"
        test-id="editButton"
        tabindex="-1"
      >Edit</button>
    </div>
  </div>
</div>

<div class="cart-delivery cart-delivery--no-edit" *ngIf="hasServiceFee">
  <div class="cart-delivery__content">
    <p class="heading-3 cart-delivery__title cart-text cart-text--title"
    >
      Service Fee
      <span class="cart-delivery__cost" test-id="serviceFeeCost">{{ (serviceFeeNet || 0) | currency:currencyCode }}</span>
    </p>

    <p
      *ngIf="serviceFeeVat > 0"
      class="cart-delivery__details cart-text cart-text--spaced"
      test-id="serviceFeeParams"
    >
      <span
        class="cart-delivery__cost"
        test-id="serviceFeeCostVat"
      >
        +{{ (serviceFeeVat || 0) | currency:currencyCode }} VAT
      </span>
    </p>
  </div>
</div>
