import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '@citypantry/components-form';
import { TooltipComponentsModule } from '@citypantry/components-tooltip';
import { AnalyticsComponentsModule } from '@citypantry/shared-analytics';
import { DeliveryContactFormComponent } from './delivery-contact-form/delivery-contact-form.component';
import { DeliveryLocationFormComponent } from './delivery-location-form/delivery-location-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AnalyticsComponentsModule,
    FormComponentsModule,
    TooltipComponentsModule,
  ],
  declarations: [
    DeliveryContactFormComponent,
    DeliveryLocationFormComponent,
  ],
  exports: [
    DeliveryContactFormComponent,
    DeliveryLocationFormComponent,
  ]
})
export class LocationModule {}
