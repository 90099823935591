/* eslint-disable max-classes-per-file */ // These two directives are tightly coupled and should live in the same file
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, throttleTime } from 'rxjs/operators';

export interface ScrollSpyConfig {
  offsetPx?: number;
  offsetElement?: ElementRef;
  onlyMatchInside?: boolean;
}

interface TargetWithTopBottomPositions {
  target: ScrollSpyTargetDirective;
  top: number;
  bottom: number;
}

@Directive({
  selector: '[scrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit {

  @Input()
  public scrollSpyConfig: ScrollSpyConfig;

  @Output('scrollSpy') // eslint-disable-line @angular-eslint/no-output-rename
  public activeElement: EventEmitter<any> = new EventEmitter();

  private targets: ScrollSpyTargetDirective[];

  private scrollEvents: Subject<void>;

  constructor(
    @Inject(DOCUMENT) public document: Document
  ) {
    this.targets = [];
    this.scrollEvents = new Subject<void>();

    merge(
      // Make sure that we don't get too many events
      this.scrollEvents.pipe(debounceTime(100)),
      // But also make sure we get some while the scroll happens
      this.scrollEvents.pipe(throttleTime(60))
    ).pipe(
      map(this.findActiveElement.bind(this)),
      filter((value) => value !== null),
      distinctUntilChanged(),
    )
      .subscribe(this.activeElement.next.bind(this.activeElement));
  }

  @HostListener('window:scroll')
  public onWindowScroll(): void {
    this.scrollEvents.next();
  }

  public ngAfterViewInit(): void {
    const initialElement = this.findActiveElement();
    if (initialElement) {
      setTimeout(() => {
        this.activeElement.next(initialElement);
      });
    }
  }

  public register(target: ScrollSpyTargetDirective): void {
    if (this.targets.indexOf(target) < 0) {
      this.targets.push(target);
    }
  }

  public deregister(target: ScrollSpyTargetDirective): void {
    this.targets = this.targets.filter((_target) => _target !== target);
  }

  private findActiveElement(): any | null {
    if (!this.targets.length) {
      return null;
    }

    const viewportHeight = this.document.documentElement.clientHeight;

    const targetsWithTopBottomPositions = this.getTargetsWithTopBottomPositions();
    const visibleTargets = targetsWithTopBottomPositions
      .filter(({ top, bottom }) => top < viewportHeight && bottom >= 0);

    const line = this.getTopOffset();
    const elementIntersectingLine = this.getFirstElementIntersectingLine(visibleTargets, line);
    if (elementIntersectingLine) {
      return elementIntersectingLine.value;
    }

    if (this.scrollSpyConfig && this.scrollSpyConfig.onlyMatchInside) {
      return null;
    }

    // Nothing intersected the line - pick the one closest to the line
    const possibleTargets = visibleTargets.length ? visibleTargets : targetsWithTopBottomPositions;

    const targetsByCloseness = possibleTargets
      .map(({ target, top }) => ({ target, distance: Math.abs(line - top) }))
      .sort((a, b) => a.distance - b.distance);
    return targetsByCloseness[0].target.value;
  }

  /**
   * Returns targets with their top and bottom positions, ordered ascending (going down the page) by top-position.
   */
  private getTargetsWithTopBottomPositions(): { target: ScrollSpyTargetDirective, top: number, bottom: number }[] {
    return this.targets
      .map((target) => {
        const rect = target.getBoundingRect();
        const top = rect.top;
        const bottom = top + rect.height;

        return {
          target,
          top,
          bottom
        };
      })
      .sort((a, b) => a.top - b.top);
  }

  private getFirstElementIntersectingLine(visibleTargets: TargetWithTopBottomPositions[], line: number): ScrollSpyTargetDirective | null {
    for (const { target, top, bottom } of visibleTargets) {
      const intersectsLine = top <= line && bottom >= line;
      if (intersectsLine) {
        return target;
      }
    }
    return null;
  }

  private getTopOffset(): number {
    const constantOffset = this.scrollSpyConfig && this.scrollSpyConfig.offsetPx || 0;

    const offsetElement = this.scrollSpyConfig && this.scrollSpyConfig.offsetElement;
    if (!offsetElement) {
      return constantOffset;
    }

    const offsetElementRect = offsetElement.nativeElement.getBoundingClientRect();
    const offsetElementBottom = offsetElementRect.top + offsetElementRect.height;

    return constantOffset + offsetElementBottom;
  }
}

@Directive({
  selector: '[scrollSpyTarget]'
})
export class ScrollSpyTargetDirective implements OnInit, OnDestroy {

  @Input('scrollSpyTarget') // eslint-disable-line @angular-eslint/no-input-rename
  public value: any;

  constructor(
    @Host() private parent: ScrollSpyDirective,
    private element: ElementRef
  ) {
  }

  public ngOnInit(): void {
    this.parent.register(this);
  }

  public ngOnDestroy(): void {
    this.parent.deregister(this);
  }

  public getBoundingRect(): DOMRect {
    return this.element.nativeElement.getBoundingClientRect();
  }
}
