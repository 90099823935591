/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type ErrorCode =
  'cannot_add_masqueraded_eater_to_location'
  | 'cannot_cancel_already_cancelled_order'
  | 'cannot_cancel_order_past_deadline'
  | 'cannot_charge_card'
  | 'cannot_create_order_past_deadline'
  | 'cannot_edit_individual_choice_order_past_deadline'
  | 'cannot_order_above_enforced_headcount_limit'
  | 'cannot_promote_member_of_other_customer'
  | 'cannot_update_eater_cart_for_cancelled_order'
  | 'cannot_verify_card'
  | 'delivery_area_not_available'
  | 'email_in_use'
  | 'incomplete_menu_submitted'
  | 'insufficient_time_for_eaters_to_choose'
  | 'menu_not_found'
  | 'not_allowed_to_order_from_multiple_vendors'
  | 'order_cancelled'
  | 'order_no_longer_editable'
  | 'unapproved_vendor_scheduling_menus'
  | 'vendor_not_found'
  | 'vendor_over_headcount_capacity'
  | 'cannot_generate_valid_vendor_location_slug'
  | 'vendor_location_names_too_similar'
  | 'cannot_delete_default_vendor_location';

export class ErrorCodes {
  public static CANNOT_ADD_MASQUERADED_EATER_TO_LOCATION:          ErrorCode & 'cannot_add_masqueraded_eater_to_location'          = 'cannot_add_masqueraded_eater_to_location';
  public static CANNOT_CANCEL_ALREADY_CANCELLED_ORDER:             ErrorCode & 'cannot_cancel_already_cancelled_order'             = 'cannot_cancel_already_cancelled_order';
  public static CANNOT_CANCEL_ORDER_PAST_DEADLINE:                 ErrorCode & 'cannot_cancel_order_past_deadline'                 = 'cannot_cancel_order_past_deadline';
  public static CANNOT_CHARGE_CARD:                                ErrorCode & 'cannot_charge_card'                                = 'cannot_charge_card';
  public static CANNOT_CREATE_ORDER_PAST_DEADLINE:                 ErrorCode & 'cannot_create_order_past_deadline'                 = 'cannot_create_order_past_deadline';
  public static CANNOT_EDIT_INDIVIDUAL_CHOICE_ORDER_PAST_DEADLINE: ErrorCode & 'cannot_edit_individual_choice_order_past_deadline' = 'cannot_edit_individual_choice_order_past_deadline';
  public static CANNOT_PROMOTE_MEMBER_OF_OTHER_CUSTOMER:           ErrorCode & 'cannot_promote_member_of_other_customer'           = 'cannot_promote_member_of_other_customer';
  public static CANNOT_ORDER_ABOVE_ENFORCED_HEADCOUNT_LIMIT:       ErrorCode & 'cannot_order_above_enforced_headcount_limit'       = 'cannot_order_above_enforced_headcount_limit';
  public static CANNOT_UPDATE_EATER_CART_FOR_CANCELLED_ORDER:      ErrorCode & 'cannot_update_eater_cart_for_cancelled_order'      = 'cannot_update_eater_cart_for_cancelled_order';
  public static CANNOT_VERIFY_CARD:                                ErrorCode & 'cannot_verify_card'                                = 'cannot_verify_card';
  public static DELIVERY_AREA_NOT_AVAILABLE:                       ErrorCode & 'delivery_area_not_available'                       = 'delivery_area_not_available';
  public static EMAIL_IN_USE:                                      ErrorCode & 'email_in_use'                                      = 'email_in_use';
  public static INCOMPLETE_MENU_SUBMITTED:                         ErrorCode & 'incomplete_menu_submitted'                         = 'incomplete_menu_submitted';
  public static INSUFFICIENT_TIME_FOR_EATERS_TO_CHOOSE:            ErrorCode & 'insufficient_time_for_eaters_to_choose'            = 'insufficient_time_for_eaters_to_choose';
  public static MENU_NOT_FOUND:                                    ErrorCode & 'menu_not_found'                                    = 'menu_not_found';
  public static NOT_ALLOWED_TO_ORDER_FROM_MULTIPLE_VENDORS:        ErrorCode & 'not_allowed_to_order_from_multiple_vendors'        = 'not_allowed_to_order_from_multiple_vendors';
  public static ORDER_CANCELLED:                                   ErrorCode & 'order_cancelled'                                   = 'order_cancelled';
  public static ORDER_NO_LONGER_EDITABLE:                          ErrorCode & 'order_no_longer_editable'                          = 'order_no_longer_editable';
  public static UNAPPROVED_VENDOR_SCHEDULING_MENUS:                ErrorCode & 'unapproved_vendor_scheduling_menus'                = 'unapproved_vendor_scheduling_menus';
  public static VENDOR_NOT_FOUND:                                  ErrorCode & 'vendor_not_found'                                  = 'vendor_not_found';
  public static VENDOR_OVER_HEADCOUNT_CAPACITY:                    ErrorCode & 'vendor_over_headcount_capacity'                    = 'vendor_over_headcount_capacity';
  public static CANNOT_GENERATE_VALID_VENDOR_LOCATION_SLUG:        ErrorCode & 'cannot_generate_valid_vendor_location_slug'        = 'cannot_generate_valid_vendor_location_slug';
  public static VENDOR_LOCATION_NAMES_TOO_SIMILAR:                 ErrorCode & 'vendor_location_names_too_similar'                 = 'vendor_location_names_too_similar';
  public static CANNOT_DELETE_DEFAULT_VENDOR_LOCATION:             ErrorCode & 'cannot_delete_default_vendor_location'             = 'cannot_delete_default_vendor_location';

  public static get values(): ErrorCode[] {
    return [
      ErrorCodes.CANNOT_ADD_MASQUERADED_EATER_TO_LOCATION,
      ErrorCodes.CANNOT_CANCEL_ALREADY_CANCELLED_ORDER,
      ErrorCodes.CANNOT_CANCEL_ORDER_PAST_DEADLINE,
      ErrorCodes.CANNOT_CHARGE_CARD,
      ErrorCodes.CANNOT_CREATE_ORDER_PAST_DEADLINE,
      ErrorCodes.CANNOT_EDIT_INDIVIDUAL_CHOICE_ORDER_PAST_DEADLINE,
      ErrorCodes.CANNOT_ORDER_ABOVE_ENFORCED_HEADCOUNT_LIMIT,
      ErrorCodes.CANNOT_PROMOTE_MEMBER_OF_OTHER_CUSTOMER,
      ErrorCodes.CANNOT_UPDATE_EATER_CART_FOR_CANCELLED_ORDER,
      ErrorCodes.CANNOT_VERIFY_CARD,
      ErrorCodes.DELIVERY_AREA_NOT_AVAILABLE,
      ErrorCodes.EMAIL_IN_USE,
      ErrorCodes.INCOMPLETE_MENU_SUBMITTED,
      ErrorCodes.INSUFFICIENT_TIME_FOR_EATERS_TO_CHOOSE,
      ErrorCodes.MENU_NOT_FOUND,
      ErrorCodes.NOT_ALLOWED_TO_ORDER_FROM_MULTIPLE_VENDORS,
      ErrorCodes.ORDER_CANCELLED,
      ErrorCodes.ORDER_NO_LONGER_EDITABLE,
      ErrorCodes.UNAPPROVED_VENDOR_SCHEDULING_MENUS,
      ErrorCodes.VENDOR_NOT_FOUND,
      ErrorCodes.VENDOR_OVER_HEADCOUNT_CAPACITY,
      ErrorCodes.CANNOT_GENERATE_VALID_VENDOR_LOCATION_SLUG,
      ErrorCodes.VENDOR_LOCATION_NAMES_TOO_SIMILAR,
      ErrorCodes.CANNOT_DELETE_DEFAULT_VENDOR_LOCATION,
    ];
  }

  // Do not modify this line - Custom code below here
}

export type ErrorCodeEnum = typeof ErrorCodes;
