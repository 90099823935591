import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsEvent } from '../analytics-event.interface';
import { AnalyticsRule } from './analytics-rule.interface';

export const qualificationFormRules: AnalyticsRule[] = [
  {
    url: '*',
    path: 'qualification-form close-cross',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: 'Close cross'
    }
  },
  {
    url: '*',
    path: 'qualification-form use-case',
    events: 'click',
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: `${event.data['use-case']} - event selected`
    })
  },
  {
    url: '*',
    path: 'qualification-form frequency',
    events: 'change',
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: `${event.data['frequency']} - frequency selected`
    })
  },
  {
    url: '*',
    path: 'qualification-form head-count',
    events: 'change',
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: `${event.data['head-count']} - head count selected`
    })
  },
  {
    url: '*',
    path: 'qualification-form source',
    events: 'change',
    definition: (event: AnalyticsEvent) => ({
      action: AnalyticsActions.SELECT,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: `${event.data['source']} - source selected`
    })
  },

  {
    url: '*',
    path: 'qualification-form submit',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.QUALIFICATION_FORM,
      label: 'Submitted'
    }
  },
];
