declare const PaymentCardIdSymbol: unique symbol;
export type PaymentCardId = string & {
  [PaymentCardIdSymbol]: never;
};

export interface PaymentCard {
  id: PaymentCardId;
  // type is not an enum because we don't know all possible card types (they come from payment providers as strings)
  type: string;
  last4: string;
  expiryDate: string;
  name: string | null;
}

export function createPaymentCardFromJson(json?: Partial<PaymentCard>): PaymentCard {
  json = json || {};
  const card: Partial<PaymentCard> = {};

  card.id = json.id;
  card.type = json.type;
  card.last4 = json.last4;
  card.expiryDate = json.expiryDate;
  card.name = json.name;

  return card as PaymentCard;
}
