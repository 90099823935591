import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Dietaries, DIETARIES_DISPLAY_ORDER, DietaryExceptNone } from '@citypantry/util-models';

@Component({
  selector: 'app-dietaries',
  templateUrl: './dietaries.component.html',
  styleUrls: ['./dietaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DietariesComponent {
  @Input()
  public dietaries: Dietaries;

  @Input()
  public activeDietaries: DietaryExceptNone[];

  @Input()
  public canBeToggled: boolean;

  @Input()
  public showUnavailable: boolean;

  @Output()
  public dietaryClick: EventEmitter<DietaryExceptNone> = new EventEmitter();

  public DIETARIES_DISPLAY_ORDER: DietaryExceptNone[] = DIETARIES_DISPLAY_ORDER;

  constructor() {
    this.activeDietaries = [];
    this.showUnavailable = false;
  }

  public clickDietary(dietary: DietaryExceptNone): void {
    // don't emit clicks for when dietaries cannot be activated or for unavailable dietaries.
    // change this if ever needed, but then be sure to filter clicks in existing parent components.
    if (this.canBeToggled && this.dietaries[dietary]) {
      this.dietaryClick.emit(dietary);
    }
  }
}
