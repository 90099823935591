import { mapSelectors, nonEmpty, unique } from '@citypantry/util';
import { BasicVendorDetails, OrderStatuses, majorCurrencyToMinor, minorCurrencyToMajor } from '@citypantry/util-models';
import { createSelector } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { getIndividualChoiceState } from '../app.state';
import { computeLoyaltyPoints } from '../util/compute-loyalty-points';
import { IndividualChoiceState, getActiveIndividualChoiceChildOrder, getOrderGroupId } from './individual-choice.state';

const getIndividualChoiceOrder = (state: IndividualChoiceState) => state.individualChoiceOrder;

export const isLoading = (state: IndividualChoiceState) => state.isLoading;
export const isOrderGroupCancelled = createSelector(getIndividualChoiceOrder,
  (order) => !!order && order.status === OrderStatuses.CANCELLED
);

const getShareIndividualChoiceOrderModal = (state: IndividualChoiceState) => state.shareIndividualChoiceOrderModal;
const getCurrentChildOrderId = (state: IndividualChoiceState) => state.currentChildOrderId;

// On the "who ordered what" page we need to choose a child order as some information only exists on the child.
// It doesn't matter which and is bad to rely on getActiveChildOrder so we just use the first one.
const getAnyChildOrder = (state: IndividualChoiceState) =>
  state.individualChoiceOrder &&
  state.individualChoiceOrder.orders ?
    state.individualChoiceOrder.orders[0] : null;

const getCurrencyCode = (state: IndividualChoiceState) => state.individualChoiceOrder && state.individualChoiceOrder.currencyIsoCode;

const isCartsAdditionalReferenceRequired = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && state.individualChoiceOrder.cartsAdditionalReferenceRequired;

const getEaterCartsWithoutAdditionalReferences = (state: IndividualChoiceState) => state.mandatoryCostCodesModal
  ? state.mandatoryCostCodesModal.eaterCartsWithoutCostCodes
  : [];

const getUniquePurchaseOrderNumbersForOrderGroup = (state: IndividualChoiceState) => state.individualChoiceOrder.orders
  .map((order) => order.purchaseOrderNumber)
  .filter(nonEmpty)
  .filter(unique);

const getUniqueDepartmentReferencesForOrderGroup = (state: IndividualChoiceState) => state.individualChoiceOrder.orders
  .map((order) => order.departmentReference)
  .filter(nonEmpty)
  .filter(unique);

const getIndividualChoiceOrderSchedule = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && state.individualChoiceOrder.schedule;

const getOrderGroupHeadCount = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && state.individualChoiceOrder.expectedHeadcount;

const getOrderGroupVendors = (state: IndividualChoiceState): BasicVendorDetails[] =>
  state.individualChoiceOrder && state.individualChoiceOrder.orders
    ? state.individualChoiceOrder.orders.map((order) => ({
      name: order.vendor.name,
      locationName: order.selectedVendorLocation?.name || null,
      image: order.vendor.images[0]
    }))
    : [];

const getOrderGroupBudget = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && majorCurrencyToMinor(state.individualChoiceOrder.budget);

const getOrderGroupTotalCost = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && state.individualChoiceOrder.orders
    .reduce((acc, childOrder) => acc + majorCurrencyToMinor(childOrder.costBreakdown.itemsCostAfterRefunds.gross), 0);

const getOrderGroupLoyaltyPoints = createSelector(getOrderGroupTotalCost, (cost) => computeLoyaltyPoints(minorCurrencyToMajor(cost)));

const getOrderGroupDeliveryDate = (state: IndividualChoiceState) =>
  state.individualChoiceOrder && state.individualChoiceOrder.orders.length > 0
    ? state.individualChoiceOrder.orders[0].requestedDeliveryDate // we expect requestedDeliveryDate to be the same for all nested orders
    : null;

const getLast4OfOrderGroupPaymentCard = (state: IndividualChoiceState) => state.individualChoiceOrder.last4OfPaymentCard;

const getUpdatedOrderLocation = (state: IndividualChoiceState) => state.updatedOrderLocation;

const isOrderUpdated = (state: IndividualChoiceState) => !!state.updatedOrderLocation;

// selectors that return functions as we do not want the NOW date to be memoized, NOW should be supplied to the function by the caller.
export const isOrderGroupFinalised = (state: IndividualChoiceState) =>
  (now: moment.Moment) => now.isAfter(state.individualChoiceOrder.choiceDeadline);
const canOrderGroupBeRepeated = (state: IndividualChoiceState) => state.individualChoiceOrder.canBeRepeated;

const getUseDeskNumbers = (state: IndividualChoiceState) => state.individualChoiceOrder.useDeskNumbers;

const isPromoBannerHidden = (state: IndividualChoiceState) => state.hidePromotionBanner;

const editContactPopupIsOpen = (state: IndividualChoiceState): boolean => state.editContactPopupIsOpen;

const editDatePopupIsOpen = (state: IndividualChoiceState) => state.editDatePopupIsOpen;

const getNewDeliveryDate = (state: IndividualChoiceState): Moment => state.newDeliveryDate;

const cancelOrderAfterEditPopupIsOpen = (state: IndividualChoiceState): boolean => state.cancelOrderAfterEditPopupIsOpen;

const isEditDatePopupLoading = (state: IndividualChoiceState) => state.isEditDatePopupLoading;

const isUpdatingDeliveryContact = (state: IndividualChoiceState): boolean => state.isUpdatingDeliveryContact;

const selectedDateAvailability = (state: IndividualChoiceState) => state.selectedDateAvailability;

const deliveryDetailsUpdateSuccessPopupIsOpen = (state: IndividualChoiceState) => state.deliveryDetailsUpdateSuccessPopupIsOpen;

const hasWorryFreePeriodEndDatePassed = (state: IndividualChoiceState) => state.individualChoiceOrder &&
  state.individualChoiceOrder.hasWorryFreePeriodEndDatePassed;

const paymentResolutionFormSubmitting = (state: IndividualChoiceState) => state.paymentResolutionFormSubmitting;

export const IndividualChoiceSelectors = mapSelectors(getIndividualChoiceState, {
  isLoading,
  isOrderGroupCancelled,

  getShareIndividualChoiceOrderModal,
  getCurrentChildOrderId,
  getIndividualChoiceOrder,
  getAnyChildOrder,
  getActiveIndividualChoiceChildOrder,
  getCurrencyCode,
  getUpdatedOrderLocation,
  isOrderUpdated,
  isCartsAdditionalReferenceRequired,
  isPromoBannerHidden,
  getUniquePurchaseOrderNumbersForOrderGroup,
  getUniqueDepartmentReferencesForOrderGroup,
  getEaterCartsWithoutAdditionalReferences,
  getIndividualChoiceOrderSchedule,
  hasWorryFreePeriodEndDatePassed,

  getOrderGroupId,
  getOrderGroupVendors,
  getOrderGroupHeadCount,
  getOrderGroupLoyaltyPoints,
  getOrderGroupBudget,
  getOrderGroupTotalCost,
  getOrderGroupDeliveryDate,
  isOrderGroupFinalised,
  canOrderGroupBeRepeated,
  getLast4OfOrderGroupPaymentCard,
  getUseDeskNumbers,

  editContactPopupIsOpen,
  editDatePopupIsOpen,
  cancelOrderAfterEditPopupIsOpen,
  getNewDeliveryDate,
  isEditDatePopupLoading,
  isUpdatingDeliveryContact,
  selectedDateAvailability,
  deliveryDetailsUpdateSuccessPopupIsOpen,
  paymentResolutionFormSubmitting,
});
