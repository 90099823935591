import { Pipe, PipeTransform } from '@angular/core';
import { FoodType } from '@citypantry/util-models';

@Pipe({
  name: 'foodTypeName'
})
export class FoodTypeNamePipe implements PipeTransform {
  private static descriptors: { [key: string]: string } = {
    'main': 'Main',
    'side': 'Side',
    'dessert': 'Dessert',
    'drink': 'Drink',
    'other': 'Other'
  };

  public transform(value: FoodType): string {
    if (!FoodTypeNamePipe.descriptors.hasOwnProperty(value)) {
      throw new Error(`Food Type '${value}' does not have a known descriptor`);
    }
    // At the moment all we want to do is convert 'main' to 'Main'
    // Using a pipe unique to food type's so if/when it needs to change (i.e. 'main' => 'Main Course')
    // then it's an easy switch
    return FoodTypeNamePipe.descriptors[value];
  }
}
