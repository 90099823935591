import { Pipe, PipeTransform } from '@angular/core';
import { HeadCountQualificationType, HeadCountQualificationTypes } from '@citypantry/util-models';

@Pipe({
  name: 'headCountQualification'
})
export class HeadCountQualificationTypePipe implements PipeTransform {
  private static descriptors: { [key: string]: string } = {
    [HeadCountQualificationTypes.ONE_TO_FIFTY]: '1 - 50',
    [HeadCountQualificationTypes.FIFTY_TO_ONE_HUNDRED]: '50 - 100',
    [HeadCountQualificationTypes.ONE_HUNDRED_TO_FIVE_HUNDRED]: '100 - 500',
    [HeadCountQualificationTypes.OVER_FIVE_HUNDRED]: '500+',
  };

  public transform(value: HeadCountQualificationType | null): string {
    if (!value) {
      return null;
    }

    return HeadCountQualificationTypePipe.descriptors[value];
  }
}
