export interface VendorLocationNoticeSlotLimits {
  shortNotice: number;
  sameDay: number;
  oneDay: number;
  twoDays: number;
  threeDays: number;
  fiveDays: number;
}

export function createEmptyVendorLocationNoticeSlotLimits(): VendorLocationNoticeSlotLimits {
  return {
    shortNotice: 0,
    sameDay: 0,
    oneDay: 0,
    twoDays: 0,
    threeDays: 0,
    fiveDays: 0,
  };
}

export function createVendorLocationNoticeSlotLimitsFromJson(
  json: Partial<VendorLocationNoticeSlotLimits>
): VendorLocationNoticeSlotLimits {
  const noticeSlotLimits: Partial<VendorLocationNoticeSlotLimits> = {};

  noticeSlotLimits.shortNotice = json.shortNotice;
  noticeSlotLimits.sameDay = json.sameDay;
  noticeSlotLimits.oneDay = json.oneDay;
  noticeSlotLimits.twoDays = json.twoDays;
  noticeSlotLimits.threeDays = json.threeDays;
  noticeSlotLimits.fiveDays = json.fiveDays;

  return noticeSlotLimits as VendorLocationNoticeSlotLimits;
}
