import { Pipe, PipeTransform } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { Dietary } from '@citypantry/util-models';

@Pipe({
  name: 'dietName'
})
export class DietNamePipe implements PipeTransform {

  public transform(value: Dietary): string {
    switch (value) {
      case 'vegetarian':  return 'Vegetarian';
      case 'vegan':       return 'Vegan';
      case 'noGluten':    return 'No Gluten';
      case 'noNuts':      return 'No Nuts';
      case 'noDairy':     return 'No Dairy';
      case 'halal':       return 'Halal';
      case 'pescatarian': return 'Pescatarian';
      case 'none':        return 'None';
      default:
        throw new UnreachableCaseError(value);
    }
  }
}
