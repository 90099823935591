<app-search-popup-header
  *ngIf="popUpMode"
  (exploreOptionsClick)="scrollToHandle('search-bar')"
  test-id="popUpHeader"
></app-search-popup-header>

<app-breadcrumbs
  *ngIf="breadcrumbs"
  [breadcrumbs]="breadcrumbs"
  class="d-block my-large"
  test-id="breadcrumbs"
></app-breadcrumbs>

<div
  class="mb-md-large mb-none"
  scrollHandle="search-bar"
>
  <ng-content select="app-search-bar"></ng-content>
</div>

<div class="container mb-massive">
  <div class="row">

    <div class="col col-12 col-md-4 col-lg-3 mb-large">
      <div class="row">
        <div class="col-12">
          <ng-content select="app-search-filters-form"></ng-content>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-8 col-lg-9 pl-md-5">
      <div
        *ngIf="showDisruptionBanner"
        class="row mb-standard"
        test-id="disruptionBannerWrapper"
      >
        <div class="col">
          <app-delivery-disruption-banner
            test-id="disruptionBanner"
            [date]="request?.date"
          ></app-delivery-disruption-banner>
        </div>
      </div>

      <div class="row" *ngIf="recommendedResults && showRecommendations">
        <div class="col col-12">
          <app-search-recommendation-results
            [recommendations]="recommendedResults"
            [url]="resultUrl"
            [date]="request?.date"
            [showDistance]="showDistance"
            [showExactRemainingCapacity]="showExactRemainingCapacity"
            test-id="recommendations"
          ></app-search-recommendation-results>
        </div>
      </div>

      <div
        *ngIf="results.length > 0 && !showPlaceholders || !isSearching"
        class="search-results-header"
        test-id="searchResultsHeader"
      >
        <div
          class="mb-standard mb-md-none search-results-header__result-count"
          test-id="resultCount"
        >
          {{ resultsTotal }} matching {{ 'result' | plural:resultsTotal }}
        </div>
        <ng-content
          select="app-search-filters-toggles"
        ></ng-content>
        <ng-content
          *ngIf="results.length > 0"
          select="app-search-sort-dropdown"
        ></ng-content>
      </div>
      <div class="row">
        <app-no-results
          *ngIf="!isSearching && !showPlaceholders && results.length === 0"
          [marketingCampaignComponent]="noSearchResultsMarketingCampaignComponentModel"
          test-id="noResults"
        ></app-no-results>
      </div>

      <div
        class="row pt-large"
        analyticsId="search-result-list"
        [analyticsData]="{ totalResultCount: resultsTotal }"
      >
        <div
          *ngIf="shouldShowPromotionCard()"
          class="col col-lg-4 col-md-6 col-12"
        >
          <app-search-promo-card
            [promotion]="searchCardPromotion"
            (buttonClick)="handlePromoCardClick($event)"
            (close)="handlePromoCardClose($event)"
            test-id="promoCard"
          ></app-search-promo-card>
        </div>
        <div class="col col-lg-4 col-md-6 col-12" *ngFor="let result of (showPlaceholders ? [] : results)">
          <app-search-result
            #searchResult
            [url]="resultUrl"
            [date]="request?.date"
            [result]="result"
            [showDistance]="showDistance"
            [showFavouriteButton]="showFavouriteButtons"
            [isFavourited]="isVendorFavourited(result.vendorId)"
            [showRemainingCapacity]="true"
            [showExactRemainingCapacity]="showExactRemainingCapacity"
            (toggleFavourite)="onFavouriteToggled(result.vendorId)"
            test-id="result"
          ></app-search-result>
        </div>
        <div class="col col-lg-4 col-md-6 col-12" *ngFor="let i of placeholders">
          <app-search-result-placeholder
            test-id="placeholder"
          ></app-search-result-placeholder>
        </div>
        <app-cant-find-what-your-looking-for
          *ngIf="shouldShowVendorComingSoon()"
          (openZopimClick)="openZopimChat.emit()"
          test-id="cantFindWhatYouWanted"
        ></app-cant-find-what-your-looking-for>
      </div>

      <div class="row" *ngIf="isSearching">
        <div class="col col-12 text-center">
          <app-square-spinner
            [small]="results.length > 0"
            test-id="spinner"
          ></app-square-spinner>
        </div>
      </div>
    </div>

  </div>
</div>
