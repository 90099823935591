import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector } from '@ngrx/store';
import { LostbarSearchResult } from './lostbar-search-result.model';
import { FEATURE_STATE_NAME, LostbarState } from './lostbar.state';

const getLostbarState = createFeatureSelector<LostbarState>(FEATURE_STATE_NAME);

const getSearchResults = (state: LostbarState): LostbarSearchResult[] =>
  state.searchResults;

export const LostbarSelectors = mapSelectors(getLostbarState, {
  getSearchResults
});
