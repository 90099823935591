import { CustomerId, MealPlan, OrderId, UserId, VendorId } from '@citypantry/util-models';
import moment from 'moment';

export interface LostbarCustomer extends LostbarCustomerJson {
  group: LostbarResultGroupType.CUSTOMERS;
}
export interface LostbarCustomerUser extends LostbarCustomerUserJson {
  group: LostbarResultGroupType.MEMBERS;
}
export interface LostbarVendor extends LostbarVendorJson {
  group: LostbarResultGroupType.VENDORS;
}
export interface LostbarVendorUser extends LostbarVendorUserJson {
  group: LostbarResultGroupType.VENDOR_USERS;
}
export interface LostbarOrder extends LostbarOrderJson {
  group: LostbarResultGroupType.ORDERS;
}
export interface LostbarMealPlan extends LostbarMealPlanJson {
  group: LostbarResultGroupType.MEAL_PLANS;
}
export interface LostbarUser extends LostbarUserJson {
  group: LostbarResultGroupType.USERS;
}

export type LostbarSearchResult =
  LostbarCustomer
  | LostbarCustomerUser
  | LostbarVendor
  | LostbarVendorUser
  | LostbarOrder
  | LostbarMealPlan
  | LostbarUser;

export interface LostbarSearchResults {
  customers: LostbarCustomer[];
  members: LostbarCustomerUser[];
  vendorUsers: LostbarVendorUser[];
  vendors: LostbarVendor[];
  orders: LostbarOrder[];
  mealPlans: LostbarMealPlan[];
  users: LostbarUser[];
}

export enum LostbarResultGroupType {
  'CUSTOMERS' = 'customers',
  'MEMBERS' = 'members',
  'VENDORS' = 'vendors',
  'VENDOR_USERS' = 'vendorUsers',
  'ORDERS' = 'orders',
  'MEAL_PLANS' = 'mealPlans',
  'USERS' = 'users',
}

export interface LostbarCustomerJson {
  _id: CustomerId;
  companyName: string;
  primaryMember: {
    phoneNumber: string;
  };
  user: {
    _id: UserId;
    email: string;
    firstName: string;
    lastName: string;
  };
}
export interface LostbarCustomerUserJson {
  _id: UserId;
  email: string;
  firstName: string;
  lastName: string;
  customer: {
    _id: CustomerId;
    companyName: string;
  };
}

export interface LostbarVendorJson {
  _id: VendorId;
  name: string;
  user: {
    _id: UserId;
    email: string;
    firstName: string;
    lastName: string;
  };
}
export interface LostbarVendorUserJson {
  _id: UserId;
  email: string;
  firstName: string;
  lastName: string;
  vendor: {
    _id: VendorId;
    name: string;
  };
}

export interface LostbarOrderJson {
  _id: OrderId;
  humanId: string;
  requestedDeliveryDate: moment.Moment;
  customer: {
    _id: CustomerId;
    companyName: string;
  };
  vendor: {
    _id: VendorId;
    name: string;
  };
}

export interface LostbarMealPlanJson {
  _id: MealPlan['id'];
  startDate: moment.Moment;
  customer: {
    _id: CustomerId;
    companyName: string;
  };
}

export interface LostbarUserJson {
  _id: UserId;
  email: string;
  firstName: string;
  lastName: string;
}

export interface LostbarSearchResultsJson {
  customers?: LostbarCustomerJson[];
  members?: LostbarCustomerUserJson[];
  vendorUsers?: LostbarVendorUserJson[];
  vendors?: LostbarVendorJson[];
  orders?: LostbarOrderJson[];
  mealPlans?: LostbarMealPlanJson[];
  users?: LostbarUserJson[];
}

export function createLostbarSearchResultsFromJson(json: Partial<LostbarSearchResultsJson>): LostbarSearchResultsJson {
  return {
    customers: (json.customers || []).map(createLostbarCustomerFromJson),
    members: (json.members || []).map(createLostbarCustomerUserFromJson),
    vendorUsers: (json.vendorUsers || []).map(createLostbarVendorUserFromJson),
    vendors: (json.vendors || []).map(createLostbarVendorFromJson),
    orders: (json.orders || []).map(createLostbarOrderFromJson),
    mealPlans: (json.mealPlans || []).map(createLostbarMealPlanFromJson),
    users: (json.users || []).map(createLostbarUserFromJson),
  };
}

function createLostbarCustomerFromJson(json: Partial<LostbarCustomerJson>): LostbarCustomerJson {
  const customer = { ...json };
  return customer as LostbarCustomerJson;
}

function createLostbarCustomerUserFromJson(json: Partial<LostbarCustomerUserJson>): LostbarCustomerUserJson {
  const customerUser = { ...json };
  return customerUser as LostbarCustomerUserJson;
}

function createLostbarVendorUserFromJson(json: Partial<LostbarVendorUserJson>): LostbarVendorUserJson {
  const vendorUser = { ...json };
  return vendorUser as LostbarVendorUserJson;
}

function createLostbarVendorFromJson(json: Partial<LostbarVendorJson>): LostbarVendorJson {
  const vendor = { ...json };
  return vendor as LostbarVendorJson;
}

function createLostbarOrderFromJson(json: Partial<LostbarOrderJson>): LostbarOrderJson {
  const order = { ...json };

  order.requestedDeliveryDate = moment(json.requestedDeliveryDate);

  return order as LostbarOrderJson;
}

function createLostbarMealPlanFromJson(json: Partial<LostbarMealPlanJson>): LostbarMealPlanJson {
  const mealPlan = { ...json };

  mealPlan.startDate = moment(json.startDate);

  return mealPlan as LostbarMealPlanJson;
}

function createLostbarUserFromJson(json: Partial<LostbarUserJson>): LostbarUserJson {
  const user = { ...json };
  return user as LostbarUserJson;
}
