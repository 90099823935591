import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthModule } from '@citypantry/shared-auth';
import { ChatUserIdentityService } from './chat-user-identity.service';

declare const zE: any;
declare const $zopim: any;

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
  ],
})
export class ChatWidgetModule {
  constructor(
    private appConfig: AppConfig,
    private chatUserIdentityService: ChatUserIdentityService,
  ) {
    if (this.appConfig.INCLUDE_ZENDESK === true) {
      zE(() => {
        zE.hide();
        this.onZendeskInit();
      });
    }

    this.chatUserIdentityService.initialiseUserIdentity();
  }

  private onZendeskInit(): void {
    $zopim(() => {
      this.onZopimInit();
    });
  }

  private onZopimInit(): void {
    $zopim.livechat.window.setOffsetHorizontal(48);

    $zopim.livechat.window.onShow(() => {
      sessionStorage.setItem('zopimExpanded', 'true');
    });

    $zopim.livechat.window.onHide(() => {
      sessionStorage.setItem('zopimExpanded', 'false');
    });
  }
}
