<div
  *ngIf="messages.length > 0"
  [ngClass]="{
    'form-error form-error--floating': !showInline,
    'text-color-red-1': showInline
  }"
  test-id="formError"
>
  <div
    *ngFor="let message of messages; let last = last"
    [class.mb-x-small]="!last"
  >
    {{ message }}
  </div>
</div>
