import { InjectionToken } from '@angular/core';

export const AnalyticsTrackingLogLevel: InjectionToken<LogLevel> = new InjectionToken('AnalyticsTrackingLogLevel');

export enum LogLevel {
  // Log levels used by the log calls
  WARN = 1, // things that shouldn't go wrong
  INFO = 2, // general overview of what the application is doing
  VERBOSE = 3, // detailed view of what is happening
  DEBUG = 4, // extremely detailed information

  // Log levels that exist only as "output at this level" values
  NONE = 0,
  ALL = 100,
}
