import moment from 'moment';
import { concat, createArray } from './array';

/**
 * Given an array of minutes this will generate an array of all possible moments in the day.
 * E.g. given [0, 30], the return value would be ['00:00', '00:30', '01:00', ..., '23:30'].
 */
export const generateAllTimesWithMinutes = (minutes: number[]): moment.Moment[] =>
  createArray(24) // [0, 1, ..., 23]
    .map((_, i) => (`${ i }`).padStart(2, '0'))
    .map((t) => minutes.map((m) => `${ t}:${(`${m }`).padStart(2, '0')}`))
    .reduce(concat())
    .map((t) => moment(t, 'HH:mm'));

export const isDateOnWeekend = (date: moment.Moment): boolean => {
  const deliveryDay = date.day();
  return (deliveryDay === 0 || deliveryDay === 6);
};
