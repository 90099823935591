import { Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * [CPD-2189]
 * Buttons inside forms are type=submit by default.
 * Safari considers forms without an action to be valid, so if you have a <form><button></form> then clicking the button will cause the page
 * to reload, unless the button has a type=button.
 *
 * This directive sets the type of buttons inside forms to `button` unless otherwise specified to avoid this issue.
 */
@Directive({
  selector: 'form button' // buttons inside forms
})
export class ButtonTypeDirective {
  constructor(
    element: ElementRef,
    renderer: Renderer2
  ) {
    if (element.nativeElement && element.nativeElement.getAttribute && !element.nativeElement.getAttribute('type')) {
      renderer.setAttribute(element.nativeElement, 'type', 'button');
    }
  }
}
