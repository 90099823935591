import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input()
  public type: 'warning' | 'info';

  @Input()
  public isTransparent: boolean;

  constructor() {
    this.type = 'warning';
  }
}
