import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnalyticsEcommerceActionEnum,
  AnalyticsEcommerceActions,
  AnalyticsEcommerceEventIdEnum,
} from '@citypantry/shared-analytics';

@Component({
  selector: 'app-item-add-button',
  template: `
    <button
      type="button"
      class="add-button"
      (click)="onClick()"
      ecommerceEventTrigger="click"
      [ecommerceActionType]="AnalyticsEcommerceActions.DETAIL"
      [ecommerceEventId]="analyticsEcommerceEventId"
      test-id="button"
    >
      <ng-container *ngIf="quantity; else add">
        <i
          aria-hidden="true"
          class="icon icon-pencil mr-1"
          test-id="pencil"
        ></i>
        {{ quantity }}
      </ng-container>
      <ng-template #add>
        <i class="icon icon-plus icon--medium" test-id="plus"></i>
      </ng-template>
    </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemAddButtonComponent {

  /**
   * Optional; if set to > 0, will display a "pencil" with the quantity instead of a "plus"
   */
  @Input()
  public quantity: number;

  @Input()
  public analyticsEcommerceEventId: AnalyticsEcommerceEventIdEnum;

  @Output()
  public select: EventEmitter<void> = new EventEmitter();

  public AnalyticsEcommerceActions: AnalyticsEcommerceActionEnum = AnalyticsEcommerceActions;

  public onClick(): void {
    this.select.emit();
  }
}
