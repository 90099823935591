/**
 * Returns a matcher to ensure an input value is inside the range (inclusive).
 *
 * @example
 * const inRange = ensureInRange(0,20);
 * inRange(17) // 17
 * inRange(-1) // 0
 * inRange(23) // 20
 *
 * @example
 * // instead of
 * Math.min(12, Math.max(0, value))
 * // use
 * ensureInRange(0, 12)(value)
 */
export function ensureInRange(min: number, max: number): (value: number) => number {
  if (min > max) {
    throw new Error(`Cannot use inRange when min (${min}) > max (${max})`);
  }
  return (value: number) => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }
    return value;
  };
}
