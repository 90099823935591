import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DeliveryContactEffects } from './delivery-contact.effects';
import { FEATURE_STATE_NAME, reducer } from './delivery-contact.state';

@NgModule({
  imports: [
    EffectsModule.forFeature([DeliveryContactEffects]),
    StoreModule.forFeature(FEATURE_STATE_NAME, reducer),
  ],
})
export class DeliveryContactModule {}
