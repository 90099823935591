import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, getItems, ItemResponse, ItemsResponse, mapmap } from '@citypantry/util';
import { AgeConfirmationEnum } from '@citypantry/util-enums';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  Cart,
  CartDeliverability,
  CartNotification,
  createCartDeliverabilityFromJson,
  createCartFromJson,
  createCartNotificationFromJson,
  createReorderCartDetailsFromJson,
  EMPTY_CART_DELIVERABILITY,
  ErrorCodes,
  ErrorResponse,
  isBundleCartItem,
  OrderId,
  ReorderCartDetails,
} from '@citypantry/util-models';
import { Moment } from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartApi {

  constructor(
    private http: HttpClient
  ) {
  }

  public getCart(id: string): Observable<Cart> {
    return this.http.get(`/carts/${id}`).pipe(
      getItem(),
      map(createCartFromJson),
    );
  }

  public getCartFromOrder(orderId: OrderId): Observable<Cart> {
    return this.http.get(`/orders/${orderId}/cart`).pipe(
      getItem(),
      map(createCartFromJson)
    );
  }

  /**
   * Sets a new cart on a given order
   *
   * @param threeDSecureEnrichedNonce null unless all the following conditions are true:
   *  1. This request increases the overall price of the order
   *  2. The order is paid by card
   *  3. The edit was performed by the customer (not a staff member)
   */
  public updateCart(
    orderId: OrderId,
    cart: Cart,
    hasCustomerConfirmedAge: AgeConfirmationEnum,
    threeDSecureEnrichedNonce: string | null
  ): Observable<void> {
    // This endpoint returns a non-menus formatted json response,
    // Which means we can't easily get the errors without parsing the error message
    const payload = {
      ...cart,
      hasCustomerConfirmedAge: hasCustomerConfirmedAge === AgeConfirmationEnum.CONFIRMED,
      threeDSecureEnrichedNonce,
    };

    return this.http.put(`/orders/${orderId}/cart`, payload, {
      headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'}
    }).pipe(
      mapTo(undefined),
      catchError((errorResponse: HttpErrorResponse | ErrorResponse | any) => {
        const error = errorResponse.apiResponse || errorResponse;
        if (error instanceof HttpErrorResponse && error.status === 422) {
          // We don't have a key in the response so have to match on the message and turn it into a keyed error message
          const vendorCapacityError = error.error.errors.some((errorMessage: any) =>
            errorMessage.messageArgs.errorMessage === 'Headcount exceeds maximum vendor capacity for this notice period'
          );

          if (vendorCapacityError) {
            return throwError({
              id: 'vendor-over-capacity',
              key: ErrorCodes.VENDOR_OVER_HEADCOUNT_CAPACITY,
            });
          }
        }
        return throwError(errorResponse);
      }),
    );
  }

  public getDeliverability(cart: Cart, deliveryDate: Moment, postcode: string): Observable<CartDeliverability> {
    if (!cart.cartItems.length) {
      return of(EMPTY_CART_DELIVERABILITY);
    }

    return this.http.post<ItemResponse<CartDeliverability>>(`/carts/deliverability`, {
      cart,
      date: deliveryDate,
      postcode
    }).pipe(
      getItem(),
      map(createCartDeliverabilityFromJson)
    );
  }

  public getNotifications(cart: Cart, orderId: OrderId): Observable<CartNotification[]> {
    return this.http.post<ItemsResponse<CartNotification>>(
      `/orders/${orderId}/cart/notifications`, { cart }).pipe(
      getItems(),
      mapmap(createCartNotificationFromJson)
    );
  }

  public createMenuPdf(cart: Cart): Observable<string> {
    return this.http.post<ItemResponse<string>>(`/menu-pdf/cart`, { cart }).pipe(
      map((content) => content.item.toString())
    );
  }

  public createQuotePdf(cart: Cart): Observable<string> {
    const params = mapCartToCreateQuoteParams(cart);
    return this.http.post<ItemResponse<string>>(`/quotes`, params).pipe(
      getItem(),
    );
  }

  public cloneCart(cart: Cart): Observable<Cart> {
    return this.http.post(`/carts/${cart.id}/clone`, null, {}).pipe(
      map(createCartFromJson)
    );
  }

  public trackAbandonedCart(cart: Cart): Observable<void> {
    return this.http.post(
      'abandoned-cart',
      { cart: cart.id },
      { headers: {[HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true'} },
    ).pipe(mapTo(undefined));
  }

  public getReorderableCart(cartId: string, menuContentId: string, requestedDate: string | null): Observable<ReorderCartDetails> {
    const params: { menuContent: string, deliveryDate?: string } = { menuContent: menuContentId };
    if (requestedDate) {
      params.deliveryDate = requestedDate;
    }
    return this.http.get(`carts/${cartId}/replicate`, { params }).pipe(
      map(createReorderCartDetailsFromJson),
    );
  }
}

export function mapCartToCreateQuoteParams(cart: Cart): { [param: string]: any } {
  const cartItems = cart.cartItems.map((cartItem) => {
    const cartItemParams: { [param: string]: any } = {
      itemId: cartItem.item.id,
      quantity: cartItem.quantity,
    };

    if (isBundleCartItem(cartItem)) {
      cartItemParams.groupsCartItems = cartItem.groups.reduce(
        (groupsCartItems, group, index) => group.cartItems.reduce(
          (acc, groupCartItem) => {
            if (groupCartItem.quantity > 0) {
              acc.push({
                groupIndex: index,
                itemId: groupCartItem.item.id,
                quantity: groupCartItem.quantity,
              });
            }

            return acc;
          }, groupsCartItems), []);
    }

    return cartItemParams;
  });

  return {
    vendorId: cart.vendorId,
    menuContentId: cart.contentId,
    postcode: cart.postcode,
    cartItems,
  };
}
