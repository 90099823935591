import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@citypantry/shared-app-config';
import { AuthSelectors } from '@citypantry/shared-auth';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  createLostbarSearchResultsFromJson,
  LostbarResultGroupType,
  LostbarSearchResult,
  LostbarSearchResultsJson,
} from './lostbar-search-result.model';

@Injectable({
  providedIn: 'root'
})
export class LostbarApi {

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private config: AppConfig,
  ) { }

  public getLostbarSearchResults(query: string): Observable<LostbarSearchResult[]> {
    return this.store.select(AuthSelectors.getLostbarToken).pipe(
      switchMap((token: string) => {
        const encodedQuery = encodeURIComponent(query);

        if (token) {
          return this.http.get(`${this.config.LOSTBAR_API_BASE}/search?term=${encodedQuery}&token=${token}`).pipe(
            map(createLostbarSearchResultsFromJson),
            map((resultsObject: LostbarSearchResultsJson) => [
              ...resultsObject.customers.map((customer) => ({
                ...customer,
                group: LostbarResultGroupType.CUSTOMERS
              })),
              ...resultsObject.members.map((member) => ({
                ...member,
                group: LostbarResultGroupType.MEMBERS
              })),
              ...resultsObject.vendors.map((vendor) => ({
                ...vendor,
                group: LostbarResultGroupType.VENDORS
              })),
              ...resultsObject.vendorUsers.map((vendorUser) => ({
                ...vendorUser,
                group: LostbarResultGroupType.VENDOR_USERS
              })),
              ...resultsObject.orders.map((order) => ({
                ...order,
                group: LostbarResultGroupType.ORDERS
              })),
              ...resultsObject.mealPlans.map((mealPlan) => ({
                ...mealPlan,
                group: LostbarResultGroupType.MEAL_PLANS
              })),
              ...resultsObject.users.map((user) => ({
                ...user,
                group: LostbarResultGroupType.USERS
              })),
            ]),
          );
        } else {
          return of([]);
        }
      })
    );
  }
}
