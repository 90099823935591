import { ensureValidEnumValue } from '@citypantry/util';
import { OrderSize, OrderSizes } from '../order';
import { AnonymousItem, createAnonymousItemFromJson } from './anonymous-item.model';
import { Contact, createContactFromJson } from './contact.model';

export interface AnonymousOrderItems {
  humanId: number;
  orderSize: OrderSize;
  containsFragileItem: boolean;
  containsOversizedItem: boolean;
  containsHotFood: boolean;
  orderValue: string;
  estimatedOrderValue: string | null; // null for marketplace orders or when an IC order has finalised
  items: AnonymousItem[];
  customerContacts: Contact[];
  vendorContacts: Contact[];
}

export function createAnonymousOrderItemsFromJson(json?: Partial<AnonymousOrderItems>): AnonymousOrderItems {
  json = json || {};
  const anonymousOrderItems: Partial<AnonymousOrderItems> = {};

  anonymousOrderItems.humanId = json.humanId;
  anonymousOrderItems.orderSize = ensureValidEnumValue(OrderSizes, json.orderSize);
  anonymousOrderItems.containsFragileItem = json.containsFragileItem;
  anonymousOrderItems.containsOversizedItem = json.containsOversizedItem;
  anonymousOrderItems.containsHotFood = json.containsHotFood;
  anonymousOrderItems.orderValue = json.orderValue;
  anonymousOrderItems.estimatedOrderValue = json.estimatedOrderValue || null;
  anonymousOrderItems.items = json.items ? json.items.map(createAnonymousItemFromJson) : [];
  anonymousOrderItems.customerContacts = json.customerContacts ? json.customerContacts.map(createContactFromJson) : [];
  anonymousOrderItems.vendorContacts = json.vendorContacts ? json.vendorContacts.map(createContactFromJson) : [];

  return anonymousOrderItems as AnonymousOrderItems;
}
