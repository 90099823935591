import { Injectable } from '@angular/core';
import { UserApi } from '@citypantry/shared-api';
import { PublicActions } from '@citypantry/state-public';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { CustomerQualificationActions } from './customer-qualification.actions';

@Injectable({ providedIn: 'root'})
export class CustomerQualificationEffects {

  public qualifyUser$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(CustomerQualificationActions.qualifyCustomer.type),
    switchMap(({ qualificationData }) => {
      return this.userApi.qualify(qualificationData);
    }),
    mapTo(CustomerQualificationActions.customerQualified()),
  ));

  public hideSearchPromoCardAfterQualification$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(CustomerQualificationActions.customerQualified.type),
    mapTo(PublicActions.hideSearchPromoCard())
  ));

  constructor(
    private action$: Actions,
    private userApi: UserApi,
  ) {
  }
}
