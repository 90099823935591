import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-horizontal-loader',
  template: `
    <div class="horizontal-loader-layout">
      <div
        *ngIf="isLoading"
        class="horizontal-loader horizontal-loader-layout__loader"
        test-id="loader"
      ></div>
    </div>
  `,
  styles: [`
    .horizontal-loader-layout {
      position: relative;
    }
    .horizontal-loader-layout__loader {
      position: absolute;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalLoaderComponent {
  @Input()
  public isLoading: boolean;
}
