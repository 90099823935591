import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchSortType, SearchSortTypeEnum, SearchSortTypes } from '@citypantry/util-models';

@Component({
  selector: 'app-search-sort-dropdown',
  template: `
    <label class="control-button control-button--expand-tablet search-sort-dropdown-layout">
      <div class="control-button__content" test-id="content">
        Sort by:
        <!-- Placeholder element so that it looks like there's a dropdown.
             Actual dropdown is invisible and overlaid -->
        <span class="subtle-select subtle-select--no-hover">
          <span class="subtle-select__input" [ngSwitch]="sortBy">
            <ng-container *ngSwitchCase="SearchSortTypes.RELEVANCE">Relevance</ng-container>
            <ng-container *ngSwitchCase="SearchSortTypes.VENDOR_DATE_ADDED">Newest</ng-container>
            <ng-container *ngSwitchCase="SearchSortTypes.MINIMUM_PRICE">Price</ng-container>
            <ng-container *ngSwitchCase="SearchSortTypes.DISTANCE">Distance</ng-container>
            <ng-container *ngSwitchCase="SearchSortTypes.TOP_PICKS">Top Picks</ng-container>
          </span>
        </span>
        <select
          class="search-sort-dropdown-layout__input"
          [ngModel]="sortBy"
          (ngModelChange)="onChanged($event)"
          analyticsId="sort-by-select"
          [analyticsData]="sortBy"
        >
          <option [value]="SearchSortTypes.RELEVANCE">Relevance</option>
          <option [value]="SearchSortTypes.VENDOR_DATE_ADDED">Newest</option>
          <option [value]="SearchSortTypes.MINIMUM_PRICE">Price</option>
          <option test-id="distanceOption"
                  *ngIf="showDistanceOption"
                  [value]="SearchSortTypes.DISTANCE">Distance</option>
          <option [value]="SearchSortTypes.TOP_PICKS">Top Picks</option>
        </select>
      </div>
    </label>
  `,
  styleUrls: ['./search-sort-dropdown.component.scss']
})
export class SearchSortDropdownComponent {
  public SearchSortTypes: SearchSortTypeEnum = SearchSortTypes;

  @Input()
  public set sortBy(value: SearchSortType) {
    this._sortBy = value;
  }

  public get sortBy(): SearchSortType {
    return this._sortBy || SearchSortTypes.RELEVANCE;
  }

  @Input()
  public showDistanceOption: boolean;

  @Output()
  public sortByChange: EventEmitter<SearchSortType> = new EventEmitter();

  private _sortBy: SearchSortType;

  public onChanged(sortBy: SearchSortType): void {
    this.sortByChange.emit(sortBy);
  }
}
