import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '@citypantry/util-browser';
import {
  Promotion,
  PromotionComponent,
  PromotionComponents,
  PromotionName,
  PromotionNames,
  SearchPromoCardModel
} from '@citypantry/util-models';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromotionBannerContent, PROMOTION_BANNER_CONTENT } from './promotion-banner.content';
import { PromotionBannerModel } from './promotion-banner.model';
import { PromotionQueries } from './promotion.queries';
import { SearchPromoCardContent, SEARCH_PROMO_CARD_CONTENT } from './search-promo-card.content';

export const PROMOTIONS_LOCAL_STORAGE_KEY = 'promotions';

export interface PromotionLocalStorageData {
  hiddenPromotions: {
    [component: string]: {
      [promotion: string]: { expiry: number};
    };
  };
  animatedInBanners: PromotionName[];
}

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  private localStorage: Storage;

  constructor(
    private promotionQueries: PromotionQueries,
    private window: WindowRef,
    @Inject(SEARCH_PROMO_CARD_CONTENT) private searchPromoCardContent: SearchPromoCardContent,
    @Inject(PROMOTION_BANNER_CONTENT) private promotionBannerContent: PromotionBannerContent,
  ) {
    this.localStorage = this.window.nativeWindow.localStorage;
  }

  public getSearchPromoCardPromotionContent(promotion: PromotionName): SearchPromoCardModel | null {
    return this.searchPromoCardContent[promotion] || null;
  }

  public getHighestPrioritySearchPromoCard(): Observable<SearchPromoCardModel | null> {
    return this.promotionQueries.getSearchPromoCardPromotions().pipe(
      map((promotions: Promotion[]) => {

        const showablePromotions  = promotions
          .filter((promotion) => this.searchPromoCardContent.hasOwnProperty(promotion.name))
          .filter((promotion) => !this.isPromotionHidden(promotion.name, PromotionComponents.SEARCH_PROMO_TILE));

        if (!showablePromotions.length) {
          return null;
        }

        const priorityPromotion: Promotion = showablePromotions.reduce((previous, current) => {
          return current.priority > previous.priority ? current : previous;
        });

        return this.searchPromoCardContent[priorityPromotion.name];
      })
    );
  }

  public getBannerPromotionContent(): Observable<PromotionBannerModel | null> {
    return this.promotionQueries.getBannerPromotions().pipe(
      map((promotions) => {
        const showablePromotions = promotions
          .filter((promotion) => this.promotionBannerContent.hasOwnProperty(promotion.name))
          .filter((promotion) => !this.isPromotionHidden(promotion.name, PromotionComponents.BANNER));

        if (!showablePromotions.length) {
          return null;
        }

        const priorityPromotion: Promotion = showablePromotions.reduce((previous, current) => {
          return current.priority > previous.priority ? current : previous;
        });

        return this.promotionBannerContent[priorityPromotion.name];
      })
    );
  }

  public shouldBannerAnimateIn(promotion: PromotionName): boolean {
    const localStoragePromoData = this.getLocalStorageData();
    return !(localStoragePromoData.animatedInBanners.indexOf(promotion) > -1);
  }

  public trackPromotionBannerAnimatedIn(promotionName: PromotionName): void {
    const localStoragePromoData = this.getLocalStorageData();

    if (!localStoragePromoData.animatedInBanners.includes(promotionName)) {
      localStoragePromoData.animatedInBanners.push(promotionName);
      this.setLocalStorageData(localStoragePromoData);
    }
  }

  public isEligibleForFreeDeliveryPromotion(): Observable<boolean> {
    return this.promotionQueries.getAllPromotions().pipe(
      map((promotions: Promotion[]) => {
        return !!promotions.find((promotion) => promotion.name === PromotionNames.FREE_DELIVERY);
      })
    );
  }

  // We default expirationDays to 100 days, but null can be provided when an infinite expiration is required
  public trackPromotionHidden(promotionName: PromotionName, targetComponents: PromotionComponent[], expirationDays: number = 100): void {
    const promotionData = this.getLocalStorageData();
    let expirationDate = null;

    if (expirationDays) {
      expirationDate = moment().add(expirationDays, 'days').valueOf();
    }

    for (const component of targetComponents) {
      if (promotionData.hiddenPromotions.hasOwnProperty(component)) {
        promotionData.hiddenPromotions[component][promotionName] = { expiry: expirationDate };
      } else {
        promotionData.hiddenPromotions[component] = { [promotionName] : { expiry: expirationDate } };
      }
      this.setLocalStorageData(promotionData);
    }
  }

  public isPromotionHidden(promotionName: PromotionName, component: PromotionComponent): boolean {
    const promotionData = this.getLocalStorageData();
    const closedComponentPromotions = promotionData.hiddenPromotions[component] || {};

    return Object.keys(closedComponentPromotions).includes(promotionName);
  }

  private getLocalStorageData(): PromotionLocalStorageData {
    const initialState: PromotionLocalStorageData = {
      hiddenPromotions: {},
      animatedInBanners: []
    };

    return this.filterOutExpiredPromotionHides({
      ...initialState,
      ...JSON.parse(this.localStorage.getItem(PROMOTIONS_LOCAL_STORAGE_KEY))
    });
  }

  private setLocalStorageData(promotionLocalStorageData: PromotionLocalStorageData): void {
    this.localStorage.setItem(PROMOTIONS_LOCAL_STORAGE_KEY, JSON.stringify(promotionLocalStorageData));
  }

  /**
   * TODO: CPD-12395 should be refactored to filter out expired entries on request, instead of the entire hiddenPromotion content
   * Returns a filtered version of localStorage.hiddenPromotions content where entries that have expired are removed
   */
  private filterOutExpiredPromotionHides(localStorageContent: PromotionLocalStorageData): PromotionLocalStorageData {
    const now = moment().tz('Europe/London').valueOf();
    const hiddenPromotions = {};

    for (const componentKey in localStorageContent.hiddenPromotions) {
      if (!localStorageContent.hiddenPromotions.hasOwnProperty(componentKey)) {
        continue;
      }

      const closedPromotions = localStorageContent.hiddenPromotions[componentKey];
      const updatedComponentSpecificPromotionHides = {};

      // Persist promotion if expiry has not passed OR the expiry is null (null is treated as infinite)
      for (const promotion in closedPromotions) {
        if (!closedPromotions.hasOwnProperty(promotion)) {
          continue;
        }

        const shouldNotExpire = closedPromotions[promotion].expiry === null;
        const notExpiredYet = now.valueOf() <= closedPromotions[promotion].expiry;

        if (shouldNotExpire || notExpiredYet) {
          updatedComponentSpecificPromotionHides[promotion] = closedPromotions[promotion];
        }
      }

      // If a component does not have any promotions left we do not bother maintaining the component key
      if (Object.keys(updatedComponentSpecificPromotionHides).length > 0) {
        hiddenPromotions[componentKey] = updatedComponentSpecificPromotionHides;
      }
    }

    return {
      ...localStorageContent,
      hiddenPromotions
    };
  }
}
