import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-delivery-disruption-banner',
  templateUrl: './delivery-disruption-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryDisruptionBannerComponent {

  @Input()
  public date: moment.Moment;

  @Input()
  public showAsLink: boolean;

  @Output()
  public chooseDelivery: EventEmitter<void> = new EventEmitter();

  public chooseDeliveryTime(): void {
    this.chooseDelivery.emit();
  }
}
