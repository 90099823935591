import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { AnalyticsRule } from './analytics-rule.interface';

export const eaterSignUpPageRules: AnalyticsRule[] = [
  {
    url: '/eat/:locationShareSlug',
    path: 'sso-sign-up-button',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `SSO sign up button clicked`,
    }
  },
  {
    url: '/eat/:locationShareSlug',
    path: 'first-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `First name field`,
    }
  },
  {
    url: '/eat/:locationShareSlug',
    path: 'last-name',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `Last name field`,
    }
  },
  {
    url: '/eat/:locationShareSlug',
    path: 'email',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `Email field`,
    }
  },
  {
    url: '/eat/:locationShareSlug',
    path: 'password',
    events: 'blur',
    definition: {
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `Password field`,
    }
  },
  {
    url: '/eat/:locationShareSlug',
    path: 'submit',
    events: 'click',
    definition: {
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.EATER_SIGN_UP_PAGE,
      label: `Form submitted`,
    }
  },
];
