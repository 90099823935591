import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CustomerQualification,
  FrequencyQualificationTypeEnum,
  FrequencyQualificationTypes,
  HeadCountQualificationTypeEnum,
  HeadCountQualificationTypes,
  SourceQualificationTypeEnum,
  SourceQualificationTypes,
  UseCaseQualificationType,
  UseCaseQualificationTypeEnum,
  UseCaseQualificationTypes
} from '@citypantry/util-models';

import { boolObjectToStrings, stringsToBoolObject } from '@citypantry/util';
import { CustomValidators } from '@citypantry/util-validators';

@Component({
  selector: 'app-customer-qualification-form',
  templateUrl: './customer-qualification-form.component.html'
})
export class CustomerQualificationFormComponent {
  public FrequencyQualificationTypes: FrequencyQualificationTypeEnum = FrequencyQualificationTypes;
  public HeadCountQualificationTypes: HeadCountQualificationTypeEnum = HeadCountQualificationTypes;
  public SourceQualificationTypes: SourceQualificationTypeEnum = SourceQualificationTypes;
  public UseCaseQualificationTypes: UseCaseQualificationTypeEnum = UseCaseQualificationTypes;

  public form: FormGroup;

  public wasSubmitted: boolean;
  public otherSourceSelected: boolean;

  @Input()
  public isEmbedded: boolean;

  @Input()
  public isSubmitting: boolean;

  @Output()
  public formSubmit: EventEmitter<CustomerQualification> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
  ) {
    this.isEmbedded = false;
    this.wasSubmitted = false;
    this.otherSourceSelected = false;

    const formConfig = {
      useCases: this.buildUseCasesFromGroup(),
      frequency: [
        '',
        [ Validators.required ]
      ],
      headCount: [
        '',
        [Validators.required]
      ],
      source: [
        '',
        [Validators.required],
      ],
      otherSource: [
        ''
      ],
    };

    this.form = this.fb.group(formConfig);

    this.form.get('source').valueChanges.subscribe((changes) => {
      const otherSourceField = this.form.get('otherSource');

      if (changes === SourceQualificationTypes.OTHER) {
        otherSourceField.setValidators([Validators.required]);
        this.otherSourceSelected = true;
      } else {
        otherSourceField.setValidators(null);
        this.otherSourceSelected = false;
      }
      otherSourceField.updateValueAndValidity();
    });
  }

  public buildUseCasesFromGroup(): FormGroup {
    const eventsObject = stringsToBoolObject(UseCaseQualificationTypes.values, []);
    const group = this.fb.group(eventsObject);
    group.setValidators(CustomValidators.checkboxGroup.requiredMin(1));

    return group;
  }

  public shouldShowErrors(field: string): boolean {
    const control = this.form.get([field]);
    return control.invalid && (control.dirty || this.wasSubmitted);
  }

  public submitForm(): void {
    this.wasSubmitted = true;

    if (!this.form.valid) {
      // required to make the validation errors show on submit if a user attempts to submit straight away
      Object.values(this.form.controls).forEach((control) => {
        control.markAsTouched();
        control.markAsDirty();
      });

      return;
    }

    const useCases: UseCaseQualificationType[] = boolObjectToStrings(this.form.value.useCases, UseCaseQualificationTypes.values);

    const qualificationData: CustomerQualification = {
      useCases,
      frequency: this.form.value.frequency,
      headCount: this.form.value.headCount,
      source: this.form.value.source,
      sourceOther: this.form.value.otherSource,
    };

    this.formSubmit.emit(qualificationData);
  }
}
