/* eslint-disable @typescript-eslint/type-annotation-spacing, max-len */ // Format the declarations for readability

export type UseCaseQualificationType = 'breakfast' | 'team_lunches' | 'events' | 'meetings_and_workshops' | 'holidays_and_celebrations';

export class UseCaseQualificationTypes {
  public static BREAKFAST:                 UseCaseQualificationType & 'breakfast'                 = 'breakfast';
  public static TEAM_LUNCHES:              UseCaseQualificationType & 'team_lunches'              = 'team_lunches';
  public static EVENTS:                    UseCaseQualificationType & 'events'                    = 'events';
  public static MEETINGS_AND_WORKSHOPS:    UseCaseQualificationType & 'meetings_and_workshops'    = 'meetings_and_workshops';
  public static HOLIDAYS_AND_CELEBRATIONS: UseCaseQualificationType & 'holidays_and_celebrations' = 'holidays_and_celebrations';

  public static get values(): UseCaseQualificationType[] {
    return [
      UseCaseQualificationTypes.BREAKFAST,
      UseCaseQualificationTypes.TEAM_LUNCHES,
      UseCaseQualificationTypes.EVENTS,
      UseCaseQualificationTypes.MEETINGS_AND_WORKSHOPS,
      UseCaseQualificationTypes.HOLIDAYS_AND_CELEBRATIONS,
    ];
  }

  // Do not modify this line - Custom code below here
  // Note: These values should match whatever is required by HubSpot as the API is no longer constrained by enum.
}

export type UseCaseQualificationTypeEnum = typeof UseCaseQualificationTypes;
