import { UserId } from '../user-id.model';

export interface SimpleUser {
  id: UserId;
  firstName: string;
  lastName: string;
  email: string;
}

export function createSimpleUserFromJson(json: Partial<SimpleUser> = {}): SimpleUser {
  const user: Partial<SimpleUser> = {};

  user.id = json.id;
  user.firstName = json.firstName;
  user.lastName = json.lastName;
  user.email = json.email;

  return user as SimpleUser;
}
