import { AnalyticsActions } from '../analytics-action.enum';
import { AnalyticsCategories } from '../analytics-category.enum';
import { InViewportComponent } from '../in-viewport.component';
import { AnalyticsRule } from './analytics-rule.interface';

// TODO CPD-14799: remove or clean up depending on the first-top-up-reward-voucher experiment outcome
export const firstTopUpRewardExperimentRules: AnalyticsRule[] = [
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog first-top-up-dialog-view',
    events: InViewportComponent.events.firstSeen,
    definition: (event) => ({
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Top up dialog seen`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog first-top-up-reward-dialog-wrapper',
    events: {
      class: 'DialogComponent',
      eventName: 'abort'
    },
    definition: (event) => ({
      action: AnalyticsActions.BLUR,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Dialog was dismissed`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  }, // blur on hidden
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog add-item-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Add item was clicked`,
      extraFields: {
        addedItemId: event.data['add-item-button'].itemId,
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog remove-item-button',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Remove item was clicked`,
      extraFields: {
        removedItemId: event.data['remove-item-button'].itemId,
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog i-want-something-else',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `I want something else was clicked`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog not-right-now',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Not right now clicked`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog continue-to-checkout',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `Continue to checkout clicked`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-reward-dialog dont-show-again',
    events: 'click',
    definition: (event) => ({
      action: AnalyticsActions.CLICK,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `I don't want to see this again was clicked`,
      extraFields: {
        userId: event.data['first-top-up-reward-dialog'].userId,
        orderId: event.data['first-top-up-reward-dialog'].orderId,
        currentCartAmount: event.data['first-top-up-reward-dialog'].cartTotal,
        budget: event.data['first-top-up-reward-dialog'].remainingBudget,
        suggestedItemIds: event.data['first-top-up-reward-dialog'].suggestedItemIds
      }
    }),
  },
  {
    url: '/my-meals/*',
    path: 'first-top-up-voucher-confirmation-message',
    events: InViewportComponent.events.firstSeen,
    definition: {
      action: AnalyticsActions.VIEW,
      category: AnalyticsCategories.FIRST_TOP_UP_VOUCHER_REWARD,
      label: `User viewed first top up voucher reward confirmation message`,
    },
  },
];
