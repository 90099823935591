import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItems } from '@citypantry/util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperationalRegionsApi {
  constructor(
    private http: HttpClient,
  ) {}

  public getOperationalRegions(): Observable<string[]> {
    return this.http.get(`/operational-regions`).pipe(
      getItems(),
    );
  }
}
