import { Injectable } from '@angular/core';
import { AppState } from '@citypantry/state';
import { NewPaymentCard, OrderId, PaymentCard, PaymentCardId, ThreeDSecureCustomerParameters, UserId } from '@citypantry/util-models';
import { Store } from '@ngrx/store';
import { BraintreeService } from '@citypantry/shared-braintree';
import { from, Observable } from 'rxjs';
import { PaymentActions } from './payment.actions';
import { PaymentModule } from './payment.module';

@Injectable({ providedIn: PaymentModule })
export class PaymentService {

  constructor(
    private store: Store<AppState>,
    private braintreeService: BraintreeService,
  ) {}

  public addCard(
    card: NewPaymentCard,
    userId: UserId,
    threeDSecureParameters: ThreeDSecureCustomerParameters
  ): Observable<PaymentCard> {
    return from(this.braintreeService.addNewPaymentCard(card, userId, threeDSecureParameters));
  }

  public verifyCard(
    cardId: PaymentCardId,
    threeDSecureParameters: ThreeDSecureCustomerParameters
  ): Observable<string> {
    return from(this.braintreeService.verifyExistingPaymentCard(cardId, threeDSecureParameters));
  }

  public payForIndividualChoiceOrder(orderId: OrderId, cardId: PaymentCardId, threeDSecureEnrichedNonce: string): void {
    this.store.dispatch(PaymentActions.payForIndividualChoiceOrder({ orderId, cardId, threeDSecureEnrichedNonce }));
  }

  public loadPaymentCards(): void {
    this.store.dispatch(PaymentActions.paymentCardsFetch());
  }

  public paymentCardsFetchSuccess(paymentCards: PaymentCard[]): void {
    this.store.dispatch(PaymentActions.paymentCardsFetchSuccess({ paymentCards }));
  }
}
