<app-logo-popup
  [show]="true"
  [noWidth]="false"
  (close)="onClose()"
  test-id="popup"
>
  <h3 class="dialog__title mb-small">Find new {{ 'restaurant' | plural:cancelledOrderIds?.length }} for your delivery</h3>

  <div class="dialog__content m-large">
    These restaurants are all available for your new delivery slot.<br>
    Your previous order <span test-id="orderIds">{{ formatOrderIds() }}</span> has been cancelled.
  </div>

  <div class="dialog__footer justify-content-center">
    <button
      class="button px-x-large"
      type="button"
      (click)="onClose()"
      test-id="okButton"
    >
      Ok
    </button>
  </div>
</app-logo-popup>
