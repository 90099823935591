/* eslint-disable max-classes-per-file */ // These two directives are tightly coupled and should live in the same file

import { ChangeDetectorRef, Directive, Host, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Directive similar to ngSwitch but for errors.
 * Use like:
 * <span [errorSwitch]="form.get('field').errors">
 *   <span *errorCase="'required'">Please select a value.</span>
 *   <span *errorCase="'min'; let error">Please select at least {{ error.minValues }} options.</span>
 *   <span *errorCase="'default'">Please check your inputs.</span>
 * </span>
 *
 * "default" is a special case mapped whenever errors are present but none of the other cases match.
 *
 * TODO when we get the time: test
 */
@Directive({
  selector: '[errorSwitch]'
})
export class ErrorSwitchDirective implements OnInit {

  @Input()
  public set errorSwitch(errors: { [key: string]: any }) {
    this.errors = errors;
    this.updateAllErrors(true);
  }

  public errors: { [key: string]: any };

  private cases: ErrorCaseDirective[];
  private updatePending: boolean;

  constructor() {
    this.cases = [];
  }

  public ngOnInit(): void {
    this.scheduleUpdate();
  }

  public registerCase(caseDirective: ErrorCaseDirective): void {
    if (this.cases.indexOf(caseDirective) < 0) {
      this.cases.push(caseDirective);
    }
    this.scheduleUpdate();
  }

  public deregisterCase(caseDirective: ErrorCaseDirective): void {
    this.cases = this.cases.filter((_case) => _case !== caseDirective);
  }

  private scheduleUpdate(): void {
    this.updatePending = true;
    setTimeout(() => {
      this.updateAllErrors();
    });
  }

  private updateAllErrors(force?: boolean): void {
    if (!force && !this.updatePending) {
      return;
    }
    this.updatePending = false;

    const matchingCase = this.findActiveCase();

    this.cases.forEach((caseDirective: ErrorCaseDirective) => {
      if (caseDirective === matchingCase) {
        caseDirective.show(this.errors[caseDirective.errorCase], this.errors);
      } else {
        caseDirective.hide();
      }
    });
  }

  private findActiveCase(): ErrorCaseDirective {
    if (!this.errors) {
      return null;
    }
    const errorKeys = this.errors ? Object.keys(this.errors) : [];
    const casesByName: { [key: string]: ErrorCaseDirective } = {};
    this.cases.forEach((_case) => casesByName[_case.errorCase] = _case);

    for (const key of errorKeys) {
      if (casesByName[key]) {
        return casesByName[key];
      }
    }
    if (errorKeys.length && casesByName['default']) {
      return casesByName['default'];
    }
    return null;
  }
}

@Directive({
  selector: '[errorCase]'
})
export class ErrorCaseDirective implements OnDestroy {

  @Input()
  public errorCase: string;

  private isShown: boolean;

  constructor(
    @Host() private parent: ErrorSwitchDirective,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {
    this.parent.registerCase(this);
  }

  public ngOnDestroy(): void {
    this.parent.deregisterCase(this);
  }

  public show(error: any, allErrors: { [key: string]: any }): void {
    if (this.isShown) {
      this.viewContainer.clear(); // Remove the old data
    }
    this.viewContainer.createEmbeddedView(this.templateRef, {
      // Output bindings available in the *errorCase definition
      $implicit: error, // let x
      error,            // let x = error
      allErrors         // let x = allErrors
    });
    this.isShown = true;
    this.cdr.markForCheck();
  }

  public hide(): void {
    if (this.isShown) {
      this.viewContainer.clear();
      this.isShown = false;
    }
  }
}
