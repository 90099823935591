export type CartStatus = 'IDLE' | 'VALIDATING' | 'SUBMITTING';

export class CartStatuses {
  public static IDLE: CartStatus = 'IDLE';
  public static VALIDATING: CartStatus = 'VALIDATING';
  public static SUBMITTING: CartStatus = 'SUBMITTING';

  public static get values(): CartStatus[] {
    return [
      CartStatuses.IDLE,
      CartStatuses.VALIDATING,
      CartStatuses.SUBMITTING
    ];
  }
}

export type CartStatusEnum = typeof CartStatuses;
