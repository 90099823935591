import { mapSelectors } from '@citypantry/util';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AuthState,
  customerSelectors,
  eaterSelectors,
  FEATURE_STATE_NAME,
  getHeaderType,
  getLostbarToken,
  getPromotions,
  getShowSSOTokenError,
  getUserBetaFeatures,
  getUserEmail,
  getUserFirstName,
  getUserHumanId,
  getUserId,
  getUserName,
  getUserSearchPreferences,
  hasSource,
  isBetaFeatureEnabledGetter,
  isCustomer,
  isCustomerOrOrderer,
  isCustomerOrStaffOrSudo,
  isEater,
  isLoaded,
  isLoading,
  isLoggedIn,
  isOnlyEater,
  isOrderer,
  isPurchaseOrderNumberAndDepartmentReferenceRequired,
  isSsoUser,
  isStaff,
  isStaffOrSudo,
  isSudo,
  isVendor,
  permissionsSelectors,
  vendorSelectors
} from './auth.state';

const getAuthState = createFeatureSelector<AuthState>(FEATURE_STATE_NAME);

const isWeWorkEmail = (email: string) => !!(email && email.match(/@(wework|welive|wegrow)\.com($|\.[a-z0-9_.-]+)/i));

export const AuthSelectors = {
  ...mapSelectors(getAuthState, {
    isFresh: (state: AuthState) => state.source === 'api',
    isLoaded,
    hasSource,

    getHeaderType,
    getLostbarToken,
    getPromotions,
    getShowSSOTokenError,
    getUserBetaFeatures,
    getUserEmail,
    getUserFirstName,
    getUserHumanId,
    getUserId,
    getUserName,
    getUserSearchPreferences,
    isBetaFeatureEnabledGetter,
    isCustomer,
    isCustomerOrOrderer,
    isCustomerOrStaffOrSudo,
    isEater,
    isLoading,
    isLoggedInUser: isLoggedIn,
    isOnlyEater,
    isOrderer,
    isPurchaseOrderNumberAndDepartmentReferenceRequired,
    isSsoUser,
    isStaff,
    isStaffOrSudo,
    isSudo,
    isVendor,
    isWeWorkUser: createSelector(getUserEmail, isWeWorkEmail),
  }),
  customer: mapSelectors(getAuthState, customerSelectors),
  vendor: mapSelectors(getAuthState, vendorSelectors),
  eater: mapSelectors(getAuthState, eaterSelectors),
  navigationPermissions: mapSelectors(getAuthState, permissionsSelectors),
};
