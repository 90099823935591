import { environment } from '@citypantry/shared-app-config';
import { mapSelectors } from '@citypantry/util';
import {
  CartCustomItem,
  CartItemBundle,
  CustomItem,
  EaterLocation,
  EaterMeal,
  EMPTY_DIETARIES,
  findMenuItemById,
  IndividualChoiceOrderSummary,
  isBundleCartItem,
  isCustomCartItem,
  isCustomItem,
  isItemBundle,
  Item,
  ItemBundle,
  MenuContent,
  MyMeals,
  MyMealsUtils,
  OrderId,
} from '@citypantry/util-models';
import moment from 'moment';
import { getEaterState } from '../app.state';
import { computeMenuDietaries } from '../util/compute-menu-dietaries';
import { EaterState } from './eater.state';

const getOrder = (state: EaterState) =>
  state.order || null;
const getOrderId = (state: EaterState): OrderId | null => {
  const order = getOrder(state);
  return order ? order.orderId : null;
};
const getOrderConfirmation = (state: EaterState) =>
  state.orderConfirmation;
const getOrderSummary = (state: EaterState): IndividualChoiceOrderSummary | null =>
  state.order ? state.order.summary : null;

const getCurrencyIsoCode = (state: EaterState): string | null =>
  getOrderSummary(state) ? getOrderSummary(state).vendor.country.currency.code : null;
const getMenuContent = (state: EaterState): MenuContent | null =>
  getOrderSummary(state) ? getOrderSummary(state).individualChoice.menuContent : null;
const getChoiceDeadline = (state: EaterState): moment.Moment | null =>
  getOrderSummary(state) ? getOrderSummary(state).individualChoice.choiceDeadline : null;
const isSubsidisedChoice = (state: EaterState): boolean | null =>
  getOrderSummary(state) ? getOrderSummary(state).individualChoice.isSubsidisedChoice : null;
const getCompanyName = (state: EaterState): string | null =>
  getOrderSummary(state) ? getOrderSummary(state).individualChoice.companyName : null;
const getOrderGroupId = (state: EaterState): OrderId | null =>
  getOrderSummary(state) ? getOrderSummary(state).individualChoice.orderGroupId : null;

const getRecommendedItemFromMenuContent = (state: EaterState): Item | null => {
  if(state.recommendedItemId) {
    const menuContent = getOrderSummary(state)?.individualChoice.menuContent;
    return menuContent && findMenuItemById(menuContent, state.recommendedItemId) || null;
  }
  return null;
};

const getShareLinkLocationToBeAdded = (state: EaterState): EaterLocation | null => state.shareLinkLocationToBeAdded;
const getCurrentEaterLocation = (state: EaterState): EaterLocation | null =>
  state.eaterCurrentLocationAddModal ? state.eaterCurrentLocationAddModal.eaterLocation : null;
const getEaterMeals = (state: EaterState) =>
  state.eaterMeals;
const getEaterPreviousMealsLoadablePage = (state: EaterState) =>
  state.eaterPreviousMeals;
const getMappedEaterPreviousMeals = (state: EaterState) =>
  MyMealsUtils.mapEaterMealsToMyMeals(state.eaterPreviousMeals.item.items, moment.tz('Europe/London'), true);
const getEaterPreviousMealsPage = (state: EaterState) =>
  state.eaterPreviousMeals.item ? state.eaterPreviousMeals.item.page : 0;
const getEaterPreviousMealsPageSize = (state: EaterState) =>
  state.eaterPreviousMeals.item ? state.eaterPreviousMeals.item.pageSize : 0;
const getEaterPreviousMealsTotal = (state: EaterState) =>
  state.eaterPreviousMeals.item ? state.eaterPreviousMeals.item.total : 0;
const getEaterPreviousDepartmentReferences = (state: EaterState) =>
  state.eaterPreviousDepartmentReferences.items ? state.eaterPreviousDepartmentReferences.items : [];
const getCartItems = (state: EaterState) =>
  state.cartItems;
const getCartTotal = (state: EaterState) =>
  state.cartTotal;
const getDepartmentReference = (state: EaterState) =>
  state.departmentReference;
const getDeskNumber = (state: EaterState) =>
  state.deskNumber;
const getRecommendedItemId = (state: EaterState) =>
  state.recommendedItemId;
const isSubmittingCart = (state: EaterState) =>
  state.isSubmittingCart;
const getCartSubmitError = (state: EaterState) =>
  state.cartSubmitError;
const getAddCurrentLocationDisplayModal = (state: EaterState) =>
  state.eaterCurrentLocationAddModal;
const getPaymentCard = (state: EaterState) =>
  state.paymentCard;
const getCurrentMenuDietaries = (state: EaterState) => {
  const orderSummary = getOrderSummary(state);
  if (!orderSummary) {
    return EMPTY_DIETARIES;
  }
  return computeMenuDietaries(orderSummary.individualChoice.menuContent);
};

const isCartsAdditionalReferenceRequired = (state: EaterState) => {
  const orderSummary = getOrderSummary(state);
  return orderSummary && orderSummary.individualChoice.cartsAdditionalReferenceRequired;
};
const getRememberCard = (state: EaterState) =>
  state.rememberCard;
const getEaterFeedbackFormMealOptions = (state: EaterState) => state.eaterFeedbackFormMealOptions;
const isEaterFeedbackFormDisplayed = (state: EaterState) => state.showEaterFeedbackFormDialog;
const isEaterFeedbackFormSubmitting = (state: EaterState) => state.isEaterFeedbackFormSubmitting;
const isEaterOrderRatingFormSubmitting = (state: EaterState) => state.isEaterOrderRatingFormSubmitting;
const getEaterFeedbackFormError = (state: EaterState) => state.eaterFeedbackFormError;
const getEaterFeedbackFormSuccess = (state: EaterState) => state.eaterFeedbackFormSuccess;
const isEaterMealFeedbackModalDisplayed = (state: EaterState) => state.orderConfirmation !== null;
const getEaterOrderRatingFormMealOption = (state: EaterState) => state.eaterOrderRatingFormEaterMealOption;
const isEaterOrderRatingFormDisplayed = (state: EaterState) => state.showEaterOrderRatingFormDialog;

const isAgeConfirmationDialogVisible = (state: EaterState) => !!state.ageConfirmationDialogVisible;
const getRemainingBudget = (state: EaterState) =>
  state.remainingBudget;
const getMinimumTransactionFee = (state: EaterState) =>
  state.minimumTransactionFee;

const getEaterFeedback = (state: EaterState) => state.eaterFeedback;
const isEaterFeedbackCreated = (state: EaterState) => state.eaterFeedbackCreated;

export const getOpenCartItem = (state: EaterState): CartCustomItem | CartItemBundle | null => {
  if (!state.openItem || typeof state.openItem.cartIndex !== 'number') {
    return null;
  }

  if (!state.cartItems || !state.cartItems[state.openItem.cartIndex]) {
    throw new Error('Unable to find cart item');
  }

  const cartItem = state.cartItems[state.openItem.cartIndex];

  if (!(isCustomCartItem(cartItem) || isBundleCartItem(cartItem)) || state.openItem.itemId !== cartItem.item.id) {
    if (environment.production) {
      return null;
    } else {
      throw new Error('Invalid cart item or cart item ID');
    }
  }

  return cartItem;
};

const getJustEatVoucherCode = (state: EaterState) => state.justEatVoucher ? state.justEatVoucher.code : null;

function getOpenItem(state: EaterState): CustomItem | ItemBundle | null {
  if (!state.openItem) {
    return null;
  }

  const menuContent = state.order ? state.order.summary.individualChoice.menuContent : null;
  const item = menuContent && findMenuItemById(menuContent, state.openItem.itemId);

  return item && (isCustomItem(item) || isItemBundle(item)) ? item : null;
}

/**
 * @returns A list of meals from the store, filtered from and after provided day, grouped and sorted in a MyMeals object.
 */
function getMyMealsGetter(state: EaterState): (now: moment.Moment) => MyMeals {
  const eaterMeals = getEaterMeals(state).items;

  return (now: moment.Moment) => {
    const startOfToday = now.clone().startOf('day');
    const filteredEaterMeals = eaterMeals.filter(
      (meal: EaterMeal) => meal.requestedDeliveryDate.isAfter(startOfToday)
    );

    return MyMealsUtils.mapEaterMealsToMyMeals(filteredEaterMeals, now);
  };
}

export const EaterSelectors = mapSelectors(getEaterState, {
  getShareLinkLocationToBeAdded,
  getOrder,
  getOrderId,
  getOrderConfirmation,
  getOrderSummary,
  getCurrentEaterLocation,
  getEaterMeals,
  getEaterPreviousMealsLoadablePage,
  getMappedEaterPreviousMeals,
  getEaterPreviousMealsPage,
  getEaterPreviousMealsPageSize,
  getEaterPreviousMealsTotal,
  getEaterPreviousDepartmentReferences,
  getCurrentMenuDietaries,
  getCartItems,
  getCartTotal,
  getDepartmentReference,
  getDeskNumber,
  getRecommendedItemId,
  isSubmittingCart,
  getCartSubmitError,
  getAddCurrentLocationDisplayModal,
  getPaymentCard,
  isSubsidisedChoice,
  isCartsAdditionalReferenceRequired,
  getRememberCard,
  getEaterFeedbackFormMealOptions,
  isEaterFeedbackFormDisplayed,
  isEaterFeedbackFormSubmitting,
  isEaterOrderRatingFormSubmitting,
  getEaterFeedbackFormError,
  getEaterFeedbackFormSuccess,
  isEaterOrderRatingFormDisplayed,
  getEaterOrderRatingFormMealOption,
  getRemainingBudget,
  getMinimumTransactionFee,
  getOpenItem,
  getOpenCartItem,
  getCurrencyIsoCode,
  getMenuContent,
  getChoiceDeadline,
  getCompanyName,
  getOrderGroupId,
  getJustEatVoucherCode,
  getMyMealsGetter,
  isEaterMealFeedbackModalDisplayed,
  isAgeConfirmationDialogVisible,
  getEaterFeedback,
  isEaterFeedbackCreated,
  getRecommendedItemFromMenuContent,
});
