import { Action } from '@ngrx/store';
import { BetaFeature } from '../models/beta-feature.enum';
import { HeaderType } from '../models/header-type.enum';
import { UserProfile } from '../models/user-profile.model';
import { UserType, UserTypes } from '../models/user-type.enum';
import { NavigationPermissions } from '../models/navigation-permissions.model';
import {
  ActionTypes,
  AuthFetchedAction,
  AuthFetchedForRegisteredEaterAction,
  BetaFeatureDisabledAction
} from './auth.actions';

export const FEATURE_STATE_NAME = 'auth';

interface CommonAuthState {
  source: 'api' | 'storage' | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  userProfile: null | UserProfile;
  navigationPermissions: null | NavigationPermissions;
  showSSOTokenError: boolean;
}

interface LoggedOutAuthState extends CommonAuthState {
  isLoggedIn: false;
  userProfile: null;
  navigationPermissions: null;
}

interface LoggedInAuthState extends CommonAuthState {
  isLoggedIn: true;
  userProfile: UserProfile;
  navigationPermissions: NavigationPermissions;
}

export type AuthState = LoggedOutAuthState | LoggedInAuthState;

export const initialState: AuthState = {
  source: null,
  isLoading: false,
  isLoggedIn: false,
  userProfile: null,
  navigationPermissions: null,
  showSSOTokenError: false,
};

export function reducer(state: AuthState = initialState, action: Action): AuthState {
  switch (action.type) {
    case ActionTypes.FETCH_AUTH:
    case ActionTypes.AUTH_REFRESH_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ActionTypes.AUTH_FETCHED_FOR_REGISTERED_EATER:
    case ActionTypes.AUTH_FETCHED: {
      const {
        profile: userProfile,
        navigationPermissions,
      } = (action as AuthFetchedAction | AuthFetchedForRegisteredEaterAction).payload;

      const updatedParams: Partial<AuthState> = {
        isLoading: false,
        source: 'api'
      };

      if (userProfile && navigationPermissions) {
        return {
          ...state,
          ...updatedParams,
          isLoggedIn: true,
          userProfile,
          navigationPermissions,
        };
      } else {
        return {
          ...state,
          ...updatedParams,
          isLoggedIn: false,
          userProfile: null,
          navigationPermissions: null,
        };
      }
    }

    case ActionTypes.AUTH_FETCH_FAILED:
    case ActionTypes.AUTH_FETCH_FAILED_FOR_REGISTERED_EATER: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.BETA_FEATURE_DISABLED: {
      if (!state.isLoggedIn) {
        return state;
      }

      const userProfile = state.userProfile;
      const betaFeatures = userProfile.betaFeatures.filter((feature) => feature !== (action as BetaFeatureDisabledAction).payload.feature);

      return {
        ...state,
        userProfile: {
          ...userProfile,
          betaFeatures
        }
      };
    }

    case ActionTypes.UNMASQUERADE: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ActionTypes.SHOW_SSO_TOKEN_ERROR: {
      return {
        ...state,
        showSSOTokenError: true
      };
    }

    case ActionTypes.HIDE_SSO_TOKEN_ERROR: {
      return {
        ...state,
        showSSOTokenError: false
      };
    }

    default: {
      return state;
    }
  }
}

export const hasSource = (state: AuthState) => state.source !== null;
export const isLoaded = (state: AuthState) => !state.isLoading;
export const isLoading = (state: AuthState) => state.isLoading;

export const isType = (type: UserType, state: AuthState) => state.isLoggedIn && state.userProfile.userTypes.indexOf(type) >= 0;
export const isStaff =    isType.bind(null, UserTypes.STAFF);
export const isCustomer = isType.bind(null, UserTypes.CUSTOMER);
export const isVendor =   isType.bind(null, UserTypes.VENDOR);
export const isOrderer =  isType.bind(null, UserTypes.ORDERER);
export const isEater =    isType.bind(null, UserTypes.EATER);
export const isCustomerOrOrderer = (state: AuthState) => isCustomer(state) || isOrderer(state);
export const isOnlyEater = (state: AuthState) => state.isLoggedIn &&
  state.userProfile.userTypes.indexOf(UserTypes.EATER) >= 0 &&
  state.userProfile.userTypes.length === 1;
export const isSudo = (state: AuthState) => state.isLoggedIn && !!state.userProfile.isSudo;
export const isStaffOrSudo = (state: AuthState) => isStaff(state) || isSudo(state);
export const isCustomerOrStaffOrSudo = (state: AuthState) => isCustomer(state) || isSudo(state) || isStaff(state);
export const isSsoUser = (state: AuthState) => state.isLoggedIn && state.userProfile.isSsoUser;
export const getPromotions = (state: AuthState) =>
  (state.userProfile && state.userProfile.promotions) ? state.userProfile.promotions : [];

export const isLoggedIn = (state: AuthState) => state.isLoggedIn;
export const getUserId = (state: AuthState) => state.isLoggedIn ? state.userProfile.id : null;
export const getUserName = (state: AuthState) => state.isLoggedIn ? state.userProfile.name : '';
export const getUserHumanId = (state: AuthState) => state.isLoggedIn ? state.userProfile.humanId : null;
export const getUserFirstName = (state: AuthState) => state.isLoggedIn ? state.userProfile.firstName : '';
export const getUserEmail = (state: AuthState) => state.isLoggedIn ? state.userProfile.email : '';
export const getUserBetaFeatures = (state: AuthState) => state.isLoggedIn ? state.userProfile.betaFeatures : [];
export const getUserSearchPreferences = (state: AuthState) => state.isLoggedIn ? state.userProfile.searchPreferences : null;
export const getLostbarToken = (state: AuthState) => state.isLoggedIn ? state.userProfile.lostbarToken : null;

// TODO CPD-15459 rename poNumberRequired in userProfile to something that describes that both purchase order number
// and department reference are required
export const isPurchaseOrderNumberAndDepartmentReferenceRequired = (state: AuthState) => state.isLoggedIn &&
  (state.userProfile.poNumberRequired || isOrderer(state));

export const getShowSSOTokenError = (state: AuthState) => state.showSSOTokenError;

export const getHeaderType = (state: AuthState): HeaderType =>  {
  if (state.isLoggedIn && state.userProfile.userTypes) {
    if (state.userProfile.userTypes.includes(UserTypes.STAFF)) {
      return HeaderType.STAFF;
    }
    if (permissionsSelectors.canAccessJefb(state)) {
      if (state.userProfile.userTypes.includes(UserTypes.VENDOR)) {
        return HeaderType.VENDOR;
      }
      if (state.userProfile.userTypes.includes(UserTypes.CUSTOMER) || state.userProfile.userTypes.includes(UserTypes.ORDERER)) {
        return HeaderType.PURCHASER;
      }
      if (state.userProfile.userTypes.includes(UserTypes.EATER)) {
        return HeaderType.EATER;
      }
    } else if (permissionsSelectors.canAccessOnDemand(state)) {
      return HeaderType.ON_DEMAND;
    }
  }
  return HeaderType.DEFAULT;
};

export const isBetaFeatureEnabledGetter = (state: AuthState): ((feature: BetaFeature) => boolean) => {
  return (feature) => state.userProfile.betaFeatures.includes(feature);
};

export const customerSelectors = {
  getId: (state: AuthState) => isCustomerOrOrderer(state) ? state.userProfile.customer.id : null,
  isOnMealPlan: (state: AuthState) => isCustomer(state) && state.userProfile.customer.isOnMealPlan,
  getNumberOfOrders: (state: AuthState) => isCustomer(state) ? state.userProfile.customer.numberOfOrders : null,
  getCompanyName: (state: AuthState) => isCustomer(state) && state.userProfile.customer.companyName || null,
  isFullyQualified: (state: AuthState) => isCustomer(state) && state.userProfile.customer.qualification
    && !!state.userProfile.customer.qualification.frequency
    && !!state.userProfile.customer.qualification.headCount
    && !!state.userProfile.customer.qualification.source
    && state.userProfile.customer.qualification.useCases.length > 0,
  isSubsidisedChoiceEnabled: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.isSubsidisedChoiceEnabled || false,
  isAdvancedBudgetingEnabled: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.isAdvancedBudgetingEnabled || false,
  getAdvancedBudgets: (state: AuthState) =>
    isCustomer(state) ? state.userProfile.customer.advancedBudgets : [],
  isEligibleForEaterChoiceOpenTimes: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.isEligibleForEaterChoiceOpenTimes || false,
  getTotalEaters: (state: AuthState) =>
    isCustomer(state) ? state.userProfile.customer.totalEaters : null,
  isPayOnAccountEnabled: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.isPayOnAccountEnabled || false,
  getHiddenBudgetVendors: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.hiddenBudgetVendors || [],
  isColleagueGroupsEnabled: (state: AuthState) =>
    isCustomer(state) && state.userProfile.customer.isColleagueGroupsEnabled,
  getLocations: (state: AuthState) => isCustomerOrOrderer(state) ? state.userProfile.locations : [],
  getPrimaryOperationalRegion: (state: AuthState) =>
    isCustomerOrOrderer(state) && state.userProfile.primaryOperationalRegion,
  isEligibleForIndividualChoice: (state: AuthState) =>
    isCustomerOrOrderer(state) && state.userProfile.isEligibleForIndividualChoice || false,
  isCartsAdditionalReferenceRequired: (state: AuthState) =>
    isCustomerOrOrderer(state) && state.userProfile.isCartsAdditionalReferenceRequired || false,
  canEnterPostcode: isCustomer
};

export const vendorSelectors = {
  /**
   * @deprecated CPD-3279 should not expose the whole object - use sub-selectors instead
   */
  // eslint-disable-next-line deprecation/deprecation
  getAll: (state: AuthState) => isVendor(state) ? state.userProfile.vendor : null,
  getId: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.id : null,
  getName: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.name : '',
  getAvatarUrl: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.avatarUrl : '',
  getPerformanceId: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.performanceId : null,
  getCurrency: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.country.currency : null,
  getCountryIso2Code: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.country.id : null,
  getAllowedVatRateTypes: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.country.allowedVatRateTypes : [],
  isIndividualChoiceEnabled: (state: AuthState) => isVendor(state) && state.userProfile.vendor.isIndividualChoiceEnabled || false,
  getLocations: (state: AuthState) => isVendor(state) ? state.userProfile.vendor.locations : [],
};

export const eaterSelectors = {
  getLocations: (state: AuthState) => isEater(state) ? state.userProfile.eater.locations : [],
  isJustEatVouchersEnabled: (state: AuthState) => isEater(state) ? state.userProfile.eater.isJustEatVouchersEnabled : false,
  getJustEatVoucherSchedule: (state: AuthState) => isEater(state) ? state.userProfile.eater.justEatVoucherSchedule : null,
  isCurrentTimeScheduledForJustEatVouchers: (state: AuthState) => isEater(state) ? !!state.userProfile.eater.justEatVoucherSchedule : false,
  justEatVouchersAmount: (state: AuthState) => (isEater(state) && state.userProfile.eater.justEatVoucherSchedule)
    ? state.userProfile.eater.justEatVoucherSchedule.amount
    : null,
};

export const permissionsSelectors = {
  canAccessJefb: (state: AuthState) => !!state.navigationPermissions?.canAccessJefb,
  canAccessOnDemand: (state: AuthState) => !!state.navigationPermissions?.canAccessOnDemand,
};

export function deserialiseAuthState(state: AuthState): AuthState {
  if (!state) {
    return initialState;
  }
  try {
    return {
      ...state,
      isLoading: false,
      source: 'storage',
      showSSOTokenError: false,
    };
  } catch (e) {
    console.error(e);
    return initialState;
  }
}
