import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AppState } from '@citypantry/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelectors } from '../state/auth.select';

@Injectable({
  providedIn: 'root',
})
export class IsStaffGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(AuthSelectors.isStaff).pipe(
      map((isStaff) => {
        if (isStaff) {
          return true;
        }

        return this.router.parseUrl('/login');
      }),
    );
  }
}
