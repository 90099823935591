import { Component } from '@angular/core';
import moment from 'moment';
@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {
  public currentYear: number;

  constructor() {
    this.currentYear = moment().tz('Europe/London').year();
  }
}
