// Cart deliverability is when you have a cart with a given date/postcode.
// These issues represent the things that can prevent the cart from being
// deliverable with those parameters.

/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type CartDeliverabilityProblemType =
  'CpOnHoliday'
  | 'OutsideMenuSchedule'
  | 'SameDayOrder'
  | 'TomorrowOrderPastDeadline'
  | 'UndeliverableItems'
  | 'VendorCapacityExceeded'
  | 'VendorOnHoliday'
  | 'ItemMenuMismatch';

export class CartDeliverabilityProblemTypes {
  public static CP_ON_HOLIDAY:                CartDeliverabilityProblemType & 'CpOnHoliday'               = 'CpOnHoliday';
  public static OUTSIDE_MENU_SCHEDULE:        CartDeliverabilityProblemType & 'OutsideMenuSchedule'       = 'OutsideMenuSchedule';
  public static SAME_DAY_ORDER:               CartDeliverabilityProblemType & 'SameDayOrder'              = 'SameDayOrder';
  public static TOMORROW_ORDER_PAST_DEADLINE: CartDeliverabilityProblemType & 'TomorrowOrderPastDeadline' = 'TomorrowOrderPastDeadline';
  public static UNDELIVERABLE_ITEMS:          CartDeliverabilityProblemType & 'UndeliverableItems'        = 'UndeliverableItems';
  public static VENDOR_CAPACITY_EXCEEDED:     CartDeliverabilityProblemType & 'VendorCapacityExceeded'    = 'VendorCapacityExceeded';
  public static VENDOR_ON_HOLIDAY:            CartDeliverabilityProblemType & 'VendorOnHoliday'           = 'VendorOnHoliday';
  public static ITEM_MENU_MISMATCH:           CartDeliverabilityProblemType & 'ItemMenuMismatch'          = 'ItemMenuMismatch';

  public static get values(): CartDeliverabilityProblemType[] {
    return [
      CartDeliverabilityProblemTypes.CP_ON_HOLIDAY,
      CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE,
      CartDeliverabilityProblemTypes.SAME_DAY_ORDER,
      CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE,
      CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS,
      CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED,
      CartDeliverabilityProblemTypes.VENDOR_ON_HOLIDAY,
      CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH,
    ];
  }

  // Do not modify this line - Custom code below here

  /**
   * Return problems in order of priority; earlier problems will be less solvable than old orders.
   */
  public static get order(): CartDeliverabilityProblemType[] {
    return [
      CartDeliverabilityProblemTypes.ITEM_MENU_MISMATCH,
      CartDeliverabilityProblemTypes.CP_ON_HOLIDAY,
      CartDeliverabilityProblemTypes.VENDOR_ON_HOLIDAY,
      CartDeliverabilityProblemTypes.SAME_DAY_ORDER,
      CartDeliverabilityProblemTypes.VENDOR_CAPACITY_EXCEEDED,
      CartDeliverabilityProblemTypes.TOMORROW_ORDER_PAST_DEADLINE,
      CartDeliverabilityProblemTypes.OUTSIDE_MENU_SCHEDULE,
      CartDeliverabilityProblemTypes.UNDELIVERABLE_ITEMS,
    ];
  }
}

export type CartDeliverabilityProblemTypeEnum = typeof CartDeliverabilityProblemTypes;
