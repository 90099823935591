/* eslint-disable @typescript-eslint/type-annotation-spacing */

export type PackagingTypeChoice = 'none' | 'individual' | 'buffet' | 'dont_mind' | 'special' | 'n/a';

export class PackagingTypeChoices {
  public static NONE:       PackagingTypeChoice & 'none'       = 'none';
  public static INDIVIDUAL: PackagingTypeChoice & 'individual' = 'individual';
  public static BUFFET:     PackagingTypeChoice & 'buffet'     = 'buffet';
  public static DONT_MIND:  PackagingTypeChoice & 'dont_mind'  = 'dont_mind';
  public static SPECIAL:    PackagingTypeChoice & 'special'    = 'special';
  public static NA:         PackagingTypeChoice & 'n/a'        = 'n/a';

  public static get values(): PackagingTypeChoice[] {
    return [
      PackagingTypeChoices.NONE,
      PackagingTypeChoices.INDIVIDUAL,
      PackagingTypeChoices.BUFFET,
      PackagingTypeChoices.DONT_MIND,
      PackagingTypeChoices.SPECIAL,
      PackagingTypeChoices.NA,
    ];
  }

  // Do not modify this line - Custom code below here

  public static fromBackendValue(value: number | null): PackagingTypeChoice {
    switch (value) {
      case null:
      case undefined:
      case 0:
        return PackagingTypeChoices.NONE;
      case 1:
        return PackagingTypeChoices.INDIVIDUAL;
      case 2:
        return PackagingTypeChoices.BUFFET;
      case 3:
        return PackagingTypeChoices.DONT_MIND;
      case 4:
        return PackagingTypeChoices.SPECIAL;
      case 5:
        return PackagingTypeChoices.NA;
      default:
        throw new Error(`Unexpected packaging type choice value "${value}"`);
    }
  }
}

export type PackagingTypeChoiceEnum = typeof PackagingTypeChoices;
