import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, IsoDate, ItemResponse } from '@citypantry/util';
import { HEADER_KEY_DISABLE_ERROR_HANDLING } from '@citypantry/util-http-interfaces';
import {
  createOrderScheduleFromJson,
  OrderId,
  OrderSchedule,
  OrderScheduleId,
  OrderScheduleType,
  OrderScheduleTypes,
} from '@citypantry/util-models';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderScheduleApi {

  constructor(
    private http: HttpClient,
  ) { }

  public createOrderSchedule(
    orderGroupId: OrderId,
    interval: number,
    endDate: IsoDate,
    useChoiceOpenTime: boolean,
    type: OrderScheduleType = OrderScheduleTypes.WEEKLY
  ): Observable<OrderSchedule> {
    const request = {
      orderGroup: orderGroupId,
      scheduleData: { interval, endDate, type, useChoiceOpenTime }
    };
    return this.http.post<ItemResponse<OrderSchedule>>(`/order-schedules`, request, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(createOrderScheduleFromJson),
    );
  }

  public cancelOrderSchedule(
    orderScheduleId: OrderScheduleId,
    date: moment.Moment
  ): Observable<OrderSchedule> {
    const request = { date: IsoDate.fromMoment(date) };
    return this.http.post<ItemResponse<OrderSchedule>>(`/order-schedules/${orderScheduleId}/cancel`, request, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' }
    }).pipe(
      getItem(),
      map(createOrderScheduleFromJson),
    );
  }

  public skipOrderScheduleDate(
    orderScheduleId: OrderScheduleId,
    date: moment.Moment,
  ): Observable<null> {
    const request = { date: IsoDate.fromMoment(date) };
    return this.http.post(`/order-schedules/${ orderScheduleId }/skip`, request, {
      headers: { [HEADER_KEY_DISABLE_ERROR_HANDLING]: 'true' },
    }).pipe(
      mapTo(null),
    );
  }
}
