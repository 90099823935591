import { environment } from '@citypantry/shared-app-config';
import { boolObjectToStrings } from '@citypantry/util';

export type Dietary = 'none' | 'vegetarian' | 'vegan' | 'noGluten' | 'noNuts' | 'noDairy' | 'halal' | 'pescatarian';

export type DietaryExceptNone = Exclude<Dietary, 'none'>;

/**
 * List of dietary names used for displaying. Does not include "none" property.
 */
export const DIETARIES_DISPLAY_ORDER: DietaryExceptNone[] = [
  'vegetarian',
  'vegan',
  'noGluten',
  'noNuts',
  'noDairy',
  'halal',
  'pescatarian',
];

/**
 * List of dietary names used for form building. Includes the "none" property.
 */
export const DIETARIES_FORM_ORDER: Dietary[] = (DIETARIES_DISPLAY_ORDER as Dietary[]).concat('none');

// NOTE: If you are adding a new Dietary, ensure you also add it to the DietaryType enum
export type Dietaries = { [key in Dietary]: boolean };

export const EMPTY_DIETARIES: Dietaries = {
  vegetarian: false,
  vegan: false,
  noGluten: false,
  noNuts: false,
  noDairy: false,
  halal: false,
  pescatarian: false,
  none: false,
};
Object.freeze(EMPTY_DIETARIES);

type CapitalisedDietaries = 'VEGETARIAN' | 'VEGAN' | 'NO_GLUTEN' | 'NO_NUTS' | 'NO_DAIRY' | 'HALAL' | 'PESCATARIAN' | 'NONE';
/**
 * Allow using Dietaries like an enum by providing a const with the same name.
 * NOTE that this is different from DietaryTypes which uses a capitalised version of these names.
 */
/* eslint-disable @typescript-eslint/naming-convention */
export const Dietaries: { [key in CapitalisedDietaries]: Dietary } = {
  VEGETARIAN: 'vegetarian',
  VEGAN: 'vegan',
  NO_GLUTEN: 'noGluten',
  NO_NUTS: 'noNuts',
  NO_DAIRY: 'noDairy',
  HALAL: 'halal',
  PESCATARIAN: 'pescatarian',
  NONE: 'none',
};
Object.freeze(Dietaries);
/* eslint-enable @typescript-eslint/naming-convention */

if (!environment.production) {
  for (const key of Object.keys(EMPTY_DIETARIES)) {
    if (Object.values(Dietaries).indexOf(key as Dietary) < 0) {
      throw new Error(`Dietary "${key}" is missing from the Dietaries const!`);
    }
  }
}

/**
 * Constructs the dietaries object from JSON. To be used when mapping the result of an HTTP call.
 */
export function createDietariesFromJson(json?: Partial<Dietaries>): Dietaries {
  if (!json) {
    json = {};
  }
  const dietaries: Dietaries = { ...EMPTY_DIETARIES };
  Object.keys(dietaries).forEach((dietary: Dietary) => {
    if (json[dietary]) {
      dietaries[dietary] = true;
    }
  });

  return dietaries;
}

/**
 * Calculates the number of dietaries set, including "none".
 * @param dietaries A Dietaries object.
 * @returns {number} The number of dietary values set to true.
 */
export function numberOfDietariesSet(dietaries: Dietaries): number {
  return dietaries && boolObjectToStrings(dietaries, DIETARIES_FORM_ORDER).length || 0;
}

/**
 * Calculates the number of dietaries set, excluding "none".
 * @param dietaries A Dietaries object.
 * @returns {number} The number of dietary values set to true.
 */
export function numberOfVisibleDietariesSet(dietaries: Dietaries): number {
  return dietaries && boolObjectToStrings(dietaries, DIETARIES_DISPLAY_ORDER).length || 0;
}

/**
 * Returns the union from a set of Dietaries objects ignoring the `none` property.
 *
 * @param {Dietaries[]} dietaries
 * @returns {Dietaries}
 */
export function dietariesUnion(dietaries: Dietaries[]): Dietaries {
  return dietaries.reduce((union: Dietaries, current: Dietaries) => ({
    vegetarian:  current.vegetarian  || union.vegetarian,
    vegan:       current.vegan       || union.vegan,
    noGluten:    current.noGluten    || union.noGluten,
    noNuts:      current.noNuts      || union.noNuts,
    noDairy:     current.noDairy     || union.noDairy,
    pescatarian: current.pescatarian || union.pescatarian,
    halal:       current.halal       || union.halal,
    none: false,
  }), { ...EMPTY_DIETARIES } as Dietaries);
}

/**
 * Returns the intersection of a set of Dietaries objects computing the `none` property.
 *
 * @param {Dietaries[]} dietaries
 * @returns {Dietaries}
 */
export function dietariesIntersection(dietaries: Dietaries[]): Dietaries {
  const intersection = !dietaries.length
    ? { ...EMPTY_DIETARIES }
    : dietaries.reduce((result: Dietaries, current: Dietaries) =>
      ({
        vegetarian:  current.vegetarian && result.vegetarian,
        vegan:       current.vegan && result.vegan,
        noGluten:    current.noGluten && result.noGluten,
        noNuts:      current.noNuts && result.noNuts,
        noDairy:     current.noDairy && result.noDairy,
        pescatarian: current.pescatarian && result.pescatarian,
        halal:       current.halal && result.halal,
        none:        false,
      })) as Dietaries;

  return {
    ...intersection,
    none: numberOfVisibleDietariesSet(intersection) === 0
  };
}
