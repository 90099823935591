import { mapSelectors } from '@citypantry/util';
import { getRouterState } from '../app.state';
import { RouterState } from './router.state';

const getQueryParams = (state: RouterState) => state && state.state && state.state.queryParams || {};
const getUrlFragment = (state: RouterState) => state && state.state && state.state.fragment || '';

export const RouterSelectors = mapSelectors(getRouterState, {
  getQueryParams,
  getUrlFragment,
});
