import { Pipe, PipeTransform } from '@angular/core';
import { UnreachableCaseError } from '@citypantry/util';
import { ServingStyle, ServingStyles } from '@citypantry/util-models';

@Pipe({
  name: 'servingStyle'
})
export class ServingStylePipe implements PipeTransform {
  public transform(value: ServingStyle): string {
    switch (value) {
      case ServingStyles.PLATTER:
        return 'Platter';
      case ServingStyles.BUFFET:
        return 'Buffet';
      case ServingStyles.POPUP:
        return 'Pop-up Event';
      case ServingStyles.INDIVIDUAL:
        return 'Individual Portions';
      default :
        throw new UnreachableCaseError(value);
    }
  }
}
